import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { Debt } from "../../../libs/types/UniversalSurvey/Debts/debt";
import { SimpleDebt } from "../../../libs/types/UniversalSurvey/Debts/simpleDebt";
import { defaultSimpleDebt } from "../../../libs/resources/defaults/frontend/defaultSimpleDebt";
import { DebtHolder } from "../../../libs/types/UniversalSurvey/Debts/debtHolder";

export type DebtsState = {
    debtBeingEdited: Debt | undefined;
    debtsInDeal: SimpleDebt[];
    debtHolder: DebtHolder | undefined;
}

export const defaultDebtsState: DebtsState = {
    debtBeingEdited: undefined,
    debtsInDeal: [],
    debtHolder: undefined
}

export function debtsReducer(state: DebtsState, action: Record<string, any>): DebtsState {
    switch (action.type) {
        case actions.SET_DEBT_BEING_EDITED:
            return { ...state, debtBeingEdited: action.payload };
        case actions.SET_DEBT_HOLDER:
            return { ...state, debtHolder: action.payload };
        case actions.ADD_DEBT:
            const debtsCopy = [...state.debtsInDeal, action.payload];
            return { ...state, debtsInDeal: debtsCopy };
        case actions.SET_DEBTS_IN_DEAL:
            return { ...state, debtsInDeal: action.payload }
        case actions.REMOVE_DEBT:
            return { ...state, debtsInDeal: findAndRemove([...state.debtsInDeal], ["id"], [action.payload]) };
        case actions.UPDATE_DEBT:
            const tempDebts = [...state.debtsInDeal];
            const matchingDebt = tempDebts.find((debt) => debt.id === action.payload.debt.id);
            if(matchingDebt) {
                const newDebt: any = { ...defaultSimpleDebt };
                for(const key of Object.keys(newDebt)) {
                    newDebt[key as keyof SimpleDebt] = action.payload.debt[key];
                }
                newDebt.debt_holder_record = action.payload.debt_holder;
                tempDebts[tempDebts.indexOf(matchingDebt)] = newDebt;
            }
            return { ...state, debtsInDeal: tempDebts };
        default:
            return state;
    }
}