import { PriorityAfterClosingItem } from "../../../types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosing";

export const defaultPriorityAfterClosingItem: PriorityAfterClosingItem = {
    record_id: -1,
    type: "mortgage",
    priority_after_closing: 1,
    date_updated: undefined,
    date_created: undefined,

    new_mortgage_record: undefined,
    lien_record: undefined,
}