import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { FireInsuranceCompany, FireInsuranceCompanyErrorState } from "../../../../libs/types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { defaultFireInsuranceCompany } from "../../../../libs/resources/defaults/frontend/defaultFireInsuranceCompany";
import { createNewFireInsuranceCompanyRecord, saveFireInsuranceCompany } from "../../../../context/UniversalSurvey/asyncActions/fireInsuranceCompanies";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultFireInsuranceCompanyErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFireInsuranceCompanyErrorState";

type Props = Omit<ModalProps, "children">;

export default function FireInsuranceCompanyModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [companyObj, setCompanyObj] = useState<FireInsuranceCompany>({ ...defaultFireInsuranceCompany });
    const [originalCopy, setOriginalCopy] = useState<FireInsuranceCompany>({ ...defaultFireInsuranceCompany });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [errorState, setErrorState] = useState<FireInsuranceCompanyErrorState>({ ...defaultFireInsuranceCompanyErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.fireInsuranceCompanies.editingCompanyRecord && state.fireInsuranceCompanies.companyInfo) {
            setCompanyObj(state.fireInsuranceCompanies.companyInfo);
            setOriginalCopy(state.fireInsuranceCompanies.companyInfo);
            let tempRegionList: RegionListItem[] = [];
            if (state.fireInsuranceCompanies.companyInfo.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.fireInsuranceCompanies.companyInfo.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
        } else {
            setCompanyObj({ ...defaultFireInsuranceCompany });
            setOriginalCopy({ ...defaultFireInsuranceCompany });
            setSelectedRegionList([]);
        }
    }, [state.fireInsuranceCompanies.editingCompanyRecord]);

    function updateCompanyInfo<
        K extends keyof FireInsuranceCompany,
        V extends FireInsuranceCompany[K]
    >(key: K, value: V): void {
        const tempCompanyObj = { ...companyObj }
        tempCompanyObj[key] = value;
        setCompanyObj(tempCompanyObj);
    }

    function submit() {
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.fireInsuranceCompanies.editingCompanyRecord) {
            saveFireInsuranceCompany(dispatch, String(state.deal.dealInfo?.id), companyObj, regionList);
        } else {
            createNewFireInsuranceCompanyRecord(dispatch, String(state.deal.dealInfo?.id), companyObj, regionList);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD, payload: false });
        setCompanyObj({ ...defaultFireInsuranceCompany });
        setOriginalCopy({ ...defaultFireInsuranceCompany });
        setSelectedRegionList([]);
        setErrorState({ ...defaultFireInsuranceCompanyErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(companyObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateCompanyInfo("all_regions", true);
        } else {
            updateCompanyInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function isSaveDisabled(): boolean {
        if (!companyObj.name || _.isEqual(originalCopy, companyObj) || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, companyObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.fireInsuranceCompanies.editingCompanyRecord ? "" : "New "}Fire Insurance Company`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.fireInsuranceCompanies.editingCompanyRecord ? " Changes" : ""}`}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveFireInsuranceCompany])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{text: "Company Name", inputId: "name", isRequired: true}}
                            placeholder="Company's name"
                            value={companyObj.name ?? undefined}
                            onChange={(e) => updateCompanyInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Company Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Abbreviated Name", inputId: "abbr_name"}}
                            value={companyObj.abbr_name ?? undefined}
                            onChange={(e) => updateCompanyInfo("abbr_name", e.target.value)}
                            placeholder="Abbreviated name"
                            inputProps={{ "aria-label": "Abbreviated Name" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ComboBox
                            options={state.regions.regions}
                            tokenField
                            value={selectedRegionList}
                            label={{ text: "Province(s) of Operation", inputId: "provinces" }}
                            onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                            placeholder="Province initials"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Email", inputId: "email"}}
                            value={companyObj.email ?? undefined}
                            onChange={(e) => updateCompanyInfo("email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                setErrorState: setErrorState,
                                errorKey: "email",
                                errorState: errorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={companyObj.phone}
                            onChange={(value) => updateCompanyInfo("phone", value)}
                            label={{ text: "Phone", inputId: "phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={companyObj.fax}
                            onChange={(value) => updateCompanyInfo("fax", value)}
                            label={{ text: "Fax", inputId: "fax" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "fax",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={companyObj.general_address}
                            setAddress={(value) => updateCompanyInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}