import { SigningAppointmentRequest } from "../../../types/UniversalSurvey/SigningAppointment/signingAppointmentRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultSigningAppointmentRequest: SigningAppointmentRequest = {
    signing_date: null,
    address: defaultAddressRequest,
    jurisdiction: null,
    municipality: null,
    remote: true,
    signed_at_subject: true
}