import { DebtAction } from "../resources/enums/debtAction";
import { DebtHolderTypes } from "../resources/enums/debtHolderTypes";
import { EmploymentStatus } from "../resources/enums/employmentStatus";
import { FamilyLawAct } from "../resources/enums/familyLawAct";
import { GovernmentLevels } from "../resources/enums/governmentLevels";
import { ChargeHolderCompanyType, LienInstrumentType } from "../resources/enums/liens";
import { LenderType } from "../resources/enums/lenderInstitutions";
import { PaymentDeliveryMethod } from "../resources/enums/paymentMethod";
import { PoaEoeRelationshipActing, PoaEoeRelationshipType } from "../resources/enums/poaEoeRelationships";
import { PropertyPinType } from "../resources/enums/propertyPinType";
import { ConditionType } from "../resources/enums/conditionType";
import { ConditionSource } from "../resources/enums/conditionSource";
import { SimpleLien } from "../types/UniversalSurvey/ExistingLien/simpleLien";
import { getExistingLienTabNameFromObject } from "./labeling/lien";
import { SimpleDebt } from "../types/UniversalSurvey/Debts/simpleDebt";
import { DocumentCategory } from "../resources/enums/documents/category";
import { DealActingAs } from "../resources/enums/dealActingAs";
import { ProvinceDB } from "../resources/enums/provinces";
import { TransactionTypeDB } from "../resources/enums/transactions";
import { LetterToMortgageeNewMortgageContentType } from "../resources/enums/documents/letterToMortgageeNewMortgage";
import { LetterToMortgageeExistingMortgageContentType } from "../resources/enums/documents/letterToMortgageeExistingMortgage";
import { DeliveryMethod } from "../resources/enums/deliveryMethod";
import { PaymentRecipientType, ThirdPartyTransfer } from "../resources/enums/paymentGroup";
import { AddressedTo } from "../resources/enums/addressedTo";
import { DealClientCapacity } from "../resources/enums/dealClientCapacity";
import { LegalProfessionalType } from "../resources/enums/legalProfessionalType";
import { DealStatus } from "../resources/enums/dealStatus";

function addOrdinal(value: number): string {
    switch (value % 10) {
        case 1:
            return value + "st";
        case 2:
            return value + "nd";
        case 3:
            return value + "rd";
        default:
            return value + "th";
      }
}

function formatFrequency(value?: string): string {
    switch (value) {
        case "weekly":
            return "Weekly";
        case "bi_weekly":
            return "Bi-weekly";
        case "semi_monthly":
            return "Semi-monthly";
        case "monthly":
            return "Monthly";
        case "annual":
            return "Annual";
        case "on_demand":
            return "On Demand"
        default:
            return ""
    }
}

function formatYears(value: number): string {
    switch (value) {
        case 1:
            return `${value} year`;
        default:
            return `${value} years`;
    }
}

function formatInsuranceAmount(value: string): string {
    switch (value) {
        case "full_insurance_amount":
            return "Full Insurance Amount";
        case "see_sct":
            return "See SCT"
        default:
            return ""
    }
}

function formatMortgageType(value: string): string {
    switch (value) {
        case "mortgage":
            return "Mortgage";
        case "heloc_step":
            return "HELOC/Step Mortgage";
        case "construction":
            return "Construction Mortgage";
        case "line_of_credit":
            return "Line of Credit"
        case "elv":
            return "ELV"
        default:
            return ""
    }
}

function capitalizeFirstLetter(value: string | undefined): string | undefined {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
}

function formatEmploymentStatus(value: EmploymentStatus): string {
    switch (value) {
        case EmploymentStatus.Employed:
            return "Employed";
        case EmploymentStatus.SelfEmployed:
            return "Self-Employed";
        case EmploymentStatus.Unemployed:
            return "Unemployed";
        case EmploymentStatus.Retired:
            return "Retired";
    }
}

function formatFamilyLawAct(value: FamilyLawAct): string {
    switch (value) {
        case FamilyLawAct.Both:
            return "Both spouses are party to this document";
        case FamilyLawAct.Consent:
            return "Spouse consents to the transaction";
        case FamilyLawAct.Consent2:
            return "Consents to the transaction";
        case FamilyLawAct.NotMatrimonial:
            return "Property is not a matrimonial home";
        case FamilyLawAct.Separated:
            return "Separated and property is not family residence";
        case FamilyLawAct.Released:
            return "Spouse has released all rights under separation agreement";
        case FamilyLawAct.CourtOrder:
            return "Transaction is authorized by a court order";
        case FamilyLawAct.NotFamilyResidence:
            return "Not separated and property is not a family residence";
        default:
            return "";
    }
}

function formatPoaEoeActing(value: PoaEoeRelationshipActing): string {
    switch (value) {
        case PoaEoeRelationshipActing.Jointly:
            return "Jointly";
        case PoaEoeRelationshipActing.Severally:
            return "Jointly and Severally";
        case PoaEoeRelationshipActing.Solely:
            return "Solely";
        default:
            return "";
    }
}

function formatPoaEoeType(value: PoaEoeRelationshipType): string {
    switch (value) {
        case PoaEoeRelationshipType.EOE:
            return "Executor of Estate";
        case PoaEoeRelationshipType.POA:
            return "Power of Attorney";
        case PoaEoeRelationshipType.dummy:
            return "";
        default:
            return "";
    }
}

function formatPropertyPinType(value: PropertyPinType): string {
    switch (value) {
        case PropertyPinType.Dwelling:
            return "Dwelling";
        case PropertyPinType.Locker:
            return "Locker";
        case PropertyPinType.Parking:
            return "Parking";
        case PropertyPinType.Storage:
            return "Storage";
        case PropertyPinType.ParkingAndStorage:
            return "Parking and Storage";
        case PropertyPinType.SharedProperty:
            return "Shared Property";
        case PropertyPinType.Other:
            return "Other";
        default:
            return "";
    }
}

function formatDebtAction(value: DebtAction): string {
    switch (value) {
        case DebtAction.PayDown:
            return "Pay Down";
        case DebtAction.Payout:
            return "Payout";
        case DebtAction.PayoutAndClose:
            return "Payout and Close";
        default:
            return "";
    }
}

function formatPaymentMethod(value?: PaymentDeliveryMethod): string {
    switch(value) {
        case PaymentDeliveryMethod.EFT:
            return "EFT";
        case PaymentDeliveryMethod.ETransfer:
            return "E-Transfer";
        case PaymentDeliveryMethod.Other:
            return "Other";
        case PaymentDeliveryMethod.Wire:
            return "Wire";
        default:
            return "";
    }
}

function formatDebtHolderType(value: DebtHolderTypes): string {
    switch(value) {
        case DebtHolderTypes.Lender:
            return "Lender";
        case DebtHolderTypes.MortgageBrokerage:
            return "Mortgage Brokerage";
        case DebtHolderTypes.Company:
            return "Company";
        case DebtHolderTypes.Other:
            return "Other";
        case DebtHolderTypes.Government:
            return "Government";
        default:
            return "";
    }
}

function formatLenderType(value?: LenderType): string {
    switch(value) {
        case LenderType.Institution:
            return "Institution";
        case LenderType.MortgageCentre:
            return "Mortgage Centre";
        case LenderType.Personal:
            return "Personal";
        case LenderType.Private:
            return "Private";
        default:
            return "";
    }
}

function formatGovernmentLevel(value?: GovernmentLevels): string {
    switch(value) {
        case GovernmentLevels.Federal:
            return "Federal";
        case GovernmentLevels.Municipal:
            return "Municipal";
        case GovernmentLevels.Provincial:
            return "Provincial";
        default:
            return ""
    }
}

function formatChargeHolderCompanyType(value?: ChargeHolderCompanyType): string {
    switch(value) {
        case ChargeHolderCompanyType.Construction:
            return "Construction";
        case ChargeHolderCompanyType.Utility:
            return "Utility";
        default:
            return "";
    }
}

function formatConditionType(value?: ConditionType): string {
    switch(value) {
        case ConditionType.Deliver:
            return "Deliver";
        case ConditionType.Discharge:
            return "Discharge";
        case ConditionType.Holdback:
            return "Holdback";
        case ConditionType.Other:
            return "Other";
        case ConditionType.Pay:
            return "Pay";
        case ConditionType.Repair:
            return "Repair";
        case ConditionType.Review:
            return "Review";
        case ConditionType.Writ:
            return "Writ";
        default:
            return "";
    }
}

function formatConditionSource(value?: ConditionSource): string {
    switch(value) {
        case ConditionSource.AgreementOfPurchaseAndSale:
            return "Agreement of Purchase and Sale";
        case ConditionSource.MortgageInstruction:
            return "Mortgage Instruction";
        case ConditionSource.Title:
            return "Title";
        case ConditionSource.Other:
            return "Other";
        default:
            return "";
    }
}

function formatLienForConditionDropdown(lien: SimpleLien): string {
    let name = getExistingLienTabNameFromObject(lien) ?? "Encumbrance";
    if (lien.payout_calc_principal !== undefined) {
        name += ` - $${lien.payout_calc_principal}`;
    }
    return name;
}

function formatDebtForConditionDropdown(debt: SimpleDebt): string {
    let name = debt.debt_holder_record?.name ?? "Debt";
    if (debt.amount_payable !== undefined) {
        name += ` - $${debt.amount_payable}`;
    }
    return name;
}

function formatDocumentCategory(docCategory: DocumentCategory): string {
    switch(docCategory) {
        case DocumentCategory.AutoDiscovered:
            return "";
        case DocumentCategory.Folder:
            return "";
        case DocumentCategory.Generated:
            return "PDF";
        case DocumentCategory.Received:
            return "PDF";
        case DocumentCategory.Uploaded:
            return "PDF";
        case DocumentCategory.Template:
            return "Preview"
        default:
            return "";
    }
}

function formatActingAs(value: DealActingAs): string {
    switch (value) {
        case DealActingAs.BorrowerOnly:
            return "Borrower";
        case DealActingAs.LenderOnly:
            return "Lender";
        case DealActingAs.BothMainClientIsBorrower:
            return "Both, borrower is main";
        case DealActingAs.BothMainClientIsLender:
            return "Both, lender is main";
        
        default:
            return "";
    }
}

function formatProvinceDB(province: ProvinceDB): string {
    switch (province) {
        case ProvinceDB.Alberta:
            return "Alberta";
        case ProvinceDB.BritishColumbia:
            return "British Columbia";
        case ProvinceDB.Manitoba:
            return "Manitoba";
        case ProvinceDB.NewBrunswick:
            return "New Brunswick";
        case ProvinceDB.NewfoundlandAndLabrador:
            return "Newfoundland and Labrador";
        case ProvinceDB.NorthwestTerritories:
            return "Northwest Territories";
        case ProvinceDB.NovaScotia:
            return "Nova Scotia";
        case ProvinceDB.Nunavut:
            return "Nunavut";
        case ProvinceDB.Ontario:
            return "Ontario";
        case ProvinceDB.PrinceEdwardIslad:
            return "Prince Edward Island";
        case ProvinceDB.Quebec:
            return "Quebec";
        case ProvinceDB.Saskatchewan:
            return "Saskatchewan";
        case ProvinceDB.Yukon:
            return "Yukon";
        default:
            return "";
    }
}

function formatThirdPartyTransfer(value: ThirdPartyTransfer | undefined): string {
    switch(value) {
        case ThirdPartyTransfer.Easyfund:
            return "Easyfund";
        case ThirdPartyTransfer.Surefund:
            return "Surefund";
        default:
            return "";
    }
}

function formatTransactionType(transaction: TransactionTypeDB): string {
    switch (transaction) {
        case TransactionTypeDB.Mortgage:
            return "Mortgage";
        case TransactionTypeDB.ILA:
            return "ILA";
        case TransactionTypeDB.TitleTransfer:
            return "Title Transfer"
        default:
            return "";
    }
}

function formatLetterToMortgageeNewMortgageContentType(value: LetterToMortgageeNewMortgageContentType): string {
    switch (value) {
        case LetterToMortgageeNewMortgageContentType.Blank:
            return "Blank";
        default:
            return "";
    }
}

function formatLetterToMortgageeExistingMortgageContentType(value: LetterToMortgageeExistingMortgageContentType): string {
    switch (value) {
        case LetterToMortgageeExistingMortgageContentType.Blank:
            return "Blank";
        case LetterToMortgageeExistingMortgageContentType.PayoutStatement:
            return "Payout Statement";
        case LetterToMortgageeExistingMortgageContentType.InformationAndPostponementRequest:
            return "Information and Postponement Request";
        default:
            return "";
    }
}

function formatDeliveryMethod(deliveryMethod: DeliveryMethod | undefined): string {
    switch(deliveryMethod) {
        case DeliveryMethod.ChequeByCourier:
            return "Cheque By Courier";
        case DeliveryMethod.ChequeByMail:
            return "Cheque By Mail";
        case DeliveryMethod.EFT:
            return "EFT";
        case DeliveryMethod.Wire:
            return "Wire";
        default: 
            return "";
    }
}

function formatPaymentRecipient(recipient: PaymentRecipientType | undefined): string {
    switch(recipient) {
        case PaymentRecipientType.Client:
            return "Client";
        case PaymentRecipientType.Company:
            return "Company";
        case PaymentRecipientType.Government:
            return "Government";
        case PaymentRecipientType.Lawyer:
            return "Lawyer";
        case PaymentRecipientType.Lender:
            return "Lender";
        case PaymentRecipientType.MortgageBrokerage:
            return "Mortgage Brokerage";
        case PaymentRecipientType.OtherChargeHolder:
            return "Other";
        case PaymentRecipientType.ThirdPartyTransfer:
            return "Third Party Transfer";
        default:
            return ""
    }
}

function formatAddressedTo(addressedTo: AddressedTo | undefined): string {
    switch (addressedTo) {
        case AddressedTo.LawFirm:
            return "Other Law Firm";
        case AddressedTo.Both:
            return "Both";
        case AddressedTo.Lender:
            return "Mortgage Lender";
        default:
            return "";
    }
}

function formatDealClientCapacity(clientCapacity: DealClientCapacity | undefined): string {
    switch (clientCapacity) {
        case DealClientCapacity.JointTenant:
            return "Joint Tenant";
        case DealClientCapacity.RegisteredOwner:
            return "Registered Owner";
        case DealClientCapacity.TenantsInCommon:
            return "Tenants in Common";
        default:
            return ""
    }
}

function formatLegalProfessionalType(type: LegalProfessionalType): string {
    switch (type) {
        case LegalProfessionalType.Lawyer:
            return "Lawyer";
        case LegalProfessionalType.Notary:
            return "Notary";
        case LegalProfessionalType.Commissioner:
            return "Commissioner";
    }
}

function formatLienInstrumentType(type: LienInstrumentType | undefined): string {
    switch (type) {
        case LienInstrumentType.BuildersLien:
            return "Builder's Lien";
        case LienInstrumentType.Caveat:
            return "Caveat";
        case LienInstrumentType.CertificateOfLisPendens:
            return "Certificate of Lis Pendens";
        case LienInstrumentType.DowerRelease:
            return "Dower Release";
        case LienInstrumentType.EncroachmentAgreement:
            return "Encroachment Agreement";
        case LienInstrumentType.Mortgage:
            return "Mortgage";
        case LienInstrumentType.Order:
            return "Order";
        case LienInstrumentType.Postponement:
            return "Postponement";
        case LienInstrumentType.PowerOfAttorney:
            return "Power of Attorney";
        case LienInstrumentType.PublicHealthNotice:
            return "Public Health Notice";
        case LienInstrumentType.TaxNotification:
            return "Tax Notification";
        case LienInstrumentType.AgreementForSale:
            return "Agreement For Sale";
        case LienInstrumentType.CertificateOfPendingLitigation:
            return "Certificate of Pending Litigation";
        case LienInstrumentType.CourtOrder:
            return "Court Order";
        case LienInstrumentType.CrownDebt:
            return "Crown Debt";
        case LienInstrumentType.EquitableCharge:
            return "Equitable Charge";
        case LienInstrumentType.FamilyLawActCharge:
            return "Family Law Act Charge";
        case LienInstrumentType.Judgment:
            return "Judgement";
        case LienInstrumentType.LandSpouseProtectionAct:
            return "Land (Spouse Protection) Act";
        case LienInstrumentType.LandTaxDeferment:
            return "Land Tax Deferment";
        case LienInstrumentType.Lease:
            return "Lease";
        case LienInstrumentType.LifeEstate:
            return "Life Estate";
        case LienInstrumentType.OptionToPurchase:
            return "Option to Purchase";
        case LienInstrumentType.PriorityAgreement:
            return "Priority Agreement";
        case LienInstrumentType.PropertyTransferTaxAct:
            return "Property Transfer Tax Act";
        case LienInstrumentType.RightOfFirstRefusal:
            return "Right of First Refusal";
        case LienInstrumentType.StrataPropertyActLien:
            return "Strata Property Act Lien";
        case LienInstrumentType.AgreementOfPurchaseAndSale:
            return "Agreement of Purchase and Sale";
        case LienInstrumentType.ApplicationForInhibitingOrder:
            return "Application for Inhibiting Order";
        case LienInstrumentType.ApplicationToAnnexRestrictiveCovS118:
            return "Application to Annex Restrictive Cov s.118";
        case LienInstrumentType.AssignmentOfRentGeneral:
            return "Assignment of Rent General";
        case LienInstrumentType.AssignmentOfRents:
            return "Assignment of Rents";
        case LienInstrumentType.CautionOfAgreementOfPurchaseAndSale:
            return "Caution of Agreement of Purchase and Sale";
        case LienInstrumentType.CautionCharge:
            return "Caution - Charge";
        case LienInstrumentType.CautionChargeBankruptcyAndInsolvencyAct:
            return "Caution - Charge (Bankruptcy and Insolvency Act)";
        case LienInstrumentType.CautionLand:
            return "Caution - Land";
        case LienInstrumentType.CautionLandEstatesAdministrationAct:
            return "Caution - Land (Estates Administration Act)";
        case LienInstrumentType.CertificateOfLienHousingDevelopmentAct:
            return "Certificate of Lien - Housing Development Act";
        case LienInstrumentType.Charge:
            return "Charge";
        case LienInstrumentType.CondominiumLien:
            return "Condominium Lien";
        case LienInstrumentType.ConstructionLien:
            return "Construction Lien";
        case LienInstrumentType.LandRegistrarsCaution:
            return "Land Registrar's Caution";
        case LienInstrumentType.MobileHome:
            return "Mobile Home";
        case LienInstrumentType.Notice:
            return "Notice";
        case LienInstrumentType.NoticeOfLease:
            return "Notice of Lease";
        case LienInstrumentType.OilAndGasLease:
            return "Oil & Gas Lease";
        case LienInstrumentType.OptionToLease:
            return "Option to Lease";
        case LienInstrumentType.SecurityInterest:
            return "Security Interest";
        case LienInstrumentType.SubdivisionAgreement:
            return "Subdivision Agreement";
        case LienInstrumentType.VendorsLien:
            return "Vendor's Lien";
        default:
            return "";
    }
}

function formatPhoneNumber(phone: string | undefined): string {
    if (!phone) return "";

    // format for Canadian/American numbers
    if (phone.startsWith("1") && phone.length === 11) {
        phone = `+1-${phone.substring(1,4)}-${phone.substring(4,7)}-${phone.substring(7)}`
    }
    return phone;
}
    
function formatDealStatus(status: DealStatus | undefined): string {
    switch (status) {
        case DealStatus.Open:
            return "Open";
        case DealStatus.Closed:
            return "Closed";
        case DealStatus.Archived:
            return "Archived";
        default:
            return "";
    }
}

export { 
    addOrdinal, 
    formatFrequency, 
    formatYears, 
    formatInsuranceAmount, 
    formatMortgageType,
    capitalizeFirstLetter,
    formatEmploymentStatus,
    formatFamilyLawAct,
    formatPoaEoeActing,
    formatPoaEoeType,
    formatPropertyPinType,
    formatDebtAction,
    formatPaymentMethod,
    formatDebtHolderType,
    formatLenderType,
    formatGovernmentLevel,
    formatChargeHolderCompanyType,
    formatConditionType,
    formatConditionSource,
    formatLienForConditionDropdown,
    formatDebtForConditionDropdown,
    formatDocumentCategory,
    formatActingAs,
    formatProvinceDB,
    formatThirdPartyTransfer,
    formatTransactionType,
    formatLetterToMortgageeNewMortgageContentType,
    formatLetterToMortgageeExistingMortgageContentType,
    formatDeliveryMethod,
    formatPaymentRecipient,
    formatAddressedTo,
    formatDealClientCapacity,
    formatLegalProfessionalType,
    formatLienInstrumentType,
    formatPhoneNumber,
    formatDealStatus
}