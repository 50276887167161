import { Button, Stack, styled } from "@mui/material";
import { Large, LargeBold } from "../../../../../components/Common/Typography/index";

import color from '../../../../../components/Common/componentStyling/Colors';
import theme from '../../../../../components/Common/componentStyling/Theme';
import CounterPill from "../../../../../components/Common/CounterPill/CounterPill";
import Theme from "../../../../../components/Common/componentStyling/Theme";

type ButtonProps = {
    active: boolean;
    label: { text: string, inputId: string };
    actionItemCount?: number;
    icon?: JSX.Element;
    onClick?: () => void;
};

const TabBarButton: React.FC<ButtonProps> = ({ ...props }) => {
    return (
        <CustomButton
            onClick={props.onClick ?? props.onClick}
            disableRipple
            active={props.active}
            id={props.label.inputId}
        >
            <Stack direction="row" gap={1} alignItems="center">
                {props.icon && (
                    <>{props.icon}</>
                )}
                {props.active ? (
                    <LargeBold style={{ color: theme.PRIMARY }}>{props.label.text}</LargeBold>
                ) : (
                    <Large>{props.label.text}</Large>
                )}
                {props.actionItemCount && (
                    <CounterPill count={props.actionItemCount} color={Theme.PRIMARY} textColor={color.WHITE} />
                )}
            </Stack>
        </CustomButton>
    );
}

const CustomButton = styled(Button, { shouldForwardProp: 
    (prop) => prop !== "active"
})<{
    active: boolean;
}>(({ active }) => ({

    color: color.BLACK,
    zIndex: 10,
    backgroundColor: active ? color.WHITE : theme.DISABLED,
    borderRadius: "0.6rem 0.6rem 0rem 0rem",
    
    borderWidth: active ? "0.2rem 0.1rem 0rem 0.1rem" : "",
    borderStyle: active ? "solid" : "",
    borderColor: active ? theme.PRIMARY : "", 

    padding: "0rem 2rem",
    gap: "0.5rem",
    textTransform: "none",
    height: "5.8rem",
    whiteSpace: "nowrap",

    '&:hover': {
        backgroundColor: active ? color.WHITE : theme.HOVER
    }
}));

export default TabBarButton;