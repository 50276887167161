import {SVGProps} from "react";
import constants from "../../styles/constants";

const ResultsNotFoundSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={271}
      height={165}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M60.175 21.965 9.62 55.656l49.8 74.171 50.556-33.692-49.8-74.17Z"
          fill={constants.colors.silver2}
        />
        <path
          d="m83.845 92.599-.586 3.546-25.14-3.591.586-3.546 25.14 3.59Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="m71.185 79.986 3.604.539-4.01 24.642-3.604-.538 4.01-24.643ZM48.027 48.657c.045.045-5.046 3.5-11.309 7.675-6.307 4.175-11.398 7.541-11.443 7.496-.045-.045 5.046-3.501 11.308-7.675 6.308-4.175 11.399-7.541 11.444-7.496ZM66.635 43.989c.044.044-8.38 5.745-18.833 12.657-10.408 6.958-18.923 12.523-18.968 12.479-.045-.045 8.38-5.746 18.833-12.658 10.452-6.913 18.922-12.523 18.968-12.478ZM70.374 49.553c.045.045-8.38 5.745-18.833 12.658-10.407 6.957-18.922 12.523-18.968 12.478-.045-.045 8.38-5.745 18.833-12.658 10.407-6.957 18.923-12.523 18.968-12.478ZM74.068 55.12c.045.044-8.38 5.745-18.832 12.657C44.828 74.734 36.313 80.3 36.267 80.255c-.045-.044 8.38-5.745 18.832-12.657 10.453-6.958 18.923-12.523 18.968-12.479ZM77.808 60.64c.045.046-8.38 5.746-18.832 12.659-10.408 6.957-18.923 12.523-18.968 12.478-.045-.045 8.38-5.746 18.833-12.658 10.452-6.912 18.922-12.523 18.967-12.478Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="m229.207 71.662-42.467 28.3 41.809 62.27 42.467-28.301-41.809-62.269Z"
          fill={constants.colors.silver2}
        />
        <path
          d="m249.103 130.976-.495 3.007-21.086-3.052.451-2.962 21.13 3.007Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="m238.471 120.383 3.018.449-3.379 20.692-3.018-.449 3.379-20.692ZM219.008 94.08c.045.046-4.235 2.963-9.507 6.464-5.271 3.501-9.596 6.329-9.641 6.284-.045-.045 4.235-2.962 9.506-6.463 5.317-3.546 9.597-6.33 9.642-6.284ZM234.641 90.176c.045.045-7.028 4.848-15.769 10.683-8.74 5.835-15.904 10.503-15.904 10.458-.045-.045 7.028-4.847 15.814-10.683 8.695-5.835 15.814-10.503 15.859-10.458ZM237.749 94.844c.045.045-7.028 4.848-15.769 10.683-8.74 5.835-15.904 10.503-15.904 10.458-.045-.045 7.029-4.847 15.814-10.683 8.696-5.835 15.814-10.503 15.859-10.458ZM240.904 99.512c.045.045-7.029 4.848-15.769 10.683-8.741 5.835-15.904 10.503-15.904 10.458-.045-.045 7.028-4.847 15.814-10.683 8.695-5.835 15.814-10.503 15.859-10.458ZM244.012 104.179c.045.045-7.028 4.848-15.769 10.683-8.74 5.835-15.904 10.503-15.904 10.458-.045-.045 7.029-4.847 15.814-10.683 8.695-5.835 15.814-10.548 15.859-10.458Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="M162.064 24.67 115.74-.012l-36.482 67.96 46.324 24.682 36.482-67.96Z"
          fill={constants.colors.silver2}
        />
        <path
          d="m117.637 71.368-2.929 1.033-7.479-20.558 2.929-.988 7.479 20.513Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="m122.142 56.647 1.081 2.962-20.499 6.957-1.081-2.917 20.499-7.002ZM136.694 26.349c-.045.09-4.73-2.38-10.497-5.431-5.767-3.052-10.408-5.611-10.363-5.7.045-.09 4.731 2.378 10.498 5.43 5.767 3.053 10.407 5.611 10.362 5.7ZM147.822 38.557c-.045.045-7.839-3.995-17.391-9.112-9.551-5.072-17.255-9.291-17.255-9.336.045-.09 7.794 3.995 17.346 9.112 9.596 5.072 17.3 9.246 17.3 9.336ZM145.074 43.63c-.045.089-7.794-3.996-17.346-9.113-9.551-5.072-17.255-9.291-17.255-9.336.045-.09 7.794 3.995 17.345 9.112 9.597 5.072 17.301 9.291 17.256 9.336ZM142.326 48.701c-.045.09-7.794-3.994-17.346-9.111-9.551-5.073-17.255-9.292-17.255-9.337.045-.09 7.794 3.995 17.345 9.112 9.597 5.117 17.301 9.292 17.256 9.336ZM139.623 53.819c-.045.09-7.794-3.995-17.346-9.112-9.596-5.072-17.3-9.247-17.255-9.336.045-.09 7.794 3.994 17.345 9.111 9.552 5.072 17.301 9.247 17.256 9.337Z"
          fill={constants.colors.grayBorder}
        />
        <path
          d="m95.785 72.357-15.904 81.602-2.072 10.683 106.372-.539c4.776-.044 9.011-4.264 10.543-10.503l18.877-77.024c1.172-4.758-1.396-9.695-5.001-9.695l-107.634.135c-2.478-.045-4.55 2.154-5.18 5.34Z"
          fill={constants.colors.red2}
        />
        <path
          d="m95.785 72.357-15.904 81.602-2.072 10.683 106.372-.539c4.776-.044 9.011-4.264 10.543-10.503l18.877-77.024c1.172-4.758-1.396-9.695-5.001-9.695l-107.634.135c-2.478-.045-4.55 2.154-5.18 5.34Z"
          fill="url(#b)"
        />
        <path
          d="m173.954 148.438-11.264-90.4c-.45-3.726-3.424-6.464-6.938-6.464l-24.69.09c-1.847 0-3.559.763-4.866 2.154l-11.714 12.434-68.662.359c-4.19 0-7.434 3.995-6.848 8.438l10.452 83.578c.45 3.68 3.425 6.463 6.894 6.463l119.663-.224c13.336.269 14.598-3.636 14.598-3.636-15.274 3.277-16.625-12.792-16.625-12.792Z"
          fill="url(#c)"
        />
        <path
          d="m122.322 130.349-4.055 4.309-31.087-27.874 4.055-4.309 31.087 27.874Z"
          fill={constants.colors.red3}
        />
        <path
          d="m116.6 101.531 4.46 3.995L92.947 135.6l-4.46-3.995 28.113-30.074Z"
          fill={constants.colors.red3}
        />
        <path d="M0 164.73h270.324" stroke={constants.colors.grayBorder} />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={145.848}
          y1={66.881}
          x2={145.848}
          y2={164.642}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={constants.colors.red5} />
          <stop offset={1} stopColor={constants.colors.red6} />
        </linearGradient>
        <linearGradient
          id="c"
          x1={114.741}
          y1={114.896}
          x2={114.741}
          y2={165.09}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={constants.colors.red} />
          <stop offset={1} stopColor={constants.colors.red4} />
        </linearGradient>
        <clipPath id="a">
          <path fill={constants.colors.white} d="M0 0h271v165H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResultsNotFoundSvg;
