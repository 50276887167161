import { ConsentToActConflictOfMortgageContext, ConsentToActConflictOfMortgageContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/consentToActConflictOfMortgageDocument";
import { defaultSimpleMortgageTransaction } from "../../frontend/defaultSimpleMortgageTransaction";


export const defaultConsentToActConflictOfMortgageContext: ConsentToActConflictOfMortgageContext = {
    mortgage_id: -1,
    
    mortgage_record: defaultSimpleMortgageTransaction,
}

export const defaultConsentToActConflictOfMortgageContextRequest: ConsentToActConflictOfMortgageContextRequest = {
    mortgage_id: -1
}