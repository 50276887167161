import * as actions from "../actionTypes"

import { Property } from "../../../libs/types/UniversalSurvey/Property/property";
import { SimpleProperty } from "../../../libs/types/UniversalSurvey/Property/simpleProperty";
import { findAndRemove } from "../../../libs/utils/arrays";

export type PropertiesState = {
    propertyInfo: Property | undefined;
    propertiesInDeal: SimpleProperty[];
    propertiesBeingSaved: number[];
    isPropertySaving: boolean;
    shouldRefreshProperty: boolean;
    pinsBeingSaved: number[];
    isPinSaving: boolean;
    shouldRefreshPin: boolean;
}

export const defaultPropertiesState: PropertiesState = {
    propertyInfo: undefined,
    propertiesInDeal: [],
    propertiesBeingSaved: [],
    isPropertySaving: false,
    shouldRefreshProperty: false,
    pinsBeingSaved: [],
    isPinSaving: false,
    shouldRefreshPin: false
}

export function propertiesReducer(state: PropertiesState, action: Record<string, any>): PropertiesState {
    switch (action.type) {
        case actions.SET_PROPERTY_INFO:
            return { ...state, propertyInfo: action.payload };
        case actions.SET_PROPERTIES_IN_DEAL:
            return { ...state, propertiesInDeal: action.payload };
        case actions.ADD_PROPERTY_BEING_SAVED:
            if (!state.propertiesBeingSaved.includes(action.payload)) {
                return { ...state, propertiesBeingSaved: [...state.propertiesBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_PROPERTY_BEING_SAVED:
            let isPropertySaving = state.isPropertySaving;
            let shouldRefreshProperty = state.shouldRefreshProperty;
            if(state.propertyInfo?.id === action.payload && isPropertySaving) {
                isPropertySaving = false;
                shouldRefreshProperty = true;
            }
            const tempPropertiesBeingSaved = [...state.propertiesBeingSaved];
            const index = tempPropertiesBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempPropertiesBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                propertiesBeingSaved: tempPropertiesBeingSaved,
                isPropertySaving: isPropertySaving,
                shouldRefreshProperty: shouldRefreshProperty
            };
        case actions.SET_IS_PROPERTY_SAVING:
            return { ...state, isPropertySaving: action.payload };
        case actions.SET_SHOULD_REFRESH_PROPERTY:
            return { ...state, shouldRefreshProperty: action.payload };

        // PINS
        case actions.ADD_PROPERTY_PIN:
            return {
                ...state,
                propertyInfo: state.propertyInfo ? {
                ...state.propertyInfo,
                property_pin_records: state.propertyInfo?.property_pin_records ?
                    [...state.propertyInfo.property_pin_records, action.payload] :
                    [action.payload] 
                } : undefined
            }
        case actions.REMOVE_PROPERTY_PIN:
            if (state.propertyInfo?.property_pin_records) {
                const tempPins = findAndRemove([...state.propertyInfo.property_pin_records], ["id"], [action.payload]);
                return {
                    ...state,
                    propertyInfo: {
                        ...state.propertyInfo,
                        property_pin_records: tempPins
                    }
                }
            }
            return state;
        case actions.ADD_PROPERTY_PIN_BEING_SAVED:
            if (!state.pinsBeingSaved.includes(action.payload)) {
                return { ...state, pinsBeingSaved: [...state.pinsBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_PROPERTY_PIN_BEING_SAVED:
            let isPinSaving = state.isPinSaving;
            let shouldRefreshPin = state.shouldRefreshPin;
            const tempPinsBeingSaved = [...state.pinsBeingSaved];
            const pinIndex = tempPinsBeingSaved.indexOf(action.payload);
            if (pinIndex >= 0) {
                tempPinsBeingSaved.splice(pinIndex, 1);
            }
            if (pinIndex >=0 && tempPinsBeingSaved.length === 0 && isPinSaving) {
                isPinSaving = false;
                shouldRefreshPin = true;
            }
            return {
                ...state,
                pinsBeingSaved: tempPinsBeingSaved,
                isPinSaving: isPinSaving,
                shouldRefreshPin: shouldRefreshPin
            };
        case actions.SET_IS_PROPERTY_PIN_SAVING:
            return { ...state, isPinSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_PROPERTY_PIN:
            return { ...state, shouldRefreshPin: action.payload };
        default:
            return state;
    }
}