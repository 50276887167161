import { useEffect, useState, useContext } from "react";

import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import TableBase from "../../../../../components/Common/Table/TableBase";
import { addDocOptions } from "../../../../libs/resources/options";
import theme from "../.././../../../components/Common/componentStyling/Theme";
import colors from "../.././../../../components/Common/componentStyling/Colors";
import SearchBar from "../../../../../components/Common/SearchBar/SearchBar";
import { getStatDecsInDeal } from "../../../../context/UniversalSurvey/asyncActions/statutoryDeclarations";
import { DocumentType } from "../../../../libs/resources/enums/documents/documentType";
import { addDocumentToDeal } from "../../../../context/UniversalSurvey/asyncActions/documents";
import { mapContextData, getContextOptions, labelDocContextOption, mapContextlessData, mapContextlessDocDisabled } from "../../../../libs/types/UniversalSurvey/Documents/utils/mapper";
import { getTrustLedgersInDeal } from "../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgers";
import { getLiensInDeal } from "../../../../context/UniversalSurvey/asyncActions/existingLiens";
import { getTrustLedgerPaymentGroupsInDeal } from "../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";
import { getSigningAppointmentsInDeal } from "../../../../context/UniversalSurvey/asyncActions/signingAppointments";
import { styled } from "@mui/material";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children">;

type AddDocRow = { data: { header: string, content?: JSX.Element, docType: DocumentType }, dontExpand?: boolean, disabled?: boolean };

export default function AddDocModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [searchValue, setSearchValue] = useState<string>("");
    const [tableRows, setTableRows] = useState<AddDocRow[]>([]);
    const [docsSelected, setDocsSelected] = useState<AddDocRow[]>([]);
    const [contextSelected, setContextSelected] = useState<Object>({});
    const [shouldTableResetState, setShouldTableResetState] = useState<boolean>(true);

    useEffect(() => {
        getStatDecsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getTrustLedgersInDeal(dispatch, String(state.deal.dealInfo?.id), Loading.TrustLedgerListSection);
        getLiensInDeal(dispatch, String(state.deal.dealInfo?.id), false, false);
        getTrustLedgerPaymentGroupsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getSigningAppointmentsInDeal(dispatch, String(state.deal.dealInfo?.id));
    }, [])
    
    useEffect(() => {
        setShouldTableResetState(true);
    }, [props.open])

    useEffect(() => {
        setShouldTableResetState(false);
        makeTableRows(searchValue);
    }, [state.documents.documentList])

    useEffect(() => {
        setShouldTableResetState(false);
        makeTableRows(searchValue);
    }, [contextSelected])

    useEffect(() => {
        setShouldTableResetState(true);
    }, [searchValue])

    function submit(): void {
        for (const doc of docsSelected) {
            let docContext;
            let docName;
            if (doc.data.content) {
                // create doc with context
                const contextId = Number(contextSelected[doc.data.header as keyof typeof contextSelected]); // may need to adjust this if there are documents with multiple types of context
                [docContext, docName] = mapContextData(doc.data.docType, contextId, state);
            } else {
                // create doc without context
                [docContext, docName] = mapContextlessData(doc.data.docType)
            }
            addDocumentToDeal(dispatch, String(state.deal.dealInfo?.id), doc.data.docType, docContext, docName);
        }
        handleDiscard();
    }

    function handleDiscard(): void {
        setContextSelected({});
        props.onClose ? props.onClose() : undefined;
    }

    function makeTableRows(searchVal: string) {
        const rows: AddDocRow[] = [];
        for (const option of addDocOptions.sort((a, b) => a.title > b.title ? 1 : -1)) {
            if (option.title.toLowerCase().includes(searchVal.toLowerCase())) {
                if (option.context) {
                    rows.push({data: {
                        header: option.title,
                        content: <div style={{ marginTop: "-1rem" }}>
                            <Dropdown
                                // @ts-ignore
                                value={Object.keys(contextSelected).includes(option.title) ? contextSelected[option.title as keyof typeof contextSelected] : undefined}
                                onChange={(e) => setContextSelected({ ...contextSelected, [option.title]: e.target.value })}
                                placeholder={`Select a ${option.context.split("_")[0].toLowerCase()}...`}
                                options={getContextOptions(option.context, state)}
                                label={{ text: `${option.context.split("_")[0]} Context`, color: theme.INPUT }}
                                formatValue={(id: number) => labelDocContextOption(option.context!, id, state)}
                                backgroundColor={colors.WHITE}
                            />
                        </div>,
                        docType: option.docType
                    }});
                } else {
                    rows.push({
                        data: { header: option.title, docType: option.docType },
                        dontExpand: true,
                        disabled: mapContextlessDocDisabled(option.docType, state)
                    });
                }
            }
        }
        setTableRows(rows);
    }

    function handleSearch(value: string) {
        setSearchValue(value);
        makeTableRows(value);
    }

    function isAllContextSelected() {
        for (const doc of docsSelected) {
            if (doc.data.content) {
                if (!contextSelected[doc.data.header as keyof typeof contextSelected]) {
                    return false;
                }
            }
        }
        return true;
    }

    return (
        <ModalBase
            open={props.open}
            onClose={() => handleDiscard()}
            onSubmit={submit}
            size="small"
            title="Add Docs"
            saveButtonLabel="Add"
            saveDisabled={docsSelected.length === 0 || !isAllContextSelected()}
            headerContent={
                <SearchBar
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    onSearch={handleSearch}
                    placeholder="Search documents..."
                />
            }
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.StatDecList, Loading.TrustLedgerListSection, Loading.LienList, Loading.TrustLedgerPaymentGroupList, Loading.SigningAppointments])}
        >
            <>
                <TableBase
                    id="add-docs-table"
                    columnHeadings={[{ label: "", objKey: "data" }]}
                    tableRows={tableRows}
                    selectEnabled
                    hideHeaderAndTitleBar
                    dontAlternateRowColors
                    setRowsSelectedInParent={setDocsSelected}
                    expandable
                    expandOnSelect
                    resetState={shouldTableResetState}
                />
                <PaddingDiv/>
            </>
        </ModalBase>
    );
}

const PaddingDiv = styled('div')({
    height: "6rem"
})