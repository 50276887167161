export enum ChargeHolderCompanyType {
    Construction = "construction",
    Utility = "utility"
}

export enum LienIsTo {
    BeDischarged = "be_discharged",
    Remain = "remain"
}

export enum LienInstrumentType {
    BuildersLien = "builders_lien",
    Caveat = "caveat",
    CertificateOfLisPendens = "certificate_of_lis_pendens",
    DowerRelease = "dower_release",
    EncroachmentAgreement = "encroachment_agreement",
    Mortgage = "mortgage",
    Order = "order",
    Postponement = "postponement",
    PowerOfAttorney = "power_of_attorney",
    PublicHealthNotice = "public_health_notice",
    TaxNotification = "tax_notification",
    AgreementForSale = "agreement_for_sale",
    CertificateOfPendingLitigation = "certificate_of_pending_litigation",
    CourtOrder = "court_order",
    CrownDebt = "crown_debt",
    EquitableCharge = "equitable_charge",
    FamilyLawActCharge = "family_law_act_charge",
    Judgment = "judgment",
    LandSpouseProtectionAct = "land_spouse_protection_act",
    LandTaxDeferment = "land_tax_deferment",
    Lease = "lease",
    LifeEstate = "life_estate",
    OptionToPurchase = "option_to_purchase",
    PriorityAgreement = "priority_agreement",
    PropertyTransferTaxAct = "property_transfer_tax_act",
    RightOfFirstRefusal = "right_of_first_refusal",
    StrataPropertyActLien = "strata_property_act_lien",
    AgreementOfPurchaseAndSale = "agreement_of_purchase_and_sale",
    ApplicationForInhibitingOrder = "application_for_inhibiting_order",
    ApplicationToAnnexRestrictiveCovS118 = "application_to_annex_restrictive_cov_s118",
    AssignmentOfRentGeneral = "assignment_of_rent_general",
    AssignmentOfRents = "assignment_of_rents",
    CautionOfAgreementOfPurchaseAndSale = "caution_of_agreement_of_purchase_and_sale",
    CautionCharge = "caution_charge",
    CautionChargeBankruptcyAndInsolvencyAct = "caution_charge_bankruptcy_and_insolvency_act",
    CautionLand = "caution_land",
    CautionLandEstatesAdministrationAct = "caution_land_estates_administration_act",
    CertificateOfLienHousingDevelopmentAct = "certificate_of_lien_housing_development_act",
    Charge = "charge",
    CondominiumLien = "condominium_lien",
    ConstructionLien = "construction_lien",
    LandRegistrarsCaution = "land_registrars_caution",
    MobileHome = "mobile_home",
    Notice = "notice",
    NoticeOfLease = "notice_of_lease",
    OilAndGasLease = "oil_and_gas_lease",
    OptionToLease = "option_to_lease",
    SecurityInterest = "security_interest",
    SubdivisionAgreement = "subdivision_agreement",
    VendorsLien = "vendors_lien"
}