import { UndertakingRequest } from "../../../types/UniversalSurvey/Undertaking/undertakingRequest";
import { AddressedTo } from "../../enums/addressedTo";
import { UndertakingStatus } from "../../enums/undertakings";

export const defaultUndertakingRequest: UndertakingRequest = {
    addressed_to: AddressedTo.LawFirm,
    direction: null,
    status: UndertakingStatus.Outstanding,
    discharged_by: null,
    instrument_number: null,
    discharge_note: null,
    discharge_date: null
}