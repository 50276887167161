import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { Document } from "../baseDocument";
import { getClientTabName } from "../../../../../context/UniversalSurvey/asyncActions/clients";
import { SimpleStatDec } from "../../../UniversalSurvey/StatutoryDeclarations/simpleStatDec";
import { getStrOutput } from "../../../../utils/pluralizationFunctions";
import { StatDecContextRequest } from "../documents/statDecDocument";
import * as _ from "lodash";
import { StatDecDocType } from "../../../../resources/enums/statDecDocType";

function labelStatDec(statDecsInDeal: SimpleStatDec[], statDecId: number): string {
    if (statDecId === -1) return "Blank";
    let label = "";
    const statDec = statDecsInDeal.find((statDec) => statDec.id === statDecId);
    if (statDec) {
        if (statDec.client_list) {
            const clientNames = [];
            for (const client of statDec.client_list) {
                clientNames.push(getClientTabName(client));
            }
            label += getStrOutput(clientNames);
        } else if (statDec.our_lawyer) {
            label += "Our Lawyer";
        }
    }
    return label;
}

function createStatDecName(statDec: SimpleStatDec | undefined): string {
    let name = "Statutory Declaration - "
    if (!statDec) return name + "Blank";
    
    if (statDec.client_list) {
        const clients = statDec.client_list.map((client) => getClientTabName(client));
        name += getStrOutput(clients);
    }

    if (statDec.our_lawyer) {
        name += "Our Lawyer";
    }

    return name;
}

function isStatDecDocInDeal(docList: Document[], statDec: SimpleStatDec): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.StatDec && doc.category === DocumentCategory.Template)
    const statDecClients = statDec.client_list ? statDec.client_list.map((client) => client.id).sort() : [];
    for (const doc of tempDocs) {
        const docClients = doc.context.clients;
        if (_.isEqual(statDecClients, docClients) && doc.context.our_lawyer === statDec.our_lawyer) {
            return true;
        }
    }
    return false;
}

function createStatDecContextRequest(statDec: SimpleStatDec | undefined): StatDecContextRequest {
    if (!statDec) return { type: StatDecDocType.Blank, clients: [], our_lawyer: false };
    return {
        type: StatDecDocType.StatDec,
        clients: statDec.client_list ? statDec.client_list.map((client) => client.id) : [],
        our_lawyer: statDec.our_lawyer
    }
}

function getStatDecDocData(statDecsInDeal: SimpleStatDec[], contextId: number): [Object, string] {
    const matchingStatDec = statDecsInDeal.find((statDec) => statDec.id === contextId);
    return [createStatDecContextRequest(matchingStatDec), createStatDecName(matchingStatDec)];
}

function getStatDecContextOptions(statDecsInDeal: SimpleStatDec[], docList: Document[]) {
    const options = [-1];
    const clientsAdded: number[][] = [];
    for (const statDec of statDecsInDeal) {
        if(!isStatDecDocInDeal(docList, statDec)) {
            const clients = statDec.client_list ? statDec.client_list.map((client) => client.id).sort() : [];
            if (!clientsAdded.some((addedClients) => _.isEqual(addedClients, clients))) {
                options.push(statDec.id);
                clientsAdded.push(clients);
            }
        }
    }
    return options;
}

export {
    labelStatDec,
    getStatDecDocData,
    getStatDecContextOptions
}