export enum PaymentDeliveryMethod {
    Wire = "wire",
    EFT = "eft",
    ETransfer = "e_transfer",
    Other = "other"
}

export enum PaymentMethod {
    Wire = "wire",
    EFT = "eft",
    ETransfer = "e_transfer",
    ChequeByMail = "cheque_by_mail",
    ChequeByCourier = "cheque_by_courier",
    OnlineBillPayment = "online_bill_payment",
    Other = "other"
}