import { GovernmentContact } from "../../../types/UniversalSurvey/GovernmentContacts/governmentContact";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultSimpleGovernment } from "./defaultSimpleGovernment";

export const defaultGovernmentContact: GovernmentContact = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    role: undefined,
    name: undefined,
    email: undefined,
    phone_work: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    government_record: defaultSimpleGovernment,
    government_contact: undefined
}