import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { getDashboardUser } from "../../../libs/service/axios/api";
import { sanitizeDashboardUserResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";

function getUser(dispatch: Dispatch<Record<string, any>>, userId: string) {
    getDashboardUser(userId)
        .then(async function (response) {
            const cleanUser = sanitizeDashboardUserResponse(response.data);
            dispatch({ type: actions.SET_USER, payload: cleanUser });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get user data: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
        })
}

export {
    getUser
}