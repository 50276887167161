import { AxiosRequestConfig, Method } from "axios";
import configuration from "../../../../utils/configuration";

const conveyanceBaseUrl = configuration.conveyanceUrl;

function generateHeader(method: Method, route: string, body?: any) {
    
    var apiUrl: string = conveyanceBaseUrl + "/" + route;
    const userID = localStorage.getItem("user_id");

    var config: AxiosRequestConfig = {
        method: method,
        url: apiUrl,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem(`token_${userID}`)}`
        }
    };

    if (body instanceof FormData) {
        config.headers!['Content-Type'] = 'multipart/form-data';
        config.data = body;
    } else {
        config.headers!['Content-Type'] = 'application/json';
        if (typeof body !== undefined) {
            config.data = body;
        }
    }

    return config;
}

export { generateHeader };