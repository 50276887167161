import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { LetterToMortgageeNewMortgageContextRequest } from "../documents/letterToMortgageeNewMortgageDocument";
import { formatLetterToMortgageeNewMortgageContentType } from "../../../../utils/formatValues";
import { Transaction } from "../../Transaction/transaction";
import { LetterToMortgageeNewMortgageContentType } from "../../../../resources/enums/documents/letterToMortgageeNewMortgage";
import { TransactionTypes } from "../../../../resources/enums/transactions";


function createLetterToMortgageeNewMortgageName(mortgage: Transaction | undefined, contentType: LetterToMortgageeNewMortgageContentType): string {
    if (!mortgage) return "";
    let name = "Letter to Mortgagee (New Mortgage)";
    
    if (mortgage.label) {
        name += ` - ${mortgage.label}`;
    }

    name += ` - ${formatLetterToMortgageeNewMortgageContentType(contentType)}`
    return name;
}

function isLetterToMortgageeNewMortgageDocInDeal(docList: Document[], mortgage: Transaction): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.LetterToMortgageeNewMortgage && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.mortgage_id === mortgage.id) return true;
    }
    return false;
}

function createLetterToMortgageeNewMortgageContextRequest(mortgage: Transaction | undefined, contentType: LetterToMortgageeNewMortgageContentType): LetterToMortgageeNewMortgageContextRequest {
    return {
        mortgage_id: mortgage ? mortgage.id : -1,
        content_type: contentType
    };
}

function getLetterToMortgageeNewMortgageDocData(transactionsInDeal: Transaction[], contextId: number, contentType: LetterToMortgageeNewMortgageContentType): [Object, string] {
    const matchingMortgage = transactionsInDeal.find((mortgage) => mortgage.id === contextId && mortgage.transaction_type === TransactionTypes.Mortgage);
    return [createLetterToMortgageeNewMortgageContextRequest(matchingMortgage, contentType), createLetterToMortgageeNewMortgageName(matchingMortgage, contentType)];
}

function getLetterToMortgageeNewMortgageContextOptions(transactionsInDeal: Transaction[], docList: Document[]) {
    const options: number[] = [];
    for (const mortgage of transactionsInDeal.filter((transaction) => transaction.transaction_type === TransactionTypes.Mortgage)) {
        if (!isLetterToMortgageeNewMortgageDocInDeal(docList, mortgage)) {
            options.push(mortgage.id);
        }
    }
    return options;
}

export {
    getLetterToMortgageeNewMortgageDocData,
    getLetterToMortgageeNewMortgageContextOptions
}