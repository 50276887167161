import * as actions from "../actionTypes"

import { LegalFirmOffice, LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { findAndReplace } from "../../../libs/utils/arrays";

export type LegalFirmOfficesState = {
    lawyerOfficeOptionList: LegalFirmOfficeListItem[];
    signerOfficeOptionList: LegalFirmOfficeListItem[];
    lenderLawyerOfficeOptionList: LegalFirmOfficeListItem[];
    recordBeingEdited: LegalFirmOffice | undefined;
    officesForFirmToLink: LegalFirmOfficeListItem[];
}

export const defaultLegalFirmOfficesState: LegalFirmOfficesState = {
    lawyerOfficeOptionList: [],
    signerOfficeOptionList: [],
    lenderLawyerOfficeOptionList: [],
    recordBeingEdited: undefined,
    officesForFirmToLink: []
}

export function legalFirmOfficesReducer(state: LegalFirmOfficesState, action: Record<string, any>): LegalFirmOfficesState {
    switch (action.type) {
        case actions.SET_SIGNER_OFFICE_OPTION_LIST:
            return { ...state, signerOfficeOptionList: action.payload };
        case actions.SET_LAWYER_OFFICE_OPTION_LIST:
            return { ...state, lawyerOfficeOptionList: action.payload };
        case actions.SET_LENDER_LAWYER_OFFICE_OPTION_LIST:
            return { ...state, lenderLawyerOfficeOptionList: action.payload };
        case actions.REPLACE_LEGAL_FIRM_OFFICE_OPTION_WITH_RECORD:
            // need to check all lists in case the same global office is in multiple lists
            return {
                ...state,
                lawyerOfficeOptionList: findAndReplace([...state.lawyerOfficeOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption),
                signerOfficeOptionList: findAndReplace([...state.signerOfficeOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption),
                lenderLawyerOfficeOptionList: findAndReplace([...state.lenderLawyerOfficeOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption),
            };
        case actions.SET_LEGAL_FIRM_OFFICE_BEING_EDITED:
            return { ...state, recordBeingEdited: action.payload };
        case actions.ADD_TO_LEGAL_FIRM_OFFICE_OPTIONS:
            const tempLawyer = [...state.lawyerOfficeOptionList];
            const tempSigner = [...state.signerOfficeOptionList];
            const tempLenderLawyer = [...state.lenderLawyerOfficeOptionList];
            if (action.payload.type === LegalProfessionalOptions.LenderLawyer) {
                tempLenderLawyer.push(action.payload.record);
            } else {
                if (action.payload.signerId === action.payload.lawyerId) {
                    tempSigner.push(action.payload.record);
                    tempLawyer.push(action.payload.record);
                } else if (action.payload.type === LegalProfessionalOptions.DealSigner) {
                    tempSigner.push(action.payload.record);
                } else if (action.payload.type === LegalProfessionalOptions.DealLawyer){
                    tempLawyer.push(action.payload.record);
                }
            }
            return { ...state, lawyerOfficeOptionList: tempLawyer, signerOfficeOptionList: tempSigner, lenderLawyerOfficeOptionList: tempLenderLawyer };


        case actions.SET_OFFICES_FOR_FIRM_TO_LINK:
            return { ...state, officesForFirmToLink: action.payload };
        default:
            return state;
    }
}