import { Debt } from "../../../types/UniversalSurvey/Debts/debt";

export const defaultDebt: Debt = {
    id: -1,
    debt_holder_id: undefined,
    charge_holder_type: undefined,
    amount_payable: undefined,
    action: undefined,
    debt_type: undefined,
    valid_statement: undefined,
    account_number: undefined,
    notes: undefined,
    date_created: undefined,
    date_updated: undefined,

    debt_holder_record: undefined,
    trust_ledger: undefined
}