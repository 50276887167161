import { Route, Switch } from "react-router-dom";

// Importing route components
import CDList from "./DealsList/CDList";
import UniversalSurveyWrapper from "./UniversalSurvey/UniversalSurveyWrapper";
import AdminPanelWrapper from "./AdminPanel/AdminPanelWrapper";
import NotFound from "./404/NotFound";


export default function DcpRoutes(props: { role: string | undefined }) {
    return (
        <Switch>
            {/* Routing conveyance section */}
            {/* Deal summary tables route */}
            <Route path="/dashboard/conveyance/conveyance-deals" exact component={CDList}/>
            {/* Universal Survey route - accepts a DCP deal id */}
            <Route path="/dashboard/conveyance/conveyance-deals/:dealId/:tab/:section?/:entityId?" exact component={UniversalSurveyWrapper} />
            {/* Admin panel */}
            {(props.role === "Admin" || props.role === "System Admin") && <Route path="/dashboard/conveyance/admin/:section?" exact component={AdminPanelWrapper} />}
            {/* Incorrect path default */}
            <Route component={NotFound} />
        </Switch>
    )
}