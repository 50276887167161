import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { Form9CContextRequest } from "../documents/form9cDocument";
import { PaymentGroup } from "../../TrustLedger/PaymentGroup/paymentGroup";
import { getRecipientName } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";

function createForm9CName(paymentGroup: PaymentGroup | undefined): string {
    let name = "Form 9C - ";
    let undefinedRecipient = "Undefined Recipient";
    name += getRecipientName(paymentGroup, undefinedRecipient);
    return name;
}

function labelForm9CContextOption(paymentGroups: PaymentGroup[], contextId: number): string {
    const matchingPaymentGroup = paymentGroups.find((group) => group.id === contextId);
    return getRecipientName(matchingPaymentGroup, "Payment Group");
}

function isForm9CDocInDeal(docList: Document[], paymentGroupId: number): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.Form9C && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.payment_group_id === paymentGroupId) return true;
    }
    return false;
}

function createForm9CContextRequest(contextId: number): Form9CContextRequest {
    return {
        payment_group_id: contextId
    };
}

function getForm9CDocData(paymentGroupsInDeal: PaymentGroup[], contextId: number): [Object, string] {
    let matchingPaymentGroup: PaymentGroup | undefined = undefined;
    if (contextId > 0) {
        matchingPaymentGroup = paymentGroupsInDeal.find((group) => group.id === contextId);
    }
    return [createForm9CContextRequest(contextId), createForm9CName(matchingPaymentGroup)];
}

function getForm9CContextOptions(paymentGroupsInDeal: PaymentGroup[], docList: Document[]) {
    const options: number[] = [];
    for (const paymentGroup of paymentGroupsInDeal) {
        if (!isForm9CDocInDeal(docList, paymentGroup.id)) {
            options.push(paymentGroup.id)
        }
    }
    return options;
}

export {
    getForm9CDocData,
    getForm9CContextOptions,
    labelForm9CContextOption
}