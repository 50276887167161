import { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import PhoneField from "../../../../../../components/Common/PhoneField/PhoneField";
import { GlobalLenderContact, LenderContact, LenderContactErrorState } from "../../../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { defaultLenderContact } from "../../../../../libs/resources/defaults/frontend/defaultLenderContact";

type Props = {
    contact: LenderContact | GlobalLenderContact;
    updateContactInfo: <K extends keyof LenderContact, V extends LenderContact[K]>(key: K, value: V) => void;
    errorState: LenderContactErrorState;
    setErrorState: (errorState: LenderContactErrorState) => void;
};

export default function LenderContactForm(props: Props) {
    const [contactObj, setContactObj] = useState<LenderContact | GlobalLenderContact>({ ...defaultLenderContact });

    useEffect(() => {
        setContactObj(props.contact)
    }, [props.contact])

    return (
        <Grid container columnSpacing={5} rowSpacing={3}>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    autoFocus
                    label={{ text: "Name", inputId: "name", isRequired: true }}
                    placeholder="Contact's name"
                    value={contactObj.name ?? undefined}
                    onChange={(e) => props.updateContactInfo("name", e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    label={{text: "Email", inputId: "email"}}
                    placeholder="Contact's email"
                    value={contactObj.email ?? undefined}
                    onChange={(e) => props.updateContactInfo("email", e.target.value)}
                    fieldType="email"
                    error={{
                        showError: true,
                        setErrorState: props.setErrorState,
                        errorKey: "email",
                        errorState: props.errorState
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <PhoneField
                    value={contactObj.phone_work}
                    onChange={(value) => props.updateContactInfo("phone_work", value)}
                    label={{text: "Work Phone", inputId: "phone_work"}}
                    error={{
                        showError: true,
                        setErrorState: props.setErrorState,
                        errorKey: "phone_work",
                        errorState: props.errorState
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <PhoneField
                    value={contactObj.fax}
                    onChange={(value) => props.updateContactInfo("fax", value)}
                    label={{text: "Fax", inputId: "fax"}}
                    error={{
                        showError: true,
                        setErrorState: props.setErrorState,
                        errorKey: "fax",
                        errorState: props.errorState
                    }}
                />
            </Grid>
        </Grid>
    );
}