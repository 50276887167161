import * as actions from "../actionTypes"

import { MortgageTransaction } from "../../../libs/types/UniversalSurvey/MortgageTransaction/mortgageTransaction";
import { findAndRemove } from "../../../libs/utils/arrays";

export type NewMortgagesState = {
    mortgageTransactionInfo: MortgageTransaction | undefined;
    mortgagesBeingSaved: number[];
    isMortgageSaving: boolean;
    shouldRefreshMortgage: boolean;
    netAdvancesBeingSaved: number[];
    isNetAdvanceSaving: boolean;
    shouldRefreshNetAdvance: boolean;
}

export const defaultNewMortgagesState: NewMortgagesState = {
    mortgageTransactionInfo: undefined,
    mortgagesBeingSaved: [],
    isMortgageSaving: false,
    shouldRefreshMortgage: false,
    netAdvancesBeingSaved: [],
    isNetAdvanceSaving: false,
    shouldRefreshNetAdvance: false
}

export function newMortgagesReducer(state: NewMortgagesState, action: Record<string, any>): NewMortgagesState {
    switch (action.type) {
        case actions.SET_MORTGAGE_TRANSACTION_INFO:
            return { ...state, mortgageTransactionInfo: action.payload };
        case actions.SET_MORTGAGE_LENDER_ID:
            if(state.mortgageTransactionInfo?.new_mortgage_record) {
                return {
                    ...state,
                    mortgageTransactionInfo: {
                        ...state.mortgageTransactionInfo,
                        new_mortgage_record: {
                            ...state.mortgageTransactionInfo?.new_mortgage_record,
                            lender_id: action.payload
                        }
                    }
                };
            }
            return state;
        case actions.SET_MORTGAGE_BROKERAGE_ID:
            if(state.mortgageTransactionInfo?.new_mortgage_record) {
                return {
                    ...state,
                    mortgageTransactionInfo: {
                        ...state.mortgageTransactionInfo,
                        new_mortgage_record: {
                            ...state.mortgageTransactionInfo?.new_mortgage_record,
                            mortgage_brokerage_id: action.payload
                        }
                    }
                };
            }
            return state;
        case actions.SET_MORTGAGE_BROKER_ID:
            if(state.mortgageTransactionInfo?.new_mortgage_record) {
                return {
                    ...state,
                    mortgageTransactionInfo: {
                        ...state.mortgageTransactionInfo,
                        new_mortgage_record: {
                            ...state.mortgageTransactionInfo?.new_mortgage_record,
                            mortgage_broker_id: action.payload
                        }
                    }
                };
            }
            return state;
        case actions.SET_MORTGAGE_PRIORITY:
            if(state.mortgageTransactionInfo?.new_mortgage_record) {
                return {
                    ...state,
                    mortgageTransactionInfo: {
                        ...state.mortgageTransactionInfo,
                        new_mortgage_record: {
                            ...state.mortgageTransactionInfo?.new_mortgage_record,
                            priority_after_closing: action.payload
                        }
                    }
                }
            }
            return state;
        case actions.ADD_MORTGAGE_BEING_SAVED:
            if (!state.mortgagesBeingSaved.includes(action.payload)) {
                return { ...state, mortgagesBeingSaved: [...state.mortgagesBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_MORTGAGE_BEING_SAVED:
            let isMortgageSaving = state.isMortgageSaving;
            let shouldRefreshMortgage = state.shouldRefreshMortgage;
            if(state.mortgageTransactionInfo?.new_mortgage_record?.id === action.payload && isMortgageSaving) {
                isMortgageSaving = false;
                shouldRefreshMortgage = true;
            }
            const tempMortgagesBeingSaved = [...state.mortgagesBeingSaved];
            const index = tempMortgagesBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempMortgagesBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                mortgagesBeingSaved: tempMortgagesBeingSaved,
                isMortgageSaving: isMortgageSaving,
                shouldRefreshMortgage: shouldRefreshMortgage
            };
        case actions.SET_IS_MORTGAGE_SAVING:
            return { ...state, isMortgageSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_MORTGAGE:
            return { ...state, shouldRefreshMortgage: action.payload };

        // NET ADVANCES
        case actions.ADD_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED:
            if (!state.netAdvancesBeingSaved.includes(action.payload)) {
                return { ...state, netAdvancesBeingSaved: [...state.netAdvancesBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED:
            let isNetAdvanceSaving = state.isNetAdvanceSaving;
            let shouldRefreshNetAdvance = state.shouldRefreshNetAdvance;
            const tempNetAdvancesBeingSaved = [...state.netAdvancesBeingSaved];
            const netAdvanceIndex = tempNetAdvancesBeingSaved.indexOf(action.payload);
            if (netAdvanceIndex >= 0) {
                tempNetAdvancesBeingSaved.splice(netAdvanceIndex, 1);
            }
            if (netAdvanceIndex >=0 && tempNetAdvancesBeingSaved.length === 0 && isNetAdvanceSaving) {
                isNetAdvanceSaving = false;
                shouldRefreshNetAdvance = true;
            }
            return {
                ...state,
                netAdvancesBeingSaved: tempNetAdvancesBeingSaved,
                isNetAdvanceSaving: isNetAdvanceSaving,
                shouldRefreshNetAdvance: shouldRefreshNetAdvance
            };
        case actions.SET_IS_NEW_MORTGAGE_NET_ADVANCE_SAVING:
            return { ...state, isNetAdvanceSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_NEW_MORTGAGE_NET_ADVANCE:
            return { ...state, shouldRefreshNetAdvance: action.payload };
        case actions.ADD_NEW_MORTGAGE_NET_ADVANCE:
            return {
                ...state,
                mortgageTransactionInfo: state.mortgageTransactionInfo ? {
                    ...state.mortgageTransactionInfo,
                    new_mortgage_record: state.mortgageTransactionInfo.new_mortgage_record ? {
                        ...state.mortgageTransactionInfo.new_mortgage_record,
                        net_advance_amount: [...state.mortgageTransactionInfo.new_mortgage_record.net_advance_amount, action.payload]
                    } : null
                } : undefined
            }
        case actions.REMOVE_NEW_MORTGAGE_NET_ADVANCE:
            if (state.mortgageTransactionInfo?.new_mortgage_record) {
                const tempNetAdvances = findAndRemove([...state.mortgageTransactionInfo?.new_mortgage_record.net_advance_amount], ["id"], [action.payload]);
                return {
                    ...state,
                    mortgageTransactionInfo: {
                        ...state.mortgageTransactionInfo,
                        new_mortgage_record: state.mortgageTransactionInfo.new_mortgage_record ? {
                            ...state.mortgageTransactionInfo.new_mortgage_record,
                            net_advance_amount: tempNetAdvances
                        } : null
                    }
                }
            }
            return { ...state };
        default:
            return state;
    }
}