import { SimpleLegalFirmOffice } from "../../../types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { defaultSimpleAddress } from "./defaultSimpleAddress";
import { defaultSimpleLegalFirm } from "./defaultSimpleLegalFirm";

export const defaultSimpleLegalFirmOffice: SimpleLegalFirmOffice = {
    id: -1,
    general_address: defaultSimpleAddress,
    jurisdiction: undefined,
    municipality: undefined,

    legal_firm_record: defaultSimpleLegalFirm,
    legal_firm_office: undefined
}