import { useContext, useCallback, useEffect } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes";

import { Grid } from "@mui/material";
import styled from "styled-components";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { Lien } from "../../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { Body, BodyBold, LargeBold } from "../../../../../components/Common/Typography";
import BasicDatePicker from "../../../../../components/Common/DatePicker/BasicDatePicker";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import { PADDED_GRID } from "../../../../../components/Common/componentStyling/Styles";
import BasicButton from "../../../../../components/Common/Button/BasicButton";
import color from "../../../../../components/Common/componentStyling/Colors";
import { FormatNumeric } from "../../../../../components/Common/TextField/MoneyFormat";
import { addLienAdditionalAmount, deleteLienAdditionalAmount, saveLienAdditionalAmount } from "../../../../context/UniversalSurvey/asyncActions/existingLiens";
import LineItemInput from "../../../../../components/Common/LineItemInput/LineItemInput";
import { LienPayoutCalcAdditionalAmount } from "../../../../libs/types/UniversalSurvey/ExistingLienPayoutCalcAdditionalAmount/lienPayoutCalcAdditionalAmount";
import { debounce } from "../../../../libs/utils/debounce";

type Props = Omit<ModalProps, "children"> & {
    lienObj: Lien;
    setLienObj: (lien: Lien) => void;
    updateLienInfo: <K extends keyof Lien, V extends Lien[K]>(key: K, value: V) => void;
    calcDayDiff: () => number;
    calcInterestTotal: () => number;
    calcGrandTotal: () => number;
};

export default function LienPayoutCalcModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    const { lienObj, setLienObj, updateLienInfo, calcDayDiff, calcInterestTotal, calcGrandTotal } = props

    useEffect(() => {
        for (const amount of lienObj.payout_calc_additional_amount) {
            if (!amount.name && !amount.amount) {
                deleteLienAdditionalAmount(dispatch, String(state.deal.dealInfo?.id), lienObj.id, amount.id)
            }
        }
    }, [props.open])

    function handleDiscard() {
        props.onClose ? props.onClose() : undefined;
    }

    function isAddAdditionalAmountDisabled() {
        for (const amount of lienObj.payout_calc_additional_amount) {
            if (!amount.name || !amount.amount) return true;
        }
        return false;
    }

    function updateAdditionalAmountInfo<
        K extends keyof LienPayoutCalcAdditionalAmount,
        V extends LienPayoutCalcAdditionalAmount[K]
    >(key: K, value: V, additionalAmountId: number): void {
        const tempAmounts = [...lienObj.payout_calc_additional_amount];
        const matchingAmount = tempAmounts.find((amount) => amount.id === additionalAmountId);
        if (matchingAmount) {
            dispatch({ type: actions.ADD_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED, payload: additionalAmountId });
            const tempAmount = { ...matchingAmount };
            tempAmount[key] = value;
            tempAmounts[tempAmounts.indexOf(matchingAmount)] = tempAmount;
            const tempLienObj = { ...lienObj };
            tempLienObj.payout_calc_additional_amount = tempAmounts;
            setLienObj(tempLienObj);
            debouncedSaveAdditionalAmounts(tempAmounts, [...state.properties.pinsBeingSaved, additionalAmountId])
        }
    }

    const debouncedSaveAdditionalAmounts = useCallback(
        debounce((amounts, amountsBeingSaved) => {
            for (const amount of amounts) {
                if (amountsBeingSaved!.includes(amount.id)) {
                    saveLienAdditionalAmount(dispatch, String(state.deal.dealInfo?.id), lienObj.id, amount);
                    dispatch({ type: actions.REMOVE_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED, payload: amount.id });
                }
            }
        }, 1000), [state.existingLiens.additionalAmountsBeingSaved, lienObj.payout_calc_additional_amount]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscard}
            size="small"
            title="Payout Calculation"
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLien])}
            discardButtonLabel="Close"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <LargeBold>Principal and Interest</LargeBold>
                </Grid>
                <Grid item xs={6}>
                    <BasicDatePicker
                        value={lienObj.payout_calc_start_date ?? null}
                        onChange={(e) => updateLienInfo("payout_calc_start_date", e as Date)}
                        label={{ text: "Effective Date" }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        inputProps={{ "aria-label": "Principal Amount" }}
                        value={lienObj.payout_calc_principal !== undefined ? String(lienObj.payout_calc_principal) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateLienInfo("payout_calc_principal", e.target.value)}
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                        label={{ text: "Principal Amount", inputId: "principal_amount" }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicDatePicker
                        value={lienObj.payout_calc_end_date ?? null}
                        onChange={(e) => updateLienInfo("payout_calc_end_date", e as Date)}
                        label={{ text: "Payout Date" }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        inputProps={{ "aria-label": "Per Diem" }}
                        value={lienObj.payout_calc_amount_per_day !== undefined ? String(lienObj.payout_calc_amount_per_day) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateLienInfo("payout_calc_amount_per_day", e.target.value)}
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                        label={{ text: "Per Diem", inputId: "payout_calc_amount_per_day" }}
                    />
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={6}>
                    <PaddedBody>
                        {`Number of Days: ${calcDayDiff()}`}
                    </PaddedBody>
                    <Body>
                        <span>Interest total: </span><FormatNumeric value={calcInterestTotal()}/>
                    </Body>
                </Grid>

                <Grid item xs={12}>
                    <LargeBold>Discharge Fee</LargeBold>
                </Grid>
                <Grid item xs={6}>
                    <BooleanControl
                        checked={lienObj.separate_cheque_for_discharge_fee}
                        onChange={(e) => updateLienInfo("separate_cheque_for_discharge_fee", !lienObj.separate_cheque_for_discharge_fee)}
                        label={{ text: "Separate Cheque for Discharge Fee", inputId: "separate_cheque_for_discharge_fee" }}
                    />
                </Grid>
                <PADDED_GRID item xs={6}>
                    <BasicTextInput
                        fullWidth
                        inputProps={{ "aria-label": "Discharge Fee Amount" }}
                        value={lienObj.payout_calc_discharge_fee !== undefined ? String(lienObj.payout_calc_discharge_fee) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateLienInfo("payout_calc_discharge_fee", e.target.value)}
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                        label={{ text: "Amount", inputId: "payout_calc_discharge_fee" }}
                    />
                </PADDED_GRID>
            
                <Grid item xs={12}>
                    <LargeBold>Additional Amount</LargeBold>
                </Grid>
                <Grid item xs={12}>
                    {lienObj.payout_calc_additional_amount.map((additionalAmount, i) => (
                        <LineItemInput
                            key={i}
                            textValue={additionalAmount.name}
                            size={7}
                            placeholder="Additional amount name"
                            textOnChange={(e) => updateAdditionalAmountInfo("name", e.target.value, additionalAmount.id)}
                            onRemove={() => deleteLienAdditionalAmount(dispatch, String(state.deal.dealInfo?.id), lienObj.id, additionalAmount.id)}
                            valueItems={[{
                                size: 5,
                                value: additionalAmount.amount !== undefined ? String(additionalAmount.amount) : undefined,
                                valueType: "either", 
                                onChange: (e) => updateAdditionalAmountInfo("amount", e.target.value, additionalAmount.id),
                                id: "amount"
                            }]}
                        />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <BasicButton
                        label={{ text: "Add Additional Amount", inputId: "add additional amount" }}
                        action="add"
                        typeOf="secondary"
                        onClick={() => addLienAdditionalAmount(dispatch, String(state.deal.dealInfo?.id), lienObj.id)}
                        disabled={isAddAdditionalAmountDisabled()}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TotalDivider/>
                </Grid>
                <Grid item xs={6}/>
                <PADDED_GRID item xs={6}>
                    <BodyBold>
                        <span>Grand total: </span><FormatNumeric value={calcGrandTotal()}/>
                    </BodyBold>
                </PADDED_GRID>
            </Grid>
        </ModalBase>
    );
}

const TotalDivider = styled("div")({
    height: "0.2rem",
    width: "100%",
    backgroundColor: color.BLACK,
})

const PaddedBody = styled(Body)({
    paddingBottom: "0.4rem"
})