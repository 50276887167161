import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { GeneralAssignmentOfRentsContextRequest } from "../documents/generalAssignmentOfRentsDocument";


function createLGeneralAssignmentOfRentsName(): string {
    return "General Assignment of Rents";
}

function isGeneralAssignmentOfRentsDocInDeal(docList: Document[]): boolean {
    const matchingDoc = docList.find((doc) => doc.type === DocumentType.GeneralAssignmentOfRents);
    return matchingDoc ? true : false
}

function createGeneralAssignmentOfRentsContextRequest(): GeneralAssignmentOfRentsContextRequest {
    return {};
}

function getGeneralAssignmentOfRentsDocData(): [Object, string] {
    return [createGeneralAssignmentOfRentsContextRequest(), createLGeneralAssignmentOfRentsName()];
}

export {
    isGeneralAssignmentOfRentsDocInDeal,
    getGeneralAssignmentOfRentsDocData
}