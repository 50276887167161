import * as actions from "../actionTypes"

import { LegalProfessional, LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { findAndReplace } from "../../../libs/utils/arrays";

export type LegalProfessionalsState = {
    optionList: LegalProfessionalListItem[];
    recordBeingEdited: LegalProfessional | undefined;
}

export const defaultLegalProfessionalsState: LegalProfessionalsState = {
    optionList: [],
    recordBeingEdited: undefined
}

export function legalProfessionalsReducer(state: LegalProfessionalsState, action: Record<string, any>): LegalProfessionalsState {
    switch (action.type) {
        case actions.SET_LEGAL_PROFESSIONAL_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.REPLACE_LEGAL_PROFESSIONAL_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_LEGAL_PROFESSIONAL_BEING_EDITED:
            return { ...state, recordBeingEdited: action.payload };
        case actions.ADD_TO_LEGAL_PROFESSIONAL_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST:
            const tempOptionsForFirm = [...state.optionList];
            const matchingForFirm = tempOptionsForFirm.find((option) => option.id === action.payload.id && option.isRecord)
            if (matchingForFirm) {
                tempOptionsForFirm[tempOptionsForFirm.indexOf(matchingForFirm)].legal_firm_record = action.payload.firmRecord;
            }
            return { ...state, optionList: tempOptionsForFirm };
        case actions.UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST:
            const tempOptionsForOffice = [...state.optionList];
            const matchingForOffice = tempOptionsForOffice.find((option) => option.id === action.payload.id && option.isRecord)
            if (matchingForOffice) {
                tempOptionsForOffice[tempOptionsForOffice.indexOf(matchingForOffice)].legal_firm_office_record = action.payload.officeRecord;
            }
            return { ...state, optionList: tempOptionsForOffice };
        default:
            return state;
    }
}