import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import { StatDec } from "../../../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { SimpleClient } from "../../../../../libs/types/UniversalSurvey/Client/simpleClient";
import { getClientTabName, isClientOnlyGuarantor } from "../../../../../context/UniversalSurvey/asyncActions/clients";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import { defaultSimpleClient } from "../../../../../libs/resources/defaults/frontend/defaultSimpleClient";
import { ClientType } from "../../../../../libs/resources/enums/clientType";

type Props = {
    onChange: Function;
    obj: StatDec;
    selectedClients: (SimpleClient & { label: string })[];
    setSelectedClients: Function;
};

export default function StatutoryDeclarationModal(props: Props) {
    const [state] = useContext(UniversalSurveyContext);
    const { onChange, obj, selectedClients, setSelectedClients } = props;
    const [statDecType, setStatDecType] = useState<"joint" | "guarantor" | "our_lawyer" | undefined>(undefined);
    const defaultOurLawyerItem = { ...defaultSimpleClient, label: "Our Lawyer", id: -1, first_name: "", last_name: "", roles: [] };

    useEffect(() => {
        if(state.conditions.conditionStatDec) {
            const statDec = state.conditions.conditionStatDec;
            const options = getClientComboboxItems();
            let selectedClientsInDeclaration = [];
            let type: "our_lawyer" | "joint" | "guarantor" | undefined = undefined;
            if (statDec.our_lawyer) {
                selectedClientsInDeclaration.push(defaultOurLawyerItem);
                type = "our_lawyer"
            }
            else if (statDec.client_list) {
                for(const client of statDec.client_list) {
                    for(const option of options) {
                        if(client.id === option.id) {
                            selectedClientsInDeclaration.push(option);
                            if (isClientOnlyGuarantor(option)) type = "guarantor";
                            else type = "joint";
                        }
                    }
                }
            }
            setStatDecType(type);
            setSelectedClients(selectedClientsInDeclaration);
        }
    }, [state.conditions.conditionStatDec]);

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            if (client.type === ClientType.Corporation) continue;
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        options.push(defaultOurLawyerItem);
        return options;
    }

    function getDeclarationComboboxItems(): string[] {
        const options: string[] = [];
        for(const declaration of state.statutoryDeclarations.globalStatDecs) {
            options.push(declaration.declaration ? declaration.declaration : "");
        }
        return options;
    }

    function isAllButClientsOptionsDisabled(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        const errorObj = { isDisabled: true, message: "Only clients can make joint declarations" };
        if (isClientOnlyGuarantor(option)) return errorObj;
        if (option.id === -1) {
            return errorObj;
        }
        return { isDisabled: false, message: "" };
    }

    function allOptionsDisabledGuarantor(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        if (selectedClients.find((client: any) => client.id === option.id)) {
            return { isDisabled: false, message: "" };
        }
        return { isDisabled: true, message: "Only one guarantor can make a declaration at once" };
    }

    function allOptionsDisabledOurLawyer(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
            if (selectedClients.find((client: any) => client.id === option.id)) {
            return { isDisabled: false, message: "" };
        }
        return { isDisabled: true, message: "Our lawyer can only make declarations individual" };
    }

    function updateClientList(clientList: (SimpleClient & { label: string })[]) {
        setSelectedClients(clientList);
        if (clientList.length === 0) {
            setStatDecType(undefined);
            onChange("our_lawyer", false);
            return;
        };
        if (clientList.length === 1) {
            if (isClientOnlyGuarantor(clientList[0])) {
                setStatDecType("guarantor");
                return;
            }
            if (clientList[0].id === -1) { 
                setStatDecType("our_lawyer"); 
                onChange("our_lawyer", true); 
                return; 
            }
        }
        setStatDecType("joint");
    }

    function assignDisablingFunction() {
        switch(statDecType) {
            case "joint":
                return isAllButClientsOptionsDisabled;
            case "guarantor":
                return allOptionsDisabledGuarantor;
            case "our_lawyer":
                return allOptionsDisabledOurLawyer;
            default:
                return undefined;
        }
    }

    return (
        <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item xs={12} >
                <ComboBox
                    label={{ text: "Declarant(s)", inputId: "statutory-declaration-form-declarants", isRequired: true }}
                    options={getClientComboboxItems()}
                    tokenField
                    placeholder="Select declarant..."
                    value={selectedClients}
                    isOptionDisabled={assignDisablingFunction()}
                    // @ts-ignore
                    onChangeFn={(value) => updateClientList(value)}
                />
            </Grid>
            <Grid item xs={12} >
                <ComboBox
                    label={{ text: "Declaration", inputId: "statutory-declaration-form-declaration", isRequired: true }}
                    options={getDeclarationComboboxItems()}
                    placeholder="Declaration"
                    value={obj.declaration ?? { label: "" }}
                    text={String(obj.declaration)}
                    setText={(value) => onChange("declaration", value)}
                    // @ts-ignore
                    onChangeFn={(value) => onChange("declaration", value as string)}
                />
            </Grid>
        </Grid>
    );
}