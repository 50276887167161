import { MortgageBrokerageRequest } from "../../../types/UniversalSurvey/MortgageBrokerage/mortgageBrokerageRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultMortgageBrokerageRequest: MortgageBrokerageRequest = {
    name: null,
    abbr_name: null,
    all_regions: null,
    general_address: defaultAddressRequest,
    phone: null,
    email: null,
    fax: null,
    financial_info: defaultFinancialInfoRequest
}