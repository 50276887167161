import { SimpleGlobalLegalFirmOffice } from "../../../types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { defaultSimpleAddress } from "./defaultSimpleAddress";
import { defaultSimpleGlobalLegalFirm } from "./defaultSimpleGlobalLegalFirm";

export const defaultSimpleGlobalLegalFirmOffice: SimpleGlobalLegalFirmOffice = {
    id: -1,
    general_address: defaultSimpleAddress,
    jurisdiction: undefined,
    municipality: undefined,

    legal_firm: defaultSimpleGlobalLegalFirm
}