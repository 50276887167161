import { useCallback, useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import { Lien } from "../../../../../libs/types/UniversalSurvey/ExistingLien/lien";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { UniversalSurveyContext } from "../../../../../context/UniversalSurvey/context";
import { DebtHolderTypeOptions, InstrumentTypeOptionsAB, InstrumentTypeOptionsBC, InstrumentTypeOptionsON } from "../../../../../libs/resources/options";
import { ChargeHolderListItem } from "../../../../../libs/types/UniversalSurvey/ExistingLien/chargeHolders";
import { ChargeHolderType } from "../../../../../libs/resources/enums/chargeHolderType";
import { createLenderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/lenders";
import { createChargeHolderCompanyOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import { createGovernmentOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/governments";
import { createOtherChargeHolderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"
import { createChargeHolderRecordFromGlobal } from "../../../../../context/UniversalSurvey/asyncActions/existingLiens";
import { formatDebtHolderType, formatLienInstrumentType } from "../../../../../libs/utils/formatValues";
import { DebtHolderTypes } from "../../../../../libs/resources/enums/debtHolderTypes";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../../../libs/utils/limits";
import { debounce } from "../../../../../libs/utils/debounce";

type Props = {
    onChange: Function;
    obj: Lien;
    setObj: Function;
    newDebtHolderType: DebtHolderTypes | undefined;
    setNewDebtHolderType: (type: DebtHolderTypes | undefined) => void;
    newDebtHolderName: string;
    setNewDebtHolderName: (name: string) => void;
};

export default function LienForm(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const { obj, onChange, setObj, newDebtHolderType, setNewDebtHolderType, newDebtHolderName, setNewDebtHolderName } = props;

    const [lienOptions, setLienOptions] = useState<string[]>([]);
    const [chargeHolder, setChargeHolder] = useState<ChargeHolderListItem | undefined>(undefined);
    const [showChargeHolderType, setShowChargeHolderType] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (state.conditions.conditionLien) {
            const lien = { ...state.conditions.conditionLien };
            let chargeHolderListItem: ChargeHolderListItem;
            if (lien.lender_record) {
                chargeHolderListItem = { 
                    id: lien.lender_record.id,
                    label: lien.lender_record.name ?? "",
                    isRecord: true, 
                    charge_holder_type: ChargeHolderType.Lender,
                    name: lien.lender_record.name ?? ""
                };
                setChargeHolder(chargeHolderListItem);
            } else if (lien.charge_holder_company_record) {
                chargeHolderListItem = { 
                    id: lien.charge_holder_company_record.id,
                    label: lien.charge_holder_company_record.name ?? "",
                    isRecord: true, 
                    charge_holder_type: ChargeHolderType.Company,
                    name: lien.charge_holder_company_record.name ?? ""
                };
                setChargeHolder(chargeHolderListItem);
            } else if (lien.government_record) {
                chargeHolderListItem = { 
                    id: lien.government_record.id,
                    label: lien.government_record.name ?? "",
                    isRecord: true, 
                    charge_holder_type: ChargeHolderType.Government,
                    name: lien.government_record.name ?? ""
                };
                setChargeHolder(chargeHolderListItem);
            } else if (lien.other_charge_holder_record) {
                chargeHolderListItem = { 
                    id: lien.other_charge_holder_record.id,
                    label: lien.other_charge_holder_record.name ?? "",
                    isRecord: true, 
                    charge_holder_type: ChargeHolderType.Other,
                    name: lien.other_charge_holder_record.name ?? ""
                };
            }
        }
    }, [state.conditions.conditionLien]);

    function makeQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        if (searchVal) query.set("search", searchVal);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        return query;
    }
    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue]);

    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined,
                makeQuery(searchValue)
            );
        }, 500), []);

    useEffect(() => {
        switch (state.deal.dealInfo?.region_id) {
            case 1:
                setLienOptions(InstrumentTypeOptionsON);
                break;
            case 2:
                setLienOptions(InstrumentTypeOptionsBC);
                break;
            case 3:
                setLienOptions(InstrumentTypeOptionsAB);
                break;
        }
    }, [state.deal.dealInfo?.region_id]);

    useEffect(() => {
        createLenderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.lenders.selectedLender ? state.lenders.selectedLender.id : undefined
        );
        createChargeHolderCompanyOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.chargeHolderCompanies.selectedCompany ? state.chargeHolderCompanies.selectedCompany.id : undefined
        );
        createGovernmentOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.governments.selectedGovernment ? state.governments.selectedGovernment.id : undefined
        );
        createOtherChargeHolderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.otherChargeHolders.selectedChargeHolder ? state.otherChargeHolders.selectedChargeHolder.id : undefined
        );
    }, []);

    useEffect(() => {
        if (state.existingLiens.chargeHolder) {
            const holder = { ...state.existingLiens.chargeHolder };
            switch(holder.charge_holder_type) {
                case ChargeHolderType.Company:
                    onChange("charge_holder_company_id", holder.id);
                    break;
                case ChargeHolderType.Government:
                    onChange("government_id", holder.id);
                    break;
                case ChargeHolderType.Lender:
                    onChange("lender_id", holder.id);
                    break;
                case ChargeHolderType.Other:
                    onChange("other_charge_holder_id", holder.id);
                    break;
            }
        }
    }, [state.existingLiens.chargeHolder]);

    function convertToChargeHolderListItem(value: any, chargeHolderType: ChargeHolderType): ChargeHolderListItem {
        const chargeHolder: ChargeHolderListItem = {
            id: value.id,
            label: value.label,
            isRecord: value.isRecord,
            name: value.name,
            charge_holder_type: chargeHolderType
        }
        return chargeHolder;
    }

    function getPayableToComboboxItems(): ChargeHolderListItem[] {
        const options: ChargeHolderListItem[] = [];
        let option: ChargeHolderListItem;
        for (const lender of state.lenders.lenderOptionList) {
            option = convertToChargeHolderListItem(lender, ChargeHolderType.Lender);
            options.push(option);
        }
        for (const company of state.chargeHolderCompanies.optionList) {
            option = convertToChargeHolderListItem(company, ChargeHolderType.Company);
            options.push(option);
        }
        for (const government of state.governments.optionList) {
            option = convertToChargeHolderListItem(government, ChargeHolderType.Government);
            options.push(option);
        }
        for (const other of state.otherChargeHolders.optionList) {
            option = convertToChargeHolderListItem(other, ChargeHolderType.Other);
            options.push(option);
        }
        return options;
    }

    function updateChargeHolderWithOption(value: ChargeHolderListItem | undefined) {
        setShowChargeHolderType(false);
        setNewDebtHolderType(undefined);
        let tempObj = { ...obj };
        if (value) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_CHARGE_HOLDER, payload: { id: value.id, charge_holder_type: value.charge_holder_type, name: value.name } })
            } else {
                createChargeHolderRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
            }
        } else {
            tempObj.charge_holder_company_id = undefined;
            tempObj.government_id = undefined;
            tempObj.other_charge_holder_id = undefined;
            tempObj.lender_id = undefined;
        }
        setChargeHolder(value);
        setObj(tempObj);
    }

    function handlePayableToBlur() {
        if (newDebtHolderName && !chargeHolder) {
            setShowChargeHolderType(true);
        }
    }

    return (
        <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item xs={12}>
                <ComboBox
                    label={{ text: "Payable To", inputId: "lien_payable_to", isRequired: true }}
                    options={getPayableToComboboxItems()}
                    placeholder="Charge holder's name"
                    value={chargeHolder}
                    isHoverActionHidden={chargeHolder ? false : true}
                    handleClear={() => updateChargeHolderWithOption(undefined)}
                    onChangeFn={(value) => updateChargeHolderWithOption(value as any)}
                    searchableKeys={["label"]}
                    isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderList])}
                    onBlur={() => {
                        handlePayableToBlur;
                        setSearchValue(undefined);
                    }}
                    setText={(value) => {
                        setNewDebtHolderName;
                        setSearchValue(value);
                    }}
                />
            </Grid>
            {showChargeHolderType &&
                <Grid item xs={6}>
                    <Dropdown
                        value={newDebtHolderType ?? ""}
                        options={DebtHolderTypeOptions.filter((option) => option !== DebtHolderTypes.MortgageBrokerage)}
                        placeholder="Select debt holder type..."
                        label={{ text: "Debt Holder Type", isRequired: true }}
                        formatValue={formatDebtHolderType}
                        isHoverActionHidden={newDebtHolderType ? false : true}
                        handleClear={() => setNewDebtHolderType(undefined)}
                        onChange={(e) => setNewDebtHolderType(e.target.value as DebtHolderTypes)}
                    />
                </Grid>
            }
            <Grid item xs={6}>
                <Dropdown
                    value={obj.instrument_type ?? undefined}
                    onChange={(e) => onChange("instrument_type", e.target.value as string)}
                    placeholder="Select instrument type..."
                    options={lienOptions}
                    formatValue={formatLienInstrumentType}
                    label={{ text: "Instrument Type", isRequired: true }}
                    isHoverActionHidden={obj.instrument_type ? false : true}
                    handleClear={() => onChange("instrument_type", undefined)}
                />
            </Grid>
            {!showChargeHolderType && <Grid item xs={6}/>}
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    moneyField
                    valueType="positive"
                    label={{text: "Amount", inputId: "amount", isRequired: true}}
                    value={obj.payout_calc_principal !== undefined ? String(obj.payout_calc_principal) : undefined}
                    onChange={(e) => onChange("payable_to_discharge", Number(e.target.value))}
                    placeholder="0.00"
                    inputProps={{ "aria-label": "Amount" }}
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    label={{text: "Account No.", inputId: "acount_number", isRequired: true}}
                    value={obj.account_number ?? undefined}
                    onChange={(e) => onChange("account_number", e.target.value)}
                    placeholder="Account"
                    inputProps={{ "aria-label": "Account No." }}
                />
            </Grid>
        </Grid>
    );
}