import { SimpleSigningAppointment } from "../../../types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultSimpleSigningAppointment: SimpleSigningAppointment = {
    id: -1,
    signing_date: undefined,
    address: defaultSimpleAddress,
    jurisdiction: undefined,
    municipality: undefined,
    remote: true,
    signed_at_subject: true,

    clients: undefined
}