import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    deleteRegionFromMortgageBrokerageRecord,
    getMortgageBrokerageRecord,
    getMortgageBrokerageRecords,
    getMortgageBrokerages,
    newMortgageBrokerageRecord,
    newRegionOnMortgageBrokerageRecord,
    saveMortgageBrokerageRecord
} from "../../../libs/service/axios/api";
import { MortgageBrokerage, MortgageBrokerageListItem } from "../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { sanitizeMortgageBrokerageResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeMortgageBrokerageRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { Loading } from "../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../libs/types/DealList/deals";

function createMortgageBrokerageOptionList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedBrokerageId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageList, isLoading: true } });
    getMortgageBrokerageRecords(dealId)
        .then(function (response: any) {
            const brokeragesOptionList: MortgageBrokerageListItem[] = [];
            const brokerageIsRecordList: number[] = [];
            for (const brokerageRecord of response.data) {
                if (brokerageRecord.name) {
                    brokeragesOptionList.push({
                        label: brokerageRecord.name,
                        name: brokerageRecord.name,
                        id: brokerageRecord.id,
                        isRecord: true
                    })
                }
                if (brokerageRecord.mortgage_brokerage?.id) {
                    brokerageIsRecordList.push(brokerageRecord.mortgage_brokerage.id);
                }
            }
            getMortgageBrokerages()
                .then(function (globalResponse: any) {
                    for (const brokerage of globalResponse.data.mortgage_brokerages) {
                        if (!brokerageIsRecordList.includes(brokerage.id)) {
                            brokeragesOptionList.push({
                                label: brokerage.name,
                                name: brokerage.name,
                                id: brokerage.id,
                                isRecord: false
                            });
                        }
                    }
                    dispatch({ type: actions.SET_MORTGAGE_BROKERAGES_OPTION_LIST, payload: brokeragesOptionList });
                    dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: selectedBrokerageId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokerages: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokerage records: ${error}`, type: AlertTypes.Error } });
        })
}

function createRecordFromMortgageBrokerage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageBrokerage: MortgageBrokerageListItem) {
    newMortgageBrokerageRecord(dealId, { mortgage_brokerage_id: mortgageBrokerage.id! })
        .then(function (response: any) {
            getMortgageBrokerageRecordInfo(dispatch, dealId, response.data.id);
            const newBrokerageListItem: MortgageBrokerageListItem = { id: response.data.id, isRecord: true, label: mortgageBrokerage.label };
            dispatch({ type: actions.REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD, payload: { oldBrokerageId: mortgageBrokerage.id, newOption: newBrokerageListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: newBrokerageListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

function submitNewMortgageBrokerageRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: MortgageBrokerage, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerage, isLoading: true } });
    newMortgageBrokerageRecord(dealId)
        .then(function (response: any) {
            saveMortgageBrokerageRecord(dealId, String(response.data.id), sanitizeMortgageBrokerageRequest(brokerageInfo))
                .then(async function () {
                    const newBrokerageListItem: MortgageBrokerageListItem = { id: response.data.id, isRecord: true, label: brokerageInfo.name ?? undefined };
                    await addMortgageBrokerageRecordRegions(dispatch, dealId, response.data.id, regionList);
                    dispatch({ type: actions.ADD_TO_MORTGAGE_BROKERAGE_OPTIONS, payload: newBrokerageListItem });
                    dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: newBrokerageListItem.id });
                    getMortgageBrokerageRecordInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerage, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerage, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

function saveMortgageBrokerage(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: MortgageBrokerage, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerage, isLoading: true } });
    saveMortgageBrokerageRecord(dealId, String(brokerageInfo.id), sanitizeMortgageBrokerageRequest(brokerageInfo))
        .then(async function () {
            await updateMortgageBrokerageRecordRegions(dispatch, dealId, brokerageInfo, regionList);
            getMortgageBrokerageRecordInfo(dispatch, dealId, brokerageInfo.id);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerage, isLoading: false } });
        })
}

async function addMortgageBrokerageRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageBrokerageRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnMortgageBrokerageRecord(dealId, String(mortgageBrokerageRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateMortgageBrokerageRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: MortgageBrokerage, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (brokerageInfo.regions) {
        for (const region of brokerageInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnMortgageBrokerageRecord(dealId, String(brokerageInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromMortgageBrokerageRecord(dealId, String(brokerageInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the mortgage brokerage record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on mortgage brokerage record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

function getMortgageBrokerageRecordInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number) {
    getMortgageBrokerageRecord(dealId, String(brokerageRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_INFO, payload: sanitizeMortgageBrokerageResponse(response.data) });
            dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_ID, payload: brokerageRecordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    createMortgageBrokerageOptionList,
    createRecordFromMortgageBrokerage,
    submitNewMortgageBrokerageRecord,
    saveMortgageBrokerage,
    getMortgageBrokerageRecordInfo
}