import { useContext } from 'react';

// Importing MUI components
import { styled, Stack } from '@mui/material';

// Importing application components
import { SavedCloud, SavingCloud } from '../../../../../components/Common/Icons/Iconography';
import { Small } from '../../../../../components/Common/Typography/index';
import theme from '../../../../../components/Common/componentStyling/Theme';
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

export default function SaveState() {
    const [state] = useContext(UniversalSurveyContext);

    return (
        <StateContainer direction={"row"} gap={1} alignItems="center">
            {state.deal.isSaving ? <SmallInput>Saving</SmallInput> : <SmallInput>Saved</SmallInput>}
            {state.deal.isSaving ? <SavingCloud color={theme.INPUT} /> : <SavedCloud color={theme.INPUT} />}
        </StateContainer>
    )
}

const StateContainer = styled(Stack)({});
const SmallInput = styled(Small)({
    color: theme.INPUT
});