import { useState, useEffect } from "react";
// import {Deal} from "types/deal";
import constants from "styles/constants";
import {
  Box,
  Pagination,
  styled,
  Table,
  TableBody,
  TableContainer,
  useTheme,
} from "@mui/material";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import DealsListSortingMenuTableHead from "./DealsListTable/DealsListSortingMenuTableHead";
import CDListItemTableBody from "./DealsListTable/CDListItemTableBody";
import DeededResultsNotFound from "v2/components/DeededResultsNotFound";

// Conveyance Specific imports
import { useDeals } from "../../../libs/hooks/useDeals";
import { usePagination } from "../../../libs/hooks/usePagination";
import { SimpleDeal } from "../../../libs/types/DealList/deals";
import { defaultDeals } from "conveyance/libs/resources/defaults/frontend/defaultDeals";

interface DealsListBaseProps {
  searchVal: string | undefined;
}

const PAGINATION_LIMIT = 10;

const CDListBase = ({
  searchVal
}: DealsListBaseProps) => {
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<boolean>(false);
  
  // Pagination state to pass to hook
  const [currentPage, setCurrentPage] = useState(1);

  // Using custom hooks to fetch deals and paginate them
  const { dealsData, isLoading } = useDeals(PAGINATION_LIMIT, currentPage - 1, searchVal, sortBy, sortOrder);
  const { totalPages } = usePagination(dealsData, PAGINATION_LIMIT);

  const theme = useTheme();

  const handleSort = (type: string) => {
    setSortBy(type);
    setSortOrder(!sortOrder);
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <DeededCircularLoader />
        </Box>
      ) : dealsData.deals.length !== 0 ? (
        <>
          <Box
            sx={{
              "& > div": {
                padding: "24px 0",
                "&:not(:last-child)": {
                  marginBottom: "8px !important",
                },
              },
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            }}
          >
          </Box>
          <TableContainer
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "calc(100% - 175px)",
              marginTop: "16px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              [theme.breakpoints.down(1283)]: {
                marginTop: "7px",
                height: "calc(100% - 205px)",
              },
            }}
            component={Box}
          >
            <Table
              aria-label="simple table"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              {!isLoading && (
                <DealsListSortingMenuTableHead
                  handleSort={handleSort}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              )}
              <TableBody>
                {dealsData.deals?.map((deal: SimpleDeal, i: number) => (
                  <CDListItemTableBody
                    deal={deal}
                    key={i}
                  />
                ))}
              </TableBody>
            </Table>
            {totalPages > 1 && !isLoading && (
              <DealsPagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={(_, val) => {
                  if (!isLoading) {
                    setCurrentPage(val);
                  }
                }}
              />
            )}
          </TableContainer>
        </>
      ) : (
        <DeededResultsNotFound
          searchVal={searchVal}
          textNotFound="There are no deals yet"
        />
      )}
    </>
  );
};

const DealsPagination = styled(Pagination)(({theme}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "64px",
  marginTop: "16px",
  paddingBottom: "16px",
  li: {
    margin: "0 3px",
  },
  "& .MuiPaginationItem-root": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    border: "none",
    padding: "0",
    margin: "0",
  },
  "& .MuiPagination-ul li:first-of-type, .MuiPagination-ul li:last-child": {
    background: "white",
    borderRadius: "5px",
    svg: {
      fontSize: "18px",
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${constants.colors.red} !important`,
    color: "white",
    border: "none",
  },
  "& .Mui-disabled": {
    background: "#F2F6FB",
  },
  [theme.breakpoints.down("sm")]: {
    li: {
      margin: "0 1px",
    },
  },
}));

export default CDListBase;
