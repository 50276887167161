import { Dispatch } from "react";

import { AxiosResponse } from "axios";
import * as actions from "../../actionTypes"
import {
    getMortgageTransactions,
    getIlaTransactions,
    getTitleTransferTransactions,
    deleteMortgageTransaction,
    deleteIlaTransaction,
    deleteTitleTransferTransaction,
    newMortgageTransaction,
    newIlaTransaction,
    newTitleTransferTransaction,
    updateDeal
} from "../../../../libs/service/axios/api";
import { Transaction } from '../../../../libs/types/UniversalSurvey/Transaction/transaction';
import { TransactionTypes } from '../../../../libs/resources/enums/transactions';
import { sortAndLabelMortgageTransactions, handleTabChangeOnMortgageTransactionRemoval } from "./mortgageTransactions";
import { sortAndLabelIlaTransactions } from "./ilaTransactions";
import { sortAndLabelTitleTransferTransactions } from "./titleTransferTransactions";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { Sections } from "../../../../libs/resources/enums/sections";
import { TabOptions } from "../../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { handleAddingMortgageOnMortgageSection } from "../newMortgages";
import { sanitizeDealRequest } from "../../../../libs/types/UniversalSurvey/utils/convertRequest";
import { Deal } from "../../../../libs/types/UniversalSurvey/Deal/deal";

function getAllTransactions(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    const promiseList = [];
    const mortgageTransactions: Transaction[] = [];
    const ilaTransactions: Transaction[] = [];
    const titleTransferTransactions: Transaction[] = [];
    const deal: Transaction[] = [];
    deal.push({ id: Number(dealId), transaction_type: TransactionTypes.Deal, label: "Deal" });

    promiseList.push(
        getMortgageTransactions(dealId)
            .then(function (response: any) {
                for (const mortgage of response.data) {
                    mortgageTransactions.push({
                        id: mortgage.id,
                        transaction_type: TransactionTypes.Mortgage,
                        priority: mortgage.new_mortgage_record.priority_after_closing,
                        lenderAbbrName: mortgage.new_mortgage_record.lender_record?.abbr_name,
                        lenderName: mortgage.new_mortgage_record.lender_record?.name,
                        guarantor_list: mortgage.new_mortgage_record.guarantor_list ?? undefined
                    });
                }
                sortAndLabelMortgageTransactions(mortgageTransactions);
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage transactions: ${error}`, type: AlertTypes.Error } });
            })
    )

    promiseList.push(
        getIlaTransactions(dealId)
            .then(function (response: any) {
                for (const ila of response.data) {
                    ilaTransactions.push({ id: ila.id, transaction_type: TransactionTypes.ILA });
                }
                sortAndLabelIlaTransactions(ilaTransactions);
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get ILA transactions: ${error}`, type: AlertTypes.Error } });
            })
    )

    promiseList.push(
        getTitleTransferTransactions(dealId)
            .then(function (response: any) {
                for (const titleTransfer of response.data) {
                    titleTransferTransactions.push({ id: titleTransfer.id, transaction_type: TransactionTypes.TitleTransfer });
                }
                sortAndLabelTitleTransferTransactions(titleTransferTransactions);
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get title transfer transactions: ${error}`, type: AlertTypes.Error } });
            })
    )

    Promise.all(promiseList)
        .then(function () {
            dispatch({
                type: actions.SET_TRANSACTION_LIST,
                payload: [...mortgageTransactions, ...ilaTransactions, ...titleTransferTransactions, ...deal]
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Setting transaction list: ${error}`, type: AlertTypes.Error } });
        })
}

function changeTransaction(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    oldTransaction: Transaction,
    newTransactionType: TransactionTypes,
    currSection: Sections | undefined,
    currEntity: number,
    tabOptions: TabOptions,
    keyInfo: Deal,
    updatePrimary: boolean
) {
    let removeRequest: (dealId: string, transactionId: string) => Promise<AxiosResponse<any>>;
    let addRequest: (dealId: string) => Promise<AxiosResponse<any>>;
    let addActionType: string;
    switch(oldTransaction.transaction_type) {
        case TransactionTypes.Mortgage:
            removeRequest = deleteMortgageTransaction;
            break;
        case TransactionTypes.ILA:
            removeRequest = deleteIlaTransaction;
            break;
        case TransactionTypes.TitleTransfer:
            removeRequest = deleteTitleTransferTransaction;
            break;
        default:
            return;
    }

    switch(newTransactionType) {
        case TransactionTypes.Mortgage:
            addRequest = newMortgageTransaction;
            addActionType = actions.CREATE_MORTGAGE_TRANSACTION;
            break;
        case TransactionTypes.ILA:
            addRequest = newIlaTransaction;
            addActionType = actions.CREATE_ILA_TRANSACTION;
            break;
        case TransactionTypes.TitleTransfer:
            addRequest = newTitleTransferTransaction;
            addActionType = actions.CREATE_TITLE_TRANSFER_TRANSACTION;
            break;
        default:
            return;
    }

    addRequest(dealId)
    .then(function (addResponse: any) {
        if(newTransactionType === TransactionTypes.Mortgage && currSection === Sections.NewMortgage) {
            handleAddingMortgageOnMortgageSection(dispatch, dealId, addResponse.data.id);
        } else {
            dispatch({ type: addActionType, payload: addResponse.data.id });
        }

        if(updatePrimary) {
            const dealInfo = sanitizeDealRequest({
                ...keyInfo,
                primary_transaction_id: addResponse.data.id,
                primary_transaction_type: newTransactionType,
            })
            updateDeal(dealId, dealInfo)
            .then(function () {
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_id", value: addResponse.data.id } });
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_type", value: newTransactionType } });
                removeTransaction(dispatch, removeRequest, dealId, oldTransaction, currSection, currEntity, tabOptions);
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating primary transaction: ${error}`, type: AlertTypes.Error } });
            })
        } else {
            removeTransaction(dispatch, removeRequest, dealId, oldTransaction, currSection, currEntity, tabOptions);
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating transaction: ${error}`, type: AlertTypes.Error } });
    })
}

function removeTransaction(dispatch: Dispatch<Record<string, any>>, removeRequest: (dealId: string, transactionId: string) => Promise<AxiosResponse<any>>, dealId: string, oldTransaction: Transaction, currSection: Sections | undefined, currEntity: number, tabOptions: TabOptions) {
    removeRequest(dealId, String(oldTransaction.id))
    .then(function () {
        if (oldTransaction.transaction_type === TransactionTypes.Mortgage) {
            handleTabChangeOnMortgageTransactionRemoval(dispatch, dealId, oldTransaction.id, currSection, currEntity, tabOptions);
        }
        dispatch({ type: actions.REMOVE_TRANSACTION, payload: { id: oldTransaction.id, transactionType: oldTransaction.transaction_type } });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting transaction: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    getAllTransactions,
    changeTransaction
}