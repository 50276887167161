// Importing React tools
import { useEffect, useState, useContext, useCallback, Fragment } from "react";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes"

// Importing mui design kit components
import { Grid, Stack, styled } from "@mui/material";

// Importing application components
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl"
import { Body, H4 } from "../../../../components/Common/Typography/index";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import { CalculationPeriodOptions, InsuranceAmountOptions, PaymentFrequencyOptions, ProductTypeOptions, lenderContactRoleOptions } from "../../../libs/resources/options";
import BasicDatePicker from "../../../../components/Common/DatePicker/BasicDatePicker";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import { formatFrequency, formatInsuranceAmount, formatMortgageType, formatYears } from "../../../libs/utils/formatValues";
import LenderModal from "../Components/Modals/LenderModal/LenderModal";
import LenderContactModal from "../Components/Modals/LenderContactModal/LenderContactModal";
import GuarantorModal from "../Components/Modals/GuarantorModal";
import MortgageBrokerageModal from "../Components/Modals/MortgageBrokerageModal";
import MortgageBrokerModal from "../Components/Modals/MortgageBrokerModal";
import { NewMortgage } from "../../../libs/types/UniversalSurvey/NewMortgage/newMortgage";
import { LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { MortgageBrokerageListItem } from "../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { LenderContactListItem } from "../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import MortgageBrokerageOtherContactModal from "../Components/Modals/MortgageBrokerageOtherContactModal";
import {
    getMortgageBrokerageOtherContactFull,
    getMortgageBrokerageOtherContactsInDeal,
    addMortgageBrokerageOtherContactToMortgage,
    changeMortgageBrokerageOtherContactInMortgage,
    removeMortgageBrokerageOtherContactFromMortgage,
    handleCreateMortgageBrokerageOtherContact
} from "../../../context/UniversalSurvey/asyncActions/mortgageBrokerageOtherContacts";
import { MortgageBrokerListItem } from "../../../libs/types/UniversalSurvey/MortgageBroker/mortgageBroker";
import { MortgageBrokerageOtherContact } from "../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import {
    saveNewMortgageDeal,
    updateMortgageTabName,
    updateMortgageLenderIdAndTabName,
    updateMortgageTabNamesOnLenderSave,
    changeSelectedNewMortgage,
    updateMortgageTabSubtitle,
    saveNewMortgageNetAdvanceAmount,
    deleteNewMortgageNetAdvanceAmount,
    addNewMortgageNetAdvanceAmount
} from "../../../context/UniversalSurvey/asyncActions/newMortgages";
import {
    createLenderRecordFromLender,
    createLenderOptionsList,
    getLenderInfo,
    updateLenderLawyer,
    updateLenderLawyerFirm,
    updateLenderLawyerOffice
} from "../../../context/UniversalSurvey/asyncActions/lenders";
import {
    createLenderContactOptionsList,
    setLenderContactRecordBeingEdited,
    addLenderContactToMortgage,
    removeLenderContactFromMortgage,
    changeLenderContactInMortgage,
    changeOrAddLenderContactToMortgage,
    setLenderContactRole
} from "../../../context/UniversalSurvey/asyncActions/lenderContacts";
import {
    createMortgageBrokerageOptionList,
    createRecordFromMortgageBrokerage,
    getMortgageBrokerageRecordInfo
} from "../../../context/UniversalSurvey/asyncActions/mortgageBrokerages";
import {
    createMortgageBrokerOptionsList,
    getMortgageBrokerRecordInfo,
    createRecordFromMortgageBroker
} from "../../../context/UniversalSurvey/asyncActions/mortgageBrokers";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import { removeGuarantorFromMortgage } from "../../../context/UniversalSurvey/asyncActions/guarantors";
import PriorityPicker from "../Components/PriorityPicker/PriorityPicker";
import * as _ from "lodash";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { sortByKey } from "../../../libs/utils/arrays";
import { LenderType } from "../../../libs/resources/enums/lenderInstitutions";
import ReadonlyLenderModal from "../Components/Modals/ReadonlyModals/ReadonlyLenderModal";
import ReadonlyMortgageBrokerageModal from "../Components/Modals/ReadonlyModals/ReadonlyMortgageBrokerageModal";
import ReadonlyGeneralBrokerModal from "../Components/Modals/ReadonlyModals/ReadonlyGeneralBrokerModal";
import ReadonlyGeneralContactModal from "../Components/Modals/ReadonlyModals/ReadonlyGeneralContactModal";
import TableBase from "../../../../components/Common/Table/TableBase";
import LineItemInput from "../../../../components/Common/LineItemInput/LineItemInput";
import { SimpleNewMortgageNetAdvanceAmount } from "../../../libs/types/UniversalSurvey/NewMortgageNetAdvanceAmount/simpleNewMortgageNetAdvanceAmount";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import { createLegalProfessionalOptionsList, setLegalProfessionalRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalProfessionals";
import { LegalProfessionalType } from "../../../libs/resources/enums/legalProfessionalType";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import LegalProfessionalModal from "../Components/Modals/LegalProfessionalModal";
import LegalFirmModal from "../Components/Modals/LegalFirmModal/LegalFirmModal";
import LegalFirmOfficeModal from "../Components/Modals/LegalFirmOfficeModal/LegalFirmOfficeModal";
import ReadonlyLegalProfessionalModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalProfessionalModal";
import ReadonlyLegalFirmModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmModal";
import ReadonlyLegalFirmOfficeModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmOfficeModal";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { getLegalProfessionalFirmOptions, setLegalFirmRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalFirms";
import { LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { getLegalProfessionalFirmOfficeOptions, setLegalFirmOfficeRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalFirmOffices";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../libs/utils/limits";

export default function NewMortgageSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [lenderModalActive, setLenderModalActive] = useState<boolean>(false);
    const [viewLenderModalActive, setViewLenderModalActive] = useState<boolean>(false);
    const [lenderContactModalActive, setLenderContactModalActive] = useState<boolean>(false);
    const [viewLenderContactModalActive, setViewLenderContactModalActive] = useState<boolean>(false);
    const [guarantorModalActive, setGuarantorModalActive] = useState<boolean>(false);
    const [brokerageModalActive, setBrokerageModalActive] = useState<boolean>(false);
    const [viewBrokerageModalActive, setViewBrokerageModalActive] = useState<boolean>(false);
    const [brokerModalActive, setBrokerModalActive] = useState<boolean>(false);
    const [viewBrokerModalActive, setViewBrokerModalActive] = useState<boolean>(false);
    const [brokerageOtherContactModalActive, setBrokerageOtherContactModalActive] = useState<boolean>(false);
    const [newMortgageObj, setNewMortgageObj] = useState<NewMortgage>(state.newMortgages.mortgageTransactionInfo?.new_mortgage_record!);
    
    const [legalProfessionalModalActive, setLegalProfessionalModalActive] = useState<boolean>(false);
    const [legalFirmModalActive, setLegalFirmModalActive] = useState<boolean>(false);
    const [legalOfficeModalActive, setLegalOfficeModalActive] = useState<boolean>(false);
    const [readonlyProfessionalModalActive, setReadonlyProfessionalModalActive] = useState<boolean>(false);
    const [readonlyFirmModalActive, setReadonlyFirmModalActive] = useState<boolean>(false);
    const [readonlyOfficeModalActive, setReadonlyOfficeModalActive] = useState<boolean>(false);

    // Handling searching global entities
    const [lenderSearchValue, setLenderSearchValue] = useState<string | undefined>(undefined);
    
    // Handling searching lenders
    function makeLenderQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        query.set("search", searchVal);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        return query;
    }

    useEffect(() => {
        debouncedLenderSearch(lenderSearchValue);
    }, [lenderSearchValue]);

    const debouncedLenderSearch = useCallback(
        debounce((lenderSearchValue) => {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                newMortgageObj.lender_id ?? undefined,
                makeLenderQuery(lenderSearchValue)
            );
        }, 500), []);

    // Create deal level lists - lenders, brokerages, brokerage other contacts
    useEffect(() => {
        createLenderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.newMortgages.mortgageTransactionInfo!.new_mortgage_record!.lender_record?.id,
            undefined
        );
        createMortgageBrokerageOptionList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.newMortgages.mortgageTransactionInfo!.new_mortgage_record!.mortgage_brokerage_record?.id
        );
        getMortgageBrokerageOtherContactsInDeal(dispatch, String(state.deal.dealInfo?.id));

        return () => dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: undefined });
    }, [])

    useEffect(() => {
        setNewMortgageObj(state.newMortgages.mortgageTransactionInfo?.new_mortgage_record!);

        if (state.newMortgages.mortgageTransactionInfo?.new_mortgage_record!.lender_contact_list) {
            dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: state.newMortgages.mortgageTransactionInfo.new_mortgage_record.lender_contact_list.map((contact) => ({ id: contact.id, label: contact.name, isRecord: true, role: contact.role })) });
        } else {
            dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: [] });
        }

        if (state.newMortgages.mortgageTransactionInfo?.new_mortgage_record!.guarantor_list) {
            dispatch({ type: actions.SET_GUARANTORS, payload: state.newMortgages.mortgageTransactionInfo.new_mortgage_record.guarantor_list });
        } else {
            dispatch({ type: actions.SET_GUARANTORS, payload: [] });
        }

        if(state.newMortgages.mortgagesBeingSaved.includes(state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.id!)) {
            dispatch({ type: actions.SET_IS_MORTGAGE_SAVING, payload: true });
        }
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.id])

    useEffect(() => {
        if (state.newMortgages.shouldRefreshMortgage) {
            changeSelectedNewMortgage(dispatch, String(state.deal.dealInfo?.id), state.newMortgages.mortgageTransactionInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_MORTGAGE, payload: false });
        }
    }, [state.newMortgages.shouldRefreshMortgage])

    useEffect(() => {
        setNewMortgageObj({ ...newMortgageObj, priority_after_closing: state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.priority_after_closing });
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.priority_after_closing])

    useEffect(() => {
        if (!newMortgageObj.priority_after_closing) {
            dispatch({ type: actions.SET_MORTGAGE_PRIORITY, payload: state.priorities.priorityAfterClosingList.length + 1});
        }
    }, [newMortgageObj.priority_after_closing]);

    useEffect(() => {
        dispatch({ type: actions.SET_SELECTED_LENDER, payload: state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.lender_record?.id })
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.lender_record?.id])

    useEffect(() => {
        if(state.lenders.lenderInfo) {
            createLenderContactOptionsList(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo);
        }
    }, [state.lenders.lenderInfo])

    useEffect(() => {
        if(state.mortgageBrokerages.selectedBrokerage && state.mortgageBrokerages.brokerageInfo) {
            createMortgageBrokerOptionsList(dispatch, String(state.deal.dealInfo?.id), state.mortgageBrokerages.brokerageInfo, state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.mortgage_broker_record?.id);
        }
    }, [state.mortgageBrokerages.selectedBrokerage, state.mortgageBrokerages.brokerageInfo])

    // Updating local object if state changes for lender, brokerage, etc.
    useEffect(() => {
        updateNewMortgageInfo("lender_id", state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.lender_id);
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.lender_id])
    useEffect(() => {
        updateNewMortgageInfo("mortgage_brokerage_id", state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.mortgage_brokerage_id);
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.mortgage_brokerage_id])
    useEffect(() => {
        updateNewMortgageInfo("mortgage_broker_id", state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.mortgage_broker_id);
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.mortgage_broker_id])

    useEffect(() => {
        if (state.newMortgages.mortgageTransactionInfo?.new_mortgage_record && newMortgageObj) {
            // the only time when net advances in context state is being updated is when creating/removing
            // need to compare the list to what is in local state and add/remove any, while keeping existing
            if (state.newMortgages.mortgageTransactionInfo.new_mortgage_record.net_advance_amount.length < newMortgageObj.net_advance_amount.length) {
                // deleted some, need to remove from local state
                const tempDeductions = [...newMortgageObj.net_advance_amount];
                for (let i = tempDeductions.length - 1; i >= 0; i--) {
                    const matchingContextDeduction = state.newMortgages.mortgageTransactionInfo.new_mortgage_record.net_advance_amount.find((contextDeduction) => contextDeduction.id === tempDeductions[i].id);
                    if (!matchingContextDeduction) {
                        tempDeductions.splice(i, 1);
                    }
                }
                setNewMortgageObj({ ...newMortgageObj, net_advance_amount: tempDeductions });
            } else if (state.newMortgages.mortgageTransactionInfo.new_mortgage_record.net_advance_amount.length > newMortgageObj.net_advance_amount.length) {
                // added net advance, need to insert into local state
                setNewMortgageObj({ ...newMortgageObj, net_advance_amount: [...newMortgageObj.net_advance_amount, state.newMortgages.mortgageTransactionInfo.new_mortgage_record.net_advance_amount[state.newMortgages.mortgageTransactionInfo.new_mortgage_record.net_advance_amount.length - 1]] });
            }
        }
    }, [state.newMortgages.mortgageTransactionInfo?.new_mortgage_record?.net_advance_amount])

    useEffect(() => {
        createLegalProfessionalOptionsList(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.region_id);
        if (state.lenders.lenderInfo?.lawyer) {
            const lawyerListItem: LegalProfessionalListItem = {
                ...state.lenders.lenderInfo.lawyer,
                isRecord: true,
                label: state.lenders.lenderInfo.lawyer.name
            };
            getLegalProfessionalFirmOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, LegalProfessionalOptions.LenderLawyer);

            if (state.lenders.lenderInfo.lawyer.legal_firm_record) {
                const firmListItem: LegalFirmListItem = {
                    ...state.lenders.lenderInfo.lawyer.legal_firm_record,
                    isRecord: true,
                    label: state.lenders.lenderInfo.lawyer.legal_firm_record.name
                }
                getLegalProfessionalFirmOfficeOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, firmListItem, LegalProfessionalOptions.LenderLawyer);
            }
        }
    }, [])

    useEffect(() => {
        getLegalProfessionalFirmOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            LegalProfessionalOptions.LenderLawyer
        )
    }, [state.lenders.lenderInfo?.lawyer?.id])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.lenders.lenderInfo?.lawyer?.id, firmRecord: state.lenders.lenderInfo?.lawyer?.legal_firm_record }
        });
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id),
            LegalProfessionalOptions.LenderLawyer
        );
    }, [state.lenders.lenderInfo?.lawyer?.legal_firm_record])

    useEffect(() => {
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id),
            LegalProfessionalOptions.LenderLawyer
        );
    }, [state.legalFirms.lenderLawyerFirmOptionList])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.lenders.lenderInfo?.lawyer?.id, officeRecord: state.lenders.lenderInfo?.lawyer?.legal_firm_office_record }
        });
    }, [state.lenders.lenderInfo?.lawyer?.legal_firm_office_record])

    function updateNewMortgageInfo<
        K extends keyof NewMortgage,
        V extends NewMortgage[K]
    >(key: K, value: V): void {
        const tempNewMortgageObj = { ...newMortgageObj }
        tempNewMortgageObj[key] = value;
        setNewMortgageObj(tempNewMortgageObj);
    }

    const debouncedSave = useCallback(
        debounce((newMortgageInfo) => {
            saveNewMortgageDeal(dispatch, String(state.deal.dealInfo?.id), newMortgageInfo);
            dispatch({ type: actions.REMOVE_MORTGAGE_BEING_SAVED, payload: newMortgageInfo.id });
        }, 1000), []);

    const prevMortgage = usePrevious(newMortgageObj);
    useDidUpdateEffect(() => {
        if (prevMortgage?.id === newMortgageObj.id) {
            dispatch({ type: actions.ADD_MORTGAGE_BEING_SAVED, payload: newMortgageObj.id });
            debouncedSave(newMortgageObj);
        }
    }, [newMortgageObj]);

    function updateLender(value: LenderListItem | null) {
        dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: [] });
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_LENDER, payload: value.id });
                getLenderInfo(dispatch, String(state.deal.dealInfo?.id), value.id, state.dataSheet.currEntity);
            } else {
                createLenderRecordFromLender(dispatch, String(state.deal.dealInfo?.id), value, state.dataSheet.currEntity!, updateMortgageLenderIdAndTabName);
            }
        } else {
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: undefined });
            dispatch({ type: actions.SET_LENDER_INFO, payload: undefined });
            dispatch({ type: actions.SET_MORTGAGE_LENDER_ID, payload: null });
            dispatch({ type: actions.UPDATE_MORTGAGE_TRANSACTION_LABEL, payload: { mortgageId: state.newMortgages.mortgageTransactionInfo?.id, abbr_name: null, name: null } })
        }
        updateMortgageTabName(dispatch, state.newMortgages.mortgageTransactionInfo?.id!, value?.abbr_name ?? value?.name);
    }

    function updateBrokerage(value: MortgageBrokerageListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                updateBrokerageInfo(value.id);
                dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: value.id });
                getMortgageBrokerageRecordInfo(dispatch, String(state.deal.dealInfo?.id), value.id!);
            } else {
                createRecordFromMortgageBrokerage(dispatch, String(state.deal.dealInfo?.id), value);
            }
        } else {
            updateBrokerageInfo(null);
        }
    }

    function updateBrokerageInfo(brokerageId?: number | null) {
        if (brokerageId !== newMortgageObj.mortgage_brokerage_id) {
            const tempNewMortgageObj = { ...newMortgageObj }
            tempNewMortgageObj["mortgage_broker_id"] = null;
            tempNewMortgageObj["mortgage_brokerage_id"] = brokerageId;
            setNewMortgageObj(tempNewMortgageObj);
            dispatch({ type: actions.SET_MORTGAGE_BROKER_ID, payload: null });
            dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_ID, payload: brokerageId });
        }
    }

    function updateBroker(value: MortgageBrokerListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                updateNewMortgageInfo("mortgage_broker_id", value.id);
                dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: value.id });
                getMortgageBrokerRecordInfo(dispatch, String(state.deal.dealInfo?.id), state.mortgageBrokerages.selectedBrokerage?.id!, value.id!);
            } else {
                createRecordFromMortgageBroker(dispatch, String(state.deal.dealInfo?.id), state.mortgageBrokerages.selectedBrokerage?.id!, value);
            }
        } else {
            updateNewMortgageInfo("mortgage_broker_id", null);
        }
    }

    function onLenderContactEdit(id: number) {
        setLenderContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender?.id!, id)
        setLenderContactModalActive(true);
    }

    function onLenderContactView(id: number) {
        setLenderContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender?.id!, id)
        setViewLenderContactModalActive(true); 
    }

    function onBrokerageOtherContactEdit(contact: MortgageBrokerageOtherContact) {
        getMortgageBrokerageOtherContactFull(dispatch, String(state.deal.dealInfo?.id), String(contact.id))
        setBrokerageOtherContactModalActive(true);
    }

    function convertOtherContactsToComboboxItems(contactList: MortgageBrokerageOtherContact[]) {
        const modifiedContacts: MortgageBrokerageOtherContact & { label: string }[] = [];
        for (const contact of contactList) {
            modifiedContacts.push({ ...contact, label: contact.name ?? "" });
        }
        sortByKey(modifiedContacts, "label");
        return modifiedContacts;
    }

    function IsOtherContactNotInMortgage(contactInDeal: MortgageBrokerageOtherContact): boolean {
        let contactNotInMortgage = true;
        for (const contact of state.mortgageBrokerageOtherContacts.otherContactsInMortgage) {
            if (contact.id === contactInDeal.id) {
                contactNotInMortgage = false;
            }
        }
        return contactNotInMortgage;
    }

    function updateNetAdvanceInfo<
        K extends keyof SimpleNewMortgageNetAdvanceAmount,
        V extends SimpleNewMortgageNetAdvanceAmount[K]
    >(key: K, value: V, netAdvanceId: number): void {
        const tempNetAdvances = [...newMortgageObj.net_advance_amount];
        const matchingNetAdvance = tempNetAdvances.find((netAdvance) => netAdvance.id === netAdvanceId);
        if (matchingNetAdvance) {
            dispatch({ type: actions.ADD_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED, payload: netAdvanceId });
            const tempNetAdvance = { ...matchingNetAdvance };
            tempNetAdvance[key] = value;
            tempNetAdvances[tempNetAdvances.indexOf(matchingNetAdvance)] = tempNetAdvance;
            const tempMortgageObj = { ...newMortgageObj };
            tempMortgageObj.net_advance_amount = tempNetAdvances;
            setNewMortgageObj(tempMortgageObj);
            debouncedSaveNetAdvances(tempNetAdvances, [...state.newMortgages.netAdvancesBeingSaved, netAdvanceId])
        }
    }

    const debouncedSaveNetAdvances = useCallback(
        debounce((netAdvances, netAdvancesBeingSaved) => {
            for (const netAdvance of netAdvances) {
                if (netAdvancesBeingSaved!.includes(netAdvance.id)) {
                    saveNewMortgageNetAdvanceAmount(dispatch, String(state.deal.dealInfo?.id), newMortgageObj.id, netAdvance)
                    dispatch({ type: actions.REMOVE_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED, payload: netAdvance.id });
                }
            }
        }, 1000), [state.newMortgages.netAdvancesBeingSaved, newMortgageObj.net_advance_amount]);

    function isThereEmptyNetAdvance() {
        for (const netAdvance of newMortgageObj.net_advance_amount) {
            if (!netAdvance.description && !netAdvance.amount) {
                return true;
            }
        }
        return false;
    }

    function calculateNetAdvance() {
        let total = 0;
        if (newMortgageObj.principal_amount) total += newMortgageObj.principal_amount;
        for (const netAdvance of newMortgageObj.net_advance_amount) {
            if (netAdvance.amount) total += netAdvance.amount;
        }
        return total;
    }

    return (
        (state.newMortgages.isMortgageSaving || isObjectLoading(state.dataSheet.objectsLoading, [Loading.MortgageBrokerageList, Loading.LegalProfessionalList])) ? <CircularLoader containerHeight="70vh" /> :
        <div>
            <LenderModal
                open={lenderModalActive}
                onClose={() => setLenderModalActive(false)}
                title={`${state.lenders.editingLenderRecord ? "" : "New "}Lender`}
                onSubmitSave={updateMortgageTabNamesOnLenderSave}
                onSubmitNew={updateMortgageLenderIdAndTabName}
            />
            <ReadonlyLenderModal
                open={viewLenderModalActive}
                onClose={() => setViewLenderModalActive(false)}
            />
            <LenderContactModal
                open={lenderContactModalActive}
                onClose={() => setLenderContactModalActive(false)}
                changeOrAddContact={changeOrAddLenderContactToMortgage}
                mortgageOrLienId={state.newMortgages.mortgageTransactionInfo?.id!}
            />
            <ReadonlyGeneralContactModal
                open={viewLenderContactModalActive}
                onClose={() => setViewLenderContactModalActive(false)}
                type="lender"
            />
            <MortgageBrokerageModal
                open={brokerageModalActive}
                onClose={() => setBrokerageModalActive(false)}
                title={`${state.mortgageBrokerages.editingMortgageBrokerageRecord ? "" : "New "}Mortgage Brokerage`}
            />
            <ReadonlyMortgageBrokerageModal
                open={viewBrokerageModalActive}
                onClose={() => setViewBrokerageModalActive(false)}
            />
            <MortgageBrokerModal
                open={brokerModalActive}
                onClose={() => setBrokerModalActive(false)}
                title={`${state.mortgageBrokers.editingMortgageBrokerRecord ? "" : "New "}Mortgage Broker`}
            />
            <ReadonlyGeneralBrokerModal
                open={viewBrokerModalActive}
                onClose={() => setViewBrokerModalActive(false)}
                type="mortgage"
            />
            <MortgageBrokerageOtherContactModal
                open={brokerageOtherContactModalActive}
                onClose={() => setBrokerageOtherContactModalActive(false)}
            />
            <GuarantorModal
                open={guarantorModalActive}
                onClose={() => setGuarantorModalActive(false)}
                mortgageLender={state.lenders.selectedLender?.label}
            />
            <LegalProfessionalModal
                open={legalProfessionalModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalProfessionalModalActive(false)}
            />
            <LegalFirmModal
                open={legalFirmModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalFirmModalActive(false)}
            />
            <LegalFirmOfficeModal
                open={legalOfficeModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalOfficeModalActive(false)}
            />
            <ReadonlyLegalProfessionalModal
                open={readonlyProfessionalModalActive}
                onClose={() => setReadonlyProfessionalModalActive(false)}
            />
            <ReadonlyLegalFirmModal
                open={readonlyFirmModalActive}
                onClose={() => setReadonlyFirmModalActive(false)}
            />
            <ReadonlyLegalFirmOfficeModal
                open={readonlyOfficeModalActive}
                onClose={() => setReadonlyOfficeModalActive(false)}
            />
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={4}>
                    <Dropdown
                        label={{ text: "Product Type" }}
                        value={newMortgageObj.type ?? undefined}
                        onChange={(e) => {
                            updateNewMortgageInfo("type", e.target.value as string);
                            updateMortgageTabSubtitle(dispatch, state.newMortgages.mortgageTransactionInfo?.id!, e.target.value as string)
                        }}
                        placeholder="Select an option..."
                        options={ProductTypeOptions} // add other options from list of all possible entries, or have options passed in as props
                        formatValue={formatMortgageType}
                        isHoverActionHidden={newMortgageObj.type ? false : true}
                        handleClear={() => {
                            updateNewMortgageInfo("type", undefined);
                            updateMortgageTabSubtitle(dispatch, state.newMortgages.mortgageTransactionInfo?.id!, undefined)
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <BooleanControl
                        label={{ text: "Blanket Mortgage", inputId: "collateral_mortgage" }}
                        checked={newMortgageObj.collateral_mortgage}
                        onChange={(e) => updateNewMortgageInfo("collateral_mortgage", !newMortgageObj.collateral_mortgage)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicTextInput
                        label={{ text: "Loan No.", inputId: "loan_number" }}
                        fullWidth
                        inputProps={{ "aria-label": "Loan Number" }}
                        placeholder={"Loan number"}
                        value={newMortgageObj.loan_number}
                        onChange={(e) => updateNewMortgageInfo("loan_number", e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicDatePicker
                        label={{ text: "Date of Commitment" }}
                        value={newMortgageObj.date_of_commitment ?? null}
                        onChange={(e) => updateNewMortgageInfo("date_of_commitment", e as Date)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <PriorityPicker
                        value={newMortgageObj.priority_after_closing!}
                        label="Priority After Closing"
                        type="after"
                    />
                </Grid>
                {newMortgageObj.type === "construction" && (
                    <Grid item xs={3}>
                        <BooleanControl
                            label={{ text: "Construction Lien Holdback", inputId: "construction_lien_holdback" }}
                            checked={newMortgageObj.construction_lien_holdback}
                            onChange={(e) => updateNewMortgageInfo("construction_lien_holdback", !newMortgageObj.construction_lien_holdback)}
                        />
                    </Grid>
                )}
                <Grid item xs={3}>
                    <BasicTextInput
                        label={{ text: "Mortgage Registration No.", inputId: "registration_number" }}
                        fullWidth
                        placeholder="Mortgage number"
                        inputProps={{ "aria-label": "Mortgage Registration No." }}
                        value={newMortgageObj.registration_number}
                        onChange={(e) => updateNewMortgageInfo("registration_number", e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicDatePicker
                        label={{ text: "Mortgage Registration Date" }}
                        value={newMortgageObj.registration_date ?? null}
                        onChange={(e) => updateNewMortgageInfo("registration_date", e as Date)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H4>Parties Involved</H4>
                </Grid>

                {/* LENDER */}
                <Grid item xs={5}>
                    <ComboBox
                        label={{ text: "Lender", inputId: "lender_record" }}
                        value={lenderSearchValue !== undefined ? lenderSearchValue : state.lenders.selectedLender ?? undefined}
                        canEdit={(state.lenders.selectedLender && state.lenders.selectedLender.type !== LenderType.MortgageCentre) ? true : false}
                        options={state.lenders.lenderOptionList}
                        placeholder={"Select lender"}
                        createEntity="lender"
                        setCreationModalOpen={() => setLenderModalActive(true)}
                        onChangeFn={(value) => updateLender(value as LenderListItem)}
                        setText={(value) => setLenderSearchValue(value)}
                        isHoverActionHidden={state.lenders.selectedLender ? false : true}
                        searchableKeys={["name", "abbr_name", "branch_number", "institution_number", "general_address"]}
                        handleClear={() => updateLender(null)}
                        handleView={() => {
                            setViewLenderModalActive(true);
                            dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: true });
                        }}
                        onEditClick={() => {
                            setLenderModalActive(true);
                            dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: true });
                        }}
                        isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderList])}
                        onBlur={() => setLenderSearchValue(undefined)}
                    />
                </Grid>
                {state.lenders.selectedLender ? (
                    <>
                        {state.lenderContacts.lenderContactsList.map((contact, i) => (
                            <Fragment key={i}>
                                {i !== 0 && <Grid item xs={5}/>}
                                <Grid item xs={4}>
                                    <ComboBox
                                        label={{ text: "Lender Contact", inputId: `lender_contact-${i}` }}
                                        createEntity="lender contact"
                                        setCreationModalOpen={() => {
                                            setLenderContactModalActive(true);
                                            dispatch({ type: actions.SET_LENDER_CONTACT_BEING_REPLACED, payload: contact });
                                        }}
                                        value={contact}
                                        canEdit={contact.id ? true : false}
                                        onEditClick={() => onLenderContactEdit(contact.id!)}
                                        handleView={() => onLenderContactView(contact.id!)}
                                        onChangeFn={(value) => changeLenderContactInMortgage(
                                            dispatch,
                                            String(state.deal.dealInfo?.id),
                                            state.newMortgages.mortgageTransactionInfo?.id!,
                                            state.lenders.selectedLender!.id,
                                            contact.id!,
                                            value as LenderContactListItem
                                        )}
                                        isHoverActionHidden={contact.id ? false : true}
                                        options={state.lenderContacts.lenderContactOptionList.filter((listContact) => !_.find(state.lenderContacts.lenderContactsList, (option) => _.isMatch(option, listContact)) || _.isMatch(contact, listContact))}
                                        handleClear={() => removeLenderContactFromMortgage(
                                            dispatch,
                                            String(state.deal.dealInfo?.id),
                                            state.newMortgages.mortgageTransactionInfo?.id!,
                                            contact.id!
                                        )}
                                        placeholder="Select a contact..."
                                        isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderContactList])}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ComboBox
                                        label={{ text: "Contact Role", inputId: `contact_role-${i}`}}
                                        value={contact.role}
                                        text={String(contact.role)}
                                        onChangeFn={(value) => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, String(value))}
                                        setText={(text) => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, String(text))}
                                        options={lenderContactRoleOptions}
                                        placeholder="Select a role..."
                                        handleClear={() => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, undefined)}
                                        isHoverActionHidden={contact.role ? false : true}
                                    />
                                </Grid>
                            </Fragment>
                        ))}
                        {state.lenderContacts.emptyContactAdded && <Grid item xs={5}/>}
                        {(state.lenderContacts.emptyContactAdded || state.lenderContacts.lenderContactsList.length === 0) && (
                            <Grid item xs={4}>
                                <ComboBox
                                    label={{ text: "Lender Contact", inputId: `empty_lender_contact` }}
                                    createEntity="lender contact"
                                    setCreationModalOpen={() => setLenderContactModalActive(true)}
                                    value={undefined}
                                    onChangeFn={(value) => addLenderContactToMortgage(
                                        dispatch,
                                        String(state.deal.dealInfo?.id),
                                        state.newMortgages.mortgageTransactionInfo?.id!,
                                        state.lenders.selectedLender!.id,
                                        value as LenderContactListItem,
                                        true
                                    )}
                                    options={state.lenderContacts.lenderContactOptionList.filter((listContact) => !_.find(state.lenderContacts.lenderContactsList, (option) => _.isMatch(option, listContact)))}
                                    handleClear={state.lenderContacts.lenderContactsList.length === 0 ? undefined : () => {dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: false })}}
                                    isHoverActionHidden={false}
                                    placeholder="Select a contact..."
                                />
                            </Grid>
                        )}
                        {/* Lenders lawyers */}
                        {(state.lenders.lenderInfo?.type === LenderType.Private || state.lenders.lenderInfo?.type === LenderType.Personal) && (
                            <>
                                <Grid item xs={4}>
                                    <ComboBox
                                        value={state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id && professional.isRecord)}
                                        options={state.legalProfessionals.optionList.filter((professional) => professional.type === LegalProfessionalType.Lawyer)}
                                        onChangeFn={(value) => updateLenderLawyer(dispatch, value as LegalProfessionalListItem, state.deal.dealInfo!, state.lenders.lenderInfo!)}
                                        placeholder="Select lawyer..."
                                        label={{ text: "Lender's Lawyer", inputId: "lawyer" }}
                                        handleClear={() => updateLenderLawyer(dispatch, null, state.deal.dealInfo!, state.lenders.lenderInfo!)}
                                        isHoverActionHidden={state.lenders.lenderInfo?.lawyer ? false : true}
                                        createEntity="lawyer"
                                        setCreationModalOpen={() => setLegalProfessionalModalActive(true)}
                                        canEdit={state.lenders.lenderInfo?.lawyer ? true : false}
                                        onEditClick={() => {
                                            setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                            setLegalProfessionalModalActive(true);
                                        }}
                                        handleView={() => {
                                            setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                            setReadonlyProfessionalModalActive(true);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    {state.lenders.lenderInfo?.lawyer &&
                                        <ComboBox
                                            value={state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id && firm.isRecord)}
                                            options={state.legalFirms.lenderLawyerFirmOptionList}
                                            onChangeFn={(value) => updateLenderLawyerFirm(dispatch, value as LegalFirmListItem, state.deal.dealInfo, state.lenders.lenderInfo)}
                                            placeholder="Select firm..."
                                            label={{ text: "Lawyer's Firm", inputId: "lawyer-firm" }}
                                            handleClear={() => updateLenderLawyerFirm(dispatch, null, state.deal.dealInfo, state.lenders.lenderInfo)}
                                            isHoverActionHidden={state.lenders.lenderInfo?.lawyer.legal_firm_record ? false : true}
                                            createEntity="firm"
                                            setCreationModalOpen={() => setLegalFirmModalActive(true)}
                                            canEdit={state.lenders.lenderInfo?.lawyer.legal_firm_record ? true : false}
                                            onEditClick={() => {
                                                setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                setLegalFirmModalActive(true);
                                            }}
                                            handleView={() => {
                                                setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                setReadonlyFirmModalActive(true);
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    {state.lenders.lenderInfo?.lawyer?.legal_firm_record &&
                                        <ComboBox
                                            value={state.legalFirmOffices.lenderLawyerOfficeOptionList.find((office) => office.id === state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id && office.isRecord)}
                                            options={state.legalFirmOffices.lenderLawyerOfficeOptionList}
                                            onChangeFn={(value) => updateLenderLawyerOffice(dispatch, value as LegalFirmOfficeListItem, state.deal.dealInfo, state.lenders.lenderInfo)}
                                            placeholder="Select office..."
                                            label={{ text: "Lawyer's Office", inputId: "lawyer-office" }}
                                            handleClear={() => updateLenderLawyerOffice(dispatch, null, state.deal.dealInfo, state.lenders.lenderInfo)}
                                            isHoverActionHidden={state.lenders.lenderInfo?.lawyer.legal_firm_office_record ? false : true}
                                            createEntity="office"
                                            setCreationModalOpen={() => setLegalOfficeModalActive(true)}
                                            canEdit={state.lenders.lenderInfo?.lawyer.legal_firm_office_record ? true : false}
                                            onEditClick={() => {
                                                setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id!, state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id!);
                                                setLegalOfficeModalActive(true);
                                            }}
                                            handleView={() => {
                                                setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id!, state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id!);
                                                setReadonlyOfficeModalActive(true);
                                            }}
                                        />
                                    }
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <BasicButton
                                label={{ text: "Add Lender Contact", inputId: "add-lender-contact" }}
                                action="add"
                                disabled={state.lenderContacts.lenderContactsList.length === 0 || state.lenderContacts.emptyContactAdded}
                                typeOf="secondary"
                                onClick={() => dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: true })}
                            />
                        </Grid>
                    </>
                ) : (<Grid item xs={12} />)}


                {/* BROKERAGE */}
                <Grid item xs={5}>
                    <ComboBox
                        label={{ text: "Mortgage Brokerage", inputId: "mortgage_brokerage_record" }}
                        value={state.mortgageBrokerages.selectedBrokerage ?? undefined}
                        canEdit={state.mortgageBrokerages.selectedBrokerage ? true : false}
                        onEditClick={() => {
                            setBrokerageModalActive(true);
                            dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKERAGE_RECORD, payload: true });
                        }}
                        handleView={() => {
                            setViewBrokerageModalActive(true);
                            dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKERAGE_RECORD, payload: true });
                        }}
                        options={state.mortgageBrokerages.brokerageOptionList}
                        placeholder={"Select mortgage brokerage"}
                        createEntity="mortgage brokerage"
                        setCreationModalOpen={() => setBrokerageModalActive(true)}
                        onChangeFn={(value) => updateBrokerage(value as MortgageBrokerageListItem)}
                        isHoverActionHidden={state.mortgageBrokerages.selectedBrokerage ? false : true}
                        handleClear={() => {
                            updateBrokerage(null);
                            dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: undefined });
                            dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_INFO, payload: undefined });
                        }}
                    />
                </Grid>
                {state.mortgageBrokerages.selectedBrokerage && (
                    <>
                        <Grid item xs={4}>
                            <ComboBox
                                label={{ text: "Mortgage Broker", inputId: "mortgage_broker_record" }}
                                createEntity="mortgage broker"
                                setCreationModalOpen={() => setBrokerModalActive(true)}
                                value={state.mortgageBrokers.selectedBroker ?? undefined}
                                onChangeFn={(value) => updateBroker(value as MortgageBrokerListItem)}
                                options={state.mortgageBrokers.brokerOptionList}
                                isHoverActionHidden={state.mortgageBrokers.selectedBroker ? false : true}
                                canEdit={state.mortgageBrokers.selectedBroker ? true : false}
                                onEditClick={() => {
                                    setBrokerModalActive(true);
                                    dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKER_RECORD, payload: true });
                                }}
                                handleView={() => {
                                    setViewBrokerModalActive(true);
                                    dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKER_RECORD, payload: true });
                                }}
                                handleClear={() => {
                                    updateBroker(null);
                                    dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: undefined });
                                    dispatch({ type: actions.SET_MORTGAGE_BROKER_INFO, payload: undefined });
                                }}
                                placeholder="Select a broker..."
                                isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.MortgageBrokerList])}
                            />
                        </Grid>

                        {state.mortgageBrokerageOtherContacts.otherContactsInMortgage && state.mortgageBrokerageOtherContacts.otherContactsInMortgage.map((contact, i) => (
                            <Grid item xs={4} key={i}>
                                <ComboBox
                                    label={{ text: "Brokerage Other Contact", inputId: `mortgage_broker_record-${i}` }}
                                    createEntity="brokerage other contact"
                                    setCreationModalOpen={() => handleCreateMortgageBrokerageOtherContact(dispatch, setBrokerageOtherContactModalActive, contact.id)}
                                    value={{ ...state.mortgageBrokerageOtherContacts.otherContactsInMortgage[i], label: state.mortgageBrokerageOtherContacts.otherContactsInMortgage[i].name }}
                                    onChangeFn={(value) => changeMortgageBrokerageOtherContactInMortgage(dispatch, String(state.deal.dealInfo?.id), String(state.newMortgages.mortgageTransactionInfo?.id!), contact.id!, value as MortgageBrokerageOtherContact)}
                                    options={convertOtherContactsToComboboxItems([
                                        contact,
                                        ...state.mortgageBrokerageOtherContacts.otherContactsInDeal.filter(
                                            (contactInDeal) => IsOtherContactNotInMortgage(contactInDeal)
                                        )
                                    ])}
                                    isHoverActionHidden={false}
                                    canEdit
                                    onEditClick={() => onBrokerageOtherContactEdit(contact)}
                                    handleClear={() => removeMortgageBrokerageOtherContactFromMortgage(dispatch, String(state.deal.dealInfo?.id), String(state.newMortgages.mortgageTransactionInfo?.id!), contact.id!)}
                                    placeholder="Select a contact..."
                                    isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.MortgageBrokerageOtherContactList])}
                                />
                            </Grid>
                        ))}
                        {(state.mortgageBrokerageOtherContacts.emptyContactAdded || state.mortgageBrokerageOtherContacts.otherContactsInMortgage.length === 0) && (
                            <Grid item xs={4}>
                                <ComboBox
                                    label={{ text: "Brokerage Other Contact", inputId: `brokerage_other_contact_record` }}
                                    createEntity="brokerage other contact"
                                    setCreationModalOpen={() => handleCreateMortgageBrokerageOtherContact(dispatch, setBrokerageOtherContactModalActive, undefined)}
                                    value={undefined}
                                    onChangeFn={(value) => addMortgageBrokerageOtherContactToMortgage(dispatch, String(state.deal.dealInfo?.id), String(state.newMortgages.mortgageTransactionInfo?.id!), value as MortgageBrokerageOtherContact)}
                                    options={convertOtherContactsToComboboxItems(
                                        state.mortgageBrokerageOtherContacts.otherContactsInDeal.filter(
                                            (contactInDeal) => IsOtherContactNotInMortgage(contactInDeal)
                                        )
                                    )}
                                    isHoverActionHidden={false}
                                    handleClear={state.mortgageBrokerageOtherContacts.otherContactsInMortgage.length === 0 ? undefined : () => {dispatch({ type: actions.SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED, payload: false })}}
                                    placeholder="Select a contact..."
                                    isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.MortgageBrokerageOtherContactList])}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <BasicButton
                                label={{ text: "Add Brokerage Other Contact", inputId: "add-brokerage-other-contact" }}
                                action="add"
                                disabled={state.mortgageBrokerageOtherContacts.otherContactsInMortgage.length === 0 || state.mortgageBrokerageOtherContacts.emptyContactAdded}
                                typeOf="secondary"
                                onClick={() => dispatch({ type: actions.SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED, payload: true })}
                            />
                        </Grid>
                    </>
                )}

                {/* GUARANTORS */}
                <Grid item xs={12} />
                {state.guarantors.guarantors.map((guarantor, i) => (
                    <Grid item xs={4} key={i}>
                        <BasicTextInput
                            label={{ text: "Guarantor", inputId: `guarantor-${i}` }}
                            fullWidth
                            inputProps={{ "aria-label": `Guarantor-${i}` }}
                            value={`${guarantor.first_name ? guarantor.first_name : ""} ${guarantor.last_name ? guarantor.last_name : ""}`}
                            readOnly
                            isHoverActionHidden={false}
                            handleClear={() => removeGuarantorFromMortgage(dispatch, String(state.deal.dealInfo?.id), state.newMortgages.mortgageTransactionInfo?.id!, guarantor)}
                        />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Stack direction="row" gap={2}>
                        <BasicButton
                            label={{ text: "Add Guarantor", inputId: "add-guarantor" }}
                            action="add"
                            typeOf="secondary"
                            onClick={() => setGuarantorModalActive(true)}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <H4>Terms</H4>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={3}>
                            <BooleanControl
                                label={{ text: "High Ratio Insured Mortgage", inputId: "high_ratio_insured_mortgage" }}
                                checked={newMortgageObj.high_ratio_insured_mortgage}
                                onChange={(e) => updateNewMortgageInfo("high_ratio_insured_mortgage", !newMortgageObj.high_ratio_insured_mortgage)}
                            />
                        </Grid>
                        {newMortgageObj.high_ratio_insured_mortgage && (
                            <>
                                <Grid item xs={3}>
                                    <BasicTextInput
                                        label={{ text: "High Ratio Insurance Premium", inputId: "high_ratio_insurance_premium" }}
                                        fullWidth
                                        inputProps={{ "aria-label": "High Ratio Insurance Premium" }}
                                        value={newMortgageObj.high_ratio_insurance_premium !== undefined ? String(newMortgageObj.high_ratio_insurance_premium) : undefined}
                                        // @ts-ignore
                                        onChange={(e) => updateNewMortgageInfo("high_ratio_insurance_premium", e.target.value)}
                                        moneyField
                                        valueType="positive"
                                        placeholder="0.00"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BasicTextInput
                                        label={{ text: "High Ratio HST/PST", inputId: "high_ratio_hst_pst" }}
                                        fullWidth
                                        inputProps={{ "aria-label": "High Ratio HST/PST" }}
                                        // value={newMortgageObj.ra}
                                        // onChange={(e) => updateNewMortgageInfo("high_ratio_insurance_premium", e.target.value as unknown as number)}
                                        moneyField
                                        valueType="positive"
                                        placeholder="0.00"
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={3}>
                            <BooleanControl
                                label={{ text: "Fixed Interest Rate", inputId: "has_fixed_interest_rate" }}
                                checked={newMortgageObj.has_fixed_interest_rate}
                                onChange={(e) => updateNewMortgageInfo("has_fixed_interest_rate", !newMortgageObj.has_fixed_interest_rate)}
                            />
                        </Grid>
                        {newMortgageObj.has_fixed_interest_rate ? (
                            <Grid item xs={2}>
                                <BasicTextInput
                                    fullWidth
                                    label={{ text: "Interest Rate", inputId: "interest_rate" }}
                                    inputProps={{ "aria-label": "Interest Rate" }}
                                    value={newMortgageObj.interest_rate ? String(newMortgageObj.interest_rate) : undefined}
                                    onChange={(e) => { Number(e.target.value) <= 100 ? updateNewMortgageInfo("interest_rate", Number(e.target.value)) : undefined}}
                                    percentageField
                                    placeholder="0"
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={2}>
                                <BasicTextInput
                                    fullWidth
                                    label={{ text: "Variable Interest Rate", inputId: "variable_interest_rate" }}
                                    placeholder={"Interest Rate"}
                                    inputProps={{ "aria-label": "Variable Interest Rate" }}
                                    value={newMortgageObj.variable_interest_rate}
                                    onChange={(e) => updateNewMortgageInfo("variable_interest_rate", e.target.value)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <ComboBox
                                label={{ text: "Calculation Period", inputId: "calculation_period" }}
                                options={CalculationPeriodOptions}
                                placeholder="Calculation Period"
                                value={newMortgageObj.calculation_period}
                                onChangeFn={(value) => updateNewMortgageInfo("calculation_period", value as string)}
                                text={newMortgageObj.calculation_period}
                                setText={(text: string) => updateNewMortgageInfo("calculation_period", text)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BooleanControl
                                label={{ text: "Interest Only", inputId: "interest_only" }}
                                checked={newMortgageObj.interest_only}
                                onChange={(e) => updateNewMortgageInfo("interest_only", !newMortgageObj.interest_only)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <BasicTextInput
                        fullWidth
                        inputProps={{ "aria-label": "Principal Amount" }}
                        label={{ text: "Principal Amount", inputId: "principal_amount" }}
                        value={newMortgageObj.principal_amount !== undefined ? String(newMortgageObj.principal_amount) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateNewMortgageInfo("principal_amount", e.target.value)}
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Registered Amount", inputId: "registered_amount" }}
                        inputProps={{ "aria-label": "Registered Amount" }}
                        value={newMortgageObj.registered_amount !== undefined ? String(newMortgageObj.registered_amount) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateNewMortgageInfo("registered_amount", e.target.value)}
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Net Advance", inputId: "net_advance" }}
                        inputProps={{ "aria-label": "Net Advance" }}
                        value={calculateNetAdvance()}
                        moneyField
                        readOnly
                    />
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={12}>
                    <StyledBody>Net Advance Credits and Debits</StyledBody>
                </Grid>
                <Grid item xs={12}>
                    {newMortgageObj.net_advance_amount.length > 0 && 
                        <TableBase 
                            columnHeadings={[
                                { label: "Description", objKey: "description" },
                                { label: "Amount", objKey: "amount" },
                                { label: "", objKey: "delete-button"}
                            ]}
                            tableRows={[]}
                            columnSizes={[10, 1, 1]}
                            id="new-mortgage-net-advance-amounts"
                        />
                    }
                    {
                        newMortgageObj.net_advance_amount.map((netAdvance, index) => (
                            <LineItemInput
                                key={index}
                                textValue={netAdvance.description}
                                size={10}
                                placeholder="Charge name"
                                textOnChange={(e) => updateNetAdvanceInfo("description", e.target.value, netAdvance.id)}
                                onRemove={() => deleteNewMortgageNetAdvanceAmount(dispatch, String(state.deal.dealInfo?.id), newMortgageObj.id, netAdvance.id)}
                                valueItems={[
                                    {
                                        size: 2,
                                        value: netAdvance.amount !== undefined ? String(netAdvance.amount) : undefined,
                                        onChange: (e) => updateNetAdvanceInfo("amount", e.target.value === "" ? undefined : Number(e.target.value), netAdvance.id),
                                        id: "amount",
                                        placeholder: "0"
                                    }
                                ]}
                            />
                        ))
                    }
                </Grid>
                <Grid item xs={12}>
                        <BasicButton
                            label={{ text: "Add Net Advance Item", inputId: "add-net-advance-item" }}
                            action="add"
                            typeOf="secondary"
                            onClick={() => addNewMortgageNetAdvanceAmount(dispatch, String(state.deal.dealInfo?.id), newMortgageObj.id)}
                            disabled={isThereEmptyNetAdvance()}
                        />
                    </Grid>

                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "SCT No.", inputId: "sct_number" }}
                        placeholder={"SCT number"}
                        inputProps={{ "aria-label": "SCT Number" }}
                        value={newMortgageObj.sct_number}
                        onChange={(e) => updateNewMortgageInfo("sct_number", e.target.value)}
                    />
                </Grid>
                {newMortgageObj.high_ratio_insured_mortgage && (
                    <Grid item xs={4}>
                        <Dropdown
                            label={{ text: "Insurance Amount" }}
                            value={newMortgageObj.insurance_amount ?? undefined}
                            onChange={(e) => updateNewMortgageInfo("insurance_amount", e.target.value as string)}
                            placeholder="Select an option..."
                            options={InsuranceAmountOptions} // add other options from list of all possible entries, or have options passed in as props
                            formatValue={formatInsuranceAmount}
                            isHoverActionHidden={newMortgageObj.insurance_amount ? false : true}
                            handleClear={() => updateNewMortgageInfo("insurance_amount", undefined)}
                        />
                    </Grid>
                )}
                <Grid item xs={4} />
                <Grid item xs={12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={2}>
                            <BooleanControl
                                label={{ text: "Assignment of Rents", inputId: "assignment_of_rents" }}
                                checked={newMortgageObj.assignment_of_rents}
                                onChange={(e) => updateNewMortgageInfo("assignment_of_rents", !newMortgageObj.assignment_of_rents)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BooleanControl
                                label={{ text: "General Security Agreement", inputId: "general_security_agreement" }}
                                checked={newMortgageObj.general_security_agreement}
                                onChange={(e) => updateNewMortgageInfo("general_security_agreement", !newMortgageObj.general_security_agreement)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BooleanControl
                                label={{ text: "Taxes Paid Through Mortgage", inputId: "taxes_paid_through_mortgage" }}
                                checked={newMortgageObj.taxes_paid_through_mortgage}
                                onChange={(e) => updateNewMortgageInfo("taxes_paid_through_mortgage", !newMortgageObj.taxes_paid_through_mortgage)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <BooleanControl
                                label={{ text: "Regular Payments", inputId: "regular_payments" }}
                                checked={newMortgageObj.regular_payments}
                                onChange={(e) => updateNewMortgageInfo("regular_payments", !newMortgageObj.regular_payments)}
                            />
                        </Grid>
                        {newMortgageObj.regular_payments && (
                            <Grid item xs={2}>
                                <BooleanControl
                                    label={{ text: "Alternate Frequency", inputId: "alternate_frequency_payments" }}
                                    checked={newMortgageObj.alternate_frequency_payments}
                                    onChange={(e) => updateNewMortgageInfo("alternate_frequency_payments", !newMortgageObj.alternate_frequency_payments)}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {newMortgageObj.assignment_of_rents && (
                    <Grid item xs={12}>
                        <Grid container rowSpacing={3} columnSpacing={5}>
                            <Grid item xs={3}>
                                <BasicTextInput
                                    label={{ text: "Assignment of Rents Registration No.", inputId: "assignment_of_rents_registration_number" }}
                                    fullWidth
                                    placeholder="Registration number"
                                    inputProps={{ "aria-label": "Assignment of Rents Registration No." }}
                                    value={newMortgageObj.assignment_of_rents_reg_num}
                                    onChange={(e) => updateNewMortgageInfo("assignment_of_rents_reg_num", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <BasicDatePicker
                                    label={{ text: "Assignment of Rents Registration Date" }}
                                    value={newMortgageObj.assignment_of_rents_reg_date ?? null}
                                    onChange={(e) => updateNewMortgageInfo("assignment_of_rents_reg_date", e as Date)}
                                />  
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={3}>
                            <BasicDatePicker
                                label={{ text: "Interest Adjustment Date" }}
                                value={newMortgageObj.interest_adjustment_date ?? null}
                                onChange={(e) => updateNewMortgageInfo("interest_adjustment_date", e as Date)}
                                minDate={state.deal.dealInfo?.opened_date ?? undefined}
                            />  
                        </Grid>
                        {newMortgageObj.regular_payments && (
                            <Grid item xs={3}>
                                <BasicDatePicker
                                    label={{ text: "First Payment Date" }}
                                    value={newMortgageObj.first_payment_date ?? null}
                                    onChange={(e) => updateNewMortgageInfo("first_payment_date", e as Date)}
                                    minDate={state.deal.dealInfo?.opened_date ?? undefined}
                                />
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <BasicDatePicker
                                label={{ text: "Maturity Date" }}
                                value={newMortgageObj.maturity_date ?? null}
                                onChange={(e) => updateNewMortgageInfo("maturity_date", e as Date)}
                                minDate={state.deal.dealInfo?.opened_date ?? undefined}
                            />  
                        </Grid>
                    </Grid>
                </Grid>
                {(newMortgageObj.taxes_paid_through_mortgage || newMortgageObj.regular_payments) && (
                    <Grid item xs={12}>
                        <Grid container rowSpacing={3} columnSpacing={5}>
                            {newMortgageObj.regular_payments && (
                                <Grid item xs={3}>
                                    <Dropdown
                                        label={{ text: "Payment Frequency" }}
                                        value={newMortgageObj.payment_frequency ?? "monthly"}
                                        onChange={(e) => updateNewMortgageInfo("payment_frequency", e.target.value as string)}
                                        placeholder="Select an option..."
                                        options={PaymentFrequencyOptions}
                                        formatValue={formatFrequency}
                                        isHoverActionHidden={newMortgageObj.payment_frequency ? false : true}
                                        handleClear={() => updateNewMortgageInfo("payment_frequency", undefined)}
                                    />
                                </Grid>
                            )}
                            {newMortgageObj.regular_payments && (
                                <Grid item xs={3}>
                                    <BasicTextInput
                                        fullWidth
                                        label={{ text: "Regular Payment", inputId: "regular_payment" }}
                                        inputProps={{ "aria-label": "Regular Payment" }}
                                        value={newMortgageObj.regular_payment !== undefined ? String(newMortgageObj.regular_payment) : undefined}
                                        // @ts-ignore
                                        onChange={(e) => updateNewMortgageInfo("regular_payment", e.target.value)}
                                        moneyField
                                        valueType="positive"
                                        placeholder="0.00"
                                    />
                                </Grid>
                            )}
                            {newMortgageObj.taxes_paid_through_mortgage && (
                                <Grid item xs={3}>
                                    <BasicTextInput
                                        fullWidth
                                        label={{ text: "Tax Portion", inputId: "tax_portion" }}
                                        inputProps={{ "aria-label": "Tax Portion" }}
                                        value={newMortgageObj.tax_portion !== undefined ? String(newMortgageObj.tax_portion) : undefined}
                                        // @ts-ignore
                                        onChange={(e) => updateNewMortgageInfo("tax_portion", e.target.value)}
                                        moneyField
                                        valueType="positive"
                                        placeholder="0.00"
                                    />
                                </Grid>
                            )}
                            {newMortgageObj.taxes_paid_through_mortgage && newMortgageObj.regular_payments && (
                                <Grid item xs={3}>
                                    <BasicTextInput
                                        fullWidth
                                        inputProps={{ "aria-label": "Total Payment" }}
                                        label={{ text: "Total Payment", inputId: "total_payment" }}
                                        value={(newMortgageObj.regular_payment ?? 0) + (newMortgageObj.tax_portion ?? 0)}
                                        moneyField
                                        valueType="positive"
                                        placeholder="0.00"
                                        readOnly
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Payment Due", inputId: "payment_due" }}
                        placeholder="Due instructions"
                        inputProps={{ "aria-label": "Payment Due" }}
                        value={newMortgageObj.payment_due}
                        onChange={(e) => updateNewMortgageInfo("payment_due", e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Dropdown
                        value={newMortgageObj.term_years ?? undefined}
                        label={{ text: "Term" }}
                        onChange={(e) => updateNewMortgageInfo("term_years", e.target.value as unknown as number)}
                        placeholder="Select term..."
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        formatValue={formatYears}
                        isHoverActionHidden={newMortgageObj.term_years ? false : true}
                        handleClear={() => updateNewMortgageInfo("term_years", undefined)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Dropdown
                        label={{ text: "Amortization Period" }}
                        value={newMortgageObj.amortization_period_years ?? undefined}
                        placeholder="Select period..."
                        onChange={(e) => updateNewMortgageInfo("amortization_period_years", e.target.value as unknown as number)}
                        options={[10, 15, 20, 25, 30, 35, 40]}
                        formatValue={formatYears}
                        isHoverActionHidden={newMortgageObj.amortization_period_years ? false : true}
                        handleClear={() => updateNewMortgageInfo("amortization_period_years", undefined)}
                    />
                </Grid>
            </Grid>
        </div>
    )
};

const StyledBody = styled(Body)({
    marginBottom: "-2rem"
})