// ALERTS
export const ALERTS_PREFIX = "ALERTS";
export const SET_ALERT_DATA = "ALERTS-SET_ALERT_DATA";
export const RESET_ALERT_DATA = "ALERTS-RESET_ALERT_DATA";

// DEAL
export const DEAL_PREFIX = "DEAL";
export const SET_DEAL_INFO = "DEAL-SET_DEAL_INFO";
export const SET_IS_LOADING = "DEAL-SET_IS_LOADING";
export const SET_IS_SAVING = "DEAL-SET_IS_SAVING";
export const SET_CURR_TAB = "DEAL-SET_CURR_TAB";
export const SET_DEAL_INFO_FIELD = "DEAL-SET_DEAL_INFO_FIELD";
export const SET_PAGE_URL = "DEAL-SET_PAGE_URL";
export const SET_DEAL_PROFESSIONAL = "DEAL-SET_DEAL_PROFESSIONAL";
export const SET_DEAL_PROFESSIONAL_FIRM = "DEAL-SET_DEAL_PROFESSIONAL_FIRM";
export const SET_DEAL_PROFESSIONAL_OFFICE = "DEAL-SET_DEAL_PROFESSIONAL_OFFICE";
export const UPDATE_DEAL_INFO_CLIENTS = "DEAL-UPDATE_DEAL_INFO_CLIENTS";
export const SET_CLERK_OPTIONS = "DEAL-SET_CLERK_OPTIONS";

// DOCUMENTS
export const DOCUMENTS_PREFIX = "DOCUMENTS";
export const SET_DOCUMENT_LIST = "DOCUMENTS-SET_DOCUMENT_LIST";
export const SET_SUGGESTED_DOCUMENT_LIST = "DOCUMENTS-SET_SUGGESTED_DOCUMENT_LIST";
export const SET_DOC_DISCOVERY_STATUS = "DOCUMENTS-SET_DOC_DISCOVERY_STATUS";
export const DELETE_DOCUMENT = "DOCUMENTS-DELETE_DOCUMENT";
export const UPDATE_DOCUMENT = "DOCUMENTS-UPDATE_DOCUMENT";
export const TOGGLE_FOLDER_OPEN = "DOCUMENTS-TOGGLE_FOLDER_OPEN";
export const ADD_TO_DOC_LIST = "DOCUMENTS-ADD_TO_DOC_LIST";
export const SET_HTML_IN_VIEWER_BODY = "DOCUMENTS-SET_HTML_IN_VIEWER_BODY";
export const SET_HTML_IN_VIEWER_FULL = "DOCUMENTS-SET_HTML_IN_VIEWER_FULL";
export const SET_PREVIEW_BEING_VIEWED = "DOCUMENTS-SET_PREVIEW_BEING_VIEWED";
export const SET_PDF_LINK = "DOCUMENTS-SET_PDF_LINK";
export const SET_DOC_IN_VIEWER_NAME = "DOCUMENTS-SET_DOC_IN_VIEWER_NAME";
export const SET_IS_DOCUMENT_PREPARING = "DOCUMENTS-SET_IS_DOCUMENT_PREPARING";
export const ADD_UPLOADED_DOC_LOADING = "DOCUMENTS-ADD_UPLOADED_DOC_LOADING";
export const REMOVE_UPLOADED_DOC_LOADING = "DOCUMENTS-REMOVE_UPLOADED_DOC_LOADING";
// DOCUMENT CONTEXT
export const SET_CLIENTS_CONTEXT = "DOCUMENTS-SET_CLIENTS_CONTEXT";
export const SET_MORTGAGE_CONTEXT = "DOCUMENTS-SET_MORTGAGE_CONTEXT";
export const SET_LIEN_CONTEXT = "DOCUMENTS-SET_LIEN_CONTEXT";
export const SET_SIGNING_APPOINTMENT_CONTEXT = "DOCUMENTS-SET_SIGNING_APPOINTMENT_CONTEXT";

// DATA SHEET
export const DATA_SHEET_PREFIX = "DATA_SHEET";
export const SET_OBJECT_LOADING = "DATA_SHEET-SET_OBJECT_LOADING";
export const SET_CURR_SECTION = "DATA_SHEET-SET_CURR_SECTION";
export const SET_CURR_SECTION_STATUS = "DATA_SHEET-SET_CURR_SECTION_STATUS";
export const SET_TAB_OPTIONS = "DATA_SHEET-SET_TAB_OPTIONS";
export const ADD_TAB_OPTION = "DATA_SHEET-ADD_TAB_OPTION";
export const REMOVE_TAB_OPTION = "DATA_SHEET-REMOVE_TAB_OPTION";
export const UPDATE_TAB_OPTION = "DATA_SHEET-UPDATE_TAB_OPTION";
export const UPDATE_TAB_OPTION_SUBTITLE = "DATA_SHEET-UPDATE_TAB_OPTION_SUBTITLE";
export const UPDATE_TAB_OPTION_TITLE = "DATA_SHEET-UPDATE_TAB_OPTION_TITLE";
export const UPDATE_TAB_OPTION_PRIORITY = "DATA_SHEET-UPDATE_TAB_OPTION_PRIORITY";
export const SET_CURR_ENTITY = "DATA_SHEET-SET_CURR_ENTITY";

// TRANSACTIONS
export const TRANSACTIONS_PREFIX = "TRANSACTIONS";
export const SET_TRANSACTION_LIST = "TRANSACTIONS-SET_TRANSACTION_LIST";
export const REMOVE_TRANSACTION = "TRANSACTIONS-REMOVE_TRANSACTION";
export const CREATE_MORTGAGE_TRANSACTION = "TRANSACTIONS-CREATE_MORTGAGE_TRANSACTION";
export const CREATE_ILA_TRANSACTION = "TRANSACTIONS-CREATE_ILA_TRANSACTION";
export const CREATE_TITLE_TRANSFER_TRANSACTION = "TRANSACTIONS-CREATE_TITLE_TRANSFER_TRANSACTION";
export const UPDATE_MORTGAGE_TRANSACTION_LABEL = "TRANSACTIONS-UPDATE_MORTGAGE_TRANSACTION_LABEL";
export const SET_TRANSACTION_BEING_EDITED = "TRANSACTIONS-SET_TRANSACTION_BEING_EDITED";

// ROLES
export const ROLES_PREFIX = "ROLES";
export const SET_ROLES = "ROLES-SET_ROLES";

// CLIENTS
export const CLIENTS_PREFIX = "CLIENTS";
export const SET_CLIENT_INFO = "CLIENTS-SET_CLIENT_INFO";
export const ADD_CLIENT_BEING_SAVED = "CLIENTS-ADD_CLIENT_BEING_SAVED";
export const REMOVE_CLIENT_BEING_SAVED = "CLIENTS-REMOVE_CLIENT_BEING_SAVED";
export const SET_IS_CLIENT_SAVING = "CLIENTS-SET_IS_CLIENT_SAVING";
export const SET_SHOULD_REFRESH_CLIENT = "CLIENTS-SET_SHOULD_REFRESH_CLIENT";
export const SET_CLIENT_SPOUSAL_RELATIONSHIP = "CLIENTS-SET_CLIENT_SPOUSAL_RELATIONSHIP";
export const SET_CLIENTS_IN_DEAL = "CLIENTS-SET_CLIENTS_IN_DEAL";
export const ADD_CLIENT_IN_DEAL = "CLIENTS-ADD_CLIENT_IN_DEAL";
export const UPDATE_CLIENT_IN_DEAL = "CLIENTS-UPDATE_CLIENT_IN_DEAL";
export const UPDATE_CLIENT_IN_DEAL_ROLES = "CLIENTS-UPDATE_CLIENT_IN_DEAL_ROLES";
export const ADD_CLIENT_POA_EOE_RELATIONSHIP = "CLIENTS-ADD_CLIENT_POA_EOE_RELATIONSHIP";
export const UPDATE_CLIENT_POA_EOE_RELATIONSHIP = "CLIENTS-UPDATE_CLIENT_POA_EOE_RELATIONSHIP";
export const DELETE_CLIENT_POA_EOE_RELATIONSHIP = "CLIENTS-DELETE_CLIENT_POA_EOE_RELATIONSHIP";
export const CHANGE_POA_EOE_RELATIONSHIP_REP = "CLIENTS-CHANGE_POA_EOE_RELATIONSHIP_REP";
export const UPDATE_POA_EOE_RELATIONSHIP_TYPES = "CLIENTS-UPDATE_POA_EOE_RELATIONSHIP_TYPES";
export const SET_CLIENT_ADDRESS_TO_COPY = "CLIENTS-SET_CLIENT_ADDRESS_TO_COPY";
export const SET_CLIENT_FINANCIAL_INFO_TO_COPY = "CLIENTS-SET_CLIENT_FINANCIAL_INFO_TO_COPY";

// PROPERTIES
export const PROPERTIES_PREFIX = "PROPERTIES";
export const SET_PROPERTY_INFO = "PROPERTIES-SET_PROPERTY_INFO";
export const SET_PROPERTIES_IN_DEAL = "PROPERTIES-SET_PROPERTIES_IN_DEAL";
export const ADD_PROPERTY_BEING_SAVED = "PROPERTIES-ADD_PROPERTY_BEING_SAVED";
export const REMOVE_PROPERTY_BEING_SAVED = "PROPERTIES-REMOVE_PROPERTY_BEING_SAVED";
export const SET_IS_PROPERTY_SAVING = "PROPERTIES-SET_IS_PROPERTY_SAVING";
export const SET_SHOULD_REFRESH_PROPERTY = "PROPERTIES-SET_SHOULD_REFRESH_PROPERTY";
export const ADD_PROPERTY_PIN = "PROPERTIES-ADD_PROPERTY_PIN";
export const REMOVE_PROPERTY_PIN = "PROPERTIES-REMOVE_PROPERTY_PIN";
export const ADD_PROPERTY_PIN_BEING_SAVED = "PROPERTIES-ADD_PROPERTY_PIN_BEING_SAVED";
export const REMOVE_PROPERTY_PIN_BEING_SAVED = "PROPERTIES-REMOVE_PROPERTY_PIN_BEING_SAVED";
export const SET_IS_PROPERTY_PIN_SAVING = "PROPERTIES-SET_IS_PROPERTY_PIN_SAVING";
export const SET_SHOULD_REFRESH_PROPERTY_PIN = "PROPERTIES-SET_SHOULD_REFRESH_PROPERTY_PIN";

// EXISTING LIENS
export const EXISTING_LIENS_PREFIX = "EXISTING_LIENS";
export const SET_LIEN_INFO = "EXISTING_LIENS-SET_LIEN_INFO";
export const SET_LIEN_LENDER_ID = "EXISTING_LIENS-SET_LIEN_LENDER_ID";
export const SET_LIEN_PRIORITY_AFTER_CLOSING = "EXISTING_LIENS-SET_LIEN_PRIORITY_AFTER_CLOSING";
export const SET_LIEN_PRIORITY_BEFORE_CLOSING = "EXISTING_LIENS-SET_LIEN_PRIORITY_BEFORE_CLOSING";
export const SET_LIENS_IN_DEAL = "EXISTING_LIENS-SET_LIENS_IN_DEAL";
export const ADD_LIEN_IN_DEAL = "EXISTING_LIENS-ADD_LIEN_IN_DEAL";
export const UPDATE_LIEN_IN_DEAL = "EXISTING_LIENS-UPDATE_LIEN_IN_DEAL";
export const SET_LIEN_COMPANY_ID = "EXISTING_LIENS-SET_LIEN_COMPANY_ID";
export const ADD_LIEN_BEING_SAVED = "EXISTING_LIENS-ADD_LIEN_BEING_SAVED";
export const REMOVE_LIEN_BEING_SAVED = "EXISTING_LIENS-REMOVE_LIEN_BEING_SAVED";
export const SET_IS_LIEN_SAVING = "EXISTING_LIENS-SET_IS_LIEN_SAVING";
export const SET_SHOULD_REFRESH_LIEN = "EXISTING_LIENS-SET_SHOULD_REFRESH_LIEN";
export const SET_LIEN_GOVERNMENT_ID = "EXISTING_LIENS-SET_LIEN_GOVERNMENT_ID";
export const SET_LIEN_OTHER_CHARGE_HOLDER_ID = "EXISTING_LIENS-SET_LIEN_OTHER_CHARGE_HOLDER_ID";
export const SET_CHARGE_HOLDER = "EXISTING_LIENS-SET_CHARGE_HOLDER";
export const REMOVE_LIEN_IN_DEAL = "EXISTING_LIENS-REMOVE_LIEN_IN_DEAL";
export const SET_LIEN_CONDITION = "EXISTING_LIENS-SET_LIEN_CONDITION";
export const DELETE_LIEN_UNDERTAKING_CONDITION = "EXISTING_LIENS-DELETE_LIEN_UNDERTAKING_CONDITION";
export const ADD_LIEN_ADDITIONAL_AMOUNT = "EXISTING_LIENS-ADD_LIEN_ADDITIONAL_AMOUNT";
export const REMOVE_LIEN_ADDITIONAL_AMOUNT = "EXISTING_LIENS-REMOVE_LIEN_ADDITIONAL_AMOUNT";
export const ADD_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED = "EXISTING_LIENS-ADD_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED";
export const REMOVE_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED = "EXISTING_LIENS-REMOVE_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED";

// NEW MORTGAGES
export const NEW_MORTGAGES_PREFIX = "NEW_MORTGAGES";
export const SET_MORTGAGE_TRANSACTION_INFO = "NEW_MORTGAGES-SET_MORTGAGE_TRANSACTION_INFO";
export const SET_MORTGAGE_LENDER_ID = "NEW_MORTGAGES-SET_MORTGAGE_LENDER_ID";
export const SET_MORTGAGE_BROKERAGE_ID = "NEW_MORTGAGES-SET_MORTGAGE_BROKERAGE_ID";
export const SET_MORTGAGE_BROKER_ID = "NEW_MORTGAGES-SET_MORTGAGE_BROKER_ID";
export const SET_MORTGAGE_PRIORITY = "NEW_MORTGAGES-SET_MORTGAGE_PRIORITY";
export const ADD_MORTGAGE_BEING_SAVED = "NEW_MORTGAGES-ADD_MORTGAGE_BEING_SAVED";
export const REMOVE_MORTGAGE_BEING_SAVED = "NEW_MORTGAGES-REMOVE_MORTGAGE_BEING_SAVED";
export const SET_IS_MORTGAGE_SAVING = "NEW_MORTGAGES-SET_IS_MORTGAGE_SAVING";
export const SET_SHOULD_REFRESH_MORTGAGE = "NEW_MORTGAGES-SET_SHOULD_REFRESH_MORTGAGE";
export const ADD_NEW_MORTGAGE_NET_ADVANCE = "NEW_MORTGAGES-ADD_NEW_MORTGAGE_NET_ADVANCE";
export const REMOVE_NEW_MORTGAGE_NET_ADVANCE = "NEW_MORTGAGES-REMOVE_NEW_MORTGAGE_NET_ADVANCE";
export const ADD_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED = "NEW_MORTGAGES-ADD_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED";
export const REMOVE_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED = "NEW_MORTGAGES-REMOVE_NEW_MORTGAGE_NET_ADVANCE_BEING_SAVED";
export const SET_IS_NEW_MORTGAGE_NET_ADVANCE_SAVING = "NEW_MORTGAGES-SET_IS_NEW_MORTGAGE_NET_ADVANCE_SAVING";
export const SET_SHOULD_REFRESH_NEW_MORTGAGE_NET_ADVANCE = "NEW_MORTGAGES-SET_SHOULD_REFRESH_NEW_MORTGAGE_NET_ADVANCE";

// GUARANTORS
export const GUARANTORS_PREFIX = "GUARANTORS";
export const SET_GUARANTORS = "GUARANTORS-SET_GUARANTORS";
export const ADD_NEW_GUARANTOR = "GUARANTORS-ADD_NEW_GUARANTOR";
export const REMOVE_GUARANTOR = "GUARANTORS-REMOVE_GUARANTOR";

// LENDERS
export const LENDERS_PREFIX = "LENDERS";
export const SET_LENDER_OPTION_LIST = "LENDERS-SET_LENDER_OPTION_LIST";
export const SET_SELECTED_LENDER = "LENDERS-SET_SELECTED_LENDER";
export const REPLACE_LENDER_OPTION_WITH_LENDER_RECORD = "LENDERS-REPLACE_LENDER_OPTION_WITH_LENDER_RECORD";
export const ADD_TO_LENDER_OPTIONS = "LENDERS-ADD_TO_LENDER_OPTIONS";
export const SET_EDITING_LENDER_RECORD = "LENDERS-SET_EDITING_LENDER_RECORD";
export const SET_LENDER_INFO = "LENDERS-SET_LENDER_INFO";
export const SET_LENDER_LAWYER = "LENDERS-SET_LENDER_LAWYER";
export const SET_LENDER_LAWYER_FIRM = "LENDERS-SET_LENDER_LAWYER_FIRM";
export const SET_LENDER_LAWYER_OFFICE = "LENDERS-SET_LENDER_LAWYER_OFFICE";
export const SET_LENDER_SOURCES = "LENDERS-SET_LENDER_SOURCES";

// LENDER CONTACTS
export const LENDER_CONTACTS_PREFIX = "LENDER_CONTACTS";
export const SET_LENDER_CONTACTS_OPTION_LIST = "LENDER_CONTACTS-SET_LENDER_CONTACTS_OPTION_LIST";
export const ADD_LENDER_CONTACT_TO_LIST = "LENDER_CONTACTS-ADD_LENDER_CONTACT_TO_LIST";
export const REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD = "LENDER_CONTACTS-REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD";
export const REPLACE_LENDER_CONTACT_WITH_UPDATED_RECORD = "LENDER_CONTACTS-REPLACE_LENDER_CONTACT_WITH_UPDATED_RECORD";
export const SET_LENDER_CONTACTS_LIST = "LENDER_CONTACTS-SET_LENDER_CONTACTS_LIST";
export const SET_LENDER_CONTACT_BEING_EDITED = "LENDER_CONTACTS-SET_LENDER_CONTACT_BEING_EDITED";
export const SET_LENDER_CONTACT_BEING_REPLACED = "LENDER_CONTACTS-SET_LENDER_CONTACT_BEING_REPLACED";
export const ADD_LENDER_CONTACT_OPTION = "LENDER_CONTACTS-ADD_LENDER_CONTACT_OPTION";
export const REMOVE_LENDER_CONTACT_FROM_LIST = "LENDER_CONTACTS-REMOVE_LENDER_CONTACT_FROM_LIST";
export const SET_EMPTY_LENDER_CONTACT_ADDED = "LENDER_CONTACTS-SET_EMPTY_LENDER_CONTACT_ADDED";
export const UPDATE_LENDER_CONTACT_ROLE = "LENDER_CONTACTS-UPDATE_LENDER_CONTACT_ROLE";

// MORTGAGE BROKERAGES
export const MORTGAGE_BROKERAGES_PREFIX = "MORTGAGE_BROKERAGES";
export const SET_MORTGAGE_BROKERAGES_OPTION_LIST = "MORTGAGE_BROKERAGES-SET_MORTGAGE_BROKERAGES_OPTION_LIST";
export const SET_SELECTED_MORTGAGE_BROKERAGE = "MORTGAGE_BROKERAGES-SET_SELECTED_MORTGAGE_BROKERAGE";
export const SET_MORTGAGE_BROKERAGE_INFO = "MORTGAGE_BROKERAGES-SET_MORTGAGE_BROKERAGE_INFO";
export const REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD = "MORTGAGE_BROKERAGES-REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD";
export const ADD_TO_MORTGAGE_BROKERAGE_OPTIONS = "MORTGAGE_BROKERAGES-ADD_TO_MORTGAGE_BROKERAGE_OPTIONS";
export const SET_EDITING_MORTGAGE_BROKERAGE_RECORD = "MORTGAGE_BROKERAGES-SET_EDITING_MORTGAGE_BROKERAGE_RECORD";

// MORTGAGE BROKERS
export const MORTGAGE_BROKERS_PREFIX = "MORTGAGE_BROKERS";
export const SET_MORTGAGE_BROKERS_OPTION_LIST = "MORTGAGE_BROKERS-SET_MORTGAGE_BROKERS_OPTION_LIST";
export const SET_SELECTED_MORTGAGE_BROKER = "MORTGAGE_BROKERS-SET_SELECTED_MORTGAGE_BROKER";
export const SET_MORTGAGE_BROKER_INFO = "MORTGAGE_BROKERS-SET_MORTGAGE_BROKER_INFO";
export const REPLACE_MORTGAGE_BROKER_OPTION_WITH_RECORD = "MORTGAGE_BROKERS-REPLACE_MORTGAGE_BROKER_OPTION_WITH_RECORD";
export const SET_EDITING_MORTGAGE_BROKER_RECORD = "MORTGAGE_BROKERS-SET_EDITING_MORTGAGE_BROKER_RECORD"
export const ADD_TO_MORTGAGE_BROKER_OPTIONS = "MORTGAGE_BROKERS-ADD_TO_MORTGAGE_BROKER_OPTIONS";

// MORTGAGE BROKERAGE OTHER CONTACTS
export const MORTGAGE_BROKERAGE_OTHER_CONTACTS_PREFIX = "MORTGAGE_BROKERAGE_OTHER_CONTACTS";
export const SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_EDITED = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_EDITED";
export const SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_DEAL = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_DEAL";
export const SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_MORTGAGE = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_MORTGAGE";
export const CREATE_MORTGAGE_BROKERAGE_OTHER_CONTACT = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-CREATE_MORTGAGE_BROKERAGE_OTHER_CONTACT";
export const UPDATE_MORTGAGE_BROKERAGE_OTHER_CONTACT = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-UPDATE_MORTGAGE_BROKERAGE_OTHER_CONTACT";
export const DELETE_MORTGAGE_BROKERAGE_OTHER_CONTACT = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-DELETE_MORTGAGE_BROKERAGE_OTHER_CONTACT";
export const REMOVE_MORTGAGE_BROKERAGE_OTHER_CONTACT = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-REMOVE_MORTGAGE_BROKERAGE_OTHER_CONTACT";
export const ADD_MORTGAGE_BROKERAGE_OTHER_CONTACT_TO_MORTGAGE = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-ADD_MORTGAGE_BROKERAGE_OTHER_CONTACT_TO_MORTGAGE";
export const CHANGE_MORTGAGE_BROKERAGE_OTHER_CONTACT_IN_MORTGAGE = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-CHANGE_MORTGAGE_BROKERAGE_OTHER_CONTACT_IN_MORTGAGE";
export const SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_REPLACED = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_REPLACED";
export const SET_MORTGAGES_WITH_MORTGAGE_BROKERAGE_OTHER_CONTACT = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_MORTGAGES_WITH_MORTGAGE_BROKERAGE_OTHER_CONTACT";
export const SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED = "MORTGAGE_BROKERAGE_OTHER_CONTACTS-SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED";

// PRIORITIES
export const PRIORITIES_PREFIX = "PRIORITIES";
export const SET_PRIORITIES_AFTER_CLOSING_IN_DEAL = "PRIORITIES-SET_PRIORITIES_AFTER_CLOSING_IN_DEAL";
export const SET_NEW_PRIORITY_AFTER_CLOSING = "PRIORITIES-SET_NEW_PRIORITY_AFTER_CLOSING";
export const SET_PRIORITIES_BEFORE_CLOSING_IN_DEAL = "PRIORITIES-SET_PRIORITIES_BEFORE_CLOSING_IN_DEAL";

// UNDERTAKINGS
export const UNDERTAKINGS_PREFIX = "UNDERTAKINGS";
export const SET_UNDERTAKING_BEING_EDITED = "UNDERTAKINGS-SET_UNDERTAKING_BEING_EDITED";
export const ADD_UNDERTAKING = "UNDERTAKINGS-ADD_UNDERTAKING";
export const SET_UNDERTAKINGS_IN_DEAL = "UNDERTAKINGS-SET_UNDERTAKINGS_IN_DEAL";
export const REMOVE_UNDERTAKING = "UNDERTAKINGS-REMOVE_UNDERTAKING";
export const UPDATE_UNDERTAKING = "UNDERTAKINGS-UPDATE_UNDERTAKING";

// CHARGE HOLDER COMPANIES
export const CHARGE_HOLDER_COMPANIES_PREFIX = "CHARGE_HOLDER_COMPANIES";
export const SET_CHARGE_HOLDER_COMPANY_OPTION_LIST = "CHARGE_HOLDER_COMPANIES-SET_CHARGE_HOLDER_COMPANY_OPTION_LIST";
export const SET_SELECTED_CHARGE_HOLDER_COMPANY = "CHARGE_HOLDER_COMPANIES-SET_SELECTED_CHARGE_HOLDER_COMPANY";
export const REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD = "CHARGE_HOLDER_COMPANIES-REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD";
export const ADD_TO_CHARGE_HOLDER_COMPANY_OPTIONS = "CHARGE_HOLDER_COMPANIES-ADD_TO_CHARGE_HOLDER_COMPANY_OPTIONS";
export const SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD = "CHARGE_HOLDER_COMPANIES-SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD";
export const SET_CHARGE_HOLDER_COMPANY_INFO = "CHARGE_HOLDER_COMPANIES-SET_CHARGE_HOLDER_COMPANY_INFO";

// GOVERNMENTS
export const GOVERNMENTS_PREFIX = "GOVERNMENTS";
export const SET_GOVERNMENT_OPTION_LIST = "GOVERNMENTS-SET_GOVERNMENT_OPTION_LIST";
export const SET_SELECTED_GOVERNMENT = "GOVERNMENTS-SET_SELECTED_GOVERNMENT";
export const REPLACE_GOVERNMENT_OPTION_WITH_RECORD = "GOVERNMENTS-REPLACE_GOVERNMENT_OPTION_WITH_RECORD";
export const ADD_TO_GOVERNMENT_OPTIONS = "GOVERNMENTS-ADD_TO_GOVERNMENT_OPTIONS";
export const SET_EDITING_GOVERNMENT_RECORD = "GOVERNMENTS-SET_EDITING_GOVERNMENT_RECORD";
export const SET_GOVERNMENT_INFO = "GOVERNMENTS-SET_GOVERNMENT_INFO";

// CHARGE HOLDER COMPANY CONTACTS
export const CHARGE_HOLDER_COMPANY_CONTACTS_PREFIX = "CHARGE_HOLDER_COMPANY_CONTACTS";
export const SET_CHARGE_HOLDER_COMPANY_CONTACTS_OPTION_LIST = "CHARGE_HOLDER_COMPANY_CONTACTS-SET_CHARGE_HOLDER_COMPANY_CONTACTS_OPTION_LIST";
export const ADD_CHARGE_HOLDER_COMPANY_CONTACT_TO_LIST = "CHARGE_HOLDER_COMPANY_CONTACTS-ADD_CHARGE_HOLDER_COMPANY_CONTACT_TO_LIST";
export const REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_OPTION_WITH_RECORD = "CHARGE_HOLDER_COMPANY_CONTACTS-REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_OPTION_WITH_RECORD";
export const REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_WITH_UPDATED_RECORD = "CHARGE_HOLDER_COMPANY_CONTACTS-REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_WITH_UPDATED_RECORD";
export const SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST = "CHARGE_HOLDER_COMPANY_CONTACTS-SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST";
export const SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED = "CHARGE_HOLDER_COMPANY_CONTACTS-SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED";
export const SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_REPLACED = "CHARGE_HOLDER_COMPANY_CONTACTS-SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_REPLACED";
export const ADD_CHARGE_HOLDER_COMPANY_CONTACT_OPTION = "CHARGE_HOLDER_COMPANY_CONTACTS-ADD_CHARGE_HOLDER_COMPANY_CONTACT_OPTION";
export const REMOVE_CHARGE_HOLDER_COMPANY_CONTACT_FROM_LIST = "CHARGE_HOLDER_COMPANY_CONTACTS-REMOVE_CHARGE_HOLDER_COMPANY_CONTACT_FROM_LIST";
export const SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED = "CHARGE_HOLDER_COMPANY_CONTACTS-SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED";
export const UPDATE_CHARGE_HOLDER_COMPANY_CONTACT_ROLE = "CHARGE_HOLDER_COMPANY_CONTACTS-UPDATE_CHARGE_HOLDER_COMPANY_CONTACT_ROLE";

// GOVERNMENT CONTACTS
export const GOVERNMENT_CONTACTS_PREFIX = "GOVERNMENT_CONTACTS";
export const SET_GOVERNMENT_CONTACTS_OPTION_LIST = "GOVERNMENT_CONTACTS-SET_GOVERNMENT_CONTACTS_OPTION_LIST";
export const ADD_GOVERNMENT_CONTACT_TO_LIST = "GOVERNMENT_CONTACTS-ADD_GOVERNMENT_CONTACT_TO_LIST";
export const REPLACE_GOVERNMENT_CONTACT_OPTION_WITH_RECORD = "GOVERNMENT_CONTACTS-REPLACE_GOVERNMENT_CONTACT_OPTION_WITH_RECORD";
export const REPLACE_GOVERNMENT_CONTACT_WITH_UPDATED_RECORD = "GOVERNMENT_CONTACTS-REPLACE_GOVERNMENT_CONTACT_WITH_UPDATED_RECORD";
export const SET_GOVERNMENT_CONTACTS_LIST = "GOVERNMENT_CONTACTS-SET_GOVERNMENT_CONTACTS_LIST";
export const SET_GOVERNMENT_CONTACT_BEING_EDITED = "GOVERNMENT_CONTACTS-SET_GOVERNMENT_CONTACT_BEING_EDITED";
export const SET_GOVERNMENT_CONTACT_BEING_REPLACED = "GOVERNMENT_CONTACTS-SET_GOVERNMENT_CONTACT_BEING_REPLACED";
export const ADD_GOVERNMENT_CONTACT_OPTION = "GOVERNMENT_CONTACTS-ADD_GOVERNMENT_CONTACT_OPTION";
export const REMOVE_GOVERNMENT_CONTACT_FROM_LIST = "GOVERNMENT_CONTACTS-REMOVE_GOVERNMENT_CONTACT_FROM_LIST";
export const SET_EMPTY_GOVERNMENT_CONTACT_ADDED = "GOVERNMENT_CONTACTS-SET_EMPTY_GOVERNMENT_CONTACT_ADDED";
export const UPDATE_GOVERNMENT_CONTACT_ROLE = "GOVERNMENT_CONTACTS-UPDATE_GOVERNMENT_CONTACT_ROLE";

// OTHER CHARGE HOLDERS
export const OTHER_CHARGE_HOLDER_PREFIX = "OTHER_CHARGE_HOLDERS";
export const SET_OTHER_CHARGE_HOLDER_OPTION_LIST = "OTHER_CHARGE_HOLDERS-SET_OTHER_CHARGE_HOLDER_OPTION_LIST";
export const SET_SELECTED_OTHER_CHARGE_HOLDER = "OTHER_CHARGE_HOLDERS-SET_SELECTED_OTHER_CHARGE_HOLDER";
export const REPLACE_OTHER_CHARGE_HOLDER_OPTION_WITH_RECORD = "OTHER_CHARGE_HOLDERS-REPLACE_OTHER_CHARGE_HOLDER_OPTION_WITH_RECORD";
export const ADD_TO_OTHER_CHARGE_HOLDER_OPTIONS = "OTHER_CHARGE_HOLDERS-ADD_TO_OTHER_CHARGE_HOLDER_OPTIONS";
export const SET_EDITING_OTHER_CHARGE_HOLDER_RECORD = "OTHER_CHARGE_HOLDERS-SET_EDITING_OTHER_CHARGE_HOLDER_RECORD";
export const SET_OTHER_CHARGE_HOLDER_INFO = "OTHER_CHARGE_HOLDERS-SET_OTHER_CHARGE_HOLDER_INFO";

// FEES
export const FEES_PREFIX = "FEES";
export const SET_FEES = "FEES-SET_FEES";
export const SET_CUSTOM_FEES = "FEES-SET_CUSTOM_FEES";
export const ADD_CUSTOM_FEE = "FEES-ADD_CUSTOM_FEE";
export const SET_EMPTY_FEE_ADDED = "FEES-SET_EMPTY_FEE_ADDED";
export const DELETE_FEE = "FEES-DELETE_FEE";

// DISBURSEMENTS
export const DISBURSEMENTS_PREFIX = "DISBURSEMENTS";
export const SET_DISBURSEMENTS = "DISBURSEMENTS-SET_DISBURSEMENTS";
export const ADD_CUSTOM_DISBURSEMENT = "DISBURSEMENTS-ADD_CUSTOM_DISBURSEMENT";
export const SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED = "DISBURSEMENTS-SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED";
export const SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED = "DISBURSEMENTS-SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED";
export const DELETE_DISBURSEMENT = "DISBURSEMENTS-DELETE_DISBURSEMENT";

// FIRE INSURANCE
export const FIRE_INSURANCE_PREFIX = "FIRE_INSURANCE";
export const SET_FIRE_INSURANCE_INFO = "FIRE_INSURANCE-SET_FIRE_INSURANCE_INFO";
export const SET_PROPERTIES_WITH_FIRE_INSURANCE = "FIRE_INSURANCE-SET_PROPERTIES_WITH_FIRE_INSURANCE";
export const CHANGE_PROPERTY_WITH_FIRE_INSURANCE = "FIRE_INSURANCE-CHANGE_PROPERTY_WITH_FIRE_INSURANCE";
export const ADD_FIRE_INSURANCE_BEING_SAVED = "FIRE_INSURANCE-ADD_FIRE_INSURANCE_BEING_SAVED";
export const REMOVE_FIRE_INSURANCE_BEING_SAVED = "FIRE_INSURANCE-REMOVE_FIRE_INSURANCE_BEING_SAVED";
export const SET_IS_FIRE_INSURANCE_SAVING = "FIRE_INSURANCE-SET_IS_FIRE_INSURANCE_SAVING";
export const SET_SHOULD_REFRESH_FIRE_INSURANCE = "FIRE_INSURANCE-SET_SHOULD_REFRESH_FIRE_INSURANCE";
export const SET_ADDING_FIRE_INSURANCE_FROM_PROPERTY_SECTION = "FIRE_INSURANCE-SET_ADDING_FIRE_INSURANCE_FROM_PROPERTY_SECTION";
export const SET_FIRE_INSURANCE_COMPANY_ID = "FIRE_INSURANCE-SET_FIRE_INSURANCE_COMPANY_ID";
export const SET_FIRE_INSURANCE_BROKERAGE_ID = "FIRE_INSURANCE-SET_FIRE_INSURANCE_BROKERAGE_ID";
export const SET_FIRE_INSURANCE_BROKER_ID = "FIRE_INSURANCE-SET_FIRE_INSURANCE_BROKER_ID";

// FIRE INSURANCE COMPANIES
export const FIRE_INSURANCE_COMPANIES_PREFIX = "FIRE_INSURANCE_COMPANIES";
export const SET_FIRE_INSURANCE_COMPANY_OPTION_LIST = "FIRE_INSURANCE_COMPANIES-SET_FIRE_INSURANCE_COMPANY_OPTION_LIST";
export const SET_SELECTED_FIRE_INSURANCE_COMPANY = "FIRE_INSURANCE_COMPANIES-SET_SELECTED_FIRE_INSURANCE_COMPANY";
export const REPLACE_FIRE_INSURANCE_COMPANY_OPTION_WITH_RECORD = "FIRE_INSURANCE_COMPANIES-REPLACE_FIRE_INSURANCE_COMPANY_OPTION_WITH_RECORD";
export const ADD_TO_FIRE_INSURANCE_COMPANY_OPTIONS = "FIRE_INSURANCE_COMPANIES-ADD_TO_FIRE_INSURANCE_COMPANY_OPTIONS";
export const SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD = "FIRE_INSURANCE_COMPANIES-SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD";
export const SET_FIRE_INSURANCE_COMPANY_INFO = "FIRE_INSURANCE_COMPANIES-SET_FIRE_INSURANCE_COMPANY_INFO";

// FIRE INSURANCE BROKERAGES
export const FIRE_INSURANCE_BROKERAGES_PREFIX = "FIRE_INSURANCE_BROKERAGES";
export const SET_FIRE_INSURANCE_BROKERAGE_OPTION_LIST = "FIRE_INSURANCE_BROKERAGES-SET_FIRE_INSURANCE_BROKERAGE_OPTION_LIST";
export const SET_SELECTED_FIRE_INSURANCE_BROKERAGE = "FIRE_INSURANCE_BROKERAGES-SET_SELECTED_FIRE_INSURANCE_BROKERAGE";
export const SET_FIRE_INSURANCE_BROKERAGE_INFO = "FIRE_INSURANCE_BROKERAGES-SET_FIRE_INSURANCE_BROKERAGE_INFO"
export const REPLACE_FIRE_INSURANCE_BROKERAGE_OPTION_WITH_RECORD = "FIRE_INSURANCE_BROKERAGES-REPLACE_FIRE_INSURANCE_BROKERAGE_OPTION_WITH_RECORD";
export const ADD_TO_FIRE_INSURANCE_BROKERAGE_OPTIONS = "FIRE_INSURANCE_BROKERAGES-ADD_TO_FIRE_INSURANCE_BROKERAGE_OPTIONS";
export const SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD = "FIRE_INSURANCE_BROKERAGES-SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD";

// FIRE INSURANCE BROKERS
export const FIRE_INSURANCE_BROKERS_PREFIX = "FIRE_INSURANCE_BROKERS";
export const SET_FIRE_INSURANCE_BROKERS_OPTION_LIST = "FIRE_INSURANCE_BROKERS-SET_FIRE_INSURANCE_BROKERS_OPTION_LIST";
export const SET_SELECTED_FIRE_INSURANCE_BROKER = "FIRE_INSURANCE_BROKERS-SET_SELECTED_FIRE_INSURANCE_BROKER";
export const SET_FIRE_INSURANCE_BROKER_INFO = "FIRE_INSURANCE_BROKERS-SET_FIRE_INSURANCE_BROKER_INFO";
export const REPLACE_FIRE_INSURANCE_BROKER_OPTION_WITH_RECORD = "FIRE_INSURANCE_BROKERS-REPLACE_FIRE_INSURANCE_BROKER_OPTION_WITH_RECORD";
export const SET_EDITING_FIRE_INSURANCE_BROKER_RECORD = "FIRE_INSURANCE_BROKERS-SET_EDITING_FIRE_INSURANCE_BROKER_RECORD"
export const ADD_TO_FIRE_INSURANCE_BROKER_OPTIONS = "FIRE_INSURANCE_BROKERS-ADD_TO_FIRE_INSURANCE_BROKER_OPTIONS";

// TITLE INSURANCE
export const TITLE_INSURANCE_PREFIX = "TITLE_INSURANCE";
export const SET_TITLE_INSURANCE_INFO = "TITLE_INSURANCE-SET_TITLE_INSURANCE_INFO";
export const REMOVE_TITLE_INSURANCE_BEING_SAVED = "TITLE_INSURANCE-REMOVE_TITLE_INSURANCE_BEING_SAVED";
export const ADD_TITLE_INSURANCE_BEING_SAVED = "TITLE_INSURANCE-ADD_TITLE_INSURANCE_BEING_SAVED";
export const SET_SHOULD_REFRESH_TITLE_INSURANCE = "TITLE_INSURANCE-SET_SHOULD_REFRESH_TITLE_INSURANCE";
export const SET_IS_TITLE_INSURANCE_SAVING = "TITLE_INSURANCE-SET_IS_TITLE_INSURANCE_SAVING";

// TITLE INSURANCE PREMIUMS
export const TITLE_INSURANCE_PREMIUMS_PREFIX = "TITLE_INSURANCE_PREMIUMS";
export const SET_TITLE_INSURANCE_PREMIUMS = "TITLE_INSURANCE_PREMIUMS-SET_TITLE_INSURANCE_PREMIUMS";
export const REMOVE_TITLE_INSURANCE_PREMIUM_BEING_SAVED = "TITLE_INSURANCE_PREMIUMS-REMOVE_TITLE_INSURANCE_PREMIUM_BEING_SAVED";
export const ADD_TITLE_INSURANCE_PREMIUM_BEING_SAVED = "TITLE_INSURANCE_PREMIUMS-ADD_TITLE_INSURANCE_PREMIUM_BEING_SAVED";
export const SET_SHOULD_REFRESH_TITLE_INSURANCE_PREMIUM = "TITLE_INSURANCE_PREMIUMS-SET_SHOULD_REFRESH_TITLE_INSURANCE_PREMIUM";
export const SET_IS_TITLE_INSURANCE_PREMIUM_SAVING = "TITLE_INSURANCE_PREMIUMS-SET_IS_TITLE_INSURANCE_PREMIUM_SAVING";
export const CREATE_TITLE_INSURANCE_PREMIUM = "TITLE_INSURANCE_PREMIUMS-CREATE_TITLE_INSURANCE_PREMIUM";
export const DELETE_TITLE_INSURANCE_PREMIUM = "TITLE_INSURANCE_PREMIUMS-DELETE_TITLE_INSURANCE_PREMIUM";

// AFFIDAVITS
export const AFFIDAVITS_PREFIX = "AFFIDAVITS";
export const SET_AFFIDAVIT_BEING_EDITED = "AFFIDAVITS-SET_AFFIDAVIT_BEING_EDITED";
export const ADD_AFFIDAVIT = "AFFIDAVITS-ADD_AFFIDAVIT";
export const SET_AFFIDAVITS_IN_DEAL = "AFFIDAVITS-SET_AFFIDAVITS_IN_DEAL";
export const REMOVE_AFFIDAVIT = "AFFIDAVITS-REMOVE_AFFIDAVIT";
export const UPDATE_AFFIDAVIT = "AFFIDAVITS-UPDATE_AFFIDAVIT";

// STATUTORY DECLARATIONS
export const DECLARATIONS_PREFIX = "DECLARATIONS";
export const SET_DECLARATION_BEING_EDITED = "DECLARATIONS-SET_DECLARATION_BEING_EDITED";
export const ADD_DECLARATION = "DECLARATIONS-ADD_DECLARATIONS";
export const SET_DECLARATIONS_IN_DEAL = "DECLARATIONS-SET_DECLARATIONS_IN_DEAL";
export const SET_GLOBAL_DECLARATIONS = "DECLARATIONS-SET_GLOBAL_DECLARATIONS";
export const REMOVE_DECLARATION = "DECLARATIONS-REMOVE_DECLARATION";
export const UPDATE_DECLARATION = "DECLARATIONS-UPDATE_DECLARATION";

// DEBTS
export const DEBTS_PREFIX = "DEBTS";
export const SET_DEBT_BEING_EDITED = "DEBTS-SET_DEBT_BEING_EDITED";
export const ADD_DEBT = "DEBTS-ADD_DEBT";
export const SET_DEBTS_IN_DEAL = "DEBTS-SET_DEBTS_IN_DEAL";
export const REMOVE_DEBT = "DEBTS-REMOVE_DEBT";
export const UPDATE_DEBT = "DEBTS-UPDATE_DEBT";
export const SET_DEBT_HOLDER = "DEBTS-SET_DEBT_HOLDER";

// TRUST LEDGERS
export const TRUST_LEDGER_PREFIX = "TRUST_LEDGER";
export const SET_TRUST_LEDGERS = "TRUST_LEDGER-SET_TRUST_LEDGERS";
export const ADD_CUSTOM_TRUST_LEDGER = "TRUST_LEDGER-ADD_CUSTOM_TRUST_LEDGER";
export const DELETE_CUSTOM_TRUST_LEDGER = "TRUST_LEDGER-DELETE_CUSTOM_TRUST_LEDGER";
export const REMOVE_TRUST_LEDGER_BEING_SAVED = "TRUST_LEDGER-REMOVE_TRUST_LEDGER_BEING_SAVED";
export const ADD_TRUST_LEDGER_BEING_SAVED = "TRUST_LEDGER-ADD_TRUST_LEDGER_BEING_SAVED";
export const SET_IS_TRUST_LEDGER_SAVING = "TRUST_LEDGER-IS_TRUST_LEDGER_SAVING";
export const SET_SHOULD_REFRESH_TRUST_LEDGER = "TRUST_LEDGER-SHOULD_REFRESH_TRUST_LEDGER";

// TRUST LEDGER PAYMENT GROUPS
export const TRUST_LEDGER_PAYMENT_GROUP_PREFIX = "TRUST_LEDGER_PAYMENT_GROUPS";
export const SET_PAYMENT_GROUPS = "TRUST_LEDGER_PAYMENT_GROUPS-SET_PAYMENT_GROUPS";

// CONDO CORPORATIONS
export const CONDO_CORPORATIONS_PREFIEX = "CONDO_CORPORATIONS";
export const SET_CONDO_CORPORATION_OPTION_LIST = "CONDO_CORPORATIONS-SET_CONDO_CORPORATION_OPTION_LIST";
export const SET_SELECTED_CONDO_CORPORATION = "CONDO_CORPORATIONS-SET_SELECTED_CONDO_CORPORATION";
export const REPLACE_CONDO_CORPORATION_OPTION_WITH_RECORD = "CONDO_CORPORATIONS-REPLACE_CONDO_CORPORATION_OPTION_WITH_RECORD";
export const SET_EDITING_CONDO_CORPORATION = "CONDO_CORPORATIONS-SET_EDITING_CONDO_CORPORATION";
export const ADD_TO_CONDO_CORPORATION_OPTIONS = "CONDO_CORPORATIONS-ADD_TO_CONDO_CORPORATION_OPTIONS";
export const SET_CONDO_CORPORATION_INFO = "CONDO_CORPORATIONS-SET_CONDO_CORPORATION_INFO";

// PROPERTY MANAGEMENT COMPANIES
export const PROPERTY_MANAGEMENT_COMPANIES_PREFIX = "PROPERTY_MANAGEMENT_COMPANIES";
export const SET_PROPERTY_MANAGEMENT_COMPANY_OPTION_LIST = "PROPERTY_MANAGEMENT_COMPANIES-SET_PROPERTY_MANAGEMENT_COMPANY_OPTION_LIST";
export const SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY = "PROPERTY_MANAGEMENT_COMPANIES-SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY";
export const SET_PROPERTY_MANAGEMENT_COMPANY_BEING_EDITED = "PROPERTY_MANAGEMENT_COMPANIES-SET_PROPERTY_MANAGEMENT_COMPANY_BEING_EDITED";
export const ADD_TO_PROPERTY_MANAGEMENT_COMPANY_OPTIONS = "PROPERTY_MANAGEMENT_COMPANIES-ADD_TO_PROPERTY_MANAGEMENT_COMPANY_OPTIONS";
export const REPLACE_PROPERTY_MANAGEMENT_COMPANY_OPTION_WITH_RECORD = "PROPERTY_MANAGEMENT_COMPANIES-REPLACE_PROPERTY_MANAGEMENT_COMPANY_OPTION_WITH_RECORD";
export const SET_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS = "PROPERTY_MANAGEMENT_COMPANIES-SET_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS";
export const ADD_TO_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS = "PROPERTY_MANAGEMENT_COMPANIES-ADD_TO_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS";

// CONDITIONS
export const CONDITIONS_PREFIX = "CONDITIONS";
export const SET_CONDITION_BEING_EDITED = "CONDITIONS-SET_CONDITION_BEING_EDITED";
export const ADD_CONDITION = "CONDITIONS-ADD_CONDITION";
export const SET_CONDITIONS_IN_DEAL = "CONDITIONS-SET_CONDITIONS_IN_DEAL";
export const REMOVE_CONDITION = "CONDITIONS-REMOVE_CONDITION";
export const UPDATE_CONDITION = "CONDITIONS-UPDATE_CONDITION";
export const SET_CONDITION_LIEN = "CONDITIONS-SET_CONDITION_LIEN";
export const SET_CONDITION_UNDERTAKING = "CONDITIONS-SET_CONDITION_UNDERTAKING";
export const SET_CONDITION_DEBT = "CONDITIONS-SET_CONDITION-DEBT";
export const SET_CONDITION_STAT_DEC = "CONDITIONS-SET_CONDITION_STAT_DEC";
export const SET_CONDITION_AFFIDAVIT = "CONDITIONS-SET_CONDITION_AFFIDAVIT";

// SIGNING APPOINTMENTS
export const SIGNING_APPOINTMENTS_PREFIX = "SIGNING_APPOINTMENTS";
export const ADD_SIGNING_APPOINTMENT = "SIGNING_APPOINTMENTS-ADD_SIGNING_APPOINTMENT";
export const SET_SIGNING_APPOINTMENTS_IN_DEAL = "SIGNING_APPOINTMENTS-SET_SIGNING_APPOINTMENTS_IN_DEAL";
export const REMOVE_SIGNING_APPOINTMENT = "SIGNING_APPOINTMENTS-REMOVE_SIGNING_APPOINTMENT";
export const UPDATE_SIGNING_APPOINTMENT_CLIENTS = "SIGNING_APPOINTMENTS-UPDATE_SIGNING_APPOINTMENT_CLIENTS";
export const REMOVE_SIGNING_APPOINTMENT_BEING_SAVED = "SIGNING_APPOINTMENTS-REMOVE_SIGNING_APPOINTMENT_BEING_SAVED";
export const ADD_SIGNING_APPOINTMENT_BEING_SAVED = "SIGNING_APPOINTMENTS-ADD_SIGNING_APPOINTMENT_BEING_SAVED";
export const SET_IS_SIGNING_APPOINTMENT_SAVING = "SIGNING_APPOINTMENTS-IS_SIGNING_APPOINTMENT_SAVING";
export const SET_SHOULD_REFRESH_SIGNING_APPOINTMENT = "SIGNING_APPOINTMENTS-SHOULD_REFRESH_SIGNING_APPOINTMENT";

// LEGAL PROFESSIONALS
export const LEGAL_PROFESSIONALS_PREFIX = "LEGAL_PROFESSIONALS";
export const SET_LEGAL_PROFESSIONAL_OPTION_LIST = "LEGAL_PROFESSIONALS-SET_LEGAL_PROFESSIONAL_OPTION_LIST";
export const REPLACE_LEGAL_PROFESSIONAL_OPTION_WITH_RECORD = "LEGAL_PROFESSIONALS-REPLACE_LEGAL_PROFESSIONAL_OPTION_WITH_RECORD";
export const SET_LEGAL_PROFESSIONAL_BEING_EDITED = "LEGAL_PROFESSIONALS-SET_LEGAL_PROFESSIONAL_BEING_EDITED";
export const ADD_TO_LEGAL_PROFESSIONAL_OPTIONS = "LEGAL_PROFESSIONALS-ADD_TO_LEGAL_PROFESSIONAL_OPTIONS";
export const UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST = "LEGAL_PROFESSIONALS-UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST";
export const UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST = "LEGAL_PROFESSIONALS-UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST";

// LEGAL FIRMS
export const LEGAL_FIRMS_PREFIX = "LEGAL_FIRMS";
export const SET_LAWYER_FIRM_OPTION_LIST = "LEGAL_FIRMS-SET_LAWYER_FIRM_OPTION_LIST";
export const SET_SIGNER_FIRM_OPTION_LIST = "LEGAL_FIRMS-SET_SIGNER_FIRM_OPTION_LIST";
export const SET_LENDER_LAWYER_FIRM_OPTION_LIST = "LEGAL_FIRMS-SET_LENDER_LAWYER_FIRM_OPTION_LIST";
export const REPLACE_LEGAL_FIRM_OPTION_WITH_RECORD = "LEGAL_FIRMS-REPLACE_LEGAL_FIRM_OPTION_WITH_RECORD";
export const SET_LEGAL_FIRM_BEING_EDITED = "LEGAL_FIRMS-SET_LEGAL_FIRM_BEING_EDITED";
export const ADD_TO_PROFESSIONAL_LEGAL_FIRM_OPTIONS = "LEGAL_FIRMS-ADD_TO_PROFESSIONAL_LEGAL_FIRM_OPTIONS";
export const SET_ALL_LEGAL_FIRM_OPTIONS = "LEGAL_FIRMS-SET_ALL_LEGAL_FIRM_OPTIONS";

// LEGAL FIRM OFFICES
export const LEGAL_FIRM_OFFICES_PREFIX = "LEGAL_FIRM_OFFICES";
export const SET_SIGNER_OFFICE_OPTION_LIST = "LEGAL_FIRM_OFFICES-SET_SIGNER_OFFICE_OPTION_LIST";
export const SET_LAWYER_OFFICE_OPTION_LIST = "LEGAL_FIRM_OFFICES-SET_LAWYER_OFFICE_OPTION_LIST";
export const SET_LENDER_LAWYER_OFFICE_OPTION_LIST = "LEGAL_FIRM_OFFICES-SET_LENDER_LAWYER_OFFICE_OPTION_LIST";
export const SET_LEGAL_FIRM_OFFICE_BEING_EDITED = "LEGAL_FIRM_OFFICES-SET_LEGAL_FIRM_OFFICE_BEING_EDITED";
export const REPLACE_LEGAL_FIRM_OFFICE_OPTION_WITH_RECORD = "LEGAL_FIRM_OFFICES-REPLACE_LEGAL_FIRM_OFFICE_OPTION_WITH_RECORD";
export const ADD_TO_LEGAL_FIRM_OFFICE_OPTIONS = "LEGAL_FIRM_OFFICES-ADD_TO_LEGAL_FIRM_OFFICE_OPTIONS";
export const SET_OFFICES_FOR_FIRM_TO_LINK = "LEGAL_FIRM_OFFICES-SET_OFFICES_FOR_FIRM_TO_LINK";

// REGIONS
export const REGIONS_PREFIX = "REGIONS";
export const SET_REGIONS = "REGIONS-SET_REGIONS";

// NOTES
export const NOTES_PREFIX = "NOTES";
export const SET_NOTES_IN_DEAL = "NOTES-SET_NOTES_IN_DEAL";
export const DELETE_NOTE = "NOTES-DELETE_NOTE";
export const UPDATE_NOTE = "NOTES-UPDATE_NOTE";

// USER
export const USER_PREFIX = "USER";
export const SET_USER = "USER-SET_USER";