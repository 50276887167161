import { FireInsurance } from "../../../libs/types/UniversalSurvey/FireInsurance/fireInsurance";
import * as actions from "../actionTypes";

export type FireInsuranceState = {
    fireInsuranceInfo: FireInsurance | undefined;
    propertiesWithFireInsurance: number[];
    fireInsurancesBeingSaved: number[];
    isFireInsuranceSaving: boolean;
    shouldRefreshFireInsurance: boolean;
    addingFireInsuranceFromPropertySection: boolean;
}

export const defaultFireInsuranceState: FireInsuranceState = {
    fireInsuranceInfo: undefined,
    propertiesWithFireInsurance: [],
    fireInsurancesBeingSaved: [],
    isFireInsuranceSaving: false,
    shouldRefreshFireInsurance: false,
    addingFireInsuranceFromPropertySection: false
}

export function fireInsuranceReducer(state: FireInsuranceState, action: Record<string, any>): FireInsuranceState {
    switch (action.type) {
        case actions.SET_FIRE_INSURANCE_INFO:
            return { ...state, fireInsuranceInfo: action.payload };
        case actions.SET_FIRE_INSURANCE_COMPANY_ID:
            if (state.fireInsuranceInfo) {
                return {
                    ...state,
                    fireInsuranceInfo: {
                        ...state.fireInsuranceInfo,
                        fire_insurance_company_id: action.payload
                    }
                };
            }
            return state;
        case actions.SET_FIRE_INSURANCE_BROKER_ID:
            if(state.fireInsuranceInfo) {
                return {
                    ...state,
                    fireInsuranceInfo: {
                        ...state.fireInsuranceInfo,
                        fire_insurance_broker_id: action.payload
                    }
                };
            }
            return state;
        case actions.SET_PROPERTIES_WITH_FIRE_INSURANCE:
            return { ...state, propertiesWithFireInsurance: action.payload };
        case actions.CHANGE_PROPERTY_WITH_FIRE_INSURANCE:
            const tempPropertiesWithFireInsurance = [...state.propertiesWithFireInsurance];
            if (action.payload.newId !== undefined) {
                tempPropertiesWithFireInsurance.push(action.payload.newId);
            }
            if (tempPropertiesWithFireInsurance.includes(action.payload.oldId)) {
                tempPropertiesWithFireInsurance.splice(state.propertiesWithFireInsurance.indexOf(action.payload.oldId), 1)
            }
            return { ...state, propertiesWithFireInsurance: tempPropertiesWithFireInsurance}
        case actions.ADD_FIRE_INSURANCE_BEING_SAVED:
            if (!state.fireInsurancesBeingSaved.includes(action.payload)) {
                return { ...state, fireInsurancesBeingSaved: [...state.fireInsurancesBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_FIRE_INSURANCE_BEING_SAVED:
            let isFireInsuranceSaving = state.isFireInsuranceSaving;
            let shouldRefreshFireInsurance = state.shouldRefreshFireInsurance;
            if(state.fireInsuranceInfo?.id === action.payload && isFireInsuranceSaving) {
                isFireInsuranceSaving = false;
                shouldRefreshFireInsurance = true;
            }
            const tempFireInsurancessBeingSaved = [...state.fireInsurancesBeingSaved];
            const index = tempFireInsurancessBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempFireInsurancessBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                fireInsurancesBeingSaved: tempFireInsurancessBeingSaved,
                isFireInsuranceSaving: isFireInsuranceSaving,
                shouldRefreshFireInsurance: shouldRefreshFireInsurance
            };
        case actions.SET_IS_FIRE_INSURANCE_SAVING:
            return { ...state, isFireInsuranceSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_FIRE_INSURANCE:
            return { ...state, shouldRefreshFireInsurance: action.payload };
        case actions.SET_ADDING_FIRE_INSURANCE_FROM_PROPERTY_SECTION:
            return { ...state, addingFireInsuranceFromPropertySection: action.payload }
        case actions.SET_FIRE_INSURANCE_BROKERAGE_ID:
            if (state.fireInsuranceInfo) {
                return {
                    ...state,
                    fireInsuranceInfo: {
                        ...state.fireInsuranceInfo,
                        fire_insurance_brokerage_id: action.payload
                    }
                };
            }
            return state;
        default:
            return state;
    }
}