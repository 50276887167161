import { Document } from "../baseDocument";
import { UndertakingContextRequest } from "../documents/undertakingDocument";
import { formatAddressedTo } from "../../../../utils/formatValues";
import { AddressedTo } from "../../../../resources/enums/addressedTo";
import { Transaction } from "../../Transaction/transaction";
import { UndertakingDocumentType } from "../../../../resources/enums/documents/undertaking";
import { TransactionTypes } from "../../../../resources/enums/transactions";
import Decimal from "decimal.js";

const contextValues = {
    "Blank": -1
};

function labelUndertakingDocContextOption(transactionsInDeal: Transaction[], contextId: number): string {
    for (const key of Object.keys(contextValues)) {
        if (contextValues[key as keyof typeof contextValues] === contextId) {
            return key;
        }
    }
    
    let transaction: Transaction | undefined;
    const dec = Decimal.mod(contextId, 1).toNumber();
    const mortgageId = Decimal.sub(contextId, dec).toNumber();

    transaction = transactionsInDeal.find((item) => item.id === mortgageId && item.transaction_type === TransactionTypes.Mortgage);
    let addressedTo: AddressedTo = getAddressedTo(dec);

    if (transaction) {
        return `${transaction.label} - ${formatAddressedTo(addressedTo)}`;
    }
    return "";
}

function createUndertakingDocName(addressedTo: AddressedTo, mortgage: Transaction | undefined, type: UndertakingDocumentType | undefined): string {
    if (type === UndertakingDocumentType.Blank) {
        return `Undertaking - Blank`
    }
    if (addressedTo === AddressedTo.Lender) {
        if (mortgage) {
            let lenderName = mortgage.lenderAbbrName ? mortgage.lenderAbbrName : mortgage.lenderName ? mortgage.lenderName : undefined;
            if (lenderName) return `Undertaking - ${lenderName}`;
        }
        return `Undertaking - Lender`
    } else {
        // TODO: update this once other side lawyer has been implemented
        return `Undertaking - ${formatAddressedTo(addressedTo)}`
    }
}

function createUndertakingContextRequest(addressedTo: AddressedTo, mortgageId: number | undefined, type: UndertakingDocumentType): UndertakingContextRequest {
    return {
        addressed_to: addressedTo,
        mortgage_id: mortgageId ?? null,
        type: type
    };
}

function getUndertakingDocData(contextId: number, transactionsInDeal: Transaction[]): [Object, string] {
    if (contextId === -1) {
        return [createUndertakingContextRequest(AddressedTo.Lender, undefined, UndertakingDocumentType.Blank), createUndertakingDocName(AddressedTo.Lender, undefined, UndertakingDocumentType.Blank)]
    }

    let transaction: Transaction | undefined;
    const dec = Decimal.mod(contextId, 1).toNumber();
    const mortgageId = Decimal.sub(contextId, dec).toNumber();

    transaction = transactionsInDeal.find((item) => item.id === mortgageId && item.transaction_type === TransactionTypes.Mortgage);
    let addressedTo: AddressedTo = getAddressedTo(dec);

    return [createUndertakingContextRequest(addressedTo, mortgageId, UndertakingDocumentType.Mortgage), createUndertakingDocName(addressedTo, transaction, UndertakingDocumentType.Mortgage)]
}

function getUndertakingContextOptions(docList: Document[], transactions: Transaction[]) {
    const options: number[] = [];
    for (const value of Object.values(contextValues)) {
        options.push(value);
    }
    for (const transaction of transactions) {
        if (transaction.transaction_type === TransactionTypes.Mortgage) {
            options.push(transaction.id); // (Lender) whole number
            options.push(transaction.id + 0.5); // (Law Firm) 0.5
        }
    }
    return options;
}

function getAddressedTo(decimalValue: number): AddressedTo {
    switch (decimalValue) {
        case 0:
            return AddressedTo.Lender;
        case 0.5:
            return AddressedTo.LawFirm;
        default:
            return AddressedTo.Lender;
    }
}

export {
    labelUndertakingDocContextOption,
    getUndertakingDocData,
    getUndertakingContextOptions
}