import * as actions from "../actionTypes"

import { Role } from "../../../libs/types/UniversalSurvey/Roles/role";


export type RolesState = {
    roles: Role[];
}

export const defaultRolesState: RolesState = {
    roles: []
}

export function rolesReducer(state: RolesState, action: Record<string, any>): RolesState {
    switch (action.type) {
        case actions.SET_ROLES:
            return { ...state, roles: action.payload };
        default:
            return state;
    }
}