export function convertToSimple<
    Input, Output extends Object
>(inputObj: Input, defaultOutputObj: Output): Output {
    let k: keyof Input;
    const cleanObj = { ...defaultOutputObj };

    for (k in inputObj) {
        if (cleanObj.hasOwnProperty(k)) {
            cleanObj[k as keyof Output] = inputObj[k] !== undefined ? inputObj[k] as any : undefined;
        }
    }
    return cleanObj;
}