import { Dispatch } from "react";

import * as actions from "../../actionTypes"
import {
    newTitleTransferTransaction,
    deleteTitleTransferTransaction
} from '../../../../libs/service/axios/api';
import { Transaction } from "../../../../libs/types/UniversalSurvey/Transaction/transaction";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";
import { TransactionMappingPills } from "../../../../libs/resources/mappings/transactions";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { updateDealTransactionData } from "../deal";

function addTitleTransferTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, numTransactions: number, setPrimary?: boolean) {
    newTitleTransferTransaction(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.CREATE_TITLE_TRANSFER_TRANSACTION, payload: response.data.id });
            if (numTransactions === 0 || setPrimary) {
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_id", value: response.data.id } });
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_type", value: TransactionTypes.TitleTransfer } });
            }
            if (setPrimary) {
                updateDealTransactionData(dispatch, dealId, response.data.id, TransactionTypes.TitleTransfer);
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New title transfer transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function removeTitleTransferTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number) {
    deleteTitleTransferTransaction(dealId, String(transactionId))
        .then(function () {
            dispatch({ type: actions.REMOVE_TRANSACTION, payload: { id: transactionId, transaction_type: TransactionTypes.TitleTransfer } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete title transfer transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function sortAndLabelTitleTransferTransactions(titleTransfers: Transaction[]) {
    titleTransfers.sort((a, b) => a.id > b.id ? 1 : -1);
    for (let i = 0; i < titleTransfers.length; i++) {
        titleTransfers[i].label = TransactionMappingPills[titleTransfers[i].transaction_type as keyof typeof TransactionMappingPills];
        if (titleTransfers.length > 1) {
            titleTransfers[i].label += ` ${i + 1}`;
        }
    }
}

export {
    addTitleTransferTransaction,
    removeTitleTransferTransaction,
    sortAndLabelTitleTransferTransactions
}