import { useContext, useState } from "react";
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

// Importing MUI components
import { styled, Button, Stack } from '@mui/material';

// Importing from DCP UI Library
import { BodyBold, Body, Small } from '../../../../../components/Common/Typography/index';
import theme from '../../../../../components/Common/componentStyling/Theme';
import color from '../../../../../components/Common/componentStyling/Colors';
import { BORDER_2, BORDER_RADIUS_ACTION_W_ICON } from "../../../../../components/Common/componentStyling/Styles";
import CounterPill from "../../../../../components/Common/CounterPill/CounterPill";
import { navigateURL } from "../../../../context/UniversalSurvey/asyncActions/deal";
import { XCircle } from "../../../../../components/Common/Icons/Iconography";

type SectionTabButtonProps = {
    isActive: boolean,
    subTitle?: string,
    missingFields: number,
    itemId: number,
    handleRemove?: (entityId: number, isActive: boolean) => void;
    testId?: string
};

const SectionTabButton: React.FC<SectionTabButtonProps> = ({ children, ...props }) => {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [buttonHovered, setButtonHovered] = useState<boolean>(false);
    const [removeHovered, setRemoveHovered] = useState<boolean>(false);

    function handleTabButtonClick() {
        if(props.itemId !== state.dataSheet.currEntity) {
            navigateURL(dispatch, String(state.deal.dealInfo?.id), "data-sheet", String(state.dataSheet.currSection), String(props.itemId));
        }
    }

    return (
        <StyledButton
            disableRipple
            disableFocusRipple
            hasActive={props.isActive}
            isRemoveHovered={removeHovered}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            data-testid={props.testId}
        >
            <Stack direction="row" gap={1} alignItems="center">
                <Stack direction="column" gap={0.4} alignItems="center" onClick={handleTabButtonClick}>
                    <TitleContainer direction="row" gap={1} alignItems="flex-end">
                        {props.isActive ? (
                            <BodyBold style={{ color: "inherit" }} data-testid="button-title">
                                {children}
                            </BodyBold>
                        ) : (
                            <Body style={{ color: "inherit" }} data-testid="button-title">
                                {children}
                            </Body>
                        )}
                        {props.missingFields > 0 ? (
                            <CounterPill count={props.missingFields} />
                        ) : null}
                    </TitleContainer>
                    <Small data-testid="button-sub-title" style={{ color: "inherit" }}>
                        {props.subTitle}
                    </Small>
                </Stack>
                {(buttonHovered && props.handleRemove) && (
                    <RemoveContainer
                        onMouseEnter={() => setRemoveHovered(true)}
                        onMouseLeave={() => setRemoveHovered(false)}
                        onClick={() => props.handleRemove!(props.itemId, props.isActive)}
                    >
                        <XCircle color={ removeHovered ? color.WHITE : color.BLACK } />
                    </RemoveContainer>
                )}
            </Stack>
        </StyledButton>
    );
}

const StyledButton = styled(Button, { shouldForwardProp: 
    (prop) => prop !== "hasActive" && prop !== "isRemoveHovered"
})<{
    hasActive: boolean;
    isRemoveHovered: boolean;
}>(({ hasActive, isRemoveHovered }) => ({
    padding: "0.5rem 0.3rem 0.5rem 0.3rem",
    boxSizing: 'border-box',
    textTransform: 'none',
    width: 'fit-content',
    transform: 'none',
    borderRadius: isRemoveHovered ? BORDER_RADIUS_ACTION_W_ICON : hasActive ? "0rem" : BORDER_RADIUS_ACTION_W_ICON,
    color: isRemoveHovered ? color.WHITE : color.BLACK,
    borderBottom: hasActive ? BORDER_2(color.RED_500) : "none",
    backgroundColor: hasActive ? "transparent" : "none",
    '&:hover': {
        backgroundColor: isRemoveHovered ? theme.ERROR : hasActive ? "transparent" : theme.HOVER,
    }
}));

const TitleContainer = styled(Stack)({
    height: "3.1rem"
})

const RemoveContainer = styled('div')({
    marginTop: "0.5rem"
});

export default SectionTabButton;