import { VerificationOfIdentityAgreementContext, VerificationOfIdentityAgreementContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/verificationOfIdentityAgreementDocument";
import { defaultSimpleSigningAppointment } from "../../frontend/defaultSimpleSigningAppointment";


export const defaultVerificationOfIdentityAgreementContext: VerificationOfIdentityAgreementContext = {
    signing_appointment_id: -1,

    signing_appointment_record: defaultSimpleSigningAppointment
}

export const defaultVerificationOfIdentityAgreementContextRequest: VerificationOfIdentityAgreementContextRequest = {
    signing_appointment_id: -1
}