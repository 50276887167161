import React, { useState } from "react";

import { styled } from "@mui/material";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import colors from "../componentStyling/Colors";
import theme from "../componentStyling/Theme";
import { Small } from "../Typography/index";
import {
    CaretLeft,
    CaretRight,
    StartIcon,
    EndIcon,
    ChevronDown
} from "../Icons/Iconography";
import { BORDER_1, BORDER_2, SMALL_TEXT } from "../componentStyling/Styles";

export type TablePaginationProps = {
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    rowsPerPageOptions: number[];
    totalRows: number;
}

type PaginationBarProps = {
    searchValue: string;
    paginationProps: TablePaginationProps;
}

export default function PaginationBar(props: PaginationBarProps) {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const { rowsPerPage, setRowsPerPage, pageNumber, setPageNumber, rowsPerPageOptions, totalRows } = props.paginationProps;

    function getPageStart(): number {
        return (pageNumber - 1) * rowsPerPage + 1;
    }

    function getPageEnd(): number {
        const start = getPageStart();
        if ((start + rowsPerPage) > totalRows) {
            return totalRows;
        }
        return start + rowsPerPage - 1;
    }

    function checkNextPageDisabled(): boolean {
        return !((totalRows / rowsPerPage) > pageNumber);
    }

    function handleFirstPageClick(): void {
        if (pageNumber !== 1) {
            setPageNumber(1);
        }
    }

    function handlePrevPageClick(): void {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    function handleNextPageClick(): void {
        if (!checkNextPageDisabled()) {
            setPageNumber(pageNumber + 1);
        }
    }

    function handleLastPageClick(): void {
        if (!checkNextPageDisabled()) {
            setPageNumber(Math.ceil(totalRows / rowsPerPage));
        }
    }

    function handleRowsPerPageChange(newRowsPerPage: number): void {
        const start = getPageStart();
        setRowsPerPage(newRowsPerPage);
        setPageNumber(Math.ceil(start / newRowsPerPage))
    }

    return (
        <Wrapper>
            <div style={{ flex: 4 }} />

            <RowsPerPage>
                <Small>Rows per Page:</Small>
                <StyledControl>
                    <StyledSelect
                        value={rowsPerPage}
                        onChange={(e) => handleRowsPerPageChange(e.target.value as number)}
                        variant="standard"
                        disableUnderline
                        MenuProps={{
                            PaperProps: { sx: paper },
                            MenuListProps: { sx: list }
                        }}
                        IconComponent={() => {
                            return (
                                <PointerDiv onClick={() => setDropdownOpen(!dropdownOpen)} style={{ paddingTop: 3 }}>
                                    <ChevronDown color={theme.INPUT} size="small" />
                                </PointerDiv>
                            )
                        }}
                        onClose={() => setDropdownOpen(false)}
                        onOpen={() => setDropdownOpen(true)}
                        open={dropdownOpen}
                    >
                        {rowsPerPageOptions?.map((item, i) => (
                            <MenuItem value={item} key={i}>
                                {<Small>{item}</Small>}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </StyledControl>
            </RowsPerPage>

            <PageInfo>
                <Small>{`${getPageStart()}-${getPageEnd()} of ${totalRows}${props.searchValue ? " results" : ""}`}</Small> {/* use props */}
            </PageInfo>

            <PageNavigation>
                <PointerDiv
                    disabled={pageNumber === 1}
                    onClick={handleFirstPageClick}
                >
                    <StartIcon color={pageNumber === 1 ? theme.DISABLED : colors.BLACK} />
                </PointerDiv>
                <PointerDiv
                    disabled={pageNumber === 1}
                    onClick={handlePrevPageClick}
                    style={{ paddingRight: "0.4rem" }}
                >
                    <CaretLeft color={pageNumber === 1 ? theme.DISABLED : colors.BLACK} />
                </PointerDiv>
                <PointerDiv
                    disabled={checkNextPageDisabled()}
                    onClick={handleNextPageClick}
                >
                    <CaretRight color={checkNextPageDisabled() ? theme.DISABLED : colors.BLACK} />
                </PointerDiv>
                <PointerDiv
                    disabled={checkNextPageDisabled()}
                    onClick={handleLastPageClick}
                >
                    <EndIcon color={checkNextPageDisabled() ? theme.DISABLED : colors.BLACK} />
                </PointerDiv>
            </PageNavigation>
        </Wrapper>
    )
}



const Wrapper = styled('div')({
    padding: "1rem 0rem",
    height: "5rem",
    borderTop: BORDER_1(colors.GRAY_300),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4rem",
    boxSizing: "border-box",
});

const RowsPerPage = styled('div')({
    display: "flex",
    flexDirection: "row",
    order: 0,
    flexGrow: 0,
    alignItems: "center",
    width: "22.5rem"
})

const PageInfo = styled('div')({
    order: 1,
    flexGrow: 0,
    width: "15.4rem",
    textAlign: "center",
})

const PageNavigation = styled('div')({
    order: 2,
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    gap: "0.9rem",
    padding: "0.2rem 1.1rem 0 3.2rem",
})

const PointerDiv = styled('div')<{
    disabled?: boolean;
}>(({ disabled }) => ({
    cursor: disabled ? "auto" : "pointer",
}));

const StyledControl = styled(FormControl)({
    height: "3rem",
    marginLeft: "1rem",
    "& .MuiInput-root": {
        justifyContent: "center",

        "& .MuiSelect-select": {
            background: colors.WHITE
        }
    }
})

const StyledSelect = styled(Select)({
    borderBottom: BORDER_2(theme.INPUT),
    ".MuiSelect-select": {
        ...SMALL_TEXT,
        color: colors.BLACK,

        paddingTop: "0.7rem",
        marginRight: "-1.4rem",
        minWidth: 0,
        alignItems: "center",
    }
});

const list = {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
        ...SMALL_TEXT,
        color: colors.GRAY_700,
        height: "3rem",
        paddingRight: 1,
        paddingLeft: 1
    },
    "& li[aria-selected=true]": {
        color: colors.BLACK,
        background: colors.RED_100
    },
    "& li[aria-selected=false]": {
        color: colors.GRAY_700,
        background: colors.GRAY_50
    },
    "& li.Mui-selected:hover": {
        background: theme.HOVER
    },
    "& li:hover": {
        background: theme.HOVER
    }
}

const paper = {
    marginTop: "0.02rem",
    marginLeft: 0,
    borderRadius: 0,
    backgroundColor: colors.GRAY_50
}