import { FinancialInfo } from "../../../types/UniversalSurvey/FinancialInfo/financialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultFinancialInfo: FinancialInfo = {
    id: -1,
    name: undefined,
    transit_number: undefined,
    institution_number: undefined,
    account_number: undefined,
    fax_for_payout_info: undefined,
    email_for_payout_info: undefined,
    portal_for_payout_info: undefined,

    branch_address: defaultSimpleAddress
}