import { Ref, RefCallback, RefObject, useEffect } from "react";

export default function useOutsideClick(ref: RefObject<HTMLElement>, setter: (set: boolean) => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setter(false);
                }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }