import { styled } from "@mui/styles";
import { Small } from "../Typography/index";
import { QuestionIcon } from "../Icons/Iconography";
import color from '../componentStyling/Colors';
import BasicTooltip, { BasicTooltipProps } from "../Tooltip/Tooltip";
import theme from "../componentStyling/Theme";

export type labelProps = {
    text: string;
    inputId?: string;
    onClick?: Function;
    open?: boolean;
    tooltipProps?: Omit<BasicTooltipProps, "children">
    color?: string;
    isRequired?: boolean;
}

export default function Label(props: labelProps) {
    return (
        <label
            htmlFor={props.inputId}
            onClick={(e) => {
                if(props.onClick) {
                    e.stopPropagation();
                    props.onClick(!props.open)
                }
            }}
        >
            <StyledDiv>
                <Small style={{ color: props.color ?? color.BLACK }}>{props.text}</Small>
                {props.isRequired && (
                    <Small style={{ color: theme.ERROR }}>
                        *
                    </Small>
                )}
                {props.tooltipProps && (
                    <BasicTooltip {...props.tooltipProps}>
                        <div style={{ marginTop: "0.3rem" }}>
                            <QuestionIcon color={color.BLACK}/>
                        </div>
                    </BasicTooltip>
                )}
            </StyledDiv>
        </label>);
}

const StyledDiv = styled('div')({
    height: "2.4rem",
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center"
})