import { MortgageTransaction } from "../../../types/UniversalSurvey/MortgageTransaction/mortgageTransaction";
import { defaultNewMortgage } from "./defaultNewMortgage";

export const defaultMortgageTransaction: MortgageTransaction = {
    id: -1,
    new_mortgage_record: defaultNewMortgage,
    date_created: undefined,
    date_updated: undefined,
    borrower_list: undefined,
    other_property_list: undefined,
    collateral_property_list: undefined
}