import { findAndRemove, findAndReplace } from "../../../libs/utils/arrays";
import { Note } from "../../../libs/types/UniversalSurvey/Note/note";
import * as actions from "../actionTypes"

export type NotesState = {
    notesInDeal: Note[]
}

export const defaultNotesState: NotesState = {
    notesInDeal: [],
}

export function notesReducer(state: NotesState, action: Record<string, any>): NotesState {
    switch (action.type) {
        case actions.SET_NOTES_IN_DEAL:
            return { ...state, notesInDeal: action.payload };
        case actions.DELETE_NOTE:
            return { ...state, notesInDeal: findAndRemove([...state.notesInDeal], ["id"], [action.payload]) };
        case actions.UPDATE_NOTE:
            return { ...state, notesInDeal: findAndReplace([...state.notesInDeal], ["id"], [action.payload.id], action.payload) };
        default:
            return state;
    }
}