export enum DocumentType {
    StatDec = "statutory_declaration",
    GuarantorWaiverOfIla = "guarantor_waiver_of_ila",
    GuarantorGuaranteeOfMortgage = "guarantor_guarantee_of_mortgage",
    ConsentToActConflictOfMortgage = "consent_to_act_conflict_of_mortgage",
    Form9C = "form9c",
    LetterToMortgageeNewMortgage = "letter_to_mortgagee_new_mortgage",
    LetterToMortgageeExistingMortgage = "letter_to_mortgagee_existing_mortgage",
    Ledger = "ledger",
    StatementOfAccount = "statement_of_account",
    LetterToClient = "letter_to_client",
    Undertaking = "undertaking",
    VerificationOfIdentityAgreement = "verification_of_identity_agreement",
    GeneralAssignmentOfRents = "general_assignment_of_rents",
    MortgagePackage = "mortgage_package",
    GeneralSecurityAgreement = "general_security_agreement",
    DirectionReTitle = "direction_re_title",
    DraThreeOrMore = "dra_three_or_more",
    DraTwoParties = "dra_two_parties"
}

export const DocumentNameMapping = {
    [DocumentType.StatDec]: "Statutory Declaration",
    [DocumentType.GuarantorWaiverOfIla]: "Guarantor Waiver of ILA",
    [DocumentType.GuarantorGuaranteeOfMortgage]: "Guarantee of Mortgage",
    [DocumentType.ConsentToActConflictOfMortgage]: "Consent to Act Re:Conflict",
    [DocumentType.Form9C]: "Form 9C",
    [DocumentType.LetterToMortgageeNewMortgage]: "Letter to Mortgagee (New Mortgage)",
    [DocumentType.LetterToMortgageeExistingMortgage]: "Letter to Mortgagee (Existing Mortgage)",
    [DocumentType.Ledger]: "Ledger",
    [DocumentType.StatementOfAccount]: "Statement of Account",
    [DocumentType.LetterToClient]: "Letter to Client",
    [DocumentType.Undertaking]: "Undertaking",
    [DocumentType.VerificationOfIdentityAgreement]: "Verification of Identity Agreement",
    [DocumentType.GeneralAssignmentOfRents]: "General Assignment of Rents",
    [DocumentType.MortgagePackage]: "Mortgage Package",
    [DocumentType.GeneralSecurityAgreement]: "General Security Agreement",
    [DocumentType.DirectionReTitle]: "Direction Re: Title",
    [DocumentType.DraThreeOrMore]: "Document Registration Agreement(3 or more parties)",
    [DocumentType.DraTwoParties]: "Document Registration Agreement(2 parties)"
}