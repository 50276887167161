import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { Sections } from "../../../libs/resources/enums/sections";
import { Section } from "../../../libs/types/UniversalSurvey/Frontend/section";
import { dataSheetControlOptions } from '../../../libs/resources/mockControlOptions';
import { switchToClientSection, changeSelectedClient } from "./clients";
import { switchToPropertySection, changeSelectedProperty } from "./properties";
import { switchToExistingLienSection, changeSelectedExistingLien } from "./existingLiens";
import { switchToNewMortgageSection, changeSelectedNewMortgage } from "./newMortgages";
import { switchToFireInsuranceSection, changeSelectedFireInsurance, createFireInsuranceForProperty } from "./fireInsurance";
import { Property } from "../../../libs/types/UniversalSurvey/Property/property";
import { switchToTitleInsuranceSection } from "./titleInsurance";
import { SectionMapping } from "../../../libs/resources/mappings/sections";

export type OptionalParameters = {
    addingFireInsuranceFromPropertySection?: boolean;
    propertyToAddFireInsuranceFor?: Property | undefined;
}

function handleSectionChange(dispatch: Dispatch<Record<string, any>>, section: string, dealId: string, hasEntityId: boolean, optionalParams?: OptionalParameters) {
    const newSectionTitle: Sections = SectionMapping[section as keyof typeof SectionMapping];
    
    dispatch({ type: actions.SET_CURR_SECTION, payload: newSectionTitle });
    dispatch({ type: actions.SET_CURR_ENTITY, payload: undefined });
    dispatch({ type: actions.SET_CURR_SECTION_STATUS, payload: dataSheetControlOptions.find((option: Section) => option.title === newSectionTitle)?.status });

    switch (newSectionTitle) {
        case Sections.ClientInformation:
            switchToClientSection(dispatch, dealId, hasEntityId);
            break;
        case Sections.PropertyInformation:
            switchToPropertySection(dispatch, dealId, hasEntityId);
            break;
        case Sections.Encumbrances:
            switchToExistingLienSection(dispatch, dealId, hasEntityId);
            break;
        case Sections.NewMortgage:
            switchToNewMortgageSection(dispatch, dealId, hasEntityId);
            break;
        case Sections.FireInsurance:
            if(optionalParams && optionalParams.addingFireInsuranceFromPropertySection && optionalParams.propertyToAddFireInsuranceFor) {
                createFireInsuranceForProperty(dispatch, dealId, optionalParams.propertyToAddFireInsuranceFor);
            } else {
                switchToFireInsuranceSection(dispatch, dealId, hasEntityId);
            }
            break;
        case Sections.TitleInsurance:
            switchToTitleInsuranceSection(dispatch, dealId);
            break;
        default:
            dispatch({ type: actions.SET_TAB_OPTIONS, payload: [] });
            return;
    }
}

function handleEntityChange(dispatch: Dispatch<Record<string, any>>, currSection: string, dealId: string, entityId: number) {
    dispatch({ type: actions.SET_CURR_ENTITY, payload: entityId });
    switch (currSection) {
        case Sections.ClientInformation:
            changeSelectedClient(dispatch, dealId, entityId);
            break;
        case Sections.PropertyInformation:
            changeSelectedProperty(dispatch, dealId, entityId);
            break;
        case Sections.Encumbrances:
            changeSelectedExistingLien(dispatch, dealId, entityId);
            break;
        case Sections.NewMortgage:
            changeSelectedNewMortgage(dispatch, dealId, entityId);
            break;
        case Sections.FireInsurance:
            changeSelectedFireInsurance(dispatch, dealId, entityId);
            break;
        default:
            dispatch({ type: actions.SET_TAB_OPTIONS, payload: [] });
            return;
    }
}

export {
    handleSectionChange,
    handleEntityChange
}