import { Dispatch } from "react";

import { AdminActionTypes } from "../actionTypes";
import { getRegions } from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeRegionsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { allRegion } from "../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { SupportedProvinces } from "../../../libs/resources/enums/provinces";
import { AdminActions } from "../context";

function getRegionList(dispatch: Dispatch<AdminActions>) {
    getRegions()
        .then(function (response: any) {
            let regions = sanitizeRegionsResponse(response.data);
            let supportedRegions = regions.filter((item) => item.abbreviation ? Object.values(SupportedProvinces).includes(item.abbreviation as SupportedProvinces) : false)
            dispatch({ type: AdminActionTypes.SET_REGIONS, payload: [allRegion, ...supportedRegions] });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get regions: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getRegionList
}