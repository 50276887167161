import { SimpleLender } from "../../../types/UniversalSurvey/Lender/simpleLender"
import { defaultFinancialInfo } from "./defaultFinancialInfo"
import { defaultSimpleAddress } from "./defaultSimpleAddress"

export const defaultSimpleLender: SimpleLender = {
    id: -1,
    name: undefined,
    abbr_name: undefined,
    type: undefined,
    institution_number: undefined,
    branch_number: undefined,
    general_address: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,

    lender: undefined
}