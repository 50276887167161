import { ClientSortOrder, PropertySortOrder } from "../resources/options";
import { TabOption, TabOptions } from "../types/UniversalSurvey/Frontend/tabOption";
import { SimpleTitleInsurancePremium } from "../types/UniversalSurvey/TitleInsurancePremium/simpleTitleInsurancePremium";
import { DocRow } from "../../routes/UniversalSurvey/Documents/Documents";
import { DocumentCategory } from "../resources/enums/documents/category";
import { orderOptions } from "../../../components/Common/Table/TableBase";
import { SimpleTrustLedger } from "../types/UniversalSurvey/TrustLedger/simpleTrustLedger";
import { TrustLedgerType } from "../resources/enums/trustLedger";

function findAndRemove<ObjType>(arrayCopy: ObjType[], fieldsToMatch: string[], valuesToMatch: any[]) {
    const matchingObj = arrayCopy.find((obj) => {
        for (let i = 0; i < fieldsToMatch.length; i++) {
            if (obj[fieldsToMatch[i] as keyof ObjType] !== valuesToMatch[i]) {
                return false;
            }
        }
        return true;
    });
    if (matchingObj) {
        arrayCopy.splice(arrayCopy.indexOf(matchingObj), 1);
    }
    return arrayCopy;
}

function findAndReplace<ObjType>(arrayCopy: ObjType[], fieldsToMatch: string[], valuesToMatch: any[], newValue: ObjType): ObjType[] {
    const matchingObj = arrayCopy.find((obj) => {
        for (let i = 0; i < fieldsToMatch.length; i++) {
            if (obj[fieldsToMatch[i] as keyof ObjType] !== valuesToMatch[i]) {
                return false;
            }
        }
        return true;
    });
    if (matchingObj) {
        arrayCopy[arrayCopy.indexOf(matchingObj)] = newValue;
    }
    return arrayCopy;
}

function sortByKey<ObjType, K extends keyof ObjType>(array: ObjType[], key: K) {
    array.sort((a, b) => {
        if (a[key] !== undefined && b[key] !== undefined) {
            return a[key] > b[key] ? 1 : -1;
        } else if (a[key] !== undefined) {
            return -1;
        } else {
            return 1;
        }
    });
}

function sortByRole(sortOrder: string[], a: TabOption, b: TabOption) {
    if (!a.subTitle && !b.subTitle) {
        return 0;
    } else if (!a.subTitle) {
        return 1;
    } else if (!b.subTitle) {
        return -1;
    } else {
        const aIndex = sortOrder.indexOf(a.subTitle);
        const bIndex = sortOrder.indexOf(b.subTitle);
        return aIndex - bIndex;
    }
}
  
function sortByArrayOrder(tabs: TabOptions, entityType: "client" | "property") {
    let sortOrder: string[] = [];
    switch(entityType) {
        case "client":
            sortOrder = ClientSortOrder;
            break;
        case "property":
            sortOrder = PropertySortOrder;
            break;
    }
    tabs.sort((a, b) => sortByRole(sortOrder, a, b));
}

function sortTitleInsurancePremiums(premiums: SimpleTitleInsurancePremium[]) {
    premiums.sort((a, b) => {
        const aIndex = premiums.indexOf(a);
        const bIndex = premiums.indexOf(b);

        if (a.price !== undefined && b.price !== undefined) {
            if (a.price < 0 && b.price >= 0) {
                return 1;
            } else if (a.price >= 0 && b.price < 0) {
                return -1;
            } else {
                return aIndex - bIndex;
            }
        } else if (a.price !== undefined) {
            return -1;
        } else if (b.price !== undefined) {
            return 1;
        }
        return aIndex - bIndex;
    })
}

function sortTrustLedgerItems(trustLedgerItems: SimpleTrustLedger[]) {
    trustLedgerItems.sort((a, b) => {
        if (a.type !== b.type) {
            if (a.type === TrustLedgerType.Expenditure) {
                return 1;
            } else {
                return -1;
            }
        }
        return 0;
    });
}

function sortChildren(row: DocRow, direction: orderOptions, objKey: string) {
    if (row.children) {
        sortDocRows(row.children, direction, objKey);
        for (const child of row.children) {
            sortChildren(child, direction, objKey);
        }
    }
}

function sortDocRows(docRows: DocRow[], direction: orderOptions, objKey: string) {
    docRows.sort((a, b) => {
        if (a.category === DocumentCategory.Folder && b.category !== DocumentCategory.Folder) {
            return -1;
        } else if (a.category !== DocumentCategory.Folder && b.category === DocumentCategory.Folder) {
            return 1;
        }

        if (a[objKey as keyof typeof a] && b[objKey as keyof typeof a]) {
            if (direction === "desc") {
                if (typeof a[objKey as keyof typeof a] === "string") {
                    return a[objKey as keyof typeof a].toLowerCase() > b[objKey as keyof typeof a].toLowerCase() ? -1 : 1;
                }
                return a[objKey as keyof typeof a] > b[objKey as keyof typeof a] ? -1 : 1;
            } else {
                if (typeof a[objKey as keyof typeof a] === "string") {
                    return a[objKey as keyof typeof a].toLowerCase() > b[objKey as keyof typeof a].toLowerCase() ? 1 : -1;
                }
                return a[objKey as keyof typeof a] > b[objKey as keyof typeof a] ? 1 : -1;
            }
        } else if (a[objKey as keyof typeof a]) {
            if (direction === "desc") {
                return 1;
            } else {
                return -1;
            }
        } else if (b[objKey as keyof typeof b]) {
            if (direction === "desc") {
                return -1;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    });
}

export {
    findAndRemove,
    findAndReplace,
    sortByKey,
    sortByArrayOrder,
    sortTitleInsurancePremiums,
    sortTrustLedgerItems,
    sortChildren,
    sortDocRows
}