import { useEffect, useRef } from 'react';

import { styled } from '@mui/material';

import { ApplyWrapper } from './HTMLWrapper';

interface HTMLIFrameProps {
    index: number;
    html: string;
    thumbnailScale: "small" | "large";
}

export default function HTMLIFrame(props: HTMLIFrameProps) {

    const { index, html, thumbnailScale } = props;
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const handleIframeClick = () => {
        window.parent.postMessage({ type: 'iframeClick', index }, '*');
    };

    useEffect(() => {
        const iframeDocument = iframeRef.current?.contentDocument;
        if (iframeDocument) {
            iframeDocument.addEventListener('click', handleIframeClick);
        }
    
        return () => {
            if (iframeDocument) {
                iframeDocument.removeEventListener('click', handleIframeClick);
            }
        };
    }, [iframeRef.current?.contentDocument]);

    return (
        <HTMLPreviewPageFrame
            ref={iframeRef}
            thumbnailScale={thumbnailScale}
            width={820}
            height={1078}
            srcDoc={`<body class="html-container">${ApplyWrapper(html, { applyStyle: true, isPreviewHTML: true })}</body>`}
        ></HTMLPreviewPageFrame>
    );
};

const HTMLPreviewPageFrame = styled('iframe')<{
    thumbnailScale: "small" | "large";
}>(({ thumbnailScale }) => ({
    transform: thumbnailScale === "small" ? "scale(0.1)" : "scale(0.2)",
    transformOrigin: "0 0",
    display: "block",
    border: "none",
    padding: "60px 90px 60px 60px",
    overflow: "hidden",
    userSelect: "none",
    '&:hover': {
        cursor: "pointer",
    }
}));