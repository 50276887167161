import { Dispatch } from "react";

import { AdminActions } from "../context";
import { AdminActionTypes } from "../actionTypes";
import { createGlobalLegalFirmOffice,
    deleteGlobalLegalFirmOffice,
    getGlobalLegalFirmOffice,
    getGlobalLegalFirmOffices,
    saveGlobalLegalFirmOffice
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import { sanitizeGlobalLegalFirmOfficeResponse, sanitizeSimpleGlobalLegalFirmOfficesResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeGlobalLegalFirmOfficeRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { LegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { transformLegalFirmOfficeToGlobalLegalFirmOffice } from "../../../libs/types/UniversalSurvey/utils/transformGlobals";

function getLegalFirmOffices(dispatch: Dispatch<AdminActions>, legalFirmId: number, query: URLSearchParams) {
    if (!query) return;
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOffices, isLoading: true } });
    getGlobalLegalFirmOffices(String(legalFirmId), query)
        .then(function (response: any) {
            const cleanData = sanitizeSimpleGlobalLegalFirmOfficesResponse(response.data.legal_firm_offices);
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICES, payload: cleanData });
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_RESULT_COUNT, payload: response.data.metadata.total });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get law firm offices: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOffices, isLoading: false } });
        })
}

function setLegalFirmOfficeBeingEdited(dispatch: Dispatch<AdminActions>, legalFirmOfficeId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOfficeBeingEdited, isLoading: true } });
    getGlobalLegalFirmOffice(String(legalFirmOfficeId))
        .then(function (response: any) {
            const cleanData = sanitizeGlobalLegalFirmOfficeResponse(response.data);
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_BEING_EDITED, payload: cleanData });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get law firm office: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOfficeBeingEdited, isLoading: false } });
        })
}

function deleteLegalFirmOffice(dispatch: Dispatch<AdminActions>, legalFirmOfficeId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOffices, isLoading: true } });
    deleteGlobalLegalFirmOffice(String(legalFirmOfficeId))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Delete law firm office: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirmOffices, isLoading: false } });
        })
}

function saveLegalFirmOffice(dispatch: Dispatch<AdminActions>, officeInfo: LegalFirmOffice) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirmOffice, isLoading: true } });
    let legalFirmOffice = transformLegalFirmOfficeToGlobalLegalFirmOffice(officeInfo, undefined);
    saveGlobalLegalFirmOffice(String(legalFirmOffice.id), sanitizeGlobalLegalFirmOfficeRequest(legalFirmOffice))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save law firm office: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirmOffice, isLoading: false } });
        })
}

function submitNewLegalFirmOffice(dispatch: Dispatch<AdminActions>, legalFirmId: number, officeInfo: LegalFirmOffice) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirmOffice, isLoading: true } });
    let legalFirmOffice = transformLegalFirmOfficeToGlobalLegalFirmOffice(officeInfo, undefined);
    createGlobalLegalFirmOffice(String(legalFirmId), sanitizeGlobalLegalFirmOfficeRequest(legalFirmOffice))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH, payload: true });
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Successfully created law firm office.`, type: AlertTypes.Success } });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Create law firm office: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirmOffice, isLoading: false } });
        })
}

export {
    getLegalFirmOffices,
    submitNewLegalFirmOffice,
    saveLegalFirmOffice,
    deleteLegalFirmOffice,
    setLegalFirmOfficeBeingEdited
}