import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { deleteRegionFromMortgageBrokerRecord, getMortgageBrokerRecord, getMortgageBrokerRecords, getGlobalMortgageBrokers, newMortgageBrokerRecord, newRegionOnMortgageBrokerRecord, saveMortgageBrokerRecord } from '../../../libs/service/axios/api';
import { MortgageBrokerage } from '../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage';
import { MortgageBroker, MortgageBrokerListItem } from '../../../libs/types/UniversalSurvey/MortgageBroker/mortgageBroker';
import { sanitizeMortgageBrokerResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeMortgageBrokerRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { Loading } from "../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../libs/types/DealList/deals";

function createMortgageBrokerOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageBrokerage: MortgageBrokerage, selectedBrokerId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerList, isLoading: true } });
    getMortgageBrokerRecords(dealId, String(mortgageBrokerage.id))
    .then(function (response: any) {
        const options: MortgageBrokerListItem[] = [];
        const brokerIsRecordList: number[] = [];

        for(const broker of response.data) {
            options.push({
                id: broker.id,
                label: broker.name,
                isRecord: true
            });
            if(broker.mortgage_broker) {
                brokerIsRecordList.push(broker.mortgage_broker.id);
            }
        }

        if(mortgageBrokerage.mortgage_brokerage?.id) {
            getGlobalMortgageBrokers(String(mortgageBrokerage.mortgage_brokerage.id))
            .then(function (globalResponse: any) {
                for (const globalBroker of globalResponse.data.mortgage_brokers) {
                    if(!brokerIsRecordList.includes(globalBroker.id)) {
                        options.push({
                            id: globalBroker.id,
                            label: globalBroker.name,
                            isRecord: false
                        });
                    }
                }
                dispatch({ type: actions.SET_MORTGAGE_BROKERS_OPTION_LIST, payload: options });
                dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: selectedBrokerId });
            })
            .catch(function(error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokers: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerList, isLoading: false } });
            })
        } else {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerList, isLoading: false } });
            dispatch({ type: actions.SET_MORTGAGE_BROKERS_OPTION_LIST, payload: options });
            dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: selectedBrokerId });
        }
    })
    .catch(function(error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerList, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage broker records: ${error}`, type: AlertTypes.Error } });
    })
}

function createRecordFromMortgageBroker(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageId: number, broker: MortgageBrokerListItem) {
    newMortgageBrokerRecord(dealId, String(brokerageId), { mortgage_broker_id: broker.id! })
    .then(function (response: any) {
        getMortgageBrokerRecordInfo(dispatch, dealId, brokerageId, response.data.id);
        const newBrokerListItem: MortgageBrokerListItem = { id: response.data.id, isRecord: true, label: broker.label };
        dispatch({ type: actions.REPLACE_MORTGAGE_BROKER_OPTION_WITH_RECORD, payload: { oldBrokerId: broker.id, newOption: newBrokerListItem, oldIsRecord: false } });
        dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: newBrokerListItem.id });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create mortgage broker record: ${error}`, type: AlertTypes.Error } });
    })
}

function submitNewMortgageBrokerRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: MortgageBroker, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBroker, isLoading: true } });
    newMortgageBrokerRecord(dealId, String(brokerageRecordId))
    .then(function (response: any) {
        saveMortgageBrokerRecord(dealId, String(brokerageRecordId), String(response.data.id), sanitizeMortgageBrokerRequest(brokerInfo))
        .then(async function() { 
            const newBrokerListItem: MortgageBrokerListItem = { id: response.data.id, isRecord: true, label: brokerInfo.name ?? undefined };
            await addMortgageBrokerRecordRegions(dispatch, dealId, brokerageRecordId, response.data.id, regionList);
            dispatch({ type: actions.ADD_TO_MORTGAGE_BROKER_OPTIONS, payload: newBrokerListItem });
            dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKER, payload: newBrokerListItem.id });
            getMortgageBrokerRecordInfo(dispatch, dealId, brokerageRecordId, response.data.id);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save mortgage broker record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBroker, isLoading: false } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBroker, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create mortgage broker record: ${error}`, type: AlertTypes.Error } });
    })
}

function saveMortgageBroker(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: MortgageBroker, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBroker, isLoading: true } });
    saveMortgageBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), sanitizeMortgageBrokerRequest(brokerInfo))
    .then(async function () {
        await updateMortgageBrokerRecordRegions(dispatch, dealId, brokerageRecordId, brokerInfo, regionList);
        getMortgageBrokerRecordInfo(dispatch, dealId, brokerageRecordId, brokerInfo.id);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save mortgage broker record: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBroker, isLoading: false } });
    })
}

function getMortgageBrokerRecordInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerRecordId: number) {
    getMortgageBrokerRecord(dealId, String(brokerageRecordId), String(brokerRecordId))
    .then(function(response: any) {
        dispatch({ type: actions.SET_MORTGAGE_BROKER_INFO, payload: sanitizeMortgageBrokerResponse(response.data) });
        dispatch({ type: actions.SET_MORTGAGE_BROKER_ID, payload: brokerRecordId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage broker record: ${error}`, type: AlertTypes.Error } });
    })
}

async function addMortgageBrokerRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnMortgageBrokerRecord(dealId, String(brokerageRecordId), String(brokerRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to mortgage broker record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateMortgageBrokerRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: MortgageBroker, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (brokerInfo.regions) {
        for (const region of brokerInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnMortgageBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to mortgage broker record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromMortgageBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the lender record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on mortgage broker record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

export {
    createMortgageBrokerOptionsList,
    createRecordFromMortgageBroker,
    submitNewMortgageBrokerRecord,
    saveMortgageBroker,
    getMortgageBrokerRecordInfo
}