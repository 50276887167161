import { DealRequest } from "../../../types/UniversalSurvey/Deal/dealRequest";
import { DealStatus } from "../../enums/dealStatus";

export const defaultDealRequest: DealRequest = {
    primary_transaction_id: null,
    primary_transaction_type: null,
    subject_property_id: null,
    acting_as: null,
    client_capacity: null,
    owner_clerk_id: null,
    closing_date: null,
    signer_id: null,
    lawyer_id: null,
    status: DealStatus.Open
}