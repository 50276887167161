import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { ChargeHolderCompanyContact, ChargeHolderCompanyContactErrorState } from "../../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { defaultChargeHolderCompanyContact } from "../../../../libs/resources/defaults/frontend/defaultChargeHolderCompanyContact";
import { submitNewChargeHolderCompanyContactRecord, saveChargeHolderCompanyContact } from "../../../../context/UniversalSurvey/asyncActions/chargeHolderCompanyContacts";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultChargeHolderCompanyContactErrorState } from "../../../../libs/resources/defaults/errorStates/defaultChargeHolderCompanyContactErrorState";

type Props = Omit<ModalProps, "children">;

export default function ChargeHolderCompanyContactModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [contactObj, setContactObj] = useState<ChargeHolderCompanyContact>({ ...defaultChargeHolderCompanyContact });
    const [errorState, setErrorState] = useState<ChargeHolderCompanyContactErrorState>({ ...defaultChargeHolderCompanyContactErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.chargeHolderCompanyContacts.contactBeingEdited) {
            setContactObj({ ...state.chargeHolderCompanyContacts.contactBeingEdited });
        } else {
            setContactObj({ ...defaultChargeHolderCompanyContact })
        }
    }, [state.chargeHolderCompanyContacts.contactBeingEdited]);

    function updateCompanyContactInfo<
        K extends keyof ChargeHolderCompanyContact,
        V extends ChargeHolderCompanyContact[K]
    >(key: K, value: V): void {
        const tempContactObj = { ...contactObj }
        tempContactObj[key] = value;
        setContactObj(tempContactObj);
    }

    function submit() {
        if (state.chargeHolderCompanyContacts.contactBeingEdited) {
            saveChargeHolderCompanyContact(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.chargeHolderCompanies.selectedCompany?.id!,
                contactObj
            )
        } else {
            submitNewChargeHolderCompanyContactRecord(dispatch,
                String(state.deal.dealInfo?.id),
                state.existingLiens.existingLienInfo!.id,
                state.chargeHolderCompanies.selectedCompany!.id,
                state.chargeHolderCompanyContacts.contactBeingReplaced,
                contactObj
            );
        }
    }

    function discard() {
        dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED, payload: undefined });
        dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_REPLACED, payload: undefined });
        setContactObj({ ...defaultChargeHolderCompanyContact });
        setErrorState({ ...defaultChargeHolderCompanyContactErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!contactObj.name || validateModalObject(errorState)) return true
        return false
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, contactObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={`${!state.chargeHolderCompanyContacts.contactBeingEdited ? "New " : ""}Company Contact`}
            saveDisabled={saveDisabled}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.ChargeHolderCompanyContactModal])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveChargeHolderCompanyContact])}
        >
            <Grid container columnSpacing={5} rowSpacing={3}>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        label={{text: "Name", inputId: "name", isRequired: true}}
                        placeholder="Contact's name"
                        value={contactObj.name ?? undefined}
                        autoFocus
                        onChange={(e) => updateCompanyContactInfo("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        label={{text: "Email", inputId: "email"}}
                        placeholder="Contact's email"
                        value={contactObj.email ?? undefined}
                        onChange={(e) => updateCompanyContactInfo("email", e.target.value)}
                        fieldType="email"
                        error={{
                            showError: true,
                            errorKey: "email",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={contactObj.phone_work}
                        onChange={(value) => updateCompanyContactInfo("phone_work", value)}
                        label={{text: "Work Phone", inputId: "phone_work"}}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "phone_work",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={contactObj.fax}
                        onChange={(value) => updateCompanyContactInfo("fax", value)}
                        label={{ text: "Fax", inputId: "fax" }}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "fax",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}
