export enum PaymentRecipientType {
    ThirdPartyTransfer = "third_party_transfer",
    Client = "client",
    Lawyer = "lawyer",
    Lender = "lender",
    Government = "government",
    Company = "company",
    MortgageBrokerage = "mortgage_brokerage",
    OtherChargeHolder = "other_charge_holder"
}

export enum ThirdPartyTransfer {
    Surefund = "surefund",
    Easyfund = "easyfund"
}