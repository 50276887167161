import { LenderListItem } from "../../types/UniversalSurvey/Lender/lender";

export function labelLenderItems(lenderList: LenderListItem[]): LenderListItem[] {
    let labeledItems: LenderListItem[] = [];
    for (const lender of lenderList) {
        labeledItems.push(labelLenderItem(lender));
    }
    return labeledItems;
}

export function labelLenderItem(lenderItemObj: LenderListItem): LenderListItem {
    let templenderItem = {...lenderItemObj};
    templenderItem.label = lenderItemObj.name ? lenderItemObj.name : "Lender name";
    templenderItem.label += lenderItemObj.branch_number ? ` - ${lenderItemObj.branch_number}` : "";
    return templenderItem;
}