import { GlobalLegalFirmRequest, LegalFirmRequest } from "../../../types/UniversalSurvey/LegalFirm/legalFirmRequest"
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest"

export const defaultLegalFirmRequest: LegalFirmRequest = {
    name: null,
    partner: false,
    all_regions: null,
    gst_number: null,
    financial_info: defaultFinancialInfoRequest
}

export const defaultGlobalLegalFirmRequest: GlobalLegalFirmRequest = {
    ...defaultLegalFirmRequest,
    notes: null
}