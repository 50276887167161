import { SimpleGlobalLegalFirm } from "../../../types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { defaultFinancialInfo } from "./defaultFinancialInfo";

export const defaultSimpleGlobalLegalFirm: SimpleGlobalLegalFirm = {
    id: -1,
    name: undefined,
    notes: undefined,
    all_regions: undefined,

    financial_info: defaultFinancialInfo,
    regions: []
}