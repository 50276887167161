import { SimpleLenderBranch } from "../../../types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";
import { defaultAddress } from "./defaultAddress";
import { defaultFinancialInfo } from "./defaultFinancialInfo";

export const defaultSimpleLenderBranch: SimpleLenderBranch = {
    id: -1,
    branch_number: undefined,
    all_regions: undefined,
    notes: undefined,

    general_address: defaultAddress,
    financial_info: defaultFinancialInfo,
    regions: [],
    lender: undefined
}