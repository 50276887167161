import { useCallback, useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import { Debt } from "../../../../libs/types/UniversalSurvey/Debts/debt";
import { defaultDebt } from "../../../../libs/resources/defaults/frontend/defaultDebt";
import { addDebt, createDebtHolderAndUpdateDebt, createDebtHolderRecordFromGlobal, getDebtHolder, removeDebt, updateDebt, updateDebtHolder } from "../../../../context/UniversalSurvey/asyncActions/debts";
import { DebtActionOptions, DebtHolderTypeOptions, DebtTypeOptions, PaymentMethodOptions } from "../../../../libs/resources/options";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { formatDebtAction, formatDebtHolderType, formatPaymentMethod } from "../../../../libs/utils/formatValues";
import { DebtAction } from "../../../../libs/resources/enums/debtAction";
import { DebtHolderTypes } from "../../../../libs/resources/enums/debtHolderTypes";
import { DebtHolder, DebtHolderErrorState, DebtHolderListItem } from "../../../../libs/types/UniversalSurvey/Debts/debtHolder";
import { defaultDebtHolder } from "../../../../libs/resources/defaults/frontend/defaultDebtHolder";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { defaultDebtHolderListItem } from "../../../../libs/resources/defaults/frontend/defaultDebtHolderListItem";
import { deleteEntityFromOwnModal } from "../../../../context/UniversalSurvey/asyncActions/conditions";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultDebtHolderErrorState } from "../../../../libs/resources/defaults/errorStates/defaultDebtHolderErrorState";
import { createLenderOptionsList } from "../../../../context/UniversalSurvey/asyncActions/lenders";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../../libs/utils/limits";
import { debounce } from "../../../../libs/utils/debounce";
// import { PaymentMethod } from "../../../../libs/resources/enums/paymentMethod";
// import { PaymentRecipientType } from "../../../../libs/resources/enums/paymentGroup";
// import { DebtTrustLedger } from "../../../../libs/types/UniversalSurvey/TrustLedger/debtTrustLedger";

type Props = Omit<ModalProps, "children">;

export default function DebtModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [debtObj, setDebtObj] = useState<Debt>({ ...defaultDebt });
    const [originalCopy, setOriginalCopy] = useState<Debt>({ ...defaultDebt });
    const [debtHolder, setDebtHolder] = useState<DebtHolderListItem>({ ...defaultDebtHolderListItem });
    const [debtHolderObj, setDebtHolderObj] = useState<DebtHolder>({ ...defaultDebtHolder });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<DebtHolderErrorState>({ ...defaultDebtHolderErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    // const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(state.debts.debtBeingEdited) {
            setDebtObj({ ...state.debts.debtBeingEdited });
            setOriginalCopy({ ...state.debts.debtBeingEdited });
            const debtHolderRecord = state.debts.debtBeingEdited.debt_holder_record;
            const chargeHolderType = state.debts.debtBeingEdited.charge_holder_type;
            let debtHolderListItem: DebtHolderListItem;
            
            if (debtHolderRecord) {
                getDebtHolder(dispatch, String(state.deal.dealInfo?.id), debtHolderRecord.id, chargeHolderType!);
                debtHolderListItem = { 
                    id: debtHolderRecord.id,
                    label: debtHolderRecord.name ?? "",
                    isRecord: true, 
                    charge_holder_type: state.debts.debtBeingEdited.charge_holder_type!,
                    name: debtHolderRecord.name ?? ""
                };
                setDebtHolder(debtHolderListItem);
            }
            // getPaymentMethod(state.debts.debtBeingEdited.trust_ledger, chargeHolderType);
        }
    }, [state.debts.debtBeingEdited]);

    function makeQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        if (searchVal) query.set("search", searchVal);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        return query;
    }
    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue]);

    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined,
                makeQuery(searchValue)
            );
        }, 500), []);

    useEffect(() => {
        if (state.debts.debtHolder) {
            setDebtHolderObj(state.debts.debtHolder);
            updateDebtInfo("debt_holder_id", state.debts.debtHolder.id);
        }
    }, [state.debts.debtHolder]);

    function updateDebtInfo<
        K extends keyof Debt,
        V extends Debt[K]
    >(key: K, value: V): void {
        const tempDebtObj = { ...debtObj }
        tempDebtObj[key] = value;
        setDebtObj(tempDebtObj);
        // getPaymentMethod(tempDebtObj.trust_ledger, tempDebtObj.charge_holder_type);
    }

    function updateDebtHolderInfo<
        K extends keyof DebtHolder,
        V extends DebtHolder[K]
    >(key: K, value: V): void {
        const tempDebtHolderObj = { ...debtHolderObj }
        tempDebtHolderObj[key] = value;
        setDebtHolderObj(tempDebtHolderObj);
    }

    // TODO Implement payment method logic
    // function getPaymentMethod(debtTrustLedger: DebtTrustLedger | undefined, chargeHolderType: DebtHolderTypes | undefined) {
    //     const paymentGroup = debtTrustLedger?.payment_group;
    //     if (paymentGroup) {
    //         let paymentMethod = paymentGroup.payment_method === null ? undefined : paymentGroup.payment_method;
    //         switch(paymentGroup.recipient_type) {
    //             case PaymentRecipientType.ThirdPartyTransfer:
    //                 setPaymentMethod(paymentMethod);
    //                 break;
    //             case PaymentRecipientType.Company:
    //                 chargeHolderType === DebtHolderTypes.Company ? setPaymentMethod(paymentMethod) : setPaymentMethod(undefined);
    //                 break;
    //             case PaymentRecipientType.Government:
    //                 chargeHolderType === DebtHolderTypes.Government ? setPaymentMethod(paymentMethod) : setPaymentMethod(undefined);
    //                 break;
    //             case PaymentRecipientType.OtherChargeHolder:
    //                 chargeHolderType === DebtHolderTypes.Other ? setPaymentMethod(paymentMethod) : setPaymentMethod(undefined);
    //                 break;
    //             case PaymentRecipientType.Lender:
    //                 chargeHolderType === DebtHolderTypes.Lender ? setPaymentMethod(paymentMethod) : setPaymentMethod(undefined);
    //                 break;
    //             case PaymentRecipientType.MortgageBrokerage:
    //                 chargeHolderType === DebtHolderTypes.MortgageBrokerage ? setPaymentMethod(paymentMethod) : setPaymentMethod(undefined);
    //                 break;
    //             default:
    //                 setPaymentMethod(undefined);
    //                 break;
    //         }
    //     } else {
    //         setPaymentMethod(undefined);
    //     }
    // }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_DEBT_BEING_EDITED, payload: undefined });
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: undefined });
        setDebtObj({ ...defaultDebt });
        setOriginalCopy({ ...defaultDebt });
        setDebtHolder({ ...defaultDebtHolderListItem });
        setDebtHolderObj({ ...defaultDebtHolder });
        setErrorState({ ...defaultDebtHolderErrorState });
    }

    function handleDiscardClick() {
        if (_.isEqual(debtObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (debtObj.charge_holder_type) {
            if (debtHolderObj.id !== -1) {
                updateDebtHolder(dispatch, String(state.deal.dealInfo?.id), debtHolderObj, debtObj.charge_holder_type);
                if (state.debts.debtBeingEdited) {
                    updateDebt(
                        dispatch,
                        String(state.deal.dealInfo?.id),
                        debtHolderObj,
                        debtObj
                    );
                } else {
                    addDebt(
                        dispatch,
                        String(state.deal.dealInfo?.id),
                        debtHolderObj,
                        debtObj
                    );
                }
            } else {
                createDebtHolderAndUpdateDebt(dispatch, String(state.deal.dealInfo?.id), { ...debtHolderObj, name: debtHolder.name}, debtObj);
            }
        }
    }

    function onDeleteConfirm() {
        const condition = state.conditions.conditionsInDeal.find((condition) => condition.debt?.id === debtObj.id);
        if (condition) {
            deleteEntityFromOwnModal(dispatch, String(state.deal.dealInfo?.id), "debt", debtObj.id, condition);
        } else {
            removeDebt(dispatch, String(state.deal.dealInfo?.id), state.debts.debtBeingEdited!.id)
        }
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!debtObj.debt_type || !debtObj.charge_holder_type || !debtObj.action || !debtObj.amount_payable || 
            !debtHolderObj.general_address || !debtHolderObj.name
        ) {
            return true;
        }
        if (_.isEqual(originalCopy, debtObj)) {
            return true;
        }
        if (validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, debtObj]);

    function convertToDebtHolderListItem(value: any, debtHolderType: DebtHolderTypes): DebtHolderListItem {
        const debtHolder: DebtHolderListItem = {
            id: value.id,
            label: value.label,
            isRecord: value.isRecord,
            name: value.name,
            charge_holder_type: debtHolderType
        }
        return debtHolder;
    }

    function getPayableToComboboxItems(): DebtHolderListItem[] {
        const options: DebtHolderListItem[] = [];
        let option: DebtHolderListItem;
        for (const lender of state.lenders.lenderOptionList) {
            option = convertToDebtHolderListItem(lender, DebtHolderTypes.Lender);
            options.push(option);
        }
        for (const company of state.chargeHolderCompanies.optionList) {
            option = convertToDebtHolderListItem(company, DebtHolderTypes.Company);
            options.push(option);
        }
        for (const government of state.governments.optionList) {
            option = convertToDebtHolderListItem(government, DebtHolderTypes.Government);
            options.push(option);
        }
        for (const other of state.otherChargeHolders.optionList) {
            option = convertToDebtHolderListItem(other, DebtHolderTypes.Other);
            options.push(option);
        }
        for (const brokerage of state.mortgageBrokerages.brokerageOptionList) {
            option = convertToDebtHolderListItem(brokerage, DebtHolderTypes.MortgageBrokerage);
            options.push(option);
        }
        return options;
    }

    function updateDebtHolderWithOption(value: DebtHolderListItem | undefined) {
        let tempDebtObj = { ...debtObj };
        if (value) {
            tempDebtObj.charge_holder_type = value.charge_holder_type;
            if (value.isRecord) {
                getDebtHolder(dispatch, String(state.deal.dealInfo?.id), value.id, value.charge_holder_type!);
            } else {
                createDebtHolderRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
            }
            setDebtHolder(value);
        } else {
            tempDebtObj.debt_holder_id = undefined;
            tempDebtObj.charge_holder_type = undefined;
            setDebtHolderObj({ ...defaultDebtHolder });
            setDebtHolder({ ...defaultDebtHolderListItem });
        }
        setDebtObj(tempDebtObj);
    }

    function updateDebtHolderWithText(value: string) {
        let tempDebtHolder = { ...debtHolder };
        updateDebtHolderInfo("name", value);
        tempDebtHolder.label = value;
        tempDebtHolder.name = value;
        setDebtHolder(tempDebtHolder);
    }

    function hasPhoneField(): boolean {
        if (debtObj.charge_holder_type === (DebtHolderTypes.MortgageBrokerage || DebtHolderTypes.Other)) {
            return true;
        }
        return false;
    }

    // function updatePaymentMethod(paymentMethod: PaymentMethod | undefined) {
    //     setPaymentMethod(paymentMethod);
    //     if (paymentMethod) {

    //     }
    // }

    return (
        <ModalBase
            title={`${state.debts.debtBeingEdited ? "" : "New "}Debt`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.debts.debtBeingEdited ? true : false}
            confirmDeleteText={`Are you sure you want to delete this debt${state.conditions.conditionsInDeal.find((condition) => condition.debt?.id === debtObj.id) ? ", which is linked to a condition" : ""}? This is permanent and irreversible.`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.debts.debtBeingEdited ? " Changes": ""}`}
            onDelete={onDeleteConfirm}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.DebtModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveDebt])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                        <ComboBox
                            autoFocus
                            label={{ text: "Type/Item", inputId: "debt_modal_debt_type", isRequired: true }}
                            options={DebtTypeOptions}
                            placeholder="Select type or item..."
                            value={debtObj.debt_type ?? ""}
                            isHoverActionHidden={debtObj.debt_type ? false : true}
                            handleClear={() => updateDebtInfo("debt_type", undefined)}
                            onChangeFn={(value) => updateDebtInfo("debt_type", value as string)}
                            setText={(value) => updateDebtInfo("debt_type", value as string)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Amount Payable", inputId: "amount_payable", isRequired: true }}
                            placeholder="0.00"
                            moneyField
                            valueType="positive"
                            value={debtObj?.amount_payable ? String(debtObj.amount_payable) : undefined}
                            onChange={(e) => updateDebtInfo("amount_payable", Number(e.target.value))}
                        /> 
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Account Number", inputId: "account_number" }}
                            placeholder="Account number"
                            value={debtObj.account_number}
                            onChange={(e) => updateDebtInfo("account_number", e.target.value)}
                        /> 
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanControl
                            checked={debtObj.valid_statement}
                            onChange={() => updateDebtInfo("valid_statement", !debtObj.valid_statement)}
                            label={{ text: "Valid Statement", inputId: "valid_statement" }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <ComboBox
                            label={{ text: "Payable To", inputId: "debt_modal_debt_holder", isRequired: true }}
                            options={getPayableToComboboxItems()}
                            placeholder="Debt holder's name"
                            value={debtHolder ?? { label: "" }}
                            isHoverActionHidden={debtHolder ? false : true}
                            handleClear={() => updateDebtHolderWithOption(undefined)}
                            onChangeFn={(value) => updateDebtHolderWithOption(value as any)}
                            setText={(value) => {
                                updateDebtHolderWithText(value as string);
                                setSearchValue(value);
                            }}
                            searchableKeys={["label"]}
                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderList])}
                            onBlur={() => {
                                setSearchValue(undefined);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Dropdown
                            value={debtObj.charge_holder_type ?? ""}
                            options={DebtHolderTypeOptions}
                            placeholder="Select debt holder type..."
                            label={{ text: "Debt Holder Type", isRequired: true }}
                            formatValue={formatDebtHolderType}
                            isHoverActionHidden={debtObj.charge_holder_type ? false : true}
                            handleClear={() => {
                                updateDebtInfo("charge_holder_type", undefined);
                                if (debtHolder.id !== -1) {
                                    setDebtHolder({ ...defaultDebtHolderListItem });
                                }
                                if (debtHolderObj.id !== -1) {
                                    setDebtHolderObj({ ...defaultDebtHolder });
                                }
                            }}
                            onChange={(e) => {
                                updateDebtInfo("charge_holder_type", e.target.value as DebtHolderTypes);
                                if (debtHolder.id !== -1) {
                                    setDebtHolder({ ...defaultDebtHolderListItem });
                                }
                                if (debtHolderObj.id !== -1) {
                                    setDebtHolderObj({ ...defaultDebtHolder });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={debtHolderObj.general_address}
                            setAddress={(value) => updateDebtHolderInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                    {hasPhoneField() && (
                        <>
                            <Grid item xs={6}>
                                <PhoneField
                                    value={debtHolderObj.phone}
                                    onChange={(value) => updateDebtHolderInfo("phone", value)}
                                    placeholder="(000) 000-0000"
                                    label={{text: "Phone", inputId: "phone"}}
                                    error={{
                                        showError: true,
                                        setErrorState: setErrorState,
                                        errorKey: "phone",
                                        errorState: errorState
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}/>
                        </>
                    )}
                    <Grid item xs={4}>
                        <Dropdown
                            value={debtObj.action ?? ""}
                            options={DebtActionOptions}
                            placeholder="Select action..."
                            label={{ text: "Action", isRequired: true }}
                            formatValue={formatDebtAction}
                            isHoverActionHidden={debtObj.action ? false : true}
                            handleClear={() => updateDebtInfo("action", undefined)}
                            onChange={(e) => updateDebtInfo("action", e.target.value as DebtAction)}
                        />
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Dropdown
                            value={paymentMethod}
                            // onChange={(e) => updateFinancialInfo("payment_method", e.target.value as PaymentMethod)}
                            options={PaymentMethodOptions}
                            formatValue={formatPaymentMethod}
                            placeholder="Payment Method"
                            label={{ text: "Payment Method", inputId: `payment_method` }}
                            isHoverActionHidden={paymentMethod ? false : true}
                            // handleClear={() => updateFinancialInfo("payment_method", undefined)}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            multiline
                            label={{ text: "Notes", inputId: "notes" }}
                            placeholder="Add notes"
                            value={debtObj?.notes ?? undefined}
                            onChange={(e) => updateDebtInfo("notes", e.target.value)}
                        /> 
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}