import * as actions from "../actionTypes"

import { createDealDisbursement, getDealDisbursements, saveDealDisbursement, deleteDealDisbursement } from "../../../libs/service/axios/api";
import { Dispatch } from "react";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeDisbursementsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeDisbursementRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { DisbursementTypes } from "../../../libs/resources/enums/disbursements";
import { SimpleDisbursement } from "../../../libs/types/UniversalSurvey/Disbursements/simpleDisbursement";
import { Disbursement } from "../../../libs/types/UniversalSurvey/Disbursements/disbursement";
import { defaultDisbursement } from "../../../libs/resources/defaults/frontend/defaultDisbursement";
import { Loading } from "../../../libs/resources/enums/loading";

async function getDisbursementsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DisbursementList, isLoading: true } });
    return await getDealDisbursements(dealId)
        .then(function (response: any) {
            let disbursements: SimpleDisbursement[] = sanitizeDisbursementsResponse(response.data);
            dispatch({ type: actions.SET_DISBURSEMENTS, payload: disbursements });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting deal disbursements: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DisbursementList, isLoading: false } });
        })
}

async function saveDisbursementInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, disbursementInfo: Disbursement) {
    saveDealDisbursement(dealId, String(disbursementInfo.id), sanitizeDisbursementRequest(disbursementInfo))
    .then()
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save disbursement record: ${error}`, type: AlertTypes.Error } });
    })
}

async function createNewDisbursement(dispatch: Dispatch<Record<string, any>>, dealId: string, type: DisbursementTypes) {
    createDealDisbursement(dealId, { type: type })
    .then(function (response: any) {
        let tempDisbursement: Disbursement = { ...defaultDisbursement, id: response.data.id, type: type };
        dispatch({ type: actions.ADD_CUSTOM_DISBURSEMENT, payload: tempDisbursement });
        if (type === DisbursementTypes.NonTaxable) {
            dispatch({ type: actions.SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED, payload: true })
        } else {
            dispatch({ type: actions.SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED, payload: true });
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create deal disbursement: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteDisbursement(dispatch: Dispatch<Record<string, any>>, dealId: string, disbursementId: number) {
    deleteDealDisbursement(dealId, String(disbursementId))
        .then(function () {
            dispatch({ type: actions.DELETE_DISBURSEMENT, payload: disbursementId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete disbursement: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getDisbursementsInDeal,
    saveDisbursementInDeal,
    createNewDisbursement,
    deleteDisbursement
}