import { AdminSections, Sections } from "../enums/sections";

export const SectionMapping = {
    "deal-overview": Sections.DealOverview,
    "client-information": Sections.ClientInformation,
    "property-information": Sections.PropertyInformation,
    "existing-encumbrances": Sections.Encumbrances,
    "new-mortgage": Sections.NewMortgage,
    "fire-insurance": Sections.FireInsurance,
    "title-insurance": Sections.TitleInsurance,
    "statement-of-account": Sections.StatementOfAccount,
    "trust-ledger": Sections.TrustLedger,
    "unsecured-debts": Sections.UnsecuredDebts,
    "land-transfer-tax": Sections.LandTransferTax,
    "conditions": Sections.Conditions
}

export const RouteSectionMapping = {
    [Sections.DealOverview]: "deal-overview",
    [Sections.ClientInformation]: "client-information",
    [Sections.PropertyInformation]: "property-information",
    [Sections.Encumbrances]: "existing-encumbrances",
    [Sections.NewMortgage]: "new-mortgage",
    [Sections.FireInsurance]: "fire-insurance",
    [Sections.TitleInsurance]: "title-insurance",
    [Sections.StatementOfAccount]: "statement-of-account",
    [Sections.TrustLedger]: "trust-ledger",
    [Sections.UnsecuredDebts]: "unsecured-debts",
    [Sections.LandTransferTax]: "land-transfer-tax",
    [Sections.Conditions]: "conditions"
}

export const AdminSectionMapping = {
    "lenders": AdminSections.Lender,
    "legal-professionals": AdminSections.LegalProfessional,
    "mortgage-brokerages": AdminSections.MortgageBrokerage,
    "companies": AdminSections.ChargeHolderCompany,
    "governments": AdminSections.Governments,
    "other-charge-holders": AdminSections.OtherChargeHolder,
    "fire-insurance-companies": AdminSections.FireInsuranceCompany,
    "fire-insurance-brokerages": AdminSections.FireInsuranceBrokerage,
    "condo-corporations": AdminSections.CondoCorporation,
    "property-management-companies": AdminSections.PropertyManagementCompany,
    "real-estate-brokerages": AdminSections.RealEstateBrokerage
}

export const AdminRouteSectionMapping = {
    [AdminSections.Lender]: "lenders",
    [AdminSections.LegalProfessional]: "legal-professionals",
    [AdminSections.MortgageBrokerage]: "mortgage-brokerages",
    [AdminSections.ChargeHolderCompany]: "companies",
    [AdminSections.Governments]: "governments",
    [AdminSections.OtherChargeHolder]: "other-charge-holders",
    [AdminSections.FireInsuranceCompany]: "fire-insurance-companies",
    [AdminSections.FireInsuranceBrokerage]: "fire-insurance-brokerages",
    [AdminSections.CondoCorporation]: "condo-corporations",
    [AdminSections.PropertyManagementCompany]: "property-management-companies",
    [AdminSections.RealEstateBrokerage]: "real-estate-brokerages"
}