import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getProperties,
    saveDealProperty,
    getProperty,
    newDealProperty,
    removePropertyPin,
    createPropertyPin,
    updatePropertyPin,
    removeProperty
} from "../../../libs/service/axios/api";
import { TabOption, TabOptions } from "../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { Property } from "../../../libs/types/UniversalSurvey/Property/property";
import { sanitizePropertyResponse, sanitizeSimplePropertiesResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizePropertyPinRequest, sanitizePropertyRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultProperty } from "../../../libs/resources/defaults/frontend/defaultProperty";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleProperty } from "../../../libs/types/UniversalSurvey/Property/simpleProperty";
import { RoleMapping } from "../../../libs/resources/mappings/roles";
import { sortByArrayOrder } from "../../../libs/utils/arrays";
import { navigateURL } from "./deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { defaultSimplePropertyPin } from "../../../libs/resources/defaults/frontend/defaultSimplePropertyPin";
import { SimplePropertyPin } from "../../../libs/types/UniversalSurvey/PropertyPins/simplePropertyPin";
import { Role } from "../../../libs/types/UniversalSurvey/Roles/role";
import { RoleTypesDB } from "../../../libs/resources/enums/roles"
import { SimpleAddress } from "../../../libs/types/UniversalSurvey/Address/simpleAddress";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { Loading } from "../../../libs/resources/enums/loading";
import { addressToStringHeader } from "conveyance/libs/utils/address";

function switchToPropertySection(dispatch: Dispatch<Record<string, any>>, dealId: string, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getProperties(dealId)
        .then(function (response: any) {
            const propertyList = sanitizeSimplePropertiesResponse(response.data);
            const propertyTabs: TabOptions = [];
            for (let i = 0; i < propertyList.length; ++i) {
                const property = propertyList[i];
                propertyTabs.push({
                    title: getPropertyTabName(property),
                    itemId: property.id,
                    subTitle: property.roles?.at(0)?.role ? RoleMapping[property.roles?.at(0)?.role!] : undefined,
                    missingFields: 0,
                });
            }
            sortByArrayOrder(propertyTabs, "property");
            if (propertyTabs.length > 0 && !hasEntityId) {
                navigateURL(dispatch, dealId, "data-sheet", Sections.PropertyInformation, String(propertyTabs[0].itemId));
            }
            dispatch({ type: actions.SET_TAB_OPTIONS, payload: propertyTabs });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Property tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function setAllDealProperties(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getProperties(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.SET_PROPERTIES_IN_DEAL, payload: sanitizeSimplePropertiesResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Property tabs: ${error}`, type: AlertTypes.Error } });
        })
}

function saveProperty(dispatch: Dispatch<Record<string, any>>, dealId: string, propertyInfo: Property) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    saveDealProperty(dealId, String(propertyInfo.id), sanitizePropertyRequest(propertyInfo))
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save property: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addDealProperty(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    newDealProperty(dealId)
        .then(function (response: any) {
            const tab: TabOption = {
                title: getPropertyTabName(defaultProperty),
                itemId: response.data.property_id,
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            navigateURL(dispatch, dealId, "data-sheet", Sections.PropertyInformation, String(response.data.property_id));
            dispatch({
                type: actions.SET_PROPERTY_INFO,
                payload: {
                    ...defaultProperty,
                    id: response.data.property_id,
                }
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New deal property: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function changeSelectedProperty(dispatch: Dispatch<Record<string, any>>, dealId: string, propertyId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getProperty(dealId, String(propertyId))
        .then(function (response: any) {
            const cleanProperty = sanitizePropertyResponse(response.data)
            dispatch({ type: actions.SET_PROPERTY_INFO, payload: cleanProperty });
            updatePropertyTabName(dispatch, cleanProperty);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Property section content: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function updatePropertyTabName(dispatch: Dispatch<Record<string, any>>, property: Property) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION,
        payload: {
            title: getPropertyTabName(property),
            itemId: property.id,
            subTitle: property.roles?.at(0)?.role ? RoleMapping[property.roles?.at(0)?.role!] : undefined,
            missingFields: 0,
        }
    })
}

function updatePropertyHeaderTitle(dispatch: Dispatch<Record<string, any>>, property: Property, roles: Role[], dealInfo: Deal | undefined) {
    // Is subject property
    let isSubject: boolean = ( (roles.filter((role) => role.role === RoleTypesDB.Subject).length > 0) && (property.id === dealInfo?.subject_property_id) );
    if (isSubject) {
        let newAddress: SimpleAddress = { ...property.address };
        dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "subject_property_address", value: newAddress } });
    }
}

function getPropertyTabName(property: Property | SimpleProperty | undefined) {
    if (!property?.address.street_address) return "New Property";
    return addressToStringHeader(property.address);
}

function savePropertyPin(dispatch: Dispatch<Record<string, any>>, dealId: string, propertyId: number, pinInfo: SimplePropertyPin) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    updatePropertyPin(dealId, String(propertyId), String(pinInfo.id), sanitizePropertyPinRequest(pinInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save property PIN: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addPropertyPin(dispatch: Dispatch<Record<string, any>>, dealId: string, propertyId: number) {
    createPropertyPin(dealId, String(propertyId))
        .then(function (response: any) {
            dispatch({ type: actions.ADD_PROPERTY_PIN, payload: { ...defaultSimplePropertyPin, id: response.data.id } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create property PIN: ${error}`, type: AlertTypes.Error } });
        })
}

function deletePropertyPin(dispatch: Dispatch<Record<string, any>>, dealId: string, propertyId: number, pinId: number) {
    removePropertyPin(dealId, String(propertyId), String(pinId))
        .then(function () {
            dispatch({ type: actions.REMOVE_PROPERTY_PIN, payload: pinId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Remove property PIN: ${error}`, type: AlertTypes.Error } });
        })
}

function deleteDealProperty(dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, tabOptions: TabOptions, isActive: boolean) {
    removeProperty(dealId, String(entityId))
    .then(function (response: any) {
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: entityId });
        if (isActive) {
            if (tabOptions.length > 1) {
                let newOption = tabOptions.find((value) => value.itemId !== entityId);
                if (newOption) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.PropertyInformation, String(newOption.itemId));
                } else {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.PropertyInformation, undefined);
                    dispatch({ type: actions.SET_PROPERTY_INFO, payload: undefined });
                }
            } else {
                navigateURL(dispatch, dealId, "data-sheet", Sections.PropertyInformation, undefined);
                dispatch({ type: actions.SET_PROPERTY_INFO, payload: undefined });
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete property: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

export {
    switchToPropertySection,
    setAllDealProperties,
    saveProperty,
    addDealProperty,
    changeSelectedProperty,
    updatePropertyTabName,
    getPropertyTabName,
    savePropertyPin,
    addPropertyPin,
    deletePropertyPin,
    deleteDealProperty,
    updatePropertyHeaderTitle
}