import { useEffect, useState } from "react";

import { Box, Dialog, Stack, styled } from "@mui/material";

import { useScrollWithShadow } from "../../../conveyance/libs/hooks/useScrollWithShadow";

import color from '../componentStyling/Colors';
import { H2, H5 } from '../Typography/index';
import BasicButton from "../Button/BasicButton";
import GenericDialog from "./GenericDialog";
import Colors from "../componentStyling/Colors";
import { BACKGROUND_MODAL_CONTAINER, PAPER_PROPS } from "../componentStyling/Styles";
import CircularLoader from "../Loader/CircularLoader";
import { usePrevious } from "../../../conveyance/libs/hooks/usePrevious";

export type ModalProps = {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    closeAfterSaving?: () => void;
    readonly?: boolean;
    size?: string;
    title?: string;
    subtitle?: string;
    showSubtitle?: boolean;
    showPills?: boolean;
    children?: JSX.Element;
    discardButtonLabel?: string;
    saveButtonLabel?: string;
    saveDisabled?: boolean;
    deleteButton?: boolean;
    confirmDeleteText?: string;
    onDelete?: () => void;
    saveDisabledOnClick?: () => void;
    hideBackdrop?: boolean;
    top?: number;
    confirmDeleteButtonText?: string;
    headerContent?: JSX.Element;
    isLoading?: boolean;
    isSaving?: boolean;
}

const MS_BEFORE_CLEANUP = 2000;
const INTERVAL_MS = 100;

export default function ModalBase(props: ModalProps) {
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
    const [scrollHeight, setScrollHeight] = useState<number | undefined>(undefined);
    const [clientHeight, setClientHeight] = useState<number | undefined>(undefined);
    const { boxShadow, onScrollHandler } = useScrollWithShadow({ clientHeight: clientHeight, scrollHeight: scrollHeight });


    function handleDeleteConfirm() {
        props.onDelete ? props.onDelete() : undefined;
        setConfirmDeleteModalOpen(false);
    }

    // Because we cannot access/guarantee the div measurements of modal children content on some DOM state/tree dependency
    // on new modal renders, running interval (100ms) to calculate the height dimensions of the updated pre-rendered div
    // This gaurantee proper shadow calculations on render not only on initial scroll through useScrollWithShadow hook
    useEffect(() => {
        // Only run interval if dialog is open
        let secondsExecuted = 0;
        if (props.open) {
                const interval = setInterval(() => {
                    // This is primary problem: cannot find this updated element on every modal render due to MUI dialog DOM heiarchy constraints
                    if (document.getElementById("content-wrapper")) {
                        setScrollHeight(document.getElementById("content-wrapper")?.scrollHeight);
                        setClientHeight(document.getElementById("content-wrapper")?.clientHeight);
                    }
                    if (clientHeight === undefined && scrollHeight === undefined) {
                        clearInterval(interval);
                    }
                    if (secondsExecuted > MS_BEFORE_CLEANUP) {
                        clearInterval(interval);
                    }
                    secondsExecuted += INTERVAL_MS;
                }, INTERVAL_MS);

                // Block app scroll on modal open
                document.documentElement.style.overflow = "hidden";

                // Clear interval on modal close
                // Unset style blocking scroll
                return () => {
                    clearInterval(interval);
                    document.documentElement.style.overflow = "unset";
                }
        }
      }, [props.open]);

    function clearHeights() {
        setScrollHeight(undefined);
        setClientHeight(undefined);
    }

    function onClose(event: any, reason: "backdropClick" | "escapeKeyDown") {
        if (reason === "escapeKeyDown") {
            props.onClose ? props.onClose() : undefined;
        } else {
            props.readonly ? props.onClose ? props.onClose() : undefined : undefined;
        }
        clearHeights();
    }

    function discard() {
        clearHeights();
        props.onClose ? props.onClose() : undefined;
    }

    const prevSaving = usePrevious(props.isSaving);
    useEffect(() => {
        if (prevSaving === true && props.isSaving === false && props.closeAfterSaving) {
            props.closeAfterSaving();
        }
    }, [props.isSaving])

    return (
        <Dialog
            fullWidth
            PaperProps={{ sx: PAPER_PROPS }}
            maxWidth={props.size === "small" ? "sm" : "md"}
            open={props.open}
            sx={{ backgroundColor: props.hideBackdrop ? undefined : props.readonly ? Colors.BLACK_25o : Colors.BLACK_75o }}
            slotProps={{
                backdrop: {invisible: true}
            }}
            onClose={(event: any, reason: "backdropClick" | "escapeKeyDown") => onClose(event, reason)}
            style={{ top: props.top ?? undefined }}
        >   
            {props.deleteButton && (
                <Dialog
                    fullWidth
                    PaperProps={{ sx: {...PAPER_PROPS, backgroundColor: color.ORANGE_90o} }}
                    maxWidth={props.size === "small" ? "sm" : "md"}
                    open={confirmDeleteModalOpen}
                    hideBackdrop
                >
                    <BACKGROUND_MODAL_CONTAINER height={document.getElementById("outer-modal")?.getBoundingClientRect().height}/>
                    <GenericDialog
                        action="destructive"
                        open={confirmDeleteModalOpen}
                        title="Confirm Delete"
                        contentText={props.confirmDeleteText}
                        onCancel={() => setConfirmDeleteModalOpen(false)}
                        onSubmit={() => handleDeleteConfirm()}
                        hideBackdrop
                        submitText={props.confirmDeleteButtonText ?? undefined}
                    />
                </Dialog>
            )}

            <ModalContainer id="outer-modal">
                <ModalHeader>
                    <H2>
                        {props.title ? props.title : "Modal Title"}
                    </H2>
                    {props.subtitle && (
                        <ModalSubtitleHeader>
                            <H5>
                                {props.subtitle}
                            </H5>
                        </ModalSubtitleHeader>
                    )}
                    {props.headerContent}
                </ModalHeader>
                <ModalContentWrapper id={"content-wrapper"} onScroll={onScrollHandler} style={{ boxShadow }}>
                    <ModalContent>
                        {props.isLoading ? <LoaderDiv><CircularLoader containerHeight={"12vh"} /></LoaderDiv> : <>{props.open ? props.children : ""}</>}
                    </ModalContent>
                </ModalContentWrapper>
                {!props.readonly && (
                    <ModalButtonRow>
                        <Stack direction="row" >
                        {props.deleteButton && <BasicButton typeOf="destructive" onClick={() => setConfirmDeleteModalOpen(true)} label={{text: "Delete", inputId: "delete"}} />}
                            <Stack direction="row" justifyContent="flex-end" gap={2} flexGrow={1}>
                                <BasicButton typeOf="dismissive" onClick={discard} label={{text: props.discardButtonLabel ?? "Discard", inputId: "discard"}} />
                                {props.onSubmit && (
                                    <BasicButton isLoading={props.isSaving} typeOf="primary" onClick={props.onSubmit} label={{text: props.saveButtonLabel ?? "Save", inputId: "save"}} disabled={props.saveDisabled} disabledOnClick={props.saveDisabledOnClick} />
                                )}
                            </Stack>
                        </Stack>
                    </ModalButtonRow>
                )}
            </ModalContainer>
        </Dialog>
    )
}

const LoaderDiv = styled('div')({
    height: "12vh",
    margin: "1.5rem 0rem"
})

const ModalContainer = styled(Box)({
    height: "100%"
});

const ModalHeader = styled(Box)<{
    height?: string;
}>(({ height }) => ({
    padding: "2rem",
    position: "sticky",
    top: 0,
    paddingTop: "2rem",
    zIndex: 100,
    background: color.WHITE,
    height: height ?? undefined,
    display: "block"
}));

const ModalSubtitleHeader = styled(Box)({
    padding: "0.5rem 0rem 0.5rem 0rem"
})

const ModalContentWrapper = styled('div')({
    overflowY: "auto",
    maxHeight: "calc(80vh - 17rem)",
});

const ModalContent = styled('div')({
    padding: "0rem 2rem 0rem 2rem",
})

const ModalButtonRow = styled(Box)({
    padding: "2rem 2rem 0rem 2rem",
    position: "sticky",
    bottom: 0,
    alignContent: "center",
    zIndex: 100,
    background: color.WHITE,
    height: "9rem",
    display: "block"
});