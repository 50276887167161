import * as actions from "../actionTypes"

import { CondoCorporation, CondoCorporationListItem } from "../../../libs/types/UniversalSurvey/CondoCorporation/condoCorporation";
import { findAndReplace } from "../../../libs/utils/arrays";

export type CondoCorporationsState = {
    optionList: CondoCorporationListItem[];
    selectedCC: CondoCorporationListItem | undefined;
    editing: boolean;
    condoCorpInfo: CondoCorporation | undefined;
}

export const defaultCondoCorporationsState: CondoCorporationsState = {
    optionList: [],
    selectedCC: undefined,
    editing: false,
    condoCorpInfo: undefined
}

export function condoCorporationsReducer(state: CondoCorporationsState, action: Record<string, any>): CondoCorporationsState {
    switch (action.type) {
        case actions.SET_CONDO_CORPORATION_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_CONDO_CORPORATION:
            const selectedCC = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedCC: selectedCC };
        case actions.REPLACE_CONDO_CORPORATION_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_CONDO_CORPORATION_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_CONDO_CORPORATION:
            return { ...state, editing: action.payload };
        case actions.SET_CONDO_CORPORATION_INFO:
            return { ...state, condoCorpInfo: action.payload };
        default:
            return state;
    }
}