import { SimpleLenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";
import { AdminActionTypes } from "../actionTypes"
import { AdminActions } from "../context";
import { LenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/lenderBranch";
import { SourceLender } from "../../../libs/types/UniversalSurvey/Lender/SourceLender/sourceLender";
import { SimpleSourceLender } from "../../../libs/types/UniversalSurvey/Lender/SourceLender/simpleSourceLender";
import { findAndReplace } from "../../../libs/utils/arrays";


export type AdminLenderState = {
    tableLenders: SimpleLenderBranch[];
    allLenderSources: SimpleSourceLender[];
    selectedLenderBranch: LenderBranch | undefined;
    selectedLenderSource: SourceLender | undefined;
    editing: boolean;
    shouldRefresh: boolean;
    resultCount: number;
    lenderModalActive: boolean;
}

export const defaultAdminLendersState: AdminLenderState = {
    tableLenders: [],
    allLenderSources: [],
    selectedLenderBranch: undefined,
    selectedLenderSource: undefined,
    editing: false,
    shouldRefresh: false,
    resultCount: 0,
    lenderModalActive: false
}

export function lendersReducer(state: AdminLenderState, action: AdminActions): AdminLenderState {
    switch (action.type) {
        case AdminActionTypes.SET_TABLE_LENDERS:
            return { ...state, tableLenders: action.payload };
        case AdminActionTypes.SET_ALL_LENDERS:
            return { ...state, allLenderSources: action.payload };
        case AdminActionTypes.SET_SELECTED_LENDER_BRANCH:
            return { ...state, selectedLenderBranch: action.payload };
        case AdminActionTypes.UPDATE_SOURCE_LENDERS_IN_TABLE:
            let modifiedBranchTableList = state.tableLenders.map(lender => {
                if (lender.lender?.id === action.payload.id) {
                    return { ...lender, lender: { ...action.payload }}
                }
                return lender;
            });
            return { ...state, tableLenders: modifiedBranchTableList };
        case AdminActionTypes.SET_SELECTED_LENDER_SOURCE:
            return { ...state, selectedLenderSource: action.payload };
        case AdminActionTypes.SET_EDITING_LENDER:
            return { ...state, editing: action.payload };
        case AdminActionTypes.SET_LENDER_MODAL_ACTIVE:
            return { ...state, lenderModalActive: action.payload };
        case AdminActionTypes.SET_LENDER_SHOULD_REFRESH:
            return { ...state, shouldRefresh: action.payload };
        case AdminActionTypes.UPDATE_LENDER_IN_TABLE:
            return { ...state, tableLenders: findAndReplace([...state.tableLenders], ["id"], [action.payload.id], action.payload.newData) };
        case AdminActionTypes.SET_LENDER_RESULT_COUNT:
            return { ...state, resultCount: action.payload };
        default:
            return state;
    }
}