// Importing built-in React/Router tools
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Importing state management
import { UniversalSurveyContext } from '../../context/UniversalSurvey/context';
import * as actions from "../../context/UniversalSurvey/actionTypes";

// Importing application components
import USHeader from './Header/USHeader';
import DataSheetGridControl from './Components/Grid/DataSheetGridControl';
import SectionBase from './Sections/SectionBase';
import CircularLoader from '../../../components/Common/Loader/CircularLoader';
import DocumentsContainer from './Documents/Documents';
import TabBar from './Components/TabBar/TabBar';
import { getDealInfo, handleTabChange, navigateURL } from '../../context/UniversalSurvey/asyncActions/deal';
import { handleEntityChange, handleSectionChange, OptionalParameters } from '../../context/UniversalSurvey/asyncActions/dataSheet';
import { getAllTransactions } from '../../context/UniversalSurvey/asyncActions/transactions/transactions';
import Alert from '../../../components/Common/Alerts/Alerts';
import { Tabs } from '../../libs/resources/enums/tabs';
import { tabOptions } from '../../libs/resources/tabOptions';
import { TabMapping } from '../../libs/resources/mappings/tabs';
import { Sections } from '../../libs/resources/enums/sections';
import NotFound from '../404/NotFound';
import { getRegionList } from '../../context/UniversalSurvey/asyncActions/regions';
import { DCP_PAGE, DCP_PAGE_CONTAINER } from '../../../components/Common/componentStyling/Styles';
import NotesContainer from './Notes/NotesContainer';
import { getUser } from '../../context/UniversalSurvey/asyncActions/user';
import storage from '../../../utils/storage';

export default function UniversalSurveyBase() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const history = useHistory();
    const { dealId, tab, section, entityId } = useParams<{ dealId: string, tab: string, section: string | undefined, entityId: string | undefined }>();
    const { enqueueSnackbar } = useSnackbar();
    const [dealIssue, setDealIssue] = useState<boolean>(false);

    useEffect(() => {
        if (section) {
            let optionalParams: OptionalParameters = {};
            if(state.fireInsurance.addingFireInsuranceFromPropertySection) {
                optionalParams = {
                    addingFireInsuranceFromPropertySection: state.fireInsurance.addingFireInsuranceFromPropertySection,
                    propertyToAddFireInsuranceFor: state.properties.propertyInfo
                }
            }
            handleSectionChange(dispatch, section, dealId, entityId ? true : false, optionalParams);
        } else {
            if (TabMapping[tab as keyof typeof TabMapping] === Tabs.DataSheet) {
                navigateURL(dispatch, dealId, tab, state.dataSheet.currSection ?? Sections.DealOverview, undefined)
            }
        }
        if (tab) {
            handleTabChange(dispatch, tab);
        }
    }, [section, tab]);

    useEffect(() => {
        if (entityId && state.dataSheet.currSection) {
            handleEntityChange(dispatch, String(state.dataSheet.currSection), dealId, Number(entityId))
        }
    }, [entityId, state.dataSheet.currSection]);

    useEffect(() => {
        if (dealId && !isNaN(Number(dealId))) {
            getAllTransactions(dispatch, dealId);
            getDealInfo(dispatch, dealId);
            setDealIssue(false);
            getRegionList(dispatch);
        } else {
            setDealIssue(true);
        }
    }, [dealId]);

    useEffect(() => {
        if (state.deal.url) {
            history.push(state.deal.url);
            setDealIssue(false);
        }
    }, [state.deal.url]);

    useEffect(() => {
        if(state.alerts.message) {
            enqueueSnackbar(state.alerts.message, {
                content: (key: any, message: string) => (
                    <Alert
                        variant={state.alerts.type}
                        textContent={message}
                        primaryAction={state.alerts.primaryAction}
                        primaryActionText={state.alerts.primaryText}
                        secondaryAction={state.alerts.secondaryAction}
                        secondaryActionText={state.alerts.secondaryText}
                    />
                ),
            })
        }
    }, [state.alerts])

    useEffect(() => {
        const userId = storage.getUserId();
        getUser(dispatch, String(userId));

        return () => dispatch({ type: actions.RESET_ALERT_DATA });
    }, [])

    return (
        <>
            {dealIssue ? (
                <NotFound />
            ) : (
                <DCP_PAGE>
                    {state.deal.isLoading ? (
                        <CircularLoader containerHeight="50vh" />
                    ) : (
                        state.deal.currTab && !dealIssue && (
                            <DCP_PAGE_CONTAINER>
                                <USHeader />
                                <TabBar tabOptions={tabOptions} />
                                {state.deal.currTab === Tabs.DataSheet && state.dataSheet.currSection && (
                                    <>
                                        <DataSheetGridControl />
                                        <SectionBase />
                                    </>
                                )}
                                {state.deal.currTab === Tabs.Docs && (
                                    <DocumentsContainer />
                                )}
                                {state.deal.currTab === Tabs.Notes && (
                                    <NotesContainer />
                                )}
                            </DCP_PAGE_CONTAINER>
                        )
                    )}
                </DCP_PAGE>
            )}
        </>
    );
}
