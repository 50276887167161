import { Dispatch } from "react";
import * as actions from "../../actionTypes"
import { deleteTrustLedgerPaymentGroup, getTrustLedgerPaymentGroups, newTrustLedgerPaymentGroup, saveTrustLedgerPayment, saveTrustLedgerPaymentGroup } from "../../../../libs/service/axios/api";
import { sanitizePaymentGroupsResponse } from "../../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { CreatePaymentGroup, PaymentGroup } from "../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import { PaymentRecipientType } from "../../../../libs/resources/enums/paymentGroup";
import { formatThirdPartyTransfer } from "../../../../libs/utils/formatValues";
import { sanitizeCreatePaymentGroupRequest } from "../../../../libs/types/UniversalSurvey/utils/convertRequest";
import { DeliveryMethod } from "../../../../libs/resources/enums/deliveryMethod";
import { getTrustLedgersInDeal } from "./trustLedgers";
import { PaymentGroupRequest } from "../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroupRequest";
import { Loading } from "../../../../libs/resources/enums/loading";
import { getClientTabName } from "../clients";

async function getTrustLedgerPaymentGroupsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, loading: boolean = true) {
    if (loading) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.TrustLedgerPaymentGroupList, isLoading: true } });
    getTrustLedgerPaymentGroups(dealId)
        .then(function (response: any) {
            const cleanItems = sanitizePaymentGroupsResponse(response.data);
            dispatch({ type: actions.SET_PAYMENT_GROUPS, payload: cleanItems });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting deal trust ledger payment groups: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            if (loading) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.TrustLedgerPaymentGroupList, isLoading: false } });
        })
}

async function createNewPaymentGrouping(dispatch: Dispatch<Record<string, any>>, dealId: string, createPaymentGroupInfo: CreatePaymentGroup) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCreatePaymentGroup, isLoading: true } });
    newTrustLedgerPaymentGroup(dealId, sanitizeCreatePaymentGroupRequest(createPaymentGroupInfo))
        .then(function () {
            getTrustLedgerPaymentGroupsInDeal(dispatch, dealId, false);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create deal trust ledger payment group: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCreatePaymentGroup, isLoading: false } });
        })
}

async function updateTrustLedgerPayment(dispatch: Dispatch<Record<string, any>>, dealId: string, trustLedgerId: string, paymentGroupId: number, deliveryMethod: DeliveryMethod | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChangePaymentGroup, isLoading: true } });
    saveTrustLedgerPayment(dealId, trustLedgerId, { payment_group_id: paymentGroupId, delivery_method: deliveryMethod ?? null })
        .then(function () {
            getTrustLedgersInDeal(dispatch, dealId);
            getTrustLedgerPaymentGroupsInDeal(dispatch, dealId, false);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save deal trust ledger payment: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChangePaymentGroup, isLoading: false } });
        })
}

async function updatePaymentGroup(dispatch: Dispatch<Record<string, any>>, dealId: string, paymentGroupId: string, paymentGroup: PaymentGroupRequest) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUpdatePaymentGroup, isLoading: true } });
    saveTrustLedgerPaymentGroup(dealId, paymentGroupId, paymentGroup)
        .then(function () {
            getTrustLedgerPaymentGroupsInDeal(dispatch, dealId, false);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create deal trust ledger payment group: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUpdatePaymentGroup, isLoading: false } });
        })
}

async function deletePaymentGrouping(dispatch: Dispatch<Record<string, any>>, dealId: string, paymentGroupId: string) {
    deleteTrustLedgerPaymentGroup(dealId, paymentGroupId)
        .then(function () {
            getTrustLedgersInDeal(dispatch, dealId);
            getTrustLedgerPaymentGroupsInDeal(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete deal trust ledger payment group: ${error}`, type: AlertTypes.Error } });
        })
}

function getRecipientName(paymentGroup: PaymentGroup | undefined, defaultString: string): string {
    let name = "";
    switch (paymentGroup?.recipient_type) {
        case PaymentRecipientType.Client:
            name = getClientTabName(paymentGroup.client, true);
            break;
        case PaymentRecipientType.ThirdPartyTransfer:
            if (!paymentGroup.third_party_transfer) {
                name += defaultString;
            } else {
                name += formatThirdPartyTransfer(paymentGroup.third_party_transfer);
            }
            break;
        case PaymentRecipientType.Company:
            name += paymentGroup.company?.name ?? defaultString;
            break;
        case PaymentRecipientType.Government:
            name += paymentGroup.government?.name ?? defaultString;
            break;
        case PaymentRecipientType.Lawyer:
            name += paymentGroup.lawyer?.name ?? defaultString;
            break;
        case PaymentRecipientType.Lender:
            name += paymentGroup.lender?.name ?? defaultString;
            break;
        case PaymentRecipientType.MortgageBrokerage:
            name += paymentGroup.mortgage_brokerage?.name ?? defaultString;
            break;
        case PaymentRecipientType.OtherChargeHolder:
            name += paymentGroup.mortgage_brokerage?.name ?? defaultString;
            break;
        default:
            name += defaultString;
            break;
    }
    return name;
}

export {
    getTrustLedgerPaymentGroupsInDeal,
    createNewPaymentGrouping,
    getRecipientName,
    updateTrustLedgerPayment,
    deletePaymentGrouping,
    updatePaymentGroup
}