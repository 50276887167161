import { ChargeHolderType } from "../../resources/enums/chargeHolderType";
import { Lien } from "../../types/UniversalSurvey/ExistingLien/lien";
import { SimpleLien } from "../../types/UniversalSurvey/ExistingLien/simpleLien";


export function getExistingLienTabNameFromObject(lien: SimpleLien | Lien) {
    switch (lien.charge_holder_type) {
        case ChargeHolderType.Lender:
            return getExistingLienTabName(lien.lender_record?.abbr_name ?? lien.lender_record?.name);
        case ChargeHolderType.Company:
            return getExistingLienTabName(lien.charge_holder_company_record?.abbr_name ?? lien.charge_holder_company_record?.name);
        case ChargeHolderType.Government:
            return getExistingLienTabName(lien.government_record?.abbr_name ?? lien.government_record?.name);
        case ChargeHolderType.Other:
            return getExistingLienTabName(lien.other_charge_holder_record?.name);
        default:
            return getExistingLienTabName(undefined);
    }
}

export function getExistingLienTabName(tabName: string | undefined | null) {
    return tabName ?? "";
}