import { SimpleTrustLedger } from "../../../types/UniversalSurvey/TrustLedger/simpleTrustLedger";
import { TrustLedgerSource } from "../../enums/trustLedger";

export const defaultSimpleTrustLedger: SimpleTrustLedger = {
    id: -1, 
    type: undefined,
    source: TrustLedgerSource.Manual,
    amount: 0,
    payment_group_id: undefined,
    delivery_method: undefined,
    name: undefined,
    date_created: undefined,

    children: []
}