import { H4, Small } from '../Typography';
import color from './Colors';
import { Grid, styled, Box } from '@mui/material';
import Theme from './Theme';

// BORDERS
export const BORDER_RADIUS_ACTION_W_ICON = "1rem 5rem 5rem 1rem";

export const BORDER_1 = (color: string) => {
    return `0.1rem solid ${color}`;
}

export const BORDER_2 = (color: string) => {
    return `0.2rem solid ${color}`;
}

export const BORDER_4 = (color: string) => {
    return `0.4rem solid ${color}`;
}

export const MAX_BODY_INFORMATION = "700px"

// SHADOW
export const FOCUS_BOX_SHADOW = `0rem 0rem 0.5rem ${color.BLUE_500}`;

// CONTAINERS
export const PILL_CONTAINER = {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem"
}

// TEXT
export const MAIN_TITLE_TEXT = {
    fontSize: "4.8rem",
    lineHeight: "6.6rem",
    fontWeight: "700"
}

export const H1_TEXT = {
    fontSize: "4rem",
    lineHeight: "5.5rem",
    fontWeight: "700"
}

export const H2_TEXT = {
    fontWeight: "600",
    fontSize: "3.2rem",
    lineHeight: "4.4rem"
}

export const H3_TEXT = {
    fontWeight: "600",
    fontSize: "2.8rem",
    lineHeight: "3.8rem"
}

export const H4_TEXT = {
    fontWeight: "600",
    fontSize: "2.4rem",
    lineHeight: "3.3rem"
}

export const H5_TEXT = {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "2.8rem"
}

export const LARGE_TEXT = {
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    fontWeight: "400"
}

export const LARGE_BOLD_TEXT = {
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    fontWeight: "600"
}

export const BODY_TEXT = {
    fontWeight: "400",
    fontSize: "1.6rem",
    lineHeight: "2.2rem"
}

export const BODY_BOLD_TEXT = {
    fontWeight: "700",
    fontSize: "1.6rem",
    lineHeight: "2.2rem"
}

export const SMALL_TEXT = {
    fontSize: "1.3rem",
    lineHeight: "1.8rem",
    fontWeight: "400"
}

export const SMALL_BOLD_TEXT = {
    fontSize: "1.3rem",
    lineHeight: "1.6rem",
    fontWeight: "700"
}

export const COPIABLE_TEXT = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        textDecoration: 'underline'
    }
}

// CONTAINERS/GRIDS
export const FLEX_GRID = styled(Grid)({
    display: "flex",
    flexDirection: "row",
    gap: "2rem"
})

export const PAPER_PROPS = {
    boxShadow: '0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15), 0rem 0.5rem 2rem rgba(0, 0, 0, 0.25)',
    borderRadius: "0.6rem",
    width: "100%",
    backgroundColor: color.WHITE,
    maxHeight: "80vh",
    overflow: "hidden"
}

export const BACKGROUND_MODAL_CONTAINER = styled(Box)<{
    height?: number;
}>(({ height }) => ({
    height: height
}));

export const PADDED_GRID = styled(Grid)({
    paddingBottom: "2.4rem"
})

export const DCP_PAGE = styled(Box)({
    background: color.WHITE,
    minHeight: "calc(100vh - 6rem)",
    padding: "2.3rem 8rem 1.7rem 2.2rem",
});

export const DCP_PAGE_CONTAINER = styled('div')({
    paddingBottom: "12rem"
});

export const DCP_SECTION_CONTAINER = styled('div')({
    paddingTop: "2rem",
});

export const DCP_SECTION_CONTENT = styled('div')({
    paddingTop: "1.5rem"
});