import { FireInsuranceBrokerage } from "../../../types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultFireInsuranceBrokerage: FireInsuranceBrokerage = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    abbr_name: undefined,
    all_regions: undefined,
    general_address: defaultSimpleAddress,
    phone: undefined,
    email: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    fire_insurance_brokerage: undefined,
    regions: []
}