import { SimpleUndertaking } from "../types/UniversalSurvey/Undertaking/simpleUndertaking";
import { UndertakingStatus } from "../resources/enums/undertakings";

function sortUndertakings(undertakings: SimpleUndertaking[]) {
    undertakings.sort((a, b) => {
        if(a.status === b.status) {
            return 1;
        } else if (a.status === UndertakingStatus.Fulfilled) {
            return -1;
        }
        return 1;
    });
}

export { sortUndertakings }