import * as actions from "../actionTypes"

import { MortgageBrokerageOtherContact } from "../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import { findAndRemove, findAndReplace } from "../../../libs/utils/arrays";

export type MortgageBrokerageOtherContactsState = {
    contactBeingEdited: MortgageBrokerageOtherContact | undefined;
    otherContactsInDeal: MortgageBrokerageOtherContact[];
    otherContactsInMortgage: MortgageBrokerageOtherContact[];
    otherContactBeingReplacedId: number | undefined;
    emptyContactAdded: boolean;
    mortgagesWithOtherContact: number[];
}

export const defaultMortgageBrokerageOtherContactsState: MortgageBrokerageOtherContactsState = {
    contactBeingEdited: undefined,
    otherContactsInDeal: [],
    otherContactsInMortgage: [],
    otherContactBeingReplacedId: undefined,
    emptyContactAdded: false,
    mortgagesWithOtherContact: []
}

export function mortgageBrokerageOtherContactsReducer(state: MortgageBrokerageOtherContactsState, action: Record<string, any>): MortgageBrokerageOtherContactsState {
    switch (action.type) {
        case actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_EDITED:
            return { ...state, contactBeingEdited: action.payload };

        case actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_DEAL:
            return { ...state, otherContactsInDeal: action.payload };

        case actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_MORTGAGE:
            return { ...state, otherContactsInMortgage: action.payload };

        case actions.CREATE_MORTGAGE_BROKERAGE_OTHER_CONTACT:
            return {
                ...state,
                otherContactsInMortgage: [...state.otherContactsInMortgage, action.payload],
                otherContactsInDeal: [...state.otherContactsInDeal, action.payload],
                emptyContactAdded: false
            };

        case actions.UPDATE_MORTGAGE_BROKERAGE_OTHER_CONTACT:
            return {
                ...state,
                otherContactsInDeal: findAndReplace([...state.otherContactsInDeal], ["id"], [action.payload.id], action.payload),
                otherContactsInMortgage: findAndReplace([...state.otherContactsInMortgage], ["id"], [action.payload.id], action.payload)
            };

        case actions.ADD_MORTGAGE_BROKERAGE_OTHER_CONTACT_TO_MORTGAGE:
            return { ...state, otherContactsInMortgage: [...state.otherContactsInMortgage, action.payload] };

        case actions.CHANGE_MORTGAGE_BROKERAGE_OTHER_CONTACT_IN_MORTGAGE:
            return { ...state, otherContactsInMortgage: findAndReplace([...state.otherContactsInMortgage], ["id"], [action.payload.oldContactId], action.payload.newContact) };

        case actions.REMOVE_MORTGAGE_BROKERAGE_OTHER_CONTACT:
            return { ...state, otherContactsInMortgage: findAndRemove([...state.otherContactsInMortgage], ["id"], [action.payload]) };

        case actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_REPLACED:
            return { ...state, otherContactBeingReplacedId: action.payload };

        case actions.DELETE_MORTGAGE_BROKERAGE_OTHER_CONTACT:
            return { ...state, otherContactsInDeal: findAndRemove([...state.otherContactsInDeal], ["id"], [action.payload]), otherContactsInMortgage: findAndRemove([...state.otherContactsInMortgage], ["id"], [action.payload]) };

        case actions.SET_MORTGAGES_WITH_MORTGAGE_BROKERAGE_OTHER_CONTACT:
            return { ...state, mortgagesWithOtherContact: action.payload };

        case actions.SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED:
            return { ...state, emptyContactAdded: action.payload }

        default:
            return state;
    }
}