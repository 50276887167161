import { Grid } from "@mui/material";

import { Undertaking } from "../../../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { undertakingDirectionOptions } from "../../../../../libs/resources/options";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";

type Props = {
    onChange: Function;
    obj: Undertaking;
};

export default function UndertakingForm(props: Props) {

    const { obj, onChange } = props;

    return (
        <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item xs={12}>
                <ComboBox
                    label={{ text: "Undertaking Directions", inputId: "undertaking-directions", isRequired: true }}
                    options={undertakingDirectionOptions}
                    placeholder="The solicitor for the mortgagor hereby personally undertakes to..."
                    value={obj?.direction ?? undefined}
                    onChangeFn={(value) => onChange("direction", value as string)}
                    text={obj.direction}
                    setText={(text: string) => onChange("direction", text)}
                    multiline
                />
            </Grid>
        </Grid>
    );
}