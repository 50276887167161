import * as actions from "../actionTypes"

import { Government, GovernmentListItem } from "../../../libs/types/UniversalSurvey/Government/government";
import { findAndReplace } from "../../../libs/utils/arrays";


export type GovernmentsState = {
    optionList: GovernmentListItem[];
    governmentInfo: Government | undefined;
    selectedGovernment: GovernmentListItem | undefined;
    editingGovernmentRecord: boolean;
}

export const defaultGovernmentsState: GovernmentsState = {
    optionList: [],
    governmentInfo: undefined,
    selectedGovernment: undefined,
    editingGovernmentRecord: false
}

export function governmentsReducer(state: GovernmentsState, action: Record<string, any>): GovernmentsState {
    switch (action.type) {
        case actions.SET_GOVERNMENT_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_GOVERNMENT:
            const selectedGovernment = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedGovernment: selectedGovernment };
        case actions.REPLACE_GOVERNMENT_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldGovernmentId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_GOVERNMENT_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_GOVERNMENT_RECORD:
            return { ...state, editingGovernmentRecord: action.payload };
        case actions.SET_GOVERNMENT_INFO:
            return { ...state, governmentInfo: action.payload };
        default:
            return state;
    }
}