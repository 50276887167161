import * as actions from "../actionTypes"

import { SimpleTitleInsurancePremium } from "../../../libs/types/UniversalSurvey/TitleInsurancePremium/simpleTitleInsurancePremium";
import { findAndRemove } from "../../../libs/utils/arrays";

export type TitleInsurancePremiumsState = {
    premiums: SimpleTitleInsurancePremium[] | undefined;
    premiumsBeingSaved: number[];
    isPremiumSaving: boolean;
    shouldRefreshPremium: boolean;
}

export const defaultTitleInsurancePremiumsState: TitleInsurancePremiumsState = {
    premiums: undefined,
    premiumsBeingSaved: [],
    isPremiumSaving: false,
    shouldRefreshPremium: false,
}

export function titleInsurancePremiumsReducer(state: TitleInsurancePremiumsState, action: Record<string, any>): TitleInsurancePremiumsState {
    switch (action.type) {
        case actions.SET_TITLE_INSURANCE_PREMIUMS:
            return { ...state, premiums: action.payload };
        case actions.ADD_TITLE_INSURANCE_PREMIUM_BEING_SAVED:
            if (!state.premiumsBeingSaved.includes(action.payload)) {
                return { ...state, premiumsBeingSaved: [...state.premiumsBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_TITLE_INSURANCE_PREMIUM_BEING_SAVED:
            let isPremiumSaving = state.isPremiumSaving;
            let shouldRefreshPremium = state.shouldRefreshPremium;
            const tempPremiumsBeingSaved = [...state.premiumsBeingSaved];
            const index = tempPremiumsBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempPremiumsBeingSaved.splice(index, 1);
            }
            if (index >=0 && tempPremiumsBeingSaved.length === 0 && isPremiumSaving) {
                isPremiumSaving = false;
                shouldRefreshPremium = true;
            }
            return {
                ...state,
                premiumsBeingSaved: tempPremiumsBeingSaved,
                isPremiumSaving: isPremiumSaving,
                shouldRefreshPremium: shouldRefreshPremium
            };
        case actions.SET_IS_TITLE_INSURANCE_PREMIUM_SAVING:
            return { ...state, isPremiumSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_TITLE_INSURANCE_PREMIUM:
            return { ...state, shouldRefreshPremium: action.payload };
        case actions.CREATE_TITLE_INSURANCE_PREMIUM:
            return { ...state, premiums: state.premiums ? [...state.premiums, action.payload] : [action.payload] };
        case actions.DELETE_TITLE_INSURANCE_PREMIUM:
            return { ...state, premiums: state.premiums ? findAndRemove([...state.premiums], ["id"], [action.payload]) : undefined };
        default:
            return state;
    }
}