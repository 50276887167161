import * as actions from "../actionTypes"

import { LenderContact, LenderContactListItem } from "../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { findAndRemove, findAndReplace, sortByKey } from "../../../libs/utils/arrays";

export type LenderContactsState = {
    lenderContactsList: LenderContactListItem[];
    lenderContactOptionList: LenderContactListItem[];
    emptyContactAdded: boolean;
    lenderContactBeingEdited: LenderContact | undefined;
    lenderContactBeingReplaced: LenderContactListItem | undefined;
}

export const defaultLenderContactsState: LenderContactsState = {
    lenderContactsList: [],
    lenderContactOptionList: [],
    emptyContactAdded: false,
    lenderContactBeingEdited: undefined,
    lenderContactBeingReplaced: undefined
}

export function lenderContactsReducer(state: LenderContactsState, action: Record<string, any>): LenderContactsState {
    switch (action.type) {
        case actions.SET_LENDER_CONTACTS_OPTION_LIST:
            return { ...state, lenderContactOptionList: action.payload };
        case actions.ADD_LENDER_CONTACT_TO_LIST:
            return { ...state, lenderContactsList: [...state.lenderContactsList, action.payload] };
        case actions.REMOVE_LENDER_CONTACT_FROM_LIST:
            const tempContactsInList = findAndRemove([...state.lenderContactsList], ["id", "isRecord"], [action.payload, true]);
            return { ...state, lenderContactsList: tempContactsInList };
        case actions.REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD:
            return { ...state, lenderContactOptionList: findAndReplace([...state.lenderContactOptionList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.REPLACE_LENDER_CONTACT_WITH_UPDATED_RECORD:
            return { ...state, lenderContactsList: findAndReplace([...state.lenderContactsList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_LENDER_CONTACTS_LIST:
            return { ...state, lenderContactsList: action.payload };
        case actions.SET_LENDER_CONTACT_BEING_EDITED:
            return { ...state, lenderContactBeingEdited: action.payload };
        case actions.SET_LENDER_CONTACT_BEING_REPLACED:
            return { ...state, lenderContactBeingReplaced: action.payload };
        case actions.ADD_LENDER_CONTACT_OPTION:
            const optionsCopy = [...state.lenderContactOptionList];
            optionsCopy.push(action.payload);
            sortByKey(optionsCopy, "label");
            return { ...state, lenderContactOptionList: optionsCopy };
        case actions.SET_EMPTY_LENDER_CONTACT_ADDED:
            return { ...state, emptyContactAdded: action.payload };
        case actions.UPDATE_LENDER_CONTACT_ROLE:
            const tempContactsRoles = [...state.lenderContactsList];
            const tempContact = { ...tempContactsRoles[action.payload.index] };
            tempContact.role = action.payload.value;
            tempContactsRoles[action.payload.index] = tempContact;

            const tempOptionsList = [...state.lenderContactOptionList];
            const tempMatchingOption =  tempOptionsList.find((contact) => contact.id === tempContact.id && contact.isRecord);
            if (tempMatchingOption) {
                tempOptionsList[tempOptionsList.indexOf(tempMatchingOption)] = tempContact;
            }
            return { ...state, lenderContactsList: tempContactsRoles, lenderContactOptionList: tempOptionsList };
        default:
            return state;
    }
}