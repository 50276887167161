import React from "react";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled, SxProps, Theme } from "@mui/material/styles";

import { Large } from "../Typography/index";
import colors from "../componentStyling/Colors";
import theme from "../componentStyling/Theme";
import { SrcTextIcon } from "../Icons/Iconography";
import Label, { labelProps } from "../Label/Label"
import { BORDER_1, BORDER_2, FOCUS_BOX_SHADOW } from "../componentStyling/Styles";
import { BasicTooltipProps } from "../Tooltip/Tooltip";

type DeededSwitchProps = SwitchProps & {
  labels?: [string, string];
  autoFilled?: boolean;
  hasSrcText?: boolean;
  sx?: SxProps<Theme>;
  label?: labelProps;
  tooltipProps?: Omit<BasicTooltipProps, "children">;
};

const BooleanControl = React.forwardRef<HTMLDivElement, DeededSwitchProps>(
  function BooleanControl(props, ref) {
    const { checked, disabled, labels, autoFilled, hasSrcText, sx, label, tooltipProps, ...other } = props;

    return (
      <>
        {label && (
          <Label {...label} tooltipProps={tooltipProps}/>
        )}
        <FlexDiv>
          <StyledTypography color={checked ? theme.PLACEHOLDER : colors.BLACK}>
            {labels ? labels[0] : "No"}
          </StyledTypography>
          <StyledSwitch
            {...other}
            checked={checked ?? false}
            disabled={disabled}
            hasFilled={autoFilled}
            sx={sx}
            id={label?.inputId}
          />
          <StyledTypography color={checked ? colors.BLACK : theme.PLACEHOLDER}>
            {labels ? labels[1] : "Yes"}
          </StyledTypography>
          {hasSrcText && (
            <SrcTextIconWrapper color={autoFilled ? theme.AUTO_FILLED : undefined}>
              <SrcTextIcon color={theme.INPUT} />
            </SrcTextIconWrapper>
          )}
        </FlexDiv>
      </>
    );
  },
);

const StyledSwitch = styled(Switch, { shouldForwardProp: 
  (prop) => prop !== "hasFilled"
})<{
  hasFilled?: boolean;
  checked?: boolean;
}>(({ hasFilled, checked }) => ({
  width: "4rem",
  height: "2.4rem",
  padding: "0rem",
  marginTop: "1rem",
  marginLeft: "2rem",
  overflow: "visible",
  marginRight: "2rem",
  "& .MuiSwitch-track": {
    borderRadius: "1.2rem",
    border: BORDER_1(''),
    borderColor: theme.INPUT,
    backgroundColor: hasFilled ? theme.AUTO_FILLED : colors.GRAY_50,
    opacity: 1,
    height: "auto"
  },
  "& .MuiSwitch-switchBase": {
    padding: "0rem",
    marginTop: "0.2rem",
    marginLeft: "0.2rem",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(1.6rem)",
      "& + .MuiSwitch-track": {
        backgroundColor: hasFilled ? theme.AUTO_FILLED : colors.WHITE,
        opacity: 1,
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: checked ? colors.BLACK : theme.INPUT,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 1,
    },
    '&.Mui-focusVisible + .MuiSwitch-track': {
      outline: BORDER_2(colors.BLUE_500),
      outlineOffset: "0.2rem",
      boxShadow: FOCUS_BOX_SHADOW,
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      appearance: "none"
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    color: checked ? colors.BLACK : theme.INPUT,
    width: "2rem",
    height:"2rem",
  },
}));

const FlexDiv = styled("div")({
  display: "flex",
  marginTop: "1.2rem",
});

const SrcTextIconWrapper = styled("div")<{}>(({ color }) => ({
  width: "4.4rem",
  height: "4.4rem",
  textAlign: "center",
  paddingTop: "1rem",
  backgroundColor: color,
  borderRadius: "0.4rem",
  marginLeft: "2rem",
}));

const StyledTypography = styled(Large)<{
  color: string;
}>(({ color }) => ({
  color: color,
  marginTop: "1rem",
}));

export default BooleanControl;
