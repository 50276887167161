import { AdminActionTypes } from "../actionTypes"
import { AdminSections } from "conveyance/libs/resources/enums/sections";
import { AdminActions } from "../context";


export type AdminNavigationState = {
    currSection: AdminSections | undefined;
    url: string | undefined;
}

export const defaultAdminNavigationState: AdminNavigationState = {
    currSection: undefined,
    url: undefined
}

export function navigationReducer(state: AdminNavigationState, action: AdminActions): AdminNavigationState {
    switch (action.type) {
        case AdminActionTypes.SET_CURR_SECTION:
            return { ...state, currSection: action.payload };
        case AdminActionTypes.SET_PAGE_URL:
            return { ...state, url: action.payload };
        default:
            return state;
    }
}