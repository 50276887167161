import React, { forwardRef, useState, useRef } from "react";

// Importing MUI components
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {styled, SxProps, Theme} from "@mui/material/styles";
import { Stack, FormControl } from "@mui/material";

// Importing application components
import theme from '../componentStyling/Theme';
import color from '../componentStyling/Colors';
import { ChevronDown } from "../Icons/Iconography";
import { SrcTextIcon, ErrorIcon } from "../Icons/Iconography";
import Label, { labelProps} from "../Label/Label";
import HoverActions from "../HoverActions/ActionTooltip";
import BasicTooltip, { BasicTooltipProps } from "../Tooltip/Tooltip";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import { BORDER_2, FOCUS_BOX_SHADOW, LARGE_TEXT } from "../componentStyling/Styles";

type DeededInputProps = Omit<SelectProps, "error"> & {
    options: string[] | number[];
    placeholder: string;
    error?: boolean;
    value?: string | number;
    autoFilled?: boolean;
    sx?: SxProps<Theme>;
    formatValue?: Function;
    label?: labelProps;
    tooltipProps?: Omit<BasicTooltipProps, "children">;
    handleClear?: () => void;
    itemsDisabled?: boolean[];
    disabledMessage?: string;
    errorMessage?: string;
    isHoverActionHidden?: boolean;
    backgroundColor?: string;
};
  
const Dropdown = forwardRef<HTMLDivElement, DeededInputProps>(
    function Dropdown(props, ref) {
        const {
            error,
            value,
            autoFilled,
            placeholder,
            options,
            sx,
            formatValue,
            label,
            tooltipProps,
            itemsDisabled,
            disabledMessage,
            handleClear,
            errorMessage,
            isHoverActionHidden,
            backgroundColor,
            readOnly,
            ...other
        } = props;
    
        const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
        const inputRef = useRef(null);
        
        return (
            <div>
                {typeof label === "object" && (
                    <Label {...label} onClick={readOnly ? undefined : setDropdownOpen} open={dropdownOpen} tooltipProps={tooltipProps}/>
                )}
                <HoverActions
                    isHidden={dropdownOpen || ((isHoverActionHidden === undefined) ? true : isHoverActionHidden ? true : false)}
                    onRemove={handleClear ? handleClear : undefined}
                >
                    <StyledControl fullWidth ref={inputRef}>
                        <StyledSelect
                            {...other}
                            readOnly={readOnly}
                            defaultValue={""}
                            disabled={!Boolean(options.length)}
                            displayEmpty={true}
                            renderValue={value => value ? (formatValue ? formatValue(value) : value) : (<PlaceholderContainer>{options.length === 0 ? "There are no available options" : placeholder}</PlaceholderContainer>)}
                            value={value ?? ""}
                            variant="standard"
                            hasFilled={autoFilled}
                            hasError={error}
                            disableUnderline
                            MenuProps={{
                                disableScrollLock: true,
                                PaperProps: {sx:paper},
                                MenuListProps: {sx:list(inputRef)},
                            }}
                            bgColor={backgroundColor}
                            IconComponent={() => {
                                return (
                                    <IconContainer direction="row" gap={1}>
                                        {autoFilled && (
                                            <SrcTextIcon color={theme.INPUT} />
                                        )}
                                        <ChevronDown color={theme.INPUT} />
                                    </IconContainer>
                                )
                            }}
                            onClick={(e) => e.stopPropagation()}
                            startAdornment={error && <ErrorIconContainer><ErrorIcon color={theme.ERROR} /></ErrorIconContainer>}
                            ref={ref}
                            onClose={() => setDropdownOpen(false)}
                            onOpen={() => setDropdownOpen(true)}
                            open={dropdownOpen}
                        >
                            {options.map((item, i) => (
                                (itemsDisabled && itemsDisabled[i]) ? itemsDisabled[i] : false) ? (
                                    disabledMessage ? (
                                        <BasicTooltip message={disabledMessage} type="error" placement="right">
                                            <MenuItem disabled={true} value={item} key={i}>
                                                {formatValue ? formatValue(item) : item}
                                            </MenuItem>
                                        </BasicTooltip>
                                    ) : (
                                        <MenuItem disabled={true} value={item} key={i}>
                                            {formatValue ? formatValue(item) : item}
                                        </MenuItem>
                                    )
                                ) : (
                                    <MenuItem value={item} key={i}>
                                        {formatValue ? formatValue(item) : item}
                                    </MenuItem>
                                )
                            )}
                        </StyledSelect>
                    </StyledControl>
                </HoverActions>
                {errorMessage && <InputErrorMessage errorMessage={errorMessage}/>}
            </div>
        )
    }
);

// Option menu styling
const list = (inputRef: React.MutableRefObject<any>) => ({
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
        ...LARGE_TEXT,
        color: color.GRAY_700,

        height: "6.1rem",
    },
    "& li[aria-selected=true]": {
        color: color.BLACK,
        background: color.RED_100
    },
    "& li[aria-selected=false]": {
        color: color.GRAY_700,
        background: color.GRAY_50
    },
    "& li.Mui-selected:hover": {
      background: theme.HOVER
    },
    "& li:hover": {
        background: theme.HOVER
    },
    "& li:focus-visible": {
        border: BORDER_2(color.BLUE_500),
        boxShadow: FOCUS_BOX_SHADOW,
    },
    "& li.Mui-disabled": {
        width: (inputRef.current && inputRef.current.getBoundingClientRect().width) ? inputRef.current.getBoundingClientRect().width : undefined,
        color: theme.PLACEHOLDER,
        background: theme.DISABLED,
        pointerEvents: "auto",
        "&:active": {
            pointerEvents: "none"
        }
    }
})

const paper = {
    borderRadius: 0,
    backgroundColor: color.GRAY_50
}


const StyledControl = styled(FormControl)({
    "& .MuiInputBase-root": {
        justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
        paddingRight: "0rem",
        height: "5.1rem",
        '&:focus': {
            border: BORDER_2(color.BLUE_500),
            boxShadow: FOCUS_BOX_SHADOW,
            borderRadius: "0.6rem",
            '&::after': {
                content: '""',
                position: 'absolute',
                left: "0.6rem",
                right: "1rem",
                bottom: '0.4rem',
                height: '0.2rem',
                width: 'calc(100% - 1.6rem)',
                backgroundColor: color.BLACK,
            }
        }
    }
})

const StyledSelect = styled(Select, { shouldForwardProp: 
    (prop) => prop !== "hasError" && prop !== "hasFilled" && prop !== "bgColor"
})<{
    hasError?: boolean;
    hasFilled?: boolean;
    bgColor?: string;
  }>(({ hasError, hasFilled, bgColor }) => ({
    ".MuiSelect-standard": {
        ...LARGE_TEXT,
        color: color.BLACK,
        
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        marginRight: hasFilled ? "-7rem" : "-3.5rem",
        paddingLeft: hasError ? "4.5rem" : 0,
        marginLeft: hasError ? "-3.5rem" : 0,
        
        borderBottom: BORDER_2(hasError ? theme.ERROR : theme.INPUT), 
        backgroundColor: hasError ? color.ORANGE_50 : hasFilled ? theme.AUTO_FILLED : bgColor ? bgColor : 'transparent',
        
        "&:before": {
            borderBottom: BORDER_2(hasError ? theme.ERROR : color.BLACK),
        },
        '&:active': {
            borderBottom: BORDER_2(hasError ? theme.ERROR : color.BLACK),
        },
        '&:focus': {
            borderBottom: BORDER_2(hasError ? theme.ERROR : color.BLACK),
            backgroundColor: hasError ? color.ORANGE_50 : hasFilled ? theme.AUTO_FILLED : bgColor ? bgColor : 'transparent',
        },
        '&:after': {
            borderBottom: BORDER_2(hasError ? theme.ERROR : color.BLACK),
        },
    }
}));

const IconContainer = styled(Stack)({
    paddingRight: "1.5rem"
});

const PlaceholderContainer = styled('div')({
    color: theme.PLACEHOLDER
})

const ErrorIconContainer = styled('div')({
    zIndex: 1,
    marginLeft: "1.2rem"
})

export default Dropdown;