import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getGovernmentContactRecords,
    getGovernmentContacts,
    addGovernmentContactToLien,
    newGovernmentContactRecord,
    removeGovernmentContactFromLien,
    getGovernmentContactRecord,
    saveGovernmentContactRecord
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeGovernmentContactsResponse, sanitizeGovernmentContactResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeGovernmentContactRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { sortByKey } from "../../../libs/utils/arrays";
import { Government } from "../../../libs/types/UniversalSurvey/Government/government";
import { SimpleGovernmentContact } from "../../../libs/types/UniversalSurvey/GovernmentContacts/simpleGovernmentContact";
import { GovernmentContact, GovernmentContactListItem } from "../../../libs/types/UniversalSurvey/GovernmentContacts/governmentContact";
import { Loading } from "../../../libs/resources/enums/loading";

function createGovernmentContactOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentInfo: Government) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactList, isLoading: true } });
    getGovernmentContactRecords(dealId, String(governmentInfo.id))
        .then(function (response: any) {
            const contactRecords = sanitizeGovernmentContactsResponse(response.data);
            const cleanOptionsList = organizeContacts(dispatch, contactRecords, governmentInfo);
            dispatch({ type: actions.SET_GOVERNMENT_CONTACTS_OPTION_LIST, payload: cleanOptionsList });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government records: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeContacts(dispatch: Dispatch<Record<string, any>>, dealContactList: SimpleGovernmentContact[], governmentInfo: Government): GovernmentContactListItem[] {
    const cleanList: GovernmentContactListItem[] = [];
    const isRecordList: number[] = [];

    for (const contact of dealContactList) {
        const contactListItem: GovernmentContactListItem = {
            label: contact.name,
            id: contact.id,
            isRecord: true,
            role: contact.role
        };
        cleanList.push(contactListItem);
        if(contact.government_contact?.id) {
            isRecordList.push(contact.government_contact.id)
        }
    }

    if(governmentInfo.government?.id) {
        getGovernmentContacts(String(governmentInfo.government.id))
            .then(function (response: any) {
                for (const contact of response.data.government_contacts) {
                    if (!isRecordList.includes(contact.id)) {
                        cleanList.push({
                            label: contact.name,
                            id: contact.id,
                            isRecord: false
                        });
                    }
                }
                sortByKey(cleanList, "label");
                return cleanList;
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government contacts: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactList, isLoading: false } });
            })
    } else {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactList, isLoading: false } });
        sortByKey(cleanList, "label");
    }
    return cleanList;
}

function addContactToList(dispatch: Dispatch<Record<string, any>>, contact: GovernmentContactListItem, removeEmpty?: boolean) {
    dispatch({ type: actions.ADD_GOVERNMENT_CONTACT_TO_LIST, payload: contact });
    if(removeEmpty) {
        dispatch({ type: actions.SET_EMPTY_GOVERNMENT_CONTACT_ADDED, payload: false });
    }
}

function addGovernmentContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, governmentRecordId: number, governmentContact: GovernmentContactListItem, removeEmpty?: boolean) {
    if(governmentContact.isRecord) {
        addGovernmentContactToLien(dealId, String(lienId), String(governmentContact.id))
        .then(function() {
            addContactToList(dispatch, governmentContact, removeEmpty);
        })
        .catch(function(error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding government contact to encumbrance: ${error}`, type: AlertTypes.Error } });
        })
    } else {
        createContactRecordFromGlobal(dispatch, dealId, lienId, governmentRecordId, governmentContact);
    }
}

function createContactRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, governmentRecordId: number, contact: GovernmentContactListItem, removeEmpty?: boolean) {
    newGovernmentContactRecord(dealId, String(governmentRecordId), contact.id!)
        .then(function (response: any) {
            addGovernmentContactToLien(dealId, String(lienId), String(response.data.id))
            .then( function () {
                const newContactListItem: GovernmentContactListItem = { id: response.data.id, isRecord: true, label: contact.label };
                addContactToList(dispatch, newContactListItem, removeEmpty);
                dispatch({ type: actions.REPLACE_GOVERNMENT_CONTACT_OPTION_WITH_RECORD, payload: { oldContactId: contact.id, newOption: newContactListItem, oldIsRecord: false } });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding government contact to encumbrance: ${error}`, type: AlertTypes.Error } });
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government contact record from government contact: ${error}`, type: AlertTypes.Error } });
        })
}

function removeGovernmentContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, oldContactId: number) {
    removeGovernmentContactFromLien(dealId, String(lienId), String(oldContactId))
    .then(function () {
        dispatch({ type: actions.REMOVE_GOVERNMENT_CONTACT_FROM_LIST, payload: oldContactId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing government contact from encumbrance: ${error}`, type: AlertTypes.Error } });
    })
}

function changeGovernmentContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, governmentRecordId: number, oldContactId: number, newContact: GovernmentContactListItem) {
    addGovernmentContact(dispatch, dealId, lienId, governmentRecordId, newContact);
    removeGovernmentContact(dispatch, dealId, lienId, oldContactId);
}

function setGovernmentContactRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentId: number, contactRecordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactModal, isLoading: true } });
    getGovernmentContactRecord(dealId, String(governmentId), String(contactRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_GOVERNMENT_CONTACT_BEING_EDITED, payload: sanitizeGovernmentContactResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentContactModal, isLoading: false } }))
}

function submitNewGovernmentContactRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, governmentRecordId: number, contactBeingReplaced: GovernmentContactListItem | undefined, contactInfo: GovernmentContact) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernmentContact, isLoading: true } });
    newGovernmentContactRecord(dealId, String(governmentRecordId), null)
        .then(function (response: any) {
            const contactRecordId = response.data.id;
            saveGovernmentContactRecord(dealId, String(governmentRecordId), String(contactRecordId), sanitizeGovernmentContactRequest(contactInfo))
                .then(function () {
                    const newContactItem: GovernmentContactListItem = {
                        id: contactRecordId,
                        label: contactInfo.name,
                        isRecord: true
                    };
                    if (contactBeingReplaced) {
                        changeGovernmentContact(dispatch, dealId, lienId, governmentRecordId, contactBeingReplaced.id!, { id: contactRecordId, label: contactInfo.name, isRecord: true });
                    } else {
                        addGovernmentContact(dispatch, dealId, lienId, governmentRecordId, { id: contactRecordId, label: contactInfo.name, isRecord: true }, true);
                    }
                    dispatch({ type: actions.ADD_GOVERNMENT_CONTACT_OPTION, payload: newContactItem });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save government contact record: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernmentContact, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernmentContact, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government contact record: ${error}`, type: AlertTypes.Error } });
        })
}
function saveGovernmentContact(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentRecordId: number, contactInfo: GovernmentContact) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernmentContact, isLoading: true } });
    saveGovernmentContactRecord(dealId, String(governmentRecordId), String(contactInfo.id), sanitizeGovernmentContactRequest(contactInfo))
        .then(function () {
            const newContact: GovernmentContactListItem = {
                id: contactInfo.id,
                label: contactInfo.name,
                isRecord: true,
                role: contactInfo.role
            }
            dispatch({
                type: actions.REPLACE_GOVERNMENT_CONTACT_OPTION_WITH_RECORD,
                payload: { oldContactId: newContact.id, newOption: newContact, oldIsRecord: true }
            });
            dispatch({
                type: actions.REPLACE_GOVERNMENT_CONTACT_WITH_UPDATED_RECORD,
                payload: { oldContactId: newContact.id, newOption: newContact, oldIsRecord: true }
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save government contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernmentContact, isLoading: false } });
        })
}

function setGovernmentContactRole(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentRecordId: number, contactRecordId: number, index: number, role: string | undefined) {
    getGovernmentContactRecord(dealId, String(governmentRecordId), String(contactRecordId))
    .then(function (response: any) {
        const contactInfo: GovernmentContact = sanitizeGovernmentContactResponse(response.data);
        contactInfo.role = role;
        saveGovernmentContactRecord(dealId, String(governmentRecordId), String(contactRecordId), sanitizeGovernmentContactRequest(contactInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_GOVERNMENT_CONTACT_ROLE, payload: {index: index, value: role }});
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save government contact record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government contact record: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    createGovernmentContactOptionsList,
    addGovernmentContact,
    removeGovernmentContact,
    changeGovernmentContact,
    setGovernmentContactRecordBeingEdited,
    submitNewGovernmentContactRecord,
    saveGovernmentContact,
    setGovernmentContactRole
}