import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    saveLenderRecord,
    newLenderRecord,
    getLenderRecords,
    getLenderRecord,
    newRegionOnLenderRecord,
    deleteRegionFromLenderRecord,
    getLenderBranches,
    getLenderSources
} from "../../../libs/service/axios/api";
import { Lender } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { sanitizeLenderResponse, sanitizeSimpleLenderBranchesResponse, sanitizeSimpleLenderSourcesResponse, sanitizeSimpleLendersResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeLenderRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { labelLenderItem, labelLenderItems } from "../../../libs/utils/labeling/lenders";
import { SimpleLender } from "../../../libs/types/UniversalSurvey/Lender/simpleLender";
import { Loading } from "../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { createLegalFirmRecordFromGlobal, setFirmRecordForLegalProfessional } from "./legalFirms";
import { createLegalProfessionalRecordFromGlobal } from "./legalProfessionals";
import { createLegalFirmOfficeRecordFromGlobal, setOfficeRecordForLegalProfessional } from "./legalFirmOffices";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { SimpleLenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";

function saveLender(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    lenderRecordId: number,
    lenderInfo: Lender,
    regionList: RegionListItem[]
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLender, isLoading: true } });
    saveLenderRecord(dealId, String(lenderRecordId), sanitizeLenderRequest(lenderInfo))
        .then(async function () {
            await updateLenderRecordRegions(dispatch, dealId, lenderInfo, regionList);
            getLenderInfo(dispatch, dealId, lenderRecordId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save lender record: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLender, isLoading: false } });
        })
}

function createLenderRecordFromLender(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    lender: LenderListItem,
    currEntity: number,
    sectionLenderIdAndTabUpdater: (dispatch: Dispatch<Record<string, any>>, lenderId: number, name: string | undefined, currEntity: number) => void,
) {
    newLenderRecord(dealId, { lender_id: lender.id })
        .then(function (response: any) {
            const newLenderListItem: LenderListItem = labelLenderItem({ 
                ...lender,
                isRecord: true,
                id: response.data.id
            });
            dispatch({ type: actions.SET_MORTGAGE_LENDER_ID, payload: response.data.id });
            dispatch({ type: actions.ADD_TO_LENDER_OPTIONS, payload: newLenderListItem });
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: newLenderListItem.id });
            sectionLenderIdAndTabUpdater(dispatch, newLenderListItem.id, lender.name, currEntity);
            getLenderInfo(dispatch, dealId, newLenderListItem.id, currEntity);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender record: ${error}`, type: AlertTypes.Error } });
        })
}

function submitNewLenderRecord(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    lenderInfo: Lender,
    currEntity: number,
    sectionLenderIdAndTabUpdater: (dispatch: Dispatch<Record<string, any>>, lenderId: number, name: string | undefined, currEntity: number) => void,
    regionList: RegionListItem[]
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLender, isLoading: true } });
    newLenderRecord(dealId)
        .then(function (response: any) {
            saveLenderRecord(dealId, String(response.data.id), sanitizeLenderRequest(lenderInfo))
                .then(async function () {
                    const newLenderListItem: LenderListItem = labelLenderItem({
                        id: response.data.id, 
                        isRecord: true, 
                        general_address: lenderInfo.general_address,
                        type: lenderInfo.type,
                        branch_number: lenderInfo.branch_number,
                        name: lenderInfo.name,
                        abbr_name: lenderInfo.abbr_name,
                        institution_number: lenderInfo.institution_number
                    });
                    await addLenderRecordRegions(dispatch, dealId, response.data.id, regionList);
                    dispatch({ type: actions.ADD_TO_LENDER_OPTIONS, payload: newLenderListItem });
                    dispatch({ type: actions.SET_SELECTED_LENDER, payload: newLenderListItem.id });
                    sectionLenderIdAndTabUpdater(dispatch, response.data.id, lenderInfo.name, currEntity);
                    getLenderInfo(dispatch, dealId, response.data.id, currEntity);
                    dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: [] });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save lender record during creation: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLender, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender record: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLender, isLoading: false } });
        })
}

async function addLenderRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnLenderRecord(dealId, String(lenderRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to lender record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateLenderRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderInfo: Lender, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (lenderInfo.regions) {
        for (const region of lenderInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnLenderRecord(dealId, String(lenderInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to lender record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromLenderRecord(dealId, String(lenderInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the lender record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on lender record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

function createLenderOptionsList(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    selectedLenderId: number | undefined,
    query?: URLSearchParams
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderList, isLoading: true } });
    getLenderRecords(dealId)
        .then(function (response: any) {
            const lenderRecordsData = sanitizeSimpleLendersResponse(response.data);
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: selectedLenderId });
            let lenderBranchesData: SimpleLenderBranch[] = [];
            if (query) {
                getLenderBranches(query)
                .then(function (lenderResponse: any) {
                    lenderBranchesData = sanitizeSimpleLenderBranchesResponse(lenderResponse.data.lenders);
                    dispatch({ type: actions.SET_LENDER_OPTION_LIST, payload: organizeLenderListResponse(lenderRecordsData, lenderBranchesData) });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lenders: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderList, isLoading: false } });
                })
            } else {
                dispatch({ type: actions.SET_LENDER_OPTION_LIST, payload: organizeLenderListResponse(lenderRecordsData, lenderBranchesData) });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderList, isLoading: false } })
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender records: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            if (!query) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderList, isLoading: false } });
        })
}

function getLenderInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, mortgageTransactionPillId?: number) {
    getLenderRecord(dealId, String(lenderRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_LENDER_INFO,
                payload: sanitizeLenderResponse(response.data)
            });
            dispatch({ type: actions.SET_MORTGAGE_LENDER_ID, payload: response.data.id });
            if (mortgageTransactionPillId) {
                dispatch({ type: actions.UPDATE_MORTGAGE_TRANSACTION_LABEL, payload: { mortgageId: mortgageTransactionPillId, abbr_name: response.data.abbr_name, name: response.data.name } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender record: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeLenderListResponse(dealLenderList: SimpleLender[], LenderBranchList: SimpleLenderBranch[]): LenderListItem[] {
    let cleanLenderList: LenderListItem[] = [];
    let lenderIsRecordList: number[] = [];

    for (const lenderItem of dealLenderList) {
        if (lenderItem.lender?.id) {
            lenderIsRecordList.push(lenderItem.lender.id);
        }
        cleanLenderList.push({
            id: lenderItem.id,
            name: lenderItem.name,
            abbr_name: lenderItem.abbr_name,
            institution_number: lenderItem.institution_number,
            branch_number: lenderItem.branch_number,
            type: lenderItem.type,
            general_address: lenderItem.general_address,
            isRecord: true,
        });
    }

    for (const lenderItem of LenderBranchList) {
        if (!lenderIsRecordList.includes(lenderItem.id)) {
            cleanLenderList.push({
                id: lenderItem.id,
                name: lenderItem.lender?.name,
                abbr_name: lenderItem.lender?.abbr_name,
                type: lenderItem.lender?.type,
                institution_number: lenderItem.lender?.institution_number,
                branch_number: lenderItem.branch_number,
                general_address: lenderItem.general_address,
                isRecord: false,
            });
        }
    }

    return labelLenderItems(cleanLenderList);
}

function updateLenderLawyer(dispatch: Dispatch<Record<string, any>>, value: LegalProfessionalListItem | null, deal: Deal, lender: Lender) {
    if (value) {
        if (value.isRecord) {
            saveLender(dispatch, String(deal.id), lender.id, { ...lender, lawyer: value }, lender.regions as RegionListItem[])
        } else {
            createLegalProfessionalRecordFromGlobal(dispatch, deal, lender, value, LegalProfessionalOptions.LenderLawyer);
        }
    } else {
        saveLender(dispatch, String(deal.id), lender.id, { ...lender, lawyer: undefined }, lender.regions as RegionListItem[])
    }
}

function updateLenderLawyerFirm(dispatch: Dispatch<Record<string, any>>, value: LegalFirmListItem | null, deal: Deal | undefined, lender: Lender | undefined) {
    if (value) {
        if (value.isRecord) {
            setFirmRecordForLegalProfessional(dispatch, String(deal?.id), deal?.lawyer_record?.id, deal?.signer_record?.id, lender?.lawyer?.id, value, LegalProfessionalOptions.LenderLawyer);
        } else {
            createLegalFirmRecordFromGlobal(dispatch, String(deal?.id), deal?.lawyer_record?.id, deal?.signer_record?.id, lender?.lawyer?.id, value, LegalProfessionalOptions.LenderLawyer);
        }
    } else {
        setFirmRecordForLegalProfessional(dispatch, String(deal?.id), deal?.lawyer_record?.id, deal?.signer_record?.id, lender?.lawyer?.id, null, LegalProfessionalOptions.LenderLawyer);
        dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE, payload: undefined });
    }
}

function getUSLenderSourceOptions(dispatch: Dispatch<Record<string, any>>, query?: URLSearchParams) {
    if (!query) return dispatch({ type: actions.SET_LENDER_SOURCES, payload: [] });
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderSources, isLoading: true } });
    getLenderSources(query)
        .then(function (response: any) {
            const cleanData = sanitizeSimpleLenderSourcesResponse(response.data.lenders);
            dispatch({ type: actions.SET_LENDER_SOURCES, payload: cleanData });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get source lenders: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderSources, isLoading: false } });
        })
}

function updateLenderLawyerOffice(dispatch: Dispatch<Record<string, any>>, value: LegalFirmOfficeListItem | null, deal: Deal | undefined, lender: Lender | undefined) {
    if (value) {
        if (value.isRecord) {
            setOfficeRecordForLegalProfessional(
                dispatch,
                String(deal?.id),
                deal?.lawyer_record?.id,
                deal?.signer_record?.id,
                lender?.lawyer?.id,
                lender?.lawyer?.legal_firm_record?.id!,
                value,
                LegalProfessionalOptions.LenderLawyer
            );
        } else {
            createLegalFirmOfficeRecordFromGlobal(
                dispatch,
                String(deal?.id),
                deal?.lawyer_record?.id,
                deal?.signer_record?.id,
                lender?.lawyer?.id,
                lender?.lawyer?.legal_firm_record?.id!,
                value,
                LegalProfessionalOptions.LenderLawyer
            );
        }
    } else {
        setOfficeRecordForLegalProfessional(
            dispatch,
            String(deal?.id),
            deal?.lawyer_record?.id,
            deal?.signer_record?.id,
            lender?.lawyer?.id,
            lender?.lawyer?.legal_firm_record?.id!,
            null,
            LegalProfessionalOptions.LenderLawyer
        );
    }
}

export {
    saveLender,
    createLenderRecordFromLender,
    submitNewLenderRecord,
    createLenderOptionsList,
    getLenderInfo,
    updateLenderLawyer,
    updateLenderLawyerFirm,
    updateLenderLawyerOffice,
    getUSLenderSourceOptions
}