import { useEffect, useState, useContext } from "react";

import { AdminContext } from "../../../../../context/AdminPanel/context";
import { AdminActionTypes } from "../../../../../context/AdminPanel/actionTypes";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { LenderContact, LenderContactErrorState, GlobalLenderContact } from "../../../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { defaultGlobalLenderContact } from "../../../../../libs/resources/defaults/frontend/defaultLenderContact";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { AdminLoading } from "../../../../../libs/resources/enums/loading";
import { defaultLenderContactErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLenderContactErrorState";
import LenderContactForm from "./LenderContactForm";
import { saveLenderContact, submitNewLenderContact } from "../../../../../context/AdminPanel/asyncActions/lenderContacts";

export default function AdminLenderContactModal(props: Omit<ModalProps, "children">) {

    const [state, dispatch] = useContext(AdminContext);
    const [contactObj, setContactObj] = useState<GlobalLenderContact>({ ...defaultGlobalLenderContact });
    const [errorState, setErrorState] = useState<LenderContactErrorState>({ ...defaultLenderContactErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.lenderContacts.contactBeingEdited) {
            setContactObj({ ...state.lenderContacts.contactBeingEdited });
        } else {
            setContactObj({ ...defaultGlobalLenderContact })
        }
    }, [state.lenderContacts.contactBeingEdited]);

    function updateContactInfo<
        K extends keyof LenderContact,
        V extends LenderContact[K]
    >(key: K, value: V): void {
        const tempContactObj = { ...contactObj }
        // @ts-ignore
        if (Object.keys(tempContactObj).includes(key)) tempContactObj[key] = value;
        setContactObj(tempContactObj);
    }

    function submit() {
        if (state.lenderContacts.contactBeingEdited) {
            saveLenderContact(dispatch, contactObj);
        } else {
            if (state.lenders.selectedLenderBranch) submitNewLenderContact(dispatch, state.lenders.selectedLenderBranch?.id, contactObj);
        }
    }

    function discard() {
        dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_BEING_EDITED, payload: undefined });
        setContactObj({ ...defaultGlobalLenderContact });
        setErrorState({ ...defaultLenderContactErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!contactObj.name || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, contactObj])

    return (
        <ModalBase
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={`${!state.lenderContacts.contactBeingEdited ? "New " : ""}Lender Contact`}
            isLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.LenderContactModal])}
            closeAfterSaving={discard}
            saveDisabled={saveDisabled}
            isSaving={isObjectLoading(state.loading.objectsLoading, [AdminLoading.SaveLenderContact])}
        >
            <LenderContactForm
                contact={contactObj}
                updateContactInfo={updateContactInfo}
                errorState={errorState}
                setErrorState={setErrorState}
            />
        </ModalBase>
    );
}
