export enum TaxRates {
    Ontario = 0.13,
    Alberta = 0.05,
    BritishColumbia = 0.12
}

export enum TaxTypes {
    Ontario = "HST",
    Alberta = "GST",
    BritishColumbia = "PST/GST"
}