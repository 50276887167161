import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid, Stack } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"
import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../../components/Common/Modal/GenericDialog";
import LinkExistingModal from "./LinkExistingModal";
import { Affidavit } from "../../../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { defaultAffidavit } from "../../../../../libs/resources/defaults/frontend/defaultAffidavit";
import { SimpleClient } from "../../../../../libs/types/UniversalSurvey/Client/simpleClient";
import BooleanControl from "../../../../../../components/Common/BooleanControl/BooleanControl";
import { AttachConditions, Condition, SupplementAttachments } from "../../../../../libs/types/UniversalSurvey/Condition/condition";
import { defaultCondition } from "../../../../../libs/resources/defaults/frontend/defaultCondition";
import {
    addCondition,
    deleteAffidavitCondition,
    deleteDebtCondition,
    deleteLienCondition,
    deleteStatDecCondition,
    deleteUndertakingCondition,
    getConditionAffidavit,
    getConditionDebt,
    getConditionLien,
    getConditionStatDec,
    getConditionUndertaking,
    removeCondition,
    updateCondition
} from "../../../../../context/UniversalSurvey/asyncActions/conditions";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { formatConditionSource, formatConditionType } from "../../../../../libs/utils/formatValues";
import { ConditionType } from "../../../../../libs/resources/enums/conditionType";
import { ConditionSourceOptions, ConditionTypeOptions } from "../../../../../libs/resources/options";
import { ConditionSource } from "../../../../../libs/resources/enums/conditionSource";
import { Undertaking } from "../../../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { defaultUndertaking } from "../../../../../libs/resources/defaults/frontend/defaultUndertaking";
import BasicAccordion from "../../../../../../components/Common/Accordion/Accordion";
import UndertakingForm from "./UndertakingForm";
import LienForm from "./LienForm";
import { Lien } from "../../../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { defaultLien } from "../../../../../libs/resources/defaults/frontend/defaultLien";
import BasicButton from "../../../../../../components/Common/Button/BasicButton";
import { Debt } from "../../../../../libs/types/UniversalSurvey/Debts/debt";
import DebtForm from "./DebtForm";
import { defaultDebt } from "../../../../../libs/resources/defaults/frontend/defaultDebt";
import AffidavitForm from "./affidavitForm";
import { defaultStatDec } from "../../../../../libs/resources/defaults/frontend/defaultStatDec";
import StatDecForm from "./statDecForm";
import { StatDec } from "../../../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { LienIsTo } from "../../../../../libs/resources/enums/liens";
import colors from "../../../../../../components/Common/componentStyling/Colors";
import { DebtHolderTypes } from "../../../../../libs/resources/enums/debtHolderTypes";
import { Sections } from "../../../../../libs/resources/enums/sections";
import { TransactionTypes } from "../../../../../libs/resources/enums/transactions";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children">;

const defaultAttachments: AttachConditions = {
    stat_dec: undefined,
    debt: undefined,
    lien: undefined,
    undertaking: undefined,
    affidavit: undefined
}

const defaultSupplements: SupplementAttachments = {
    client: undefined,
    client_list: []
}

export default function ConditionModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [conditionObj, setConditionObj] = useState<Condition>({ ...defaultCondition });
    const [originalCopy, setOriginalCopy] = useState<Condition>({ ...defaultCondition });
    const [attachments, setAttachments] = useState<AttachConditions>({ ...defaultAttachments });
    const [supplements, setSupplements] = useState<SupplementAttachments>({ ...defaultSupplements });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [confirmUnlinkLienOpen, setConfirmUnlinkLienOpen] = useState<boolean>(false);
    const [confirmUnlinkDebtOpen, setConfirmUnlinkDebtOpen] = useState<boolean>(false);
    const [linkLienOpen, setLinkLienOpen] = useState<boolean>(false);
    const [linkDebtOpen, setLinkDebtOpen] = useState<boolean>(false);
    const [confirmDeleteLienOpen, setConfirmDeleteLienOpen] = useState<boolean>(false);
    const [confirmDeleteUndertakingOpen, setConfirmDeleteUndertakingOpen] = useState<boolean>(false);
    const [confirmDeleteDebtOpen, setConfirmDeleteDebtOpen] = useState<boolean>(false);
    const [confirmDeleteAffidavitOpen, setConfirmDeleteAffidavitOpen] = useState<boolean>(false);
    const [confirmDeleteStatDecOpen, setConfirmDeleteStatDecOpen] = useState<boolean>(false);
    const [newDebtHolderType, setNewDebtHolderType] = useState<DebtHolderTypes | undefined>(undefined);
    const [newDebtHolderName, setNewDebtHolderName] = useState<string>("");
    const [selectedTransaction, setSelectedTransaction] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        if(state.conditions.conditionBeingEdited) {
            let condition = state.conditions.conditionBeingEdited;

            // Getting attached entities
            if (condition.lien_id) {
                getConditionLien(dispatch, String(state.deal.dealInfo?.id), String(condition.lien_id));
            } 
            if (condition.undertaking_id) {
                getConditionUndertaking(dispatch, String(state.deal.dealInfo?.id), String(condition.undertaking_id));
            }
            if (condition.debt_id) {
                getConditionDebt(dispatch, String(state.deal.dealInfo?.id), String(condition.debt_id));
            }
            if (condition.affidavit_id) {
                getConditionAffidavit(dispatch, String(state.deal.dealInfo?.id), String(condition.affidavit_id));
            }
            if (condition.stat_dec_id) {
                getConditionStatDec(dispatch, String(state.deal.dealInfo?.id), String(condition.stat_dec_id));
            }

            setConditionObj(condition);
            setOriginalCopy(condition);

            // Setting new mortgage
            const transaction = state.transactions.transactionList.find((transaction) => (
                transaction.id === condition.mortgage_id &&
                transaction.transaction_type === TransactionTypes.Mortgage
            ))
            transaction ? setSelectedTransaction(transaction.label) : undefined;
        }
    }, [state.conditions.conditionBeingEdited]);

    useEffect(() => {
        let tempAttachments = { ...attachments };
        tempAttachments.lien = state.conditions.conditionLien ? { ...state.conditions.conditionLien } : undefined;
        setAttachments(tempAttachments);

        let tempConditionObj = { ...conditionObj };
        tempConditionObj.lien_id = state.conditions.conditionLien?.id;
        setConditionObj(tempConditionObj);
    }, [state.conditions.conditionLien]);

    useEffect(() => {
        let tempAttachments = { ...attachments };
        tempAttachments.undertaking = state.conditions.conditionUndertaking ? { ...state.conditions.conditionUndertaking } : undefined;
        setAttachments(tempAttachments);

        let tempConditionObj = { ...conditionObj };
        tempConditionObj.undertaking_id = state.conditions.conditionUndertaking?.id;
        setConditionObj(tempConditionObj);
    }, [state.conditions.conditionUndertaking]);

    useEffect(() => {
        let tempAttachments = { ...attachments };
        tempAttachments.debt = state.conditions.conditionDebt ? { ...state.conditions.conditionDebt } : undefined;
        setAttachments(tempAttachments);

        let tempConditionObj = { ...conditionObj };
        tempConditionObj.debt_id = state.conditions.conditionDebt?.id;
        setConditionObj(tempConditionObj);
    }, [state.conditions.conditionDebt]);

    useEffect(() => {
        let tempAttachments = { ...attachments };
        tempAttachments.affidavit = state.conditions.conditionAffidavit ? { ...state.conditions.conditionAffidavit } : undefined;
        setAttachments(tempAttachments);

        let tempConditionObj = { ...conditionObj };
        tempConditionObj.affidavit_id = state.conditions.conditionAffidavit?.id;
        setConditionObj(tempConditionObj);
    }, [state.conditions.conditionAffidavit]);

    useEffect(() => {
        let tempAttachments = { ...attachments };
        tempAttachments.stat_dec = state.conditions.conditionStatDec ? { ...state.conditions.conditionStatDec } : undefined;
        setAttachments(tempAttachments);
        
        let tempConditionObj = { ...conditionObj };
        tempConditionObj.stat_dec_id = state.conditions.conditionStatDec?.id;
        setConditionObj(tempConditionObj);
    }, [state.conditions.conditionStatDec]);

    // Update functions
    function updateConditionInfo<
        K extends keyof Condition,
        V extends Condition[K]
    >(key: K, value: V): void {
        const tempConditionObj = { ...conditionObj };
        tempConditionObj[key] = value;
        setConditionObj(tempConditionObj);
    }

    function updateUndertakingInfo<
        K extends keyof Undertaking,
        V extends Undertaking[K]
    >(key: K, value: V): void {
        let tempAttachments = { ...attachments };
        tempAttachments.undertaking![key] = value;
        setAttachments(tempAttachments);
    }

    function updateLienInfo<
        K extends keyof Lien,
        V extends Lien[K]
    >(key: K, value: V): void {
        let tempAttachments = { ...attachments };
        tempAttachments.lien![key] = value;
        setAttachments(tempAttachments);
    }

    function updateDebtInfo<
        K extends keyof Debt,
        V extends Debt[K]
    >(key: K, value: V): void {
        let tempAttachments = { ...attachments };
        tempAttachments.debt![key] = value;
        setAttachments(tempAttachments);
    }

    function updateAffidavitInfo<
        K extends keyof Affidavit,
        V extends Affidavit[K]
    >(key: K, value: V): void {
        let tempAttachments = { ...attachments };
        tempAttachments.affidavit![key] = value;
        setAttachments(tempAttachments);
    }

    function updateStatDecInfo<
        K extends keyof StatDec,
        V extends StatDec[K]
    >(key: K, value: V): void {
        let tempAttachments = { ...attachments };
        tempAttachments.stat_dec![key] = value;
        setAttachments(tempAttachments);
    }

    function setLien(obj: Lien) {
        let tempAttachments = { ...attachments };
        tempAttachments.lien = obj;
        setAttachments(tempAttachments); 
    }

    function setDebt(obj: Debt) {
        let tempAttachments = { ...attachments };
        tempAttachments.debt = obj;
        setAttachments(tempAttachments); 
    }

    function setSelectedClient(obj?: SimpleClient) {
        let tempSupplements = { ...supplements };
        tempSupplements.client = obj;
        setSupplements(tempSupplements);
    }

    function setSelectedClients(obj: (SimpleClient & { label: string })[]) {
        let tempSupplements = { ...supplements };
        tempSupplements.client_list = obj;
        setSupplements(tempSupplements);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function handleUnlinkDebtConfirm() {
        if (attachments.debt?.id) removeConditionDebt(undefined);
        setConfirmUnlinkDebtOpen(false);
    }

    function handleUnlinkLienConfirm() {
        if (attachments.lien?.id) removeConditionLien(undefined);
        setConfirmUnlinkLienOpen(false);
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_CONDITION_BEING_EDITED, payload: undefined });
        dispatch({ type: actions.SET_CONDITION_LIEN, payload: undefined });
        dispatch({ type: actions.SET_CONDITION_UNDERTAKING, payload: undefined });
        dispatch({ type: actions.SET_CONDITION_DEBT, payload: undefined });
        dispatch({ type: actions.SET_CONDITION_AFFIDAVIT, payload: undefined });
        dispatch({ type: actions.SET_CONDITION_STAT_DEC, payload: undefined });
        dispatch({ type: actions.SET_CHARGE_HOLDER, payload: undefined });
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: undefined });
        setConditionObj({ ...defaultCondition });
        setOriginalCopy({ ...defaultCondition});
        setAttachments({ ...defaultAttachments });
        setSupplements({ ...defaultSupplements });
        setNewDebtHolderType(undefined);
        setNewDebtHolderName("");
        setSelectedTransaction(undefined);
    }

    function handleDiscardClick() {
        if (_.isEqual(conditionObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (state.conditions.conditionBeingEdited) {
            updateCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, attachments, supplements, newDebtHolderName, newDebtHolderType);
        } else {
            addCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, attachments, supplements, newDebtHolderName, newDebtHolderType);
        }
    }

    function onDeleteConfirm() {
        removeCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj)
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (attachments.affidavit && (!attachments.affidavit.client_id || !attachments.affidavit.debtors || !attachments.affidavit.execution_num || attachments.affidavit.amount === undefined || attachments.affidavit.cost === undefined || !attachments.affidavit.plaintiffs)) return true;
        if (attachments.debt && (attachments.debt && (!((attachments.debt.debt_holder_id && attachments.debt.charge_holder_type) || (newDebtHolderName && newDebtHolderType)) || !attachments.debt.action || !attachments.debt.amount_payable))) return true;
        if (attachments.lien && (!(attachments.lien.charge_holder_company_id || attachments.lien.lender_id || attachments.lien.government_id || attachments.lien.other_charge_holder_id || (newDebtHolderName && newDebtHolderType)) || attachments.lien.payout_calc_principal === undefined))  return true;
        if (attachments.stat_dec && (supplements.client_list.length === 0 || !attachments.stat_dec.declaration)) return true;
        if (attachments.undertaking && !attachments.undertaking.direction) return true;
        if (!conditionObj.type || !conditionObj.source || !conditionObj.condition) return true;    
        if (conditionObj.source === ConditionSource.MortgageInstruction && (!selectedTransaction || !conditionObj.mortgage_id)) return true;
        return false;
    }

    function updateConditionType(value?: ConditionType) {
        const tempAttachments = { ...defaultAttachments };
        setNewDebtHolderType(undefined);
        setNewDebtHolderName("");
        switch (value) {
            case ConditionType.Discharge:
                tempAttachments.undertaking = attachments.undertaking ?? { ...defaultUndertaking };
                tempAttachments.lien = attachments.lien ?? { ...defaultLien };
                tempAttachments.debt = undefined;
                tempAttachments.affidavit = undefined;
                tempAttachments.stat_dec = undefined;
                break;
            case ConditionType.Pay:
                tempAttachments.undertaking = attachments.undertaking ?? { ...defaultUndertaking };
                tempAttachments.lien = undefined;
                tempAttachments.debt = attachments.debt ?? { ...defaultDebt };
                tempAttachments.affidavit = undefined;
                tempAttachments.stat_dec = undefined;
                break;
            case ConditionType.Holdback:
                tempAttachments.undertaking = attachments.undertaking ?? { ...defaultUndertaking };
                tempAttachments.lien = undefined;
                tempAttachments.debt = undefined;
                tempAttachments.affidavit = undefined;
                tempAttachments.stat_dec = undefined;
                break;
            case ConditionType.Writ:
                tempAttachments.undertaking = undefined;
                tempAttachments.lien = undefined;
                tempAttachments.debt = undefined;
                tempAttachments.affidavit = attachments.affidavit ?? { ...defaultAffidavit };
                tempAttachments.stat_dec = undefined;
                break;
            case ConditionType.Deliver:
                tempAttachments.undertaking = attachments.undertaking ?? { ...defaultUndertaking };
                tempAttachments.lien = undefined;
                tempAttachments.debt = undefined;
                tempAttachments.affidavit = undefined;
                tempAttachments.stat_dec = undefined;
                break;
            case ConditionType.Other:
                return;
            default:
                break;
        }
        setSupplements({ ...defaultSupplements });
        setAttachments(tempAttachments);
    }

    function addLien() {
        let tempAttachments = { ...attachments };
        tempAttachments.lien = { ...defaultLien, is_to: LienIsTo.BeDischarged };
        setAttachments(tempAttachments);
    }

    function removeConditionLien(id: number | undefined | null) {
        setNewDebtHolderType(undefined);
        setNewDebtHolderName("");
        let tempAttachments = { ...attachments };
        tempAttachments.lien = undefined;
        if (id) deleteLienCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, id);
        dispatch({ type: actions.SET_CONDITION_LIEN, payload: undefined });
        setAttachments(tempAttachments);
        updateConditionInfo("lien_id", undefined);
    }

    function addUndertaking() {
        let tempAttachments = { ...attachments };
        tempAttachments.undertaking = { ...defaultUndertaking };
        setAttachments(tempAttachments);
    }

    function removeConditionUndertaking(id: number | undefined | null) {
        let tempAttachments = { ...attachments };
        tempAttachments.undertaking = undefined;
        if (id) deleteUndertakingCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, id);
        dispatch({ type: actions.SET_CONDITION_UNDERTAKING, payload: undefined });
        dispatch({ type: actions.DELETE_LIEN_UNDERTAKING_CONDITION });
        setAttachments(tempAttachments);
        updateConditionInfo("undertaking_id", undefined);
    }

    function addDebt() {
        let tempAttachments = { ...attachments };
        tempAttachments.debt = { ...defaultDebt };
        setAttachments(tempAttachments);
    }

    function removeConditionDebt(id: number | undefined | null) {
        setNewDebtHolderType(undefined);
        setNewDebtHolderName("");
        let tempAttachments = { ...attachments };
        tempAttachments.debt = undefined;
        if (id) deleteDebtCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, id);
        dispatch({ type: actions.SET_CONDITION_DEBT, payload: undefined });
        setAttachments(tempAttachments);
        updateConditionInfo("debt_id", undefined);
    }

    function addAffidavit() {
        let tempAttachments = { ...attachments };
        tempAttachments.affidavit = { ...defaultAffidavit };
        setAttachments(tempAttachments);
    }

    function removeConditionAffidavit(id: number | undefined | null) {
        let tempAttachments = { ...attachments };
        let tempSupplements = { ...supplements };
        tempAttachments.affidavit = undefined;
        tempSupplements.client = undefined;
        if (id) deleteAffidavitCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, id)
        dispatch({ type: actions.SET_CONDITION_AFFIDAVIT, payload: undefined });
        setAttachments(tempAttachments);
        setSupplements(tempSupplements);
        updateConditionInfo("affidavit_id", undefined);
    }

    function addStatDec() {
        let tempAttachments = { ...attachments };
        tempAttachments.stat_dec = { ...defaultStatDec };
        setAttachments(tempAttachments);
    }

    function removeConditionStatDec(id: number | undefined | null) {
        let tempAttachments = { ...attachments };
        let tempSupplements = { ...supplements };
        tempAttachments.stat_dec = undefined;
        tempSupplements.client_list = [];
        if (id) deleteStatDecCondition(dispatch, String(state.deal.dealInfo?.id), conditionObj, id)
        dispatch({ type: actions.SET_CONDITION_STAT_DEC, payload: undefined });
        setAttachments(tempAttachments);
        setSupplements(tempSupplements);
        updateConditionInfo("stat_dec_id", undefined);
    }

    function conditionHasNonUndertakingEntity(): boolean {
        if (attachments.affidavit || attachments.debt || attachments.lien || attachments.stat_dec) {
            return true;
        }
        return false;
    }

    function handleTransactionChange(newType: string | undefined): void {
        if (newType) {
            setSelectedTransaction(newType);
            const mortgageTransaction = state.transactions.transactionList.find((transaction) => transaction.label === newType);
            mortgageTransaction ? updateConditionInfo('mortgage_id', mortgageTransaction.id) : undefined;
        } else {
            updateConditionInfo("mortgage_id", undefined);
            setSelectedTransaction(undefined);
        }
    }

    return (
        <ModalBase
            title={`${state.conditions.conditionBeingEdited ? "" : "New "}Condition`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.conditions.conditionBeingEdited ? true : false}
            confirmDeleteText="Are you sure you want to delete this condition? This is permanent and irreversible. Any entities linked to this condition will be deleted as well."
            saveDisabled={isSaveDisabled()}
            discardButtonLabel={`Discard${state.conditions.conditionBeingEdited ? " Changes": ""}`}
            onDelete={onDeleteConfirm}
            confirmDeleteButtonText="Delete Condition and Entities"
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.ConditionModal, Loading.ConditionLien, Loading.ConditionUndertaking, Loading.ConditionDebt, Loading.ConditionAffidavit, Loading.ConditionStatDec])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveCondition, Loading.SaveLien, Loading.SaveUndertaking, Loading.SaveDebt, Loading.SaveAffidavit, Loading.SaveStatDec])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={6} >
                        <Dropdown
                            formatValue={formatConditionType}
                            value={conditionObj.type ?? ""}
                            onChange={(e) => {
                                updateConditionType(e.target.value as ConditionType);
                                updateConditionInfo("type", e.target.value as ConditionType);
                            }}
                            placeholder="Select type..."
                            options={ConditionTypeOptions}
                            label={{ text: "Type", isRequired: true }}
                            isHoverActionHidden={conditionObj.type ? false : true}
                            inputProps={{ "aria-label": "Type" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Dropdown
                            formatValue={formatConditionSource}
                            value={conditionObj.source ?? ""}
                            onChange={(e) => updateConditionInfo("source", e.target.value as ConditionSource)}
                            placeholder="Select source..."
                            options={ConditionSourceOptions}
                            label={{ text: "Source", isRequired: true }}
                            isHoverActionHidden={conditionObj.source ? false : true}
                            inputProps={{ "aria-label": "Source" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            multiline
                            label={{ text: "Condition", inputId: "condition", isRequired: true }}
                            placeholder="Condition details"
                            value={conditionObj.condition ?? undefined}
                            onChange={(e) => updateConditionInfo("condition", e.target.value)}
                        />
                    </Grid>
                    {conditionObj.source === ConditionSource.MortgageInstruction && (
                        <Grid item xs={6}>
                            <Dropdown
                                value={selectedTransaction}
                                onChange={(e) => handleTransactionChange(e.target.value as string)}
                                placeholder="Select new mortgage..."
                                options={state.transactions.transactionList
                                    .filter((transaction) => transaction.transaction_type === TransactionTypes.Mortgage)
                                    .map((transaction) => transaction.label ? transaction.label : transaction.transaction_type)
                                }
                                tooltipProps={{
                                    message: "Select the new mortgage that the mortgage instruction originates from",
                                    type: "info",
                                    placement: "right"
                                }}
                                label={{ text: "New Mortgage", isRequired: true }}
                                isHoverActionHidden={selectedTransaction ? false : true}
                                handleClear={() => handleTransactionChange(undefined)}
                            />
                        </Grid>
                    )}
                    {conditionObj.type === ConditionType.Holdback && (
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                label={{ text: "Amount", inputId: "amount_payable" }}
                                placeholder="0.00"
                                moneyField
                                valueType="positive"
                                value={conditionObj?.holdback ? String(conditionObj.holdback) : undefined}
                                onChange={(e) => updateConditionInfo("holdback", Number(e.target.value))}
                            />  
                        </Grid>
                    )}
                    {attachments.undertaking && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title="Undertaking"
                                defaultExpanded
                                onDelete={() => setConfirmDeleteUndertakingOpen(true)}
                                id="undertaking-accordion"
                                dialogOpen={confirmDeleteUndertakingOpen}
                                dialog={confirmDeleteUndertakingOpen ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteUndertakingOpen}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this linked undertaking? This is permanent and irreversible."
                                        onCancel={() => setConfirmDeleteUndertakingOpen(false)}
                                        onSubmit={() => {
                                            removeConditionUndertaking(conditionObj.undertaking_id);
                                            setConfirmDeleteUndertakingOpen(false);
                                        }}
                                        submitText="Delete Undertaking"
                                        hideBackdrop
                                        top={2 * (document.getElementById("undertaking-accordion")?.getBoundingClientRect().top! + document.getElementById("undertaking-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteUndertakingOpen ? colors.ORANGE_90o : undefined}
                            >
                                <UndertakingForm
                                    obj={attachments.undertaking}
                                    onChange={updateUndertakingInfo}
                                />
                            </BasicAccordion>
                        </Grid>
                    )}
                    {attachments.lien && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title="Encumbrance"
                                defaultExpanded
                                onDelete={state.dataSheet.currSection === Sections.Encumbrances ? undefined : () => setConfirmDeleteLienOpen(true)}
                                onLink={() => setLinkLienOpen(true)}
                                onUnlink={attachments.lien.id > 0 ? () => setConfirmUnlinkLienOpen(true) : undefined}
                                id="lien-accordion"
                                dialogOpen={confirmUnlinkLienOpen || linkLienOpen || confirmDeleteLienOpen}
                                dialog={confirmUnlinkLienOpen ?
                                    <GenericDialog
                                        action="secondary"
                                        open={confirmUnlinkLienOpen}
                                        title="Confirm Unlink"
                                        contentText="Are you sure you want to unlink this Lien?"
                                        onCancel={() => setConfirmUnlinkLienOpen(false)}
                                        onSubmit={() => handleUnlinkLienConfirm()}
                                        submitText="Unlink Lien"
                                        hideBackdrop
                                        top={2 * (document.getElementById("lien-accordion")?.getBoundingClientRect().top! + document.getElementById("lien-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    linkLienOpen ?
                                    <LinkExistingModal
                                        open={linkLienOpen}
                                        entity="Encumbrance"
                                        onClose={() => setLinkLienOpen(false)}
                                        top={2 * (document.getElementById("lien-accordion")?.getBoundingClientRect().top! + document.getElementById("lien-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    confirmDeleteLienOpen ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteLienOpen}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this linked lien? This is permanent and irreversible."
                                        onCancel={() => setConfirmDeleteLienOpen(false)}
                                        onSubmit={() => {
                                            removeConditionLien(conditionObj.lien_id);
                                            setConfirmDeleteLienOpen(false);
                                        }}
                                        submitText="Delete Lien"
                                        hideBackdrop
                                        top={2 * (document.getElementById("lien-accordion")?.getBoundingClientRect().top! + document.getElementById("lien-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteLienOpen ? colors.ORANGE_90o : undefined}
                            >
                                <LienForm
                                    obj={attachments.lien}
                                    onChange={updateLienInfo}
                                    setObj={setLien}
                                    newDebtHolderType={newDebtHolderType}
                                    setNewDebtHolderType={setNewDebtHolderType}
                                    newDebtHolderName={newDebtHolderName}
                                    setNewDebtHolderName={setNewDebtHolderName}
                                />
                            </BasicAccordion>
                        </Grid>
                    )}
                    {attachments.debt && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title="Debt"
                                defaultExpanded
                                onDelete={() => setConfirmDeleteDebtOpen(true)}
                                onLink={() => setLinkDebtOpen(true)}
                                onUnlink={attachments.debt.id > 0 ? () => setConfirmUnlinkDebtOpen(true) : undefined}
                                id="debt-accordion"
                                dialogOpen={confirmUnlinkDebtOpen || linkDebtOpen || confirmDeleteDebtOpen}
                                dialog={confirmUnlinkDebtOpen ?
                                    <GenericDialog
                                        action="secondary"
                                        open={confirmUnlinkDebtOpen}
                                        title="Confirm Unlink"
                                        contentText="Are you sure you want to unlink this Debt?"
                                        onCancel={() => setConfirmUnlinkDebtOpen(false)}
                                        onSubmit={() => handleUnlinkDebtConfirm()}
                                        submitText="Unlink Debt"
                                        hideBackdrop
                                        top={2 * (document.getElementById("debt-accordion")?.getBoundingClientRect().top! + document.getElementById("debt-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    linkDebtOpen ?
                                    <LinkExistingModal
                                        open={linkDebtOpen}
                                        entity="Debt"
                                        onClose={() => setLinkDebtOpen(false)}
                                        top={2 * (document.getElementById("debt-accordion")?.getBoundingClientRect().top! + document.getElementById("debt-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    confirmDeleteDebtOpen ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteDebtOpen}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this linked debt? This is permanent and irreversible."
                                        onCancel={() => setConfirmDeleteDebtOpen(false)}
                                        onSubmit={() => {
                                            removeConditionDebt(conditionObj.debt_id);
                                            setConfirmDeleteDebtOpen(false);
                                        }}
                                        submitText="Delete Debt"
                                        hideBackdrop
                                        top={2 * (document.getElementById("debt-accordion")?.getBoundingClientRect().top! + document.getElementById("debt-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteLienOpen ? colors.ORANGE_90o : undefined}
                            >
                                <DebtForm
                                    obj={attachments.debt}
                                    onChange={updateDebtInfo}
                                    setObj={setDebt}
                                    newDebtHolderType={newDebtHolderType}
                                    setNewDebtHolderType={setNewDebtHolderType}
                                    newDebtHolderName={newDebtHolderName}
                                    setNewDebtHolderName={setNewDebtHolderName}
                                />
                            </BasicAccordion>
                        </Grid>
                    )}
                    {attachments.affidavit && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title="Affidavit"
                                defaultExpanded
                                onDelete={() => setConfirmDeleteAffidavitOpen(true)}
                                id="affidavit-accordion"
                                dialogOpen={confirmDeleteAffidavitOpen}
                                dialog={confirmDeleteAffidavitOpen ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteAffidavitOpen}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this linked affidavit? This is permanent and irreversible."
                                        onCancel={() => setConfirmDeleteAffidavitOpen(false)}
                                        onSubmit={() => {
                                            removeConditionAffidavit(conditionObj.affidavit_id);
                                            setConfirmDeleteAffidavitOpen(false);
                                        }}
                                        submitText="Delete Affidavit"
                                        hideBackdrop
                                        top={2 * (document.getElementById("affidavit-accordion")?.getBoundingClientRect().top! + document.getElementById("affidavit-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteAffidavitOpen ? colors.ORANGE_90o : undefined}
                            >
                                <AffidavitForm
                                    obj={attachments.affidavit}
                                    onChange={updateAffidavitInfo}
                                    selectedClient={supplements.client}
                                    setSelectedClient={setSelectedClient}
                                />
                            </BasicAccordion>
                        </Grid>
                    )}
                    {attachments.stat_dec && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title="Statutory Declaration"
                                defaultExpanded
                                onDelete={() => setConfirmDeleteStatDecOpen(true)}
                                id="stat-dec-accordion"
                                dialogOpen={confirmDeleteStatDecOpen}
                                dialog={confirmDeleteStatDecOpen ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteStatDecOpen}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this linked statutory declaration? This is permanent and irreversible."
                                        onCancel={() => setConfirmDeleteStatDecOpen(false)}
                                        onSubmit={() => {
                                            removeConditionStatDec(conditionObj.stat_dec_id);
                                            setConfirmDeleteStatDecOpen(false);
                                        }}
                                        submitText="Delete Statutory Declaration"
                                        hideBackdrop
                                        top={2 * (document.getElementById("stat-dec-accordion")?.getBoundingClientRect().top! + document.getElementById("stat-dec-accordion")?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteStatDecOpen ? colors.ORANGE_90o : undefined}
                            >
                                <StatDecForm
                                    obj={attachments.stat_dec}
                                    onChange={updateStatDecInfo}
                                    selectedClients={supplements.client_list}
                                    setSelectedClients={setSelectedClients}
                                />
                            </BasicAccordion>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Stack direction={"row"} gap={2.5}>
                            {(!attachments.undertaking && (
                                conditionObj.type === ConditionType.Discharge ||
                                conditionObj.type === ConditionType.Pay ||
                                conditionObj.type === ConditionType.Holdback ||
                                conditionObj.type === ConditionType.Other ||
                                conditionObj.type === ConditionType.Deliver
                            )) && (
                                <BasicButton
                                    size="small"
                                    typeOf="secondary"
                                    label={{text: "Add Undertaking", inputId: "add-undertaking"}}
                                    onClick={addUndertaking}
                                />
                            )}
                            {(!conditionHasNonUndertakingEntity() && (
                                conditionObj.type === ConditionType.Other
                            )) && (
                                <BasicButton
                                    size="small"
                                    typeOf="secondary"
                                    label={{text: "Add Stat. Dec.", inputId: "add-stat-dec"}}
                                    onClick={addStatDec}
                                />
                            )}
                            {(!conditionHasNonUndertakingEntity() && (
                                conditionObj.type === ConditionType.Writ ||
                                conditionObj.type === ConditionType.Other
                            )) && (
                                <BasicButton
                                    size="small"
                                    typeOf="secondary"
                                    label={{text: "Add Affidavit", inputId: "add-affidavit"}}
                                    onClick={addAffidavit}
                                />
                            )}
                            {(!conditionHasNonUndertakingEntity() && (
                                conditionObj.type === ConditionType.Pay ||
                                conditionObj.type === ConditionType.Other
                            )) && (
                                <BasicButton
                                    size="small"
                                    typeOf="secondary"
                                    label={{text: "Add Debt", inputId: "add-debt"}}
                                    onClick={addDebt}
                                />
                            )}
                            {(!conditionHasNonUndertakingEntity() && (
                                conditionObj.type === ConditionType.Discharge ||
                                conditionObj.type === ConditionType.Other
                            )) && (
                                <BasicButton
                                    size="small"
                                    typeOf="secondary"
                                    label={{text: "Add Encumbrance", inputId: "add-lien"}}
                                    onClick={addLien}
                                />
                            )}
                        </Stack>
                    </Grid>
                    {conditionObj.type && (
                        <>
                            <Grid item xs={11}>
                                <Grid container direction={"column"} alignItems={"flex-end"}>
                                    <Grid item xs={10}>
                                        <BooleanControl
                                            checked={conditionObj.condition_met}
                                            labels={["Unmet", "Met"]}
                                            onChange={(e) => updateConditionInfo("condition_met", !conditionObj.condition_met)}
                                            label={{ text: "Status", inputId: `status` }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}/>
                        </>
                    )}
                </Grid>
            </>
        </ModalBase>
    );
}