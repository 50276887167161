import { Undertaking } from "../../../types/UniversalSurvey/Undertaking/undertaking";
import { DischargedBy, UndertakingStatus } from "../../enums/undertakings";

export const defaultUndertaking: Undertaking = {
    id: -1,
    addressed_to: undefined,
    direction: undefined,
    condition: undefined,
    status: UndertakingStatus.Outstanding,
    discharged_by: DischargedBy.Instrument,
    instrument_number: undefined,
    discharge_note: undefined,
    discharge_date: undefined,
    date_created: undefined,
    date_updated: undefined
}