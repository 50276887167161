import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { FireInsuranceCompany } from "../../../../../libs/types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { FireInsuranceBrokerage } from "../../../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import { defaultFireInsuranceCompany } from "../../../../../libs/resources/defaults/frontend/defaultFireInsuranceCompany";
import { defaultFireInsuranceBrokerage } from "../../../../../libs/resources/defaults/frontend/defaultFireInsuranceBrokerage";
import { addressToString, regionListToString } from "../../../../../libs/utils/address";

type Props = Omit<ModalProps, "children"> & {
    type: "company" | "brokerage";
};

export default function ReadonlyGeneralFireInsuranceModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [generalObj, setGeneralObj] = useState<FireInsuranceCompany | FireInsuranceBrokerage>({...defaultFireInsuranceCompany});

    useEffect(() => {
        switch(props.type) {
            case "company":
                if (state.fireInsuranceCompanies.editingCompanyRecord && state.fireInsuranceCompanies.companyInfo) {
                    setGeneralObj({ ...state.fireInsuranceCompanies.companyInfo });
                } else {
                    setGeneralObj({ ...defaultFireInsuranceCompany });
                }
                break;
            case "brokerage":
                if (state.fireInsuranceBrokerages.editingRecord && state.fireInsuranceBrokerages.brokerageInfo) {
                    setGeneralObj({ ...state.fireInsuranceBrokerages.brokerageInfo });
                } else {
                    setGeneralObj({ ...defaultFireInsuranceBrokerage });
                }
                break;
        }
    }, [state.fireInsuranceCompanies.editingCompanyRecord, state.fireInsuranceBrokerages.editingRecord]);

    function generateSubtitle(): string {
        switch(props.type) {
            case "company":
                return "FIRE INSURANCE COMPANY";
            case "brokerage":
                return "FIRE INSURANCE BROKERAGE";
        }
    }

    function generateNotes() {
        switch(props.type) {
            case "company":
                return state.fireInsuranceCompanies.companyInfo?.fire_insurance_company?.notes;
            case "brokerage":
                return state.fireInsuranceBrokerages.brokerageInfo?.fire_insurance_brokerage?.notes;
        }
    }

    function discard() {
        switch(props.type) {
            case "company":
                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD, payload: false });
                setGeneralObj({ ...defaultFireInsuranceCompany });
                break;
            case "brokerage":
                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD, payload: false });
                setGeneralObj({ ...defaultFireInsuranceBrokerage });
                break;
        }
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            title={generalObj.name}
            subtitle={generateSubtitle()}
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Abbreviated Name:
                        </Small>
                        <Large>{generalObj.abbr_name}</Large>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Province(s) of Operation:
                        </Small>
                        <Large>{regionListToString(generalObj.all_regions, generalObj.regions)}</Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Phone:
                        </Small>
                        <Link
                            href={generalObj.phone}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(generalObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Fax:
                        </Small>
                        <Link
                            href={generalObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Email:
                        </Small>
                        <Link
                            href={generalObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>{generateNotes()}</Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}