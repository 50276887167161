import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    addDealFirmOfficeToLegalProfessional,
    addDealFirmToLegalProfessional,
    createDealLegalProfessional,
    deleteRegionFromLegalProfessionalRecord,
    getDealLegalProfessional,
    getDealLegalProfessionals,
    getGlobalLegalProfessionals,
    newRegionOnLegalProfessionalRecord,
    saveDealLegalProfessional,
} from "../../../libs/service/axios/api";
import { sanitizeLegalProfessionalResponse, sanitizeSimpleLegalProfessionalsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleLegalProfessional } from "../../../libs/types/UniversalSurvey/LegalProfessional/simpleLegalProfessional";
import { LegalProfessional, LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { updateDealData } from "./deal";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { sanitizeLegalProfessionalRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultSimpleLegalProfessional } from "../../../libs/resources/defaults/frontend/defaultSimpleLegalProfessional";
import { createLegalFirmRecordFromGlobal, getLegalProfessionalFirmOptions } from "./legalFirms";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { SimpleLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { SimpleLegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { createLegalFirmOfficeRecordFromGlobal, linkLegalFirmOfficeToLegalProfessional, linkLegalFirmToLegalProfessional } from "./legalFirmOffices";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { Loading } from "../../../libs/resources/enums/loading";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { Lender } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { saveLender } from "./lenders";

function createLegalProfessionalOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, region: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalProfessionalList, isLoading: true } });
    getDealLegalProfessionals(dealId)
        .then(function (response: any) {
            const cleanDealData = sanitizeSimpleLegalProfessionalsResponse(response.data);
            let query = undefined;
            if (region) {
                query = new URLSearchParams({ region_id: String(region) })
            }
            getGlobalLegalProfessionals(query)
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeSimpleLegalProfessionalsResponse(globalResponse.data.legal_professionals);
                    const organizedData = organizeLegalProfessionalListResponse(cleanDealData, cleanGlobalData);
                    dispatch({ type: actions.SET_LEGAL_PROFESSIONAL_OPTION_LIST, payload: organizedData });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global legal professionals: ${error}`, type: AlertTypes.Error } });
                })
                .finally(() => {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalProfessionalList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalProfessionalList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal professional records: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeLegalProfessionalListResponse(dealList: SimpleLegalProfessional[], globalList: SimpleLegalProfessional[]): LegalProfessionalListItem[] {
    let cleanList: LegalProfessionalListItem[] = [];
    let isRecordList: number[] = [];

    for (const professional of dealList) {
        if (professional.legal_professional?.id) {
            isRecordList.push(professional.legal_professional.id);
        }
        cleanList.push({
            ...professional,
            label: professional.name ?? "Legal Professional",
            isRecord: true
        });
    }

    for (const professional of globalList) {
        if (!isRecordList.includes(professional.id)) {
            cleanList.push({
                ...professional,
                label: professional.name ?? "Legal Professional",
                isRecord: false
            });
        }
    }

    return cleanList;
}

function createLegalProfessionalRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, deal: Deal, lender: Lender | undefined, professional: LegalProfessionalListItem, type: LegalProfessionalOptions) {
    createDealLegalProfessional(String(deal.id), professional.id)
        .then(function (response: any) {
            const newListItem: LegalProfessionalListItem = {
                ...professional,
                isRecord: true,
                legal_professional: {
                    id: professional.id,
                    name: professional.name,
                    initial: professional.initial,
                    type: professional.type,
                    notes: undefined,
                    all_regions: undefined,
                    legal_firms: [],
                    legal_firm_offices: [],
                    regions: []
                },
                id: response.data.id
            };
            dispatch({ type: actions.REPLACE_LEGAL_PROFESSIONAL_OPTION_WITH_RECORD, payload: { oldId: professional.id, newOption: newListItem, oldIsRecord: false } });
            if (type === LegalProfessionalOptions.DealLawyer || type === LegalProfessionalOptions.DealSigner) {
                updateDealData(dispatch, {
                    ...deal,
                    lawyer_record: type === LegalProfessionalOptions.DealLawyer ? newListItem as SimpleLegalProfessional : deal.lawyer_record,
                    signer_record: type === LegalProfessionalOptions.DealSigner ? newListItem as SimpleLegalProfessional : deal.signer_record
                });
            } else if (type === LegalProfessionalOptions.LenderLawyer && lender) {
                saveLender(dispatch, String(deal.id), lender.id, { ...lender, lawyer: newListItem as SimpleLegalProfessional }, lender.regions as RegionListItem[]);
                dispatch({ type: actions.SET_LENDER_LAWYER, payload: newListItem as SimpleLegalProfessional });
            }
            getLegalProfessionalFirmOptions(dispatch, String(deal.id), newListItem, type);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal professional record: ${error}`, type: AlertTypes.Error } });
        })
}

function saveLegalProfessionalRecord(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string, data: LegalProfessional,
    type: LegalProfessionalOptions,
    firmToLink: LegalFirmListItem | undefined,
    officeToLink: LegalFirmOfficeListItem | undefined,
    regionList: RegionListItem[]
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: true } });
    saveDealLegalProfessional(dealId, String(data.id), sanitizeLegalProfessionalRequest(data))
        .then(async function () {
            const dataCopy = { ...data };
            for (const key of Object.keys(dataCopy)) {
                if (!Object.keys(defaultSimpleLegalProfessional).includes(key)) {
                    delete dataCopy[key as keyof typeof dataCopy];
                }
            }

            await updateLegalProfessionalRecordRegions(dispatch, dealId, data, regionList);
            const partialRecord = await handleLinkingFirmAndOffice(dispatch, dealId, data.id, type, firmToLink, officeToLink);
            dataCopy.legal_firm_record = partialRecord.legal_firm_record;
            dataCopy.legal_firm_office_record = partialRecord.legal_firm_office_record

            if (type === LegalProfessionalOptions.DealLawyer || type === LegalProfessionalOptions.DealSigner) {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL, payload: { record: dataCopy, isSigner: type === LegalProfessionalOptions.DealSigner } });
            } else if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER, payload: dataCopy });
            }
            getLegalProfessionalFirmOptions(dispatch, dealId, { ...dataCopy, label: dataCopy.name, isRecord: true }, type);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal professional record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: false } });
        })
}

async function handleLinkingFirmAndOffice(dispatch: Dispatch<Record<string, any>>, dealId: string, professionalId: number, type: LegalProfessionalOptions, firmToLink: LegalFirmListItem | undefined, officeToLink: LegalFirmOfficeListItem | undefined): Promise<{ legal_firm_record: SimpleLegalFirm | undefined, legal_firm_office_record: SimpleLegalFirmOffice | undefined }> {
    return new Promise<{ legal_firm_record: SimpleLegalFirm | undefined, legal_firm_office_record: SimpleLegalFirmOffice | undefined }>(async (resolve) => {
        let firmItem: LegalFirmListItem | undefined = undefined;
        let officeItem: LegalFirmOfficeListItem | undefined = undefined;
        if (firmToLink) {
            if (firmToLink.isRecord) {
                await linkLegalFirmToLegalProfessional(dispatch, dealId, professionalId, firmToLink.id);
                firmItem = firmToLink;
            } else {
                firmItem = await createLegalFirmRecordFromGlobal(
                    dispatch,
                    dealId,
                    type === LegalProfessionalOptions.DealLawyer ? professionalId : undefined,
                    type === LegalProfessionalOptions.DealSigner ? professionalId : undefined,
                    type === LegalProfessionalOptions.LenderLawyer ? professionalId : undefined,
                    firmToLink,
                    type
                );
            }

            if (officeToLink) {
                if (officeToLink.isRecord) {
                    await linkLegalFirmOfficeToLegalProfessional(dispatch, dealId, professionalId, firmToLink.id, officeToLink.id);
                    officeItem = officeToLink;
                } else {
                    officeItem = await createLegalFirmOfficeRecordFromGlobal(
                        dispatch,
                        dealId,
                        type === LegalProfessionalOptions.DealLawyer ? professionalId : undefined,
                        type === LegalProfessionalOptions.DealSigner ? professionalId : undefined,
                        type === LegalProfessionalOptions.LenderLawyer ? professionalId : undefined,
                        firmItem!.id,
                        officeToLink,
                        type
                    );
                }
            }
        }

        let firmRecord: SimpleLegalFirm | undefined = undefined;
        let officeRecord: SimpleLegalFirmOffice | undefined = undefined;

        if (firmItem) {
            let firmCopy = { ...firmItem };
            delete firmCopy.isRecord;
            delete firmCopy.label;
            firmRecord = firmCopy;
        }
        if (officeItem) {
            let officeCopy = { ...officeItem };
            delete officeCopy.isRecord;
            delete officeCopy.label;
            officeRecord = officeCopy;
        }

        resolve({ legal_firm_record: firmRecord, legal_firm_office_record: officeRecord });
    })
}

function setLegalProfessionalRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, recordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalProfessionalModal, isLoading: true } });
    getDealLegalProfessional(dealId, String(recordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_LEGAL_PROFESSIONAL_BEING_EDITED, payload: sanitizeLegalProfessionalResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal professional record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalProfessionalModal, isLoading: false } });
        })
}

function submitNewLegalProfessionalRecord(
    dispatch: Dispatch<Record<string, any>>,
    deal: Deal,
    data: LegalProfessional,
    lender: Lender | undefined,
    type: LegalProfessionalOptions,
    firmToLink: LegalFirmListItem | undefined,
    officeToLink: LegalFirmOfficeListItem | undefined,
    regionList: RegionListItem[]
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: true } });
    createDealLegalProfessional(String(deal.id), null)
        .then(function (response: any) {
            saveDealLegalProfessional(String(deal.id), String(response.data.id), sanitizeLegalProfessionalRequest(data))
                .then(async function () {

                    const partialRecord = await handleLinkingFirmAndOffice(dispatch, String(deal.id), response.data.id, type, firmToLink, officeToLink);

                    const simpleRecord: SimpleLegalProfessional = {
                        id: response.data.id,
                        initial: data.initial,
                        name: data.name,
                        type: data.type,
                        legal_professional: undefined,
                        ...partialRecord
                    }
                    await addLegalProfessionalRecordRegions(dispatch, String(deal.id), response.data.id, regionList);
                    dispatch({ type: actions.ADD_TO_LEGAL_PROFESSIONAL_OPTIONS, payload: { ...simpleRecord, isRecord: true, label: data.name } });
                    if (type === LegalProfessionalOptions.DealLawyer || type === LegalProfessionalOptions.DealSigner) {
                        dispatch({ type: actions.SET_DEAL_PROFESSIONAL, payload: { record: simpleRecord, isSigner: type === LegalProfessionalOptions.DealSigner } });
                        updateDealData(dispatch, {
                            ...deal,
                            lawyer_record: type === LegalProfessionalOptions.DealLawyer ? simpleRecord : deal.lawyer_record,
                            signer_record: type === LegalProfessionalOptions.DealSigner ? simpleRecord : deal.signer_record
                        });
                    } else if(type === LegalProfessionalOptions.LenderLawyer && lender) {
                        saveLender(dispatch, String(deal.id), lender.id, { ...lender, lawyer: simpleRecord }, lender.regions as RegionListItem[]);
                        dispatch({ type: actions.SET_LENDER_LAWYER, payload: simpleRecord });
                    }
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal professional record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(() => {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal professional record: ${error}`, type: AlertTypes.Error } });
        })
}

async function addLegalProfessionalRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, professionalId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnLegalProfessionalRecord(dealId, String(professionalId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to legal professional record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateLegalProfessionalRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, data: LegalProfessional, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (data.regions) {
        for (const region of data.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnLegalProfessionalRecord(dealId, String(data.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to legal professional record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromLegalProfessionalRecord(dealId, String(data.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the legal professional record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on legal professional record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

export {
    createLegalProfessionalOptionsList,
    createLegalProfessionalRecordFromGlobal,
    saveLegalProfessionalRecord,
    setLegalProfessionalRecordBeingEdited,
    submitNewLegalProfessionalRecord
}
