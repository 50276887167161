import { SimpleClient } from "../../../types/UniversalSurvey/Client/simpleClient";

export const defaultSimpleClient: SimpleClient = {
    id: -1,
    first_name: undefined,
    last_name: undefined,
    middle_name: undefined,
    type: undefined,
    corporation_name: undefined,
    tenants_in_common_share: undefined,
    
    spousal_relationship: undefined,
    poa_eoe_relationships: undefined,
    roles: []
}