import { Stack } from "@mui/material";

import { EmailIcon, FaxIcon, NewWindowIcon, PhoneIcon } from "../Icons/Iconography";
import Colors from "../componentStyling/Colors";
import { Large } from "../Typography";
import parsePhoneNumber from 'libphonenumber-js';

interface EmailLinkProps {
    type: "email" | "phone" | "url" | "fax";
    href?: string;
}

export default function Link(props: EmailLinkProps) {
    function generateHref(href: string): string {
        switch(props.type) {
            case "email":
                return `mailto: ${href}`;
            case "phone":
                return `tel: +${href}`;
            case "url":
                return href;
            case "fax":
                return `mailto: ${href}@rcfax.com`
            default:
                return ""
        }
    }

    function getIcon() {
        switch(props.type) {
            case "email":
                return ( <EmailIcon color={Colors.BLUE_600} /> );
            case "phone":
                return ( <PhoneIcon color={Colors.BLUE_600} /> );
            case "url":
                return ( <NewWindowIcon color={Colors.BLUE_600} /> );
            case "fax":
                return ( <FaxIcon color={Colors.BLUE_600} /> );
            default:
                return null;
        }
    }

    function phoneDisplay(number: string) {
        const phoneNumber = parsePhoneNumber(`+${number}`);
        return phoneNumber?.formatInternational();
    }
    
    return (
        <Stack direction="row" gap={1}>
            {getIcon()}
            {props.href && (
                <a
                    href={generateHref(props.href)}
                    target={props.type === "url" ? "_blank" : undefined}
                >
                    <Large style={{ color: Colors.BLUE_600 }}>
                        {(props.type === "phone" || props.type === "fax") ? phoneDisplay(props.href) : props.href}
                    </Large>
                </a>
            )}
        </Stack>
    )
}