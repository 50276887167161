import styles from './documentStyle.css?inline';

export const stylesheet = `
<style>
  ${styles}
</style>
`;

export interface WrapperOptions {
  applyStyle?: boolean;
  isPreviewHTML?: boolean;
};

export const ApplyWrapper = (htmlBody: string, options: WrapperOptions):string => {
  return (
    `
      <!DOCTYPE html>
      <html lang="en"${options.isPreviewHTML ? ' style="overflow:hidden;"': ''}>

      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        ${options.applyStyle ? stylesheet : "<style></style>"}
      </head>
      ${htmlBody}
      </html>
    `
  )
};