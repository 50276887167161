// Importing MUI components
import { Box, styled, Grid } from '@mui/material';

// Importing application components
import GridButton from './GridButton';
import { AdminSection, Section } from '../../../../libs/types/UniversalSurvey/Frontend/section';

type GridControlFormProps = {
    options: Section[] | AdminSection[]
    handleSectionChange: (title: string) => void;
    activeSection: string | undefined;
}

export default function GridControlForm(props: GridControlFormProps) {
    return (
        <GridBase>
            <ControlGrid data-testid="data-sheet-tabs" container columnSpacing={5}>
                {props.options.map((option) => (
                    <Grid item key={option.title} xs={12} md={6} lg={4}>
                        <GridButton
                            fullWidth
                            disableRipple
                            onClick={() => props.handleSectionChange(option.title)}
                            isActive={props.activeSection === option.title}
                            status={option.status}
                        >
                            {option.title}
                        </GridButton>
                    </Grid>
                ))}
            </ControlGrid>
        </GridBase>
    );
}

const GridBase = styled(Box)({
    paddingTop: "1rem"
});

const ControlGrid = styled(Grid)({
    alignItems: "center",
});