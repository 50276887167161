// Importing material components
import { Grid, styled } from "@mui/material";

// Importing application components
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import { Body } from "../../../../../components/Common/Typography";
import { FinancialInfo, FinancialInfoErrorState } from "conveyance/libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import AddressGrid from "../AddressGrid/AddressGrid";
import { emailErrorMessage, urlErrorMessage } from "../../../../libs/utils/validation";

type FinancialInfoGridProps = {
    financialInfo: FinancialInfo;
    setFinancialInfo: (value: FinancialInfo) => void;
    errorState?: FinancialInfoErrorState;
    setErrorState?: (value: FinancialInfoErrorState) => void;
    id: string;
    title?: string;
}

export default function FinancialInfoGrid(props: FinancialInfoGridProps) {
    const { financialInfo, setFinancialInfo, errorState, setErrorState, id, title } = props;

    function updateFinancialInfo<
        K extends keyof FinancialInfo,
        V extends FinancialInfo[K]
    >(key: K, value: V): void {
        const tempFinancialInfo: FinancialInfo = { ...financialInfo };
        tempFinancialInfo[key] = value;
        setFinancialInfo(tempFinancialInfo);
    }

    return (
        <PaddedGrid container rowSpacing={3} columnSpacing={5}>
            {title && <Grid item xs={12}><StyledBody>{title}</StyledBody></Grid>}
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.name}
                    onChange={(e) => updateFinancialInfo("name", e.target.value)}
                    label={{ text: "Name", inputId: `name-${id}` }}
                    placeholder="Institution Name"
                />
            </Grid>
            <Grid item xs={3}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.transit_number}
                    onChange={(e) => updateFinancialInfo("transit_number", e.target.value)}
                    label={{ text: "Transit Number", inputId: `transit_number-${id}` }}
                    placeholder="Transit Number"
                />
            </Grid>
            <Grid item xs={3}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.institution_number}
                    onChange={(e) => updateFinancialInfo("institution_number", e.target.value)}
                    label={{ text: "Institution Number", inputId: `institution_number-${id}` }}
                    placeholder="Institution Number"
                />
            </Grid>
            <Grid item xs={4}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.account_number}
                    onChange={(e) => updateFinancialInfo("account_number", e.target.value)}
                    label={{ text: "Account Number", inputId: `account_number-${id}` }}
                    placeholder="Account Number"
                />
            </Grid>
            <Grid item xs={6}>
                <PhoneField
                    value={financialInfo.fax_for_payout_info}
                    onChange={(value) => updateFinancialInfo("fax_for_payout_info", value)}
                    label={{text: "Fax for Payout/Info", inputId: "fax_for_payout_info"}}
                    inputProps={{ "aria-label": "Fax Number" }}
                    error={{
                        showError: true,
                        errorState: errorState,
                        setErrorState: setErrorState,
                        errorKey: "fax_for_payout_info"
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.email_for_payout_info}
                    onChange={(e) => updateFinancialInfo("email_for_payout_info", e.target.value)}
                    label={{ text: "Email for Payout/Info", inputId: `email_for_payout_info-${id}` }}
                    placeholder="Email"
                    fieldType="email"
                    error={{
                        showError: true,
                        errorState: errorState,
                        message: emailErrorMessage,
                        setErrorState: setErrorState,
                        errorKey: "email_for_payout_info"
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <BasicTextInput
                    fullWidth
                    value={financialInfo.portal_for_payout_info}
                    onChange={(e) => updateFinancialInfo("portal_for_payout_info", e.target.value)}
                    label={{ text: "Portal for Payout/Info", inputId: `portal_for_payout_info-${id}` }}
                    placeholder="https://"
                    fieldType="url"
                    error={{
                        showError: true,
                        errorState: errorState,
                        message: urlErrorMessage,
                        setErrorState: setErrorState,
                        errorKey: "portal_for_payout_info"
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressGrid
                    address={financialInfo.branch_address}
                    setAddress={(value) => updateFinancialInfo("branch_address", value)}
                    id="branch_address"
                    title="Branch Address"
                />
            </Grid>
        </PaddedGrid>
    )
};

const StyledBody = styled(Body)({
    marginBottom: "-1.5rem",
    marginTop: "1rem"
})

const PaddedGrid = styled(Grid)({
    paddingTop: "0.2rem",
    paddingBottom: "1rem"
})