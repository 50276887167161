import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getAllDealFireInsurances,
    getDealFireInsurance,
    createDealFireInsurance,
    saveDealFireInsurance,
    deleteDealFireInsurance
} from "../../../libs/service/axios/api";
import { TabOptions } from '../../../libs/types/UniversalSurvey/Frontend/tabOption';
import { sanitizeFireInsuranceResponse, sanitizeFireInsurancesResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeFireInsuranceRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleFireInsurance } from "../../../libs/types/UniversalSurvey/FireInsurance/simpleFireInsurance";
import { FireInsurance } from "../../../libs/types/UniversalSurvey/FireInsurance/fireInsurance";
import { getPropertyTabName } from "./properties";
import { defaultFireInsurance } from "../../../libs/resources/defaults/frontend/defaultFireInsurance";
import { Property } from "../../../libs/types/UniversalSurvey/Property/property";
import { getFireInsuranceBrokerageInfo } from "./fireInsuranceBrokerages";
import { getFireInsuranceCompanyInfo } from "./fireInsuranceCompanies";
import { getFireInsuranceBrokerRecordInfo } from "./fireInsuranceBrokers";
import { navigateURL } from "./deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { Loading } from "../../../libs/resources/enums/loading";

function switchToFireInsuranceSection(dispatch: Dispatch<Record<string, any>>, dealId: string, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getAllDealFireInsurances(dealId)
        .then(function (response: any) {
            const fireInsuranceList = sanitizeFireInsurancesResponse(response.data);
            const propertiesWithFireInsurance: number[] = [];
            const fireInsuranceTabs: TabOptions = [];
            for (let i = 0; i < fireInsuranceList.length; i++) {
                const fireInsuranceObj = fireInsuranceList[i]
                if (i === 0 && !hasEntityId) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, String(fireInsuranceObj.id));
                }
                fireInsuranceTabs.push({
                    title: getFireInsuranceTabName(fireInsuranceObj),
                    itemId: fireInsuranceList[i].id,
                    subTitle: fireInsuranceList[i].property_record ? getPropertyTabName(fireInsuranceList[i].property_record) : undefined,
                    missingFields: 0
                })

                if (fireInsuranceObj.property_record) {
                    propertiesWithFireInsurance.push(fireInsuranceObj.property_record.id)
                }
                dispatch({ type: actions.SET_PROPERTIES_WITH_FIRE_INSURANCE, payload: propertiesWithFireInsurance });
            }
            dispatch({ type: actions.SET_TAB_OPTIONS, payload: fireInsuranceTabs });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Fire insurance tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function changeSelectedFireInsurance(dispatch: Dispatch<Record<string, any>>, dealId: string, fireInsuranceId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getDealFireInsurance(dealId, String(fireInsuranceId))
        .then(function (response: any) {
            const cleanFireInsurance = sanitizeFireInsuranceResponse(response.data)
            dispatch({ type: actions.SET_FIRE_INSURANCE_INFO, payload: cleanFireInsurance });
            updateFireInsuranceTabName(dispatch, cleanFireInsurance);

            // Company
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: cleanFireInsurance.fire_insurance_company_record?.id });
            if (cleanFireInsurance?.fire_insurance_company_record?.id) {
                getFireInsuranceCompanyInfo(dispatch, dealId, cleanFireInsurance.fire_insurance_company_record.id);
            }

            // Brokerage
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: cleanFireInsurance.fire_insurance_brokerage_record?.id });
            if (cleanFireInsurance?.fire_insurance_brokerage_record?.id) {
                getFireInsuranceBrokerageInfo(dispatch, dealId, cleanFireInsurance.fire_insurance_brokerage_record.id);
                if (cleanFireInsurance?.fire_insurance_broker_record?.id) {
                    getFireInsuranceBrokerRecordInfo(dispatch, dealId, cleanFireInsurance.fire_insurance_brokerage_record.id, cleanFireInsurance.fire_insurance_broker_record.id);
                }
            }

        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Fire insurance section content: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function saveFireInsurance(dispatch: Dispatch<Record<string, any>>, dealId: string, fireInsuranceInfo: FireInsurance) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    saveDealFireInsurance(dealId, String(fireInsuranceInfo.id), sanitizeFireInsuranceRequest(fireInsuranceInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addFireInsurance(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    createDealFireInsurance(dealId)
        .then(function (response: any) {
            dispatch({
                type: actions.ADD_TAB_OPTION,
                payload: {
                    title: getFireInsuranceTabName(defaultFireInsurance),
                    itemId: response.data.id,
                    missingFields: 0,
                }
            });
            navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, String(response.data.id));
            dispatch({
                type: actions.SET_FIRE_INSURANCE_INFO,
                payload: {
                    ...defaultFireInsurance,
                    id: response.data.id
                }
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New fire insurance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function createFireInsuranceForProperty(dispatch: Dispatch<Record<string, any>>, dealId: string, property: Property) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getAllDealFireInsurances(dealId)
        .then(function (response: any) {
            const fireInsuranceList = sanitizeFireInsurancesResponse(response.data);
            const propertiesWithFireInsurance: number[] = [];
            const fireInsuranceTabs: TabOptions = [];
            for (let i = 0; i < fireInsuranceList.length; i++) {
                const fireInsuranceObj = fireInsuranceList[i]
                fireInsuranceTabs.push({
                    title: getFireInsuranceTabName(fireInsuranceObj),
                    itemId: fireInsuranceList[i].id,
                    missingFields: 0
                })

                if (fireInsuranceObj.property_record) {
                    propertiesWithFireInsurance.push(fireInsuranceObj.property_record.id)
                }
            }

            createDealFireInsurance(dealId, { property_id: property.id })
                .then(function (response: any) {
                    const simplePropertyRecord = {
                        id: property.id,
                        address: property.address,
                        roles: property.roles
                    }
                    
                    fireInsuranceTabs.push({
                            title: getFireInsuranceTabName({
                                ...defaultFireInsurance,
                                property_record: simplePropertyRecord
                            }),
                            itemId: response.data.id,
                            missingFields: 0,
                    });
                    propertiesWithFireInsurance.push(property.id)
                    dispatch({
                        type: actions.SET_FIRE_INSURANCE_INFO,
                        payload: {
                            ...defaultFireInsurance,
                            property_record: simplePropertyRecord,
                            property_id: simplePropertyRecord.id,
                            id: response.data.id
                        }
                    });
                    navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, String(response.data.id));
                    dispatch({ type: actions.SET_TAB_OPTIONS, payload: fireInsuranceTabs });
                    dispatch({ type: actions.SET_PROPERTIES_WITH_FIRE_INSURANCE, payload: propertiesWithFireInsurance });
                    dispatch({ type: actions.SET_ADDING_FIRE_INSURANCE_FROM_PROPERTY_SECTION, payload: false });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New fire insurance: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Fire insurance tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function updateFireInsuranceTabName(dispatch: Dispatch<Record<string, any>>, fireInsurance: FireInsurance) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION,
        payload: {
            title: getFireInsuranceTabName(fireInsurance),
            subTitle: fireInsurance.property_record ? getPropertyTabName(fireInsurance.property_record) : undefined,
            itemId: fireInsurance.id,
            missingFields: 0,
        }
    })
}

function getFireInsuranceTabName(insurance: SimpleFireInsurance) {
    let tabTitle = "";
    if (insurance.fire_insurance_company_record) {
        tabTitle += insurance.fire_insurance_company_record.abbr_name ? insurance.fire_insurance_company_record.abbr_name : "";
    }
    if (insurance.coverage_amount && insurance.fire_insurance_company_record) {
        tabTitle += " - ";
    }
    if (insurance.coverage_amount) {
        tabTitle += `$${insurance.coverage_amount.toLocaleString()}`;
    }
    if (!tabTitle) {
        return "New Fire Insurance";
    }
    return tabTitle;
}

function deleteFireInsurance(dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, tabOptions: TabOptions, isActive: boolean) {
    deleteDealFireInsurance(dealId, String(entityId))
    .then(function (response: any) {
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: entityId });
        if (isActive) {
            if (tabOptions.length > 1) {
                let newOption = tabOptions.find((value) => value.itemId !== entityId);
                if (newOption) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, String(newOption.itemId));
                } else {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, undefined);
                    dispatch({ type: actions.SET_FIRE_INSURANCE_INFO, payload: undefined });
                }
            } else {
                navigateURL(dispatch, dealId, "data-sheet", Sections.FireInsurance, undefined);
                dispatch({ type: actions.SET_FIRE_INSURANCE_INFO, payload: undefined });
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete fire insurance: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

export {
    switchToFireInsuranceSection,
    changeSelectedFireInsurance,
    addFireInsurance,
    saveFireInsurance,
    createFireInsuranceForProperty,
    updateFireInsuranceTabName,
    deleteFireInsurance
}