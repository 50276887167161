import { useCallback, useContext, useEffect, useState } from "react";

import { Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AdminContext } from "../../../context/AdminPanel/context";
import { AdminActionTypes } from "../../../context/AdminPanel/actionTypes";
import { H4, Large, Small } from "../../../../components/Common/Typography/index";
import TableBase, { orderOptions } from "../../../../components/Common/Table/TableBase";
import { getLenderBranchOptions, deleteLender, setSelectedLender } from "../../../context/AdminPanel/asyncActions/lenders";
import { debounce } from "../../../libs/utils/debounce";
import { addressToString, addressToStringShort } from "../../../libs/utils/address";
import { formatLenderType, formatPhoneNumber } from "../../../libs/utils/formatValues";
import { EditIcon, TrashIcon, XCircle } from "../../../../components/Common/Icons/Iconography";
import Colors from "../../../../components/Common/componentStyling/Colors";
import Theme from "../../../../components/Common/componentStyling/Theme";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import { LenderType } from "../../../libs/resources/enums/lenderInstitutions";
import FinancialInfoReadonlyGrid from "../../UniversalSurvey/Components/FinancialInfoGrid/FinancialInfoReadonly";
import { isObjectLoading } from "../../../libs/utils/loading";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import AdminLenderModal from "../../UniversalSurvey/Components/Modals/LenderModal/AdminLenderModal";
import { deleteLenderContact, getGlobalLenderContacts, setLenderContactBeingEdited } from "../../../context/AdminPanel/asyncActions/lenderContacts";
import AdminLenderContactModal from "../../../routes/UniversalSurvey/Components/Modals/LenderContactModal/AdminLenderContactModal";
import { GlobalSimpleLenderContact } from "../../../libs/types/UniversalSurvey/LenderContact/simpleLenderContact";
import { LenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/lenderBranch";
import { SimpleLenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";

type LenderTableRow = SimpleLenderBranch & { tableAddress: string, tableType: string };
type ContactTableRow = GlobalSimpleLenderContact & { tablePhone: string, tableFax: string };

export default function LenderSection() {
    const [state, dispatch] = useContext(AdminContext);
    const [deleteButtonHoveredRow, setDeleteButtonHoveredRow] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>("");
    const [sortOrder, setSortOrder] = useState<orderOptions>("asc");
    const [sortKey, setSortKey] = useState<string>("name");
    const [lenderToBeDeleted, setLenderToBeDeleted] = useState<number | undefined>(undefined);
    const [deleteLenderModalActive, setDeleteLenderModalActive] = useState<boolean>(false);
    const [lenderObj, setLenderObj] = useState<LenderBranch | undefined>(undefined);
    const [viewingLender, setViewingLender] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [pageNumber, setPageNumber] = useState<number>(1);

    // contacts
    const [searchValueContacts, setSearchValueContacts] = useState<string | undefined>("");
    const [sortOrderContacts, setSortOrderContacts] = useState<orderOptions>("asc");
    const [sortKeyContacts, setSortKeyContacts] = useState<string>("name");
    const [contactToBeDeleted, setContactToBeDeleted] = useState<number | undefined>(undefined);
    const [deleteContactModalActive, setDeleteContactModalActive] = useState<boolean>(false);
    const [contactModalActive, setContactModalActive] = useState<boolean>(false);
    const [rowsPerPageContacts, setRowsPerPageContacts] = useState<number>(20);
    const [pageNumberContacts, setPageNumberContacts] = useState<number>(1);

    function makeQuery(): URLSearchParams {
        const query = new URLSearchParams();
        if (searchValue) query.set("search", searchValue);
        if (sortOrder) query.set("sort_order", sortOrder);
        if (sortKey) query.set("sort_by", sortKey);
        query.set("offset", ((pageNumber - 1) * rowsPerPage).toString());
        query.set("limit", rowsPerPage.toString());
        return query;
    }

    function makeQueryContacts(): URLSearchParams {
        const query = new URLSearchParams();
        if (searchValueContacts) query.set("search", searchValueContacts);
        if (sortOrderContacts) query.set("sort_order", sortOrderContacts);
        if (sortKeyContacts) query.set("sort_by", sortKeyContacts);
        query.set("offset", ((pageNumberContacts - 1) * rowsPerPageContacts).toString());
        query.set("limit", rowsPerPageContacts.toString());
        return query;
    }

    useEffect(() => {
        setPageNumber(1);
        getLenderBranchOptions(dispatch, makeQuery());
    }, [searchValue, sortOrder, sortKey, rowsPerPage])

    useEffect(() => {
        if (state.lenders.selectedLenderBranch) {
            setPageNumberContacts(1);
            getGlobalLenderContacts(dispatch, state.lenders.selectedLenderBranch.id, makeQueryContacts());
        }
    }, [searchValueContacts, sortOrderContacts, sortKeyContacts, rowsPerPageContacts])

    useEffect(() => {
        getLenderBranchOptions(dispatch, makeQuery());
    }, [pageNumber])

    useEffect(() => {
        if (state.lenders.selectedLenderBranch) {
            getGlobalLenderContacts(dispatch, state.lenders.selectedLenderBranch.id, makeQueryContacts());
        }
    }, [pageNumberContacts])

    useEffect(() => {
        if (state.lenders.shouldRefresh) {
            getLenderBranchOptions(dispatch, makeQuery());
            dispatch({ type: AdminActionTypes.SET_LENDER_SHOULD_REFRESH, payload: false });
        }
    }, [state.lenders.shouldRefresh])

    useEffect(() => {
        if (state.lenderContacts.shouldRefresh && state.lenders.selectedLenderBranch) {
            getGlobalLenderContacts(dispatch, state.lenders.selectedLenderBranch.id, makeQueryContacts());
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH, payload: false });
        }
    }, [state.lenderContacts.shouldRefresh])

    useEffect(() => {
        setLenderObj(state.lenders.selectedLenderBranch);
    }, [state.lenders.selectedLenderBranch])

    useEffect(() => {
        if (state.alerts.message === "Successfully created lender.") {
            getLenderBranchOptions(dispatch, makeQuery());
        }
    }, [state.alerts.message])

    const debouncedOnSearch = useCallback(
        debounce((value) => {
            setSearchValue(value.toLowerCase());
        }, 500), []);

    const debouncedOnSearchContacts = useCallback(
        debounce((value) => {
            setSearchValueContacts(value.toLowerCase());
        }, 500), []);

    function makeTableRows(): LenderTableRow[] {
        const tableRows = [];
        for (const lender of state.lenders.tableLenders) {
            tableRows.push({ 
                ...lender, 
                name: lender.lender?.name,  
                abbr_name: lender.lender?.abbr_name, 
                institution_number: lender.lender?.institution_number,
                tableType: formatLenderType(lender?.lender?.type), 
                tableAddress: addressToStringShort(lender?.general_address) 
            })
        }
        return tableRows
    }

    function makeTableRowsContacts(): ContactTableRow[] {
        const rows = [];
        for (const contact of state.lenderContacts.contacts) {
            rows.push({ ...contact, tablePhone: formatPhoneNumber(contact.phone_work), tableFax: formatPhoneNumber(contact.fax) });
        }
        return rows;
    }

    function onSort(objKey: string, order: orderOptions): void {
        if (objKey === "tableType") objKey = "type";
        setSortKey(objKey);
        setSortOrder(order);
    }

    function onSortContacts(objKey: string, order: orderOptions): void {
        setSortKeyContacts(objKey);
        setSortOrderContacts(order);
    }

    function onView(row: LenderTableRow) {
        setSelectedLender(dispatch, row.id);
        setViewingLender(true);
    }

    function onEdit(row: LenderTableRow) {
        setSelectedLender(dispatch, row.id, "edit");
        dispatch({ type: AdminActionTypes.SET_EDITING_LENDER, payload: true });
        dispatch({ type: AdminActionTypes.SET_LENDER_MODAL_ACTIVE, payload: true });
    }

    function onEditContact(row: ContactTableRow) {
        setLenderContactBeingEdited(dispatch, row.id);
        setContactModalActive(true);
    }

    function onDelete(row: LenderTableRow) {
        setLenderToBeDeleted(row.id);
        setDeleteLenderModalActive(true);
    }

    function onDeleteContact(row: ContactTableRow) {
        setContactToBeDeleted(row.id);
        setDeleteContactModalActive(true);
    }

    function onDeleteConfirm() {
        deleteLender(dispatch, lenderToBeDeleted ?? -1);
        setDeleteLenderModalActive(false);
        if (state.lenders.selectedLenderBranch?.id === lenderToBeDeleted) setViewingLender(false);
    }

    function onDeleteContactConfirm() {
        deleteLenderContact(dispatch, contactToBeDeleted ?? -1);
        setDeleteContactModalActive(false);
    }

    function closeSidebar() {
        dispatch({ type: AdminActionTypes.SET_SELECTED_LENDER_BRANCH, payload: undefined });
        setViewingLender(false)
    }

    return (
        <div>
            <AdminLenderModal
                title={`${state.lenders.editing ? "" : "New "}Lender`}
            />
            <AdminLenderContactModal
                open={contactModalActive}
                onClose={() => setContactModalActive(false)}
                title={`${state.lenderContacts.contactBeingEdited ? "" : "New "}Contact`}
            />
            <GenericDialog
                action="destructive"
                title="Confirm Delete"
                onCancel={() => setDeleteLenderModalActive(false)}
                onSubmit={onDeleteConfirm}
                open={deleteLenderModalActive}
                contentText={"Are you sure you want to delete this lender? This is permanent and irreversible."}
            />
            <GenericDialog
                action="destructive"
                title="Confirm Delete"
                onCancel={() => setDeleteContactModalActive(false)}
                onSubmit={onDeleteContactConfirm}
                open={deleteContactModalActive}
                contentText={"Are you sure you want to delete this contact? This is permanent and irreversible."}
            />
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={12} xl={viewingLender ? 6 : 12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}>
                            <TableBase
                                id="admin-lenders-table"
                                columnHeadings={[
                                    { label: "Name", objKey: "name", sortable: true },
                                    { label: "Abbr. Name", objKey: "abbr_name", sortable: true },
                                    { label: "Type", objKey: "tableType", sortable: true },
                                    { label: "Institution No.", objKey: "institution_number" },
                                    { label: "Branch No.", objKey: "branch_number", sortable: true },
                                    { label: "Address", objKey: "tableAddress" }
                                ]}
                                tableRows={makeTableRows()}
                                titleBarProps={{
                                    searchEnabled: true,
                                    onSearch: (value) => debouncedOnSearch(value)
                                }}
                                onSort={onSort}
                                clickEnabled
                                onRowClick={onView}
                                rowHoverProps={{
                                    buttonsInHoverAction: [
                                        {
                                            typeOf: "secondary",
                                            leftIcon: <EditIcon color={Colors.BLACK} />,
                                            label: { text: "Edit", inputId: "edit" },
                                            onClick: (row) => onEdit(row)
                                        },
                                        {
                                            typeOf: "destructive",
                                            leftIcon: <TrashIcon color={deleteButtonHoveredRow ? Colors.WHITE : Theme.ERROR} />,
                                            label: { text: "Delete", inputId: "delete" },
                                            onClick: onDelete,
                                            onMouseEnter: () => setDeleteButtonHoveredRow(true),
                                            onMouseLeave: () => setDeleteButtonHoveredRow(false)
                                        },
                                    ]
                                }}
                                rowsLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.Lenders])}
                                paginationEnabled
                                paginationProps={{
                                    rowsPerPage,
                                    setRowsPerPage,
                                    pageNumber,
                                    setPageNumber,
                                    rowsPerPageOptions: [10, 20, 50],
                                    totalRows: state.lenders.resultCount,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BasicButton
                                label={{ text: "Add Lender", inputId: "add-lender" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => dispatch({ type: AdminActionTypes.SET_LENDER_MODAL_ACTIVE, payload: true })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {viewingLender &&
                <Grid item xs={12} xl={6}>
                    {isObjectLoading(state.loading.objectsLoading, [AdminLoading.SelectedLender]) ?
                        <CircularLoader containerHeight="70vh" testId="admin-table-loader" /> :
                        <Grid container rowSpacing={3} columnSpacing={5}>
                            <Grid item xs={10}>
                                <H4 data-testid="lender-sidebar-header">{lenderObj?.lender?.name}</H4>
                            </Grid>
                            <Grid item xs={2}>
                                <IconWrapper onClick={closeSidebar} data-testid="close-sidebar">
                                    <XCircle color={Colors.BLACK} />
                                </IconWrapper>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Abbreviated Name:</StyledSmall>
                                    <Large>{lenderObj?.lender?.abbr_name}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Type:</StyledSmall>
                                    <Large>{formatLenderType(lenderObj?.lender?.type)}</Large>
                                </Stack>
                            </Grid>
                            {lenderObj?.lender?.type === (LenderType.Institution || LenderType.MortgageCentre) && <>
                                <Grid item xs={6}>
                                    <Stack direction="column" gap={1}>
                                        <StyledSmall>Inst. No.:</StyledSmall>
                                        <Large>{lenderObj?.lender?.institution_number}</Large>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="column" gap={1}>
                                        <StyledSmall>Branch No.:</StyledSmall>
                                        <Large>{lenderObj.branch_number}</Large>
                                    </Stack>
                                </Grid>
                            </>}
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>General Contact Name:</StyledSmall>
                                    <Large>{lenderObj?.general_contact_name}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>General Contact Email:</StyledSmall>
                                    <Large>{lenderObj?.general_email}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>General Address:</StyledSmall>
                                    <Large>{addressToString(lenderObj?.general_address)}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Final Report Contact Name:</StyledSmall>
                                    <Large>{lenderObj?.final_report_contact_name}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Final Report Email:</StyledSmall>
                                    <Large>{lenderObj?.final_report_email}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Final Report Address:</StyledSmall>
                                    <Large>{addressToString(lenderObj?.final_report_address)}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Address for Service:</StyledSmall>
                                    <Large>{addressToString(lenderObj?.address_for_service)}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Address for Registration:</StyledSmall>
                                    <Large>{addressToString(lenderObj?.address_for_registration)}</Large>
                                </Stack>
                            </Grid>
                            {lenderObj && <Grid item xs={12}>
                                <FinancialInfoReadonlyGrid
                                    financialInfo={lenderObj?.financial_info}
                                    title="Financial Information"
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <StyledSmall>Notes:</StyledSmall>
                                    <Large>{lenderObj?.notes}</Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TableBase
                                    id="admin-lender-contacts-table"
                                    columnHeadings={[
                                        { label: "Name", objKey: "name", sortable: true },
                                        { label: "Email", objKey: "email" },
                                        { label: "Phone", objKey: "tablePhone" },
                                        { label: "Fax", objKey: "tableFax" }
                                    ]}
                                    tableRows={makeTableRowsContacts()}
                                    titleBarProps={{
                                        title: "Contacts",
                                        searchEnabled: true,
                                        onSearch: (value) => debouncedOnSearchContacts(value)
                                    }}
                                    onSort={onSortContacts}
                                    rowHoverProps={{
                                        buttonsInHoverAction: [
                                            {
                                                typeOf: "secondary",
                                                leftIcon: <EditIcon color={Colors.BLACK} />,
                                                label: { text: "Edit", inputId: "edit-contact" },
                                                onClick: (row) => onEditContact(row)
                                            },
                                            {
                                                typeOf: "destructive",
                                                leftIcon: <TrashIcon color={deleteButtonHoveredRow ? Colors.WHITE : Theme.ERROR} />,
                                                label: { text: "Delete", inputId: "delete-contact" },
                                                onClick: onDeleteContact,
                                                onMouseEnter: () => setDeleteButtonHoveredRow(true),
                                                onMouseLeave: () => setDeleteButtonHoveredRow(false)
                                            },
                                        ]
                                    }}
                                    rowsLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.LenderContacts])}
                                    paginationEnabled
                                    paginationProps={{
                                        rowsPerPage: rowsPerPageContacts,
                                        setRowsPerPage: setRowsPerPageContacts,
                                        pageNumber: pageNumberContacts,
                                        setPageNumber: setPageNumberContacts,
                                        rowsPerPageOptions: [10, 20, 50],
                                        totalRows: state.lenderContacts.resultCount,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BasicButton
                                    label={{ text: "Add Contact", inputId: "add-contact" }}
                                    action="add"
                                    typeOf="secondary"
                                    onClick={() => setContactModalActive(true)}
                                    size="small"
                                />
                            </Grid>
                        </Grid>}
                </Grid>}
            </Grid>
        </div>
    )
};

const StyledSmall = styled(Small)({
    color: Theme.INPUT
})

const IconWrapper = styled('div')({
    cursor: "pointer",
    paddingTop: "0.5rem"
})