import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getDealCondoCorporations,
    getGlobalCondoCorporations,
    newDealCondoCorporation,
    getDealCondoCorporation,
    saveDealCondoCorporation,
    getGlobalCondoCorporation
} from "../../../libs/service/axios/api";
import { sanitizeCondoCorporationResponse, sanitizeCondoCorporationsResponse, sanitizeSimplePropertyManagementCompanyResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeCondoCorporationRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleCondoCorporation } from "../../../libs/types/UniversalSurvey/CondoCorporation/simpleCondoCorporation";
import { CondoCorporation, CondoCorporationListItem } from "../../../libs/types/UniversalSurvey/CondoCorporation/condoCorporation";
import { SimplePropertyManagementCompany } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/simplePropertyManagementCompany";
import { createPropertyManagementCompanyRecordFromGlobal } from "./propertyManagementCompanies";
import { Loading } from "../../../libs/resources/enums/loading";

function saveCondoCorporation(dispatch: Dispatch<Record<string, any>>, dealId: string, condoCorpInfo: CondoCorporation) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondoCorporation, isLoading: true } });
    saveDealCondoCorporation(dealId, String(condoCorpInfo.id), sanitizeCondoCorporationRequest(condoCorpInfo))
        .then(function () {
            const newListItem: CondoCorporationListItem = {
                id: condoCorpInfo.id,
                isRecord: true,
                label: condoCorpInfo.name,
                name: condoCorpInfo.name,
                abbr_name: condoCorpInfo.abbr_name,
            }
            dispatch({ type: actions.REPLACE_CONDO_CORPORATION_OPTION_WITH_RECORD, payload: { oldId: condoCorpInfo.id, newOption: newListItem, oldIsRecord: true } });
            dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: condoCorpInfo.id });
            dispatch({ type: actions.SET_CONDO_CORPORATION_INFO, payload: condoCorpInfo });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save condo corporation record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondoCorporation, isLoading: false } });
        })
}

function updateCondoCorporationPropertyManagementCompany(dispatch: Dispatch<Record<string, any>>, dealId: string, condoCorpId: number, pmcId: number | undefined) {
    getDealCondoCorporation(dealId, String(condoCorpId))
        .then(function (response: any) {
            const condoCorp: CondoCorporation = sanitizeCondoCorporationResponse(response.data);
            const sanitizedRequest = sanitizeCondoCorporationRequest({
                ...condoCorp,
                property_management_company_record: pmcId === undefined ? undefined : condoCorp.property_management_company_record ?
                    { ...condoCorp.property_management_company_record, id: pmcId } :
                    { id: pmcId, name: undefined, property_management_company: undefined }
            });
            if (pmcId !== undefined) {
                sanitizedRequest.self_managed = false;
            }

            saveDealCondoCorporation(
                dealId,
                String(condoCorpId),
                sanitizedRequest
            )
                .then(function () {
                    getCondoCorporationInfo(dispatch, dealId, condoCorpId)
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save condo corporation record: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condo corporation record: ${error}`, type: AlertTypes.Error } });
        })
}

function createCondoCorpRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, condoCorp: CondoCorporationListItem, simplePmcRecords: SimplePropertyManagementCompany[]) {
    getGlobalCondoCorporation(String(condoCorp.id))
        .then(function (globalResponse: any) {
            newDealCondoCorporation(dealId, { condo_corporation_id: condoCorp.id })
                .then(function (response: any) {
                    const newListItem: CondoCorporationListItem = {
                        ...condoCorp,
                        label: condoCorp.name,
                        isRecord: true,
                        id: response.data.id
                    };
                    dispatch({ type: actions.REPLACE_CONDO_CORPORATION_OPTION_WITH_RECORD, payload: { oldId: condoCorp.id, newOption: newListItem, oldIsRecord: false } });
                    dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: newListItem.id });

                    let shouldGetCondoInfo = true;
                    // check if the global condo corp has an assigned global pmc
                    if (globalResponse.data.property_management_company) {
                        shouldGetCondoInfo = false;
                        const cleanSimpleGlobalPmc = sanitizeSimplePropertyManagementCompanyResponse(globalResponse.data.property_management_company);
                        // check if there is already a record in the deal for that global pmc
                        const matchingRecord = simplePmcRecords.find((record) => record.property_management_company?.id === cleanSimpleGlobalPmc.id);
                        if (matchingRecord) {
                            // add record to new condo corp record
                            dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: matchingRecord.id });
                        } else {
                            // create pmc record and add it to the new condo corp record
                            createPropertyManagementCompanyRecordFromGlobal(dispatch, dealId, {
                                id: cleanSimpleGlobalPmc.id,
                                label: cleanSimpleGlobalPmc.name,
                                name: cleanSimpleGlobalPmc.name,
                                isRecord: false
                            })
                        }
                    }

                    if (shouldGetCondoInfo) getCondoCorporationInfo(dispatch, dealId, newListItem.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create condo corporation record: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global condo corporation: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewCondoCorporationRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, condoCorpInfo: CondoCorporation) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondoCorporation, isLoading: true } });
    newDealCondoCorporation(dealId)
        .then(function (response: any) {
            saveDealCondoCorporation(dealId, String(response.data.id), sanitizeCondoCorporationRequest(condoCorpInfo))
                .then(function () {
                    const newListItem: CondoCorporationListItem = {
                        id: response.data.id,
                        isRecord: true,
                        name: condoCorpInfo.name,
                        abbr_name: condoCorpInfo.abbr_name,
                        label: condoCorpInfo.name
                    };
                    dispatch({ type: actions.ADD_TO_CONDO_CORPORATION_OPTIONS, payload: newListItem });
                    dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: newListItem.id });
                    dispatch({ type: actions.SET_CONDO_CORPORATION_INFO, payload: { ...condoCorpInfo, id: newListItem.id } });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save condo corp record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondoCorporation, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondoCorporation, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create condo corp record: ${error}`, type: AlertTypes.Error } });
        })
}

function createCondoCorporationOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedCondoCorporationId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.CondoCorporationList, isLoading: true } });
    getDealCondoCorporations(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizeCondoCorporationsResponse(dealResponse.data);

            getGlobalCondoCorporations()
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeCondoCorporationsResponse(globalResponse.data.condo_corporations);
                    dispatch({ type: actions.SET_CONDO_CORPORATION_OPTION_LIST, payload: organizeCondoCorpList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: selectedCondoCorporationId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global condo corporations: ${error}`, type: AlertTypes.Error } });
                })
                .finally(() => {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.CondoCorporationList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.CondoCorporationList, isLoading: false } })
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condo corporation records: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeCondoCorpList(dealCondoCorpList: SimpleCondoCorporation[], globalCondoCorpList: SimpleCondoCorporation[]): CondoCorporationListItem[] {
    let cleanList: CondoCorporationListItem[] = [];
    let isRecordList: number[] = [];

    for (const condoCorp of dealCondoCorpList) {
        if (condoCorp.condo_corporation?.id) {
            isRecordList.push(condoCorp.condo_corporation.id);
        }
        cleanList.push({
            id: condoCorp.id,
            name: condoCorp.name,
            abbr_name: condoCorp.abbr_name,
            isRecord: true,
            label: condoCorp.name
        });
    }

    for (const condoCorp of globalCondoCorpList) {
        if (!isRecordList.includes(condoCorp.id)) {
            cleanList.push({
                id: condoCorp.id,
                name: condoCorp.name,
                abbr_name: condoCorp.abbr_name,
                isRecord: false,
                label: condoCorp.name
            });
        }
    }

    cleanList.sort((a, b) => {
        if (a.name && b.name) {
            return a.name > b.name ? 1 : -1;
        } else if (a.name) {
            return 1;
        }
        return -1;
    });

    return cleanList;
}

function getCondoCorporationInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, condoCorpRecordId: number) {
    getDealCondoCorporation(dealId, String(condoCorpRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_CONDO_CORPORATION_INFO, payload: sanitizeCondoCorporationResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condo corp record: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    saveCondoCorporation,
    createCondoCorporationOptionsList,
    updateCondoCorporationPropertyManagementCompany,
    createCondoCorpRecordFromGlobal,
    createNewCondoCorporationRecord,
    getCondoCorporationInfo
}