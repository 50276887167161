import { Dispatch } from "react";

import { AdminActions } from "../context";
import { AdminActionTypes } from "../actionTypes";
import {
    createGlobalLegalFirm,
    deleteGlobalLegalFirm,
    deleteRegionFromLegalFirm,
    getGlobalLegalFirm,
    getGlobalLegalFirms,
    newRegionOnLegalFirm,
    saveGlobalLegalFirm
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import { sanitizeGlobalLegalFirmResponse, sanitizeSimpleGlobalLegalFirmsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { sanitizeGlobalLegalFirmRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { LegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { transformLegalFirmToGlobalLegalFirm } from "../../../libs/types/UniversalSurvey/utils/transformGlobals";
import { SimpleGlobalLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { defaultSimpleGlobalLegalFirm } from "../../../libs/resources/defaults/frontend/defaultSimpleGlobalLegalFirm";

function getLegalFirmOptions(dispatch: Dispatch<AdminActions>, query: URLSearchParams) {
    if (!query) return;
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirms, isLoading: true } });
    getGlobalLegalFirms(query)
        .then(function (response: any) {
            const cleanData = sanitizeSimpleGlobalLegalFirmsResponse(response.data.legal_firms);
            if (query) {
                dispatch({ type: AdminActionTypes.SET_TABLE_LEGAL_FIRMS, payload: cleanData });
            }
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRMS_RESULT_COUNT, payload: response.data.metadata.total });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get law firms: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirms, isLoading: false } });
        })
}

function setSelectedLegalFirm(dispatch: Dispatch<AdminActions>, legalFirmId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SelectedLegalFirm, isLoading: true } });
    getGlobalLegalFirm(String(legalFirmId))
        .then(function (response: any) {
            const cleanData = sanitizeGlobalLegalFirmResponse(response.data);
            dispatch({ type: AdminActionTypes.SET_SELECTED_LEGAL_FIRM, payload: cleanData });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get law firm: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SelectedLegalFirm, isLoading: false } });
        })
}

function deleteLegalFirm(dispatch: Dispatch<AdminActions>, legalFirmId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirms, isLoading: true } });
    deleteGlobalLegalFirm(String(legalFirmId))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Delete law firm: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LegalFirms, isLoading: false } });
        })
}

function saveLegalFirm(dispatch: Dispatch<AdminActions>, legalFirmInfo: LegalFirm, regionList: RegionListItem[]) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: true } });
    let legalFirm = sanitizeGlobalLegalFirmRequest(transformLegalFirmToGlobalLegalFirm(legalFirmInfo));
    saveGlobalLegalFirm(String(legalFirmInfo.id), legalFirm)
        .then(async function () {
            await updateLegalFirmRegions(dispatch, legalFirmInfo, regionList);
            let newSimpleGlobalLegalFirm: SimpleGlobalLegalFirm = {
                ...defaultSimpleGlobalLegalFirm,
                id: legalFirmInfo.id,
                name: legalFirmInfo.name ?? undefined,
                all_regions: legalFirmInfo.all_regions,
            };
            dispatch({ type: AdminActionTypes.UPDATE_LEGAL_FIRM_IN_TABLE, payload: newSimpleGlobalLegalFirm });
            setSelectedLegalFirm(dispatch, legalFirmInfo.id);
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save law firm: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: false } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: false } });
        })
}

function newLegalFirm(dispatch: Dispatch<AdminActions>, legalFirmInfo: LegalFirm, regionList: RegionListItem[]) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: true } });
    let legalFirm = sanitizeGlobalLegalFirmRequest(transformLegalFirmToGlobalLegalFirm(legalFirmInfo));
    createGlobalLegalFirm(legalFirm)
        .then(async function (response: any) {
            await updateLegalFirmRegions(dispatch, {...legalFirmInfo, id: response.data.id }, regionList);
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_SHOULD_REFRESH, payload: true });
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Sucessfully created law firm.`, type: AlertTypes.Success} });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Create law firm: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: false } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLegalFirm, isLoading: false } });
        })
}

async function updateLegalFirmRegions(dispatch: Dispatch<AdminActions>, legalFirmInfo: LegalFirm, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (legalFirmInfo.regions) {
        for (const region of legalFirmInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnLegalFirm(String(legalFirmInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to law firm: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromLegalFirm(String(legalFirmInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the legal firm.")) {
                        dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Cannot delete region on law firm: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

export {
    getLegalFirmOptions,
    newLegalFirm,
    saveLegalFirm,
    setSelectedLegalFirm,
    deleteLegalFirm
}