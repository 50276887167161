const configuration = {
  envName: import.meta.env.VITE_APP_ENV_NAME,
  apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  frontendUrl: import.meta.env.VITE_APP_FRONTEND_URL,
  fullstory: {
    enabled: import.meta.env.VITE_APP_FULLSTORY_ENABLED === "true",
    orgId: import.meta.env.VITE_APP_FULLSTORY_ORG_ID,
  },
  mixpanel: {
    id: import.meta.env.VITE_APP_MIXPANEL_ID,
  },
  bugSnag: {
    apiKey: import.meta.env.VITE_APP_BUGSNAG_KEY,
  },
  idv: {
    templateId: import.meta.env.VITE_APP_IDV_TEMPLATE_ID,
    environmentId: import.meta.env.VITE_APP_IDV_ENVIRONMENT_ID,
  },
  cookieDays: import.meta.env.VITE_APP_COOKIE_DAYS,
  hCaptchaKey: import.meta.env.VITE_APP_HCAPTCHA_KEY,
  conveyanceEnabled: import.meta.env.VITE_APP_CONVEYANCE_ENABLED,
  customerPortalEnabled: import.meta.env.VITE_APP_CUSTOMER_PORTAL_ENABLED,
  cookieConsentEnabled: import.meta.env.VITE_APP_SHOW_COOKIE_CONSENT === "true",
  conveyanceUrl: import.meta.env.VITE_APP_CONVEYANCE_URL,
  webPageUrl: import.meta.env.VITE_APP_WEBPAGE_URL ?? 'https://www.deeded.ca',
  autoHideErrorDuration: 10000,
  docsBaseUrl: import.meta.env.VITE_APP_DOCS_BASE_URL ?? 'https://docs.deeded.ca',
  pointsEnabled: import.meta.env.VITE_APP_POINTS_ENABLED === "true",
  pointsUrl: "https://www.deeded.ca/perks",
  partnerHubUrl: "https://partnersuccesshub.deeded.ca",
  callScheduleUrl: "https://clients.deeded.ca/meeting-scheduler.html",
  googleAPIKeys: {
    placesAPIKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
  },
};

export default configuration;
