import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { formatGovernmentLevel } from "../../../../libs/utils/formatValues";
import { Government, GovernmentErrorState } from "../../../../libs/types/UniversalSurvey/Government/government";
import { defaultGovernment } from "../../../../libs/resources/defaults/frontend/defaultGovernment";
import { createNewGovernmentRecord, saveGovernment } from "../../../../context/UniversalSurvey/asyncActions/governments";
import { GovernmentLevels } from "../../../../libs/resources/enums/governmentLevels";
import { GovernmentLevelOptions } from "../../../../libs/resources/options";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import FinancialInfoGrid from "../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultGovernmentErrorState } from "../../../../libs/resources/defaults/errorStates/defaultGovernmentErrorState";
import { defaultFinancialInfoErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";

type Props = Omit<ModalProps, "children">;

export default function GovernmentModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [governmentObj, setGovernmentObj] = useState<Government>({ ...defaultGovernment });
    const [originalCopy, setOriginalCopy] = useState<Government>({ ...defaultGovernment });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<GovernmentErrorState>({ ...defaultGovernmentErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.governments.editingGovernmentRecord && state.governments.governmentInfo) {
            setGovernmentObj(state.governments.governmentInfo);
            setOriginalCopy(state.governments.governmentInfo);
        } else {
            setGovernmentObj({ ...defaultGovernment });
        }
    }, [state.governments.editingGovernmentRecord]);

    function updateGovernmentInfo<
        K extends keyof Government,
        V extends Government[K]
    >(key: K, value: V): void {
        const tempGovernmentObj = { ...governmentObj }
        tempGovernmentObj[key] = value;
        setGovernmentObj(tempGovernmentObj);
    }

    function submit() {
        if (state.governments.editingGovernmentRecord) {
            saveGovernment(dispatch, String(state.deal.dealInfo?.id), governmentObj);
        } else {
            createNewGovernmentRecord(dispatch, String(state.deal.dealInfo?.id), governmentObj, state.dataSheet.currEntity!);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_GOVERNMENT_RECORD, payload: false });
        setGovernmentObj({ ...defaultGovernment });
        setOriginalCopy({ ...defaultGovernment });
        setErrorState({ ...defaultGovernmentErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(governmentObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!governmentObj.name || _.isEqual(originalCopy, governmentObj) || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, governmentObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.governments.editingGovernmentRecord ? "" : "New "}Government`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.governments.editingGovernmentRecord ? " Changes" : ""}`}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveGovernment])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{text: "Government Name", inputId: "name", isRequired: true}}
                            placeholder="Government entity's name"
                            value={governmentObj.name ?? undefined}
                            onChange={(e) => updateGovernmentInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Government Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Abbreviated Name", inputId: "abbr_name"}}
                            value={governmentObj.abbr_name ?? undefined}
                            onChange={(e) => updateGovernmentInfo("abbr_name", e.target.value)}
                            placeholder="Abbreviated name"
                            inputProps={{ "aria-label": "Abbreviated Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Dropdown
                            formatValue={formatGovernmentLevel}
                            value={governmentObj.level ?? ""}
                            onChange={(e) => updateGovernmentInfo("level", e.target.value as GovernmentLevels)}
                            placeholder="Select an option..."
                            options={GovernmentLevelOptions}
                            label={{ text: "Level" }}
                            inputProps={{ "aria-label": "Level" }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Department", inputId: "department"}}
                            value={governmentObj.department?? undefined}
                            onChange={(e) => updateGovernmentInfo("department", e.target.value)}
                            placeholder="Government department"
                            inputProps={{ "aria-label": "Department" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={governmentObj.fax}
                            onChange={(value) => updateGovernmentInfo("fax", value)}
                            label={{text: "Fax Number", inputId: "fax"}}
                            inputProps={{ "aria-label": "Fax Number" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "fax",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "General Email", inputId: "general_email"}}
                            value={governmentObj.general_email ?? undefined}
                            onChange={(e) => updateGovernmentInfo("general_email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "General Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "general_email",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={governmentObj.general_address}
                            setAddress={(value) => updateGovernmentInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Website", inputId: "website"}}
                            value={governmentObj.website ?? undefined}
                            onChange={(e) => updateGovernmentInfo("website", e.target.value)}
                            placeholder="https://"
                            inputProps={{ "aria-label": "Website" }}
                            fieldType="url"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "website",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FinancialInfoGrid
                            financialInfo={governmentObj.financial_info}
                            setFinancialInfo={(value) => updateGovernmentInfo("financial_info", value)}
                            errorState={financialInfoErrorState}
                            setErrorState={setFinancialInfoErrorState}
                            id="financial_info"
                            title="Financial Information"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}