import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getDealTitleInsurance,
    getDealTitleInsurancePremiums,
    saveDealTitleInsurance
} from "../../../libs/service/axios/api";
import { sanitizeSimpleTitleInsurancePremiumsResponse, sanitizeTitleInsuranceResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeTitleInsuranceRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { TitleInsurance } from "../../../libs/types/UniversalSurvey/TitleInsurance/titleInsurance";
import { sortTitleInsurancePremiums } from "../../../libs/utils/arrays";
import { Loading } from "../../../libs/resources/enums/loading";

function switchToTitleInsuranceSection(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getDealTitleInsurance(dealId)
        .then(function (response: any) {
            const titleInsurance = sanitizeTitleInsuranceResponse(response.data);
            dispatch({ type: actions.SET_TITLE_INSURANCE_INFO, payload: titleInsurance });

            getDealTitleInsurancePremiums(dealId)
                .then(function (response: any) {
                    const premiums = sanitizeSimpleTitleInsurancePremiumsResponse(response.data);
                    sortTitleInsurancePremiums(premiums);
                    dispatch({ type: actions.SET_TITLE_INSURANCE_PREMIUMS, payload: premiums });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get title insurance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function saveTitleInsurance(dispatch: Dispatch<Record<string, any>>, dealId: string, titleInsuranceInfo: TitleInsurance) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    saveDealTitleInsurance(dealId, sanitizeTitleInsuranceRequest(titleInsuranceInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save title insurance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

export {
    saveTitleInsurance,
    switchToTitleInsuranceSection
}