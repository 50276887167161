import { StatDec } from "../../../types/UniversalSurvey/StatutoryDeclarations/statDec";

export const defaultStatDec: StatDec = {
    id: -1,
    declaration: undefined,
    our_lawyer: false,
    date_created: undefined,
    date_updated: undefined,

    client_list: undefined
}