import { DisbursementRequest } from "../../../types/UniversalSurvey/Disbursements/disbursementRequest";
import { DisbursementTypes } from "../../enums/disbursements";

export const defaultDisbursementRequest: DisbursementRequest = {
    type: DisbursementTypes.Taxable,
    taxable_amount: null,
    non_taxable_amount: null,
    taxable_desc: null,
    taxable_acc_code: null,
    non_taxable_desc: null,
    non_taxable_acc_code: null
}