import { LegalFirmOffice } from "../../../types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { defaultSimpleAddress } from "./defaultSimpleAddress";
import { defaultSimpleLegalFirm } from "./defaultSimpleLegalFirm";

export const defaultLegalFirmOffice: LegalFirmOffice = {
    id: -1,
    general_address: defaultSimpleAddress,
    jurisdiction: undefined,
    municipality: undefined,
    phone: undefined,
    phone_extension: undefined,
    fax: undefined,
    email: undefined,
    date_created: undefined,
    date_updated: undefined,

    legal_firm_office: undefined,
    legal_firm_record: defaultSimpleLegalFirm
}