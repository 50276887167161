export enum Sections {
    DealOverview = "Deal Overview",
    ClientInformation = "Client Information",
    PropertyInformation = "Property Information",
    Encumbrances = "Existing Encumbrances",
    NewMortgage = "New Mortgage",
    FireInsurance = "Fire Insurance",
    TitleInsurance = "Title Insurance",
    StatementOfAccount = "Statement of Account",
    TrustLedger = "Trust Ledger",
    UnsecuredDebts = "Unsecured Debts",
    LandTransferTax = "Land Transfer Tax",
    Conditions = "Conditions"
}

export enum AdminSections {
    LegalProfessional = "Legal Professionals",
    Lender = "Lenders",
    MortgageBrokerage = "Mortgage Brokerages",
    ChargeHolderCompany = "Companies",
    Governments = "Governments",
    OtherChargeHolder = "Other Charge Holders",
    FireInsuranceCompany = "Fire Insurance Companies",
    FireInsuranceBrokerage = "Fire Insurance Brokerages",
    CondoCorporation = "Condo Corporations",
    PropertyManagementCompany = "Property Management Companies",
    RealEstateBrokerage = "Real Estate Brokerages"
}