// Importing React tools
import { useContext, useState, useEffect } from "react";

// Importing mui design kit components
import { Grid, Stack } from "@mui/material";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing application components
import { H4, BodyBold, Body } from "../../../../components/Common/Typography/index";
import UndertakingModal from "../Components/Modals/UndertakingModal";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import TableBase from "../../../../components/Common/Table/TableBase";
import { getUndertakingsInDeal, getUndertakingBeingEdited } from "../../../context/UniversalSurvey/asyncActions/undertakings";
import { getLiensInDeal } from "../../../context/UniversalSurvey/asyncActions/existingLiens";
import DisplayPills from "../../../../components/Common/StatusPills/DisplayPills";
import { SimpleUndertaking } from "../../../libs/types/UniversalSurvey/Undertaking/simpleUndertaking";
import { UndertakingStatus } from "../../../libs/resources/enums/undertakings";
import { getAffidavitBeingEdited, getAffidavitsInDeal } from "../../../context/UniversalSurvey/asyncActions/affidavits";
import AffidavitModal from "../Components/Modals/AffidavitModal";
import { getClientTabName } from "../../../context/UniversalSurvey/asyncActions/clients";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import StatutoryDeclarationModal from "../Components/Modals/StatutoryDeclarationModal";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { getGlobalStatDecsOptions, getStatDecBeingEdited, getStatDecsInDeal } from "../../../context/UniversalSurvey/asyncActions/statutoryDeclarations";
import { getStrOutput } from "../../../libs/utils/pluralizationFunctions";
import { getConditionBeingEdited, getConditionsInDeal } from "../../../context/UniversalSurvey/asyncActions/conditions";
import { capitalizeFirstLetter, formatConditionType } from "../../../libs/utils/formatValues";
import ConditionModal from "../Components/Modals/ConditionModal/ConditionModal";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import { DateObjectToDateFormat } from "../../../libs/utils/dateHelper";

export default function ConditionSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [undertakingModalActive, setUndertakingModalActive] = useState<boolean>(false);
    const [affidavitModalActive, setAffidavitModalActive] = useState<boolean>(false);
    const [statDecModalActive, setStatDecModalActive] = useState<boolean>(false);
    const [conditionModalActive, setConditionModalActive] = useState<boolean>(false);

    useEffect(() => {
        getConditionsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getUndertakingsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getLiensInDeal(dispatch, String(state.deal.dealInfo?.id), false, false);
        getAffidavitsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getStatDecsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getGlobalStatDecsOptions(dispatch);
    }, []);

    function makeConditionRows() {
        const rows = [];
        const tempConditions = [...state.conditions.conditionsInDeal];
        tempConditions.sort((a, b) => {
            if (a.condition_met !== b.condition_met) {
                if (a.condition_met) {
                    return -1;
                } else {
                    return 1;
                }
            }
            return a.id - b.id;
        });
        for (const condition of tempConditions) {
            let linked = [];
            if (condition.undertaking) linked.push("UT");
            if (condition.affidavit) linked.push("AF");
            if (condition.stat_dec) linked.push("SD");
            if (condition.debt) linked.push("DE");
            if (condition.lien) linked.push("EN");
            rows.push({
                id: condition.id,
                type: formatConditionType(condition.type),
                condition: condition.condition,
                linked: getStrOutput(linked),
                status: (
                    <Stack direction="row" justifyContent="flex-start">
                        <DisplayPills
                            label={condition.condition_met ? "Met" : "Unmet"}
                            status={{ errors: 0, missingFields: condition.condition_met ? 0 : 1, isEmpty: false }}
                        />
                    </Stack>
                )
            })
        }
        return rows;
    }

    function makeUndertakingRows() {
        const rows = [];
        for (const undertaking of state.undertakings.undertakingsInDeal) {
            rows.push({
                id: undertaking.id,
                directions: getUndertakingLabel(undertaking),
                discharged_by: capitalizeFirstLetter(undertaking.discharged_by),
                discharge_date: DateObjectToDateFormat(undertaking.discharge_date, false, false),
                status: (
                    <Stack direction="row" justifyContent="flex-start">
                        <DisplayPills
                            label={undertaking.status === UndertakingStatus.Fulfilled ? "Fulfilled" : "Outstanding"}
                            status={{ errors: 0, missingFields: undertaking.status === UndertakingStatus.Fulfilled ? 0 : 1, isEmpty: false }}
                        />
                    </Stack>
                )
            })
        }
        return rows;
    }

    function makeAffidavitRows() {
        const rows = [];
        for (const affidavit of state.affidavits.affidavitsInDeal) {
            rows.push({
                id: affidavit.id,
                client: affidavit.client_record ? getClientTabName(affidavit.client_record) : "",
                execution_num: affidavit.execution_num,
                plaintiffs: affidavit.plaintiffs,
                amount: <Body><FormatNumeric value={affidavit.amount ?? 0} /></Body>
            })
        }
        return rows;
    }

    function makeDeclarationRows() {
        const rows = [];
        for (const declaration of state.statutoryDeclarations.statDecsInDeal) {
            rows.push({
                id: declaration.id,
                clients: declaration.our_lawyer ? "Our Lawyer" : getClientsLabel(declaration.client_list ?? []),
                declaration: declaration.declaration
            })
        }
        return rows;
    }

    function getUndertakingLabel(undertaking: SimpleUndertaking) {
        let label = undertaking.direction;

        if (undertaking.status === UndertakingStatus.Fulfilled) {
            return label;
        }
        return <BodyBold>{label}</BodyBold>;
    }

    function getClientsLabel(clients: SimpleClient[]) {
        let clientList: string[] = [];
        for (const client of clients) {
            clientList.push(getClientTabName(client))
        }
        return getStrOutput(clientList);
    }

    return (
        <div>
            <UndertakingModal
                open={undertakingModalActive}
                onClose={() => setUndertakingModalActive(false)}
            />
            <AffidavitModal
                open={affidavitModalActive}
                onClose={() => setAffidavitModalActive(false)}
            />
            <StatutoryDeclarationModal
                open={statDecModalActive}
                onClose={() => setStatDecModalActive(false)}
            />
            <ConditionModal
                open={conditionModalActive}
                onClose={() => setConditionModalActive(false)}
            />
            {isObjectLoading(state.dataSheet.objectsLoading, [Loading.ConditionList, Loading.UndertakingList, Loading.LienList, Loading.AffidavitList, Loading.StatDecList, Loading.GlobalStatDecList]) ? <CircularLoader containerHeight="70vh" /> :
            <Grid container rowSpacing={5} columnSpacing={5}>
                {state.conditions.conditionsInDeal.length > 0 &&
                    <Grid item xs={12}>
                        <TableBase
                            columnHeadings={[
                                { label: "Type", objKey: "type" },
                                { label: "Condition", objKey: "condition" },
                                { label: "Linked", objKey: "linked" },
                                { label: "Status", objKey: "status" },
                            ]}
                            tableRows={makeConditionRows()}
                            clickEnabled
                            onRowClick={(row) => {
                                getConditionBeingEdited(dispatch, String(state.deal.dealInfo?.id), row.id)
                                setConditionModalActive(true);
                            }}
                            id="condition-table"
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <BasicButton
                        label={{ text: "Add a Condition", inputId: "add-condition" }}
                        action="add"
                        typeOf="secondary"
                        onClick={() => setConditionModalActive(true)}
                    />
                </Grid>

                {state.undertakings.undertakingsInDeal.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <H4>Undertakings</H4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableBase
                                columnHeadings={[
                                    { label: "Directions", objKey: "directions" },
                                    { label: "Discharged by", objKey: "discharged_by" },
                                    { label: "Discharge Date", objKey: "discharge_date" },
                                    { label: "Status", objKey: "status" },
                                ]}
                                tableRows={makeUndertakingRows()}
                                clickEnabled
                                onRowClick={(row) => {
                                    getUndertakingBeingEdited(dispatch, String(state.deal.dealInfo?.id), row.id)
                                    setUndertakingModalActive(true);
                                }}
                                id="undertaking-table"
                            />
                        </Grid>
                    </>
                )}

                {state.statutoryDeclarations.statDecsInDeal.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <H4>Statutory Declarations</H4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableBase
                                columnHeadings={[
                                    { label: "Clients", objKey: "clients" },
                                    { label: "Declaration", objKey: "declaration" }
                                ]}
                                tableRows={makeDeclarationRows()}
                                clickEnabled
                                onRowClick={(row) => {
                                    getStatDecBeingEdited(dispatch, String(state.deal.dealInfo?.id), row.id)
                                    setStatDecModalActive(true);
                                }}
                                id="stat-dec-table"
                            />
                        </Grid>
                    </>
                )}

                {state.affidavits.affidavitsInDeal.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <H4>Executions Affidavits</H4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableBase
                                columnHeadings={[
                                    { label: "Client", objKey: "client" },
                                    { label: "Execution No.", objKey: "execution_num" },
                                    { label: "Plaintiff", objKey: "plaintiffs" },
                                    { label: "Amount", objKey: "amount" },
                                ]}
                                tableRows={makeAffidavitRows()}
                                clickEnabled
                                onRowClick={(row) => {
                                    getAffidavitBeingEdited(dispatch, String(state.deal.dealInfo?.id), row.id)
                                    setAffidavitModalActive(true);
                                }}
                                id="affidavit-table"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            }
        </div>
    )
};