import { useEffect, useState, useContext } from "react";

import { AdminContext } from "../../../../../context/AdminPanel/context";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Lender, LenderErrorState } from "../../../../../libs/types/UniversalSurvey/Lender/lender";
import { defaultLender } from "../../../../../libs/resources/defaults/frontend/defaultLender";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultLenderErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLenderErrorState";
import { defaultFinancialInfoErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";
import LenderForm from "./LenderForm";
import { AdminActionTypes } from "../../../../../context/AdminPanel/actionTypes";
import { saveLender, submitNewLender } from "../../../../../context/AdminPanel/asyncActions/lenders";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { AdminLoading } from "../../../../../libs/resources/enums/loading";
import { transformLenderBranchToLender } from "../../../../../libs/types/UniversalSurvey/utils/transformGlobals";


export default function AdminLenderModal(props: Omit<ModalProps, "children" | "open">) {
    const [state, dispatch] = useContext(AdminContext);
    const [lenderObj, setLenderObj] = useState<Lender>({ ...defaultLender });
    const [sourceLenderId, setSourceLenderId] = useState<number | undefined>(undefined);
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([allRegion]);
    const [errorState, setErrorState] = useState<LenderErrorState>({ ...defaultLenderErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if(state.lenders.editing && state.lenders.selectedLenderBranch) {
            setLenderObj(transformLenderBranchToLender(state.lenders.selectedLenderBranch));
            let tempRegionList: RegionListItem[] = [];
            if (state.lenders.selectedLenderBranch.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.lenders.selectedLenderBranch.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
            setSourceLenderId(state.lenders.selectedLenderBranch.lender?.id === -1 ? undefined : state.lenders.selectedLenderBranch.lender?.id);
        } else {
            setLenderObj({ ...defaultLender });
            setSelectedRegionList([allRegion]);
        }
        setErrorState({ ...defaultLenderErrorState });
    }, [state.lenders.editing, state.lenders.selectedLenderBranch]);

    useEffect(() => {
        setModalOpen(state.lenders.lenderModalActive);
    }, [state.lenders.lenderModalActive]);

    function updateLenderInfo<
        K extends keyof Lender,
        V extends Lender[K]
    >(key: K, value: V): void {
        const tempLenderObj = { ...lenderObj }
        // @ts-ignore
        if (Object.keys(tempLenderObj).includes(key)) tempLenderObj[key] = value;
        setLenderObj(tempLenderObj);
    }

    function submit() {
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.lenders.editing) {
            saveLender(dispatch, lenderObj, sourceLenderId!, regionList);
        } else {
            submitNewLender(dispatch, lenderObj, sourceLenderId, regionList);
        }
    }

    function discard() {
        dispatch({ type: AdminActionTypes.SET_EDITING_LENDER, payload: false });
        dispatch({ type: AdminActionTypes.SET_LENDER_MODAL_ACTIVE, payload: false });
        setLenderObj({ ...defaultLender });
        setErrorState({ ...defaultLenderErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        setSelectedRegionList([allRegion]);
        setSourceLenderId(undefined);
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled() {
        if (!lenderObj.name || !lenderObj.type || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, lenderObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={modalOpen}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={props.title}
            saveDisabled={saveDisabled}
            isLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.SelectedLender])}
            isSaving={isObjectLoading(state.loading.objectsLoading, [AdminLoading.SaveLender])}
        >
            <LenderForm
                type={"admin"}
                lender={lenderObj}
                lenderSourceId={sourceLenderId}
                setLenderSourceId={setSourceLenderId}
                setLender={setLenderObj}
                updateLenderInfo={updateLenderInfo}
                regions={state.regions.regions}
                selectedRegionList={selectedRegionList}
                setSelectedRegionList={setSelectedRegionList}
                errorState={errorState}
                setErrorState={setErrorState}
                financialInfoErrorState={financialInfoErrorState}
                setFinancialInfoErrorState={setFinancialInfoErrorState}
            />
        </ModalBase>
    );
}