import { Dispatch } from "react";

import { AdminActions } from "../context";
import { AdminActionTypes } from "../actionTypes";
import { deleteLenderBranch, deleteRegionFromLender, getLenderBranch, getLenderBranches, getLenderSources, newLenderBranch, newLenderSource, newRegionOnLender, saveLenderBranch, saveLenderSource,  } from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import { sanitizeLenderBranchResponse, sanitizeSimpleLenderBranchesResponse, sanitizeSimpleLenderSourcesResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { getGlobalLenderContacts } from "./lenderContacts";
import { sanitizeCreateLenderBranchRequest, sanitizeCreateLenderSourceRequest, sanitizeLenderBranchRequest, sanitizeLenderSourceRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { Lender } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { SimpleSourceLender } from "../../../libs/types/UniversalSurvey/Lender/SourceLender/simpleSourceLender";
import { SimpleLenderBranch } from "../../../libs/types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";
import { transformLenderToSimpleLenderBranch } from "../../../libs/types/UniversalSurvey/utils/transformGlobals";

function getLenderBranchOptions(dispatch: Dispatch<AdminActions>, query: URLSearchParams) {
    if (!query) return;
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.Lenders, isLoading: true } });
    getLenderBranches(query)
        .then(function (response: any) {
            const cleanData = sanitizeSimpleLenderBranchesResponse(response.data.lenders);
            if (query) {
                dispatch({ type: AdminActionTypes.SET_TABLE_LENDERS, payload: cleanData });
            }
            dispatch({ type: AdminActionTypes.SET_LENDER_RESULT_COUNT, payload: response.data.metadata.total });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get lenders: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.Lenders, isLoading: false } });
        })
}

function getLenderSourceOptions(dispatch: Dispatch<AdminActions>, query?: URLSearchParams) {
    if (!query) return dispatch({ type: AdminActionTypes.SET_ALL_LENDERS, payload: [] });
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderSources, isLoading: true } });
    getLenderSources(query)
        .then(function (response: any) {
            const cleanData = sanitizeSimpleLenderSourcesResponse(response.data.lenders);
            dispatch({ type: AdminActionTypes.SET_ALL_LENDERS, payload: cleanData });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get source lenders: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderSources, isLoading: false } });
        })
}

function setSelectedLender(dispatch: Dispatch<AdminActions>, lenderId: number, reason?: "edit" | undefined) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SelectedLender, isLoading: true } });
    getLenderBranch(String(lenderId))
        .then(function (response: any) {
            const cleanData = sanitizeLenderBranchResponse(response.data);
            dispatch({ type: AdminActionTypes.SET_SELECTED_LENDER_BRANCH, payload: cleanData });
            if (reason !== "edit") getGlobalLenderContacts(dispatch, lenderId);
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get lender: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SelectedLender, isLoading: false } });
        })
}

function deleteLender(dispatch: Dispatch<AdminActions>, lenderId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.Lenders, isLoading: true } });
    deleteLenderBranch(String(lenderId))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LENDER_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Delete lender: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.Lenders, isLoading: false } });
        })
}

function saveLender(dispatch: Dispatch<AdminActions>, lenderInfo: Lender, sourceLenderId: number, regionList: RegionListItem[]) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: true } });
    updateSourceLender(dispatch, lenderInfo, sourceLenderId)
        .then(() => {
            let newSimpleSourceLender: SimpleSourceLender = {
                id: sourceLenderId,
                name: lenderInfo.name ?? undefined,
                institution_number: lenderInfo.institution_number ?? undefined,
                abbr_name: lenderInfo.abbr_name ?? undefined,
                type: lenderInfo.type!
            };
            dispatch({ type: AdminActionTypes.UPDATE_SOURCE_LENDERS_IN_TABLE, payload: newSimpleSourceLender });
            saveLenderBranch(String(lenderInfo.id), sanitizeLenderBranchRequest(lenderInfo))
                .then(async function () {
                    await updateLenderRegions(dispatch, lenderInfo, regionList);
                    dispatch({ type: AdminActionTypes.UPDATE_LENDER_IN_TABLE, payload: { id: lenderInfo.id, newData: transformLenderToSimpleLenderBranch(lenderInfo, sourceLenderId) as SimpleLenderBranch }});
                    dispatch({ type: AdminActionTypes.SET_LENDER_MODAL_ACTIVE, payload: false });
                    setSelectedLender(dispatch, lenderInfo.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save lender: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save lender source: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: false } });
        })
}

async function updateLenderRegions(dispatch: Dispatch<AdminActions>, lenderInfo: Lender, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (lenderInfo.regions) {
        for (const region of lenderInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnLender(String(lenderInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to lender: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromLender(String(lenderInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the lender.")) {
                        dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Cannot delete region on lender: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

async function updateSourceLender(dispatch: Dispatch<AdminActions>, lenderInfo: Lender, sourceLenderId: number) {
    let lenderSource = sanitizeLenderSourceRequest(lenderInfo)
    return saveLenderSource(String(sourceLenderId), lenderSource);
}

function submitNewLender(dispatch: Dispatch<AdminActions>, lenderInfo: Lender, sourceLenderId: number | undefined, regionList: RegionListItem[]) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: true } });
    if (sourceLenderId) {
        updateSourceLender(dispatch, lenderInfo, sourceLenderId)
            .then(() => {
                let newSimpleSourceLender: SimpleSourceLender = {
                    id: sourceLenderId,
                    name: lenderInfo.name ?? undefined,
                    institution_number: lenderInfo.institution_number ?? undefined,
                    abbr_name: lenderInfo.abbr_name ?? undefined,
                    type: lenderInfo.type!
                };
                dispatch({ type: AdminActionTypes.UPDATE_SOURCE_LENDERS_IN_TABLE, payload: newSimpleSourceLender });
                submitNewLenderBranch(dispatch, lenderInfo, sourceLenderId, regionList);
            })
            .catch(function (error: any) {
                dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save lender source: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
                dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: false } });
            })
    } else {
        newLenderSource(sanitizeCreateLenderSourceRequest(lenderInfo))
        .then(function (response: any) {
            submitNewLenderBranch(dispatch, lenderInfo, response.data.id, regionList);
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Create lender source: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: false } });
        })
    }
}

function submitNewLenderBranch(dispatch: Dispatch<AdminActions>, lenderInfo: Lender, sourceLenderId: number, regionList: RegionListItem[]) {
    newLenderBranch({...sanitizeCreateLenderBranchRequest(lenderInfo), lender_id: sourceLenderId })
        .then(async function (response: any) {
            await updateLenderRegions(dispatch, { ...lenderInfo, id: response.data.id }, regionList);
            setSelectedLender(dispatch, response.data.id);
            dispatch({ type: AdminActionTypes.SET_LENDER_MODAL_ACTIVE, payload: false });
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Successfully created lender.`, type: AlertTypes.Success } });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Create lender: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLender, isLoading: false } });
        })
}

export {
    getLenderBranchOptions,
    getLenderSourceOptions,
    setSelectedLender,
    deleteLender,
    saveLender,
    submitNewLender
}