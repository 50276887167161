import { DebtHolderResponse } from "../../../types/UniversalSurvey/Debts/debtHolderResponse";

export const defaultDebtHolderResponse: DebtHolderResponse = {
    id: -1, 
    name: null,
    general_address: null,
    financial_info: null,
    general_email: null,
    address: null,
    phone: null,
    email: null
}