import React, {useEffect, useState} from "react";
import {Box, BoxProps, Input, styled, SxProps, Theme} from "@mui/material";
import MagnifingGlassSVG from "../../components/Svg/MagnifingGlass";
import constants from "../../styles/constants";

interface DeededSearchProps {
  onChange: (val: string) => void;
  placeholder: string;
  value: string | undefined;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const DeededSearch: React.FC<DeededSearchProps & Omit<BoxProps, "variant" | "onChange">> = ({
  onChange,
  placeholder,
  value,
  disabled,
  sx,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState(value ?? "");

  // Debounce effect
  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(searchTerm);
    }, 300); // Wait 300 ms to start the start

    return () => {
      clearTimeout(handler); // Clean timeout if the search term changes before it starts
    };
  }, [searchTerm, onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Updates local state instead of calling directly onChange
  };

  return (
    <SearshBox
      className={disabled ? "disabled" : ""}
      data-selenium-test="DeededSearch"
      sx={sx}
      {...props}
    >
      <MagnifingGlassSVG />

      <InputStyled
        placeholder={placeholder}
        onChange={handleInputChange}
        value={searchTerm}
        disabled={disabled}
        data-testid="search-input"
      />
    </SearshBox>
  );
};

const SearshBox = styled(Box)(({theme}) => ({
  boxSizing: "border-box",
  minWidth: "274px",
  border: `1px solid ${constants.colors.grayLight}`,
  width: "100%",
  height: "36px",
  lineHeight: "40px !important",
  marginRight: "20px",
  display: "flex",
  background: constants.colors.white,
  borderRadius: "5px",
  alignItems: "center",
  transition: constants.transitions,
  position: "relative",
  svg: {
    transition: constants.transitions,
    fill: constants.colors.searchColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "10px",
    display: "flex",
    alignItems: "center",
    margin: "auto 0",
    pointerEvents: "none",
  },
  "&:hover": {
    border: `1px solid ${constants.colors.deededGray}`,
    transition: constants.transitions,
  },
  "&.disabled": {
    background: constants.colors.disabled2,
    cursor: "no-drop",
    border: `1px solid ${constants.colors.grayLight}`,
    "&:hover, &:focus-within": {
      svg: {
        fill: constants.colors.searchColor,
      },
    },
  },
  "&:hover, &:focus-within": {
    svg: {
      transition: constants.transitions,
      fill: constants.colors.red,
    },
  },
  "&:focus-within": {
    border: `1px solid ${constants.colors.red}`,
    transition: constants.transitions,
  },
  "& > div:before, & > div:after": {
    display: "none",
  },
}));

const InputStyled = styled(Input)({
  boxSizing: "border-box",
  border: "none",
  height: "34px",
  outline: "none",
  width: "100%",
  lineHeight: "36px !important",
  borderRadius: "5px",
  fontWeight: 500,
  fontSize: "14px",
  paddingLeft: "40px",
  paddingRight: "15px",
  "&[disabled]": {
    cursor: "no-drop",
    color: constants.colors.grayBorder,
    background: constants.colors.disabled2,
  },
  "&::placeholder": {
    color: constants.colors.searchColor,
  },
});

export default DeededSearch;
