import { FinancialInfoRequest } from "../../../types/UniversalSurvey/FinancialInfo/financialInfoRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultFinancialInfoRequest: FinancialInfoRequest = {
    transit_number: null,
    institution_number: null,
    name: null,
    account_number: null,
    fax_for_payout_info: null,
    email_for_payout_info: null,
    portal_for_payout_info: null,

    branch_address: defaultAddressRequest
}