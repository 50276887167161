import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Lender } from "../../../../../libs/types/UniversalSurvey/Lender/lender";
import { defaultLender } from "../../../../../libs/resources/defaults/frontend/defaultLender";
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import { formatLenderType } from "../../../../../libs/utils/formatValues";
import { LenderType } from "../../../../../libs/resources/enums/lenderInstitutions";
import Link from "../../../../../../components/Common/Links/Link";
import { addressToString, regionListToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyLenderModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [lenderObj, setLenderObj] = useState<Lender>({ ...defaultLender });

    useEffect(() => {
        if(state.lenders.lenderInfo) {
            setLenderObj(state.lenders.lenderInfo);
        }
    }, [state.lenders.editingLenderRecord]);

    function discard() {
        dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: false });
        setLenderObj({ ...defaultLender });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={lenderObj.name}
            subtitle="LENDER"
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Abbreviated Name:
                        </Small>
                        <Large>
                            {lenderObj.abbr_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Lender Type:
                        </Small>
                        <Large>
                            {formatLenderType(lenderObj.type)}
                        </Large>
                    </Stack>
                </Grid>
                {lenderObj.type === (LenderType.Institution || LenderType.MortgageCentre) ? (
                    <>
                        <Grid item xs={2}>
                            <Stack direction="column" gap={1}>
                                <Small style={{ color: Theme.INPUT }}>
                                    Inst. No.:
                                </Small>
                                <Large>
                                    {lenderObj.institution_number}
                                </Large>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack direction="column" gap={1}>
                                <Small style={{ color: Theme.INPUT }}>
                                    Branch No.:
                                </Small>
                                <Large>
                                    {lenderObj.branch_number}
                                </Large>
                            </Stack>
                        </Grid>
                    </>
                ) : ( <Grid item xs={4} /> )}
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Contact Name:
                        </Small>
                        <Large>
                            {lenderObj.general_contact_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Address:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(lenderObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Contact Email:
                        </Small>
                        <Link 
                            href={lenderObj.general_email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Final Report Contact Name:
                        </Small>
                        <Large>
                            {lenderObj.final_report_contact_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Final Report Address:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(lenderObj.final_report_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Final Report Email:
                        </Small>
                        <Link 
                            href={lenderObj.final_report_email} 
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address for Service:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(lenderObj.address_for_service)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address for Registration:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(lenderObj.address_for_registration)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={lenderObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>
                            {lenderObj.lender?.notes}
                        </Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}