import { FireInsuranceRequest } from "../../../types/UniversalSurvey/FireInsurance/fireInsuranceRequest";
import { FireInsuranceNumberType } from "../../enums/fireInsurance";

export const defaultFireInsuranceRequest: FireInsuranceRequest = {
    property_id: null,
    type: FireInsuranceNumberType.Policy,
    number: null,
    coverage_amount: null,
    expiry_date: null,
    guaranteed_replacement_costs: null,
    interest_noted: null,
    fire_insurance_company_id: null,
    fire_insurance_brokerage_id: null,
    fire_insurance_broker_id: null
}