import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { LedgerContextRequest } from "../documents/ledgerDocument";


function createLedgerName(): string {
    return "Ledger";
}

function isLedgerDocInDeal(docList: Document[]): boolean {
    const matchingDoc = docList.find((doc) => doc.type === DocumentType.Ledger);
    return matchingDoc ? true : false
}

function createLedgerContextRequest(): LedgerContextRequest {
    return {};
}

function getLedgerDocData(): [Object, string] {
    return [createLedgerContextRequest(), createLedgerName()];
}

export {
    isLedgerDocInDeal,
    getLedgerDocData
}