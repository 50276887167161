import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { StatDecContext, StatDecDocument, StatDecTemplate } from "../documents/statDecDocument";
import { BaseDocumentResponse } from "../baseDocumentResponse";
import { BaseDocument, Document } from "../baseDocument";
import { sanitizeResponse } from "../../utils/convertResponse";
import { defaultBaseDocument } from "../../../../resources/defaults/documents/defaultBaseDocument";
import { defaultStatDecContext, defaultStatDecContextRequest } from "../../../../resources/defaults/documents/contexts/defaultStatDecContext";
import { BaseTemplateResponse } from "../templates/baseTemplateResponse";
import { BaseTemplate, Template } from "../templates/baseTemplate";
import { defaultBaseTemplate } from "../../../../resources/defaults/documents/defaultBaseTemplate";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { DocContextType } from "../../../../resources/enums/documents/docContextType";
import { State } from "../../../../../context/UniversalSurvey/context";
import { getStatDecContextOptions, getStatDecDocData, labelStatDec } from "../context/statDecContext";
import { labelMortgage, labelTitleTransfer } from "../../../../utils/labeling/transactions";
import { getGuarantorWaiverOfIlaContextOptions, getGuarantorWaiverOfIlaDocData } from "../context/guarantorWaiverOfIlaContext";
import { getGuarantorGuaranteeOfMortgageContextOptions, getGuarantorGuaranteeOfMortgageDocData } from "../context/guarantorGuaranteeOfMortgageContext";
import { GuarantorWaiverOfIlaContext, GuarantorWaiverOfIlaDocument, GuarantorWaiverOfIlaTemplate } from "../documents/guarantorWaiverOfIlaDocument";
import { defaultGuarantorWaiverOfIlaContext, defaultGuarantorWaiverOfIlaContextRequest } from "../../../../resources/defaults/documents/contexts/defaultGuarantorWaiverOfIlaContext";
import { GuarantorGuaranteeOfMortgageContext, GuarantorGuaranteeOfMortgageDocument, GuarantorGuaranteeOfMortgageTemplate } from "../documents/guarantorGuaranteeOfMortgageDocument";
import { defaultGuarantorGuaranteeOfMortgageContext, defaultGuarantorGuaranteeOfMortgageContextRequest } from "../../../../resources/defaults/documents/contexts/defaultGuarantorGuaranteeOfMortgageContext";
import { getConsentToActConflictOfMortgageContextOptions, getConsentToActConflictOfMortgageDocData } from "../context/consentToActConflictOfMortgageContext";
import { ConsentToActConflictOfMortgageContext, ConsentToActConflictOfMortgageDocument, ConsentToActConflictOfMortgageTemplate } from "../documents/consentToActConflictOfMortgageDocument";
import { defaultConsentToActConflictOfMortgageContext, defaultConsentToActConflictOfMortgageContextRequest } from "../../../../resources/defaults/documents/contexts/defaultConsentToActConflictOfMortgageContext";
import { Form9CContext, Form9CDocument, Form9CTemplate } from "../documents/form9cDocument";
import { defaultForm9CContext, defaultForm9CContextRequest } from "../../../../resources/defaults/documents/contexts/defaultForm9cContext";
import { getForm9CContextOptions, getForm9CDocData, labelForm9CContextOption } from "../context/form9cContext";
import { LetterToMortgageeNewMortgageContext, LetterToMortgageeNewMortgageDocument, LetterToMortgageeNewMortgageTemplate } from "../documents/letterToMortgageeNewMortgageDocument";
import { defaultLetterToMortgageeNewMortgageContext, defaultLetterToMortgageeNewMortgageContextRequest } from "../../../../resources/defaults/documents/contexts/defaultLetterToMortgageeNewMortgageContext";
import { getLetterToMortgageeNewMortgageContextOptions, getLetterToMortgageeNewMortgageDocData } from "../context/letterToMortgageeNewMortgageContext";
import { LetterToMortgageeNewMortgageContentType } from "../../../../resources/enums/documents/letterToMortgageeNewMortgage";
import { getLetterToMortgageeExistingMortgageContextOptions, getLetterToMortgageeExistingMortgageDocData, labelLetterToMortgageeExistingMortgageContextOption } from "../context/letterToMortgageeExistingMortgageContext";
import { LetterToMortgageeExistingMortgageContext, LetterToMortgageeExistingMortgageDocument, LetterToMortgageeExistingMortgageTemplate } from "../documents/letterToMortgageeExistingMortgageDocument";
import { defaultLetterToMortgageeExistingMortgageContext, defaultLetterToMortgageeExistingMortgageContextRequest } from "../../../../resources/defaults/documents/contexts/defaultLetterToMortgageeExistingMortgageContext";
import { getLedgerDocData, isLedgerDocInDeal } from "../context/ledgerContext";
import { defaultLedgerContext, defaultLedgerContextRequest } from "../../../../resources/defaults/documents/contexts/defaultLedgerContext";
import { LedgerContext, LedgerDocument, LedgerTemplate } from "../documents/ledgerDocument";
import { defaultStatementOfAccountContext, defaultStatementOfAccountContextRequest } from "../../../../resources/defaults/documents/contexts/defaultStatementOfAccountContext";
import { StatementOfAccountContext, StatementOfAccountDocument, StatementOfAccountTemplate } from "../documents/statementOfAccountDocument";
import { getStatementOfAccountDocData, isStatementOfAccountDocInDeal } from "../context/statementOfAccountContext";
import { getLetterToClientContextOptions, getLetterToClientDocData, labelLetterToClientContextOption } from "../context/letterToClientContext";
import { LetterToClientContext, LetterToClientDocument, LetterToClientTemplate } from "../documents/letterToClientDocument";
import { defaultLetterToClientContext, defaultLetterToClientContextRequest } from "../../../../resources/defaults/documents/contexts/defaultLetterToClientContext";
import { getUndertakingContextOptions, getUndertakingDocData, labelUndertakingDocContextOption } from "../context/undertakingContext";
import { UndertakingContext, UndertakingDocument, UndertakingTemplate } from "../documents/undertakingDocument";
import { defaultUndertakingContext, defaultUndertakingContextRequest } from "../../../../resources/defaults/documents/contexts/defaultUndertakingContext";
import { defaultVerificationOfIdentityAgreementContext, defaultVerificationOfIdentityAgreementContextRequest } from "../../../../resources/defaults/documents/contexts/defaultVerificationOfIdentityAgreementContext";
import { VerificationOfIdentityAgreementContext, VerificationOfIdentityAgreementDocument, VerificationOfIdentityAgreementTemplate } from "../documents/verificationOfIdentityAgreementDocument";
import { getVerificationOfIdentityAgreementContextOptions, getVerificationOfIdentityAgreementDocData, labelVerificationOfIdentityAgreementDocContextOption } from "../context/verificationOfIdentityAgreementContext";
import { defaultGeneralAssignmentOfRentsContext, defaultGeneralAssignmentOfRentsContextRequest } from "../../../../resources/defaults/documents/contexts/defaultGeneralAssignementOfRentsContext";
import { GeneralAssignmentOfRentsContext, GeneralAssignmentOfRentsDocument, GeneralAssignmentOfRentsTemplate } from "../documents/generalAssignmentOfRentsDocument";
import { getGeneralAssignmentOfRentsDocData, isGeneralAssignmentOfRentsDocInDeal } from "../context/generalAssignmentOfRentsContext";
import { MortgagePackageContext, MortgagePackageDocument, MortgagePackageTemplate } from "../documents/mortgagePackage";
import { defaultMortgagePackageContext, defaultMortgagePackageContextRequest } from "../../../../resources/defaults/documents/contexts/defaultMortgagePackageContext";
import { getMortgagePackageContextOptions, getMortgagePackageDocData } from "../context/mortgagePackageContext";
import { GeneralSecurityAgreementContext, GeneralSecurityAgreementDocument, GeneralSecurityAgreementTemplate } from "../documents/generalSecurityAgreementDocument";
import { defaultGeneralSecurityAgreementContext, defaultGeneralSecurityAgreementContextRequest } from "../../../../resources/defaults/documents/contexts/defaultGeneralSecurityAgreementContext";
import { getGeneralSecurityAgreementContextOptions, getGeneralSecurityAgreementDocData } from "../context/generalSecurityAgreementContext";
import { DirectionReTitleContext, DirectionReTitleDocument, DirectionReTitleTemplate } from "../documents/directionReTitleDocument";
import { defaultDirectionReTitleContext, defaultDirectionReTitleContextRequest } from "../../../../resources/defaults/documents/contexts/defaultDirectionReTitleContext";
import { getDirectionReTitleContextOptions, getDirectionReTitleDocData } from "../context/directionReTitleContext";
import { DraThreeOrMoreContext, DraThreeOrMoreDocument, DraThreeOrMoreTemplate } from "../documents/draThreeOrMoreDocument";
import { defaultDraThreeOrMoreContext, defaultDraThreeOrMoreContextRequest } from "../../../../resources/defaults/documents/contexts/defaultDraThreeOrMoreContext";
import { DraTwoPartiesContext, DraTwoPartiesDocument, DraTwoPartiesTemplate } from "../documents/draTwoPartiesDocument";
import { defaultDraTwoPartiesContext, defaultDraTwoPartiesContextRequest } from "../../../../resources/defaults/documents/contexts/defaultDraTwoPartiesContext";
import { getDraThreeOrMoreDocData, isDraThreeOrMoreDocInDeal } from "../context/draThreeOrMore";
import { getDraTwoPartiesDocData, isDraTwoPartiesDocInDeal } from "../context/draTwoPartiesContext";

function mapDocument(document: BaseDocumentResponse): Document {
    let cleanBaseDocument: BaseDocument = sanitizeResponse(document, defaultBaseDocument);
    if (cleanBaseDocument.category === DocumentCategory.Folder) cleanBaseDocument.open = true;
    switch (cleanBaseDocument.type) {
        case DocumentType.StatDec:
            let cleanStatDecContext: StatDecContext = sanitizeResponse(document.context, defaultStatDecContext);
            let returnStatDecDoc: StatDecDocument = { ...cleanBaseDocument, context: cleanStatDecContext, type: DocumentType.StatDec }
            return returnStatDecDoc;
        case DocumentType.GuarantorWaiverOfIla:
            let cleanGuarantorWaiverOfIlaContext: GuarantorWaiverOfIlaContext = sanitizeResponse(document.context, defaultGuarantorWaiverOfIlaContext);
            let returnGuarantorWaiverOfIlaDoc: GuarantorWaiverOfIlaDocument = { ...cleanBaseDocument, context: cleanGuarantorWaiverOfIlaContext, type: DocumentType.GuarantorWaiverOfIla };
            return returnGuarantorWaiverOfIlaDoc;
        case DocumentType.GuarantorGuaranteeOfMortgage:
            let cleanGuarantorGuaranteeOfMortgageContext: GuarantorGuaranteeOfMortgageContext = sanitizeResponse(document.context, defaultGuarantorGuaranteeOfMortgageContext);
            let returnGuarantorGuaranteeOfMortgageDoc: GuarantorGuaranteeOfMortgageDocument = { ...cleanBaseDocument, context: cleanGuarantorGuaranteeOfMortgageContext, type: DocumentType.GuarantorGuaranteeOfMortgage };
            return returnGuarantorGuaranteeOfMortgageDoc;
        case DocumentType.ConsentToActConflictOfMortgage:
            let cleanConsentToActConflictOfMortgageContext: ConsentToActConflictOfMortgageContext = sanitizeResponse(document.context, defaultConsentToActConflictOfMortgageContext);
            let returnConsentToActConflictOfMortgageDoc: ConsentToActConflictOfMortgageDocument = { ...cleanBaseDocument, context: cleanConsentToActConflictOfMortgageContext, type: DocumentType.ConsentToActConflictOfMortgage };
            return returnConsentToActConflictOfMortgageDoc;
        case DocumentType.Form9C:
            let cleanForm9CContext: Form9CContext = sanitizeResponse(document.context, defaultForm9CContext);
            let returnForm9CDoc: Form9CDocument = { ...cleanBaseDocument, context: cleanForm9CContext, type: DocumentType.Form9C };
            return returnForm9CDoc;
        case DocumentType.LetterToMortgageeNewMortgage:
            let cleanLetterToMortgageeNewMortgageContext: LetterToMortgageeNewMortgageContext = sanitizeResponse(document.context, defaultLetterToMortgageeNewMortgageContext);
            let returnLetterToMortgageeNewMortgageDoc: LetterToMortgageeNewMortgageDocument = { ...cleanBaseDocument, context: cleanLetterToMortgageeNewMortgageContext, type: DocumentType.LetterToMortgageeNewMortgage };
            return returnLetterToMortgageeNewMortgageDoc;
        case DocumentType.LetterToMortgageeExistingMortgage:
            let cleanLetterToMortgageeExistingMortgageContext: LetterToMortgageeExistingMortgageContext = sanitizeResponse(document.context, defaultLetterToMortgageeExistingMortgageContext);
            let returnLetterToMortgageeExistingMortgageDoc: LetterToMortgageeExistingMortgageDocument = { ...cleanBaseDocument, context: cleanLetterToMortgageeExistingMortgageContext, type: DocumentType.LetterToMortgageeExistingMortgage };
            return returnLetterToMortgageeExistingMortgageDoc;
        case DocumentType.Ledger:
            let cleanLedgerContext: LedgerContext = sanitizeResponse(document.context, defaultLedgerContext);
            let returnLedgerDoc: LedgerDocument = { ...cleanBaseDocument, context: cleanLedgerContext, type: DocumentType.Ledger };
            return returnLedgerDoc;
        case DocumentType.StatementOfAccount:
            let cleanStatementOfAccountContext: StatementOfAccountContext = sanitizeResponse(document.context, defaultStatementOfAccountContext);
            let returnStatementOfAccountDoc: StatementOfAccountDocument = { ...cleanBaseDocument, context: cleanStatementOfAccountContext, type: DocumentType.StatementOfAccount };
            return returnStatementOfAccountDoc;
        case DocumentType.LetterToClient:
            let cleanLetterToClientContext: LetterToClientContext = sanitizeResponse(document.context, defaultLetterToClientContext);
            let returnLetterToClientDoc: LetterToClientDocument = { ...cleanBaseDocument, context: cleanLetterToClientContext, type: DocumentType.LetterToClient };
            return returnLetterToClientDoc;
        case DocumentType.Undertaking:
            let cleanUndertakingContext: UndertakingContext = sanitizeResponse(document.context, defaultUndertakingContext);
            let returnUndertakingDoc: UndertakingDocument = { ...cleanBaseDocument, context: cleanUndertakingContext, type: DocumentType.Undertaking };
            return returnUndertakingDoc;
        case DocumentType.VerificationOfIdentityAgreement:
            let cleanVerificationOfIdentityAgreementContext: VerificationOfIdentityAgreementContext = sanitizeResponse(document.context, defaultVerificationOfIdentityAgreementContext);
            let returnVerificationOfIdentityAgreementDoc: VerificationOfIdentityAgreementDocument = { ...cleanBaseDocument, context: cleanVerificationOfIdentityAgreementContext, type: DocumentType.VerificationOfIdentityAgreement };
            return returnVerificationOfIdentityAgreementDoc;
        case DocumentType.Ledger:
            let cleanGeneralAssignmentOfRentsContext: GeneralAssignmentOfRentsContext = sanitizeResponse(document.context, defaultGeneralAssignmentOfRentsContext);
            let returnGeneralAssignmentOfRentsDoc: GeneralAssignmentOfRentsDocument = { ...cleanBaseDocument, context: cleanGeneralAssignmentOfRentsContext, type: DocumentType.GeneralAssignmentOfRents };
            return returnGeneralAssignmentOfRentsDoc;
        case DocumentType.MortgagePackage:
            let cleanMortgagePackageContext: MortgagePackageContext = sanitizeResponse(document.context, defaultMortgagePackageContext);
            let returnMortgagePackageDoc: MortgagePackageDocument = { ...cleanBaseDocument, context: cleanMortgagePackageContext, type: DocumentType.MortgagePackage };
            return returnMortgagePackageDoc;
        case DocumentType.GeneralSecurityAgreement:
            let cleanGeneralSecurityAgreementContext: GeneralSecurityAgreementContext = sanitizeResponse(document.context, defaultGeneralSecurityAgreementContext);
            let returnGeneralSecurityAgreementDoc: GeneralSecurityAgreementDocument = { ...cleanBaseDocument, context: cleanGeneralSecurityAgreementContext, type: DocumentType.GeneralSecurityAgreement };
            return returnGeneralSecurityAgreementDoc;
        case DocumentType.DirectionReTitle:
            let cleanDirectionReTitleContext: DirectionReTitleContext = sanitizeResponse(document.context, defaultDirectionReTitleContext);
            let returnDirectionReTitleDoc: DirectionReTitleDocument = { ...cleanBaseDocument, context: cleanDirectionReTitleContext, type: DocumentType.DirectionReTitle };
            return returnDirectionReTitleDoc;
        case DocumentType.DraThreeOrMore:
            let cleanDraThreeOrMoreContext: DraThreeOrMoreContext = sanitizeResponse(document.context, defaultDraThreeOrMoreContext);
            let returnDraThreeOrMoreDoc: DraThreeOrMoreDocument = { ...cleanBaseDocument, context: cleanDraThreeOrMoreContext, type: DocumentType.DraThreeOrMore };
            return returnDraThreeOrMoreDoc;
        case DocumentType.DraTwoParties:
            let cleanDraTwoPartiesContext: DraTwoPartiesContext = sanitizeResponse(document.context, defaultDraTwoPartiesContext);
            let returnDraTwoPartiesDoc: DraTwoPartiesDocument = { ...cleanBaseDocument, context: cleanDraTwoPartiesContext, type: DocumentType.DraTwoParties };
            return returnDraTwoPartiesDoc;
        default: 
            return cleanBaseDocument;
    }
}

function mapDocuments(documentList: BaseDocumentResponse[]): Document[] {
    let cleanList: Document[] = [];
    for (const document of documentList) {
        cleanList.push(mapDocument(document));
    }
    return cleanList;
}

function mapTemplate(template: BaseTemplateResponse): Template {
    let cleanBaseTemplate: BaseTemplate = sanitizeResponse(template, defaultBaseTemplate);
    switch (cleanBaseTemplate.type) {
        case DocumentType.StatDec:
            let cleanStatDecContext: StatDecContext = sanitizeResponse(template.context, defaultStatDecContext);
            let returnStatDecTemplate: StatDecTemplate = { ...cleanBaseTemplate, context: cleanStatDecContext, type: DocumentType.StatDec };
            return returnStatDecTemplate;
        case DocumentType.GuarantorWaiverOfIla:
            let cleanGuarantorWaiverOfIlaContext: GuarantorWaiverOfIlaContext = sanitizeResponse(template.context, defaultGuarantorWaiverOfIlaContext);
            let returnGuarantorWaiverOfIlaTemplate: GuarantorWaiverOfIlaTemplate = { ...cleanBaseTemplate, context: cleanGuarantorWaiverOfIlaContext, type: DocumentType.GuarantorWaiverOfIla };
            return returnGuarantorWaiverOfIlaTemplate;
        case DocumentType.GuarantorGuaranteeOfMortgage:
            let cleanGuarantorGuaranteeOfMortgageContext: GuarantorGuaranteeOfMortgageContext = sanitizeResponse(template.context, defaultGuarantorGuaranteeOfMortgageContext);
            let returnGuarantorGuaranteeOfMortgageTemplate: GuarantorGuaranteeOfMortgageTemplate = { ...cleanBaseTemplate, context: cleanGuarantorGuaranteeOfMortgageContext, type: DocumentType.GuarantorGuaranteeOfMortgage };
            return returnGuarantorGuaranteeOfMortgageTemplate;
        case DocumentType.ConsentToActConflictOfMortgage:
            let cleanConsentToActConflictOfMortgageContext: ConsentToActConflictOfMortgageContext = sanitizeResponse(template.context, defaultConsentToActConflictOfMortgageContext);
            let returnConsentToActConflictOfMortgageTemplate: ConsentToActConflictOfMortgageTemplate = { ...cleanBaseTemplate, context: cleanConsentToActConflictOfMortgageContext, type: DocumentType.ConsentToActConflictOfMortgage };
            return returnConsentToActConflictOfMortgageTemplate;
        case DocumentType.Form9C:
            let cleanForm9CContext: Form9CContext = sanitizeResponse(template.context, defaultForm9CContext);
            let returnForm9CTemplate: Form9CTemplate = { ...cleanBaseTemplate, context: cleanForm9CContext, type: DocumentType.Form9C };
            return returnForm9CTemplate;
        case DocumentType.LetterToMortgageeNewMortgage:
            let cleanLetterToMortgageeNewMortgageContext: LetterToMortgageeNewMortgageContext = sanitizeResponse(template.context, defaultLetterToMortgageeNewMortgageContext);
            let returnLetterToMortgageeNewMortgageTemplate: LetterToMortgageeNewMortgageTemplate = { ...cleanBaseTemplate, context: cleanLetterToMortgageeNewMortgageContext, type: DocumentType.LetterToMortgageeNewMortgage };
            return returnLetterToMortgageeNewMortgageTemplate;
        case DocumentType.LetterToMortgageeExistingMortgage:
            let cleanLetterToMortgageeExistingMortgageContext: LetterToMortgageeExistingMortgageContext = sanitizeResponse(template.context, defaultLetterToMortgageeExistingMortgageContext);
            let returnLetterToMortgageeExistingMortgageTemplate: LetterToMortgageeExistingMortgageTemplate = { ...cleanBaseTemplate, context: cleanLetterToMortgageeExistingMortgageContext, type: DocumentType.LetterToMortgageeExistingMortgage };
            return returnLetterToMortgageeExistingMortgageTemplate;
        case DocumentType.Ledger:
            let cleanLedgerContext: LedgerContext = sanitizeResponse(template.context, defaultLedgerContext);
            let returnLedgerTemplate: LedgerTemplate = { ...cleanBaseTemplate, context: cleanLedgerContext, type: DocumentType.Ledger };
            return returnLedgerTemplate;
        case DocumentType.StatementOfAccount:
            let cleanStatementOfAccountContext: StatementOfAccountContext = sanitizeResponse(template.context, defaultStatementOfAccountContext);
            let returnStatementOfAccountTemplate: StatementOfAccountTemplate = { ...cleanBaseTemplate, context: cleanStatementOfAccountContext, type: DocumentType.StatementOfAccount };
            return returnStatementOfAccountTemplate;
        case DocumentType.LetterToClient:
            let cleanLetterToClientContext: LetterToClientContext = sanitizeResponse(template.context, defaultLetterToClientContext);
            let returnLetterToClientTemplate: LetterToClientTemplate = { ...cleanBaseTemplate, context: cleanLetterToClientContext, type: DocumentType.LetterToClient };
            return returnLetterToClientTemplate;
        case DocumentType.Undertaking:
            let cleanUndertakingContext: UndertakingContext = sanitizeResponse(template.context, defaultUndertakingContext);
            let returnUndertakingTemplate: UndertakingTemplate = { ...cleanBaseTemplate, context: cleanUndertakingContext, type: DocumentType.Undertaking };
            return returnUndertakingTemplate;
        case DocumentType.VerificationOfIdentityAgreement:
            let cleanVerificationOfIdentityAgreementContext: VerificationOfIdentityAgreementContext = sanitizeResponse(template.context, defaultVerificationOfIdentityAgreementContext);
            let returnVerificationOfIdentityAgreementTemplate: VerificationOfIdentityAgreementTemplate = { ...cleanBaseTemplate, context: cleanVerificationOfIdentityAgreementContext, type: DocumentType.VerificationOfIdentityAgreement };
            return returnVerificationOfIdentityAgreementTemplate;
        case DocumentType.GeneralAssignmentOfRents:
            let cleanGeneralAssignmentOfRentsContext: GeneralAssignmentOfRentsContext = sanitizeResponse(template.context, defaultGeneralAssignmentOfRentsContext);
            let returnGeneralAssignmentOfRentsTemplate: GeneralAssignmentOfRentsTemplate = { ...cleanBaseTemplate, context: cleanGeneralAssignmentOfRentsContext, type: DocumentType.GeneralAssignmentOfRents };
            return returnGeneralAssignmentOfRentsTemplate;
        case DocumentType.MortgagePackage:
            let cleanMortgagePackageContext: MortgagePackageContext = sanitizeResponse(template.context, defaultMortgagePackageContext);
            let returnMortgagePackageTemplate: MortgagePackageTemplate = { ...cleanBaseTemplate, context: cleanMortgagePackageContext, type: DocumentType.MortgagePackage };
            return returnMortgagePackageTemplate;
        case DocumentType.GeneralSecurityAgreement:
            let cleanGeneralSecurityAgreementContext: GeneralSecurityAgreementContext = sanitizeResponse(template.context, defaultGeneralSecurityAgreementContext);
            let returnGeneralSecurityAgreementTemplate: GeneralSecurityAgreementTemplate = { ...cleanBaseTemplate, context: cleanGeneralSecurityAgreementContext, type: DocumentType.GeneralSecurityAgreement };
            return returnGeneralSecurityAgreementTemplate;
        case DocumentType.DirectionReTitle:
            let cleanDirectionReTitleContext: DirectionReTitleContext = sanitizeResponse(template.context, defaultDirectionReTitleContext);
            let returnDirectionReTitleTemplate: DirectionReTitleTemplate = { ...cleanBaseTemplate, context: cleanDirectionReTitleContext, type: DocumentType.DirectionReTitle };
            return returnDirectionReTitleTemplate;
        case DocumentType.DraThreeOrMore:
            let cleanDraThreeOrMoreContext: DraThreeOrMoreContext = sanitizeResponse(template.context, defaultDraThreeOrMoreContext);
            let returnDraThreeOrMoreTemplate: DraThreeOrMoreTemplate = { ...cleanBaseTemplate, context: cleanDraThreeOrMoreContext, type: DocumentType.DraThreeOrMore };
            return returnDraThreeOrMoreTemplate;
        case DocumentType.DraThreeOrMore:
            let cleanDraTwoPartiesContext: DraTwoPartiesContext = sanitizeResponse(template.context, defaultDraTwoPartiesContext);
            let returnDraTwoPartiesTemplate: DraTwoPartiesTemplate = { ...cleanBaseTemplate, context: cleanDraTwoPartiesContext, type: DocumentType.DraTwoParties };
            return returnDraTwoPartiesTemplate;
        default:
            return cleanBaseTemplate;
    }
}

function mapContextData(documentType: DocumentType, contextId: number, state: State): [Object | undefined, string] {
    switch(documentType) {
        case DocumentType.StatDec:
            return getStatDecDocData(state.statutoryDeclarations.statDecsInDeal, contextId);
        case DocumentType.GuarantorWaiverOfIla:
            return getGuarantorWaiverOfIlaDocData(state.transactions.transactionList, contextId);
        case DocumentType.GuarantorGuaranteeOfMortgage:
            return getGuarantorGuaranteeOfMortgageDocData(state.transactions.transactionList, contextId);
        case DocumentType.ConsentToActConflictOfMortgage:
            return getConsentToActConflictOfMortgageDocData(state.transactions.transactionList, contextId);
        case DocumentType.Form9C:
            return getForm9CDocData(state.paymentGroups.paymentGroups, contextId);
        case DocumentType.LetterToMortgageeNewMortgage:
            // TODO: update this when we add types other than 'blank'
            return getLetterToMortgageeNewMortgageDocData(state.transactions.transactionList, contextId, LetterToMortgageeNewMortgageContentType.Blank);
        case DocumentType.LetterToMortgageeExistingMortgage:
            return getLetterToMortgageeExistingMortgageDocData(state.existingLiens.liensInDeal, contextId);
        case DocumentType.LetterToClient:
            return getLetterToClientDocData(contextId);
        case DocumentType.Undertaking:
            return getUndertakingDocData(contextId, state.transactions.transactionList);
        case DocumentType.VerificationOfIdentityAgreement:
            return getVerificationOfIdentityAgreementDocData(state.signingAppointments.signingApptsInDeal, contextId);
        case DocumentType.MortgagePackage:
            return getMortgagePackageDocData(state.transactions.transactionList, contextId);
        case DocumentType.GeneralSecurityAgreement:
            return getGeneralSecurityAgreementDocData(state.transactions.transactionList, contextId);
        case DocumentType.DirectionReTitle:
            return getDirectionReTitleDocData(state.transactions.transactionList, contextId);
        default:
            return [undefined, "Doc name"];
    }
}

function mapContextlessData(documentType: DocumentType): [Object | undefined, string] {
    switch(documentType) {
        case DocumentType.Ledger:
            return getLedgerDocData();
        case DocumentType.StatementOfAccount:
            return getStatementOfAccountDocData();
        case DocumentType.GeneralAssignmentOfRents:
            return getGeneralAssignmentOfRentsDocData();
        case DocumentType.DraThreeOrMore:
            return getDraThreeOrMoreDocData();
        case DocumentType.DraTwoParties:
            return getDraTwoPartiesDocData();
        default:
            return [undefined, "Doc name"];
    }
}

function mapContextlessDocDisabled(documentType: DocumentType, state: State): boolean {
    switch(documentType) {
        case DocumentType.Ledger:
            return isLedgerDocInDeal(state.documents.documentList);
        case DocumentType.StatementOfAccount:
            return isStatementOfAccountDocInDeal(state.documents.documentList);
        case DocumentType.GeneralAssignmentOfRents:
            return isGeneralAssignmentOfRentsDocInDeal(state.documents.documentList);
        case DocumentType.DraThreeOrMore:
            return isDraThreeOrMoreDocInDeal(state.documents.documentList);
        case DocumentType.DraTwoParties:
            return isDraTwoPartiesDocInDeal(state.documents.documentList);
        default:
            return false;
    }
}

function getContextOptions(contextType: DocContextType, state: State): number[] {
    switch (contextType) {
        case DocContextType.StatDec:
            return getStatDecContextOptions(state.statutoryDeclarations.statDecsInDeal, state.documents.documentList);
        case DocContextType.GuarantorWaiverOfIla:
            return getGuarantorWaiverOfIlaContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.GuarantorGuaranteeOfMortgage:
            return getGuarantorGuaranteeOfMortgageContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.ConsentToActConflictOfMortgage:
            return getConsentToActConflictOfMortgageContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.Form9C:
            return getForm9CContextOptions(state.paymentGroups.paymentGroups, state.documents.documentList);
        case DocContextType.LetterToMortgageeNewMortgage:
            return getLetterToMortgageeNewMortgageContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.LetterToMortgageeExistingMortgage:
            return getLetterToMortgageeExistingMortgageContextOptions(state.existingLiens.liensInDeal, state.documents.documentList);
        case DocContextType.LetterToClient:
            return getLetterToClientContextOptions(state.documents.documentList);
        case DocContextType.Undertaking:
            return getUndertakingContextOptions(state.documents.documentList, state.transactions.transactionList);
        case DocContextType.VerificationOfIdentityAgreement:
            return getVerificationOfIdentityAgreementContextOptions(state.signingAppointments.signingApptsInDeal, state.documents.documentList);
        case DocContextType.MortgagePackage:
            return getMortgagePackageContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.GeneralSecurityAgreement:
            return getGeneralSecurityAgreementContextOptions(state.transactions.transactionList, state.documents.documentList);
        case DocContextType.DirectionReTitle:
            return getDirectionReTitleContextOptions(state.transactions.transactionList, state.documents.documentList);
        default:
            return [];
    }
}

function labelDocContextOption(contextType: DocContextType, contextId: number, state: State): string {
    switch (contextType) {
        case DocContextType.StatDec:
            return labelStatDec(state.statutoryDeclarations.statDecsInDeal, contextId);
        case DocContextType.GuarantorWaiverOfIla:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.GuarantorGuaranteeOfMortgage:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.ConsentToActConflictOfMortgage:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.Form9C:
            return labelForm9CContextOption(state.paymentGroups.paymentGroups, contextId);
        case DocContextType.LetterToMortgageeNewMortgage:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.LetterToMortgageeExistingMortgage:
            return labelLetterToMortgageeExistingMortgageContextOption(state.existingLiens.liensInDeal, contextId);
        case DocContextType.LetterToClient:
            return labelLetterToClientContextOption(contextId);
        case DocContextType.Undertaking:
            return labelUndertakingDocContextOption(state.transactions.transactionList, contextId);
        case DocContextType.VerificationOfIdentityAgreement:
            return labelVerificationOfIdentityAgreementDocContextOption(state.signingAppointments.signingApptsInDeal, contextId);
        case DocContextType.MortgagePackage:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.GeneralSecurityAgreement:
            return labelMortgage(state.transactions.transactionList, contextId);
        case DocContextType.DirectionReTitle:
            return labelTitleTransfer(state.transactions.transactionList, contextId);
        default:
            return "Context";
    }
}

function mapDocumentDefaultContextRequest(docType: DocumentType | undefined) {
    switch(docType) {
        case DocumentType.StatDec:
            return defaultStatDecContextRequest;
        case DocumentType.GuarantorWaiverOfIla:
            return defaultGuarantorWaiverOfIlaContextRequest;
        case DocumentType.GuarantorGuaranteeOfMortgage:
            return defaultGuarantorGuaranteeOfMortgageContextRequest;
        case DocumentType.ConsentToActConflictOfMortgage:
            return defaultConsentToActConflictOfMortgageContextRequest;
        case DocumentType.Form9C:
            return defaultForm9CContextRequest;
        case DocumentType.LetterToMortgageeNewMortgage:
            return defaultLetterToMortgageeNewMortgageContextRequest;
        case DocumentType.LetterToMortgageeExistingMortgage:
            return defaultLetterToMortgageeExistingMortgageContextRequest;
        case DocumentType.Ledger:
            return defaultLedgerContextRequest;
        case DocumentType.StatementOfAccount:
            return defaultStatementOfAccountContextRequest;
        case DocumentType.LetterToClient:
            return defaultLetterToClientContextRequest;
        case DocumentType.Undertaking:
            return defaultUndertakingContextRequest;
        case DocumentType.VerificationOfIdentityAgreement:
            return defaultVerificationOfIdentityAgreementContextRequest;
        case DocumentType.GeneralAssignmentOfRents:
            return defaultGeneralAssignmentOfRentsContextRequest;
        case DocumentType.MortgagePackage:
            return defaultMortgagePackageContextRequest;
        case DocumentType.GeneralSecurityAgreement:
            return defaultGeneralSecurityAgreementContextRequest;
        case DocumentType.DirectionReTitle:
            return defaultDirectionReTitleContextRequest;
        case DocumentType.DraThreeOrMore:
            return defaultDraThreeOrMoreContextRequest;
        case DocumentType.DraTwoParties:
            return defaultDraTwoPartiesContextRequest;
        default:
            return {}
    }
}

export {
    mapDocuments,
    mapTemplate,
    mapContextData,
    mapContextlessData,
    mapContextlessDocDisabled,
    getContextOptions,
    labelDocContextOption,
    mapDocumentDefaultContextRequest
}