import {Box, BoxProps, styled, SxProps, Theme} from "@mui/material";
import {Oval} from "react-loader-spinner";
import constants from "../../styles/constants";

const DeededCircularLoader: React.FC<
  {sx?: SxProps<Theme>} & Omit<BoxProps, "variant">
> = ({sx, ...props}) => (
  <DeededLoader data-testid="circular-loader" {...props} sx={sx}>
    <Oval
      ariaLabel="loading-indicator"
      height={100}
      width={100}
      strokeWidth={5}
      color={constants.colors.red}
      secondaryColor={constants.colors.white}
    />
  </DeededLoader>
);

const DeededLoader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default DeededCircularLoader;
