import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import { Affidavit } from "../../../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { SimpleClient } from "../../../../../libs/types/UniversalSurvey/Client/simpleClient";
import { getClientTabName, getDealClients } from "../../../../../context/UniversalSurvey/asyncActions/clients";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import BooleanControl from "../../../../../../components/Common/BooleanControl/BooleanControl";
import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import { ClientType } from "../../../../../libs/resources/enums/clientType";

type Props = {
    onChange: Function;
    obj: Affidavit;
    selectedClient: SimpleClient | undefined;
    setSelectedClient: Function;
};

export default function AffidavitForm(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const { obj, onChange, setSelectedClient, selectedClient } = props;

    useEffect(() => {
        if(state.conditions.conditionAffidavit) {
            const affidavit = state.conditions.conditionAffidavit;
            const clientRecord = affidavit.client_record;
            if (clientRecord) {
                const client = { ...clientRecord, label: getClientTabName(clientRecord) };
                setSelectedClient(client);
            }
        }
    }, [state.conditions.conditionAffidavit])

    useEffect(() => {
        getDealClients(dispatch, String(state.deal.dealInfo?.id));
    }, [])

    function updateSelectedClient(client: SimpleClient | null) {
        if (client) {
            onChange("client_id", client.id);
            setSelectedClient(client);
        } else {
            onChange("client_id", null);
            setSelectedClient(undefined);
        }
    }

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            if (client.type === ClientType.Corporation) continue;
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        return options;
    }

    return (
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={8} >
                    <ComboBox
                        label={{ text: "Declarant", inputId: "affidavit_modal_client_record", isRequired: true }}
                        options={getClientComboboxItems()}
                        placeholder="Select declarant..."
                        value={selectedClient ?? { label: "" }}
                        isHoverActionHidden={selectedClient ? false : true}
                        handleClear={() => updateSelectedClient(null)}
                        isOptionEqualFn={(option, value) => option.id === value.id}
                        // @ts-ignore
                        onChangeFn={(value) => updateSelectedClient(value as SimpleClient)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BooleanControl
                        checked={obj.lawyer_declaration}
                        onChange={(e) => onChange("lawyer_declaration", !obj.lawyer_declaration)}
                        label={{ text: "Lawyer Declaration", inputId: `lawyer_declaration`, isRequired: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Debtor(s) (comma separated)", inputId: "affidavit_modal_debtors", isRequired: true }}
                        placeholder="Debtor(s)'s name(s)"
                        value={obj?.debtors ?? undefined}
                        onChange={(e) => onChange("debtors", e.target.value)}
                        tooltipProps={{
                            message: 'Names should be separated by a comma followed by a space. Do not add "and" or "&". E.g. "Party One, Party Two, Party Three"',
                            placement: "right-start"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Execution No.", inputId: "affidavit_execution_num", isRequired: true }}
                        placeholder="23-00000000"
                        value={obj?.execution_num ?? undefined}
                        onChange={(e) => onChange("execution_num", e.target.value)}
                    /> 
                </Grid>
                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Amount of Judgement", inputId: "affidavit_amount", isRequired: true }}
                        placeholder="0.00"
                        moneyField
                        valueType="positive"
                        value={obj?.amount !== undefined ? String(obj.amount) : undefined}
                        // @ts-ignore
                        onChange={(e) => onChange("amount", e.target.value)}
                    /> 
                </Grid>
                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Costs", inputId: "affidavit_cost", isRequired: true }}
                        placeholder="0.00"
                        moneyField
                        valueType="positive"
                        value={obj?.cost !== undefined ? String(obj.cost) : undefined}
                        // @ts-ignore
                        onChange={(e) => onChange("cost", e.target.value)}
                    /> 
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Plaintiff(s) (comma separated)", inputId: "affidavit_modal_plaintiffs", isRequired: true }}
                        placeholder="Plaintiff(s)'s name(s)"
                        value={obj?.plaintiffs ?? undefined}
                        onChange={(e) => onChange("plaintiffs", e.target.value)}
                        tooltipProps={{
                            message: 'Names should be separated by a comma followed by a space. Do not add "and" or "&". E.g. "Party One, Party Two, Party Three"',
                            placement: "right-start"
                        }}
                    />
                </Grid>
            </Grid>
    );
}