import { CreatePaymentGroup } from "../../../types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";

export const defaultCreatePaymentGroup: CreatePaymentGroup = {
    recipient_type: undefined,
    third_party_transfer: undefined,
    lender_id: undefined,
    government_id: undefined,
    mortgage_brokerage_id: undefined,
    client_id: undefined,
    other_charge_holder_id: undefined,
    company_id: undefined,
    lawyer_id: undefined,
    wiring_fee: undefined,
    payment_method: undefined
}