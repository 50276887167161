import { Dispatch } from "react";

import * as actions from "../../actionTypes"
import {
    newIlaTransaction,
    deleteIlaTransaction
} from '../../../../libs/service/axios/api';
import { Transaction } from "../../../../libs/types/UniversalSurvey/Transaction/transaction";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";
import { TransactionMappingPills } from "../../../../libs/resources/mappings/transactions";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { updateDealTransactionData } from "../deal";

function addIlaTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, numTransactions: number, setPrimary?: boolean) {
    newIlaTransaction(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.CREATE_ILA_TRANSACTION, payload: response.data.id });
            if (numTransactions === 0 || setPrimary) {
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_id", value: response.data.id } });
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_type", value: TransactionTypes.ILA } });
            }
            if (setPrimary) {
                updateDealTransactionData(dispatch, dealId, response.data.id, TransactionTypes.ILA);
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New ILA transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function removeIlaTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number) {
    deleteIlaTransaction(dealId, String(transactionId))
        .then(function () {
            dispatch({ type: actions.REMOVE_TRANSACTION, payload: { id: transactionId, transactionType: TransactionTypes.ILA } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete ILA transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function sortAndLabelIlaTransactions(ilas: Transaction[]) {
    ilas.sort((a, b) => a.id > b.id ? 1 : -1);
    for (let i = 0; i < ilas.length; i++) {
        ilas[i].label = TransactionMappingPills[ilas[i].transaction_type as keyof typeof TransactionMappingPills];
        if (ilas.length > 1) {
            ilas[i].label += ` ${i + 1}`;
        }
    }
}

export {
    addIlaTransaction,
    removeIlaTransaction,
    sortAndLabelIlaTransactions
}