import * as actions from "../actionTypes"

import { findAndRemove, sortTrustLedgerItems } from "../../../libs/utils/arrays";
import { SimpleTrustLedger } from "../../../libs/types/UniversalSurvey/TrustLedger/simpleTrustLedger";

export type TrustLedgersState = {
    trustLedgers: SimpleTrustLedger[];
    trustLedgersBeingSaved: number[];
    isTrustLedgerSaving: boolean;
    shouldRefreshTrustLedger: boolean;
}

export const defaultTrustLedgersState: TrustLedgersState = {
    trustLedgers: [],
    trustLedgersBeingSaved: [],
    isTrustLedgerSaving: false,
    shouldRefreshTrustLedger: false
}

export function trustLedgersReducer(state: TrustLedgersState, action: Record<string, any>): TrustLedgersState {
    switch (action.type) {
        case actions.SET_TRUST_LEDGERS:
            sortTrustLedgerItems(action.payload);
            return { ...state, trustLedgers: action.payload };
        case actions.ADD_CUSTOM_TRUST_LEDGER:
            let tempItems = [...state.trustLedgers, action.payload];
            sortTrustLedgerItems(tempItems);
            return { ...state, trustLedgers: tempItems};
        case actions.SET_IS_TRUST_LEDGER_SAVING:
            return { ...state, isTrustLedgerSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_TRUST_LEDGER:
            return { ...state, shouldRefreshTrustLedger: action.payload };
        case actions.REMOVE_TRUST_LEDGER_BEING_SAVED:
            let isLedgerItemSaving = state.isTrustLedgerSaving;
            let shouldRefreshTrustLedger = state.shouldRefreshTrustLedger;
            const tempTrustLedgersBeingSaved = [...state.trustLedgersBeingSaved];
            const index = tempTrustLedgersBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempTrustLedgersBeingSaved.splice(index, 1);
            }
            if (index >=0 && tempTrustLedgersBeingSaved.length === 0 && isLedgerItemSaving) {
                isLedgerItemSaving = false;
                shouldRefreshTrustLedger = true;
            }
            return {
                ...state,
                trustLedgersBeingSaved: tempTrustLedgersBeingSaved,
                isTrustLedgerSaving: isLedgerItemSaving,
                shouldRefreshTrustLedger: shouldRefreshTrustLedger
            };
        case actions.ADD_TRUST_LEDGER_BEING_SAVED:
            if (!state.trustLedgersBeingSaved.includes(action.payload)) {
                return { ...state, trustLedgersBeingSaved: [...state.trustLedgersBeingSaved, action.payload] };
            }
            return state;
        case actions.DELETE_CUSTOM_TRUST_LEDGER:
            return { ...state, trustLedgers: findAndRemove(state.trustLedgers, ["id"], [action.payload])};
        default:
            return state;
    }
}