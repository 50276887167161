export enum EntityTypeForRoles {
    Property = "property",
    Client = "client"
};

export enum RoleTypes {
    Subject = "Subject",
    Collateral = "Collateral",
    Other = "Other",
    Borrower = "Borrower",
    Advisee = "Advisee",
    Transferee = "Transferee",
    Transferor = "Transferor",
    Guarantor = "Guarantor",
    Client = "Client",
    ConsentingSpouse = "Consenting Spouse",
    POA = "POA",
    EOE = "EOE"
}

export enum RoleTypesDB {
    Subject = "subject_property",
    Collateral = "collateral_property",
    Other = "other_property",
    Borrower = "borrower",
    Advisee = "advisee",
    Transferee = "transferee",
    Transferor = "transferor",
    Guarantor = "guarantor",
    ConsentingSpouse = "consenting_spouse",
    POA = "power_of_attorney",
    EOE = "executor_of_estate"
}