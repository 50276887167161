// import {useState} from "react";
import {styled, TableCell, TableHead, TableRow} from "@mui/material";

// import useUser from "../../../../../utils/hooks/useUser";
import DealsListSortingMenuItemTable from "./DealsListSortingMenuItemTable";

interface DealsListSortingMenuProps {
  sortBy: string | undefined;
  sortOrder: boolean;
  handleSort: (type: string) => void;
}

const menuItemBasicList = [
  {item: "Type", type: "primary_transaction_type"},
  {item: "Province", type: "province"},
  {item: "Client Name", type: null},
  {item: "Address", type: "subject_property_address"},
  {item: "Created", type: "opened_date"},
  {item: "Closing", type: "closing_date"},
  {item: "Number", type: "file_num"},
  {item: "Deal Status", type: "status"},
];
// const menuItemClientList = [
//   {item: "Type", type: "type"},
//   {item: "Province", type: "province"},
//   {item: "Client Name", type: "client_name"},
//   {item: "Address", type: "address"},
//   {item: "Closing", type: "closing"},
// ];

const DealsListSortingMenuTableHead = ({
  handleSort,
  sortBy,
  sortOrder,
}: DealsListSortingMenuProps) => {
  // const user = useUser().user;
  // const [menuItemState, setMenuItemState] = useState(
  //   user.role === "Client" ? menuItemClientList : menuItemBasicList,
  // );
  
  return (
    <TableHead>
      <SortingMenuContainerRow>
        {menuItemBasicList.map((item, i) => {
          return (
            <TableCell
              sx={{
                borderBottom: "none",
              }}
              key={i}
            >
              <DealsListSortingMenuItemTable
                sortBy={sortBy}
                sortOrder={sortOrder}
                key={item.type}
                title={item.item}
                type={item.type}
                handleSort={handleSort}
              />
            </TableCell>
          );
        })}
      </SortingMenuContainerRow>
    </TableHead>
  );
};

const SortingMenuContainerRow = styled(TableRow)({});

export default DealsListSortingMenuTableHead;
