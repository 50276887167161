import react, { useEffect, useState } from 'react';

type ScrollWithShadowProps = {
    clientHeight: number | undefined;
    scrollHeight: number | undefined;
}

export function useScrollWithShadow(props: ScrollWithShadowProps) {
    const { clientHeight, scrollHeight } = props;
    const [scrollTop, setScrollTop] = useState(0);

    const onScrollHandler = (event: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        setScrollTop(event.target.scrollTop);
    };

    useEffect(() => {
        getBoxShadow();
    }, [clientHeight, scrollHeight]);

    function getBoxShadow() {
        let boxShadow = 'none';
        if (scrollHeight && clientHeight) {
            if (scrollHeight > clientHeight) {
                const isBottom = clientHeight === scrollHeight - scrollTop;
                const isTop = scrollTop === 0;
                const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
        
                const top = 'inset 0 8px 5px -5px rgb(200 200 200 / 1)';
                const bottom = 'inset 0 -8px 5px -5px rgb(200 200 200 / 1)';
        
                if (isTop) {
                    boxShadow = bottom;
                } else if (isBetween) {
                    boxShadow = `${top}, ${bottom}`;
                } else if (isBottom) {
                    boxShadow = top;
                }
            }
        }
        return boxShadow;
    }

    return { boxShadow: getBoxShadow(), onScrollHandler };
}