import { defaultLien } from "../../../resources/defaults/frontend/defaultLien";
import { defaultLender } from "../../../resources/defaults/frontend/defaultLender";
import { defaultGlobalLenderContact, defaultLenderContact } from "../../../resources/defaults/frontend/defaultLenderContact";
import { defaultMortgageBroker } from "../../../resources/defaults/frontend/defaultMortgageBroker";
import { defaultMortgageBrokerage } from "../../../resources/defaults/frontend/defaultMortgageBrokerage";
import { defaultMortgageBrokerageOtherContact } from "../../../resources/defaults/frontend/defaultMortgageBrokerageOtherContact";
import { defaultNewMortgage } from "../../../resources/defaults/frontend/defaultNewMortgage";
import { defaultProperty } from "../../../resources/defaults/frontend/defaultProperty";
import { defaultClient } from "../../../resources/defaults/frontend/defaultClient";
import { defaultMortgageTransaction } from "../../../resources/defaults/frontend/defaultMortgageTransaction";
import { defaultUndertaking } from "../../../resources/defaults/frontend/defaultUndertaking";
import { defaultSimpleLien } from "../../../resources/defaults/frontend/defaultSimpleLien";
import { defaultSimpleChargeHolderCompany } from "../../../resources/defaults/frontend/defaultSimpleChargeHolderCompany";
import { defaultSimpleUndertaking } from "../../../resources/defaults/frontend/defaultSimpleUndertaking";
import { defaultChargeHolderCompany } from "../../../resources/defaults/frontend/defaultChargeHolderCompany";
import { defaultSimpleLender } from "../../../resources/defaults/frontend/defaultSimpleLender";
import { defaultSimpleChargeHolderCompanyContact } from "../../../resources/defaults/frontend/defaultSimpleChargeHolderCompanyContact";
import { defaultChargeHolderCompanyContact } from "../../../resources/defaults/frontend/defaultChargeHolderCompanyContact";
import { defaultOtherChargeHolder } from "../../../resources/defaults/frontend/defaultOtherChargeHolder";
import { defaultSimpleOtherChargeHolder } from "../../../resources/defaults/frontend/defaultSimpleOtherChargeHolder";
import { Client } from "../Client/client";
import { Lien } from "../ExistingLien/lien";
import { Lender } from "../Lender/lender";
import { GlobalLenderContact, LenderContact } from "../LenderContact/lenderContact";
import { MortgageBroker } from "../MortgageBroker/mortgageBroker";
import { MortgageBrokerage } from "../MortgageBrokerage/mortgageBrokerage";
import { MortgageBrokerageOtherContact } from "../MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import { NewMortgage } from "../NewMortgage/newMortgage";
import { Property } from "../Property/property";
import { MortgageTransaction } from "../MortgageTransaction/mortgageTransaction";
import { Undertaking } from "../Undertaking/undertaking";
import { ClientResponse } from "../Client/clientResponse";
import { LienResponse } from "../ExistingLien/lienResponse";
import { LenderResponse } from "../Lender/lenderResponse";
import { GlobalLenderContactResponse, LenderContactResponse } from "../LenderContact/lenderContactResponse";
import { MortgageBrokerResponse } from "../MortgageBroker/mortgageBrokerResponse";
import { MortgageBrokerageResponse } from "../MortgageBrokerage/mortgageBrokerageResponse";
import { MortgageBrokerageOtherContactResponse } from "../MortgageBrokerageOtherContact/mortgageBrokerageOtherContactResponse";
import { NewMortgageResponse } from "../NewMortgage/newMortgageResponse";
import { MortgageTransactionResponse } from "../MortgageTransaction/mortgageTransactionResponse"
import { PropertyResponse } from "../Property/propertyResponse";
import { PriorityAfterClosingResponseItem } from "../PriorityAfterClosing/priorityAfterClosingResponse";
import { PriorityAfterClosingItem } from "../PriorityAfterClosing/priorityAfterClosing";
import { defaultPriorityAfterClosingItem } from "../../../resources/defaults/frontend/defaultPriorityAfterClosing";
import { UndertakingResponse } from "../Undertaking/undertakingResponse";
import { SimpleLienResponse } from "../ExistingLien/simpleLienResponse";
import { SimpleLien } from "../ExistingLien/simpleLien";
import { SimpleChargeHolderCompany } from "../ChargeHolderCompany/simpleChargeHolderCompany";
import { SimpleChargeHolderCompanyResponse } from "../ChargeHolderCompany/simpleChargeHolderCompanyResponse";
import { ChargeHolderCompanyResponse } from "../ChargeHolderCompany/chargeHolderCompanyResponse";
import { SimpleLender } from "../Lender/simpleLender";
import { SimpleUndertakingResponse } from "../Undertaking/simpleUndertakingResponse";
import { SimpleUndertaking } from "../Undertaking/simpleUndertaking";
import { ChargeHolderCompany } from "../ChargeHolderCompany/chargeHolderCompany";
import { SimpleLenderResponse } from "../Lender/simpleLenderResponse";
import { SimpleGovernmentResponse } from "../Government/simpleGovernmentResponse";
import { SimpleGovernment } from "../Government/simpleGovernment";
import { defaultSimpleGovernment } from "../../../resources/defaults/frontend/defaultSimpleGovernment";
import { GovernmentResponse } from "../Government/governmentResponse";
import { Government } from "../Government/government";
import { defaultGovernment } from "../../../resources/defaults/frontend/defaultGovernment";
import { SimpleChargeHolderCompanyContact } from "../ChargeHolderCompanyContact/simpleChargeHolderCompanyContact";
import { ChargeHolderCompanyContactResponse } from "../ChargeHolderCompanyContact/chargeHolderCompanyContactResponse";
import { ChargeHolderCompanyContact } from "../ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { GovernmentContactResponse } from "../GovernmentContacts/governmentContactResponse";
import { GovernmentContact } from "../GovernmentContacts/governmentContact";
import { defaultGovernmentContact } from "../../../resources/defaults/frontend/defaultGovernmentContact";
import { SimpleGovernmentContact } from "../GovernmentContacts/simpleGovernmentContact";
import { SimpleGovernmentContactResponse } from "../GovernmentContacts/simpleGovernmentContactResponse";
import { defaultSimpleGovernmentContact } from "../../../resources/defaults/frontend/defaultSimpleGovernmentContact";
import { SimpleOtherChargeHolder } from "../OtherChargeHolder/simpleOtherChargeHolder";
import { SimpleOtherChargeHolderResponse } from "../OtherChargeHolder/simpleOtherChargeHolderResponse";
import { OtherChargeHolderResponse } from "../OtherChargeHolder/otherChargeHolderResponse";
import { OtherChargeHolder } from "../OtherChargeHolder/otherChargeHolder";
import { Fee } from "../Fees/fee";
import { defaultFee } from "../../../resources/defaults/frontend/defaultFee";
import { SimpleFee } from "../Fees/simpleFee";
import { defaultSimpleFee } from "../../../resources/defaults/frontend/defaultSimpleFee";
import { SimpleFeeResponse } from "../Fees/simpleFeeResponse";
import { FeeResponse } from "../Fees/feeResponse";
import { Disbursement } from "../Disbursements/disbursement";
import { defaultDisbursement } from "../../../resources/defaults/frontend/defaultDisbursement";
import { SimpleDisbursement } from "../Disbursements/simpleDisbursement";
import { defaultSimpleDisbursement } from "../../../resources/defaults/frontend/defaultSimpleDisbursement";
import { SimpleDisbursementResponse } from "../Disbursements/simpleDisbursementResponse";
import { DisbursementResponse } from "../Disbursements/disbursementResponse";
import { FireInsuranceResponse } from "../FireInsurance/fireInsuranceResponse";
import { FireInsurance } from "../FireInsurance/fireInsurance";
import { defaultFireInsurance } from "../../../resources/defaults/frontend/defaultFireInsurance";
import { SimpleFireInsuranceResponse } from "../FireInsurance/simpleFireInsuranceResponse";
import { SimpleFireInsurance } from "../FireInsurance/simpleFireInsurance";
import { defaultSimpleFireInsurance } from "../../../resources/defaults/frontend/defaultSimpleFireInsurance";
import { SimpleProperty } from "../Property/simpleProperty";
import { SimplePropertyResponse } from "../Property/simplePropertyResponse";
import { defaultSimpleProperty } from "../../../resources/defaults/frontend/defaultSimpleProperty";
import { SimpleFireInsuranceCompanyResponse } from "../FireInsuranceCompany/simpleFireInsuranceCompanyResponse";
import { SimpleFireInsuranceCompany } from "../FireInsuranceCompany/simpleFireInsuranceCompany";
import { defaultSimpleFireInsuranceCompany } from "../../../resources/defaults/frontend/defaultSimpleFireInsuranceCompany";
import { FireInsuranceCompanyResponse } from "../FireInsuranceCompany/fireInsuranceCompanyResponse";
import { FireInsuranceCompany } from "../FireInsuranceCompany/fireInsuranceCompany";
import { defaultFireInsuranceCompany } from "../../../resources/defaults/frontend/defaultFireInsuranceCompany";
import { SimpleFireInsuranceBrokerageResponse } from "../FireInsuranceBrokerage/simpleFireInsuranceBrokerageResponse";
import { SimpleFireInsuranceBrokerage } from "../FireInsuranceBrokerage/simpleFireInsuranceBrokerage";
import { defaultSimpleFireInsuranceBrokerage } from "../../../resources/defaults/frontend/defaultSimpleFireInsuranceBrokerage";
import { FireInsuranceBrokerageResponse } from "../FireInsuranceBrokerage/fireInsuranceBrokerageResponse";
import { FireInsuranceBrokerage } from "../FireInsuranceBrokerage/fireInsuranceBrokerage";
import { defaultFireInsuranceBrokerage } from "../../../resources/defaults/frontend/defaultFireInsuranceBrokerage";
import { SimpleFireInsuranceBrokerResponse } from "../FireInsuranceBroker/simpleFireInsuranceBrokerResponse";
import { SimpleFireInsuranceBroker } from "../FireInsuranceBroker/simpleFireInsuranceBroker";
import { defaultSimpleFireInsuranceBroker } from "../../../resources/defaults/frontend/defaultSimpleFireInsuranceBroker";
import { FireInsuranceBrokerResponse } from "../FireInsuranceBroker/fireInsuranceBrokerResponse";
import { FireInsuranceBroker } from "../FireInsuranceBroker/fireInsuranceBroker";
import { defaultFireInsuranceBroker } from "../../../resources/defaults/frontend/defaultFireInsuranceBroker";
import { SimpleClient } from "../Client/simpleClient";
import { defaultSimpleClient } from "../../../resources/defaults/frontend/defaultSimpleClient";
import { AffidavitResponse } from "../Affivadit/affidavitResponse";
import { Affidavit } from "../Affivadit/affidavit";
import { defaultAffidavit } from "../../../resources/defaults/frontend/defaultAffidavit";
import { SimpleAffidavitResponse } from "../Affivadit/simpleAffidavitResponse";
import { SimpleAffidavit } from "../Affivadit/simpleAffidavit";
import { defaultSimpleAffidavit } from "../../../resources/defaults/frontend/defaultSimpleAffidavit";
import { TitleInsuranceResponse } from "../TitleInsurance/titleInsuranceResponse";
import { TitleInsurance } from "../TitleInsurance/titleInsurance";
import { defaultTitleInsurance } from "../../../resources/defaults/frontend/defaultTitleInsurance";
import { SimpleTitleInsurancePremiumResponse } from "../TitleInsurancePremium/simpleTitleInsurancePremiumResponse";
import { SimpleTitleInsurancePremium } from "../TitleInsurancePremium/simpleTitleInsurancePremium";
import { defaultSimpleTitleInsurancePremium } from "../../../resources/defaults/frontend/defaultSimpleTitleInsurancePremium";
import { DateObjectToDateFormat, convertDateStringToDateObject, stringToDateFormat } from "../../../utils/dateHelper";
import { StaffJobRecord } from "../Frontend/staffJobRecord";
import { TransactionMappingDbToClean } from "../../../resources/mappings/transactions";
import { StatDecResponse } from "../StatutoryDeclarations/statDecResponse";
import { StatDec } from "../StatutoryDeclarations/statDec";
import { defaultStatDec } from "../../../resources/defaults/frontend/defaultStatDec";
import { SimpleStatDecResponse } from "../StatutoryDeclarations/simpleStatDecResponse";
import { SimpleStatDec } from "../StatutoryDeclarations/simpleStatDec";
import { defaultSimpleStatDec } from "../../../resources/defaults/frontend/defaultSimpleStatDec";
import { SimplePropertyPin } from "../PropertyPins/simplePropertyPin";
import { defaultSimplePropertyPin } from "../../../resources/defaults/frontend/defaultSimplePropertyPin";
import { DebtResponse } from "../Debts/debtResponse";
import { Debt } from "../Debts/debt";
import { defaultDebt } from "../../../resources/defaults/frontend/defaultDebt";
import { SimpleDebtResponse } from "../Debts/simpleDebtResponse";
import { SimpleDebt } from "../Debts/simpleDebt";
import { defaultSimpleDebt } from "../../../resources/defaults/frontend/defaultSimpleDebt";
import { TrustLedgerResponse } from "../TrustLedger/trustLedgerResponse";
import { TrustLedger } from "../TrustLedger/trustLedger";
import { SimpleTrustLedger } from "../TrustLedger/simpleTrustLedger";
import { SimpleTrustLedgerResponse } from "../TrustLedger/simpleTrustLedgerResponse";
import { defaultTrustLedger } from "../../../resources/defaults/frontend/defaultTrustLedger";
import { defaultSimpleTrustLedger } from "../../../resources/defaults/frontend/defaultSimpleTrustLedger";
import { SimpleCondoCorporationResponse } from "../CondoCorporation/simpleCondoCorporationResponse";
import { SimpleCondoCorporation } from "../CondoCorporation/simpleCondoCorporation";
import { defaultSimpleCondoCorporation } from "../../../resources/defaults/frontend/defaultSimpleCondoCorporation";
import { CondoCorporationResponse } from "../CondoCorporation/condoCorporationResponse";
import { CondoCorporation } from "../CondoCorporation/condoCorporation";
import { defaultCondoCorporation } from "../../../resources/defaults/frontend/defaultCondoCorporation";
import { defaultPropertyManagementCompany } from "../../../resources/defaults/frontend/defaultPropertyManagementCompany";
import { PropertyManagementCompanyResponse } from "../PropertyManagementCompany/propertyManagementCompanyResponse";
import { PropertyManagementCompany } from "../PropertyManagementCompany/propertyManagementCompany";
import { SimplePropertyManagementCompanyResponse } from "../PropertyManagementCompany/simplePropertyManagementCompanyResponse";
import { SimplePropertyManagementCompany } from "../PropertyManagementCompany/simplePropertyManagementCompany";
import { defaultSimplePropertyManagementCompany } from "../../../resources/defaults/frontend/defaultSimplePropertyManagementCompany";
import { ConditionResponse } from "../Condition/conditionResponse";
import { Condition } from "../Condition/condition";
import { defaultCondition } from "../../../resources/defaults/frontend/defaultCondition";
import { SimpleConditionResponse } from "../Condition/simpleConditionResponse";
import { SimpleCondition } from "../Condition/simpleCondition";
import { defaultSimpleCondition } from "../../../resources/defaults/frontend/defaultSimpleCondition";
import { defaultBaseDocument } from "../../../resources/defaults/documents/defaultBaseDocument";
import { BaseDocumentResponse } from "../Documents/baseDocumentResponse";
import { Document } from "../Documents/baseDocument";
import { DealResponse } from "../Deal/dealResponse";
import { Deal } from "../Deal/deal";
import { defaultDeal } from "../../../resources/defaults/frontend/defaultDeal";
import { BaseTemplateHtmlResponse, BaseTemplateResponse } from "../Documents/templates/baseTemplateResponse";
import { BaseTemplateHtml, Template } from "../Documents/templates/baseTemplate";
import { defaultBaseTemplate, defaultBaseTemplateHtml } from "../../../resources/defaults/documents/defaultBaseTemplate";
import { GeneratedResponse } from "../Documents/generated/generatedResponse";
import { Generated } from "../Documents/generated/generated";
import { defaultGenerated } from "../../../resources/defaults/documents/defaultGenerated";
import { Preview } from "../Documents/previews/preview";
import { PreviewResponse } from "../Documents/previews/previewResponse";
import { defaultPreview } from "../../../resources/defaults/documents/defaultPreview";
import { StaffType } from "../../../resources/enums/staffType";
import { SimpleMortgageTransactionResponse } from "../MortgageTransaction/simpleMortgageTransactionResponse";
import { SimpleMortgageTransaction } from "../MortgageTransaction/simpleMortgageTransaction";
import { defaultSimpleMortgageTransaction } from "../../../resources/defaults/frontend/defaultSimpleMortgageTransaction";
import { SigningAppointmentResponse } from "../SigningAppointment/signingAppointmentResponse";
import { SigningAppointment } from "../SigningAppointment/signingAppointment";
import { defaultSigningAppointment } from "../../../resources/defaults/frontend/defaultSigningAppointment";
import { SimpleSigningAppointmentResponse } from "../SigningAppointment/simpleSigningAppointmentResponse";
import { SimpleSigningAppointment } from "../SigningAppointment/simpleSigningAppointment";
import { defaultSimpleSigningAppointment } from "../../../resources/defaults/frontend/defaultSimpleSigningAppointment";
import { SimpleLegalProfessionalResponse } from "../LegalProfessional/simpleLegalProfessionalResponse";
import { SimpleLegalProfessional } from "../LegalProfessional/simpleLegalProfessional";
import { defaultSimpleLegalProfessional } from "../../../resources/defaults/frontend/defaultSimpleLegalProfessional";
import { LegalProfessionalResponse } from "../LegalProfessional/legalProfessionalResponse";
import { LegalProfessional } from "../LegalProfessional/legalProfessional";
import { defaultLegalProfessional } from "../../../resources/defaults/frontend/defaultLegalProfessional";
import { SimpleGlobalLegalFirm, SimpleLegalFirm } from "../LegalFirm/simpleLegalFirm";
import { SimpleGlobalLegalFirmResponse, SimpleLegalFirmResponse } from "../LegalFirm/simpleLegalFirmResponse";
import { defaultSimpleLegalFirm } from "../../../resources/defaults/frontend/defaultSimpleLegalFirm";
import { GlobalLegalFirm, LegalFirm } from "../LegalFirm/legalFirm";
import { defaultGlobalLegalFirm, defaultLegalFirm } from "../../../resources/defaults/frontend/defaultLegalFirm";
import { GlobalLegalFirmResponse, LegalFirmResponse } from "../LegalFirm/legalFirmResponse";
import { defaultSimpleLegalFirmOffice } from "../../../resources/defaults/frontend/defaultSimpleLegalFirmOffice";
import { SimpleGlobalLegalFirmOfficeResponse, SimpleLegalFirmOfficeResponse } from "../LegalFirmOffice/simpleLegalFirmOfficeResponse";
import { SimpleGlobalLegalFirmOffice, SimpleLegalFirmOffice } from "../LegalFirmOffice/simpleLegalFirmOffice";
import { GlobalLegalFirmOfficeResponse, LegalFirmOfficeResponse } from "../LegalFirmOffice/legalFirmOfficeResponse";
import { GlobalLegalFirmOffice, LegalFirmOffice } from "../LegalFirmOffice/legalFirmOffice";
import { defaultLegalFirmOffice } from "../../../resources/defaults/frontend/defaultLegalFirmOffice";
import { UploadedResponse } from "../Documents/uploaded/uploadedResponse";
import { Uploaded } from "../Documents/uploaded/uploaded";
import { defaultUploaded } from "../../../resources/defaults/documents/defaultUploaded";
import { defaultSimpleAddress } from "../../../resources/defaults/frontend/defaultSimpleAddress";
import { defaultDeals } from "../../../resources/defaults/frontend/defaultDeals";
import { defaultSimpleDeal } from "../../../resources/defaults/frontend/defaultSimpleDeal";
import { Deals, RegionListItem, SimpleDeal } from "../../DealList/deals";
import { DealsResponse, RegionResponse } from "../../DealList/dealsResponse";
import { defaultFinancialInfo } from "../../../resources/defaults/frontend/defaultFinancialInfo";
import { PaymentGroupResponse } from "../TrustLedger/PaymentGroup/paymentGroupResponse";
import { PaymentGroup } from "../TrustLedger/PaymentGroup/paymentGroup";
import { defaultPaymentGroup } from "../../../resources/defaults/frontend/defaultPaymentGroup";
import { SimpleNewMortgageNetAdvanceAmount } from "../NewMortgageNetAdvanceAmount/simpleNewMortgageNetAdvanceAmount";
import { SimpleNewMortgageNetAdvanceAmountResponse } from "../NewMortgageNetAdvanceAmount/simpleNewMortgageNetAdvanceAmountResponse";
import { defaultSimpleNewMortgageNetAdvanceAmount } from "../../../resources/defaults/frontend/defaultSimpleNewMortgageNetAdvanceAmount";
import { defaultRegion } from "../../../resources/defaults/frontend/defaultRegion";
import { PriorityBeforeClosingResponseItem } from "../PriorityBeforeClosing/priorityBeforeClosingResponse";
import { PriorityBeforeClosingItem } from "../PriorityBeforeClosing/priorityBeforeClosing";
import { defaultPriorityBeforeClosingItem } from "../../../resources/defaults/frontend/defaultPriorityBeforeClosing";
import { defaultPaginationMetadata } from "../../../resources/defaults/frontend/defaultPaginationMetadata";
import { GlobalSimpleLenderContactResponse } from "../LenderContact/simpleLenderContactResponse";
import { GlobalSimpleLenderContact } from "../LenderContact/simpleLenderContact";
import { defaultGlobalSimpleLenderContact } from "../../../resources/defaults/frontend/defaultSimpleLenderContact";
import { NoteResponse } from "../Note/noteResponse";
import { Note } from "../Note/note";
import { defaultNote } from "../../../resources/defaults/frontend/defaultNote";
import { defaultDashboardUser } from "../../../resources/defaults/frontend/defaultDashboardUser";
import { SimpleLenderBranch } from "../Lender/LenderBranch/simpleLenderBranch";
import { SimpleLenderBranchResponse } from "../Lender/LenderBranch/simpleLenderBranchResponse";
import { defaultSimpleLenderBranch } from "../../../resources/defaults/frontend/defaultSimpleLenderBranch";
import { LenderBranchResponse } from "../Lender/LenderBranch/lenderBranchResponse";
import { LenderBranch } from "../Lender/LenderBranch/lenderBranch";
import { defaultLenderBranch } from "../../../resources/defaults/frontend/defaultLenderBranch";
import { SimpleSourceLenderResponse } from "../Lender/SourceLender/simpleSourceLenderResponse";
import { SimpleSourceLender } from "../Lender/SourceLender/simpleSourceLender";
import { defaultLenderSource } from "../../../resources/defaults/frontend/defaultLenderSource";
import { DashboardUserResponse } from "../DashboardUser/dashboardUserResponse";
import { DashboardUser } from "../DashboardUser/dashboardUser";
import { defaultSimpleGlobalLegalFirm } from "../../../resources/defaults/frontend/defaultSimpleGlobalLegalFirm";
import { defaultSimpleGlobalLegalFirmOffice } from "../../../resources/defaults/frontend/defaultSimpleGlobalLegalFirmOffice";
import { defaultGlobalLegalFirmOffice } from "../../../resources/defaults/frontend/defaultGlobalLegalFirmOffice";

function sanitizeResponse<
    Input, Output extends Object
>(responseObj: Input, defaultFrontendObj: Output): Output {
    let k: keyof Input;
    const cleanObj = { ...defaultFrontendObj };
    for (k in responseObj) {
        if (cleanObj.hasOwnProperty(k)) {
            if ((k === "date_created" || k === "date_updated")) {
                cleanObj[k as keyof Output] = responseObj[k] !== null ? convertDateStringToDateObject(responseObj[k] as string, true) as any : undefined;
            } else if (cleanObj[k as keyof Output] === defaultSimpleAddress) {
                if (responseObj[k] === null){
                cleanObj[k as keyof Output] = { ...defaultSimpleAddress } as Output[keyof Output];
                }else{
                    cleanObj[k as keyof Output] = sanitizeResponse(responseObj[k], defaultSimpleAddress) as Output[keyof Output];
                }
            } else if (cleanObj[k as keyof Output] === defaultFinancialInfo) {
                if (responseObj[k] === null){
                cleanObj[k as keyof Output] = { ...defaultFinancialInfo } as Output[keyof Output];
                }else{
                    cleanObj[k as keyof Output] = sanitizeResponse(responseObj[k], defaultFinancialInfo) as Output[keyof Output];
                }
            } else {
                cleanObj[k as keyof Output] = responseObj[k] != null ? responseObj[k] as any : undefined;
            }
        }
    }
    return cleanObj;
}

function sanitizeClientResponse(clientObj: ClientResponse): Client {
    const cleanResponse = sanitizeResponse(clientObj, defaultClient);
    if (typeof cleanResponse.birthdate === "string") {
        cleanResponse.birthdate = convertDateStringToDateObject(cleanResponse.birthdate);
    }
    if (typeof cleanResponse.primary_expiry === "string") {
        cleanResponse.primary_expiry = convertDateStringToDateObject(cleanResponse.primary_expiry);
    }
    if (typeof cleanResponse.secondary_expiry === "string") {
        cleanResponse.secondary_expiry = convertDateStringToDateObject(cleanResponse.secondary_expiry);
    }
    return cleanResponse;
}

function sanitizeSimpleClientsResponse(clientList: SimpleClient[]): SimpleClient[] {
    const cleanList: SimpleClient[] = [];
    for(const client of clientList) {
        cleanList.push(sanitizeResponse(client, defaultSimpleClient))
    }
    return cleanList;
}

function sanitizePropertyResponse(propertyObj: PropertyResponse): Property {
    const cleanProperty = sanitizeResponse(propertyObj, defaultProperty);
    if (cleanProperty.property_pin_records) {
        const cleanPins: SimplePropertyPin[] = [];
        for (const pin of cleanProperty.property_pin_records) {
            cleanPins.push(sanitizeResponse(pin, defaultSimplePropertyPin))
        }
        cleanProperty.property_pin_records = cleanPins;
    }
    if (cleanProperty.condo_corporation_record) {
        cleanProperty.condo_corporation_id = cleanProperty.condo_corporation_record.id;
    }
    return cleanProperty;
}

function sanitizeSimplePropertiesResponse(propertyList: SimplePropertyResponse[]): SimpleProperty[] {
    const cleanList: SimpleProperty[] = [];
    for(const property of propertyList) {
        cleanList.push(sanitizeResponse(property, defaultSimpleProperty))
    }
    return cleanList;
}

function sanitizeLenderResponse(lenderObj: LenderResponse): Lender {
    return sanitizeResponse(lenderObj, defaultLender);
}

function sanitizeLenderBranchResponse(lenderObj: LenderBranchResponse): LenderBranch {
    return sanitizeResponse(lenderObj, defaultLenderBranch);
}

function sanitizeLenderContactResponse(lenderContactObj: LenderContactResponse): LenderContact {
    return sanitizeResponse(lenderContactObj, defaultLenderContact);
}

function sanitizeGlobalLenderContactResponse(lenderContactObj: GlobalLenderContactResponse): GlobalLenderContact {
    return sanitizeResponse(lenderContactObj, defaultGlobalLenderContact);
}

function sanitizeMortgageBrokerageResponse(brokerageObj: MortgageBrokerageResponse): MortgageBrokerage {
    return sanitizeResponse(brokerageObj, defaultMortgageBrokerage);
}

function sanitizeMortgageBrokerResponse(brokerObj: MortgageBrokerResponse): MortgageBroker {
    return sanitizeResponse(brokerObj, defaultMortgageBroker);
}

function sanitizeMortgageBrokerageOtherContactResponse(contactObj: MortgageBrokerageOtherContactResponse): MortgageBrokerageOtherContact {
    return sanitizeResponse(contactObj, defaultMortgageBrokerageOtherContact);
}

function sanitizeNewMortgageResponse(mortgageObj: NewMortgageResponse): NewMortgage {
    const cleanResponse = sanitizeResponse(mortgageObj, defaultNewMortgage);
    if (typeof cleanResponse.date_of_commitment === "string") {
        cleanResponse.date_of_commitment = convertDateStringToDateObject(cleanResponse.date_of_commitment);
    }
    if (typeof cleanResponse.registration_date === "string") {
        cleanResponse.registration_date = convertDateStringToDateObject(cleanResponse.registration_date);
    }
    if (typeof cleanResponse.interest_adjustment_date === "string") {
        cleanResponse.interest_adjustment_date = convertDateStringToDateObject(cleanResponse.interest_adjustment_date);
    }
    if (typeof cleanResponse.first_payment_date === "string") {
        cleanResponse.first_payment_date = convertDateStringToDateObject(cleanResponse.first_payment_date);
    }
    if (typeof cleanResponse.maturity_date === "string") {
        cleanResponse.maturity_date = convertDateStringToDateObject(cleanResponse.maturity_date);
    }
    if (typeof cleanResponse.assignment_of_rents_reg_date === "string") {
        cleanResponse.assignment_of_rents_reg_date = convertDateStringToDateObject(cleanResponse.assignment_of_rents_reg_date);
    }
    cleanResponse.net_advance_amount = sanitizeNewMortgageNetAdvanceAmountsResponse(mortgageObj.net_advance_amount);

    return cleanResponse;
}

function sanitizeNewMortgageNetAdvanceAmountsResponse(deductions: SimpleNewMortgageNetAdvanceAmountResponse[]): SimpleNewMortgageNetAdvanceAmount[] {
    const cleanList: SimpleNewMortgageNetAdvanceAmount[] = [];
    for(const deduction of deductions) {
        cleanList.push(sanitizeResponse(deduction, defaultSimpleNewMortgageNetAdvanceAmount))
    }
    return cleanList;
}

function sanitizeMortgageTransactionResponse(mortgageTransactionObj: MortgageTransactionResponse): MortgageTransaction {
    let sanitizedNewMortgage: NewMortgage = defaultNewMortgage;
    if(mortgageTransactionObj.new_mortgage_record) {
        sanitizedNewMortgage = sanitizeNewMortgageResponse(mortgageTransactionObj.new_mortgage_record)
    }
    return { ...sanitizeResponse(mortgageTransactionObj, defaultMortgageTransaction), new_mortgage_record: sanitizedNewMortgage };
}

function sanitizeMortgageTransactionsResponse(mortgageList: SimpleMortgageTransactionResponse[]): SimpleMortgageTransaction[] {
    const cleanList: SimpleMortgageTransaction[] = [];
    for(const mortgage of mortgageList) {
        cleanList.push(sanitizeResponse(mortgage, defaultSimpleMortgageTransaction));
    }
    return cleanList;
}

function sanitizeLienResponse(lienObj: LienResponse): Lien {
    const cleanResponse = sanitizeResponse(lienObj, defaultLien);
    if (typeof cleanResponse.registration_date === "string") {
        cleanResponse.registration_date = convertDateStringToDateObject(cleanResponse.registration_date);
    }
    if (typeof cleanResponse.payout_calc_start_date === "string") {
        cleanResponse.payout_calc_start_date = convertDateStringToDateObject(cleanResponse.payout_calc_start_date);
    }
    if (typeof cleanResponse.payout_calc_end_date === "string") {
        cleanResponse.payout_calc_end_date = convertDateStringToDateObject(cleanResponse.payout_calc_end_date);
    }
    if (cleanResponse.property_record?.id) cleanResponse.property_id = cleanResponse.property_record.id;
    cleanResponse.lender_id = cleanResponse.lender_record?.id;
    cleanResponse.government_id = cleanResponse.government_record?.id;
    cleanResponse.other_charge_holder_id = cleanResponse.other_charge_holder_record?.id;
    cleanResponse.charge_holder_company_id = cleanResponse.charge_holder_company_record?.id;
    return cleanResponse;
}

function sanitizePrioritiesAfterClosingResponse(priorityList: PriorityAfterClosingResponseItem[]): PriorityAfterClosingItem[] {
    let cleanPriorityList: PriorityAfterClosingItem[] = [];
    for (const priorityItem of priorityList) {
        cleanPriorityList.push(sanitizeResponse(priorityItem, defaultPriorityAfterClosingItem));
    }
    return cleanPriorityList;
}

function sanitizePrioritiesBeforeClosingResponse(priorityList: PriorityBeforeClosingResponseItem[]): PriorityBeforeClosingItem[] {
    let cleanPriorityList: PriorityBeforeClosingItem[] = [];
    for (const priorityItem of priorityList) {
        cleanPriorityList.push(sanitizeResponse(priorityItem, defaultPriorityBeforeClosingItem));
    }
    return cleanPriorityList;
}

function sanitizeSimpleLienResponse(simpleLienObj: SimpleLienResponse): SimpleLien {
    return sanitizeResponse(simpleLienObj, defaultSimpleLien);
}

function sanitizeUndertakingResponse(undertakingObj: UndertakingResponse): Undertaking {
    const cleanResponse = sanitizeResponse(undertakingObj, defaultUndertaking);
    if (typeof cleanResponse.discharge_date === "string") {
        cleanResponse.discharge_date = convertDateStringToDateObject(cleanResponse.discharge_date);
    }
    return cleanResponse;
}

function sanitizeSimpleLendersResponse(lenderList: SimpleLenderResponse[]): SimpleLender[] {
    const cleanList: SimpleLender[] = [];
    for(const lender of lenderList) {
        cleanList.push(sanitizeResponse(lender, defaultSimpleLender));
    }
    return cleanList;
}

function sanitizeSimpleLenderBranchesResponse(lenderList: SimpleLenderBranchResponse[]): SimpleLenderBranch[] {
    const cleanList: SimpleLenderBranch[] = [];
    for(const lender of lenderList) {
        cleanList.push(sanitizeResponse(lender, defaultSimpleLenderBranch));
    }
    return cleanList;
}

function sanitizeSimpleLenderSourcesResponse(lenderList: SimpleSourceLenderResponse[]): SimpleSourceLender[] {
    const cleanList: SimpleSourceLender[] = [];
    for(const lender of lenderList) {
        cleanList.push(sanitizeResponse(lender, defaultLenderSource));
    }
    return cleanList;
}

function sanitizeGlobalSimpleLenderContactsResponse(contactList: GlobalSimpleLenderContactResponse[]): GlobalSimpleLenderContact[] {
    const cleanList: GlobalSimpleLenderContact[] = [];
    for(const contact of contactList) {
        cleanList.push(sanitizeResponse(contact, defaultGlobalSimpleLenderContact));
    }
    return cleanList;
}

function sanitizeSimpleUndertakingsResponse(undertakingList: SimpleUndertakingResponse[]): SimpleUndertaking[] {
    const cleanUndertakingList: SimpleUndertaking[] = [];
    for (const undertaking of undertakingList) {
        const cleanResponse = sanitizeResponse(undertaking, defaultSimpleUndertaking);
        if (typeof cleanResponse.discharge_date === "string") {
            cleanResponse.discharge_date = convertDateStringToDateObject(cleanResponse.discharge_date);
        }
        cleanUndertakingList.push(cleanResponse);
    }
    return cleanUndertakingList;
}

function sanitizeChargeHolderCompaniesResponse(companyList: SimpleChargeHolderCompanyResponse[]): SimpleChargeHolderCompany[] {
    const cleanList: SimpleChargeHolderCompany[] = [];
    for(const company of companyList) {
        cleanList.push(sanitizeResponse(company, defaultSimpleChargeHolderCompany))
    }
    return cleanList;
}

function sanitizeChargeHolderCompanyResponse(companyObj: ChargeHolderCompanyResponse): ChargeHolderCompany {
    return sanitizeResponse(companyObj, defaultChargeHolderCompany)
}

function sanitizeGovernmentsResponse(governmentList: SimpleGovernmentResponse[]): SimpleGovernment[] {
    const cleanList: SimpleGovernment[] = [];
    for(const government of governmentList) {
        cleanList.push(sanitizeResponse(government, defaultSimpleGovernment))
    }
    return cleanList;
}

function sanitizeChargeHolderCompanyContactResponse(contactObj: ChargeHolderCompanyContactResponse): ChargeHolderCompanyContact {
    return sanitizeResponse(contactObj, defaultChargeHolderCompanyContact);
}

function sanitizeChargeHolderCompanyContactsResponse(contactList: SimpleChargeHolderCompanyContact[]): SimpleChargeHolderCompanyContact[] {
    const cleanList: SimpleChargeHolderCompanyContact[] = [];
    for(const contact of contactList) {
        cleanList.push(sanitizeResponse(contact, defaultSimpleChargeHolderCompanyContact))
    }
    return cleanList;
}

function sanitizeGovernmentResponse(governmentObj: GovernmentResponse): Government {
    return sanitizeResponse(governmentObj, defaultGovernment);
}

function sanitizeGovernmentContactResponse(contactObj: GovernmentContactResponse): GovernmentContact {
    return sanitizeResponse(contactObj, defaultGovernmentContact);
}

function sanitizeGovernmentContactsResponse(contactList: SimpleGovernmentContactResponse[]): SimpleGovernmentContact[] {
    const cleanList: SimpleGovernmentContact[] = [];
    for(const contact of contactList) {
        cleanList.push(sanitizeResponse(contact, defaultSimpleGovernmentContact));
    }
    return cleanList;
}

function sanitizeOtherChargeHoldersResponse(otherChargeHolderList: SimpleOtherChargeHolderResponse[]): SimpleOtherChargeHolder[] {
    const cleanList: SimpleOtherChargeHolder[] = [];
    for (const chargeHolder of otherChargeHolderList) {
        cleanList.push(sanitizeResponse(chargeHolder, defaultSimpleOtherChargeHolder));
    }
    return cleanList;
}

function sanitizeOtherChargeHolderResponse(otherChargeHolderObj: OtherChargeHolderResponse): OtherChargeHolder {
    return sanitizeResponse(otherChargeHolderObj, defaultOtherChargeHolder)
}

function sanitizeFeesResponse(feeList: SimpleFeeResponse[]): SimpleFee[] {
    const cleanList: SimpleFee[] = [];
    for (const fee of feeList) {
        let tempFee = sanitizeResponse(fee, defaultSimpleFee);
        tempFee.discount = tempFee.amount ? tempFee.amount < 0 : false;
        cleanList.push(tempFee);
    }
    return cleanList;
}

function sanitizeFeeResponse(fee: FeeResponse): Fee {
    return sanitizeResponse(fee, defaultFee);
}

function sanitizeDisbursementsResponse(disbursementList: SimpleDisbursementResponse[]): SimpleDisbursement[] {
    const cleanList: SimpleDisbursement[] = [];
    for (const disbursement of disbursementList) {
        cleanList.push(sanitizeResponse(disbursement, defaultSimpleDisbursement));
    }
    return cleanList;
}

function sanitizeDisbursementResponse(disbursement: DisbursementResponse): Disbursement {
    return sanitizeResponse(disbursement, defaultDisbursement);
}

function sanitizeFireInsuranceResponse(fireInsuranceObj: FireInsuranceResponse): FireInsurance {
    const cleanResponse = sanitizeResponse(fireInsuranceObj, defaultFireInsurance);
    if (fireInsuranceObj.property_record?.id) {
        cleanResponse.property_id = fireInsuranceObj.property_record.id
    }
    if (fireInsuranceObj.fire_insurance_broker_record) {
        cleanResponse.fire_insurance_broker_id = fireInsuranceObj.fire_insurance_broker_record.id
    }
    if (fireInsuranceObj.fire_insurance_brokerage_record) {
        cleanResponse.fire_insurance_brokerage_id = fireInsuranceObj.fire_insurance_brokerage_record.id
    }
    if (typeof cleanResponse.expiry_date === "string") {
        cleanResponse.expiry_date = convertDateStringToDateObject(cleanResponse.expiry_date);
    }
    return cleanResponse;
}

function sanitizeFireInsurancesResponse(fireInsuranceList: SimpleFireInsuranceResponse[]): SimpleFireInsurance[] {
    const cleanList: SimpleFireInsurance[] = [];
    for(const insurance of fireInsuranceList) {
        cleanList.push(sanitizeResponse(insurance, defaultSimpleFireInsurance));
    }
    return cleanList;
}

function sanitizeFireInsuranceCompaniesResponse(fireInsuranceCompanyList: SimpleFireInsuranceCompanyResponse[]): SimpleFireInsuranceCompany[] {
    const cleanList: SimpleFireInsuranceCompany[] = [];
    for (const insuranceCompany of fireInsuranceCompanyList) {
        cleanList.push(sanitizeResponse(insuranceCompany, defaultSimpleFireInsuranceCompany));
    }
    return cleanList;
}

function sanitizeFireInsuranceCompanyResponse(fireInsuranceCompanyObj: FireInsuranceCompanyResponse): FireInsuranceCompany {
    return sanitizeResponse(fireInsuranceCompanyObj, defaultFireInsuranceCompany);
}

function sanitizeSimpleFireInsuranceBrokeragesResponse(brokerageList: SimpleFireInsuranceBrokerageResponse[]): SimpleFireInsuranceBrokerage[] {
    const cleanList: SimpleFireInsuranceBrokerage[] = [];
    for(const brokerage of brokerageList) {
        cleanList.push(sanitizeResponse(brokerage, defaultSimpleFireInsuranceBrokerage))
    }
    return cleanList;
}

function sanitizeFireInsuranceBrokerageResponse(brokerageObj: FireInsuranceBrokerageResponse): FireInsuranceBrokerage {
    return sanitizeResponse(brokerageObj, defaultFireInsuranceBrokerage)
}

function sanitizeSimpleFireInsuranceBrokersResponse(brokerList: SimpleFireInsuranceBrokerResponse[]): SimpleFireInsuranceBroker[] {
    const cleanList: SimpleFireInsuranceBroker[] = [];
    for(const broker of brokerList) {
        cleanList.push(sanitizeResponse(broker, defaultSimpleFireInsuranceBroker))
    }
    return cleanList;
}

function sanitizeFireInsuranceBrokerResponse(brokerObj: FireInsuranceBrokerResponse): FireInsuranceBroker {
    return sanitizeResponse(brokerObj, defaultFireInsuranceBroker)
}

function sanitizeAffidavitResponse(affidavitObj: AffidavitResponse): Affidavit {
    return sanitizeResponse(affidavitObj, defaultAffidavit)
}

function sanitizeSimpleAffidavitsResponse(affidavitList: SimpleAffidavitResponse[]): SimpleAffidavit[] {
    const cleanList: SimpleAffidavit[] = [];
    for(const affidavit of affidavitList) {
        cleanList.push(sanitizeResponse(affidavit, defaultSimpleAffidavit))
    }
    return cleanList;
}

function sanitizeTitleInsuranceResponse(titleInsuranceObj: TitleInsuranceResponse): TitleInsurance {
    return sanitizeResponse(titleInsuranceObj, defaultTitleInsurance);
} 

function sanitizeSimpleTitleInsurancePremiumsResponse(premiumList: SimpleTitleInsurancePremiumResponse[]): SimpleTitleInsurancePremium[] {
    const cleanList: SimpleTitleInsurancePremium[] = [];
    for(const premium of premiumList) {
        const cleanPremium = sanitizeResponse(premium, defaultSimpleTitleInsurancePremium)
        if (premium.price && premium.price < 0) {
            cleanPremium.discount = true;
        }
        cleanList.push(cleanPremium)
    }
    return cleanList;
}

function sanitizeDealResponse(response: DealResponse): Deal {
    let tempDealData: Deal = sanitizeResponse(response, defaultDeal);
    let contributingClerks: string[] = [];
    let signingAgents: string[] = [];
    
    tempDealData.staff_job_record?.forEach((staffItem: StaffJobRecord) => {
        switch(staffItem.staff_type) {
            case StaffType.Clerk:
                contributingClerks.push(`${staffItem.first_name} ${staffItem.last_name} (${staffItem.initial})`);
                break;
            case StaffType.SigningAgent: 
                signingAgents.push(`${staffItem.first_name} ${staffItem.last_name} (${staffItem.initial})`);
                break;
        }
    });

    if (typeof tempDealData.closing_date === "string") {
        tempDealData.closing_date = convertDateStringToDateObject(tempDealData.closing_date);
    }
    
    tempDealData.contributing_clerks = contributingClerks.length > 0 ? contributingClerks : undefined;

    if (tempDealData.opened_date) {
        if (typeof tempDealData.opened_date === "string") {
            tempDealData.opened_date = convertDateStringToDateObject(tempDealData.opened_date);
        }
        tempDealData.file_opened = `${DateObjectToDateFormat(tempDealData.opened_date, false)}`;
        tempDealData.opened_by ? tempDealData.file_opened = tempDealData.file_opened + ` by ${tempDealData.opened_by.first_name} ${tempDealData.opened_by.last_name}` : null
    }

    if (tempDealData.last_update) {
        if (typeof tempDealData.last_update === "string") {
            tempDealData.last_update = convertDateStringToDateObject(tempDealData.last_update);
        }
    }

    if (tempDealData.primary_transaction_type) {
        tempDealData.primary_transaction_type = TransactionMappingDbToClean[tempDealData.primary_transaction_type as unknown as keyof typeof TransactionMappingDbToClean]
    }
    
    return tempDealData;
}

function sanitizeStatDecResponse(statDecObj: StatDecResponse): StatDec {
    return sanitizeResponse(statDecObj, defaultStatDec);
}

function sanitizeSimpleStatDecsResponse(statDecList: SimpleStatDecResponse[]): SimpleStatDec[] {
    const cleanList: SimpleStatDec[] = [];
    for(const statDec of statDecList) {
        cleanList.push(sanitizeResponse(statDec, defaultSimpleStatDec));
    }
    return cleanList;
}

function sanitizeDebtResponse(debtObj: DebtResponse): Debt {
    return sanitizeResponse(debtObj, defaultDebt);
}

function sanitizeSimpleDebtResponse(debtList: SimpleDebtResponse[]): SimpleDebt[] {
    const cleanList: SimpleDebt[] = [];
    for(const debt of debtList) {
        cleanList.push(sanitizeResponse(debt, defaultSimpleDebt));
    }
    return cleanList;
}

function sanitizeTrustLedgerResponse(trustLedger: TrustLedgerResponse): TrustLedger {
    return sanitizeResponse(trustLedger, defaultTrustLedger);
}

function sanitizeTrustLedgersResponse(trustLedgerList: SimpleTrustLedgerResponse[]): SimpleTrustLedger[] {
    const cleanList: SimpleTrustLedger[] = [];
    for(const ledger of trustLedgerList) {
        cleanList.push(sanitizeResponse(ledger, defaultSimpleTrustLedger));
    }
    return cleanList;
}

function sanitizeCondoCorporationsResponse(condoCorpList: SimpleCondoCorporationResponse[]): SimpleCondoCorporation[] {
    const cleanList: SimpleCondoCorporation[] = [];
    for(const cc of condoCorpList) {
        cleanList.push(sanitizeResponse(cc, defaultSimpleCondoCorporation));
    }
    return cleanList;
}

function sanitizeCondoCorporationResponse(condoCorp: CondoCorporationResponse): CondoCorporation {
    const cleanObj = sanitizeResponse(condoCorp, defaultCondoCorporation);
    if (cleanObj.property_management_company_record) {
        cleanObj.property_management_company_record_id = cleanObj.property_management_company_record.id;
    }
    return cleanObj;
}

function sanitizePropertyManagementCompaniesResponse(pmcList: SimplePropertyManagementCompanyResponse[]): SimplePropertyManagementCompany[] {
    const cleanList: SimplePropertyManagementCompany[] = [];
    for(const pmc of pmcList) {
        cleanList.push(sanitizeResponse(pmc, defaultSimplePropertyManagementCompany));
    }
    return cleanList;
}

function sanitizePropertyManagementCompanyResponse(pmc: PropertyManagementCompanyResponse): PropertyManagementCompany {
    return sanitizeResponse(pmc, defaultPropertyManagementCompany);
}

function sanitizeSimplePropertyManagementCompanyResponse(pmc: SimplePropertyManagementCompanyResponse): SimplePropertyManagementCompany {
    return sanitizeResponse(pmc, defaultSimplePropertyManagementCompany);
}

function sanitizeConditionResponse(condition: ConditionResponse): Condition {
    let cleanObj = sanitizeResponse(condition, defaultCondition);
    cleanObj.affidavit_id = condition.affidavit?.id;
    cleanObj.debt_id = condition.debt?.id;
    cleanObj.lien_id = condition.lien?.id;
    cleanObj.stat_dec_id = condition.stat_dec?.id;
    cleanObj.undertaking_id = condition.undertaking?.id
    cleanObj.mortgage_id = condition.mortgage?.id
    return cleanObj;
}

function sanitizeSimpleConditionsResponse(conditionList: SimpleConditionResponse[]): SimpleCondition[] {
    const cleanList: SimpleCondition[] = [];
    for(const condition of conditionList) {
        cleanList.push(sanitizeResponse(condition, defaultSimpleCondition));
    }
    return cleanList;
}

function sanitizeDocumentResponse(document: BaseDocumentResponse): Document {
    return sanitizeResponse(document, defaultBaseDocument);
}

function sanitizeTemplateResponse(template: BaseTemplateResponse): Template {
    return sanitizeResponse(template, defaultBaseTemplate);
}

function sanitizeTemplateHtmlResponse(template: BaseTemplateHtmlResponse): BaseTemplateHtml {
    return sanitizeResponse(template, defaultBaseTemplateHtml);
}

function sanitizeGeneratedResponse(generated: GeneratedResponse): Generated {
    return sanitizeResponse(generated, defaultGenerated);
}

function sanitizeUploadedResponse(uploaded: UploadedResponse): Uploaded {
    return sanitizeResponse(uploaded, defaultUploaded);
}

function sanitizePreviewResponse(generated: PreviewResponse): Preview {
    return sanitizeResponse(generated, defaultPreview);
}

function sanitizeSigningAppointmentResponse(signingAppointment: SigningAppointmentResponse): SigningAppointment {
    return sanitizeResponse(signingAppointment, defaultSigningAppointment);
}

function sanitizeSimpleSigningAppointmentResponse(signingAppointmentList: SimpleSigningAppointmentResponse[]): SimpleSigningAppointment[] {
    let cleanList: SimpleSigningAppointment[] = [];
    for(let signingAppointment of signingAppointmentList) {
        let cleanClientList: SimpleClient[] = sanitizeSimpleClientsResponse(signingAppointment.clients ?? []);
        cleanList.push(sanitizeResponse({ ...signingAppointment, clients: cleanClientList }, defaultSimpleSigningAppointment));
    }
    return cleanList;
}

function sanitizeSimpleLegalProfessionalsResponse(professionalList: SimpleLegalProfessionalResponse[]): SimpleLegalProfessional[] {
    const cleanList: SimpleLegalProfessional[] = [];
    for(const professional of professionalList) {
        cleanList.push(sanitizeResponse(professional, defaultSimpleLegalProfessional));
    }
    return cleanList;
}

function sanitizeLegalProfessionalResponse(professional: LegalProfessionalResponse): LegalProfessional {
    return sanitizeResponse(professional, defaultLegalProfessional);
}

function sanitizeSimpleLegalFirmsResponse(firmList: SimpleLegalFirmResponse[]): SimpleLegalFirm[] {
    const cleanList: SimpleLegalFirm[] = [];
    for(const firm of firmList) {
        cleanList.push(sanitizeResponse(firm, defaultSimpleLegalFirm));
    }
    return cleanList;
}

function sanitizeSimpleGlobalLegalFirmsResponse(firmList: SimpleGlobalLegalFirmResponse[]): SimpleGlobalLegalFirm[] {
    const cleanList: SimpleGlobalLegalFirm[] = [];
    for(const firm of firmList) {
        cleanList.push(sanitizeResponse(firm, defaultSimpleGlobalLegalFirm));
    }
    return cleanList;
}

function sanitizeGlobalLegalFirmResponse(firm: GlobalLegalFirmResponse): GlobalLegalFirm {
    return sanitizeResponse(firm, defaultGlobalLegalFirm);
}

function sanitizeLegalFirmResponse(firm: LegalFirmResponse): LegalFirm {
    return sanitizeResponse(firm, defaultLegalFirm);
}

function sanitizeSimpleLegalFirmOfficesResponse(officeList: SimpleLegalFirmOfficeResponse[]): SimpleLegalFirmOffice[] {
    const cleanList: SimpleLegalFirmOffice[] = [];
    for(const office of officeList) {
        cleanList.push(sanitizeResponse(office, defaultSimpleLegalFirmOffice));
    }
    return cleanList;
}

function sanitizeLegalFirmOfficeResponse(firm: LegalFirmOfficeResponse): LegalFirmOffice {
    return sanitizeResponse(firm, defaultLegalFirmOffice);
}

function sanitizeSimpleGlobalLegalFirmOfficesResponse(officeList: SimpleGlobalLegalFirmOfficeResponse[]): SimpleGlobalLegalFirmOffice[] {
    const cleanList: SimpleGlobalLegalFirmOffice[] = [];
    for(const office of officeList) {
        cleanList.push(sanitizeResponse(office, defaultSimpleGlobalLegalFirmOffice));
    }
    return cleanList; 
}

function sanitizeGlobalLegalFirmOfficeResponse(office: GlobalLegalFirmOfficeResponse): GlobalLegalFirmOffice {
    return sanitizeResponse(office, defaultGlobalLegalFirmOffice);
}

function sanitizeDealsResponse(dealsResponse: DealsResponse): Deals {
    const cleanDeals: SimpleDeal[] = [];
    let dealList: Deals = { ...defaultDeals };
    for(const deal of dealsResponse.deals) {
        let simpleDeal: SimpleDeal = sanitizeResponse(deal, defaultSimpleDeal)
        if (deal.closing_date) {
            simpleDeal.closing_date = convertDateStringToDateObject(deal.closing_date)
        }
        if (deal.opened_date) {
            simpleDeal.opened_date = convertDateStringToDateObject(deal.opened_date)
        }
        cleanDeals.push(simpleDeal);
    }
    dealList.deals = cleanDeals;
    dealList.metadata = sanitizeResponse(dealsResponse.metadata, defaultPaginationMetadata)
    return dealList;
}

function sanitizePaymentGroupsResponse(paymentGroupList: PaymentGroupResponse[]): PaymentGroup[] {
    const cleanList: PaymentGroup[] = [];
    for(const paymentGroup of paymentGroupList) {
        cleanList.push(sanitizeResponse(paymentGroup, defaultPaymentGroup));
    }
    return cleanList;
}

function sanitizeRegionsResponse(regions: RegionResponse[]): RegionListItem[] {
    const cleanList: RegionListItem[] = [];
    for (const region of regions) {
        let tempItem = sanitizeResponse(region, defaultRegion);
        cleanList.push({
            ...tempItem,
            label: tempItem.abbreviation
        });
    }
    return cleanList;
}

function sanitizeNotesResponse(noteList: NoteResponse[]): Note[] {
    const cleanList: Note[] = [];
    for(const note of noteList) {
        const cleanNote = sanitizeResponse(note, defaultNote);
        if (cleanNote.author) cleanNote.author = sanitizeResponse(cleanNote.author, defaultDashboardUser);
        cleanList.push(cleanNote);
    }
    return cleanList;
}

function sanitizeDashboardUserResponse(user: DashboardUserResponse): DashboardUser {
    return sanitizeResponse(user, defaultDashboardUser);
}

function sanitizeDashboardUsersResponse(userList: DashboardUserResponse[]): DashboardUser[] {
    const cleanList: DashboardUser[] = [];
    for(const user of userList) {
        const cleanUser = sanitizeResponse(user, defaultDashboardUser);
        cleanList.push(cleanUser);
    }
    return cleanList;
}

export {
    sanitizeResponse,
    sanitizeClientResponse,
    sanitizeSimpleClientsResponse,
    sanitizePropertyResponse,
    sanitizeSimplePropertiesResponse,
    sanitizeLenderResponse,
    sanitizeLenderBranchResponse,
    sanitizeLenderContactResponse,
    sanitizeGlobalLenderContactResponse,
    sanitizeMortgageBrokerResponse,
    sanitizeMortgageBrokerageResponse,
    sanitizeMortgageBrokerageOtherContactResponse,
    sanitizeNewMortgageResponse,
    sanitizeLienResponse,
    sanitizeMortgageTransactionResponse,
    sanitizeMortgageTransactionsResponse,
    sanitizePrioritiesAfterClosingResponse,
    sanitizePrioritiesBeforeClosingResponse,
    sanitizeSimpleLienResponse,
    sanitizeUndertakingResponse,
    sanitizeSimpleLendersResponse,
    sanitizeSimpleLenderBranchesResponse,
    sanitizeSimpleLenderSourcesResponse,
    sanitizeGlobalSimpleLenderContactsResponse,
    sanitizeSimpleUndertakingsResponse,
    sanitizeChargeHolderCompaniesResponse,
    sanitizeChargeHolderCompanyResponse,
    sanitizeGovernmentResponse,
    sanitizeGovernmentsResponse,
    sanitizeChargeHolderCompanyContactResponse,
    sanitizeChargeHolderCompanyContactsResponse,
    sanitizeGovernmentContactResponse,
    sanitizeGovernmentContactsResponse,
    sanitizeOtherChargeHoldersResponse,
    sanitizeOtherChargeHolderResponse,
    sanitizeFeesResponse,
    sanitizeFeeResponse,
    sanitizeDisbursementsResponse,
    sanitizeDisbursementResponse,
    sanitizeFireInsuranceResponse,
    sanitizeFireInsurancesResponse,
    sanitizeFireInsuranceCompaniesResponse,
    sanitizeFireInsuranceCompanyResponse,
    sanitizeSimpleFireInsuranceBrokeragesResponse,
    sanitizeFireInsuranceBrokerageResponse,
    sanitizeFireInsuranceBrokerResponse,
    sanitizeSimpleFireInsuranceBrokersResponse,
    sanitizeAffidavitResponse,
    sanitizeSimpleAffidavitsResponse,
    sanitizeTitleInsuranceResponse,
    sanitizeSimpleTitleInsurancePremiumsResponse,
    sanitizeDealResponse,
    sanitizeSimpleStatDecsResponse,
    sanitizeStatDecResponse,
    sanitizeDebtResponse,
    sanitizeSimpleDebtResponse,
    sanitizeTrustLedgerResponse,
    sanitizeTrustLedgersResponse,
    sanitizeCondoCorporationsResponse,
    sanitizeCondoCorporationResponse,
    sanitizePropertyManagementCompaniesResponse,
    sanitizePropertyManagementCompanyResponse,
    sanitizeSimplePropertyManagementCompanyResponse,
    sanitizeConditionResponse,
    sanitizeSimpleConditionsResponse,
    sanitizeDocumentResponse,
    sanitizeTemplateResponse,
    sanitizeUploadedResponse,
    sanitizeTemplateHtmlResponse,
    sanitizeGeneratedResponse,
    sanitizePreviewResponse,
    sanitizeSigningAppointmentResponse,
    sanitizeSimpleSigningAppointmentResponse,
    sanitizeSimpleLegalProfessionalsResponse,
    sanitizeLegalProfessionalResponse,
    sanitizeSimpleLegalFirmsResponse,
    sanitizeLegalFirmResponse,
    sanitizeSimpleLegalFirmOfficesResponse,
    sanitizeLegalFirmOfficeResponse,
    sanitizeGlobalLegalFirmOfficeResponse,
    sanitizeSimpleGlobalLegalFirmOfficesResponse,
    sanitizeDealsResponse,
    sanitizePaymentGroupsResponse,
    sanitizeRegionsResponse,
    sanitizeNotesResponse,
    sanitizeDashboardUserResponse,
    sanitizeSimpleGlobalLegalFirmsResponse,
    sanitizeGlobalLegalFirmResponse,
    sanitizeDashboardUsersResponse
};