import { forwardRef, useContext, useEffect, useRef, useState } from "react";

// Importing MUI components
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

// Drag and drop plugin
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Importing application components
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import theme from '../../../../../components/Common/componentStyling/Theme';
import color from '../../../../../components/Common/componentStyling/Colors';
import { ChevronDown, ChevronUp, DragHandleIcon } from "../../../../../components/Common/Icons/Iconography";
import { SrcTextIcon } from "../../../../../components/Common/Icons/Iconography";
import Label from "../../../../../components/Common/Label/Label";
import { BORDER_1, BORDER_2, FOCUS_BOX_SHADOW } from "../../../../../components/Common/componentStyling/Styles";
import { PriorityAfterClosingItem } from "../../../../libs/types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosing";
import { addOrdinal } from "../../../../libs/utils/formatValues";
import { isEmpty } from "lodash";
import { UniversalSurveyContext } from "../../../../context/UniversalSurvey/context";
import { getPrioritiesAfterClosingInDeal, getPrioritiesBeforeClosingInDeal, updatePrioritiesAfterClosing, updatePrioritiesBeforeClosing } from "../../../../context/UniversalSurvey/asyncActions/priorities";
import { Sections } from "../../../../libs/resources/enums/sections";
import useOutsideClick from "../../../../libs/hooks/useOutsideClick";
import { updateMortgageTabPriority } from "../../../../context/UniversalSurvey/asyncActions/newMortgages";
import { updateExistingLienTabPriority } from "../../../../context/UniversalSurvey/asyncActions/existingLiens";
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import { PriorityBeforeClosingItem } from "conveyance/libs/types/UniversalSurvey/PriorityBeforeClosing/priorityBeforeClosing";

type DeededInputProps = {
    value: number;
    autoFilled?: boolean;
    label?: string;
    type: "before" | "after"
};

type PlaceholderProps = {
    clientY?: number;
    clientX?: number;
}
  
const PriorityPicker = forwardRef<HTMLDivElement, DeededInputProps>(
    function Picker(props, ref) {
        const {
            value,
            autoFilled,
            label,
            type
        } = props;

        const queryAttr = "data-rbd-drag-handle-draggable-id";
        const pickerRef = useRef(null);
        const [state, dispatch] = useContext(UniversalSurveyContext);
        const [pickerOpen, setPickerOpen] = useState<boolean>(false);
        const [remainingCells, setRemainingCells] = useState<number>(4);
        const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>({});
        useOutsideClick(pickerRef, setPickerOpen);

        const reorderPrioritiesAfterClosing = (priorityList: PriorityAfterClosingItem[], startIndex: number, endIndex: number) => {
            const result = Array.from(priorityList);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };

        const reorderPrioritiesBeforeClosing = (priorityList: PriorityBeforeClosingItem[], startIndex: number, endIndex: number) => {
            const result = Array.from(priorityList);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        
        useEffect(() => {
            if (type === "after") {
                if (state.priorities.priorityAfterClosingList.length >= 4) {
                    setRemainingCells(0);
                } else {
                    setRemainingCells(4 - state.priorities.priorityAfterClosingList.length);
                }
            }
        }, [state.priorities.priorityAfterClosingList]);

        useEffect(() => {
            if (type === "before") {
                if (state.priorities.priorityBeforeClosingList.length >= 4) {
                    setRemainingCells(0);
                } else {
                    setRemainingCells(4 - state.priorities.priorityBeforeClosingList.length);
                }
            }
        }, [state.priorities.priorityBeforeClosingList]);

        useEffect(() => {
            if (pickerOpen) {
                if (type === "after") getPrioritiesAfterClosingInDeal(dispatch, String(state.deal.dealInfo?.id));
                else getPrioritiesBeforeClosingInDeal(dispatch, String(state.deal.dealInfo?.id));
            }
        }, [pickerOpen]);

        function assignPriorityAfterClosing(priorityList: PriorityAfterClosingItem[]) {
            switch(state.dataSheet.currSection) {
                case Sections.NewMortgage:
                    priorityList.filter((item, index) => {
                        updateMortgageTabPriority(dispatch, item.new_mortgage_record?.id!, index + 1);
                        if (item.new_mortgage_record?.id === state.dataSheet.currEntity) {
                            dispatch({ type: actions.SET_MORTGAGE_PRIORITY, payload: index + 1 });
                        }
                    })
                    break;
                case Sections.Encumbrances:
                    priorityList.filter((item, index) => {
                        updateExistingLienTabPriority(dispatch, item.record_id, index + 1);
                        if (item.lien_record?.id === state.dataSheet.currEntity) {
                            dispatch({ type: actions.SET_LIEN_PRIORITY_AFTER_CLOSING, payload: index + 1 });
                        }
                    })
                    break;
            }
        }

        function assignPriorityBeforeClosing(priorityList: PriorityBeforeClosingItem[]) {
            for (let i = 0; i < priorityList.length; i++) {
                if (priorityList[i].lien_record?.id === state.dataSheet.currEntity) {
                    dispatch({ type: actions.SET_LIEN_PRIORITY_BEFORE_CLOSING, payload: i + 1 });
                }
            }
        }

        function onDragEnd(result: any) {
            if (!result.destination) {
                return;
            }
            setPlaceholderProps({});
            if (type === "after") {
                const priorityList = state.priorities.priorityAfterClosingList.find((priority) => priority.record_id === state.priorities.newPriorityAfterClosing?.record_id && priority.type === state.priorities.newPriorityAfterClosing?.type) ? state.priorities.priorityAfterClosingList : state.priorities.newPriorityAfterClosing ? [...state.priorities.priorityAfterClosingList, state.priorities.newPriorityAfterClosing!] : state.priorities.priorityAfterClosingList;
                let reorderedPriorityList = reorderPrioritiesAfterClosing(priorityList, result.source.index, result.destination.index);
                updatePrioritiesAfterClosing(dispatch, String(state.deal.dealInfo?.id), reorderedPriorityList);
                assignPriorityAfterClosing(reorderedPriorityList);
            } else {
                const priorityList = state.priorities.priorityBeforeClosingList;
                let reorderedPriorityList = reorderPrioritiesBeforeClosing(priorityList, result.source.index, result.destination.index);
                updatePrioritiesBeforeClosing(dispatch, String(state.deal.dealInfo?.id), reorderedPriorityList);
                assignPriorityBeforeClosing(reorderedPriorityList);
            }
        }

        const handleDragStart = (event: any) => {
            const draggedDOM = getDraggedDom(event.draggableId);
        
            if (!draggedDOM || !draggedDOM.parentNode) {
                return;
            }
        
            const sourceIndex = event.source.index;
            var clientY =
                //@ts-ignore
                parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                //@ts-ignore
                [...draggedDOM.parentNode.children]
                    .slice(0, sourceIndex)
                    .reduce((total, curr) => {
                        const style = curr.currentStyle || window.getComputedStyle(curr);
                        const marginBottom = parseFloat(style.marginBottom);
                        return total + curr.clientHeight + marginBottom;
                    }, 0);
        
            setPlaceholderProps({
                clientY,
                clientX: parseFloat(
                    //@ts-ignore
                    window.getComputedStyle(draggedDOM.parentNode).paddingLeft
                )
            });
        };

        const handleDragUpdate = (event: any) => {
            if (!event.destination) {
                return;
            }
        
            const draggedDOM = getDraggedDom(event.draggableId);
        
            if (!draggedDOM || !draggedDOM.parentNode) {
                return;
            }
        
            const destinationIndex = event.destination.index;
            const sourceIndex = event.source.index;
        
            //@ts-ignore
            const childrenArray = [...draggedDOM.parentNode.children];
            const movedItem = childrenArray[sourceIndex];
            childrenArray.splice(sourceIndex, 1);
        
            const updatedArray = [
                ...childrenArray.slice(0, destinationIndex),
                movedItem,
                ...childrenArray.slice(destinationIndex + 1)
            ];
        
            var clientY =
                //@ts-ignore
                parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);
        
            setPlaceholderProps({
                clientY,
                clientX: parseFloat(
                    //@ts-ignore
                    window.getComputedStyle(draggedDOM.parentNode).paddingLeft
                )
            });
        };

        const getDraggedDom = (draggableId: string) => {
            const domQuery = `[${queryAttr}='${draggableId}']`;
            const draggedDOM = document.querySelector(domQuery);
            return draggedDOM;
        };

        return (
            <div ref={pickerRef}>
                {label && (
                    <Label text={label} onClick={setPickerOpen} open={pickerOpen} />
                )}
                <BasicTextInput
                    value={addOrdinal(value!)}
                    variant="standard"
                    fullWidth
                    autoFilled={autoFilled}
                    onFocusCapture={() => setPickerOpen(true)}
                    onBlurCapture={() => setPickerOpen(false)}
                    sx={{
                        caretColor: "transparent"
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconContainer direction="row" gap={1}>
                                {autoFilled && (
                                    <SrcTextIcon color={theme.INPUT} />
                                )}
                                {pickerOpen ? (
                                    <ChevronUp color={theme.INPUT} />
                                ) : (
                                    <ChevronDown color={theme.INPUT} />
                                )}
                            </IconContainer>
                        ),
                    }}
                    ref={ref}
                />
                {pickerOpen && (
                    <DragDropContext
                        onDragEnd={onDragEnd}
                        onDragStart={handleDragStart}
                        onDragUpdate={handleDragUpdate}
                    >
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <PickerContainer
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {(type === "after" ?
                                    (state.priorities.priorityAfterClosingList.find((priority) => priority.record_id === state.priorities.newPriorityAfterClosing?.record_id && priority.type === state.priorities.newPriorityAfterClosing?.type) ? state.priorities.priorityAfterClosingList : state.priorities.newPriorityAfterClosing ? [...state.priorities.priorityAfterClosingList, state.priorities.newPriorityAfterClosing!] : state.priorities.priorityAfterClosingList) :
                                    state.priorities.priorityBeforeClosingList)
                                    .map((item: PriorityAfterClosingItem | PriorityBeforeClosingItem, index) => (
                                        // @ts-ignore
                                        <Draggable key={type === "after" ? `${item.record_id}-${item.type}` : `${item.lien_record?.id}`} draggableId={type === "after" ? `${item.record_id}-${item.type}` : `${item.lien_record?.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <PickerItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    isDragging={snapshot.isDragging}
                                                >
                                                    <IconContainer direction="row" gap={1} alignItems="center">
                                                        <DragHandleIcon color={color.BLACK} />
                                                        {item.label}
                                                    </IconContainer>
                                                </PickerItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                                        <PlaceholderPickerItem
                                            className="placeholder"
                                            style={{
                                                position: "absolute",
                                                top: placeholderProps.clientY,
                                                left: placeholderProps.clientX,
                                            }}
                                        />
                                    )}
                                    {Array.from(Array(remainingCells), (_, i) => (
                                        <EmptyPickerItem key={i}></EmptyPickerItem>
                                    ))}
                                </PickerContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>
        )
    }
);

const PickerContainer = styled('div')({
    width: "46rem",
    minHeight: "29.8rem",
    padding: "1rem 1rem 0rem 1rem",
    flexDirection: "column",
    boxShadow: "0px 0px 0.4rem rgba(0, 0, 0, 0.25), 0px 0px 4rem rgba(0, 0, 0, 0.25)",
    backgroundColor: color.GRAY_50,
    position: "absolute",
    zIndex: 1
});

const PickerItem = styled('div')<{
    isDragging?: boolean;
}>(({ isDragging }) => ({
    width: "44rem",
    height: "6.2rem",
    backgroundColor: isDragging ? color.RED_300 : color.GRAY_50,
    border: BORDER_1(isDragging ? color.RED_100 : color.GRAY_300),
    marginBottom: "1rem",
    borderRadius: "0.6rem",
    padding: "1.9rem 1rem",
    ':hover': {
        backgroundColor: color.GRAY_300
    },
    ':focus-within': {
        outline: "none",
        border: BORDER_2(color.BLUE_500),
        boxShadow: FOCUS_BOX_SHADOW,
        borderRadius: "0.6rem",  
    }
}));

const EmptyPickerItem = styled('div')({
    width: "44rem",
    height: "6.2rem",
    backgroundColor: color.GRAY_100,
    boxShadow: "inset 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.25)",
    marginBottom: "1rem",
    borderRadius: "0.6rem",
});

const PlaceholderPickerItem = styled('div')({
    backgroundColor: color.RED_50,
    width: "44rem",
    height: "6.2rem",
    border: `0.2rem dashed ${theme.PRIMARY}`,
    borderRadius: "0.6rem",
})

const IconContainer = styled(Stack)({});

export default PriorityPicker;