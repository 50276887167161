import { useEffect, useState, useContext } from "react";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import * as _ from "lodash";
import { LegalFirmOffice, LegalFirmOfficeErrorState } from "../../../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { defaultLegalFirmOffice } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirmOffice";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { AdminLoading, Loading } from "../../../../../libs/resources/enums/loading";
import { defaultLegalFirmOfficeErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLegalFirmOfficeErrorState";
import LegalFirmOfficeForm from "./LegalFirmOfficeForm";
import { AdminContext } from "../../../../../context/AdminPanel/context";
import { transformGlobalLegalFirmOfficeToLegalFirmOffice } from "../../../../../libs/types/UniversalSurvey/utils/transformGlobals";
import { saveLegalFirmOffice, submitNewLegalFirmOffice } from "../../../../../context/AdminPanel/asyncActions/legalFirmOffices";
import { AdminActionTypes } from "../../../../../context/AdminPanel/actionTypes";

type Props = Omit<ModalProps, "children">

export default function AdminLegalFirmOfficeModal(props: Props) {
    const [state, dispatch] = useContext(AdminContext);

    const [officeObj, setOfficeObj] = useState<LegalFirmOffice>({ ...defaultLegalFirmOffice });
    const [originalObj, setOriginalObj] = useState<LegalFirmOffice>({ ...defaultLegalFirmOffice });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<LegalFirmOfficeErrorState>({ ...defaultLegalFirmOfficeErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.legalFirmOffices.legalFirmOfficeBeingEdited) {
            let legalFirmOffice: LegalFirmOffice = transformGlobalLegalFirmOfficeToLegalFirmOffice(state.legalFirmOffices.legalFirmOfficeBeingEdited)
            setOfficeObj(legalFirmOffice);
            setOriginalObj(legalFirmOffice);
        }
    }, [state.legalFirmOffices.legalFirmOfficeBeingEdited]);

    function submit() {
        if (state.legalFirmOffices.legalFirmOfficeBeingEdited) {
            saveLegalFirmOffice(dispatch, officeObj);
        } else {
            if (state.legalFirms.selectedLegalFirm) submitNewLegalFirmOffice(dispatch, state.legalFirms.selectedLegalFirm.id, officeObj);
        }
    }

    function discard() {
        dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_BEING_EDITED, payload: undefined });
        setOfficeObj({ ...defaultLegalFirmOffice });
        setOriginalObj({ ...defaultLegalFirmOffice });
        setErrorState({ ...defaultLegalFirmOfficeErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(officeObj, originalObj)) {
            discard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function isSaveDisabled() {
        if (_.isEqual(officeObj, originalObj)) return true;
        if (!officeObj.general_address.street_address || !officeObj.general_address.city || !officeObj.general_address.province_enum || !officeObj.general_address.postal_code) return true;
        if (validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, officeObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            size={props.size}
            title={`${state.legalFirmOffices.legalFirmOfficeBeingEdited ? "" : "New "}Law Firm Office`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.legalFirmOffices.legalFirmOfficeBeingEdited ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.LegalFirmOfficeBeingEdited])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.loading.objectsLoading, [Loading.SaveLegalFirmOffice])}
        >
            <LegalFirmOfficeForm
                officeObj={officeObj}
                setOfficeObj={setOfficeObj}
                errorState={errorState}
                setErrorState={setErrorState}
                discard={discard}
                confirmDiscardOpen={confirmDiscardOpen}
                setConfirmDiscardOpen={setConfirmDiscardOpen}
            />
        </ModalBase>
    );
}