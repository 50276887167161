import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { StatementOfAccountContextRequest } from "../documents/statementOfAccountDocument";


function createStatementOfAccountName(): string {
    return "Statement of Account";
}

function isStatementOfAccountDocInDeal(docList: Document[]): boolean {
    const matchingDoc = docList.find((doc) => doc.type === DocumentType.StatementOfAccount);
    return matchingDoc ? true : false
}

function createStatementOfAccountContextRequest(): StatementOfAccountContextRequest {
    return {};
}

function getStatementOfAccountDocData(): [Object, string] {
    return [createStatementOfAccountContextRequest(), createStatementOfAccountName()];
}

export {
    isStatementOfAccountDocInDeal,
    getStatementOfAccountDocData
}