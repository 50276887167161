import { createContext, Dispatch, useReducer } from "react";
import * as actions from "./actionTypes";
import { AlertsState, defaultAlertsState, alertsReducer } from "./reducers/alerts"
import { DealState, defaultDealState, dealReducer } from "./reducers/deal"
import { DocumentsState, defaultDocumentsState, documentsReducer } from "./reducers/documents";
import { TransactionsState, defaultTransactionsState, transactionsReducer } from "./reducers/transactions";
import { RolesState, defaultRolesState, rolesReducer } from "./reducers/roles";
import { ClientsState, defaultClientsState, clientsReducer } from "./reducers/clients";
import { PropertiesState, defaultPropertiesState, propertiesReducer } from "./reducers/properties";
import { ExistingLiensState, defaultExistingLiensState, existingLiensReducer } from "./reducers/existingLiens";
import { NewMortgagesState, defaultNewMortgagesState, newMortgagesReducer } from "./reducers/newMortgages";
import { GuarantorsState, defaultGuarantorsState, guarantorsReducer } from "./reducers/guarantors";
import { LendersState, defaultLendersState, lendersReducer } from "./reducers/lenders";
import { LenderContactsState, defaultLenderContactsState, lenderContactsReducer } from "./reducers/lenderContacts";
import { defaultMortgageBrokerageState, mortgageBrokerageReducer, MortgageBrokerageState } from "./reducers/mortgageBrokerages";
import { defaultMortgageBrokerState, mortgageBrokerReducer, MortgageBrokerState } from "./reducers/mortgageBrokers";
import { defaultMortgageBrokerageOtherContactsState, mortgageBrokerageOtherContactsReducer, MortgageBrokerageOtherContactsState } from "./reducers/mortgageBrokerageOtherContacts";
import { defaultPriorityState, PrioritiesState, priorityReducer } from "./reducers/priorities";
import { defaultUndertakingsState, undertakingsReducer, UndertakingsState } from "./reducers/undertakings";
import { defaultChargeHolderCompaniesState, chargeHolderCompaniesReducer, ChargeHolderCompaniesState } from "./reducers/chargeHolderCompanies";
import { defaultGovernmentsState, governmentsReducer, GovernmentsState } from "./reducers/governments";
import { defaultChargeHolderCompanyContactsState, chargeHolderCompanyContactsReducer, ChargeHolderCompanyContactsState } from "./reducers/chargeHolderCompanyContacts";
import { defaultGovernmentContactsState, governmentContactsReducer, GovernmentContactsState } from "./reducers/governmentContacts";
import { defaultOtherChargeHoldersState, otherChargeHoldersReducer, OtherChargeHoldersState } from "./reducers/otherChargeHolders";
import { dataSheetReducer, DataSheetState, defaultDataSheetState } from "./reducers/dataSheet";
import { defaultFeesState, feesReducer, FeesState } from "./reducers/fees";
import { defaultDisbursementsState, disbursementsReducer, DisbursementsState } from "./reducers/disbursements";
import { defaultFireInsuranceState, fireInsuranceReducer, FireInsuranceState } from "./reducers/fireInsurance";
import { defaultFireInsuranceCompaniesState, fireInsuranceCompaniesReducer, FireInsuranceCompaniesState } from "./reducers/fireInsuranceCompanies";
import { defaultFireInsuranceBrokeragesState, fireInsuranceBrokeragesReducer, FireInsuranceBrokeragesState } from "./reducers/fireInsuranceBrokerages";
import { defaultFireInsuranceBrokerState, fireInsuranceBrokerReducer, FireInsuranceBrokerState } from "./reducers/fireInsuranceBrokers";
import { affidavitsReducer, AffidavitsState, defaultAffidavitsState } from "./reducers/affidavits";
import { defaultTitleInsuranceState, titleInsuranceReducer, TitleInsuranceState } from "./reducers/titleInsurance";
import { defaultTitleInsurancePremiumsState, titleInsurancePremiumsReducer, TitleInsurancePremiumsState } from "./reducers/titleInsurancePremiums";
import { defaultStatutoryDeclarationsState, statutoryDeclarationsReducer, StatutoryDeclarationsState } from "./reducers/statutoryDeclarations";
import { debtsReducer, DebtsState, defaultDebtsState } from "./reducers/debts";
import { defaultTrustLedgersState, trustLedgersReducer, TrustLedgersState } from "./reducers/trustLedgers";
import { defaultCondoCorporationsState, condoCorporationsReducer, CondoCorporationsState } from "./reducers/condoCorporations";
import { defaultPropertyManagementCompaniesState, propertyManagementCompaniesReducer, PropertyManagementCompaniesState } from "./reducers/propertyManagementCompanies";
import { conditionsReducer, ConditionsState, defaultConditionsState } from "./reducers/conditions";
import { defaultSigningAppointmentsState, signingAppointmentsReducer, SigningAppointmentsState } from "./reducers/signingAppointments";
import { legalProfessionalsReducer, LegalProfessionalsState, defaultLegalProfessionalsState } from "./reducers/legalProfessionals";
import { legalFirmsReducer, LegalFirmsState, defaultLegalFirmsState } from "./reducers/legalFirms";
import { legalFirmOfficesReducer, LegalFirmOfficesState, defaultLegalFirmOfficesState } from "./reducers/legalFirmOffices";
import { defaultTrustLedgerPaymentGroupsState, trustLedgerPaymentGroupsReducer, TrustLedgerPaymentGroupsState } from "./reducers/paymentGroups";
import { defaultRegionState, regionReducer, RegionState } from "./reducers/regions";
import { defaultNotesState, notesReducer, NotesState } from "./reducers/notes";
import { defaultUserState, userReducer, UserState } from "./reducers/user";

export type State = {
    alerts: AlertsState;
    deal: DealState;
    documents: DocumentsState;
    dataSheet: DataSheetState;
    transactions: TransactionsState;
    roles: RolesState;
    clients: ClientsState;
    properties: PropertiesState;
    existingLiens: ExistingLiensState;
    newMortgages: NewMortgagesState;
    guarantors: GuarantorsState;
    lenders: LendersState;
    lenderContacts: LenderContactsState;
    mortgageBrokerages: MortgageBrokerageState;
    mortgageBrokers: MortgageBrokerState;
    mortgageBrokerageOtherContacts: MortgageBrokerageOtherContactsState;
    priorities: PrioritiesState;
    undertakings: UndertakingsState;
    chargeHolderCompanies: ChargeHolderCompaniesState;
    governments: GovernmentsState;
    chargeHolderCompanyContacts: ChargeHolderCompanyContactsState;
    governmentContacts: GovernmentContactsState;
    otherChargeHolders: OtherChargeHoldersState;
    fees: FeesState;
    disbursements: DisbursementsState;
    fireInsurance: FireInsuranceState;
    fireInsuranceCompanies: FireInsuranceCompaniesState;
    fireInsuranceBrokerages: FireInsuranceBrokeragesState;
    fireInsuranceBrokers: FireInsuranceBrokerState;
    affidavits: AffidavitsState;
    titleInsurance: TitleInsuranceState;
    titleInsurancePremiums: TitleInsurancePremiumsState;
    statutoryDeclarations: StatutoryDeclarationsState;
    debts: DebtsState;
    trustLedgers: TrustLedgersState;
    condoCorporations: CondoCorporationsState;
    propertyManagementCompanies: PropertyManagementCompaniesState;
    conditions: ConditionsState;
    signingAppointments: SigningAppointmentsState;
    legalProfessionals: LegalProfessionalsState;
    legalFirms: LegalFirmsState;
    legalFirmOffices: LegalFirmOfficesState;
    paymentGroups: TrustLedgerPaymentGroupsState;
    regions: RegionState;
    notes: NotesState;
    user: UserState;
}

export const defaultState: State = {
    alerts: defaultAlertsState,
    deal: defaultDealState,
    documents: defaultDocumentsState,
    dataSheet: defaultDataSheetState,
    transactions: defaultTransactionsState,
    roles: defaultRolesState,
    clients: defaultClientsState,
    properties: defaultPropertiesState,
    existingLiens: defaultExistingLiensState,
    newMortgages: defaultNewMortgagesState,
    guarantors: defaultGuarantorsState,
    lenders: defaultLendersState,
    lenderContacts: defaultLenderContactsState,
    mortgageBrokerages: defaultMortgageBrokerageState,
    mortgageBrokers: defaultMortgageBrokerState,
    mortgageBrokerageOtherContacts: defaultMortgageBrokerageOtherContactsState,
    priorities: defaultPriorityState,
    undertakings: defaultUndertakingsState,
    chargeHolderCompanies: defaultChargeHolderCompaniesState,
    governments: defaultGovernmentsState,
    chargeHolderCompanyContacts: defaultChargeHolderCompanyContactsState,
    governmentContacts: defaultGovernmentContactsState,
    otherChargeHolders: defaultOtherChargeHoldersState,
    fees: defaultFeesState,
    disbursements: defaultDisbursementsState,
    fireInsurance: defaultFireInsuranceState,
    fireInsuranceCompanies: defaultFireInsuranceCompaniesState,
    fireInsuranceBrokerages: defaultFireInsuranceBrokeragesState,
    fireInsuranceBrokers: defaultFireInsuranceBrokerState,
    affidavits: defaultAffidavitsState,
    titleInsurance: defaultTitleInsuranceState,
    titleInsurancePremiums: defaultTitleInsurancePremiumsState,
    statutoryDeclarations: defaultStatutoryDeclarationsState,
    debts: defaultDebtsState,
    trustLedgers: defaultTrustLedgersState,
    condoCorporations: defaultCondoCorporationsState,
    propertyManagementCompanies: defaultPropertyManagementCompaniesState,
    conditions: defaultConditionsState,
    signingAppointments: defaultSigningAppointmentsState,
    legalProfessionals: defaultLegalProfessionalsState,
    legalFirms: defaultLegalFirmsState,
    legalFirmOffices: defaultLegalFirmOfficesState,
    paymentGroups: defaultTrustLedgerPaymentGroupsState,
    regions: defaultRegionState,
    notes: defaultNotesState,
    user: defaultUserState
}

export const UniversalSurveyContext = createContext([defaultState, () => {}] as [State, Dispatch<Record<string, any>>]);

const reducer = (state: State, action: Record<string, any>): State => {
    const prefix = action.type.slice(0, action.type.indexOf("-"));
    switch (prefix) {
        case actions.ALERTS_PREFIX:
            return {
                ...state,
                alerts: alertsReducer(state.alerts, action)
            };
        case actions.DEAL_PREFIX:
            return {
                ...state,
                deal: dealReducer(state.deal, action)
            };
        case actions.DOCUMENTS_PREFIX:
            return {
                ...state,
                documents: documentsReducer(state.documents, action)
            };
        case actions.DATA_SHEET_PREFIX:
            return {
                ...state,
                dataSheet: dataSheetReducer(state.dataSheet, action)
            };
        case actions.TRANSACTIONS_PREFIX:
            return {
                ...state,
                transactions: transactionsReducer(state.transactions, action)
            };
        case actions.ROLES_PREFIX:
            return {
                ...state,
                roles: rolesReducer(state.roles, action)
            };
        case actions.CLIENTS_PREFIX:
            return {
                ...state,
                clients: clientsReducer(state.clients, action)
            };
        case actions.PROPERTIES_PREFIX:
            return {
                ...state,
                properties: propertiesReducer(state.properties, action)
            };
        case actions.EXISTING_LIENS_PREFIX:
            return {
                ...state,
                existingLiens: existingLiensReducer(state.existingLiens, action)
            };
        case actions.NEW_MORTGAGES_PREFIX:
            return {
                ...state,
                newMortgages: newMortgagesReducer(state.newMortgages, action)
            };
        case actions.GUARANTORS_PREFIX:
            return {
                ...state,
                guarantors: guarantorsReducer(state.guarantors, action)
            };
        case actions.LENDERS_PREFIX:
            return {
                ...state,
                lenders: lendersReducer(state.lenders, action)
            };
        case actions.LENDER_CONTACTS_PREFIX:
            return {
                ...state,
                lenderContacts: lenderContactsReducer(state.lenderContacts, action)
            };
        case actions.MORTGAGE_BROKERAGES_PREFIX:
            return {
                ...state,
                mortgageBrokerages: mortgageBrokerageReducer(state.mortgageBrokerages, action)
            };
        case actions.MORTGAGE_BROKERS_PREFIX:
            return {
                ...state,
                mortgageBrokers: mortgageBrokerReducer(state.mortgageBrokers, action)
            };
        case actions.MORTGAGE_BROKERAGE_OTHER_CONTACTS_PREFIX:
            return {
                ...state,
                mortgageBrokerageOtherContacts: mortgageBrokerageOtherContactsReducer(state.mortgageBrokerageOtherContacts, action)
            };
        case actions.PRIORITIES_PREFIX:
            return {
                ...state,
                priorities: priorityReducer(state.priorities, action)
            };
        case actions.UNDERTAKINGS_PREFIX:
            return {
                ...state,
                undertakings: undertakingsReducer(state.undertakings, action)
            };
        case actions.CHARGE_HOLDER_COMPANIES_PREFIX:
            return {
                ...state,
                chargeHolderCompanies: chargeHolderCompaniesReducer(state.chargeHolderCompanies, action)
            };
        case actions.GOVERNMENTS_PREFIX:
            return {
                ...state,
                governments: governmentsReducer(state.governments, action)
            };
        case actions.CHARGE_HOLDER_COMPANY_CONTACTS_PREFIX:
            return {
                ...state,
                chargeHolderCompanyContacts: chargeHolderCompanyContactsReducer(state.chargeHolderCompanyContacts, action)
            };
        case actions.GOVERNMENT_CONTACTS_PREFIX:
            return {
                ...state,
                governmentContacts: governmentContactsReducer(state.governmentContacts, action)
            };
        case actions.OTHER_CHARGE_HOLDER_PREFIX:
            return {
                ...state,
                otherChargeHolders: otherChargeHoldersReducer(state.otherChargeHolders, action)
            };
        case actions.FEES_PREFIX:
            return {
                ...state,
                fees: feesReducer(state.fees, action)
            };
        case actions.DISBURSEMENTS_PREFIX:
            return {
                ...state,
                disbursements: disbursementsReducer(state.disbursements, action)
            };
        case actions.FIRE_INSURANCE_PREFIX:
            return {
                ...state,
                fireInsurance: fireInsuranceReducer(state.fireInsurance, action)
            };
        case actions.FIRE_INSURANCE_COMPANIES_PREFIX:
            return {
                ...state,
                fireInsuranceCompanies: fireInsuranceCompaniesReducer(state.fireInsuranceCompanies, action)
            };
        case actions.FIRE_INSURANCE_BROKERAGES_PREFIX:
            return {
                ...state,
                fireInsuranceBrokerages: fireInsuranceBrokeragesReducer(state.fireInsuranceBrokerages, action)
            };
        case actions.FIRE_INSURANCE_BROKERS_PREFIX:
            return {
                ...state,
                fireInsuranceBrokers: fireInsuranceBrokerReducer(state.fireInsuranceBrokers, action)
            }
        case actions.AFFIDAVITS_PREFIX:
            return {
                ...state,
                affidavits: affidavitsReducer(state.affidavits, action)
            };
        case actions.TITLE_INSURANCE_PREFIX:
            return {
                ...state,
                titleInsurance: titleInsuranceReducer(state.titleInsurance, action)
            };
        case actions.TITLE_INSURANCE_PREMIUMS_PREFIX:
            return {
                ...state,
                titleInsurancePremiums: titleInsurancePremiumsReducer(state.titleInsurancePremiums, action)
            };
        case actions.DECLARATIONS_PREFIX:
            return {
                ...state,
                statutoryDeclarations: statutoryDeclarationsReducer(state.statutoryDeclarations, action)
            };
        case actions.DEBTS_PREFIX:
            return {
                ...state,
                debts: debtsReducer(state.debts, action)
            };
        case actions.TRUST_LEDGER_PREFIX:
            return {
                ...state,
                trustLedgers: trustLedgersReducer(state.trustLedgers, action)
            };
        case actions.CONDO_CORPORATIONS_PREFIEX:
            return {
                ...state,
                condoCorporations: condoCorporationsReducer(state.condoCorporations, action)
            };
        case actions.PROPERTY_MANAGEMENT_COMPANIES_PREFIX:
            return {
                ...state,
                propertyManagementCompanies: propertyManagementCompaniesReducer(state.propertyManagementCompanies, action)
            };
        case actions.CONDITIONS_PREFIX:
            return {
                ...state,
                conditions: conditionsReducer(state.conditions, action)
            };
        case actions.SIGNING_APPOINTMENTS_PREFIX:
            return {
                ...state,
                signingAppointments: signingAppointmentsReducer(state.signingAppointments, action)
            };
        case actions.LEGAL_PROFESSIONALS_PREFIX:
            return {
                ...state,
                legalProfessionals: legalProfessionalsReducer(state.legalProfessionals, action)
            };
        case actions.LEGAL_FIRMS_PREFIX:
            return {
                ...state,
                legalFirms: legalFirmsReducer(state.legalFirms, action)
            };
        case actions.LEGAL_FIRM_OFFICES_PREFIX:
            return {
                ...state,
                legalFirmOffices: legalFirmOfficesReducer(state.legalFirmOffices, action)
            };
        case actions.TRUST_LEDGER_PAYMENT_GROUP_PREFIX:
            return {
                ...state,
                paymentGroups: trustLedgerPaymentGroupsReducer(state.paymentGroups, action)
            };
        case actions.REGIONS_PREFIX:
            return {
                ...state,
                regions: regionReducer(state.regions, action)
            };
        case actions.NOTES_PREFIX:
            return {
                ...state,
                notes: notesReducer(state.notes, action)
            };
        case actions.USER_PREFIX:
            return {
                ...state,
                user: userReducer(state.user, action)
            };
        default:
            return state;
    }
};

export default function UniversalSurveyContextProvider(props: Record<string, any>) {
    const [state, dispatch] = useReducer(reducer, defaultState);
    return <UniversalSurveyContext.Provider value={[state, dispatch]}>{props.children}</UniversalSurveyContext.Provider>;
}