import Button, {ButtonProps} from "@mui/material/Button";
import {styled, SxProps, Theme} from "@mui/material/styles";
import constants from "../../styles/constants";

type DeededButtonProps = Omit<ButtonProps, "variant"> & {
  capitalized?: boolean;
  kind?: "primary" | "secondary" | "secondary-v2";
  disabled?: boolean;
  sx?: SxProps<Theme>;
};
const DeededButton: React.FC<DeededButtonProps> = ({
  kind,
  disabled,
  children,
  sx,
  ...props
}) => {
  const resolvedKind = kind ?? "primary";

  return (
    <StyledButton
      {...props}
      disabled={disabled}
      kind={resolvedKind}
      data-selenium-test="DeededButton"
      sx={sx}
    >
      {children}
    </StyledButton>
  );
};
const StyledButton = styled(Button)<DeededButtonProps>(
  ({capitalized, kind, disabled, theme}) => ({
    borderRadius: "5px",
    backgroundColor:
      kind === "secondary-v2"
        ? constants.colors.redVeryPale
        : kind === "secondary"
        ? constants.colors.white
        : disabled
        ? constants.colors.white
        : constants.colors.red,
    width: "216px",
    height: "40px",
    textTransform: !capitalized ? "capitalize" : undefined,
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    color: kind === "primary" ? constants.colors.white : constants.colors.red,
    letterSpacing: "unset",
    border:
      kind === "secondary-v2"
        ? `1px solid ${constants.colors.redVeryPale}`
        : disabled
        ? `1px solid ${constants.colors.grayLight}`
        : `1px solid ${constants.colors.red}`,
    transition: constants.transitions,
    "&:hover": {
      transition: constants.transitions,
      color:
        kind === "secondary-v2" || kind === "primary"
          ? constants.colors.red
          : constants.colors.white,
      border: `1px solid ${
        kind === "secondary-v2"
          ? constants.colors.pinkPale
          : kind === "secondary"
          ? constants.colors.red
          : constants.colors.redPale
      }`,
      backgroundColor:
        kind === "secondary-v2"
          ? constants.colors.pinkPale
          : kind === "secondary"
          ? constants.colors.red
          : constants.colors.white,
    },
    "&.Mui-disabled": {
      cursor: "no-drop",
      pointerEvents: "auto",
      border: `1px solid ${constants.colors.grayLight}`,
      backgroundColor: constants.colors.white,
      color: constants.colors.searchColor,
      svg: {
        stroke: `${constants.colors.searchColor} !important`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
);

export default DeededButton;
