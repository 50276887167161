import { ChargeHolderCompany } from "../../../types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultChargeHolderCompany: ChargeHolderCompany = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    abbr_name: undefined,
    type: undefined,
    general_contact_name: undefined,
    general_address: defaultSimpleAddress,
    general_email: undefined,
    address_for_service: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    website: undefined,
    date_created: undefined,
    date_updated: undefined,

    charge_holder_company: undefined,
}