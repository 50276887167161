import { useContext, useState } from "react";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { getConditionDebt, getConditionLien } from "../../../../../context/UniversalSurvey/asyncActions/conditions";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { formatLienForConditionDropdown, formatDebtForConditionDropdown } from "../../../../../libs/utils/formatValues";
import { LienIsTo } from "../../../../../libs/resources/enums/liens";

type Props = Omit<ModalProps, "children"> & {
    entity: "Encumbrance" | "Debt";
    top?: number;
};

export default function LinkExistingModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
    }

    function submit() {
        if (selectedOption) {
            if (props.entity === "Encumbrance") {
                getConditionLien(dispatch, String(state.deal.dealInfo?.id), selectedOption?.substring(0, selectedOption.indexOf("-")));
            } else {
                getConditionDebt(dispatch, String(state.deal.dealInfo?.id), selectedOption?.substring(0, selectedOption.indexOf("-")));
            }
        }
        onDiscard();
    }

    function isLienInCondition(id: number) {
        for (const condition of state.conditions.conditionsInDeal) {
            if (condition.lien?.id === id) return true;
        }
        return false;
    }

    function isDebtInCondition(id: number) {
        for (const condition of state.conditions.conditionsInDeal) {
            if (condition.debt?.id === id) return true;
        }
        return false;
    }

    function createOptionsList() {
        let options: string[] = [];
        if (props.entity === "Encumbrance") {
            for (const lien of state.existingLiens.liensInDeal) {
                if (lien.is_to === LienIsTo.BeDischarged && !isLienInCondition(lien.id)) {
                    options.push(`${lien.id}-${formatLienForConditionDropdown(lien)}`)
                }
            }
        } else {
            for (const debt of state.debts.debtsInDeal) {
                if (!isDebtInCondition(debt.id)) {
                    options.push(`${debt.id}-${formatDebtForConditionDropdown(debt)}`)
                }
            }
        }
        return options;
    }

    return (
        <ModalBase
            title={`Link ${props.entity}`}
            open={props.open}
            onClose={onDiscard}
            onSubmit={submit}
            saveDisabled={!selectedOption}
            discardButtonLabel="Cancel"
            saveButtonLabel="Link"
            hideBackdrop
            size="small"
            top={props.top ?? undefined}
        >
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={12}>
                    <Dropdown
                        formatValue={(value: string) => value.substring(value.indexOf("-") + 1)}
                        value={selectedOption ?? ""}
                        onChange={(e) => setSelectedOption(e.target.value as string)}
                        placeholder={`Select a${props.entity === "Encumbrance" ? "n encumbrance" : " debt"}...`}
                        options={createOptionsList()}
                        label={{ text: "" }}
                        isHoverActionHidden={selectedOption ? false : true}
                        inputProps={{ "aria-label": "Entity" }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}