import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getClients,
    saveDealClient,
    newDealClient,
    getClient,
    newSpousalRelationship,
    saveSpousalRelationship,
    deleteSpousalRelationship,
    removeClient
 } from "../../../libs/service/axios/api";
import { TabOption, TabOptions } from '../../../libs/types/UniversalSurvey/Frontend/tabOption';
import { Client } from '../../../libs/types/UniversalSurvey/Client/client';
import { sanitizeClientResponse, sanitizeSimpleClientsResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeClientRequest, sanitizeSpousalRelationshipRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultClient } from "../../../libs/resources/defaults/frontend/defaultClient";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { defaultSimpleSpousalRelationship } from "../../../libs/resources/defaults/frontend/defaultSimpleSpousalRelationship";
import { FamilyLawAct } from "../../../libs/resources/enums/familyLawAct";
import { SimpleSpousalRelationship } from "../../../libs/types/UniversalSurvey/SpousalRelationship/SimpleSpousalRelationship";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { OneSpouseFamilyLawOptions } from "../../../libs/resources/options";
import { BasicClient } from "../../../libs/types/UniversalSurvey/Client/basicClient";
import { capitalizeFirstLetter } from '../../../libs/utils/formatValues';
import { sortByArrayOrder } from "../../../libs/utils/arrays";
import { SimpleRole } from "../../../libs/types/UniversalSurvey/Roles/simpleRole";
import { RoleTypesDB } from "../../../libs/resources/enums/roles";
import { navigateURL } from "./deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { RecordType } from "../../../libs/resources/enums/recordTypes";
import { defaultSimpleClient } from "../../../libs/resources/defaults/frontend/defaultSimpleClient";
import { ClientType } from "../../../libs/resources/enums/clientType";
import { Role } from "../../../libs/types/UniversalSurvey/Roles/role";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { Loading } from "../../../libs/resources/enums/loading";

function switchToClientSection(dispatch: Dispatch<Record<string, any>>, dealId: string, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getClients(dealId)
        .then(function (response: any) {
            const clientList = response.data;
            const clientTabs: TabOptions = [];
            for (let i = 0; i < clientList.length; ++i) {
                const client = sanitizeClientResponse(clientList[i]);
                clientTabs.push({
                    title: getClientTabName(client),
                    itemId: client.id,
                    subTitle: getClientTabSubtitle(client),
                    missingFields: 0,
                });
            }
            sortByArrayOrder(clientTabs, "client");
            if (clientTabs.length > 0 && !hasEntityId) {
                navigateURL(dispatch, dealId, "data-sheet", Sections.ClientInformation, String(clientTabs[0].itemId));
            }
            dispatch({ type: actions.SET_TAB_OPTIONS, payload: clientTabs });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Client tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function saveClient(dispatch: Dispatch<Record<string, any>>, dealId: string, clientInfo: Client) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    saveDealClient(dealId, String(clientInfo.id), sanitizeClientRequest(clientInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save client: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function updateClientOwnershipShare(dispatch: Dispatch<Record<string, any>>, dealId: string, clientInfo: SimpleClient) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: clientInfo });

    getClient(dealId, String(clientInfo.id))
        .then(function (response: any) {
            const cleanClient: Client = { ...sanitizeClientResponse(response.data), tenants_in_common_share: clientInfo.tenants_in_common_share ?? undefined };
            saveDealClient(dealId, String(clientInfo.id), sanitizeClientRequest(cleanClient))
                .then()
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save client: ${error}`, type: AlertTypes.Error } });
                })
                .finally(() => {
                    dispatch({ type: actions.SET_IS_SAVING, payload: false });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get client: ${error}`, type: AlertTypes.Error } });
        })
}

function addDealClient(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    newDealClient(dealId)
        .then(function (response: any) {
            const tab: TabOption = {
                title: getClientTabName(defaultClient),
                itemId: response.data.client_id,
                subTitle: getClientTabSubtitle(response.data),
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            navigateURL(dispatch, dealId, "data-sheet", Sections.ClientInformation, String(response.data.client_id));
            const client = { ...defaultClient, id: response.data.client_id };
            dispatch({ type: actions.SET_CLIENT_INFO, payload: client });
            saveClient(dispatch, dealId, client);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New deal client: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function changeSelectedClient(dispatch: Dispatch<Record<string, any>>, dealId: string, clientId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    getClient(dealId, String(clientId))
        .then(function (response: any) {
            const cleanClient = sanitizeClientResponse(response.data)
            dispatch({ type: actions.SET_CLIENT_INFO, payload: cleanClient });
            updateClientTabName(dispatch, cleanClient);

            // If there is an empty spousal relationship record, remove it
            if (cleanClient.spousal_relationship && !cleanClient.spousal_relationship.family_law_act) {
                deleteSpousalRelationship(dealId, String(cleanClient.spousal_relationship.id))
                    .then(function () {
                        dispatch({ type: actions.SET_CLIENT_SPOUSAL_RELATIONSHIP, payload: undefined });
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing empty spousal relationship: ${error}`, type: AlertTypes.Error } });
                    })
            }

            // Get updated list of simple clients
            getClients(dealId)
                .then(function (response: any) {
                    dispatch({ type: actions.SET_CLIENTS_IN_DEAL, payload: sanitizeSimpleClientsResponse(response.data) });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal clients: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Client section content: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function getDealClients(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getClients(dealId)
    .then(function (response: any) {
        dispatch({ type: actions.SET_CLIENTS_IN_DEAL, payload: sanitizeSimpleClientsResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal clients: ${error}`, type: AlertTypes.Error } });
    })
}

function updateClientTabName(dispatch: Dispatch<Record<string, any>>, client: Client) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION,
        payload: {
            title: getClientTabName(client),
            subTitle: getClientTabSubtitle(client),
            itemId: client.id,
            missingFields: 0,
        }
    })
}

function updateClientHeaderTitle(dispatch: Dispatch<Record<string, any>>, client: Client, roles: Role[], dealInfo: Deal | undefined) {
    // Is primary client
    let isPrimary: boolean = roles.filter((role) => role.record_id === dealInfo?.primary_transaction_id ).length > 0;
    if (isPrimary) {
        let updatedClient: SimpleClient = { 
            ...defaultSimpleClient,
            id: client.id,
            first_name: client.first_name,
            last_name: client.last_name,
            middle_name: client.middle_name
        };
        dispatch({ type: actions.UPDATE_DEAL_INFO_CLIENTS, payload: updatedClient });
    }
}

function getClientTabName(client: Client | SimpleClient | undefined, skipMiddle: boolean = false, onBehalfOf: boolean = false) {
    let personName: string = ""
    if (!client || (!client.first_name && !client.middle_name && !client.last_name) || (skipMiddle && !client.first_name && !client.last_name)) {
        personName = "New Client";
    } else {
        if (client.first_name) {
            personName = client.first_name;
        }
        if (client.middle_name && skipMiddle !== true) {
            if (personName) personName += " ";
            personName += client.middle_name;
        }
        if (client.last_name) {
            if (personName) personName += " ";
            personName += client.last_name
        }
    }

    switch(client?.type) {
        case ClientType.Corporation:
            let corporationName: string = "";
            if (!client.corporation_name) {
                corporationName = "New Corporation";
            } else {
                corporationName = client.corporation_name
            }
            return onBehalfOf ? `${personName}, on behalf of ${corporationName}` : corporationName
        default:
            return personName;
    }
}

function getClientTabSubtitle(client: Client | SimpleClient) {
    return capitalizeFirstLetter((client.roles?.at(0)?.role) ? client.roles?.at(0)?.role.replace("_", " ") : "client");
}

function addSpousalRelationshipForClient(dispatch: Dispatch<Record<string, any>>, dealId: string, client: Client) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    
    newSpousalRelationship(dealId, String(client.id))
        .then(function (response: any) {
            dispatch({ type: actions.SET_CLIENT_SPOUSAL_RELATIONSHIP, payload: {
                ...defaultSimpleSpousalRelationship,
                id: response.data.id,
                spouse_one: {
                    id: client.id,
                    first_name: client.first_name,
                    last_name: client.last_name
                }
            }})
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating spousal relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function updateFamilyLawAct(dispatch: Dispatch<Record<string, any>>, dealId: string, fla: FamilyLawAct | undefined, clientId: number, relationshipInfo: SimpleSpousalRelationship) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    // swapping spouse order if necessary
    if (fla === FamilyLawAct.Consent2 && clientId === relationshipInfo.spouse_one?.id) {
        swapSpouses(relationshipInfo);
    } else if (fla === FamilyLawAct.Consent && clientId === relationshipInfo.spouse_two?.id) {
        swapSpouses(relationshipInfo);
    }

    // adding/removing consenting spouse role if necessary
    if (relationshipInfo.family_law_act !== FamilyLawAct.Consent && (fla === FamilyLawAct.Consent || fla === FamilyLawAct.Consent2) && relationshipInfo.spouse_two) {
        addConsentingSpouseRole(dispatch, relationshipInfo.spouse_two);
    } else if (relationshipInfo.family_law_act === FamilyLawAct.Consent && (fla !== FamilyLawAct.Consent && fla !== FamilyLawAct.Consent2) && relationshipInfo.spouse_two) {
        removeConsentingSpouseRole(dispatch, relationshipInfo.spouse_two);
    } else if (relationshipInfo.family_law_act === FamilyLawAct.Consent && (fla === FamilyLawAct.Consent || fla === FamilyLawAct.Consent2)) {
        if (relationshipInfo.spouse_two) {
            addConsentingSpouseRole(dispatch, relationshipInfo.spouse_two);
        }
        if (relationshipInfo.spouse_one) {
            removeConsentingSpouseRole(dispatch, relationshipInfo.spouse_one);
        }
    }

    // setting FLA
    if (fla === FamilyLawAct.Consent2) {
        relationshipInfo.family_law_act = FamilyLawAct.Consent
    } else {
        relationshipInfo.family_law_act = fla
    }

    if (fla === undefined || OneSpouseFamilyLawOptions.includes(fla)) {
        if (clientId === relationshipInfo.spouse_one?.id) {
            relationshipInfo.spouse_two = undefined;
        } else if (clientId === relationshipInfo.spouse_two?.id) {
            relationshipInfo.spouse_one = undefined;
        }
    }

    updateSpousalRelationship(dispatch, dealId, relationshipInfo);
}

function swapSpouses(relationshipInfo: SimpleSpousalRelationship) {
    const tempSpouse = relationshipInfo.spouse_one;
    relationshipInfo.spouse_one = relationshipInfo.spouse_two;
    relationshipInfo.spouse_two = tempSpouse;
}

function updateSpouse(dispatch: Dispatch<Record<string, any>>, dealId: string, currClientId: number, spouse: SimpleClient | undefined, relationshipInfo: SimpleSpousalRelationship) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    // if the new spouse already has a relationship (but doesn't have a spouse listed)
    if (spouse?.spousal_relationship && (!spouse.spousal_relationship.spouse_one || !spouse.spousal_relationship.spouse_two)) {
        deleteSpousalRelationship(dealId, String(spouse.spousal_relationship.id))
            .then(function () {
                dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: {
                    ...spouse,
                    spousal_relationship: {
                        id: relationshipInfo.id,
                        family_law_act: relationshipInfo.family_law_act,
                        spouse_one: relationshipInfo.spouse_two?.id === currClientId ? spouse : relationshipInfo.spouse_two,
                        spouse_two: relationshipInfo.spouse_one?.id === currClientId ? spouse : relationshipInfo.spouse_one,
                    }
                }});
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting spousal relationship: ${error}`, type: AlertTypes.Error } });
            })
    }

    if (relationshipInfo.spouse_one?.id === currClientId) {
        if (relationshipInfo.spouse_two && relationshipInfo.family_law_act === FamilyLawAct.Consent) {
            removeConsentingSpouseRole(dispatch, relationshipInfo.spouse_two);
        }
        relationshipInfo.spouse_two = spouse;
    } else if (relationshipInfo.spouse_two?.id === currClientId) {
        if (relationshipInfo.spouse_one && relationshipInfo.family_law_act === FamilyLawAct.Consent) {
            removeConsentingSpouseRole(dispatch, relationshipInfo.spouse_one);
        }
        relationshipInfo.spouse_one = spouse;
    }

    if (relationshipInfo.family_law_act === FamilyLawAct.Consent && spouse && relationshipInfo.spouse_one?.id === currClientId) {
        addConsentingSpouseRole(dispatch, spouse);
    }

    updateSpousalRelationship(dispatch, dealId, relationshipInfo);
}

function updateSpousalRelationship(dispatch: Dispatch<Record<string, any>>, dealId: string, relationshipInfo: SimpleSpousalRelationship) {
    saveSpousalRelationship(dealId, String(relationshipInfo.id), sanitizeSpousalRelationshipRequest(relationshipInfo))
        .then(function () {
            dispatch({ type: actions.SET_CLIENT_SPOUSAL_RELATIONSHIP, payload: relationshipInfo });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating spousal relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function removeConsentingSpouseRole(dispatch: Dispatch<Record<string, any>>, client: SimpleClient) {
    const newClient: SimpleClient = {
        ...client,
        roles: client.roles ? client.roles.filter((role) => role.role !== RoleTypesDB.ConsentingSpouse) : []
    }
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: newClient });
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: newClient.id, subtitle: getClientTabSubtitle(newClient) } });
}

function addConsentingSpouseRole(dispatch: Dispatch<Record<string, any>>, client: SimpleClient) {
    const newRole: SimpleRole = {record_type: RecordType.Spouse, role: RoleTypesDB.ConsentingSpouse, record_id: client.spousal_relationship?.id ? client.spousal_relationship?.id : -1 };
    const newClient: SimpleClient = {
        ...client,
        roles: client.roles ? [...client.roles, newRole] : [newRole]
    }
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: newClient });
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: client.id, subtitle: getClientTabSubtitle(newClient) } });
}

function createNewSpouse(dispatch: Dispatch<Record<string, any>>, dealId: string, spousalRelationship: SimpleSpousalRelationship, spouseInfo: BasicClient, currClientId?: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: true } });
    let spouseAddedSuccessfully = true;
    newDealClient(dealId)
        .then(function (response) {
            const clientId = response.data.client_id

            let roles: SimpleRole[] = [];
            if (spousalRelationship.family_law_act === FamilyLawAct.Consent) {
                roles.push({ record_type: RecordType.Spouse, role: RoleTypesDB.ConsentingSpouse, record_id: spousalRelationship.id });
            }

            const newClient: SimpleClient = {
                ...defaultSimpleClient,
                id: clientId,
                first_name: spouseInfo.first_name,
                last_name: spouseInfo.last_name,
                roles: roles
            };

            const tab: TabOption = {
                title: getClientTabName(newClient),
                subTitle: getClientTabSubtitle(newClient),
                itemId: clientId,
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            dispatch({ type: actions.ADD_CLIENT_IN_DEAL, payload: newClient})

            const clientObj: Partial<Client> = {
                id: clientId,
                first_name: spouseInfo.first_name,
                last_name: spouseInfo.last_name,
                email: spouseInfo.email,
            }
            const cleanObj = JSON.parse(JSON.stringify(clientObj));
            const promiseList: any[] = [];
            promiseList.push(saveDealClient(dealId, String(clientId), sanitizeClientRequest(cleanObj))
                .catch(function (error: any) {
                    spouseAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving spouse: ${error}`, type: AlertTypes.Error } });
                }));

            const cleanSpousalRelationship = { ...spousalRelationship }
            if (cleanSpousalRelationship.spouse_one?.id !== undefined && cleanSpousalRelationship.spouse_one?.id === currClientId) {
                cleanSpousalRelationship.spouse_two = { ...defaultSimpleClient, id: clientId, first_name: spouseInfo.first_name, last_name: spouseInfo.last_name, roles: [] };
            } else if (cleanSpousalRelationship.spouse_one?.id !== undefined && cleanSpousalRelationship.spouse_two?.id === currClientId) {
                cleanSpousalRelationship.spouse_one = { ...defaultSimpleClient, id: clientId, first_name: spouseInfo.first_name, last_name: spouseInfo.last_name, roles: [] };
            }
            promiseList.push(saveSpousalRelationship(dealId, String(cleanSpousalRelationship.id), sanitizeSpousalRelationshipRequest(cleanSpousalRelationship))
                .catch(function (error: any) {
                    spouseAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding client to spousal relationship: ${error}`, type: AlertTypes.Error } });
                }));

            Promise.all(promiseList).then(function () {
                if (spouseAddedSuccessfully) {
                    dispatch({ type: actions.SET_CLIENT_SPOUSAL_RELATIONSHIP, payload: cleanSpousalRelationship });
                }
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: false } });
            });
        }).catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating spouse: ${error}`, type: AlertTypes.Error } });
        })
}

function isClientOnlyGuarantor(client: SimpleClient & { label: string }): boolean {
    let isClientGuarantor: boolean = false;
    if (client.roles) {
        for (const role of client.roles) {
            if (role.role === RoleTypesDB.Guarantor) {
                isClientGuarantor = true;
            }
            if (
                role.role === RoleTypesDB.Advisee ||
                role.role === RoleTypesDB.Borrower ||
                role.role === RoleTypesDB.Transferee ||
                role.role === RoleTypesDB.Transferor ||
                role.role === RoleTypesDB.ConsentingSpouse
            ) {
                return false;
            }
        }
    }
    return isClientGuarantor;
}

function deleteDealClient(dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, tabOptions: TabOptions, isActive: boolean) {
    removeClient(dealId, String(entityId))
    .then(function (response: any) {
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: entityId });
        if (isActive) {
            if (tabOptions.length > 1) {
                let newOption = tabOptions.find((value) => value.itemId !== entityId);
                if (newOption) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.ClientInformation, String(newOption.itemId));
                } else {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.ClientInformation, undefined);
                    dispatch({ type: actions.SET_CLIENT_INFO, payload: undefined });
                }
            } else {
                navigateURL(dispatch, dealId, "data-sheet", Sections.ClientInformation, undefined);
                dispatch({ type: actions.SET_CLIENT_INFO, payload: undefined });
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete client: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

function copyClientAddress(dispatch: Dispatch<Record<string, any>>, dealId: string, clientId: number) {
    getClient(dealId, String(clientId))
    .then(function (response: any) {
        const cleanClient = sanitizeClientResponse(response.data);
        dispatch({ type: actions.SET_CLIENT_ADDRESS_TO_COPY, payload: cleanClient.address_for_service });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get client: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

function copyClientFinancialInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, clientId: number) {
    getClient(dealId, String(clientId))
    .then(function (response: any) {
        const cleanClient = sanitizeClientResponse(response.data);
        dispatch({ type: actions.SET_CLIENT_FINANCIAL_INFO_TO_COPY, payload: cleanClient.financial_info });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get client: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

export {
    switchToClientSection,
    saveClient,
    updateClientOwnershipShare,
    addDealClient,
    changeSelectedClient,
    updateClientTabName,
    getClientTabName,
    getClientTabSubtitle,
    addSpousalRelationshipForClient,
    updateFamilyLawAct,
    updateSpouse,
    updateSpousalRelationship,
    createNewSpouse,
    getDealClients,
    isClientOnlyGuarantor,
    deleteDealClient,
    updateClientHeaderTitle,
    copyClientAddress,
    copyClientFinancialInfo
}