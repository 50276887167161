import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import * as _ from "lodash";
import { LegalFirm, LegalFirmListItem } from "../../../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { defaultLegalFirm } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirm";
import { saveLegalFirmRecord, submitNewLegalFirmRecord } from "../../../../../context/UniversalSurvey/asyncActions/legalFirms";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { LegalProfessionalOptions } from "../../../../../libs/resources/enums/legalProfessionalOptions";
import { defaultFinancialInfoErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";
import { validateModalObject } from "../../../../../libs/utils/validation";
import LegalFirmForm from "./legalFirmForm";

type Props = Omit<ModalProps, "children"> & {
    type: LegalProfessionalOptions;
};

export default function LegalFirmModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [firmObj, setFirmObj] = useState<LegalFirm>({ ...defaultLegalFirm });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [originalRegionList, setOriginalRegionList] = useState<RegionListItem[]>([]);
    const [originalObj, setOriginalObj] = useState<LegalFirm>({ ...defaultLegalFirm });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.legalFirms.recordBeingEdited) {
            setFirmObj(state.legalFirms.recordBeingEdited);
            setOriginalObj(state.legalFirms.recordBeingEdited);
            let tempRegionList: RegionListItem[] = [];
            if (state.legalFirms.recordBeingEdited.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.legalFirms.recordBeingEdited.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
            setOriginalRegionList(tempRegionList);
        }
    }, [state.legalFirms.recordBeingEdited]);

    function updateLegalFirmInfo<
        K extends keyof LegalFirm,
        V extends LegalFirm[K]
    >(key: K, value: V): void {
        const tempObj = { ...firmObj }
        tempObj[key] = value;
        setFirmObj(tempObj);
    }

    function submit() {
        const newListItem: LegalFirmListItem = { 
            ...firmObj, 
            isRecord: true,
            label: firmObj.name,
        };
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.legalFirms.recordBeingEdited) {
            saveLegalFirmRecord(dispatch, String(state.deal.dealInfo?.id), firmObj, props.type, regionList);
            dispatch({ type: actions.REPLACE_LEGAL_FIRM_OPTION_WITH_RECORD, payload: { oldId: firmObj.id, newOption: newListItem, oldIsRecord: true }});
        } else {
            submitNewLegalFirmRecord(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.lawyer_record?.id, state.deal.dealInfo?.signer_record?.id, state.lenders.lenderInfo?.lawyer?.id, firmObj, props.type, regionList);
        }
    }

    function discard() {
        dispatch({ type: actions.SET_LEGAL_FIRM_BEING_EDITED, payload: undefined });
        setFirmObj({ ...defaultLegalFirm });
        setOriginalObj({ ...defaultLegalFirm });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        setOriginalRegionList([]);
        setSelectedRegionList([]);
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(firmObj, originalObj) && _.isEqual(selectedRegionList.sort(), originalRegionList.sort())) {
            discard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function isSaveDisabled(): boolean {
        if (!(firmObj.name && selectedRegionList.length > 0) || (_.isEqual(firmObj, originalObj) && _.isEqual(selectedRegionList.sort(), originalRegionList.sort())) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [financialInfoErrorState, firmObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            size={props.size}
            title={`${state.legalFirms.recordBeingEdited ? "" : "New "}Legal Firm`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.legalFirms.recordBeingEdited ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LegalFirmModal])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLegalFirm])}
        >
            <LegalFirmForm
                legalFirm={firmObj}
                setLegalFirm={setFirmObj}
                updateLegalFirmInfo={updateLegalFirmInfo}
                selectedRegionList={selectedRegionList}
                setSelectedRegionList={setSelectedRegionList}
                regions={state.regions.regions}
                financialInfoErrorState={financialInfoErrorState}
                setFinancialInfoErrorState={setFinancialInfoErrorState}
                confirmDiscardOpen={confirmDiscardOpen}
                setConfirmDiscardOpen={setConfirmDiscardOpen}
                discard={discard}
            />
        </ModalBase>
    );
}