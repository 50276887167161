import { Tabs } from "../enums/tabs";

export const TabMapping = {
    "data-sheet": Tabs.DataSheet,
    "docs": Tabs.Docs,
    "notes": Tabs.Notes
};

export const TabRouteMapping = {
    [Tabs.DataSheet]: "data-sheet",
    [Tabs.Docs]: "docs",
    [Tabs.Notes]: "notes"
}