import { FireInsuranceBroker } from "../../../types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import { VerificationStatus } from "../../enums/verificationStatus";

export const defaultFireInsuranceBroker: FireInsuranceBroker = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    all_regions: undefined,
    email: undefined,
    phone_work: undefined,
    phone_cell: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    fire_insurance_brokerage_record: undefined,
    fire_insurance_broker: undefined,
    regions: []
}