import { useState } from 'react';
import { ChipProps, Stack, styled } from '@mui/material';
import { Large } from '../Typography/index';
import colors from '../componentStyling/Colors';
import { XCircle } from '../Icons/Iconography';
import Theme from '../componentStyling/Theme';
import { BORDER_RADIUS_ACTION_W_ICON } from '../componentStyling/Styles';

type TokenProps = ChipProps & {
    label?: string;
}

export default function Token(props: TokenProps) {
    const { onDelete, ...other } = props;
    const [isChipHovered, setIsChipHovered] = useState<boolean>(false);
    const [isRemoveHovered, setIsRemoveHovered] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    return (
        <StyledChip
            {...other}
            onFocus={onFocus}
            onBlur={onBlur}
            isChipHovered={isChipHovered}
            isRemoveHovered={isRemoveHovered}
            onMouseEnter={() => setIsChipHovered(true)}
            onMouseLeave={() => setIsChipHovered(false)}
        >
            <Stack direction="row" gap={1.5} alignItems="center">
                <Large style={{color: isRemoveHovered ? colors.WHITE : colors.BLACK}}>{props.label ? props.label : ""}</Large>
                {(onDelete && (isChipHovered || focused)) && (
                    <EndIconDiv
                        onMouseEnter={() => setIsRemoveHovered(true)}
                        onMouseLeave={() => setIsRemoveHovered(false)}
                        onClick={onDelete}
                    >
                        <XCircle color={isRemoveHovered ? colors.WHITE : colors.BLACK} />
                    </EndIconDiv>
                )}
            </Stack>
        </StyledChip>
    );
}

const StyledChip = styled('div', { shouldForwardProp: 
    (prop) => prop !== "isChipHovered" && prop !== "isRemoveHovered"
})<{
    isChipHovered: boolean;
    isRemoveHovered: boolean;
}>(({ isChipHovered, isRemoveHovered }) => ({
    backgroundColor: isRemoveHovered ? Theme.ERROR : isChipHovered ? Theme.HOVER : colors.GRAY_50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: (isRemoveHovered || isChipHovered) ? BORDER_RADIUS_ACTION_W_ICON : "0.2rem",
    height: "2.7rem",
    padding: "0.2rem",
    width: "fit-content",
    textTransform: "none",
    "&&:focus": {
        backgroundColor: isRemoveHovered ? Theme.ERROR : colors.RED_100,
        borderRadius: BORDER_RADIUS_ACTION_W_ICON,
        outline: 'none'
    },
    gap: "0.5rem"
}));

const EndIconDiv = styled('div')({
    paddingTop: "0.4rem",
    marginLeft: "-0.8rem",
    cursor: "pointer"
});