import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    newDebt,
    saveDebt,
    getDebts,
    getDebt,
    deleteDebt,
    getLenderRecord,
    getDealChargeHolderCompany,
    getDealGovernment,
    getMortgageBrokerageRecord,
    getDealOtherChargeHolder,
    createDealGovernment,
    newLenderRecord,
    createDealChargeHolderCompany,
    newMortgageBrokerageRecord,
    saveLenderRecord,
    saveDealChargeHolderCompany,
    saveDealGovernment,
    saveMortgageBrokerageRecord,
    saveDealOtherChargeHolder,
    createDealOtherChargeHolder
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { 
    sanitizeChargeHolderCompanyRequest,
    sanitizeDebtRequest,
    sanitizeGovernmentRequest,
    sanitizeLenderRequest, 
    sanitizeMortgageBrokerageRequest, 
    sanitizeOtherChargeHolderRequest
} from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import {
    sanitizeChargeHolderCompanyResponse,
    sanitizeDebtResponse,
    sanitizeGovernmentResponse,
    sanitizeLenderResponse,
    sanitizeMortgageBrokerageResponse,
    sanitizeOtherChargeHolderResponse,
    sanitizeSimpleDebtResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { DebtHolder, DebtHolderListItem } from "../../../libs/types/UniversalSurvey/Debts/debtHolder";
import { Debt } from "../../../libs/types/UniversalSurvey/Debts/debt";
import { SimpleDebt } from "../../../libs/types/UniversalSurvey/Debts/simpleDebt";
import { DebtHolderResponse } from "../../../libs/types/UniversalSurvey/Debts/debtHolderResponse";
import { defaultDebtHolderResponse } from "../../../libs/resources/defaults/frontend/defaultDebtHolderResponse";
import { DebtHolderTypes } from "../../../libs/resources/enums/debtHolderTypes";
import { Government, GovernmentListItem } from "../../../libs/types/UniversalSurvey/Government/government";
import { Lender, LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { ChargeHolderCompany, ChargeHolderCompanyListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { MortgageBrokerage, MortgageBrokerageListItem } from "../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { OtherChargeHolder } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { defaultLender } from "../../../libs/resources/defaults/frontend/defaultLender";
import { defaultChargeHolderCompany } from "../../../libs/resources/defaults/frontend/defaultChargeHolderCompany";
import { defaultGovernment } from "../../../libs/resources/defaults/frontend/defaultGovernment";
import { defaultMortgageBrokerage } from "../../../libs/resources/defaults/frontend/defaultMortgageBrokerage";
import { defaultOtherChargeHolder } from "../../../libs/resources/defaults/frontend/defaultOtherChargeHolder";
import { defaultSimpleAddress } from "../../../libs/resources/defaults/frontend/defaultSimpleAddress";
import { defaultDebtHolder } from "../../../libs/resources/defaults/frontend/defaultDebtHolder";
import { defaultFinancialInfo } from "../../../libs/resources/defaults/frontend/defaultFinancialInfo";
import { Loading } from "../../../libs/resources/enums/loading";

function addDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder | undefined, debtInfo: Debt) {    
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: true } });
    newDebt(dealId)
        .then(function (response: any) {
            saveDebt(dealId, String(response.data.id), sanitizeDebtRequest(debtInfo))
                .then(function () {
                    let newDebtHolder: DebtHolder = { ...defaultDebtHolder }
                    if (debtHolderInfo) {
                        newDebtHolder.name = debtHolderInfo.name ?? undefined,
                        newDebtHolder.id = debtHolderInfo.id
                    }
                    let newDebt: Debt = { ...debtInfo, id: response.data.id, debt_holder_record: newDebtHolder };
                    dispatch({ type: actions.ADD_DEBT, payload: newDebt });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving debt: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating debt: ${error}`, type: AlertTypes.Error } });
        })
}

function updateDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder | undefined, debtInfo: Debt) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: true } });
    saveDebt(dealId, String(debtInfo.id), sanitizeDebtRequest(debtInfo))
        .then(function () {
            let newDebtHolder: DebtHolderResponse = { ...defaultDebtHolderResponse }
            if (debtHolderInfo) {
                newDebtHolder.name = debtHolderInfo.name ?? null,
                newDebtHolder.id = debtHolderInfo.id
            }
            dispatch({ type: actions.UPDATE_DEBT, payload: { debt: debtInfo, debt_holder: newDebtHolder } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving debt: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
        })
}

function getDebtsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DebtList, isLoading: true } });
    getDebts(dealId)
        .then(function (response: any) {
            const debts: SimpleDebt[] = sanitizeSimpleDebtResponse(response.data);
            dispatch({ type: actions.SET_DEBTS_IN_DEAL, payload: debts });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get debts: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DebtList, isLoading: false } });
        })
}

function getDebtBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, debtId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DebtModal, isLoading: true } });
    getDebt(dealId, String(debtId))
    .then(function (response: any) {
        dispatch({ type: actions.SET_DEBT_BEING_EDITED, payload: sanitizeDebtResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get debt: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.DebtModal, isLoading: false } });
    })
}

function removeDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, debtId: number) {
    deleteDebt(dealId, String(debtId))
    .then(function () {
        dispatch({ type: actions.REMOVE_DEBT, payload: debtId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete debt: ${error}`, type: AlertTypes.Error } });
    })
}

// Fetching debt holder records

function getLenderDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number) {
    getLenderRecord(dealId, String(debtHolderId))
    .then(function (response: any) {
        let newDebtHolder: DebtHolder = {
            id: debtHolderId,
            name: response.data.name ?? undefined,
            general_address: response.data.general_address ?? defaultSimpleAddress,
            email: response.data.general_email ?? undefined,
            phone: undefined,
            financial_info: response.data.financial_info ?? defaultFinancialInfo
        }
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: newDebtHolder });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender record: ${error}`, type: AlertTypes.Error } });
    })
}

function getCompanyDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number) {
    getDealChargeHolderCompany(dealId, String(debtHolderId))
    .then(function (response: any) {
        let newDebtHolder: DebtHolder = {
            id: debtHolderId,
            name: response.data.name ?? undefined,
            general_address: response.data.general_address ?? defaultSimpleAddress,
            email: response.data.general_email ?? undefined,
            phone: undefined,
            financial_info: response.data.financial_info ?? defaultFinancialInfo
        }
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: newDebtHolder });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get charge holder company record: ${error}`, type: AlertTypes.Error } });
    })
}

function getGovernmentDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number) {
    getDealGovernment(dealId, String(debtHolderId))
    .then(function (response: any) {
        let newDebtHolder: DebtHolder = {
            id: debtHolderId,
            name: response.data.name ?? undefined,
            general_address: response.data.general_address ?? defaultSimpleAddress,
            email: response.data.general_email ?? undefined,
            phone: undefined,
            financial_info: response.data.financial_info ?? defaultFinancialInfo
        }
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: newDebtHolder });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government record: ${error}`, type: AlertTypes.Error } });
    })
}

function getMortgageBrokerageDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number) {
    getMortgageBrokerageRecord(dealId, String(debtHolderId))
    .then(function (response: any) {
        let newDebtHolder: DebtHolder = {
            id: debtHolderId,
            name: response.data.name ?? undefined,
            general_address: response.data.address ?? defaultSimpleAddress,
            email: response.data.email ?? undefined,
            phone: response.data.phone ?? undefined,
            financial_info: response.data.financial_info ?? defaultFinancialInfo
        }
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: newDebtHolder });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
    })
}

function getOtherDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number) {
    getDealOtherChargeHolder(dealId, String(debtHolderId))
    .then(function (response: any) {
        let newDebtHolder: DebtHolder = {
            id: debtHolderId,
            name: response.data.name ?? undefined,
            general_address: response.data.address ?? defaultSimpleAddress,
            email: response.data.email ?? undefined,
            phone: response.data.phone ?? undefined,
            financial_info: response.data.financial_info ?? defaultFinancialInfo
        }
        dispatch({ type: actions.SET_DEBT_HOLDER, payload: newDebtHolder });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get other charge holder record: ${error}`, type: AlertTypes.Error } });
    })
}

// Creating debt holder records from global records

function createGovernmentDebtHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderListItem: DebtHolderListItem) {
    let newRecordId: number;
    createDealGovernment(dealId, debtHolderListItem.id)
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: GovernmentListItem = {
                label: debtHolderListItem.label,
                name: debtHolderListItem.name,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_GOVERNMENT_OPTION_WITH_RECORD, payload: { oldGovernmentId: debtHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            getDebtHolder(dispatch, dealId, newRecordId, DebtHolderTypes.Government);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government record: ${error}`, type: AlertTypes.Error } });
        })
}

function createLenderDebtHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderListItem: DebtHolderListItem) {
    let newRecordId: number;
    newLenderRecord(dealId, { lender_id: debtHolderListItem.id })
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: LenderListItem = {
                label: debtHolderListItem.label,
                name: debtHolderListItem.name,
                type: undefined,
                institution_number: undefined,
                branch_number: undefined,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_LENDER_OPTION_WITH_LENDER_RECORD, payload: { oldLenderId: debtHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            getDebtHolder(dispatch, dealId, newRecordId, DebtHolderTypes.Lender);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender record: ${error}`, type: AlertTypes.Error } });
        })
}

function createCompanyDebtHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderListItem: DebtHolderListItem) {
    let newRecordId: number;
    createDealChargeHolderCompany(dealId, debtHolderListItem.id)
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: ChargeHolderCompanyListItem = {
                label: debtHolderListItem.label,
                name: debtHolderListItem.name,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: debtHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            getDebtHolder(dispatch, dealId, newRecordId, DebtHolderTypes.Company);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createMortgageBrokerageDebtHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderListItem: DebtHolderListItem) {
    let newRecordId: number;
    newMortgageBrokerageRecord(dealId, { mortgage_brokerage_id: debtHolderListItem.id })
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: MortgageBrokerageListItem = {
                label: debtHolderListItem.label,
                name: debtHolderListItem.name,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD, payload: { oldBrokerageId: debtHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            getDebtHolder(dispatch, dealId, newRecordId, DebtHolderTypes.MortgageBrokerage);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

// Updating debt holder records

function updateLenderDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder) {
    getLenderRecord(dealId, String(debtHolderInfo.id))
    .then(function (response: any) {
        let lenderInfo: Lender = sanitizeLenderResponse(response.data);
        lenderInfo.name = debtHolderInfo.name;
        lenderInfo.general_address = debtHolderInfo.general_address;
        lenderInfo.general_email = debtHolderInfo.email;
        saveLenderRecord(dealId, String(debtHolderInfo.id), sanitizeLenderRequest(lenderInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving lender record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender record: ${error}`, type: AlertTypes.Error } });
    })
}

function updateCompanyDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder) {
    getDealChargeHolderCompany(dealId, String(debtHolderInfo.id))
    .then(function (response: any) {
        let companyInfo: ChargeHolderCompany = sanitizeChargeHolderCompanyResponse(response.data);
        companyInfo.name = debtHolderInfo.name;
        companyInfo.general_address = debtHolderInfo.general_address;
        companyInfo.general_email = debtHolderInfo.email;
        saveDealChargeHolderCompany(dealId, String(debtHolderInfo.id), sanitizeChargeHolderCompanyRequest(companyInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get charge holder company record: ${error}`, type: AlertTypes.Error } });
    })
}

function updateGovernmentDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder) {
    getDealGovernment(dealId, String(debtHolderInfo.id))
    .then(function (response: any) {
        let governmentInfo: Government = sanitizeGovernmentResponse(response.data);
        governmentInfo.name = debtHolderInfo.name;
        governmentInfo.general_address = debtHolderInfo.general_address;
        governmentInfo.general_email = debtHolderInfo.email;
        saveDealGovernment(dealId, String(debtHolderInfo.id), sanitizeGovernmentRequest(governmentInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving government record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government record: ${error}`, type: AlertTypes.Error } });
    })
}

function updateMortgageBrokerageDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder) {
    getMortgageBrokerageRecord(dealId, String(debtHolderInfo.id))
    .then(function (response: any) {
        let brokerageInfo: MortgageBrokerage = sanitizeMortgageBrokerageResponse(response.data);
        brokerageInfo.name = debtHolderInfo.name
        brokerageInfo.general_address = debtHolderInfo.general_address;
        brokerageInfo.email = debtHolderInfo.email;
        brokerageInfo.phone = debtHolderInfo.phone;
        saveMortgageBrokerageRecord(dealId, String(debtHolderInfo.id), sanitizeMortgageBrokerageRequest(brokerageInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
    })
}

function updateOtherDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder) {
    getDealOtherChargeHolder(dealId, String(debtHolderInfo.id))
    .then(function (response: any) {
        let otherInfo: OtherChargeHolder = sanitizeOtherChargeHolderResponse(response.data);
        otherInfo.name = debtHolderInfo.name;
        otherInfo.general_address = debtHolderInfo.general_address;
        otherInfo.email = debtHolderInfo.email;
        otherInfo.phone = debtHolderInfo.phone;
        saveDealOtherChargeHolder(dealId, String(debtHolderInfo.id), sanitizeOtherChargeHolderRequest(otherInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving other charge holder record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get other charge holder record: ${error}`, type: AlertTypes.Error } });
    })
}

// Creating debt holder records and updating/creating corresponding debt record

function updateDebtWithDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    if (debtInfo.id !== -1) {
        updateDebt(dispatch, dealId, debtHolderInfo, debtInfo);
    } else {
        addDebt(dispatch, dealId, debtHolderInfo, debtInfo);
    }
}

function createLenderDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    let recordId: number;
    newLenderRecord(dealId)
    .then(function (response: any) {
        recordId = response.data.id;
        let newDebtHolderInfo: Lender = {
            ...defaultLender,
            name: debtHolderInfo.name,
            general_address: debtHolderInfo.general_address,
            general_email: debtHolderInfo.email
        };
        saveLenderRecord(dealId, String(recordId), sanitizeLenderRequest(newDebtHolderInfo))
        .then(function () {
            updateDebtWithDebtHolder(dispatch, dealId, { ...debtHolderInfo, id: recordId }, {...debtInfo, debt_holder_id: recordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving lender record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating lender record: ${error}`, type: AlertTypes.Error } });
    })
}

function createCompanyDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    let recordId: number;
    createDealChargeHolderCompany(dealId)
    .then(function (response: any) {
        recordId = response.data.id;
        let newDebtHolderInfo: ChargeHolderCompany = {
            ...defaultChargeHolderCompany,
            name: debtHolderInfo.name,
            general_address: debtHolderInfo.general_address,
            general_email: debtHolderInfo.email,
            abbr_name: ""
        };
        saveDealChargeHolderCompany(dealId, String(recordId), sanitizeChargeHolderCompanyRequest(newDebtHolderInfo))
        .then(function () {
            updateDebtWithDebtHolder(dispatch, dealId, { ...debtHolderInfo, id: recordId }, {...debtInfo, debt_holder_id: recordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating charge holder company record: ${error}`, type: AlertTypes.Error } });
    })
}

function createGovernmentDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    let recordId: number;
    createDealGovernment(dealId)
    .then(function (response: any) {
        recordId = response.data.id;
        let newDebtHolderInfo: Government = {
            ...defaultGovernment,
            name: debtHolderInfo.name,
            general_address: debtHolderInfo.general_address,
            general_email: debtHolderInfo.email,
            abbr_name: ""
        };
        saveDealGovernment(dealId, String(recordId), sanitizeGovernmentRequest(newDebtHolderInfo))
        .then(function () {
            updateDebtWithDebtHolder(dispatch, dealId, { ...debtHolderInfo, id: recordId }, {...debtInfo, debt_holder_id: recordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving government record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating government record: ${error}`, type: AlertTypes.Error } });
    })
}

function createMortgageBrokerageDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    let recordId: number;
    newMortgageBrokerageRecord(dealId)
    .then(function (response: any) {
        recordId = response.data.id;
        let newDebtHolderInfo: MortgageBrokerage = {
            ...defaultMortgageBrokerage,
            name: debtHolderInfo.name,
            general_address: debtHolderInfo.general_address,
            email: debtHolderInfo.email,
            phone: debtHolderInfo.phone
        };
        saveMortgageBrokerageRecord(dealId, String(recordId), sanitizeMortgageBrokerageRequest(newDebtHolderInfo))
        .then(function () {
            updateDebtWithDebtHolder(dispatch, dealId, { ...debtHolderInfo, id: recordId }, {...debtInfo, debt_holder_id: recordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
    })
}

function createOtherDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    let recordId: number;
    createDealOtherChargeHolder(dealId)
    .then(function (response: any) {
        recordId = response.data.id;
        let newDebtHolderInfo: OtherChargeHolder = {
            ...defaultOtherChargeHolder,
            name: debtHolderInfo.name,
            general_address: debtHolderInfo.general_address,
            email: debtHolderInfo.email,
            phone: debtHolderInfo.phone
        };
        saveDealOtherChargeHolder(dealId, String(recordId), sanitizeOtherChargeHolderRequest(newDebtHolderInfo))
        .then(function () {
            updateDebtWithDebtHolder(dispatch, dealId, { ...debtHolderInfo, id: recordId }, {...debtInfo, debt_holder_id: recordId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving other charge holder record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating other charge holder record: ${error}`, type: AlertTypes.Error } });
    })
}

// Main debt holder proxies

function getDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderId: number, debtHolderType: DebtHolderTypes) {
    switch(debtHolderType) {
        case DebtHolderTypes.Lender:
            getLenderDebtHolder(dispatch, dealId, debtHolderId);
            break;
        case DebtHolderTypes.Company:
            getCompanyDebtHolder(dispatch, dealId, debtHolderId);
            break;
        case DebtHolderTypes.Government:
            getGovernmentDebtHolder(dispatch, dealId, debtHolderId);
            break;
        case DebtHolderTypes.MortgageBrokerage:
            getMortgageBrokerageDebtHolder(dispatch, dealId, debtHolderId);
            break;
        case DebtHolderTypes.Other:
            getOtherDebtHolder(dispatch, dealId, debtHolderId);
            break;
    }
}

function createDebtHolderRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderListItem: DebtHolderListItem) {
    switch(debtHolderListItem.charge_holder_type) {
        case DebtHolderTypes.Lender:
            createLenderDebtHolderRecord(dispatch, dealId, debtHolderListItem);
            break;
        case DebtHolderTypes.Company:
            createCompanyDebtHolderRecord(dispatch, dealId, debtHolderListItem);
            break;
        case DebtHolderTypes.Government:
            createGovernmentDebtHolderRecord(dispatch, dealId, debtHolderListItem);
            break;
        case DebtHolderTypes.MortgageBrokerage:
            createMortgageBrokerageDebtHolderRecord(dispatch, dealId, debtHolderListItem);
            break;
    }
}

function updateDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtHolderType: DebtHolderTypes) {
    switch(debtHolderType) {
        case DebtHolderTypes.Lender:
            updateLenderDebtHolder(dispatch, dealId, debtHolderInfo);
            break;
        case DebtHolderTypes.Company:
            updateCompanyDebtHolder(dispatch, dealId, debtHolderInfo);
            break;
        case DebtHolderTypes.Government:
            updateGovernmentDebtHolder(dispatch, dealId, debtHolderInfo);
            break;
        case DebtHolderTypes.MortgageBrokerage:
            updateMortgageBrokerageDebtHolder(dispatch, dealId, debtHolderInfo);
            break;
        case DebtHolderTypes.Other:
            updateOtherDebtHolder(dispatch, dealId, debtHolderInfo);
            break;
    }
}

function createDebtHolderAndUpdateDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, debtHolderInfo: DebtHolder, debtInfo: Debt) {
    switch(debtInfo.charge_holder_type) {
        case DebtHolderTypes.Lender:
            createLenderDebtHolder(dispatch, dealId, debtHolderInfo, debtInfo);
            break;
        case DebtHolderTypes.Company:
            createCompanyDebtHolder(dispatch, dealId, debtHolderInfo, debtInfo);
            break;
        case DebtHolderTypes.Government:
            createGovernmentDebtHolder(dispatch, dealId, debtHolderInfo, debtInfo);
            break;
        case DebtHolderTypes.MortgageBrokerage:
            createMortgageBrokerageDebtHolder(dispatch, dealId, debtHolderInfo, debtInfo);
            break;
        case DebtHolderTypes.Other:
            createOtherDebtHolder(dispatch, dealId, debtHolderInfo, debtInfo);
            break;
    }
}

export {
    addDebt,
    updateDebt,
    getDebtsInDeal,
    getDebtBeingEdited,
    removeDebt,
    getDebtHolder,
    createDebtHolderRecordFromGlobal,
    updateDebtHolder,
    createDebtHolderAndUpdateDebt
}