import { useContext, useState, useEffect } from "react";

import { Dialog } from "@mui/material";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import DocumentPreviewer, { ViewerDocTypeOptions, ViewerModeOptions } from "../.././../../../components/Common/DocumentViewer/DocumentViewer";
import { ButtonProps } from "../.././../../../components/Common/Button/BasicButton";
import { PdfIcon } from "../.././../../../components/Common/Icons/Iconography";
import colors from "../.././../../../components/Common/componentStyling/Colors";
import { produceDoc } from "../../../../context/UniversalSurvey/asyncActions/documents";
import { GenerateType } from "../../../../libs/resources/enums/documents/generateType";

type Props = {
    open: boolean;
    onClose: () => void;
    viewerDocType: ViewerDocTypeOptions;
    viewerMode?: ViewerModeOptions;
};

export default function DocumentViewerModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [customButtons, setCustomButtons] = useState<ButtonProps[]>([]);

    useEffect(() => {
        makeCustomButtons();
    }, [state.documents.previewBeingViewed, state.documents.htmlInViewerBody, state.documents.htmlInViewerFull])

    function onDiscard() {
        dispatch({ type: actions.SET_PREVIEW_BEING_VIEWED, payload: undefined });
        dispatch({ type: actions.SET_PDF_LINK, payload: undefined });
        dispatch({ type: actions.SET_DOC_IN_VIEWER_NAME, payload: undefined });
        dispatch({ type: actions.SET_HTML_IN_VIEWER_BODY, payload: undefined });
        dispatch({ type: actions.SET_HTML_IN_VIEWER_FULL, payload: undefined });
        props.onClose();
    }

    function makeCustomButtons() {
        const buttons: ButtonProps[] = [];
        buttons.push({
            typeOf: "secondary",
            label: { text: "Close", inputId: "preview-discard"},
            onClick: onDiscard
        });

        if (state.documents.previewBeingViewed) {
            buttons.push({
                typeOf: "secondary",
                leftIcon: <PdfIcon color={colors.BLACK} />,
                label: { text: "Produce", inputId: "preview-produce-pdf" },
                onClick: () => { 
                    produceDoc(dispatch, String(state.deal.dealInfo?.id), state.documents.previewBeingViewed!, GenerateType.PDF);
                }
            })
            buttons.push({
                typeOf: "secondary",
                leftIcon: <DescriptionOutlinedIcon />,
                label: { text: "Produce .docx", inputId: "preview-produce-docx" },
                onClick: () => { 
                    produceDoc(dispatch, String(state.deal.dealInfo?.id), state.documents.previewBeingViewed!, GenerateType.DOCX);
                }
            })
        }

        setCustomButtons(buttons);
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
        >
            <DocumentPreviewer
                docType={props.viewerDocType}
                mode={props.viewerMode}
                htmlBody={state.documents.htmlInViewerBody}
                setHTML={(value) => dispatch({ type: actions.SET_HTML_IN_VIEWER_BODY, payload: value })}
                html={state.documents.htmlInViewerFull}
                file={state.documents.pdfLink}
                title={state.documents.docInViewerName}
                isPreviewLoading={state.documents.previewBeingViewed !== undefined && state.documents.htmlInViewerFull === undefined}
                isPreparingDocument={state.documents.isDocumentPreparing}
                hidePreviewButton
                customButtons={customButtons}
                parentHeight={window.innerHeight}
                produceButtonsDisabled={state.documents.isDocumentPreparing}
            />
        </Dialog>
    );
}