// Importing React tools
import { useContext, useState, useEffect, useCallback, useRef } from "react";

// Importing mui design kit components
import { Grid, styled } from "@mui/material";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes";

// Importing application components
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl";
import DisplayRolePills from "../Components/TransactionPills/DisplayRolePills";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import { LandRegistryOptionsON, LandRegistryOptionsAB, LandRegistryOptionsBC, PropertyPinTypeOptions, PropertyTypeOptions } from "../../../libs/resources/options";
import {
    saveProperty,
    updatePropertyTabName,
    changeSelectedProperty,
    getPropertyTabName,
    addPropertyPin,
    savePropertyPin,
    deletePropertyPin,
    updatePropertyHeaderTitle
} from "../../../context/UniversalSurvey/asyncActions/properties";
import { Property } from "../../../libs/types/UniversalSurvey/Property/property";
import { EntityTypeForRoles } from "../../../libs/resources/enums/roles";
import { createRolesForEntity } from "../../../context/UniversalSurvey/asyncActions/roles/roles";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import BasicButton from "../../../../components/Common/Button/BasicButton";
import TableBase from "../../../../components/Common/Table/TableBase";
import { FireInsuranceNumberType } from "../../../libs/resources/enums/fireInsurance";
import { Body, H4 } from "../../../../components/Common/Typography/index";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import { navigateURL } from "../../../context/UniversalSurvey/asyncActions/deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { SimplePropertyPin } from "../../../libs/types/UniversalSurvey/PropertyPins/simplePropertyPin";
import { PropertyPinType } from "../../../libs/resources/enums/propertyPinType";
import { addOrdinal, formatPropertyPinType } from "../../../libs/utils/formatValues";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import {
    createCondoCorpRecordFromGlobal,
    createCondoCorporationOptionsList,
    getCondoCorporationInfo,
    saveCondoCorporation,
    updateCondoCorporationPropertyManagementCompany
} from "../../../context/UniversalSurvey/asyncActions/condoCorporations";
import { CondoCorporationListItem } from "../../../libs/types/UniversalSurvey/CondoCorporation/condoCorporation";
import CondoCorporationModal from "../Components/Modals/CondoCorporationModal";
import {
    createPropertyManagementCompanyOptionsList,
    createPropertyManagementCompanyRecordFromGlobal,
    setPropertyManagementCompanyBeingEdited
} from "../../../context/UniversalSurvey/asyncActions/propertyManagementCompanies";
import { PropertyManagementCompanyListItem } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompany";
import PropertyManagementCompanyModal from "../Components/Modals/PropertyManagementCompanyModal";
import BasicAccordion from "../../../../components/Common/Accordion/Accordion";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import colors from "../../../../components/Common/componentStyling/Colors";
import AddressGrid from "../Components/AddressGrid/AddressGrid";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";


export default function PropertySection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [propertyObj, setPropertyObj] = useState<Property>(state.properties.propertyInfo!);
    const [condoCorpModalActive, setCondoCorpModalActive] = useState<boolean>(false);
    const [propertyManagementCompanyModalActive, setPropertyManagementCompanyModalActive] = useState<boolean>(false);
    const [confirmDeletePinSetList, setConfirmDeletePinSetList] = useState<boolean[]>([]);

    const propertyObjRef = useRef<Property | undefined>();
    propertyObjRef.current = propertyObj;
    
    useEffect(() => {
        cleanUpEmptyPins();
        checkIfPinsAreSaving();

        return () => {
            cleanUpEmptyPins();
            dispatch({ type: actions.SET_PROPERTY_INFO, payload: undefined });
        }
    }, [])

    useEffect(() => {
        let tempConfirmDeletePinSetList = [];
        if (propertyObj.property_pin_records) {
            for (const pinSet of propertyObj.property_pin_records) {
                tempConfirmDeletePinSetList.push(false);
            }
        }
        setConfirmDeletePinSetList(tempConfirmDeletePinSetList);
    }, [propertyObj.property_pin_records]);

    useEffect(() => {
        if (state.properties.propertyInfo) {
            setPropertyObj(state.properties.propertyInfo);
            createRolesForEntity(dispatch, state.properties.propertyInfo.roles!, state.transactions.transactionList);
            if (state.properties.propertiesBeingSaved.includes(state.properties.propertyInfo.id)) {
                dispatch({ type: actions.SET_IS_PROPERTY_SAVING, payload: true });
            }
            checkIfPinsAreSaving();
        }
    }, [state.properties.propertyInfo?.id])

    useEffect(() => {
        if (state.properties.propertyInfo?.condo_corporation_id) {
            getCondoCorporationInfo(dispatch, String(state.deal.dealInfo?.id), state.properties.propertyInfo.condo_corporation_id);
        }
        createCondoCorporationOptionsList(dispatch, String(state.deal.dealInfo?.id), state.properties.propertyInfo?.condo_corporation_id);
        createPropertyManagementCompanyOptionsList(dispatch, String(state.deal.dealInfo?.id), propertyObj.condo_corporation_record?.property_management_company_record?.id);
    }, [state.properties.propertyInfo?.condo_corporation_id])

    useEffect(() => {
        dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: state.condoCorporations.condoCorpInfo?.property_management_company_record?.id })
    }, [state.condoCorporations.condoCorpInfo])

    useEffect(() => {
        updatePropertyInfo("condo_corporation_id", state.condoCorporations.selectedCC?.id)
    }, [state.condoCorporations.selectedCC])

    useEffect(() => {
        if (state.condoCorporations.selectedCC) {
            updateCondoCorporationPropertyManagementCompany(dispatch, String(state.deal.dealInfo?.id), state.condoCorporations.selectedCC.id, state.propertyManagementCompanies.selectedPMC?.id);
        }
    }, [state.propertyManagementCompanies.selectedPMC])

    useEffect(() => {
        if (state.properties.propertyInfo?.property_pin_records && propertyObj.property_pin_records) {
            // the only time when property_pin_records in context state is being updated is when creating/removing pins
            // need to compare the list to what is in local state and add/remove any, while keeping existing
            if (state.properties.propertyInfo.property_pin_records.length < propertyObj.property_pin_records.length) {
                // deleted some, need to remove from local state
                const tempPins = [...propertyObj.property_pin_records];
                for (let i = tempPins.length - 1; i >= 0; i--) {
                    const matchingContextPin = state.properties.propertyInfo.property_pin_records.find((contextPin) => contextPin.id === tempPins[i].id);
                    if (!matchingContextPin) {
                        tempPins.splice(i, 1);
                    }
                }
                setPropertyObj({ ...propertyObj, property_pin_records: tempPins });
            } else if (state.properties.propertyInfo.property_pin_records.length > propertyObj.property_pin_records.length) {
                // added pin, need to insert into local state
                setPropertyObj({ ...propertyObj, property_pin_records: [...propertyObj.property_pin_records, state.properties.propertyInfo.property_pin_records[state.properties.propertyInfo.property_pin_records.length - 1]] });
            }
        }
    }, [state.properties.propertyInfo?.property_pin_records])

    useEffect(() => {
        if (state.properties.shouldRefreshProperty) {
            changeSelectedProperty(dispatch, String(state.deal.dealInfo?.id), state.properties.propertyInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_PROPERTY, payload: false });
        }
    }, [state.properties.shouldRefreshProperty])

    useEffect(() => {
        if (state.properties.shouldRefreshPin) {
            changeSelectedProperty(dispatch, String(state.deal.dealInfo?.id), state.properties.propertyInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_PROPERTY_PIN, payload: false });
        }
    }, [state.properties.shouldRefreshPin])

    function cleanUpEmptyPins() {
        if (propertyObjRef.current?.property_pin_records) {
            for (const pin of propertyObjRef.current.property_pin_records) {
                if (!pin.full_legal_description && !pin.pin && !pin.short_legal_description && !pin.type) {
                    deletePropertyPin(dispatch, String(state.deal.dealInfo?.id), propertyObj.id, pin.id);
                }
            }
        }
    }

    function isThereAnEmptyPin() {
        if (propertyObj.property_pin_records) {
            for (const pin of propertyObj.property_pin_records) {
                if (!pin.full_legal_description && !pin.pin && !pin.short_legal_description && !pin.type) {
                    return true;
                }
            }
        }
        return false;
    }

    function checkIfPinsAreSaving() {
        if (state.properties.propertyInfo?.property_pin_records) {
            for (const pin of state.properties.propertyInfo.property_pin_records) {
                if (state.properties.pinsBeingSaved.includes(pin.id)) {
                    dispatch({ type: actions.SET_IS_PROPERTY_PIN_SAVING, payload: true });
                }
            }
        }
    }

    function updatePropertyInfo<
        K extends keyof Property,
        V extends Property[K]
    >(key: K, value: V): void {
        const tempPropertyObj = { ...propertyObj };
        tempPropertyObj[key] = value;
        setPropertyObj(tempPropertyObj);
        updatePropertyTabName(dispatch, tempPropertyObj);
        updatePropertyHeaderTitle(dispatch, tempPropertyObj, state.roles.roles, state.deal.dealInfo );
    }

    const debouncedSave = useCallback(
        debounce((propertyInfo) => {
            saveProperty(dispatch, String(state.deal.dealInfo?.id), propertyInfo);
            dispatch({ type: actions.REMOVE_PROPERTY_BEING_SAVED, payload: propertyInfo.id });
        }, 1000), []);

    const prevProperty = usePrevious(propertyObj);
    useDidUpdateEffect(() => {
        if (prevProperty?.id === propertyObj.id) {
            dispatch({ type: actions.ADD_PROPERTY_BEING_SAVED, payload: propertyObj.id });
            debouncedSave(propertyObj);
        }
    }, [propertyObj]);

    function updateCondoCorp(value: CondoCorporationListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: value.id });
                getCondoCorporationInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createCondoCorpRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value, state.propertyManagementCompanies.simplePmcRecords);
            }
        }
    }

    function updatePropertyManagementCompany(value: PropertyManagementCompanyListItem | null) {
        if (value !== null) {
            if (value.id === -1) {
                if (state.condoCorporations.condoCorpInfo) {
                    saveCondoCorporation(dispatch, String(state.deal.dealInfo?.id), { ...state.condoCorporations.condoCorpInfo, self_managed: true, property_management_company_record_id: undefined, property_management_company_record: undefined });
                }
                dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: undefined });
            } else {
                if (value.isRecord) {
                    dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: value.id });
                } else {
                    createPropertyManagementCompanyRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
                }
            }
        }
    }

    function updatePropertyPinInfo<
        K extends keyof SimplePropertyPin,
        V extends SimplePropertyPin[K]
    >(key: K, value: V, pinId: number): void {
        const tempPins = [...propertyObj.property_pin_records!];
        const matchingPin = tempPins.find((pin) => pin.id === pinId);
        if (matchingPin) {
            dispatch({ type: actions.ADD_PROPERTY_PIN_BEING_SAVED, payload: pinId });
            const tempPin = { ...matchingPin };
            tempPin[key] = value;
            tempPins[tempPins.indexOf(matchingPin)] = tempPin;
            const tempPropertyObj = { ...propertyObj };
            tempPropertyObj.property_pin_records = tempPins;
            setPropertyObj(tempPropertyObj);
            debouncedSavePins(tempPins, [...state.properties.pinsBeingSaved, pinId])
        }
    }

    const debouncedSavePins = useCallback(
        debounce((pins, pinsBeingSaved) => {
            for (const pin of pins) {
                if (pinsBeingSaved!.includes(pin.id)) {
                    savePropertyPin(dispatch, String(state.deal.dealInfo?.id), propertyObj.id, pin)
                    dispatch({ type: actions.REMOVE_PROPERTY_PIN_BEING_SAVED, payload: pin.id });
                }
            }
        }, 1000), [state.properties.pinsBeingSaved, propertyObj.property_pin_records]);

    function updateConfirmDeletePinSetStates(index: number, state: boolean) {
        let tempDeletePinSetStates = [ ...confirmDeletePinSetList ];
        tempDeletePinSetStates[index] = state;
        setConfirmDeletePinSetList(tempDeletePinSetStates);
    }

    function getLandRegistryOfficeOptions() {
        switch (state.deal.dealInfo?.region_id) {
            case 1:
                return LandRegistryOptionsON;
            case 2:
                return LandRegistryOptionsBC;
            case 3:
                return LandRegistryOptionsAB;
            default:
                return [];
        }
    }

    return (
        (state.properties.isPropertySaving || isObjectLoading(state.dataSheet.objectsLoading, [Loading.CondoCorporationList, Loading.PropertyManagementCompanyList])) ? <CircularLoader containerHeight="70vh" /> :
            <div>
                <CondoCorporationModal
                    open={condoCorpModalActive}
                    onClose={() => setCondoCorpModalActive(false)}
                />
                <PropertyManagementCompanyModal
                    open={propertyManagementCompanyModalActive}
                    onClose={() => setPropertyManagementCompanyModalActive(false)}
                />
                <DisplayRolePills
                    addRolePill
                    entityId={propertyObj.id}
                    entityLabel={getPropertyTabName(propertyObj)}
                    entityType={EntityTypeForRoles.Property}
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={propertyObj.address}
                            setAddress={(value) => updatePropertyInfo("address", value)}
                            id="property_address"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            inputProps={{ "aria-label": "Jurisdiction" }}
                            value={propertyObj.jurisdiction}
                            onChange={(e) => updatePropertyInfo("jurisdiction", e.target.value)}
                            label={{ text: "Jurisdiction", inputId: "jurisdiction" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            inputProps={{ "aria-label": "Municipality" }}
                            value={propertyObj.municipality}
                            onChange={(e) => updatePropertyInfo("municipality", e.target.value)}
                            label={{ text: "Municipality", inputId: "municipality" }}
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <BooleanControl
                            checked={propertyObj.registration_method === "registry" ? false : true}
                            labels={["Registry", "Land Title"]}
                            onChange={(e) => updatePropertyInfo("registration_method", propertyObj.registration_method === "registry" ? "land_title" : "registry")}
                            label={{ text: "Registration Method", inputId: "registration_method" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ComboBox
                            label={{ text: "Land Registry Office", inputId: "land_registry_office" }}
                            options={getLandRegistryOfficeOptions()}
                            placeholder="Select an option..."
                            value={propertyObj.land_registry_office}
                            onChangeFn={(value) => updatePropertyInfo("land_registry_office", value as string)}
                            isHoverActionHidden={propertyObj.land_registry_office ? false : true}
                            handleClear={() => updatePropertyInfo("land_registry_office", undefined)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            inputProps={{ "aria-label": "Roll Number" }}
                            value={propertyObj.roll_number}
                            onChange={(e) => updatePropertyInfo("roll_number", e.target.value)}
                            label={{ text: "Roll Number", inputId: "roll_number" }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BooleanControl
                            checked={propertyObj.has_potl}
                            onChange={(e) => updatePropertyInfo("has_potl", !propertyObj.has_potl)}
                            label={{ text: "POTL", inputId: "has_potl" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Dropdown
                            value={propertyObj.property_type ? propertyObj.property_type : undefined}
                            onChange={(e) => updatePropertyInfo("property_type", e.target.value as string)}
                            placeholder="Select an option..."
                            options={PropertyTypeOptions}
                            label={{ text: "Property Type" }}
                            isHoverActionHidden={propertyObj.property_type ? false : true}
                            handleClear={() => updatePropertyInfo("property_type", undefined)}
                        />
                    </Grid>
                    {propertyObj.property_type === "Residential Condominium" && (
                        <>
                            <Grid item xs={2}>
                                <BasicTextInput
                                    fullWidth
                                    inputProps={{ "aria-label": "Condo Fee" }}
                                    value={propertyObj.condo_fee}
                                    moneyField
                                    valueType="positive"
                                    onChange={(e) => updatePropertyInfo("condo_fee", Number(e.target.value))}
                                    label={{ text: "Condo Fee", inputId: "condo_fee" }}
                                />
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={6}>
                                <ComboBox
                                    label={{ text: "Condo Corporation", inputId: "condo_corporation" }}
                                    value={state.condoCorporations.selectedCC}
                                    canEdit={state.condoCorporations.selectedCC ? true : false}
                                    onEditClick={() => {
                                        setCondoCorpModalActive(true);
                                        dispatch({ type: actions.SET_EDITING_CONDO_CORPORATION, payload: true });
                                    }}
                                    options={state.condoCorporations.optionList}
                                    placeholder="Select condo corporation"
                                    createEntity="condo corporation"
                                    setCreationModalOpen={() => setCondoCorpModalActive(true)}
                                    onChangeFn={(value) => updateCondoCorp(value as CondoCorporationListItem)}
                                    isHoverActionHidden={state.condoCorporations.selectedCC ? false : true}
                                    handleClear={() => {
                                        dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: undefined });
                                        dispatch({ type: actions.SET_SELECTED_CONDO_CORPORATION, payload: undefined });
                                        dispatch({ type: actions.SET_CONDO_CORPORATION_INFO, payload: undefined });
                                    }}
                                    searchableKeys={["name", "abbr_name"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {state.condoCorporations.selectedCC && (
                                    <ComboBox
                                        label={{ text: "Property Management", inputId: "property_management" }}
                                        value={state.condoCorporations.condoCorpInfo?.self_managed ? { id: -1, name: "Self-managed", label: "Self-managed" } : state.propertyManagementCompanies.selectedPMC}
                                        canEdit={state.propertyManagementCompanies.selectedPMC ? true : false}
                                        onEditClick={() => {
                                            setPropertyManagementCompanyModalActive(true);
                                            setPropertyManagementCompanyBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.propertyManagementCompanies.selectedPMC!.id);
                                        }}
                                        options={[{ id: -1, name: "Self-managed", label: "Self-managed" }, ...state.propertyManagementCompanies.optionList]}
                                        placeholder="Select property management"
                                        createEntity="property management"
                                        setCreationModalOpen={() => setPropertyManagementCompanyModalActive(true)}
                                        onChangeFn={(value) => updatePropertyManagementCompany(value as PropertyManagementCompanyListItem)}
                                        isHoverActionHidden={state.propertyManagementCompanies.selectedPMC ? false : true}
                                        handleClear={() => dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: undefined })}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                    {propertyObj.property_type === "Multi-unit Residential" && (
                        <Grid item xs={2}>
                            <BasicTextInput
                                fullWidth
                                inputProps={{ "aria-label": "Number of Units" }}
                                value={propertyObj.number_of_units}
                                onChange={(e) => updatePropertyInfo("number_of_units", Number(e.target.value))}
                                label={{ text: "Number of Units", inputId: "number_of_units" }}
                            />
                        </Grid> 
                    )}
                    {propertyObj.property_pin_records?.map((pin, i) => (
                        <Grid item xs={12} key={i}>
                            <BasicAccordion 
                                title={`${addOrdinal(i + 1)} PIN Set`}
                                defaultExpanded
                                onDelete={() => updateConfirmDeletePinSetStates(i, true)}
                                id={`pin-set-${i}-accordion`}
                                dialogOpen={confirmDeletePinSetList[i]}
                                dialog={confirmDeletePinSetList[i] ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeletePinSetList[i]}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this pin set? This is permanent and irreversible."
                                        onCancel={() => updateConfirmDeletePinSetStates(i, false)}
                                        onSubmit={() => {
                                            deletePropertyPin(dispatch, String(state.deal.dealInfo?.id), propertyObj.id, pin.id)
                                            updateConfirmDeletePinSetStates(i, false);
                                        }}
                                        submitText="Delete PIN Set"
                                        hideBackdrop
                                        top={2 * (document.getElementById(`pin-set-${i}-accordion`)?.getBoundingClientRect().top! + document.getElementById(`pin-set-${i}-accordion`)?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeletePinSetList[i] ? colors.ORANGE_90o : undefined}
                            >
                                <Grid container rowSpacing={3} columnSpacing={5}>
                                    <Grid item xs={4}>
                                        <BasicTextInput
                                            fullWidth
                                            multiline
                                            placeholder="Property's legal description"
                                            inputProps={{ "aria-label": `Legal Description ${i+1}` }}
                                            value={pin.full_legal_description}
                                            onChange={(e) => updatePropertyPinInfo("full_legal_description", e.target.value, pin.id)}
                                            label={{ text: "Legal Description", inputId: `pin_legal_description_${i}` }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container rowSpacing={3} columnSpacing={5}>
                                            <Grid item xs={6}>
                                                <BasicTextInput
                                                    fullWidth
                                                    placeholder="Property's PIN"
                                                    inputProps={{ "aria-label": `PIN ${i+1}` }}
                                                    value={pin.pin}
                                                    onChange={(e) => updatePropertyPinInfo("pin", e.target.value, pin.id)}
                                                    label={{ text: `PIN ${i+1}`, inputId: `pin_${i+1}` }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Dropdown
                                                    value={pin.type}
                                                    onChange={(e) => updatePropertyPinInfo("type", e.target.value as PropertyPinType, pin.id)}
                                                    placeholder="Select type..."
                                                    options={PropertyPinTypeOptions}
                                                    label={{ text: "PIN Type" }}
                                                    formatValue={formatPropertyPinType}
                                                    handleClear={() => updatePropertyPinInfo("type", undefined, pin.id)}
                                                    isHoverActionHidden={pin.type ? false : true}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <BasicTextInput
                                                    fullWidth
                                                    placeholder="Property's legal description"
                                                    inputProps={{ "aria-label": `Short Legal Description ${i+1}` }}
                                                    value={pin.short_legal_description}
                                                    onChange={(e) => updatePropertyPinInfo("short_legal_description", e.target.value, pin.id)}
                                                    label={{ text: "Short Legal Description", inputId: `pin_short_legal_description_${i+1}` }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BasicAccordion>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <BasicButton
                            label={{ text: "Add PIN Set", inputId: "add-pin-set" }}
                            action="add"
                            typeOf="secondary"
                            onClick={() => addPropertyPin(dispatch, String(state.deal.dealInfo?.id), propertyObj.id)}
                            disabled={isThereAnEmptyPin()}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PaddedH4>Fire Insurance</PaddedH4>
                    </Grid>
                    {propertyObj.fire_insurance_record ?
                        <Grid item xs={12}>
                            <TableBase 
                                columnHeadings={[
                                    { label: "Insurance Company", objKey: "company_name"},
                                    { label: `${propertyObj.fire_insurance_record.type === FireInsuranceNumberType.Policy ? "Policy" : "Binder"} Number`, objKey: "number"},
                                    { label: "Gtd. Replc. Costs", objKey: "guaranteed_replacement_costs" },
                                    { label: "All Mortgages Noted", objKey: "interest_noted" },
                                    { label: "Coverage Amount", objKey: "coverage_amount" }
                                ]}
                                tableRows={[{
                                    //@ts-ignore
                                    company_name: propertyObj.fire_insurance_record.fire_insurance_company_record?.name ?? "",
                                    number: propertyObj.fire_insurance_record.number,
                                    guaranteed_replacement_costs: propertyObj.fire_insurance_record.guaranteed_replacement_costs ? "Yes" : "No",
                                    interest_noted: propertyObj.fire_insurance_record.interest_noted ? "Yes" : "No",
                                    coverage_amount: propertyObj.fire_insurance_record.coverage_amount ? <Body>{FormatNumeric({value: propertyObj.fire_insurance_record.coverage_amount})}</Body> : ""
                                }]}
                                dataAlignment={["left", "left", "left", "left", "left"]}
                                id="property-fire-insurance"
                            />
                        </Grid> :
                        <Grid item xs={12}>
                            <BasicButton
                                label={{ text: "Add Fire Insurance", inputId: "add-fire-insurance" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => {
                                    dispatch({ type: actions.SET_ADDING_FIRE_INSURANCE_FROM_PROPERTY_SECTION, payload: true });
                                    navigateURL(dispatch, String(state.deal.dealInfo?.id), "data-sheet", Sections.FireInsurance, undefined);
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </div>
    )
};

const PaddedH4 = styled(H4)({
    paddingTop: "1.6rem"
})