import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { Affidavit } from "../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { SimpleAffidavit } from "../../../libs/types/UniversalSurvey/Affivadit/simpleAffidavit";
import { defaultSimpleAffidavit } from "../../../libs/resources/defaults/frontend/defaultSimpleAffidavit";

export type AffidavitsState = {
    affidavitBeingEdited: Affidavit | undefined;
    affidavitsInDeal: SimpleAffidavit[];
}

export const defaultAffidavitsState: AffidavitsState = {
    affidavitBeingEdited: undefined,
    affidavitsInDeal: []
}

export function affidavitsReducer(state: AffidavitsState, action: Record<string, any>): AffidavitsState {
    switch (action.type) {
        case actions.SET_AFFIDAVIT_BEING_EDITED:
            return { ...state, affidavitBeingEdited: action.payload };
        case actions.ADD_AFFIDAVIT:
            const affidavitsCopy = [...state.affidavitsInDeal, action.payload];
            return { ...state, affidavitsInDeal: affidavitsCopy };
        case actions.SET_AFFIDAVITS_IN_DEAL:
            return { ...state, affidavitsInDeal: action.payload }
        case actions.REMOVE_AFFIDAVIT:
            return { ...state, affidavitsInDeal: findAndRemove([...state.affidavitsInDeal], ["id"], [action.payload]) };
        case actions.UPDATE_AFFIDAVIT:
            const tempAffidavits = [...state.affidavitsInDeal];
            const matchingAffidavit = tempAffidavits.find((affidavit) => affidavit.id === action.payload.affidavit.id);
            if(matchingAffidavit) {
                const newAffidavit: any = { ...defaultSimpleAffidavit };
                for(const key of Object.keys(newAffidavit)) {
                    newAffidavit[key as keyof SimpleAffidavit] = action.payload.affidavit[key];
                }
                newAffidavit.client_record = action.payload.client;
                tempAffidavits[tempAffidavits.indexOf(matchingAffidavit)] = newAffidavit;
            }
            return { ...state, affidavitsInDeal: tempAffidavits };
        default:
            return state;
    }
}