import { TitleInsurance } from "../../../types/UniversalSurvey/TitleInsurance/titleInsurance";

export const defaultTitleInsurance: TitleInsurance = {
    id: -1,
    provider_deal_id: undefined,
    status: undefined,
    policy_number: undefined,
    coverage_amount: undefined,
    name_of_insured: undefined,
    date_created: undefined,
    date_updated: undefined
}