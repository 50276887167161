import * as actions from "../actionTypes"

import { SimpleGuarantor } from "../../../libs/types/UniversalSurvey/Guarantor/simpleGuarantor";
import { findAndRemove } from "../../../libs/utils/arrays";

export type GuarantorsState = {
    guarantors: SimpleGuarantor[];
}

export const defaultGuarantorsState: GuarantorsState = {
    guarantors: []
}

export function guarantorsReducer(state: GuarantorsState, action: Record<string, any>): GuarantorsState {
    switch (action.type) {
        case actions.SET_GUARANTORS:
            return { ...state, guarantors: action.payload };
        case actions.ADD_NEW_GUARANTOR:
            return { ...state, guarantors: [...state.guarantors, action.payload] };
        case actions.REMOVE_GUARANTOR:
            return { ...state, guarantors: findAndRemove([...state.guarantors], ["id"], [action.payload.id]) };
        default:
            return state;
    }
}