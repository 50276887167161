import { Dispatch } from "react";

import { AdminActions } from "../context";
import { AdminActionTypes } from "../actionTypes";
import { AdminSections } from "../../../libs/resources/enums/sections";
import { AdminRouteSectionMapping, AdminSectionMapping } from "../../../libs/resources/mappings/sections";

const PATHNAME = "/dashboard/conveyance/admin";

function navigateAdminURL(dispatch: Dispatch<AdminActions>, section: string | undefined) {
    const newSection = AdminRouteSectionMapping[section as keyof typeof AdminRouteSectionMapping];
    const path = `${PATHNAME}/${newSection ? newSection : ''}`;

    dispatch({ type: AdminActionTypes.SET_PAGE_URL, payload: path });
}

function handleAdminSectionChange(dispatch: Dispatch<AdminActions>, section: string | undefined) {
    let newSectionTitle: AdminSections | undefined = undefined
    if (section) {
        newSectionTitle = AdminSectionMapping[section as keyof typeof AdminSectionMapping];
    } else {
        newSectionTitle = AdminSections.LegalProfessional;
    }
    dispatch({ type: AdminActionTypes.SET_CURR_SECTION, payload: newSectionTitle });
}

export {
    navigateAdminURL,
    handleAdminSectionChange
}