import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack, styled } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { INPUT_SMALL, Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import { PADDED_GRID } from "../../../../../../components/Common/componentStyling/Styles";
import { LegalFirm } from "../../../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { defaultLegalFirm } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirm";
import { regionListToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyLegalFirmModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [firmObj, setFirmObj] = useState<LegalFirm>({ ...defaultLegalFirm });

    useEffect(() => {
        if(state.legalFirms.recordBeingEdited) {
            setFirmObj(state.legalFirms.recordBeingEdited);
        }
    }, [state.legalFirms.recordBeingEdited]);

    function discard() {
        dispatch({ type: actions.SET_LEGAL_FIRM_BEING_EDITED, payload: undefined });
        setFirmObj({ ...defaultLegalFirm });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={firmObj.name}
            subtitle="LEGAL FIRM"
        >
            <PADDED_GRID container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Province(s) of Operation:
                        </INPUT_SMALL>
                        <Large>
                            {regionListToString(firmObj.all_regions, firmObj.regions)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Is a Deeded Partner:
                        </INPUT_SMALL>
                        <Large>
                            {firmObj.partner ? "Yes" : "No"}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            GST Number:
                        </INPUT_SMALL>
                        <Large>
                            {firmObj.gst_number}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={firmObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
            </PADDED_GRID>
        </ModalBase>
    );
}