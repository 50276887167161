import { useState, useContext, Dispatch, useEffect } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { BasicClient } from "../../../../libs/types/UniversalSurvey/Client/basicClient";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {
    currClientId: number;
    relationship: any;
    submitFn: (dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: any, clientInfo: BasicClient, currClientId?: number) => void;
};

export default function BasicClientModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [errorState, setErrorState] = useState<{ email?: boolean }>({ email: false });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    function submit() {
        const client: BasicClient = {
            first_name: firstName,
            last_name: lastName,
            email: email
        }
        props.submitFn(dispatch, String(state.deal.dealInfo?.id), props.relationship, client, props.currClientId);
    }

    function handleDiscard() {
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setErrorState({ email: false });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!firstName || !lastName || !email || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, firstName, lastName, email]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscard}
            onSubmit={submit}
            size="small"
            title={props.title ?? "New Client"}
            saveDisabled={saveDisabled}
            closeAfterSaving={handleDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveClient])}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        autoFocus
                        label={{ text: "First Name", inputId: "client_first_name", isRequired: true }}
                        placeholder="Client's first name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Last Name", inputId: "client_last_name", isRequired: true }}
                        placeholder="Client's last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Email", inputId: "client_email", isRequired: true }}
                        placeholder="Client's email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fieldType="email"
                        error={{
                            showError: true,
                            setErrorState: setErrorState,
                            errorKey: "email",
                            errorState: errorState
                        }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}