import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import { formatGovernmentLevel } from "../../../../../libs/utils/formatValues";
import Link from "../../../../../../components/Common/Links/Link";
import { Government } from "../../../../../libs/types/UniversalSurvey/Government/government";
import { defaultGovernment } from "../../../../../libs/resources/defaults/frontend/defaultGovernment";
import { addressToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyGovernmentModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [governmentObj, setGovernmentObj] = useState<Government>({ ...defaultGovernment });

    useEffect(() => {
        if(state.governments.editingGovernmentRecord && state.governments.governmentInfo) {
            setGovernmentObj(state.governments.governmentInfo);
        }
    }, [state.governments.editingGovernmentRecord]);

    function discard() {
        dispatch({ type: actions.SET_EDITING_GOVERNMENT_RECORD, payload: false });
        setGovernmentObj({ ...defaultGovernment });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={governmentObj.name}
            subtitle="GOVERNMENT"
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Abbreviated Name:
                        </Small>
                        <Large>
                            {governmentObj.abbr_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Level:
                        </Small>
                        <Large>
                            {formatGovernmentLevel(governmentObj.level)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Department:
                        </Small>
                        <Large>
                            {governmentObj.department}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Fax Number:
                        </Small>
                        <Link
                            href={governmentObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Address:
                        </Small>
                        <Large style={{whiteSpace: "pre-line"}}>
                            {addressToString(governmentObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Email:
                        </Small>
                        <Link
                            href={governmentObj.general_email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Website:
                        </Small>
                        <Link 
                            href={governmentObj.website}
                            type="url"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={governmentObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>
                            {governmentObj.government?.notes}
                        </Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}