import { MortgageBroker } from "../../../types/UniversalSurvey/MortgageBroker/mortgageBroker";
import { VerificationStatus } from "../../enums/verificationStatus";

export const defaultMortgageBroker: MortgageBroker = {
    id: -1,
    name: undefined,
    verification_status: VerificationStatus.Unverified,
    all_regions: undefined,
    email: undefined,
    phone_work: undefined,
    phone_cell: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    mortgage_brokerage_record: undefined,
    mortgage_broker: undefined,
    regions: []
}