import { RoleTypes } from "../enums/roles";

export const RoleMapping = {
    "subject_property": RoleTypes.Subject,
    "collateral_property": RoleTypes.Collateral,
    "other_property": RoleTypes.Other,
    "borrower": RoleTypes.Borrower,
    "advisee": RoleTypes.Advisee,
    "transferee": RoleTypes.Transferee,
    "transferor": RoleTypes.Transferor,
    "guarantor": RoleTypes.Guarantor,
    "client": RoleTypes.Client,
    "consenting_spouse": RoleTypes.ConsentingSpouse,
    "power_of_attorney": RoleTypes.POA,
    "executor_of_estate": RoleTypes.EOE
};