import { DocumentCategory } from "../../../resources/enums/documents/category";
import { BaseDocument } from "../../../types/UniversalSurvey/Documents/baseDocument";

export const defaultBaseDocument: BaseDocument = {
    id: -1,
    parent_id: undefined,
    type: undefined,
    category: DocumentCategory.AutoDiscovered,
    generate_type: undefined,
    name: undefined,
    date_created: undefined,
    date_updated: undefined,

    tags: [],
    context: undefined
}