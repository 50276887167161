import { useContext, useState, useCallback, useEffect, useRef } from "react";

// Importing material components
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

// Importing application components
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import { Body, STYLED_H4 } from "../../../../components/Common/Typography/index";
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import { DealActingAs } from "../../../libs/resources/enums/dealActingAs";
import { ActingAsOptions, DealClientCapacityOptions } from "../../../libs/resources/options";
import { addOrdinal, formatActingAs, formatDealClientCapacity } from "../../../libs/utils/formatValues";
import colors from '../../../../components/Common/componentStyling/Colors';
import { getClerkOptions, updateDealData } from "../../../context/UniversalSurvey/asyncActions/deal";
import { debounce } from "../../../libs/utils/debounce";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import { SimpleSigningAppointment } from "../../../libs/types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";
import {
    addSigningAppointment,
    getSigningAppointmentsInDeal,
    removeSigningAppointment,
    updateSigningAppointment,
    updateSigningAppointmentClients
} from "../../../context/UniversalSurvey/asyncActions/signingAppointments";
import BasicDatePicker from "../../../../components/Common/DatePicker/BasicDatePicker";
import BasicTimePicker from "../../../../components/Common/TimePicker/BasicTimePicker";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { getClientTabName, getDealClients, updateClientOwnershipShare } from "../../../context/UniversalSurvey/asyncActions/clients";
import { LegalProfessionalType } from "../../../libs/resources/enums/legalProfessionalType";
import { createLegalProfessionalOptionsList, createLegalProfessionalRecordFromGlobal, setLegalProfessionalRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalProfessionals";
import { SimpleLegalProfessional } from "../../../libs/types/UniversalSurvey/LegalProfessional/simpleLegalProfessional";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import LegalProfessionalModal from "../Components/Modals/LegalProfessionalModal";
import LegalFirmModal from "../Components/Modals/LegalFirmModal/LegalFirmModal";
import LegalFirmOfficeModal from "../Components/Modals/LegalFirmOfficeModal/LegalFirmOfficeModal";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { createLegalFirmRecordFromGlobal, getLegalProfessionalFirmOptions, setFirmRecordForLegalProfessional, setLegalFirmRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalFirms";
import { createLegalFirmOfficeRecordFromGlobal, getLegalProfessionalFirmOfficeOptions, setLegalFirmOfficeRecordBeingEdited, setOfficeRecordForLegalProfessional } from "../../../context/UniversalSurvey/asyncActions/legalFirmOffices";
import { LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import ReadonlyLegalProfessionalModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalProfessionalModal";
import ReadonlyLegalFirmModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmModal";
import ReadonlyLegalFirmOfficeModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmOfficeModal";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl";
import BasicAccordion from "../../../../components/Common/Accordion/Accordion";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import AddressGrid from "../Components/AddressGrid/AddressGrid";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import { isObjectLoading } from "../../../libs/utils/loading";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { DealClientCapacity } from "../../../libs/resources/enums/dealClientCapacity";
import { RecordType } from "../../../libs/resources/enums/recordTypes";
import { RoleTypesDB } from "../../../libs/resources/enums/roles";
import { DealStatus } from "../../../libs/resources/enums/dealStatus";
import { DashboardUser, DashboardUserListItem } from "../../../libs/types/UniversalSurvey/DashboardUser/dashboardUser";
import { defaultDashboardUser } from "../../../libs/resources/defaults/frontend/defaultDashboardUser";

export default function DealOverviewSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [dealObj, setDealObj] = useState<Deal>(state.deal.dealInfo!);

    const [legalProfessionalModalActive, setLegalProfessionalModalActive] = useState<boolean>(false);
    const [legalProfessionalModalType, setLegalProfessionalModalType] = useState<LegalProfessionalOptions>(LegalProfessionalOptions.DealLawyer);
    const [legalFirmModalActive, setLegalFirmModalActive] = useState<boolean>(false);
    const [legalFirmModalType, setLegalFirmModalType] = useState<LegalProfessionalOptions>(LegalProfessionalOptions.DealLawyer);
    const [legalOfficeModalActive, setLegalOfficeModalActive] = useState<boolean>(false);
    const [legalOfficeModalType, setLegalOfficeModalType] = useState<LegalProfessionalOptions>(LegalProfessionalOptions.DealLawyer);
    const [readonlyProfessionalModalActive, setReadonlyProfessionalModalActive] = useState<boolean>(false);
    const [readonlyFirmModalActive, setReadonlyFirmModalActive] = useState<boolean>(false);
    const [readonlyOfficeModalActive, setReadonlyOfficeModalActive] = useState<boolean>(false);
    const [clerkComboboxValue, setClerkComboboxValue] = useState<DashboardUserListItem | undefined>(undefined);

    const [tempSigningAppts, setTempSigningAppts] = useState<SimpleSigningAppointment[]>([]);
    const [tempSigningDates, setTempSigningDates] = useState<(Date | null)[]>([]);
    const [selectedClients, setSelectedClients] = useState<(SimpleClient & { label: string })[][]>([]);
    const [confirmDeleteSigningApptList, setConfirmDeleteSigningApptList] = useState<boolean[]>([]);

    const signingApptListRef = useRef<SimpleSigningAppointment[]>(state.signingAppointments.signingApptsInDeal);

    useEffect(() => {
        createLegalProfessionalOptionsList(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.region_id)

        if (state.deal.dealInfo?.lawyer_record) {
            const lawyerListItem: LegalProfessionalListItem = {
                ...state.deal.dealInfo.lawyer_record,
                isRecord: true,
                label: state.deal.dealInfo.lawyer_record.name
            };
            getLegalProfessionalFirmOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, LegalProfessionalOptions.DealLawyer);

            if (state.deal.dealInfo.lawyer_record.legal_firm_record) {
                const firmListItem: LegalFirmListItem = {
                    ...state.deal.dealInfo.lawyer_record.legal_firm_record,
                    isRecord: true,
                    label: state.deal.dealInfo.lawyer_record.legal_firm_record.name
                }
                getLegalProfessionalFirmOfficeOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, firmListItem, LegalProfessionalOptions.DealLawyer);
            }
        }

        if (state.deal.dealInfo?.signer_record) {
            const signerListItem: LegalProfessionalListItem = {
                ...state.deal.dealInfo.signer_record,
                isRecord: true,
                label: state.deal.dealInfo.signer_record.name
            };
            getLegalProfessionalFirmOptions(dispatch, String(state.deal.dealInfo?.id), signerListItem, LegalProfessionalOptions.DealSigner);
            
            if (state.deal.dealInfo.signer_record.legal_firm_record) {
                const firmListItem: LegalFirmListItem = {
                    ...state.deal.dealInfo.signer_record.legal_firm_record,
                    isRecord: true,
                    label: state.deal.dealInfo.signer_record.legal_firm_record.name
                }
                getLegalProfessionalFirmOfficeOptions(dispatch, String(state.deal.dealInfo?.id), signerListItem, firmListItem, LegalProfessionalOptions.DealSigner);
            }
        }
    }, [])

    useEffect(() => {
        getLegalProfessionalFirmOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.lawyer_record?.id),
            LegalProfessionalOptions.DealLawyer
        )
        updateDeal("lawyer_record", state.deal.dealInfo?.lawyer_record);
    }, [state.deal.dealInfo?.lawyer_record?.id])

    useEffect(() => {
        getLegalProfessionalFirmOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.signer_record?.id),
            LegalProfessionalOptions.DealSigner
        );
        updateDeal("signer_record", state.deal.dealInfo?.signer_record);
    }, [state.deal.dealInfo?.signer_record?.id])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.deal.dealInfo?.lawyer_record?.id, firmRecord: state.deal.dealInfo?.lawyer_record?.legal_firm_record }
        });
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.lawyer_record?.id),
            state.legalFirms.lawyerFirmOptionList.find((firm) => firm.id === state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id),
            LegalProfessionalOptions.DealLawyer
        );
        updateDeal("lawyer_record", state.deal.dealInfo?.lawyer_record);
    }, [state.deal.dealInfo?.lawyer_record?.legal_firm_record])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.deal.dealInfo?.signer_record?.id, firmRecord: state.deal.dealInfo?.signer_record?.legal_firm_record }
        });
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.signer_record?.id),
            state.legalFirms.signerFirmOptionList.find((firm) => firm.id === state.deal.dealInfo?.signer_record?.legal_firm_record?.id),
            LegalProfessionalOptions.DealSigner
        );
        updateDeal("signer_record", state.deal.dealInfo?.signer_record);
    }, [state.deal.dealInfo?.signer_record?.legal_firm_record])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.deal.dealInfo?.lawyer_record?.id, officeRecord: state.deal.dealInfo?.lawyer_record?.legal_firm_office_record }
        });
        updateDeal("lawyer_record", state.deal.dealInfo?.lawyer_record);
    }, [state.deal.dealInfo?.lawyer_record?.legal_firm_office_record])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.deal.dealInfo?.signer_record?.id, officeRecord: state.deal.dealInfo?.signer_record?.legal_firm_office_record }
        });
        updateDeal("signer_record", state.deal.dealInfo?.signer_record);
    }, [state.deal.dealInfo?.signer_record?.legal_firm_office_record])

    useEffect(() => {
        if (state.deal.dealInfo?.lawyer_record && state.deal.dealInfo?.lawyer_record.id === state.deal.dealInfo?.signer_record?.id) {
            if (state.legalFirmOffices.lawyerOfficeOptionList.length > 0 && state.legalFirmOffices.signerOfficeOptionList.length === 0) {
                dispatch({ type: actions.SET_SIGNER_OFFICE_OPTION_LIST, payload: state.legalFirmOffices.lawyerOfficeOptionList });
            } else if (state.legalFirmOffices.signerOfficeOptionList.length > 0 && state.legalFirmOffices.lawyerOfficeOptionList.length === 0) {
                dispatch({ type: actions.SET_LAWYER_OFFICE_OPTION_LIST, payload: state.legalFirmOffices.signerOfficeOptionList });
            }
        }
    }, [state.deal.dealInfo?.lawyer_record, state.deal.dealInfo?.signer_record])

    useEffect(() => {
        if (state.deal.dealInfo?.owner_clerk) {
            setClerkComboboxValue(state.deal.clerkOptions.find((clerk) => clerk.id === state.deal.dealInfo?.owner_clerk?.id));
        } else if (state.deal.dealInfo?.owner_clerk_name) {
            const clerkListItem: DashboardUserListItem = {
                ...defaultDashboardUser,
                label: state.deal.dealInfo.owner_clerk_name
            }
            setClerkComboboxValue(clerkListItem);
        } else {
            setClerkComboboxValue(undefined);
        }
    }, [state.deal.clerkOptions, state.deal.dealInfo?.owner_clerk])

    function updateDeal<
        K extends keyof Deal,
        V extends Deal[K]
    >(key: K, value: V): void {
        const tempDeal: Deal = { ...dealObj };
        tempDeal[key] = value;
        setDealObj(tempDeal);
    }

    function updateSigningApptInfo<
        K extends keyof SimpleSigningAppointment,
        V extends SimpleSigningAppointment[K]
    >(key: K, value: V, signingApptId: number): void {
        if (key === "signing_date") {
            // @ts-ignore
            value = new Date(value); // necessary because the timepicker component uses a different object type
        }
        dispatch({ type: actions.ADD_SIGNING_APPOINTMENT_BEING_SAVED, payload: signingApptId });
        const matchingSigningAppt = tempSigningAppts.find((signingApptItem) => signingApptItem.id === signingApptId);
        if (matchingSigningAppt) {
            const tempSigningApptItems = [ ...tempSigningAppts ];
            const tempSigningApptItem = { ...matchingSigningAppt };
            tempSigningApptItem[key] = value;
            tempSigningApptItems[tempSigningApptItems.indexOf(matchingSigningAppt)] = tempSigningApptItem;
            setTempSigningAppts(tempSigningApptItems);
            dispatch({ type: actions.SET_SIGNING_APPOINTMENTS_IN_DEAL, payload: tempSigningApptItems });
            debouncedSaveSigningApptItem(tempSigningApptItems, [...state.signingAppointments.signingApptsBeingSaved, signingApptId]);
        }
    }

    useEffect(() => {
        getSigningAppointmentsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getDealClients(dispatch, String(state.deal.dealInfo?.id));
        cleanUpSigningAppts();
        getClerkOptions(dispatch);

        return () => cleanUpSigningAppts();
    }, []);

    useEffect(() => {
        signingApptListRef.current = state.signingAppointments.signingApptsInDeal;
        setTempSigningAppts(state.signingAppointments.signingApptsInDeal);
        let signingDates: (Date | null)[] = [];
        let tempDeleteApptStates: boolean[] = [];
        let selectedClientsInSigningAppts: (SimpleClient & { label: string })[][] = [[]];
        let count = 0;

        for (let signingAppt of state.signingAppointments.signingApptsInDeal) {
            signingDates.push(signingAppt.signing_date ?? null);
            tempDeleteApptStates.push(false);
            selectedClientsInSigningAppts.push([]);
            for (let client of signingAppt.clients ?? []) {
                selectedClientsInSigningAppts[count].push({ ...client, roles: [], label: getClientTabName(client, false, true) });
            }
            count += 1;
        }

        setTempSigningDates(signingDates);
        setSelectedClients(selectedClientsInSigningAppts);
        setConfirmDeleteSigningApptList(tempDeleteApptStates);
    }, [state.signingAppointments.signingApptsInDeal]);

    useEffect(() => {
        if (state.signingAppointments.shouldRefreshSigningAppt) {
            getSigningAppointmentsInDeal(dispatch, String(state.deal.dealInfo?.id));
            dispatch({ type: actions.SET_SHOULD_REFRESH_SIGNING_APPOINTMENT, payload: false });
        }
    }, [state.signingAppointments.shouldRefreshSigningAppt]);

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            const label = getClientTabName(client, false, true);
            options.push({ ...client, roles: [], label: label });
        }
        return options;
    }

    const debouncedSave = useCallback(
        debounce((dealObj) => {
            updateDealData(dispatch, dealObj);
        }, 1000), []);

    const prevDealInfo = usePrevious(dealObj);
    useDidUpdateEffect(() => {
        if (prevDealInfo?.id === dealObj.id) {
            debouncedSave(dealObj);
        }
    }, [dealObj]);

    const debouncedSaveSigningApptItem = useCallback(
        debounce((signingApptList, signingApptsBeingSaved) => {
            for (let signingApptItem of signingApptList) {
                if (signingApptsBeingSaved!.includes(signingApptItem.id)) {
                    updateSigningAppointment(dispatch, String(state.deal.dealInfo?.id), signingApptItem);
                    dispatch({ type: actions.REMOVE_SIGNING_APPOINTMENT_BEING_SAVED, payload: signingApptItem.id });
                }
            }
        }, 1000), [state.signingAppointments.signingApptsBeingSaved, state.signingAppointments.signingApptsInDeal]);

    function cleanUpSigningAppts() {
        for (const signingAppt of signingApptListRef.current) {
            if (isEmptySigningAppt(signingAppt)) {
                removeSigningAppointment(dispatch, String(state.deal.dealInfo?.id), signingAppt.id);
            }
        }
    }

    function isEmptySigningAppt(signingAppt: SimpleSigningAppointment): boolean {
        let isEmpty = true;
        for (const key of Object.keys(signingAppt)) {
            if (key === "id" || key === "remote" || key === "signed_at_subject") continue;
            else if (key === "address") {
                if (signingAppt.signed_at_subject) continue;
                for (const addressKey of Object.keys(signingAppt.address)) {
                    if (addressKey === "id" || addressKey === "country") continue;
                    if (signingAppt.address[addressKey as keyof typeof signingAppt.address]) isEmpty = false;
                }
            }
            else if (key === "clients") {
                if (signingAppt[key] && signingAppt[key]!.length > 0) isEmpty = false;
            } else {
                if (signingAppt[key as keyof typeof signingAppt]) isEmpty = false;
            }
        }
        return isEmpty
    }

    function updateClientList(clientList: (SimpleClient & { label: string })[], index: number) {
        let tempSelectedClients = [ ...selectedClients ]
        tempSelectedClients[index] = clientList;
        setSelectedClients(tempSelectedClients);
        updateSigningAppointmentClients(dispatch, String(state.deal.dealInfo?.id), clientList, tempSigningAppts[index])
    }

    function isClientsOptionAlreadySelected(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        const errorObj = { isDisabled: true, message: "Client has already been selected for a signing appointment" };
        for (let selectedClientList of selectedClients) {
            if (selectedClientList.find((client: any) => client.id === option.id)) {
                return errorObj;
            }
        }
        return { isDisabled: false, message: "" };
    }

    function hasEmptySigningAppt(): boolean {
        for (let signingAppt of tempSigningAppts) {
            if (isEmptySigningAppt(signingAppt)) return true;
        }
        return false;
    }

    function updateLegalProfessional(value: LegalProfessionalListItem | null, type: LegalProfessionalOptions) {
        if (value) {
            if (value.isRecord) {
                if (type === LegalProfessionalOptions.DealSigner) {
                    updateDealData(dispatch, { ...state.deal.dealInfo!, signer_record: value as SimpleLegalProfessional });
                } else {
                    updateDealData(dispatch, { ...state.deal.dealInfo!, lawyer_record: value as SimpleLegalProfessional });
                }
            } else {
                createLegalProfessionalRecordFromGlobal(dispatch, state.deal.dealInfo!, state.lenders.lenderInfo, value, type);
            }
        } else {
            if (type === LegalProfessionalOptions.DealSigner) {
                updateDealData(dispatch, { ...state.deal.dealInfo!, signer_record: undefined });
            } else {
                updateDealData(dispatch, { ...state.deal.dealInfo!, lawyer_record: undefined });
            }
        }
    }

    function updateLegalFirm(value: LegalFirmListItem | null, type: LegalProfessionalOptions) {
        if (value) {
            if (value.isRecord) {
                setFirmRecordForLegalProfessional(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.lawyer_record?.id, state.deal.dealInfo?.signer_record?.id, state.lenders.lenderInfo?.lawyer?.id, value, type);
            } else {
                createLegalFirmRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.lawyer_record?.id, state.deal.dealInfo?.signer_record?.id, state.lenders.lenderInfo?.lawyer?.id, value, type);
            }
        } else {
            setFirmRecordForLegalProfessional(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.lawyer_record?.id, state.deal.dealInfo?.signer_record?.id, state.lenders.lenderInfo?.lawyer?.id, null, type);
            dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { isSigner: type === LegalProfessionalOptions.DealSigner, record: undefined } });
            if (state.deal.dealInfo?.lawyer_record?.id === state.deal.dealInfo?.signer_record?.id) {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { isSigner: type !== LegalProfessionalOptions.DealSigner, record: undefined } });
            }
        }
    }

    function updateLegalFirmOffice(value: LegalFirmOfficeListItem | null, type: LegalProfessionalOptions) {
        if (value) {
            if (value.isRecord) {
                setOfficeRecordForLegalProfessional(
                    dispatch,
                    String(state.deal.dealInfo?.id),
                    state.deal.dealInfo?.lawyer_record?.id,
                    state.deal.dealInfo?.signer_record?.id,
                    state.lenders.lenderInfo?.lawyer?.id,
                    type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record?.id! : state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!,
                    value,
                    type
                );
            } else {
                createLegalFirmOfficeRecordFromGlobal(
                    dispatch,
                    String(state.deal.dealInfo?.id),
                    state.deal.dealInfo?.lawyer_record?.id,
                    state.deal.dealInfo?.signer_record?.id,
                    state.lenders.lenderInfo?.lawyer?.id,
                    type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record?.id! : state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!,
                    value,
                    type
                );
            }
        } else {
            setOfficeRecordForLegalProfessional(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.deal.dealInfo?.lawyer_record?.id,
                state.deal.dealInfo?.signer_record?.id,
                state.lenders.lenderInfo?.lawyer?.id,
                type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record?.id! : state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!,
                null,
                type
            );
        }
    }

    function onLegalProfessionalEdit(id: number, type: LegalProfessionalOptions) {
        setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), id);
        setLegalProfessionalModalActive(true);
        setLegalProfessionalModalType(type);
    }

    function onLegalFirmEdit(id: number, type: LegalProfessionalOptions) {
        setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), id);
        setLegalFirmModalActive(true);
        setLegalFirmModalType(type);
    }

    function onLegalOfficeEdit(firmId: number, officeId: number, type: LegalProfessionalOptions) {
        setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), firmId, officeId);
        setLegalOfficeModalActive(true);
        setLegalOfficeModalType(type);
    }

    function onLegalProfessionalView(id: number) {
        setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), id);
        setReadonlyProfessionalModalActive(true);
    }

    function onLegalFirmView(id: number) {
        setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), id);
        setReadonlyFirmModalActive(true);
    }

    function onLegalOfficeView(firmId: number, officeId: number) {
        setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), firmId, officeId);
        setReadonlyOfficeModalActive(true);
    }

    function updateConfirmDeleteSigningApptStates(index: number, state: boolean) {
        let tempDeleteApptStates = [ ...confirmDeleteSigningApptList ];
        tempDeleteApptStates[index] = state;
        setConfirmDeleteSigningApptList(tempDeleteApptStates);
    }

    function updateOwnershipShare(clientId: number, share: number) {
        const matchingClient = state.clients.clientsInDeal.find((client) => client.id === clientId);
        if (matchingClient) {
            let tempClient = { ...matchingClient, tenants_in_common_share: share };
            updateClientOwnershipShare(dispatch, String(state.deal.dealInfo?.id), tempClient);
        }
    }

    function getTennantsInCommon(): SimpleClient[] {
        return state.clients.clientsInDeal.filter((client) => client.roles?.some((role) => role.record_type === RecordType.TitleTransfer && role.role === RoleTypesDB.Transferee))
    }

    function updateClerk(value: DashboardUserListItem | undefined) {
        if (value) {
            updateDealData(dispatch, {
                ...state.deal.dealInfo!,
                owner_clerk: value as DashboardUser,
                owner_clerk_name: undefined
            });
        } else {
            updateDealData(dispatch, { ...state.deal.dealInfo!, owner_clerk: undefined });
        }
    }

    return (
        <div>
            <LegalProfessionalModal
                open={legalProfessionalModalActive}
                type={legalProfessionalModalType}
                onClose={() => setLegalProfessionalModalActive(false)}
            />
            <LegalFirmModal
                open={legalFirmModalActive}
                type={legalFirmModalType}
                onClose={() => setLegalFirmModalActive(false)}
            />
            <LegalFirmOfficeModal
                open={legalOfficeModalActive}
                type={legalOfficeModalType}
                onClose={() => setLegalOfficeModalActive(false)}
            />
            <ReadonlyLegalProfessionalModal
                open={readonlyProfessionalModalActive}
                onClose={() => setReadonlyProfessionalModalActive(false)}
            />
            <ReadonlyLegalFirmModal
                open={readonlyFirmModalActive}
                onClose={() => setReadonlyFirmModalActive(false)}
            />
            <ReadonlyLegalFirmOfficeModal
                open={readonlyOfficeModalActive}
                onClose={() => setReadonlyOfficeModalActive(false)}
            />
            {isObjectLoading(state.dataSheet.objectsLoading, [Loading.LegalProfessionalList, Loading.LawyerFirmList, Loading.LawyerOfficeList, Loading.SignerFirmList, Loading.SignerOfficeList, Loading.SigningAppointments, Loading.ClerkOptions]) ? <CircularLoader containerHeight="70vh" /> :
            <Grid container rowSpacing={2} columnSpacing={5}>

                <Grid item xs={12}>
                    <STYLED_H4>Legal Professionals</STYLED_H4>
                </Grid>

                {/* Lawyer */}
                <Grid item xs={4}>
                    <ComboBox
                        value={state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.lawyer_record?.id && professional.isRecord)}
                        options={state.legalProfessionals.optionList.filter((professional) => professional.type === LegalProfessionalType.Lawyer)}
                        onChangeFn={(value) => updateLegalProfessional(value as LegalProfessionalListItem, LegalProfessionalOptions.DealLawyer)}
                        placeholder="Select lawyer..."
                        label={{ text: "Lawyer", inputId: "lawyer" }}
                        handleClear={() => updateLegalProfessional(null, LegalProfessionalOptions.DealLawyer)}
                        isHoverActionHidden={state.deal.dealInfo?.lawyer_record ? false : true}
                        createEntity="lawyer"
                        setCreationModalOpen={() => {
                            setLegalProfessionalModalActive(true);
                            setLegalProfessionalModalType(LegalProfessionalOptions.DealLawyer);
                        }}
                        canEdit={state.deal.dealInfo?.lawyer_record ? true : false}
                        onEditClick={() => onLegalProfessionalEdit(state.deal.dealInfo?.lawyer_record?.id!, LegalProfessionalOptions.DealLawyer)}
                        handleView={() => onLegalProfessionalView(state.deal.dealInfo?.lawyer_record?.id!)}
                    />
                </Grid>
                <Grid item xs={4}>
                    {state.deal.dealInfo?.lawyer_record &&
                        <ComboBox
                            value={state.legalFirms.lawyerFirmOptionList.find((firm) => firm.id === state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id && firm.isRecord)}
                            options={state.legalFirms.lawyerFirmOptionList}
                            onChangeFn={(value) => updateLegalFirm(value as LegalFirmListItem, LegalProfessionalOptions.DealLawyer)}
                            placeholder="Select firm..."
                            label={{ text: "Law Firm", inputId: "lawyer-firm" }}
                            handleClear={() => updateLegalFirm(null, LegalProfessionalOptions.DealLawyer)}
                            isHoverActionHidden={state.deal.dealInfo?.lawyer_record.legal_firm_record ? false : true}
                            createEntity="firm"
                            setCreationModalOpen={() => {
                                setLegalFirmModalActive(true);
                                setLegalFirmModalType(LegalProfessionalOptions.DealLawyer);
                            }}
                            canEdit={state.deal.dealInfo.lawyer_record.legal_firm_record ? true : false}
                            onEditClick={() => onLegalFirmEdit(state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!, LegalProfessionalOptions.DealLawyer)}
                            handleView={() => onLegalFirmView(state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!)}
                        />
                    }
                </Grid>
                <Grid item xs={4}>
                    {state.deal.dealInfo?.lawyer_record?.legal_firm_record &&
                        <ComboBox
                            value={state.legalFirmOffices.lawyerOfficeOptionList.find((office) => office.id === state.deal.dealInfo?.lawyer_record?.legal_firm_office_record?.id && office.isRecord)}
                            options={state.legalFirmOffices.lawyerOfficeOptionList}
                            onChangeFn={(value) => updateLegalFirmOffice(value as LegalFirmOfficeListItem, LegalProfessionalOptions.DealLawyer)}
                            placeholder="Select office..."
                            label={{ text: "Law Office", inputId: "lawyer-office" }}
                            handleClear={() => updateLegalFirmOffice(null, LegalProfessionalOptions.DealLawyer)}
                            isHoverActionHidden={state.deal.dealInfo?.lawyer_record.legal_firm_office_record ? false : true}
                            createEntity="office"
                            setCreationModalOpen={() => {
                                setLegalOfficeModalActive(true);
                                setLegalOfficeModalType(LegalProfessionalOptions.DealLawyer);
                            }}
                            canEdit={state.deal.dealInfo.lawyer_record.legal_firm_office_record ? true : false}
                            onEditClick={() => onLegalOfficeEdit(state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!, state.deal.dealInfo?.lawyer_record?.legal_firm_office_record?.id!, LegalProfessionalOptions.DealLawyer)}
                            handleView={() => onLegalOfficeView(state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id!, state.deal.dealInfo?.lawyer_record?.legal_firm_office_record?.id!)}
                        />
                    }
                </Grid>

                {/* Signer */}
                <Grid item xs={4}>
                    <ComboBox
                        value={state.legalProfessionals.optionList.find((professional) => professional.id === state.deal.dealInfo?.signer_record?.id  && professional.isRecord)}
                        options={state.legalProfessionals.optionList}
                        onChangeFn={(value) => updateLegalProfessional(value as LegalProfessionalListItem, LegalProfessionalOptions.DealSigner)}
                        placeholder="Select signer..."
                        label={{ text: "Signer", inputId: "signer" }}
                        handleClear={() => updateLegalProfessional(null, LegalProfessionalOptions.DealSigner)}
                        isHoverActionHidden={state.deal.dealInfo?.signer_record ? false : true}
                        createEntity="signer"
                        setCreationModalOpen={() => {
                            setLegalProfessionalModalActive(true);
                            setLegalProfessionalModalType(LegalProfessionalOptions.DealSigner);
                        }}
                        canEdit={state.deal.dealInfo?.signer_record ? true : false}
                        onEditClick={() => onLegalProfessionalEdit(state.deal.dealInfo?.signer_record?.id!, LegalProfessionalOptions.DealSigner)}
                        handleView={() => onLegalProfessionalView(state.deal.dealInfo?.signer_record?.id!)}
                    />
                </Grid>
                <Grid item xs={4}>
                    {state.deal.dealInfo?.signer_record &&
                        <ComboBox
                            value={state.legalFirms.signerFirmOptionList.find((firm) => firm.id === state.deal.dealInfo?.signer_record?.legal_firm_record?.id && firm.isRecord)}
                            options={state.legalFirms.signerFirmOptionList}
                            onChangeFn={(value) => updateLegalFirm(value as LegalFirmListItem, LegalProfessionalOptions.DealSigner)}
                            placeholder="Select firm..."
                            label={{ text: "Signer's Firm", inputId: "signer-firm" }}
                            handleClear={() => updateLegalFirm(null, LegalProfessionalOptions.DealSigner)}
                            isHoverActionHidden={state.deal.dealInfo?.signer_record.legal_firm_record ? false : true}
                            createEntity="firm"
                            setCreationModalOpen={() => {
                                setLegalFirmModalActive(true);
                                setLegalFirmModalType(LegalProfessionalOptions.DealSigner);
                            }}
                            canEdit={state.deal.dealInfo.signer_record.legal_firm_record ? true : false}
                            onEditClick={() => onLegalFirmEdit(state.deal.dealInfo?.signer_record?.legal_firm_record?.id!, LegalProfessionalOptions.DealSigner)}
                            handleView={() => onLegalFirmView(state.deal.dealInfo?.signer_record?.legal_firm_record?.id!)}
                        />
                    }
                </Grid>
                <Grid item xs={4}>
                    {state.deal.dealInfo?.signer_record?.legal_firm_record &&
                        <ComboBox
                            value={state.legalFirmOffices.signerOfficeOptionList.find((office) => office.id === state.deal.dealInfo?.signer_record?.legal_firm_office_record?.id && office.isRecord)}
                            options={state.legalFirmOffices.signerOfficeOptionList}
                            onChangeFn={(value) => updateLegalFirmOffice(value as LegalFirmOfficeListItem, LegalProfessionalOptions.DealSigner)}
                            placeholder="Select office..."
                            label={{ text: "Signer's Office", inputId: "signer-office" }}
                            handleClear={() => updateLegalFirmOffice(null, LegalProfessionalOptions.DealSigner)}
                            isHoverActionHidden={state.deal.dealInfo?.signer_record.legal_firm_office_record ? false : true}
                            createEntity="office"
                            setCreationModalOpen={() => {
                                setLegalOfficeModalActive(true);
                                setLegalOfficeModalType(LegalProfessionalOptions.DealSigner);
                            }}
                            canEdit={state.deal.dealInfo.signer_record.legal_firm_office_record ? true : false}
                            onEditClick={() => onLegalOfficeEdit(state.deal.dealInfo?.signer_record?.legal_firm_record?.id!, state.deal.dealInfo?.signer_record?.legal_firm_office_record?.id!, LegalProfessionalOptions.DealSigner)}
                            handleView={() => onLegalOfficeView(state.deal.dealInfo?.signer_record?.legal_firm_record?.id!, state.deal.dealInfo?.signer_record?.legal_firm_office_record?.id!)}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <STYLED_H4>Other Information</STYLED_H4>
                </Grid>
                <Grid item xs={4}>
                    <BooleanControl
                        checked={dealObj?.status === DealStatus.Closed}
                        labels={["Open", "Closed"]}
                        onChange={(e) => updateDeal("status", dealObj.status === DealStatus.Open ? DealStatus.Closed : DealStatus.Open)}
                        label={{ text: "Deal Status", inputId: "deal_status" }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Dropdown
                        value={dealObj.acting_as}
                        onChange={(e) => updateDeal("acting_as", e.target.value as DealActingAs)}
                        placeholder="Select an option..."
                        options={ActingAsOptions}
                        label={{ text: "Deeded is acting for"}}
                        isHoverActionHidden={dealObj.acting_as ? false : true}
                        handleClear={() => updateDeal("acting_as", undefined)}
                        formatValue={formatActingAs}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BasicDatePicker
                        label={{ text: "Closing Date", inputId: "deal_closing_date" }}
                        value={dealObj.closing_date ?? null}
                        onChange={(e) => updateDeal("closing_date", e as Date)}
                        minDate={state.deal.dealInfo?.opened_date ?? undefined}
                        disableWeekends
                        error={{
                            showError: true
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ComboBox
                        value={clerkComboboxValue}
                        options={state.deal.clerkOptions}
                        onChangeFn={(value) => updateClerk(value as DashboardUserListItem)}
                        placeholder="Select clerk..."
                        label={{ text: "Law Clerk", inputId: "owner_clerk_name" }}
                        handleClear={() => updateClerk(undefined)}
                        isHoverActionHidden={state.deal.dealInfo?.owner_clerk ? false : true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Dropdown
                        value={dealObj.client_capacity}
                        onChange={(e) => updateDeal("client_capacity", e.target.value as DealClientCapacity)}
                        placeholder="Select an option..."
                        options={DealClientCapacityOptions}
                        label={{ text: "Client Capacity", inputId: "deal_client_capacity" }}
                        isHoverActionHidden={dealObj.client_capacity ? false : true}
                        handleClear={() => updateDeal("client_capacity", undefined)}
                        formatValue={formatDealClientCapacity}
                    />
                </Grid>
                {dealObj.client_capacity === DealClientCapacity.TenantsInCommon && (
                    <>
                        {getTennantsInCommon().length > 0 && (
                            <Grid item xs={12}>
                                <StyledBody>Ownership Shares</StyledBody>
                            </Grid>
                        )}
                        {getTennantsInCommon().map((client) =>
                            <Grid item xs={4} key={`client-ownership-${client.id}`}>
                                <BasicTextInput
                                    fullWidth
                                    label={{ text: getClientTabName(client), inputId: `client-ownership-share-${client.id}` }}
                                    inputProps={{ "aria-label": `Client ${client.id} Ownership Share` }}
                                    value={client.tenants_in_common_share ? String(client.tenants_in_common_share) : undefined}
                                    onChange={(e) => { Number(e.target.value) <= 100 ? updateOwnershipShare(client.id, Number(e.target.value)) : undefined}}
                                    percentageField
                                    placeholder="0"
                                />
                            </Grid>
                        )}
                    </>
                )}
            
                <Grid item xs={12}>
                    <STYLED_H4>Signing Appointments</STYLED_H4>
                </Grid>
                {tempSigningAppts.map((signingAppt, index) => (
                    <PaddedGrid item xs={12} key={index} isLast={index === tempSigningAppts.length - 1}>
                        <BasicAccordion 
                            title={`${addOrdinal(index + 1)} Signing Appointment`}
                            defaultExpanded
                            onDelete={() => updateConfirmDeleteSigningApptStates(index, true)}
                            id={`signing-appt-${index}-accordion`}
                            dialogOpen={confirmDeleteSigningApptList[index]}
                            dialog={confirmDeleteSigningApptList[index] ?
                                <GenericDialog
                                    action="destructive"
                                    open={confirmDeleteSigningApptList[index]}
                                    title="Confirm Delete"
                                    contentText="Are you sure you want to delete this signing appointment? This is permanent and irreversible."
                                    onCancel={() => updateConfirmDeleteSigningApptStates(index, false)}
                                    onSubmit={() => {
                                        removeSigningAppointment(dispatch, String(state.deal.dealInfo?.id), signingAppt.id)
                                        updateConfirmDeleteSigningApptStates(index, false);
                                    }}
                                    submitText="Delete Signing Appointment"
                                    hideBackdrop
                                    top={2 * (document.getElementById(`signing-appt-${index}-accordion`)?.getBoundingClientRect().top! + document.getElementById(`signing-appt-${index}-accordion`)?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                /> :
                                undefined
                            }
                            dialogBackgroundColor={confirmDeleteSigningApptList[index] ? colors.ORANGE_90o : undefined}
                        >
                            <Grid container rowSpacing={2} columnSpacing={5}>
                                <Grid item xs={6}>
                                    <BasicDatePicker
                                        value={signingAppt.signing_date ?? null}
                                        minDate={state.deal.dealInfo?.opened_date ?? undefined}
                                        onChange={(e) => { 
                                            let date = e as Date
                                            date.setHours(12);
                                            updateSigningApptInfo("signing_date", date, signingAppt.id);
                                            let tempDates = [ ...tempSigningDates ];
                                            tempDates[index] = date;
                                            setTempSigningDates(tempDates);
                                        }}
                                        label={{ text: "Signing Date", inputId: "signing-appt-signing_date" }}
                                        data-testid="signing-appt-signing_date"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BasicTimePicker
                                        value={tempSigningDates[index]}
                                        setValue={(e: Date | null) => {
                                            let tempDates = [ ...tempSigningDates ];
                                            tempDates[index] = e as Date;
                                            setTempSigningDates(tempDates);
                                            if (new Date(e ?? "").toString() !== "Invalid Date") {
                                                updateSigningApptInfo("signing_date", e as Date, signingAppt.id);
                                            }
                                        }}
                                        label={{ text: "Signing Time", inputId: "signing-appt-signing_time" }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ComboBox
                                        label={{ text: "Client(s)", inputId: "signing-appt-clients" }}
                                        options={getClientComboboxItems()}
                                        tokenField
                                        placeholder="Select client..."
                                        value={selectedClients[index]}
                                        isOptionDisabled={isClientsOptionAlreadySelected}
                                        // @ts-ignore
                                        onChangeFn={(value) => updateClientList(value, index)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BooleanControl
                                        checked={signingAppt.remote}
                                        onChange={() => updateSigningApptInfo("remote", !signingAppt.remote, signingAppt.id)}
                                        label={{ text: "Remote Signing", inputId: "remote" }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BooleanControl
                                        checked={signingAppt.signed_at_subject}
                                        onChange={() => updateSigningApptInfo("signed_at_subject", !signingAppt.signed_at_subject, signingAppt.id)}
                                        label={{ text: "Signed at Subject Property", inputId: "signed_at_subject" }}
                                    />
                                </Grid>
                                {!signingAppt.signed_at_subject && <Grid item xs={12}>
                                    <AddressGrid
                                        address={signingAppt.address}
                                        setAddress={(value) => updateSigningApptInfo("address", value, signingAppt.id)}
                                        id={`signing_appointment-${signingAppt.id}`}
                                    />
                                </Grid>}
                                <Grid item xs={6}>
                                    <BasicTextInput
                                        fullWidth
                                        value={signingAppt.jurisdiction}
                                        placeholder="Jurisdiction"
                                        onChange={(e) => updateSigningApptInfo("jurisdiction", e.target.value, signingAppt.id)}
                                        label={{ text: "Jurisdiction", inputId: "signing-appt-jurisdiction" }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BasicTextInput
                                        fullWidth
                                        value={signingAppt.municipality}
                                        placeholder="Municipality"
                                        onChange={(e) => updateSigningApptInfo("municipality", e.target.value, signingAppt.id)}
                                        label={{ text: "Municipality", inputId: "signing-appt-municipality" }}
                                    />
                                </Grid>
                            </Grid>
                        </BasicAccordion>
                    </PaddedGrid>
                ))}
                <Grid item xs={12}>
                    <BasicButton
                        label={{ text: "Add Signing Appointment", inputId: "add-signing-appt" }}
                        action="add"
                        typeOf="secondary"
                        onClick={() => addSigningAppointment(dispatch, String(state.deal.dealInfo?.id))}
                        disabled={hasEmptySigningAppt()}
                    />
                </Grid>
            </Grid>
            }
        </div>
    )
};

const PaddedGrid = styled(Grid, { shouldForwardProp: 
    (prop) => prop !== "isLast"
})<{
    isLast: boolean;
  }>(({ isLast }) => ({
    paddingBottom: isLast ? "2rem" : "0rem"
}));

const StyledBody = styled(Body)({
    paddingTop: "1rem",
    marginBottom: "-1rem"
})