import { useCallback, useContext, useEffect, useState } from "react";

import { Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AdminContext } from "../../../context/AdminPanel/context";
import { AdminActionTypes } from "../../../context/AdminPanel/actionTypes";
import { H4, INPUT_SMALL, Large, STYLED_H4, Small } from "../../../../components/Common/Typography/index";
import TableBase, { orderOptions } from "../../../../components/Common/Table/TableBase";
import { debounce } from "../../../libs/utils/debounce";
import { addressToStringShort, regionListToString } from "../../../libs/utils/address";
import { EditIcon, TrashIcon, XCircle } from "../../../../components/Common/Icons/Iconography";
import Colors from "../../../../components/Common/componentStyling/Colors";
import Theme from "../../../../components/Common/componentStyling/Theme";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import FinancialInfoReadonlyGrid from "../../UniversalSurvey/Components/FinancialInfoGrid/FinancialInfoReadonly";
import { isObjectLoading } from "../../../libs/utils/loading";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import { SimpleGlobalLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { 
    deleteLegalFirm,
    getLegalFirmOptions,
    setSelectedLegalFirm
} from "../../../context/AdminPanel/asyncActions/legalFirms";
import { GlobalLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import AdminLegalFirmModal from "../../../routes/UniversalSurvey/Components/Modals/LegalFirmModal/AdminLegalFirmModal";
import { defaultFinancialInfo } from "../../../libs/resources/defaults/frontend/defaultFinancialInfo";
import { DEFAULT_LEGAL_FIRM_OFFICE_RESULT_LIMIT, DEFAULT_LEGAL_FIRM_RESULT_LIMIT } from "../../../libs/utils/limits";
import { SimpleGlobalLegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import {
    deleteLegalFirmOffice,
    getLegalFirmOffices,
    setLegalFirmOfficeBeingEdited
} from "../../../context/AdminPanel/asyncActions/legalFirmOffices";
import AdminLegalFirmOfficeModal from "../../../routes/UniversalSurvey/Components/Modals/LegalFirmOfficeModal/AdminLegalFirmOfficeModal";

type LegalFirmTableRow = SimpleGlobalLegalFirm & { name: string | undefined };
type LegalFirmOfficeTableRow = _.Omit<SimpleGlobalLegalFirmOffice, "general_address"> & { general_address: string };

export default function LegalProfessionalSection() {
    const [state, dispatch] = useContext(AdminContext);

    const [deleteButtonHoveredRow, setDeleteButtonHoveredRow] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>("");
    const [sortOrder, setSortOrder] = useState<orderOptions>("asc");
    const [sortKey, setSortKey] = useState<string>("name");
    const [legalFirmToBeDeleted, setLegalFirmToBeDeleted] = useState<number | undefined>(undefined);
    const [deleteLegalFirmModalActive, setDeleteLegalFirmModalActive] = useState<boolean>(false);
    const [legalFirmModalActive, setLegalFirmModalActive] = useState<boolean>(false);
    const [legalFirmObj, setLegalFirmObj] = useState<GlobalLegalFirm | undefined>(undefined);
    const [viewingLegalFirm, setViewingLegalFirm] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(Number(DEFAULT_LEGAL_FIRM_RESULT_LIMIT));
    const [pageNumber, setPageNumber] = useState<number>(1);

    // legal firm offices
    const [searchValueOffices, setSearchValueOffices] = useState<string | undefined>("");
    const [sortOrderOffices, setSortOrderOffices] = useState<orderOptions>("asc");
    const [sortKeyOffices, setSortKeyOffices] = useState<string>("general_address");
    const [officeToBeDeleted, setOfficeToBeDeleted] = useState<number | undefined>(undefined);
    const [deleteOfficeModalActive, setDeleteOfficeModalActive] = useState<boolean>(false);
    const [officeModalActive, setOfficeModalActive] = useState<boolean>(false);
    const [rowsPerPageOffices, setRowsPerPageOffices] = useState<number>(Number(DEFAULT_LEGAL_FIRM_OFFICE_RESULT_LIMIT));
    const [pageNumberOffices, setPageNumberOffices] = useState<number>(1);

    function makeQuery(): URLSearchParams {
        const query = new URLSearchParams();
        if (searchValue) query.set("search", searchValue);
        if (sortOrder) query.set("sort_order", sortOrder);
        if (sortKey) query.set("sort_by", sortKey);
        query.set("offset", ((pageNumber - 1) * rowsPerPage).toString());
        query.set("limit", rowsPerPage.toString());
        return query;
    }

    function makeQueryOffices(): URLSearchParams {
        const query = new URLSearchParams();
        if (searchValueOffices) query.set("search", searchValueOffices);
        if (sortOrderOffices) query.set("sort_order", sortOrderOffices);
        if (sortKeyOffices) query.set("sort_by", sortKeyOffices);
        query.set("offset", ((pageNumberOffices - 1) * rowsPerPageOffices).toString());
        query.set("limit", rowsPerPageOffices.toString());
        return query;
    }

    useEffect(() => {
        setPageNumber(1);
        getLegalFirmOptions(dispatch, makeQuery());
    }, [searchValue, sortOrder, sortKey, rowsPerPage])

    useEffect(() => {
        if (state.legalFirms.selectedLegalFirm) {
            setPageNumberOffices(1);
            getLegalFirmOffices(dispatch, state.legalFirms.selectedLegalFirm.id, makeQueryOffices());
        }
    }, [searchValueOffices, sortOrderOffices, sortKeyOffices, rowsPerPageOffices]);

    useEffect(() => {
        getLegalFirmOptions(dispatch, makeQuery());
    }, [pageNumber]);

    useEffect(() => {
        if (state.legalFirms.selectedLegalFirm) {
            getLegalFirmOffices(dispatch, state.legalFirms.selectedLegalFirm.id, makeQueryOffices());
        }
    }, [pageNumberOffices]);

    useEffect(() => {
        if (state.legalFirms.shouldRefresh) {
            getLegalFirmOptions(dispatch, makeQuery());
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_SHOULD_REFRESH, payload: false });
        }
    }, [state.legalFirms.shouldRefresh]);

    useEffect(() => {
        if (state.legalFirmOffices.shouldRefresh && state.legalFirms.selectedLegalFirm) {
            getLegalFirmOffices(dispatch, state.legalFirms.selectedLegalFirm.id, makeQueryOffices());
            dispatch({ type: AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH, payload: false });
        }
    }, [state.legalFirmOffices.shouldRefresh]);

    useEffect(() => {
        setLegalFirmObj(state.legalFirms.selectedLegalFirm);
    }, [state.legalFirms.selectedLegalFirm])

    const debouncedOnSearch = useCallback(
        debounce((value) => {
            setSearchValue(value.toLowerCase());
        }, 500), []);

    const debouncedOnSearchOffices = useCallback(
        debounce((value) => {
            setSearchValueOffices(value.toLowerCase());
        }, 500), []);

    function makeTableRows(): LegalFirmTableRow[] {
        const tableRows = [];
        for (const firm of state.legalFirms.tableLegalFirms) {
            // TODO: Outline professionals/offices potentially?
            tableRows.push({ 
                ...firm
            })
        }
        return tableRows
    }

    function makeTableRowsOffices(): LegalFirmOfficeTableRow[] {
        const rows = [];
        for (const office of state.legalFirmOffices.legalFirmOffices) {
            rows.push({
                ...office,
                general_address: addressToStringShort(office?.general_address) 
            });
        }
        return rows;
    }

    function onSort(objKey: string, order: orderOptions): void {
        setSortKey(objKey);
        setSortOrder(order);
    }

    function onSortOffices(objKey: string, order: orderOptions): void {
        setSortKeyOffices(objKey);
        setSortOrderOffices(order);
    }

    function onView(row: LegalFirmTableRow) {
        setSelectedLegalFirm(dispatch, row.id);
        getLegalFirmOffices(dispatch, row.id, makeQueryOffices());
        setViewingLegalFirm(true);
    }

    function onEdit(row: LegalFirmTableRow) {
        setSelectedLegalFirm(dispatch, row.id);
        dispatch({ type: AdminActionTypes.SET_EDITING_LEGAL_FIRM, payload: true });
        setLegalFirmModalActive(true);
    }

    function onEditOffice(row: LegalFirmOfficeTableRow) {
        setLegalFirmOfficeBeingEdited(dispatch, row.id);
        setOfficeModalActive(true);
    }

    function onDelete(row: LegalFirmTableRow) {
        setLegalFirmToBeDeleted(row.id);
        setDeleteLegalFirmModalActive(true);
    }

    function onDeleteOffice(row: LegalFirmOfficeTableRow) {
        setOfficeToBeDeleted(row.id);
        setDeleteOfficeModalActive(true);
    }

    function onDeleteConfirm() {
        deleteLegalFirm(dispatch, legalFirmToBeDeleted ?? -1);
        setDeleteLegalFirmModalActive(false);
        if (state.legalFirms.selectedLegalFirm?.id === legalFirmToBeDeleted) setViewingLegalFirm(false);
    }

    function onDeleteOfficeConfirm() {
        deleteLegalFirmOffice(dispatch, officeToBeDeleted ?? -1);
        setDeleteOfficeModalActive(false);
    }

    function closeSidebar() {
        dispatch({ type: AdminActionTypes.SET_SELECTED_LEGAL_FIRM, payload: undefined });
        setViewingLegalFirm(false)
    }

    return (
        <div>
            <AdminLegalFirmModal
                open={legalFirmModalActive}
                onClose={() => setLegalFirmModalActive(false)}
                title={`${state.legalFirms.editing ? "" : "New "}Law Firm`}
            />
            <AdminLegalFirmOfficeModal
                open={officeModalActive}
                onClose={() => setOfficeModalActive(false)}
            />
            <GenericDialog
                action="destructive"
                title="Confirm Delete"
                onCancel={() => setDeleteLegalFirmModalActive(false)}
                onSubmit={onDeleteConfirm}
                open={deleteLegalFirmModalActive}
                contentText={"Are you sure you want to delete this law firm? This is permanent and irreversible."}
            />
            <GenericDialog
                action="destructive"
                title="Confirm Delete"
                onCancel={() => setDeleteOfficeModalActive(false)}
                onSubmit={onDeleteOfficeConfirm}
                open={deleteOfficeModalActive}
                contentText={"Are you sure you want to delete this office? This is permanent and irreversible."}
            />
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid 
                    item
                    xs={12}
                    xl={viewingLegalFirm ? 6 : 12}
                >
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}>
                            <STYLED_H4>
                                Law Firms
                            </STYLED_H4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableBase
                                id="admin-legal-firms-table"
                                columnHeadings={[
                                    { label: "Name", objKey: "name", sortable: true }
                                ]}
                                tableRows={makeTableRows()}
                                titleBarProps={{
                                    searchEnabled: true,
                                    onSearch: (value) => debouncedOnSearch(value)
                                }}
                                onSort={onSort}
                                clickEnabled
                                onRowClick={onView}
                                rowHoverProps={{
                                    buttonsInHoverAction: [
                                        {
                                            typeOf: "secondary",
                                            leftIcon: <EditIcon color={Colors.BLACK} />,
                                            label: { text: "Edit", inputId: "edit" },
                                            onClick: (row) => onEdit(row)
                                        },
                                        {
                                            typeOf: "destructive",
                                            leftIcon: <TrashIcon color={deleteButtonHoveredRow ? Colors.WHITE : Theme.ERROR} />,
                                            label: { text: "Delete", inputId: "delete" },
                                            onClick: onDelete,
                                            onMouseEnter: () => setDeleteButtonHoveredRow(true),
                                            onMouseLeave: () => setDeleteButtonHoveredRow(false)
                                        },
                                    ]
                                }}
                                rowsLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.LegalFirms])}
                                paginationEnabled
                                paginationProps={{
                                    rowsPerPage,
                                    setRowsPerPage,
                                    pageNumber,
                                    setPageNumber,
                                    rowsPerPageOptions: [10, 20, 50],
                                    totalRows: state.legalFirms.resultCount,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BasicButton
                                label={{ text: "Add Legal Firm", inputId: "add-legal-firm" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => setLegalFirmModalActive(true)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {viewingLegalFirm &&
                <Grid item xs={12} xl={6}>
                    {isObjectLoading(state.loading.objectsLoading, [AdminLoading.SelectedLegalFirm]) ?
                        <CircularLoader containerHeight="70vh" testId="admin-table-loader" /> :
                        <Grid container rowSpacing={3} columnSpacing={5}>
                            <Grid item xs={10}>
                                <H4 data-testid="legal-firm-sidebar-header">{legalFirmObj?.name}</H4>
                            </Grid>
                            <Grid item xs={2}>
                                <IconWrapper onClick={closeSidebar} data-testid="close-sidebar">
                                    <XCircle color={Colors.BLACK} />
                                </IconWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" gap={1}>
                                    <INPUT_SMALL>
                                        Province(s) of Operation:
                                    </INPUT_SMALL>
                                    <Large>
                                        {regionListToString(legalFirmObj?.all_regions, legalFirmObj?.regions ?? [])}
                                    </Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <INPUT_SMALL>
                                        Is a Deeded Partner:
                                    </INPUT_SMALL>
                                    <Large>
                                        {legalFirmObj?.partner ? "Yes" : "No"}
                                    </Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" gap={1}>
                                    <INPUT_SMALL>
                                        GST Number:
                                    </INPUT_SMALL>
                                    <Large>
                                        {legalFirmObj?.gst_number}
                                    </Large>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <FinancialInfoReadonlyGrid
                                    financialInfo={legalFirmObj?.financial_info ?? defaultFinancialInfo}
                                    title="Financial Information"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TableBase
                                    id="admin-legal-firm-offices-table"
                                    columnHeadings={[
                                        { label: "Address", objKey: "general_address", sortable: true }
                                    ]}
                                    tableRows={makeTableRowsOffices()}
                                    titleBarProps={{
                                        title: "Offices",
                                        searchEnabled: true,
                                        onSearch: (value) => debouncedOnSearchOffices(value)
                                    }}
                                    onSort={onSortOffices}
                                    rowHoverProps={{
                                        buttonsInHoverAction: [
                                            {
                                                typeOf: "secondary",
                                                leftIcon: <EditIcon color={Colors.BLACK} />,
                                                label: { text: "Edit", inputId: "edit-legal-firm-office" },
                                                onClick: (row) => onEditOffice(row)
                                            },
                                            {
                                                typeOf: "destructive",
                                                leftIcon: <TrashIcon color={deleteButtonHoveredRow ? Colors.WHITE : Theme.ERROR} />,
                                                label: { text: "Delete", inputId: "delete-legal-firm-office" },
                                                onClick: onDeleteOffice,
                                                onMouseEnter: () => setDeleteButtonHoveredRow(true),
                                                onMouseLeave: () => setDeleteButtonHoveredRow(false)
                                            },
                                        ]
                                    }}
                                    rowsLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.LegalFirmOffices])}
                                    paginationEnabled
                                    paginationProps={{
                                        rowsPerPage: rowsPerPageOffices,
                                        setRowsPerPage: setRowsPerPageOffices,
                                        pageNumber: pageNumberOffices,
                                        setPageNumber: setPageNumberOffices,
                                        rowsPerPageOptions: [10, 20, 50],
                                        totalRows: state.legalFirmOffices.resultCount,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BasicButton
                                    label={{ text: "Add Office", inputId: "add-legal-firm-office" }}
                                    action="add"
                                    typeOf="secondary"
                                    onClick={() => setOfficeModalActive(true)}
                                    size="small"
                                />
                            </Grid>
                        </Grid>}
                </Grid>}
            </Grid>
        </div>
    )
};

const IconWrapper = styled('div')({
    cursor: "pointer",
    paddingTop: "0.5rem"
})