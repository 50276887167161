import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { Document } from "../baseDocument";
import { VerificationOfIdentityAgreementContextRequest } from "../documents/verificationOfIdentityAgreementDocument";
import { SimpleSigningAppointment } from "../../SigningAppointment/simpleSigningAppointment";
import { getClientsString } from "../../../../utils/clients";

function labelVerificationOfIdentityAgreementDocContextOption(dealSigningAppts: SimpleSigningAppointment[], contextId: number): string {
    let matchingAppt: SimpleSigningAppointment | undefined = dealSigningAppts.find((appt) => appt.id === contextId);
    return getClientsString(matchingAppt?.clients, true);
}

function createVerificationOfIdentityAgreementDocName(signingAppt: SimpleSigningAppointment | undefined): string {
    let name = "Verification of Identity Agreement";
    if (!signingAppt?.clients) return name;
    name += ` - ${getClientsString(signingAppt.clients, true)}`;
    return name;
}

function isVerificationOfIdentityAgreementDocInDeal(docList: Document[], signingAppt: SimpleSigningAppointment): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.VerificationOfIdentityAgreement && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.signing_appointment_id === signingAppt.id) {
            return true;
        }
    }
    return false;
}

function createVerificationOfIdentityAgreementContextRequest(signingAppt: SimpleSigningAppointment | undefined): VerificationOfIdentityAgreementContextRequest {
    return { signing_appointment_id: signingAppt ? signingAppt.id : -1 };
}

function getVerificationOfIdentityAgreementDocData(dealSigningAppts: SimpleSigningAppointment[], contextId: number): [Object, string] {
    let matchingAppt: SimpleSigningAppointment | undefined = dealSigningAppts.find((appt) => appt.id === contextId);
    return [createVerificationOfIdentityAgreementContextRequest(matchingAppt), createVerificationOfIdentityAgreementDocName(matchingAppt)];
}

function getVerificationOfIdentityAgreementContextOptions(signingApptsInDeal: SimpleSigningAppointment[], docList: Document[]) {
    const options: number[] = [];
    for (const appt of signingApptsInDeal) {
        if (!isVerificationOfIdentityAgreementDocInDeal(docList, appt) && appt.clients && appt.clients.length > 0) {
            options.push(appt.id);
        }
    }
    return options;
}

export {
    labelVerificationOfIdentityAgreementDocContextOption,
    getVerificationOfIdentityAgreementDocData,
    getVerificationOfIdentityAgreementContextOptions
}