import { useCallback, useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { UniversalSurveyContext } from "../../../../../context/UniversalSurvey/context";
import { DebtActionOptions, DebtHolderTypeOptions, DebtTypeOptions } from "../../../../../libs/resources/options";
import { createLenderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/lenders";
import { createChargeHolderCompanyOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import { createGovernmentOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/governments";
import { createOtherChargeHolderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import { createMortgageBrokerageOptionList } from "../../../../../context/UniversalSurvey/asyncActions/mortgageBrokerages";
import { DebtHolderListItem } from "../../../../../libs/types/UniversalSurvey/Debts/debtHolder";
import { DebtHolderTypes } from "../../../../../libs/resources/enums/debtHolderTypes";
import { Debt } from "../../../../../libs/types/UniversalSurvey/Debts/debt";
import { createDebtHolderRecordFromGlobal, getDebtHolder } from "../../../../../context/UniversalSurvey/asyncActions/debts";
import { formatDebtAction, formatDebtHolderType } from "../../../../../libs/utils/formatValues";
import { DebtAction } from "../../../../../libs/resources/enums/debtAction";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../../../libs/utils/limits";
import { debounce } from "../../../../../libs/utils/debounce";

type Props = {
    onChange: Function;
    obj: Debt;
    setObj: Function;
    newDebtHolderType: DebtHolderTypes | undefined;
    setNewDebtHolderType: (type: DebtHolderTypes | undefined) => void;
    newDebtHolderName: string;
    setNewDebtHolderName: (name: string) => void;
};

export default function DebtForm(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const { obj, onChange, setObj, newDebtHolderType, setNewDebtHolderType, newDebtHolderName, setNewDebtHolderName } = props;

    const [debtHolder, setDebtHolder] = useState<DebtHolderListItem | undefined>(undefined);
    const [showChargeHolderType, setShowChargeHolderType] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if(state.conditions.conditionDebt) {
            const debt = state.conditions.conditionDebt;
            const debtHolderRecord = debt.debt_holder_record;
            let debtHolderListItem: DebtHolderListItem;
            if (debtHolderRecord) {
                getDebtHolder(dispatch, String(state.deal.dealInfo?.id), debtHolderRecord.id, debt.charge_holder_type!);
                debtHolderListItem = { 
                    id: debtHolderRecord.id,
                    label: debtHolderRecord.name ?? "",
                    isRecord: true, 
                    charge_holder_type: debt.charge_holder_type!,
                    name: debtHolderRecord.name ?? ""
                };
                setDebtHolder(debtHolderListItem);
            }
        }
    }, [state.conditions.conditionDebt]);


    function makeQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        if (searchVal) query.set("search", searchVal);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        return query;
    }
    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue]);

    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined,
                makeQuery(searchValue)
            );
        }, 500), []);

    useEffect(() => {
        createLenderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            undefined
        );
        createChargeHolderCompanyOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            undefined
        );
        createGovernmentOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            undefined
        );
        createOtherChargeHolderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            undefined
        );
        createMortgageBrokerageOptionList(
            dispatch,
            String(state.deal.dealInfo?.id),
            undefined
        )
    }, []);

    useEffect(() => {
        if (state.debts.debtHolder) {
            onChange("debt_holder_id", state.debts.debtHolder.id);
        }
    }, [state.debts.debtHolder]);

    function convertToDebtHolderListItem(value: any, debtHolderType: DebtHolderTypes): DebtHolderListItem {
        const debtHolder: DebtHolderListItem = {
            id: value.id,
            label: value.label,
            isRecord: value.isRecord,
            name: value.name,
            charge_holder_type: debtHolderType
        }
        return debtHolder;
    }

    function getPayableToComboboxItems(): DebtHolderListItem[] {
        const options: DebtHolderListItem[] = [];
        let option: DebtHolderListItem;
        for (const lender of state.lenders.lenderOptionList) {
            option = convertToDebtHolderListItem(lender, DebtHolderTypes.Lender);
            options.push(option);
        }
        for (const company of state.chargeHolderCompanies.optionList) {
            option = convertToDebtHolderListItem(company, DebtHolderTypes.Company);
            options.push(option);
        }
        for (const government of state.governments.optionList) {
            option = convertToDebtHolderListItem(government, DebtHolderTypes.Government);
            options.push(option);
        }
        for (const other of state.otherChargeHolders.optionList) {
            option = convertToDebtHolderListItem(other, DebtHolderTypes.Other);
            options.push(option);
        }
        for (const brokerage of state.mortgageBrokerages.brokerageOptionList) {
            option = convertToDebtHolderListItem(brokerage, DebtHolderTypes.MortgageBrokerage);
            options.push(option);
        }
        return options;
    }

    function updateDebtHolderWithOption(value: DebtHolderListItem | undefined) {
        setShowChargeHolderType(false);
        setNewDebtHolderType(undefined);
        let tempDebtObj = { ...obj };
        if (value) {
            tempDebtObj.charge_holder_type = value.charge_holder_type;
            if (value.isRecord) {
                getDebtHolder(dispatch, String(state.deal.dealInfo?.id), value.id, value.charge_holder_type!);
            } else {
                createDebtHolderRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
            }
        } else {
            tempDebtObj.debt_holder_id = undefined;
            tempDebtObj.charge_holder_type = undefined;
        }
        setDebtHolder(value);
        setObj(tempDebtObj);
    }

    function handlePayableToBlur() {
        if (newDebtHolderName && !debtHolder) {
            setShowChargeHolderType(true);
        }
    }

    return (
        <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item xs={12}>
                <ComboBox
                    label={{ text: "Payable To", inputId: "debt_payable_to", isRequired: true }}
                    options={getPayableToComboboxItems()}
                    placeholder="Debt holder's name"
                    value={debtHolder}
                    isHoverActionHidden={debtHolder ? false : true}
                    handleClear={() => updateDebtHolderWithOption(undefined)}
                    onChangeFn={(value) => updateDebtHolderWithOption(value as any)}
                    searchableKeys={["label"]}
                    isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderList])}
                    onBlur={() => {
                        handlePayableToBlur;
                        setSearchValue(undefined);
                    }}
                    setText={(value) => {
                        setNewDebtHolderName;
                        setSearchValue(value);
                    }}
                />
            </Grid>
            {showChargeHolderType &&
                <Grid item xs={6}>
                    <Dropdown
                        value={newDebtHolderType ?? ""}
                        options={DebtHolderTypeOptions}
                        placeholder="Select debt holder type..."
                        label={{ text: "Debt Holder Type", isRequired: true }}
                        formatValue={formatDebtHolderType}
                        isHoverActionHidden={newDebtHolderType ? false : true}
                        handleClear={() => setNewDebtHolderType(undefined)}
                        onChange={(e) => setNewDebtHolderType(e.target.value as DebtHolderTypes)}
                    />
                </Grid>
            }
            <Grid item xs={6}>
                <ComboBox
                    label={{ text: "Type/Item", inputId: "debt_type", isRequired: true }}
                    options={DebtTypeOptions}
                    placeholder="Select type or item..."
                    value={obj.debt_type ?? ""}
                    isHoverActionHidden={obj.debt_type ? false : true}
                    handleClear={() => onChange("debt_type", undefined)}
                    onChangeFn={(value) => onChange("debt_type", value as string)}
                    setText={(value) => onChange("debt_type", value as string)}
                />
            </Grid>
            {!showChargeHolderType && <Grid item xs={6}/>}
            <Grid item xs={4}>
                <Dropdown
                    value={obj.action ?? ""}
                    options={DebtActionOptions}
                    placeholder="Select action..."
                    label={{ text: "Action", isRequired: true }}
                    formatValue={formatDebtAction}
                    isHoverActionHidden={obj.action ? false : true}
                    handleClear={() => onChange("action", undefined)}
                    onChange={(e) => onChange("action", e.target.value as DebtAction)}
                />
            </Grid>
            <Grid item xs={4}>
                <BasicTextInput
                    fullWidth
                    label={{ text: "Amount", inputId: "amount_payable", isRequired: true }}
                    placeholder="0.00"
                    moneyField
                    valueType="positive"
                    value={obj?.amount_payable ? String(obj.amount_payable) : undefined}
                    onChange={(e) => onChange("amount_payable", Number(e.target.value))}
                /> 
            </Grid>
            <Grid item xs={4}>
                <BasicTextInput
                    fullWidth
                    label={{ text: "Account Number", inputId: "account_number" }}
                    placeholder="Account number"
                    value={obj.account_number}
                    onChange={(e) => onChange("account_number", e.target.value)}
                /> 
            </Grid>
        </Grid>
    );
}