import * as actions from "../actionTypes"

import { createDealFee, getDealFees, saveDealFee, deleteDealFee } from "../../../libs/service/axios/api";
import { Dispatch } from "react";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeFeesResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeFeeRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { SimpleFee } from "../../../libs/types/UniversalSurvey/Fees/simpleFee";
import { Fee } from "../../../libs/types/UniversalSurvey/Fees/fee";
import { defaultFee } from "../../../libs/resources/defaults/frontend/defaultFee";
import { Loading } from "../../../libs/resources/enums/loading";

async function getFeesInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FeeList, isLoading: true } });
    return await getDealFees(dealId)
        .then(function (response: any) {
            let fees: SimpleFee[] = sanitizeFeesResponse(response.data);
            dispatch({ type: actions.SET_FEES, payload: fees });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting deal fees: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FeeList, isLoading: false } });
        })
}

function saveFeeInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, feeInfo: Fee) {
    saveDealFee(dealId, String(feeInfo.id), sanitizeFeeRequest(feeInfo))
    .then()
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fee record: ${error}`, type: AlertTypes.Error } });
    })
}

function createNewFee(dispatch: Dispatch<Record<string, any>>, dealId: string, isDiscount?: boolean) {
    createDealFee(dealId)
    .then(function (response: any) {
        let tempFee: Fee = { ...defaultFee, id: response.data.id, discount: isDiscount ? isDiscount : undefined };
        dispatch({ type: actions.ADD_CUSTOM_FEE, payload: tempFee });
        dispatch({ type: actions.SET_EMPTY_FEE_ADDED, payload: true });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create deal fee: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteFee(dispatch: Dispatch<Record<string, any>>, dealId: string, feeId: number) {
    deleteDealFee(dealId, String(feeId))
        .then(function () {
            dispatch({ type: actions.DELETE_FEE, payload: feeId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete fee: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getFeesInDeal,
    saveFeeInDeal,
    createNewFee,
    deleteFee
}