import { Tab } from "../types/UniversalSurvey/Frontend/tab";
import { Tabs } from "./enums/tabs";

export const tabOptions: Tab[] = [
    {
        title: Tabs.DataSheet
    },
    {
        title: Tabs.Docs,
    },
    {
        title: Tabs.Notes
    }
];
