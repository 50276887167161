import * as actions from "../actionTypes"
import { ChargeHolderCompanyContact, ChargeHolderCompanyContactListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { findAndRemove, findAndReplace, sortByKey } from "../../../libs/utils/arrays";

export type ChargeHolderCompanyContactsState = {
    contactsList: ChargeHolderCompanyContactListItem[];
    contactOptionList: ChargeHolderCompanyContactListItem[];
    emptyContactAdded: boolean;
    contactBeingEdited: ChargeHolderCompanyContact | undefined;
    contactBeingReplaced: ChargeHolderCompanyContactListItem | undefined;
}

export const defaultChargeHolderCompanyContactsState: ChargeHolderCompanyContactsState = {
    contactsList: [],
    contactOptionList: [],
    emptyContactAdded: false,
    contactBeingEdited: undefined,
    contactBeingReplaced: undefined
}

export function chargeHolderCompanyContactsReducer(state: ChargeHolderCompanyContactsState, action: Record<string, any>): ChargeHolderCompanyContactsState {
    switch (action.type) {
        case actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_OPTION_LIST:
            return { ...state, contactOptionList: action.payload };
        case actions.ADD_CHARGE_HOLDER_COMPANY_CONTACT_TO_LIST:
            return { ...state, contactsList: [...state.contactsList, action.payload] };
        case actions.REMOVE_CHARGE_HOLDER_COMPANY_CONTACT_FROM_LIST:
            const tempContactsInList = findAndRemove([...state.contactsList], ["id", "isRecord"], [action.payload, true]);
            return { ...state, contactsList: tempContactsInList, emptyContactAdded: tempContactsInList.length === 0 ? false : state.emptyContactAdded };
        case actions.REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_OPTION_WITH_RECORD:
            return { ...state, contactOptionList: findAndReplace([...state.contactOptionList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_WITH_UPDATED_RECORD:
            return { ...state, contactsList: findAndReplace([...state.contactsList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST:
            return { ...state, contactsList: action.payload };
        case actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED:
            return { ...state, contactBeingEdited: action.payload };
        case actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_REPLACED:
            return { ...state, contactBeingReplaced: action.payload };
        case actions.ADD_CHARGE_HOLDER_COMPANY_CONTACT_OPTION:
            const optionsCopy = [...state.contactOptionList];
            optionsCopy.push(action.payload);
            sortByKey(optionsCopy, "label");
            return { ...state, contactOptionList: optionsCopy };
        case actions.SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED:
            return { ...state, emptyContactAdded: action.payload };
        case actions.UPDATE_CHARGE_HOLDER_COMPANY_CONTACT_ROLE:
            const tempContactsRoles = [...state.contactsList];
            const tempContact = { ...tempContactsRoles[action.payload.index] };
            tempContact.role = action.payload.value;
            tempContactsRoles[action.payload.index] = tempContact;

            const tempOptionsList = [...state.contactOptionList];
            const tempMatchingOption =  tempOptionsList.find((contact) => contact.id === tempContact.id && contact.isRecord);
            if (tempMatchingOption) {
                tempOptionsList[tempOptionsList.indexOf(tempMatchingOption)] = tempContact;
            }
            return { ...state, contactsList: tempContactsRoles, contactOptionList: tempOptionsList };
        default:
            return state;
    }
}