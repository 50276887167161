import React, { useRef } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { InputAdornment, InputProps } from "@mui/material";

import theme from '../componentStyling/Theme';
import { SearchIcon, XCircle } from "../Icons/Iconography";
import { BORDER_2, LARGE_TEXT } from "../componentStyling/Styles";
import colors from "../componentStyling/Colors";

type SearchBarProps = TextFieldProps & {
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    onSearch?: (searchValue: string) => void;
    placeholder?: string;
    id?: string;
};

export default function SearchBar(props: SearchBarProps) {
    const searchRef = useRef<HTMLInputElement>();

    function handleSearchClear(): void {
        props.setSearchValue("");
        searchRef.current?.focus();
        props.onSearch ? props.onSearch("") : undefined;
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        props.setSearchValue(e.target.value);
        props.onSearch ? props.onSearch(e.target.value) : undefined;
    }

    const inputProps: InputProps = {
        startAdornment: <InputAdornment position="start"><SearchIcon color={theme.INPUT} /></InputAdornment>,
        id: `search-bar${props.id ? `-${props.id}` : ""}`
    };
    if (props.searchValue) {
        inputProps.endAdornment = (
            <PointerAdornment position="end" onClick={handleSearchClear}>
                <XCircle color={theme.INPUT} />
            </PointerAdornment>
        )
    }


    return (
        <SearchInput
            {...props}
            fullWidth
            variant="standard"
            value={props.searchValue}
            onChange={handleSearchChange}
            InputProps={inputProps}
            placeholder={props.placeholder ?? "Search..."}
            inputRef={searchRef}
        />
    )
}

const PointerAdornment = styled(InputAdornment)({
    cursor: "pointer",
});

const SearchInput = styled(TextField, { shouldForwardProp: 
    (prop) => prop !== "onSearch" && prop !== "setSearchValue" && prop !== "searchValue"
})({
    maxWidth: "54rem",

    "& .MuiInputBase-adornedEnd": {
        paddingRight: "1.2rem",
    },
    "& .MuiInputBase-adornedStart": {
        paddingLeft: "1rem",
    },

    ".MuiInput-underline:hover:before": {
        borderBottom: BORDER_2(theme.INPUT),
    },
    ".MuiInput-underline:before": {
        borderBottom: BORDER_2(theme.INPUT),
    },
    ".MuiInput-underline:after": {
        borderBottom: BORDER_2(theme.INPUT),
    },

    ".MuiInput-root": {
        minHeight: "5rem",

        "& .MuiInputBase-input": {
            ...LARGE_TEXT,
            color: colors.BLACK,
            "&::placeholder": {
                opacity: 1,
                color: theme.PLACEHOLDER
            },
            padding: "0",
        },
    },
});