import {Box, styled, Typography} from "@mui/material";
import constants from "styles/constants";

const DealsListHeader = () => {

  return (
    <HeaderContainer>
      <FirstLine>
        <Title>Conveyance</Title>
      </FirstLine>
    </HeaderContainer>
  );
};

const Title = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "3.2rem",
  lineHeight: "3.9rem",
  margin: "-2px 0 24px",
  textTransform: "uppercase",
  color: constants.colors.intakeTitle,
});

const FirstLine = styled(Box)(({theme}) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const HeaderContainer = styled(Box)({
  width: "100%",
  marginBottom: "16px",
  background: "transparent",
});

export default DealsListHeader;
