// Importing MUI components
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

// Importing application components
import theme from '../componentStyling/Theme';
import colors from '../componentStyling/Colors';
import { Large } from "../Typography/index";
import { BORDER_2 } from "../componentStyling/Styles";
import { ReactElement, forwardRef } from "react";

export type BasicTooltipProps = Omit<TooltipProps, "title"> & {
    message: string;
    type?: "info" | "error" | "warning";
    width?: string;
    children: ReactElement;
};

const BasicTooltip = forwardRef((props: BasicTooltipProps, ref) => {

    return (
        <CustomTooltip
            {...props}
            ref={ref}
            type={props.type ? props.type : "info"}
            width={props.width ? props.width : "48rem"}
            title={
                <Large style={{ color: props.type === "warning" ? colors.BLACK : colors.WHITE }}>
                    <strong>{props.type === "warning" ? "Warning: " : props.type === "error" ? "Error: " : ""}</strong>
                    {props.message.split('\n').map((line, index) => 
                        <span key={index}>
                            {line} <br />
                        </span>
                    )}
                </Large>
            }
            PopperProps={{
                popperOptions: {
                    modifiers: [
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ['left', 'top'],
                            }
                        }
                    ]
                }
            }}
            arrow
        >
            <div style={{ width: "fit-content" }}>{props.children}</div>
        </CustomTooltip>
    )
})

const CustomTooltip = styled(({ className, type, width, ...props }: TooltipProps & {
    type: "warning" | "info" | "error";
    width: string;
}) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ type, width }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: width,
        background: type === "warning" ? theme.WARNING : type === "error" ? theme.ERROR : colors.BLACK,
        borderRadius: "0.6rem",
        border: type === "warning" ? BORDER_2(colors.BLACK) : BORDER_2(colors.WHITE),
        padding: "1rem",
        gap: "1rem",
        boxSizing: "border-box",
        marginRight: "1rem",
        position: "relative"
    },
    [`& .${tooltipClasses.arrow}`]: {
        fontSize: "25px",
        "&::before": {
            backgroundColor: type === "warning" ? theme.WARNING : type === "error" ? theme.ERROR : colors.BLACK,
            border: type === "warning" ? BORDER_2(colors.BLACK) : BORDER_2(colors.WHITE)
        }
    }
}));

export default BasicTooltip;