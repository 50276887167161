import * as actions from "../actionTypes"

import { Undertaking } from "../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { SimpleUndertaking } from "../../../libs/types/UniversalSurvey/Undertaking/simpleUndertaking";
import { findAndRemove } from "../../../libs/utils/arrays";
import { defaultSimpleUndertaking } from "../../../libs/resources/defaults/frontend/defaultSimpleUndertaking";
import { sortUndertakings } from "../../../libs/utils/undertakings";


export type UndertakingsState = {
    undertakingBeingEdited: Undertaking | undefined;
    undertakingsInDeal: SimpleUndertaking[];
}

export const defaultUndertakingsState: UndertakingsState = {
    undertakingBeingEdited: undefined,
    undertakingsInDeal: []
}

export function undertakingsReducer(state: UndertakingsState, action: Record<string, any>): UndertakingsState {
    switch (action.type) {
        case actions.SET_UNDERTAKING_BEING_EDITED:
            return { ...state, undertakingBeingEdited: action.payload };
        case actions.ADD_UNDERTAKING:
            const undertakingsCopy = [...state.undertakingsInDeal, action.payload];
            sortUndertakings(undertakingsCopy);
            return { ...state, undertakingsInDeal: undertakingsCopy };
        case actions.SET_UNDERTAKINGS_IN_DEAL:
            return { ...state, undertakingsInDeal: action.payload }
        case actions.REMOVE_UNDERTAKING:
            return { ...state, undertakingsInDeal: findAndRemove([...state.undertakingsInDeal], ["id"], [action.payload]) };
        case actions.UPDATE_UNDERTAKING:
            const tempUndertakings = [...state.undertakingsInDeal];
            const matchingUndertaking = tempUndertakings.find((undertaking) => undertaking.id === action.payload.id);
            if(matchingUndertaking) {
                const newUndertaking: any = { ...defaultSimpleUndertaking };
                for(const key of Object.keys(newUndertaking)) {
                    newUndertaking[key as keyof SimpleUndertaking] = action.payload[key];
                }
                tempUndertakings[tempUndertakings.indexOf(matchingUndertaking)] = newUndertaking;
                sortUndertakings(tempUndertakings);
            }
            return { ...state, undertakingsInDeal: tempUndertakings };
        default:
            return state;
    }
}