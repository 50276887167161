import { Button, Stack, styled, ButtonProps as ButtonPropsMui, SxProps, Theme } from "@mui/material";
import { LargeBold, SmallBold } from "../Typography/index";

import color from '../componentStyling/Colors';
import myTheme from '../componentStyling/Theme';
import { BORDER_2, FOCUS_BOX_SHADOW } from "../componentStyling/Styles";
import { PlusIcon } from "../Icons/Iconography";
import CircularLoader from "../Loader/CircularLoader";
import Colors from "../componentStyling/Colors";

export type ButtonProps = {
    typeOf: "CTA" | "primary" | "secondary" | "dismissive" | "destructive";
    action?: "add";
    label: { text: string, inputId?: string };
    size?: "medium" | "small";
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    onClick?: (e?: any) => void;
    disabledOnClick?: () => void;
    disabled?: boolean;
    component?: JSX.Element;
    testId?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    className?: string;
    sx?: SxProps<Theme>;
    isLoading?: boolean;
};

const BasicButton: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <div onClick={props.disabled ? props.disabledOnClick : undefined}
             data-testid={props.testId}
        >
            <CustomButton
                disabled={props.disabled}
                typeOf={props.typeOf}
                size={props.size ? props.size : "medium"}
                disableRipple
                id={props.label.inputId}
                onClick={props.onClick}
                className={props.className}
                // @ts-ignore
                component={props.component}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                sx={props.sx}
            >
                {props.isLoading ?
                    <CircularLoader
                        loaderHeight={props.size === "small" ? "2rem" : "4rem"}
                        loaderWidth={props.size === "small" ? "2rem" : "4.4rem"}
                        containerHeight={props.size === "small" ? "2rem" : "4rem"}
                        color={Colors.WHITE}
                    /> :
                    <>
                        <Stack direction="row" alignItems="center" gap={1}>
                            {props.leftIcon && (
                                props.leftIcon 
                            )}
                            {props.size && props.size === "small" ? (
                                <SmallBold style={{ color: "inherit" }}>
                                    {props.label.text}
                                </SmallBold>
                            ) : (
                                <LargeBold style={{ color: "inherit" }}>
                                    {props.label.text}
                                </LargeBold>
                            )}
                            {props.action === "add" ? (
                                <PlusIcon color={props.disabled ? myTheme.PLACEHOLDER : color.BLACK} />
                            ) : (
                                props.rightIcon && (
                                    props.rightIcon
                                )
                            )}
                        </Stack>
                        {children}
                    </>
                }
            </CustomButton>
        </div>
    );
}

const CustomButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "typeOf"
  })<{
    typeOf: "CTA" | "primary" | "secondary" | "dismissive" | "destructive";
    disabled?: boolean;
    size?: "medium" | "small";
} & ButtonPropsMui>(({ typeOf, disabled, size, theme }) => ({
    
    color: disabled ? myTheme.PLACEHOLDER :
        typeOf === "CTA" ? color.WHITE :
        typeOf === "primary" ? color.WHITE :
        typeOf === "secondary" ? color.BLACK :
        typeOf === "dismissive" ? color.BLACK : 
        typeOf === "destructive" ? myTheme.ERROR : 
        color.WHITE,
    
    backgroundColor: disabled ? myTheme.DISABLED : 
        typeOf === "CTA" ? color.RED_500 :
        typeOf === "primary" ? color.BLACK : 
        color.WHITE,
    
    border: disabled ? BORDER_2('transparent') :
        typeOf === "secondary" ? BORDER_2(color.BLACK) :
        typeOf === "dismissive" ? BORDER_2(myTheme.HOVER) : 
        typeOf === "destructive" ? BORDER_2(myTheme.ERROR) :
        BORDER_2('transparent'),

    padding: size === "small" ? "0.5rem 1rem" : "1.8rem 2rem",
    gap: "1rem",
    borderRadius: "0.6rem",
    textTransform: "none",
    height: size === "small" ? "3rem" : "5.9rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
        padding: size === "small" ? "1.2rem 1rem" : "1.2rem 2rem",
        width: '100%'
    },
    '&:hover': {
        backgroundColor: typeOf === "CTA" ? color.RED_700 :
            typeOf === "primary" ? color.GRAY_800 :
            typeOf === "destructive" ? myTheme.ERROR : 
            myTheme.HOVER,
        color: typeOf === "destructive" ? color.WHITE : ""
    },

    '&:active': {
        backgroundColor: typeOf === "CTA" ? color.RED_900 :
            typeOf === "primary" ? myTheme.INPUT :
            typeOf === "secondary" ? myTheme.PLACEHOLDER : 
            typeOf === "destructive" ? color.ORANGE_700 :
            myTheme.PLACEHOLDER,
        border: typeOf === "CTA" ? BORDER_2(color.RED_900) :
            typeOf === "primary" ? BORDER_2(myTheme.INPUT) :
            typeOf === "secondary" ? BORDER_2(color.BLACK) :
            typeOf === "dismissive" ? BORDER_2(myTheme.PLACEHOLDER) : 
            typeOf === "destructive" ? BORDER_2(color.ORANGE_700) :
            "inherit",
        color: typeOf === "destructive" ? color.WHITE : ""

    },

    '&:focus': {
        outline: BORDER_2(color.BLUE_500),
        boxShadow: FOCUS_BOX_SHADOW        
    }
}));

export default BasicButton;