import { AdminActionTypes } from "../actionTypes"
import { AdminActions } from "../context";
import { SimpleGlobalLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { GlobalLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { findAndReplace } from "../../../libs/utils/arrays";


export type AdminLegalFirmState = {
    tableLegalFirms: SimpleGlobalLegalFirm[];
    selectedLegalFirm: GlobalLegalFirm | undefined;
    editing: boolean;
    shouldRefresh: boolean;
    resultCount: number;
}

export const defaultAdminLegalFirmsState: AdminLegalFirmState = {
    tableLegalFirms: [],
    selectedLegalFirm: undefined,
    editing: false,
    shouldRefresh: false,
    resultCount: 0,
}

export function legalFirmsReducer(state: AdminLegalFirmState, action: AdminActions): AdminLegalFirmState {
    switch (action.type) {
        case AdminActionTypes.SET_TABLE_LEGAL_FIRMS:
            return { ...state, tableLegalFirms: action.payload };
        case AdminActionTypes.SET_SELECTED_LEGAL_FIRM:
            return { ...state, selectedLegalFirm: action.payload };
        case AdminActionTypes.UPDATE_LEGAL_FIRM_IN_TABLE:
            return { ...state, tableLegalFirms: findAndReplace([...state.tableLegalFirms], ["id"], [action.payload.id], action.payload) };
        case AdminActionTypes.SET_EDITING_LEGAL_FIRM:
            return { ...state, editing: action.payload };
        case AdminActionTypes.SET_LEGAL_FIRM_SHOULD_REFRESH:
            return { ...state, shouldRefresh: action.payload };
        case AdminActionTypes.SET_LEGAL_FIRMS_RESULT_COUNT:
            return { ...state, resultCount: action.payload };
        default:
            return state;
    }
}