import { TransactionTypes } from "../../resources/enums/transactions";
import { Transaction } from "../../types/UniversalSurvey/Transaction/transaction";

export function labelMortgage(transactionsInDeal: Transaction[], mortgageId: number): string {
    const mortgage = transactionsInDeal.find((transaction) => transaction.id === mortgageId && transaction.transaction_type === TransactionTypes.Mortgage);
    return mortgage?.label ?? "MTG";
}

export function labelTitleTransfer(transactionsInDeal: Transaction[], titleTransferId: number): string {
    const titleTransfer = transactionsInDeal.find((transaction) => transaction.id === titleTransferId && transaction.transaction_type === TransactionTypes.TitleTransfer);
    return titleTransfer?.label ?? "Title Transfer";
}