// Importing built-in React/Router tools
import { useContext } from 'react';

// Importing MUI components
import { Grid, styled } from '@mui/material';

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing application components
import { H2 } from '../../../../components/Common/Typography/index';
// import DisplayPills from '../../../../components/Common/StatusPills/DisplayPills';
import ClientSection from './ClientSection';
import ExistingLienSection from './ExistingLienSection';
import PropertySection from './PropertySection';
import NewMortgageSection from './NewMortgageSection';
import FireInsuranceSection from './FireInsuranceSection';
import StatementOfAccountsSection from './StatementOfAccountsSection';
import ConditionSection from './ConditionSection';
import TitleInsuranceSection from './TitleInsuranceSection';
import { Sections } from '../../../libs/resources/enums/sections';
import SectionTabs, { SectionTabsProps } from '../Components/Tabs/SectionTabs';
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { addDealClient, deleteDealClient } from '../../../context/UniversalSurvey/asyncActions/clients';
import { addDealProperty, deleteDealProperty } from '../../../context/UniversalSurvey/asyncActions/properties';
import { addExistingLien, deleteExistingLienFromTab } from '../../../context/UniversalSurvey/asyncActions/existingLiens';
import { addNewMortgage } from '../../../context/UniversalSurvey/asyncActions/newMortgages';
import { addFireInsurance, deleteFireInsurance } from '../../../context/UniversalSurvey/asyncActions/fireInsurance';
import UnsecuredDebtsSection from './UnsecuredDebtsSection';
import TrustLedgerSection from './TrustLedgerSection';
import { deleteDealMortgageTransaction } from '../../../context/UniversalSurvey/asyncActions/transactions/mortgageTransactions';
import DealOverviewSection from './DealOverviewSection';
import { Loading } from '../../../libs/resources/enums/loading';
import { DCP_SECTION_CONTAINER, DCP_SECTION_CONTENT } from '../../../../components/Common/componentStyling/Styles';

export default function SectionBase() {
    const [state] = useContext(UniversalSurveyContext);

    function getAddFunctionAndText(): SectionTabsProps {
        let sectionTabProps: SectionTabsProps = {
            buttonRow: [],
            handleRemove: () => { },
        };

        switch (state.dataSheet.currSection) {
            case Sections.ClientInformation:
                sectionTabProps.handleRemove = deleteDealClient;
                sectionTabProps.entityText = "client";
                sectionTabProps.buttonRow = [{
                    function: addDealClient,
                    buttonText: "Client"
                }];
                return sectionTabProps;
            case Sections.Encumbrances:
                sectionTabProps.handleRemove = deleteExistingLienFromTab;
                sectionTabProps.entityText = "encumbrance"
                sectionTabProps.buttonRow = [{
                    function: addExistingLien,
                    buttonText: "Encumbrance"
                }]
                return sectionTabProps;
            case Sections.PropertyInformation:
                sectionTabProps.handleRemove = deleteDealProperty;
                sectionTabProps.entityText = "property";
                sectionTabProps.buttonRow = [{
                    function: addDealProperty,
                    buttonText: "Property"
                }]
                return sectionTabProps;
            case Sections.NewMortgage:
                sectionTabProps.handleRemove = deleteDealMortgageTransaction;
                sectionTabProps.entityText = "new mortgage";
                sectionTabProps.buttonRow = [{
                    function: addNewMortgage,
                    buttonText: "New Mortgage"
                }]
                return sectionTabProps;
            case Sections.FireInsurance:
                sectionTabProps.handleRemove = deleteFireInsurance;
                sectionTabProps.entityText = "fire insurance";
                sectionTabProps.buttonRow = [{
                    function: addFireInsurance,
                    buttonText: "Fire Insurance"
                }]
                return sectionTabProps;
            default:
                return sectionTabProps;
        }
    }

    return (
        <DCP_SECTION_CONTAINER>
            <SectionHeader container>
                <Grid item xs={12}>
                    <H2 data-testid="current-section-header">
                        {state.dataSheet.currSection}
                    </H2>
                </Grid>
                {/* <Grid item xs={6}>
                    <PillContainer direction="row" justifyContent="flex-end">
                        <DisplayPills
                            status={state.dataSheet.currSectionStatus ?? { errors: 0, missingFields: 0, isEmpty: false }}
                            isHeader
                        />
                    </PillContainer>
                </Grid> */}
            </SectionHeader>
            <DCP_SECTION_CONTENT>
                {(state.dataSheet.tabOptions.length > 0 || getAddFunctionAndText().buttonRow.length > 0) &&
                    <TabContainer data-testid="section-tabs">
                        <SectionTabs {...getAddFunctionAndText()} />
                    </TabContainer>
                }
                {state.dataSheet.objectsLoading.includes(Loading.Section) ? (
                    <CircularLoader containerHeight="70vh" />
                ) : (
                    <div>
                        {state.dataSheet.currSection === Sections.DealOverview && state.deal.dealInfo && (
                            <DealOverviewSection />
                        )}
                        {state.dataSheet.currSection === Sections.ClientInformation && state.clients.clientInfo && (
                            <ClientSection />
                        )}
                        {state.dataSheet.currSection === Sections.PropertyInformation && state.properties.propertyInfo && (
                            <PropertySection />
                        )}
                        {state.dataSheet.currSection === Sections.Encumbrances && state.existingLiens.existingLienInfo && (
                            <ExistingLienSection />
                        )}
                        {state.dataSheet.currSection === Sections.NewMortgage && state.newMortgages.mortgageTransactionInfo?.new_mortgage_record && (
                            <NewMortgageSection />
                        )}
                        {state.dataSheet.currSection === Sections.FireInsurance && state.fireInsurance.fireInsuranceInfo && (
                            <FireInsuranceSection />
                        )}
                        {state.dataSheet.currSection === Sections.TitleInsurance && state.titleInsurance.titleInsuranceInfo && state.titleInsurancePremiums.premiums && (
                            <TitleInsuranceSection />
                        )}
                        {state.dataSheet.currSection === Sections.Conditions && (
                            <ConditionSection />
                        )}
                        {state.dataSheet.currSection === Sections.StatementOfAccount && (
                            <StatementOfAccountsSection />
                        )}
                        {state.dataSheet.currSection === Sections.UnsecuredDebts && (
                            <UnsecuredDebtsSection />
                        )}
                        {state.dataSheet.currSection === Sections.TrustLedger && (
                            <TrustLedgerSection />
                        )}
                    </div>
                )}
            </DCP_SECTION_CONTENT>
        </DCP_SECTION_CONTAINER>
    )
}
const SectionHeader = styled(Grid)({});

const TabContainer = styled('div')({
    paddingBottom: "2rem"
})
