import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    createUndertaking,
    saveUndertaking,
    getUndertakings,
    getUndertaking,
    deleteUndertaking
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { Undertaking } from "../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { sanitizeUndertakingRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { SimpleUndertaking } from "../../../libs/types/UniversalSurvey/Undertaking/simpleUndertaking";
import {
    sanitizeSimpleUndertakingsResponse,
    sanitizeUndertakingResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sortUndertakings } from "../../../libs/utils/undertakings";
import { DischargedBy, UndertakingStatus } from "../../../libs/resources/enums/undertakings";
import { Loading } from "../../../libs/resources/enums/loading";

function addUndertaking(dispatch: Dispatch<Record<string, any>>, dealId: string, undertakingInfo: Undertaking) {    
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: true } });
    createUndertaking(dealId)
        .then(function (response: any) {
            saveUndertaking(dealId, String(response.data.id), sanitizeUndertakingRequest(undertakingInfo))
                .then(function () {
                    dispatch({ type: actions.ADD_UNDERTAKING, payload: { ...undertakingInfo, id: response.data.id } });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving undertaking: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating undertaking: ${error}`, type: AlertTypes.Error } });
        })
}

function updateUndertaking(dispatch: Dispatch<Record<string, any>>, dealId: string, undertakingInfo: Undertaking) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: true } });
    let tempUndertakingInfo = { ...undertakingInfo }
    if (tempUndertakingInfo.status === UndertakingStatus.Outstanding) {
        tempUndertakingInfo.discharge_date = undefined;
        tempUndertakingInfo.instrument_number = undefined;
        tempUndertakingInfo.discharged_by = undefined;
    }
    if (tempUndertakingInfo.discharged_by === DischargedBy.Instrument) {
        tempUndertakingInfo.discharge_note = undefined;
    }
    if (tempUndertakingInfo.discharged_by === DischargedBy.Manual) {
        tempUndertakingInfo.instrument_number = undefined;
    }
    saveUndertaking(dealId, String(undertakingInfo.id), sanitizeUndertakingRequest(tempUndertakingInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_UNDERTAKING, payload: tempUndertakingInfo });
            dispatch({ type: actions.SET_SHOULD_REFRESH_LIEN, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving undertaking: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
        })
}

function getUndertakingsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.UndertakingList, isLoading: true } });
    getUndertakings(dealId)
        .then(function (response: any) {
            const undertakings: SimpleUndertaking[] = sanitizeSimpleUndertakingsResponse(response.data);
            sortUndertakings(undertakings);
            dispatch({ type: actions.SET_UNDERTAKINGS_IN_DEAL, payload: undertakings });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get undertakings: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.UndertakingList, isLoading: false } });
        })
}

function getUndertakingBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, undertakingId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.UndertakingModal, isLoading: true } });
    getUndertaking(dealId, String(undertakingId))
    .then(function (response: any) {
        dispatch({ type: actions.SET_UNDERTAKING_BEING_EDITED, payload: sanitizeUndertakingResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get undertaking: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.UndertakingModal, isLoading: false } });
    })
}

function removeUndertaking(dispatch: Dispatch<Record<string, any>>, dealId: string, undertakingId: number) {
    deleteUndertaking(dealId, String(undertakingId))
    .then(function () {
        dispatch({ type: actions.REMOVE_UNDERTAKING, payload: undertakingId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete undertaking: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    addUndertaking,
    updateUndertaking,
    getUndertakingsInDeal,
    getUndertakingBeingEdited,
    removeUndertaking
}