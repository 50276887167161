import { SimpleDeal } from "../../../types/DealList/deals";
import { DealStatus } from "../../enums/dealStatus";

export const defaultSimpleDeal: SimpleDeal= {
    id: -1,
    primary_transaction_type: undefined,
    file_num: undefined,
    opened_date: undefined,
    closing_date: undefined,
    status: DealStatus.Open,

    region: undefined,
    subject_property_address: undefined,
    clients: undefined
}