import { ComponentProps, useEffect, useState } from "react"

import DatePicker from 'react-date-picker';
 
import { Stack, styled } from "@mui/material";

import { CalendarIcon, SrcTextIcon, CaretLeft, CaretRight, ErrorIcon } from '../Icons/Iconography';
import theme from '../componentStyling/Theme';
import Label, { labelProps } from '../Label/Label'
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";

import './Calendar.css';
import './DatePicker.css';
import './ErrorDatePicker.css';
import { BasicTooltipProps } from "../Tooltip/Tooltip";
import { InputErrorProps } from "../../../conveyance/libs/utils/validation";

interface DcpDatePickerProps extends ComponentProps<typeof DatePicker> {
    value: Date | null;
    autoFilled?: boolean;
    error?: InputErrorProps;
    label?: labelProps;
    tooltipProps?: Omit<BasicTooltipProps, "children">;
    floatRight?: boolean;
    disableWeekends?: boolean;
}

const defaultErrorState: InputErrorProps = {
    showError: false
}

const BasicDatePicker: React.FC<DcpDatePickerProps> = ({
    autoFilled,
    error,
    label,
    tooltipProps,
    floatRight,
    disableWeekends,
    value,
    ...props
}) => {

    const [pickerOpen, setPickerOpen] = useState<boolean>(false);
    const [internalError, setInternalError] = useState<InputErrorProps>({ ...defaultErrorState });
    const [isFieldValid, setIsFieldValid] = useState<boolean>(true);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    function isWeekend(date: Date | null): boolean {
        if (disableWeekends && date) {
            if (date.getDay() === 0 || date.getDay() === 6) return true;
        }
        return false;
    }

    // Setting validation
    useEffect(() => {
        let newError = { ...defaultErrorState };
        if (error?.errorKey) newError.errorKey = error.errorKey;
        if (error?.errorState) newError.errorState = error.errorState;
        if (error?.showError) newError.showError = error.showError;
        if (error?.message) newError.message = error.message;
        if (error?.customValidation) newError.customValidation = error.customValidation;
        setInternalError(newError);
    }, [error]);

    function handleErrorStateUpdate(state: boolean): void {
        if (internalError.errorState && internalError.errorKey && error?.setErrorState) {
            let newError = { ...internalError }
            if (newError.errorState!.hasOwnProperty(newError.errorKey!)) {
                newError.errorState![newError.errorKey as keyof Object] = state as any;
            }
            error.setErrorState(newError.errorState!);
        }
    }

    // Applying validation
    useEffect(() => {
        if (value && internalError.showError) {
            let tempInternalError = { ...internalError };
            if (isWeekend(value)) {
                setIsFieldValid(false);
                handleErrorStateUpdate(true);
                tempInternalError.message = "Date is a weekend";
                setInternalError(tempInternalError);
                return;
            }
        }
        setIsFieldValid(true);
        handleErrorStateUpdate(false);
    }, [value, error]);

    useEffect(() => {
        if (error?.overwriteInternalError) {
          setIsFieldValid(false);
        }
      }, [error]);

    return (
        <>
            {label && (
                <Label {...label} onClick={setPickerOpen} open={pickerOpen} tooltipProps={tooltipProps}/>
            )}
            <Stack direction="row" alignItems="center">
                {!isFieldValid && !isFocused && (
                    <ErrorIconContainer>
                        <ErrorIcon color={theme.ERROR} />
                    </ErrorIconContainer>
                )}
                <DatePicker
                    value={value}
                    onFocus={() => setIsFocused(true)}
                    //@ts-ignore
                    onBlur={() => setIsFocused(false)}
                    className={(!isFieldValid && !isFocused) ? 'react-date-picker-error' : autoFilled ? 'react-date-picker-autoFilled' : 'none'}
                    calendarClassName={floatRight ? "float-right" : ""}
                    calendarIcon={
                        <Stack direction="row" gap={2}>
                            {autoFilled && (
                                <SrcTextIcon color={theme.INPUT} />
                            )}
                            <CalendarIcon color={theme.INPUT} />
                        </Stack>
                    }
                    tileDisabled={disableWeekends ? ( (args: { date: Date }) => isWeekend(args.date)) : undefined}
                    clearIcon={null}
                    monthPlaceholder="MM"
                    dayPlaceholder="DD"
                    yearPlaceholder="YYYY"
                    format="M/d/y"
                    prevLabel={<CaretLeft color={theme.INPUT} />}
                    nextLabel={<CaretRight color={theme.INPUT} />}
                    onCalendarClose={() => setPickerOpen(false)}
                    minDetail="decade"
                    isOpen={pickerOpen}
                    {...props}
                />
            </Stack>
            {!isFieldValid && internalError.showError && !isFocused && <InputErrorMessage errorMessage={internalError.message}/>}
        </>
    );
};

const ErrorIconContainer = styled('div')({
    zIndex: 1, 
    paddingLeft: "1rem",
    margin: "auto"
})

export default BasicDatePicker;