import * as _ from "lodash";
import { Client } from "../Client/client";
import { ClientRequest } from "../Client/clientRequest";
import { defaultClientRequest } from "../../../resources/defaults/request/defaultClientRequest";
import { Property } from "../Property/property";
import { PropertyRequest } from "../Property/propertyRequest";
import { defaultPropertyRequest } from "../../../resources/defaults/request/defaultPropertyRequest";
import { Lender } from "../Lender/lender";
import { LenderRequest } from "../Lender/lenderRequest";
import { defaultLenderRequest } from "../../../resources/defaults/request/defaultLenderRequest";
import { GlobalLenderContactRequest, LenderContactRequest } from "../LenderContact/lenderContactRequest";
import { GlobalLenderContact, LenderContact } from "../LenderContact/lenderContact";
import { MortgageBrokerage } from "../MortgageBrokerage/mortgageBrokerage";
import { MortgageBrokerageRequest } from "../MortgageBrokerage/mortgageBrokerageRequest";
import { defaultGlobalLenderContactRequest, defaultLenderContactRequest } from "../../../resources/defaults/request/defaultLenderContactRequest";
import { defaultMortgageBrokerageRequest } from "../../../resources/defaults/request/defaultMortgageBrokerageRequest";
import { MortgageBroker } from "../MortgageBroker/mortgageBroker";
import { MortgageBrokerRequest } from "../MortgageBroker/mortgageBrokerRequest";
import { defaultMortgageBrokerRequest } from "../../../resources/defaults/request/defaultMortgageBrokerRequest";
import { MortgageBrokerageOtherContact } from "../MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import { MortgageBrokerageOtherContactRequest } from "../MortgageBrokerageOtherContact/mortgageBrokerageOtherContactRequest";
import { defaultMortgageBrokerageOtherContactRequest } from "../../../resources/defaults/request/defaultMortgageBrokerageOtherContactRequest";
import { defaultChargeHolderCompanyContactRequest } from "../../../resources/defaults/request/defaultChargeHolderCompanyContactRequest";
import { defaultOtherChargeHolderRequest } from "../../../resources/defaults/request/defaultOtherChargeHolderRequest";
import { defaultDealRequest } from "../../../resources/defaults/request/defaultDealRequest";
import { NewMortgage } from "../NewMortgage/newMortgage";
import { NewMortgageRequest } from "../NewMortgage/newMortgageRequest";
import { defaultNewMortgageRequest } from "../../../resources/defaults/request/defaultNewMortgageRequest";
import { Lien } from "../ExistingLien/lien";
import { LienRequest } from "../ExistingLien/lienRequest";
import { defaultLienRequest } from "../../../resources/defaults/request/defaultLienRequest";
import { PriorityAfterClosingItem } from "../PriorityAfterClosing/priorityAfterClosing";
import { PriorityAfterClosingRequest } from "../PriorityAfterClosing/priorityAfterClosingRequest";
import { defaultPriorityAfterClosingRequestItem } from "../../../resources/defaults/request/defaultPriorityAfterClosingRequest";
import { Undertaking } from "../Undertaking/undertaking";
import { UndertakingRequest } from "../Undertaking/undertakingRequest";
import { defaultUndertakingRequest } from "../../../resources/defaults/request/defaultUndertakingRequest";
import { ChargeHolderCompany } from "../ChargeHolderCompany/chargeHolderCompany";
import { ChargeHolderCompanyRequest } from "../ChargeHolderCompany/chargeHolderCompanyRequest";
import { defaultChargeHolderCompanyRequest } from "../../../resources/defaults/request/defaultChargeHolderCompanyRequest";
import { Government } from "../Government/government";
import { GovernmentRequest } from "../Government/governmentRequest";
import { defaultGovernmentRequest } from "../../../resources/defaults/request/defaultGovernmentRequest";
import { ChargeHolderCompanyContact } from "../ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { ChargeHolderCompanyContactRequest } from "../ChargeHolderCompanyContact/chargeHolderCompanyContactRequest";
import { GovernmentContact } from "../GovernmentContacts/governmentContact";
import { GovernmentContactRequest } from "../GovernmentContacts/governmentContactRequest";
import { defaultGovernmentContactRequest } from "../../../resources/defaults/request/defaultGovernmentContactRequest";
import { OtherChargeHolder } from "../OtherChargeHolder/otherChargeHolder";
import { OtherChargeHolderRequest } from "../OtherChargeHolder/otherChargeHolderRequest";
import { FeeRequest } from "../Fees/feeRequest";
import { defaultFeeRequest } from "../../../../libs/resources/defaults/request/defaultFeeRequest";
import { Fee } from "../Fees/fee";
import { Disbursement } from "../Disbursements/disbursement";
import { DisbursementRequest } from "../Disbursements/disbursementRequest";
import { defaultDisbursementRequest } from "../../../resources/defaults/request/defaultDisbursementRequest";
import { Deal } from "../Deal/deal";
import { DealRequest } from "../Deal/dealRequest";
import { FireInsurance } from "../FireInsurance/fireInsurance";
import { FireInsuranceRequest } from "../FireInsurance/fireInsuranceRequest";
import { defaultFireInsuranceRequest } from "../../../resources/defaults/request/defaultFireInsuranceRequest";
import { FireInsuranceCompany } from "../FireInsuranceCompany/fireInsuranceCompany";
import { FireInsuranceCompanyRequest } from "../FireInsuranceCompany/fireInsuranceCompanyRequest";
import { defaultFireInsuranceCompanyRequest } from "../../../resources/defaults/request/defaultFireInsuranceCompanyRequest";
import { FireInsuranceBrokerage } from "../FireInsuranceBrokerage/fireInsuranceBrokerage";
import { FireInsuranceBrokerageRequest } from "../FireInsuranceBrokerage/fireInsuranceBrokerageRequest";
import { defaultFireInsuranceBrokerageRequest } from "../../../resources/defaults/request/defaultFireInsuranceBrokerageRequest";
import { FireInsuranceBrokerRequest } from "../FireInsuranceBroker/fireInsuranceBrokerRequest";
import { FireInsuranceBroker } from "../FireInsuranceBroker/fireInsuranceBroker";
import { defaultFireInsuranceBrokerRequest } from "../../../resources/defaults/request/defaultFireInsuranceBrokerRequest";
import { SimpleSpousalRelationship } from "../SpousalRelationship/SimpleSpousalRelationship";
import { SpousalRelationshipRequest } from "../SpousalRelationship/SpousalRelationshipRequest";
import { defaultSpousalRelationshipRequest } from "../../../resources/defaults/request/defaultSpousalRelationshipRequest";
import { SimplePoaEoeRelationship } from "../PoaEoeRelationship/SimplePoaEoeRelationship";
import { PoaEoeRelationshipRequest } from "../PoaEoeRelationship/PoaEoeRelationshipRequest";
import { defaultPoaEoeRelationshipRequest } from "../../../resources/defaults/request/defaultPoaEoeRelationshipRequest";
import { Affidavit } from "../Affivadit/affidavit";
import { AffidavitRequest } from "../Affivadit/affidavitRequest";
import { defaultAffidavitRequest } from "../../../resources/defaults/request/defaultAffidavitRequest";
import { TitleInsurance } from "../TitleInsurance/titleInsurance";
import { TitleInsuranceRequest } from "../TitleInsurance/titleInsuranceRequest";
import { defaultTitleInsuranceRequest } from "../../../resources/defaults/request/defaultTitleInsuranceRequest";
import { SimpleTitleInsurancePremium } from "../TitleInsurancePremium/simpleTitleInsurancePremium";
import { TitleInsurancePremiumRequest } from "../TitleInsurancePremium/titleInsuranceRequest";
import { defaultTitleInsurancePremiumRequest } from "../../../resources/defaults/request/defaultTitleInsurancePremiumRequest";
import { StatDec } from "../StatutoryDeclarations/statDec";
import { StatDecRequest } from "../StatutoryDeclarations/statDecRequest";
import { defaultStatDecRequest } from "../../../resources/defaults/request/defaultStatDecRequest";
import { SimplePropertyPin } from "../PropertyPins/simplePropertyPin";
import { PropertyPinRequest } from "../PropertyPins/propertyPinRequest";
import { defaultPropertyPinRequest } from "../../../resources/defaults/request/defaultPropertyPinRequest";
import { Debt } from "../Debts/debt";
import { DebtRequest } from "../Debts/debtRequest";
import { defaultDebtRequest } from "../../../resources/defaults/request/defaultDebtRequest";
import { SimpleTrustLedger } from "../TrustLedger/simpleTrustLedger";
import { TrustLedgerRequest } from "../TrustLedger/trustLedgerRequest";
import { defaultTrustLedgerRequest } from "../../../resources/defaults/request/defaultTrustLedgerRequest";
import { CondoCorporation } from "../CondoCorporation/condoCorporation";
import { CondoCorporationRequest } from "../CondoCorporation/condoCorporationRequest";
import { defaultCondoCorporationRequest } from "../../../resources/defaults/request/defaultCondoCorporationRequest";
import { PropertyManagementCompany } from "../PropertyManagementCompany/propertyManagementCompany";
import { PropertyManagementCompanyRequest } from "../PropertyManagementCompany/propertyManagementCompanyRequest";
import { defaultPropertyManagementCompanyRequest } from "../../../resources/defaults/request/defaultPropertyManagementCompanyRequest";
import { Condition } from "../Condition/condition";
import { ConditionRequest } from "../Condition/conditionRequest";
import { defaultConditionRequest } from "../../../resources/defaults/request/defaultConditionRequest";
import { TemplateRequest } from "../Documents/templates/baseTemplateRequest";
import { DocRow } from "../../../../routes/UniversalSurvey/Documents/Documents";
import { defaultTemplateRequest } from "../../../resources/defaults/documents/defaultTemplateRequest";
import { GeneratedRequest } from "../Documents/generated/generatedRequest";
import { defaultGeneratedRequest } from "../../../resources/defaults/documents/defaultGeneratedRequest";
import { TransactionMappingCleanToDb } from "../../../resources/mappings/transactions";
import { SigningAppointmentRequest } from "../SigningAppointment/signingAppointmentRequest";
import { defaultSigningAppointmentRequest } from "../../../resources/defaults/request/defaultSigningAppointmentRequest";
import { SimpleSigningAppointment } from "../SigningAppointment/simpleSigningAppointment";
import { LegalProfessional } from "../LegalProfessional/legalProfessional";
import { LegalProfessionalRequest } from "../LegalProfessional/legalProfessionalRequest";
import { defaultLegalProfessionalRequest } from "../../../resources/defaults/request/defaultLegalProfessionalRequest";
import { GlobalLegalFirm, LegalFirm } from "../LegalFirm/legalFirm";
import { GlobalLegalFirmRequest, LegalFirmRequest } from "../LegalFirm/legalFirmRequest";
import { defaultGlobalLegalFirmRequest, defaultLegalFirmRequest } from "../../../resources/defaults/request/defaultLegalFirmRequest";
import { GlobalLegalFirmOffice, LegalFirmOffice } from "../LegalFirmOffice/legalFirmOffice";
import { GlobalLegalFirmOfficeRequest, LegalFirmOfficeRequest } from "../LegalFirmOffice/legalFirmOfficeRequest";
import { defaultGlobalLegalFirmOfficeRequest, defaultLegalFirmOfficeRequest } from "../../../resources/defaults/request/defaultLegalFirmOfficeRequest";
import { UploadedRequest } from "../Documents/uploaded/uploadedRequest";
import { defaultUploadedRequest } from "../../../resources/defaults/documents/defaultUploadedRequest";
import { defaultAddressRequest } from "../../../resources/defaults/request/defaultAddressRequest";
import { defaultFinancialInfoRequest } from "../../../resources/defaults/request/defaultFinancialInfoRequest";
import { CreatePaymentGroup } from "../TrustLedger/PaymentGroup/paymentGroup";
import { CreatePaymentGroupRequest } from "../TrustLedger/PaymentGroup/createPaymentGroupRequest";
import { defaultCreatePaymentGroupRequest } from "../../../resources/defaults/request/defaultCreatePaymentGroupRequest";
import { SimpleNewMortgageNetAdvanceAmount } from "../NewMortgageNetAdvanceAmount/simpleNewMortgageNetAdvanceAmount";
import { NewMortgageNetAdvanceAmountRequest } from "../NewMortgageNetAdvanceAmount/newMortgageNetAdvanceAmountRequest";
import { defaultNewMortgageNetAdvanceAmountRequest } from "../../../resources/defaults/request/defaultNewMortgageNetAdvanceAmountRequest";
import { PriorityBeforeClosingItem } from "../PriorityBeforeClosing/priorityBeforeClosing";
import { PriorityBeforeClosingRequest } from "../PriorityBeforeClosing/priorityBeforeClosingRequest";
import { LienPayoutCalcAdditionalAmount } from "../ExistingLienPayoutCalcAdditionalAmount/lienPayoutCalcAdditionalAmount";
import { LienPayoutCalcAdditionalAmountRequest } from "../ExistingLienPayoutCalcAdditionalAmount/lienPayoutCalcAdditionalAmountRequest";
import { defaultLienPayoutCalcAdditionalAmountRequest } from "../../../resources/defaults/frontend/defaultLienPayoutCalcAdditionalAmountRequest";
import { mapDocumentDefaultContextRequest } from "../Documents/utils/mapper";
import { Note } from "../Note/note";
import { NoteRequest } from "../Note/noteRequest";
import { defaultNoteRequest } from "../../../resources/defaults/request/defaultNoteRequest";
import { CreateLenderBranchRequest, LenderBranchRequest } from "../Lender/LenderBranch/lenderBranchRequest";
import { defaultLenderBranchRequest } from "../../../resources/defaults/request/defaultLenderBranchRequest";
import { defaultCreateLenderBranchRequest } from "../../../resources/defaults/request/defaultCreateLenderBranchRequest";
import { CreateSourceLenderRequest, SourceLenderRequest } from "../Lender/SourceLender/sourceLenderRequest";
import { defaultLenderSourceRequest } from "../../../resources/defaults/request/defaultLenderSourceRequest";
import { defaultCreateLenderSourceRequest } from "../../../resources/defaults/request/defaultCreateLenderSourceRequest";

function sanitizeRequest<
    Input, Output extends Object
>(inputObj: Input, defaultRequestObj: Output): Output {
    let k: keyof Input;
    const cleanObj = { ...defaultRequestObj };

    for (k in inputObj) {
        if (cleanObj.hasOwnProperty(k)) {
            if (inputObj[k] !== null && typeof inputObj[k] === "object" && (_.keys(defaultAddressRequest).every(key => key in (inputObj[k] as object)))) {
                cleanObj[k as keyof Output] = sanitizeRequest(inputObj[k], defaultAddressRequest) as Output[keyof Output];
            } else if (inputObj[k] !== null && typeof inputObj[k] === "object" && (_.keys(defaultFinancialInfoRequest).every(key => key in (inputObj[k] as object)))) {
                cleanObj[k as keyof Output] = sanitizeRequest(inputObj[k], defaultFinancialInfoRequest) as Output[keyof Output];
            } else {
                cleanObj[k as keyof Output] = inputObj[k] != undefined ? typeof inputObj[k] === "string" ? (inputObj[k] as string).trim() : inputObj[k] as any : null;
            }
        }
    }
    return cleanObj;
}

function sanitizeClientRequest(clientObj: Client): ClientRequest {
    return sanitizeRequest(clientObj, defaultClientRequest);
}

function sanitizePropertyRequest(propertyObj: Property): PropertyRequest {
    return sanitizeRequest(propertyObj, defaultPropertyRequest);
}

function sanitizePropertyPinRequest(pinObj: SimplePropertyPin): PropertyPinRequest {
    return sanitizeRequest(pinObj, defaultPropertyPinRequest)
}

function sanitizeLenderRequest(lenderObj: Lender): LenderRequest {
    let cleanObj = sanitizeRequest(lenderObj, defaultLenderRequest);
    cleanObj.lawyer_id = lenderObj.lawyer?.id ?? null;
    return cleanObj;
}

function sanitizeLenderSourceRequest(lenderObj: Lender): SourceLenderRequest {
    let cleanObj = sanitizeRequest(lenderObj, defaultLenderSourceRequest);
    return cleanObj;
}

function sanitizeCreateLenderSourceRequest(lenderObj: Lender): CreateSourceLenderRequest {
    let cleanObj = sanitizeRequest(lenderObj, defaultCreateLenderSourceRequest);
    return cleanObj;
}

function sanitizeLenderBranchRequest(lenderObj: Lender): LenderBranchRequest {
    let cleanObj = sanitizeRequest(lenderObj, defaultLenderBranchRequest);
    return cleanObj;
}

function sanitizeCreateLenderBranchRequest(lenderObj: Lender): CreateLenderBranchRequest {
    let cleanObj = sanitizeRequest(lenderObj, defaultCreateLenderBranchRequest);
    return cleanObj;
}

function sanitizeLenderContactRequest(lenderContactObj: LenderContact): LenderContactRequest {
    return sanitizeRequest(lenderContactObj, defaultLenderContactRequest);
}

function sanitizeGlobalLenderContactRequest(contactObj: GlobalLenderContact): GlobalLenderContactRequest {
    let cleanObj = sanitizeRequest(contactObj, defaultGlobalLenderContactRequest);
    cleanObj.lender_id = contactObj.lender.id
    return cleanObj;
}

function sanitizeMortgageBrokerageRequest(brokerageObj: MortgageBrokerage): MortgageBrokerageRequest {
    return sanitizeRequest(brokerageObj, defaultMortgageBrokerageRequest);
}

function sanitizeMortgageBrokerRequest(brokerObj: MortgageBroker): MortgageBrokerRequest {
    return sanitizeRequest(brokerObj, defaultMortgageBrokerRequest);
}

function sanitizeMortgageBrokerageOtherContactRequest(contactObj: MortgageBrokerageOtherContact): MortgageBrokerageOtherContactRequest {
    return sanitizeRequest(contactObj, defaultMortgageBrokerageOtherContactRequest);
}

function sanitizeNewMortgageRequest(mortgageObj: NewMortgage): NewMortgageRequest {
    return sanitizeRequest(mortgageObj, defaultNewMortgageRequest);
}

function sanitizeNewMortgageNetAdvanceAmountRequest(deductionObj: SimpleNewMortgageNetAdvanceAmount): NewMortgageNetAdvanceAmountRequest {
    return sanitizeRequest(deductionObj, defaultNewMortgageNetAdvanceAmountRequest);
}

function sanitizeLienRequest(lienObj: Lien): LienRequest {
    return sanitizeRequest(lienObj, defaultLienRequest);
}

function sanitizeLienAdditionalAmountRequest(amountObj: LienPayoutCalcAdditionalAmount): LienPayoutCalcAdditionalAmountRequest {
    return sanitizeRequest(amountObj, defaultLienPayoutCalcAdditionalAmountRequest);
}

function sanitizePrioritiesAfterClosingRequest(priorityList: PriorityAfterClosingItem[]): PriorityAfterClosingRequest {
    const prioritiesBody: PriorityAfterClosingRequest = { priorities: [] };
    for (const priorityItem of priorityList) {
        prioritiesBody.priorities.push(sanitizeRequest(priorityItem, defaultPriorityAfterClosingRequestItem));
    }
    return prioritiesBody;
}

function sanitizePrioritiesBeforeClosingRequest(priorityList: PriorityBeforeClosingItem[]): PriorityBeforeClosingRequest {
    const prioritiesBody: PriorityBeforeClosingRequest = { priorities: [] };
    for (const priorityItem of priorityList) {
        prioritiesBody.priorities.push(priorityItem.lien_record?.id ?? -1);
    }
    return prioritiesBody;
}

function sanitizeUndertakingRequest(undertakingObj: Undertaking): UndertakingRequest {
    return sanitizeRequest(undertakingObj, defaultUndertakingRequest);
}

function sanitizeChargeHolderCompanyRequest(companyObj: ChargeHolderCompany): ChargeHolderCompanyRequest {
    return sanitizeRequest(companyObj, defaultChargeHolderCompanyRequest);
}

function sanitizeGovernmentRequest(governmentObj: Government): GovernmentRequest {
    return sanitizeRequest(governmentObj, defaultGovernmentRequest);
}

function sanitizeChargeHolderCompanyContactRequest(contactObj: ChargeHolderCompanyContact): ChargeHolderCompanyContactRequest {
    return sanitizeRequest(contactObj, defaultChargeHolderCompanyContactRequest);
}

function sanitizeGovernmentContactRequest(contactObj: GovernmentContact): GovernmentContactRequest {
    return sanitizeRequest(contactObj, defaultGovernmentContactRequest);
}

function sanitizeOtherChargeHolderRequest(otherChargeHolderObj: OtherChargeHolder): OtherChargeHolderRequest {
    return sanitizeRequest(otherChargeHolderObj, defaultOtherChargeHolderRequest);
}

function sanitizeFeeRequest(feeObj: Fee): FeeRequest {
    return sanitizeRequest(feeObj, defaultFeeRequest);
}

function sanitizeDisbursementRequest(disbursementObj: Disbursement): DisbursementRequest {
    return sanitizeRequest(disbursementObj, defaultDisbursementRequest);
}

function sanitizeDealRequest(dealObj: Deal): DealRequest {
    return sanitizeRequest({
        ...dealObj,
        primary_transaction_type: dealObj.primary_transaction_type ? TransactionMappingCleanToDb[dealObj.primary_transaction_type as keyof typeof TransactionMappingCleanToDb] : undefined,
        lawyer_id: dealObj.lawyer_record?.id,
        signer_id: dealObj.signer_record?.id,
        owner_clerk_id: dealObj.owner_clerk?.id
    }, defaultDealRequest);
}

function sanitizeFireInsuranceRequest(fireInsuranceObj: FireInsurance): FireInsuranceRequest {
    return sanitizeRequest(fireInsuranceObj, defaultFireInsuranceRequest);
}

function sanitizeFireInsuranceCompanyRequest(fireInsuranceCompanyObj: FireInsuranceCompany): FireInsuranceCompanyRequest {
    return sanitizeRequest(fireInsuranceCompanyObj, defaultFireInsuranceCompanyRequest)
}

function sanitizeFireInsuranceBrokerageRequest(brokerageObj: FireInsuranceBrokerage): FireInsuranceBrokerageRequest {
    return sanitizeRequest(brokerageObj, defaultFireInsuranceBrokerageRequest);
}

function sanitizeFireInsuranceBrokerRequest(brokerObj: FireInsuranceBroker): FireInsuranceBrokerRequest {
    return sanitizeRequest(brokerObj, defaultFireInsuranceBrokerRequest);
}

function sanitizeSpousalRelationshipRequest(relationshipObj: SimpleSpousalRelationship): SpousalRelationshipRequest {
    const objWithIdFields = { ...relationshipObj, spouse_one_id: relationshipObj.spouse_one?.id, spouse_two_id: relationshipObj.spouse_two?.id };
    return sanitizeRequest(objWithIdFields, defaultSpousalRelationshipRequest);
}

function sanitizePoaEoeRelationshipRequest(relationshipObj: SimplePoaEoeRelationship): PoaEoeRelationshipRequest {
    const objWithIdFields = { ...relationshipObj, representative_id: relationshipObj.representative?.id, representee_id: relationshipObj.representee?.id };
    return sanitizeRequest(objWithIdFields, defaultPoaEoeRelationshipRequest);
}

function sanitizeAffidavitRequest(affidavitObj: Affidavit): AffidavitRequest {
    return sanitizeRequest(affidavitObj, defaultAffidavitRequest);
}

function sanitizeTitleInsuranceRequest(titleInsuranceObj: TitleInsurance): TitleInsuranceRequest {
    return sanitizeRequest(titleInsuranceObj, defaultTitleInsuranceRequest);
}

function sanitizeTitleInsurancePremiumRequest(premiumObj: SimpleTitleInsurancePremium): TitleInsurancePremiumRequest {
    return sanitizeRequest(premiumObj, defaultTitleInsurancePremiumRequest);
}

function sanitizeStatDecRequest(statDecObj: StatDec): StatDecRequest {
    return sanitizeRequest(statDecObj, defaultStatDecRequest);
}

function sanitizeDebtRequest(debtObj: Debt): DebtRequest {
    return sanitizeRequest(debtObj, defaultDebtRequest);
}

function sanitizeTrustLedgerRequest(trustLedgerObj: SimpleTrustLedger): TrustLedgerRequest {
    return sanitizeRequest(trustLedgerObj, defaultTrustLedgerRequest);
}

function sanitizeCondoCorporationRequest(condoCorpObj: CondoCorporation): CondoCorporationRequest {
    const cleanObj = sanitizeRequest(condoCorpObj, defaultCondoCorporationRequest);
    cleanObj.property_management_company_record_id = condoCorpObj.property_management_company_record?.id ?? null;
    return cleanObj;
}

function sanitizePropertyManagementCompanyRequest(pmcObj: PropertyManagementCompany): PropertyManagementCompanyRequest {
    return sanitizeRequest(pmcObj, defaultPropertyManagementCompanyRequest);
}

function sanitizeConditionRequest(condition: Condition): ConditionRequest {
    return sanitizeRequest(condition, defaultConditionRequest);
}

function sanitizeTemplateDocRowRequest(docRow: DocRow): TemplateRequest {
    const cleanContext = sanitizeRequest(docRow.context, mapDocumentDefaultContextRequest(docRow.type));
    return sanitizeRequest({ ...docRow, context: cleanContext }, defaultTemplateRequest);
}

function sanitizeUploadedDocRowRequest(docRow: DocRow): UploadedRequest {
    return sanitizeRequest(docRow, defaultUploadedRequest);
}

function santitizeGeneratedDocRowRequest(docRow: DocRow): GeneratedRequest {
    delete docRow.context?.client_records;
    return sanitizeRequest(docRow, defaultGeneratedRequest);
}

function sanitizeSigningAppointmentRequest(signingAppointment: SimpleSigningAppointment): SigningAppointmentRequest {
    return sanitizeRequest(signingAppointment, defaultSigningAppointmentRequest);
}

function sanitizeLegalProfessionalRequest(professional: LegalProfessional): LegalProfessionalRequest {
    return sanitizeRequest(professional, defaultLegalProfessionalRequest);
}

function sanitizeLegalFirmRequest(firm: LegalFirm): LegalFirmRequest {
    return sanitizeRequest(firm, defaultLegalFirmRequest);
}

function sanitizeGlobalLegalFirmRequest(firm: GlobalLegalFirm): GlobalLegalFirmRequest {
    return sanitizeRequest(firm, defaultGlobalLegalFirmRequest);
}

function sanitizeLegalFirmOfficeRequest(office: LegalFirmOffice): LegalFirmOfficeRequest {
    return sanitizeRequest(office, defaultLegalFirmOfficeRequest);
}

function sanitizeGlobalLegalFirmOfficeRequest(office: GlobalLegalFirmOffice): GlobalLegalFirmOfficeRequest {
    return sanitizeRequest(office, defaultGlobalLegalFirmOfficeRequest);
}

function sanitizeCreatePaymentGroupRequest(paymentGroup: CreatePaymentGroup): CreatePaymentGroupRequest {
    return sanitizeRequest(paymentGroup, defaultCreatePaymentGroupRequest);
}

function sanitizeNoteRequest(note: Note): NoteRequest {
    return sanitizeRequest(note, defaultNoteRequest);
}

export { 
    sanitizeClientRequest,
    sanitizePropertyRequest,
    sanitizePropertyPinRequest,
    sanitizeLenderRequest,
    sanitizeLenderBranchRequest,
    sanitizeCreateLenderBranchRequest,
    sanitizeCreateLenderSourceRequest,
    sanitizeLenderSourceRequest,
    sanitizeLenderContactRequest,
    sanitizeGlobalLenderContactRequest,
    sanitizeMortgageBrokerageRequest,
    sanitizeMortgageBrokerRequest,
    sanitizeMortgageBrokerageOtherContactRequest,
    sanitizeNewMortgageRequest,
    sanitizeNewMortgageNetAdvanceAmountRequest,
    sanitizeLienRequest,
    sanitizeLienAdditionalAmountRequest,
    sanitizePrioritiesAfterClosingRequest,
    sanitizePrioritiesBeforeClosingRequest,
    sanitizeUndertakingRequest,
    sanitizeChargeHolderCompanyRequest,
    sanitizeGovernmentRequest,
    sanitizeChargeHolderCompanyContactRequest,
    sanitizeGovernmentContactRequest,
    sanitizeOtherChargeHolderRequest,
    sanitizeFeeRequest,
    sanitizeDisbursementRequest,
    sanitizeDealRequest,
    sanitizeFireInsuranceRequest,
    sanitizeFireInsuranceCompanyRequest,
    sanitizeFireInsuranceBrokerageRequest,
    sanitizeFireInsuranceBrokerRequest,
    sanitizeSpousalRelationshipRequest,
    sanitizePoaEoeRelationshipRequest,
    sanitizeAffidavitRequest,
    sanitizeTitleInsuranceRequest,
    sanitizeTitleInsurancePremiumRequest,
    sanitizeStatDecRequest,
    sanitizeDebtRequest,
    sanitizeTrustLedgerRequest,
    sanitizeCondoCorporationRequest,
    sanitizePropertyManagementCompanyRequest,
    sanitizeConditionRequest,
    sanitizeTemplateDocRowRequest,
    sanitizeUploadedDocRowRequest,
    santitizeGeneratedDocRowRequest,
    sanitizeSigningAppointmentRequest,
    sanitizeLegalProfessionalRequest,
    sanitizeLegalFirmRequest,
    sanitizeGlobalLegalFirmRequest,
    sanitizeLegalFirmOfficeRequest,
    sanitizeGlobalLegalFirmOfficeRequest,
    sanitizeCreatePaymentGroupRequest,
    sanitizeNoteRequest
};