// Type
export interface InputErrorProps {
    showError: boolean;
    message?: string;
    errorState?: Object;
    errorKey?: string
    setErrorState?: (value: Object) => void;
    customValidation?: (value: any) => boolean; // If returns false field is invalid
    overwriteInternalError?: boolean; // Bypasses internal component validation
}
const VALIDATE_ON_FOCUS = false;

// URLS
const validateUrl = (url: string) => {
    return String(url)
        .toLowerCase()
        .match(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        );
};

const urlErrorMessage = "Must be a valid URL (Starts with https://, http://, or www.)";

// Emails
const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const emailErrorMessage = "Must be a valid email";

function validateModalObject(obj: Object): boolean {
    let hasError = false;
    for (const key in obj) {
        // Has error (value is true)
        if (obj[key as keyof Object]) {
            hasError = true;
        }
    }
    return hasError;
}

export {
    VALIDATE_ON_FOCUS,
    validateUrl,
    validateEmail,
    validateModalObject,
    urlErrorMessage,
    emailErrorMessage
}