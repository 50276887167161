import { Dispatch } from 'react';

import * as actions from "../../actionTypes"
import _ from "lodash";
import {
    createCollateralProperty,
    createOtherProperty,
    removeCollateralProperty,
    removeOtherProperty,
    createBorrower,
    createAdvisee,
    createTransferee,
    createTransferor,
    removeAdvisee,
    removeBorrower,
    removeTransferee,
    removeTransferor,
    createGuarantor,
    removeGuarantor,
} from "../../../../libs/service/axios/api";
import { sortMortgages } from './mortgages';
import {
    sortTitleTransfers,
    labelTitleTransfer
} from './titleTransfers';
import { Role } from '../../../../libs/types/UniversalSurvey/Roles/role';
import { Transaction } from '../../../../libs/types/UniversalSurvey/Transaction/transaction';
import { TransactionMappingCleanToDb, TransactionMappingDbToClean } from '../../../../libs/resources/mappings/transactions';
import { RoleTypes, RoleTypesDB } from '../../../../libs/resources/enums/roles';
import { RoleMapping } from '../../../../libs/resources/mappings/roles';
import { TransactionTypes } from '../../../../libs/resources/enums/transactions';
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { RecordType } from '../../../../libs/resources/enums/recordTypes';
import { refreshDealInfo, updateDealPropertyData } from '../deal';

function createRolesForEntity(dispatch: Dispatch<Record<string, any>>, roles: Role[], transactions: Transaction[]) {
    const tempRoles: Role[] = [];
    for (const role of roles) {
        let tempRole: Role = { ...role };
        const transaction = transactions.find((transaction) => (
            transaction.id === tempRole.record_id &&
            transaction.transaction_type === TransactionMappingDbToClean[tempRole.record_type as unknown as keyof typeof TransactionMappingDbToClean]
        ));
        if (transaction) {
            tempRole.label = labelRole(tempRole.role, transaction?.label);
            tempRoles.push(tempRole)
        }
    }
    dispatch({ type: actions.SET_ROLES, payload: sortRoles(tempRoles) });
}

function removeRole(dispatch: Dispatch<Record<string, any>>, dealId: string, roles: Role[], roleToRemove: Role, entityId: number, removeFromState: boolean = true): void {
    const transactionId = roleToRemove.record_id

    let dispatchCall = function() {
        if (removeFromState) {
            dispatch({ type: actions.SET_ROLES, payload: sortRoles([...roles.filter((role) => !_.isEqual(role, roleToRemove))]) });
            dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: entityId, subtitle: undefined } });
        }
    }

    let removeRequest;
    switch (roleToRemove.role) {
        case RoleTypesDB.Subject:
            updateDealPropertyData(dispatch, dealId, undefined);
            refreshDealInfo(dispatch, dealId);
            dispatchCall();
            break;
        case RoleTypesDB.Collateral:
            removeRequest = removeCollateralProperty(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Other:
            removeRequest = removeOtherProperty(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Borrower:
            removeRequest = removeBorrower(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Advisee:
            removeRequest = removeAdvisee(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Transferee:
            removeRequest = removeTransferee(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Transferor:
            removeRequest = removeTransferor(dealId, String(transactionId), String(entityId));
            break;
        case RoleTypesDB.Guarantor:
            removeRequest = removeGuarantor(dealId, String(transactionId), String(entityId));
            break;
    }
    removeRequest?.then(function () {
        dispatchCall();
        refreshDealInfo(dispatch, dealId);
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing role: ${error}`, type: AlertTypes.Error } });
    })
}

function addOrEditRole(dispatch: Dispatch<Record<string, any>>, dealId: string, roles: Role[], roleToEdit: Role | undefined, newTransaction: Transaction, newRole: RoleTypes, entityId: number) {
    if (roleToEdit) {
        removeRole(dispatch, dealId, roles, roleToEdit, entityId, false);
        dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: entityId, subtitle: undefined } });
    }

    let addRequest;
    let role: RoleTypesDB;

    let dispatchCall = function(role: RoleTypesDB) {
        dispatch({
            type: actions.SET_ROLES,
            payload: sortRoles([...roles.filter((role) => !_.isEqual(role, roleToEdit)), {
                record_id: newTransaction.id,
                record_type: TransactionMappingCleanToDb[newTransaction.transaction_type as keyof typeof TransactionMappingCleanToDb] as unknown as RecordType,
                role: role,
                label: labelRole(role, newTransaction.label),
            }])
        });
    }

    switch (newRole) {
        case RoleTypes.Subject:
            updateDealPropertyData(dispatch, dealId, entityId);
            dispatchCall(RoleTypesDB.Subject);
            refreshDealInfo(dispatch, dealId);
            break;
        case RoleTypes.Collateral:
            addRequest = createCollateralProperty(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Collateral
            break;
        case RoleTypes.Other:
            addRequest = createOtherProperty(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Other
            break;
        case RoleTypes.Borrower:
            addRequest = createBorrower(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Borrower
            break;
        case RoleTypes.Advisee:
            addRequest = createAdvisee(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Advisee
            break;
        case RoleTypes.Transferee:
            addRequest = createTransferee(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Transferee
            break;
        case RoleTypes.Transferor:
            addRequest = createTransferor(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Transferor
            break;
        case RoleTypes.Guarantor:
            addRequest = createGuarantor(dealId, String(newTransaction.id), String(entityId));
            role = RoleTypesDB.Guarantor
            break;
    }
    addRequest?.then(function () {
        dispatchCall(role);
        refreshDealInfo(dispatch, dealId);
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating role: ${error}`, type: AlertTypes.Error } });
    })
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: entityId, subtitle: newRole } })
}

function sortRoles(roles: Role[]) {
    const deal = roles.filter((role) => role.record_type === RecordType.Deal);
    const mortgages = sortMortgages(roles.filter((role) => role.record_type === TransactionMappingCleanToDb[TransactionTypes.Mortgage] as unknown as RecordType));
    const ilas = roles.filter((role) => role.record_type === TransactionMappingCleanToDb[TransactionTypes.ILA] as unknown as RecordType).sort((a, b) => a.record_id > b.record_id ? 1 : -1);
    const titleTransfers = sortTitleTransfers(roles.filter((role) => role.record_type === TransactionMappingCleanToDb[TransactionTypes.TitleTransfer] as unknown as RecordType));

    return [...deal, ...mortgages, ...ilas, ...titleTransfers];
}

function labelRole(role: RoleTypesDB, transactionLabel: string | undefined) {
    if (transactionLabel) {
        if (transactionLabel.includes("Title Transfer")) {
            return labelTitleTransfer(role, transactionLabel);
        }
        return `${transactionLabel}: ${RoleMapping[role]}`;
    }
    return RoleMapping[role];
}

export {
    createRolesForEntity,
    removeRole,
    addOrEditRole
}