import { Generated } from "../../../types/UniversalSurvey/Documents/generated/generated";
import { GenerateType } from "../../enums/documents/generateType";

export const defaultGenerated: Generated = {
    id: -1,
    name: undefined,
    generate_status: undefined,
    generate_type: GenerateType.PDF,
    parent_id: undefined,
    presigned_url: undefined
}