// Importing React tools
import { useContext, useState, useEffect, useCallback, useRef } from "react";

// Importing mui design kit components
import { Grid, Stack, styled } from "@mui/material";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing application components
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { Body, BodyBold, H4, Small } from "../../../../components/Common/Typography/index";
import { createNewFee, getFeesInDeal, saveFeeInDeal, deleteFee } from "../../../context/UniversalSurvey/asyncActions/fees";
import color from '../../../../components/Common/componentStyling/Colors';
import theme from '../../../../components/Common/componentStyling/Theme';
import BasicButton from "../../../../components/Common/Button/BasicButton";
import LineItemInput from "../../../../components/Common/LineItemInput/LineItemInput";
import { debounce } from "../../../libs/utils/debounce";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import { createNewDisbursement, getDisbursementsInDeal, saveDisbursementInDeal, deleteDisbursement } from "../../../context/UniversalSurvey/asyncActions/disbursements";
import { DisbursementSource, DisbursementTypes } from "../../../libs/resources/enums/disbursements";
import { TaxRates, TaxTypes } from "../../../libs/resources/enums/taxes";
import { FeeSource } from "../../../libs/resources/enums/fees";
import { SimpleFee } from "../../../libs/types/UniversalSurvey/Fees/simpleFee";
import { SimpleDisbursement } from "../../../libs/types/UniversalSurvey/Disbursements/simpleDisbursement";
import { calculateTaxAmount } from "../../../libs/utils/taxes";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import { DisbursementListItem } from "../../../libs/types/UniversalSurvey/Disbursements/disbursement";
import { DisbursementOptionsNotSubjectToTax, DisbursementOptionsSubjectToTax } from "../../../libs/resources/options";

export default function StatementOfAccountsSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [feeList, setFeeList] = useState<SimpleFee[]>([]);
    const [taxableDisbursementList, setTaxableDisbursementList] = useState<SimpleDisbursement[]>([]);
    const [nonTaxableDisbursementList, setNonTaxableDisbursementList] = useState<SimpleDisbursement[]>([]);
    const [feeSubtotal, setFeeSubtotal] = useState<number>(0);
    const [feeTaxTotal, setFeeTaxTotal] = useState<number>(0);
    const [taxableDisSubtotal, setTaxableDisSubtotal] = useState<number>(0);
    const [nonTaxableDisSubtotal, setNonTaxableDisSubtotal] = useState<number>(0);
    const [disbursementTaxTotal, setDisbursementTaxTotal] = useState<number>(0);
    const [taxTotal, setTaxTotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [taxType, setTaxType] = useState<string>("GST");
    const [taxString, setTaxString] = useState<string>("Canada GST (5%)");

    const feeRef = useRef<SimpleFee[]>(state.fees.fees);
    const disbursementRef = useRef<SimpleDisbursement[]>(state.disbursements.disbursements);

    useEffect(() => {
        getFeesInDeal(dispatch, String(state.deal.dealInfo?.id));
        getDisbursementsInDeal(dispatch, String(state.deal.dealInfo?.id));
        cleanUpEmptyFeesAndDisbursements();

        return () => cleanUpEmptyFeesAndDisbursements();
    }, []);

    useEffect(() => {
        switch (state.deal.dealInfo?.region_id) {
            case 1:
                setTaxType(TaxTypes.Ontario);
                setTaxString(`Ontario ${TaxTypes.Ontario} (${TaxRates.Ontario * 100}%)`);
                break;
            case 2:
                setTaxType(TaxTypes.BritishColumbia);
                setTaxString(`British Columbia ${TaxTypes.BritishColumbia} (${TaxRates.BritishColumbia * 100}%)`);
                break;
            case 3:
                setTaxType(TaxTypes.Alberta);
                setTaxString(`Alberta ${TaxTypes.Alberta} (${TaxRates.Alberta * 100}%)`);
                break;
        }
    }, [state.deal.dealInfo?.region_id]);

    useEffect(() => {
        feeRef.current = state.fees.fees
        setFeeList(state.fees.fees);
    }, [state.fees.fees]);

    useEffect(() => {
        disbursementRef.current = state.disbursements.disbursements
        let tempTaxableDisbursementList: SimpleDisbursement[] = [];
        let tempNonTaxableDisbursementList: SimpleDisbursement[] = [];
        for (const disbursement of state.disbursements.disbursements) {
            if (disbursement.type === DisbursementTypes.Taxable || disbursement.type === DisbursementTypes.Split) {
                tempTaxableDisbursementList.push(disbursement);
            }
            if (disbursement.type === DisbursementTypes.NonTaxable || disbursement.type === DisbursementTypes.Split) {
                tempNonTaxableDisbursementList.push(disbursement);
            }
        }
        setTaxableDisbursementList(tempTaxableDisbursementList);
        setNonTaxableDisbursementList(tempNonTaxableDisbursementList);
    }, [state.disbursements.disbursements])

    useEffect(() => {
        let tempSubtotal = 0;
        for (const fee of feeList) {
            if (fee.amount) tempSubtotal += fee.amount;
        }
        setFeeSubtotal(tempSubtotal);
        setFeeTaxTotal(calculateTaxAmount(state.deal.dealInfo?.region_id, tempSubtotal));

        for (const fee of feeList) {
            if (fee.amount === undefined || (fee.description === "" || fee.description === undefined)) {
                dispatch({ type: actions.SET_EMPTY_FEE_ADDED, payload: true });
                return;
            }
        }
        dispatch({ type: actions.SET_EMPTY_FEE_ADDED, payload: false });
    }, [feeList]);

    useEffect(() => {
        let tempTaxedSubtotal = 0;
        let tempNonTaxSubtotal = 0;
        for (const disbursement of taxableDisbursementList) {
            if (disbursement.taxable_amount) tempTaxedSubtotal += disbursement.taxable_amount;
        }
        for (const disbursement of nonTaxableDisbursementList) {
            if (disbursement.non_taxable_amount) tempNonTaxSubtotal += disbursement.non_taxable_amount;
        }
        setTaxableDisSubtotal(tempTaxedSubtotal);
        setNonTaxableDisSubtotal(tempNonTaxSubtotal);
        setDisbursementTaxTotal(calculateTaxAmount(state.deal.dealInfo?.region_id, tempTaxedSubtotal));
    }, [taxableDisbursementList, nonTaxableDisbursementList]);

    useEffect(() => {
        for (const disbursement of taxableDisbursementList) {
            if (disbursement.taxable_amount === undefined || (disbursement.taxable_desc === "" || disbursement.taxable_desc === undefined)) {
                dispatch({ type: actions.SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED, payload: true });
                return;
            }
        }
        dispatch({ type: actions.SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED, payload: false });
    }, [taxableDisbursementList]);

    useEffect(() => {
        for (const disbursement of nonTaxableDisbursementList) {
            if (disbursement.non_taxable_amount === undefined || (disbursement.non_taxable_desc === "" || disbursement.non_taxable_desc === undefined)) {
                dispatch({ type: actions.SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED, payload: true });
                return;
            }
        }
        dispatch({ type: actions.SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED, payload: false });
    }, [nonTaxableDisbursementList]);

    useEffect(() => {
        setTaxTotal(disbursementTaxTotal + feeTaxTotal);
        setTotal(taxableDisSubtotal + feeSubtotal + nonTaxableDisSubtotal);
    }, [disbursementTaxTotal, feeTaxTotal, taxableDisSubtotal, feeSubtotal, nonTaxableDisSubtotal]);

    function cleanUpEmptyFeesAndDisbursements() {
        for (const fee of feeRef.current) {
            if (fee.amount === undefined && !fee.description) {
                deleteFee(dispatch, String(state.deal.dealInfo?.id), fee.id);
            }
        }

        for (const disbursement of disbursementRef.current) {
            if (disbursement.non_taxable_amount === undefined && !disbursement.non_taxable_desc && disbursement.taxable_amount === undefined && !disbursement.taxable_desc) {
                deleteDisbursement(dispatch, String(state.deal.dealInfo?.id), disbursement.id);
            }
        }
    }

    function updateFeeDescription(id: number, value: string) {
        let tempFeeList = [...feeList];
        const matchingFee = tempFeeList.find((fee) => fee.id === id);
        if (matchingFee) {
            tempFeeList[tempFeeList.indexOf(matchingFee)].description = value;
            setFeeList(tempFeeList);
            debouncedFeeSave(tempFeeList[tempFeeList.indexOf(matchingFee)]);
            dispatch({ type: actions.SET_CUSTOM_FEES, payload: tempFeeList });
        }
    }
    
    function updateFeeValue(id: number, value: number) {
        let tempFeeList = [...feeList];
        const matchingFee = tempFeeList.find((fee) => fee.id === id);
        if (matchingFee) {
            tempFeeList[tempFeeList.indexOf(matchingFee)].amount = value;
            setFeeList(tempFeeList);
            debouncedFeeSave(tempFeeList[tempFeeList.indexOf(matchingFee)]);
            dispatch({ type: actions.SET_CUSTOM_FEES, payload: tempFeeList });
        }
    }

    function updateDisbursementDescription(id: number, value: string, type: DisbursementTypes) {
        let tempDisbursementList = type === DisbursementTypes.Taxable ? [...taxableDisbursementList] : [...nonTaxableDisbursementList];
        const matchingDisbursement = tempDisbursementList.find((disbursement) => disbursement.id === id);
        if (matchingDisbursement) {
            tempDisbursementList[tempDisbursementList.indexOf(matchingDisbursement)][type === DisbursementTypes.Taxable ? "taxable_desc" : "non_taxable_desc"] = value;
            type === DisbursementTypes.Taxable ? setTaxableDisbursementList(tempDisbursementList) : setNonTaxableDisbursementList(tempDisbursementList);
            debouncedDisbursementSave(tempDisbursementList[tempDisbursementList.indexOf(matchingDisbursement)]);
        }
    }

    function updateDisbursementValue(id: number, value: number, type: DisbursementTypes) {
        let tempDisbursementList = type === DisbursementTypes.Taxable ? [...taxableDisbursementList] : [...nonTaxableDisbursementList];
        const matchingDisbursement = tempDisbursementList.find((disbursement) => disbursement.id === id);
        if (matchingDisbursement) {
            tempDisbursementList[tempDisbursementList.indexOf(matchingDisbursement)][type === DisbursementTypes.Taxable ? "taxable_amount" : "non_taxable_amount"] = value;
            type === DisbursementTypes.Taxable ? setTaxableDisbursementList(tempDisbursementList) : setNonTaxableDisbursementList(tempDisbursementList);
            debouncedDisbursementSave(tempDisbursementList[tempDisbursementList.indexOf(matchingDisbursement)]);
        }
    }

    const debouncedFeeSave = useCallback(
        debounce((feeInfo) => {
            saveFeeInDeal(dispatch, String(state.deal.dealInfo?.id), feeInfo);
        }, 1000), []);
    
    const debouncedDisbursementSave = useCallback(
        debounce((disbursementInfo) => {
            saveDisbursementInDeal(dispatch, String(state.deal.dealInfo?.id), disbursementInfo);
        }, 1000), []);

    return (
        isObjectLoading(state.dataSheet.objectsLoading, [Loading.FeeList, Loading.DisbursementList]) ? <CircularLoader containerHeight="70vh" /> :
        <div>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Small style={{ color: theme.INPUT }}>
                                Tax rate:
                            </Small>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Small style={{ color: theme.INPUT }}>
                                    {taxString}
                                </Small>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AccountingContainer>
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={12}>
                        <H4>Fees</H4>
                    </Grid>
                    <Grid item xs={12}>
                        {feeList.filter((fee) => fee.source !== FeeSource.Manual).map((feeItem, index) => (
                            <LineItemInput
                                key={index}
                                textValue={feeItem.description}
                                size={8}
                                valueItems={[ { size: 4, value: feeItem.amount !== undefined ? String(feeItem.amount) : undefined } ]}
                                readOnly
                            />
                        ))}
                        {feeList.filter((fee) => fee.source === FeeSource.Manual).map((feeItem, index) => (
                            <LineItemInput
                                key={index}
                                textValue={feeItem.description}
                                placeholder={"New line item"}
                                textOnChange={(e) => updateFeeDescription(feeItem.id, e.target.value)}
                                valueItems={[
                                    { 
                                        size: 4,
                                        value: feeItem.amount !== undefined ? String(feeItem.amount) : undefined,
                                        valueType: feeItem.discount ? "negative" : "either",
                                        onChange: (e) => updateFeeValue(feeItem.id, e.target.value),
                                        id: "amount"
                                    }
                                ]}
                                size={8}
                                onRemove={() => deleteFee(dispatch, String(state.deal.dealInfo?.id), feeItem.id)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={2}>
                            <BasicButton
                                label={{ text: "Add Line Item", inputId: "add-line-item" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createNewFee(dispatch, String(state.deal.dealInfo?.id))}
                                disabled={state.fees.emptyFeeAdded}
                            />
                            <BasicButton
                                label={{ text: "Add Discount", inputId: "add-discount" }}
                                action="add"
                                typeOf="secondary"
                                disabled={state.fees.emptyFeeAdded}
                                onClick={() => createNewFee(dispatch, String(state.deal.dealInfo?.id), true)}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </AccountingContainer>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Body>
                                Subtotal:
                            </Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Body>
                                    <FormatNumeric value={feeSubtotal} />
                                </Body>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Body>
                                {taxType}:
                            </Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Body>
                                    <FormatNumeric value={feeTaxTotal} />
                                </Body>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AccountingContainer>
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={12}>
                        <H4>Disbursements (Subject to Taxes)</H4>
                    </Grid>
                    <Grid item xs={12}>
                        {taxableDisbursementList.filter((disbursement) => disbursement.source !== DisbursementSource.Manual).map((disbursementItem, index) => (
                            <LineItemInput
                                key={index}
                                textValue={disbursementItem.taxable_desc}
                                size={8}
                                valueItems={[ { size: 4, value: disbursementItem.taxable_amount !== undefined ? String(disbursementItem.taxable_amount) : undefined } ]}
                                readOnly
                            />
                        ))}
                        {taxableDisbursementList.filter((disbursement) => disbursement.source === DisbursementSource.Manual).map((disbursementItem, index) => (
                            <LineItemInput
                                key={index}
                                textValue={disbursementItem.taxable_desc}
                                placeholder={"New line item"}
                                textOnChange={(value) => updateDisbursementDescription(disbursementItem.id, value, DisbursementTypes.Taxable)}
                                onChangeFn={(value: DisbursementListItem) => {
                                    updateDisbursementDescription(disbursementItem.id, value.description, DisbursementTypes.Taxable);
                                    updateDisbursementValue(disbursementItem.id, value.amount ?? 0, DisbursementTypes.Taxable);
                                }}
                                autocomplete
                                options={DisbursementOptionsSubjectToTax}
                                valueItems={[ 
                                    { 
                                        size: 4, 
                                        value: disbursementItem.taxable_amount !== undefined ? String(disbursementItem.taxable_amount) : undefined,
                                        valueType: "positive",
                                        onChange: (e) => updateDisbursementValue(disbursementItem.id, e.target.value, DisbursementTypes.Taxable),
                                        id: "taxable_amount"
                                    } 
                                ]}
                                size={8}
                                onRemove={() => deleteDisbursement(dispatch, String(state.deal.dealInfo?.id), disbursementItem.id)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={2}>
                            <BasicButton
                                label={{ text: "Add Line Item", inputId: "add-line-item" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createNewDisbursement(dispatch, String(state.deal.dealInfo?.id), DisbursementTypes.Taxable)}
                                disabled={state.disbursements.emptyTaxableDisbursementAdded}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </AccountingContainer>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Body>
                                Subtotal:
                            </Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Body>
                                    <FormatNumeric value={taxableDisSubtotal} />
                                </Body>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Body>
                                {taxType}:
                            </Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Body>
                                    <FormatNumeric value={disbursementTaxTotal} />
                                </Body>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AccountingContainer>
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={12}>
                        <H4>Disbursements (Not Subject to Taxes)</H4>
                    </Grid>
                    <Grid item xs={12}>
                        {nonTaxableDisbursementList.filter((disbursement) => disbursement.source !== DisbursementSource.Manual).map((disbursementItem, index) => (
                            (disbursementItem.type === DisbursementTypes.NonTaxable || disbursementItem.type === DisbursementTypes.Split) && (
                                <LineItemInput
                                    key={index}
                                    textValue={disbursementItem.non_taxable_desc}
                                    size={8}
                                    valueItems={[ { size: 4, value: disbursementItem.non_taxable_amount !== undefined ? String(disbursementItem.non_taxable_amount) : undefined } ]}
                                    readOnly
                                />
                            )
                        ))}
                        {nonTaxableDisbursementList.filter((disbursement) => disbursement.source === DisbursementSource.Manual).map((disbursementItem, index) => (
                            <LineItemInput
                                key={index}
                                textValue={disbursementItem.non_taxable_desc}
                                placeholder={"New line item"}
                                textOnChange={(value) => updateDisbursementDescription(disbursementItem.id, value, DisbursementTypes.NonTaxable)}
                                onChangeFn={(value: DisbursementListItem) => {
                                    updateDisbursementDescription(disbursementItem.id, value.description, DisbursementTypes.NonTaxable);
                                    updateDisbursementValue(disbursementItem.id, value.amount ?? 0, DisbursementTypes.NonTaxable);
                                }}
                                autocomplete
                                options={DisbursementOptionsNotSubjectToTax}
                                valueItems={[ 
                                    { 
                                        size: 4, 
                                        value: disbursementItem.non_taxable_amount !== undefined ? String(disbursementItem.non_taxable_amount) : undefined,
                                        valueType: "positive",
                                        onChange: (e) => updateDisbursementValue(disbursementItem.id, e.target.value, DisbursementTypes.NonTaxable),
                                        id: "non_taxable_amount"
                                    } 
                                ]}
                                size={8}
                                onRemove={() => deleteDisbursement(dispatch, String(state.deal.dealInfo?.id), disbursementItem.id)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={2}>
                            <BasicButton
                                label={{ text: "Add Line Item", inputId: "add-line-item" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createNewDisbursement(dispatch, String(state.deal.dealInfo?.id), DisbursementTypes.NonTaxable)}
                                disabled={state.disbursements.emptyNonTaxableDisbursementAdded}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </AccountingContainer>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Body>
                                Subtotal:
                            </Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Body>
                                    <FormatNumeric value={nonTaxableDisSubtotal} />
                                </Body>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DividerContainer>
                <TotalDivider />
            </DividerContainer>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <BodyBold>
                                Total:
                            </BodyBold>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <BodyBold>
                                    <FormatNumeric value={total} />
                                </BodyBold>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <BodyBold>
                                {taxType} total:
                            </BodyBold>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <BodyBold>
                                    <FormatNumeric value={taxTotal} />
                                </BodyBold>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <BodyBold>
                                Grand Total:
                            </BodyBold>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end">
                                <BodyBold>
                                    <FormatNumeric value={total + taxTotal} />
                                </BodyBold>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
};

const AccountingContainer = styled('div')({
    paddingTop: "4rem",
})

const DividerContainer = styled('div')({
    paddingTop: "5rem",
    paddingBottom: "5rem"
});

const TotalDivider = styled("div")({
    height: "0.2rem",
    width: "100%",
    backgroundColor: color.BLACK,
})