import * as actions from "../actionTypes"

import { Lender, LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { SimpleSourceLender } from "../../../libs/types/UniversalSurvey/Lender/SourceLender/simpleSourceLender";
import { findAndReplace } from "../../../libs/utils/arrays";


export type LendersState = {
    lenderOptionList: LenderListItem[];
    lenderSourceList: SimpleSourceLender[];
    lenderInfo: Lender | undefined;
    selectedLender: LenderListItem | undefined;
    editingLenderRecord: boolean;
}

export const defaultLendersState: LendersState = {
    lenderOptionList: [],
    lenderSourceList: [],
    lenderInfo: undefined,
    selectedLender: undefined,
    editingLenderRecord: false
}

export function lendersReducer(state: LendersState, action: Record<string, any>): LendersState {
    switch (action.type) {
        case actions.SET_LENDER_OPTION_LIST:
            return { ...state, lenderOptionList: action.payload };
        case actions.SET_LENDER_SOURCES:
            return { ...state, lenderSourceList: action.payload };
        case actions.SET_SELECTED_LENDER:
            const selectedLender = state.lenderOptionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedLender: selectedLender };
        case actions.REPLACE_LENDER_OPTION_WITH_LENDER_RECORD:
            return { ...state, lenderOptionList: findAndReplace([...state.lenderOptionList], ["id", "isRecord"], [action.payload.oldLenderId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_LENDER_OPTIONS:
            return { ...state, lenderOptionList: [...state.lenderOptionList, action.payload] };
        case actions.SET_EDITING_LENDER_RECORD:
            return { ...state, editingLenderRecord: action.payload };
        case actions.SET_LENDER_INFO:
            return { ...state, lenderInfo: action.payload };
        case actions.SET_LENDER_LAWYER:
            return {
                ...state,
                lenderInfo: state.lenderInfo ? {
                    ...state.lenderInfo,
                    lawyer: action.payload
                } : undefined
            }
        case actions.SET_LENDER_LAWYER_FIRM:
            return {
                ...state,
                lenderInfo: state.lenderInfo ? {
                    ...state.lenderInfo,
                    lawyer: state.lenderInfo?.lawyer ? { ...state.lenderInfo.lawyer, legal_firm_record: action.payload } : undefined
                } : undefined
            }
        case actions.SET_LENDER_LAWYER_OFFICE:
            return {
                ...state,
                lenderInfo: state.lenderInfo ? {
                    ...state.lenderInfo,
                    lawyer: state.lenderInfo?.lawyer ? { ...state.lenderInfo.lawyer, legal_firm_office_record: action.payload } : undefined
                } : undefined
            }
        default:
            return state;
    }
}