import { PaymentGroup } from "conveyance/libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import { PaymentRecipientType } from "../../enums/paymentGroup";

export const defaultPaymentGroup: PaymentGroup = {
    id: -1,
    recipient_type: PaymentRecipientType.ThirdPartyTransfer,
    third_party_transfer: undefined,
    payment_method: undefined,
    wiring_fee: undefined,

    client: undefined,
    lawyer: undefined,
    lender: undefined,
    government: undefined,
    company: undefined,
    mortgage_brokerage: undefined,
    other_charge_holder: undefined,
    trust_ledgers: []
}