import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getLenderContactRecord,
    getLenderContactRecords,
    getLenderContacts,
    newLenderContactRecord,
    saveLenderContactRecord,
    addLenderContactToMortgageTransaction,
    removeLenderContactFromMortgageTransaction,
    addLenderContactToLien,
    removeLenderContactFromLien
} from "../../../libs/service/axios/api";
import { LenderContact, LenderContactListItem } from '../../../libs/types/UniversalSurvey/LenderContact/lenderContact';
import { sanitizeLenderContactResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeLenderContactRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { Lender } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { sortByKey } from "../../../libs/utils/arrays";
import { Loading } from "../../../libs/resources/enums/loading";


function saveLenderContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, lenderContactInfo: LenderContact) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLenderContact, isLoading: true } });
    saveLenderContactRecord(dealId, String(lenderRecordId), String(lenderContactInfo.id), sanitizeLenderContactRequest(lenderContactInfo))
        .then(function () {
            const newLendercontact: LenderContactListItem = {
                id: lenderContactInfo.id,
                label: lenderContactInfo.name,
                isRecord: true,
                role: lenderContactInfo.role
            }
            dispatch({
                type: actions.REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD,
                payload: { oldContactId: newLendercontact.id, newOption: newLendercontact, oldIsRecord: true }
            });
            dispatch({
                type: actions.REPLACE_LENDER_CONTACT_WITH_UPDATED_RECORD,
                payload: { oldContactId: newLendercontact.id, newOption: newLendercontact, oldIsRecord: true }
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save lender contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLenderContact, isLoading: false } });
        })
}

function addLenderContactToList(dispatch: Dispatch<Record<string, any>>, lenderContact: LenderContactListItem, removeEmpty?: boolean) {
    dispatch({ type: actions.ADD_LENDER_CONTACT_TO_LIST, payload: lenderContact });
    if(removeEmpty) {
        dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: false });
    }
}

function addLenderContactToMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, lenderRecordId: number, lenderContact: LenderContactListItem, removeEmpty?: boolean) {
    if(lenderContact.isRecord) {
        addLenderContactToMortgageTransaction(dealId, String(mortgageId), String(lenderContact.id))
        .then(function() {
            addLenderContactToList(dispatch, lenderContact, removeEmpty);
        })
        .catch(function(error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding lender contact to mortgage: ${error}`, type: AlertTypes.Error } });
        })
    } else {
        createLenderContactFromDBForMortgage(dispatch, dealId, mortgageId, lenderRecordId, lenderContact);
    }
}

function addLenderContactToExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, lenderRecordId: number, lenderContact: LenderContactListItem, removeEmpty?: boolean) {
    if(lenderContact.isRecord) {
        addLenderContactToLien(dealId, String(lienId), String(lenderContact.id))
        .then(function() {
            addLenderContactToList(dispatch, lenderContact, removeEmpty);
        })
        .catch(function(error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding lender contact to encumbrance: ${error}`, type: AlertTypes.Error } });
        })
    } else {
        createLenderContactFromDBForLien(dispatch, dealId, lienId, lenderRecordId, lenderContact);
    }
}

function changeLenderContactInMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, lenderRecordId: number, oldContactId: number, newLenderContact: LenderContactListItem) {
    addLenderContactToMortgage(dispatch, dealId, mortgageId, lenderRecordId, newLenderContact);
    removeLenderContactFromMortgage(dispatch, dealId, mortgageId, oldContactId);
}

function changeLenderContactInExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, lenderRecordId: number, oldContactId: number, newLenderContact: LenderContactListItem) {
    addLenderContactToExistingLien(dispatch, dealId, lienId, lenderRecordId, newLenderContact);
    removeLenderContactFromExistingLien(dispatch, dealId, lienId, oldContactId);
}

function removeLenderContactFromMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, oldContactId: number) {
    removeLenderContactFromMortgageTransaction(dealId, String(mortgageId), String(oldContactId))
    .then(function () {
        dispatch({ type: actions.REMOVE_LENDER_CONTACT_FROM_LIST, payload: oldContactId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing lender contact from mortgage: ${error}`, type: AlertTypes.Error } });
    })
}

function removeLenderContactFromExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, oldContactId: number) {
    removeLenderContactFromLien(dealId, String(lienId), String(oldContactId))
    .then(function () {
        dispatch({ type: actions.REMOVE_LENDER_CONTACT_FROM_LIST, payload: oldContactId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing lender contact from encumbrance: ${error}`, type: AlertTypes.Error } });
    })
}

function createLenderContactFromDBForMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, lenderRecordId: number, lenderContact: LenderContactListItem, removeEmpty?: boolean) {
    newLenderContactRecord(dealId, String(lenderRecordId), lenderContact.id!)
        .then(function (response: any) {
            addLenderContactToMortgageTransaction(dealId, String(mortgageId), String(response.data.id))
            .then( function () {
                const newLenderContactListItem: LenderContactListItem = { id: response.data.id, isRecord: true, label: lenderContact.label };
                addLenderContactToList(dispatch, newLenderContactListItem, removeEmpty);
                dispatch({ type: actions.REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD, payload: { oldContactId: lenderContact.id, newOption: newLenderContactListItem, oldIsRecord: false } });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding lender contact to mortgage: ${error}`, type: AlertTypes.Error } });
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender contact record from lender contact: ${error}`, type: AlertTypes.Error } });
        })
}

function createLenderContactFromDBForLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, lenderRecordId: number, lenderContact: LenderContactListItem, removeEmpty?: boolean) {
    newLenderContactRecord(dealId, String(lenderRecordId), lenderContact.id!)
        .then(function (response: any) {
            addLenderContactToLien(dealId, String(lienId), String(response.data.id))
            .then( function () {
                const newLenderContactListItem: LenderContactListItem = { id: response.data.id, isRecord: true, label: lenderContact.label };
                addLenderContactToList(dispatch, newLenderContactListItem, removeEmpty);
                dispatch({ type: actions.REPLACE_LENDER_CONTACT_OPTION_WITH_RECORD, payload: { oldContactId: lenderContact.id, newOption: newLenderContactListItem, oldIsRecord: false } });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding lender contact to encumbrance: ${error}`, type: AlertTypes.Error } });
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender contact record from lender contact: ${error}`, type: AlertTypes.Error } });
        })
}

function changeOrAddLenderContactToMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, lenderRecordId: number, newContactId: number, lenderContactBeingReplaced: LenderContactListItem | undefined, lenderContactInfo: LenderContact) {
    if (lenderContactBeingReplaced) {
        changeLenderContactInMortgage(dispatch, dealId, mortgageId, lenderRecordId, lenderContactBeingReplaced.id!, { id: newContactId, label: lenderContactInfo.name, isRecord: true });
    } else {
        addLenderContactToMortgage(dispatch, dealId, mortgageId, lenderRecordId, { id: newContactId, label: lenderContactInfo.name, isRecord: true }, true);
    }
}

function changeOrAddLenderContactToExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, lenderRecordId: number, newContactId: number, lenderContactBeingReplaced: LenderContactListItem | undefined, lenderContactInfo: LenderContact) {
    if (lenderContactBeingReplaced) {
        changeLenderContactInExistingLien(dispatch, dealId, lienId, lenderRecordId, lenderContactBeingReplaced.id!, { id: newContactId, label: lenderContactInfo.name, isRecord: true });
    } else {
        addLenderContactToExistingLien(dispatch, dealId, lienId, lenderRecordId, { id: newContactId, label: lenderContactInfo.name, isRecord: true }, true);
    }
}

function submitNewLenderContactRecord(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    entityId: number,
    lenderRecordId: number,
    lenderContactBeingReplaced: LenderContactListItem | undefined,
    lenderContactInfo: LenderContact,
    changeOrAddContact: (dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, lenderRecordId: number, newContactId: number, lenderContactBeingReplaced: LenderContactListItem | undefined, lenderContactInfo: LenderContact) => void
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLenderContact, isLoading: true } });
    newLenderContactRecord(dealId, String(lenderRecordId), null)
        .then(function (response: any) {
            const lenderContactRecordId = response.data.id;
            saveLenderContactRecord(dealId, String(lenderRecordId), String(lenderContactRecordId), sanitizeLenderContactRequest(lenderContactInfo))
                .then(function () {
                    const newContactItem: LenderContactListItem = {
                        id: lenderContactRecordId,
                        label: lenderContactInfo.name,
                        isRecord: true
                    };
                    dispatch({ type: actions.ADD_LENDER_CONTACT_OPTION, payload: newContactItem });
                    changeOrAddContact(dispatch, dealId, entityId, lenderRecordId, lenderContactRecordId, lenderContactBeingReplaced, lenderContactInfo);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save lender contact record: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLenderContact, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLenderContact, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender contact record: ${error}`, type: AlertTypes.Error } });
        })
}

function createLenderContactOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderInfo: Lender) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactList, isLoading: true } });
    getLenderContactRecords(dealId, String(lenderInfo.id))
        .then(function (response: any) {
            const contactOptionList: LenderContactListItem[] = [];
            const contactIsRecordList: number[] = [];
            for (let lenderContactRecord of response.data) {
                lenderContactRecord = sanitizeLenderContactResponse(lenderContactRecord);
                const lenderContactListItem: LenderContactListItem = {
                    label: lenderContactRecord.name,
                    id: lenderContactRecord.id,
                    isRecord: true,
                    role: lenderContactRecord.role
                };
                contactOptionList.push(lenderContactListItem);
                if(lenderContactRecord.lender_contact?.id) {
                    contactIsRecordList.push(lenderContactRecord.lender_contact?.id)
                }
            }

            if(lenderInfo.lender?.id) {
                getLenderContacts(String(lenderInfo.lender.id))
                    .then(function (response: any) {
                        for (const lenderContact of response.data.lender_contacts) {
                            if (!contactIsRecordList.includes(lenderContact.id)) {
                                contactOptionList.push({
                                    label: lenderContact.name,
                                    id: lenderContact.id,
                                    isRecord: false
                                });
                            }
                        }
                        sortAndSetContactOptionList(dispatch, contactOptionList);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender contacts: ${error}`, type: AlertTypes.Error } });
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactList, isLoading: false } });
                    })
            } else {
                sortAndSetContactOptionList(dispatch, contactOptionList);
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactList, isLoading: false } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender contact records: ${error}`, type: AlertTypes.Error } });
        })
}

function sortAndSetContactOptionList(dispatch: Dispatch<Record<string, any>>, contactOptionList: LenderContactListItem[]) {
    sortByKey(contactOptionList, "label");
    dispatch({ type: actions.SET_LENDER_CONTACTS_OPTION_LIST, payload: contactOptionList });
}

function setLenderContactRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderId: number, contactRecordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactModal, isLoading: true } });
    getLenderContactRecord(dealId, String(lenderId), String(contactRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_LENDER_CONTACT_BEING_EDITED, payload: sanitizeLenderContactResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LenderContactModal, isLoading: false } }))
}

function setLenderContactRole(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, contactRecordId: number, index: number, role: string | undefined) {
    getLenderContactRecord(dealId, String(lenderRecordId), String(contactRecordId))
    .then(function (response: any) {
        const contactInfo: LenderContact = sanitizeLenderContactResponse(response.data);
        contactInfo.role = role;
        saveLenderContactRecord(dealId, String(lenderRecordId), String(contactRecordId), sanitizeLenderContactRequest(contactInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_LENDER_CONTACT_ROLE, payload: {index: index, value: role }});
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save lender contact record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lender contact record: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    saveLenderContact,
    addLenderContactToMortgage,
    addLenderContactToExistingLien,
    changeLenderContactInMortgage,
    changeLenderContactInExistingLien,
    removeLenderContactFromMortgage,
    removeLenderContactFromExistingLien,
    submitNewLenderContactRecord,
    createLenderContactOptionsList,
    setLenderContactRecordBeingEdited,
    changeOrAddLenderContactToMortgage,
    changeOrAddLenderContactToExistingLien,
    setLenderContactRole
}