import * as actions from "../actionTypes"

import { RegionListItem } from "../../../libs/types/DealList/deals";
import { AdminActions } from "../context";

export type AdminRegionState = {
    regions: RegionListItem[]
}

export const defaultAdminRegionState: AdminRegionState = {
    regions: []
}

export function regionReducer(state: AdminRegionState, action: AdminActions): AdminRegionState {
    switch (action.type) {
        case actions.AdminActionTypes.SET_REGIONS:
            return { ...state, regions: action.payload };
        default:
            return state;
    }
}