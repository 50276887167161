import { useContext } from "react";

// Importing MUI components
import { Box, styled, Grid } from '@mui/material';

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing from DCP UI Library
import { H1, Large } from '../../../../components/Common/Typography/index';
import KeyInfoArea from './HeaderSections/KeyInformation';
import TransactionArea from './HeaderSections/TransactionArea';
import { addressToStringHeader } from "../../../libs/utils/address";
import { getClientsString } from "../../../libs/utils/clients";
import { PILL_CONTAINER } from "../../../../components/Common/componentStyling/Styles";


export default function USHeader() {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    function formatTitle(): string { 
        let resulStr = "New Deal";
        let addressString = addressToStringHeader(state.deal.dealInfo?.subject_property_address);
        let clientString = getClientsString(state.deal.dealInfo?.clients);
        if (!clientString) clientString = "";

        if (addressString.length > 0 && clientString.length > 0) {
            return `${clientString} - ${addressString}`
        }
        if (clientString.length > 0 && addressString.length === 0) {
            return clientString;
        }
        if (addressString.length > 0 && clientString.length === 0) {
            return addressString;
        }
        return resulStr;
    }

    return (
        <HeaderBase>
            <HeaderGrid container spacing={1}>
                <Grid item>
                    <H1 data-testid="deal-header-title-full-name-address">
                        {formatTitle()}
                    </H1>
                </Grid>
                {state.deal.dealInfo?.file_num && (
                    <Grid item>
                        <LargeWrapper>
                            <Large data-testid="deal-number-header-title">
                                {`#${state.deal.dealInfo?.file_num}`}
                            </Large>
                        </LargeWrapper>
                    </Grid>
                )}
            </HeaderGrid>
            <TransactionArea />
            {state.deal.dealInfo && <KeyInfoArea />}
        </HeaderBase>
    )
}

const HeaderBase = styled(Box)({
    height: "100%",
});

const LargeWrapper = styled('div')({
    marginTop: "-3.2rem"
})

const HeaderGrid = styled(Grid)({
    ...PILL_CONTAINER,
    //@ts-ignore
    alignItems: "flex-end"
});