import * as actions from "../actionTypes";
import { GovernmentContact, GovernmentContactListItem } from "../../../libs/types/UniversalSurvey/GovernmentContacts/governmentContact";
import { findAndRemove, findAndReplace, sortByKey } from "../../../libs/utils/arrays";

export type GovernmentContactsState = {
    contactsList: GovernmentContactListItem[];
    contactOptionList: GovernmentContactListItem[];
    emptyContactAdded: boolean;
    contactBeingEdited: GovernmentContact | undefined;
    contactBeingReplaced: GovernmentContactListItem | undefined;
}

export const defaultGovernmentContactsState: GovernmentContactsState = {
    contactsList: [],
    contactOptionList: [],
    emptyContactAdded: false,
    contactBeingEdited: undefined,
    contactBeingReplaced: undefined
}

export function governmentContactsReducer(state: GovernmentContactsState, action: Record<string, any>): GovernmentContactsState {
    switch (action.type) {
        case actions.SET_GOVERNMENT_CONTACTS_OPTION_LIST:
            return { ...state, contactOptionList: action.payload };
        case actions.ADD_GOVERNMENT_CONTACT_TO_LIST:
            return { ...state, contactsList: [...state.contactsList, action.payload] };
        case actions.REMOVE_GOVERNMENT_CONTACT_FROM_LIST:
            const tempContactsInList = findAndRemove([...state.contactsList], ["id", "isRecord"], [action.payload, true]);
            return { ...state, contactsList: tempContactsInList, emptyContactAdded: tempContactsInList.length === 0 ? false : state.emptyContactAdded };
        case actions.REPLACE_GOVERNMENT_CONTACT_OPTION_WITH_RECORD:
            return { ...state, contactOptionList: findAndReplace([...state.contactOptionList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.REPLACE_GOVERNMENT_CONTACT_WITH_UPDATED_RECORD:
            return { ...state, contactsList: findAndReplace([...state.contactsList], ["id", "isRecord"], [action.payload.oldContactId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_GOVERNMENT_CONTACTS_LIST:
            return { ...state, contactsList: action.payload };
        case actions.SET_GOVERNMENT_CONTACT_BEING_EDITED:
            return { ...state, contactBeingEdited: action.payload };
        case actions.SET_GOVERNMENT_CONTACT_BEING_REPLACED:
            return { ...state, contactBeingReplaced: action.payload };
        case actions.ADD_GOVERNMENT_CONTACT_OPTION:
            const optionsCopy = [...state.contactOptionList];
            optionsCopy.push(action.payload);
            sortByKey(optionsCopy, "label");
            return { ...state, contactOptionList: optionsCopy };
        case actions.SET_EMPTY_GOVERNMENT_CONTACT_ADDED:
            return { ...state, emptyContactAdded: action.payload };
        case actions.UPDATE_GOVERNMENT_CONTACT_ROLE:
            const tempContactsRoles = [...state.contactsList];
            const tempContact = { ...tempContactsRoles[action.payload.index] };
            tempContact.role = action.payload.value;
            tempContactsRoles[action.payload.index] = tempContact;

            return { ...state, contactsList: tempContactsRoles, contactOptionList: findAndReplace([...state.contactOptionList], ["id", "isRecord"], [tempContact.id, true], tempContact) };
        default:
            return state;
    }
}