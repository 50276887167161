import * as actions from "../actionTypes"

import { PaymentGroup } from "../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";

export type TrustLedgerPaymentGroupsState = {
    paymentGroups: PaymentGroup[];
}

export const defaultTrustLedgerPaymentGroupsState: TrustLedgerPaymentGroupsState = {
    paymentGroups: []
}

export function trustLedgerPaymentGroupsReducer(state: TrustLedgerPaymentGroupsState, action: Record<string, any>): TrustLedgerPaymentGroupsState {
    switch (action.type) {
        case actions.SET_PAYMENT_GROUPS:
            return { ...state, paymentGroups: action.payload };
        default:
            return state;
    }
}