import * as React from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';

interface MoneyFormatProps {
    onChange: (event: { target: { name: number; value: number } }) => void;
    name: number;
    dollarPrefix?: boolean;
    allowNegative?: boolean;
}

const MoneyFormat = React.forwardRef<NumberFormat<InputAttributes>, MoneyFormatProps>(function NumberFormatCustom(props, ref) {
    const { onChange, dollarPrefix, name, allowNegative, ...other } = props;

    return (
        // @ts-ignore
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        // @ts-ignore
                        value: values.value === "" ? undefined : Number(values.value)
                    },
                });
            }}
            prefix={dollarPrefix ? "$" : ""}
            thousandSeparator
            allowNegative={allowNegative !== undefined ? allowNegative : true}
            fixedDecimalScale
            decimalScale={2}
            isNumericString
        />
    );
});

function FormatNumeric(props: { value: number }) {
    return (
        // @ts-ignore
        <NumberFormat 
            value={props.value} 
            decimalScale={2} 
            fixedDecimalScale 
            prefix='$' 
            displayType='text' 
            thousandSeparator 
        />
    );
}

export { MoneyFormat, FormatNumeric };