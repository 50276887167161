import * as actions from "../actionTypes"

import { MortgageBrokerageListItem, MortgageBrokerage } from "../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { findAndReplace } from "../../../libs/utils/arrays";

export type MortgageBrokerageState = {    
    brokerageOptionList: MortgageBrokerageListItem[];
    brokerageInfo: MortgageBrokerage | undefined;
    selectedBrokerage: MortgageBrokerageListItem | undefined;
    editingMortgageBrokerageRecord: boolean;
}

export const defaultMortgageBrokerageState: MortgageBrokerageState = {
    brokerageOptionList: [],
    brokerageInfo: undefined,
    selectedBrokerage: undefined,
    editingMortgageBrokerageRecord: false
}

export function mortgageBrokerageReducer(state: MortgageBrokerageState, action: Record<string, any>): MortgageBrokerageState {
    switch (action.type) {
        case actions.SET_MORTGAGE_BROKERAGES_OPTION_LIST:
            return { ...state, brokerageOptionList: action.payload };
        case actions.SET_SELECTED_MORTGAGE_BROKERAGE:
            const selectedBrokerage = state.brokerageOptionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedBrokerage: selectedBrokerage };
        case actions.REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD:
            return { ...state, brokerageOptionList: findAndReplace([...state.brokerageOptionList], ["id", "isRecord"], [action.payload.oldBrokerageId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_MORTGAGE_BROKERAGE_OPTIONS:
            return { ...state, brokerageOptionList: [...state.brokerageOptionList, action.payload] };
        case actions.SET_EDITING_MORTGAGE_BROKERAGE_RECORD:
            return { ...state, editingMortgageBrokerageRecord: action.payload };
        case actions.SET_MORTGAGE_BROKERAGE_INFO:
            return { ...state, brokerageInfo: action.payload };
        default:
            return state;
    }
}