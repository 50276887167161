import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DraTwoPartiesContextRequest } from "../documents/draTwoPartiesDocument";

function createDraTwoPartiesName(): string {
    return "Document Registration Agreement(2 parties)";
}

function isDraTwoPartiesDocInDeal(docList: Document[]): boolean {
    const matchingDoc = docList.find((doc) => doc.type === DocumentType.DraTwoParties);
    return matchingDoc ? true : false
}

function createDraTwoPartiesContextRequest(): DraTwoPartiesContextRequest {
    return {};
}

function getDraTwoPartiesDocData(): [Object, string] {
    return [createDraTwoPartiesContextRequest(), createDraTwoPartiesName()];
}

export {
    isDraTwoPartiesDocInDeal,
    getDraTwoPartiesDocData
}