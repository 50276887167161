export enum ProvinceDB {
    Alberta = "alberta",
    BritishColumbia = "british_columbia",
    Manitoba = "manitoba",
    NewBrunswick = "new_brunswick",
    NewfoundlandAndLabrador = "newfoundland_and_labrador",
    NorthwestTerritories = "northwest_territories",
    NovaScotia = "nova_scotia",
    Nunavut = "nunavut",
    Ontario = "ontario",
    PrinceEdwardIslad = "prince_edward_island",
    Quebec = "quebec",
    Saskatchewan = "saskatchewan",
    Yukon = "yukon"
}

export enum SupportedProvinces {
    Ontario = "ON",
    Alberta = "AB",
    BritishColumbia = "BC"
}