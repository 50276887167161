import { Dispatch } from "react";

import * as actions from "../../actionTypes"
import {
    newMortgageTransaction,
    deleteMortgageTransaction
} from '../../../../libs/service/axios/api';
import { Transaction } from "../../../../libs/types/UniversalSurvey/Transaction/transaction";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";
import { TransactionMappingPills } from '../../../../libs/resources/mappings/transactions';
import { Sections } from "../../../../libs/resources/enums/sections";
import { TabOptions } from "../../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { changeSelectedNewMortgage, handleAddingMortgageOnMortgageSection } from "../newMortgages";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { navigateURL, updateDealTransactionData } from "../deal";

function addMortgageTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, currSection: Sections | undefined, numTransactions: number, setPrimary?: boolean) {
    newMortgageTransaction(dealId)
        .then(function (response: any) {
            if (currSection === Sections.NewMortgage) {
                handleAddingMortgageOnMortgageSection(dispatch, dealId, response.data.id);
            } else {
                dispatch({ type: actions.CREATE_MORTGAGE_TRANSACTION, payload: response.data.id });
            }

            if (numTransactions === 0 || setPrimary) {
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_id", value: response.data.id } });
                dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_type", value: TransactionTypes.Mortgage } });
            }
            if (setPrimary) {
                updateDealTransactionData(dispatch, dealId, response.data.id, TransactionTypes.Mortgage);
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New mortgage transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function removeMortgageTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number, currSection: Sections | undefined, currEntity: number, tabOptions: TabOptions) {
    deleteMortgageTransaction(dealId, String(transactionId))
        .then(function () {
            handleTabChangeOnMortgageTransactionRemoval(dispatch, dealId, transactionId, currSection, currEntity, tabOptions);
            dispatch({ type: actions.REMOVE_TRANSACTION, payload: { id: transactionId, transaction_type: TransactionTypes.Mortgage } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete mortgage transaction: ${error}`, type: AlertTypes.Error } });
        })
}

function handleTabChangeOnMortgageTransactionRemoval(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number, currSection: Sections | undefined, currEntity: number, tabOptions: TabOptions) {
    if (currSection === Sections.NewMortgage) {
        if (currEntity === transactionId) {
            if (tabOptions.length === 1) {
                dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: undefined });
            } else {
                changeSelectedNewMortgage(dispatch, dealId, tabOptions[0].itemId === transactionId ? tabOptions[1].itemId : tabOptions[0].itemId);
            }
        }
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: transactionId });
    }
}

function sortAndLabelMortgageTransactions(mortgages: Transaction[]) {
    const mortgagePrioritySet = new Set();
    for (const mortgage of mortgages) {
        mortgagePrioritySet.add(mortgage.priority);
    }
    const mortgageIdList = Array.from(mortgagePrioritySet).sort();

    for (const mortgage of mortgages) {
        mortgage.label = TransactionMappingPills[mortgage.transaction_type as keyof typeof TransactionMappingPills]
        if (mortgages.length > 1) {
            mortgage.label += ` ${mortgageIdList.indexOf(mortgage.priority) + 1}`
        }
        mortgage.label += mortgage.lenderAbbrName ? ` - ${mortgage.lenderAbbrName}` : mortgage.lenderName ? ` - ${mortgage.lenderName}` : "";
    }

    mortgages.sort((a, b) => {
        if (a.priority && b.priority) {
            return a.priority > b.priority ? 1 : -1
        }
        return -1
    })
}

function deleteDealMortgageTransaction(dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, tabOptions: TabOptions, isActive: boolean) {
    deleteMortgageTransaction(dealId, String(entityId))
    .then(function (response: any) {
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: entityId });
        dispatch({ type: actions.REMOVE_TRANSACTION, payload: { id: entityId, transaction_type: TransactionTypes.Mortgage } });
        if (isActive) {
            if (tabOptions.length > 1) {
                let newOption = tabOptions.find((value) => value.itemId !== entityId);
                if (newOption) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.NewMortgage, String(newOption.itemId));
                } else {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.NewMortgage, undefined);
                    dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: undefined });
                }
            } else {
                navigateURL(dispatch, dealId, "data-sheet", Sections.NewMortgage, undefined);
                dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: undefined });
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete new mortgage: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

export {
    addMortgageTransaction,
    removeMortgageTransaction,
    handleTabChangeOnMortgageTransactionRemoval,
    sortAndLabelMortgageTransactions,
    deleteDealMortgageTransaction
}