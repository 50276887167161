// Importing built-in React/Router tools
import { useState, useContext, useEffect } from 'react';

// Importing MUI components
import { Stack, styled, Grid, Collapse, IconButton } from '@mui/material';

// Importing state management
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

// Importing from DCP UI Library
import { Body } from '../../../../../components/Common/Typography/index';
import { displayListKey, getStrOutput } from '../../../../libs/utils/pluralizationFunctions';
import { ChevronDown, ChevronUp } from '../../../../../components/Common/Icons/Iconography';
import theme from '../../../../../components/Common/componentStyling/Theme';
import { DateObjectToDateFormat, convertDateStringToDateObject } from '../../../../libs/utils/dateHelper';
import { formatActingAs, formatDealClientCapacity, formatDealStatus } from '../../../../libs/utils/formatValues';
import { DashboardUser } from '../../../../libs/types/UniversalSurvey/DashboardUser/dashboardUser';
import { labelDashboardUser } from '../../../../libs/utils/labeling/dashboardUser';

export default function KeyInfoArea() {
    const [state] = useContext(UniversalSurveyContext);
    
    const [showMore, setShowMore] = useState(false);
    const [signingDates, setSigningDates] = useState<string[]>([]);

    useEffect(() => {
        const dates = [];
        for (const appt of state.signingAppointments.signingApptsInDeal) {
            if (appt.signing_date) {
                if (typeof appt.signing_date === "string") {
                    appt.signing_date = convertDateStringToDateObject(appt.signing_date, true);
                }
                dates.push(DateObjectToDateFormat(appt.signing_date, true, true));
            }
        }
        setSigningDates(dates);
    }, [state.signingAppointments.signingApptsInDeal])

    const keyInfo = state.deal.dealInfo;

    function DisplayList(props: {list: string[]}) {
        if (props.list.length > 1) {
            if (showMore) {
                return (
                    <>
                        {props.list.map((item) => (
                            <Body>{item}</Body>
                        ))}
                    </>
                );
            } else {
                return (<Body>{getStrOutput(props.list)}</Body>);
            }
        } else {
            return (<Body>{props.list[0]}</Body>)
        }
    }

    function onShowMore() {
        setShowMore(!showMore);
    }

    function getFormattedName(user: DashboardUser | string) {
        if (typeof user === "string") {
            return user;
        }
        return labelDashboardUser(user);
    }

    return (
        <KeyInfoBase>
            <LeftColumn>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <Body>
                            Owner clerk:
                        </Body>
                    </Grid>
                    <Grid item>
                        {(keyInfo?.owner_clerk || keyInfo?.owner_clerk_name) ? (
                            <Body>{getFormattedName(keyInfo.owner_clerk ?? keyInfo.owner_clerk_name!)}</Body>
                        ) : (
                            <PlaceholderBody>Not set</PlaceholderBody>
                        )}
                    </Grid>
                </ItemContainer>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <Body>
                            Signing agent:
                        </Body>
                    </Grid>
                    <Grid item>
                        {keyInfo?.signer_record?.name ? (
                            <Body>{keyInfo.signer_record.name}</Body>
                        ) : (
                            <PlaceholderBody>Not set</PlaceholderBody>
                        )}
                    </Grid>
                </ItemContainer>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <Body>
                            Solicitor:
                        </Body>
                    </Grid>
                    <Grid item>
                        {keyInfo?.lawyer_record?.name ? (
                            <Body>{keyInfo.lawyer_record.name}</Body>
                        ) : (
                            <PlaceholderBody>Not set</PlaceholderBody>
                        )}
                    </Grid>
                </ItemContainer>
                <Collapse in={showMore} unmountOnExit>
                    <>
                        {/* <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    {displayListKey(keyInfo?.contributing_clerks, "Contributing clerk")}
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.contributing_clerks ? (
                                    <DisplayList list={keyInfo.contributing_clerks} />
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer> */}
                        <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    File opened:
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.file_opened? (
                                    <Body>{keyInfo.file_opened}</Body>
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer>
                        <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    Last updated:
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.last_update ? (
                                    <Body>{`${DateObjectToDateFormat(keyInfo.last_update, false)}${keyInfo?.last_update_by?.first_name ? ` by ${keyInfo.last_update_by.first_name}${keyInfo?.last_update_by.last_name ? ` ${keyInfo.last_update_by.last_name}` : ""}` : ""}`}</Body>
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer>
                    </>
                </Collapse>
            </LeftColumn>
            <RightColumn>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <DateBody>
                            Deal Status:
                        </DateBody>
                    </Grid>
                    <Grid item>
                        <DateBody data-testid="deal-status-value">{formatDealStatus(keyInfo?.status)}</DateBody>
                    </Grid>
                    <Grid item xs={true} style={{marginTop: "-20px"}}>
                        <Stack direction={"row"} justifyContent="flex-end">
                            <IconButton onClick={onShowMore}>
                                {showMore ? (
                                   <ChevronUp color={theme.INPUT} /> 
                                ) : (
                                    <ChevronDown color={theme.INPUT} />
                                )}
                            </IconButton>
                        </Stack>
                    </Grid>
                </ItemContainer>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <DateBody>
                            {displayListKey(signingDates, "Signing date")}
                        </DateBody>
                    </Grid>
                    <Grid item>
                        {signingDates.length > 0 ? (
                            signingDates.map((date, index) => (
                                <DateBody key={index}>{date}</DateBody>
                            ))
                        ) : (
                            <PlaceholderBody data-testid="signing-date-not-set-value">Not set</PlaceholderBody>
                        )}
                    </Grid>
                </ItemContainer>
                <ItemContainer container spacing={1}>
                    <Grid item>
                        <DateBody>
                            Closing date:
                        </DateBody>
                    </Grid>
                    <Grid item>
                        {keyInfo?.closing_date ? (
                            <DateBody data-testid="closing-date-value">{DateObjectToDateFormat(keyInfo.closing_date, false)}</DateBody>
                        ) : (
                            <PlaceholderBody>Not set</PlaceholderBody>
                        )}
                    </Grid>
                </ItemContainer>
                <Collapse in={showMore} unmountOnExit>
                    <>
                        {/* <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    Referrer:
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.referrer ? (
                                    <Body>{keyInfo.referrer}</Body>
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer> */}
                        <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    Client capacity:
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.client_capacity ? (
                                    <Body>{formatDealClientCapacity(keyInfo.client_capacity)}</Body>
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer>
                        <ItemContainer container spacing={1}>
                            <Grid item>
                                <Body>
                                    Deeded is acting for:
                                </Body>
                            </Grid>
                            <Grid item>
                                {keyInfo?.acting_as ? (
                                    <Body>{formatActingAs(keyInfo.acting_as)}</Body>
                                ) : (
                                    <PlaceholderBody>Not set</PlaceholderBody>
                                )}
                            </Grid>
                        </ItemContainer>
                    </>
                </Collapse>
            </RightColumn>
        </KeyInfoBase>
    )
}

const KeyInfoBase = styled('div')({
    paddingTop: "2rem",
    height: "100%"
});

const LeftColumn = styled('div')({
    width: "50%",
    float: "left"
});

const RightColumn = styled('div')({
    width: "50%",
    float: "right"
});

const ItemContainer = styled(Grid)({
    paddingTop: "1rem",
});

const DateBody = styled(Body)({
    fontWeight: 700
});

const PlaceholderBody = styled(Body)({
    color: theme.INPUT
});