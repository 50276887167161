import { useState, useContext, useEffect } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import TransactionPill from "../TransactionPills/TransactionPill";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { Guarantor } from "../../../../libs/types/UniversalSurvey/Guarantor/guarantor";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";
import { createNewGuarantor } from "../../../../context/UniversalSurvey/asyncActions/guarantors";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {
    mortgageLender?: string;
};

export default function GuarantorModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [errorState, setErrorState] = useState<{ email?: boolean }>({ email: false });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    function submit() {
        const guarantor: Guarantor = {
            first_name: firstName,
            last_name: lastName,
            email: email
        }
        createNewGuarantor(dispatch, String(state.deal.dealInfo?.id), state.newMortgages.mortgageTransactionInfo?.id!, guarantor);
    }

    function handleDiscard() {
        resetState();
        setErrorState({ email: false });
        props.onClose ? props.onClose() : undefined;
    }

    function resetState() {
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
    }

    function isSaveDisabled(): boolean {
        if (!firstName || !lastName || !email || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, firstName, lastName, email]);
    
    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscard}
            onSubmit={submit}
            size="small"
            title="New Guarantor"
            saveDisabled={saveDisabled}
            closeAfterSaving={handleDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveGuarantor])}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TransactionPill
                        type={TransactionTypes.Mortgage}
                        label={props.mortgageLender ? props.mortgageLender : ""}
                        isPrimary={state.deal.dealInfo?.primary_transaction_id === state.newMortgages.mortgageTransactionInfo?.id && state.deal.dealInfo?.primary_transaction_type === TransactionTypes.Mortgage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        autoFocus
                        label={{text: "First Name", inputId: "guarantor_first_name", isRequired: true}}
                        placeholder="Guarantor's first name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{text: "Last Name", inputId: "guarantor_last_name", isRequired: true}}
                        placeholder="Guarantor's last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        label={{text: "Email", inputId: "guarantor_email", isRequired: true}}
                        placeholder="Guarantor's email"
                        value={email}
                        fieldType="email"
                        onChange={(e) => setEmail(e.target.value)}
                        error={{
                            showError: true,
                            errorKey: "email",
                            setErrorState: setErrorState,
                            errorState: errorState
                        }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}