import { createContext, Dispatch, useReducer } from "react";
import { AdminPayloadTypes } from "./actionTypes";
import { AdminAlertsState, defaultAdminAlertsState, alertsReducer } from "./reducers/alerts";
import { AdminNavigationState, defaultAdminNavigationState, navigationReducer } from "./reducers/navigation";
import { AdminLoadingState, defaultAdminLoadingState, loadingReducer } from "./reducers/loading";
import { AdminLenderState, defaultAdminLendersState, lendersReducer } from "./reducers/lenders";
import { AdminRegionState, defaultAdminRegionState, regionReducer } from "./reducers/regions";
import { AdminLenderContactsState, defaultAdminLenderContactsState, lenderContactsReducer } from "./reducers/lenderContacts";
import { AdminLegalFirmState, defaultAdminLegalFirmsState, legalFirmsReducer } from "./reducers/legalFirms";
import { AdminLegalFirmOfficesState, defaultAdminLegalFirmOfficesState, legalFirmOfficesReducer } from "./reducers/legalFirmOffices";

export type AdminState = {
    alerts: AdminAlertsState;
    navigation: AdminNavigationState;
    loading: AdminLoadingState;
    lenders: AdminLenderState;
    lenderContacts: AdminLenderContactsState;
    regions: AdminRegionState;
    legalFirms: AdminLegalFirmState;
    legalFirmOffices: AdminLegalFirmOfficesState;
}

export const defaultAdminState: AdminState = {
    alerts: defaultAdminAlertsState,
    navigation: defaultAdminNavigationState,
    loading: defaultAdminLoadingState,
    lenders: defaultAdminLendersState,
    lenderContacts: defaultAdminLenderContactsState,
    regions: defaultAdminRegionState,
    legalFirms: defaultAdminLegalFirmsState,
    legalFirmOffices: defaultAdminLegalFirmOfficesState
}

export const AdminContext = createContext<[AdminState, Dispatch<AdminActions>]>([defaultAdminState, () => {}]);

const reducer = (state: AdminState, action: AdminActions): AdminState => ({
    alerts: alertsReducer(state.alerts, action),
    navigation: navigationReducer(state.navigation, action),
    loading: loadingReducer(state.loading, action),
    lenders: lendersReducer(state.lenders, action),
    lenderContacts: lenderContactsReducer(state.lenderContacts, action),
    regions: regionReducer(state.regions, action),
    legalFirms: legalFirmsReducer(state.legalFirms, action),
    legalFirmOffices: legalFirmOfficesReducer(state.legalFirmOffices, action)
});

export default function AdminContextProvider(props: Record<string, any>) {
    const [state, dispatch] = useReducer(reducer, defaultAdminState);
    return <AdminContext.Provider value={[state, dispatch]}>{props.children}</AdminContext.Provider>;
}

type AdminActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined ?
    { type: Key; } :
    { type: Key; payload: M[Key]; }
}

export type AdminActions = AdminActionMap<AdminPayloadTypes>[keyof AdminActionMap<AdminPayloadTypes>];