import { Lender } from "../../../types/UniversalSurvey/Lender/lender";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultLender: Lender = {
    id: -1,
    name: undefined,
    abbr_name: undefined,
    type: undefined,
    institution_number: undefined,
    branch_number: undefined,
    all_regions: true,
    general_contact_name: undefined,
    general_address: defaultSimpleAddress,
    general_email: undefined,
    final_report_contact_name: undefined,
    final_report_address: defaultSimpleAddress,
    final_report_email: undefined,
    address_for_service: defaultSimpleAddress,
    address_for_registration: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    date_created: undefined,
    date_updated: undefined,

    lender: undefined,
    regions: [],
    lawyer: undefined
}