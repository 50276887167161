import { AdminActionTypes } from "../actionTypes"
import { AdminActions } from "../context";
import { SimpleGlobalLegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { GlobalLegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";


export type AdminLegalFirmOfficesState = {
    legalFirmOffices: SimpleGlobalLegalFirmOffice[];
    legalFirmOfficeBeingEdited: GlobalLegalFirmOffice | undefined;
    shouldRefresh: boolean;
    resultCount: number;
}

export const defaultAdminLegalFirmOfficesState: AdminLegalFirmOfficesState = {
    legalFirmOffices: [],
    legalFirmOfficeBeingEdited: undefined,
    shouldRefresh: false,
    resultCount: 0
}

export function legalFirmOfficesReducer(state: AdminLegalFirmOfficesState, action: AdminActions): AdminLegalFirmOfficesState {
    switch (action.type) {
        case AdminActionTypes.SET_LEGAL_FIRM_OFFICES:
            return { ...state, legalFirmOffices: action.payload };
        case AdminActionTypes.SET_LEGAL_FIRM_OFFICE_BEING_EDITED:
            return { ...state, legalFirmOfficeBeingEdited: action.payload };
        case AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH:
            return { ...state, shouldRefresh: action.payload };
        case AdminActionTypes.SET_LEGAL_FIRM_OFFICE_RESULT_COUNT:
            return { ...state, resultCount: action.payload };
        default:
            return state;
    }
}