import { MortgagePackageContext, MortgagePackageContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/mortgagePackage";
import { defaultSimpleMortgageTransaction } from "../../frontend/defaultSimpleMortgageTransaction";

export const defaultMortgagePackageContext: MortgagePackageContext = {
    mortgage_id: -1,

    mortgage_record: defaultSimpleMortgageTransaction
}

export const defaultMortgagePackageContextRequest: MortgagePackageContextRequest = {
    mortgage_id: -1
}