import { Condition } from "../../../types/UniversalSurvey/Condition/condition";

export const defaultCondition: Condition = {
    id: -1,
    source: undefined,
    type: undefined,
    condition: undefined,
    condition_met: false,
    holdback: undefined,
    date_created: undefined,
    date_updated: undefined,

    lien_id: undefined,
    debt_id: undefined,
    affidavit_id: undefined,
    stat_dec_id: undefined,
    undertaking_id: undefined,
    mortgage_id: undefined,

    undertaking: undefined,
    stat_dec: undefined,
    debt: undefined,
    affidavit: undefined,
    lien: undefined,
    mortgage: undefined
}