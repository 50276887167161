import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Lender, LenderErrorState, LenderListItem } from "../../../../../libs/types/UniversalSurvey/Lender/lender";
import { saveLender, submitNewLenderRecord } from "../../../../../context/UniversalSurvey/asyncActions/lenders";
import { defaultLender } from "../../../../../libs/resources/defaults/frontend/defaultLender";
import { LenderType } from "../../../../../libs/resources/enums/lenderInstitutions";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { labelLenderItem } from "../../../../../libs/utils/labeling/lenders";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultLenderErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLenderErrorState";
import { defaultFinancialInfoErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";
import LenderForm from "./LenderForm";

type Props = Omit<ModalProps, "children"> & {
    onSubmitSave: (dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, lenderInfo: Lender, updateSectionTabs?: boolean) => void;
    onSubmitNew: (dispatch: Dispatch<Record<string, any>>, lenderId: number, name: string | undefined, currEntity: number) => void;
};

export default function LenderModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [lenderObj, setLenderObj] = useState<Lender>({ ...defaultLender });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([allRegion]);
    const [errorState, setErrorState] = useState<LenderErrorState>({ ...defaultLenderErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.lenders.editingLenderRecord && state.lenders.lenderInfo) {
            setLenderObj(state.lenders.lenderInfo);
            let tempRegionList: RegionListItem[] = [];
            if (state.lenders.lenderInfo.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.lenders.lenderInfo.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
        } else {
            setLenderObj({ ...defaultLender });
            setSelectedRegionList([allRegion]);
        }
        setErrorState({ ...defaultLenderErrorState });
    }, [state.lenders.editingLenderRecord]);

    function updateLenderInfo<
        K extends keyof Lender,
        V extends Lender[K]
    >(key: K, value: V): void {
        const tempLenderObj = { ...lenderObj };
        tempLenderObj[key] = value;
        setLenderObj(tempLenderObj);
    }

    function submit() {
        const newLenderListItem: LenderListItem = labelLenderItem({ 
            id: lenderObj.id, 
            isRecord: true,
            branch_number: lenderObj.branch_number,
            institution_number: lenderObj.institution_number,
            name: lenderObj.name,
            abbr_name: lenderObj.abbr_name,
            type: lenderObj.type,
            general_address: lenderObj.general_address
        });
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.lenders.editingLenderRecord) {
            saveLender(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo!.id, lenderObj, regionList);
            props.onSubmitSave(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo!.id, lenderObj, true);
            dispatch({ type: actions.REPLACE_LENDER_OPTION_WITH_LENDER_RECORD, payload: { oldLenderId: lenderObj.id, newOption: newLenderListItem, oldIsRecord: true }});
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: newLenderListItem.id });
        } else {
            submitNewLenderRecord(dispatch, String(state.deal.dealInfo?.id), lenderObj, state.dataSheet.currEntity!, props.onSubmitNew, regionList);
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: newLenderListItem.id });
        }
    }

    function discard() {
        dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: false });
        setLenderObj({ ...defaultLender });
        setErrorState({ ...defaultLenderErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        setSelectedRegionList([allRegion]);
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled() {
        if (!lenderObj.name || !lenderObj.type || !(selectedRegionList.length > 0) || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, lenderObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={props.title}
            saveDisabled={saveDisabled}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLender])}
        >
            <LenderForm
                type={"deal"}
                lender={lenderObj}
                setLender={setLenderObj}
                updateLenderInfo={updateLenderInfo}
                regions={state.regions.regions}
                selectedRegionList={selectedRegionList}
                setSelectedRegionList={setSelectedRegionList}
                errorState={errorState}
                setErrorState={setErrorState}
                financialInfoErrorState={financialInfoErrorState}
                setFinancialInfoErrorState={setFinancialInfoErrorState}
            />
        </ModalBase>
    );
}