import { NewMortgageRequest } from "../../../types/UniversalSurvey/NewMortgage/newMortgageRequest";

export const defaultNewMortgageRequest: NewMortgageRequest = {
    type: null,
    collateral_mortgage: null,
    loan_number: null,
    date_of_commitment: null,
    acting_for_lender: null,
    lender_id: null,
    mortgage_brokerage_id: null,
    mortgage_broker_id: null,
    construction_lien_holdback: null,
    registration_number: null,
    registration_date: null,
    instruction_received: null,
    preliminary_report_submitted: null,
    final_report_submitted: null,
    high_ratio_insured_mortgage: null,
    high_ratio_insurance_premium: null,
    principal_amount: null,
    registered_amount: null,
    has_fixed_interest_rate: null,
    interest_rate: null,
    calculation_period: null,
    interest_only: null,
    variable_interest_rate: null,
    sct_number: null,
    insurance_amount: null,
    assignment_of_rents: null,
    assignment_of_rents_reg_num: null,
    assignment_of_rents_reg_date: null,
    general_security_agreement: null,
    taxes_paid_through_mortgage: null,
    regular_payments: null,
    alternate_frequency_payments: null,
    interest_adjustment_date: null,
    first_payment_date: null,
    maturity_date: null,
    payment_frequency: null,
    regular_payment: null,
    tax_portion: null,
    payment_due: null,
    act_for_mortgagee: null,
    term_years: null,
    amortization_period_years: null,
}