export enum Loading {
    // Fetching Section/Modal data
    Section = "Section",
    LegalProfessionalList = "LegalProfessionalList",
    AllFirmList = "AllFirmList",
    LawyerFirmList = "LawyerFirmList",
    LawyerFirmOfficeList = "LawyerFirmOfficeList",
    LawyerOfficeList = "LawyerOfficeList",
    SignerFirmList = "SignerFirmList",
    SignerOfficeList = "SignerOfficeList",
    LenderLawyerFirmList = "LenderLawyerFirmList",
    LenderLawyerOfficeList = "LenderLawyerOfficeList",
    SigningAppointments = "SigningAppointments",
    LegalProfessionalModal = "LegalProfessionalModal",
    LegalFirmModal = "LegalFirmModal",
    LegalFirmOfficeModal = "LegalFirmOfficeModal",
    CondoCorporationList = "CondoCorporationList",
    PropertyManagementCompanyList = "PropertyManagementCompanyList",
    CondoCorporationModal = "CondoCorporationModal",
    PropertyManagementCompanyModal = "PropertyManagementCompanyModal",
    LenderList = "LenderList",
    LenderSources = "LenderSources",
    ChargeHolderCompanyList = "ChargeHol[derCompanyList",
    GovernmentList = "GovernmentList",
    OtherChargeHolderList = "OtherChargeHolderList",
    LenderContactList = "LenderContactList",
    ChargeHolderCompanyContactList = "ChargeHolderCompanyContactList",
    GovernmentContactList = "GovernmentContactList",
    LenderContactModal = "LenderContactModal",
    ChargeHolderCompanyContactModal = "ChargeHolderCompanyContactModal",
    GovernmentContactModal = "GovernmentContactModal",
    MortgageBrokerageList = "MortgageBrokerageList",
    MortgageBrokerList = "MortgageBrokerList",
    MortgageBrokerageOtherContactList = "MortgageBrokerageOtherContactList",
    MortgageBrokerageOtherContactModal = "MortgageBrokerageOtherContactModal",
    FireInsuranceCompanyList = "FireInsuranceCompanyList",
    FireInsuranceBrokerageList = "FireInsuranceBrokerageList",
    FireInsuranceBrokerList = "FireInsuranceBrokerList",
    FeeList = "FeeList",
    DisbursementList = "DisbursementList",
    TrustLedgerListSection = "TrustLedgerListSection",
    TrustLedgerListModal = "TrustLedgerListModal",
    TrustLedgerPaymentGroupList = "TrustLedgerPaymentGroupList",
    DebtList = "DebtList",
    DebtModal = "DebtModal",
    ConditionList = "ConditionList",
    UndertakingList = "UndertakingList",
    LienList = "LienList",
    AffidavitList = "AffidavitList",
    StatDecList = "StatDecList",
    GlobalStatDecList = "GlobalStatDecList",
    UndertakingModal = "UndertakingModal",
    AffidavitModal = "AffidavitModal",
    StatDecModal = "StatDecModal",
    ConditionModal = "ConditionModal",
    ConditionLien = "ConditionLien",
    ConditionUndertaking = "ConditionUndertaking",
    ConditionDebt = "ConditionDebt",
    ConditionAffidavit = "ConditionAffidavit",
    ConditionStatDec = "ConditionStatDec",
    Documents = "Documents",
    Notes = "Notes",
    ClerkOptions = "ClerkOptions",

    // Saving
    SaveLender = "SaveLender",
    SaveLenderContact = "SaveLenderContact",
    SaveGovernment = "SaveGovernment",
    SaveGovernmentContact = "SaveGovernmentContact",
    SaveChargeHolderCompany = "SaveChargeHolderCompany",
    SaveChargeHolderCompanyContact = "SaveChargeHolderCompanyContact",
    SaveOtherChargeHolder = "SaveOtherChargeHolder",
    SaveFireInsuranceBrokerage = "SaveFireInsuranceBrokerage",
    SaveFireInsuranceBroker = "SaveFireInsuranceBroker",
    SaveFireInsuranceCompany = "SaveFireInsuranceCompany",
    SaveMortgageBrokerage = "SaveMortgageBrokerage",
    SaveMortgageBroker = "SaveMortgageBroker",
    SaveMortgageBrokerageOtherContact = "SaveMortgageBrokerageOtherContact",
    SaveLegalProfessional = "SaveLegalProfessional",
    SaveLegalFirm = "SaveLegalFirm",
    SaveLegalFirmOffice = "SaveLegalFirmOffice",
    SaveUploadedDoc = "SaveUploadedDoc",
    SaveCondoCorporation = "SaveCondoCorporation",
    SavePropertyManagementCompany = "SavePropertyManagementCompany",
    SaveGuarantor = "SaveGuarantor",
    SaveRepresentative = "SaveRepresentative",
    SaveClient = "SaveClient",
    SaveAffidavit = "SaveAffidavit",
    SaveLinkedCondition = "SaveLinkedCondition",
    SaveStatDec = "SaveStatDec",
    SaveUndertaking = "SaveUndertaking",
    SaveDebt = "SaveDebt",
    SaveLien = "SaveLien",
    SaveCondition = "SaveCondition",
    SaveCreatePaymentGroup = "SaveCreatePaymentGroup",
    SaveUpdatePaymentGroup = "SaveUpdatePaymentGroup",
    SaveChangePaymentGroup = "SaveChangePaymentGroup"
}

export enum AdminLoading {
    Section = "Section",
    Lenders = "Lenders",
    LenderSources = "LenderSources",
    SelectedLender = "SelectedLender",
    SaveLender = "SaveLender",
    LenderContacts = "LenderContacts",
    LenderContactBeingEdited = "LenderContactBeingEdited",
    LenderContactModal = "LenderContactModal",
    SaveLenderContact = "SaveLenderContact",
    LegalFirms = "LegalFirms",
    SaveLegalFirm = "SaveLegalFirm",
    SelectedLegalFirm = "SelectedLegalFirm",
    LegalFirmOffices = "LegalFirmOffices",
    SaveLegalFirmOffice = "SaveLegalFirmOffice",
    SelectedLegalFirmOffice = "SelectedLegalFirmOffice",
    LegalFirmOfficeBeingEdited = "LegalFirmOfficeBeingEdited",
}