import { MortgageBrokerageOtherContact } from "../../../types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";

export const defaultMortgageBrokerageOtherContact:  MortgageBrokerageOtherContact = {
    role: undefined,
    id: -1,
    name: undefined,
    phone: undefined,
    email: undefined,
    date_created: undefined,
    date_updated: undefined
}