import { FireInsuranceCompany } from "../../../types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultFireInsuranceCompany: FireInsuranceCompany = {
    id: -1,
    verification_status: undefined,
    name: undefined,
    abbr_name: undefined,
    general_address: defaultSimpleAddress,
    all_regions: undefined,
    email: undefined,
    phone: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    fire_insurance_company: undefined,
    regions: []
}