import { Region } from "../types/DealList/deals";
import { Address } from "../types/UniversalSurvey/Address/address";
import { SimpleAddress } from "../types/UniversalSurvey/Address/simpleAddress";
import { formatProvinceDB } from "./formatValues";
import { getStrOutput } from "./pluralizationFunctions";

export function addressToString(address?: SimpleAddress | Address | null): string {
    if (!address) return "";
    return `${address.street_address ? `${address.street_address}, ` : ""}${address.city ? `${address.city}, `: "" }${address.province_enum ? formatProvinceDB(address.province_enum) + ", " : ""}${address.postal_code ? `${address.postal_code}, ` : ""}${address.country ?? ""}`
}

export function addressToStringShort(address?: SimpleAddress | Address | null): string {
    if (!address) return "";
    return `${address.street_address ? `${address.street_address}, ` : ""}${address.city ? `${address.city}`: ""}`
}

export function addressToStringHeader(address: SimpleAddress | Address | null | undefined): string {
    if (!address?.street_address) return ""
    return address.street_address
}

export function regionListToString(allRegion: boolean | undefined, regionList: Region[]): string {
    if (allRegion) return "ALL";
    let abbrList: string[] = [];
    for (const region of regionList) {
        region.abbreviation ? abbrList.push(region.abbreviation) : undefined;
    }
    return getStrOutput(abbrList);
}