import { RoleTypesDB } from "../../../../libs/resources/enums/roles";
import { RoleMapping } from "../../../../libs/resources/mappings/roles";
import { Role } from "../../../../libs/types/UniversalSurvey/Roles/role";


function sortTitleTransfers(titleTransfers: Role[]): Role[] {
    titleTransfers.sort((a, b) => {
        if(a.record_id === b.record_id){
            if (a.role === RoleTypesDB.Transferee && b.role !== RoleTypesDB.Transferee) {
                return -1
            }
            return 1;
        } else {
            return a.record_id > b.record_id ? 1 : -1
        }
    });
    return titleTransfers;
}

function labelTitleTransfer(role: RoleTypesDB, transactionLabel: string): string {
    return `${transactionLabel.replace("Title Transfer", "TT")}: ${RoleMapping[role]}`
}

export {
    sortTitleTransfers,
    labelTitleTransfer
}