import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { FireInsuranceBrokerage, FireInsuranceBrokerageErrorState } from "../../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import { defaultFireInsuranceBrokerage } from "../../../../libs/resources/defaults/frontend/defaultFireInsuranceBrokerage";
import { createNewFireInsuranceBrokerageRecord, saveFireInsuranceBrokerage } from "../../../../context/UniversalSurvey/asyncActions/fireInsuranceBrokerages";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultFireInsuranceBrokerageErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFireInsuranceBrokerageErrorState";

type Props = Omit<ModalProps, "children">;

export default function FireInsuranceBrokerageModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [brokerageObj, setBrokerageObj] = useState<FireInsuranceBrokerage>({ ...defaultFireInsuranceBrokerage });
    const [originalCopy, setOriginalCopy] = useState<FireInsuranceBrokerage>({ ...defaultFireInsuranceBrokerage });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [errorState, setErrorState] = useState<FireInsuranceBrokerageErrorState>({ ...defaultFireInsuranceBrokerageErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.fireInsuranceBrokerages.editingRecord && state.fireInsuranceBrokerages.brokerageInfo) {
            setBrokerageObj(state.fireInsuranceBrokerages.brokerageInfo);
            setOriginalCopy(state.fireInsuranceBrokerages.brokerageInfo);
            let tempRegionList: RegionListItem[] = [];
            if (state.fireInsuranceBrokerages.brokerageInfo.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.fireInsuranceBrokerages.brokerageInfo.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
        } else {
            setBrokerageObj({ ...defaultFireInsuranceBrokerage });
            setOriginalCopy({ ...defaultFireInsuranceBrokerage });
            setSelectedRegionList([]);
        }
    }, [state.fireInsuranceBrokerages.editingRecord]);

    function updateBrokerageInfo<
        K extends keyof FireInsuranceBrokerage,
        V extends FireInsuranceBrokerage[K]
    >(key: K, value: V): void {
        const tempBrokerageObj = { ...brokerageObj }
        tempBrokerageObj[key] = value;
        setBrokerageObj(tempBrokerageObj);
    }

    function submit() {
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.fireInsuranceBrokerages.editingRecord) {
            saveFireInsuranceBrokerage(dispatch, String(state.deal.dealInfo?.id), brokerageObj, regionList);
        } else {
            createNewFireInsuranceBrokerageRecord(dispatch, String(state.deal.dealInfo?.id), brokerageObj, regionList);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD, payload: false });
        setBrokerageObj({ ...defaultFireInsuranceBrokerage });
        setOriginalCopy({ ...defaultFireInsuranceBrokerage });
        setSelectedRegionList([]);
        setErrorState({ ...defaultFireInsuranceBrokerageErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(brokerageObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateBrokerageInfo("all_regions", true);
        } else {
            updateBrokerageInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function isSaveDisabled() {
        if (_.isEqual(originalCopy, brokerageObj)) return true;
        if (!brokerageObj.name || !(selectedRegionList.length > 0) || !brokerageObj.general_address) return true;
        if (validateModalObject(errorState)) return true;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, brokerageObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.fireInsuranceBrokerages.editingRecord ? "" : "New "}Fire Insurance Brokerage`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.fireInsuranceBrokerages.editingRecord ? " Changes" : ""}`}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveFireInsuranceBrokerage])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{ text: "Brokerage Name", inputId: "name", isRequired: true }}
                            placeholder="Brokerage's name"
                            value={brokerageObj.name ?? undefined}
                            onChange={(e) => updateBrokerageInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Brokerage Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Abbreviated Name", inputId: "abbr_name" }}
                            value={brokerageObj.abbr_name ?? undefined}
                            onChange={(e) => updateBrokerageInfo("abbr_name", e.target.value)}
                            placeholder="Abbreviated name"
                            inputProps={{ "aria-label": "Abbreviated Name" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ComboBox
                            options={state.regions.regions}
                            tokenField
                            value={selectedRegionList}
                            label={{ text: "Province(s) of Operation", inputId: "provinces", isRequired: true }}
                            onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                            placeholder="Province initials"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Email", inputId: "email" }}
                            value={brokerageObj.email ?? undefined}
                            onChange={(e) => updateBrokerageInfo("email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorKey: "email",
                                setErrorState: setErrorState,
                                errorState: errorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={brokerageObj.phone}
                            onChange={(value) => updateBrokerageInfo("phone", value)}
                            label={{ text: "Phone", inputId: "phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={brokerageObj.fax}
                            onChange={(value) => updateBrokerageInfo("fax", value)}
                            label={{ text: "Fax", inputId: "fax" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "fax",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={brokerageObj.general_address}
                            setAddress={(value) => updateBrokerageInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}