import { DebtAction } from "./enums/debtAction";
import { DebtHolderTypes } from "./enums/debtHolderTypes";
import { EmploymentStatus } from "./enums/employmentStatus";
import { FamilyLawAct } from "./enums/familyLawAct";
import { GovernmentLevels } from "./enums/governmentLevels";
import { LienInstrumentType, LienIsTo } from "./enums/liens";
import { ChargeHolderType } from "./enums/chargeHolderType";
import { LenderType } from "./enums/lenderInstitutions";
import { PaymentDeliveryMethod } from "./enums/paymentMethod";
import { PoaEoeRelationshipActing, PoaEoeRelationshipType } from "./enums/poaEoeRelationships";
import { PropertyPinType } from "./enums/propertyPinType";
import { ProvinceDB } from "./enums/provinces";
import { RoleTypes } from "./enums/roles";
import { TransactionTypes } from "./enums/transactions";
import { TrustLedgerSource } from "./enums/trustLedger";
import { ConditionType } from "./enums/conditionType";
import { ConditionSource } from "./enums/conditionSource";
import { DocContextType } from "./enums/documents/docContextType";
import { DocumentNameMapping, DocumentType } from "./enums/documents/documentType";
import { DealActingAs } from "./enums/dealActingAs";
import { DeliveryMethod } from "./enums/deliveryMethod";
import { PaymentRecipientType, ThirdPartyTransfer } from "./enums/paymentGroup";
import { AddressedTo } from "./enums/addressedTo";
import { DealClientCapacity } from "./enums/dealClientCapacity";
import { LegalProfessionalType } from "./enums/legalProfessionalType";
import { DisbursementListItem } from "../types/UniversalSurvey/Disbursements/disbursement";

// Properties
const PropertyTypeOptions = ["Single-family dwelling", "Residential Condominium", "Multi-unit Residential", "Vacant Land", "Farm Land", "Mobile Home", "Commercial"];
const LandRegistryOptionsON: string[] = [
    "Algoma (No. 1)",
    "Brant (No. 2)",
    "Bruce (No. 3)",
    "Cochrane (No. 6)",
    "Dufferin (No. 7)",
    "Dundas (No. 8)",
    "Durham (No. 40)",
    "Essex (No. 12)",
    "Frontenac (No. 13)",
    "Glengarry (No. 14)",
    "Grenville (No. 15)",
    "Grey (No. 16)",
    "Haldimand (No. 18)",
    "Halton (No. 20)",
    "Hastings (No. 21)",
    "Huron (No. 22)",
    "Kenora (No. 23)",
    "Kent (No. 24)",
    "Lambton (No. 25)",
    "Lanark (No. 27)",
    "Leeds (No. 28)",
    "Lennox (No. 29)",
    "Niagara North (No. 30)",
    "Niagara South (No. 59)",
    "Manitoulin (No. 31)",
    "Metro Toronto (No. 80 (64 & 66))",
    "Middlesex (No. 33)",
    "Muskoka (No. 35)",
    "Nipissing (No. 36)",
    "Norfolk (No. 37)",
    "Northumberland (No. 39)",
    "Ottawa-Carleton (No. 4)",
    "Oxford (No. 41)",
    "Parry Sound (No. 42)",
    "Peel (No. 43)",
    "Perth (No. 44)",
    "Peterborough (No. 45)",
    "Prescott (No. 46)",
    "Prince Edward (No. 47)",
    "Rainy River (No. 48)",
    "Renfrew (No. 49)",
    "Russell (No. 50)",
    "Simcoe (No. 51)",
    "Stormont (No. 52)",
    "Sudbury (No. 53)",
    "Timiskaming (No. 54)",
    "Thunder Bay (No. 55)",
    "Victoria (No. 57)",
    "Waterloo (No. 58)",
    "Wellington (No. 61)",
    "Wentworth (No. 62)",
    "York Region (No. 65)"
];
const LandRegistryOptionsAB: string[] = []; // TODO: Add options when adding support for AB
const LandRegistryOptionsBC: string[] = []; // TODO: Add options when adding support for BC

// Instruments
// Options may be added later for the province options
const InstrumentTypeOptionsAB = [
    LienInstrumentType.BuildersLien,
    LienInstrumentType.Caveat,
    LienInstrumentType.CertificateOfLisPendens,
    LienInstrumentType.DowerRelease,
    LienInstrumentType.EncroachmentAgreement,
    LienInstrumentType.Mortgage,
    LienInstrumentType.Order,
    LienInstrumentType.Postponement,
    LienInstrumentType.PowerOfAttorney,
    LienInstrumentType.PublicHealthNotice,
    LienInstrumentType.TaxNotification
];
const InstrumentTypeOptionsBC = [
    LienInstrumentType.AgreementForSale,
    LienInstrumentType.BuildersLien,
    LienInstrumentType.Caveat,
    LienInstrumentType.CertificateOfPendingLitigation,
    LienInstrumentType.CourtOrder,
    LienInstrumentType.CrownDebt,
    LienInstrumentType.EquitableCharge,
    LienInstrumentType.FamilyLawActCharge,
    LienInstrumentType.Judgment,
    LienInstrumentType.LandSpouseProtectionAct,
    LienInstrumentType.LandTaxDeferment,
    LienInstrumentType.Lease,
    LienInstrumentType.LifeEstate,
    LienInstrumentType.Mortgage,
    LienInstrumentType.OptionToPurchase,
    LienInstrumentType.PriorityAgreement,
    LienInstrumentType.PropertyTransferTaxAct,
    LienInstrumentType.RightOfFirstRefusal,
    LienInstrumentType.StrataPropertyActLien
];
const InstrumentTypeOptionsON = [
    LienInstrumentType.AgreementOfPurchaseAndSale,
    LienInstrumentType.ApplicationForInhibitingOrder,
    LienInstrumentType.ApplicationToAnnexRestrictiveCovS118,
    LienInstrumentType.AssignmentOfRentGeneral,
    LienInstrumentType.AssignmentOfRents,
    LienInstrumentType.CautionOfAgreementOfPurchaseAndSale,
    LienInstrumentType.CautionCharge,
    LienInstrumentType.CautionChargeBankruptcyAndInsolvencyAct,
    LienInstrumentType.CautionLand,
    LienInstrumentType.CautionLandEstatesAdministrationAct,
    LienInstrumentType.Caveat,
    LienInstrumentType.CertificateOfLienHousingDevelopmentAct,
    LienInstrumentType.CertificateOfPendingLitigation,
    LienInstrumentType.Charge,
    LienInstrumentType.CondominiumLien,
    LienInstrumentType.ConstructionLien,
    LienInstrumentType.LandRegistrarsCaution,
    LienInstrumentType.Lease,
    LienInstrumentType.MobileHome,
    LienInstrumentType.Notice,
    LienInstrumentType.NoticeOfLease,
    LienInstrumentType.OilAndGasLease,
    LienInstrumentType.OptionToLease,
    LienInstrumentType.OptionToPurchase,
    LienInstrumentType.Postponement,
    LienInstrumentType.SecurityInterest,
    LienInstrumentType.SubdivisionAgreement,
    LienInstrumentType.VendorsLien
];
// TODO: Will likely need to add trigger options for company/government/other as well
const LenderTriggerOptionsAB = [
    LienInstrumentType.Mortgage
];
const LenderTriggerOptionsBC = [
    LienInstrumentType.Lease,
    LienInstrumentType.Mortgage
];
const LenderTriggerOptionsON = [
    LienInstrumentType.AssignmentOfRents,
    LienInstrumentType.CautionCharge,
    LienInstrumentType.CautionChargeBankruptcyAndInsolvencyAct,
    LienInstrumentType.Charge,
    LienInstrumentType.AgreementOfPurchaseAndSale,
    LienInstrumentType.ApplicationForInhibitingOrder,
    LienInstrumentType.ApplicationToAnnexRestrictiveCovS118,
    LienInstrumentType.AssignmentOfRentGeneral,
    LienInstrumentType.CautionLand,
    LienInstrumentType.CautionLandEstatesAdministrationAct,
    LienInstrumentType.NoticeOfLease,
    LienInstrumentType.OilAndGasLease,
    LienInstrumentType.OptionToLease,
    LienInstrumentType.OptionToPurchase,
    LienInstrumentType.Postponement,
    LienInstrumentType.Lease,
    LienInstrumentType.Notice,
    LienInstrumentType.SecurityInterest,
    LienInstrumentType.SubdivisionAgreement,
    LienInstrumentType.VendorsLien
];
const CompanyTriggerOptionsON = [
    LienInstrumentType.CertificateOfLienHousingDevelopmentAct,
    LienInstrumentType.CondominiumLien,
    LienInstrumentType.Caveat,
    LienInstrumentType.CertificateOfPendingLitigation,
    LienInstrumentType.ConstructionLien,
    LienInstrumentType.CautionOfAgreementOfPurchaseAndSale
];
const OtherTriggerOptionsON = [
    LienInstrumentType.Caveat,
    LienInstrumentType.CertificateOfPendingLitigation,
    LienInstrumentType.ConstructionLien,
    LienInstrumentType.CautionOfAgreementOfPurchaseAndSale
];
const AllTriggerOptionsON = [
    LienInstrumentType.LandRegistrarsCaution
];
const IsToOptions = [LienIsTo.BeDischarged, LienIsTo.Remain];
const IsToMapping = { [LienIsTo.BeDischarged]: "Be Discharged", [LienIsTo.Remain]: "Remain" };
const IsToMappingAB = { [LienIsTo.BeDischarged]: "Non-Permitted", [LienIsTo.Remain]: "Permitted" };
const CorrespondenceOptions = ["lender", "lawyer"];
const DischargeRegisteredByOptions = ["lender", "our_firm"];
const DischargeRegisteredByMapping = {lender: "Lender", our_firm: "Our Firm"};
const ProductTypeOptions = ["mortgage", "heloc_step", "construction", "line_of_credit", "elv"];
const CalculationPeriodOptions = ["Half-Yearly, not in advance", "Semi-Annually, not in advance", "Monthly, not in advance", "Annually", "Weekly", "Bi-Weekly", "Quarterly", "N/A"];
const InsuranceAmountOptions = ["full_insurance_amount", "see_sct"];
const PaymentFrequencyOptions = ["weekly", "bi_weekly", "semi_monthly", "monthly", "annual", "on_demand"];
const TransactionOptions = [TransactionTypes.ILA, TransactionTypes.Mortgage, TransactionTypes.TitleTransfer];
const PropertyRolesForTransactions = {
    [TransactionTypes.ILA]: [],
    [TransactionTypes.Mortgage]: [RoleTypes.Collateral, RoleTypes.Other],
    [TransactionTypes.TitleTransfer]: [],
    [TransactionTypes.Deal]: [RoleTypes.Subject]
}
const ClientRolesForTransactions = {
    [TransactionTypes.ILA]: [RoleTypes.Advisee],
    [TransactionTypes.Mortgage]: [RoleTypes.Borrower, RoleTypes.Guarantor],
    [TransactionTypes.TitleTransfer]: [RoleTypes.Transferee, RoleTypes.Transferor],
    [TransactionTypes.Deal]: []
}

const MortgageBrokerageOtherContactRoleOptions = ["admin", "broker"];
const ChargeHolderTypeOptions = [ChargeHolderType.Lender, ChargeHolderType.Company, ChargeHolderType.Government, ChargeHolderType.Other];

const undertakingDirectionOptions = [
    "ensure that the monthly condominium fees have been paid in accordance with the Statement of Adjustments",
    "ensure that tax payments have been made in accordance with the Statement of Adjustments",
    "on or before closing, provide a good and valid discharge of Notice of Security Interest registered as Instrument No. [instrument number] on [date] by [lender] in favour of in the amount of $[amount]",
    "hold [percentage] of the purchase price, being $[percentage in dollar amount], pending receipt of the CRA Non Residents Clearance Certificate and delivery of same to [vendor's law firm]",
    "hold $[dollar amount] from the sale proceeds until noon the business day following closing pending confirmation that keys were delivered in the lockbox as advised. Should we be advised prior to noon the business day following closing that a locksmith was required for entry the $[dollar amount] holdback shall be applied to cover the cost of the lock change. If we have not received notice prior to noon the business day following closing holdback funds shall be released to the seller",
    "deliver satisfactory proof of payment of ...",
    "ensure that [lien name] Registration No. [registration number] is discharged",
    "pay the Cash to Close to the Vendor's Solicitor",
    "pay the costs of a Lender Policy of Title Insurance",
    "payment of legal fees and disbursements of the undersigned's law office",
    "pay the balance to and in the name of the Borrower, and to no other party whatsoever",
    "deliver the original documents by courier or regular mail to your office within 7 days after closing",
    "discharge from title the following liens",
    "pay down the following debts",
    "payout the following debts",
    "payout and close the following debts",
    "provide proof of revised (void, insurance, etc)",
    "deliver",
    "holdback"
];
const undertakingDischargeNotes = [
    "discharge completed by lender",
    "discharge completed by Deeded",
    "debt paid by Surefund",
    "debt paid by direct wire from Deeded",
    "Holdback released to..."
];
const LenderTypeOptions = [LenderType.Institution, LenderType.Private, LenderType.Personal];
const GovernmentLevelOptions = [GovernmentLevels.Federal, GovernmentLevels.Provincial, GovernmentLevels.Municipal];

const lenderContactRoleOptions = ["Funder", "Underwriter", "Administrator", "Accounting", "Discharge"];
const chargeHolderCompanyContactRoleOptions = ["Accounting", "Management", "Registration", "Administration"];
const governmentContactRoleOptions = ["Administration"]
const IDTypeOptions = [
    "Driver's Licence - Canadian",
    "Passport - Canadian",
    "Birth Certificate",
    "Ontario Photo Card",
    "Alberta Photo ID Card",
    "Manitoba Enhanced ID Card",
    "New Brunswick Photo ID Card",
    "Canadian Prov/Territory Health Card",
    "Canadian Citizenship Card",
    "Certificate of Canadian Citizenship",
    "Canadian Permanent Resident Card",
    "Certificate of Naturalization",
    "Canadian Social Insurance Card",
    "NEXUS Card",
    "Canadian Secure Certificate of Indian Status",
    "Canadian Forces Identification Card",
    "Canadian Firearms Licence",
    "Driver's License - USA",
    "Driver's Licence - International",
    "Passport - USA",
    "Passport - International",
    "Credit Card"
];
const Countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (formerly Swaziland)",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste (formerly East Timor)",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City (Holy See)",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];
const AllProvincesOptions = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
];
const AllProvinceDBOptions = [
    ProvinceDB.Alberta,
    ProvinceDB.BritishColumbia,
    ProvinceDB.Manitoba,
    ProvinceDB.NewBrunswick,
    ProvinceDB.NewfoundlandAndLabrador,
    ProvinceDB.NorthwestTerritories,
    ProvinceDB.NovaScotia,
    ProvinceDB.Nunavut,
    ProvinceDB.Ontario,
    ProvinceDB.PrinceEdwardIslad,
    ProvinceDB.Quebec,
    ProvinceDB.Saskatchewan,
    ProvinceDB.Yukon
];
const StateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];
const EmploymentStatusOptions = [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed, EmploymentStatus.Unemployed, EmploymentStatus.Retired];
const ClientSortOrder = [RoleTypes.Client.toString(), "Corporation", RoleTypes.Borrower.toString(), RoleTypes.ConsentingSpouse.toString(), RoleTypes.POA.toString(), RoleTypes.EOE.toString(), RoleTypes.Guarantor.toString()];
const PropertySortOrder = [RoleTypes.Subject.toString(), RoleTypes.Collateral.toString(), RoleTypes.Other.toString()];
const FamilyLawActOptions = [FamilyLawAct.Both, FamilyLawAct.Consent, FamilyLawAct.Consent2, FamilyLawAct.NotMatrimonial, FamilyLawAct.Separated, FamilyLawAct.Released, FamilyLawAct.CourtOrder, FamilyLawAct.NotFamilyResidence];
const OneSpouseFamilyLawOptions = [FamilyLawAct.Released, FamilyLawAct.CourtOrder, FamilyLawAct.NotFamilyResidence];
const PoaEoeRelationshipTypeOptions = [PoaEoeRelationshipType.POA, PoaEoeRelationshipType.EOE];
const PoaEoeRelationshipActingOptions = [PoaEoeRelationshipActing.Solely, PoaEoeRelationshipActing.Jointly, PoaEoeRelationshipActing.Severally];
const PropertyPinTypeOptions = [PropertyPinType.Dwelling, PropertyPinType.Locker, PropertyPinType.Parking, PropertyPinType.Storage, PropertyPinType.ParkingAndStorage, PropertyPinType.SharedProperty, PropertyPinType.Other];
const DebtTypeOptions = [
    "Bank loan",
    "Car loan",
    "Collection Agency",
    "Condo fees",
    "Condo special assessment",
    "Contract",
    "CRA",
    "Credit card",
    "Equilization payment",
    "Family Responsibility Office (FRO)",
    "Legal Aid",
    "Legal Fee",
    "Line of credit",
    "Other loan",
    "Property tax",
    "Rental Equipment",
    "Utility",
    "Writ of Execution"
];
const DebtActionOptions = [DebtAction.PayDown, DebtAction.Payout, DebtAction.PayoutAndClose];
const PaymentMethodOptions = [PaymentDeliveryMethod.Wire, PaymentDeliveryMethod.EFT, PaymentDeliveryMethod.ETransfer, PaymentDeliveryMethod.Other];
const DebtHolderTypeOptions = [DebtHolderTypes.Lender, DebtHolderTypes.Company, DebtHolderTypes.Government, DebtHolderTypes.MortgageBrokerage, DebtHolderTypes.Other];
const ConditionTypeOptions = [
    ConditionType.Deliver,
    ConditionType.Discharge,
    ConditionType.Holdback,
    ConditionType.Other,
    ConditionType.Pay,
    ConditionType.Repair,
    ConditionType.Review,
    ConditionType.Writ
]
const ConditionSourceOptions = [ConditionSource.AgreementOfPurchaseAndSale, ConditionSource.MortgageInstruction, ConditionSource.Other, ConditionSource.Title];
const addDocOptions: { title: string, docType: DocumentType, context?: DocContextType }[] = [
    { title: DocumentNameMapping[DocumentType.StatDec], docType: DocumentType.StatDec, context: DocContextType.StatDec },
    { title: DocumentNameMapping[DocumentType.GuarantorWaiverOfIla], docType: DocumentType.GuarantorWaiverOfIla, context: DocContextType.GuarantorWaiverOfIla },
    { title: DocumentNameMapping[DocumentType.GuarantorGuaranteeOfMortgage], docType: DocumentType.GuarantorGuaranteeOfMortgage, context: DocContextType.GuarantorGuaranteeOfMortgage },
    { title: DocumentNameMapping[DocumentType.ConsentToActConflictOfMortgage], docType: DocumentType.ConsentToActConflictOfMortgage, context: DocContextType.ConsentToActConflictOfMortgage },
    { title: DocumentNameMapping[DocumentType.Form9C], docType: DocumentType.Form9C, context: DocContextType.Form9C },
    { title: DocumentNameMapping[DocumentType.LetterToMortgageeNewMortgage], docType: DocumentType.LetterToMortgageeNewMortgage, context: DocContextType.LetterToMortgageeNewMortgage },
    { title: DocumentNameMapping[DocumentType.LetterToMortgageeExistingMortgage], docType: DocumentType.LetterToMortgageeExistingMortgage, context: DocContextType.LetterToMortgageeExistingMortgage },
    { title: DocumentNameMapping[DocumentType.Ledger], docType: DocumentType.Ledger },
    { title: DocumentNameMapping[DocumentType.StatementOfAccount], docType: DocumentType.StatementOfAccount },
    { title: DocumentNameMapping[DocumentType.LetterToClient], docType: DocumentType.LetterToClient, context: DocContextType.LetterToClient },
    { title: DocumentNameMapping[DocumentType.Undertaking], docType: DocumentType.Undertaking, context: DocContextType.Undertaking },
    { title: DocumentNameMapping[DocumentType.VerificationOfIdentityAgreement], docType: DocumentType.VerificationOfIdentityAgreement, context: DocContextType.VerificationOfIdentityAgreement },
    { title: DocumentNameMapping[DocumentType.GeneralAssignmentOfRents], docType: DocumentType.GeneralAssignmentOfRents },
    { title: DocumentNameMapping[DocumentType.MortgagePackage], docType: DocumentType.MortgagePackage, context: DocContextType.MortgagePackage },
    { title: DocumentNameMapping[DocumentType.GeneralSecurityAgreement], docType: DocumentType.GeneralSecurityAgreement, context: DocContextType.GeneralSecurityAgreement },
    { title: DocumentNameMapping[DocumentType.DirectionReTitle], docType: DocumentType.DirectionReTitle, context: DocContextType.DirectionReTitle },
    { title: DocumentNameMapping[DocumentType.DraThreeOrMore], docType: DocumentType.DraThreeOrMore },
    { title: DocumentNameMapping[DocumentType.DraTwoParties], docType: DocumentType.DraTwoParties },
]
const ActingAsOptions = [DealActingAs.BorrowerOnly, DealActingAs.LenderOnly, DealActingAs.BothMainClientIsBorrower, DealActingAs.BothMainClientIsLender]
const DeliveryMethodOptions = [DeliveryMethod.ChequeByCourier, DeliveryMethod.ChequeByMail, DeliveryMethod.EFT, DeliveryMethod.Wire]
const PaymentRecipientTypeOptions = [
    PaymentRecipientType.Client,
    PaymentRecipientType.Company,
    PaymentRecipientType.Government,
    PaymentRecipientType.Lawyer,
    PaymentRecipientType.Lender,
    PaymentRecipientType.MortgageBrokerage,
    PaymentRecipientType.OtherChargeHolder,
    PaymentRecipientType.ThirdPartyTransfer
];
const ThirdPartyTransferOptions = [ThirdPartyTransfer.Surefund];
const AddressedToOptions = [AddressedTo.Lender, AddressedTo.LawFirm, AddressedTo.Both];
const DealClientCapacityOptions = [DealClientCapacity.RegisteredOwner, DealClientCapacity.JointTenant, DealClientCapacity.TenantsInCommon];
const LegalProfessionalTypeOptions = [LegalProfessionalType.Lawyer, LegalProfessionalType.Notary, LegalProfessionalType.Commissioner];
const DisbursementOptionsSubjectToTax: DisbursementListItem[] = [
    {
        label: "Title Search",
        amount: undefined,
        description: "Title Search"
    },
    {
        label: "Government Title Subsearch/Writ Search",
        amount: undefined,
        description: "Government Title Subsearch/Writ Search"
    },
    {
        label: "File Storage/Administration Fee - 50.00",
        amount: 50,
        description: "File Storage/Administration Fee"
    },
    {
        label: "ID Verification and Security check - 25.00",
        amount: 25,
        description: "ID Verification and Security check"
    }
];
const DisbursementOptionsNotSubjectToTax: DisbursementListItem[] = [
    {
        label: "Government registration of Charge",
        amount: undefined,
        description: "Government registration of Charge"
    },
    {
        label: "Government Registration of Notice of Assignment of Rents",
        amount: undefined,
        description: "Government Registration of Notice of Assignment of Rents"
    },
    {
        label: "Government Registration of Transfer",
        amount: undefined,
        description: "Government Registration of Transfer"
    },
    {
        label: "Government Registration of Discharge of Charge",
        amount: undefined,
        description: "Government Registration of Discharge of Charge"
    },
    {
        label: "Government Registration of Discharge of other encumbrances",
        amount: undefined,
        description: "Government Registration of Discharge of other encumbrances"
    },
    {
        label: "Government Title Subsearch/Writ Search - 13.59",
        amount: 13.59,
        description: "Government Title Subsearch/Writ Search"
    },
    {
        label: "Banking/Wire - 65.00",
        amount: 65,
        description: "Banking/Wire"
    },
    {
        label: "Tax Certificate",
        amount: undefined,
        description: "Tax Certificate"
    }
];

export {
    PropertyTypeOptions,
    LandRegistryOptionsON,
    LandRegistryOptionsAB,
    LandRegistryOptionsBC,
    InstrumentTypeOptionsAB,
    InstrumentTypeOptionsBC,
    InstrumentTypeOptionsON,
    LenderTriggerOptionsAB,
    LenderTriggerOptionsBC,
    LenderTriggerOptionsON,
    IsToOptions,
    IsToMapping,
    IsToMappingAB,
    CorrespondenceOptions,
    DischargeRegisteredByOptions,
    DischargeRegisteredByMapping,
    ProductTypeOptions,
    CalculationPeriodOptions,
    InsuranceAmountOptions,
    PaymentFrequencyOptions,
    TransactionOptions,
    PropertyRolesForTransactions,
    ClientRolesForTransactions,
    MortgageBrokerageOtherContactRoleOptions,
    OtherTriggerOptionsON,
    CompanyTriggerOptionsON,
    AllTriggerOptionsON,
    ChargeHolderTypeOptions,
    undertakingDirectionOptions,
    undertakingDischargeNotes,
    LenderTypeOptions,
    GovernmentLevelOptions,
    chargeHolderCompanyContactRoleOptions,
    governmentContactRoleOptions,
    lenderContactRoleOptions,
    IDTypeOptions,
    Countries,
    AllProvincesOptions,
    StateOptions,
    EmploymentStatusOptions,
    ClientSortOrder,
    PropertySortOrder,
    FamilyLawActOptions,
    OneSpouseFamilyLawOptions,
    PoaEoeRelationshipTypeOptions,
    PoaEoeRelationshipActingOptions,
    PropertyPinTypeOptions,
    DebtTypeOptions,
    DebtActionOptions,
    PaymentMethodOptions,
    DebtHolderTypeOptions,
    ConditionSourceOptions,
    ConditionTypeOptions,
    addDocOptions,
    ActingAsOptions,
    AllProvinceDBOptions,
    DeliveryMethodOptions,
    PaymentRecipientTypeOptions,
    ThirdPartyTransferOptions,
    AddressedToOptions,
    DealClientCapacityOptions,
    LegalProfessionalTypeOptions,
    DisbursementOptionsSubjectToTax,
    DisbursementOptionsNotSubjectToTax
};