// Importing React tools
import { useContext, useState, useEffect } from "react";

// Importing mui design kit components
import { Grid } from "@mui/material";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing application components
import BasicButton from "../../../../components/Common/Button/BasicButton";
import TableBase from "../../../../components/Common/Table/TableBase";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import { getDebtBeingEdited, getDebtsInDeal } from "../../../context/UniversalSurvey/asyncActions/debts";
import DebtModal from "../Components/Modals/DebtModal";
import { createLenderOptionsList } from "../../../context/UniversalSurvey/asyncActions/lenders";
import { createChargeHolderCompanyOptionsList } from "../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import { createGovernmentOptionsList } from "../../../context/UniversalSurvey/asyncActions/governments";
import { createOtherChargeHolderOptionsList } from "../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import { createMortgageBrokerageOptionList } from "../../../context/UniversalSurvey/asyncActions/mortgageBrokerages";
import { formatDebtAction } from "../../../libs/utils/formatValues";
import { Body } from "../../../../components/Common/Typography";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";

export default function UnsecuredDebtsSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [debtModalActive, setDebtModalActive] = useState<boolean>(false);

    useEffect(() => {
        getDebtsInDeal(dispatch, String(state.deal.dealInfo?.id));
    }, []);

    useEffect(() => {
        if (debtModalActive) {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined
            );
            createChargeHolderCompanyOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined
            );
            createGovernmentOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined
            );
            createOtherChargeHolderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined
            );
            createMortgageBrokerageOptionList(
                dispatch,
                String(state.deal.dealInfo?.id),
                undefined
            );
        }
    }, [debtModalActive]);

    function makeDebtRows() {
        const rows = [];
        for (const debt of state.debts.debtsInDeal) {
            rows.push({
                id: debt.id,
                debt_type: debt.debt_type,
                amount_payable: (<Body><FormatNumeric value={debt.amount_payable ?? 0} /></Body>),
                debt_holder: debt.debt_holder_record ? debt.debt_holder_record.name : "",
                account_number: debt.account_number,
                action: debt.action ? formatDebtAction(debt.action) : ""
            })
        }
        return rows;
    }

    return (
        isObjectLoading(state.dataSheet.objectsLoading, [Loading.DebtList]) ? <CircularLoader containerHeight="70vh" /> :
        <div>
            <DebtModal
                open={debtModalActive}
                onClose={() => setDebtModalActive(false)}
            />
            <Grid container rowSpacing={5} columnSpacing={5}>
                {state.debts.debtsInDeal.length > 0 &&
                    <Grid item xs={12}>
                        <TableBase
                            columnHeadings={[
                                { label: "Type/Item", objKey: "debt_type" },
                                { label: "Amount Payable", objKey: "amount_payable" },
                                { label: "Payable To", objKey: "debt_holder" },
                                { label: "Account", objKey: "account_number" },
                                { label: "Action", objKey: "action" },
                            ]}
                            tableRows={makeDebtRows()}
                            clickEnabled
                            onRowClick={(row) => {
                                getDebtBeingEdited(dispatch, String(state.deal.dealInfo?.id), row.id)
                                setDebtModalActive(true);
                            }}
                            id="debts"
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <BasicButton
                        label={{ text: "Add a Debt", inputId: "add-debt" }}
                        action="add"
                        typeOf="secondary"
                        onClick={() => setDebtModalActive(true)}
                    />
                </Grid>
            </Grid>
        </div>
    )
};