import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { MortgageBrokerageOtherContactRoleOptions } from "../../../../libs/resources/options"
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import {
    updateMortgageBrokerageOtherContact,
    createMortgageBrokerageOtherContact,
    createAndReplaceMortgageBrokerageOtherContact,
    removeAndDeleteMortgageBrokerageOtherContact,
    findAllMortgagesWithMortgageBrokerageOtherContact,
    removeMortgageBrokerageOtherContactFromMultipleMortgagesAndDelete
} from "../../../../context/UniversalSurvey/asyncActions/mortgageBrokerageOtherContacts";
import { MortgageBrokerageOtherContact, MortgageBrokerageOtherContactErrorState } from "../../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import { sanitizeMortgageBrokerageOtherContactRequest } from "../../../../libs/types/UniversalSurvey/utils/convertRequest";
import { MortgageBrokerageOtherContactRequest } from "../../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContactRequest";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultMortgageBrokerageOtherContactErrorState } from "../../../../libs/resources/defaults/errorStates/defaultMortgageBrokerageOtherContactErrorState";

type Props = Omit<ModalProps, "children">;

export default function MortgageBrokerageOtherContactModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [otherContactObj, setOtherContactObj] = useState<MortgageBrokerageOtherContact | undefined>(undefined);
    const [originalCopy, setOriginalCopy] = useState<MortgageBrokerageOtherContact | undefined>(undefined);
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<MortgageBrokerageOtherContactErrorState>({ ...defaultMortgageBrokerageOtherContactErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    function updateContactInfo<
        K extends keyof MortgageBrokerageOtherContact,
        V extends MortgageBrokerageOtherContact[K]
    >(key: K, value: V): void {
        const tempOtherContactObj = { ...otherContactObj }
        tempOtherContactObj[key] = value;
        setOtherContactObj(tempOtherContactObj);
    }

    useEffect(() => {
        setOtherContactObj(state.mortgageBrokerageOtherContacts.contactBeingEdited);
        if (state.mortgageBrokerageOtherContacts.contactBeingEdited) {
            setOriginalCopy({ ...state.mortgageBrokerageOtherContacts.contactBeingEdited });
            findAllMortgagesWithMortgageBrokerageOtherContact(dispatch, String(state.deal.dealInfo?.id), state.mortgageBrokerageOtherContacts.contactBeingEdited.id!);
        }
    }, [state.mortgageBrokerageOtherContacts.contactBeingEdited])

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_EDITED, payload: undefined });
        setOtherContactObj(undefined);
        setOriginalCopy(undefined);
        dispatch({ type: actions.SET_MORTGAGES_WITH_MORTGAGE_BROKERAGE_OTHER_CONTACT, payload: [] });
        setErrorState({ ...defaultMortgageBrokerageOtherContactErrorState });
    }

    function handleDiscardClick() {
        if (_.isEqual(otherContactObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (otherContactObj) {
            if (otherContactObj.id) {
                const contactId = otherContactObj.id;
                let requestObj: MortgageBrokerageOtherContactRequest = sanitizeMortgageBrokerageOtherContactRequest(otherContactObj);
                updateMortgageBrokerageOtherContact(dispatch, String(state.deal.dealInfo?.id), contactId, requestObj)
            } else {
                if (state.mortgageBrokerageOtherContacts.otherContactBeingReplacedId) {
                    createAndReplaceMortgageBrokerageOtherContact(
                        dispatch,
                        String(state.deal.dealInfo?.id),
                        String(state.dataSheet.currEntity),
                        state.mortgageBrokerageOtherContacts.otherContactBeingReplacedId,
                        otherContactObj
                    );
                } else {
                    createMortgageBrokerageOtherContact(dispatch, String(state.deal.dealInfo?.id), String(state.dataSheet.currEntity), otherContactObj);
                }
            }
        }
    }

    function onDeleteConfirm() {
        if (state.mortgageBrokerageOtherContacts.mortgagesWithOtherContact.length > 1) {
            removeMortgageBrokerageOtherContactFromMultipleMortgagesAndDelete(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.mortgageBrokerageOtherContacts.mortgagesWithOtherContact,
                state.mortgageBrokerageOtherContacts.contactBeingEdited?.id!
            );
        } else {
            removeAndDeleteMortgageBrokerageOtherContact(
                dispatch,
                String(state.deal.dealInfo?.id),
                String(state.dataSheet.currEntity),
                state.mortgageBrokerageOtherContacts.contactBeingEdited?.id!
            );
        }
        onDiscard();
    }

    function isSaveDisabled():boolean {
        if (!otherContactObj?.name || !otherContactObj.role || _.isEqual(originalCopy, otherContactObj) || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, otherContactObj]);

    return (
        <ModalBase
            title={`${state.mortgageBrokerageOtherContacts.contactBeingEdited ? "" : "New "}Brokerage Other Contact`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.mortgageBrokerageOtherContacts.contactBeingEdited ? true : false}
            confirmDeleteText={`${state.mortgageBrokerageOtherContacts.mortgagesWithOtherContact.length > 1 ? "Warning: this contact is used in other mortgages. " : ""}Are you sure you want to delete this brokerage other contact? This is permanent and irreversible.`}
            saveDisabled={saveDisabled}
            onDelete={onDeleteConfirm}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.MortgageBrokerageOtherContactModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveMortgageBrokerageOtherContact])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{ text: "Name", inputId: "name", isRequired: true }}
                            placeholder="Contact's name"
                            value={otherContactObj?.name ?? undefined}
                            onChange={(e) => updateContactInfo("name", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Dropdown
                            fullWidth
                            label={{ text: "Role", isRequired: true }}
                            placeholder="Select contact's role"
                            options={MortgageBrokerageOtherContactRoleOptions}
                            isHoverActionHidden
                            formatValue={(value: string) => value === "broker" ? "Secondary Broker" : (value[0].toLocaleUpperCase() + value.substring(1))}
                            value={otherContactObj?.role ?? ""}
                            onChange={(e) => updateContactInfo("role", e.target.value as string)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={otherContactObj?.phone}
                            onChange={(value) => updateContactInfo("phone", value)}
                            label={{ text: "Phone", inputId: "phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Email", inputId: "email" }}
                            placeholder="Email"
                            value={otherContactObj?.email ?? undefined}
                            onChange={(e) => updateContactInfo("email", e.target.value)}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "email",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}