import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { TransactionTypes } from "../../../../resources/enums/transactions";
import { Transaction } from "../../Transaction/transaction";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { DirectionReTitleContextRequest } from "../documents/directionReTitleDocument";


function createDirectionReTitleName(titleTransfer: Transaction | undefined): string {
    if (!titleTransfer) return "";
    let name = "Direction Re: Title";
    
    if (titleTransfer.label) {
        name += ` - ${titleTransfer.label}`;
    }
    return name;
}

function isDirectionReTitleDocInDeal(docList: Document[], titleTransfer: Transaction): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.DirectionReTitle && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.title_transfer_id === titleTransfer.id) return true
    }
    return false;
}

function createDirectionReTitleContextRequest(titleTransfer: Transaction | undefined): DirectionReTitleContextRequest {
    return { title_transfer_id: titleTransfer ? titleTransfer.id : -1 };
}

function getDirectionReTitleDocData(transactionsInDeal: Transaction[], contextId: number): [Object, string] {
    const matchingTitleTransfer = transactionsInDeal.find((tt) => tt.id === contextId && tt.transaction_type === TransactionTypes.TitleTransfer);
    return [createDirectionReTitleContextRequest(matchingTitleTransfer), createDirectionReTitleName(matchingTitleTransfer)];
}

function getDirectionReTitleContextOptions(transactionsInDeal: Transaction[], docList: Document[]) {
    const options = [];
    for (const titleTransfer of transactionsInDeal.filter((transaction) => transaction.transaction_type === TransactionTypes.TitleTransfer)) {
        if(!isDirectionReTitleDocInDeal(docList, titleTransfer)) {
            options.push(titleTransfer.id);
        }
    }
    return options;
}

export {
    getDirectionReTitleDocData,
    getDirectionReTitleContextOptions
}