import { useEffect, useRef, useState } from "react";

// Importing MUI components
import { Grid, Stack, styled } from "@mui/material";

// Importing application components
import Colors from "../componentStyling/Colors";
import { BodyBold } from "../Typography";
import { BORDER_1, BORDER_2, BORDER_4 } from "../componentStyling/Styles";
import BasicButton, { ButtonProps } from "../Button/BasicButton";
import { CaretLeft, CaretRight, MinusIcon, PlusIcon, ThumbLargeIcon, ThumbMediumIcon, ViewSourceIcon, VisibileIcon } from "../Icons/Iconography";
import { ApplyWrapper } from "./Helpers/HTMLWrapper";
import { useObserveElementWidth } from "./Helpers/useObserveElementWidth";
import Alert from "../Alerts/Alerts";
import { AlertTypes } from "../../../conveyance/libs/resources/enums/alertTypes";
import CircularLoader from "../Loader/CircularLoader";
import BasicIconButton from "../IconButton/IconButton";
import BasicTextInput from "../TextField/BasicTextInput";
import './Helpers/template.css';

// Importing libraries
//@ts-ignore
import DOMPurify from 'dompurify';
//@ts-ignore
import { Previewer } from 'pagedjs';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from 'react-pdf';
import Theme from "../componentStyling/Theme";
import HTMLIFrame from "./Helpers/PreviewPageFrame";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

export type ViewerDocTypeOptions = "pdf" | "html";
export type ViewerModeOptions = "viewer" | "editor";

type DocViewerProps = {
    docType: ViewerDocTypeOptions;
    parentHeight?: number;
    file?: string | File;
    html?: string;
    htmlBody?: string;
    setHTML?: (value: string) => void;
    title?: string;
    mode?: ViewerModeOptions;
    isPreviewLoading?: boolean;
    isPreparingDocument?: boolean;
    hidePreviewButton?: boolean;
    customButtons?: ButtonProps[];
    produceButtonsDisabled?: boolean;
}

export default function DocumentPreviewer(props: DocViewerProps) {
    const {
        docType,
        html,
        htmlBody,
        title,
        mode,
        setHTML,
        file,
        parentHeight,
        isPreviewLoading,
        isPreparingDocument,
        hidePreviewButton,
        customButtons,
        produceButtonsDisabled
    } = props;
    const { docWidth, docContainerRef } = useObserveElementWidth<HTMLDivElement>();

    const ref = useRef<HTMLDivElement>(null);
    const transformRef = useRef<ReactZoomPanPinchContentRef>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [previewer, setPreviewer] = useState(new Previewer());
    const [flow, setFlow] = useState<any[]>([]);

    const [processedHTML, setProcessedHTML] = useState<string>("");
    const [isCompleteHTML, setIsCompleteHTML] = useState<boolean>(false); 
    const [editSrc, setEditSrc] = useState<boolean>(false);

    const [htmlChunk, setHtmlChunk] = useState<string>("");
    const [pageCount, setPageCount] = useState<number>(0);
    const [tempPage, setTempPage] = useState<number>(1);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [scale, setScale] = useState<number>(1);
    const [dampenScrolling, setDampenScrolling] = useState<number>(0);
    const [thumbnailScale, setThumbnailScale] = useState<"large" | "small">("large");
    const [previousBottom, setPreviousBottom] = useState<number | undefined>(undefined);
    
    function sanitizeHTML(html: string): string {
        const clean = DOMPurify.sanitize(html, {
            ALLOWED_ATTR: [ 'style', 'class',
                // attributes for svg
                'width', 'height', 'viewBox', 'fill', 'd'
            ],
            FORCE_BODY: true
        });
        return clean;
    }

    // Setting HTML
    // If full HTML is passed and editor mode is enabled still disallow users from editing full HTML
    useEffect(() => {
        if (htmlBody) {
            setProcessedHTML(ApplyWrapper(sanitizeHTML(htmlBody), { applyStyle: true }));
            setIsCompleteHTML(false);
            if (mode === "editor") {
                setEditSrc(true);
            } else {
                setEditSrc(false);
            }
            return;
        }
        if (html) {
            setProcessedHTML(sanitizeHTML(html));
            setIsCompleteHTML(true);
            setEditSrc(false);
        }
    }, [html, htmlBody, mode]);

    // PagedJS HTML logic
    useEffect(() => {
        setPreviewer(new Previewer());

        switch (docType) {
            case "html":
                if (processedHTML && !(mode === "editor")) {
                    if (flow.length === 0) {
                        previewer.preview(
                            processedHTML,
                            [],
                            document.querySelector("#preview")
                        ).then((newFlow: any) => {
                            setFlow(newFlow.pages);
                            setPageCount(newFlow.total);
                            setPreviewer(previewer);
                            if (ref.current) {
                                ref.current.innerHTML = "";
                            }
                        });
                    } else {
                        previewer.preview(
                            htmlChunk,
                            [],
                            document.querySelector("#preview")
                        ).then((newFlow: any) => {
                            setPreviewer(previewer);
                        }); 
                    }
                }
                return;
            case "pdf":
                return;
        }
    }, [ref, processedHTML, htmlChunk]);

    useEffect(() => {
        if (flow.length > 0) {
            setHtmlChunk(ApplyWrapper(flow[selectedPage - 1]?.area?.innerHTML ?? "", { applyStyle: true }));
        }
    }, [selectedPage, flow]);

    useEffect(() => {
        if (transformRef.current && containerRef.current && (mode !== "editor" || html)) {
            transformRef.current.setTransform((containerRef.current.clientWidth - 50 - docWidth) * 0.5, 0, 1);
        }
    }, [transformRef, containerRef, docWidth, mode, html]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number}): void {
        setPageCount(numPages);
    }

    function zoomIn() {
        if (transformRef.current) {
            transformRef.current.zoomIn(0.25, 0);
        }
    }

    function zoomOut() {
        if (transformRef.current) {
            transformRef.current.zoomOut(0.25, 0);
        }
    }

    function pageOnChange(value: number) {
        let correctedPage;
        correctedPage = Math.max(1, Math.min(value, pageCount));
        setSelectedPage(correctedPage);
    }

    useEffect(() => {
        setTempPage(selectedPage)
    }, [selectedPage]);

    const handleMessage = (event: any) => {
        if (event.data && event.data.type === 'iframeClick') {
            if (event.data.index >= 0) {
                setSelectedPage(event.data.index + 1);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const handleScroll = (e: any) => {
        const container = containerRef.current;
        if (container) {
            const { scrollTop } = container;
            const isAtTop = scrollTop === 0;
            let tempBottom = scrollTop;

            if (e.deltaY < 0) {
                tempBottom = 0;
            }

            if (isAtTop && e.deltaY < 0) {
                setDampenScrolling(dampenScrolling + 1);
                if (dampenScrolling > 15) {
                    setDampenScrolling(0);
                    pageOnChange(selectedPage - 1);
                }
                return;
            }
        
            if ((previousBottom === tempBottom) && e.deltaY > 0) {
                setDampenScrolling(dampenScrolling + 1);
                if (dampenScrolling > 10) {
                    setDampenScrolling(0);
                    pageOnChange(selectedPage + 1);
                }
                return;
            }

            if ((previousBottom !== tempBottom)) {
                setPreviousBottom(tempBottom);
            }

            setDampenScrolling(0);
        }
    };

    // for use with draft.js editor
    // const [content, setContent] = useState<any>({});
    
    return (
        <DocumentViewerContainer height={parentHeight}>
            <BodyBold color={Colors.WHITE} style={{ textAlign: "center", color: Colors.WHITE }}>
                Source: {title ?? "Document title"} | Page {selectedPage}
            </BodyBold>
            <Container container>
                {editSrc && (
                    <HTMLContainer item xs={5}>
                        <textarea
                            value={htmlBody}
                            onChange={(e) => setHTML ? setHTML(e.target.value as string) : undefined}
                            style={{ width: "100%", height: "100%", resize: "vertical" }}
                        />
                    </HTMLContainer>
                )}
                <ViewerContainer
                    item
                    xs={editSrc ? 7 : true}
                    ref={containerRef}
                    height={parentHeight}
                    onWheel={handleScroll}
                >
                    <TransformWrapper
                        wheel={{ 
                            wheelDisabled: true, 
                            step: 0.25,
                        }}
                        onTransformed={(ref: ReactZoomPanPinchRef, state: { scale: number, positionX: number, positionY: number } ) => {
                            const newScale = state.scale;
                            setScale(newScale >= 1 ? newScale : 1);
                        }}
                        doubleClick={{ disabled: true }}
                        ref={transformRef}
                        smooth={false}
                    >
                        {(customButtons || (mode === "editor" && !isCompleteHTML && !hidePreviewButton)) && (
                            <DocButtonRowContainer>
                                <Stack gap={2} direction={"row"}>
                                    {customButtons && customButtons?.length > 0 && customButtons.map((buttonProps, i) => 
                                        <BasicButton
                                            {...buttonProps}
                                            disabled={buttonProps.label.inputId.includes("produce") && produceButtonsDisabled}
                                            size="small"
                                            key={`viewer-custom-button-${i}`}
                                            onClick={() => buttonProps.onClick ? buttonProps.onClick() : undefined}
                                        />
                                    )}
                                    {(mode === "editor" && !isCompleteHTML && !hidePreviewButton) && (
                                        <BasicIconButton
                                            onClick={() => setEditSrc(!editSrc)}
                                            icon={!editSrc ? (<ViewSourceIcon color={Colors.GRAY_600} />) : (<VisibileIcon color={Colors.GRAY_600} />)}
                                            testId="editSrc"
                                        />
                                    )}
                                </Stack>
                            </DocButtonRowContainer>
                        )}
                        <TransformComponent>
                            <DocumentContainer ref={docContainerRef}>
                                {docType === "pdf" ? (
                                    <Document
                                        file={file}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        error={(
                                            <AlertContainer>
                                                <Alert
                                                    variant={AlertTypes.Error}
                                                    textContent="Failed to load PDF file."
                                                />
                                            </AlertContainer>
                                        )}
                                        loading={(
                                            <CircularLoader loaderHeight="10rem" loaderWidth="10rem" containerHeight="100rem" />
                                        )}
                                    >
                                        <PDFContainer>
                                            <Page
                                                error={(
                                                    <AlertContainer>
                                                        <Alert
                                                            variant={AlertTypes.Error}
                                                            textContent="Failed to load the page."
                                                        />
                                                    </AlertContainer>
                                                )}
                                                loading={(
                                                    <CircularLoader loaderHeight="10rem" loaderWidth="10rem" containerHeight="100rem" />
                                                )}
                                                pageNumber={selectedPage}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                height={1078}
                                                width={820}
                                            />
                                        </PDFContainer>
                                    </Document>
                                ) : (
                                    mode === "editor" ? (
                                        <DocumentEditorContainer>
                                            <DocumentEditor>
                                                <div
                                                    className="html-container"
                                                    dangerouslySetInnerHTML={{ __html: processedHTML }}
                                                />
                                            </DocumentEditor>
                                        </DocumentEditorContainer>
                                    ) : (
                                        <div>
                                            {isPreparingDocument && (
                                                <AlertContainer>
                                                    <Stack direction="column" alignItems="center">
                                                        <Alert
                                                            variant={AlertTypes.Warning}
                                                            textContent="Preparing document. One moment please..."
                                                        />
                                                    </Stack>
                                                </AlertContainer>
                                            )}
                                            <div 
                                                id="preview"
                                                className="html-container"
                                                ref={ref}
                                            >
                                                {isPreviewLoading && <CircularLoader loaderHeight="10rem" loaderWidth="10rem" containerHeight="100rem" />}
                                            </div>
                                        </div>
                                    )
                                )}
                            </DocumentContainer>
                        </TransformComponent>
                    </TransformWrapper>
                </ViewerContainer>
                {!editSrc && (
                    <PreviewPanelContainer 
                        item
                        height={containerRef.current?.clientHeight}
                        width={thumbnailScale === "small" ? "15rem" : "25rem"}
                    >
                        {docType === "html" ? (
                            <Stack direction={"column"} gap={thumbnailScale === "small" ? 3 : 2} alignItems={"center"}>
                                {flow.map((flowItem: any, index) => (
                                    <PreviewPanelPageContainer
                                        thumbnailScale={thumbnailScale}
                                        key={`page_${index}`}
                                        className="iframeWrapper"
                                        active={(index + 1) === selectedPage} 
                                        onClick={() => setSelectedPage(index + 1)}                                  
                                    >
                                        <HTMLIFrame
                                            html={flowItem?.area?.innerHTML ?? ""}
                                            index={index}
                                            thumbnailScale={thumbnailScale}
                                        />
                                    </PreviewPanelPageContainer>
                                ))}
                            </Stack>
                        ) : (
                            <Document
                                file={file ?? ""}
                                error={""}
                                loading={""}
                            >
                                <Stack direction={"column"} gap={thumbnailScale === "small" ? 3 : 2} alignItems={"center"}>
                                    {Array.from(new Array(pageCount), (el, index) => (
                                        <PreviewPanelPageContainer
                                            thumbnailScale={thumbnailScale}
                                            key={`page_${index}`}
                                            className="pdfPageWrapper"
                                            active={(index + 1) === selectedPage}    
                                            onClick={() => setSelectedPage(index + 1)}                                      
                                        >
                                            <Page
                                                pageNumber={index + 1}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                height={thumbnailScale === "small" ? 100 : 220}
                                                width={thumbnailScale === "small" ? 75 : 155}
                                            />
                                        </PreviewPanelPageContainer>
                                    ))}
                                </Stack>
                            </Document>
                        )}
                    </PreviewPanelContainer>
                )}
            </Container>
            <Grid container>
                <Grid item xs={6}>
                    <Stack direction="row" gap={2} alignItems={"center"}>
                        <BodyBold style={{ color: Colors.WHITE }}>
                            {Math.ceil(scale * 100)}% Zoom
                        </BodyBold>
                        <BasicIconButton
                            onClick={zoomOut}
                            disabled={scale <= 1}
                            icon={<MinusIcon color={Colors.GRAY_600} />}
                            testId="zoomOut"
                        />
                        <BasicIconButton
                            onClick={zoomIn}
                            disabled={scale >= 8}
                            icon={<PlusIcon color={Colors.GRAY_600} />}
                            testId="zoomIn"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" gap={2} alignItems={"center"} justifyContent={"flex-end"}>
                        {(mode !== "editor") && (
                            <>
                                <BodyBold style={{ color: Colors.WHITE }}>
                                    Page:
                                </BodyBold>
                                <BasicTextInput
                                    style={{ width: "5rem", backgroundColor: Colors.WHITE }}
                                    inputProps={{ style: { textAlign: "right" }} }
                                    placeholder="0"
                                    onBlur={() => pageOnChange(tempPage)}
                                    value={String(tempPage)}
                                    onChange={(e) => setTempPage(Number(e.target.value))}
                                    size="extra-small"
                                    data-testid="current-page-number"
                                />
                                <BodyBold data-testId="page-count" style={{ color: Colors.WHITE }}>
                                    / {pageCount}
                                </BodyBold>
                                <BasicIconButton
                                    onClick={() => setSelectedPage(selectedPage - 1)}
                                    icon={<CaretLeft color={Colors.GRAY_600} />}
                                    disabled={selectedPage === 1}
                                    testId="prevPage"
                                />
                                <BasicIconButton
                                    onClick={() => setSelectedPage(selectedPage + 1)}
                                    icon={<CaretRight color={Colors.GRAY_600} />}
                                    disabled={selectedPage === pageCount}
                                    testId="nextPage"
                                />
                                <BasicIconButton
                                    onClick={() => setThumbnailScale("small")}
                                    icon={<ThumbMediumIcon color={Colors.GRAY_600} />}
                                    testId="smallThumbnailScale"
                                    disabled={thumbnailScale === "small"}
                                />
                                <BasicIconButton
                                    onClick={() => setThumbnailScale("large")}
                                    icon={<ThumbLargeIcon color={Colors.GRAY_600} />}
                                    testId="largeThumbnailScale"
                                    disabled={thumbnailScale === "large"}
                                />
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </DocumentViewerContainer>
    );
}

const DocumentViewerContainer = styled('div')<{
    height?: number;
}>(({ height }) => ({
    backgroundColor: Colors.GRAY_800,
    padding: "3rem",
    width: "100%",
    height: height ?? "100%",
    // overflow: "hidden"
}));

const Container = styled(Grid)({
    marginTop: "1rem",
    marginBottom: "1rem",
})

const ViewerContainer = styled(Grid)<{
    height?: number;
}>(({ height }) => ({
    backgroundColor: Colors.GRAY_900,
    border: BORDER_1(Colors.BLACK),
    boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "0rem 2.5rem 0rem 2.5rem",
    height: height ? (height >= 500) ? `calc(${height}px - 125px)` : "1078px" : "1078px",
    width: "100%",
    overflowY: "auto"
}));

const DocumentContainer = styled(Grid)({
    overflow: "hidden"
});

const DocumentEditorContainer = styled('div')({
    backgroundColor: Colors.WHITE,
    margin: "1rem",
    padding: "1in",
    width: "8.5in"
});

const PDFContainer = styled('div')({
    padding: "1rem"
});

const DocumentEditor = styled('div')({
    backgroundColor: Colors.WHITE,
});

const HTMLContainer = styled(Grid)({});

const AlertContainer = styled('div')({
    paddingTop: "1rem"
});

const PreviewPanelPageContainer = styled('div')<{
    active?: boolean;
    thumbnailScale: "small" | "large"
}>(({ active, thumbnailScale }) => ({
    width: thumbnailScale === "small" ? "82.5px !important" : "165px !important",
    height: thumbnailScale === "small" ? "107.8px !important" : "210px !important",
    lineHeight: "0px",
    display: "inline-block",
    backgroundColor: Colors.WHITE,
    border: active ? BORDER_4(Theme.PRIMARY) : BORDER_2(Colors.BLACK),
    zIndex: 1,
    '&:hover': {
        boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        border: active ? BORDER_4(Theme.PRIMARY) : BORDER_4(Theme.HOVER)
    }
}));

const PreviewPanelContainer = styled(Grid)<{
    height?: string | number;
    width?: string | number;
}>(({ height, width }) => ({
    width: width ? width : "15rem",
    marginRight: "-2.5rem",
    overflowY: "auto",
    overflowX: "hidden",
    height: height ? height : "100%"
}));

const DocButtonRowContainer = styled('div')({
    padding: "1rem 1rem 1rem 1rem",
    backgroundColor: Colors.GRAY_800,
    marginLeft: "-2.5rem",
    marginRight: "-2.5rem",
    position: "sticky",
    top: 0,
    zIndex: 1
});