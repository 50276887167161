import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    addChargeHolderCompanyContactToLien,
    removeChargeHolderCompanyContactFromLien,
    newChargeHolderCompanyContactRecord,
    getChargeHolderCompanyContactRecords,
    getChargeHolderCompanyContacts,
    getChargeHolderCompanyContactRecord,
    saveChargeHolderCompanyContactRecord
} from "../../../libs/service/axios/api";
import { ChargeHolderCompanyContact, ChargeHolderCompanyContactListItem } from '../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact';
import { ChargeHolderCompany } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeChargeHolderCompanyContactsResponse, sanitizeChargeHolderCompanyContactResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeChargeHolderCompanyContactRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { SimpleChargeHolderCompanyContact } from "../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/simpleChargeHolderCompanyContact";
import { sortByKey } from "../../../libs/utils/arrays";
import { Loading } from "../../../libs/resources/enums/loading";

function createChargeHolderCompanyContactOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: ChargeHolderCompany) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactList, isLoading: true } });
    getChargeHolderCompanyContactRecords(dealId, String(companyInfo.id))
        .then(function (response: any) {
            const contactRecords = sanitizeChargeHolderCompanyContactsResponse(response.data);
            const cleanOptionsList = organizeContacts(dispatch, contactRecords, companyInfo);
            dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_OPTION_LIST, payload: cleanOptionsList });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get company contact records: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeContacts(dispatch: Dispatch<Record<string, any>>, dealContactList: SimpleChargeHolderCompanyContact[], companyInfo: ChargeHolderCompany): ChargeHolderCompanyContactListItem[] {
    const cleanList: ChargeHolderCompanyContactListItem[] = [];
    const isRecordList: number[] = [];

    for (const contact of dealContactList) {
        const contactListItem: ChargeHolderCompanyContactListItem = {
            label: contact.name,
            id: contact.id,
            isRecord: true,
            role: contact.role
        };
        cleanList.push(contactListItem);
        if(contact.charge_holder_company_contact?.id) {
            isRecordList.push(contact.charge_holder_company_contact.id)
        }
    }

    if(companyInfo.charge_holder_company?.id) {
        getChargeHolderCompanyContacts(String(companyInfo.charge_holder_company.id))
            .then(function (response: any) {
                for (const contact of response.data.charge_holder_company_contacts) {
                    if (!isRecordList.includes(contact.id)) {
                        cleanList.push({
                            label: contact.name,
                            id: contact.id,
                            isRecord: false
                        });
                    }
                }
                sortByKey(cleanList, "label");
                return cleanList;
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get company contacts: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactList, isLoading: false } });
            })
    } else {
        sortByKey(cleanList, "label");
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactList, isLoading: false } });
    }
    return cleanList;
}

function addContactToList(dispatch: Dispatch<Record<string, any>>, contact: ChargeHolderCompanyContactListItem, removeEmpty?: boolean) {
    dispatch({ type: actions.ADD_CHARGE_HOLDER_COMPANY_CONTACT_TO_LIST, payload: contact });
    if(removeEmpty) {
        dispatch({ type: actions.SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED, payload: false });
    }
}

function addChargeHolderCompanyContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, companyRecordId: number, companyContact: ChargeHolderCompanyContactListItem, removeEmpty?: boolean) {
    if(companyContact.isRecord) {
        addChargeHolderCompanyContactToLien(dealId, String(lienId), String(companyContact.id))
        .then(function() {
            addContactToList(dispatch, companyContact, removeEmpty);
        })
        .catch(function(error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding company contact to encumbrance: ${error}`, type: AlertTypes.Error } });
        })
    } else {
        createContactRecordFromGlobal(dispatch, dealId, lienId, companyRecordId, companyContact);
    }
}

function createContactRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, companyRecordId: number, contact: ChargeHolderCompanyContactListItem, removeEmpty?: boolean) {
    newChargeHolderCompanyContactRecord(dealId, String(companyRecordId), contact.id!)
        .then(function (response: any) {
            addChargeHolderCompanyContactToLien(dealId, String(lienId), String(response.data.id))
            .then( function () {
                const newContactListItem: ChargeHolderCompanyContactListItem = { id: response.data.id, isRecord: true, label: contact.label };
                addContactToList(dispatch, newContactListItem, removeEmpty);
                dispatch({ type: actions.REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_OPTION_WITH_RECORD, payload: { oldContactId: contact.id, newOption: newContactListItem, oldIsRecord: false } });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding company contact to encumbrance: ${error}`, type: AlertTypes.Error } });
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create company contact record from company contact: ${error}`, type: AlertTypes.Error } });
        })
}

function removeChargeHolderCompanyContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, oldContactId: number) {
    removeChargeHolderCompanyContactFromLien(dealId, String(lienId), String(oldContactId))
    .then(function () {
        dispatch({ type: actions.REMOVE_CHARGE_HOLDER_COMPANY_CONTACT_FROM_LIST, payload: oldContactId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing company contact from encumbrance: ${error}`, type: AlertTypes.Error } });
    })
}

function changeChargeHolderCompanyContact(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, companyRecordId: number, oldContactId: number, newContact: ChargeHolderCompanyContactListItem) {
    addChargeHolderCompanyContact(dispatch, dealId, lienId, companyRecordId, newContact);
    removeChargeHolderCompanyContact(dispatch, dealId, lienId, oldContactId);
}

function setChargeHolderCompanyContactRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, companyId: number, contactRecordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactModal, isLoading: true } });
    getChargeHolderCompanyContactRecord(dealId, String(companyId), String(contactRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED, payload: sanitizeChargeHolderCompanyContactResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get company contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyContactModal, isLoading: false } }))
}

function submitNewChargeHolderCompanyContactRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, companyRecordId: number, contactBeingReplaced: ChargeHolderCompanyContactListItem | undefined, contactInfo: ChargeHolderCompanyContact) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompanyContact, isLoading: true } });
    newChargeHolderCompanyContactRecord(dealId, String(companyRecordId), null)
        .then(function (response: any) {
            const contactRecordId = response.data.id;
            saveChargeHolderCompanyContactRecord(dealId, String(companyRecordId), String(contactRecordId), sanitizeChargeHolderCompanyContactRequest(contactInfo))
                .then(function () {
                    const newContactItem: ChargeHolderCompanyContactListItem = {
                        id: contactRecordId,
                        label: contactInfo.name,
                        isRecord: true
                    };
                    if (contactBeingReplaced) {
                        changeChargeHolderCompanyContact(dispatch, dealId, lienId, companyRecordId, contactBeingReplaced.id!, { id: contactRecordId, label: contactInfo.name, isRecord: true });
                    } else {
                        addChargeHolderCompanyContact(dispatch, dealId, lienId, companyRecordId, { id: contactRecordId, label: contactInfo.name, isRecord: true }, true);
                    }
                    dispatch({ type: actions.ADD_CHARGE_HOLDER_COMPANY_CONTACT_OPTION, payload: newContactItem });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save company contact record: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompanyContact, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompanyContact, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create company contact record: ${error}`, type: AlertTypes.Error } });
        })
}
function saveChargeHolderCompanyContact(dispatch: Dispatch<Record<string, any>>, dealId: string, companyRecordId: number, contactInfo: ChargeHolderCompanyContact) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompanyContact, isLoading: true } });
    saveChargeHolderCompanyContactRecord(dealId, String(companyRecordId), String(contactInfo.id), sanitizeChargeHolderCompanyContactRequest(contactInfo))
        .then(function () {
            const newContact: ChargeHolderCompanyContactListItem = {
                id: contactInfo.id,
                label: contactInfo.name,
                isRecord: true,
                role: contactInfo.role
            }
            dispatch({
                type: actions.REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_OPTION_WITH_RECORD,
                payload: { oldContactId: newContact.id, newOption: newContact, oldIsRecord: true }
            });
            dispatch({
                type: actions.REPLACE_CHARGE_HOLDER_COMPANY_CONTACT_WITH_UPDATED_RECORD,
                payload: { oldContactId: newContact.id, newOption: newContact, oldIsRecord: true }
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save company contact record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompanyContact, isLoading: false } });
        })
}

function setChargeHolderCompanyContactRole(dispatch: Dispatch<Record<string, any>>, dealId: string, companyRecordId: number, contactRecordId: number, index: number, role: string | undefined) {
    getChargeHolderCompanyContactRecord(dealId, String(companyRecordId), String(contactRecordId))
    .then(function (response: any) {
        const contactInfo: ChargeHolderCompanyContact = sanitizeChargeHolderCompanyContactResponse(response.data);
        contactInfo.role = role;
        saveChargeHolderCompanyContactRecord(dealId, String(companyRecordId), String(contactRecordId), sanitizeChargeHolderCompanyContactRequest(contactInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_CHARGE_HOLDER_COMPANY_CONTACT_ROLE, payload: {index: index, value: role }});
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save company contact record: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get company contact record: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    createChargeHolderCompanyContactOptionsList,
    addChargeHolderCompanyContact,
    removeChargeHolderCompanyContact,
    changeChargeHolderCompanyContact,
    setChargeHolderCompanyContactRecordBeingEdited,
    submitNewChargeHolderCompanyContactRecord,
    saveChargeHolderCompanyContact,
    setChargeHolderCompanyContactRole
}