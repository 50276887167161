export enum TrustLedgerType {
    Expenditure = "expenditure",
    Receipt = "receipt",
    Balance = "balance"
}

export enum TrustLedgerSource {
    // Manual
    Manual = "manual",
    // Receipts
    ReceiptMortgageFunds = "receipt_mortgage_funds",
    ReceiptUnionFunds = "receipt_union_funds",
    ReceiptAgentFunds = "receipt_agent_funds",
    ReceiptClientFunds = "receipt_client_funds",
    // Expenditures
    ExpenditureLandTransferTax = "expenditure_land_transfer_tax",
    ExpenditurePaidOffMortgage = "expenditure_paid_off_mortgage",
    ExpenditurePaidOffLien = "expenditure_paid_off_lien",
    ExpenditurePaidOffDebt = "expenditure_paid_off_debt",
    ExpenditureTitleInsurance = "expenditure_title_insurance",
    ExpenditureWiringFee = "expenditure_wiring_fee",
    ExpenditureInternalFeesDisbursement = "expenditure_internal_fees_disbursement",
    ExpenditureLienDischargeFee = "expenditure_lien_discharge_fee",
    // Balance
    Balance = "balance"
}