import { LienRequest } from "../../../types/UniversalSurvey/ExistingLien/lienRequest";

export const defaultLienRequest: LienRequest = {
    property_id: null,
    lender_id: null,
    charge_holder_company_id: null,
    government_id: null,
    other_charge_holder_id: null,
    instrument_type: null,
    charge_holder_type: null,
    mortgage_loan_number: null,
    account_number: null,
    registration_number: null,
    registration_date: null,
    correspondence: null,
    is_to: null,
    collateral_mortgage: null,
    obtain_info_statement: null,
    principal_balance: null,
    payout_calc_start_date: null,
    payout_calc_end_date: null,
    payout_calc_principal: null,
    payout_calc_amount_per_day: null,
    separate_cheque_for_discharge_fee: false,
    payout_calc_discharge_fee: null,
    discharge_registered_by: null,
    step_heloc: false
}