
// Importing MUI components
import { Box, styled, Stack } from '@mui/material';

// Importing application components
import { EmptyCircle } from '../Icons/Iconography';
import { CheckCircle } from '../Icons/Iconography';
import { ErrorIcon } from '../Icons/Iconography';
import { CautionIcon } from '../Icons/Iconography';
import Pill from './Pill';
import theme from '../componentStyling/Theme';
import color from '../componentStyling/Colors';
import { Status } from '../../../conveyance/libs/types/UniversalSurvey/Frontend/status';

type DisplayPillsProps = {
    status: Status;
    isHeader?: boolean;
    label?: string;
}

const EmptyChip = (props: { label?: string }) => {
    return (<Pill style={{ color: color.BLACK, backgroundColor: color.GRAY_100 }} label={props.label ?? "Empty"} icon={ <EmptyCircle color={color.BLACK} /> } />);
}

const DoneChip = (props: { label?: string }) => {
    return (<Pill style={{ color: color.GREEN_500, backgroundColor: color.WHITE, border: `0.1rem solid ${theme.HOVER}` }} label={props.label ?? "Done"} icon={ <CheckCircle color={color.GREEN_500} /> } />)
}

const MissingChip = (props: { missingFields: number, label?: string }) => {
    return (<Pill style={{ color: color.BLACK, backgroundColor: theme.WARNING }} label={props.label ?? `${props.missingFields} Missing fields`} icon={ <CautionIcon color={color.BLACK} /> } />)
}

const ErrorChip = (props: { errors: number, label?: string }) => {
    return (<Pill style={{ color: color.WHITE, backgroundColor: theme.ERROR }} label={props.label ?? `${props.errors} Errors`} icon={ <ErrorIcon color={color.WHITE} /> } />)
}

export default function DisplayPills(props: DisplayPillsProps) {
    const status = props.status;
    
    const CustomPill = () => {
        if (status.errors > 0 && status.missingFields > 0) {
            if (props.isHeader) {
                return (
                    <PillStack direction="row" gap={1}>
                        <MissingChip missingFields={status.missingFields} />
                        <ErrorChip errors={status.errors} />
                    </PillStack>
                );
            } else {
                return (
                    <ErrorChip errors={status.errors} />
                );
            }
        }
        if (status.isEmpty) {
            return (<EmptyChip label={props.label} />);
        } else if (status.missingFields === 0 && status.errors === 0) {
            return (<DoneChip label={props.label} />);
        } else if (status.errors > 0) {
            return (<ErrorChip errors={status.errors} label={props.label} />);
        } else {
            return (<MissingChip missingFields={status.missingFields} label={props.label} />);
        }
    }

    return (
        <PillContainer>
            <CustomPill />
        </PillContainer>
    )
}

const PillContainer = styled(Box)({});

const PillStack = styled(Stack)({});
