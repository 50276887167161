import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { SimpleDisbursement } from "../../../libs/types/UniversalSurvey/Disbursements/simpleDisbursement";

export type DisbursementsState = {
    disbursements: SimpleDisbursement[];
    emptyTaxableDisbursementAdded: boolean;
    emptyNonTaxableDisbursementAdded: boolean;
}

export const defaultDisbursementsState: DisbursementsState = {
    disbursements: [],
    emptyTaxableDisbursementAdded: false,
    emptyNonTaxableDisbursementAdded: false
}

export function disbursementsReducer(state: DisbursementsState, action: Record<string, any>): DisbursementsState {
    switch (action.type) {
        case actions.SET_DISBURSEMENTS:
            return { ...state, disbursements: action.payload };
        case actions.ADD_CUSTOM_DISBURSEMENT:
            return { ...state, disbursements: [...state.disbursements, action.payload] }
        case actions.SET_EMPTY_NON_TAXABLE_DISBURSEMENT_ADDED:
            return { ...state, emptyNonTaxableDisbursementAdded: action.payload };
        case actions.SET_EMPTY_TAXABLE_DISBURSEMENT_ADDED:
            return { ...state, emptyTaxableDisbursementAdded: action.payload };
        case actions.DELETE_DISBURSEMENT:
            return { ...state, disbursements: findAndRemove([...state.disbursements], ["id"], [action.payload]) };
        default:
            return state;
    }
}