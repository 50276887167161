import axios, { AxiosRequestConfig } from "axios";
import { generateHeader } from "./generateHeader";

// Importing application code
import { generateQueryUrl } from "./generateQueryUrl";
import { URLSearchParams } from "url";
import { ClientRequest } from "../../types/UniversalSurvey/Client/clientRequest";
import { PropertyRequest } from "../../types/UniversalSurvey/Property/propertyRequest";
import { LienRequest } from "../../types/UniversalSurvey/ExistingLien/lienRequest";
import { NewMortgageRequest } from "../../types/UniversalSurvey/NewMortgage/newMortgageRequest";
import { LenderRequest } from "../../types/UniversalSurvey/Lender/lenderRequest";
import { GlobalLenderContactRequest, LenderContactRequest } from "../../types/UniversalSurvey/LenderContact/lenderContactRequest";
import { MortgageBrokerageRequest } from "../../types/UniversalSurvey/MortgageBrokerage/mortgageBrokerageRequest";
import { MortgageBrokerRequest } from "../../types/UniversalSurvey/MortgageBroker/mortgageBrokerRequest";
import { MortgageBrokerageOtherContactRequest } from "../../types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContactRequest";
import { PriorityAfterClosingRequest } from "../../types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosingRequest";
import { UndertakingRequest } from "../../types/UniversalSurvey/Undertaking/undertakingRequest";
import { ChargeHolderCompanyRequest } from "../../types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompanyRequest";
import { GovernmentRequest } from "../../types/UniversalSurvey/Government/governmentRequest";
import { ChargeHolderCompanyContactRequest } from "../../types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContactRequest";
import { GovernmentContactRequest } from "../../types/UniversalSurvey/GovernmentContacts/governmentContactRequest";
import { OtherChargeHolderRequest } from "../../types/UniversalSurvey/OtherChargeHolder/otherChargeHolderRequest";
import { FeeRequest } from "../../types/UniversalSurvey/Fees/feeRequest";
import { DisbursementRequest } from "../../types/UniversalSurvey/Disbursements/disbursementRequest";
import { DealRequest } from "../../types/UniversalSurvey/Deal/dealRequest";
import { FireInsuranceRequest } from "../../types/UniversalSurvey/FireInsurance/fireInsuranceRequest";
import { FireInsuranceCompanyRequest } from "../../types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompanyRequest";
import { FireInsuranceBrokerageRequest } from "../../types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerageRequest";
import { FireInsuranceBrokerRequest } from "../../types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBrokerRequest";
import { SpousalRelationshipRequest } from "../../types/UniversalSurvey/SpousalRelationship/SpousalRelationshipRequest";
import { PoaEoeRelationshipRequest } from "../../types/UniversalSurvey/PoaEoeRelationship/PoaEoeRelationshipRequest";
import { AffidavitRequest } from "../../types/UniversalSurvey/Affivadit/affidavitRequest";
import { TitleInsuranceRequest } from "../../types/UniversalSurvey/TitleInsurance/titleInsuranceRequest";
import { TitleInsurancePremiumRequest } from "../../types/UniversalSurvey/TitleInsurancePremium/titleInsuranceRequest";
import { StatDecRequest } from "../../types/UniversalSurvey/StatutoryDeclarations/statDecRequest";
import { PropertyPinRequest } from "../../types/UniversalSurvey/PropertyPins/propertyPinRequest";
import { DebtRequest } from "../../types/UniversalSurvey/Debts/debtRequest";
import { TrustLedgerRequest } from "../../types/UniversalSurvey/TrustLedger/trustLedgerRequest";
import { TrustLedgerType } from "../../resources/enums/trustLedger";
import { CondoCorporationRequest } from "../../types/UniversalSurvey/CondoCorporation/condoCorporationRequest";
import { PropertyManagementCompanyRequest } from "../../types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompanyRequest";
import { ConditionRequest } from "../../types/UniversalSurvey/Condition/conditionRequest";
import { FolderRequest } from "../../types/UniversalSurvey/Documents/folders/folderRequest";
import { ReceivedRequest } from "../../types/UniversalSurvey/Documents/received/receivedRequest";
import { GeneratedRequest } from "../../types/UniversalSurvey/Documents/generated/generatedRequest";
import { TemplateRequest } from "../../types/UniversalSurvey/Documents/templates/baseTemplateRequest";
import { SigningAppointmentRequest } from "../../types/UniversalSurvey/SigningAppointment/signingAppointmentRequest";
import { LegalProfessionalRequest } from "../../types/UniversalSurvey/LegalProfessional/legalProfessionalRequest";
import { GlobalLegalFirmRequest, LegalFirmRequest } from "../../types/UniversalSurvey/LegalFirm/legalFirmRequest";
import { GlobalLegalFirmOfficeRequest, LegalFirmOfficeRequest } from "../../types/UniversalSurvey/LegalFirmOffice/legalFirmOfficeRequest";
import { CreateGeneratedRequest } from "../../types/UniversalSurvey/Documents/generated/createGeneratedRequest";
import { UploadedRequest } from "../../types/UniversalSurvey/Documents/uploaded/uploadedRequest";
import { CreatePaymentGroupRequest } from "../../types/UniversalSurvey/TrustLedger/PaymentGroup/createPaymentGroupRequest";
import { PaymentGroupRequest } from "../../types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroupRequest";
import { TrustLedgerPaymentRequest } from "../../types/UniversalSurvey/TrustLedger/trustLedgerPaymentRequest";
import { NewMortgageNetAdvanceAmountRequest } from "../../types/UniversalSurvey/NewMortgageNetAdvanceAmount/newMortgageNetAdvanceAmountRequest";
import { PriorityBeforeClosingRequest } from "../../types/UniversalSurvey/PriorityBeforeClosing/priorityBeforeClosingRequest";
import { LienPayoutCalcAdditionalAmountRequest } from "../../types/UniversalSurvey/ExistingLienPayoutCalcAdditionalAmount/lienPayoutCalcAdditionalAmountRequest";
import { NoteRequest } from "../../types/UniversalSurvey/Note/noteRequest";
import { CreateSourceLenderRequest, SourceLenderRequest } from "../../types/UniversalSurvey/Lender/SourceLender/sourceLenderRequest";
import { CreateLenderBranchRequest, LenderBranchRequest } from "../../types/UniversalSurvey/Lender/LenderBranch/lenderBranchRequest";

// GET Query API
async function getQueryAPI(apiUrl: string, query?: URLSearchParams) {
    if (query) {
        apiUrl = generateQueryUrl(query, apiUrl);
    }
    let config: AxiosRequestConfig = generateHeader("get", apiUrl);
    const res = axios(config);
    return res;
}

// ********************
// Deal API Requests
// ********************

async function getDeals(query: URLSearchParams) {
    let apiUrl = generateQueryUrl(query, "deals");
    let config: AxiosRequestConfig = generateHeader("get", apiUrl);

    const res = axios(config);
    return await res;
}

async function getDeal(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}`);
    const res = axios(config);
    return await res;
}

async function updateDeal(dealId: string, dealInfo: Partial<DealRequest>) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}`, dealInfo);
    const res = axios(config);
    return await res;
}

// **********************
// Documents API Requests
// **********************

async function getDocuments(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents`);
    const res = axios(config);
    return await res;
}

// Template
async function createTemplateDocument(dealId: string, request: TemplateRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/documents/templates`, request);
    const res = axios(config);
    return await res;
}

async function saveTemplateDocument(dealId: string, docId: string, request: TemplateRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/templates/${docId}`, request);
    const res = axios(config);
    return await res;
}

async function deleteTemplateDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/documents/templates/${docId}`);
    const res = axios(config);
    return await res;
}

async function getTemplateDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/templates/${docId}`);
    const res = axios(config);
    return await res;
}

// Preview
async function createPreviewDocument(dealId: string, templateId: number) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/documents/previews`, { templateDocumentId: templateId });
    const res = axios(config);
    return await res;
}

async function getPreviewDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/previews/${docId}`);
    const res = axios(config);
    return await res;
}

async function getPreviewDocumentStatus(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/previews/${docId}/status`);
    const res = axios(config);
    return await res;
}

// Generated
async function createGeneratedDocument(dealId: string, postBody: CreateGeneratedRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/documents/generated`, postBody);
    const res = axios(config);
    return await res;
}

async function getGeneratedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/generated/${docId}`)
    const res = axios(config);
    return await res;
}

async function getGeneratedDocumentStatus(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/generated/${docId}/status`)
    const res = axios(config);
    return await res;
}

async function saveGeneratedDocument(dealId: string, docId: string, request: GeneratedRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/generated/${docId}`, request);
    const res = axios(config);
    return await res;
}

async function deleteGeneratedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/documents/generated/${docId}`);
    const res = axios(config);
    return await res;
}

// Received
async function getReceivedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/received/${docId}`);
    const res = axios(config);
    return await res;
}

async function saveReceivedDocument(dealId: string, docId: string, request: ReceivedRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/received/${docId}`, request);
    const res = axios(config);
    return await res;
}

async function deleteReceivedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/documents/received/${docId}`);
    const res = axios(config);
    return await res;
}

// Auto Discovered
async function convertSuggestedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/auto-discovered/${docId}`);
    const res = axios(config);
    return await res;
}

// Folder
async function createDocFolder(dealId: string, folderRequest: FolderRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/documents/folders`, folderRequest);
    const res = axios(config);
    return await res;
}

async function saveDocFolder(dealId: string, folderId: string, folderRequest: FolderRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/folders/${folderId}`, folderRequest);
    const res = axios(config);
    return await res;
}

async function deleteDocFolder(dealId: string, folderId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/documents/folders/${folderId}`);
    const res = axios(config);
    return await res;
}

// Uploaded

async function createUploadedDocument(dealId: string, postBody: FormData) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/documents/uploaded`, postBody);
    const res = axios(config);
    return await res;
}

async function getUploadedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/documents/uploaded/${docId}`)
    const res = axios(config);
    return await res;
}

async function saveUploadedDocument(dealId: string, docId: string, request: UploadedRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/documents/uploaded/${docId}`, request);
    const res = axios(config);
    return await res;
}

async function deleteUploadedDocument(dealId: string, docId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/documents/uploaded/${docId}`);
    const res = axios(config);
    return await res;
}


// ********************
// Clients API Requests
// ********************

async function getClients(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/clients`);
    const res = axios(config);
    return await res;
}

async function getClient(dealId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/clients/${clientId}`);
    const res = axios(config);
    return await res;
}

async function newDealClient(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/clients`);
    const res = axios(config);
    return await res;
}

async function saveDealClient(dealId: string, clientId: string, clientInfo: ClientRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/clients/${clientId}`, clientInfo);
    const res = axios(config);
    return await res;
}

async function removeClient(dealId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/clients/${clientId}`);
    const res = axios(config);
    return await res;
}

// ***********************
// Properties API Requests
// ***********************

async function getProperties(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/properties`);
    const res = axios(config);
    return await res;
}

async function getProperty(dealId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

async function newDealProperty(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/properties`);
    const res = axios(config);
    return await res;
}

async function saveDealProperty(dealId: string, propertyId: string, propertyInfo: PropertyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/properties/${propertyId}`, propertyInfo);
    const res = axios(config);
    return await res;
}

async function removeProperty(dealId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

// **************************
// Property PINs API Requests
// **************************

async function getPropertyPin(dealId: string, propertyId: string, pinRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/properties/${propertyId}/pins/${pinRecordId}`);
    const res = axios(config);
    return await res;
}

async function getPropertyPins(dealId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/properties/${propertyId}/pins`);
    const res = axios(config);
    return await res;
}

async function createPropertyPin(dealId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/properties/${propertyId}/pins`);
    const res = axios(config);
    return await res;
}

async function updatePropertyPin(dealId: string, propertyId: string, pinRecordId: string, pinInfo: PropertyPinRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/properties/${propertyId}/pins/${pinRecordId}`, pinInfo);
    const res = axios(config);
    return await res;
}

async function removePropertyPin(dealId: string, propertyId: string, propertyPinId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/properties/${propertyId}/pins/${propertyPinId}`);
    const res = axios(config);
    return await res;
}

// ************************
// Lien API Requests
// ************************

async function getDealLiens(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/liens`);
    const res = axios(config);
    return await res;
}

async function getDealLien(dealId: string, lienId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/liens/${lienId}`);
    const res = axios(config);
    return await res;
}

async function newDealLien(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/liens`);
    const res = axios(config);
    return await res;
}

async function saveDealLien(dealId: string, lienId: string, lienInfo: LienRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/liens/${lienId}`, lienInfo);
    const res = axios(config);
    return await res;
}

async function removeDealLien(dealId: string, lienId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/liens/${lienId}`);
    const res = axios(config);
    return await res; 
}

async function addLenderContactToLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/liens/${lienId}/lender-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function removeLenderContactFromLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/liens/${lienId}/lender-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function addChargeHolderCompanyContactToLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/liens/${lienId}/charge-holder-company-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function removeChargeHolderCompanyContactFromLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/liens/${lienId}/charge-holder-company-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function addGovernmentContactToLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/liens/${lienId}/government-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function removeGovernmentContactFromLien(dealId: string, lienId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/liens/${lienId}/government-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

// ***********************************
// Lien Additional Amount API Requests
// ***********************************

async function newLienPayoutAdditionalAmount(dealId: string, lienId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/liens/${lienId}/payout-calc-additional-amount`);
    const res = axios(config);
    return await res;
}

async function saveLienPayoutAdditionalAmount(dealId: string, lienId: string, additionalAmountId: string, additionalAmountInfo: LienPayoutCalcAdditionalAmountRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/liens/${lienId}/payout-calc-additional-amount/${additionalAmountId}`, additionalAmountInfo);
    const res = axios(config);
    return await res;
}

async function removeLienPayoutAdditionalAmount(dealId: string, lienId: string, additionalAmountId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/liens/${lienId}/payout-calc-additional-amount/${additionalAmountId}`);
    const res = axios(config);
    return await res; 
}

// *************************
// New Mortgage API Requests
// *************************

async function getNewMortgages(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/new-mortgages`);
    const res = axios(config);
    return await res;
}

async function getNewMortgage(dealId: string, newMortgageId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/new-mortgages/${newMortgageId}`);
    const res = axios(config);
    return await res;
}

async function newNewMortgage(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/new-mortgages`);
    const res = axios(config);
    return await res;
}

async function saveNewMortgage(dealId: string, newMortgageId: string, newMortgageInfo: NewMortgageRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/new-mortgages/${newMortgageId}`, newMortgageInfo);
    const res = axios(config);
    return await res;
}

// *********************************************
// New Mortgage Net Advance Amount API Requests
// *********************************************

async function getNewMortgageNetAdvanceAmount(dealId: string, newMortgageId: string, netAdvanceAmountId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/new-mortgages/${newMortgageId}/net-advance-amount/${netAdvanceAmountId}`);
    const res = axios(config);
    return await res;
}

async function getNewMortgageNetAdvanceAmounts(dealId: string, newMortgageId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/new-mortgages/${newMortgageId}/net-advance-amount`);
    const res = axios(config);
    return await res;
}

async function createNewMortgageNetAdvanceAmount(dealId: string, newMortgageId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/new-mortgages/${newMortgageId}/net-advance-amount`);
    const res = axios(config);
    return await res;
}

async function updateNewMortgageNetAdvanceAmount(dealId: string, newMortgageId: string, netAdvanceAmountId: string, data: NewMortgageNetAdvanceAmountRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/new-mortgages/${newMortgageId}/net-advance-amount/${netAdvanceAmountId}`, data);
    const res = axios(config);
    return await res;
}

async function removeNewMortgageNetAdvanceAmount(dealId: string, newMortgageId: string, netAdvanceAmountId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/new-mortgages/${newMortgageId}/net-advance-amount/${netAdvanceAmountId}`);
    const res = axios(config);
    return await res;
}

// ********************
// Lenders API Requests
// ********************

async function newRegionOnLender(lenderId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/lenders/${lenderId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLender(lenderId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/lenders/${lenderId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function getLenderBranches(query?: URLSearchParams) {
    return await getQueryAPI("global/lenders", query);
}

async function getLenderBranch(lenderId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/lenders/${lenderId}`);
    const res = axios(config);
    return await res;
}

async function newLenderBranch(lenderBranchInfo: CreateLenderBranchRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/lenders`, lenderBranchInfo);
    const res = axios(config);
    return await res;
}

async function saveLenderBranch(lenderId: string, lenderInfo: LenderBranchRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/lenders/${lenderId}`, lenderInfo);
    const res = axios(config);
    return await res;
}

async function deleteLenderBranch(lenderId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/lenders/${lenderId}`);
    const res = axios(config);
    return await res;
}

async function getLenderRecords(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/lenders`);
    const res = axios(config);
    return await res;
}

async function getLenderRecord(dealId: string, lenderRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/lenders/${lenderRecordId}`);
    const res = axios(config);
    return await res;
}

async function newLenderRecord(dealId: string, body?: {lender_id: number | null}) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/lenders`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function saveLenderRecord(dealId: string, lenderRecordId: string, lenderRecordInfo: LenderRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/lenders/${lenderRecordId}`, lenderRecordInfo);
    const res = axios(config);
    return await res;
}

async function newRegionOnLenderRecord(dealId: string, lenderRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/lenders/${lenderRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLenderRecord(dealId: string, lenderRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/lenders/${lenderRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function newLenderSource(lenderSourceInfo: CreateSourceLenderRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/source-lenders`, lenderSourceInfo);
    const res = axios(config);
    return await res;
}

async function saveLenderSource(lenderId: string, lenderSourceInfo: SourceLenderRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/source-lenders/${lenderId}`, lenderSourceInfo);
    const res = axios(config);
    return await res;
}

async function getLenderSources(query?: URLSearchParams) {
    return await getQueryAPI("global/source-lenders", query);
}

async function getLenderSource(lenderId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/source-lenders/${lenderId}`);
    const res = axios(config);
    return await res;
}

async function deleteLenderSource(lenderId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/source-lenders/${lenderId}`);
    const res = axios(config);
    return await res;
}

// ***************************
// Lender Contact API Requests
// ***************************

async function getLenderContacts(lenderId: string | undefined, query?: URLSearchParams) {
    if (lenderId) return await getQueryAPI(`global/lenders/${lenderId}/contacts`, query);
    return await getQueryAPI(`global/lenders/contacts`, query);
}

async function getLenderContact(contactId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/lenders/contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function newGlobalLenderContact(lenderId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/lenders/${lenderId}/contacts`);
    const res = axios(config);
    return await res;
}

async function saveGlobalLenderContact(contactId: string, contactInfo: GlobalLenderContactRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/lenders/contacts/${contactId}`, contactInfo);
    const res = axios(config);
    return await res;
}

async function deleteGlobalLenderContact(contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/lenders/contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function getLenderContactRecords(dealId: string, lenderRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/lenders/${lenderRecordId}/contacts`);
    const res = axios(config);
    return await res;
}

async function getLenderContactRecord(dealId: string, lenderRecordId: string, contactRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/lenders/${lenderRecordId}/contacts/${contactRecordId}`);
    const res = axios(config);
    return await res;
}

async function newLenderContactRecord(dealId: string, lenderRecordId: string, lenderContactId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/lenders/${lenderRecordId}/contacts`, lenderContactId ? { lender_contact_id: lenderContactId } : {});
    const res = axios(config);
    return await res;
}

async function saveLenderContactRecord(dealId: string, lenderRecordId: string, contactRecordId: string, lenderContactRecordInfo: LenderContactRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/lenders/${lenderRecordId}/contacts/${contactRecordId}`, lenderContactRecordInfo);
    const res = axios(config);
    return await res;
}

// ************************
// Transaction API Requests
// ************************

async function getDealTransactions(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions`);
    const res = axios(config);
    return await res;
}

// MORTGAGE
async function getMortgageTransactions(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/mortgage`);
    const res = axios(config);
    return await res;
}

async function getMortgageTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/mortgage/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function newMortgageTransaction(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage`);
    const res = axios(config);
    return await res;
}

async function updateMortgageTransaction(dealId: string, transactionId: string, transactionInfo: {} ) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/transactions/mortgage/${transactionId}`, transactionInfo);
    const res = axios(config);
    return await res;
}

async function deleteMortgageTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function createCollateralProperty(dealId: string, transactionId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/collateral-properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

async function createOtherProperty(dealId: string, transactionId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/other-properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

async function removeCollateralProperty(dealId: string, transactionId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/collateral-properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

async function removeOtherProperty(dealId: string, transactionId: string, propertyId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/other-properties/${propertyId}`);
    const res = axios(config);
    return await res;
}

async function createBorrower(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/borrowers/${clientId}`);
    const res = axios(config);
    return await res;
}

async function removeBorrower(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/borrowers/${clientId}`);
    const res = axios(config);
    return await res;
}

async function createGuarantor(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/guarantors/${clientId}`);
    return await axios(config);
}

async function removeGuarantor(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/guarantors/${clientId}`);
    return await axios(config);
}

async function addMortgageBrokerageOtherContact(dealId: string, transactionId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/mortgage-brokerage-other-contacts/${contactId}`);
    return await axios(config);
}

async function removeMortgageBrokerageOtherContact(dealId: string, transactionId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/mortgage-brokerage-other-contacts/${contactId}`);
    return await axios(config);
}

async function addLenderContactToMortgageTransaction(dealId: string, transactionId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/mortgage/${transactionId}/lender-contacts/${contactId}`);
    return await axios(config);
}

async function removeLenderContactFromMortgageTransaction(dealId: string, transactionId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/mortgage/${transactionId}/lender-contacts/${contactId}`);
    return await axios(config);
}

// ILA

async function getIlaTransactions(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/ila`);
    const res = axios(config);
    return await res;
}

async function getIlaTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/ila/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function newIlaTransaction(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/ila`);
    const res = axios(config);
    return await res;
}

async function updateIlaTransaction(dealId: string, transactionId: string, transactionInfo: {} ) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/transactions/ila/${transactionId}`, transactionInfo);
    const res = axios(config);
    return await res;
}

async function deleteIlaTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/ila/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function createAdvisee(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/ila/${transactionId}/advisees/${clientId}`);
    const res = axios(config);
    return await res;
}

async function removeAdvisee(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/ila/${transactionId}/advisees/${clientId}`);
    const res = axios(config);
    return await res;
}

// TITLE TRANSFER

async function getTitleTransferTransactions(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/title-transfer`);
    const res = axios(config);
    return await res;
}

async function getTitleTransferTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/transactions/title-transfer/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function newTitleTransferTransaction(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/title-transfer`);
    const res = axios(config);
    return await res;
}

async function updateTitleTransferTransaction(dealId: string, transactionId: string, transactionInfo: {}) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/transactions/title-transfer/${transactionId}`, transactionInfo);
    const res = axios(config);
    return await res;
}

async function deleteTitleTransferTransaction(dealId: string, transactionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/title-transfer/${transactionId}`);
    const res = axios(config);
    return await res;
}

async function createTransferee(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/title-transfer/${transactionId}/transferee-clients-in/${clientId}`);
    const res = axios(config);
    return await res;
}

async function removeTransferee(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/title-transfer/${transactionId}/transferee-clients-in/${clientId}`);
    const res = axios(config);
    return await res;
}

async function createTransferor(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/transactions/title-transfer/${transactionId}/transferor-clients-out/${clientId}`);
    const res = axios(config);
    return await res;
}

async function removeTransferor(dealId: string, transactionId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/transactions/title-transfer/${transactionId}/transferor-clients-out/${clientId}`);
    const res = axios(config);
    return await res;
}

// *******************************
// Mortgage Brokerage API Requests
// *******************************

async function newRegionOnMortgageBrokerage(mortgageBrokerageId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/mortgage-brokerages/${mortgageBrokerageId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromMortgageBrokerage(mortgageBrokerageId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/mortgage-brokerages/${mortgageBrokerageId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function getMortgageBrokerages(query?: URLSearchParams) {
    return await getQueryAPI("global/mortgage-brokerages", query);
}

async function getMortgageBrokerageRecords(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerages`);
    const res = axios(config);
    return await res;
}

async function getMortgageBrokerageRecord(dealId: string, mortgageBrokerageRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}`);
    const res = axios(config);
    return await res;
}

async function newMortgageBrokerageRecord(dealId: string, body?: { mortgage_brokerage_id: number | null }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/mortgage-brokerages`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function saveMortgageBrokerageRecord(dealId: string, mortgageBrokerageRecordId: string, brokerageInfo: MortgageBrokerageRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}`, brokerageInfo);
    const res = axios(config);
    return await res;
}

async function newRegionOnMortgageBrokerageRecord(dealId: string, mortgageBrokerageRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromMortgageBrokerageRecord(dealId: string, mortgageBrokerageRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// *****************************
// Mortgage Brokers API Requests
// *****************************

async function newRegionOnMortgageBroker(mortgageBrokerageId: string, mortgageBrokerId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/mortgage-brokerages/${mortgageBrokerageId}/brokers/${mortgageBrokerId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromMortgageBroker(mortgageBrokerageId: string, mortgageBrokerId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/mortgage-brokerages/${mortgageBrokerageId}/brokers/${mortgageBrokerId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalMortgageBrokers(mortgageBrokerageId: string | undefined, query?: URLSearchParams) {
    if (mortgageBrokerageId) return await getQueryAPI(`global/mortgage-brokerages/${mortgageBrokerageId}/brokers`, query);
    return await getQueryAPI(`global/mortgage-brokerages/brokers`, query);
}

async function getMortgageBrokerRecords(dealId: string, mortgageBrokerageRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers`);
    const res = axios(config);
    return await res;
}

async function getMortgageBrokerRecord(dealId: string, mortgageBrokerageRecordId: string, mortgageBrokerRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers/${mortgageBrokerRecordId}`);
    const res = axios(config);
    return await res;
}

async function newMortgageBrokerRecord(dealId: string, mortgageBrokerageRecordId: string, body?: { mortgage_broker_id: number | null }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function saveMortgageBrokerRecord(dealId: string, mortgageBrokerageRecordId: string, mortgageBrokerRecordId: string, brokerInfo: MortgageBrokerRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers/${mortgageBrokerRecordId}`, brokerInfo);
    const res = axios(config);
    return await res;
}

async function newRegionOnMortgageBrokerRecord(dealId: string, mortgageBrokerageRecordId: string, mortgageBrokerRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers/${mortgageBrokerRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromMortgageBrokerRecord(dealId: string, mortgageBrokerageRecordId: string, mortgageBrokerRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/mortgage-brokerages/${mortgageBrokerageRecordId}/brokers/${mortgageBrokerRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// **********************************************
// Mortgage Brokerage Other Contacts API Requests
// **********************************************

async function getMortgageBrokerageOtherContacts(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerage-other-contacts/`);
    const res = axios(config);
    return await res;
}

async function getMortgageBrokerageOtherContact(dealId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/mortgage-brokerage-other-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

async function newMortgageBrokerageOtherContact(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/mortgage-brokerage-other-contacts/`);
    const res = axios(config);
    return await res;
}

async function saveMortgageBrokerageOtherContact(dealId: string, contactId: string, contactInfo: MortgageBrokerageOtherContactRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/mortgage-brokerage-other-contacts/${contactId}`, contactInfo);
    const res = axios(config);
    return await res;
}

async function deleteMortgageBrokerageOtherContact(dealId: string, contactId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/mortgage-brokerage-other-contacts/${contactId}`);
    const res = axios(config);
    return await res;
}

// ************************
// Priorities after closing
// ************************

async function getPrioritiesAfterClosing(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/priorities-after-closing`);
    const res = axios(config);
    return await res;
}

async function savePrioritiesAfterClosing(dealId: string, priorityInfo: PriorityAfterClosingRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/priorities-after-closing`, priorityInfo);
    const res = axios(config);
    return await res;
}

// ************************
// Priorities before closing
// ************************

async function getPrioritiesBeforeClosing(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/priorities-before-closing`);
    const res = axios(config);
    return await res;
}

async function savePrioritiesBeforeClosing(dealId: string, priorityInfo: PriorityBeforeClosingRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/priorities-before-closing`, priorityInfo);
    const res = axios(config);
    return await res;
}

// ************
// Undertakings
// ************

async function createUndertaking(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/undertakings`);
    const res = axios(config);
    return await res;
}

async function getUndertaking(dealId: string, undertakingId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/undertakings/${undertakingId}`);
    const res = axios(config);
    return await res;
}

async function getUndertakings(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/undertakings`);
    const res = axios(config);
    return await res;
}

async function saveUndertaking(dealId: string, undertakingId: string, undertakingInfo: UndertakingRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/undertakings/${undertakingId}`, undertakingInfo);
    const res = axios(config);
    return await res;
}

async function deleteUndertaking(dealId: string, undertakingId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/undertakings/${undertakingId}`);
    const res = axios(config);
    return await res;
}

// ***********************
// CHARGE HOLDER COMPANIES
// ***********************

async function createGlobalChargeHolderCompany(companyInfo: ChargeHolderCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/charge-holder-companies`, companyInfo);
    const res = axios(config);
    return await res;
}

async function getGlobalChargeHolderCompanies(query?: URLSearchParams) {
    return await getQueryAPI(`global/charge-holder-companies`, query);
}

async function getGlobalChargeHolderCompany(companyId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/charge-holder-companies/${companyId}`);
    const res = axios(config);
    return await res;
}

async function saveGlobalChargeHolderCompany(companyId: string, companyInfo: ChargeHolderCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/charge-holder-companies/${companyId}`, companyInfo);
    const res = axios(config);
    return await res;
}

async function deleteGlobalChargeHolderCompany(companyId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/charge-holder-companies/${companyId}`);
    const res = axios(config);
    return await res;
}

async function createDealChargeHolderCompany(dealId: string, companyId?: number) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/charge-holder-companies`, companyId ? { charge_holder_company_id: companyId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealChargeHolderCompanies(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/charge-holder-companies`);
    const res = axios(config);
    return await res;
}

async function getDealChargeHolderCompany(dealId: string, companyRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/charge-holder-companies/${companyRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveDealChargeHolderCompany(dealId: string, companyRecordId: string, companyInfo: ChargeHolderCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/charge-holder-companies/${companyRecordId}`, companyInfo);
    const res = axios(config);
    return await res;
}

async function deleteDealChargeHolderCompany(dealId: string, companyRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/charge-holder-companies/${companyRecordId}`);
    const res = axios(config);
    return await res;
}

// ******************************
// CHARGE HOLDER COMPANY CONTACTS
// ******************************

async function getChargeHolderCompanyContacts(companyId: string | undefined, query?: URLSearchParams) {
    if (companyId) return await getQueryAPI(`global/charge-holder-companies/${companyId}/contacts`, query);
    return await getQueryAPI(`global/charge-holder-companies/contacts`, query);
}

async function getChargeHolderCompanyContactRecords(dealId: string, companyRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/charge-holder-companies/${companyRecordId}/contacts`);
    const res = axios(config);
    return await res;
}

async function getChargeHolderCompanyContactRecord(dealId: string, companyRecordId: string, contactRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/charge-holder-companies/${companyRecordId}/contacts/${contactRecordId}`);
    const res = axios(config);
    return await res;
}

async function newChargeHolderCompanyContactRecord(dealId: string, companyRecordId: string, contactId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/charge-holder-companies/${companyRecordId}/contacts`, contactId ? { charge_holder_company_contact_id: contactId } : {});
    const res = axios(config);
    return await res;
}

async function saveChargeHolderCompanyContactRecord(dealId: string, companyRecordId: string, contactRecordId: string, contactRecordInfo: ChargeHolderCompanyContactRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/charge-holder-companies/${companyRecordId}/contacts/${contactRecordId}`, contactRecordInfo);
    const res = axios(config);
    return await res;
}

// ***********
// GOVERNMENTS
// ***********

async function createDealGovernment(dealId: string, governmentId?: number) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/governments`, governmentId ? { government_id: governmentId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealGovernments(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/governments`);
    const res = axios(config);
    return await res;
}

async function getDealGovernment(dealId: string, governmentRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/governments/${governmentRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveDealGovernment(dealId: string, governmentRecordId: string, governmentInfo: GovernmentRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/governments/${governmentRecordId}`, governmentInfo);
    const res = axios(config);
    return await res;
}

async function deleteDealGovernment(dealId: string, governmentRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/governments/${governmentRecordId}`);
    const res = axios(config);
    return await res;
}

async function createGlobalGovernment(governmentInfo: GovernmentRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/governments`, governmentInfo);
    const res = axios(config);
    return await res;
}

async function getGlobalGovernments(query?: URLSearchParams) {
    return await getQueryAPI("global/governments", query);
}

async function getGlobalGovernment(governmentId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/governments/${governmentId}`);
    const res = axios(config);
    return await res;
}

async function saveGlobalGovernment(governmentId: string, governmentInfo: GovernmentRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/governments/${governmentId}`, governmentInfo);
    const res = axios(config);
    return await res;
}

async function deleteGlobalGovernment(governmentId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/governments/${governmentId}`);
    const res = axios(config);
    return await res;
}

// *******************
// GOVERNMENT CONTACTS
// *******************

async function getGovernmentContacts(governmentId: string | undefined, query?: URLSearchParams) {
    if (governmentId) return await getQueryAPI(`global/governments/${governmentId}/contacts`, query);
    return await getQueryAPI(`global/governments/contacts`, query);
}

async function getGovernmentContactRecords(dealId: string, governmentRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/governments/${governmentRecordId}/contacts`);
    const res = axios(config);
    return await res;
}

async function getGovernmentContactRecord(dealId: string, governmentRecordId: string, contactRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/governments/${governmentRecordId}/contacts/${contactRecordId}`);
    const res = axios(config);
    return await res;
}

async function newGovernmentContactRecord(dealId: string, governmentRecordId: string, contactId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/governments/${governmentRecordId}/contacts`, contactId ? { government_contact_id: contactId } : {});
    const res = axios(config);
    return await res;
}

async function saveGovernmentContactRecord(dealId: string, governmentRecordId: string, contactRecordId: string, contactRecordInfo: GovernmentContactRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/governments/${governmentRecordId}/contacts/${contactRecordId}`, contactRecordInfo);
    const res = axios(config);
    return await res;
}

// ********************
// OTHER CHARGE HOLDERS
// ********************

async function createDealOtherChargeHolder(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/other-charge-holders`);
    const res = axios(config);
    return await res;
}

async function getDealOtherChargeHolder(dealId: string, otherChargeHolderId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/other-charge-holders/${otherChargeHolderId}`);
    const res = axios(config);
    return await res;
}

async function getDealOtherChargeHolders(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/other-charge-holders`);
    const res = axios(config);
    return await res;
}

async function saveDealOtherChargeHolder(dealId: string, otherChargeHolderId: string, otherChargeHolderInfo: OtherChargeHolderRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/other-charge-holders/${otherChargeHolderId}`, otherChargeHolderInfo);
    const res = axios(config);
    return await res;
}

async function deleteDealOtherChargeHolder(dealId: string, otherChargeHolderId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/other-charge-holders/${otherChargeHolderId}`);
    const res = axios(config);
    return await res;
}

// ****
// FEES
// ****

async function getGlobalFees(query?: URLSearchParams) {
    return await getQueryAPI("global/fees", query);
}

async function getDealFees(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fees`);
    const res = axios(config);
    return await res;
}

async function saveDealFee(dealId: string, feeId: string, feeInfo: FeeRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/fees/${feeId}`, feeInfo);
    const res = axios(config);
    return await res;
}

async function createDealFee(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fees`);
    const res = axios(config);
    return await res;
}

async function deleteDealFee(dealId: string, feeId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fees/${feeId}`);
    const res = axios(config);
    return await res;
}

// *************
// DISBURSEMENTS
// *************

async function getGlobalDisbursements(query?: URLSearchParams) {
    return await getQueryAPI("global/disbursements", query);
}

async function getDealDisbursements(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/disbursements`);
    const res = axios(config);
    return await res;
}

async function saveDealDisbursement(dealId: string, disbursementId: string, disbursementInfo: DisbursementRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/disbursements/${disbursementId}`, disbursementInfo);
    const res = axios(config);
    return await res;
}

async function createDealDisbursement(dealId: string, body: { type: string }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/disbursements`, body);
    const res = axios(config);
    return await res;
}

async function deleteDealDisbursement(dealId: string, disbursementId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/disbursements/${disbursementId}`);
    const res = axios(config);
    return await res;
}

// **************
// FIRE INSURANCE
// **************

async function createDealFireInsurance(dealId: string, body?: { property_id: number }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function getDealFireInsurance(dealId: string, fireInsuranceId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance/${fireInsuranceId}`);
    const res = axios(config);
    return await res;
}

async function getAllDealFireInsurances(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance`);
    const res = axios(config);
    return await res;
}

async function saveDealFireInsurance(dealId: string, fireInsuranceId: string, body: FireInsuranceRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/fire-insurance/${fireInsuranceId}`, body);
    const res = axios(config);
    return await res;
}

async function deleteDealFireInsurance(dealId: string, fireInsuranceId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance/${fireInsuranceId}`);
    const res = axios(config);
    return await res;
}

// ************************
// FIRE INSURANCE COMPANIES
// ************************

async function createGlobalFireInsuranceCompany(companyInfo: FireInsuranceCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/fire-insurance-companies`, companyInfo);
    const res = axios(config);
    return await res;
}

async function getGlobalFireInsuranceCompanies(query?: URLSearchParams) {
    return await getQueryAPI("global/fire-insurance-companies", query);
}

async function getGlobalFireInsuranceCompany(companyId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/fire-insurance-companies/${companyId}`);
    const res = axios(config);
    return await res;
}

async function saveGlobalFireInsuranceCompany(companyId: string, companyInfo: FireInsuranceCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/fire-insurance-companies/${companyId}`, companyInfo);
    const res = axios(config);
    return await res;
}

async function deleteGlobalFireInsuranceCompany(companyId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/fire-insurance-companies/${companyId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnFireInsuranceCompany(fireInsuranceCompanyId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/fire-insurance-companies/${fireInsuranceCompanyId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceCompany(fireInsuranceCompanyId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/fire-insurance-companies/${fireInsuranceCompanyId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function createDealFireInsuranceCompany(dealId: string, companyId?: number) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-companies`, companyId ? { fire_insurance_company_id: companyId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealFireInsuranceCompanies(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-companies`);
    const res = axios(config);
    return await res;
}

async function getDealFireInsuranceCompany(dealId: string, companyRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-companies/${companyRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveDealFireInsuranceCompany(dealId: string, companyRecordId: string, companyInfo: FireInsuranceCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/fire-insurance-companies/${companyRecordId}`, companyInfo);
    const res = axios(config);
    return await res;
}

async function deleteDealFireInsuranceCompany(dealId: string, companyRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance-companies/${companyRecordId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnFireInsuranceCompanyRecord(dealId: string, fireInsuranceCompanyRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-companies/${fireInsuranceCompanyRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceCompanyRecord(dealId: string, fireInsuranceCompanyRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance-companies/${fireInsuranceCompanyRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// ************************
// FIRE INSURANCE BROKERAGE
// ************************

async function createGlobalFireInsuranceBrokerage(body: FireInsuranceBrokerageRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/fire-insurance-brokerages`, body);
    const res = axios(config);
    return await res;
}

async function getGlobalFireInsuranceBrokerage(fireInsuranceBrokerageId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}`);
    const res = axios(config);
    return await res;
}

async function getAllGlobalFireInsuranceBrokerages(query?: URLSearchParams) {
    return await getQueryAPI("global/fire-insurance-brokerages", query);
}

async function saveGlobalFireInsuranceBrokerage(fireInsuranceBrokerageId: string, body: FireInsuranceBrokerageRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}`, body);
    const res = axios(config);
    return await res;
}

async function deleteGlobalFireInsuranceBrokerage(fireInsuranceBrokerageId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnFireInsuranceBrokerage(fireInsuranceBrokerageId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceBrokerage(fireInsuranceBrokerageId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function createDealFireInsuranceBrokerage(dealId: string, body?: { fire_insurance_brokerage_id: number }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-brokerages`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function getDealFireInsuranceBrokerage(dealId: string, fireInsuranceBrokerageRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}`);
    const res = axios(config);
    return await res;
}

async function getAllDealFireInsuranceBrokerages(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-brokerages`);
    const res = axios(config);
    return await res;
}

async function saveDealFireInsuranceBrokerage(dealId: string, fireInsuranceBrokerageRecordId: string, body: FireInsuranceBrokerageRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}`, body);
    const res = axios(config);
    return await res;
}

async function deleteDealFireInsuranceBrokerage(dealId: string, fireInsuranceBrokerageRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnFireInsuranceBrokerageRecord(dealId: string, fireInsuranceBrokerageRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceBrokerageRecord(dealId: string, fireInsuranceBrokerageRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// *********************
// FIRE INSURANCE BROKER
// *********************

async function newRegionOnFireInsuranceBroker(fireInsuranceBrokerageId: string, fireInsuranceBrokerId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}/brokers/${fireInsuranceBrokerId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceBroker(fireInsuranceBrokerageId: string, fireInsuranceBrokerId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/fire-insurance-brokerages/${fireInsuranceBrokerageId}/brokers/${fireInsuranceBrokerId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalFireInsuranceBrokers(fireInsuranceBrokerageId: string | undefined, query?: URLSearchParams) {
    if (fireInsuranceBrokerageId) return await getQueryAPI(`global/fire-insurance-brokerages/${fireInsuranceBrokerageId}/brokers`, query);
    return await getQueryAPI(`global/fire-insurance-brokerages/brokers`, query);
}

async function getFireInsuranceBrokerRecords(dealId: string, fireInsuranceBrokerageRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers`);
    const res = axios(config);
    return await res;
}

async function getFireInsuranceBrokerRecord(dealId: string, fireInsuranceBrokerageRecordId: string, fireInsuranceBrokerRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers/${fireInsuranceBrokerRecordId}`);
    const res = axios(config);
    return await res;
}

async function newFireInsuranceBrokerRecord(dealId: string, fireInsuranceBrokerageRecordId: string, body?: { fire_insurance_broker_id: number | null }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function saveFireInsuranceBrokerRecord(dealId: string, fireInsuranceBrokerageRecordId: string, fireInsuranceBrokerRecordId: string, brokerInfo: FireInsuranceBrokerRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers/${fireInsuranceBrokerRecordId}`, brokerInfo);
    const res = axios(config);
    return await res;
}

async function newRegionOnFireInsuranceBrokerRecord(dealId: string, fireInsuranceBrokerageRecordId: string, fireInsuranceBrokerRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers/${fireInsuranceBrokerRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromFireInsuranceBrokerRecord(dealId: string, fireInsuranceBrokerageRecordId: string, fireInsuranceBrokerRecordId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/fire-insurance-brokerages/${fireInsuranceBrokerageRecordId}/brokers/${fireInsuranceBrokerRecordId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// *********************
// SPOUSAL RELATIONSHIPS
// *********************

async function newSpousalRelationship(dealId: string, spouseOneId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/spousal-relationships`, { spouse_one_id: spouseOneId });
    const res = axios(config);
    return await res;
}

async function getSpousalRelationships(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/spousal-relationships`);
    const res = axios(config);
    return await res;
}

async function getSpousalRelationship(dealId: string, spousalRelationshipId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/spousal-relationships/${spousalRelationshipId}`);
    const res = axios(config);
    return await res;
}

async function saveSpousalRelationship(dealId: string, spousalRelationshipId: string, relationshipInfo: SpousalRelationshipRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/spousal-relationships/${spousalRelationshipId}`, relationshipInfo);
    const res = axios(config);
    return await res;
}

async function deleteSpousalRelationship(dealId: string, spousalRelationshipId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/spousal-relationships/${spousalRelationshipId}`);
    const res = axios(config);
    return await res;
}

// *********************
// POA/EOE RELATIONSHIPS
// *********************

async function newPoaEoeRelationship(dealId: string, representativeId: number | null, representeeId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/poa-eoe-relationships`, { representative_id: representativeId, representee_id: representeeId });
    const res = axios(config);
    return await res;
}

async function getPoaEoeRelationships(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/poa-eoe-relationships`);
    const res = axios(config);
    return await res;
}

async function getPoaEoeRelationship(dealId: string, poaEoeRelationshipId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/poa-eoe-relationships/${poaEoeRelationshipId}`);
    const res = axios(config);
    return await res;
}

async function savePoaEoeRelationship(dealId: string, poaEoeRelationshipId: string, relationshipInfo: PoaEoeRelationshipRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/poa-eoe-relationships/${poaEoeRelationshipId}`, relationshipInfo);
    const res = axios(config);
    return await res;
}

async function deletePoaEoeRelationship(dealId: string, poaEoeRelationshipId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/poa-eoe-relationships/${poaEoeRelationshipId}`);
    const res = axios(config);
    return await res;
}

// **********
// AFFIDAVITS
// **********

async function newAffidavit(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/affidavits`);
    const res = axios(config);
    return await res;
}

async function getAffidavit(dealId: string, affidavitId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/affidavits/${affidavitId}`);
    const res = axios(config);
    return await res;
}

async function getAffidavits(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/affidavits`);
    const res = axios(config);
    return await res;
}

async function saveAffidavit(dealId: string, affidavitId: string, affidavitInfo: AffidavitRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/affidavits/${affidavitId}`, affidavitInfo);
    const res = axios(config);
    return await res;
}

async function deleteAffidavit(dealId: string, affidavitId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/affidavits/${affidavitId}`);
    const res = axios(config);
    return await res;
}

// ***************
// TITLE INSURANCE
// ***************

async function getDealTitleInsurance(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/title-insurance`);
    const res = axios(config);
    return await res;
}

async function saveDealTitleInsurance(dealId: string, data: TitleInsuranceRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/title-insurance`, data);
    const res = axios(config);
    return await res;
}

// ************************
// TITLE INSURANCE PREMIUMS
// ************************

async function newDealTitleInsurancePremium(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/title-insurance/premiums`);
    const res = axios(config);
    return await res;
}

async function getDealTitleInsurancePremiums(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/title-insurance/premiums`);
    const res = axios(config);
    return await res;
}

async function getDealTitleInsurancePremium(dealId: string, premiumId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/title-insurance/premiums/${premiumId}`);
    const res = axios(config);
    return await res;
}

async function saveDealTitleInsurancePremium(dealId: string, premiumId: string, data: TitleInsurancePremiumRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/title-insurance/premiums/${premiumId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealTitleInsurancePremium(dealId: string, premiumId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/title-insurance/premiums/${premiumId}`);
    const res = axios(config);
    return await res;
}

// **********************
// STATUTORY DECLARATIONS
// **********************

async function newDealStatDec(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/statutory-declarations`);
    const res = axios(config);
    return await res;
}

async function getDealStatDecs(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/statutory-declarations`);
    const res = axios(config);
    return await res;
}

async function getDealStatDec(dealId: string, statutoryDeclarationRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/statutory-declarations/${statutoryDeclarationRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveDealStatDec(dealId: string, statutoryDeclarationRecordId: string, statDecInfo: StatDecRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/statutory-declarations/${statutoryDeclarationRecordId}`, statDecInfo);
    const res = axios(config);
    return await res;
}

async function deleteDealStatDec(dealId: string, statutoryDeclarationRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/statutory-declarations/${statutoryDeclarationRecordId}`);
    const res = axios(config);
    return await res;
}

async function newClientStatDec(dealId: string, statutoryDeclarationRecordId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/statutory-declarations/${statutoryDeclarationRecordId}/clients/${clientId}`);
    const res = axios(config);
    return await res;
}

async function deleteClientStatDec(dealId: string, statutoryDeclarationRecordId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/statutory-declarations/${statutoryDeclarationRecordId}/clients/${clientId}`);
    const res = axios(config);
    return await res;
}

async function newGlobalStatDec() {
    let config: AxiosRequestConfig = generateHeader("post", `global/statutory-declarations`);
    const res = axios(config);
    return await res;
}

async function getGloablStatDecs(query?: URLSearchParams) {
    return await getQueryAPI(`global/statutory-declarations`, query);
}

async function getGlobalStatDec(statutoryDeclarationId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/statutory-declarations/${statutoryDeclarationId}`);
    const res = axios(config);
    return await res;
}

async function saveGlobalStatDec(statutoryDeclarationId: string, statDecInfo: StatDecRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/statutory-declarations/${statutoryDeclarationId}`, statDecInfo);
    const res = axios(config);
    return await res;
}

async function deleteGlobalStatDec(statutoryDeclarationId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/statutory-declarations/${statutoryDeclarationId}`);
    const res = axios(config);
    return await res;
}

// ***************
// UNSECURED DEBTS
// ***************

async function newDebt(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/debts`);
    const res = axios(config);
    return await res;
}

async function getDebts(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/debts`);
    const res = axios(config);
    return await res;
}

async function getDebt(dealId: string, debtRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/debts/${debtRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveDebt(dealId: string, debtRecordId: string, debtInfo: DebtRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/debts/${debtRecordId}`, debtInfo);
    const res = axios(config);
    return await res;
}

async function deleteDebt(dealId: string, debtRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/debts/${debtRecordId}`);
    const res = axios(config);
    return await res;
}

// *************
// TRUST LEDGERS
// *************

async function newTrustLedger(dealId: string, requestBody: { type: TrustLedgerType }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/trust-ledgers`, requestBody);
    const res = axios(config);
    return await res;
}

async function getTrustLedgers(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/trust-ledgers`);
    const res = axios(config);
    return await res;
}

async function getTrustLedger(dealId: string, trustLedgerRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/trust-ledgers/${trustLedgerRecordId}`);
    const res = axios(config);
    return await res;
}

async function saveTrustLedger(dealId: string, trustLedgerRecordId: string, trustLedgerInfo: TrustLedgerRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/trust-ledgers/${trustLedgerRecordId}`, trustLedgerInfo);
    const res = axios(config);
    return await res;
}

async function deleteTrustLedger(dealId: string, trustLedgerRecordId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/trust-ledgers/${trustLedgerRecordId}`);
    const res = axios(config);
    return await res;
}

// Payment Groups

async function saveTrustLedgerPayment(dealId: string, trustLedgerRecordId: string, trustLedgerPaymentInfo: TrustLedgerPaymentRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/trust-ledgers/${trustLedgerRecordId}/payment`, trustLedgerPaymentInfo);
    const res = axios(config);
    return await res;
}

async function newTrustLedgerPaymentGroup(dealId: string, requestBody: CreatePaymentGroupRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/trust-ledger-payment-groups`, requestBody);
    const res = axios(config);
    return await res;
}

async function getTrustLedgerPaymentGroups(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/trust-ledger-payment-groups`);
    const res = axios(config);
    return await res;
}

async function getTrustLedgerPaymentGroup(dealId: string, paymentGroupId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/trust-ledger-payment-groups/${paymentGroupId}`);
    const res = axios(config);
    return await res;
}

async function saveTrustLedgerPaymentGroup(dealId: string, paymentGroupId: string, requestBody: PaymentGroupRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/trust-ledger-payment-groups/${paymentGroupId}`, requestBody);
    const res = axios(config);
    return await res;
}

async function deleteTrustLedgerPaymentGroup(dealId: string, paymentGroupId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/trust-ledger-payment-groups/${paymentGroupId}`);
    const res = axios(config);
    return await res;
}


// ******************
// CONDO CORPORATIONS
// ******************

// Global

async function getGlobalCondoCorporations(query?: URLSearchParams) {
    return await getQueryAPI("global/condo-corporations", query);
}

async function getGlobalCondoCorporation(condoCorpId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/condo-corporations/${condoCorpId}`);
    const res = axios(config);
    return await res;
}

// Deal

async function newDealCondoCorporation(dealId: string, body?: { condo_corporation_id: number }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/condo-corporations`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function getDealCondoCorporations(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/condo-corporations`);
    const res = axios(config);
    return await res;
}

async function getDealCondoCorporation(dealId: string, condoCorporationId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/condo-corporations/${condoCorporationId}`);
    const res = axios(config);
    return await res;
}

async function saveDealCondoCorporation(dealId: string, condoCorporationId: string, data: CondoCorporationRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/condo-corporations/${condoCorporationId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealCondoCorporation(dealId: string, condoCorporationId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/condo-corporations/${condoCorporationId}`);
    const res = axios(config);
    return await res;
}

// *****************************
// PROPERTY MANAGEMENT COMPANIES
// *****************************

// Global

async function getGlobalPropertyManagementCompanies(query?: URLSearchParams) {
    return await getQueryAPI("global/property-management-companies", query);
}

// Deal

async function newDealPropertyManagementCompany(dealId: string, body?: { property_management_company_id: number }) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/property-management-companies`, body ? body : undefined);
    const res = axios(config);
    return await res;
}

async function getDealPropertyManagementCompanies(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/property-management-companies`);
    const res = axios(config);
    return await res;
}

async function getDealPropertyManagementCompany(dealId: string, pmcId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/property-management-companies/${pmcId}`);
    const res = axios(config);
    return await res;
}

async function saveDealPropertyManagementCompany(dealId: string, pmcId: string, data: PropertyManagementCompanyRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/property-management-companies/${pmcId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealPropertyManagementCompany(dealId: string, pmcId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/property-management-companies/${pmcId}`);
    const res = axios(config);
    return await res;
}

// **********
// CONDITIONS
// **********

async function getConditions(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/conditions`);
    const res = axios(config);
    return await res;
}

async function getCondition(dealId: string, conditionId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/conditions/${conditionId}`);
    const res = axios(config);
    return await res;
}

async function newCondition(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/conditions`);
    const res = axios(config);
    return await res;
}

async function saveCondition(dealId: string, conditionId: string, conditionInfo: ConditionRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/conditions/${conditionId}`, conditionInfo);
    const res = axios(config);
    return await res;
}

async function deleteCondition(dealId: string, conditionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/conditions/${conditionId}`);
    const res = axios(config);
    return await res;
}

// *******************
// SIGNING APPOINTMENT
// *******************

async function getSigningAppointments(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/signing-appointments`);
    const res = axios(config);
    return await res;
}

async function getSigningAppointment(dealId: string, signingApptId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/signing-appointments/${signingApptId}`);
    const res = axios(config);
    return await res;
}

async function newSigningAppointment(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/signing-appointments`);
    const res = axios(config);
    return await res;
}

async function saveSigningAppointment(dealId: string, signingApptId: string, signingApptInfo: SigningAppointmentRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/signing-appointments/${signingApptId}`, signingApptInfo);
    const res = axios(config);
    return await res;
}

async function deleteSigningAppointment(dealId: string, signingApptId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/signing-appointments/${signingApptId}`);
    const res = axios(config);
    return await res;
}

// Clients

async function newClientSigningAppointment(dealId: string, signingApptId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/signing-appointments/${signingApptId}/client/${clientId}`);
    const res = axios(config);
    return await res;
}

async function deleteClientSigningAppointment(dealId: string, signingApptId: string, clientId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/signing-appointments/${signingApptId}/client/${clientId}`);
    const res = axios(config);
    return await res;
}

// *******************
// LEGAL PROFESSIONALS
// *******************

async function createGlobalLegalProfessional() {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-professionals`);
    const res = axios(config);
    return await res;
}

async function getGlobalLegalProfessional(professionalId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/legal-professionals/${professionalId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalLegalProfessionals(query?: URLSearchParams) {
    return await getQueryAPI("global/legal-professionals", query);
}

async function saveGlobalLegalProfessional(professionalId: string, data: LegalProfessionalRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/legal-professionals/${professionalId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteGlobalLegalProfessional(professionalId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-professionals/${professionalId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnLegalProfessional(professionalId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-professionals/${professionalId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLegalProfessional(professionalId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-professionals/${professionalId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalFirmsForLegalProfessional(professionalId: string, query?: URLSearchParams) {
    return await getQueryAPI(`global/legal-professionals/${professionalId}/legal-firms`, query);
}

async function addGlobalFirmToLegalProfessional(professionalId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-professionals/${professionalId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function removeGlobalFirmFromLegalProfessional(professionalId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-professionals/${professionalId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalFirmOfficesForLegalProfessional(professionalId: string, firmId: string, query?: URLSearchParams) {
    return await getQueryAPI(`global/legal-professionals/${professionalId}/legal-firms/${firmId}/offices`, query);
}

async function addGlobalFirmOfficeToLegalProfessional(professionalId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-professionals/${professionalId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function removeGlobalFirmOfficeFromLegalProfessional(professionalId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-professionals/${professionalId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function createDealLegalProfessional(dealId: string, globalProfessionalId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-professionals`, globalProfessionalId ? { legal_professional_id: globalProfessionalId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealLegalProfessional(dealId: string, professionalId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-professionals/${professionalId}`);
    const res = axios(config);
    return await res;
}

async function getDealLegalProfessionals(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-professionals`);
    const res = axios(config);
    return await res;
}

async function saveDealLegalProfessional(dealId: string, professionalId: string, data: LegalProfessionalRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/legal-professionals/${professionalId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealLegalProfessional(dealId: string, professionalId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-professionals/${professionalId}`);
    const res = axios(config);
    return await res;
}

async function getDealFirmsForLegalProfessional(dealId: string, professionalId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms`);
    const res = axios(config);
    return await res;
}

async function addDealFirmToLegalProfessional(dealId: string, professionalId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function removeDealFirmFromLegalProfessional(dealId: string, professionalId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function getDealFirmOfficesForLegalProfessional(dealId: string, professionalId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}/offices`);
    const res = axios(config);
    return await res;
}

async function addDealFirmOfficeToLegalProfessional(dealId: string, professionalId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function removeDealFirmOfficeFromLegalProfessional(dealId: string, professionalId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function setFirmForLegalProfessionalRecord(dealId: string, professionalId: string, firmId: number | null) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms`, { legal_firm_record_id: firmId });
    const res = axios(config);
    return await res;
}

async function setOfficeForLegalProfessionalRecord(dealId: string, professionalId: string, firmId: string, officeId: number | null) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/legal-professionals/${professionalId}/legal-firms/${firmId}/offices`, { legal_firm_office_record_id: officeId });
    const res = axios(config);
    return await res;
}

async function newRegionOnLegalProfessionalRecord(dealId: string, professionalId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-professionals/${professionalId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLegalProfessionalRecord(dealId: string, professionalId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-professionals/${professionalId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// ***********
// LEGAL FIRMS
// ***********

async function createGlobalLegalFirm(data: GlobalLegalFirmRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-firms`, data);
    const res = axios(config);
    return await res;
}

async function getGlobalLegalFirms(query?: URLSearchParams) {
    return await getQueryAPI("global/legal-firms", query);
}

async function getGlobalLegalFirm(firmId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function saveGlobalLegalFirm(firmId: string, data: GlobalLegalFirmRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/legal-firms/${firmId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteGlobalLegalFirm(firmId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnLegalFirm(firmId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-firms/${firmId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLegalFirm(firmId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-firms/${firmId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function createDealLegalFirm(dealId: string, globalFirmId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-firms`, globalFirmId ? { legal_firm_id: globalFirmId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealLegalFirms(dealId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-firms`);
    const res = axios(config);
    return await res;
}

async function getDealLegalFirm(dealId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function saveDealLegalFirm(dealId: string, firmId: string, data: LegalFirmRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/legal-firms/${firmId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealLegalFirm(dealId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-firms/${firmId}`);
    const res = axios(config);
    return await res;
}

async function newRegionOnLegalFirmRecord(dealId: string, firmId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-firms/${firmId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

async function deleteRegionFromLegalFirmRecord(dealId: string, firmId: string, regionId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-firms/${firmId}/regions/${regionId}`);
    const res = axios(config);
    return await res;
}

// *************
// LEGAL OFFICES
// *************

async function createGlobalLegalFirmOffice(firmId: string, data: GlobalLegalFirmOfficeRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `global/legal-firms/${firmId}/offices`, data);
    const res = axios(config);
    return await res;
}

async function getGlobalLegalFirmOffice(officeId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `global/legal-firms/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function getGlobalLegalFirmOffices(firmId: string, query?: URLSearchParams) {
    return await getQueryAPI(`global/legal-firms/${firmId}/offices`, query);
}

async function saveGlobalLegalFirmOffice(officeId: string, data: GlobalLegalFirmOfficeRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `global/legal-firms/offices/${officeId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteGlobalLegalFirmOffice(officeId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `global/legal-firms/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function createDealLegalFirmOffice(dealId: string, firmId: string, globalOfficeId: number | null) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/legal-firms/${firmId}/offices`, globalOfficeId ? { legal_firm_office_id: globalOfficeId } : undefined);
    const res = axios(config);
    return await res;
}

async function getDealLegalFirmOffice(dealId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

async function getDealLegalFirmOffices(dealId: string, firmId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/legal-firms/${firmId}/offices`);
    const res = axios(config);
    return await res;
}

async function saveDealLegalFirmOffice(dealId: string, firmId: string, officeId: string, data: LegalFirmOfficeRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/legal-firms/${firmId}/offices/${officeId}`, data);
    const res = axios(config);
    return await res;
}

async function deleteDealLegalFirmOffice(dealId: string, firmId: string, officeId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/legal-firms/${firmId}/offices/${officeId}`);
    const res = axios(config);
    return await res;
}

// *******
// REGIONS
// *******

async function getRegions() {
    let config: AxiosRequestConfig = generateHeader("get", `regions`);
    const res = axios(config);
    return await res;
}

// *****
// NOTES
// *****

async function getNotes(dealId: string, query?: URLSearchParams) {
    return await getQueryAPI(`deals/${dealId}/notes`, query);
}

async function getNote(dealId: string, noteId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `deals/${dealId}/notes/${noteId}`);
    const res = axios(config);
    return await res;
}

async function newNote(dealId: string, noteInfo: NoteRequest) {
    let config: AxiosRequestConfig = generateHeader("post", `deals/${dealId}/notes`, noteInfo);
    const res = axios(config);
    return await res;
}

async function saveNote(dealId: string, noteId: string, noteInfo: NoteRequest) {
    let config: AxiosRequestConfig = generateHeader("put", `deals/${dealId}/notes/${noteId}`, noteInfo);
    const res = axios(config);
    return await res;
}

async function deleteNote(dealId: string, noteId: string) {
    let config: AxiosRequestConfig = generateHeader("delete", `deals/${dealId}/notes/${noteId}`);
    const res = axios(config);
    return await res;
}

// ****
// USER
// ****

async function getDashboardUser(userId: string) {
    let config: AxiosRequestConfig = generateHeader("get", `user/${userId}`);
    const res = axios(config);
    return await res;
}

async function getDashboardUsers(query: URLSearchParams) {
    return await getQueryAPI(`user`, query);
}

export {
    getDeals,
    getDeal,
    updateDeal,
    // Documents,
    getDocuments,
    createTemplateDocument,
    saveTemplateDocument,
    deleteTemplateDocument,
    getTemplateDocument,
    createPreviewDocument,
    getPreviewDocument,
    getPreviewDocumentStatus,
    createGeneratedDocument,
    getGeneratedDocument,
    getGeneratedDocumentStatus,
    saveGeneratedDocument,
    deleteGeneratedDocument,
    getReceivedDocument,
    saveReceivedDocument,
    deleteReceivedDocument,
    convertSuggestedDocument,
    createDocFolder,
    saveDocFolder,
    deleteDocFolder,
    createUploadedDocument,
    deleteUploadedDocument,
    saveUploadedDocument,
    getUploadedDocument,
    // Clients
    getClients,
    getClient,
    newDealClient,
    saveDealClient,
    removeClient,
    // Properties
    getProperties,
    getProperty,
    newDealProperty,
    saveDealProperty,
    removeProperty,
    // Property PINs
    getPropertyPin,
    getPropertyPins,
    createPropertyPin,
    updatePropertyPin,
    removePropertyPin,
    // Liens
    getDealLiens,
    getDealLien,
    newDealLien,
    saveDealLien,
    removeDealLien,
    addLenderContactToLien,
    removeLenderContactFromLien,
    addChargeHolderCompanyContactToLien,
    removeChargeHolderCompanyContactFromLien,
    addGovernmentContactToLien,
    removeGovernmentContactFromLien,
    // Lien additional amounts
    newLienPayoutAdditionalAmount,
    saveLienPayoutAdditionalAmount,
    removeLienPayoutAdditionalAmount,
    // New Mortgages
    getNewMortgage,
    getNewMortgages,
    newNewMortgage,
    saveNewMortgage,
    // New Mortgage Net Advance Amounts
    getNewMortgageNetAdvanceAmount,
    getNewMortgageNetAdvanceAmounts,
    createNewMortgageNetAdvanceAmount,
    updateNewMortgageNetAdvanceAmount,
    removeNewMortgageNetAdvanceAmount,
    // Lenders
    newRegionOnLender,
    deleteRegionFromLender,
    getLenderRecord,
    getLenderRecords,
    newLenderRecord,
    saveLenderRecord,
    getLenderBranches,
    getLenderBranch,
    newLenderBranch,
    saveLenderBranch,
    deleteLenderBranch,
    newRegionOnLenderRecord,
    deleteRegionFromLenderRecord,
    newLenderSource,
    getLenderSources,
    getLenderSource,
    deleteLenderSource,
    saveLenderSource,
    // Lender Contacts
    getLenderContacts,
    getLenderContact,
    newGlobalLenderContact,
    saveGlobalLenderContact,
    deleteGlobalLenderContact,
    getLenderContactRecords,
    getLenderContactRecord,
    newLenderContactRecord,
    saveLenderContactRecord,
    // Transactions
    getDealTransactions,
    // Mortgages
    getMortgageTransactions,
    getMortgageTransaction,
    newMortgageTransaction,
    updateMortgageTransaction,
    deleteMortgageTransaction,
    createCollateralProperty,
    createOtherProperty,
    removeCollateralProperty,
    removeOtherProperty,
    createBorrower,
    removeBorrower,
    createGuarantor,
    removeGuarantor,
    addMortgageBrokerageOtherContact,
    removeMortgageBrokerageOtherContact,
    addLenderContactToMortgageTransaction,
    removeLenderContactFromMortgageTransaction,
    // ILAs
    getIlaTransactions,
    getIlaTransaction,
    newIlaTransaction,
    updateIlaTransaction,
    deleteIlaTransaction,
    createAdvisee,
    removeAdvisee,
    // Title Transfers
    getTitleTransferTransactions,
    getTitleTransferTransaction,
    newTitleTransferTransaction,
    updateTitleTransferTransaction,
    deleteTitleTransferTransaction,
    createTransferee,
    removeTransferee,
    createTransferor,
    removeTransferor,
    // Mortgage Brokerages
    newRegionOnMortgageBrokerage,
    deleteRegionFromMortgageBrokerage,
    getMortgageBrokerages,
    getMortgageBrokerageRecords,
    getMortgageBrokerageRecord,
    newMortgageBrokerageRecord,
    saveMortgageBrokerageRecord,
    newRegionOnMortgageBrokerageRecord,
    deleteRegionFromMortgageBrokerageRecord,
    // Mortgage Brokers
    newRegionOnMortgageBroker,
    deleteRegionFromMortgageBroker,
    getGlobalMortgageBrokers,
    getMortgageBrokerRecords,
    getMortgageBrokerRecord,
    newMortgageBrokerRecord,
    saveMortgageBrokerRecord,
    newRegionOnMortgageBrokerRecord,
    deleteRegionFromMortgageBrokerRecord,
    // Mortgage Brokerage Other Contacts
    getMortgageBrokerageOtherContacts,
    getMortgageBrokerageOtherContact,
    newMortgageBrokerageOtherContact,
    saveMortgageBrokerageOtherContact,
    deleteMortgageBrokerageOtherContact,
    // Priorities after closing
    getPrioritiesAfterClosing,
    savePrioritiesAfterClosing,
    // Priorities before closing
    getPrioritiesBeforeClosing,
    savePrioritiesBeforeClosing,
    // Undertakings
    createUndertaking,
    getUndertaking,
    getUndertakings,
    saveUndertaking,
    deleteUndertaking,
    // Charge Holder Companies
    createGlobalChargeHolderCompany,
    getGlobalChargeHolderCompanies,
    getGlobalChargeHolderCompany,
    saveGlobalChargeHolderCompany,
    deleteGlobalChargeHolderCompany,
    createDealChargeHolderCompany,
    getDealChargeHolderCompanies,
    getDealChargeHolderCompany,
    saveDealChargeHolderCompany,
    deleteDealChargeHolderCompany,
    // Governments
    createDealGovernment,
    createGlobalGovernment,
    getDealGovernment,
    getDealGovernments,
    getGlobalGovernment,
    getGlobalGovernments,
    saveDealGovernment,
    saveGlobalGovernment,
    deleteDealGovernment,
    deleteGlobalGovernment,
    // Charge Holder Company Contacts
    getChargeHolderCompanyContacts,
    getChargeHolderCompanyContactRecords,
    getChargeHolderCompanyContactRecord,
    newChargeHolderCompanyContactRecord,
    saveChargeHolderCompanyContactRecord,
    // Government Contacts
    getGovernmentContacts,
    getGovernmentContactRecords,
    getGovernmentContactRecord,
    newGovernmentContactRecord,
    saveGovernmentContactRecord,
    // Other Charge Holders
    createDealOtherChargeHolder,
    getDealOtherChargeHolder,
    getDealOtherChargeHolders,
    saveDealOtherChargeHolder,
    deleteDealOtherChargeHolder,
    // Fees
    getGlobalFees,
    getDealFees,
    saveDealFee,
    createDealFee,
    deleteDealFee,
    // Disbursements
    getGlobalDisbursements,
    getDealDisbursements,
    saveDealDisbursement,
    createDealDisbursement,
    deleteDealDisbursement,
    // Fire Insurance
    createDealFireInsurance,
    getDealFireInsurance,
    getAllDealFireInsurances,
    saveDealFireInsurance,
    deleteDealFireInsurance,
    // Fire Insurance Companies
    createGlobalFireInsuranceCompany,
    getGlobalFireInsuranceCompanies,
    getGlobalFireInsuranceCompany,
    saveGlobalFireInsuranceCompany,
    deleteGlobalFireInsuranceCompany,
    newRegionOnFireInsuranceCompany,
    deleteRegionFromFireInsuranceCompany,
    createDealFireInsuranceCompany,
    getDealFireInsuranceCompanies,
    getDealFireInsuranceCompany,
    saveDealFireInsuranceCompany,
    deleteDealFireInsuranceCompany,
    newRegionOnFireInsuranceCompanyRecord,
    deleteRegionFromFireInsuranceCompanyRecord,
    // Fire Insurance Brokerages
    createGlobalFireInsuranceBrokerage,
    getGlobalFireInsuranceBrokerage,
    getAllGlobalFireInsuranceBrokerages,
    saveGlobalFireInsuranceBrokerage,
    deleteGlobalFireInsuranceBrokerage,
    newRegionOnFireInsuranceBrokerage,
    deleteRegionFromFireInsuranceBrokerage,
    createDealFireInsuranceBrokerage,
    getDealFireInsuranceBrokerage,
    getAllDealFireInsuranceBrokerages,
    saveDealFireInsuranceBrokerage,
    deleteDealFireInsuranceBrokerage,
    newRegionOnFireInsuranceBrokerageRecord,
    deleteRegionFromFireInsuranceBrokerageRecord,
    // Fire Insurance Brokers
    newRegionOnFireInsuranceBroker,
    deleteRegionFromFireInsuranceBroker,
    getFireInsuranceBrokerRecord,
    getFireInsuranceBrokerRecords,
    getGlobalFireInsuranceBrokers,
    saveFireInsuranceBrokerRecord,
    newFireInsuranceBrokerRecord,
    newRegionOnFireInsuranceBrokerRecord,
    deleteRegionFromFireInsuranceBrokerRecord,
    // Spousal Relationships
    newSpousalRelationship,
    getSpousalRelationships,
    getSpousalRelationship,
    saveSpousalRelationship,
    deleteSpousalRelationship,
    // POA/EOE Relationships
    newPoaEoeRelationship,
    getPoaEoeRelationships,
    getPoaEoeRelationship,
    savePoaEoeRelationship,
    deletePoaEoeRelationship,
    // Affidavit
    newAffidavit,
    getAffidavit,
    getAffidavits,
    saveAffidavit,
    deleteAffidavit,
    // Title Insurance
    getDealTitleInsurance,
    saveDealTitleInsurance,
    // Title Insurance Premiums
    newDealTitleInsurancePremium,
    getDealTitleInsurancePremiums,
    getDealTitleInsurancePremium,
    saveDealTitleInsurancePremium,
    deleteDealTitleInsurancePremium,
    // Statutory Declarations
    newDealStatDec,
    getDealStatDec,
    getDealStatDecs,
    newClientStatDec,
    deleteClientStatDec,
    newGlobalStatDec,
    getGloablStatDecs,
    getGlobalStatDec,
    saveDealStatDec,
    saveGlobalStatDec,
    deleteDealStatDec,
    deleteGlobalStatDec,
    // Debts
    newDebt,
    getDebt,
    getDebts,
    saveDebt,
    deleteDebt,
    // Trust Ledgers
    newTrustLedger,
    getTrustLedger,
    getTrustLedgers,
    saveTrustLedger,
    deleteTrustLedger,
    saveTrustLedgerPayment,
    newTrustLedgerPaymentGroup,
    getTrustLedgerPaymentGroup,
    getTrustLedgerPaymentGroups,
    saveTrustLedgerPaymentGroup,
    deleteTrustLedgerPaymentGroup,
    // Condo Corporations
    getGlobalCondoCorporations,
    getGlobalCondoCorporation,
    newDealCondoCorporation,
    getDealCondoCorporations,
    getDealCondoCorporation,
    saveDealCondoCorporation,
    deleteDealCondoCorporation,
    // Property Management Companies
    getGlobalPropertyManagementCompanies,
    newDealPropertyManagementCompany,
    getDealPropertyManagementCompanies,
    getDealPropertyManagementCompany,
    saveDealPropertyManagementCompany,
    deleteDealPropertyManagementCompany,
    // Conditions
    getCondition,
    getConditions,
    saveCondition,
    newCondition,
    deleteCondition,
    // Signing Appointment
    getSigningAppointment,
    getSigningAppointments,
    saveSigningAppointment,
    deleteSigningAppointment,
    newSigningAppointment,
    newClientSigningAppointment,
    deleteClientSigningAppointment,
    // Legal Professionals
    createGlobalLegalProfessional,
    getGlobalLegalProfessional,
    getGlobalLegalProfessionals,
    saveGlobalLegalProfessional,
    deleteGlobalLegalProfessional,
    newRegionOnLegalProfessional,
    deleteRegionFromLegalProfessional,
    getGlobalFirmsForLegalProfessional,
    addGlobalFirmToLegalProfessional,
    removeGlobalFirmFromLegalProfessional,
    getGlobalFirmOfficesForLegalProfessional,
    addGlobalFirmOfficeToLegalProfessional,
    removeGlobalFirmOfficeFromLegalProfessional,
    createDealLegalProfessional,
    getDealLegalProfessional,
    getDealLegalProfessionals,
    saveDealLegalProfessional,
    deleteDealLegalProfessional,
    getDealFirmsForLegalProfessional,
    addDealFirmToLegalProfessional,
    removeDealFirmFromLegalProfessional,
    getDealFirmOfficesForLegalProfessional,
    addDealFirmOfficeToLegalProfessional,
    removeDealFirmOfficeFromLegalProfessional,
    setFirmForLegalProfessionalRecord,
    setOfficeForLegalProfessionalRecord,
    newRegionOnLegalProfessionalRecord,
    deleteRegionFromLegalProfessionalRecord,
    // Legal Firms
    createGlobalLegalFirm,
    getGlobalLegalFirms,
    getGlobalLegalFirm,
    saveGlobalLegalFirm,
    deleteGlobalLegalFirm,
    newRegionOnLegalFirm,
    deleteRegionFromLegalFirm,
    createDealLegalFirm,
    getDealLegalFirms,
    getDealLegalFirm,
    saveDealLegalFirm,
    deleteDealLegalFirm,
    newRegionOnLegalFirmRecord,
    deleteRegionFromLegalFirmRecord,
    // Legal Firm Offices
    createGlobalLegalFirmOffice,
    getGlobalLegalFirmOffice,
    getGlobalLegalFirmOffices,
    saveGlobalLegalFirmOffice,
    deleteGlobalLegalFirmOffice,
    createDealLegalFirmOffice,
    getDealLegalFirmOffice,
    getDealLegalFirmOffices,
    saveDealLegalFirmOffice,
    deleteDealLegalFirmOffice,
    // Regions
    getRegions,
    // Notes
    getNotes,
    getNote,
    newNote,
    saveNote,
    deleteNote,
    getDashboardUser,
    getDashboardUsers
};