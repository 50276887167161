import { CondoCorporationRequest } from "../../../types/UniversalSurvey/CondoCorporation/condoCorporationRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultCondoCorporationRequest: CondoCorporationRequest = {
    property_management_company_record_id: null,
    name: null,
    abbr_name: null,
    fee_for_status_certificate: null,
    tax_on_fee: null,
    general_address: defaultAddressRequest,
    phone_office: null,
    phone_cell: null,
    fax: null,
    email: null,
    self_managed: false
}