import { useEffect, useState, useContext } from "react";

import { Grid, styled } from "@mui/material";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import _ from "lodash";
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { H5 } from "../../../../../components/Common/Typography/index";
import TransactionPill from '../TransactionPills/TransactionPill';
import { PropertyRolesForTransactions, ClientRolesForTransactions } from "../../../../libs/resources/options";
import { EntityTypeForRoles, RoleTypes } from "../../../../libs/resources/enums/roles";
import { TransactionMappingDbToClean } from "../../../../libs/resources/mappings/transactions";
import { RoleMapping } from "../../../../libs/resources/mappings/roles";
import { Role } from "../../../../libs/types/UniversalSurvey/Roles/role";
import { addOrEditRole } from "../../../../context/UniversalSurvey/asyncActions/roles/roles";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";


type Props = Omit<ModalProps, "children"> & {
    entityLabel: string;
    title: string;
    entityId: number;
    entityType: EntityTypeForRoles;
    roleToEdit?: Role;
};

export default function RoleModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [selectedTransaction, setSelectedTransaction] = useState<string | undefined>(undefined);
    const [selectedRole, setSelectedRole] = useState<RoleTypes | undefined | "">("");

    useEffect(() => {
        const transaction = state.transactions.transactionList.find((transaction) => (
            transaction.id === props.roleToEdit?.record_id &&
            transaction.transaction_type === TransactionMappingDbToClean[props.roleToEdit?.record_type as unknown as keyof typeof TransactionMappingDbToClean]
        ))
        if (transaction) {
            setSelectedTransaction(transaction.label);
            setSelectedRole(RoleMapping[props.roleToEdit?.role as keyof typeof RoleMapping]);
        }
    }, [props.roleToEdit])

    function submit(): void {
        const transaction = state.transactions.transactionList.find((transaction) => transaction.label === selectedTransaction);
        if (transaction && selectedRole) {
            addOrEditRole(dispatch, String(state.deal.dealInfo?.id), state.roles.roles, props.roleToEdit, transaction, selectedRole, props.entityId);
        }

        handleModalClose();
    }

    function handleTransactionChange(newType: string | undefined): void {
        setSelectedRole(undefined);
        newType ? setSelectedTransaction(newType) : setSelectedTransaction(undefined);
    }

    function getRolesForTransactionType(): string[] {
        const transaction = state.transactions.transactionList.find((transaction) => transaction.label === selectedTransaction);
        if(transaction){
            switch(props.entityType) {
                case EntityTypeForRoles.Property:
                    return PropertyRolesForTransactions[transaction.transaction_type as keyof typeof PropertyRolesForTransactions];
                case EntityTypeForRoles.Client:
                    return ClientRolesForTransactions[transaction.transaction_type as keyof typeof ClientRolesForTransactions];
            }
        }
        return [];
    }

    function getTransactionsDisabled(): boolean[] {
        const transactionsDisabled: boolean[] = [];

        for (const transaction of state.transactions.transactionList) {
            let matchingRolesFound = 0;
            let maxRoles = 0;
            for (const role of state.roles.roles) {
                if (
                    role.record_id === transaction.id && TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean] === transaction.transaction_type &&
                    !(role.record_id === props.roleToEdit?.record_id && TransactionMappingDbToClean[props.roleToEdit.record_type as unknown as keyof typeof TransactionMappingDbToClean] === transaction.transaction_type)
                ) {
                    matchingRolesFound += 1;
                }
            }
            switch (transaction.transaction_type) {
                case TransactionTypes.TitleTransfer:
                    maxRoles = 2;
                    break;
                default:
                    maxRoles = 1;
                    break;
            }
            transactionsDisabled.push(matchingRolesFound >= maxRoles ? true : false);
        }
        return transactionsDisabled;
    }

    function getRolesDisabled(): boolean[] {
        const availableRoles: string[] = getRolesForTransactionType();
        const rolesDisabled: boolean[] = [];

        const transaction = state.transactions.transactionList.find((transaction) => transaction.label === selectedTransaction);
        for (const roleOption of availableRoles) {
            let matchingRoleFound = false
            for (const role of state.roles.roles ? state.roles.roles : []) {
                if (
                    transaction?.id === role.record_id &&
                    transaction?.transaction_type === TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean] &&
                    RoleMapping[role.role as keyof typeof RoleMapping] === roleOption &&
                    role !== props.roleToEdit
                ) {
                    matchingRoleFound = true;
                    break;
                }
            }
            rolesDisabled.push(matchingRoleFound);
        }
        return rolesDisabled;
    }

    function handleModalClose(): void {
        props.onClose ? props.onClose() : undefined;
        setSelectedTransaction(undefined);
        setSelectedRole("");
    }

    function editingRoleMatchesSelected() {
        const transaction = state.transactions.transactionList.find((transaction) => transaction.label === selectedTransaction);
        if (
            transaction?.transaction_type === TransactionMappingDbToClean[props.roleToEdit?.record_type as unknown as keyof typeof TransactionMappingDbToClean] &&
            transaction?.id === props.roleToEdit?.record_id &&
            selectedRole === RoleMapping[props.roleToEdit.role as keyof typeof RoleMapping]
        ) {
            return true;
        }
        return false;
    }

    return (
        <ModalBase
            open={props.open}
            onClose={() => handleModalClose()}
            onSubmit={submit}
            size="small"
            title={props.title}
            saveDisabled={!selectedRole || !selectedTransaction || editingRoleMatchesSelected()}
        >
            <>
                <H5 data-testid="role-modal-client-full-name">{`FOR: ${props.entityLabel.toUpperCase()}`}</H5>

                <PillContainer>
                    {state.roles.roles?.filter((role) => !_.isEqual(role, props.roleToEdit)).map((role, i) => (
                        <TransactionPill
                            type={TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean]}
                            label={role.label}
                            key={`role-pill-${i}`}
                            isPrimary={
                                role.record_id === state.deal.dealInfo?.primary_transaction_id &&
                                TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean] === state.deal.dealInfo?.primary_transaction_type
                            }
                        />
                    ))}
                </PillContainer>

                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Dropdown
                            value={selectedTransaction}
                            autoFocus
                            onChange={(e) => handleTransactionChange(e.target.value as string)}
                            placeholder="Select transaction..."
                            options={state.transactions.transactionList.map((transaction) => transaction.label ? transaction.label : transaction.transaction_type)}
                            label={{ text: "Transaction", isRequired: true }}
                            isHoverActionHidden={selectedTransaction ? false : true}
                            handleClear={() => handleTransactionChange(undefined)}
                            itemsDisabled={getTransactionsDisabled()}
                            disabledMessage={`${props.entityLabel} already has the maximum number of roles for this transaction.`}
                            data-testid="role-modal-transaction-drop-down"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Dropdown
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value as RoleTypes)}
                            placeholder="Select role..."
                            options={getRolesForTransactionType()}
                            label={{ text: "Role", isRequired: true }}
                            disabled={!selectedTransaction}
                            itemsDisabled={getRolesDisabled()}
                            disabledMessage={`Role already assigned to ${props.entityLabel}`}
                            isHoverActionHidden={selectedRole ? false : true}
                            handleClear={() => setSelectedRole(undefined)}
                            data-testid="role-modal-role-drop-down"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}

const PillContainer = styled('div')({
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    paddingBottom: 24,
    paddingTop: 10
});