
import { useState, useEffect } from "react";

// Conveyance Specific imports
import { getDeals } from "../service/axios/api";
import { Deals } from "../types/DealList/deals";
import { defaultDeals } from "../resources/defaults/frontend/defaultDeals";
import { sanitizeDealsResponse } from "../types/UniversalSurvey/utils/convertResponse";

const useDeals = (paginationLimit: number, currentPage: number, searchVal?: string, sortBy?: string, sortOrder?: boolean) => {
    // var declarations
    const [dealsData, setDealsData] = useState<Deals>(defaultDeals);
    const [isLoading, setIsLoading] = useState(false);

    // API-C call to fetch all deals
    useEffect(() => {
        setIsLoading(true);
        const skipResults: string = String(currentPage * paginationLimit);
        const pageLimit: string = String(paginationLimit);
        var query = new URLSearchParams({ limit: pageLimit, offset: skipResults });
        if (searchVal) query.set("search", searchVal);
        if (sortBy) query.set("sort_by", sortBy);
        if (sortOrder !== undefined) query.set("sort_order", sortOrder ? "desc" : "asc");

        getDeals(query)
        .then(function (response: any) {
            setDealsData(sanitizeDealsResponse(response.data));
        })
        .catch(function (error: any) {
            console.log(error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [paginationLimit, currentPage, searchVal, sortBy, sortOrder]);

    return { dealsData, isLoading };
}

export { useDeals };