import { ChargeHolderCompanyContact } from "../../../types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { VerificationStatus } from "../../enums/verificationStatus";

export const defaultChargeHolderCompanyContact: ChargeHolderCompanyContact = {
    id: undefined,
    verification_status: VerificationStatus.Unverified,
    role: undefined,
    name: undefined,
    email: undefined,
    phone_work: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    charge_holder_company_record: undefined,
    charge_holder_company_contact: undefined
}