import { useState, useEffect } from "react";
import { Body, BodyBold, Small } from "../Typography";
import { styled } from "@mui/material";
import BasicButton from "../Button/BasicButton";
import BasicTextInput from "../TextField/BasicTextInput";

type NoteCardProps = {
    id: string;
    title: string;
    subtitle?: string;
    content: string;
    userIsAuthor?: boolean;
    onDelete?: () => void;
    onSave: (value: string) => void;
    onlyEdit?: boolean;
    onlyEditPlaceholder?: string;
}

export default function NoteCard(props: NoteCardProps) {
    const [editing, setEditing] = useState<boolean>(false);
    const [editText, setEditText] = useState<string>("");
    const [savedContent, setSavedContent] = useState<string | undefined>(undefined);

    const {
        id,
        title,
        subtitle,
        content,
        userIsAuthor,
        onDelete,
        onSave,
        onlyEdit,
        onlyEditPlaceholder,
    } = props

    useEffect(() => {
        setSavedContent(undefined);
    }, [content])

    function onEdit() {
        setEditText(content);
        setEditing(true);
    }

    function onDiscard() {
        setEditText("");
        setEditing(false);
    }

    function handleSave() {
        setSavedContent(editText);
        onSave(editText);
        onDiscard();
    }

    function getContent(): string[] {
        if (savedContent) {
            return savedContent.split("\n");
        }
        return content.split("\n");
    }

    return (
        <Container>
            <TitleDiv>
                <BodyBold>{title}</BodyBold>
                <Small>{subtitle}</Small>
            </TitleDiv>
            {(editing || onlyEdit) ?
                <div>
                    <BasicTextInput
                        fullWidth
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                        multiline
                        placeholder={onlyEdit ? (onlyEditPlaceholder ?? "Add a note") : undefined}
                        label={{ text: "", inputId: `input-${id}`}}
                        style={{ marginTop: "-2.4rem" }}
                    />
                    <ButtonWrapper>
                        {!onlyEdit && <BasicButton
                            size="small"
                            typeOf="dismissive"
                            label={{ text: "Discard", inputId: `discard-${id}` }}
                            onClick={onDiscard}
                        />}
                        <BasicButton
                            size="small"
                            typeOf="primary"
                            label={{ text: "Save", inputId: `save-${id}` }}
                            onClick={handleSave}
                            disabled={!editText}
                        />
                    </ButtonWrapper>
                </div> :
                <div>
                    {getContent().map((line, i) => (
                        <StyledBody data-testid="note-content" emptyLine={!line} key={`body-${i}`}>
                            {line ? line : " "}
                        </StyledBody>
                    ))}
                    {userIsAuthor &&
                        <ButtonWrapper>
                            <BasicButton
                                size="small"
                                typeOf="secondary"
                                label={{ text: "Edit", inputId: `edit-${id}` }}
                                onClick={onEdit}
                            />
                            <BasicButton
                                size="small"
                                typeOf="destructive"
                                label={{ text: "Delete", inputId: `delete-${id}` }}
                                onClick={onDelete}
                            />
                        </ButtonWrapper>
                    }
                </div>
            }
        </Container>
    )
}

const Container = styled('div')({
    boxShadow: "0 0 0.5rem rgba(0,0,0,0.4)",
    width: "100%",
    borderRadius: "1rem",
    padding: "1rem 1.5rem 1.5rem 1.5rem",
    margin: "0rem 0rem 2rem 0rem"
})

const TitleDiv = styled('div')({
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
    padding: "0 0 1rem 0"
})

const ButtonWrapper = styled('div')({
    display: "flex",
    gap: "1rem",
    marginTop: "1.5rem"
})

const StyledBody = styled(Body, { shouldForwardProp: (prop) => prop !== "emptyLine"})<{
    emptyLine: boolean;
}>(({ emptyLine }) => ({
    whiteSpace: emptyLine ? "pre" : undefined,
    overflowWrap: "break-word"
}));