import { useContext, useEffect, useState } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";

import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { MortgageBrokerage, MortgageBrokerageErrorState } from "../../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { submitNewMortgageBrokerageRecord, saveMortgageBrokerage } from "../../../../context/UniversalSurvey/asyncActions/mortgageBrokerages";
import { defaultMortgageBrokerage } from "../../../../libs/resources/defaults/frontend/defaultMortgageBrokerage";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import FinancialInfoGrid from "../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { RegionListItem } from "../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultMortgageBrokerageErrorState } from "conveyance/libs/resources/defaults/errorStates/defaultMortgageBrokerageErrorState";
import { defaultFinancialInfoErrorState } from "conveyance/libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";

type Props = Omit<ModalProps, "children">;

export default function MortgageBrokerageModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext)
    const [brokerageObj, setBrokerageObj] = useState<MortgageBrokerage>({ ...defaultMortgageBrokerage });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [errorState, setErrorState] = useState<MortgageBrokerageErrorState>({ ...defaultMortgageBrokerageErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.mortgageBrokerages.editingMortgageBrokerageRecord && state.mortgageBrokerages.brokerageInfo) {
            setBrokerageObj(state.mortgageBrokerages.brokerageInfo);
            let tempRegionList: RegionListItem[] = [];
            if (state.mortgageBrokerages.brokerageInfo.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.mortgageBrokerages.brokerageInfo.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
        } else {
            setBrokerageObj({ ...defaultMortgageBrokerage });
            setSelectedRegionList([]);
        }
    }, [state.mortgageBrokerages.editingMortgageBrokerageRecord])

    function updateBrokerageInfo<
        K extends keyof MortgageBrokerage,
        V extends MortgageBrokerage[K]
    >(key: K, value: V): void {
        const tempBrokerageObj = { ...brokerageObj }
        tempBrokerageObj[key] = value;
        setBrokerageObj(tempBrokerageObj);
    }

    function submit() {
        const newMortgageBrokerageListItem = { label: brokerageObj.name, id: brokerageObj.id, isRecord: true };
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.mortgageBrokerages.editingMortgageBrokerageRecord) {
            saveMortgageBrokerage(dispatch, String(state.deal.dealInfo?.id), brokerageObj, regionList);
            dispatch({ type: actions.REPLACE_MORTGAGE_BROKERAGE_OPTION_WITH_RECORD, payload: { oldLenderId: brokerageObj.id, newOption: newMortgageBrokerageListItem, oldIsRecord: true }});
        } else {
            submitNewMortgageBrokerageRecord(dispatch, String(state.deal.dealInfo?.id), brokerageObj, regionList);
        }
        dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: newMortgageBrokerageListItem.id });
    }

    function discard() {
        dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKERAGE_RECORD, payload: false });
        setBrokerageObj({ ...defaultMortgageBrokerage });
        setSelectedRegionList([]);
        setErrorState({ ...defaultMortgageBrokerageErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        props.onClose ? props.onClose() : undefined;
    }
    
    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateBrokerageInfo("all_regions", true);
        } else {
            updateBrokerageInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function isSaveDisabled():boolean {
        if (!brokerageObj.name || !(selectedRegionList.length > 0) || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(false) : setSaveDisabled(true);
    }, [errorState, brokerageObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={props.title}
            saveDisabled={!saveDisabled}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveMortgageBrokerage])}
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <BasicTextInput
                        fullWidth
                        autoFocus
                        label={{ text: "Brokerage Name", inputId: "name", isRequired: true }}
                        placeholder="Brokerage's name"
                        value={brokerageObj.name ? brokerageObj.name : undefined}
                        onChange={(e) => updateBrokerageInfo("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        label={{ text: "Abbreviated Name", inputId: "abbr_name" }}
                        value={brokerageObj.abbr_name ? brokerageObj.abbr_name : undefined}
                        onChange={(e) => updateBrokerageInfo("abbr_name", e.target.value)}
                        placeholder="Abbreviated name"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ComboBox
                        options={state.regions.regions}
                        tokenField
                        value={selectedRegionList}
                        label={{ text: "Province(s) of Operation", inputId: "provinces", isRequired: true }}
                        onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                        placeholder="Province initials"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        value={brokerageObj.email ? brokerageObj.email : undefined}
                        onChange={(e) => updateBrokerageInfo("email", e.target.value)}
                        label={{ text: "Email", inputId: "email" }}
                        placeholder="Email address"
                        fieldType="email"
                        error={{
                            showError: true,
                            errorKey: "email",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={brokerageObj.phone}
                        onChange={(value) => updateBrokerageInfo("phone", value)}
                        label={{text: "Phone", inputId: "phone"}}
                        error={{
                            showError: true,
                            errorKey: "phone",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={brokerageObj.fax}
                        onChange={(value) => updateBrokerageInfo("fax", value)}
                        label={{text: "Fax", inputId: "fax"}}
                        error={{
                            showError: true,
                            errorKey: "fax",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddressGrid
                        address={brokerageObj.general_address}
                        setAddress={(value) => updateBrokerageInfo("general_address", value)}
                        id="general_address"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoGrid
                        financialInfo={brokerageObj.financial_info}
                        setFinancialInfo={(value) => updateBrokerageInfo("financial_info", value)}
                        errorState={financialInfoErrorState}
                        setErrorState={setFinancialInfoErrorState}
                        id="financial_info"
                        title="Financial Information"
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}