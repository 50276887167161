import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { getMortgageTransaction, getMortgageTransactions, saveNewMortgage, newMortgageTransaction, updateNewMortgageNetAdvanceAmount, createNewMortgageNetAdvanceAmount, removeNewMortgageNetAdvanceAmount } from "../../../libs/service/axios/api";
import { TabOption, TabOptions } from "../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { NewMortgage } from "../../../libs/types/UniversalSurvey/NewMortgage/newMortgage";
import { sanitizeMortgageTransactionResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeNewMortgageNetAdvanceAmountRequest, sanitizeNewMortgageRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultMortgageTransaction } from "../../../libs/resources/defaults/frontend/defaultMortgageTransaction";
import { getLenderInfo } from "./lenders";
import { getMortgageBrokerageRecordInfo } from "./mortgageBrokerages";
import { getMortgageBrokerRecordInfo } from "./mortgageBrokers";
import { Lender } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { formatMortgageType } from "../../../libs/utils/formatValues";
import { sortByKey } from "../../../libs/utils/arrays";
import { navigateURL } from "./deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { SimpleNewMortgageNetAdvanceAmount } from "../../../libs/types/UniversalSurvey/NewMortgageNetAdvanceAmount/simpleNewMortgageNetAdvanceAmount";
import { defaultSimpleNewMortgageNetAdvanceAmount } from "../../../libs/resources/defaults/frontend/defaultSimpleNewMortgageNetAdvanceAmount";
import { Loading } from "../../../libs/resources/enums/loading";

function switchToNewMortgageSection(dispatch: Dispatch<Record<string, any>>, dealId: string, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    let tempTabs: TabOptions = [];
    getMortgageTransactions(dealId)
        .then(function (response: any) {
            const mortgages = response.data;
            let promiseList = []
            for (const mortgage of mortgages) {
                promiseList.push(
                    getMortgageTransaction(dealId, mortgage.id).then(function (response) {
                        if (response) {
                            let name = response.data.new_mortgage_record?.lender_record?.abbr_name ?? response.data.new_mortgage_record?.lender_record?.name;
                            tempTabs.push({
                                title: getMortgageTabName(name),
                                subTitle: response.data.new_mortgage_record?.type ? formatMortgageType(response.data.new_mortgage_record?.type) : undefined,
                                priority: response.data.new_mortgage_record?.priority_after_closing,
                                itemId: response.data.id,
                                missingFields: 0
                            })
                        }
                    })
                )
            }
            Promise.all(promiseList).then(function () {
                dispatch({
                    type: actions.SET_TAB_OPTIONS,
                    payload: tempTabs
                });
                if (tempTabs.length > 0 && !hasEntityId) {
                    sortByKey(tempTabs, 'priority');
                    navigateURL(dispatch, dealId, "data-sheet", Sections.NewMortgage, String(tempTabs[0].itemId));
                }
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New mortgage tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function saveNewMortgageDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, newMortgageInfo: NewMortgage) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    saveNewMortgage(dealId, String(newMortgageInfo.id), sanitizeNewMortgageRequest(newMortgageInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save new mortgage: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addNewMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    newMortgageTransaction(dealId)
        .then(function (response: any) {
            handleAddingMortgageOnMortgageSection(dispatch, dealId, response.data.id)
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New deal mortgage: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function handleAddingMortgageOnMortgageSection(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number) {
    getMortgageTransaction(dealId, String(transactionId))
    .then(function (transactionResponse: any) {
        const newMortgageId = transactionResponse.data.new_mortgage_record.id;
        const tab: TabOption = {
            title: getMortgageTabName(undefined),
            itemId: transactionId,
            missingFields: 0,
        };
        dispatch({ type: actions.CREATE_MORTGAGE_TRANSACTION, payload: transactionId });
        dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
        navigateURL(dispatch, dealId, "data-sheet", Sections.NewMortgage, String(transactionId));
        dispatch({
            type: actions.SET_MORTGAGE_TRANSACTION_INFO,
            payload: {
                ...defaultMortgageTransaction,
                new_mortgage_record: { ...defaultMortgageTransaction.new_mortgage_record, id: newMortgageId },
                id: transactionId
            }
        });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgage transaction: ${error}`, type: AlertTypes.Error } });
    })
}

function changeSelectedNewMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageTransactionId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: undefined });
    dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: false });
    dispatch({ type: actions.SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED, payload: false });

    getMortgageTransaction(dealId, String(mortgageTransactionId))
        .then(function (response: any) {
            const cleanMortgage = sanitizeMortgageTransactionResponse(response.data);
            dispatch({ type: actions.SET_MORTGAGE_TRANSACTION_INFO, payload: cleanMortgage });
            const newMortgageRecord = response.data.new_mortgage_record;
            let name = cleanMortgage.new_mortgage_record?.lender_record?.abbr_name ?? cleanMortgage.new_mortgage_record?.lender_record?.name;
            updateMortgageTabName(dispatch, cleanMortgage.id, name);
            updateMortgageTabSubtitle(dispatch, cleanMortgage.id, cleanMortgage.new_mortgage_record?.type);
            updateMortgageTabPriority(dispatch, cleanMortgage.id, cleanMortgage.new_mortgage_record?.priority_after_closing);
            
            // Lender
            dispatch({ type: actions.SET_MORTGAGE_LENDER_ID, payload: newMortgageRecord.lender_record?.id });
            if (newMortgageRecord.lender_record?.id) {
                getLenderInfo(dispatch, dealId, newMortgageRecord.lender_record.id);

                const contactList = [];
                if(newMortgageRecord.lender_contact_list) {
                    for(const contact of newMortgageRecord.lender_contact_list) {
                        contactList.push({
                            id: contact.id,
                            label: contact.name,
                            isRecord: true,
                            role: contact.role
                        });
                    }
                }
                dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: contactList });
            }

            // Brokerage
            dispatch({ type: actions.SET_SELECTED_MORTGAGE_BROKERAGE, payload: newMortgageRecord.mortgage_brokerage_record?.id });
            dispatch({ type: actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_MORTGAGE, payload: newMortgageRecord.mortgage_brokerage_other_contact_list ?? [] });
            if (newMortgageRecord?.mortgage_brokerage_record?.id) {
                getMortgageBrokerageRecordInfo(dispatch, dealId, newMortgageRecord.mortgage_brokerage_record.id);
                if (newMortgageRecord.mortgage_broker_record?.id) {
                    getMortgageBrokerRecordInfo(dispatch, dealId, newMortgageRecord.mortgage_brokerage_record.id, newMortgageRecord.mortgage_broker_record.id);
                }
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New mortgage section content: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function updateMortgageTabName(dispatch: Dispatch<Record<string, any>>, mortgageTransactionId: number, name?: string) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_TITLE,
        payload: {
            title: getMortgageTabName(name),
            itemId: mortgageTransactionId,
            missingFields: 0,
        }
    });
}

function updateMortgageTabSubtitle(dispatch: Dispatch<Record<string, any>>, mortgageTransactionId: number, type: string | undefined) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_SUBTITLE,
        payload: {
            subtitle: type ? formatMortgageType(type) : undefined,
            itemId: mortgageTransactionId,
        }
    });
}

function updateMortgageTabPriority(dispatch: Dispatch<Record<string, any>>, mortgageTransactionId: number, priority: number | undefined) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_PRIORITY,
        payload: {
            itemId: mortgageTransactionId,
            priority: priority
        }
    })
}

function getMortgageTabName(name: string | undefined | null) {
    return name ?? "";
}

function updateMortgageLenderIdAndTabName(dispatch: Dispatch<Record<string, any>>, lenderId: number, name: string | undefined, currEntity: number) {
    dispatch({ type: actions.SET_MORTGAGE_LENDER_ID, payload: lenderId });
    updateMortgageTabName(dispatch, currEntity, name);
}

function updateMortgageTabNamesOnLenderSave(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, lenderInfo: Lender, updateSectionTabs?: boolean) {
    getMortgageTransactions(dealId)
        .then(function (response: any) {
            for (const mortgage of response.data) {
                if (mortgage.new_mortgage_record.lender_record?.id === lenderRecordId) {
                    if(updateSectionTabs) {
                        updateMortgageTabName(dispatch, mortgage.id, lenderInfo.name);
                    }
                    dispatch({ type: actions.UPDATE_MORTGAGE_TRANSACTION_LABEL, payload: { mortgageId: mortgage.id, abbr_name: lenderInfo.abbr_name, name: lenderInfo.name } })
                }
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get mortgages: ${error}`, type: AlertTypes.Error } });
        })
}

function saveNewMortgageNetAdvanceAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, newMortgageId: number, netAdvanceInfo: SimpleNewMortgageNetAdvanceAmount) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    updateNewMortgageNetAdvanceAmount(dealId, String(newMortgageId), String(netAdvanceInfo.id), sanitizeNewMortgageNetAdvanceAmountRequest(netAdvanceInfo))
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save new mortgage net advance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addNewMortgageNetAdvanceAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, newMortgageId: number) {
    createNewMortgageNetAdvanceAmount(dealId, String(newMortgageId))
        .then(function (response: any) {
            dispatch({ type: actions.ADD_NEW_MORTGAGE_NET_ADVANCE, payload: { ...defaultSimpleNewMortgageNetAdvanceAmount, id: response.data.id } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create new mortgage net advance: ${error}`, type: AlertTypes.Error } });
        })
}

function deleteNewMortgageNetAdvanceAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, newMortgageId: number, netAdvanceId: number) {
    removeNewMortgageNetAdvanceAmount(dealId, String(newMortgageId), String(netAdvanceId))
        .then(function () {
            dispatch({ type: actions.REMOVE_NEW_MORTGAGE_NET_ADVANCE, payload: netAdvanceId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Remove new mortgage net advance: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    switchToNewMortgageSection,
    saveNewMortgageDeal,
    addNewMortgage,
    handleAddingMortgageOnMortgageSection,
    changeSelectedNewMortgage,
    updateMortgageTabName,
    updateMortgageLenderIdAndTabName,
    updateMortgageTabNamesOnLenderSave,
    updateMortgageTabPriority,
    updateMortgageTabSubtitle,
    saveNewMortgageNetAdvanceAmount,
    addNewMortgageNetAdvanceAmount,
    deleteNewMortgageNetAdvanceAmount
}