import { Dispatch } from 'react';

import * as actions from "../actionTypes"
import { newDealClient, saveDealClient, createGuarantor, removeGuarantor } from '../../../libs/service/axios/api';
import { Client } from '../../../libs/types/UniversalSurvey/Client/client';
import { Guarantor } from '../../../libs/types/UniversalSurvey/Guarantor/guarantor';
import { SimpleGuarantor } from '../../../libs/types/UniversalSurvey/Guarantor/simpleGuarantor';
import { sanitizeClientRequest } from '../../../libs/types/UniversalSurvey/utils/convertRequest';
import { AlertTypes } from '../../../libs/resources/enums/alertTypes';
import { Loading } from '../../../libs/resources/enums/loading';


function createNewGuarantor(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageTransactionId: number, guarantorInfo: Guarantor) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGuarantor, isLoading: true } });
    let guarantorAddedSuccessfully = true;
    newDealClient(dealId)
        .then(function (response) {
            const clientId = response.data.client_id
            const clientObj: Partial<Client> = {
                id: clientId,
                first_name: guarantorInfo.first_name,
                last_name: guarantorInfo.last_name,
                email: guarantorInfo.email,
            }
            let cleanObj = JSON.parse(JSON.stringify(clientObj));
            let requestObj = sanitizeClientRequest(cleanObj);
            const promiseList: any[] = [];
            promiseList.push(saveDealClient(dealId, String(clientId), requestObj).catch(function (error: any) {
                guarantorAddedSuccessfully = false;
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving guarantor info: ${error}`, type: AlertTypes.Error } });
            }));

            promiseList.push(createGuarantor(dealId, String(mortgageTransactionId), String(clientId))
                .catch(function (error: any) {
                    guarantorAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding guarantor role: ${error}`, type: AlertTypes.Error } });
                }));

            Promise.all(promiseList).then(function () {
                if (guarantorAddedSuccessfully) {
                    dispatch({
                        type: actions.ADD_NEW_GUARANTOR,
                        payload: {
                            first_name: guarantorInfo.first_name ?? null,
                            last_name: guarantorInfo.last_name ?? null,
                            id: clientId
                        }
                    })
                }
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGuarantor, isLoading: false } });
            })
        }).catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGuarantor, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating new guarantor: ${error}`, type: AlertTypes.Error } });
        })
}

function removeGuarantorFromMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: number, guarantor: SimpleGuarantor) {
    removeGuarantor(dealId, String(mortgageId), String(guarantor.id))
        .then(function () {
            dispatch({ type: actions.REMOVE_GUARANTOR, payload: guarantor });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing guarantor: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    createNewGuarantor,
    removeGuarantorFromMortgage
}