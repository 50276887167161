import { useContext, useEffect, useState } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";

import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { FireInsuranceBroker, FireInsuranceBrokerErrorState } from "../../../../libs/types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import { defaultFireInsuranceBroker } from "../../../../libs/resources/defaults/frontend/defaultFireInsuranceBroker";
import { saveFireInsuranceBroker, submitNewFireInsuranceBrokerRecord } from "../../../../context/UniversalSurvey/asyncActions/fireInsuranceBrokers";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { allRegion } from "../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { RegionListItem } from "../../../../libs/types/DealList/deals";
import { defaultFireInsuranceBrokerErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFireInsuranceBrokerErrorState";

type Props = Omit<ModalProps, "children">;

export default function FireInsuranceBrokerModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext)
    const [brokerObj, setBrokerObj] = useState<FireInsuranceBroker>({ ...defaultFireInsuranceBroker });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [errorState, setErrorState] = useState<FireInsuranceBrokerErrorState>({ ...defaultFireInsuranceBrokerErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.fireInsuranceBrokers.editingBrokerRecord && state.fireInsuranceBrokers.brokerInfo) {
            setBrokerObj(state.fireInsuranceBrokers.brokerInfo);
            let tempRegionList: RegionListItem[] = [];
            if (state.fireInsuranceBrokers.brokerInfo.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.fireInsuranceBrokers.brokerInfo.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
        } else {
            setBrokerObj({ ...defaultFireInsuranceBroker });
            setSelectedRegionList([]);
        }
    }, [state.fireInsuranceBrokers.editingBrokerRecord]);
    
    function updateBrokerInfo<
        K extends keyof FireInsuranceBroker,
        V extends FireInsuranceBroker[K]
    >(key: K, value: V): void {
        const tempBrokerObj = { ...brokerObj };
        tempBrokerObj[key] = value;
        setBrokerObj(tempBrokerObj);
    }

    function submit() {
        const newFireInsuranceBrokerListItem = { label: brokerObj.name, id: brokerObj.id, isRecord: true };
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.fireInsuranceBrokers.editingBrokerRecord) {
            saveFireInsuranceBroker(dispatch, String(state.deal.dealInfo?.id), state.fireInsuranceBrokerages.selectedBrokerage?.id!, brokerObj, regionList);
            dispatch({ type: actions.REPLACE_FIRE_INSURANCE_BROKER_OPTION_WITH_RECORD, payload: { oldBrokerId: brokerObj.id, newOption: newFireInsuranceBrokerListItem, oldIsRecord: true }});
        } else {
            submitNewFireInsuranceBrokerRecord(dispatch, String(state.deal.dealInfo?.id), state.fireInsuranceBrokerages.selectedBrokerage?.id!, brokerObj, regionList);
        }
        dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: newFireInsuranceBrokerListItem.id });
    }

    function discard() {
        dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKER_RECORD, payload: false });
        setBrokerObj({ ...defaultFireInsuranceBroker });
        setSelectedRegionList([]);
        setErrorState({ ...defaultFireInsuranceBrokerErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateBrokerInfo("all_regions", true);
        } else {
            updateBrokerInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function isSaveDisabled(): boolean {
        if (!brokerObj.name || !(selectedRegionList.length > 0) || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, brokerObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={`${state.fireInsuranceBrokers.editingBrokerRecord ? "" : "New "} Fire Insurance Broker`}
            saveDisabled={saveDisabled}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveFireInsuranceBroker])}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        autoFocus
                        label={{text: "Name", inputId: "name", isRequired: true}}
                        placeholder="Broker's name"
                        value={brokerObj.name ? brokerObj.name : undefined}
                        onChange={(e) => updateBrokerInfo("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ComboBox
                        options={state.regions.regions}
                        tokenField
                        value={selectedRegionList}
                        label={{ text: "Province(s) of Operation", inputId: "provinces", isRequired: true }}
                        onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                        placeholder="Province initials"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        value={brokerObj.email ? brokerObj.email : undefined}
                        onChange={(e) => updateBrokerInfo("email", e.target.value)}
                        label={{text: "Email", inputId: "email"}}
                        placeholder="Broker's email"
                        fieldType="email"
                        error={{
                            showError: true,
                            setErrorState: setErrorState,
                            errorKey: "email",
                            errorState: errorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={brokerObj.phone_work}
                        onChange={(value) => updateBrokerInfo("phone_work", value)}
                        label={{text: "Work Phone", inputId: "phone_work"}}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "phone_work",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={brokerObj.phone_cell}
                        onChange={(value) => updateBrokerInfo("phone_cell", value)}
                        label={{text: "Cell Phone", inputId: "phone_cell"}}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "phone_cell",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={brokerObj.fax}
                        onChange={(value) => updateBrokerInfo("fax", value)}
                        label={{text: "Fax", inputId: "fax"}}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "fax",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}