import { Government } from "../../../types/UniversalSurvey/Government/government";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultGovernment: Government = {
    verificationStatus: VerificationStatus.Unverified,
    level: undefined,
    id: -1,
    department: undefined,
    name: undefined,
    abbr_name: undefined,
    general_address: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    general_email: undefined,
    fax: undefined,
    website: undefined,
    date_created: undefined,
    date_updated: undefined,
    government: undefined
}