import * as actions from "../actionTypes"

import { DocumentStatus } from "../../../libs/resources/enums/documents/documentStatus";
import { Document } from "../../../libs/types/UniversalSurvey/Documents/baseDocument";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { DocumentType } from "../../../libs/resources/enums/documents/documentType";
import { findAndRemove } from "../../../libs/utils/arrays";
import { MortgageTransaction } from "../../../libs/types/UniversalSurvey/MortgageTransaction/mortgageTransaction";
import { SimpleLien } from "../../../libs/types/UniversalSurvey/ExistingLien/simpleLien";
import { SimpleSigningAppointment } from "../../../libs/types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";
import { StatDecDocType } from "../../../libs/resources/enums/statDecDocType";
import { RecordType } from "../../../libs/resources/enums/recordTypes";
import { RoleTypesDB } from "../../../libs/resources/enums/roles";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { TransactionTypes } from "conveyance/libs/resources/enums/transactions";
import { DocListType } from "../../../libs/resources/enums/documents/docListType";

export type DocumentsState = {
    discoveryStatus: DocumentStatus | undefined;
    documentList: Document[];
    suggestedDocList: Document[];
    htmlInViewerBody: string | undefined;
    htmlInViewerFull: string | undefined;
    previewBeingViewed: number | undefined;
    pdfLink: string | undefined;
    docInViewerName: string | undefined;
    isDocumentPreparing: boolean;
    uploadedDocsLoading: string[];
}

export const defaultDocumentsState: DocumentsState = {
    discoveryStatus: undefined,
    documentList: [],
    suggestedDocList: [],
    htmlInViewerBody: undefined,
    htmlInViewerFull: undefined,
    previewBeingViewed: undefined,
    pdfLink: undefined,
    docInViewerName: undefined,
    isDocumentPreparing: false,
    uploadedDocsLoading: []
}

export function documentsReducer(state: DocumentsState, action: Record<string, any>): DocumentsState {
    switch (action.type) {
        case actions.SET_DOCUMENT_LIST:
            return { ...state, documentList: action.payload };
        case actions.SET_SUGGESTED_DOCUMENT_LIST:
            return { ...state, suggestedDocList: action.payload };
        case actions.SET_DOC_DISCOVERY_STATUS:
            return { ...state, discoveryStatus: action.payload };
        case actions.DELETE_DOCUMENT:
            return { ...state, documentList: findAndRemove([...state.documentList], ["id"], [action.payload]) };
        case actions.UPDATE_DOCUMENT:
            const tempDocs = [...state.documentList];
            const docToUpdate = tempDocs.find((doc) => doc.id === action.payload.id);
            if (docToUpdate) {
                const newDoc = { ...docToUpdate };
                for (const key of Object.keys(action.payload)) {
                    newDoc[key as keyof typeof newDoc] = action.payload[key] ?? undefined;
                }
                tempDocs[tempDocs.indexOf(docToUpdate)] = newDoc;
            }
            return { ...state, documentList: tempDocs };
        case actions.TOGGLE_FOLDER_OPEN:
            const tempFolders = [...state.documentList];
            const folderToToggle = tempFolders.find((folder) => folder.id === action.payload);
            if (folderToToggle) {
                tempFolders[tempFolders.indexOf(folderToToggle)].open = !tempFolders[tempFolders.indexOf(folderToToggle)].open;
            }
            return { ...state, documentList: tempFolders };
        case actions.ADD_TO_DOC_LIST:
            return { ...state, documentList: [...state.documentList, action.payload] };
        case actions.SET_HTML_IN_VIEWER_BODY:
            return { ...state, htmlInViewerBody: action.payload };
        case actions.SET_HTML_IN_VIEWER_FULL:
            return { ...state, htmlInViewerFull: action.payload };
        case actions.SET_PREVIEW_BEING_VIEWED:
            return { ...state, previewBeingViewed: action.payload };
        case actions.SET_PDF_LINK:
            return { ...state, pdfLink: action.payload };
        case actions.SET_DOC_IN_VIEWER_NAME:
            return { ...state, docInViewerName: action.payload };
        case actions.SET_IS_DOCUMENT_PREPARING:
            return { ...state, isDocumentPreparing: action.payload };
        case actions.ADD_UPLOADED_DOC_LOADING:
            return { ...state, uploadedDocsLoading: [...state.uploadedDocsLoading, action.payload] };
        case actions.REMOVE_UPLOADED_DOC_LOADING:
            const tempUploaded = [...state.uploadedDocsLoading];
            const matchingName = tempUploaded.find((name) => name === action.payload);
            if (matchingName) tempUploaded.splice(tempUploaded.indexOf(matchingName), 1);
            return { ...state, uploadedDocsLoading: tempUploaded };
        // Contexts
        case actions.SET_CLIENTS_CONTEXT:
            return {
                ...state,
                documentList: action.payload.docType !== DocListType.Suggested ? expandClientContext(state.documentList, action.payload.clients, action.payload.deal) : state.documentList,
                suggestedDocList: action.payload.docType !== DocListType.Deal ? expandClientContext(state.suggestedDocList, action.payload.clients, action.payload.deal) : state.suggestedDocList,
            };
        case actions.SET_MORTGAGE_CONTEXT:
            return {
                ...state,
                documentList: action.payload.docType !== DocListType.Suggested ? expandMortgageContext(state.documentList, action.payload.mortgages) : state.documentList,
                suggestedDocList: action.payload.docType !== DocListType.Deal ? expandMortgageContext(state.suggestedDocList, action.payload.mortgages) : state.suggestedDocList,
            };
        case actions.SET_LIEN_CONTEXT:
            return {
                ...state,
                documentList: action.payload.docType !== DocListType.Suggested ? expandLienContext(state.documentList, action.payload.liens) : state.documentList,
                suggestedDocList: action.payload.docType !== DocListType.Deal ? expandLienContext(state.suggestedDocList, action.payload.liens) : state.suggestedDocList,
            };
        case actions.SET_SIGNING_APPOINTMENT_CONTEXT:
            return {
                ...state,
                documentList: action.payload.docType !== DocListType.Suggested ? expandSigningAppointmentContext(state.documentList, action.payload.appts) : state.documentList,
                suggestedDocList: action.payload.docType !== DocListType.Deal ? expandSigningAppointmentContext(state.suggestedDocList, action.payload.appts) : state.suggestedDocList,
            };
        default:
            return state;
    }
}

// CONTEXT EXPANDING
// Documents referencing clients
function expandClientContext(documentList: Document[], clientList: SimpleClient[], deal: Deal): Document[] {
    const tempDocList = [...documentList];
    const dealClientList: SimpleClient[] = [...clientList];
    for (const document of tempDocList) {
        switch (document.type) {

            case DocumentType.StatDec:
                let tempClientList: SimpleClient[] = [];
                if (document?.context.type === StatDecDocType.StatDec) {
                    if (document?.context?.clients) {
                        // Assigning simple clients to context
                        for (const clientId of document.context.clients) {
                            let matchingClient = dealClientList.find((client) => client.id === clientId);
                            if (matchingClient) tempClientList.push(matchingClient);
                        }
                    }
                } else if (document?.context.type === StatDecDocType.Blank) {
                    for (const client of dealClientList) {
                        switch (deal.primary_transaction_type) {
                            case TransactionTypes.Mortgage:
                                if (client.roles?.some((role) => role.record_type === RecordType.Mortgage && role.role === RoleTypesDB.Borrower && role.record_id === deal.primary_transaction_id)) {
                                    tempClientList.push(client);
                                }
                                break;
                            default:
                                break;
                        }
                        
                    }
                }
                // Attaching simple clients to document context
                const index = tempDocList.indexOf(document);
                tempDocList[index].context.client_records = tempClientList;
                break;
            
            // TODO: handle more unqiue documents using a version of 'clients' in context
            
            default:
                break;
        }
    }
    return tempDocList;
}

// Documents referencing mortgages
function expandMortgageContext(documentList: Document[], mortgageList: MortgageTransaction[]): Document[] {
    const tempDocList = [...documentList];
    for (const document of tempDocList) {
        switch (document.type) {
            case DocumentType.GuarantorWaiverOfIla:
                addMortgageRecordToContext(document, mortgageList);
                break;
            case DocumentType.GuarantorGuaranteeOfMortgage:
                addMortgageRecordToContext(document, mortgageList);
                break;
            case DocumentType.ConsentToActConflictOfMortgage:
                addMortgageRecordToContext(document, mortgageList);
                break;
            case DocumentType.LetterToMortgageeNewMortgage:
                addMortgageRecordToContext(document, mortgageList);
                break;
                
            // TODO: handle more unqiue documents using a version of 'mortgage' in context
            
            default:
                break;
        }
    }
    return tempDocList;
}

function addMortgageRecordToContext(document: Document, mortgageList: MortgageTransaction[]) {
    if (document?.context?.mortgage_id) {
        const matchingMortgage = mortgageList.find((mortgage) => mortgage.id === document.context.mortgage_id);
        if (matchingMortgage) document.context.mortgage_record = matchingMortgage;
    }
}

// Documents referencing liens
function expandLienContext(documentList: Document[], lienList: SimpleLien[]): Document[] {
    const tempDocList = [...documentList];
    for (const document of tempDocList) {
        switch (document.type) {
            case DocumentType.LetterToMortgageeExistingMortgage:
                const matchingLien = lienList.find((lien) => lien.id === document.context.existing_mortgage_id);
                if (matchingLien) {
                    document.context.lien = matchingLien;
                }
                break;
                
            // TODO: handle more unqiue documents using a version of 'lien' in context
            
            default:
                break;
        }
    }
    return tempDocList;
}

// Documents referencing signing appointments
function expandSigningAppointmentContext(documentList: Document[], apptList: SimpleSigningAppointment[]): Document[] {
    const tempDocList = [...documentList];
    for (const document of tempDocList) {
        switch (document.type) {
            case DocumentType.VerificationOfIdentityAgreement:
                const matchingAppt = apptList.find((appt) => appt.id === document.context.signing_appointment_id);
                if (matchingAppt) {
                    document.context.signing_appointment_record = matchingAppt;
                }
                break;
                
            // TODO: handle more unqiue documents using a version of 'signing_appointment' in context
            
            default:
                break;
        }
    }
    return tempDocList;
}