import { CreatePaymentGroupRequest } from "../../../types/UniversalSurvey/TrustLedger/PaymentGroup/createPaymentGroupRequest";

export const defaultCreatePaymentGroupRequest: CreatePaymentGroupRequest = {
    recipient_type: null,
    third_party_transfer: null,
    wiring_fee: null,
    lender_id: null,
    government_id: null,
    mortgage_brokerage_id: null,
    client_id: null,
    other_charge_holder_id: null,
    company_id: null,
    lawyer_id: null,
    payment_method: null
}