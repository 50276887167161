import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { Condition } from "../../../libs/types/UniversalSurvey/Condition/condition";
import { SimpleCondition } from "../../../libs/types/UniversalSurvey/Condition/simpleCondition";
import { defaultSimpleCondition } from "../../../libs/resources/defaults/frontend/defaultSimpleCondition";
import { Lien } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { Undertaking } from "../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { StatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { Debt } from "../../../libs/types/UniversalSurvey/Debts/debt";
import { Affidavit } from "../../../libs/types/UniversalSurvey/Affivadit/affidavit";

export type ConditionsState = {
    conditionBeingEdited: Condition | undefined;
    conditionsInDeal: SimpleCondition[];
    conditionLien: Lien | undefined;
    conditionUndertaking: Undertaking | undefined;
    conditionStatDec: StatDec | undefined;
    conditionDebt: Debt | undefined;
    conditionAffidavit: Affidavit | undefined;
}

export const defaultConditionsState: ConditionsState = {
    conditionBeingEdited: undefined,
    conditionsInDeal: [],
    conditionLien: undefined,
    conditionUndertaking: undefined,
    conditionAffidavit: undefined,
    conditionDebt: undefined,
    conditionStatDec: undefined
}

export function conditionsReducer(state: ConditionsState, action: Record<string, any>): ConditionsState {
    switch (action.type) {
        case actions.SET_CONDITION_BEING_EDITED:
            return { ...state, conditionBeingEdited: action.payload };
        case actions.ADD_CONDITION:
            const conditionsCopy = [...state.conditionsInDeal, action.payload];
            return { ...state, conditionsInDeal: conditionsCopy };
        case actions.SET_CONDITIONS_IN_DEAL:
            return { ...state, conditionsInDeal: action.payload }
        case actions.REMOVE_CONDITION:
            return { ...state, conditionsInDeal: findAndRemove([...state.conditionsInDeal], ["id"], [action.payload]) };
        case actions.SET_CONDITION_LIEN:
            return { ...state, conditionLien: action.payload };
        case actions.SET_CONDITION_UNDERTAKING:
            return { ...state, conditionUndertaking: action.payload };
        case actions.SET_CONDITION_AFFIDAVIT:
            return { ...state, conditionAffidavit: action.payload };
        case actions.SET_CONDITION_STAT_DEC:
            return { ...state, conditionStatDec: action.payload };
        case actions.SET_CONDITION_DEBT:
            return { ...state, conditionDebt: action.payload };
        case actions.UPDATE_CONDITION:
            const tempConditions = [...state.conditionsInDeal];
            const matchingCondition = tempConditions.find((condition) => condition.id === action.payload.condition.id);
            if(matchingCondition) {
                const newCondition: any = { ...defaultSimpleCondition };
                for(const key of Object.keys(newCondition)) {
                    newCondition[key as keyof SimpleCondition] = action.payload.condition[key];
                }
                tempConditions[tempConditions.indexOf(matchingCondition)] = newCondition;
            }
            return { ...state, conditionsInDeal: tempConditions };
        default:
            return state;
    }
}