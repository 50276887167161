import { SimpleDisbursement } from "../../../types/UniversalSurvey/Disbursements/simpleDisbursement";
import { DisbursementTypes, DisbursementSource } from "../../enums/disbursements";

export const defaultSimpleDisbursement: SimpleDisbursement = {
    id: -1,
    type: DisbursementTypes.Taxable,
    source: DisbursementSource.Manual,
    taxable_amount: undefined,
    non_taxable_amount: undefined,
    taxable_desc: undefined,
    taxable_acc_code: undefined,
    non_taxable_desc: undefined,
    non_taxable_acc_code: undefined,
    date_created: undefined
};