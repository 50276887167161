import { useState, useEffect, useRef } from "react";

export default function useSingleDoubleClick(
    toggle: boolean,
    singleFn: (() => void) | undefined,
    doubleFn: () => void
) {
    const [doubleClickTimer, setDoubleClickTimer] = useState<boolean | ReturnType<typeof setTimeout>>(false);
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            if (doubleClickTimer) { // handle double click
                if(typeof doubleClickTimer !== "boolean") clearTimeout(doubleClickTimer);
                setDoubleClickTimer(false);
                doubleFn();
            } else { // handle single click
                setDoubleClickTimer(setTimeout(() => {
                    setDoubleClickTimer(false);
                    singleFn ? singleFn() : undefined;
                }, 400))
            }
        }
        didMountRef.current = true;
    }, [toggle])
}