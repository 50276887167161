import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    addDealFirmToLegalProfessional,
    createDealLegalFirm,
    deleteRegionFromLegalFirmRecord,
    getDealFirmsForLegalProfessional,
    getDealLegalFirm,
    getDealLegalFirms,
    getGlobalFirmsForLegalProfessional,
    getGlobalLegalFirms,
    newRegionOnLegalFirmRecord,
    saveDealLegalFirm,
    setFirmForLegalProfessionalRecord
} from "../../../libs/service/axios/api";
import { sanitizeLegalFirmResponse, sanitizeSimpleGlobalLegalFirmsResponse, sanitizeSimpleLegalFirmsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { SimpleGlobalLegalFirm, SimpleLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { LegalFirm, LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { sanitizeLegalFirmRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultSimpleLegalFirm } from "../../../libs/resources/defaults/frontend/defaultSimpleLegalFirm";
import { setOfficeRecordForLegalProfessional } from "./legalFirmOffices";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { Loading } from "../../../libs/resources/enums/loading";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { defaultSimpleGlobalLegalFirm } from "conveyance/libs/resources/defaults/frontend/defaultSimpleGlobalLegalFirm";

function getLegalProfessionalFirmOptions(dispatch: Dispatch<Record<string, any>>, dealId: string, professional: LegalProfessionalListItem | undefined, type: LegalProfessionalOptions) {
    if (professional) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: type === LegalProfessionalOptions.DealSigner ? Loading.SignerFirmList : type === LegalProfessionalOptions.DealLawyer ? Loading.LawyerFirmList : Loading.LenderLawyerFirmList, isLoading: true } });
        getDealFirmsForLegalProfessional(dealId, String(professional.id))
            .then(function (response: any) {
                const cleanDealFirms = sanitizeSimpleLegalFirmsResponse(response.data);
                if (professional.legal_professional) {
                    // TODO: Update with legal professional admin panel
                    // getGlobalFirmsForLegalProfessional(String(professional.legal_professional.id))
                    //     .then(function (globalResponse: any) {
                    //         const cleanGlobalFirms = sanitizeSimpleLegalFirmsResponse(globalResponse.data.legal_firms);
                    //         const organizedData = organizeLegalFirmListResponse(cleanDealFirms, cleanGlobalFirms);
                    //         setOptionList(dispatch, organizedData, type);
                    //         selectFirmIfOnlyOne(dispatch, dealId, organizedData, professional.id, type);
                    //     })
                    //     .catch(function (error: any) {
                    //         dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global legal firms: ${error}`, type: AlertTypes.Error } });
                    //     })
                } else {
                    const organizedData = organizeLegalFirmListResponse(cleanDealFirms, []);
                    setOptionList(dispatch, organizedData, type);
                    selectFirmIfOnlyOne(dispatch, dealId, organizedData, professional.id, type);
                }
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm records: ${error}`, type: AlertTypes.Error } });
            })
            .finally(() => {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: type === LegalProfessionalOptions.DealSigner ? Loading.SignerFirmList : type === LegalProfessionalOptions.DealLawyer ? Loading.LawyerFirmList : Loading.LenderLawyerFirmList, isLoading: false } });
            })
    } else {
        setOptionList(dispatch, [], type);
    }
}

function setOptionList(dispatch: Dispatch<Record<string, any>>, organizedData: LegalFirmListItem[], type: LegalProfessionalOptions) {
    if (type === LegalProfessionalOptions.DealSigner) {
        dispatch({ type: actions.SET_SIGNER_FIRM_OPTION_LIST, payload: organizedData });
    } else if (type === LegalProfessionalOptions.DealLawyer) {
        dispatch({ type: actions.SET_LAWYER_FIRM_OPTION_LIST, payload: organizedData });
    } else {
        dispatch({ type: actions.SET_LENDER_LAWYER_FIRM_OPTION_LIST, payload: organizedData });
    }
}

function selectFirmIfOnlyOne(dispatch: Dispatch<Record<string, any>>, dealId: string, optionList: LegalFirmListItem[], professionalId: number, type: LegalProfessionalOptions) {
    if (optionList.length === 1) {
        if (optionList[0].isRecord) {
            let record = { ...optionList[0] };
            delete record.isRecord;
            delete record.label;
            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_FIRM, payload: record });
            } else {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_FIRM, payload: { isSigner: type === LegalProfessionalOptions.DealSigner, record } });
            }
        } else {
            createLegalFirmRecordFromGlobal(
                dispatch,
                dealId,
                type === LegalProfessionalOptions.DealLawyer ? professionalId : undefined,
                type === LegalProfessionalOptions.DealSigner ? professionalId : undefined,
                type === LegalProfessionalOptions.LenderLawyer ? professionalId : undefined,
                optionList[0],
                type
            );
        }
    }
}

function getAllLegalFirmOptions(dispatch: Dispatch<Record<string, any>>, dealId: string, query?: URLSearchParams) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AllFirmList, isLoading: true } });
    getDealLegalFirms(dealId)
        .then(function (response: any) {
            const legalFirmRecordsData = sanitizeSimpleLegalFirmsResponse(response.data);
            let globalLegalFirmsData: SimpleGlobalLegalFirm[] = [];
            if (query) {
                getGlobalLegalFirms(query)
                .then(function (globalResponse: any) {
                    globalLegalFirmsData = sanitizeSimpleGlobalLegalFirmsResponse(globalResponse.data.legal_firms);
                    dispatch({ type: actions.SET_ALL_LEGAL_FIRM_OPTIONS, payload: organizeLegalFirmListResponse(legalFirmRecordsData, globalLegalFirmsData) });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global legal firms: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AllFirmList, isLoading: false } });
                })
            } else {
                dispatch({ type: actions.SET_ALL_LEGAL_FIRM_OPTIONS, payload: organizeLegalFirmListResponse(legalFirmRecordsData, globalLegalFirmsData) });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm records: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            if (!query) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AllFirmList, isLoading: false } });
        })
}

function organizeLegalFirmListResponse(dealList: SimpleLegalFirm[], globalList: SimpleGlobalLegalFirm[]): LegalFirmListItem[] {
    let cleanList: LegalFirmListItem[] = [];
    let isRecordList: number[] = [];

    for (const firm of dealList) {
        if (firm.legal_firm?.id) {
            isRecordList.push(firm.legal_firm.id);
        }
        cleanList.push({
            ...firm,
            label: firm.name,
            isRecord: true,
        });
    }

    for (const firm of globalList) {
        if (!isRecordList.includes(firm.id)) {
            cleanList.push({
                ...firm,
                label: firm.name,
                isRecord: false,
                legal_firm: undefined
            });
        }
    }

    return cleanList;
}

function setFirmRecordForLegalProfessional(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, firmRecord: LegalFirmListItem | null, type: LegalProfessionalOptions) {
    setFirmForLegalProfessionalRecord(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), firmRecord ? firmRecord.id : null)
        .then(function () {
            let recordData;
            if (firmRecord) {
                recordData = { ...firmRecord };
                delete recordData.isRecord;
                delete recordData.label;
            } else {
                recordData = undefined;
            }

            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_FIRM, payload: recordData });
            } else {
                if (lawyerId === signerId) {
                    dispatch({ type: actions.SET_DEAL_PROFESSIONAL_FIRM, payload: { record: recordData, isSigner: true } });
                    dispatch({ type: actions.SET_DEAL_PROFESSIONAL_FIRM, payload: { record: recordData, isSigner: false } });
                } else {
                    dispatch({ type: actions.SET_DEAL_PROFESSIONAL_FIRM, payload: { record: recordData, isSigner: type === LegalProfessionalOptions.DealSigner } });
                }
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Setting firm for legal professional: ${error}`, type: AlertTypes.Error } });
        })
}

async function createLegalFirmRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, firm: LegalFirmListItem, type: LegalProfessionalOptions): Promise<LegalFirmListItem | undefined> {
    return new Promise<LegalFirmListItem | undefined>((resolve) => {
        createDealLegalFirm(dealId, firm.id)
        .then(function (response: any) {
            addDealFirmToLegalProfessional(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), String(response.data.id))
                .then(function () {
                    const newListItem: LegalFirmListItem = {
                        ...firm,
                        isRecord: true,
                        label: firm.name,
                        legal_firm: {
                            ...defaultSimpleGlobalLegalFirm,
                            id: firm.id,
                            name: firm.name
                        },
                        id: response.data.id
                    };
                    dispatch({ type: actions.REPLACE_LEGAL_FIRM_OPTION_WITH_RECORD, payload: { oldId: firm.id, newOption: newListItem, oldIsRecord: false } });
                    setFirmRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, newListItem, type);
                    resolve(newListItem);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Add firm to legal professional record: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal firm record: ${error}`, type: AlertTypes.Error } });
            resolve(undefined);
        })
    })
    
}

function setLegalFirmRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, recordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalFirmModal, isLoading: true } });
    getDealLegalFirm(dealId, String(recordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_LEGAL_FIRM_BEING_EDITED, payload: sanitizeLegalFirmResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalFirmModal, isLoading: false } });
        })
}

function saveLegalFirmRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, data: LegalFirm, type: LegalProfessionalOptions, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: true } });
    saveDealLegalFirm(dealId, String(data.id), sanitizeLegalFirmRequest(data))
        .then(async function () {
            const dataCopy = { ...data };
            for (const key of Object.keys(dataCopy)) {
                if (!Object.keys(defaultSimpleLegalFirm).includes(key)) {
                    delete dataCopy[key as keyof typeof dataCopy];
                }
            }
            await updateLegalFirmRecordRegions(dispatch, dealId, data, regionList);
            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_FIRM, payload: dataCopy });
            } else if (type === LegalProfessionalOptions.DealLawyer || type === LegalProfessionalOptions.DealSigner) {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_FIRM, payload: { record: dataCopy, isSigner: type === LegalProfessionalOptions.DealSigner } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal firm record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: false } });
        })
}

function submitNewLegalFirmRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, data: LegalFirm, type: LegalProfessionalOptions, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: true } });
    createDealLegalFirm(dealId, null)
        .then(function (response: any) {
            addDealFirmToLegalProfessional(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), String(response.data.id))
                .then(function () {
                    saveDealLegalFirm(dealId, String(response.data.id), sanitizeLegalFirmRequest(data))
                        .then(async function () {
                            const simpleRecord: SimpleLegalFirm = {
                                ...defaultSimpleLegalFirm,
                                id: response.data.id,
                                name: data.name,
                                legal_firm: undefined,
                            }
                            await addLegalFirmRecordRegions(dispatch, dealId, response.data.id, regionList);
                            const listItem = { ...simpleRecord, isRecord: true, label: data.name };
                            dispatch({ type: actions.ADD_TO_PROFESSIONAL_LEGAL_FIRM_OPTIONS, payload: { record: listItem, signerId, lawyerId, lenderLawyerId, type } });
                            setFirmRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, listItem, type);
                            
                            // clearing offices
                            if (type === LegalProfessionalOptions.LenderLawyer) {
                                dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE_OPTION_LIST, payload: [] });
                            } else {
                                if (lawyerId === signerId) {
                                    dispatch({ type: actions.SET_SIGNER_OFFICE_OPTION_LIST, payload: [] });
                                    dispatch({ type: actions.SET_LAWYER_OFFICE_OPTION_LIST, payload: [] });
                                    setOfficeRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, response.data.id, null, type === LegalProfessionalOptions.DealLawyer ? LegalProfessionalOptions.DealSigner : LegalProfessionalOptions.DealLawyer);
                                } else if (type === LegalProfessionalOptions.DealSigner) {
                                    dispatch({ type: actions.SET_SIGNER_OFFICE_OPTION_LIST, payload: [] });
                                } else {
                                    dispatch({ type: actions.SET_LAWYER_OFFICE_OPTION_LIST, payload: [] });
                                }
                            }
                            setOfficeRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, response.data.id, null, type);
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal firm record during creation: ${error}`, type: AlertTypes.Error } });
                        })
                        .finally(() => {
                            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: false } });
                        })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Add firm to legal professional record: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirm, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal firm record: ${error}`, type: AlertTypes.Error } });
        })
}

async function addLegalFirmRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, firmId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnLegalFirmRecord(dealId, String(firmId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to legal firm record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateLegalFirmRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, firmInfo: LegalFirm, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (firmInfo.regions) {
        for (const region of firmInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnLegalFirmRecord(dealId, String(firmInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to legal firm record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromLegalFirmRecord(dealId, String(firmInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the legal firm record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on legal firm record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}


export {
    getLegalProfessionalFirmOptions,
    getAllLegalFirmOptions,
    setFirmRecordForLegalProfessional,
    createLegalFirmRecordFromGlobal,
    setLegalFirmRecordBeingEdited,
    saveLegalFirmRecord,
    submitNewLegalFirmRecord
}