import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { CondoCorporation, CondoCorporationErrorState } from "../../../../libs/types/UniversalSurvey/CondoCorporation/condoCorporation";
import { defaultCondoCorporation } from "../../../../libs/resources/defaults/frontend/defaultCondoCorporation";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import { createNewCondoCorporationRecord, saveCondoCorporation } from "../../../../context/UniversalSurvey/asyncActions/condoCorporations";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import { calculateTaxAmount } from "../../../../libs/utils/taxes";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultCondoCorporationErrorState } from "../../../../libs/resources/defaults/errorStates/defaultCondoCorporationErrorState";

type Props = Omit<ModalProps, "children">;

export default function CondoCorporationModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [condoCorpObj, setCondoCorpObj] = useState<CondoCorporation>({ ...defaultCondoCorporation });
    const [originalCopy, setOriginalCopy] = useState<CondoCorporation>({ ...defaultCondoCorporation });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<CondoCorporationErrorState>({ ...defaultCondoCorporationErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.condoCorporations.editing && state.condoCorporations.condoCorpInfo) {
            setCondoCorpObj(state.condoCorporations.condoCorpInfo);
            setOriginalCopy(state.condoCorporations.condoCorpInfo);
        } else {
            setCondoCorpObj({ ...defaultCondoCorporation });
        }
    }, [state.condoCorporations.editing]);

    function updateCondoCorpInfo<
        K extends keyof CondoCorporation,
        V extends CondoCorporation[K]
    >(key: K, value: V): void {
        const tempCondoCorpObj = { ...condoCorpObj }
        tempCondoCorpObj[key] = value;
        setCondoCorpObj(tempCondoCorpObj);
    }

    function submit() {
        if (state.condoCorporations.editing) {
            saveCondoCorporation(dispatch, String(state.deal.dealInfo?.id), condoCorpObj);
        } else {
            createNewCondoCorporationRecord(dispatch, String(state.deal.dealInfo?.id), condoCorpObj);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_CONDO_CORPORATION, payload: false });
        setCondoCorpObj({ ...defaultCondoCorporation });
        setOriginalCopy({ ...defaultCondoCorporation });
        setErrorState({ ...defaultCondoCorporationErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(condoCorpObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function calculateTotalFee() {
        if (!condoCorpObj.fee_for_status_certificate) {
            return 0;
        }
        if (!condoCorpObj.tax_on_fee) {
            return condoCorpObj.fee_for_status_certificate;
        }
        if (state.deal.dealInfo?.region_id) return condoCorpObj.fee_for_status_certificate + calculateTaxAmount(state.deal.dealInfo?.region_id, condoCorpObj.fee_for_status_certificate);
    }

    function isSaveDisabled(): boolean {
        if (
            !condoCorpObj.name ||
            !condoCorpObj.fee_for_status_certificate ||
            !condoCorpObj.general_address ||
            _.isEqual(originalCopy, condoCorpObj) ||
            validateModalObject(errorState)
        ) return true
        return false
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, condoCorpObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.condoCorporations.editing ? "" : "New "}Condo Corporation`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.condoCorporations.editing ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.CondoCorporationModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveCondoCorporation])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{ text: "Corporation Name", inputId: "name", isRequired: true }}
                            placeholder="Corporation's name"
                            value={condoCorpObj.name ?? undefined}
                            onChange={(e) => updateCondoCorpInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Corporation Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Abbreviated Name", inputId: "abbr_name" }}
                            value={condoCorpObj.abbr_name ?? undefined}
                            onChange={(e) => updateCondoCorpInfo("abbr_name", e.target.value)}
                            placeholder="Abbreviated name"
                            inputProps={{ "aria-label": "Abbreviated Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Fee for Status Certificate", inputId: "fee_for_status_certificate", isRequired: true }}
                            value={condoCorpObj?.fee_for_status_certificate !== undefined ? String(condoCorpObj.fee_for_status_certificate) : undefined}
                            // @ts-ignore
                            onChange={(e) => updateCondoCorpInfo("fee_for_status_certificate", e.target.value)}
                            placeholder="0.00"
                            inputProps={{ "aria-label": "Fee for Status Certificate" }}
                            moneyField
                            valueType="positive"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanControl
                            checked={condoCorpObj.tax_on_fee}
                            onChange={(e) => updateCondoCorpInfo("tax_on_fee", condoCorpObj.tax_on_fee ? false : true)}
                            label={{ text: "Tax on Fee", inputId: "tax_on_fee" }}
                            labels={["No", "Yes"]}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Total Fee", inputId: "total_fee" }}
                            value={calculateTotalFee()}
                            placeholder="$0.00"
                            inputProps={{ "aria-label": "Total Fee" }}
                            moneyField
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={condoCorpObj.phone_office}
                            onChange={(value) => updateCondoCorpInfo("phone_office", value)}
                            label={{ text: "Office Phone", inputId: "phone_office" }}
                            inputProps={{ "aria-label": "Office Phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone_office",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={condoCorpObj.phone_cell}
                            onChange={(value) => updateCondoCorpInfo("phone_cell", value)}
                            label={{ text: "Cell Phone", inputId: "phone_cell" }}
                            inputProps={{ "aria-label": "Cell Phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone_cell",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={condoCorpObj.fax}
                            onChange={(value) => updateCondoCorpInfo("fax", value)}
                            label={{ text: "Fax", inputId: "fax" }}
                            inputProps={{ "aria-label": "Fax" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "fax",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Email", inputId: "email" }}
                            value={condoCorpObj.email ?? undefined}
                            onChange={(e) => updateCondoCorpInfo("email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorKey: "email",
                                errorState: errorState,
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={condoCorpObj.general_address}
                            setAddress={(value) => updateCondoCorpInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                    {condoCorpObj.condo_corporation?.notes && 
                        <Grid item xs={12}>
                            <BasicTextInput
                                fullWidth
                                readOnly
                                multiline
                                label={{ text: "Notes", inputId: "notes" }}
                                value={condoCorpObj.condo_corporation.notes}
                                inputProps={{ "aria-label": "Notes" }}
                            />
                        </Grid>
                    }
                </Grid>
            </>
        </ModalBase>
    );
}