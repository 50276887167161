import * as actions from "../actionTypes"

import { RegionListItem } from "../../../libs/types/DealList/deals";

export type RegionState = {
    regions: RegionListItem[]
}

export const defaultRegionState: RegionState = {
    regions: []
}

export function regionReducer(state: RegionState, action: Record<string, any>): RegionState {
    switch (action.type) {
        case actions.SET_REGIONS:
            return { ...state, regions: action.payload };
        default:
            return state;
    }
}