import { FireInsurance } from "../../../types/UniversalSurvey/FireInsurance/fireInsurance";
import { FireInsuranceNumberType } from "../../../resources/enums/fireInsurance";

export const defaultFireInsurance: FireInsurance = {
    id: -1,
    property_id: undefined,
    fire_insurance_company_id: undefined,
    fire_insurance_brokerage_id: undefined,
    fire_insurance_broker_id: undefined,
    type: FireInsuranceNumberType.Policy,
    number: undefined,
    coverage_amount: undefined,
    expiry_date: undefined,
    guaranteed_replacement_costs: false,
    interest_noted: false,
    date_created: undefined,
    date_updated: undefined,

    property_record: undefined,
    fire_insurance_company_record: undefined,
    fire_insurance_brokerage_record: undefined,
    fire_insurance_broker_record: undefined,
}