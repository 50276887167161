import { DashboardUser } from "../../types/UniversalSurvey/DashboardUser/dashboardUser";

export function labelDashboardUser(user: DashboardUser | undefined): string {
    if (!user) return "Unknown User";
    if (!user.first_name && !user.last_name) return "User";
    if (user.first_name) {
        if (user.last_name) return `${user.first_name} ${user.last_name}`;
        return user.first_name;
    }
    return user.last_name!;
}