import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { Loading } from "../../../libs/resources/enums/loading";
import { getNotes, deleteNote, saveNote, newNote } from "../../../libs/service/axios/api";
import { Note } from "../../../libs/types/UniversalSurvey/Note/note";
import { sanitizeNotesResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeNoteRequest } from "conveyance/libs/types/UniversalSurvey/utils/convertRequest";

function getNotesInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Notes, isLoading: true } });

    const query = new URLSearchParams({ sort_by: "date_created", sort_order: "desc" });
    getNotes(dealId, query)
        .then(function (response: any) {
            const notes: Note[] = sanitizeNotesResponse(response.data);
            dispatch({ type: actions.SET_NOTES_IN_DEAL, payload: notes });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get notes: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Notes, isLoading: false } });
        })
}

function removeNote(dispatch: Dispatch<Record<string, any>>, dealId: string, noteId: number) {
    deleteNote(dealId, String(noteId))
        .then(function () {
            dispatch({ type: actions.DELETE_NOTE, payload: noteId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete note: ${error}`, type: AlertTypes.Error } });
        })
}

function updateNote(dispatch: Dispatch<Record<string, any>>, dealId: string, note: Note) {
    saveNote(dealId, String(note.id), sanitizeNoteRequest(note))
        .then(function () {
            dispatch({ type: actions.UPDATE_NOTE, payload: note });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete note: ${error}`, type: AlertTypes.Error } });
        })
}

function addNote(dispatch: Dispatch<Record<string, any>>, dealId: string, note: Note) {
    newNote(dealId, sanitizeNoteRequest(note))
        .then(function () {
            getNotesInDeal(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete note: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getNotesInDeal,
    removeNote,
    updateNote,
    addNote
}