import {Deal} from "types/deal";
import {
  getEditWorkdocLink,
  getFolderInfo,
  getFolders,
  getFolderSizeA,
  getInteralIds,
  getWorkdocLink,
  moveFile,
  uploadNewDocument,
} from "../../api";
import {Dispatch} from "redux";
import {formatBytes} from "utils/formatBytes";
import {DealType} from "types/deal";
import {RootStore} from "types/flux";
import {addErrorAC} from "./ErrorReducer";
import {RootState} from "../store";

type DispatchThunkCompatibleType = (
  action: (() => Promise<unknown>) | object,
) => unknown;

type Tags = "Purchase" | "Mortgage" | "Sale" | "Lender";

type InteralIdsForDisplayingFolders = {id: string; interal_ids: number[]};

type FileInfoType = {
  fileName: string;
  shortFileName?: string;
  filesInside: number | null;
  capacity: string | null;
  lastUpdate: string | null;
  Type: string | null;
  owner: string | null;
  edited: string | null;
  dateEdited: string | null;
  created: string | null;
  dateCreated: string | null;
  tags: Tags[];
};

type BreadCrumbsType = {
  name: string;
  id: number;
  deal_id: number;
  deal_type: string;
  clientId: number;
  clientFullName: string;
};

type FolderType = {
  is_can_delete?: boolean;
  agreement: {
    document_updates:
      | {
          comment: string;
        }[];
  } | null;
  count_files: number;
  id: number;
  name: string;
  path: string;
  mime_type: unknown | null;
  size: string;
  deal_type: DealType;
  doc_type: string;
  status: unknown | null;
  user_id: number;
  deal_folder_id: unknown | null;
  created_at: string;
  updated_at: string;
  deleted_at: unknown | null;
  deal_id: number;
  editor_id: unknown | null;
  google_drive_id: unknown | null;
  lastModifiedDate: string;
  members: unknown[];
  tags: Tags[];
  editor: {full_name: string} | null;
  deal_status_label: string | null;
};

interface GetFolders {
  current_page: number;
  data: FolderType[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: unknown | null;
  to: number;
  total: number;
}

export interface StorageReducerState {
  isLoadingDocument: boolean;
  isGettingFolders: boolean;
  idActiveMembersList: number | null;
  interalIdsForDisplayingFolders: InteralIdsForDisplayingFolders[];
  idsDisplayingFolders: number[];
  isFolderSelected: unknown | null;
  idMoveFile: unknown | null;
  idHoveredFolder: unknown | null;
  yavizvalas: "yavizvalas";
  clientName: string;
  selectedFormatForFileInfo: string;
  renameMode: boolean;
  deletedCount: number;
  idActions: unknown | null;
  searchValue: string;
  folderID: number | null;
  folderName: string;
  currentPage: number;
  pagesCount: number;
  fileInfoSection: boolean;
  inFolderCount: number | null;
  folderSize: number | null;
  fileinfo: FileInfoType;
  folders: FolderType[];
  files: unknown[];
  breadCrumbs: BreadCrumbsType[];
  loader: boolean;
}

type StorageActionType =
  | "SET_LOADER"
  | "GET_FOLDERS"
  | "SET_FOLDER_ID"
  | "SET_SEARCH_VALUE"
  | "SET_CURRENT_PAGE"
  | "SET_PAGES_COUNT"
  | "ADD_BREADCRUMBS"
  | "CLEAR_BREADCRUMBS"
  | "FILTER_BREADCRUMBS"
  | "SET_FILE_INFO_SECTION_FALSE"
  | "SET_FILE_INFO_SECTION_TRUE"
  | "SET_FILE_INFO"
  | "SET_FOLDER_COUNT"
  | "SET_FOLDER_SIZE"
  | "SET_ID_ACTIONS"
  | "SET_ID_ACTIONS_NULL"
  | "SET_DELETED_COUNT"
  | "SET_RENAME_MODE"
  | "SET_SELECTED_FORMAT_FOR_FILE_INFO"
  | "SET_TAGS_FOR_FILE_INFO"
  | "SET_CLIENT_NAME"
  | "SET_ID_MOVE_FILE"
  | "SET_ID_HOVERED_FOLDER"
  | "CLEAR_FOLDERS"
  | "SET_IS_FOLDER_SELECTED"
  | "SET_INTERAL_IDS_FOR_DISPLAYING_FOLDERS"
  | "CLEAR_INTERAL_IDS_FOR_DISPLAYING_FOLDERS"
  | "RESET_STORAGE"
  | "SET_ID_ACTIVE_MEMBERS"
  | "SET_IS_GETTING_FOLDERS"
  | "CLEAR_BREADCRUMBS_FOR_SUBFOLDER"
  | "SET_IS_LOADING_DOCUMENT";
export type StorageAction = {
  type: StorageActionType;
  payload?: unknown;
};

export const STORAGE_REDUCER = "STORAGE_REDUCER";

const getLocalStorage = (key: string, defaultVal: unknown) => {
  return JSON.parse(localStorage.getItem(STORAGE_REDUCER) as string)
    ? JSON.parse(localStorage.getItem(STORAGE_REDUCER) as string)[key] ??
        defaultVal
    : defaultVal;
};

export const setLocaleStorage = (
  state: StorageReducerState,
  key: string,
  val: unknown,
) => {
  const storageStateNew = {...state, [key]: val};
  localStorage.setItem(STORAGE_REDUCER, JSON.stringify(storageStateNew));
};

let storageState: StorageReducerState = {
  isLoadingDocument: getLocalStorage("isLoadingDocument", false),
  isGettingFolders: getLocalStorage("isGettingFolders", false),
  idActiveMembersList: getLocalStorage("idActiveMembersList", null),
  interalIdsForDisplayingFolders: getLocalStorage(
    "interalIdsForDisplayingFolders",
    [],
  ),
  idsDisplayingFolders: getLocalStorage("idsDisplayingFolders", []),
  isFolderSelected: getLocalStorage("isFolderSelected", null),
  idMoveFile: getLocalStorage("idMoveFile", null),
  idHoveredFolder: getLocalStorage("idHoveredFolder", null),
  yavizvalas: getLocalStorage("yavizvalas", "yavizvalas"),
  clientName: getLocalStorage("clientName", ""),
  selectedFormatForFileInfo: getLocalStorage("selectedFormatForFileInfo", ""),
  renameMode: getLocalStorage("renameMode", false),
  deletedCount: getLocalStorage("deletedCount", 0),
  idActions: getLocalStorage("idActions", null),
  searchValue: getLocalStorage("searchValue", ""),
  folderID: getLocalStorage("folderID", null),
  folderName: getLocalStorage("folderName", ""),
  currentPage: getLocalStorage("currentPage", 1),
  pagesCount: getLocalStorage("pagesCount", 1),
  fileInfoSection: getLocalStorage("fileInfoSection", false),
  inFolderCount: getLocalStorage("inFolderCount", null),
  folderSize: getLocalStorage("folderSize", null),
  fileinfo: {
    fileName: getLocalStorage("fileName", ""),
    shortFileName: getLocalStorage("shortFileName", ""),
    filesInside: getLocalStorage("filesInside", null),
    capacity: getLocalStorage("capacity", null),
    lastUpdate: getLocalStorage("lastUpdate", null),
    Type: getLocalStorage("Type", null),
    owner: getLocalStorage("owner", null),
    edited: getLocalStorage("edited", null),
    dateEdited: getLocalStorage("dateEdited", null),
    created: getLocalStorage("created", null),
    dateCreated: getLocalStorage("dateCreated", null),
    tags: getLocalStorage("tags", ["Purchase", "Mortgage", "Sale", "Lender"]),
  },
  folders: getLocalStorage("folders", []),
  files: getLocalStorage("files", []),
  breadCrumbs: getLocalStorage("breadCrumbs", []),
  loader: false,
};

export const StorageReducer = (
  state = storageState,
  action: StorageAction,
): StorageReducerState => {
  switch (action.type) {
    case "SET_IS_LOADING_DOCUMENT": {
      return {
        ...state,
        isLoadingDocument: action.payload as boolean,
      };
    }
    case "SET_LOADER": {
      return {
        ...state,
        loader: action.payload as boolean,
      };
    }
    case "SET_IS_GETTING_FOLDERS": {
      setLocaleStorage(state, "isGettingFolders", action.payload);

      return {
        ...state,
        isGettingFolders: action.payload as boolean,
      };
    }
    case "SET_ID_ACTIVE_MEMBERS": {
      setLocaleStorage(state, "idActiveMembersList", action.payload);

      return {
        ...state,
        idActiveMembersList: action.payload as number | null,
      };
    }
    case "RESET_STORAGE": {
      localStorage.setItem(STORAGE_REDUCER, JSON.stringify(storageState));

      return {...storageState};
    }

    case "CLEAR_INTERAL_IDS_FOR_DISPLAYING_FOLDERS": {
      setLocaleStorage(state, "interalIdsForDisplayingFolders", []);

      return {
        ...state,
        interalIdsForDisplayingFolders: [],
      };
    }
    case "SET_INTERAL_IDS_FOR_DISPLAYING_FOLDERS": {
      setLocaleStorage(state, "interalIdsForDisplayingFolders", action.payload);

      return {
        ...state,
        interalIdsForDisplayingFolders:
          action.payload as InteralIdsForDisplayingFolders[],
      };
    }
    case "SET_IS_FOLDER_SELECTED": {
      setLocaleStorage(state, "isFolderSelected", action.payload);

      return {
        ...state,
        isFolderSelected: action.payload,
      };
    }
    case "CLEAR_FOLDERS": {
      setLocaleStorage(state, "folders", []);

      return {
        ...state,
        folders: [],
      };
    }
    case "SET_ID_MOVE_FILE": {
      setLocaleStorage(state, "idMoveFile", action.payload);

      return {
        ...state,
        idMoveFile: action.payload,
      };
    }
    case "SET_ID_HOVERED_FOLDER": {
      if (action.payload === state.idHoveredFolder) {
        return state;
      }

      setLocaleStorage(state, "idHoveredFolder", action.payload);
      return {
        ...state,
        idHoveredFolder: action.payload,
      };
    }
    case "SET_CLIENT_NAME": {
      if (!action.payload) return state;

      const name =
        (action.payload as Deal).participants?.filter(
          (x) => x.role === "Client",
        )?.[0]?.full_name ?? "Unknown name";

      setLocaleStorage(state, "clientName", name);
      setLocaleStorage(state, "folderID", (action.payload as Deal).folder_id);
      setLocaleStorage(
        state,
        "folderName",
        (action.payload as Deal).folder_name,
      );
      return {
        ...state,
        clientName: name,
        folderID: (action.payload as Deal).folder_id,
        folderName: (action.payload as Deal).folder_name,
      };
    }
    case "SET_TAGS_FOR_FILE_INFO": {
      setLocaleStorage(state, "fileinfo", {
        ...state.fileinfo,
        tags: action.payload as Tags[],
      });

      return {
        ...state,
        fileinfo: {...state.fileinfo, tags: action.payload as Tags[]},
      };
    }
    case "SET_SELECTED_FORMAT_FOR_FILE_INFO": {
      let format;
      const payload: string = action.payload as string;
      if (payload.endsWith(".pdf")) format = ".pdf";
      else if (payload.endsWith(".doc") || payload.endsWith(".docx"))
        format = ".doc";
      else if (
        payload.endsWith(".jpg") ||
        payload.endsWith(".jpeg") ||
        payload.endsWith(".png") ||
        payload.endsWith(".tiff")
      )
        format = ".jpg";
      else if (
        payload.endsWith(".xls") ||
        payload.endsWith(".xlsx") ||
        payload.endsWith(".xlsm") ||
        payload.endsWith(".xlsb") ||
        payload.endsWith(".xltx")
      )
        format = ".xls";
      else format = "";

      setLocaleStorage(state, "selectedFormatForFileInfo", format);
      return {
        ...state,
        selectedFormatForFileInfo: format,
      };
    }
    case "SET_RENAME_MODE": {
      setLocaleStorage(state, "renameMode", action.payload);

      return {...state, renameMode: action.payload as boolean};
    }
    case "SET_DELETED_COUNT": {
      setLocaleStorage(state, "deletedCount", state.deletedCount + 1);

      return {
        ...state,
        deletedCount: state.deletedCount + 1,
      };
    }
    case "SET_ID_ACTIONS_NULL": {
      return {
        ...state,
        idActions: null,
      };
    }
    case "SET_ID_ACTIONS": {
      setLocaleStorage(state, "idActions", action.payload);

      return {
        ...state,
        idActions: action.payload,
      };
    }
    case "SET_FOLDER_SIZE": {
      setLocaleStorage(state, "folderSize", action.payload);
      setLocaleStorage(state, "fileinfo", {
        ...state.fileinfo,
        capacity: formatBytes(action.payload as number),
      });

      return {
        ...state,
        folderSize: action.payload as number,
        fileinfo: {
          ...state.fileinfo,
          capacity: formatBytes(action.payload as number),
        },
      };
    }
    case "SET_FOLDER_COUNT": {
      setLocaleStorage(state, "inFolderCount", action.payload);
      setLocaleStorage(state, "fileinfo", {
        ...state.fileinfo,
        filesInside: action.payload as number,
      });

      return {
        ...state,
        inFolderCount: action.payload as number,
        fileinfo: {
          ...state.fileinfo,
          filesInside: action.payload as number,
        },
      };
    }
    case "SET_FILE_INFO": {
      const payload: FileInfoType = action.payload as FileInfoType;

      setLocaleStorage(state, "fileinfo", {
        fileName: payload.fileName,
        filesInside: payload.filesInside,
        capacity: payload.capacity, //1.56,
        lastUpdate: payload.lastUpdate, //" today at 03:21 AM",
        Type: payload.Type, //"Category",
        owner: payload.owner, //"Me",
        edited: payload.edited, //"by me",
        dateEdited: payload.dateEdited, //"10/18/2021",
        created: payload.created, //"by me",
        dateCreated: payload.dateCreated, //"10/21/2020",
        tags: payload.tags, //["Purchase", "Mortagage", "Sale", "Lender"],
      });

      return {
        ...state,
        fileinfo: {
          fileName: payload.fileName,
          filesInside: payload.filesInside,
          capacity: payload.capacity, //1.56,
          lastUpdate: payload.lastUpdate, //" today at 03:21 AM",
          Type: payload.Type, //"Category",
          owner: payload.owner, //"Me",
          edited: payload.edited, //"by me",
          dateEdited: payload.dateEdited, //"10/18/2021",
          created: payload.created, //"by me",
          dateCreated: payload.dateCreated, //"10/21/2020",
          tags: payload.tags, //["Purchase", "Mortagage", "Sale", "Lender"],
        },
      };
    }
    case "SET_FILE_INFO_SECTION_TRUE": {
      setLocaleStorage(state, "fileInfoSection", true);

      return {
        ...state,
        fileInfoSection: true,
      };
    }
    case "SET_FILE_INFO_SECTION_FALSE": {
      setLocaleStorage(state, "fileInfoSection", false);

      return {
        ...state,
        fileInfoSection: false,
      };
    }
    case "FILTER_BREADCRUMBS": {
      const index = action.payload as number;

      setLocaleStorage(
        state,
        "breadCrumbs",
        state.breadCrumbs?.slice(0, index + 1),
      );

      return {
        ...state,
        breadCrumbs: state.breadCrumbs?.slice(0, index + 1),
      };
    }
    case "CLEAR_BREADCRUMBS_FOR_SUBFOLDER": {
      return {
        ...state,
        breadCrumbs: state.breadCrumbs.slice(0, 1),
      };
    }
    case "CLEAR_BREADCRUMBS": {
      setLocaleStorage(state, "breadCrumbs", []);

      return {
        ...state,
        breadCrumbs: [],
      };
    }
    case "ADD_BREADCRUMBS": {
      const lastItem = (state.breadCrumbs[state.breadCrumbs.length - 1] ??
        null) as BreadCrumbsType | null;
      const newItem = action.payload as BreadCrumbsType;
      /** We don't add the same folder to the list if it's already added... */
      if (lastItem?.id === newItem.id) {
        return {...state};
      }

      setLocaleStorage(state, "breadCrumbs", [
        ...(state.breadCrumbs as BreadCrumbsType[]),
        action.payload as BreadCrumbsType,
      ]);

      return {
        ...state,
        breadCrumbs: [
          ...(state.breadCrumbs as BreadCrumbsType[]),
          action.payload as BreadCrumbsType,
        ],
      };
    }
    case "SET_PAGES_COUNT": {
      setLocaleStorage(state, "pagesCount", action.payload);

      return {
        ...state,
        pagesCount: action.payload as number,
      };
    }
    case "SET_CURRENT_PAGE": {
      setLocaleStorage(state, "currentPage", action.payload);

      return {
        ...state,
        currentPage: action.payload as number,
      };
    }
    case "SET_SEARCH_VALUE": {
      setLocaleStorage(state, "searchValue", action.payload);

      return {
        ...state,
        searchValue: action.payload as string,
      };
    }
    case "SET_FOLDER_ID": {
      setLocaleStorage(state, "folderID", action.payload);

      return {
        ...state,
        folderID: action.payload as number | null,
      };
    }
    case "GET_FOLDERS": {
      setLocaleStorage(
        state,
        "folders",
        (action.payload as GetFolders).data.map((f) => {
          const updatedDateTime = f.lastModifiedDate.split(" ");
          const updatedDate = updatedDateTime[0];
          const updatedTime = updatedDateTime[1];
          const splitedUpdatedDate = updatedDate.split("-");
          const splitedUpdatedTime = updatedTime.split(":");
          let switcherUpdatedTime = "am";
          if (+splitedUpdatedTime[0] >= 12) {
            splitedUpdatedTime[0] = (+splitedUpdatedTime[0] - 12).toString();
            switcherUpdatedTime = "pm";
          }
          const createdDateTime = f.created_at.split(" ");
          const createdDate = createdDateTime[0];
          const createdTime = createdDateTime[1];
          const splitedCreatedDate = createdDate.split("-");
          const splitedCreatedTime = createdTime.split(":");
          let switcherCreatedTime = "am";
          if (+splitedCreatedTime[0] >= 12) {
            splitedCreatedTime[0] = (+splitedCreatedTime[0] - 12).toString();
            switcherCreatedTime = "pm";
          }
          const comment = f.agreement
            ? f.agreement.document_updates[
                f.agreement.document_updates.length - 1
              ].comment
              ? f.agreement.document_updates[
                  f.agreement.document_updates.length - 1
                ].comment
              : null
            : null;

          return {
            isCanDelete: f?.is_can_delete,
            comment,
            name: f.name,
            lastEdit: "--",
            lastEditF: splitedUpdatedDate,
            lastEditFT: splitedUpdatedTime,
            lastEditFO: f.editor?.full_name,
            switcher: switcherUpdatedTime,
            createdDate: splitedCreatedDate,
            createdTime: splitedCreatedTime,
            switcherCreatedTime: switcherCreatedTime,
            members: f.members,
            membersNumText: " ",
            status: f.status,
            id: f.id,
            doc_type: f.doc_type,
            size: f.size,
            countFiles: f.count_files,
            deal_status_label: f.deal_status_label,
          } as unknown as FolderType;
        }),
      );

      setLocaleStorage(
        state,
        "idsDisplayingFolders",
        (action.payload as GetFolders).data.map((f) => {
          return f.id;
        }),
      );

      return {
        ...state,
        folders: (action.payload as GetFolders).data.map((f) => {
          const updatedDateTime = f.lastModifiedDate.split(" ");
          const updatedDate = updatedDateTime[0];
          const updatedTime = updatedDateTime[1];
          const splitedUpdatedDate = updatedDate.split("-");
          const splitedUpdatedTime = updatedTime.split(":");
          let switcherUpdatedTime = "am";
          if (+splitedUpdatedTime[0] >= 12) {
            splitedUpdatedTime[0] = (+splitedUpdatedTime[0] - 12).toString();
            switcherUpdatedTime = "pm";
          }
          const createdDateTime = f.created_at.split(" ");
          const createdDate = createdDateTime[0];
          const createdTime = createdDateTime[1];
          const splitedCreatedDate = createdDate.split("-");
          const splitedCreatedTime = createdTime.split(":");
          let switcherCreatedTime = "am";
          if (+splitedCreatedTime[0] >= 12) {
            splitedCreatedTime[0] = (+splitedCreatedTime[0] - 12).toString();
            switcherCreatedTime = "pm";
          }
          const comment = f.agreement
            ? f.agreement.document_updates[
                f.agreement.document_updates.length - 1
              ].comment
              ? f.agreement.document_updates[
                  f.agreement.document_updates.length - 1
                ].comment
              : null
            : null;

          return {
            comment,
            isCanDelete: f?.is_can_delete,
            name: f.name,
            lastEdit: "--",
            lastEditF: splitedUpdatedDate,
            lastEditFT: splitedUpdatedTime,
            lastEditFO: f.editor?.full_name,
            switcher: switcherUpdatedTime,
            createdDate: splitedCreatedDate,
            createdTime: splitedCreatedTime,
            switcherCreatedTime: switcherCreatedTime,
            members: f.members,
            membersNumText: " ",
            status: f.status,
            id: f.id,
            doc_type: f.doc_type,
            size: f.size,
            countFiles: f.count_files,
            deal_status_label: f.deal_status_label,
          } as unknown as FolderType;
        }),
        // eslint-disable-next-line array-callback-return
        idsDisplayingFolders: (action.payload as GetFolders).data.map((f) => {
          return f.id;
        }),
      };
    }
    default:
      return state;
  }
};
export const setIsLoadingDocumentAC = (payload: boolean) => ({
  type: "SET_IS_LOADING_DOCUMENT",
  payload,
});
export const setLoaderAC = (payload: boolean) => {
  return {
    type: "SET_LOADER",
    payload,
  };
};
export const setIsGettingFoldersAC = (payload: boolean) => {
  return {
    type: "SET_IS_GETTING_FOLDERS",
    payload,
  };
};
export const setFoldersAC = (payload: GetFolders) => {
  return {
    type: "GET_FOLDERS",
    payload,
  };
};
export const setFolderIdAC = (payload: number | null) => {
  return {
    type: "SET_FOLDER_ID",
    payload,
  };
};
export const setSeachValue = (payload: string) => {
  return {
    type: "SET_SEARCH_VALUE",
    payload,
  };
};
export const setCurrentPage = (payload: number) => {
  return {
    type: "SET_CURRENT_PAGE",
    payload: payload,
  };
};
export const setPagesCountAC = (payload: number) => {
  return {
    type: "SET_PAGES_COUNT",
    payload,
  };
};
export const addBreadCrumbsAC = (payload: BreadCrumbsType) => {
  return {
    type: "ADD_BREADCRUMBS",
    payload,
  };
};
export const clearBreadCrumbsAC = () => {
  return {
    type: "CLEAR_BREADCRUMBS",
  };
};
export const clearBreadCrumbsForSubfolderAC = () => ({
  type: "CLEAR_BREADCRUMBS_FOR_SUBFOLDER",
});
export const filterBreadCrumbsAC = (payload: number) => {
  return {
    type: "FILTER_BREADCRUMBS",
    payload,
  };
};
export const setFileInfoSectionFalseAC = () => {
  return {
    type: "SET_FILE_INFO_SECTION_FALSE",
  };
};
export const setFileInfoSectionTrueAC = () => {
  return {
    type: "SET_FILE_INFO_SECTION_TRUE",
  };
};
export const setFileInfoAC = (payload: FileInfoType) => {
  return {
    type: "SET_FILE_INFO",
    payload,
  };
};
export const setInFolderCountAC = (payload: number) => {
  return {
    type: "SET_FOLDER_COUNT",
    payload,
  };
};
export const setFolderSizeAC = (payload: number) => {
  return {
    type: "SET_FOLDER_SIZE",
    payload,
  };
};
export const setIdActionsAC = (payload: number) => {
  return {
    type: "SET_ID_ACTIONS",
    payload,
  };
};
export const setIdActionsNullAC = () => {
  return {
    type: "SET_ID_ACTIONS_NULL",
  };
};
export const setDeletedCountAC = () => {
  return {
    type: "SET_DELETED_COUNT",
  };
};
export const setRenameModeAC = (payload: boolean) => {
  return {
    type: "SET_RENAME_MODE",
    payload,
  };
};
export const setSelectedFormatForFileInfoAC = (payload: string) => {
  return {
    type: "SET_SELECTED_FORMAT_FOR_FILE_INFO",
    payload,
  };
};
export const setTagsForFileInfoAC = (payload: Tags[]) => {
  return {
    type: "SET_TAGS_FOR_FILE_INFO",
    payload,
  };
};
export const setClientNameAC = (payload: Deal) => {
  return {
    type: "SET_CLIENT_NAME",
    payload,
  };
};
export const setIdMoveFileAC = (payload: number) => {
  return {
    type: "SET_ID_MOVE_FILE",
    payload,
  };
};
export const setIdHoveredFolderAC = (payload: null | number) => {
  return {
    type: "SET_ID_HOVERED_FOLDER",
    payload,
  };
};
export const clearFoldersAC = () => {
  return {
    type: "CLEAR_FOLDERS",
  };
};
export const setIsFolderSelectedAC = (payload: null | boolean) => {
  return {
    type: "SET_IS_FOLDER_SELECTED",
    payload,
  };
};
export const interalIdsForDisplayingFoldersAC = (
  payload: InteralIdsForDisplayingFolders[],
) => {
  return {
    type: "SET_INTERAL_IDS_FOR_DISPLAYING_FOLDERS",
    payload,
  };
};
export const clearInteralIdsForDisplayingFoldersAC = () => {
  return {
    type: "CLEAR_INTERAL_IDS_FOR_DISPLAYING_FOLDERS",
  };
};
export const resetStorageAC = (): StorageAction => {
  return {type: "RESET_STORAGE"};
};
export const setIdActiveMembersListAC = (payload: number | null) => {
  return {
    type: "SET_ID_ACTIVE_MEMBERS",
    payload,
  };
};

export const getFoldersTC =
  (
    folderID?: number,
    searchValue?: string,
    currentPage?: number,
    dealId?: number,
    nopagination?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoaderAC(true));
      const location = window.location;
      const isStorage = location?.pathname.includes("storage");
      if (!folderID && !isStorage) {
        dispatch(setLoaderAC(false));
        return;
      }

      dispatch(setIsGettingFoldersAC(true));
      let folders = await getFolders(
        folderID ?? null,
        searchValue,
        currentPage ?? 1,
        dealId,
        nopagination ?? false,
      );
      let pagesCount = Math.ceil(folders.total / 10);
      dispatch(setPagesCountAC(pagesCount));
      dispatch(setFoldersAC(folders));
      dispatch(setIsGettingFoldersAC(false));
      dispatch(setLoaderAC(false));
    } catch (e) {
      console.error({e});
    }
  };

export const getFolderInfoTC =
  (folderID: number) => async (dispatch: Dispatch) => {
    const folderInfo = await getFolderInfo(folderID);
    dispatch(setInFolderCountAC(folderInfo.count));
  };
export const getFolderSizeTC =
  (folderID: number) => async (dispatch: Dispatch) => {
    const folderSize: {size: number} = await getFolderSizeA(folderID);

    dispatch(setFolderSizeAC(folderSize.size));
  };
export const getTagsForFileTC =
  (folderID: number) => async (dispatch: Dispatch) => {
    const folderInfo = await getFolderInfo(folderID);
    let tagsName: Tags[] = [];
    for (let i = 0; i < folderInfo.dealFile.tags.length; i++) {
      tagsName = [...tagsName, folderInfo.dealFile.tags[i]?.name];
    }
    dispatch(setTagsForFileInfoAC(tagsName));
  };

export const moveFileTC =
  (
    folderId: number,
    searchValue: string,
    currentPage: number,
    dealId: number,
    nopagination: boolean,
  ) =>
  async (dispatch: DispatchThunkCompatibleType, getState: () => RootStore) => {
    const idFile = getState().StorageReducer.idMoveFile as number;
    const idFolder = getState().StorageReducer.idHoveredFolder as number;
    if (idFolder) {
      await moveFile(idFile, idFolder);
    }
    dispatch(
      getFoldersTC(folderId, searchValue, currentPage, dealId, nopagination),
    );
  };
export const getInteralIdsTC =
  (idsDisplayingFolders: number[]) => async (dispatch: Dispatch) => {
    const interalIdsForDisplayingFolders = await getInteralIds(
      idsDisplayingFolders,
    );
    dispatch(interalIdsForDisplayingFoldersAC(interalIdsForDisplayingFolders));
  };
export const getWorkdocLinkTC =
  (fileId: number) => async (dispatch: Dispatch) => {
    try {
      const workdocLinkPromise = await getWorkdocLink(fileId);
      window.open(workdocLinkPromise.data.url)!.focus();
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot get link"));
    }
  };
export const getEditWorkdocLinkTC =
  (fileId: number) => async (dispatch: Dispatch) => {
    try {
      const editWorkdocLinkPromise = await getEditWorkdocLink(fileId);
      window.open(editWorkdocLinkPromise.data.url)!.focus();
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot get link"));
    }
  };
export const uploadDocumentTC =
  (
    files: File[],
    dealType: {value: string; label: string},
    docType: {label: string},
    client: {value: number},
    getFolders: () => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const formData = new FormData();
      for (const file of files) {
        formData.append("files[]", file, file.name);
      }

      formData.append("dealId", dealType.value);
      formData.append("dealType", dealType.label);
      formData.append("docType", docType.label);
      formData.append("status", "Approved");
      formData.append("client_id", client.value as unknown as string | Blob);
      await uploadNewDocument(formData as unknown as number);
      getFolders();
    } catch (e) {
      console.error(e);
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response?.data?.error ?? "There was an error loading the files"));
    }
  };
