import { format } from 'date-fns';

function convertDateStringToDateObject(dateString: string, isDatetime: boolean = false) {
    const date = new Date(dateString);
    // TODO: Test localization here with dev site to ensure timezones are correct
    // For now comment out to match signing time entered through signing appointment in deal overview
    if (!isDatetime) {
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    }
    return date
}

function stringToDateFormat(dateString: string | undefined, withTime: boolean): string {
    if (!dateString) return "";
    let localDate = convertDateStringToDateObject(dateString);
    return DateObjectToDateFormat(localDate, withTime, false);
}

function DateObjectToDateFormat(
    date: Date | undefined,
    withTime: boolean,
    withTimezone: boolean = false,
    displayFormat: SupportedDateFormats = SupportedDateFormats.MonthAbbreviated
): string {

    let formattedDate = "";
    if (!date) return formattedDate;

    switch(displayFormat) {
        case SupportedDateFormats.MonthAbbreviated:
            formattedDate = format(date, 'MMM. dd, yyyy');
            break;
        case SupportedDateFormats.DashSeparated:
            formattedDate = format(date, 'MM/dd/yyyy');
            break;
    }

    if (withTime) {
        let time = format(date, 'h:mm a');
        formattedDate += ` @ ${time}`;
    }

    if (withTimezone) {
        let dateString = date.toString();
        let startOfTimeZone = dateString.indexOf("(");
        if (startOfTimeZone > 0) {
            let timezone = "";
            for (const word of dateString.substring(startOfTimeZone + 1, dateString.length - 1).replace("saving ", "").split(" ")) { // Chrome/Edge don't put "saving", but Firefox does.
                timezone += word[0];
            }
            formattedDate += ` (${timezone})`
        }
    }

    return formattedDate;
}

function getDayDiff(day1: Date, day2: Date) {
    return Math.round((day2.getTime() - day1.getTime()) / 86400000)
}

enum SupportedDateFormats {
    DashSeparated,
    MonthAbbreviated
}

export {
    convertDateStringToDateObject,
    stringToDateFormat,
    DateObjectToDateFormat,
    getDayDiff,
    SupportedDateFormats
};