// Importing built-in React/Router tools
import { useContext } from 'react';

// Importing state management
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

// Importing application components
import { navigateURL } from '../../../../context/UniversalSurvey/asyncActions/deal';
import { dataSheetControlOptions } from '../../../../libs/resources/mockControlOptions';
import GridControlForm from './GridControlForm';

export default function DataSheetGridControl() {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    // Updating url query params when selecting selection
    function handleSectionChange(title: string) {
        navigateURL(dispatch, String(state.deal.dealInfo?.id), "data-sheet", title, undefined);
    }

    return (
        <GridControlForm
            options={dataSheetControlOptions}
            handleSectionChange={handleSectionChange}
            activeSection={state.dataSheet.currSection}
        />
    );
}