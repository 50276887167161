import color from './Colors';

export default {
    AUTO_FILLED: color.BLUE_50,
    ERROR: color.ORANGE_500,
    DISABLED: color.GRAY_100,
    HOVER: color.GRAY_200,
    INPUT: color.GRAY_600,
    PLACEHOLDER: color.GRAY_400,
    PRIMARY: color.RED_500,
    SELECTED: color.RED_100,
    WARNING: color.YELLOW_500,
}