import { Link } from "react-router-dom";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div className="notfound__wrapper">
      <div className="container">
        <h1>Oops...</h1>
        <h2>404 - CONVEYANCE PAGE NOT FOUND</h2>
        <p>
          The page you are looking for might have been removed has its changed
          or is temporarily unavailable
        </p>
        <Link to="/dashboard/conveyance/conveyance-deals" className="btn">
          go to conveyance list
        </Link>
      </div>
    </div>
  );
};