import { forwardRef, useState, SyntheticEvent, useRef, useEffect } from "react";
import _, { every } from "lodash";

// Importing MUI components
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box'
import { styled, SxProps, Theme } from "@mui/material/styles";

// Importing application components
import theme from '../componentStyling/Theme';
import color from '../componentStyling/Colors';
import { CheckCircle, ChevronDown, ChevronUp, ClockIcon } from "../Icons/Iconography";
import { SrcTextIcon, ErrorIcon, PlusIcon } from "../Icons/Iconography";
import Label, { labelProps } from "../Label/Label";
import HoverActions from "../HoverActions/ActionTooltip";
import Token from "./Token";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import { LARGE_TEXT } from "../componentStyling/Styles";
import BasicTextInput from "../TextField/BasicTextInput";
import useOutsideClick from "../../../conveyance/libs/hooks/useOutsideClick";
import { IconButton, Stack } from "@mui/material";
import BasicTooltip, { BasicTooltipProps } from "../Tooltip/Tooltip";
import CircularLoader from "../Loader/CircularLoader";
import { defaultAddressRequest } from "../../../conveyance/libs/resources/defaults/request/defaultAddressRequest";
import { addressToString } from "../../../conveyance/libs/utils/address";
import { InputErrorProps } from "../../../conveyance/libs/utils/validation";

/**
 * options: Array of strings or objects. 
 *          If objects, searching will filter on all values in the object.
 *          Must have a "label" key, the corresponding value will be what shows up in the dropdown.
 * value: the selected value
 * placeholder: placeholder text
 * createEntity: string - if passed, there will be an "Add {addEntity}" option as the first option
 * setCreationModalOpen: useState function to set whether a modal is open, triggered by clicking on the "create new ___" option
 * open: used for testing purposes - dropdown list doesn't render on focus in a test
 */
type ComboboxProps = Omit<AutocompleteProps<string | Object, false, false, true>, "renderInput"> & {
    options: string[] | Object[];
    value?: string | Object | string[] | Object[] | null;
    placeholder?: string;
    autoFilled?: boolean;
    timeInput?: boolean;
    sx?: SxProps<Theme>;
    label?: labelProps;
    tooltipProps?: Omit<BasicTooltipProps, "children">;
    createEntity?: string;
    setCreationModalOpen?: (creationModalOpen: boolean) => void;
    open?: boolean;
    canEdit?: boolean;
    onEditClick?: (editModalOpen: boolean) => void;
    handleClear?: (any: any) => void;
    tokenField?: boolean;
    isHoverActionHidden?: boolean;
    text?: string;
    setText?: (text: string) => void;
    multiline?: boolean;
    searchableKeys?: string[];
    onChangeFn?: (value: String | Object | string[] | Object[] | null) => void;
    isOptionDisabled?: (option: any, value?: any) => { isDisabled: boolean, message: string };
    isOptionEqualFn?: (option: any, value: any) => boolean;
    autoFocus?: boolean;
    isLoading?: boolean;
    size?: "extra-small" | "small" | "default";
    isLineItem?: boolean;
    handleView?: (viewModalOpen: boolean) => void;
    error?: InputErrorProps;
}

const ComboBox = forwardRef<HTMLDivElement, ComboboxProps>(
    function ComboBox(props, ref) {
        const { 
            options,
            value,
            placeholder,
            autoFilled,
            timeInput,
            error,
            sx,
            label,
            tooltipProps,
            createEntity,
            setCreationModalOpen,
            open,
            canEdit,
            onEditClick,
            handleClear,
            tokenField,
            isHoverActionHidden,
            text,
            setText,
            multiline,
            searchableKeys,
            onChangeFn,
            isOptionDisabled,
            isOptionEqualFn,
            autoFocus,
            isLoading,
            size,
            isLineItem,
            handleView,
            ...other
        } = props;

        const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
        const comboRef = useRef(null);
        const inputRef = useRef(null);
        const [textLocal, setTextLocal] = useState<string>("");
        useOutsideClick(comboRef, setDropdownOpen);

        const optionEqualFunc = (option: unknown, value: unknown) => {
            if (isOptionEqualFn) {
                return isOptionEqualFn(option, value);
            } else {
                if (typeof option === "string") {
                    return option === value
                } else {
                    return _.isEqual(option, value)
                }
            }
        }

        function filterFunction(options: any[], state: any): any[] {
            if (isLoading) {
                return [{ label: "", spinnerOption: true }];
            }

            const inputWords = state.inputValue.toLowerCase().split(" ");
            
            return options.filter((option) => {
                if (typeof option === "string") {
                    return inputWords.every((word: string) => option.toLowerCase().includes(word));
                } else {
                    return inputWords.every((word: string) => {
                        let keys: string[] = searchableKeys ? searchableKeys : Object.keys(option);
                        return keys.some((key) => {
                            if (typeof option[key] === "string") {
                                return option[key].toLowerCase().includes(word);
                            } else if (typeof option[key] === "number") {
                                return option[key].toString().includes(word);
                            } else if (option[key] !== null && typeof option[key] === "object" && (_.keys(defaultAddressRequest).every(k => k in (option[key] as object)))) {
                                return addressToString(option[key]).toLowerCase().includes(word);
                            } else {
                                return false;
                            }
                        });
                    });
                }
            });
        }

        function createAddOption() {
            if (options.every(item => typeof item === "string")) {
                // if options is type string[]
                return createEntity
            } else {
                // if options is an object
                return { label: placeholder, isAddEntity: true }
            }
        }

        function handleAddEntity() {
            if (label?.inputId) {
                const focusedInput = document.getElementById(label.inputId);
                if (focusedInput) focusedInput.blur();
            }
            setDropdownOpen(false);
            if(setCreationModalOpen){
                setCreationModalOpen(true);
            }
        }

        function renderOption(props: any, option: any) {
            let width: number | undefined = undefined;
            if (inputRef.current) {
                // @ts-ignore
                width = inputRef.current.getBoundingClientRect().width;
            }
            if(option.spinnerOption) {
                return (
                    <EllipsisBox
                        component="li"
                        {...props}
                        onClick={() => {}}
                        style={{justifyContent: "center", cursor: "default"}}
                    >
                        <CircularLoader containerHeight="6.1rem" loaderHeight="5.5rem" loaderWidth="5.5rem"/>
                    </EllipsisBox>
                )
            }

            const optionIsAddButton = props.id?.substring(props.id.length - 2, props.id.length) === "-0" && createEntity
            return (
                (props["aria-disabled"] && isOptionDisabled && !props["aria-selected"]) ? (
                    <BasicTooltip message={isOptionDisabled ? isOptionDisabled(option).message : ""} placement="right" type="error" key={props.id}>
                        <EllipsisBox
                            width={width ? width + 40 : undefined}
                            component="li"
                            {...props}
                            {...(optionIsAddButton && { onClick: handleAddEntity })}
                            onClick={() => {}}
                        >
                            <div>{typeof option === "string" ? option : option.label}</div>
                        </EllipsisBox>
                    </BasicTooltip>
                ) : (
                    <EllipsisBox
                        component="li"
                        {...props}
                        {...(optionIsAddButton && { onClick: handleAddEntity })}
                    >
                        {(createEntity && optionIsAddButton) ? (
                            <>
                                <div style={{ paddingRight: "1.2rem", paddingTop: "0.5rem" }}>
                                    <PlusIcon color={color.GRAY_700} />
                                </div>
                                {`Create new ${createEntity}`}
                            </>
                        ) : (
                            props["aria-selected"] && tokenField) ? (
                                <Box sx={ itemBox }>
                                    {typeof option === "string" ? option : option.label}
                                    <CheckCircle color={color.GRAY_600} />
                                </Box>
                            ) : (
                                <div>{typeof option === "string" ? option : option.label}</div>
                            )
                        }
                    </EllipsisBox>
                )
            )
        }

        function handleOnChange(e: SyntheticEvent, newValue: string | Object | string[] | Object[] | null) {
            if (newValue && typeof newValue === "object" && Object.keys(newValue).includes("isAddEntity")) {
                handleAddEntity();
            } else {
                onChangeFn ? onChangeFn(newValue) : undefined;
            }
        }

        useEffect(() => {
            if (autoFocus && inputRef) {
                //@ts-ignore
                inputRef.current.focus();
            }
        }, [autoFocus, inputRef]);

        return (
            <div>
                {label && (
                    <Label {...label} tooltipProps={tooltipProps}/>
                )}
                <HoverActions 
                    isHidden={(dropdownOpen || ((isHoverActionHidden === undefined) ? true : isHoverActionHidden ? true : false)) || error !== undefined} 
                    onEdit={canEdit ? onEditClick : undefined}
                    onRemove={handleClear ? handleClear : undefined}
                    onView={handleView ? handleView : undefined}
                >
                    <Autocomplete
                        // @ts-ignore
                        onChange={(e, value) => handleOnChange(e, value)}
                        multiple={(tokenField && value) ? true : false}
                        renderInput={(params) => {
                            return (
                                (tokenField && value) ? (
                                    <BasicTextInput
                                        {...params}
                                        multiline={multiline}
                                        placeholder={placeholder ? placeholder : ""}
                                        autoFilled={autoFilled}
                                        error={error}
                                        inputRef={inputRef}
                                        size={size}
                                        isLineItem={isLineItem}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Stack gap={1} direction="row" alignItems="center">
                                                    {autoFilled && <SrcTextIcon color={theme.INPUT} />}
                                                    {timeInput && <ClockIcon color={theme.INPUT} />}
                                                    {dropdownOpen ? (
                                                        <IconButton disableRipple onClick={() => setDropdownOpen(true)}>
                                                            <ChevronUp color={theme.INPUT} />
                                                        </IconButton>

                                                    ) : (
                                                        <IconButton disableRipple onClick={() => setDropdownOpen(false)}>
                                                            <ChevronDown color={theme.INPUT} />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                            ),
                                            startAdornment: (
                                                <>
                                                    {params.InputProps.startAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                ) : (
                                    <BasicTextInput
                                        {...params}
                                        autoFilled={autoFilled}
                                        error={error}
                                        multiline={multiline}
                                        placeholder={placeholder ? placeholder : ""}
                                        inputRef={inputRef}
                                        size={size}
                                        isLineItem={isLineItem}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Stack gap={1} direction="row" alignItems="center">
                                                    {autoFilled && <SrcTextIcon color={theme.INPUT} />}
                                                    {timeInput && <ClockIcon color={theme.INPUT} />}
                                                    {dropdownOpen ? (
                                                        <IconButton disableRipple onClick={() => setDropdownOpen(true)}>
                                                            <ChevronUp color={theme.INPUT} />
                                                        </IconButton>

                                                    ) : (
                                                        <IconButton disableRipple onClick={() => setDropdownOpen(false)}>
                                                            <ChevronDown color={theme.INPUT} />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                            )
                                        }}
                                        value={text ?? textLocal}
                                        onChange={(e) => setText ? setText(e.target.value) : setTextLocal(e.target.value)}
                                    />
                                )
                            )
                        }}
                        // @ts-ignore
                        renderTags={(itemList, getTagProps) => {
                            return (
                                itemList.map((item, index) => 
                                    typeof item === "string" ? (
                                        <Token label={item} {...getTagProps({ index })} />
                                    ) : (
                                        // @ts-ignore
                                        <Token label={item.label} {...getTagProps({ index })} />
                                    )
                                )
                            )
                        }}
                        {...other}
                        isOptionEqualToValue={optionEqualFunc}
                        filterOptions={(options, state) => createEntity ?
                            [createAddOption(), ...filterFunction(options, state)] :
                            filterFunction(options, state)
                        }
                        options={options}
                        freeSolo
                        //@ts-ignore
                        value={value ?? ""}
                        ListboxProps={{ sx: listbox }}
                        renderOption={(props, option) => renderOption(props, option)}
                        getOptionDisabled={(option) => isOptionDisabled ? isOptionDisabled(option, value).isDisabled : (option as any).spinnerOption ? true : false}
                        open={open ? open : dropdownOpen}
                        onOpen={() => setDropdownOpen(true)}
                        onClose={() => setDropdownOpen(false)}
                        openOnFocus
                        disableClearable
                        selectOnFocus
                        ref={ref}
                        id={label?.inputId}
                    />
                </HoverActions>
            </div>
        )
    }
);

const EllipsisBox = styled(Box)({
    "& div": {
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
    }
})

const listbox = {
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
        ...LARGE_TEXT,
        color: color.GRAY_700,
        height: "6.1rem",
    },
    "& .MuiAutocomplete-option": {
        color: color.GRAY_700,
        backgroundColor: color.GRAY_50,
        "&[aria-selected=true]": {
            color: color.BLACK,
            backgroundColor: color.RED_100,
        },

    },
    "& .MuiAutocomplete-option.Mui-focused": {
        backgroundColor: theme.HOVER
    },
    "& .MuiAutocomplete-option[aria-selected=true].Mui-focused": {
        backgroundColor: theme.HOVER
    },
    "& li[aria-disabled=true]": {
        color: theme.PLACEHOLDER,
        background: theme.DISABLED,
        pointerEvents: "inherit !important",
        "&:active": {
            pointerEvents: "disabled"
        }
    }
};

const itemBox = {
    display: 'flex',
    justifyContent: 'space-between',
    component: 'span',
    width: "100%"
}

const ErrorIconContainer = styled('div')({
    zIndex: 1,
    marginLeft: "1rem"
})

export default ComboBox;