import { useState, useEffect, useContext } from "react";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import { Lender, LenderErrorState, LenderListItem } from "../../../../../libs/types/UniversalSurvey/Lender/lender";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { LenderTypeOptions } from "../../../../../libs/resources/options";
import { formatLenderType } from "../../../../../libs/utils/formatValues";
import { LenderType } from "../../../../../libs/resources/enums/lenderInstitutions";
import AddressGrid from "../../AddressGrid/AddressGrid";
import FinancialInfoGrid from "../../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { defaultLender } from "../../../../../libs/resources/defaults/frontend/defaultLender";
import { SourceLenderListItem } from "../../../../../libs/types/UniversalSurvey/Lender/SourceLender/sourceLender";
import { AdminContext } from "../../../../../context/AdminPanel/context";
import { getLenderSourceOptions } from "../../../../../context/AdminPanel/asyncActions/lenders";
import { SimpleSourceLender } from "../../../../../libs/types/UniversalSurvey/Lender/SourceLender/simpleSourceLender";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { AdminLoading, Loading } from "../../../../../libs/resources/enums/loading";
import { UniversalSurveyContext } from "../../../../../context/UniversalSurvey/context";
import { getUSLenderSourceOptions } from "../../../../../context/UniversalSurvey/asyncActions/lenders";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../../../libs/utils/limits";
import { AdminActionTypes } from "../../../../../context/AdminPanel/actionTypes";
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"

type Props = {
    type: "admin" | "deal";
    lender?: Lender;
    setLender?: (lender: Lender) => void;
    lenderSourceId?: number;
    setLenderSourceId?: (id: number | undefined) => void;
    updateLenderInfo: <K extends keyof Lender, V extends Lender[K]>(key: K, value: V) => void;
    selectedRegionList: RegionListItem[]
    setSelectedRegionList: (regions: RegionListItem[]) => void;
    regions: RegionListItem[];
    errorState: LenderErrorState;
    setErrorState: (errorState: LenderErrorState) => void;
    financialInfoErrorState: FinancialInfoErrorState;
    setFinancialInfoErrorState: (errorState: FinancialInfoErrorState) => void;
};

export default function LenderForm(props: Props) {
    const {
        type,
        lender,
        lenderSourceId,
        setLenderSourceId,
        setLender,
        updateLenderInfo,
        selectedRegionList,
        setSelectedRegionList,
        regions,
        errorState,
        setErrorState,
        financialInfoErrorState,
        setFinancialInfoErrorState
    } = props;
    
    const [state, dispatch] = useContext(AdminContext);
    const [surveyState, surveyDispatch] = useContext(UniversalSurveyContext);
    const [lenderObj, setLenderObj] = useState<Lender>({ ...defaultLender });
    const [lenderOptionList, setLenderOptionList] = useState<SimpleSourceLender[]>([]);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [sortBy, setSortBy] = useState<"abbr_name" | "name" | "institution_number" | undefined>(undefined);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
 
    useEffect(() => {
        if (lender) setLenderObj(lender);
    }, [lender]);

    function makeQuery(): URLSearchParams | undefined {
        const query = new URLSearchParams();
        if (searchValue !== undefined) query.set("search", searchValue);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        query.set("type", "institution");
        if (sortBy) query.set("sort_by", sortBy);
        if (sortBy === "abbr_name") {
            query.set("sort_order", "desc");
        } else {
            query.set("sort_order", "asc");
        }
        return query;
    }
    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            switch(type) {
                case "deal":
                    getUSLenderSourceOptions(surveyDispatch, makeQuery());
                    break;
                case "admin":
                    getLenderSourceOptions(dispatch, makeQuery());
                    break;
            }
        }, 500);

        setTimeoutId(newTimeoutId);
    }, [searchValue]);

    useEffect(() => {
        switch(type) {
            case "deal":
                setLenderOptionList(surveyState.lenders.lenderSourceList);
                break;
            case "admin":
                setLenderOptionList(state.lenders.allLenderSources);
                break;
        }
    }, [state.lenders.allLenderSources, surveyState.lenders.lenderSourceList]);

    function getFieldLists(lenderOptionList: SourceLenderListItem[], field: keyof SourceLenderListItem) {
        let itemList: SourceLenderListItem[] = [];
        for (const item of lenderOptionList) {
            if (item[field] && !itemList.some(listItem => listItem.label === String(item[field]))) {
                if (String(item[field]).length > 0) {
                    itemList.push({...item, label: String(item[field]), id: item.id });
                }
            }
        }
        return itemList;
    }

    function handleLenderNameUpdate(value: LenderListItem) {
        let tempLenderObj = { ...lenderObj };
        if (value.institution_number) {
            tempLenderObj.institution_number = value.institution_number;
            tempLenderObj.abbr_name = value.abbr_name;
            tempLenderObj.type = LenderType.Institution;
        }
        tempLenderObj.name = value.name;
        if (setLenderSourceId) setLenderSourceId(value.id);
        if (setLender) setLender(tempLenderObj as Lender);
    }

    function handleAbbrNameUpdate(value: LenderListItem) {
        let tempLenderObj = { ...lenderObj };
        if (value.institution_number) {
            tempLenderObj.institution_number = value.institution_number;
            tempLenderObj.name = value.name;
            tempLenderObj.type = LenderType.Institution;
        }
        tempLenderObj.abbr_name = value.abbr_name;
        if (setLenderSourceId) setLenderSourceId(value.id);
        if (setLender) setLender(tempLenderObj as Lender);
    }

    function handleInstitutionUpdate(value: LenderListItem) {
        let tempLenderObj = { ...lenderObj };
        tempLenderObj.institution_number = value.institution_number;
        tempLenderObj.name = value.name;
        tempLenderObj.abbr_name = value.abbr_name;
        if (setLenderSourceId) setLenderSourceId(value.id);
        if (setLender) setLender(tempLenderObj as Lender);
    }

    function handleLenderTypeUpdate(value: LenderType | undefined) {
        let tempLenderObj = { ...lenderObj };
        if (value) {
            if (value === LenderType.Personal || LenderType.Private) {
                tempLenderObj.branch_number = undefined;
                tempLenderObj.institution_number = undefined;
            }
        }
        tempLenderObj.type = value;
        if (setLender) setLender(tempLenderObj as Lender);
    }

    function isOptionsLoading(): boolean {
        switch(type) {
            case "deal":
                return isObjectLoading(surveyState.dataSheet.objectsLoading, [Loading.LenderSources]);
            case "admin":
                return isObjectLoading(state.loading.objectsLoading, [AdminLoading.LenderSources]);
        }
    }

    function clearList() {
        switch(type) {
            case "deal":
                return surveyDispatch({ type: actions.SET_LENDER_SOURCES, payload: [] });
            case "admin":
                return dispatch({ type: AdminActionTypes.SET_ALL_LENDERS, payload: [] });
        }
    }

    return (
        <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item xs={8}>
                <ComboBox
                    options={getFieldLists(lenderOptionList, "name")}
                    onFocus={() => {
                        clearList();
                        setSearchValue(lenderObj.name ?? "");
                        setSortBy("name");
                    }}
                    onBlur={() => setSearchValue(undefined)}
                    value={lenderObj.name ?? undefined}
                    label={{ text: "Lender Name", inputId: "name", isRequired: true }}
                    placeholder={"Name"}
                    onInputChange={(_, value) => {
                        updateLenderInfo("name", value as string);
                        setSearchValue(value);
                    }}
                    onChangeFn={(value) => handleLenderNameUpdate(value as LenderListItem)}
                    isHoverActionHidden={lenderObj.name ? false : true}
                    handleClear={() => {
                        updateLenderInfo("name", undefined);
                        setLenderSourceId ? setLenderSourceId(undefined) : undefined;
                    }}
                    isLoading={isOptionsLoading()}
                    searchableKeys={["name"]}
                />
            </Grid>
            <Grid item xs={4}>
                <ComboBox
                    options={getFieldLists(lenderOptionList, "abbr_name")}
                    value={lenderObj.abbr_name ?? undefined}
                    label={{ text: "Abbreviated Name", inputId: "abbr_name" }}
                    placeholder={"Abbreviated name"}
                    onFocus={() => {
                        clearList();
                        setSearchValue(lenderObj.abbr_name ?? "");
                        setSortBy("abbr_name");
                    }}
                    onBlur={() => setSearchValue(undefined)}
                    onInputChange={(_, value) => {
                        updateLenderInfo("abbr_name", value as string);
                        setSearchValue(value);
                    }}
                    onChangeFn={(value) => handleAbbrNameUpdate(value as LenderListItem)}
                    isHoverActionHidden={lenderObj.abbr_name ? false : true}
                    handleClear={() => {
                        updateLenderInfo("abbr_name", undefined);
                        setLenderSourceId ? setLenderSourceId(undefined) : undefined;
                    }}
                    isLoading={isOptionsLoading()}
                />
            </Grid>
            <Grid item xs={4}>
                <Dropdown
                    label={{ text: "Lender Type", isRequired: true }}
                    readOnly={lenderSourceId ? true : false}
                    value={lenderObj.type}
                    onChange={(e) => handleLenderTypeUpdate(e.target.value as LenderType)}
                    placeholder="Select an option..."
                    options={LenderTypeOptions}
                    formatValue={formatLenderType}
                    isHoverActionHidden={true}
                    data-testid="lender-modal-lender-type-drop-down"
                />
            </Grid>
            {lenderObj.type === "institution" ? (
                <>
                    <Grid item xs={4}>
                        <ComboBox
                            options={getFieldLists(lenderOptionList, "institution_number")}
                            value={lenderObj.institution_number ?? undefined}
                            onFocus={() => {
                                clearList();
                                setSearchValue(lenderObj.institution_number ?? "");
                                setSortBy("institution_number");
                            }}
                            onBlur={() => setSearchValue(undefined)}
                            label={{ text: "Inst. No.", inputId: "institution_number" }}
                            placeholder={"000"}
                            onInputChange={(_, value) => {
                                updateLenderInfo("institution_number", value as string)
                                setSearchValue(value);
                            }}
                            onChangeFn={(value) => handleInstitutionUpdate(value as LenderListItem)}
                            isHoverActionHidden={lenderObj.institution_number ? false : true}
                            handleClear={() => {
                                updateLenderInfo("institution_number", undefined);
                                setLenderSourceId ? setLenderSourceId(undefined) : undefined;
                            }}
                            isLoading={isOptionsLoading()}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            value={lenderObj.branch_number ?? undefined}
                            onChange={(e) => updateLenderInfo("branch_number", e.target.value)}
                            label={{ text: "Branch No.", inputId: "branch_number" }}
                            placeholder="00000"
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={8}/>
            )}
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={lenderObj.general_contact_name ?? undefined}
                    onChange={(e) => updateLenderInfo("general_contact_name", e.target.value)}
                    label={{text: "General Contact Name", inputId: "general_contact_name"}}
                    placeholder="Contact's name"
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={lenderObj.general_email ?? undefined}
                    onChange={(e) => updateLenderInfo("general_email", e.target.value)}
                    label={{text: "General Contact Email", inputId: "general_email"}}
                    placeholder="Contact's email"
                    fieldType="email"
                    error={{
                        showError: true,
                        errorKey: "general_email",
                        errorState: errorState,
                        setErrorState: setErrorState
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressGrid
                    address={lenderObj.general_address}
                    setAddress={(value) => updateLenderInfo("general_address", value)}
                    id="general_address"
                    title="General Contact Address"
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={lenderObj.final_report_contact_name ?? undefined}
                    onChange={(e) => updateLenderInfo("final_report_contact_name", e.target.value)}
                    label={{text: "Final Report Contact Name", inputId: "final_report_contact_name"}}
                    placeholder="Contact's name"
                />
            </Grid>
            <Grid item xs={6}>
                <BasicTextInput
                    fullWidth
                    value={lenderObj.final_report_email ?? undefined}
                    onChange={(e) => updateLenderInfo("final_report_email", e.target.value)}
                    label={{text: "Final Report Email", inputId: "final_report_email"}}
                    placeholder="Contact's email"
                    fieldType="email"
                    error={{
                        showError: true,
                        errorKey: "final_report_email",
                        errorState: errorState,
                        setErrorState: setErrorState
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressGrid
                    address={lenderObj.final_report_address}
                    setAddress={(value) => updateLenderInfo("final_report_address", value)}
                    id="final_report_address"
                    title="Final Report Address"
                    copyAddress={{ address: lenderObj.general_address, description: "General Contact Address" }}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressGrid
                    address={lenderObj.address_for_service}
                    setAddress={(value) => updateLenderInfo("address_for_service", value)}
                    id="address_for_service"
                    title="Address for Service"
                    copyAddress={{ address: lenderObj.general_address, description: "General Contact Address" }}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressGrid
                    address={lenderObj.address_for_registration}
                    setAddress={(value) => updateLenderInfo("address_for_registration", value)}
                    id="address_for_registration"
                    title="Address for Registration"
                    copyAddress={{ address: lenderObj.general_address, description: "General Contact Address" }}
                />
            </Grid>
            <Grid item xs={12}>
                <FinancialInfoGrid
                    financialInfo={lenderObj.financial_info}
                    setFinancialInfo={(value) => updateLenderInfo("financial_info", value)}
                    errorState={financialInfoErrorState}
                    setErrorState={setFinancialInfoErrorState}
                    id="financial_info"
                    title="Financial Information"
                />
            </Grid>
        </Grid>
    );
}