// Importing built-in React/Router tools
import { useContext } from 'react';

// Importing state management
import { AdminContext } from '../../../../context/AdminPanel/context';

// Importing application components
import { adminControlOptions } from '../../../../libs/resources/mockControlOptions';
import GridControlForm from './GridControlForm';
import { navigateAdminURL } from 'conveyance/context/AdminPanel/asyncActions/navigation';

export default function AdminGridControl() {
    const [state, dispatch] = useContext(AdminContext);

    function handleSectionChange(title: string) {
        navigateAdminURL(dispatch, title);
    }

    return (
        <GridControlForm
            options={adminControlOptions}
            handleSectionChange={handleSectionChange}
            activeSection={state.navigation.currSection}
        />
    );
}