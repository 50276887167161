import { LegalProfessional } from "../../../types/UniversalSurvey/LegalProfessional/legalProfessional"
import { LegalProfessionalType } from "../../enums/legalProfessionalType"
import { VerificationStatus } from "../../enums/verificationStatus"

export const defaultLegalProfessional: LegalProfessional = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    initial: undefined,
    all_regions: undefined,
    email: undefined,
    phone: undefined,
    phone_extension: undefined,
    type: LegalProfessionalType.Lawyer,
    date_created: undefined,
    date_updated: undefined,

    legal_professional: undefined,
    legal_firm_record: undefined,
    legal_firm_office_record: undefined,
    regions: []
}
