import { SimpleGovernment } from "../../../types/UniversalSurvey/Government/simpleGovernment";
import { GovernmentLevels } from "../../enums/governmentLevels";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultSimpleGovernment: SimpleGovernment = {
    level: GovernmentLevels.Municipal,
    id: -1,
    name: undefined,
    abbr_name: undefined,
    department: undefined,
    general_address: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    government: undefined
}