import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    newCondition,
    saveCondition,
    getConditions,
    getCondition,
    createUndertaking,
    saveUndertaking,
    getUndertaking,
    getDealLien,
    deleteCondition,
    saveDealLien,
    newDealLien,
    getDebt,
    saveDebt,
    newDebt,
    saveAffidavit,
    newAffidavit,
    getAffidavit,
    saveDealStatDec,
    newDealStatDec,
    getDealStatDec,
    newLenderRecord,
    saveLenderRecord,
    createDealChargeHolderCompany,
    saveDealChargeHolderCompany,
    createDealGovernment,
    saveDealGovernment,
    newMortgageBrokerageRecord,
    saveMortgageBrokerageRecord,
    createDealOtherChargeHolder,
    saveDealOtherChargeHolder
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeAffidavitRequest, sanitizeConditionRequest, sanitizeDebtRequest, sanitizeLienRequest, sanitizeStatDecRequest, sanitizeUndertakingRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import {
    sanitizeAffidavitResponse,
    sanitizeConditionResponse,
    sanitizeDebtResponse,
    sanitizeLienResponse,
    sanitizeSimpleConditionsResponse,
    sanitizeStatDecResponse,
    sanitizeUndertakingResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AttachConditions, Condition, SupplementAttachments } from "../../../libs/types/UniversalSurvey/Condition/condition";
import { SimpleCondition } from "../../../libs/types/UniversalSurvey/Condition/simpleCondition";
import { Lien } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { Debt } from "../../../libs/types/UniversalSurvey/Debts/debt";
import { Undertaking } from "../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { StatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { Affidavit } from "../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { addStatDecClients, removeStatDec, updateStatDecClients } from "./statutoryDeclarations";
import { removeUndertaking } from "./undertakings";
import { deleteExistingLien } from "./existingLiens";
import { removeAffidavit } from "./affidavits";
import { removeDebt } from "./debts";
import { convertToSimple } from "../../../libs/types/UniversalSurvey/utils/convertToSimple";
import { defaultSimpleUndertaking } from "../../../libs/resources/defaults/frontend/defaultSimpleUndertaking";
import { defaultSimpleLien } from "../../../libs/resources/defaults/frontend/defaultSimpleLien";
import { defaultSimpleDebt } from "../../../libs/resources/defaults/frontend/defaultSimpleDebt";
import { defaultSimpleAffidavit } from "../../../libs/resources/defaults/frontend/defaultSimpleAffidavit";
import { defaultSimpleStatDec } from "../../../libs/resources/defaults/frontend/defaultSimpleStatDec";
import { DebtHolderTypes } from "../../../libs/resources/enums/debtHolderTypes";
import { defaultLenderRequest } from "../../../libs/resources/defaults/request/defaultLenderRequest";
import { defaultChargeHolderCompanyRequest } from "../../../libs/resources/defaults/request/defaultChargeHolderCompanyRequest";
import { defaultGovernmentRequest } from "../../../libs/resources/defaults/request/defaultGovernmentRequest";
import { defaultMortgageBrokerageRequest } from "../../../libs/resources/defaults/request/defaultMortgageBrokerageRequest";
import { defaultOtherChargeHolderRequest } from "../../../libs/resources/defaults/request/defaultOtherChargeHolderRequest";
import { ChargeHolderType } from "../../../libs/resources/enums/chargeHolderType";
import { defaultSimpleLender } from "../../../libs/resources/defaults/frontend/defaultSimpleLender";
import { defaultSimpleGovernment } from "../../../libs/resources/defaults/frontend/defaultSimpleGovernment";
import { defaultSimpleChargeHolderCompany } from "../../../libs/resources/defaults/frontend/defaultSimpleChargeHolderCompany";
import { defaultSimpleOtherChargeHolder } from "../../../libs/resources/defaults/frontend/defaultSimpleOtherChargeHolder";
import { defaultDebtHolder } from "../../../libs/resources/defaults/frontend/defaultDebtHolder";
import { Loading } from "../../../libs/resources/enums/loading";

async function addCondition(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    conditionInfo: Condition,
    attachments: AttachConditions,
    supplementAttachments: SupplementAttachments,
    newDebtHolderName: string,
    newDebtHolderType: DebtHolderTypes | undefined
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondition, isLoading: true } });
    if (newDebtHolderType) {
        const newDebtHolder = await createNewDebtHolder(dispatch, dealId, newDebtHolderName, newDebtHolderType);
        if (attachments.lien && newDebtHolderType !== DebtHolderTypes.MortgageBrokerage && newDebtHolder) {
            addDebtHolderToLien(attachments.lien, newDebtHolder, newDebtHolderType);
        } else if (attachments.debt && newDebtHolder) {
            attachments.debt.debt_holder_id = newDebtHolder.id;
            attachments.debt.charge_holder_type = newDebtHolderType;
            attachments.debt.debt_holder_record = { ...defaultDebtHolder, id: newDebtHolder.id, name: newDebtHolder.name };
        }
    }

    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.undertaking_id = await handleUndertaking(dispatch, dealId, conditionInfo, attachments.undertaking);
    tempCondition.lien_id = await handleLien(dispatch, dealId, conditionInfo, attachments.lien);
    tempCondition.debt_id = await handleDebt(dispatch, dealId, conditionInfo, attachments.debt);
    tempCondition.affidavit_id = await handleAffidavit(dispatch, dealId, conditionInfo, attachments.affidavit, supplementAttachments.client);
    tempCondition.stat_dec_id = await handleStatDec(dispatch, dealId, conditionInfo, attachments.stat_dec, supplementAttachments.client_list);

    tempCondition.undertaking = attachments.undertaking ? convertToSimple(attachments.undertaking, defaultSimpleUndertaking) : undefined;
    tempCondition.lien = attachments.lien ? convertToSimple(attachments.lien, defaultSimpleLien) : undefined;
    tempCondition.debt = attachments.debt ? convertToSimple(attachments.debt, defaultSimpleDebt) : undefined;
    tempCondition.affidavit = attachments.affidavit ? convertToSimple(attachments.affidavit, defaultSimpleAffidavit) : undefined;
    tempCondition.stat_dec = attachments.stat_dec ? convertToSimple(attachments.stat_dec, defaultSimpleStatDec) : undefined;

    newCondition(dealId)
        .then(function (response: any) {
            tempCondition.id = response.data.id;
            saveCondition(dealId, String(response.data.id), sanitizeConditionRequest(tempCondition))
            .then(function () {
                dispatch({ type: actions.ADD_CONDITION, payload: tempCondition });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving condition: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondition, isLoading: false } });
            })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondition, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating condition: ${error}`, type: AlertTypes.Error } });
        })
}

async function updateCondition(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    conditionInfo: Condition,
    attachments: AttachConditions,
    supplementAttachments: SupplementAttachments,
    newDebtHolderName: string,
    newDebtHolderType: DebtHolderTypes | undefined
) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondition, isLoading: true } });
    if (newDebtHolderType) {
        const newDebtHolder = await createNewDebtHolder(dispatch, dealId, newDebtHolderName, newDebtHolderType);
        if (attachments.lien && newDebtHolderType !== DebtHolderTypes.MortgageBrokerage && newDebtHolder) {
            addDebtHolderToLien(attachments.lien, newDebtHolder, newDebtHolderType);
        } else if (attachments.debt && newDebtHolder) {
            attachments.debt.debt_holder_id = newDebtHolder.id;
            attachments.debt.charge_holder_type = newDebtHolderType;
            attachments.debt.debt_holder_record = { ...defaultDebtHolder, id: newDebtHolder.id, name: newDebtHolder.name };
        }
    }

    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.undertaking_id = await handleUndertaking(dispatch, dealId, conditionInfo, attachments.undertaking);
    tempCondition.lien_id = await handleLien(dispatch, dealId, conditionInfo, attachments.lien);
    tempCondition.debt_id = await handleDebt(dispatch, dealId, conditionInfo, attachments.debt);
    tempCondition.affidavit_id = await handleAffidavit(dispatch, dealId, conditionInfo, attachments.affidavit, supplementAttachments.client);
    tempCondition.stat_dec_id = await handleStatDec(dispatch, dealId, conditionInfo, attachments.stat_dec, supplementAttachments.client_list);

    tempCondition.undertaking = attachments.undertaking ? convertToSimple(attachments.undertaking, defaultSimpleUndertaking) : undefined;
    tempCondition.lien = attachments.lien ? convertToSimple(attachments.lien, defaultSimpleLien) : undefined;
    tempCondition.debt = attachments.debt ? convertToSimple(attachments.debt, defaultSimpleDebt) : undefined;
    tempCondition.affidavit = attachments.affidavit ? convertToSimple(attachments.affidavit, defaultSimpleAffidavit) : undefined;
    tempCondition.stat_dec = attachments.stat_dec ? convertToSimple(attachments.stat_dec, defaultSimpleStatDec) : undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_SHOULD_REFRESH_LIEN, payload: true });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving condition: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveCondition, isLoading: false } });
    })
}

function addDebtHolderToLien(lien: Lien, newDebtHolder: { id: number, name: string }, newDebtHolderType: DebtHolderTypes) {
    lien.charge_holder_type = newDebtHolderType as unknown as ChargeHolderType
    switch (newDebtHolderType) {
        case DebtHolderTypes.Lender:
            lien.lender_record = convertToSimple(newDebtHolder, defaultSimpleLender);
            lien.lender_id = newDebtHolder.id;
            break;
        case DebtHolderTypes.Government:
            lien.government_record = convertToSimple(newDebtHolder, defaultSimpleGovernment);
            lien.government_id = newDebtHolder.id;
            break;
        case DebtHolderTypes.Company:
            lien.charge_holder_company_record = convertToSimple(newDebtHolder, defaultSimpleChargeHolderCompany);
            lien.charge_holder_company_id = newDebtHolder.id;
            break;
        case DebtHolderTypes.Other:
            lien.other_charge_holder_record = convertToSimple(newDebtHolder, defaultSimpleOtherChargeHolder);
            lien.other_charge_holder_id = newDebtHolder.id;
            break;
    }
}

// Updating/submitting attached entities
async function handleUndertaking(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, undertaking: Undertaking | undefined): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve) => {
        if (undertaking) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: true } });
            if (conditionInfo.undertaking_id) {
                saveUndertaking(dealId, String(conditionInfo.undertaking_id), sanitizeUndertakingRequest(undertaking))
                .then(function () {
                    dispatch({ type: actions.UPDATE_UNDERTAKING, payload: undertaking });
                    resolve(undertaking.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving undertaking: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
                })
            } else {
                createUndertaking(dealId)
                .then(function (response: any) {
                    saveUndertaking(dealId, String(response.data.id), sanitizeUndertakingRequest(undertaking))
                    .then(function () {
                        dispatch({ type: actions.ADD_UNDERTAKING, payload: { ...undertaking, id: response.data.id } });
                        resolve(response.data.id);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving undertaking: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
                    })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveUndertaking, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating undertaking: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
            }
        } else resolve(undefined);
    })
}

async function handleLien(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, lien: Lien | undefined): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve) => {
        if (lien) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLien, isLoading: true } });
            if (conditionInfo.lien_id) {
                saveDealLien(dealId, String(conditionInfo.lien_id), sanitizeLienRequest(lien))
                .then(function () {
                    dispatch({ type: actions.SET_SHOULD_REFRESH_LIEN, payload: true });
                    resolve(lien.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving lien: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLien, isLoading: false } });
                })
            } else {
                newDealLien(dealId)
                .then(function (response: any) {
                    saveDealLien(dealId, String(response.data.id), sanitizeLienRequest(lien))
                    .then(function () {
                        resolve(response.data.id);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving lien: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLien, isLoading: false } });
                    })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLien, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating lien: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
            }
        } else resolve(undefined);
    })
}

async function handleDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, debt: Debt | undefined): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve) => {
        if (debt) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: true } });
            if (conditionInfo.debt_id) {
                saveDebt(dealId, String(conditionInfo.debt_id), sanitizeDebtRequest(debt))
                .then(function () {
                    resolve(debt.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving debt: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
                })
            } else {
                newDebt(dealId)
                .then(function (response: any) {
                    saveDebt(dealId, String(response.data.id), sanitizeDebtRequest(debt))
                    .then(function () {
                        resolve(response.data.id);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving debt: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
                    })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveDebt, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating debt: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
            }
        } else resolve(undefined);
    })
}

async function handleAffidavit(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    conditionInfo: Condition,
    affidavit: Affidavit | undefined,
    selectedClient: SimpleClient | undefined
): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve) => {
        if (affidavit) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: true } });
            if (conditionInfo.affidavit_id) {
                saveAffidavit(dealId, String(conditionInfo.affidavit_id), sanitizeAffidavitRequest(affidavit))
                .then(function () {
                    dispatch({ type: actions.UPDATE_AFFIDAVIT, payload: { affidavit: affidavit, client: selectedClient } });
                    resolve(affidavit.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving affidavit: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
                })
            } else {
                newAffidavit(dealId)
                .then(function (response: any) {
                    saveAffidavit(dealId, String(response.data.id), sanitizeAffidavitRequest(affidavit))
                    .then(function () {
                        let newAffidavit: Affidavit = { ...affidavit, id: response.data.id, client_record: selectedClient };
                        dispatch({ type: actions.ADD_AFFIDAVIT, payload: newAffidavit });
                        resolve(response.data.id);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving affidavit: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
                    })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating affidavit: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
            }
        } else resolve(undefined);
    })
}

async function handleStatDec(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    conditionInfo: Condition,
    statDec: StatDec | undefined,
    selectedClients: (SimpleClient & { label: string })[]
): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve) => {
        if (statDec) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: true } });
            if (conditionInfo.stat_dec_id) {
                saveDealStatDec(dealId, String(conditionInfo.stat_dec_id), sanitizeStatDecRequest(statDec))
                .then(function () {
                    dispatch({ type: actions.UPDATE_DECLARATION, payload: { declaration: statDec, clients: selectedClients } });
                    updateStatDecClients(dispatch, dealId, statDec.our_lawyer ? [] : selectedClients, statDec);
                    resolve(statDec.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving statutory declaration: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
                })
            } else {
                newDealStatDec(dealId)
                .then(function (response: any) {
                    saveDealStatDec(dealId, String(response.data.id), sanitizeStatDecRequest(statDec))
                    .then(function () {
                        dispatch({ type: actions.ADD_DECLARATION, payload: { ...statDec, id: response.data.id, client_list: selectedClients } });
                        if (!statDec.our_lawyer) addStatDecClients(dispatch, dealId, selectedClients, response.data.id);
                        resolve(response.data.id);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving statutory declaration: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
                    .finally(function () {
                        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
                    })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating statutory declaration: ${error}`, type: AlertTypes.Error } });
                    resolve(undefined);
                })
            }
        } else resolve(undefined);
    })
}

// Attaching lien to condition
function attachLienToCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, condition: SimpleCondition, lienId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLinkedCondition, isLoading: true } });
    getCondition(dealId, String(condition.id))
    .then(function (response: any) {
        let tempCondition = sanitizeConditionResponse(response.data);
        tempCondition.lien_id = lienId;
        saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
        .then(function () {
            dispatch({ type: actions.SET_LIEN_CONDITION, payload: condition });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving condition: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLinkedCondition, isLoading: false } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLinkedCondition, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condition: ${error}`, type: AlertTypes.Error } });
    })
}

// Deleting attached entities
function deleteUndertakingCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, undertakingId: number) {
    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.undertaking_id = undefined;
    tempCondition.undertaking = undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_CONDITION_UNDERTAKING, payload: undefined });
        removeUndertaking(dispatch, dealId, undertakingId);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting undertaking: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteLienCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, lienId: number) {
    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.lien_id = undefined;
    tempCondition.lien = undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_CONDITION_LIEN, payload: undefined });
        deleteExistingLien(dispatch, dealId, lienId);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting lien: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteStatDecCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, statDecId: number) {
    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.stat_dec_id = undefined;
    tempCondition.stat_dec = undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_CONDITION_STAT_DEC, payload: undefined });
        removeStatDec(dispatch, dealId, statDecId);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting statutory declaration: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteAffidavitCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, affidavitId: number) {
    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.affidavit_id = undefined;
    tempCondition.affidavit = undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_CONDITION_AFFIDAVIT, payload: undefined });
        removeAffidavit(dispatch, dealId, affidavitId);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting affidavit: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteDebtCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionInfo: Condition, debtId: number) {
    let tempCondition: Condition = { ...conditionInfo };
    tempCondition.debt_id = undefined;
    tempCondition.debt = undefined;

    saveCondition(dealId, String(tempCondition.id), sanitizeConditionRequest(tempCondition))
    .then(function () {
        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: tempCondition } });
        dispatch({ type: actions.SET_CONDITION_DEBT, payload: undefined });
        removeDebt(dispatch, dealId, debtId);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting debt: ${error}`, type: AlertTypes.Error } });
    })
}

type EntityType = "affidavit" | "statDec" | "undertaking" | "debt";
function deleteEntityFromOwnModal(dispatch: Dispatch<Record<string, any>>, dealId: string, entityType: EntityType, entityId: number, condition?: SimpleCondition) {
    if (condition) {
        getCondition(dealId, String(condition.id))
            .then(function (response: any) {
                const condition: Condition =  sanitizeConditionResponse(response.data);
                removeEntityFromCondition(condition, entityType);
                saveCondition(dealId, String(condition.id), sanitizeConditionRequest(condition))
                    .then(function () {
                        dispatch({ type: actions.UPDATE_CONDITION, payload: { condition: condition } });
                        deleteEntity(dispatch, dealId, entityType, entityId);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save condition: ${error}`, type: AlertTypes.Error } });
                    })
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condition: ${error}`, type: AlertTypes.Error } });
            })
    } else {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete entity: can't find linked condition`, type: AlertTypes.Error } });
    }
}

function removeEntityFromCondition(condition: Condition, entityType: EntityType) {
    switch (entityType) {
        case "affidavit":
            condition.affidavit = undefined;
            condition.affidavit_id = undefined;
            return;
        case "statDec":
            condition.stat_dec = undefined;
            condition.stat_dec_id = undefined;
            return;
        case "undertaking":
            condition.undertaking = undefined;
            condition.undertaking_id = undefined;
            return;
        case "debt":
            condition.debt = undefined;
            condition.debt_id = undefined;
            return;
        default:
            return;
    }
}

function deleteEntity(dispatch: Dispatch<Record<string, any>>, dealId: string, entityType: EntityType, entityId: number) {
    switch (entityType) {
        case "affidavit":
            removeAffidavit(dispatch, dealId, entityId);
            break
        case "statDec":
            removeStatDec(dispatch, dealId, entityId)
            break
        case "undertaking":
            removeUndertaking(dispatch, dealId, entityId);
            break
        case "debt":
            removeDebt(dispatch, dealId, entityId);
            break
        default:
            return;
    }
}

// Getting attached entities
function getConditionLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionLien, isLoading: true } });
    getDealLien(dealId, String(lienId))
    .then(function (response: any) {
        const cleanLien = sanitizeLienResponse(response.data);
        dispatch({ type: actions.SET_CONDITION_LIEN, payload: cleanLien });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get lien: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionLien, isLoading: false } });
    })
}

function getConditionUndertaking(dispatch: Dispatch<Record<string, any>>, dealId: string, undertakingId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionUndertaking, isLoading: true } });
    getUndertaking(dealId, String(undertakingId))
    .then(function (response: any) {
        const cleanUndertaking = sanitizeUndertakingResponse(response.data);
        dispatch({ type: actions.SET_CONDITION_UNDERTAKING, payload: cleanUndertaking });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get undertaking: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionUndertaking, isLoading: false } });
    })
}

function getConditionDebt(dispatch: Dispatch<Record<string, any>>, dealId: string, debtId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionDebt, isLoading: true } });
    getDebt(dealId, String(debtId))
    .then(function (response: any) {
        const cleanDebt = sanitizeDebtResponse(response.data);
        dispatch({ type: actions.SET_CONDITION_DEBT, payload: cleanDebt });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get debt: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionDebt, isLoading: false } });
    })
}

function getConditionAffidavit(dispatch: Dispatch<Record<string, any>>, dealId: string, affidavitId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionAffidavit, isLoading: true } });
    getAffidavit(dealId, String(affidavitId))
    .then(function (response: any) {
        const cleanAffidavit = sanitizeAffidavitResponse(response.data);
        dispatch({ type: actions.SET_CONDITION_AFFIDAVIT, payload: cleanAffidavit });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get affidavit: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionAffidavit, isLoading: false } });
    })
}

function getConditionStatDec(dispatch: Dispatch<Record<string, any>>, dealId: string, statDecId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionStatDec, isLoading: true } });
    getDealStatDec(dealId, String(statDecId))
    .then(function (response: any) {
        const cleanStatDec = sanitizeStatDecResponse(response.data);
        dispatch({ type: actions.SET_CONDITION_STAT_DEC, payload: cleanStatDec });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get stat dec: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionStatDec, isLoading: false } });
    })
}

function getConditionsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionList, isLoading: true } });
    getConditions(dealId)
        .then(function (response: any) {
            const conditions: SimpleCondition[] = sanitizeSimpleConditionsResponse(response.data);
            dispatch({ type: actions.SET_CONDITIONS_IN_DEAL, payload: conditions });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get conditions: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionList, isLoading: false } });
        })
}

function getConditionBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, conditionId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionModal, isLoading: true } });
    getCondition(dealId, String(conditionId))
    .then(function (response: any) {
        dispatch({ type: actions.SET_CONDITION_BEING_EDITED, payload: sanitizeConditionResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get condition: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ConditionModal, isLoading: false } });
    })
}

function removeCondition(dispatch: Dispatch<Record<string, any>>, dealId: string, condition: Condition) {
    deleteCondition(dealId, String(condition.id))
        .then(function () {
            dispatch({ type: actions.REMOVE_CONDITION, payload: condition.id });

            if (condition.stat_dec_id) dispatch({ type: actions.REMOVE_DECLARATION, payload: condition.stat_dec_id });
            if (condition.affidavit_id) dispatch({ type: actions.REMOVE_AFFIDAVIT, payload: condition.affidavit_id });
            if (condition.lien_id) dispatch({ type: actions.REMOVE_LIEN_IN_DEAL, payload: condition.lien_id });
            if (condition.debt_id) dispatch({ type: actions.REMOVE_DEBT, payload: condition.debt_id });
            if (condition.undertaking_id) dispatch({ type: actions.REMOVE_UNDERTAKING, payload: condition.undertaking_id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete condition: ${error}`, type: AlertTypes.Error } });
        })
}

async function createNewDebtHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, newDebtHolderName: string, newDebtHolderType: DebtHolderTypes): Promise<{ id: number, name: string } | undefined> {
    return new Promise<{ id: number, name: string } | undefined>((resolve) => {
        switch (newDebtHolderType) {
            case DebtHolderTypes.Company:
                createDealChargeHolderCompany(dealId)
                    .then(function (response: any) {
                        saveDealChargeHolderCompany(dealId, response.data.id, { ...defaultChargeHolderCompanyRequest, name: newDebtHolderName })
                        .then(function () {
                            resolve({ id: response.data.id, name: newDebtHolderName });
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving company record: ${error}`, type: AlertTypes.Error } });
                        })
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating company record: ${error}`, type: AlertTypes.Error } });
                    })
                return;

            case DebtHolderTypes.Government:
                (dealId)
                    createDealGovernment(dealId)
                    .then(function (response: any) {
                        saveDealGovernment(dealId, response.data.id, { ...defaultGovernmentRequest, name: newDebtHolderName })
                        .then(function () {
                            resolve({ id: response.data.id, name: newDebtHolderName });
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving government record: ${error}`, type: AlertTypes.Error } });
                        })
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating government record: ${error}`, type: AlertTypes.Error } });
                    })
                return;

            case DebtHolderTypes.Lender:
                newLenderRecord(dealId)
                    .then(function (response: any) {
                        saveLenderRecord(dealId, response.data.id, { ...defaultLenderRequest, name: newDebtHolderName })
                        .then(function () {
                            resolve({ id: response.data.id, name: newDebtHolderName });
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving lender record: ${error}`, type: AlertTypes.Error } });
                        })
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating lender record: ${error}`, type: AlertTypes.Error } });
                    })
                return;

            case DebtHolderTypes.MortgageBrokerage:
                newMortgageBrokerageRecord(dealId)
                    .then(function (response: any) {
                        saveMortgageBrokerageRecord(dealId, response.data.id, { ...defaultMortgageBrokerageRequest, name: newDebtHolderName })
                        .then(function () {
                            resolve({ id: response.data.id, name: newDebtHolderName });
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
                        })
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating mortgage brokerage record: ${error}`, type: AlertTypes.Error } });
                    })
                return;

            case DebtHolderTypes.Other:
                createDealOtherChargeHolder(dealId)
                    .then(function (response: any) {
                        saveDealOtherChargeHolder(dealId, response.data.id, { ...defaultOtherChargeHolderRequest, name: newDebtHolderName })
                        .then(function () {
                            resolve({ id: response.data.id, name: newDebtHolderName });
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving other charge holder record: ${error}`, type: AlertTypes.Error } });
                        })
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating other charge holder record: ${error}`, type: AlertTypes.Error } });
                    })
                return;

            default:
                return;
        }
    })
}

export {
    addCondition,
    updateCondition,
    getConditionsInDeal,
    getConditionBeingEdited,
    removeCondition,
    getConditionLien,
    getConditionUndertaking,
    getConditionDebt,
    getConditionAffidavit,
    getConditionStatDec,
    deleteUndertakingCondition,
    deleteLienCondition,
    deleteStatDecCondition,
    deleteAffidavitCondition,
    deleteDebtCondition,
    deleteEntityFromOwnModal,
    attachLienToCondition
}