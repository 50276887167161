import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { attachLienToCondition, getConditionsInDeal } from "../../../../context/UniversalSurvey/asyncActions/conditions";
import { ConditionType } from "../../../../libs/resources/enums/conditionType";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {};

export default function LinkConditionModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [selectedCondition, setSelectedCondition] = useState<string | undefined>(undefined);

    useEffect(() => {
        getConditionsInDeal(dispatch, String(state.deal.dealInfo?.id));
    }, []);

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        setSelectedCondition(undefined);
    }

    function submit() {
        const condition = state.conditions.conditionsInDeal.find((condition) => condition.condition === selectedCondition);
        if (condition) {
            attachLienToCondition(dispatch, String(state.deal.dealInfo?.id), condition, state.existingLiens.existingLienInfo!.id);
        }
    }

    function isSaveDisabled(): boolean {
        if (!selectedCondition) return true;
        return false;
    }

    function getConditionOptions(): string[] {
        const options: string[] = [];
        for(const condition of state.conditions.conditionsInDeal) {
            if (condition.type === ConditionType.Discharge && !condition.lien) {
                options.push(condition.condition ?? "");
            }
        }
        return options;
    }

    return (
        <ModalBase
            title="Link Condition"
            open={props.open}
            onClose={() => onDiscard()}
            onSubmit={submit}
            saveDisabled={isSaveDisabled()}
            discardButtonLabel="Cancel"
            saveButtonLabel="Link"
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLinkedCondition])}
        >
            <>
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12} >
                        <Dropdown
                            autoFocus
                            options={getConditionOptions()}
                            placeholder="Select a condition..."
                            value={selectedCondition ?? ""}
                            onChange={(e) => setSelectedCondition(e.target.value as string)}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}