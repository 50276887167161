import { SimpleCondition } from "../../../types/UniversalSurvey/Condition/simpleCondition";

export const defaultSimpleCondition: SimpleCondition = {
    id: -1,
    type: undefined,
    condition: undefined,
    condition_met: false,
    source: undefined,

    undertaking: undefined,
    debt: undefined,
    stat_dec: undefined,
    affidavit: undefined,
    lien: undefined
}