import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import { Affidavit } from "../../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { defaultAffidavit } from "../../../../libs/resources/defaults/frontend/defaultAffidavit";
import { SimpleClient } from "../../../../libs/types/UniversalSurvey/Client/simpleClient";
import { addAffidavit, updateAffidavit } from "../../../../context/UniversalSurvey/asyncActions/affidavits";
import { getClientTabName, getDealClients } from "../../../../context/UniversalSurvey/asyncActions/clients";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import { deleteEntityFromOwnModal } from "../../../../context/UniversalSurvey/asyncActions/conditions";
import { ClientType } from "../../../../libs/resources/enums/clientType";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children">;

export default function AffidavitModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [affidavitObj, setAffidavitObj] = useState<Affidavit>({ ...defaultAffidavit });
    const [originalCopy, setOriginalCopy] = useState<Affidavit>({ ...defaultAffidavit });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [selectedClient, setSelectedClient] = useState<SimpleClient | undefined>(undefined);

    useEffect(() => {
        if(state.affidavits.affidavitBeingEdited) {
            setAffidavitObj(state.affidavits.affidavitBeingEdited);
            setOriginalCopy(state.affidavits.affidavitBeingEdited);
            const clientRecord = state.affidavits.affidavitBeingEdited.client_record;
            if (clientRecord) {
                const client = { ...clientRecord, label: getClientTabName(clientRecord) };
                setSelectedClient(client);
            }
        }
    }, [state.affidavits.affidavitBeingEdited])

    useEffect(() => {
        getDealClients(dispatch, String(state.deal.dealInfo?.id));
    }, [props.open])

    function updateAffidavitInfo<
        K extends keyof Affidavit,
        V extends Affidavit[K]
    >(key: K, value: V): void {
        const tempAffidavitObj = { ...affidavitObj }
        tempAffidavitObj[key] = value;
        setAffidavitObj(tempAffidavitObj);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_AFFIDAVIT_BEING_EDITED, payload: undefined })
        setAffidavitObj({ ...defaultAffidavit });
        setOriginalCopy({ ...defaultAffidavit });
        setSelectedClient(undefined);
    }

    function handleDiscardClick() {
        if (_.isEqual(affidavitObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (state.affidavits.affidavitBeingEdited) {
            updateAffidavit(dispatch, String(state.deal.dealInfo?.id), selectedClient, affidavitObj);
        } else {
            addAffidavit(dispatch, String(state.deal.dealInfo?.id), selectedClient, affidavitObj);
        }
    }

    function onDeleteConfirm() {
        const condition = state.conditions.conditionsInDeal.find((condition) => condition.affidavit?.id === affidavitObj.id);
        deleteEntityFromOwnModal(dispatch, String(state.deal.dealInfo?.id), "affidavit", affidavitObj.id, condition);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!affidavitObj.execution_num || !affidavitObj.debtors || !affidavitObj.cost || !affidavitObj.amount || !affidavitObj.plaintiffs || !selectedClient) {
            return true;
        }
        if (_.isEqual(originalCopy, affidavitObj)) {
            return true;
        }
        return false;
    }

    function updateSelectedClient(client: SimpleClient | null) {
        if (client) {
            updateAffidavitInfo("client_id", client.id);
            setSelectedClient(client);
        } else {
            updateAffidavitInfo("client_id", null);
            setSelectedClient(undefined);
        }
    }

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            if (client.type === ClientType.Corporation) continue;
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        return options;
    }

    return (
        <ModalBase
            title={`${state.affidavits.affidavitBeingEdited ? "" : "New "}Affidavit`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.affidavits.affidavitBeingEdited ? true : false}
            confirmDeleteText="Are you sure you want to delete this affidavit, which is linked to a condition? This is permanent and irreversible."
            saveDisabled={isSaveDisabled()}
            discardButtonLabel={`Discard${state.affidavits.affidavitBeingEdited ? " Changes": ""}`}
            onDelete={onDeleteConfirm}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.AffidavitModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveAffidavit])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8} >
                        <ComboBox
                            autoFocus
                            label={{ text: "Declarant", inputId: "affidavit_modal_client_record", isRequired: true }}
                            options={getClientComboboxItems()}
                            placeholder="Select declarant..."
                            value={selectedClient ?? { label: "" }}
                            isHoverActionHidden={selectedClient ? false : true}
                            handleClear={() => updateSelectedClient(null)}
                            isOptionEqualFn={(option, value) => option.id === value.id}
                            // @ts-ignore
                            onChangeFn={(value) => updateSelectedClient(value as SimpleClient)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanControl
                            checked={affidavitObj.lawyer_declaration}
                            onChange={(e) => updateAffidavitInfo("lawyer_declaration", !affidavitObj.lawyer_declaration)}
                            label={{ text: "Lawyer Declaration", inputId: `lawyer_declaration` }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Debtor(s) (comma separated)", inputId: "affidavit_modal_debtors", isRequired: true }}
                            placeholder="Debtor(s)'s name(s)"
                            value={affidavitObj?.debtors ?? undefined}
                            onChange={(e) => updateAffidavitInfo("debtors", e.target.value)}
                            tooltipProps={{
                                message: 'Names should be separated by a comma followed by a space. Do not add "and" or "&". E.g. "Party One, Party Two, Party Three"',
                                placement: "right-start"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Execution No.", inputId: "affidavit_execution_num", isRequired: true }}
                            placeholder="23-00000000"
                            value={affidavitObj?.execution_num ?? undefined}
                            onChange={(e) => updateAffidavitInfo("execution_num", e.target.value)}
                        /> 
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Amount of Judgement", inputId: "affidavit_amount", isRequired: true }}
                            placeholder="0.00"
                            moneyField
                            valueType="positive"
                            value={affidavitObj?.amount !== undefined ? String(affidavitObj.amount) : undefined}
                            // @ts-ignore
                            onChange={(e) => updateAffidavitInfo("amount", e.target.value)}
                        /> 
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Costs", inputId: "affidavit_cost", isRequired: true }}
                            placeholder="0.00"
                            moneyField
                            valueType="positive"
                            value={affidavitObj?.cost !== undefined ? String(affidavitObj.cost) : undefined}
                            // @ts-ignore
                            onChange={(e) => updateAffidavitInfo("cost", e.target.value)}
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Plaintiff(s) (comma separated)", inputId: "affidavit_modal_plaintiffs", isRequired: true }}
                            placeholder="Plaintiff(s)'s name(s)"
                            value={affidavitObj?.plaintiffs ?? undefined}
                            onChange={(e) => updateAffidavitInfo("plaintiffs", e.target.value)}
                            tooltipProps={{
                                message: 'Names should be separated by a comma followed by a space. Do not add "and" or "&". E.g. "Party One, Party Two, Party Three"',
                                placement: "right-start"
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}