import React, { useState, useEffect, Fragment } from "react";

import Table, { TableProps } from '@mui/material/Table';
import { Grid, styled } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

import colors from "../componentStyling/Colors";
import theme from "../componentStyling/Theme";
import { Body, Small, SmallBold } from "../Typography/index";
import {
    CaretUp,
    CaretDown,
    BoxIcon,
    SelectedIcon,
    SelectedPartialIcon,
    ChevronUp,
    ChevronDown,
} from "../Icons/Iconography";
import PaginationBar from "./PaginationBar"
import TitleBar, { TitleBarSpecificProps } from "./TitleBar"
import { BORDER_1, BORDER_2 } from "../componentStyling/Styles";
import { TablePaginationProps } from "./PaginationBar";
import CounterPill from "../CounterPill/CounterPill";
import BasicButton, { ButtonProps } from "../Button/BasicButton";
import CircularLoader from "../Loader/CircularLoader";

type alignmentOptions = "left" | "right";
export type orderOptions = "asc" | "desc" | undefined;
export type Row = Object & { children?: Row[], dontExpand?: boolean, disabled?: boolean };
type DraggableProps = {
    maxDepth?: number; // if not passed - no max depth
    onDrop: (rowsBeingDropped: Row[], rowDroppedOnto: Row) => void;
    valuesShownWhileDragging: string[];
}

type RowHoverProps = {
    condition?: (((row: any) => boolean) | undefined)[];
    buttonsInHoverAction: ButtonProps[]
}

type TableBaseProps = TableProps & {
    titleBarProps?: TitleBarSpecificProps;
    hideHeaderAndTitleBar?: boolean;
    columnHeadings: { label: string, objKey: string, sortable?: boolean }[],
    columnSizes?: number[];
    tableRows: Row[];
    headerAlignment?: alignmentOptions | alignmentOptions[];
    dataAlignment?: alignmentOptions | alignmentOptions[];
    selectEnabled?: boolean;
    clickEnabled?: boolean;
    paginationEnabled?: boolean;
    searchableColumns?: string[];
    onSort?: (objKey: string, order: orderOptions) => void;
    onRowClick?: (row: any) => void;
    paginationProps?: TablePaginationProps,
    id: string;
    draggableProps?: DraggableProps;
    rowHoverProps?: RowHoverProps;
    expandable?: boolean;
    expandOnSelect?: boolean;
    dontAlternateRowColors?: boolean;
    setRowsSelectedInParent?: (rowsSelected: any[]) => void;
    resetState?: boolean;
    rowsLoading?: boolean;
};

const TableBase = React.forwardRef<HTMLDivElement, TableBaseProps>(
    function TableBase(props, ref) {
        const [searchValue, setSearchValue] = useState<string>("");
        const [sortedColumn, setSortedColumn] = useState<number | undefined>(undefined);
        const [sortOrder, setSortOrder] = useState<orderOptions>(undefined);
        const [rowsChecked, setRowsChecked] = useState<(boolean | "partial")[]>([]);
        const [rows, setRows] = useState<Row[]>([]);
        const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);
        const [rowsBeingDragged, setRowsBeingDragged] = useState<number[]>([]);
        const [dropzoneRows, setDropzoneRows] = useState<number[]>([]);
        const [calledDragLeave, setCalledDragLeave] = useState<boolean>(false);
        const [dropzoneDisabled, setDropzoneDisabled] = useState<boolean>(false);
        const [rowsExpanded, setRowsExpanded] = useState<boolean[]>([]);
        const [expandedRowHeights, setExpandedRowHeights] = useState<number[]>([]);

        useEffect(() => {
            if(props.resetState === true || props.resetState === undefined) {
                setRowsChecked(new Array(getTotalRowCount()).fill(false));
                setRowsExpanded(new Array(getTotalRowCount()).fill(false));
                setExpandedRowHeights(new Array(getTotalRowCount()).fill(0));
                const tempRows: Row[] = [];
                for (const row of props.tableRows) {
                    flattenRows(tempRows, row);
                }
                setRows(tempRows);
            }
        }, [props.tableRows])

        useEffect(() => {
            if (props.setRowsSelectedInParent) {
                const tempRows = [];
                for (let i = 0; i < rowsChecked.length; i++) {
                    if (rowsChecked[i] === true) {
                        tempRows.push(rows[i]);
                    }
                }
                props.setRowsSelectedInParent(tempRows);
            }
        }, [rowsChecked])

        function flattenRows (rows: Row[], row: Row) {
            rows.push(row);
            if (row.children) {
                for (const child of row.children) {
                    flattenRows(rows, child);
                }
            }            
        }

        function getTotalRowCount() {
            let totalCount = props.tableRows.length;
            for (const row of props.tableRows) {
                totalCount += countSubRows(row);
            }
            return totalCount;
        }

        function handleColumnSort(colNum: number): void {
            let order: orderOptions;
            if (colNum !== sortedColumn) {
                setSortOrder("desc");
                order = "desc";
                setSortedColumn(colNum);
            } else {
                if (sortOrder === "desc") {
                    setSortOrder("asc");
                    order = "asc";
                } else if (sortOrder === "asc") {
                    setSortOrder(undefined);
                    order = undefined;
                    setSortedColumn(undefined);
                }
            }
            props.onSort ? props.onSort(props.columnHeadings[colNum].objKey, order) : undefined;
        }

        function handleRowClick(row: Row, rowNum: number): void {
            if (props.onRowClick && props.clickEnabled) {
                props.onRowClick(row);
            } else {
                if (!row.disabled) handleSelectRow(rowNum, row);
            }
        }

        function handleSelectAllClick(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>): void {
            if (checkAllCheckedOrNot(true)) {
                setAllCheckedOrNot(false);
            } else {
                setAllCheckedOrNot(true);
            }
        }

        function handleSelectRow(rowNum: number, row: Row) {
            const rowsCheckedCopy = [...rowsChecked];
            if (row.children) { // checking children rows
                handleToggleChildren(rowNum, row, rowsCheckedCopy);
            }
            else { // checking single row
                rowsCheckedCopy[rowNum] = !rowsCheckedCopy[rowNum]
            }

            toggleParentRows(rowNum, rowsCheckedCopy);
            setRowsChecked(rowsCheckedCopy);

            if (props.expandOnSelect && !row.dontExpand && rowsCheckedCopy[rowNum] === true && !rowsExpanded[rowNum]) {
                handleRowExpand(rowNum);
            }
        }

        function toggleParentRows(rowNum: number, rowsCheckedCopy: (boolean | "partial")[]) {
            for (let i = rowNum; i >= 0; i--) {
                if (rows[i].children && rows[i].children?.includes(rows[rowNum])) {
                    rowsCheckedCopy[i] = checkAllChildrenCheckedOrNot(true, i + 1, countSubRows(rows[i]) + i + 1, rowsCheckedCopy) ? true :
                        checkAllChildrenCheckedOrNot(false, i + 1, countSubRows(rows[i]) + i + 1, rowsCheckedCopy) ? false :
                            "partial"
                    toggleParentRows(i, rowsCheckedCopy);
                    break;
                }
            }
        }

        function handleSelectRowClick(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, rowNum: number, row: Row): void {
            if (!row.disabled) handleSelectRow(rowNum, row);
            e.stopPropagation();
        }

        function handleToggleChildren(rowNum: number, row: Row, rowsCheckedCopy: (boolean | "partial")[]) {
            const subRowCount = countSubRows(row);
            if (checkAllChildrenCheckedOrNot(true, rowNum, subRowCount + rowNum + 1, rowsCheckedCopy)) {
                setRangeCheckedOrNot(false, rowNum, subRowCount + rowNum + 1, rowsCheckedCopy);
            } else {
                setRangeCheckedOrNot(true, rowNum, subRowCount + rowNum + 1, rowsCheckedCopy);
            }
        }

        function setRangeCheckedOrNot(checkedOrNot: boolean, start: number, end: number, rowsCheckedCopy: (boolean | "partial")[]): void {
            for (let i = start; i < end; i++) {
                rowsCheckedCopy[i] = checkedOrNot;
            }
        }

        function setAllCheckedOrNot(checkedOrNot: boolean): void {
            const rowsCheckedCopy = [...rowsChecked];
            for (let i = 0; i < rowsCheckedCopy.length; i++) {
                rowsCheckedCopy[i] = checkedOrNot;
            }
            setRowsChecked(rowsCheckedCopy);
        }

        function checkAllCheckedOrNot(checkedOrNot: boolean): boolean {
            for (const row of rowsChecked) {
                if (row !== checkedOrNot) {
                    return false;
                }
            }
            return true;
        }

        function checkAllChildrenCheckedOrNot(checkedOrNot: boolean, start: number, end: number, rowsCheckedCopy: (boolean | "partial")[]): boolean {
            for (let i = start; i < end; i++) {
                if (rowsCheckedCopy[i] !== checkedOrNot) {
                    return false;
                }
            }
            return true;
        }

        function countSubRows(row: Row): number {
            let count = 0;
            if (row.children) {
                for (const child of row.children) {
                    count++;
                    count += countSubRows(child);
                }
            }
            return count;
        }

        function handleDragStart(e: React.DragEvent<HTMLDivElement>, rowNum: number) {
            setHoveredRow(undefined);
            if (rowsChecked.indexOf(true) > -1) {
                const rowsToDrag: number[] = [];
                for (let i = rowsChecked.indexOf(true); i < rowsChecked.length; i++) {
                    if (rowsChecked[i] === true) {
                        rowsToDrag.push(i);
                        i += countSubRows(rows[i]);
                    }
                }
                setRowsBeingDragged(rowsToDrag);
            } else {
                setRowsBeingDragged([rowNum]);
            }

            e.dataTransfer?.setDragImage(document.createElement('img'), 0, 0);
        }

        function handleDrag(e: React.DragEvent<HTMLDivElement>) {
            e.preventDefault();
            const elem = document.getElementById(`dti-${props.id}`);
            if (elem) {
                elem.style.left = (e.clientX + 5) + "px";
                elem.style.top = e.clientY + "px";
            }
        }

        function handleDragEnd() {
            setRowsBeingDragged([]);
            setDropzoneRows([]);
        }

        function handleOnDrop(rowNum: number) {
            const rowsBeingDropped: Row[] = [];
            for (let i of rowsBeingDragged) {
                rowsBeingDropped.push(rows[i]);
            }
            props.draggableProps?.onDrop ? props.draggableProps.onDrop(rowsBeingDropped, rows[rowNum]) : undefined;
        }

        function handleDragEnter(e: React.DragEvent<HTMLDivElement>, rowNum: number) {
            e.preventDefault();
            setCalledDragLeave(false);

            // finding drop rows
            const dropRows: number[] = [];
            if (rows[rowNum].children) {
                dropRows.push(rowNum);
                for (let i = rowNum + 1; i < rowNum + countSubRows(rows[rowNum]) + 1; i++) dropRows.push(i);
                setDropzoneRows(dropRows);
            } else {
                // set to immediate parent, or if no parent - set to whole table
                const parentRowNum = findImmediateParentRowNum(rowNum);
                if (parentRowNum !== undefined) {
                    dropRows.push(parentRowNum);
                    for (let i = parentRowNum + 1; i < parentRowNum + countSubRows(rows[parentRowNum]) + 1; i++) dropRows.push(i);
                    setDropzoneRows(dropRows);
                } else {
                    for (let i = 0; i < rows.length; i++) {
                        dropRows.push(i);
                    }
                    setDropzoneRows(dropRows);
                }
            }

            if (dropRows.length === rows.length) {
                setDropzoneDisabled(false);
                return;
            }
            setDropzoneDisabled(isDropzoneDisabled(dropRows[0]));
        }

        function isDropzoneDisabled(dropRow: number) {
            for (const row of rowsBeingDragged) {
                // dropping on child
                for (let i = row + 1; i < row + countSubRows(rows[row]) + 1; i++) {
                    if (i === dropRow) {
                        return true;
                    }
                }

                // dropping on self
                if (dropRow === row) {
                    return true;
                }
            }

            // dropping will exceed depth limit
            const dropzoneDepth = findRowLevel(dropRow);
            const dragDepths: number[] = [];
            for (const row of rowsBeingDragged) {
                dragDepths.push(findChildDepth(row, 0))
            }
            const totalDepth = dropzoneDepth + Math.max(...dragDepths);
            if (props.draggableProps?.maxDepth !== undefined && totalDepth > props.draggableProps.maxDepth) return true;

            return false;
        }

        function handleDragLeave() {
            if(calledDragLeave) {
                setDropzoneRows([]);
            }
            setCalledDragLeave(true);
        }

        function findImmediateParentRowNum(rowNum: number): number | undefined {
            for (let i = rowNum; i >= 0; i--) {
                if (rows[i].children?.includes(rows[rowNum])) {
                    return i;
                }
            }
            return undefined;
        }

        function findRowLevel(rowNum: number): number {
            let levels = 1;
            let parentRow = findImmediateParentRowNum(rowNum);
            while (parentRow !== undefined) {
                levels += 1;
                parentRow = findImmediateParentRowNum(parentRow);
            }
            return levels;
        }

        function findChildDepth(rowNum: number, depth: number): number {
            if (rows[rowNum].children) {
                depth += 1;
                const childDepths: number[] = [];
                for (const child of rows[rowNum].children!) {
                    childDepths.push(findChildDepth(rows.indexOf(child)!, 0));
                }
                if (childDepths.length > 0) depth += Math.max(...childDepths);
            }
            return depth;
        }

        function getCellAlignment(rowNum: number, colNum: number): alignmentOptions {
            // If dataAlignment is set - follow that
            // Otherwise: if headerAlignment is set - follow that
            // Otherwise: left
            return props.dataAlignment ?
                typeof props.dataAlignment === "string" ?
                        props.dataAlignment :
                        props.dataAlignment[colNum] :
                    props.headerAlignment ?
                        typeof props.headerAlignment === "string" ?
                            props.headerAlignment :
                            props.headerAlignment[rowNum] :
                        "left"
        }

        function handleRowExpand(rowNum: number, e?: React.MouseEvent<HTMLTableCellElement>) {
            e?.stopPropagation();
            const tempRowsExpanded = [...rowsExpanded];
            tempRowsExpanded[rowNum] = !tempRowsExpanded[rowNum];
            setRowsExpanded(tempRowsExpanded);
        }

        function makeRowCommonProps(row: Row, rowNum: number, offset: number) {
            return {
                draggable: props.draggableProps !== undefined && (rowsChecked[rowNum] === true || rowsChecked.indexOf(true) === -1),
                onDragEnd: handleDragEnd,
                onDrag: handleDrag,
                onDrop: () => dropzoneDisabled ? undefined : handleOnDrop(rowNum),
                onDragLeave: handleDragLeave,
                clickable: props.clickEnabled || props.selectEnabled || props.draggableProps !== undefined,
                rowNumber: rowNum,
                offset: offset,
                isHovered: hoveredRow === rowNum,
                isSelected: rowsChecked[rowNum] === true || rowsChecked[rowNum] === "partial",
                isBeingDragged: rowsBeingDragged.includes(rowNum),
                isDropRow: dropzoneRows.includes(rowNum),
                isDropRowStart: rowNum === dropzoneRows[0],
                isDropRowEnd: rowNum === dropzoneRows[dropzoneRows.length - 1],
                isDropzoneDisabled: dropzoneDisabled && dropzoneRows.includes(rowNum),
                onClick: props.clickEnabled || props.selectEnabled ? () => handleRowClick(row, rowNum) : undefined,
                onMouseEnter: () => rowsBeingDragged.length === 0 ? setHoveredRow(rowNum) : undefined,
                onMouseLeave: () => setHoveredRow(undefined),
                isExpanded: rowsExpanded[rowNum],
                dontAlternateRowColors: props.dontAlternateRowColors ?? false,
                disabled: row.disabled ?? false
            }
        }

        function updateExpandedRowHeight(rowNum: number) {
            const expandedHeight = document.getElementById(`row-${props.id}-${rowNum}-expanded`)?.getBoundingClientRect().height;
            if (expandedHeight) {
                const tempHeights = [...expandedRowHeights];
                tempHeights[rowNum] = expandedHeight;
                setExpandedRowHeights(tempHeights);
            }
        }

        function isRowExpandable(row: Row) {
            for (const key of Object.keys(row)) {
                if (typeof row[key as keyof typeof row] === "object" &&
                    Object.keys(row[key as keyof typeof row]!).includes("header") &&
                    Object.keys(row[key as keyof typeof row]!).includes("content")
                ) {
                    return true;
                }
            }
            return false;
        }

        function makeTableRows(rows: Row[], offset: number, rowNumber: number, level: number): JSX.Element[] {
            return rows.map((row, i) => {
                const rowNumberCopy = rowNumber;
                const rowContent = (
                    <Fragment key={`fragment-${rowNumber}`}>
                        <tr>
                            <td>
                                <FillDiv
                                    {...makeRowCommonProps(row, rowNumberCopy, offset)}
                                    height={document.getElementById(`row-${props.id}-${rowNumberCopy}`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}`)!.getBoundingClientRect().height : 1}
                                    key={`fill-${rowNumberCopy}`}
                                    isLastRow={rows.length - 1 === i}
                                    onDragEnter={(e) => handleDragEnter(e, rowNumberCopy)}
                                    onDragOver={(e) => dropzoneDisabled ? undefined : e.preventDefault()} // onDrop doesn't work without this
                                    onDragStart={(e) => handleDragStart(e, rowNumberCopy)}
                                />
                            </td>
                            <td>
                                <BorderDiv
                                    height={document.getElementById(`row-${props.id}-${rowNumberCopy}`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}`)!.getBoundingClientRect().height : 1}
                                    left={document.getElementById(`row-${props.id}-${rowNumberCopy}`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}`)!.getBoundingClientRect().right - offset * 10 : 1}
                                    isDropRow={dropzoneRows.includes(rowNumberCopy)}
                                    isDropzoneDisabled={dropzoneDisabled && dropzoneRows.includes(rowNumberCopy)}
                                />
                            </td>
                            {props.rowHoverProps &&
                                <td>
                                    <HoverDiv
                                        height={document.getElementById(`row-${props.id}-${rowNumberCopy}`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}`)!.getBoundingClientRect().height : 1}
                                        right={document.getElementById(`row-${props.id}-${rowNumberCopy}`) ? window.innerWidth - document.getElementById(`row-${props.id}-${rowNumberCopy}`)!.getBoundingClientRect().right + offset * 10 : 0}
                                        render={hoveredRow === rowNumberCopy}
                                        onMouseEnter={() => rowsBeingDragged.length === 0 ? setHoveredRow(rowNumberCopy) : undefined}
                                        onMouseLeave={() => setHoveredRow(undefined)}
                                    >
                                        {props.rowHoverProps.buttonsInHoverAction.map((buttonProps, i) => {
                                            if (props.rowHoverProps?.condition &&
                                                props.rowHoverProps.condition[i] !== undefined &&
                                                props.rowHoverProps.condition[i]!(row) === false
                                            ) {
                                                return;
                                            }
                                            return (
                                                <BasicButton
                                                    {...buttonProps}
                                                    size="small"
                                                    key={`hover-button-${i}`}
                                                    onClick={() => buttonProps.onClick ? buttonProps.onClick(row) : undefined}
                                                />
                                            )
                                        })}
                                    </HoverDiv>
                                </td>
                            }
                        </tr>
                        <StyledRow
                            {...makeRowCommonProps(row, rowNumberCopy, offset)}
                            onDragEnter={(e) => handleDragEnter(e, rowNumberCopy)}
                            onDragOver={(e) => dropzoneDisabled ? undefined : e.preventDefault()} // onDrop doesn't work without this
                            onDragStart={(e) => handleDragStart(e, rowNumberCopy)}
                            id={`row-${props.id}-${rowNumberCopy}`}
                            key={rowNumberCopy}
                            data-testid={`row-${props.id}`}
                        >
                            {props.selectEnabled && (
                                <SelectBoxWrapper onClick={(e) => handleSelectRowClick(e, rowNumberCopy, row)} disabled={row.disabled ?? false}>
                                    {rowsChecked[rowNumberCopy] === true ? <SelectedIcon color={colors.BLACK} /> :
                                        rowsChecked[rowNumberCopy] === "partial" ? <SelectedPartialIcon color={colors.BLACK} /> :
                                        <BoxIcon color={colors.BLACK} />
                                    }
                                </SelectBoxWrapper>
                            )}
                            {props.columnHeadings.map((heading, j) => (
                                <StyledTableCell
                                    key={j}
                                    align={getCellAlignment(i, j)}
                                >
                                    {/* @ts-ignore */}
                                    {props.expandable && row[heading.objKey as keyof typeof row]?.header ? (typeof row[heading.objKey as keyof typeof row]?.header === "string" ? <Body>{row[heading.objKey as keyof typeof row]?.header}</Body> : row[heading.objKey as keyof typeof row]?.header) : (
                                        typeof row[heading.objKey as keyof typeof row] === "string" ?
                                            <Body data-testid="table-row-column-content">{row[heading.objKey as keyof typeof row]}</Body> :
                                            row[heading.objKey as keyof typeof row]
                                    )}
                                </StyledTableCell>
                            ))}
                            {props.expandable &&
                                <>
                                    {isRowExpandable(row) ? 
                                        <ChevronWrapper
                                            align="right"
                                            offset={offset}
                                            onClick={(e) => handleRowExpand(rowNumberCopy, e)}
                                            id={`chevron-wrapper-${rowNumberCopy}`}
                                        >
                                            {rowsExpanded[rowNumberCopy] ? <ChevronUp color={colors.BLACK} /> : <ChevronDown color={colors.BLACK}/>}
                                        </ChevronWrapper> :
                                        null
                                    }
                                </>
                            }
                        </StyledRow>

                        {props.expandable &&
                            <Fragment key={`fragment-${rowNumber}-expanded`}>
                                <tr>
                                    <td>
                                        <FillDiv
                                            {...makeRowCommonProps(row, rowNumberCopy, offset)}
                                            onDragEnter={(e) => handleDragEnter(e, rowNumberCopy)}
                                            onDragOver={(e) => dropzoneDisabled ? undefined : e.preventDefault()} // onDrop doesn't work without this
                                            onDragStart={(e) => handleDragStart(e, rowNumberCopy)}
                                            height={document.getElementById(`row-${props.id}-${rowNumberCopy}-expanded`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}-expanded`)!.getBoundingClientRect().height : 1}
                                            key={`fill-${rowNumberCopy}-expanded`}
                                            isLastRow={rows.length - 1 === i}
                                            isExpandableRow
                                        />
                                    </td>
                                    <td>
                                        <BorderDiv
                                            height={document.getElementById(`row-${props.id}-${rowNumberCopy}-expanded`) ? document.getElementById(`row-${props.id}-${rowNumberCopy}-expanded`)!.getBoundingClientRect().height : 1}
                                            left={document.getElementById(props.id) ? document.getElementById(props.id)!.getBoundingClientRect().width + 10 : 1}
                                            isDropRow={dropzoneRows.includes(rowNumberCopy)}
                                            isDropzoneDisabled={dropzoneDisabled && dropzoneRows.includes(rowNumberCopy)}
                                            isExpandableRow
                                            isExpanded={rowsExpanded[rowNumberCopy]}
                                        />
                                    </td>
                                </tr>
                                <StyledRow
                                    {...makeRowCommonProps(row, rowNumberCopy, offset)}
                                    onDragEnter={(e) => handleDragEnter(e, rowNumberCopy)}
                                    onDragOver={(e) => dropzoneDisabled ? undefined : e.preventDefault()} // onDrop doesn't work without this
                                    onDragStart={(e) => handleDragStart(e, rowNumberCopy)}
                                    id={`row-${props.id}-${rowNumberCopy}-expanded`}
                                    key={`${rowNumberCopy}-expanded`}
                                    isExpandableRow
                                >
                                    {props.selectEnabled && <TableCell style={{ border: "none", padding: 0 }}/>}{/** Taking up a column to match select box */}
                                    {props.columnHeadings.map((heading, j) => (
                                        <StyledTableCell
                                            key={`sub-content-${j}`}
                                            align={getCellAlignment(i, j)}
                                            hidden={!rowsExpanded[rowNumberCopy]}
                                        >
                                            <Collapse
                                                in={rowsExpanded[rowNumberCopy]}
                                                timeout="auto"
                                                unmountOnExit
                                                addEndListener={() => setTimeout(() => updateExpandedRowHeight(rowNumberCopy), 200)}
                                            >
                                                {/* @ts-ignore */}
                                                {row[heading.objKey as keyof typeof row]?.content && typeof row[heading.objKey as keyof typeof row]?.content === "string" ? <Body>{row[heading.objKey as keyof typeof row]?.content}</Body> : row[heading.objKey as keyof typeof row]?.content}
                                            </Collapse>
                                        </StyledTableCell>
                                    ))}
                                    {<TableCell style={{ border: "none", padding: 0 }}/>}{/** Taking up a column to match chevron */}
                                </StyledRow>
                            </Fragment>
                        }

                        {row.children && makeTableRows(row.children, offset + 3.4, rowNumberCopy + 1, level + 1)}
                    </Fragment>
                )
                rowNumber += countSubRows(row) + 1;
                return rowContent;
            })
        }

        return (
            <div>
                {props.draggableProps &&
                    <DataTransferImage
                        id={`dti-${props.id}`}
                    >
                        {[...rowsBeingDragged].reverse().map((row, i) => (
                            <DtiDiv i={rowsBeingDragged.length - i - 1} key={`dragged-${i}`}>
                                {rowsBeingDragged.length > 1 && (i === rowsBeingDragged.length - 1) &&
                                    <CounterPill
                                        count={rowsBeingDragged.length}
                                        color={colors.WHITE}
                                        customStyling={{border: BORDER_1(colors.GRAY_300), height: "1.8rem", width: "1.8rem"}}
                                    />
                                }
                                <DtiTextDiv>
                                    {props.draggableProps!.valuesShownWhileDragging.map((value, i) => 
                                        // @ts-ignore
                                        rows[row][value]?.header ? <Body key={`body-${i}`}>{rows[row][value].header}</Body> : <Body key={`body-${i}`}>{rows[row][value]}</Body>
                                    )}
                                </DtiTextDiv>
                            </DtiDiv>
                        ))}
                    </DataTransferImage>
                }

                {(props.titleBarProps?.title || props.titleBarProps?.searchEnabled || props.titleBarProps?.filterProps || !checkAllCheckedOrNot(false)) && !props.hideHeaderAndTitleBar &&
                    <TitleBar
                        nothingChecked={checkAllCheckedOrNot(false)}
                        selectedState={!checkAllCheckedOrNot(false)}
                        title={props.titleBarProps?.title}
                        searchEnabled={props.titleBarProps?.searchEnabled}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onSearch={props.titleBarProps?.onSearch}
                        onSelectedStorageClick={props.titleBarProps?.onSelectedStorageClick}
                        onSelectedXClick={props.titleBarProps?.onSelectedXClick}
                        rowsChecked={rowsChecked}
                        selectedStorageEnabled={props.titleBarProps?.selectedStorageEnabled}
                        selectedXEnabled={props.titleBarProps?.selectedXEnabled}
                        filterProps={props.titleBarProps?.filterProps}
                        buttonsInTitleBar={props.titleBarProps?.buttonsInTitleBar}
                        extraTitleContent={props.titleBarProps?.extraTitleContent}
                        rows={rows}
                    />
                }

                <TableContainer>
                    <Table style={{overflow: "hidden"}} id={props.id}>
                        {props.columnSizes && (
                            <colgroup>
                                {props.columnSizes.map((size, i) => <col key={i} width={`${size/12*100 + 0.5}%`}/>)}
                            </colgroup>
                        )}
                        <StyledTableHead hidden={props.hideHeaderAndTitleBar ?? false}>
                            <TableRow>
                                {props.selectEnabled && (
                                    <TableHeaderSelectBoxWrapper onClick={handleSelectAllClick}>
                                        {checkAllCheckedOrNot(true) ?
                                            <SelectedIcon color={colors.BLACK} /> :
                                            checkAllCheckedOrNot(false) ?
                                                <BoxIcon color={colors.BLACK} /> :
                                                <SelectedPartialIcon color={colors.BLACK} />
                                        }
                                    </TableHeaderSelectBoxWrapper>
                                )}
                                {props.columnHeadings.map((col, i) => (
                                    <TableHeaderCell
                                        key={i}
                                        align={props.headerAlignment ? typeof props.headerAlignment === "string" ? props.headerAlignment : props.headerAlignment[i] : "left"}
                                        sortable={col.sortable}
                                        onClick={col.sortable ? () => handleColumnSort(i) : undefined}
                                    >
                                        {i === sortedColumn ? (
                                            <SortedHeaderDiv align={props.headerAlignment ? typeof props.headerAlignment === "string" ? props.headerAlignment : props.headerAlignment[i] : "left"}>
                                                <SmallBold>{col.label}</SmallBold>
                                                {sortOrder === "desc" ? <CaretDown color={colors.BLACK} size="small"/> : <CaretUp color={colors.BLACK} size="small"/>}
                                            </SortedHeaderDiv>
                                        ) : (
                                            <Small>{col.label}</Small>
                                        )}
                                    </TableHeaderCell>
                                ))}
                                {props.expandable && <TableHeaderCell />}
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>{!props.rowsLoading && makeTableRows(props.tableRows, 0, 0, 0)}</TableBody>
                    </Table>
                </TableContainer>
                {props.rowsLoading && <CircularLoader containerHeight="30vh"/>}

                {props.paginationEnabled && props.paginationProps && (
                    <PaginationBar
                        paginationProps={props.paginationProps}
                        searchValue={searchValue}
                    />
                )}
            </div>
        )
    }
)

const dropzoneBorder = (color: string) => `0.2rem dashed ${color}`

const HoverDiv = styled('div')<{
    height: number;
    render: boolean;
    right: number;
}>(({ height, render, right }) => ({
    display: render ? "flex" : "none",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    right: right,
    height: height,
    marginRight: "2rem",
    gap: "1rem",
    position: "absolute",
    zIndex: 1,
    background: theme.HOVER,
}))

const DataTransferImage = styled('div')({
    position: "fixed",
    zIndex: 1,
    width: "40%"
});

const DtiTextDiv = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "20%"
})

const DtiDiv = styled('div')<{
    i: number;
}>(({ i }) => ({
    background: theme.SELECTED,
    border: BORDER_2(colors.RED_300),
    borderRadius: "0.6rem",
    display: "inline-flex",
    padding: "0.5rem 1rem",
    alignItems: "center",
    gap: "0.3rem",
    flexShrink: 0,
    position: "absolute",
    left: `${0.4*i}rem`,
    top: `${0.4*i}rem`,
    width: "100%"
}));

const BorderDiv = styled('div', { shouldForwardProp: 
    (prop) => prop !== "isDropRow" && prop !== "isDropzoneDisabled" && prop !== "isExpandableRow" && prop !== "isExpanded"
})<{
    height: number;
    left: number;
    isDropRow: boolean;
    isDropzoneDisabled: boolean;
    isExpandableRow?: boolean
    isExpanded?: boolean
}>(({ height, left, isDropRow, isDropzoneDisabled, isExpandableRow, isExpanded }) => ({
    position: "absolute",
    left: left,
    zIndex: 1,
    boxSizing: "border-box",
    borderRight: (isDropRow && (!isExpanded || (isExpandableRow && isExpanded))) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined,
    height: height,
}));

const FillDiv = styled('div', { shouldForwardProp: 
    (prop) => prop !== "clickable" && prop !== "isHovered" && prop !== "isSelected" && prop !== "rowNumber" &&
    prop !== "isBeingDragged" && prop !== "isDropRow" && prop !== "isDropRowStart" && prop !== "isDropRowEnd" &&
    prop !== "isDropzoneDisabled" && prop !== "isLastRow" && prop !== "isExpandableRow" && prop !== "isExpanded" && prop !== "dontAlternateRowColors"
})<{
    offset: number;
    rowNumber: number;
    isHovered: boolean;
    isSelected: boolean;
    clickable?: boolean;
    isBeingDragged: boolean;
    isDropRow: boolean;
    isDropRowStart: boolean;
    isDropRowEnd: boolean;
    height: number;
    isDropzoneDisabled: boolean;
    isLastRow: boolean;
    isExpandableRow?: boolean;
    isExpanded: boolean;
    dontAlternateRowColors: boolean;
}>(({ offset, rowNumber, isHovered, isSelected, clickable, isBeingDragged, isDropRow, isDropRowStart, isDropRowEnd, height, isDropzoneDisabled, isLastRow, isExpandableRow, isExpanded, dontAlternateRowColors }) => ({
    width: `${offset}rem`,
    opacity: (isBeingDragged || isDropzoneDisabled) ? 0.5 : 1,
    height: height + (isLastRow ? 0 : 2),
    background: isBeingDragged ? theme.SELECTED :
                    isDropzoneDisabled ? colors.GRAY_50 :
                    isDropRow ? colors.RED_50 :
                    (isHovered && clickable) ? theme.HOVER :
                    isSelected ? theme.SELECTED :
                    dontAlternateRowColors ? colors.WHITE :
                    rowNumber % 2 === 0 ? colors.WHITE : colors.GRAY_50,
    position: "absolute",
    cursor: clickable ? "pointer" : "auto",
    boxSizing: "border-box",
    borderLeft: (isDropRow && ((isExpandableRow && isExpanded) || !isExpandableRow)) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined,
    borderTop: (isDropRowStart && !isExpandableRow) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined,
    borderBottom: (isDropRowEnd && ((isExpandableRow && isExpanded) || (!isExpandableRow && !isExpanded))) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined
}));

const StyledRow = styled(TableRow, { shouldForwardProp: 
    (prop) => prop !== "clickable" && prop !== "isHovered" && prop !== "isSelected" && prop !== "rowNumber" &&
    prop !== "isBeingDragged" && prop !== "isDropRow" && prop !== "isDropRowStart" && prop !== "isDropRowEnd" &&
    prop !== "isDropzoneDisabled" && prop !== "isExpandableRow" && prop !== "isExpanded" && prop !== "dontAlternateRowColors"
})<{
    rowNumber: number;
    offset?: number;
    clickable?: boolean;
    isHovered: boolean;
    isSelected: boolean;
    isBeingDragged: boolean;
    isDropRow: boolean;
    isDropRowStart: boolean;
    isDropRowEnd: boolean;
    isDropzoneDisabled: boolean;
    isExpandableRow?: boolean;
    isExpanded: boolean;
    dontAlternateRowColors: boolean;
    disabled: boolean;
}>(({ rowNumber, offset, clickable, isHovered, isSelected, isBeingDragged, isDropRow, isDropRowStart, isDropRowEnd, isDropzoneDisabled, isExpandableRow, isExpanded, dontAlternateRowColors, disabled }) => ({
    background: isBeingDragged ? theme.SELECTED :
                isDropzoneDisabled ? colors.GRAY_50 :
                isDropRow ? colors.RED_50 :
                isHovered ? theme.HOVER :
                isSelected ? theme.SELECTED :
                dontAlternateRowColors ? colors.WHITE :
                rowNumber % 2 === 0 ? colors.WHITE : colors.GRAY_50,
    opacity: (isBeingDragged || isDropzoneDisabled) ? 0.5 : 1,
    padding: "0rem 1rem",
    gap: "4rem",
    position: "relative",
    left: `${offset}rem`,
    cursor: disabled ? "not-allowed" : clickable ? "pointer" : "auto",
    boxSizing: "border-box",
    borderLeft: (isDropRow && !offset && ((isExpandableRow && isExpanded) || !isExpandableRow)) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined,
    borderTop: (isDropRowStart && !isExpandableRow) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined,
    borderBottom: (isDropRowEnd && ((isExpandableRow && isExpanded) || (!isExpandableRow && !isExpanded))) ? dropzoneBorder(isDropzoneDisabled ? theme.DISABLED : theme.PRIMARY) : undefined
}));

const StyledTableHead = styled(TableHead)<{
    hidden: boolean;
}>(({ hidden }) => ({
    borderBottom: BORDER_1(colors.GRAY_300),
    height: "4rem",
    display: hidden ? "none" : ""
}));

const ChevronWrapper = styled(TableCell)<{
    offset: number;
}>(({ offset }) => ({
    position: "absolute",
    right: `${offset}rem`,
    padding: "1rem",
    border: "none",
    cursor: "pointer"
}));

const SelectBoxWrapper = styled(TableCell)<{
    disabled: boolean;
}>(({ disabled }) => ({
    padding: "0.8rem 0rem 0.4rem 1rem",
    border: "none",
    cursor: disabled ? "not-allowed" : "pointer",
    width: "2.4rem"
}));


const TableHeaderSelectBoxWrapper = styled(TableCell)({
    padding: "0.8rem 0rem 0rem 1rem",
    cursor: "pointer",
    width: "2.4rem"
});

const StyledTableCell = styled(TableCell)<{
    hidden?: boolean;
}>(({ hidden }) => ({
    border: "none",
    padding: hidden ? "0rem" : "1rem"
}));

const TableHeaderCell = styled(TableCell, { shouldForwardProp: 
    (prop) => prop !== "sortable"
})<{
    sortable?: boolean;
}>(({ sortable }) => ({
    cursor: sortable ? "pointer" : "auto",
    padding: "0rem 1rem"
}));

const SortedHeaderDiv = styled(TableCell)<{
    align?: alignmentOptions;
}>(({ align }) => ({
    display: "flex",
    flexDirection: align === "left" ? "row" : "row-reverse",
    border: "none",
    alignItems: "center",
    gap: "0.8rem",
    padding: 0
}));

export default TableBase;