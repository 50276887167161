import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { SimpleFee } from "../../../libs/types/UniversalSurvey/Fees/simpleFee";

export type FeesState = {
    fees: SimpleFee[];
    emptyFeeAdded: boolean;
}

export const defaultFeesState: FeesState = {
    fees: [],
    emptyFeeAdded: false
}

export function feesReducer(state: FeesState, action: Record<string, any>): FeesState {
    switch (action.type) {
        case actions.SET_FEES:
            return { ...state, fees: action.payload };
        case actions.ADD_CUSTOM_FEE:
            return { ...state, fees: [ ...state.fees, action.payload ] };
        case actions.SET_EMPTY_FEE_ADDED:
            return { ...state, emptyFeeAdded: action.payload };
        case actions.DELETE_FEE:
            return { ...state, fees: findAndRemove([...state.fees], ["id"], [action.payload]) };
        default:
            return state;
    }
}