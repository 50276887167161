import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getClients,
    getDocuments,
    deleteGeneratedDocument,
    deleteDocFolder,
    deleteTemplateDocument,
    saveGeneratedDocument,
    saveDocFolder,
    saveTemplateDocument,
    convertSuggestedDocument,
    createPreviewDocument,
    createGeneratedDocument,
    getPreviewDocumentStatus,
    getGeneratedDocument,
    createDocFolder,
    createTemplateDocument,
    getPreviewDocument,
    getMortgageTransactions,
    getGeneratedDocumentStatus,
    createUploadedDocument,
    saveUploadedDocument,
    deleteUploadedDocument,
    getUploadedDocument,
    getDealLiens,
    getSigningAppointments,
    getDeal
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { mapDocuments } from "../../../libs/types/UniversalSurvey/Documents/utils/mapper";
import {
    sanitizeDealResponse,
    sanitizeDocumentResponse,
    sanitizeGeneratedResponse,
    sanitizeMortgageTransactionsResponse,
    sanitizePreviewResponse,
    sanitizeSimpleClientsResponse,
    sanitizeSimpleLienResponse,
    sanitizeSimpleSigningAppointmentResponse,
    sanitizeTemplateResponse,
    sanitizeUploadedResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { DocumentType } from "../../../libs/resources/enums/documents/documentType";
import { Document } from "../../../libs/types/UniversalSurvey/Documents/baseDocument";
import { FolderRequest } from "../../../libs/types/UniversalSurvey/Documents/folders/folderRequest";
import { DocRow } from "../../../routes/UniversalSurvey/Documents/Documents";
import { sanitizeTemplateDocRowRequest, sanitizeUploadedDocRowRequest, santitizeGeneratedDocRowRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { DocumentCategory } from "../../../libs/resources/enums/documents/category";
import { DocumentStatus } from "../../../libs/resources/enums/documents/documentStatus";
import { GenerateType } from "../../../libs/resources/enums/documents/generateType";
import { SimpleLien } from "../../../libs/types/UniversalSurvey/ExistingLien/simpleLien";
import { SimpleSigningAppointment } from "../../../libs/types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";
import { Loading } from "../../../libs/resources/enums/loading";
import { DocListType } from "../../../libs/resources/enums/documents/docListType";
import { Generated } from "../../../libs/types/UniversalSurvey/Documents/generated/generated";
import { Uploaded } from "../../../libs/types/UniversalSurvey/Documents/uploaded/uploaded";

function getDocumentList(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Documents, isLoading: true } });
    getDocuments(dealId)
    .then(function (response: any) {
        let documentList: Document[] = mapDocuments(response.data.documents);
        let dealDocs: Document[] = documentList.filter((doc) => doc.category !== DocumentCategory.AutoDiscovered);
        let suggestedDocs: Document[] = documentList.filter((doc) => doc.category === DocumentCategory.AutoDiscovered);

        dispatch({ type: actions.SET_DOCUMENT_LIST, payload: dealDocs });
        dispatch({ type: actions.SET_SUGGESTED_DOCUMENT_LIST, payload: suggestedDocs });
        dispatch({ type: actions.SET_DOC_DISCOVERY_STATUS, payload: response.data.discovery_status });

        // Attach context functions
        DocumentContextMapper(dispatch, dealId, documentList, DocListType.Both);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get documents: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Documents, isLoading: false } });
    })
}

// Context fn mapper
function DocumentContextMapper(dispatch: Dispatch<Record<string, any>>, dealId: string, documentList: Document[], docType: DocListType) {
    let fetchedClientsContext: boolean = false;
    let fetchedMortgageContext: boolean = false;
    let fetchedTrustLedgerContext: boolean = false;
    let fetchedLienContext: boolean = false;
    let fetchingSigningAppointmentContext: boolean = false;

    for (const document of documentList) {
        switch (document.type) {
            case DocumentType.StatDec:
                if (!fetchedClientsContext) {
                    getContextClients(dispatch, dealId, docType);
                    fetchedClientsContext = true;
                }
                break;
            case DocumentType.GuarantorWaiverOfIla:
                if (!fetchedMortgageContext) {
                    getContextMortgage(dispatch, dealId, docType);
                    fetchedMortgageContext = true;
                }
                break;
            case DocumentType.GuarantorGuaranteeOfMortgage:
                if (!fetchedMortgageContext) {
                    getContextMortgage(dispatch, dealId, docType);
                    fetchedMortgageContext = true;
                }
                break;
            case DocumentType.ConsentToActConflictOfMortgage:
                if (!fetchedMortgageContext) {
                    getContextMortgage(dispatch, dealId, docType);
                    fetchedMortgageContext = true;
                }
                break;
            case DocumentType.LetterToMortgageeNewMortgage:
                if (!fetchedMortgageContext) {
                    getContextMortgage(dispatch, dealId, docType);
                    fetchedMortgageContext = true;
                }
                break;
            case DocumentType.LetterToMortgageeExistingMortgage:
                if (!fetchedLienContext) {
                    getContextLien(dispatch, dealId, docType);
                    fetchedLienContext = true;
                }
                break;
            case DocumentType.VerificationOfIdentityAgreement:
                if (!fetchingSigningAppointmentContext) {
                    getContextSigningAppointment(dispatch, dealId, docType);
                    fetchingSigningAppointmentContext = true;
                }
                break;
            default:
                break;
        }
    }
}

// Attaching context objects
function getContextClients(dispatch: Dispatch<Record<string, any>>, dealId: string, docType: DocListType) {
    getClients(dealId)
    .then(function (response: any) {
        getDeal(dealId)
        .then(function (dealResponse: any) {
            const deal = sanitizeDealResponse(dealResponse.data)
            dispatch({ type: actions.SET_CLIENTS_CONTEXT, payload: { deal: deal, clients: sanitizeSimpleClientsResponse(response.data), docType }});
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal: ${error}`, type: AlertTypes.Error } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal clients: ${error}`, type: AlertTypes.Error } });
    })
}

function getContextMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, docType: DocListType) {
    getMortgageTransactions(dealId)
    .then(function (response: any) {
        dispatch({ type: actions.SET_MORTGAGE_CONTEXT, payload: { mortgages: sanitizeMortgageTransactionsResponse(response.data), docType } });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal mortgage transactions: ${error}`, type: AlertTypes.Error } });
    })
}

function getContextLien(dispatch: Dispatch<Record<string, any>>, dealId: string, docType: DocListType) {
    getDealLiens(dealId)
    .then(function (response: any) {
        const cleanLiens: SimpleLien[] = [];
        for (const lien of response.data) cleanLiens.push(sanitizeSimpleLienResponse(lien));
        dispatch({ type: actions.SET_LIEN_CONTEXT, payload: { liens: cleanLiens, docType } });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal liens: ${error}`, type: AlertTypes.Error } });
    })
}

function getContextSigningAppointment(dispatch: Dispatch<Record<string, any>>, dealId: string, docType: DocListType) {
    getSigningAppointments(dealId)
    .then(function (response: any) {
        const cleanAppts: SimpleSigningAppointment[] = sanitizeSimpleSigningAppointmentResponse(response.data);
        dispatch({ type: actions.SET_SIGNING_APPOINTMENT_CONTEXT, payload: { appts: cleanAppts, docType } });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal signing appointments: ${error}`, type: AlertTypes.Error } });
    })
}

function removeExtraDocRowFields(docRow: DocRow) {
    const data = { ...docRow }
    for (const key of ["tableContext", "tableType", "added", "produced", "versions", "children", "tableName"]) {
        delete data[key as keyof DocRow];
    }
    return data;
}

// Removing functions
function removeGeneratedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number) {
    deleteGeneratedDocument(dealId, String(documentId))
        .then(function () {
            dispatch({ type: actions.DELETE_DOCUMENT, payload: documentId });
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete generated document: ${error}`, type: AlertTypes.Error } });
        })
}

function removeTemplateDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number) {
    deleteTemplateDocument(dealId, String(documentId))
        .then(function () {
            dispatch({ type: actions.DELETE_DOCUMENT, payload: documentId });
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete template document: ${error}`, type: AlertTypes.Error } });
        })
}

function removeUploadedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number) {
    deleteUploadedDocument(dealId, String(documentId))
        .then(function () {
            dispatch({ type: actions.DELETE_DOCUMENT, payload: documentId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete generated document: ${error}`, type: AlertTypes.Error } });
        })
}

function removeFolder(dispatch: Dispatch<Record<string, any>>, dealId: string, folderId: number) {
    getDocuments(dealId)
        .then(function (response: any) {
            let documentList: Document[] = mapDocuments(response.data.documents);
            let promiseList: any[] = [];
            deleteChildrenThenFolder(dispatch, documentList, dealId, folderId, promiseList);

            Promise.all(promiseList)
                .then(function () {
                    deleteDocFolder(dealId, String(folderId))
                        .then(function () {
                            dispatch({ type: actions.DELETE_DOCUMENT, payload: folderId });
                            refreshSuggestedDocs(dispatch, dealId);
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete folder: ${error}`, type: AlertTypes.Error } });
                        })
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get documents: ${error}`, type: AlertTypes.Error } });
        })
}

async function deleteChildrenThenFolder(dispatch: Dispatch<Record<string, any>>, documentList: Document[], dealId: string, folderToDeleteId: number, promiseListForParent: any[]) {
    let promiseListForThisFolder: any[] = [];
    for (const doc of documentList) {
        if (doc.parent_id === folderToDeleteId) {
            if (doc.category === DocumentCategory.Folder) {
                deleteChildrenThenFolder(dispatch, documentList, dealId, doc.id, promiseListForThisFolder);
            } else {
                switch (doc.category) {
                    case DocumentCategory.Template:
                        promiseListForThisFolder.push(
                            deleteTemplateDocument(dealId, String(doc.id))
                                .then(function () {
                                    dispatch({ type: actions.DELETE_DOCUMENT, payload: doc.id });
                                })
                                .catch(function (error: any) {
                                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete template document: ${error}`, type: AlertTypes.Error } });
                                })
                        )
                        break;
                    case DocumentCategory.Generated:
                        promiseListForThisFolder.push(
                            deleteGeneratedDocument(dealId, String(doc.id))
                                .then(function () {
                                    dispatch({ type: actions.DELETE_DOCUMENT, payload: doc.id });
                                })
                                .catch(function (error: any) {
                                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete generated document: ${error}`, type: AlertTypes.Error } });
                                })
                        )
                        break;
                    default:
                        break;
                }
            }
        }
    }
    
    for (const promise of promiseListForThisFolder) {
        promiseListForParent.push(promise);
    }

    promiseListForParent.push(
        Promise.all(promiseListForThisFolder)
            .then(function () {
                deleteDocFolder(dealId, String(folderToDeleteId));
                dispatch({ type: actions.DELETE_DOCUMENT, payload: folderToDeleteId });
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete folder: ${error}`, type: AlertTypes.Error } });
            })
    )
}

// Updating functions
function updateGeneratedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number, data: DocRow) {
    saveGeneratedDocument(dealId, String(documentId), santitizeGeneratedDocRowRequest(data))
        .then(function () {
            dispatch({ type: actions.UPDATE_DOCUMENT, payload: { ...data, id: documentId }});
            DocumentContextMapper(dispatch, dealId, [removeExtraDocRowFields(data)], DocListType.Deal);
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving generated document: ${error}`, type: AlertTypes.Error } });
        })
}

function updateTemplateDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number, data: DocRow) {
    saveTemplateDocument(dealId, String(documentId), sanitizeTemplateDocRowRequest(data))
        .then(function () {
            dispatch({ type: actions.UPDATE_DOCUMENT, payload: { ...data, id: documentId }});
            DocumentContextMapper(dispatch, dealId, [removeExtraDocRowFields(data)], DocListType.Deal);
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving template document: ${error}`, type: AlertTypes.Error } });
        })
}

function updateUploadedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, documentId: number, data: DocRow) {
    saveUploadedDocument(dealId, String(documentId), sanitizeUploadedDocRowRequest(data))
        .then(function () {
            dispatch({ type: actions.UPDATE_DOCUMENT, payload: { ...data, id: documentId }});
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving uploaded document: ${error}`, type: AlertTypes.Error } });
        })
}

function updateFolder(dispatch: Dispatch<Record<string, any>>, dealId: string, folderId: number, data: FolderRequest) {
    saveDocFolder(dealId, String(folderId), data)
        .then(function () {
            dispatch({ type: actions.UPDATE_DOCUMENT, payload: { ...data, id: folderId, editing: false }});
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving folder: ${error}`, type: AlertTypes.Error } });
        })
}

function addSuggestedDoc(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number) {
    convertSuggestedDocument(dealId, String(docId))
        .then(function () {
            refreshSuggestedDocs(dispatch, dealId);
            getDocumentList(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding suggested doc: ${error}`, type: AlertTypes.Error } });
        })
}

function refreshSuggestedDocs(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getDocuments(dealId)
    .then(function (response: any) {
        let documentList: Document[] = mapDocuments(response.data.documents);
        const suggestedDocs = documentList.filter((doc) => doc.category === DocumentCategory.AutoDiscovered);

        dispatch({ type: actions.SET_SUGGESTED_DOCUMENT_LIST, payload: suggestedDocs });
        dispatch({ type: actions.SET_DOC_DISCOVERY_STATUS, payload: response.data.discovery_status });

        // Attach context functions
        DocumentContextMapper(dispatch, dealId, suggestedDocs, DocListType.Suggested);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get documents: ${error}`, type: AlertTypes.Error } });
    })
}

function createPreviewDoc(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number, docName: string | undefined = undefined) {
    createPreviewDocument(dealId, docId)
        .then(function (previewResponse: any) {
            const previewId = previewResponse.data.id;
            let interval: any;
            dispatch({ type: actions.SET_PREVIEW_BEING_VIEWED, payload: previewId });
            refreshSuggestedDocs(dispatch, dealId);
            if (docName) {
                dispatch({ type: actions.SET_DOC_IN_VIEWER_NAME, payload: docName });
            }
            interval = setInterval(() => getPreviewHtmlWhenPreviewIsCompiled(dispatch, dealId, previewId, interval), 1000);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating preview document: ${error}`, type: AlertTypes.Error } });
        })
}

async function produceMultipleDoc(dispatch: Dispatch<Record<string, any>>, dealId: string, docIds: number[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Documents, isLoading: true } });

    const promiseList: Promise<void>[] = docIds.map(async (doc) => {
        try {
            const previewResponse = await createPreviewDocument(dealId, doc);
            const previewId = previewResponse.data.id;
            await waitForPreviewCompilation(dispatch, dealId, previewId);
        } catch (error) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating preview document: ${error}`, type: AlertTypes.Error } });
        }
    });

    try {
        await Promise.all(promiseList);
    } catch (error) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Produce multiple document: ${error}`, type: AlertTypes.Error } });
    }

    getDocumentList(dispatch, dealId);
}

async function waitForPreviewCompilation(dispatch: Dispatch<Record<string, any>>, dealId: string, previewId: number) {
    let interval: any;

    return new Promise(async (resolve, reject) => {
        interval = setInterval(async () => {
            try {
                const response = await getPreviewDocumentStatus(dealId, String(previewId));
                if (response.data.compile_status === DocumentStatus.Complete) {
                    clearInterval(interval);
                    await produceGeneratedDoc(dispatch, dealId, previewId);
                    resolve(undefined);
                } else if (response.data.compile_status === DocumentStatus.Error) {
                    clearInterval(interval);
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get preview doc: ${response.data.compile_error}`, type: AlertTypes.Error } });
                    reject(response.data.compile_error);
                }
            } catch (error) {
                clearInterval(interval);
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get preview doc status: ${error}`, type: AlertTypes.Error } });
                reject(error);
            }
        }, 1000);
    });
}

async function produceGeneratedDoc(dispatch: Dispatch<Record<string, any>>, dealId: string, previewId: number) {
    try {
        await createGeneratedDocument(dealId, { previewDocumentId: previewId, type: GenerateType.PDF });
    } catch (error) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Generating document: ${error}`, type: AlertTypes.Error } });
    }
}

function produceDoc(dispatch: Dispatch<Record<string, any>>, dealId: string, previewId: number, generateType: GenerateType) {
    createGeneratedDocument(dealId, { previewDocumentId: previewId, type: generateType })
        .then(function (genResponse: any) {
            refreshSuggestedDocs(dispatch, dealId);
            dispatch({ type: actions.SET_IS_DOCUMENT_PREPARING, payload: true });
            if (generateType === GenerateType.DOCX) {
                downloadDocx(dispatch, dealId, genResponse.data.id);
            }
            if (generateType === GenerateType.PDF) {
                preparePDF(dispatch, dealId, genResponse.data.id);
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Generating document: ${error}`, type: AlertTypes.Error } });
        })
}

// Handling docx generation and download
function downloadWhenGenerationComplete(dispatch: Dispatch<Record<string, any>>, dealId: string, generatedDocId: number, interval: any) {
    getGeneratedDocumentStatus(dealId, String(generatedDocId))
        .then(function (response: any) {
            if (response.data.generate_status === DocumentStatus.Complete) {
                downloadGeneratedDocument(dispatch, dealId, generatedDocId);
                clearInterval(interval);
                dispatch({ type: actions.SET_IS_DOCUMENT_PREPARING, payload: false });
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Document generation successful and has been downloaded`, type: AlertTypes.Success } });
            } else if (response.data.generate_status === DocumentStatus.Error) {
                clearInterval(interval)
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Error generating document ${generatedDocId}`, type: AlertTypes.Error } });
                dispatch({ type: actions.SET_IS_DOCUMENT_PREPARING, payload: false });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get generated document status: ${error}`, type: AlertTypes.Error } });
        })
}

function downloadDocx(dispatch: Dispatch<Record<string, any>>, dealId: string, generatedDocId: number) {
    getGeneratedDocumentStatus(dealId, String(generatedDocId))
        .then(function (response: any) {
            let interval: any;
            interval = setInterval(() => downloadWhenGenerationComplete(dispatch, dealId, generatedDocId, interval), 2000);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get generated document status: ${error}`, type: AlertTypes.Error } });
        })
}

function pdfGenerationComplete(dispatch: Dispatch<Record<string, any>>, dealId: string, generatedDocId: number, interval: any) {
    getGeneratedDocumentStatus(dealId, String(generatedDocId))
        .then(function (response: any) {
            if (response.data.generate_status === DocumentStatus.Complete) {
                clearInterval(interval);
                getDocumentList(dispatch, dealId);
                dispatch({ type: actions.SET_IS_DOCUMENT_PREPARING, payload: false });
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Document generation successful, document should be available in document list`, type: AlertTypes.Success } });
            } else if (response.data.generate_status === DocumentStatus.Error) {
                clearInterval(interval)
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Error generating document ${generatedDocId}`, type: AlertTypes.Error } });
                dispatch({ type: actions.SET_IS_DOCUMENT_PREPARING, payload: false });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get generated document status: ${error}`, type: AlertTypes.Error } });
        })
}

function preparePDF(dispatch: Dispatch<Record<string, any>>, dealId: string, generatedDocId: number) {
    getGeneratedDocumentStatus(dealId, String(generatedDocId))
        .then(function (response: any) {
            let interval: any;
            interval = setInterval(() => pdfGenerationComplete(dispatch, dealId, generatedDocId, interval), 2000);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get generated document status: ${error}`, type: AlertTypes.Error } });
        })
}


function getPreviewHtmlWhenPreviewIsCompiled(dispatch: Dispatch<Record<string, any>>, dealId: string, previewId: number, interval: any) {
    getPreviewDocumentStatus(dealId, String(previewId))
        .then(function (response: any) {
            if (response.data.compile_status === DocumentStatus.Complete) {
                clearInterval(interval);

                getPreviewDocument(dealId, String(previewId))
                    .then(function (prevResponse: any) {
                        const cleanData = sanitizePreviewResponse(prevResponse.data);
                        dispatch({ type: actions.SET_HTML_IN_VIEWER_FULL, payload: cleanData.html });
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Generating document: ${error}`, type: AlertTypes.Error } });
                    })
            } else if (response.data.compile_status === DocumentStatus.Error) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get preview doc: ${response.data.compile_error}`, type: AlertTypes.Error } });
                clearInterval(interval);
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get preview doc status: ${error}`, type: AlertTypes.Error } });
        })
}

function downloadGeneratedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number) {
    getGeneratedDocument(dealId, String(docId))
        .then(async function (response) {
            downloadDoc(dispatch, response.data);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Downloading document: ${error}`, type: AlertTypes.Error } });
        })
}

function downloadUploadedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number) {
    getUploadedDocument(dealId, String(docId))
        .then(async function (response) {
            downloadDoc(dispatch, response.data);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Downloading document: ${error}`, type: AlertTypes.Error } });
        })
}

async function downloadDoc(dispatch: Dispatch<Record<string, any>>, responseData: Generated | Uploaded) {
    if (responseData.presigned_url) {
        const data = await fetch(new URL(responseData.presigned_url));
        const blob = await data.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        let fileType = "pdf";
        // @ts-ignore
        if (Object.keys(responseData).includes("generate_type")) fileType = responseData.generate_type;
        link.download = `${responseData.name}.${fileType}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Downloading document: URL not found`, type: AlertTypes.Error } });
    }
}

async function canAccessGeneratedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number): Promise<boolean> {
    let canDocumentBeViewed: boolean = false;

    try {
        const response = await getGeneratedDocument(dealId, String(docId));
        const cleanData = sanitizeGeneratedResponse(response.data);

        if (cleanData.generate_status !== DocumentStatus.Complete) {
            dispatch({
                type: actions.SET_ALERT_DATA,
                payload: { message: `PDF is still being prepared, try again in a moment...`, type: AlertTypes.Warning }
            });
            canDocumentBeViewed = false;
        } else {
            dispatch({ type: actions.SET_PDF_LINK, payload: cleanData.presigned_url });
            dispatch({ type: actions.SET_DOC_IN_VIEWER_NAME, payload: cleanData.name });
            canDocumentBeViewed = true;
        }
    } catch (error) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Viewing PDF: ${error}`, type: AlertTypes.Error } });
        canDocumentBeViewed = false;
    }

    return canDocumentBeViewed;
}


function viewGeneratedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number) {
    getGeneratedDocument(dealId, String(docId))
        .then(async function (response) {
            const cleanData = sanitizeGeneratedResponse(response.data);
            dispatch({ type: actions.SET_PDF_LINK, payload: cleanData.presigned_url });
            dispatch({ type: actions.SET_DOC_IN_VIEWER_NAME, payload: cleanData.name });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Viewing PDF: ${error}`, type: AlertTypes.Error } });
        })
}

function viewUploadedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, docId: number) {
    getUploadedDocument(dealId, String(docId))
        .then(async function (response) {
            const cleanData = sanitizeUploadedResponse(response.data);
            dispatch({ type: actions.SET_PDF_LINK, payload: cleanData.presigned_url });
            dispatch({ type: actions.SET_DOC_IN_VIEWER_NAME, payload: cleanData.name });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Viewing PDF: ${error}`, type: AlertTypes.Error } });
        })
}

function addFolder(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    createDocFolder(dealId, { name: "New Folder" })
        .then(function (response: any) {
            const cleanFolder = sanitizeDocumentResponse({ ...response.data, category: DocumentCategory.Folder });
            dispatch({ type: actions.ADD_TO_DOC_LIST, payload: { ...cleanFolder, name: "", editing: true, open: true } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding folder: ${error}`, type: AlertTypes.Error } });
        })
}

function addDocumentToDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, type: DocumentType, context: any, name: string) {
    createTemplateDocument(dealId, { type, context, name, parent_id: null })
        .then(function (response: any) {
            const cleanDoc = sanitizeTemplateResponse(response.data);
            dispatch({ type: actions.ADD_TO_DOC_LIST, payload: cleanDoc });
            refreshSuggestedDocs(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating template document: ${error}`, type: AlertTypes.Error } });
        })
}

function newUploadedDocument(dispatch: Dispatch<Record<string, any>>, dealId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    createUploadedDocument(dealId, formData)
        .then(function (response: any) {
            saveUploadedDocument(dealId, String(response.data.id), { parent_id: null, name: file.name })
                .then(function () {
                    getDocumentList(dispatch, dealId);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving uploaded document "${file.name}": ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.REMOVE_UPLOADED_DOC_LOADING, payload: file.name });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.REMOVE_UPLOADED_DOC_LOADING, payload: file.name });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Uploading document "${file.name}": ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getDocumentList,
    removeGeneratedDocument,
    removeTemplateDocument,
    removeUploadedDocument,
    removeFolder,
    updateGeneratedDocument,
    updateTemplateDocument,
    updateUploadedDocument,
    updateFolder,
    addSuggestedDoc,
    refreshSuggestedDocs,
    createPreviewDoc,
    produceDoc,
    downloadGeneratedDocument,
    downloadUploadedDocument,
    viewGeneratedDocument,
    viewUploadedDocument,
    addFolder,
    addDocumentToDeal,
    newUploadedDocument,
    produceMultipleDoc,
    canAccessGeneratedDocument
}