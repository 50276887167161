// Importing material components
import { Grid, styled } from "@mui/material";

// Importing application components
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { AllProvinceDBOptions } from "../../../../libs/resources/options";
import { formatProvinceDB } from "../../../../libs/utils/formatValues";
import { ProvinceDB } from "../../../../libs/resources/enums/provinces";
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import { SimpleAddress } from "../../../../libs/types/UniversalSurvey/Address/simpleAddress";
import { Body } from "../../../../../components/Common/Typography";
import BasicButton from "../../../../../components/Common/Button/BasicButton";
import { QuestionIcon } from "../../../../../components/Common/Icons/Iconography";
import BasicTooltip from "../../../../../components/Common/Tooltip/Tooltip";
import Colors from "../../../../../components/Common/componentStyling/Colors";
import { AddressRequired } from "../../../../libs/types/UniversalSurvey/Address/address";

type AddressGridProps = {
    address: SimpleAddress;
    setAddress: (value: SimpleAddress) => void;
    id: string;
    title?: string;
    copyAddress?: { address: SimpleAddress, description: string };
    addressRequired?: AddressRequired;
}

export default function AddressGrid(props: AddressGridProps) {
    const { address, setAddress, id, title, copyAddress, addressRequired } = props;

    function updateAddress<
        K extends keyof SimpleAddress,
        V extends SimpleAddress[K]
    >(key: K, value: V): void {
        const tempAddress: SimpleAddress = { ...address };
        tempAddress[key] = value;
        setAddress(tempAddress);
    }

    return (
        <PaddedGrid container rowSpacing={3} columnSpacing={5}>
            {title && <Grid item xs={12}><StyledBody>{title}</StyledBody></Grid>}
            {copyAddress &&
                <Grid item xs={12}>
                    <CopyButtonDiv>
                        <BasicButton
                            size="small"
                            typeOf="secondary"
                            label={{ text: `Copy ${copyAddress.description}`, inputId: `copy-address-${copyAddress.description}` }}
                            onClick={() => setAddress({ ...copyAddress.address, id: address.id })}
                        />
                        <BasicTooltip message="This will copy data, but will not link the two addresses. Changes made to one after copying will not affect the other." placement="right">
                            <div style={{ marginTop: "0.3rem" }}>
                                <QuestionIcon color={Colors.BLACK}/>
                            </div>
                        </BasicTooltip>
                    </CopyButtonDiv>
                </Grid>
            }
            <Grid item xs={9}>
                <BasicTextInput
                    fullWidth
                    value={address.street_address}
                    onChange={(e) => updateAddress("street_address", e.target.value)}
                    label={{ text: "Street Address", inputId: `street_address-${id}`, isRequired: addressRequired?.street_address }}
                    placeholder="Street Address"
                />
            </Grid>
            <Grid item xs={3}>
                <BasicTextInput
                    fullWidth
                    value={address.city}
                    onChange={(e) => updateAddress("city", e.target.value)}
                    label={{ text: "City", inputId: `city-${id}`, isRequired: addressRequired?.city }}
                    placeholder="City"
                />
            </Grid>
            <Grid item xs={5}>
                <Dropdown
                    value={address.province_enum ?? undefined}
                    onChange={(e) => updateAddress("province_enum", e.target.value as ProvinceDB)}
                    options={AllProvinceDBOptions}
                    formatValue={formatProvinceDB}
                    placeholder="Select a province..."
                    label={{ text: "Province", isRequired: addressRequired?.province_enum }}
                    isHoverActionHidden={address.province_enum ? false : true}
                    handleClear={() => updateAddress("province_enum", undefined)}
                    data-testid={`province-drop-down-${id}`}
                />
            </Grid>
            <Grid item xs={3}>
                <BasicTextInput
                    fullWidth
                    value={address.postal_code}
                    onChange={(e) => updateAddress("postal_code", e.target.value)}
                    label={{ text: "Postal Code", inputId: `postal_code-${id}`, isRequired: addressRequired?.postal_code }}
                    placeholder="Postal Code"
                />
            </Grid>
            <Grid item xs={4}>
                <BasicTextInput
                    fullWidth
                    value={address.country}
                    onChange={(e) => updateAddress("country", e.target.value)}
                    label={{ text: "Country", inputId: `country-${id}`, isRequired: addressRequired?.country }}
                    placeholder="Country"
                />
            </Grid>
        </PaddedGrid>
    )
};

const StyledBody = styled(Body)({
    marginBottom: "-1.5rem",
    marginTop: "1rem"
})

const PaddedGrid = styled(Grid)({
    paddingTop: "0.2rem"
})

const CopyButtonDiv = styled('div')({
    display: "flex",
    gap: "0.6rem"
})