// Importing MUI components
import { styled, Box } from '@mui/material';
import {Oval} from "react-loader-spinner";


import Theme from '../componentStyling/Theme';

type LoaderProps = {
    loaderWidth?: string,
    loaderHeight?: string,
    containerHeight: string,
    color?: string,
    secondaryColor?: string,
    strokeWidth?: number
    testId?: string
}

export default function CircularLoader(props: LoaderProps) {
    return (
        <LoadingContainer height={props.containerHeight}>
            <DeededLoader data-testid={props.testId ?? "circular-loader"} {...props}>
                <Oval
                    ariaLabel="loading-indicator"
                    height={props.loaderHeight ? props.loaderHeight : "10rem"}
                    width={props.loaderWidth ? props.loaderWidth: "10rem"}
                    strokeWidth={props.strokeWidth || 5}
                    color={props.color ? props.color : Theme.PRIMARY}
                    secondaryColor={props.secondaryColor ? props.secondaryColor : Theme.PLACEHOLDER}
                />
            </DeededLoader>
        </LoadingContainer>
    )
}

const LoadingContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const DeededLoader = styled(Box,  {
    shouldForwardProp: (prop) => prop !== 'containerHeight' && prop !== "loaderHeight" && prop !== "loaderWidth",
  })({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});
