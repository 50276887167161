import { LenderRequest } from "../../../types/UniversalSurvey/Lender/lenderRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultLenderRequest: LenderRequest = {
    name: null,
    abbr_name: null,
    type: null,
    institution_number: null,
    branch_number: null,
    all_regions: null,
    general_contact_name: null,
    general_address: defaultAddressRequest,
    general_email: null,
    final_report_contact_name: null,
    final_report_address: defaultAddressRequest,
    final_report_email: null,
    address_for_service: defaultAddressRequest,
    address_for_registration: defaultAddressRequest,
    financial_info: defaultFinancialInfoRequest,
    lawyer_id: null
}