import { TaxRates } from "../resources/enums/taxes"
import Decimal from "decimal.js";

export function calculateTaxAmount(regionId: number | undefined, taxableAmount: number) {
    const GST_VALUE = new Decimal(0.05);

    const convertedTaxableAmount = new Decimal(taxableAmount);
    let gst: Decimal = Decimal.mul(convertedTaxableAmount, GST_VALUE).toDecimalPlaces(2);
    let pst: Decimal;
    switch(regionId) {
        case 1:
            pst = Decimal.mul(convertedTaxableAmount, Decimal.sub(new Decimal(TaxRates.Ontario), GST_VALUE)).toDecimalPlaces(2);
            break;
        case 2:
            pst = Decimal.mul(convertedTaxableAmount, Decimal.sub(new Decimal(TaxRates.BritishColumbia), GST_VALUE)).toDecimalPlaces(2);
            break;
        case 3:
            pst = Decimal.mul(convertedTaxableAmount, Decimal.sub(new Decimal(TaxRates.Alberta), GST_VALUE)).toDecimalPlaces(2);
            break;
        default:
            pst = Decimal.mul(convertedTaxableAmount, Decimal.sub(new Decimal(TaxRates.Ontario), GST_VALUE)).toDecimalPlaces(2);
            break;
    }
    return Decimal.add(gst, pst).toNumber();
}