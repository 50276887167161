import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { LetterToClientContextRequest } from "../documents/letterToClientDocument";
import { LetterToClientContentType } from "../../../../resources/enums/documents/letterToClient";

const contextValues = {
    [LetterToClientContentType.Blank]: 1,
    [LetterToClientContentType.Report]: 2
};

function createLetterToClientName(contentType: LetterToClientContentType): string {
    return `Letter to Client - ${contentType === LetterToClientContentType.Blank ? "Blank" : "Report"}`;
}

function labelLetterToClientContextOption(contextValue: number) {
    switch(contextValue) {
        case contextValues[LetterToClientContentType.Blank]:
            return "Blank";
        case contextValues[LetterToClientContentType.Report]:
            return "Report";
        default:
            return "Blank";
    }
}

function isReportLetterInDeal(docList: Document[]): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.LetterToClient && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.content_type === LetterToClientContentType.Report) return true;
    }
    return false;
}

function createLetterToClientContextRequest(contentType: LetterToClientContentType): LetterToClientContextRequest {
    return { content_type: contentType };
}

function getLetterToClientDocData(contextValue: number): [Object, string] {
    let contentType: LetterToClientContentType
    switch(contextValue) {
        case contextValues[LetterToClientContentType.Blank]:
            contentType = LetterToClientContentType.Blank;
            break;
        case contextValues[LetterToClientContentType.Report]:
            contentType = LetterToClientContentType.Report;
            break;
        default:
            contentType = LetterToClientContentType.Blank;
            break;
    }
    return [createLetterToClientContextRequest(contentType), createLetterToClientName(contentType)];
}

function getLetterToClientContextOptions(docList: Document[]) {
    const options: number[] = [];
    for (const key of Object.keys(contextValues)) {
        if (!(key === LetterToClientContentType.Report && isReportLetterInDeal(docList))) {
            options.push(contextValues[key as keyof typeof contextValues]);
        }
    }
    return options;
}

export {
    getLetterToClientDocData,
    getLetterToClientContextOptions,
    labelLetterToClientContextOption
}