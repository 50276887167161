import * as actions from "../actionTypes"
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";


export type AlertsState = {
    message: string | null;
    duration: number;
    type: AlertTypes;
    primaryText: string | undefined;
    primaryAction: (() => void) | undefined;
    secondaryText: string | undefined;
    secondaryAction: (() => void) | undefined;
}

export const defaultAlertsState: AlertsState = {
    message: null,
    duration: 6000,
    type: AlertTypes.Error,
    primaryText: undefined,
    primaryAction: undefined,
    secondaryText: undefined,
    secondaryAction: undefined,
}

export function alertsReducer(state: AlertsState, action: Record<string, any>): AlertsState {
    switch (action.type) {
        case actions.SET_ALERT_DATA:
            return { ...action.payload, duration: action.payload.duration ?? 6000 };
        case actions.RESET_ALERT_DATA:
            return { ...defaultAlertsState };
        default:
            return state;
    }
}