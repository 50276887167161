import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import constants from "styles/constants";

// Importing conveyance section code
import { SimpleDeal } from "../../../../libs/types/DealList/deals";
import { DateObjectToDateFormat } from "../../../../libs/utils/dateHelper";
import { TransactionTypeDB } from "../../../../libs/resources/enums/transactions";
import { formatDealStatus, formatTransactionType } from "../../../../libs/utils/formatValues";
import { getClientsString } from "../../../../libs/utils/clients";
import { addressToStringHeader } from "../../../../libs/utils/address";

interface CDListItemTableBodyProps {
  deal: SimpleDeal
}

const CDListItemTableBody = (props: CDListItemTableBodyProps) => {
  const theme = useTheme();
  const history = useHistory();
  const deal = props.deal;
  
  const [provinceColor, setProvinceColor] = useState<string>("");
  const [dealTypeColor, setDealTypeColor] = useState<string>("");
  const [displayClient, setDisplayClient] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [closingDate, setClosingDate] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  // TODO: update table item with additional dropdown to present all transactions within DCP deal
  useEffect(() => {
    // Clients
    setDisplayClient(getClientsString(deal.clients));

    // Address
    if (deal.subject_property_address) {
      setAddress(addressToStringHeader(deal.subject_property_address));
    }

    // Dates
    if (deal.opened_date) {
      setCreatedAt(DateObjectToDateFormat(deal.opened_date, false, false));
    }
    if (deal.closing_date) {
      setClosingDate(DateObjectToDateFormat(deal.closing_date, false, false));
    }

    // Region
    switch (deal.region?.name) {
      case "Alberta":
        setProvinceColor(constants.provinceColors.AB);
        break;
      case "British Columbia":
        setProvinceColor(constants.provinceColors.BC);
        break;
      case "Ontario":
        setProvinceColor(constants.provinceColors.ON);
        break;
      default:
        setProvinceColor(constants.provinceColors.Other);
    }

    // Type
    // TODO: Update when we support more deal types
    switch (deal.primary_transaction_type) {
      case TransactionTypeDB.Mortgage:
        setDealTypeColor(constants.dealTypeColor.Mortgage);
        break;
      default:
        setDealTypeColor(constants.dealTypeColor.Mortgage);
        break;
    }
    setType(deal.primary_transaction_type ? formatTransactionType(deal.primary_transaction_type) : "");

    setStatus(formatDealStatus(deal.status));

  }, [deal]);

  function handleClickOnDeal(dealId: number) {
    if (dealId) {
      history.push(`/dashboard/conveyance/conveyance-deals/${dealId}/data-sheet`);
    }
  };

  return (
    <>
      <ItemContainer theme={theme} onClick={() => handleClickOnDeal(deal.id)}>
        <TableBodyItemCell theme={theme}>
          {" "}
          <DealText
            theme={theme}
            sx={{
              color: dealTypeColor,
            }}
          >
            {type}
          </DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <Box>
            <ProvinceBox sx={{background: provinceColor}}>
              {deal.region?.abbreviation}
            </ProvinceBox>
          </Box>
        </TableBodyItemCell>
        <TableBodyItemCell
          sx={{
            position: "relative",
          }}
          theme={theme}
        >
          <DealText theme={theme} sx={{maxWidth: "130px"}}>
            {displayClient}
          </DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <DealText theme={theme}>{address}</DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <DealText theme={theme}>{createdAt}</DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <DealText theme={theme}>{closingDate}</DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <DealText
            theme={theme}
          >
            {deal.file_num}
          </DealText>
        </TableBodyItemCell>
        <TableBodyItemCell theme={theme}>
          <DealText theme={theme}>
            {status}
          </DealText>
        </TableBodyItemCell>
      </ItemContainer>
    </>
  );
};

const TableBodyItemCell = styled(TableCell)<{theme: Theme}>(({theme}) => ({
  borderBottom: `4px solid ${constants.colors.bg}`,
  [theme.breakpoints.down(1440)]: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  [theme.breakpoints.down(1160)]: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}));

const ProvinceBox = styled(Box)({
  width: "39px",
  height: "22px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: "600",
  borderRadius: "4px",
  color: "white",
});

const ItemContainer = styled(TableRow)<{theme: Theme}>(({theme}) => ({
  cursor: "pointer",
  height: "50px",
  background: "white",
  marginBottom: "4px",
  padding: "0 16px",
  borderRadius: "2px",
  boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
  transition: constants.transitions,
  "&:hover": {
    background: constants.colors.configNotificationsTableBorder,
    transition: constants.transitions,
  },
  [theme.breakpoints.down(1440)]: {
    maxHeight: "40px !important",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const DealText = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Montserrat",
  color: "#7F7F7F",
  textTransform: "capitalize",
  [theme.breakpoints.down(1440)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1355)]: {
    fontSize: "10px",
  },
}));

export default CDListItemTableBody;