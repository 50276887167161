import { Box, Dialog, DialogProps as DialogPropsMui, Stack, styled } from "@mui/material";

import { Body, H1, H2, H4 } from "../Typography/index";
import color from '../componentStyling/Colors';
import BasicButton, { ButtonProps } from "../Button/BasicButton";
import { PAPER_PROPS } from "../componentStyling/Styles";

type DialogProps = {
    action?: "neutral" | "positive" | "destructive" | "secondary";
    title?: string;
    titleComponent?: "H1" | "H2" | "H4";
    open: boolean;
    contentText?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
    cancelText?: string;
    submitText?: string;
    hideBackdrop?: boolean;
    cancelBtnProps?: Partial<ButtonProps>;
    dialogProps?: Partial<DialogPropsMui>;
    top?: number;
}

const titleComponents = {
    'H1': H1,
    'H2': H2,
    'H4': H4,
};

export default function GenericDialog(props: DialogProps) {

    function onClose(event: any, reason: "backdropClick" | "escapeKeyDown") {
        if (reason === "escapeKeyDown") {
            props.onSubmit ? props.onSubmit() : undefined;
        }
    }
    const TitleTypography = props?.titleComponent ? titleComponents[props.titleComponent] : H2;
    return (
        <Dialog
            fullWidth
            PaperProps={{ sx: PAPER_PROPS }}
            maxWidth="sm"
            open={props.open}
            hideBackdrop={props.hideBackdrop ?? false}
            style={{ top: props.top ?? undefined }}
            onClose={(event: any, reason: "backdropClick" | "escapeKeyDown") => onClose(event, reason)}
            {...props.dialogProps}
        >
            <ModalHeader>
                <TitleTypography>
                    {props.title ? props.title : "Dialog Title"}
                </TitleTypography>
            </ModalHeader>
            <ModalContent>
                <Body>
                    {props.contentText}
                </Body>
            </ModalContent>
            <ModalButtonRow>
                <ButtonsContainer>
                    <BasicButton
                        typeOf="dismissive" 
                        onClick={props.onCancel}
                        label={{text: props.cancelText ? props.cancelText : "Cancel", inputId: "cancel"}}
                        {...(props.cancelBtnProps)}
                        testId="confirmation-modal-cancel-btn"
                    />
                    {props.action === "neutral" && (
                        <BasicButton
                            typeOf="primary" 
                            onClick={props.onSubmit}
                            label={{text: props.submitText ? props.submitText : "Remove", inputId: "submit"}}
                            testId="confirmation-modal-submit-btn"
                        />
                    )}
                    {props.action === "positive" && (
                        <BasicButton
                            typeOf="CTA" 
                            onClick={props.onSubmit}
                            label={{text: props.submitText ? props.submitText : "Complete", inputId: "submit"}}
                        />
                    )}
                    {props.action === "destructive" && (
                        <BasicButton
                            typeOf="destructive"
                            onClick={props.onSubmit}
                            label={{text: props.submitText ? props.submitText : "Delete", inputId: "submit"}}
                        />
                    )}
                    {props.action === "secondary" && (
                        <BasicButton
                            typeOf="secondary"
                            onClick={props.onSubmit}
                            label={{text: props.submitText ? props.submitText : "Submit", inputId: "submit"}}
                        />
                    )}
                </ButtonsContainer>
            </ModalButtonRow>
        </Dialog>
    )
}

const ModalHeader = styled(Box)({
    padding: "0rem 2rem 0rem 2rem",
    position: "sticky",
    top: 0,
    paddingTop: "2rem",
    zIndex: 100,
    background: color.WHITE,
    height: "8rem",
    display: "block"
});

const ModalContent = styled('div')({
    padding: "0rem 2rem 0rem 2rem",
})

const ModalButtonRow = styled(Box)(({ theme }) => ({
    padding: "0rem 2rem 0rem 2rem",
    position: "sticky",
    bottom: 0,
    paddingTop: "2rem",
    alignContent: "center",
    zIndex: 100,
    background: color.WHITE,
    height: "9rem",
    display: "block",
    [theme.breakpoints.down("mobile")]: {
        height: "auto",
        paddingBottom: "2rem"
    },
}));


const ButtonsContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: '2rem',
    flexGrow: 1,
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column-reverse",
        alignContent: "stretch",
        justifyContent: "center",
    },
}));