import { SimpleFireInsurance } from "../../../types/UniversalSurvey/FireInsurance/simpleFireInsurance";
import { FireInsuranceNumberType } from "../../enums/fireInsurance";

export const defaultSimpleFireInsurance: SimpleFireInsurance = {
    id: -1,
    type: FireInsuranceNumberType.Policy,
    number: undefined,
    coverage_amount: undefined,
    interest_noted: undefined,
    guaranteed_replacement_costs: undefined,

    property_record: undefined,
    fire_insurance_broker_record: undefined,
    fire_insurance_company_record: undefined,
    fire_insurance_brokerage_record: undefined
}