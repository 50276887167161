import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { SimpleSigningAppointment } from "../../../libs/types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";

export type SigningAppointmentsState = {
    signingApptsBeingSaved: number[];
    isSigningApptSaving: boolean;
    shouldRefreshSigningAppt: boolean;
    signingApptsInDeal: SimpleSigningAppointment[];
}

export const defaultSigningAppointmentsState: SigningAppointmentsState = {
    signingApptsBeingSaved: [],
    isSigningApptSaving: false,
    shouldRefreshSigningAppt: false,
    signingApptsInDeal: [],
}

export function signingAppointmentsReducer(state: SigningAppointmentsState, action: Record<string, any>): SigningAppointmentsState {
    switch (action.type) {
        case actions.ADD_SIGNING_APPOINTMENT:
            const signingApptsCopy = [...state.signingApptsInDeal, action.payload];
            return { ...state, signingApptsInDeal: signingApptsCopy };
        case actions.SET_SIGNING_APPOINTMENTS_IN_DEAL:
            return { ...state, signingApptsInDeal: action.payload };
        case actions.REMOVE_SIGNING_APPOINTMENT:
            return { ...state, signingApptsInDeal: findAndRemove([...state.signingApptsInDeal], ["id"], [action.payload]) };
        case actions.SET_IS_SIGNING_APPOINTMENT_SAVING:
            return { ...state, isSigningApptSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_SIGNING_APPOINTMENT:
            return { ...state, shouldRefreshSigningAppt: action.payload };
        case actions.REMOVE_SIGNING_APPOINTMENT_BEING_SAVED:
            let isSigningApptSaving = state.isSigningApptSaving;
            let shouldRefreshSigningAppt = state.shouldRefreshSigningAppt;
            const tempSigningApptsBeingSaved = [...state.signingApptsBeingSaved];
            const index = tempSigningApptsBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempSigningApptsBeingSaved.splice(index, 1);
            }
            if (index >=0 && tempSigningApptsBeingSaved.length === 0 && isSigningApptSaving) {
                isSigningApptSaving = false;
                shouldRefreshSigningAppt = true;
            }
            return {
                ...state,
                signingApptsBeingSaved: tempSigningApptsBeingSaved,
                isSigningApptSaving: isSigningApptSaving,
                shouldRefreshSigningAppt: shouldRefreshSigningAppt
            };
        case actions.ADD_SIGNING_APPOINTMENT_BEING_SAVED:
            if (!state.signingApptsBeingSaved.includes(action.payload)) {
                return { ...state, signingApptsBeingSaved: [...state.signingApptsBeingSaved, action.payload] };
            }
            return state;
        case actions.UPDATE_SIGNING_APPOINTMENT_CLIENTS:
            const tempSigningAppts = [...state.signingApptsInDeal];
            const matchingSigningAppt = tempSigningAppts.find((signingAppt) => signingAppt.id === action.payload.signingAppt.id);
            if(matchingSigningAppt) {
                const newSigningAppointment: SimpleSigningAppointment = { ...matchingSigningAppt };
                const clients = [];
                for(const client of action.payload.clients) {
                    delete client['label'];
                    clients.push(client);
                }
                newSigningAppointment.clients = clients;
                tempSigningAppts[tempSigningAppts.indexOf(matchingSigningAppt)] = newSigningAppointment;
            }
            return { ...state, signingApptsInDeal: tempSigningAppts };
        default:
            return state;
    }
}