import { AdminActionTypes } from "../actionTypes"
import { AdminActions } from "../context";
import { GlobalSimpleLenderContact } from "../../../libs/types/UniversalSurvey/LenderContact/simpleLenderContact";
import { GlobalLenderContact } from "../../../libs/types/UniversalSurvey/LenderContact/lenderContact";


export type AdminLenderContactsState = {
    contacts: GlobalSimpleLenderContact[];
    contactBeingEdited: GlobalLenderContact | undefined;
    shouldRefresh: boolean;
    resultCount: number;
}

export const defaultAdminLenderContactsState: AdminLenderContactsState = {
    contacts: [],
    contactBeingEdited: undefined,
    shouldRefresh: false,
    resultCount: 0
}

export function lenderContactsReducer(state: AdminLenderContactsState, action: AdminActions): AdminLenderContactsState {
    switch (action.type) {
        case AdminActionTypes.SET_LENDER_CONTACTS:
            return { ...state, contacts: action.payload };
        case AdminActionTypes.SET_LENDER_CONTACT_BEING_EDITED:
            return { ...state, contactBeingEdited: action.payload };
        case AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH:
            return { ...state, shouldRefresh: action.payload };
        case AdminActionTypes.SET_LENDER_CONTACT_RESULT_COUNT:
            return { ...state, resultCount: action.payload };
        default:
            return state;
    }
}