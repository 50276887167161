import { GlobalLegalFirm, LegalFirm } from "../../../types/UniversalSurvey/LegalFirm/legalFirm"
import { defaultFinancialInfo } from "./defaultFinancialInfo"

export const defaultLegalFirm: LegalFirm = {
    id: -1,
    name: undefined,
    partner: false,
    all_regions: undefined,
    gst_number: undefined,
    financial_info: defaultFinancialInfo,
    date_created: undefined,
    date_updated: undefined,

    legal_firm: undefined,
    regions: []
}

export const defaultGlobalLegalFirm: GlobalLegalFirm = {
    ...defaultLegalFirm,
    notes: undefined
}