import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack, styled } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { INPUT_SMALL, Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { LegalProfessional } from "../../../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { defaultLegalProfessional } from "../../../../../libs/resources/defaults/frontend/defaultLegalProfessional";
import { PADDED_GRID } from "../../../../../../components/Common/componentStyling/Styles";
import { regionListToString } from "../../../../../libs/utils/address";
import { formatLegalProfessionalType } from "../../../../../libs/utils/formatValues";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyLegalProfessionalModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [professionalObj, setProfessionalObj] = useState<LegalProfessional>({ ...defaultLegalProfessional });

    useEffect(() => {
        if(state.legalProfessionals.recordBeingEdited) {
            setProfessionalObj(state.legalProfessionals.recordBeingEdited);
        }
    }, [state.legalProfessionals.recordBeingEdited]);

    function discard() {
        dispatch({ type: actions.SET_LEGAL_PROFESSIONAL_BEING_EDITED, payload: undefined });
        setProfessionalObj({ ...defaultLegalProfessional });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={`${professionalObj.name}${professionalObj.initial ? ` (${professionalObj.initial})` : ""}`}
            subtitle="LEGAL PROFESSIONAL"
        >
            <PADDED_GRID container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Province(s) of Operation:
                        </INPUT_SMALL>
                        <Large>
                            {regionListToString(professionalObj.all_regions, professionalObj.regions)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Legal Professional Type:
                        </INPUT_SMALL>
                        <Large>
                            {formatLegalProfessionalType(professionalObj.type)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Email:
                        </INPUT_SMALL>
                        <Link 
                            href={professionalObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Cell Phone:
                        </INPUT_SMALL>
                        <Link
                            href={professionalObj.phone}
                            type="phone"
                        />
                    </Stack>
                </Grid>
            </PADDED_GRID>
        </ModalBase>
    );
}