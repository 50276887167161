import { GlobalLegalFirmOffice } from "../../../types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { defaultSimpleAddress } from "./defaultSimpleAddress";
import { defaultSimpleGlobalLegalFirm } from "./defaultSimpleGlobalLegalFirm";

export const defaultGlobalLegalFirmOffice: GlobalLegalFirmOffice = {
    id: -1,
    jurisdiction: undefined,
    municipality: undefined,
    phone: undefined,
    phone_extension: undefined,
    fax: undefined,
    email: undefined,
    notes: undefined,
    date_created: undefined,
    date_updated: undefined,

    general_address: defaultSimpleAddress,
    legal_firm: defaultSimpleGlobalLegalFirm
}