import { getClientTabName } from "../../context/UniversalSurvey/asyncActions/clients";
import { SimpleClient } from "../types/UniversalSurvey/Client/simpleClient";
import { getStrOutput } from "./pluralizationFunctions";

export function getClientsString(clients: SimpleClient[] | undefined, onBehalfOf: boolean = false): string {
    if (clients) {
        let clientNamedList: string[] = [];
        for (const client of clients) {
            clientNamedList.push(getClientTabName(client, false, onBehalfOf));
        }
        return getStrOutput(clientNamedList);
    } else {
        return "";
    }
}