import { SourceLender } from "../../../types/UniversalSurvey/Lender/SourceLender/sourceLender";
import { LenderType } from "../../enums/lenderInstitutions";

export const defaultLenderSource: SourceLender = {
    id: -1,
    type: LenderType.Institution,
    name: undefined,
    abbr_name: undefined,
    institution_number: undefined,
    date_created: undefined,
    date_updated: undefined
}