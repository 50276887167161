import { OtherChargeHolderRequest } from "../../../types/UniversalSurvey/OtherChargeHolder/otherChargeHolderRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultOtherChargeHolderRequest: OtherChargeHolderRequest = {
    name: null,
    amount: null,
    general_address: defaultAddressRequest,
    phone: null,
    email: null,
    financial_info: defaultFinancialInfoRequest
}