import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { getDealLiens, saveDealLien, newDealLien, getDealLien, removeDealLien, createDealChargeHolderCompany, createDealGovernment, newLenderRecord, saveLienPayoutAdditionalAmount, newLienPayoutAdditionalAmount, removeLienPayoutAdditionalAmount } from "../../../libs/service/axios/api";
import { TabOption, TabOptions } from "../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { Lien } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { sanitizeLienResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeLienAdditionalAmountRequest, sanitizeLienRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultLien } from "../../../libs/resources/defaults/frontend/defaultLien";
import { getLenderInfo } from "./lenders";
import { Lender, LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import { updateMortgageTabNamesOnLenderSave } from "./newMortgages";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeSimpleLienResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { SimpleLien } from "../../../libs/types/UniversalSurvey/ExistingLien/simpleLien";
import { ChargeHolderCompany, ChargeHolderCompanyListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { Government, GovernmentListItem } from "../../../libs/types/UniversalSurvey/Government/government";
import { OtherChargeHolder } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { sortByKey } from "../../../libs/utils/arrays";
import { navigateURL } from "./deal";
import { Sections } from "../../../libs/resources/enums/sections";
import { ChargeHolderType } from "../../../libs/resources/enums/chargeHolderType";
import { ChargeHolderListItem } from "../../../libs/types/UniversalSurvey/ExistingLien/chargeHolders";
import { getExistingLienTabName, getExistingLienTabNameFromObject } from "../../../libs/utils/labeling/lien";
import { defaultSimpleAddress } from "../../../libs/resources/defaults/frontend/defaultSimpleAddress";
import { Loading } from "../../../libs/resources/enums/loading";
import { LienPayoutCalcAdditionalAmount } from "../../../libs/types/UniversalSurvey/ExistingLienPayoutCalcAdditionalAmount/lienPayoutCalcAdditionalAmount";
import { defaultLienPayoutCalcAdditionalAmount } from "../../../libs/resources/defaults/frontend/defaultLienPayoutCalcAdditionalAmount";
import { formatLienInstrumentType } from "../../../libs/utils/formatValues";
import { LienInstrumentType } from "../../../libs/resources/enums/liens";

function switchToExistingLienSection(dispatch: Dispatch<Record<string, any>>, dealId: string, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });
    getLiensInDeal(dispatch, dealId, true, hasEntityId)
    .then(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
    })
}

async function getLiensInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, setTabs: boolean, hasEntityId: boolean) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LienList, isLoading: true } });
    return await getDealLiens(dealId)
        .then(function (response: any) {
            const existingLienList = response.data;
            const liensInDeal: SimpleLien[] = [];
            for (let i = 0; i < existingLienList.length; ++i) {
                const existingLien = sanitizeSimpleLienResponse(existingLienList[i]);
                liensInDeal.push(existingLien);
            }
            dispatch({ type: actions.SET_LIENS_IN_DEAL, payload: liensInDeal });

            if (setTabs) {
                sortByKey(liensInDeal, 'priority_after_closing');
                setLienTabs(dispatch, dealId, liensInDeal, hasEntityId)
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Encumbrances tabs: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LienList, isLoading: false } });
        })
}

function setLienTabs(dispatch: Dispatch<Record<string, any>>, dealId: string, liensInDeal: SimpleLien[], hasEntityId: boolean) {
    const lienTabs: TabOptions = [];
    if (liensInDeal.length > 0 && !hasEntityId) {
        navigateURL(dispatch, dealId, "data-sheet", Sections.Encumbrances, String(liensInDeal[0].id));
    }
    for (const existingLien of liensInDeal) {
        lienTabs.push({
            title: getExistingLienTabNameFromObject(existingLien),
            itemId: existingLien.id,
            subTitle: formatLienInstrumentType(existingLien.instrument_type),
            priority: existingLien.priority_after_closing,
            missingFields: 0,
        });
    }
    dispatch({ type: actions.SET_TAB_OPTIONS, payload: lienTabs});
}

function saveExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienInfo: Lien) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    saveDealLien(dealId, String(lienInfo.id), sanitizeLienRequest(lienInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_LIEN_IN_DEAL, payload: lienInfo });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save encumbrance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function addExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    newDealLien(dealId)
        .then(function (response: any) {
            const tab: TabOption = {
                title: getExistingLienTabName(undefined),
                itemId: response.data.id,
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            navigateURL(dispatch, dealId, "data-sheet", Sections.Encumbrances, String(response.data.id));
            dispatch({
                type: actions.SET_LIEN_INFO,
                payload: {
                    ...defaultLien,
                    id: response.data.id
                }
            });
            dispatch({ type: actions.ADD_LIEN_IN_DEAL, payload: { id: response.data.id } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `New deal encumbrance: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function changeSelectedExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: true } });

    dispatch({ type: actions.SET_LIEN_INFO, payload: undefined });
    dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: false });
    dispatch({ type: actions.SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED, payload: false });

    getDealLien(dealId, String(lienId))
        .then(function (response: any) {
            const cleanLien = sanitizeLienResponse(response.data)
            dispatch({ type: actions.SET_LIEN_INFO, payload: cleanLien });
            if (response.data.lender_record?.id) {
                getLenderInfo(dispatch, dealId, response.data.lender_record.id);
            }
            updateExistingLienTabName(dispatch, cleanLien.id, getExistingLienTabNameFromObject(cleanLien));
            updateExistingLienTabSubtitle(dispatch, cleanLien.id, cleanLien.instrument_type);
            updateExistingLienTabPriority(dispatch, cleanLien.id, cleanLien.priority_after_closing);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Encumbrance section content: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.Section, isLoading: false } });
        });
}

function deleteExistingLien(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number) {
    removeDealLien(dealId, String(lienId))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete lien: ${error}`, type: AlertTypes.Error } });
        })
}

function updateExistingLienTabName(dispatch: Dispatch<Record<string, any>>, lienId: number, tabName?: string | null, priority?: number | null, type?: string | null) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_TITLE,
        payload: {
            title: getExistingLienTabName(tabName),
            itemId: lienId,
            missingFields: 0,
        }
    });
}

function updateExistingLienTabPriority(dispatch: Dispatch<Record<string, any>>, lienId: number, priority: number | undefined) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_PRIORITY,
        payload: {
            itemId: lienId,
            priority: priority
        }
    })
}

function updateExistingLienTabSubtitle(dispatch: Dispatch<Record<string, any>>, lienId: number, type: LienInstrumentType | undefined) {
    dispatch({
        type: actions.UPDATE_TAB_OPTION_SUBTITLE,
        payload: {
            subtitle: formatLienInstrumentType(type),
            itemId: lienId,
        }
    });
}

function updateLienLenderIdAndTabName(dispatch: Dispatch<Record<string, any>>, lenderId: number, name: string | undefined, currEntity: number) {
    dispatch({ type: actions.SET_LIEN_LENDER_ID, payload: lenderId });
    updateExistingLienTabName(dispatch, currEntity, name);
}

function updateLienCompanyIdAndTabName(dispatch: Dispatch<Record<string, any>>, companyId: number, name: string | undefined, currEntity: number) {
    dispatch({ type: actions.SET_LIEN_COMPANY_ID, payload: companyId });
    updateExistingLienTabName(dispatch, currEntity, name);
}

function updateLienGovernmentIdAndTabName(dispatch: Dispatch<Record<string, any>>, governmentId: number, name: string | undefined, currEntity: number) {
    dispatch({ type: actions.SET_LIEN_GOVERNMENT_ID, payload: governmentId });
    updateExistingLienTabName(dispatch, currEntity, name);
}

function updateLienOtherChargeHolderIdAndTabName(dispatch: Dispatch<Record<string, any>>, otherChargeHolderId: number, name: string | undefined, currEntity: number) {
    dispatch({ type: actions.SET_LIEN_OTHER_CHARGE_HOLDER_ID, payload: otherChargeHolderId });
    updateExistingLienTabName(dispatch, currEntity, name);
}

function updateLienTabNamesOnLenderSave(dispatch: Dispatch<Record<string, any>>, dealId: string, lenderRecordId: number, lenderInfo: Lender) {
    updateMortgageTabNamesOnLenderSave(dispatch, dealId, lenderRecordId, lenderInfo);
    getDealLiens(dealId)
        .then(function (response: any) {
            for (const lien of response.data) {
                if (lien.lender_record?.id === lenderRecordId && lien.charge_holder_type === ChargeHolderType.Lender) {
                    updateExistingLienTabName(dispatch, lien.id, lenderInfo.abbr_name);
                }
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal liens: ${error}`, type: AlertTypes.Error } });
        })
}

function updateLienTabNamesOnChargeHolderCompanySave(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: ChargeHolderCompany) {
    getDealLiens(dealId)
        .then(function (response: any) {
            for (const lien of response.data) {
                if (lien.charge_holder_company_record?.id === companyInfo.id && lien.charge_holder_type === ChargeHolderType.Company) {
                    updateExistingLienTabName(dispatch, lien.id, companyInfo.abbr_name);
                }
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal liens: ${error}`, type: AlertTypes.Error } });
        })
}

function updateLienTabNamesOnGovernmentSave(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentInfo: Government) {
    getDealLiens(dealId)
    .then(function (response: any) {
        for (const lien of response.data) {
            if (lien.government_record?.id === governmentInfo.id && lien.charge_holder_type === ChargeHolderType.Government) {
                updateExistingLienTabName(dispatch, lien.id, governmentInfo.abbr_name);
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal liens: ${error}`, type: AlertTypes.Error } });
    })  
}

function updateLienTabNamesOnOtherChargeHolderSave(dispatch: Dispatch<Record<string, any>>, dealId: string, otherChargeHolderInfo: OtherChargeHolder) {
    getDealLiens(dealId)
    .then(function (response: any) {
        for (const lien of response.data) {
            if (lien.other_charge_holder_record?.id === otherChargeHolderInfo.id && lien.charge_holder_type === ChargeHolderType.Other) {
                updateExistingLienTabName(dispatch, lien.id, otherChargeHolderInfo.name);
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal liens: ${error}`, type: AlertTypes.Error } });
    })  
}

// Creating debt holder records from global records

function createGovernmentChargeHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, chargeHolderListItem: ChargeHolderListItem) {
    let newRecordId: number;
    createDealGovernment(dealId, chargeHolderListItem.id)
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: GovernmentListItem = {
                label: chargeHolderListItem.label,
                name: chargeHolderListItem.name,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_GOVERNMENT_OPTION_WITH_RECORD, payload: { oldGovernmentId: chargeHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_CHARGE_HOLDER, payload: { id: newRecordId, name: chargeHolderListItem.name, charge_holder_type: ChargeHolderType.Government } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government record: ${error}`, type: AlertTypes.Error } });
        })
}

function createLenderChargeHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, chargeHolderListItem: ChargeHolderListItem) {
    let newRecordId: number;
    newLenderRecord(dealId, { lender_id: chargeHolderListItem.id })
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: LenderListItem = {
                label: chargeHolderListItem.label,
                name: chargeHolderListItem.name,
                type: undefined,
                institution_number: undefined,
                branch_number: undefined,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_LENDER_OPTION_WITH_LENDER_RECORD, payload: { oldLenderId: chargeHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_CHARGE_HOLDER, payload: { id: newRecordId, name: chargeHolderListItem.name, charge_holder_type: ChargeHolderType.Lender } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create lender record: ${error}`, type: AlertTypes.Error } });
        })
}

function createCompanyChargeHolderRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, chargeHolderListItem: ChargeHolderListItem) {
    let newRecordId: number;
    createDealChargeHolderCompany(dealId, chargeHolderListItem.id)
        .then(function (response: any) {
            newRecordId = response.data.id;
            const newListItem: ChargeHolderCompanyListItem = {
                label: chargeHolderListItem.label,
                name: chargeHolderListItem.name,
                general_address: defaultSimpleAddress,
                abbr_name: undefined,
                isRecord: true,
                id: newRecordId
            };
            dispatch({ type: actions.REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: chargeHolderListItem.id, newOption: newListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_CHARGE_HOLDER, payload: { id: newRecordId, name: chargeHolderListItem.name, charge_holder_type: ChargeHolderType.Company } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createChargeHolderRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, chargeHolderListItem: ChargeHolderListItem) {
    switch(chargeHolderListItem.charge_holder_type) {
        case ChargeHolderType.Lender:
            createLenderChargeHolderRecord(dispatch, dealId, chargeHolderListItem);
            break;
        case ChargeHolderType.Company:
            createCompanyChargeHolderRecord(dispatch, dealId, chargeHolderListItem);
            break;
        case ChargeHolderType.Government:
            createGovernmentChargeHolderRecord(dispatch, dealId, chargeHolderListItem);
            break;
    }
}

function deleteExistingLienFromTab(dispatch: Dispatch<Record<string, any>>, dealId: string, entityId: number, tabOptions: TabOptions, isActive: boolean) {
    removeDealLien(dealId, String(entityId))
    .then(function (response: any) {
        dispatch({ type: actions.REMOVE_TAB_OPTION, payload: entityId });
        if (isActive) {
            if (tabOptions.length > 1) {
                let newOption = tabOptions.find((value) => value.itemId !== entityId);
                if (newOption) {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.Encumbrances, String(newOption.itemId));
                } else {
                    navigateURL(dispatch, dealId, "data-sheet", Sections.Encumbrances, undefined);
                    dispatch({ type: actions.SET_LIEN_INFO, payload: undefined });
                }
            } else {
                navigateURL(dispatch, dealId, "data-sheet", Sections.Encumbrances, undefined);
                dispatch({ type: actions.SET_LIEN_INFO, payload: undefined });
            }
        }
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete encumbrance: ${error.response.data.message[0]}`, type: AlertTypes.Error } });
    })
}

function saveLienAdditionalAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, amountInfo: LienPayoutCalcAdditionalAmount) {
    saveLienPayoutAdditionalAmount(dealId, String(lienId), String(amountInfo.id), sanitizeLienAdditionalAmountRequest(amountInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save encumbrance payout additional amount: ${error}`, type: AlertTypes.Error } });
        })
}

function addLienAdditionalAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number) {
    newLienPayoutAdditionalAmount(dealId, String(lienId))
        .then(function (response: any) {
            dispatch({ type: actions.ADD_LIEN_ADDITIONAL_AMOUNT, payload: { ...defaultLienPayoutCalcAdditionalAmount, id: response.data.id } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create encumbrance payout additional amount: ${error}`, type: AlertTypes.Error } });
        })
}

function deleteLienAdditionalAmount(dispatch: Dispatch<Record<string, any>>, dealId: string, lienId: number, amountId: number) {
    removeLienPayoutAdditionalAmount(dealId, String(lienId), String(amountId))
        .then(function () {
            dispatch({ type: actions.REMOVE_LIEN_ADDITIONAL_AMOUNT, payload: amountId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Remove encumbrance payout additional amount: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    switchToExistingLienSection,
    getLiensInDeal,
    saveExistingLien,
    addExistingLien,
    changeSelectedExistingLien,
    updateExistingLienTabName,
    updateLienCompanyIdAndTabName,
    updateLienLenderIdAndTabName,
    updateLienGovernmentIdAndTabName,
    updateLienOtherChargeHolderIdAndTabName,
    updateLienTabNamesOnLenderSave,
    updateLienTabNamesOnChargeHolderCompanySave,
    updateLienTabNamesOnGovernmentSave,
    updateLienTabNamesOnOtherChargeHolderSave,
    updateExistingLienTabPriority,
    updateExistingLienTabSubtitle,
    deleteExistingLien,
    createChargeHolderRecordFromGlobal,
    deleteExistingLienFromTab,
    saveLienAdditionalAmount,
    addLienAdditionalAmount,
    deleteLienAdditionalAmount
}