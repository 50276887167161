import { ChargeHolderCompanyRequest } from "../../../types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompanyRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultChargeHolderCompanyRequest: ChargeHolderCompanyRequest = {
    type: null,
    name: null,
    abbr_name: null,
    general_contact_name: null,
    general_address: defaultAddressRequest,
    general_email: null,
    address_for_service: defaultAddressRequest,
    financial_info: defaultFinancialInfoRequest,
    website: null,
}