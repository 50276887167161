import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    saveDealClient,
    newDealClient,
    savePoaEoeRelationship
} from "../../../libs/service/axios/api";
import { TabOption } from '../../../libs/types/UniversalSurvey/Frontend/tabOption';
import { Client } from '../../../libs/types/UniversalSurvey/Client/client';
import { sanitizeClientRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { SimplePoaEoeRelationship } from "../../../libs/types/UniversalSurvey/PoaEoeRelationship/SimplePoaEoeRelationship";
import { sanitizePoaEoeRelationshipRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { newPoaEoeRelationship } from "../../../libs/service/axios/api";
import { PoaEoeRelationshipType } from "../../../libs/resources/enums/poaEoeRelationships";
import { defaultSimplePoaEoeRelationship } from "../../../libs/resources/defaults/frontend/defaultSimplePoaEoeRelationship";
import { defaultSimpleClient } from "../../../libs/resources/defaults/frontend/defaultSimpleClient";
import { deletePoaEoeRelationship } from "../../../libs/service/axios/api";
import { BasicClient } from "../../../libs/types/UniversalSurvey/Client/basicClient";
import { getClientTabName, getClientTabSubtitle } from "./clients";
import { BasicRepresentative } from "../../../libs/types/UniversalSurvey/Client/basicRepresentative";
import { RoleTypesDB } from "../../../libs/resources/enums/roles";
import { SimpleRole } from "../../../libs/types/UniversalSurvey/Roles/simpleRole";
import { RecordType } from "../../../libs/resources/enums/recordTypes";
import { Loading } from "../../../libs/resources/enums/loading";

function updatePoaEoeReasonOrActing(dispatch: Dispatch<Record<string, any>>, dealId: string, relationships: SimplePoaEoeRelationship[], clientsInDeal: SimpleClient[]) {
    const promiseList: any[] = [];
    for (const relationship of relationships) {
        promiseList.push(savePoaEoeRelationship(dealId, String(relationship.id), sanitizePoaEoeRelationshipRequest(relationship))
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE relationship: ${error}`, type: AlertTypes.Error } });
            }));
    }

    Promise.all(promiseList).then(function () {
        const tempClientsInDeal = [...clientsInDeal];
        for (let i = 0; i < tempClientsInDeal.length; i++) {
            const tempClient = { ...tempClientsInDeal[i] };
            if (tempClient.poa_eoe_relationships) {
                for (let j = 0; j < tempClient.poa_eoe_relationships.length; j++) {
                    const matchingRelationship = relationships.find((relationship) => relationship.id === tempClient.poa_eoe_relationships![j].id);
                    if (matchingRelationship) {
                        tempClient.poa_eoe_relationships[j] = matchingRelationship;
                    }
                }
            }
            tempClientsInDeal[i] = tempClient;
        }
        dispatch({ type: actions.SET_CLIENTS_IN_DEAL, payload: tempClientsInDeal });
    })
}

function createPoaEoeRelationship(dispatch: Dispatch<Record<string, any>>, dealId: string, representativeId: string | null, representeeId: string | null, type?: PoaEoeRelationshipType) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    newPoaEoeRelationship(dealId, representativeId ? Number(representativeId) : null, representeeId ? Number(representeeId) : null)
        .then(function (response: any) {
            dispatch({ type: actions.ADD_CLIENT_POA_EOE_RELATIONSHIP, payload: { id: response.data.id, representativeId: representativeId, representeeId: representeeId, type: type ?? undefined } });
            if (type) {
                const relationship = {
                    ...defaultSimplePoaEoeRelationship,
                    id: response.data.id,
                    type: type,
                    representative: representativeId ? {
                        ...defaultSimpleClient,
                        id: parseInt(representativeId)
                    } : undefined,
                    representee: representeeId ? {
                        ...defaultSimpleClient,
                        id: parseInt(representeeId)
                    } : undefined
                };
                savePoaEoeRelationship(dealId, relationship.id, sanitizePoaEoeRelationshipRequest(relationship))
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
                    })
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function callSavePoaEoeRelationship(dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: SimplePoaEoeRelationship) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    savePoaEoeRelationship(dealId, String(relationship.id), sanitizePoaEoeRelationshipRequest(relationship))
        .then(function () {
            dispatch({ type: actions.UPDATE_CLIENT_POA_EOE_RELATIONSHIP, payload: relationship });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function updatePoaEoeRelationshipRep(dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: SimplePoaEoeRelationship, repBeingUpdated: "representative" | "representee", newRep: SimpleClient | undefined) {
    // if choosing the same person, return
    if (relationship.representative?.id === newRep?.id || relationship.representee?.id === newRep?.id) {
        return;
    }

    dispatch({ type: actions.SET_IS_SAVING, payload: true });

    const tempRelationship = { ...relationship };
    if (repBeingUpdated === "representative") {
        tempRelationship.representative = newRep;
    } else {
        tempRelationship.representee = newRep;
    }

    savePoaEoeRelationship(dealId, String(tempRelationship.id), sanitizePoaEoeRelationshipRequest(tempRelationship))
        .then(function () {
            dispatch({
                type: actions.CHANGE_POA_EOE_RELATIONSHIP_REP,
                payload: {
                    relationship: tempRelationship,
                    repBeingUpdated: repBeingUpdated,
                    oldRepId: repBeingUpdated === "representative" ? relationship.representative?.id : relationship.representee?.id,
                    newRep: newRep
                }
            });

            if (repBeingUpdated === "representative") {
                if (relationship.representative) {
                    removePoaEoeRole(dispatch, relationship.representative, relationship.id)
                }
                if (relationship.type && newRep) {
                    addPoaEoeRole(dispatch, newRep, relationship.type === PoaEoeRelationshipType.POA ? RoleTypesDB.POA : RoleTypesDB.EOE, relationship.id);
                }
            } else if (relationship.type) {
                dispatch({ type: actions.UPDATE_POA_EOE_RELATIONSHIP_TYPES, payload: { relationships: [relationship], newType: relationship.type } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function updatePoaEoeRelationshipTypes(dispatch: Dispatch<Record<string, any>>, dealId: string, relationships: SimplePoaEoeRelationship[], newType: PoaEoeRelationshipType) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    // due to BE constraints, need to update all relationships to type null first, then update to the new type
    const typeToNullPromiseList: any[] = [];
    for (const relationship of relationships) {
        typeToNullPromiseList.push(savePoaEoeRelationship(dealId, String(relationship.id), sanitizePoaEoeRelationshipRequest({ ...relationship, type: undefined }))
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
            }))
    }

    Promise.all(typeToNullPromiseList).then(function () {
        const newTypePromiseList: any[] = [];
        for (const relationship of relationships) {
            newTypePromiseList.push(savePoaEoeRelationship(dealId, String(relationship.id), sanitizePoaEoeRelationshipRequest({ ...relationship, type: newType }))
                .then(function () {
                    if (relationship.representative) {
                        changePoaEoeRole(dispatch, relationship.representative, newType === PoaEoeRelationshipType.POA ? RoleTypesDB.POA : RoleTypesDB.EOE, relationship.id);
                    }
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
                }))
        }

        Promise.all(newTypePromiseList).then(function () {
            dispatch({ type: actions.UPDATE_POA_EOE_RELATIONSHIP_TYPES, payload: { relationships: relationships, newType: newType } });
        })
    })
        .finally(function () {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function updatePoaEoeRelationship<
    K extends keyof SimplePoaEoeRelationship,
    V extends SimplePoaEoeRelationship[K]
>(dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: SimplePoaEoeRelationship, key: K, value: V) {
    const tempRelationship = { ...relationship };
    tempRelationship[key] = value;
    callSavePoaEoeRelationship(dispatch, dealId, tempRelationship);
}

function removePoaEoeRelationship(dispatch: Dispatch<Record<string, any>>, dealId: string, relationshipId: number) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    deletePoaEoeRelationship(dealId, String(relationshipId))
        .then(function () {
            dispatch({ type: actions.DELETE_CLIENT_POA_EOE_RELATIONSHIP, payload: relationshipId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing POA/EOE Relationship: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        });
}

function createNewRepresentee(dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: SimplePoaEoeRelationship, clientInfo: BasicClient) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: true } });
    let repAddedSuccessfully = true;
    newDealClient(dealId)
        .then(function (response) {
            const clientId = response.data.client_id
            const cleanRelationship = { ...relationship };
            cleanRelationship.representee = {
                ...defaultSimpleClient,
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                roles: []
            };

            const newClient: SimpleClient = {
                ...defaultSimpleClient,
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                roles: [],
                poa_eoe_relationships: [cleanRelationship]
            }
            const tab: TabOption = {
                title: getClientTabName(newClient),
                subTitle: getClientTabSubtitle(newClient),
                itemId: clientId,
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            dispatch({ type: actions.ADD_CLIENT_IN_DEAL, payload: newClient })

            const clientObj: Partial<Client> = {
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                email: clientInfo.email,
            }
            const cleanObj = JSON.parse(JSON.stringify(clientObj));
            const promiseList: any[] = [];
            promiseList.push(saveDealClient(dealId, String(clientId), sanitizeClientRequest(cleanObj))
                .catch(function (error: any) {
                    repAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving representee: ${error}`, type: AlertTypes.Error } });
                }));

            promiseList.push(savePoaEoeRelationship(dealId, String(cleanRelationship.id), sanitizePoaEoeRelationshipRequest(cleanRelationship))
                .catch(function (error: any) {
                    repAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding representee to POA/EOE relationship: ${error}`, type: AlertTypes.Error } });
                }));

            Promise.all(promiseList).then(function () {
                if (repAddedSuccessfully) {
                    dispatch({ type: actions.UPDATE_CLIENT_POA_EOE_RELATIONSHIP, payload: cleanRelationship })
                }
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: false } });
            });
        }).catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveClient, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating representee: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewRepresentative(dispatch: Dispatch<Record<string, any>>, dealId: string, relationship: SimplePoaEoeRelationship, relationshipsToUpdate: SimplePoaEoeRelationship[], clientInfo: BasicRepresentative) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveRepresentative, isLoading: true } });
    let repAddedSuccessfully = true;
    newDealClient(dealId)
        .then(function (response) {
            const clientId = response.data.client_id
            const cleanRelationship = { ...relationship, type: undefined };
            cleanRelationship.representative = { 
                ...defaultSimpleClient,
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                roles: [{ record_id: relationship.id, record_type: RecordType.PoaEoe, role: clientInfo.represtativeType === PoaEoeRelationshipType.POA ? RoleTypesDB.POA : RoleTypesDB.EOE }]
            };

            const newClient: SimpleClient = {
                ...defaultSimpleClient,
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                roles: [{ record_id: relationship.id, record_type: RecordType.PoaEoe, role: clientInfo.represtativeType === PoaEoeRelationshipType.POA ? RoleTypesDB.POA : RoleTypesDB.EOE }],
                poa_eoe_relationships: [cleanRelationship]
            }
            const tab: TabOption = {
                title: getClientTabName(newClient),
                subTitle: getClientTabSubtitle(newClient),
                itemId: clientId,
                missingFields: 0,
            };
            dispatch({ type: actions.ADD_TAB_OPTION, payload: tab });
            dispatch({ type: actions.ADD_CLIENT_IN_DEAL, payload: newClient });

            const clientObj: Partial<Client> = {
                id: clientId,
                first_name: clientInfo.first_name,
                last_name: clientInfo.last_name,
                email: clientInfo.email,
            }
            const cleanObj = JSON.parse(JSON.stringify(clientObj));
            const promiseList: any[] = [];
            promiseList.push(saveDealClient(dealId, String(clientId), sanitizeClientRequest(cleanObj))
                .catch(function (error: any) {
                    repAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving representative: ${error}`, type: AlertTypes.Error } });
                }));

            promiseList.push(savePoaEoeRelationship(dealId, String(cleanRelationship.id), sanitizePoaEoeRelationshipRequest(cleanRelationship))
                .catch(function (error: any) {
                    repAddedSuccessfully = false;
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding representative to POA/EOE relationship: ${error}`, type: AlertTypes.Error } });
                }));

            Promise.all(promiseList).then(function () {
                if (repAddedSuccessfully) {
                    const matchingRel = relationshipsToUpdate.find((rel) => rel.id === cleanRelationship.id);
                    if (matchingRel) {
                        relationshipsToUpdate[relationshipsToUpdate.indexOf(matchingRel)] = cleanRelationship;
                    }
                    dispatch({ type: actions.UPDATE_CLIENT_POA_EOE_RELATIONSHIP, payload: cleanRelationship });
                    updatePoaEoeRelationshipTypes(dispatch, dealId, relationshipsToUpdate, clientInfo.represtativeType!);
                }
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveRepresentative, isLoading: false } });
            });
        }).catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveRepresentative, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating representative: ${error}`, type: AlertTypes.Error } });
        })
}

function removePoaEoeRole(dispatch: Dispatch<Record<string, any>>, client: SimpleClient, relationshipId: number) {
    const newClient: SimpleClient = {
        ...client,
        roles: client.roles ? client.roles.filter((role) => !(role.record_type === RecordType.PoaEoe && role.record_id === relationshipId)) : []
    }
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: newClient });
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: newClient.id, subtitle: getClientTabSubtitle(newClient) } });
}

function addPoaEoeRole(dispatch: Dispatch<Record<string, any>>, client: SimpleClient, roleToAdd: RoleTypesDB, relationshipId: number) {
    const newRole: SimpleRole = {
        record_type: RecordType.PoaEoe,
        role: roleToAdd,
        record_id: relationshipId
    };
    const newClient: SimpleClient = {
        ...client,
        roles: client.roles ? [...client.roles, newRole] : [newRole]
    }
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL, payload: newClient });
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: client.id, subtitle: getClientTabSubtitle(newClient) } });
}

function changePoaEoeRole(dispatch: Dispatch<Record<string, any>>, client: SimpleClient, roleToAdd: RoleTypesDB, relationshipId: number) {
    const newRole: SimpleRole = {
        record_type: RecordType.PoaEoe,
        role: roleToAdd,
        record_id: relationshipId
    };
    const newClient: SimpleClient = {
        ...client,
        roles: client.roles ? [...client.roles.filter((role) => !(role.record_type === RecordType.PoaEoe && role.record_id === relationshipId)), newRole] : [newRole]
    }
    dispatch({ type: actions.UPDATE_CLIENT_IN_DEAL_ROLES, payload: newClient.roles });
    dispatch({ type: actions.UPDATE_TAB_OPTION_SUBTITLE, payload: { itemId: client.id, subtitle: getClientTabSubtitle(newClient) } });
}

export {
    updatePoaEoeReasonOrActing,
    createPoaEoeRelationship,
    updatePoaEoeRelationshipRep,
    updatePoaEoeRelationshipTypes,
    updatePoaEoeRelationship,
    removePoaEoeRelationship,
    createNewRepresentee,
    createNewRepresentative,
    callSavePoaEoeRelationship,
    removePoaEoeRole
}