import { PriorityBeforeClosingItem } from "../../../libs/types/UniversalSurvey/PriorityBeforeClosing/priorityBeforeClosing";
import { PriorityAfterClosingItem } from "../../../libs/types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosing";
import * as actions from "../actionTypes"

export type PrioritiesState = {
    priorityAfterClosingList: PriorityAfterClosingItem[];
    newPriorityAfterClosing: PriorityAfterClosingItem | undefined;
    priorityBeforeClosingList: PriorityBeforeClosingItem[];
}

export const defaultPriorityState: PrioritiesState = {
    priorityAfterClosingList: [],
    newPriorityAfterClosing: undefined,
    priorityBeforeClosingList: [],
}

export function priorityReducer(state: PrioritiesState, action: Record<string, any>): PrioritiesState {
    switch (action.type) {
        case actions.SET_PRIORITIES_AFTER_CLOSING_IN_DEAL:
            return { ...state, priorityAfterClosingList: action.payload };
        case actions.SET_NEW_PRIORITY_AFTER_CLOSING:
            return { ...state, newPriorityAfterClosing: action.payload };
        case actions.SET_PRIORITIES_BEFORE_CLOSING_IN_DEAL:
            return { ...state, priorityBeforeClosingList: action.payload };
        default:
            return state;
    }
}