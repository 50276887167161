import { LetterToMortgageeExistingMortgageContentType } from "../../../enums/documents/letterToMortgageeExistingMortgage";
import { LetterToMortgageeExistingMortgageContext, LetterToMortgageeExistingMortgageContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/letterToMortgageeExistingMortgageDocument";
import { defaultSimpleLien } from "../../frontend/defaultSimpleLien";

export const defaultLetterToMortgageeExistingMortgageContext: LetterToMortgageeExistingMortgageContext = {
    existing_mortgage_id: -1,
    content_type: LetterToMortgageeExistingMortgageContentType.Blank,
    
    lien: defaultSimpleLien
}

export const defaultLetterToMortgageeExistingMortgageContextRequest: LetterToMortgageeExistingMortgageContextRequest = {
    existing_mortgage_id: -1,
    content_type: LetterToMortgageeExistingMortgageContentType.Blank    
}