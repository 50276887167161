import { DebtHolder } from "../../../types/UniversalSurvey/Debts/debtHolder";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultDebtHolder: DebtHolder = {
    id: -1,
    name: undefined,
    email: undefined,
    phone: undefined,
    general_address: defaultSimpleAddress,
    financial_info: defaultFinancialInfo
}