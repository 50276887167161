import { DashboardUser } from "../../../libs/types/UniversalSurvey/DashboardUser/dashboardUser";
import * as actions from "../actionTypes"

export type UserState = {
    user: DashboardUser | undefined
}

export const defaultUserState: UserState = {
    user: undefined,
}

export function userReducer(state: UserState, action: Record<string, any>): UserState {
    switch (action.type) {
        case actions.SET_USER:
            return { ...state, user: action.payload };
        default:
            return state;
    }
}