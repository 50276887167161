import { Deal } from '../../../types/UniversalSurvey/Deal/deal';
import { DealStatus } from '../../enums/dealStatus';

export const defaultDeal: Deal = {
    id: -1,
    region_id: undefined,
    opened_date: undefined,
    last_update: undefined,
    closing_date: undefined,
    acting_as: undefined,
    opened_by: undefined,
    last_update_by: undefined,
    file_num: undefined,
    owner_clerk_name: undefined,
    owner_clerk: undefined,
    staff_job_record: [],
    signing_appointment: [],
    referrer: undefined,
    client_capacity: undefined,
    lawyer_record: undefined,
    signer_record: undefined,
    contributing_clerks: undefined,
    file_opened: undefined,
    primary_transaction_id: undefined,
    primary_transaction_type: undefined,
    subject_property_id: undefined,
    status: DealStatus.Open,
    clients: [],
    subject_property_address: undefined,
    date_created: undefined,
    date_updated: undefined,
}