import React, { useEffect, useState } from "react";

import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { ChevronDown, LinkIcon, LinkBrokenIcon, TrashIcon, EditIcon } from "../Icons/Iconography";
import colors from "../componentStyling/Colors";
import theme from "../componentStyling/Theme"
import { H3 } from "../Typography";
import BasicButton from "../Button/BasicButton";
import { BACKGROUND_MODAL_CONTAINER, BORDER_1, PAPER_PROPS } from "../componentStyling/Styles";
import Colors from "../componentStyling/Colors";

type DeededAccordionProps = AccordionProps & {
    title: string;
    onLink?: () => void;
    onUnlink?: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
    dialog?: JSX.Element;
    dialogOpen?: boolean;
    dialogBackgroundColor?: string;
    id?: string;
}

const BasicAccordion = React.forwardRef<HTMLDivElement, DeededAccordionProps>(
    function BasicAccordion(props, ref) {
        const {
            title,
            onLink,
            onUnlink,
            onDelete,
            onEdit,
            dialog,
            dialogOpen,
            dialogBackgroundColor,
            id
        } = props;
        const [unlinkHovered, setUnlinkHovered] = useState<boolean>(false);

        useEffect(() => {
            if (dialogOpen) {
                // Block app scroll on modal open
                document.documentElement.style.overflow = "hidden";

                // Clear interval on modal close
                // Unset style blocking scroll
                return () => {
                    document.documentElement.style.overflow = "unset";
                }
            }
        }, [dialogOpen])

        return (
            <StyledAccordion disableGutters id={id} {...props}>
                <AccordionSummary
                    expandIcon={<ChevronDown color={colors.BLACK}/>}
                >
                    <H3>{title}</H3>
                    <ButtonDiv>
                        {onLink && 
                            <BasicButton
                                leftIcon={<LinkIcon color={colors.BLACK}/>}
                                size="small"
                                label={{ text: "Link Existing", inputId: `link-existing-${title}` }}
                                typeOf="secondary"
                                onClick={(e) => {onLink(); e.stopPropagation();}}
                            />
                        }
                        {onUnlink &&
                            <div
                                onMouseEnter={() => setUnlinkHovered(true)}
                                onMouseLeave={() => setUnlinkHovered(false)}
                            >
                                <BasicButton
                                    leftIcon={<LinkBrokenIcon color={unlinkHovered ? colors.WHITE : theme.ERROR}/>}
                                    size="small"
                                    label={{ text: "Unlink", inputId: `unlink-${title}` }}
                                    typeOf="destructive"
                                    onClick={(e) => {onUnlink(); e.stopPropagation();}}
                                />
                            </div>
                        }
                        {onEdit && 
                            <div onClick={(e) => { onEdit(); e.stopPropagation(); }}>
                                <EditIcon color={Colors.BLACK} />
                            </div>
                        }
                        {onDelete &&
                            <div onClick={(e) => { onDelete(); e.stopPropagation(); }}>
                                <TrashIcon color={theme.ERROR} />
                            </div>
                        }
                    </ButtonDiv>
                </AccordionSummary>
                <AccordionDetails>
                    <>
                        {dialog && dialogOpen !== undefined && id &&
                            <Dialog
                                fullWidth
                                maxWidth={false}
                                PaperProps={{ sx: {...PAPER_PROPS, backgroundColor: dialogBackgroundColor ?? colors.BLACK_75o} }}
                                open={dialogOpen}
                                hideBackdrop
                                style={{
                                    width: document.getElementById(id)?.getBoundingClientRect().width! + 64,
                                    left: document.getElementById(id)?.getBoundingClientRect().left! - 32,
                                    top: 2 * (document.getElementById(id)?.getBoundingClientRect().top! + document.getElementById(id)?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)
                                }}
                            >
                                <BACKGROUND_MODAL_CONTAINER height={document.getElementById(id)?.getBoundingClientRect().height}/>
                                {dialog}
                            </Dialog>
                        }
                        {props.children}
                    </>
                </AccordionDetails>
            </StyledAccordion>
        )
    }
)

const StyledAccordion = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== "dialogBackgroundColor" && prop !== "onLink" && prop !== "onUnlink" && prop !== "dialogOpen" && prop !== "onDelete" && prop !== "onEdit"
})({
    borderRadius: "0.6rem",
    border: BORDER_1(theme.PLACEHOLDER),
    marginTop: "2.4rem",
    "&::before": {
        display: "none"
    }
})

const ButtonDiv = styled('div')({
    display: "flex",
    marginLeft: "auto",
    marginRight: "4rem",
    alignItems: "center",
    gap: "4rem"
})

export default BasicAccordion;