import { useContext, Dispatch, useEffect, useState } from 'react';

import { Grid, styled } from '@mui/material';

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import SectionTabButton from './SectionTabButton';
import BasicButton from '../../../../../components/Common/Button/BasicButton';
import { TabOptions } from '../../../../libs/types/UniversalSurvey/Frontend/tabOption';
import { Sections } from '../../../../libs/resources/enums/sections';
import { addSectionTabsPrefix } from '../../../../libs/utils/labeling/sectionTabs';
import { sortByKey, sortByArrayOrder } from '../../../../libs/utils/arrays';
import GenericDialog from '../../../../../components/Common/Modal/GenericDialog';
import { capitalizeFirstLetter } from '../../../../libs/utils/formatValues';

export type SectionButton = {
    function: (dispatch: Dispatch<Record<string, any>>, dealId: string) => void;
    buttonText: string;
}

export type SectionTabsProps = {
    buttonRow: SectionButton[],
    entityText?: string,
    handleRemove?: (
        dispatch: Dispatch<Record<string, any>>,
        dealId: string,
        entityId: number,
        tabOptions: TabOptions,
        isActive: boolean,
    ) => void;
}

export default function SectionTabs(props:  SectionTabsProps) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [tabOptions, setTabOptions] = useState<TabOptions>([]);
    const [itemId, setItemId] = useState<number | undefined>(undefined);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

    function handleRemove(entityId: number, isActive: boolean) {
        setRemoveModalOpen(true);
        setItemId(entityId);
        setIsActive(isActive)
    }

    function handleDeleteConfirm() {
        if (itemId) {
            props.handleRemove!(dispatch, String(state.deal.dealInfo?.id), itemId, state.dataSheet.tabOptions, isActive);
            setRemoveModalOpen(false);
        }
    }

    // Adding section title prefixes and running tab sorting methods
    useEffect(() => {
        if (state.dataSheet.tabOptions.length > 0) {
            let tempTabOptions = [...state.dataSheet.tabOptions];
            switch(state.dataSheet.currSection) {
                case Sections.Encumbrances:
                    sortByKey(tempTabOptions, 'priority');
                    setTabOptions(addSectionTabsPrefix(tempTabOptions, "Encumbrance"));
                    return;
                case Sections.NewMortgage:
                    sortByKey(tempTabOptions, 'priority')
                    setTabOptions(addSectionTabsPrefix(tempTabOptions, "MTG"));
                    return;
                case Sections.ClientInformation:
                    sortByArrayOrder(tempTabOptions, "client");
                    setTabOptions(tempTabOptions);
                    return;
                case Sections.PropertyInformation:
                    sortByArrayOrder(tempTabOptions, "property");
                    setTabOptions(tempTabOptions);
                    return;
                default:
                    setTabOptions(tempTabOptions);
                    return;
            }
        }
        setTabOptions([]);
    }, [state.dataSheet.tabOptions]);

    return (
        <SectionContainer container>
            <GenericDialog
                action="destructive"
                title="Confirm Removal"
                onCancel={() => setRemoveModalOpen(false)}
                onSubmit={() => handleDeleteConfirm()}
                open={removeModalOpen}
                contentText={`Are you sure you want to remove this ${props.entityText?.toLowerCase()}? This is permanent and irreversible`}
            />
            {tabOptions.map((item, i) => (
                <Grid item key={i}>
                    <SectionTabButton 
                        isActive={state.dataSheet.currEntity === item.itemId} 
                        itemId={item.itemId} 
                        missingFields={item.missingFields}
                        subTitle={item.subTitle}
                        handleRemove={handleRemove}
                        testId="entry-tab"
                    >
                        {item.title.includes("null") ? `New ${capitalizeFirstLetter(props.entityText)}` : item.title}
                    </SectionTabButton>
                </Grid>
            ))}
            {props.buttonRow.length > 0 && (
                props.buttonRow.map((buttonProps, index) => (
                    <Grid item key={index}>
                        <BasicButton 
                            label={{text: `Add ${buttonProps.buttonText}`, inputId: `add-entity-${index}`}}
                            action="add"
                            typeOf="secondary"
                            onClick={() => buttonProps.function(dispatch, String(state.deal.dealInfo?.id))}
                            testId="add-entry-button"
                        />
                    </Grid>
                ))
            )}
        </SectionContainer>
    );
}

const SectionContainer = styled(Grid)({
    //@ts-ignore
    gap: "2rem",
    alignItems: "flex-end",
    direction: "row"
})