import { AdminSections } from "../../libs/resources/enums/sections";
import { AdminLoading } from "../../libs/resources/enums/loading";
import { AlertTypes } from "../../libs/resources/enums/alertTypes";
import { RegionListItem } from "../../libs/types/DealList/deals";
import { GlobalSimpleLenderContact } from "../../libs/types/UniversalSurvey/LenderContact/simpleLenderContact";
import { GlobalLenderContact } from "../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { SimpleLenderBranch } from "../../libs/types/UniversalSurvey/Lender/LenderBranch/simpleLenderBranch";
import { LenderBranch } from "../../libs/types/UniversalSurvey/Lender/LenderBranch/lenderBranch";
import { SourceLender } from "../../libs/types/UniversalSurvey/Lender/SourceLender/sourceLender";
import { SimpleSourceLender } from "../../libs/types/UniversalSurvey/Lender/SourceLender/simpleSourceLender";
import { SimpleGlobalLegalFirm } from "../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { GlobalLegalFirm } from "../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { SimpleGlobalLegalFirmOffice } from "../../libs/types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { GlobalLegalFirmOffice } from "../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";

export enum AdminActionTypes {
    // ALERTS
    SET_ALERT_DATA = "SET_ALERT_DATA",
    RESET_ALERT_DATA = "RESET_ALERT_DATA",

    // SECTIONS
    SET_CURR_SECTION = "SET_CURR_SECTION",
    SET_PAGE_URL = "SET_PAGE_URL",

    // LOADING
    SET_OBJECT_LOADING = "SET_OBJECT_LOADING",

    // REGIONS
    SET_REGIONS = "SET_REGIONS",

    // LENDERS
    SET_TABLE_LENDERS = "SET_TABLE_LENDERS",
    SET_ALL_LENDERS = "SET_ALL_LENDERS",
    UPDATE_SOURCE_LENDERS_IN_TABLE = "UPDATE_SOURCE_LENDERS_IN_TABLE",
    SET_SELECTED_LENDER_BRANCH = "SET_SELECTED_LENDER_BRANCH",
    SET_SELECTED_LENDER_SOURCE = "SET_SELECTED_LENDER_SOURCE",
    SET_EDITING_LENDER = "SET_EDITING_LENDER",
    SET_LENDER_SHOULD_REFRESH = "SET_REFRESH_AFTER_DELETE_LENDER",
    UPDATE_LENDER_IN_TABLE = "UPDATE_LENDER_IN_TABLE",
    SET_LENDER_RESULT_COUNT = "SET_LENDER_RESULT_COUNT",
    SET_LENDER_MODAL_ACTIVE = "SET_LENDER_MODAL_ACTIVE",

    // LENDER CONTACTS
    SET_LENDER_CONTACTS = "SET_LENDER_CONTACTS",
    SET_LENDER_CONTACT_RESULT_COUNT = "SET_LENDER_CONTACT_RESULT_COUNT",
    SET_LENDER_CONTACT_SHOULD_REFRESH = "SET_LENDER_CONTACT_SHOULD_REFRESH",
    SET_LENDER_CONTACT_BEING_EDITED = "SET_LENDER_CONTACT_BEING_EDITED",

    // LEGAL FIRMS
    SET_TABLE_LEGAL_FIRMS = "SET_TABLE_LEGAL_FIRMS",
    SET_LEGAL_FIRMS_RESULT_COUNT = "SET_LEGAL_FIRMS_RESULT_COUNT",
    SET_EDITING_LEGAL_FIRM = "SET_EDITING_LEGAL_FIRM",
    SET_SELECTED_LEGAL_FIRM = "SET_SELECTED_LEGAL_FIRM",
    SET_LEGAL_FIRM_SHOULD_REFRESH = "SET_LEGAL_FIRM_SHOULD_REFRESH",
    UPDATE_LEGAL_FIRM_IN_TABLE = "UPDATE_LEGAL_FIRM_IN_TABLE",

    // LEGAL FIRM OFFICES
    SET_LEGAL_FIRM_OFFICES = "SET_LEGAL_FIRM_OFFICES",
    SET_LEGAL_FIRM_OFFICE_RESULT_COUNT = "SET_LEGAL_FIRM_OFFICE_RESULT_COUNT",
    SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH = "SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH",
    SET_LEGAL_FIRM_OFFICE_BEING_EDITED = "SET_LEGAL_FIRM_OFFICE_BEING_EDITED",
}

export type AdminPayloadTypes = {
    // ALERTS
    [AdminActionTypes.SET_ALERT_DATA]: {
        message: string | null;
        duration?: number;
        type: AlertTypes;
        primaryText?: string;
        primaryAction?: (() => void);
        secondaryText?: string | undefined;
        secondaryAction?: (() => void);
    },
    [AdminActionTypes.RESET_ALERT_DATA]: undefined,

    // SECTIONS
    [AdminActionTypes.SET_CURR_SECTION]: AdminSections,
    [AdminActionTypes.SET_PAGE_URL]: string,

    // LOADING
    [AdminActionTypes.SET_OBJECT_LOADING]: { obj: AdminLoading, isLoading: boolean },

    // REGIONS
    [AdminActionTypes.SET_REGIONS]: RegionListItem[],

    // LENDERS
    [AdminActionTypes.SET_TABLE_LENDERS]: SimpleLenderBranch[],
    [AdminActionTypes.SET_ALL_LENDERS]: SimpleSourceLender[],
    [AdminActionTypes.SET_SELECTED_LENDER_BRANCH]: LenderBranch | undefined,
    [AdminActionTypes.SET_SELECTED_LENDER_SOURCE]: SourceLender | undefined,
    [AdminActionTypes.UPDATE_SOURCE_LENDERS_IN_TABLE]: SimpleSourceLender,
    [AdminActionTypes.SET_EDITING_LENDER]: boolean,
    [AdminActionTypes.SET_LENDER_SHOULD_REFRESH]: boolean,
    [AdminActionTypes.UPDATE_LENDER_IN_TABLE]: { id: number, newData: SimpleLenderBranch }
    [AdminActionTypes.SET_LENDER_RESULT_COUNT]: number,
    [AdminActionTypes.SET_LENDER_MODAL_ACTIVE]: boolean,

    // LENDER CONTACTS
    [AdminActionTypes.SET_LENDER_CONTACTS]: GlobalSimpleLenderContact[],
    [AdminActionTypes.SET_LENDER_CONTACT_RESULT_COUNT]: number,
    [AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH]: boolean,
    [AdminActionTypes.SET_LENDER_CONTACT_BEING_EDITED]: GlobalLenderContact | undefined,

    // LEGAL FIRMS
    [AdminActionTypes.SET_TABLE_LEGAL_FIRMS]: SimpleGlobalLegalFirm[],
    [AdminActionTypes.SET_LEGAL_FIRMS_RESULT_COUNT]: number,
    [AdminActionTypes.SET_EDITING_LEGAL_FIRM]: boolean,
    [AdminActionTypes.SET_SELECTED_LEGAL_FIRM]: GlobalLegalFirm | undefined,
    [AdminActionTypes.SET_LEGAL_FIRM_SHOULD_REFRESH]: boolean,
    [AdminActionTypes.UPDATE_LEGAL_FIRM_IN_TABLE]: SimpleGlobalLegalFirm,

    // LEGAL FIRM OFFICES
    [AdminActionTypes.SET_LEGAL_FIRM_OFFICES]: SimpleGlobalLegalFirmOffice[],
    [AdminActionTypes.SET_LEGAL_FIRM_OFFICE_RESULT_COUNT]: number,
    [AdminActionTypes.SET_LEGAL_FIRM_OFFICE_SHOULD_REFRESH]: boolean,
    [AdminActionTypes.SET_LEGAL_FIRM_OFFICE_BEING_EDITED]: GlobalLegalFirmOffice | undefined,
}
