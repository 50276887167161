enum UserRole {
    Admin = "Admin",
    SystemAdmin = "System Admin",
    Client = "Client",
    SecondaryClient = "Secondary Client",
    Realtor = "Realtor",
    RealEstateMortgageTeam = "Real Estate/Mortgage Team",
    Broker = "Broker",
    Lender = "Lender",
    CompanyAdmin = "Company Admin",
    Lawyer = "Lawyer",
    LawClerk = "Law Clerk",
    MobileSigner = "Mobile Signer",
    ClosingAdvisor = "Closing Advisor",
    Access = "Access",
    FileOpener = "File Opener",
    DocumentSupportSpecialist = "Document Support Specialist",
    Borrower = "Borrower",
    Deleted = "Deleted",
    ReferringProfessional = "Referring Professional"
}

export default UserRole;