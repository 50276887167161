import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import { formatChargeHolderCompanyType } from "../../../../../libs/utils/formatValues";
import Link from "../../../../../../components/Common/Links/Link";
import { defaultChargeHolderCompany } from "../../../../../libs/resources/defaults/frontend/defaultChargeHolderCompany";
import { ChargeHolderCompany } from "../../../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { addressToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyChargeHolderCompanyModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [companyObj, setCompanyObj] = useState<ChargeHolderCompany>({ ...defaultChargeHolderCompany });

    useEffect(() => {
        if(state.chargeHolderCompanies.editingCompanyRecord && state.chargeHolderCompanies.companyInfo) {
            setCompanyObj(state.chargeHolderCompanies.companyInfo);
        }
    }, [state.chargeHolderCompanies.editingCompanyRecord]);

    function discard() {
        dispatch({ type: actions.SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD, payload: false });
        setCompanyObj({ ...defaultChargeHolderCompany });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={companyObj.name}
            subtitle="COMPANY"
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Abbreviated Name:
                        </Small>
                        <Large>
                            {companyObj.abbr_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Company Type:
                        </Small>
                        <Large>
                            {formatChargeHolderCompanyType(companyObj.type)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Contact Name:
                        </Small>
                        <Large>
                            {companyObj.general_contact_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Contact Email:
                        </Small>
                        <Link
                            href={companyObj.general_email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Website:
                        </Small>
                        <Link
                            href={companyObj.website}
                            type="url"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            General Address:
                        </Small>
                        <Large style={{ whiteSpace: "pre-line" }}>
                            {addressToString(companyObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address for Service:
                        </Small>
                        <Large style={{ whiteSpace: "pre-line" }}>
                            {addressToString(companyObj.address_for_service)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={companyObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>
                            {companyObj.charge_holder_company?.notes}
                        </Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}