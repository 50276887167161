import { PropertyRequest } from "../../../types/UniversalSurvey/Property/propertyRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultPropertyRequest: PropertyRequest = {
    address: defaultAddressRequest,
    jurisdiction: null,
    has_potl: null,
    interest_estate: null,
    registration_method: null,
    municipality: null,
    land_registry_office: null,
    roll_number: null,
    property_type: null,
    number_of_units: null,
    lot: null,
    plan: null,
    level: null,
    condo_corporation_id: null,
    condo_fee: null
}