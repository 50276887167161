import { useEffect, useState, useContext, useCallback, Fragment } from "react";
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { Grid, Stack, styled } from "@mui/material";
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl"
import BasicDatePicker from "../../../../components/Common/DatePicker/BasicDatePicker";
import {
    InstrumentTypeOptionsAB,
    InstrumentTypeOptionsBC,
    InstrumentTypeOptionsON,
    LenderTriggerOptionsAB,
    LenderTriggerOptionsBC,
    LenderTriggerOptionsON,
    IsToOptions,
    IsToMapping,
    IsToMappingAB,
    CorrespondenceOptions,
    DischargeRegisteredByOptions,
    DischargeRegisteredByMapping,
    CompanyTriggerOptionsON,
    AllTriggerOptionsON,
    OtherTriggerOptionsON,
    ChargeHolderTypeOptions,
    governmentContactRoleOptions,
    lenderContactRoleOptions,
    chargeHolderCompanyContactRoleOptions
} from "../../../libs/resources/options";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import LenderModal from "../Components/Modals/LenderModal/LenderModal";
import ChargeHolderCompanyModal from "../Components/Modals/ChargeHolderCompanyModal";
import {
    saveExistingLien,
    updateExistingLienTabName,
    updateLienLenderIdAndTabName,
    updateLienTabNamesOnLenderSave,
    changeSelectedExistingLien,
    updateExistingLienTabSubtitle,
    updateExistingLienTabPriority
} from "../../../context/UniversalSurvey/asyncActions/existingLiens";
import { Lien, LienOptions } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { LenderListItem } from "../../../libs/types/UniversalSurvey/Lender/lender";
import {
    createLenderRecordFromLender,
    createLenderOptionsList,
    getLenderInfo,
    updateLenderLawyer,
    updateLenderLawyerFirm,
    updateLenderLawyerOffice
} from "../../../context/UniversalSurvey/asyncActions/lenders";
import PriorityPicker from "../Components/PriorityPicker/PriorityPicker";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import { capitalizeFirstLetter, formatLienInstrumentType } from "../../../libs/utils/formatValues";
import { getLienLabel, getPrioritiesAfterClosingInDeal, getPrioritiesBeforeClosingInDeal } from "../../../context/UniversalSurvey/asyncActions/priorities";
import {
    createChargeHolderCompanyOptionsList,
    createChargeHolderCompanyRecordFromGlobal,
    getChargeHolderCompanyInfo
} from "../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import { ChargeHolderCompanyListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { LienInstrumentType, LienIsTo } from "../../../libs/resources/enums/liens";
import { ChargeHolderType } from "../../../libs/resources/enums/chargeHolderType";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { GovernmentListItem } from "../../../libs/types/UniversalSurvey/Government/government";
import {
    createGovernmentOptionsList,
    createGovernmentRecordFromGlobal,
    getGovernmentInfo
} from "../../../context/UniversalSurvey/asyncActions/governments";
import GovernmentModal from "../Components/Modals/GovernmentModal";
import {
    setLenderContactRecordBeingEdited,
    addLenderContactToExistingLien,
    removeLenderContactFromExistingLien,
    changeLenderContactInExistingLien,
    changeOrAddLenderContactToExistingLien,
    createLenderContactOptionsList,
    setLenderContactRole
} from "../../../context/UniversalSurvey/asyncActions/lenderContacts";
import * as _ from "lodash";
import { LenderContactListItem } from "../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import LenderContactModal from "../Components/Modals/LenderContactModal/LenderContactModal";
import ChargeHolderCompanyContactModal from "../Components/Modals/ChargeHolderCompanyContactModal";
import {
    addChargeHolderCompanyContact,
    removeChargeHolderCompanyContact,
    createChargeHolderCompanyContactOptionsList,
    changeChargeHolderCompanyContact,
    setChargeHolderCompanyContactRecordBeingEdited,
    setChargeHolderCompanyContactRole
} from "../../../context/UniversalSurvey/asyncActions/chargeHolderCompanyContacts";
import { ChargeHolderCompanyContactListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact";
import {
    createOtherChargeHolderOptionsList,
    getOtherChargeHolderInfo
} from "../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import { OtherChargeHolderListItem } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import OtherChargeHolderModal from "../Components/Modals/OtherChargeHolderModal";
import {
    addGovernmentContact,
    changeGovernmentContact,
    createGovernmentContactOptionsList,
    removeGovernmentContact,
    setGovernmentContactRecordBeingEdited,
    setGovernmentContactRole
} from "../../../context/UniversalSurvey/asyncActions/governmentContacts";
import GovernmentContactModal from "../Components/Modals/GovernmentContactModal";
import { GovernmentContactListItem } from "../../../libs/types/UniversalSurvey/GovernmentContacts/governmentContact";
import ReadonlyLenderModal from "../Components/Modals/ReadonlyModals/ReadonlyLenderModal";
import { LenderType } from "../../../libs/resources/enums/lenderInstitutions";
import ReadonlyGeneralContactModal from "../Components/Modals/ReadonlyModals/ReadonlyGeneralContactModal";
import ReadonlyGovernmentModal from "../Components/Modals/ReadonlyModals/ReadonlyGovernmentModal";
import ReadonlyChargeHolderCompanyModal from "../Components/Modals/ReadonlyModals/ReadonlyChargeHolderCompanyModal";
import ReadonlyOtherChargeHolderModal from "../Components/Modals/ReadonlyModals/ReadonlyOtherChargeHolderModal";
import { LinkIcon } from "../../../../components/Common/Icons/Iconography";
import colors from "../../../../components/Common/componentStyling/Colors";
import { Body, H4, LargeBold } from "../../../../components/Common/Typography";
import DisplayPills from "../../../../components/Common/StatusPills/DisplayPills";
import { UndertakingStatus } from "../../../libs/resources/enums/undertakings";
import LinkConditionModal from "../Components/Modals/LinkConditionModal";
import Theme from "../../../../components/Common/componentStyling/Theme";
import ConditionModal from "../Components/Modals/ConditionModal/ConditionModal";
import UndertakingModal from "../Components/Modals/UndertakingModal";
import { getConditionBeingEdited } from "../../../context/UniversalSurvey/asyncActions/conditions";
import { getUndertakingBeingEdited } from "../../../context/UniversalSurvey/asyncActions/undertakings";
import { PriorityAfterClosingItem } from "../../../libs/types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosing";
import { defaultPriorityAfterClosingItem } from "../../../libs/resources/defaults/frontend/defaultPriorityAfterClosing";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import { createLegalProfessionalOptionsList, setLegalProfessionalRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalProfessionals";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { getLegalProfessionalFirmOptions, setLegalFirmRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalFirms";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { getLegalProfessionalFirmOfficeOptions, setLegalFirmOfficeRecordBeingEdited } from "../../../context/UniversalSurvey/asyncActions/legalFirmOffices";
import LegalProfessionalModal from "../Components/Modals/LegalProfessionalModal";
import LegalFirmModal from "../Components/Modals/LegalFirmModal/LegalFirmModal";
import LegalFirmOfficeModal from "../Components/Modals/LegalFirmOfficeModal/LegalFirmOfficeModal";
import ReadonlyLegalProfessionalModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalProfessionalModal";
import ReadonlyLegalFirmModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmModal";
import ReadonlyLegalFirmOfficeModal from "../Components/Modals/ReadonlyModals/ReadonlyLegalFirmOfficeModal";
import { LegalProfessionalType } from "../../../libs/resources/enums/legalProfessionalType";
import { LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import LienPayoutCalcModal from "../Components/Modals/LienPayoutCalcModal";
import { getDayDiff } from "../../../libs/utils/dateHelper";
import { DEFAULT_LENDER_SOURCE_RESULT_LIMIT } from "../../../libs/utils/limits";

export default function ExistingLienSection() {

    const [state, dispatch] = useContext(UniversalSurveyContext);

    const [lenderModalActive, setLenderModalActive] = useState<boolean>(false);
    const [viewLenderModalActive, setViewLenderModalActive] = useState<boolean>(false);
    const [lenderContactModalActive, setLenderContactModalActive] = useState<boolean>(false);
    const [viewContactModal, setViewContactModal] = useState<"lender" | "government" | "company" | undefined>(undefined);
    const [companyModalActive, setCompanyModalActive] = useState<boolean>(false);
    const [viewCompanyModalActive, setViewCompanyModalActive] =useState<boolean>(false);
    const [governmentModalActive, setGovernmentModalActive] = useState<boolean>(false);
    const [viewGovernmentModalActive, setViewGovernmentModalActive] = useState<boolean>(false);
    const [companyContactModalActive, setCompanyContactModalActive] = useState<boolean>(false);
    const [governmentContactModalActive, setGovernmentContactModalActive] = useState<boolean>(false);
    const [otherChargeHolderModalActive, setOtherChargeHolderModalActive] = useState<boolean>(false);
    const [viewOtherChargeHolderModalActive, setViewOtherChargeHolderModalActive] = useState<boolean>(false);
    const [linkConditionModalActive, setLinkConditionModalActive] = useState<boolean>(false);
    const [conditionModalActive, setConditionModalActive] = useState<boolean>(false);
    const [undertakingModalActive, setUndertakingModalActive] = useState<boolean>(false);
    const [payoutCalcModalActive, setPayoutCalcModalActive] = useState<boolean>(false);

    const [lienObj, setLienObj] = useState<Lien>(state.existingLiens.existingLienInfo!);
    const [instrumentType, setInstrumentType] = useState<"shared" | "non-shared">("non-shared");
    const [chargeHolderTypeOptions, setChargeHolderTypeOptions] = useState<string[]>(ChargeHolderTypeOptions);
    const [lienOptions, setLienOptions] = useState<LienOptions>({});
    
    const [legalProfessionalModalActive, setLegalProfessionalModalActive] = useState<boolean>(false);
    const [legalFirmModalActive, setLegalFirmModalActive] = useState<boolean>(false);
    const [legalOfficeModalActive, setLegalOfficeModalActive] = useState<boolean>(false);
    const [readonlyProfessionalModalActive, setReadonlyProfessionalModalActive] = useState<boolean>(false);
    const [readonlyFirmModalActive, setReadonlyFirmModalActive] = useState<boolean>(false);
    const [readonlyOfficeModalActive, setReadonlyOfficeModalActive] = useState<boolean>(false);

    // Handling searching global entities
    const [lenderSearchValue, setLenderSearchValue] = useState<string | undefined>(undefined);
    
    // Handling searching lenders
    function makeLenderQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        query.set("search", searchVal);
        query.set("limit", DEFAULT_LENDER_SOURCE_RESULT_LIMIT);
        return query;
    }

    useEffect(() => {
        debouncedLenderSearch(lenderSearchValue);
    }, [lenderSearchValue]);

    const debouncedLenderSearch = useCallback(
        debounce((lenderSearchValue) => {
            createLenderOptionsList(
                dispatch,
                String(state.deal.dealInfo?.id),
                lienObj.lender_id ?? undefined,
                makeLenderQuery(lenderSearchValue)
            );
        }, 500), []);

    useEffect(() => {
        return () => {
            dispatch({ type: actions.SET_LIEN_INFO, payload: undefined });
            dispatch({ type: actions.SET_NEW_PRIORITY_AFTER_CLOSING, payload: undefined });
        }
    }, [])

    useEffect(() => {
        setLienObj(state.existingLiens.existingLienInfo!);
        getPrioritiesAfterClosingInDeal(dispatch, String(state.deal.dealInfo?.id));
        getPrioritiesBeforeClosingInDeal(dispatch, String(state.deal.dealInfo?.id));

        if (state.existingLiens.existingLienInfo?.charge_holder_company_record?.id) {
            getChargeHolderCompanyInfo(dispatch, String(state.deal.dealInfo?.id), state.existingLiens.existingLienInfo.charge_holder_company_record.id);
        }

        if (state.existingLiens.existingLienInfo?.government_record?.id) {
            getGovernmentInfo(dispatch, String(state.deal.dealInfo?.id), state.existingLiens.existingLienInfo.government_record.id);
        }

        if (state.existingLiens.existingLienInfo?.other_charge_holder_record?.id) {
            getOtherChargeHolderInfo(dispatch, String(state.deal.dealInfo?.id), state.existingLiens.existingLienInfo.other_charge_holder_record.id);
        }

        if (state.existingLiens.existingLienInfo?.id && state.existingLiens.liensBeingSaved.includes(state.existingLiens.existingLienInfo.id)) {
            dispatch({ type: actions.SET_IS_LIEN_SAVING, payload: true });
        }

        if (state.existingLiens.existingLienInfo!.lender_contact_list) {
            dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: state.existingLiens.existingLienInfo!.lender_contact_list.map((contact) => ({ id: contact.id, label: contact.name, isRecord: true, role: contact.role })) });
        } else {
            dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: [] });
        }

        if (state.existingLiens.existingLienInfo!.charge_holder_company_contact_list) {
            dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST, payload: state.existingLiens.existingLienInfo!.charge_holder_company_contact_list.map((contact) => ({ id: contact.id, label: contact.name, isRecord: true, role: contact.role })) });
        } else {
            dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST, payload: [] });
        }

        if (state.existingLiens.existingLienInfo!.government_contact_list) {
            dispatch({ type: actions.SET_GOVERNMENT_CONTACTS_LIST, payload: state.existingLiens.existingLienInfo!.government_contact_list.map((contact) => ({ id: contact.id, label: contact.name, isRecord: true, role: contact.role })) });
        } else {
            dispatch({ type: actions.SET_GOVERNMENT_CONTACTS_LIST, payload: [] });
        }
    }, [state.existingLiens.existingLienInfo?.id])

    useEffect(() => {
        if (state.existingLiens.shouldRefreshLien) {
            changeSelectedExistingLien(dispatch, String(state.deal.dealInfo?.id), state.existingLiens.existingLienInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_LIEN, payload: false });
        }
    }, [state.existingLiens.shouldRefreshLien])

    useEffect(() => {
        setLienObj({ ...lienObj, priority_after_closing: state.existingLiens.existingLienInfo?.priority_after_closing });
    }, [state.existingLiens.existingLienInfo?.priority_after_closing]);

    useEffect(() => {
        setLienObj({ ...lienObj, priority_before_closing: state.existingLiens.existingLienInfo?.priority_before_closing });
    }, [state.existingLiens.existingLienInfo?.priority_before_closing]);

    useEffect(() => {
        setLienObj({ ...lienObj, condition_record: state.existingLiens.existingLienInfo?.condition_record });
    }, [state.existingLiens.existingLienInfo?.condition_record]);

    useEffect(() => {
        setLienObj({ ...lienObj, payout_calc_additional_amount: state.existingLiens.existingLienInfo?.payout_calc_additional_amount ?? [] });
    }, [state.existingLiens.existingLienInfo?.payout_calc_additional_amount]);

    useEffect(() => {
        if (!lienObj.priority_after_closing && state.priorities.priorityAfterClosingList) {
            setLienObj({ ...lienObj, priority_after_closing: state.priorities.priorityAfterClosingList.length + 1 })
        }
    }, [lienObj.priority_after_closing, state.priorities.priorityAfterClosingList]);

    useEffect(() => {
        if(state.lenders.lenderInfo) {
            createLenderContactOptionsList(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo);
        }
    }, [state.lenders.lenderInfo])

    useEffect(() => {
        if(state.chargeHolderCompanies.companyInfo) {
            createChargeHolderCompanyContactOptionsList(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.companyInfo);
        }
    }, [state.chargeHolderCompanies.companyInfo])

    useEffect(() => {
        if(state.governments.governmentInfo) {
            createGovernmentContactOptionsList(dispatch, String(state.deal.dealInfo?.id), state.governments.governmentInfo);
        }
    }, [state.governments.governmentInfo])

    useEffect(() => {
        createLenderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.existingLiens.existingLienInfo!.lender_record?.id,
            undefined
        );
        createChargeHolderCompanyOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.existingLiens.existingLienInfo!.charge_holder_company_record?.id
        );
        createGovernmentOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.existingLiens.existingLienInfo!.government_record?.id
        );
        createOtherChargeHolderOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.existingLiens.existingLienInfo!.other_charge_holder_record?.id
        );
    }, [state.dataSheet.currEntity])

    useEffect(() => {
        updateLienInfo("lender_id", state.lenders.selectedLender?.id);
    }, [state.lenders.selectedLender])
    useEffect(() => {
        updateLienInfo("charge_holder_company_id", state.chargeHolderCompanies.selectedCompany?.id);
    }, [state.chargeHolderCompanies.selectedCompany])
    useEffect(() => {
        updateLienInfo("government_id", state.governments.selectedGovernment?.id);
    }, [state.governments.selectedGovernment])
    useEffect(() => {
        updateLienInfo("other_charge_holder_id", state.otherChargeHolders.selectedChargeHolder?.id);
    }, [state.otherChargeHolders.selectedChargeHolder])

    useEffect(() => {
        createLegalProfessionalOptionsList(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.region_id);
        if (state.lenders.lenderInfo?.lawyer) {
            const lawyerListItem: LegalProfessionalListItem = {
                ...state.lenders.lenderInfo.lawyer,
                isRecord: true,
                label: state.lenders.lenderInfo.lawyer.name
            };
            getLegalProfessionalFirmOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, LegalProfessionalOptions.LenderLawyer);

            if (state.lenders.lenderInfo.lawyer.legal_firm_record) {
                const firmListItem: LegalFirmListItem = {
                    ...state.lenders.lenderInfo.lawyer.legal_firm_record,
                    isRecord: true,
                    label: state.lenders.lenderInfo.lawyer.legal_firm_record.name
                }
                getLegalProfessionalFirmOfficeOptions(dispatch, String(state.deal.dealInfo?.id), lawyerListItem, firmListItem, LegalProfessionalOptions.LenderLawyer);
            }
        }
    }, [])

    useEffect(() => {
        getLegalProfessionalFirmOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            LegalProfessionalOptions.LenderLawyer
        )
    }, [state.lenders.lenderInfo?.lawyer?.id])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_FIRM_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.lenders.lenderInfo?.lawyer?.id, firmRecord: state.lenders.lenderInfo?.lawyer?.legal_firm_record }
        });
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id),
            LegalProfessionalOptions.LenderLawyer
        );
    }, [state.lenders.lenderInfo?.lawyer?.legal_firm_record])

    useEffect(() => {
        getLegalProfessionalFirmOfficeOptions(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id),
            state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id),
            LegalProfessionalOptions.LenderLawyer
        );
    }, [state.legalFirms.lenderLawyerFirmOptionList])

    useEffect(() => {
        dispatch({
            type: actions.UPDATE_OFFICE_IN_LEGAL_PROFESSIONAL_OPTION_LIST,
            payload: { id: state.lenders.lenderInfo?.lawyer?.id, officeRecord: state.lenders.lenderInfo?.lawyer?.legal_firm_office_record }
        });
    }, [state.lenders.lenderInfo?.lawyer?.legal_firm_office_record])

    function updateLienInfo<
        K extends keyof Lien,
        V extends Lien[K]
    >(key: K, value: V): void {
        const tempLienObj = { ...lienObj }
        tempLienObj[key] = value;
        setLienObj(tempLienObj);
    }

    const debouncedSave = useCallback(
        debounce((lien) => {
            saveExistingLien(dispatch, String(state.deal.dealInfo?.id), lien);
            dispatch({ type: actions.REMOVE_LIEN_BEING_SAVED, payload: lien.id });
        }, 1000), []);

    const prevLien = usePrevious(lienObj);
    useDidUpdateEffect(() => {
        if (prevLien?.id === lienObj.id) {
            dispatch({ type: actions.ADD_LIEN_BEING_SAVED, payload: lienObj.id });
            debouncedSave(lienObj);
        }
    }, [lienObj]);

    function updateLender(value: LenderListItem | null) {
        dispatch({ type: actions.SET_LENDER_CONTACTS_LIST, payload: [] });
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_LENDER, payload: value.id });
                getLenderInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createLenderRecordFromLender(dispatch, String(state.deal.dealInfo?.id), value, state.dataSheet.currEntity!, updateLienLenderIdAndTabName);
            }
        } else {
            dispatch({ type: actions.SET_SELECTED_LENDER, payload: undefined });
            dispatch({ type: actions.SET_LENDER_INFO, payload: undefined });
            dispatch({ type: actions.SET_LIEN_LENDER_ID, payload: null });
        }
        updateExistingLienTabName(dispatch, lienObj.id, value?.abbr_name ?? value?.name);
    }

    function updateCompany(value: ChargeHolderCompanyListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: value.id });
                getChargeHolderCompanyInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createChargeHolderCompanyRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value, state.dataSheet.currEntity!);
            }
        }
        dispatch({ type: actions.SET_LIEN_COMPANY_ID, payload: value ? value.id : null });
        dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACTS_LIST, payload: [] });
        updateExistingLienTabName(dispatch, lienObj.id, value?.abbr_name ?? value?.name);
    }

    function updateGovernment(value: GovernmentListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: value.id });
                getGovernmentInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createGovernmentRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value, state.dataSheet.currEntity!);
            }
        }
        dispatch({ type: actions.SET_LIEN_GOVERNMENT_ID, payload: value ? value.id : null });
        dispatch({ type: actions.SET_GOVERNMENT_CONTACTS_LIST, payload: [] });
        updateExistingLienTabName(dispatch, lienObj.id, value?.abbr_name ?? value?.name);
    }

    function updateOtherChargeHolder(value: OtherChargeHolderListItem | null) {
        if (value !== null) {
            dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: value.id });
            getOtherChargeHolderInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
        }
        dispatch({ type: actions.SET_LIEN_OTHER_CHARGE_HOLDER_ID, payload: value ? value.id : null });
        updateExistingLienTabName(dispatch, lienObj.id, value?.label);
    }

    function formatIsTo(value: string): string {
        if (state.deal.dealInfo?.region_id === 3) {
            return IsToMappingAB[value as keyof typeof IsToMappingAB];
        }
        return IsToMapping[value as keyof typeof IsToMapping];
    }

    useEffect(() => {
        let tempLienOptions: LienOptions = {};
        switch (state.deal.dealInfo?.region_id) {
            case 1:
                tempLienOptions.instrumentTypes = InstrumentTypeOptionsON;
                tempLienOptions.lenderTriggerOptions = LenderTriggerOptionsON;
                tempLienOptions.companyTriggerOptions = CompanyTriggerOptionsON;
                tempLienOptions.otherTriggerOptions = OtherTriggerOptionsON;
                tempLienOptions.allTriggerOptions = AllTriggerOptionsON;
                setLienOptions(tempLienOptions);
                break;
            case 2:
                tempLienOptions.instrumentTypes = InstrumentTypeOptionsBC;
                tempLienOptions.lenderTriggerOptions = LenderTriggerOptionsBC;
                setLienOptions(tempLienOptions);
                break;
            case 3:
                tempLienOptions.instrumentTypes = InstrumentTypeOptionsAB;
                tempLienOptions.lenderTriggerOptions = LenderTriggerOptionsAB;
                setLienOptions(tempLienOptions);
                break;
        }
    }, [state.deal.dealInfo?.region_id]);

    useEffect(() => {
        let instrumentType = lienObj.instrument_type;
        if (instrumentType) {
            if (lienOptions.allTriggerOptions?.includes(instrumentType)) {
                setInstrumentType("shared");
                return;
            }
            if (lienOptions.otherTriggerOptions?.includes(instrumentType) && lienOptions.companyTriggerOptions?.includes(instrumentType)) {
                setInstrumentType("shared");
                setChargeHolderTypeOptions([ChargeHolderType.Company, ChargeHolderType.Other]);
                return;
            }
            setChargeHolderTypeOptions(ChargeHolderTypeOptions);
            setInstrumentType("non-shared");
        } else {
            setInstrumentType("non-shared");
        }
    }, [lienObj]);

    function updateInstrumentType(instrument: LienInstrumentType) {
        let tempLienObj = { ...lienObj };
        tempLienObj["instrument_type"] = instrument;
        updateExistingLienTabName(dispatch, state.dataSheet.currEntity!, undefined);
        updateExistingLienTabSubtitle(dispatch, state.dataSheet.currEntity!, instrument);
        if (lienOptions.allTriggerOptions?.includes(instrument)) {
            setInstrumentType("shared");
            clearLender();
            clearCompany();
            clearGovernment();
            clearOtherChargeHolder();
            tempLienObj["charge_holder_type"] = undefined;
            setChargeHolderTypeOptions(ChargeHolderTypeOptions);
            setLienObj(tempLienObj);
            return;
        }
        if (lienOptions.otherTriggerOptions?.includes(instrument) && lienOptions.companyTriggerOptions?.includes(instrument)) {
            setInstrumentType("shared");
            setChargeHolderTypeOptions([ChargeHolderType.Company, ChargeHolderType.Other]);
            clearCompany();
            clearOtherChargeHolder();
            tempLienObj["charge_holder_type"] = undefined;
            setLienObj(tempLienObj);
            return;
        }
        if (lienOptions.otherTriggerOptions?.includes(instrument)) {
            tempLienObj["charge_holder_type"] = ChargeHolderType.Other;
            setLienObj(tempLienObj);
            return;
        }
        if (lienOptions.companyTriggerOptions?.includes(instrument)) {
            clearCompany();
            tempLienObj["charge_holder_type"] = ChargeHolderType.Company;
            setLienObj(tempLienObj);
            return;
        }
        if (lienOptions.lenderTriggerOptions?.includes(instrument)) {
            clearLender();
            tempLienObj["charge_holder_type"] = ChargeHolderType.Lender;
            setLienObj(tempLienObj);
            return;
        }
    }

    function updateIsTo(value: string | undefined) {
        updateLienInfo("is_to", value as LienIsTo);
        if (value) {
            if (value === LienIsTo.BeDischarged) {
                updateExistingLienTabPriority(dispatch, lienObj.id, undefined);
            } else {
                updateExistingLienTabPriority(dispatch, lienObj.id, state.priorities.priorityAfterClosingList.length + 1);
                const newPriority: PriorityAfterClosingItem = {
                    ...defaultPriorityAfterClosingItem,
                    record_id: lienObj.id,
                    type: "lien",
                    priority_after_closing: state.priorities.priorityAfterClosingList.length + 1,
                    label: `Encumbrance ${state.priorities.priorityAfterClosingList.filter((priority) => priority.type === "lien").length + 1}${getLienLabel(lienObj)}`
                }
                dispatch({ type: actions.SET_NEW_PRIORITY_AFTER_CLOSING, payload: newPriority });
            }
        } else {
            updateExistingLienTabPriority(dispatch, lienObj.id, undefined);
        }
    }

    function handleInstrumentTypeClear() {
        clearLender();
        clearCompany();
        clearGovernment();
        clearOtherChargeHolder();
        updateLienInfo("instrument_type", undefined);
        updateExistingLienTabSubtitle(dispatch, state.dataSheet.currEntity!, undefined);
    }

    function handleChargeHolderTypeUpdate() {
        clearLender();
        clearCompany();
        clearGovernment();
        clearOtherChargeHolder();
    }

    function clearLender() {
        updateLienInfo("lender_id", undefined);
        updateLender(null);
        dispatch({ type: actions.SET_SELECTED_LENDER, payload: undefined });
        dispatch({ type: actions.SET_LENDER_INFO, payload: undefined });
    }

    function clearCompany() {
        updateLienInfo("charge_holder_company_id", undefined);
        updateCompany(null);
        dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: undefined });
        dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_INFO, payload: undefined });
    }

    function clearGovernment() {
        updateLienInfo("government_id", undefined);
        updateGovernment(null);
        dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: undefined });
        dispatch({ type: actions.SET_GOVERNMENT_INFO, payload: undefined });
    }

    function clearOtherChargeHolder() {
        updateLienInfo("other_charge_holder_id", undefined);
        updateOtherChargeHolder(null);
        dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: undefined });
        dispatch({ type: actions.SET_OTHER_CHARGE_HOLDER_INFO, payload: undefined });
    }

    function onLenderContactEdit(id: number) {
        setLenderContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender?.id!, id)
        setLenderContactModalActive(true);
    }

    function onCompanyContactEdit(id: number) {
        setChargeHolderCompanyContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.selectedCompany?.id!, id);
        setCompanyContactModalActive(true);
    }

    function onGovernmentContactEdit(id: number) {
        setGovernmentContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.governments.selectedGovernment?.id!, id);
        setGovernmentContactModalActive(true);
    }

    function onContactView(type: "lender" | "government" | "company", id: number) {
        switch(type) {
            case "lender":
                setLenderContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender?.id!, id);
                break;
            case "government":
                setGovernmentContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.governments.selectedGovernment?.id!, id);
                break;
            case "company":
                setChargeHolderCompanyContactRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.selectedCompany?.id!, id);
                break;
        }
        setViewContactModal(type);
    }

    function calcPayoutDayDiff() {
        if (lienObj.payout_calc_start_date && lienObj.payout_calc_end_date) {
            return getDayDiff(lienObj.payout_calc_start_date, lienObj.payout_calc_end_date);
        }
        return 0;
    }

    function calcPayoutInterestTotal() {
        let total = 0;
        if (lienObj.payout_calc_amount_per_day) {
            const dayDiff = calcPayoutDayDiff();
            total += dayDiff * lienObj.payout_calc_amount_per_day;
        }
        return total;
    }

    function calcPayoutGrandTotal() {
        let total = calcPayoutInterestTotal();
        total += lienObj.payout_calc_discharge_fee ?? 0;
        total += lienObj.payout_calc_principal ?? 0;
        for (const additionalAmount of lienObj.payout_calc_additional_amount) {
            total += additionalAmount.amount ?? 0;
        }
        return total;
    }

    return (
        <div>
            <LenderModal
                open={lenderModalActive}
                onClose={() => {
                    setLenderModalActive(false);
                    dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: false });
                }}
                title={`${state.lenders.editingLenderRecord ? "" : "Add "}Lender`}
                onSubmitSave={updateLienTabNamesOnLenderSave}
                onSubmitNew={updateLienLenderIdAndTabName}
            />
            <ReadonlyLenderModal
                open={viewLenderModalActive}
                onClose={() => setViewLenderModalActive(false)}
            />
            <LenderContactModal
                open={lenderContactModalActive}
                onClose={() => setLenderContactModalActive(false)}
                changeOrAddContact={changeOrAddLenderContactToExistingLien}
                mortgageOrLienId={state.existingLiens.existingLienInfo?.id!}
            />
            <ReadonlyGeneralContactModal
                open={viewContactModal ? true : false}
                type={viewContactModal ?? "lender"}
                onClose={() => setViewContactModal(undefined)}
            />
            <ChargeHolderCompanyModal
                open={companyModalActive}
                onClose={() => setCompanyModalActive(false)}
            />
            <GovernmentModal
                open={governmentModalActive}
                onClose={() => setGovernmentModalActive(false)}
            />
            <ReadonlyGovernmentModal
                open={viewGovernmentModalActive}
                onClose={() => setViewGovernmentModalActive(false)}
            />
            <ChargeHolderCompanyContactModal
                open={companyContactModalActive}
                onClose={() => setCompanyContactModalActive(false)}
            />
            <ReadonlyChargeHolderCompanyModal
                open={viewCompanyModalActive}
                onClose={() => setViewCompanyModalActive(false)}
            />
            <GovernmentContactModal
                open={governmentContactModalActive}
                onClose={() => setGovernmentContactModalActive(false)}
            />
            <OtherChargeHolderModal
                open={otherChargeHolderModalActive}
                onClose={() => setOtherChargeHolderModalActive(false)}
            />
            <ReadonlyOtherChargeHolderModal
                open={viewOtherChargeHolderModalActive}
                onClose={() => setViewOtherChargeHolderModalActive(false)}
            />
            <LinkConditionModal
                open={linkConditionModalActive}
                onClose={() => setLinkConditionModalActive(false)}
            />
            <ConditionModal
                open={conditionModalActive}
                onClose={() => setConditionModalActive(false)}
            />
            <UndertakingModal
                open={undertakingModalActive}
                onClose={() => setUndertakingModalActive(false)}
            />
            <LegalProfessionalModal
                open={legalProfessionalModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalProfessionalModalActive(false)}
            />
            <LegalFirmModal
                open={legalFirmModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalFirmModalActive(false)}
            />
            <LegalFirmOfficeModal
                open={legalOfficeModalActive}
                type={LegalProfessionalOptions.LenderLawyer}
                onClose={() => setLegalOfficeModalActive(false)}
            />
            <ReadonlyLegalProfessionalModal
                open={readonlyProfessionalModalActive}
                onClose={() => setReadonlyProfessionalModalActive(false)}
            />
            <ReadonlyLegalFirmModal
                open={readonlyFirmModalActive}
                onClose={() => setReadonlyFirmModalActive(false)}
            />
            <ReadonlyLegalFirmOfficeModal
                open={readonlyOfficeModalActive}
                onClose={() => setReadonlyOfficeModalActive(false)}
            />
            <LienPayoutCalcModal
                open={payoutCalcModalActive}
                onClose={() => setPayoutCalcModalActive(false)}
                lienObj={lienObj}
                setLienObj={setLienObj}
                updateLienInfo={updateLienInfo}
                calcDayDiff={calcPayoutDayDiff}
                calcInterestTotal={calcPayoutInterestTotal}
                calcGrandTotal={calcPayoutGrandTotal}
            />
            {(state.existingLiens.isLienSaving || isObjectLoading(state.dataSheet.objectsLoading, [Loading.ChargeHolderCompanyList, Loading.GovernmentList, Loading.OtherChargeHolderList])) ? <CircularLoader containerHeight="70vh" /> :
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={6}>
                        <Dropdown
                            value={lienObj.instrument_type ?? undefined}
                            onChange={(e) => updateInstrumentType(e.target.value as LienInstrumentType)}
                            placeholder="Select instrument..."
                            options={lienOptions.instrumentTypes ?? []}
                            label={{ text: "Instrument Type" }}
                            isHoverActionHidden={lienObj.instrument_type ? false : true}
                            handleClear={() => handleInstrumentTypeClear()}
                            formatValue={formatLienInstrumentType}
                            data-testid="instrument-type-drop-down"
                        />
                    </Grid>
                    {(instrumentType === "shared" && lienObj.instrument_type) ? (
                        <>
                            <Grid item xs={3}>
                                <Dropdown
                                    value={lienObj.charge_holder_type}
                                    options={chargeHolderTypeOptions}
                                    onChange={(e) => {
                                        handleChargeHolderTypeUpdate();
                                        updateLienInfo("charge_holder_type", e.target.value as ChargeHolderType);
                                    }}
                                    placeholder="Select a type..."
                                    label={{ text: "Charge Holder Type" }}
                                    isHoverActionHidden={lienObj.charge_holder_type ? false : true}
                                    handleClear={() => {
                                        handleChargeHolderTypeUpdate();
                                        updateLienInfo("charge_holder_type", undefined);
                                    }}
                                    formatValue={capitalizeFirstLetter}
                                />
                            </Grid>
                            <Grid item xs={3} />
                        </>
                    ) : (
                        <Grid item xs={6} />
                    )}

                    {(lienObj.instrument_type && lienObj.charge_holder_type) && (
                        <>
                            {lienObj.charge_holder_type === ChargeHolderType.Lender && (
                                <>
                                    <Grid item xs={5}>
                                        <ComboBox
                                            label={{ text: "Lender", inputId: "lender_record" }}
                                            value={lenderSearchValue !== undefined ? lenderSearchValue : state.lenders.selectedLender ?? undefined}
                                            canEdit={(state.lenders.selectedLender && state.lenders.selectedLender.type !== LenderType.MortgageCentre) ? true : false}
                                            onEditClick={() => {
                                                setLenderModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: true });
                                            }}
                                            options={state.lenders.lenderOptionList}
                                            placeholder={"Select lender"}
                                            createEntity="lender"
                                            setCreationModalOpen={() => setLenderModalActive(true)}
                                            onChangeFn={(value) => updateLender(value as LenderListItem)}
                                            isHoverActionHidden={state.lenders.selectedLender ? false : true}
                                            handleView={() => {
                                                setViewLenderModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_LENDER_RECORD, payload: true });
                                            }}
                                            handleClear={() => updateLender(null)}
                                            searchableKeys={["name", "abbr_name", "branch_number", "institution_number", "general_address"]}
                                            setText={(value) => setLenderSearchValue(value)}
                                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderList])}
                                            onBlur={() => setLenderSearchValue(undefined)}
                                        />
                                    </Grid>

                                    {state.lenders.selectedLender ? (
                                        <>
                                            {state.lenderContacts.lenderContactsList.map((contact, i) => (
                                                <Fragment key={i}>
                                                    {i !== 0 && <Grid item xs={5}/>}
                                                    <Grid item xs={4}>
                                                        <ComboBox
                                                            label={{ text: "Lender Contact", inputId: `lender_contact-${i}` }}
                                                            createEntity="lender contact"
                                                            placeholder="Select a contact..."
                                                            setCreationModalOpen={() => {
                                                                setLenderContactModalActive(true);
                                                                dispatch({ type: actions.SET_LENDER_CONTACT_BEING_REPLACED, payload: contact });
                                                            }}
                                                            value={contact}
                                                            canEdit={contact.id ? true : false}
                                                            onEditClick={() => onLenderContactEdit(contact.id!)}
                                                            onChangeFn={(value) => changeLenderContactInExistingLien(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                state.lenders.selectedLender!.id,
                                                                contact.id!,
                                                                value as LenderContactListItem
                                                            )}
                                                            isHoverActionHidden={contact.id ? false : true}
                                                            handleView={() => onContactView("lender", contact.id!)}
                                                            options={state.lenderContacts.lenderContactOptionList.filter((listContact) => !_.find(state.lenderContacts.lenderContactsList, (option) => _.isMatch(option, listContact)) || _.isMatch(contact, listContact))}
                                                            handleClear={() => removeLenderContactFromExistingLien(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                contact.id!
                                                            )}
                                                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderContactList])}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <ComboBox
                                                            label={{ text: "Contact Role", inputId: `contact_role-${i}`}}
                                                            value={contact.role}
                                                            text={String(contact.role)}
                                                            onChangeFn={(value) => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, String(value))}
                                                            setText={(text) => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, String(text))}
                                                            options={lenderContactRoleOptions}
                                                            placeholder="Select a role..."
                                                            handleClear={() => setLenderContactRole(dispatch, String(state.deal.dealInfo?.id), state.lenders.selectedLender!.id, contact.id!, i, undefined)}
                                                            isHoverActionHidden={contact.role ? false : true}
                                                        />
                                                    </Grid>
                                                </Fragment>
                                            ))}
                                            {state.lenderContacts.emptyContactAdded && <Grid item xs={5}/>}
                                            {(state.lenderContacts.emptyContactAdded || state.lenderContacts.lenderContactsList.length === 0) && (
                                                <Grid item xs={4}>
                                                    <ComboBox
                                                        label={{ text: "Lender Contact", inputId: `empty_lender_contact` }}
                                                        createEntity="lender contact"
                                                        setCreationModalOpen={() => setLenderContactModalActive(true)}
                                                        value={undefined}
                                                        onChangeFn={(value) => addLenderContactToExistingLien(
                                                            dispatch,
                                                            String(state.deal.dealInfo?.id),
                                                            state.existingLiens.existingLienInfo?.id!,
                                                            state.lenders.selectedLender!.id,
                                                            value as LenderContactListItem,
                                                            true
                                                        )}
                                                        options={state.lenderContacts.lenderContactOptionList.filter((listContact) => !_.find(state.lenderContacts.lenderContactsList, (option) => _.isMatch(option, listContact)))}
                                                        handleClear={state.lenderContacts.lenderContactsList.length === 0 ? undefined : () => {dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: false })}}
                                                        isHoverActionHidden={false}
                                                        placeholder="Select a contact..."
                                                        isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderContactList])}
                                                    />
                                                </Grid>
                                            )}

                                            {/* Lenders lawyers */}
                                            {(state.lenders.lenderInfo?.type === LenderType.Private || state.lenders.lenderInfo?.type === LenderType.Personal) && (
                                                <>
                                                    <Grid item xs={4}>
                                                        <ComboBox
                                                            value={state.legalProfessionals.optionList.find((professional) => professional.id === state.lenders.lenderInfo?.lawyer?.id && professional.isRecord)}
                                                            options={state.legalProfessionals.optionList.filter((professional) => professional.type === LegalProfessionalType.Lawyer)}
                                                            onChangeFn={(value) => updateLenderLawyer(dispatch, value as LegalProfessionalListItem, state.deal.dealInfo!, state.lenders.lenderInfo!)}
                                                            placeholder="Select lawyer..."
                                                            label={{ text: "Lender's Lawyer", inputId: "lawyer" }}
                                                            handleClear={() => updateLenderLawyer(dispatch, null, state.deal.dealInfo!, state.lenders.lenderInfo!)}
                                                            isHoverActionHidden={state.lenders.lenderInfo?.lawyer ? false : true}
                                                            createEntity="lawyer"
                                                            setCreationModalOpen={() => setLegalProfessionalModalActive(true)}
                                                            canEdit={state.lenders.lenderInfo?.lawyer ? true : false}
                                                            onEditClick={() => {
                                                                setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                                setLegalProfessionalModalActive(true);
                                                            }}
                                                            handleView={() => {
                                                                setLegalProfessionalRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                                setReadonlyProfessionalModalActive(true);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {state.lenders.lenderInfo?.lawyer &&
                                                            <ComboBox
                                                                value={state.legalFirms.lenderLawyerFirmOptionList.find((firm) => firm.id === state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id && firm.isRecord)}
                                                                options={state.legalFirms.lenderLawyerFirmOptionList}
                                                                onChangeFn={(value) => updateLenderLawyerFirm(dispatch, value as LegalFirmListItem, state.deal.dealInfo, state.lenders.lenderInfo)}
                                                                placeholder="Select firm..."
                                                                label={{ text: "Lawyer's Firm", inputId: "lawyer-firm" }}
                                                                handleClear={() => updateLenderLawyerFirm(dispatch, null, state.deal.dealInfo, state.lenders.lenderInfo)}
                                                                isHoverActionHidden={state.lenders.lenderInfo?.lawyer.legal_firm_record ? false : true}
                                                                createEntity="firm"
                                                                setCreationModalOpen={() => setLegalFirmModalActive(true)}
                                                                canEdit={state.lenders.lenderInfo?.lawyer.legal_firm_record ? true : false}
                                                                onEditClick={() => {
                                                                    setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                                    setLegalFirmModalActive(true);
                                                                }}
                                                                handleView={() => {
                                                                    setLegalFirmRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.id!);
                                                                    setReadonlyFirmModalActive(true);
                                                                }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {state.lenders.lenderInfo?.lawyer?.legal_firm_record &&
                                                            <ComboBox
                                                                value={state.legalFirmOffices.lenderLawyerOfficeOptionList.find((office) => office.id === state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id && office.isRecord)}
                                                                options={state.legalFirmOffices.lenderLawyerOfficeOptionList}
                                                                onChangeFn={(value) => updateLenderLawyerOffice(dispatch, value as LegalFirmOfficeListItem, state.deal.dealInfo, state.lenders.lenderInfo)}
                                                                placeholder="Select office..."
                                                                label={{ text: "Lawyer's Office", inputId: "lawyer-office" }}
                                                                handleClear={() => updateLenderLawyerOffice(dispatch, null, state.deal.dealInfo, state.lenders.lenderInfo)}
                                                                isHoverActionHidden={state.lenders.lenderInfo?.lawyer.legal_firm_office_record ? false : true}
                                                                createEntity="office"
                                                                setCreationModalOpen={() => setLegalOfficeModalActive(true)}
                                                                canEdit={state.lenders.lenderInfo?.lawyer.legal_firm_office_record ? true : false}
                                                                onEditClick={() => {
                                                                    setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id!, state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id!);
                                                                    setLegalOfficeModalActive(true);
                                                                }}
                                                                handleView={() => {
                                                                    setLegalFirmOfficeRecordBeingEdited(dispatch, String(state.deal.dealInfo?.id), state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id!, state.lenders.lenderInfo?.lawyer?.legal_firm_office_record?.id!);
                                                                    setReadonlyOfficeModalActive(true);
                                                                }}
                                                            />
                                                        }
                                                    </Grid>
                                                </>
                                            )}

                                            <Grid item xs={12}>
                                                <BasicButton
                                                    label={{ text: "Add Lender Contact", inputId: "add-lender-contact" }}
                                                    action="add"
                                                    disabled={state.lenderContacts.lenderContactsList.length === 0 || state.lenderContacts.emptyContactAdded}
                                                    typeOf="secondary"
                                                    onClick={() => dispatch({ type: actions.SET_EMPTY_LENDER_CONTACT_ADDED, payload: true })}
                                                />
                                            </Grid>
                                        </>
                                    ) : (<Grid item xs={12} />)}

                                    <Grid item xs={3}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Loan No." }}
                                            value={lienObj.mortgage_loan_number}
                                            onChange={(e) => updateLienInfo("mortgage_loan_number", e.target.value)}
                                            placeholder="Mortgage loan no."
                                            label={{ text: "Loan No.", inputId: "mortgage_loan_number" }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {lienObj.charge_holder_type === ChargeHolderType.Company && (
                                <>
                                    <Grid item xs={5}>
                                        <ComboBox
                                            value={state.chargeHolderCompanies.selectedCompany}
                                            label={{ text: "Company", inputId: "company" }}
                                            options={state.chargeHolderCompanies.optionList}
                                            placeholder={"Select company"}
                                            createEntity="company"
                                            setCreationModalOpen={() => setCompanyModalActive(true)}
                                            searchableKeys={["name", "abbr_name", "general_address"]}
                                            onChangeFn={(value) => updateCompany(value as ChargeHolderCompanyListItem)}
                                            canEdit={state.chargeHolderCompanies.selectedCompany ? true : false}
                                            onEditClick={() => {
                                                setCompanyModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD, payload: true });
                                            }}
                                            handleView={() => {
                                                setViewCompanyModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD, payload: true });
                                            }}
                                            isHoverActionHidden={state.chargeHolderCompanies.selectedCompany ? false : true}
                                            handleClear={() => {
                                                updateCompany(null);
                                                dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: undefined });
                                                dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_INFO, payload: undefined });
                                            }}
                                        />
                                    </Grid>

                                    {state.chargeHolderCompanies.selectedCompany ? (
                                        <>
                                            {state.chargeHolderCompanyContacts.contactsList.map((contact, i) => (
                                                <Fragment key={i}>
                                                    {i !== 0 && <Grid item xs={5}/>}
                                                    <Grid item xs={4}>
                                                        <ComboBox
                                                            label={{ text: "Company Contact", inputId: `company_contact-${i}` }}
                                                            placeholder="Select a contact..."
                                                            createEntity="company contact"
                                                            setCreationModalOpen={() => {
                                                                setCompanyContactModalActive(true);
                                                                dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_REPLACED, payload: contact });
                                                            }}
                                                            value={contact}
                                                            canEdit={contact.id ? true : false}
                                                            onEditClick={() => onCompanyContactEdit(contact.id!)}
                                                            onChangeFn={(value) => changeChargeHolderCompanyContact(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                state.chargeHolderCompanies.selectedCompany!.id,
                                                                contact.id!,
                                                                value as ChargeHolderCompanyContactListItem
                                                            )}
                                                            handleView={() => onContactView("company", contact.id!)}
                                                            isHoverActionHidden={contact.id ? false : true}
                                                            options={state.chargeHolderCompanyContacts.contactOptionList.filter((listContact) => !_.find(state.chargeHolderCompanyContacts.contactsList, (option) => _.isMatch(option, listContact)) || _.isMatch(contact, listContact))}
                                                            handleClear={() => removeChargeHolderCompanyContact(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                contact.id!
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <ComboBox
                                                            label={{ text: "Contact Role", inputId: `contact_role-${i}`}}
                                                            value={contact.role}
                                                            text={String(contact.role)}
                                                            onChangeFn={(value) => setChargeHolderCompanyContactRole(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.selectedCompany!.id, contact.id!, i, String(value))}
                                                            setText={(text) => setChargeHolderCompanyContactRole(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.selectedCompany!.id, contact.id!, i, String(text))}
                                                            options={chargeHolderCompanyContactRoleOptions}
                                                            placeholder="Select a role..."
                                                            handleClear={() => setChargeHolderCompanyContactRole(dispatch, String(state.deal.dealInfo?.id), state.chargeHolderCompanies.selectedCompany!.id, contact.id!, i, undefined)}
                                                            isHoverActionHidden={contact.role ? false : true}
                                                        />
                                                    </Grid>
                                                </Fragment>
                                            ))}
                                            {state.chargeHolderCompanyContacts.emptyContactAdded && <Grid item xs={5}/>}
                                            {(state.chargeHolderCompanyContacts.emptyContactAdded || state.chargeHolderCompanyContacts.contactsList.length === 0) && (
                                                <Grid item xs={4}>
                                                    <ComboBox
                                                        label={{ text: "Company Contact", inputId: `empty_company_contact` }}
                                                        createEntity="company contact"
                                                        setCreationModalOpen={() => setCompanyContactModalActive(true)}
                                                        value={undefined}
                                                        onChangeFn={(value) => addChargeHolderCompanyContact(
                                                            dispatch,
                                                            String(state.deal.dealInfo?.id),
                                                            state.existingLiens.existingLienInfo?.id!,
                                                            state.chargeHolderCompanies.selectedCompany!.id,
                                                            value as ChargeHolderCompanyContactListItem,
                                                            true
                                                        )}
                                                        options={state.chargeHolderCompanyContacts.contactOptionList.filter((listContact) => !_.find(state.chargeHolderCompanyContacts.contactsList, (option) => _.isMatch(option, listContact)))}
                                                        handleClear={state.chargeHolderCompanyContacts.contactsList.length === 0 ? undefined : () => {dispatch({ type: actions.SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED, payload: false })}}
                                                        isHoverActionHidden={false}
                                                        placeholder="Select a contact..."
                                                        isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.ChargeHolderCompanyContactList])}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <BasicButton
                                                    label={{ text: "Add Company Contact", inputId: "add-company-contact" }}
                                                    action="add"
                                                    disabled={state.chargeHolderCompanyContacts.contactsList.length === 0 || state.chargeHolderCompanyContacts.emptyContactAdded}
                                                    typeOf="secondary"
                                                    onClick={() => dispatch({ type: actions.SET_EMPTY_CHARGE_HOLDER_COMPANY_CONTACT_ADDED, payload: true })}
                                                />
                                            </Grid>
                                        </>
                                    ) : (<Grid item xs={12} />)}

                                    <Grid item xs={3}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Account No." }}
                                            value={lienObj.account_number}
                                            onChange={(e) => updateLienInfo("account_number", e.target.value)}
                                            placeholder="Account no."
                                            label={{ text: "Account No.", inputId: "account_number" }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {lienObj.charge_holder_type === ChargeHolderType.Government && (
                                <>
                                    <Grid item xs={5}>
                                        <ComboBox
                                            value={state.governments.selectedGovernment}
                                            label={{ text: "Government", inputId: "government" }}
                                            options={state.governments.optionList}
                                            placeholder={"Select government"}
                                            createEntity="government"
                                            setCreationModalOpen={() => setGovernmentModalActive(true)}
                                            searchableKeys={["name", "abbr_name", "general_address"]}
                                            onChangeFn={(value) => updateGovernment(value as GovernmentListItem)}
                                            canEdit={state.governments.selectedGovernment ? true : false}
                                            onEditClick={() => {
                                                setGovernmentModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_GOVERNMENT_RECORD, payload: true });
                                            }}
                                            handleView={() => {
                                                setViewGovernmentModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_GOVERNMENT_RECORD, payload: true });
                                            }}
                                            isHoverActionHidden={state.governments.selectedGovernment ? false : true}
                                            handleClear={() => {
                                                updateGovernment(null);
                                                dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: undefined });
                                                dispatch({ type: actions.SET_GOVERNMENT_INFO, payload: undefined });
                                            }}
                                        />
                                    </Grid>

                                    {state.governments.selectedGovernment ? (
                                        <>
                                            {state.governmentContacts.contactsList.map((contact, i) => (
                                                <Fragment key={i}>
                                                    {i !== 0 && <Grid item xs={5}/>}
                                                    <Grid item xs={4}>
                                                        <ComboBox
                                                            label={{ text: "Government Contact", inputId: `government_contact-${i}` }}
                                                            createEntity="government contact"
                                                            setCreationModalOpen={() => {
                                                                setGovernmentContactModalActive(true);
                                                                dispatch({ type: actions.SET_GOVERNMENT_CONTACT_BEING_REPLACED, payload: contact });
                                                            }}
                                                            placeholder="Select a contact..."
                                                            value={contact}
                                                            canEdit={contact.id ? true : false}
                                                            onEditClick={() => onGovernmentContactEdit(contact.id!)}
                                                            onChangeFn={(value) => changeGovernmentContact(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                state.governments.selectedGovernment!.id,
                                                                contact.id!,
                                                                value as GovernmentContactListItem
                                                            )}
                                                            handleView={() => onContactView("government", contact.id!)}
                                                            isHoverActionHidden={contact.id ? false : true}
                                                            options={state.governmentContacts.contactOptionList.filter((listContact) => !_.find(state.governmentContacts.contactsList, (option) => _.isMatch(option, listContact)) || _.isMatch(contact, listContact))}
                                                            handleClear={() => removeGovernmentContact(
                                                                dispatch,
                                                                String(state.deal.dealInfo?.id),
                                                                state.existingLiens.existingLienInfo?.id!,
                                                                contact.id!
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <ComboBox
                                                            label={{ text: "Contact Role", inputId: `contact_role-${i}`}}
                                                            value={contact.role}
                                                            text={String(contact.role)}
                                                            onChangeFn={(value) => setGovernmentContactRole(dispatch, String(state.deal.dealInfo?.id), state.governments.selectedGovernment!.id, contact.id!, i, String(value))}
                                                            setText={(text) => setGovernmentContactRole(dispatch, String(state.deal.dealInfo?.id), state.governments.selectedGovernment!.id, contact.id!, i, String(text))}
                                                            options={governmentContactRoleOptions}
                                                            placeholder="Select a role..."
                                                            handleClear={() => setGovernmentContactRole(dispatch, String(state.deal.dealInfo?.id), state.governments.selectedGovernment!.id, contact.id!, i, undefined)}
                                                            isHoverActionHidden={contact.role ? false : true}
                                                        />
                                                    </Grid>
                                                </Fragment>
                                            ))}
                                            {state.governmentContacts.emptyContactAdded && <Grid item xs={5}/>}
                                            {(state.governmentContacts.emptyContactAdded || state.governmentContacts.contactsList.length === 0) && (
                                                <Grid item xs={4}>
                                                    <ComboBox
                                                        label={{ text: "Government Contact", inputId: `empty_government_contact` }}
                                                        createEntity="government contact"
                                                        setCreationModalOpen={() => setGovernmentContactModalActive(true)}
                                                        value={undefined}
                                                        onChangeFn={(value) => addGovernmentContact(
                                                            dispatch,
                                                            String(state.deal.dealInfo?.id),
                                                            state.existingLiens.existingLienInfo?.id!,
                                                            state.governments.selectedGovernment!.id,
                                                            value as GovernmentContactListItem,
                                                            true
                                                        )}
                                                        options={state.governmentContacts.contactOptionList.filter((listContact) => !_.find(state.governmentContacts.contactsList, (option) => _.isMatch(option, listContact)))}
                                                        handleClear={state.governmentContacts.contactsList.length === 0 ? undefined : () => {dispatch({ type: actions.SET_EMPTY_GOVERNMENT_CONTACT_ADDED, payload: false })}}
                                                        isHoverActionHidden={false}
                                                        placeholder="Select a contact..."
                                                        isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.GovernmentContactList])}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <BasicButton
                                                    label={{ text: "Add Government Contact", inputId: "add-government-contact" }}
                                                    action="add"
                                                    disabled={state.governmentContacts.contactsList.length === 0 || state.governmentContacts.emptyContactAdded}
                                                    typeOf="secondary"
                                                    onClick={() => dispatch({ type: actions.SET_EMPTY_GOVERNMENT_CONTACT_ADDED, payload: true })}
                                                />
                                            </Grid>
                                        </>
                                    ) : (<Grid item xs={12} />)}

                                    <Grid item xs={3}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Account No." }}
                                            value={lienObj.account_number}
                                            onChange={(e) => updateLienInfo("account_number", e.target.value)}
                                            placeholder="Account no."
                                            label={{ text: "Account No.", inputId: "account_number" }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {lienObj.charge_holder_type === ChargeHolderType.Other && (
                                <>
                                    <Grid item xs={5}>
                                        <ComboBox
                                            value={state.otherChargeHolders.selectedChargeHolder}
                                            label={{ text: "Other Charge Holder", inputId: "other_charge_holder" }}
                                            options={state.otherChargeHolders.optionList}
                                            placeholder={"Select other charge holder"}
                                            createEntity="other charge holder"
                                            setCreationModalOpen={() => setOtherChargeHolderModalActive(true)}
                                            searchableKeys={["name", "general_address"]}
                                            onChangeFn={(value) => updateOtherChargeHolder(value as OtherChargeHolderListItem)}
                                            canEdit={state.otherChargeHolders.selectedChargeHolder ? true : false}
                                            onEditClick={() => {
                                                setOtherChargeHolderModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_OTHER_CHARGE_HOLDER_RECORD, payload: true });
                                            }}
                                            handleView={() => {
                                                setViewOtherChargeHolderModalActive(true);
                                                dispatch({ type: actions.SET_EDITING_OTHER_CHARGE_HOLDER_RECORD, payload: true });
                                            }}
                                            isHoverActionHidden={state.otherChargeHolders.selectedChargeHolder ? false : true}
                                            handleClear={() => {
                                                updateOtherChargeHolder(null);
                                                dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: undefined });
                                                dispatch({ type: actions.SET_OTHER_CHARGE_HOLDER_INFO, payload: undefined });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={7} />

                                    <Grid item xs={3}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Account No." }}
                                            value={lienObj.account_number}
                                            onChange={(e) => updateLienInfo("account_number", e.target.value)}
                                            placeholder="Account no."
                                            label={{ text: "Account No.", inputId: "account_number" }}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={3}>
                                <BasicTextInput
                                    fullWidth
                                    inputProps={{ "aria-label": "Registration No." }}
                                    value={lienObj.registration_number}
                                    onChange={(e) => updateLienInfo("registration_number", e.target.value)}
                                    placeholder="Mortgage registration no."
                                    label={{ text: "Registration No.", inputId: "registration_number" }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <BasicDatePicker
                                    value={lienObj.registration_date ?? null}
                                    onChange={(e) => updateLienInfo("registration_date", e as Date)}
                                    label={{ text: "Registration Date" }}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={3} />

                            <Grid item xs={3}>
                                <BooleanControl
                                    checked={lienObj.step_heloc}
                                    onChange={(e) => updateLienInfo("step_heloc", !lienObj.step_heloc)}
                                    label={{ text: "STEP / HELOC", inputId: "step_heloc" }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <BooleanControl
                                    checked={lienObj.collateral_mortgage}
                                    onChange={(e) => updateLienInfo("collateral_mortgage", !lienObj.collateral_mortgage)}
                                    label={{ text: "Blanket Mortgage", inputId: "collateral_mortgage" }}
                                />
                            </Grid>

                            {lienObj.lender_record?.type === "private" ? (
                                <>
                                    <Grid item xs={3}>
                                        <Dropdown
                                            value={lienObj.correspondence ?? undefined}
                                            onChange={(e) => updateLienInfo("correspondence", e.target.value as string)}
                                            placeholder="Select recipient..."
                                            options={CorrespondenceOptions}
                                            formatValue={(value: string) => value[0].toLocaleUpperCase() + value.substring(1)}
                                            label={{ text: "Correspondence with..." }}
                                            isHoverActionHidden={lienObj.correspondence ? false : true}
                                            handleClear={() => updateLienInfo("correspondence", undefined)}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={3} />
                            )}
                            <Grid item xs={3} />
                            
                            <Grid item xs={3}>
                                <Dropdown
                                    value={lienObj.is_to ?? undefined}
                                    onChange={(e) => updateIsTo(e.target.value as string)}
                                    placeholder="Select action..."
                                    options={IsToOptions}
                                    formatValue={formatIsTo}
                                    label={{ text: `Is${state.deal.dealInfo?.region_id === 3 ? "" : " to..."}` }}
                                    isHoverActionHidden={lienObj.is_to ? false : true}
                                    handleClear={() => updateIsTo(undefined)}
                                    data-testid="is-to-drop-down"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <PriorityPicker
                                    value={lienObj.priority_before_closing ?? state.priorities.priorityBeforeClosingList.length}
                                    label="Priority Before Closing"
                                    type="before"
                                />
                            </Grid>
                            {lienObj.is_to === LienIsTo.Remain && (
                                <Grid item xs={2}>
                                    <PriorityPicker
                                        value={lienObj.priority_after_closing ?? state.priorities.priorityAfterClosingList.length}
                                        label="Priority After Closing"
                                        type="after"
                                    />
                                </Grid>
                            )}
                            {lienObj.is_to === LienIsTo.Remain && (
                                <Fragment>
                                    <Grid item xs={2}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Principal Balance" }}
                                            value={lienObj.principal_balance !== undefined ? String(lienObj.principal_balance) : undefined}
                                            // @ts-ignore
                                            onChange={(e) => updateLienInfo("principal_balance", e.target.value)}
                                            moneyField
                                            valueType="positive"
                                            placeholder="0.00"
                                            label={{ text: "Approximate Principal Balance", inputId: "principal_balance" }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <BooleanControl
                                            checked={lienObj.obtain_info_statement}
                                            onChange={(e) => updateLienInfo("obtain_info_statement", !lienObj.obtain_info_statement)}
                                            label={{ text: "Obtaining Information Statement", inputId: "obtain_info_statement" }}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                            {lienObj.is_to === LienIsTo.BeDischarged && (
                                <Fragment>
                                    <Grid item xs={3}>
                                        <BasicTextInput
                                            fullWidth
                                            inputProps={{ "aria-label": "Payable to Discharge" }}
                                            value={calcPayoutGrandTotal()}
                                            moneyField
                                            calculateField
                                            placeholder="0.00"
                                            label={{ text: "Amount Payable to Discharge", inputId: "payable_to_discharge" }}
                                            readOnly
                                            onIconClick={() => setPayoutCalcModalActive(true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Dropdown
                                            value={lienObj.discharge_registered_by ?? undefined}
                                            onChange={(e) => updateLienInfo("discharge_registered_by", e.target.value as string)}
                                            placeholder="Select registeree..."
                                            options={DischargeRegisteredByOptions}
                                            formatValue={(value: string) => DischargeRegisteredByMapping[value as keyof typeof DischargeRegisteredByMapping]}
                                            label={{ text: "Discharge to be Registered by" }}
                                            isHoverActionHidden={lienObj.discharge_registered_by ? false : true}
                                            handleClear={() => updateLienInfo("discharge_registered_by", undefined)}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                            {lienObj.is_to === LienIsTo.BeDischarged && (
                                lienObj.condition_record ? (
                                    <>
                                        <Grid item xs={12}>
                                            <H4>
                                                Linked Entities
                                            </H4>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <HoverDiv onClick={() => {
                                                getConditionBeingEdited(dispatch, String(state.deal.dealInfo?.id), lienObj.condition_record!.id);
                                                setConditionModalActive(true);
                                            }}>
                                                <Grid container rowSpacing={3} columnSpacing={5} alignContent={"center"}>
                                                    <Grid item xs={1.5}>
                                                        <LargeBold>
                                                            Condition
                                                        </LargeBold>
                                                    </Grid>
                                                    <Grid item xs={9.5}>
                                                        <Body>
                                                            {lienObj.condition_record.condition}
                                                        </Body>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Stack direction="row" justifyContent="flex-end">
                                                            <DisplayPills
                                                                label={lienObj.condition_record.condition_met ? "Met" : "Unmet"}
                                                                status={{ errors: 0, missingFields: lienObj.condition_record.condition_met ? 0 : 1, isEmpty: false }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </HoverDiv>
                                        </Grid>
                                        {lienObj.condition_record.undertaking && (
                                            <Grid item xs={12}>
                                                <HoverDiv onClick={() => {
                                                    getUndertakingBeingEdited(dispatch, String(state.deal.dealInfo?.id), lienObj.condition_record?.undertaking?.id!)
                                                    setUndertakingModalActive(true);
                                                }}>
                                                    <Grid container rowSpacing={3} columnSpacing={5} alignContent={"center"}>
                                                        <Grid item xs={1.5}>
                                                            <LargeBold>
                                                                Undertaking
                                                            </LargeBold>
                                                        </Grid>
                                                        <Grid item xs={9.5}>
                                                            <Body>
                                                                {lienObj.condition_record.undertaking.direction}
                                                            </Body>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Stack direction="row" justifyContent="flex-end">
                                                                <DisplayPills
                                                                    label={lienObj.condition_record.undertaking.status === UndertakingStatus.Fulfilled ? "Fulfilled" : "Outstanding"}
                                                                    status={{ errors: 0, missingFields: lienObj.condition_record.undertaking.status === UndertakingStatus.Fulfilled ? 0 : 1, isEmpty: false }}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </HoverDiv>
                                            </Grid>
                                        )}
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        <BasicButton
                                            label={{ text: "Link Condition", inputId: "link-condition" }}
                                            typeOf="secondary"
                                            rightIcon={<LinkIcon color={colors.BLACK}/>}
                                            onClick={() => setLinkConditionModalActive(true)}
                                        />
                                    </Grid>
                                )
                            )}
                        </>
                    )}
                </Grid>
            }
        </div>
    )
};

const HoverDiv = styled("div")({
    marginTop: "-1.5rem",
    padding: "1.5rem 1rem 1rem 1rem",
    '&:hover': {
        backgroundColor: Theme.HOVER,
        cursor: "pointer"
    }
})
