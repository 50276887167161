import { TabOptions } from "../../types/UniversalSurvey/Frontend/tabOption";

export function addSectionTabsPrefix(tabOptions: TabOptions, prefix: string): TabOptions {
    let tempTabOptions: TabOptions = [];
    let count = 1;
    for (const tab of tabOptions) {
        let tempTab = {...tab};
        tempTab.title = `${prefix} ${count}` + (tab.title?.length > 0 ? ` - ${tab.title}` : "");
        tempTabOptions.push(tempTab);
        count += 1;
    }
    return tempTabOptions;
}