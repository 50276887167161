import { DebtRequest } from "../../../types/UniversalSurvey/Debts/debtRequest";

export const defaultDebtRequest: DebtRequest = {
    charge_holder_type: null,
    amount_payable: null,
    action: null,
    debt_type: null,
    valid_statement: null,
    account_number: null,
    notes: null,
    debt_holder_id: null
}