import { useContext, useState, useEffect, useCallback } from "react";
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl"
import BasicDatePicker from "../../../../components/Common/DatePicker/BasicDatePicker";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { FireInsurance } from "../../../libs/types/UniversalSurvey/FireInsurance/fireInsurance";
import { changeSelectedFireInsurance, saveFireInsurance, updateFireInsuranceTabName } from "../../../context/UniversalSurvey/asyncActions/fireInsurance";
import { FireInsuranceNumberType } from "../../../libs/resources/enums/fireInsurance";
import { setAllDealProperties, getPropertyTabName } from "../../../context/UniversalSurvey/asyncActions/properties";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import { FireInsuranceBrokerageListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import {
    createFireInsuranceBrokerageRecordFromGlobal,
    createFireInsuranceBrokerageOptionList,
    getFireInsuranceBrokerageInfo
} from "../../../context/UniversalSurvey/asyncActions/fireInsuranceBrokerages";
import { defaultSimpleFireInsuranceBrokerage } from "../../../libs/resources/defaults/frontend/defaultSimpleFireInsuranceBrokerage";
import FireInsuranceBrokerageModal from "../Components/Modals/FireInsuranceBrokerageModal";
import FireInsuranceCompanyModal from "../Components/Modals/FireInsuranceCompanyModal";
import { FireInsuranceCompanyListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { createFireInsuranceCompanyOptionsList, createFireInsuranceCompanyRecordFromGlobal, getFireInsuranceCompanyInfo } from "../../../context/UniversalSurvey/asyncActions/fireInsuranceCompanies";
import { defaultSimpleFireInsuranceCompany } from "../../../libs/resources/defaults/frontend/defaultSimpleFireInsuranceCompany";
import { createFireInsuranceBrokerOptionsList, createRecordFromFireInsuranceBroker, getFireInsuranceBrokerRecordInfo } from "../../../context/UniversalSurvey/asyncActions/fireInsuranceBrokers";
import { FireInsuranceBrokerListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import FireInsuranceBrokerModal from "../Components/Modals/FireInsuranceBrokerModal";
import ReadonlyGeneralFireInsuranceModal from "../Components/Modals/ReadonlyModals/ReadonlyGeneralFireInsuranceModal";
import ReadonlyGeneralBrokerModal from "../Components/Modals/ReadonlyModals/ReadonlyGeneralBrokerModal";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";


export default function FireInsuranceSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [fireInsuranceObj, setFireInsuranceObj] = useState<FireInsurance>(state.fireInsurance.fireInsuranceInfo!);
    const [fireInsuranceCompanyModalActive, setFireInsuranceCompanyModalActive] = useState<boolean>(false);
    const [brokerageModalActive, setBrokerageModalActive] = useState<boolean>(false);
    const [viewFireInsuranceGeneralModal, setViewFireInsuranceGeneralModal] = useState<"company" | "brokerage" | undefined>(undefined);
    const [viewBrokerModalActive, setViewBrokerModalActive] = useState<boolean>(false);
    const [brokerModalActive, setBrokerModalActive] = useState<boolean>(false);

    useEffect(() => {
        setAllDealProperties(dispatch, String(state.deal.dealInfo?.id));

        return () => dispatch({ type: actions.SET_FIRE_INSURANCE_INFO, payload: undefined });
    }, [])

    useEffect(() => {
        createFireInsuranceCompanyOptionsList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.deal.dealInfo?.region_id,
            state.fireInsurance.fireInsuranceInfo?.fire_insurance_company_record?.id
        );
        createFireInsuranceBrokerageOptionList(
            dispatch,
            String(state.deal.dealInfo?.id),
            state.deal.dealInfo?.region_id,
            state.fireInsurance.fireInsuranceInfo?.fire_insurance_brokerage_record?.id
        );
        dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: undefined });
    }, [state.dataSheet.currEntity])

    useEffect(() => {
        if(
            (state.fireInsuranceBrokerages.brokerageInfo && state.fireInsuranceBrokerages.selectedBrokerage) && 
            (state.fireInsuranceBrokerages.brokerageInfo.id === state.fireInsuranceBrokerages.selectedBrokerage.id)
        ) {
            createFireInsuranceBrokerOptionsList(dispatch, String(state.deal.dealInfo?.id), state.fireInsuranceBrokerages.brokerageInfo, state.fireInsurance.fireInsuranceInfo?.fire_insurance_broker_record?.id);
        }
        updateFireInsuranceInfo("fire_insurance_brokerage_id", state.fireInsuranceBrokerages.selectedBrokerage?.id);
    }, [state.fireInsuranceBrokerages.selectedBrokerage, state.fireInsuranceBrokerages.brokerageInfo])

    useEffect(() => {
        if (state.fireInsurance.fireInsuranceInfo) {
            setFireInsuranceObj(state.fireInsurance.fireInsuranceInfo);
            if (state.fireInsurance.fireInsurancesBeingSaved.includes(state.fireInsurance.fireInsuranceInfo.id)) {
                dispatch({ type: actions.SET_IS_FIRE_INSURANCE_SAVING, payload: true });
            }
        }
    }, [state.fireInsurance.fireInsuranceInfo?.id])

    useEffect(() => {
        if (state.fireInsurance.shouldRefreshFireInsurance) {
            changeSelectedFireInsurance(dispatch, String(state.deal.dealInfo?.id), state.fireInsurance.fireInsuranceInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_FIRE_INSURANCE, payload: false });
        }
    }, [state.fireInsurance.shouldRefreshFireInsurance])

    function updateFireInsuranceInfo<
        K extends keyof FireInsurance,
        V extends FireInsurance[K]
    >(key: K, value: V): void {
        const tempFireInsuranceObj = { ...fireInsuranceObj }
        if (key === "property_id") {
            dispatch({ type: actions.CHANGE_PROPERTY_WITH_FIRE_INSURANCE, payload: { oldId: tempFireInsuranceObj.property_id, newId: value } })
            tempFireInsuranceObj.property_record = state.properties.propertiesInDeal.find((property) => property.id === value);
        }
        if (key === "fire_insurance_company_id") {
            if (value === undefined) {
                tempFireInsuranceObj.fire_insurance_company_record = undefined
            } else {
                let recordObj = tempFireInsuranceObj.fire_insurance_company_record ? tempFireInsuranceObj.fire_insurance_company_record : defaultSimpleFireInsuranceCompany;
                tempFireInsuranceObj.fire_insurance_company_record = {
                    ...recordObj,
                    abbr_name: state.fireInsuranceCompanies.selectedCompany?.abbr_name,
                    id: state.fireInsuranceCompanies.selectedCompany?.id!
                }
            }
        }
        if (key === "fire_insurance_brokerage_id") {
            if (value === undefined) {
                tempFireInsuranceObj.fire_insurance_brokerage_record = undefined
            } else {
                let recordObj = tempFireInsuranceObj.fire_insurance_brokerage_record ?? defaultSimpleFireInsuranceBrokerage;
                tempFireInsuranceObj.fire_insurance_brokerage_record = {
                    ...recordObj,
                    abbr_name: state.fireInsuranceBrokerages.selectedBrokerage?.abbr_name,
                    id: state.fireInsuranceBrokerages.selectedBrokerage?.id!
                }
            }
        }
        tempFireInsuranceObj[key] = value;
        setFireInsuranceObj(tempFireInsuranceObj);
        updateFireInsuranceTabName(dispatch, tempFireInsuranceObj);
    }

    const debouncedSave = useCallback(
        debounce((fireInsuranceObj) => {
            saveFireInsurance(dispatch, String(state.deal.dealInfo?.id), fireInsuranceObj);
            dispatch({ type: actions.REMOVE_FIRE_INSURANCE_BEING_SAVED, payload: fireInsuranceObj.id });
        }, 1000), []);

    const prevFireInsurance = usePrevious(fireInsuranceObj);
    useDidUpdateEffect(() => {
        if (prevFireInsurance?.id === fireInsuranceObj.id) {
            dispatch({ type: actions.ADD_FIRE_INSURANCE_BEING_SAVED, payload: fireInsuranceObj.id });
            debouncedSave(fireInsuranceObj);
        }
    }, [fireInsuranceObj]);
    
    useEffect(() => {
        updateFireInsuranceInfo("fire_insurance_company_id", state.fireInsuranceCompanies.selectedCompany?.id);
    }, [state.fireInsuranceCompanies.selectedCompany])

    function updateCompany(value: FireInsuranceCompanyListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: value.id });
                getFireInsuranceCompanyInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createFireInsuranceCompanyRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
            }
        }
        dispatch({ type: actions.SET_FIRE_INSURANCE_COMPANY_ID, payload: value ? value.id : null });
    }

    function updateBrokerageInfo(brokerageId?: number | null) {
        if (brokerageId !== fireInsuranceObj.fire_insurance_brokerage_id) {
            const tempFireInsuranceObj = { ...fireInsuranceObj }
            tempFireInsuranceObj["fire_insurance_broker_id"] = null;
            tempFireInsuranceObj["fire_insurance_brokerage_id"] = brokerageId;
            setFireInsuranceObj(tempFireInsuranceObj);
            dispatch({ type: actions.SET_FIRE_INSURANCE_BROKER_ID, payload: null });
            dispatch({ type: actions.SET_FIRE_INSURANCE_BROKERAGE_ID, payload: brokerageId });
        }
    }

    function updateBrokerage(value: FireInsuranceBrokerageListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                updateBrokerageInfo(value.id)
                dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: value.id });
                getFireInsuranceBrokerageInfo(dispatch, String(state.deal.dealInfo?.id), value.id);
            } else {
                createFireInsuranceBrokerageRecordFromGlobal(dispatch, String(state.deal.dealInfo?.id), value);
            }
        } else {
            updateBrokerageInfo(null);
        }
        dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: undefined });
    }

    useEffect(() => {
        updateFireInsuranceInfo("fire_insurance_broker_id", state.fireInsuranceBrokers.selectedBroker?.id);
    }, [state.fireInsuranceBrokers.selectedBroker])

    function updateBroker(value: FireInsuranceBrokerListItem | null) {
        if (value !== null) {
            if (value.isRecord) {
                dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: value.id });
                getFireInsuranceBrokerRecordInfo(dispatch, String(state.deal.dealInfo?.id), state.fireInsuranceBrokerages.selectedBrokerage?.id!, value.id!);
            } else {
                createRecordFromFireInsuranceBroker(dispatch, String(state.deal.dealInfo?.id), state.fireInsuranceBrokerages.selectedBrokerage?.id!, value);
            }
        }
        dispatch({ type: actions.SET_FIRE_INSURANCE_BROKER_ID, payload: value ? value.id : null });
    }

    function viewGeneralFireInsuranceModal(type: "company" | "brokerage") {
        switch(type) {
            case "company":
                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD, payload: true });
                break;
            case "brokerage":
                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD, payload: true });
                break;
        }
        setViewFireInsuranceGeneralModal(type);
    }

    return (
        (state.fireInsurance.isFireInsuranceSaving || isObjectLoading(state.dataSheet.objectsLoading, [Loading.FireInsuranceBrokerageList, Loading.FireInsuranceCompanyList])) ? <CircularLoader containerHeight="70vh" /> :
        <>
            <FireInsuranceBrokerageModal
                open={brokerageModalActive}
                onClose={() => setBrokerageModalActive(false)}
            />
            <FireInsuranceCompanyModal
                open={fireInsuranceCompanyModalActive}
                onClose={() => setFireInsuranceCompanyModalActive(false)}
            />
            <ReadonlyGeneralFireInsuranceModal
                open={viewFireInsuranceGeneralModal ? true : false}
                onClose={() => setViewFireInsuranceGeneralModal(undefined)}
                type={viewFireInsuranceGeneralModal ?? "company"}
            />
            <FireInsuranceBrokerModal
                open={brokerModalActive}
                onClose={() => setBrokerModalActive(false)}
            />
            <ReadonlyGeneralBrokerModal
                open={viewBrokerModalActive}
                onClose={() => setViewBrokerModalActive(false)}
                type={"fire_insurance"}
            />
            <PaddedGrid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={6}>
                    <Dropdown
                        value={fireInsuranceObj.property_id}
                        onChange={(e) => {
                            updateFireInsuranceInfo("property_id", Number(e.target.value));
                        }}
                        placeholder="Select property..."
                        options={state.properties.propertiesInDeal.map((property) => property.id)}
                        label={{ text: "Property" }}
                        itemsDisabled={state.properties.propertiesInDeal.map((property) => state.fireInsurance.propertiesWithFireInsurance.includes(property.id) && fireInsuranceObj.property_id !== property.id)}
                        disabledMessage="This property already has a fire insurance record."
                        formatValue={(value: number) => getPropertyTabName(state.properties.propertiesInDeal.find((property) => property.id === value))}
                        isHoverActionHidden={fireInsuranceObj.property_id ? false : true}
                        handleClear={() => updateFireInsuranceInfo("property_id", undefined)}
                    />
                </Grid>
                <Grid item xs={6} />

                <Grid item xs={6}>
                    <ComboBox
                        label={{ text: "Insurance Brokerage", inputId: "fire_insurance_brokerage_record" }}
                        value={state.fireInsuranceBrokerages.selectedBrokerage}
                        canEdit={state.fireInsuranceBrokerages.selectedBrokerage ? true : false}
                        onEditClick={() => {
                            setBrokerageModalActive(true);
                            dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD, payload: true });
                        }}
                        handleView={() => viewGeneralFireInsuranceModal("brokerage")}
                        options={state.fireInsuranceBrokerages.optionList}
                        placeholder={"Select insurance brokerage"}
                        createEntity="insurance brokerage"
                        setCreationModalOpen={() => setBrokerageModalActive(true)}
                        onChangeFn={(value) => updateBrokerage(value as FireInsuranceBrokerageListItem)}
                        isHoverActionHidden={state.fireInsuranceBrokerages.selectedBrokerage ? false : true}
                        handleClear={() => {
                            updateBrokerage(null);
                            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: undefined });
                            dispatch({ type: actions.SET_FIRE_INSURANCE_BROKERAGE_INFO, payload: undefined });
                        }}
                        searchableKeys={["name", "abbr_name", "general_address"]}
                    />
                </Grid>

                {state.fireInsuranceBrokerages.selectedBrokerage ? (
                    <Grid item xs={4}>
                        <ComboBox
                            label={{ text: "Insurance Broker", inputId: "fire_insurance_broker_record" }}
                            createEntity="fire insurance broker"
                            setCreationModalOpen={() => setBrokerModalActive(true)}
                            value={state.fireInsuranceBrokers.selectedBroker ?? undefined}
                            placeholder={"Select insurance broker"}
                            onChangeFn={(value) => updateBroker(value as FireInsuranceBrokerListItem)}
                            options={state.fireInsuranceBrokers.brokerOptionList}
                            isHoverActionHidden={state.fireInsuranceBrokers.selectedBroker ? false : true}
                            canEdit={state.fireInsuranceBrokers.selectedBroker ? true : false}
                            onEditClick={() => {
                                setBrokerModalActive(true);
                                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKER_RECORD, payload: true });
                            }}
                            handleView={() => {
                                setViewBrokerModalActive(true);
                                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKER_RECORD, payload: true });
                            }}
                            handleClear={() => {
                                updateBroker(null);
                                dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: undefined });
                                dispatch({ type: actions.SET_FIRE_INSURANCE_BROKER_INFO, payload: undefined });
                            }}
                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.FireInsuranceBrokerList])}
                        />
                    </Grid>
                ) : ( <Grid item xs={4} /> )}
                <Grid item xs={2} />

                <Grid item xs={6}>
                    <ComboBox
                        label={{ text: "Insurance Company", inputId: "fire_insurance_company" }}
                        value={state.fireInsuranceCompanies.selectedCompany}
                        canEdit={state.fireInsuranceCompanies.selectedCompany ? true : false}
                        onEditClick={() => {
                            setFireInsuranceCompanyModalActive(true);
                            dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD, payload: true });
                        }}
                        handleView={() => viewGeneralFireInsuranceModal("company")}
                        options={state.fireInsuranceCompanies.optionList}
                        placeholder={"Select company"}
                        createEntity="insurance company"
                        setCreationModalOpen={() => setFireInsuranceCompanyModalActive(true)}
                        onChangeFn={(value) => updateCompany(value as FireInsuranceCompanyListItem)}
                        isHoverActionHidden={state.fireInsuranceCompanies.selectedCompany ? false : true}
                        handleClear={() => {
                            updateCompany(null);
                            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: undefined });
                            dispatch({ type: actions.SET_FIRE_INSURANCE_COMPANY_INFO, payload: undefined });
                        }}
                        searchableKeys={["name", "abbr_name", "general_address"]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BooleanControl
                        checked={fireInsuranceObj.type === FireInsuranceNumberType.Binder}
                        onChange={(e) => updateFireInsuranceInfo("type", fireInsuranceObj.type === FireInsuranceNumberType.Binder ? FireInsuranceNumberType.Policy : FireInsuranceNumberType.Binder)}
                        label={{ text: "Policy or Binder Number", inputId: "policy_or_binder_number" }}
                        labels={["Policy", "Binder"]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicTextInput
                        fullWidth
                        value={fireInsuranceObj.number}
                        onChange={(e) => updateFireInsuranceInfo("number", e.target.value)}
                        label={{ text: `${fireInsuranceObj.type === FireInsuranceNumberType.Binder ? "Binder" : "Policy"} Number`, inputId: "number" }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <BasicTextInput
                        fullWidth
                        moneyField
                        valueType="positive"
                        placeholder="0.00"
                        value={fireInsuranceObj.coverage_amount !== undefined ? String(fireInsuranceObj.coverage_amount) : undefined}
                        // @ts-ignore
                        onChange={(e) => updateFireInsuranceInfo("coverage_amount", e.target.value)}
                        label={{ text: "Coverage Amount", inputId: "coverage_amount" }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BasicDatePicker
                        value={fireInsuranceObj.expiry_date ?? null}
                        onChange={(e) => updateFireInsuranceInfo("expiry_date", e as Date)}
                        label={{ text: "Expiry Date" }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BooleanControl
                        checked={fireInsuranceObj.guaranteed_replacement_costs}
                        onChange={(e) => updateFireInsuranceInfo("guaranteed_replacement_costs", !fireInsuranceObj.guaranteed_replacement_costs)}
                        label={{ text: "Guaranteed Replacement Costs", inputId: "guaranteed_replacement_costs" }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <BooleanControl
                        checked={fireInsuranceObj.interest_noted}
                        onChange={(e) => updateFireInsuranceInfo("interest_noted", !fireInsuranceObj.interest_noted)}
                        label={{ text: "Interest of All Mortgages Noted", inputId: "interest_noted" }}
                    />
                </Grid>
            </PaddedGrid>
        </>
    )
};

const PaddedGrid = styled(Grid)({
    paddingTop: "5rem"
})