import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getPrioritiesAfterClosing, getPrioritiesBeforeClosing, savePrioritiesAfterClosing, savePrioritiesBeforeClosing
} from "../../../libs/service/axios/api";
import { PriorityAfterClosingItem } from "../../../libs/types/UniversalSurvey/PriorityAfterClosing/priorityAfterClosing";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizePrioritiesAfterClosingResponse, sanitizePrioritiesBeforeClosingResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizePrioritiesAfterClosingRequest, sanitizePrioritiesBeforeClosingRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { ChargeHolderType } from "../../../libs/resources/enums/chargeHolderType";
import { SimpleLien } from "../../../libs/types/UniversalSurvey/ExistingLien/simpleLien";
import { Lien } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { PriorityBeforeClosingItem } from "conveyance/libs/types/UniversalSurvey/PriorityBeforeClosing/priorityBeforeClosing";

function getPrioritiesAfterClosingInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getPrioritiesAfterClosing(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.SET_PRIORITIES_AFTER_CLOSING_IN_DEAL, payload: labelPrioritiesAfterClosing(sanitizePrioritiesAfterClosingResponse(response.data)) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get priorities after closing: ${error}`, type: AlertTypes.Error } });
        })
}

function getPrioritiesBeforeClosingInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getPrioritiesBeforeClosing(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.SET_PRIORITIES_BEFORE_CLOSING_IN_DEAL, payload: labelPrioritiesBeforeClosing(sanitizePrioritiesBeforeClosingResponse(response.data)) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get priorities before closing: ${error}`, type: AlertTypes.Error } });
        })
}

function updatePrioritiesAfterClosing(dispatch: Dispatch<Record<string, any>>, dealId: string, priorityList: PriorityAfterClosingItem[]) {
    savePrioritiesAfterClosing(dealId, sanitizePrioritiesAfterClosingRequest(priorityList))
        .then(() => {
            getPrioritiesAfterClosingInDeal(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save priorities after closing: ${error}`, type: AlertTypes.Error } });
        })
}

function updatePrioritiesBeforeClosing(dispatch: Dispatch<Record<string, any>>, dealId: string, priorityList: PriorityBeforeClosingItem[]) {
    savePrioritiesBeforeClosing(dealId, sanitizePrioritiesBeforeClosingRequest(priorityList))
        .then(() => {
            getPrioritiesBeforeClosingInDeal(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save priorities before closing: ${error}`, type: AlertTypes.Error } });
        })
}

function labelPrioritiesAfterClosing(priorityList: PriorityAfterClosingItem[]): PriorityAfterClosingItem[] {
    let lienCount = 1;
    let mortgageCount = 1;

    for (const priorityItem of priorityList) {
        if (priorityItem.type === "lien") {
            priorityItem.label = `Encumbrance ${lienCount}`;
            priorityItem.label += getLienLabel(priorityItem.lien_record);
            lienCount += 1;
        } else {
            priorityItem.label = `MTG ${mortgageCount}`
            if (priorityItem.new_mortgage_record?.lender_record?.abbr_name != null) {
                priorityItem.label += ` - ${priorityItem.new_mortgage_record.lender_record.abbr_name}`;
            }
            if (priorityItem.new_mortgage_record?.loan_number != null) {
                priorityItem.label += ` - ${priorityItem.new_mortgage_record.loan_number}`;
            }
            mortgageCount += 1;
        }
    }

    return priorityList;
}

function labelPrioritiesBeforeClosing(priorityList: PriorityBeforeClosingItem[]): PriorityBeforeClosingItem[] {
    // Sorting by priority after closing so that the labels can line up with tabs
    const tempPriorities = [...priorityList];
    tempPriorities.sort((a, b) => {
        if (a.lien_record?.priority_after_closing && b.lien_record?.priority_after_closing) {
            return a.lien_record.priority_after_closing - b.lien_record.priority_after_closing;
        } else if (a.lien_record?.priority_after_closing) {
            return -1;
        } else if (b.lien_record?.priority_after_closing) {
            return 1;
        }
        return 1;
    });

    for (const priorityItem of priorityList) {
        priorityItem.label = `Encumbrance ${tempPriorities.indexOf(priorityItem) + 1}`;
        priorityItem.label += getLienLabel(priorityItem.lien_record);
    }

    return priorityList;
}

function getLienLabel(lien: SimpleLien | Lien | undefined) {
    let label = "";
    switch (lien?.charge_holder_type) {
        case ChargeHolderType.Lender:
            label += lien.lender_record?.abbr_name ? ` - ${lien.lender_record.abbr_name}` : "";
            label += lien.mortgage_loan_number ? ` - ${lien.mortgage_loan_number}` : "";
            break;
        case ChargeHolderType.Company:
            label += lien.charge_holder_company_record?.abbr_name ? ` - ${lien.charge_holder_company_record.abbr_name}` : "";
            label += lien.account_number ? ` - ${lien.account_number}` : "";
            break;
        case ChargeHolderType.Government:
            label += lien.government_record?.abbr_name ? ` - ${lien.government_record.abbr_name}` : "";
            label += lien.account_number ? ` - ${lien.account_number}` : "";
            break;
        case ChargeHolderType.Other:
            label += lien.other_charge_holder_record?.name ? ` - ${lien.other_charge_holder_record.name}` : "";
            label += lien.account_number ? ` - ${lien.account_number}` : "";
            break;
        default:
            break;
    }
    return label
}

export {
    getPrioritiesAfterClosingInDeal,
    getPrioritiesBeforeClosingInDeal,
    updatePrioritiesAfterClosing,
    updatePrioritiesBeforeClosing,
    getLienLabel
}