import { LegalProfessionalRequest } from "../../../types/UniversalSurvey/LegalProfessional/legalProfessionalRequest"
import { LegalProfessionalType } from "../../enums/legalProfessionalType"

export const defaultLegalProfessionalRequest: LegalProfessionalRequest = {
    name: null,
    initial: null,
    all_regions: null,
    email: null,
    phone: null,
    phone_extension: null,
    type: LegalProfessionalType.Lawyer
}
