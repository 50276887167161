import { useState, useContext, useRef } from "react";

import { Grid, Stack, styled } from "@mui/material";

import * as actions from "../../../../context/UniversalSurvey/actionTypes";
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import theme from "../.././../../../components/Common/componentStyling/Theme";
import colors from "../.././../../../components/Common/componentStyling/Colors";
import { newUploadedDocument } from "../../../../context/UniversalSurvey/asyncActions/documents";
import BasicButton from "../../../../../components/Common/Button/BasicButton";
import { CloudSavingIcon, PdfIcon, TrashIcon } from "../../../../../components/Common/Icons/Iconography";
import { SmallBold } from "../../../../../components/Common/Typography";
import BasicIconButton from "../../../../../components/Common/IconButton/IconButton";
import InputErrorMessage from "../../../../../components/Common/InputErrorMessage/InputErrorMessage";

// 10MB
const MAX_FILE_SIZE = 10000000;

type Props = Omit<ModalProps, "children">;

export default function UploadDocumentModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    const [files, setFiles] = useState<File[]>([]);
    const [fileError, setFileError] = useState<string>("");
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);

    const handleDrag = function(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        let files = e.dataTransfer.files
        if (files) {
            createFiles(files);
        }
    };

    const handleChange = function(e: any) {
        e.preventDefault();
        let files = e.target.files
        if (files) {
            createFiles(files);
        }
    };
    
    function readFile(file: File): Promise<File> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = (e: any) => {
                if (!e.target.result.includes('data:application/pdf')) {
                    setFileError("We only accept PDF files");
                    reject(reader);
                } else if (file.size > MAX_FILE_SIZE) {
                    setFileError("PDF is too large. We only accept PDFs up to 10MB");
                    reject(reader)
                }
                resolve(file);
            }

            reader.readAsDataURL(file);
        })
    }

    function createFiles(filesToUpload: File[]) {
        const promiseList: Promise<File>[] = [];
        setFileError("");
        for (const file of filesToUpload) {
            promiseList.push(readFile(file));
        }

        Promise.all(promiseList).then((values: File[]) => {
            setFiles(values);
        })
    }

    const onButtonClick = () => {
        if (inputRef.current) {
            //@ts-ignore
            inputRef.current.click();
        }
    };

    function discard() {
        setDragActive(false);
        setFiles([]);
        setFileError("");
        props.onClose ? props.onClose() : undefined;
    }

    const removeFile = (index: number) => {
        const tempFiles = [...files];
        tempFiles.splice(index, 1);
        setFiles(tempFiles);
    }

    function submit() {
        for (const file of files) {
            dispatch({ type: actions.ADD_UPLOADED_DOC_LOADING, payload: file.name });
            newUploadedDocument(dispatch, String(state.deal.dealInfo!.id), file);
        }
    }

    return (
        <ModalBase
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size="small"
            title="Upload PDF File(s)"
            saveButtonLabel="Upload"
            saveDisabled={files.length === 0}
            closeAfterSaving={discard}
            isSaving={state.documents.uploadedDocsLoading.length > 0}
        >
            <Grid container rowSpacing={3} columnSpacing={5}>
                {fileError && (
                    <Grid item xs={12}>
                        <InputErrorMessage errorMessage={fileError} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {files.length > 0 ? (
                        files.map((file, i) => 
                            <FileObjectContainer container key={`file-${i}`}>
                                <Grid item xs={11}>
                                    <Stack direction={"row"} gap={2} alignItems={"center"} style={{ paddingTop: "0.4rem" }}>
                                        <PdfIcon color={colors.BLACK} />
                                        <SmallBold>
                                            {file.name}
                                        </SmallBold>
                                    </Stack>
                                </Grid>
                                <Grid item xs={1}>
                                    <Stack direction={"column"} alignItems={"center"}>
                                        <BasicIconButton
                                            onClick={() => removeFile(i)}
                                            icon={<TrashIcon color={theme.ERROR} />}
                                        />
                                    </Stack>
                                </Grid>
                            </FileObjectContainer>
                        )
                    ) : (
                        <FormContainer id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                            <FileUploadInput ref={inputRef} type="file" multiple id="input-file-upload" accept='application/pdf' onChange={handleChange} />
                            <FileUploadLabel id="label-file-upload" htmlFor="input-file-upload" dragActive={dragActive}>
                                <div>
                                    <SmallBold>
                                        Drag and drop your files here or
                                    </SmallBold>
                                    <div style={{ paddingTop: "1rem" }}>
                                        <BasicButton
                                            typeOf="CTA"
                                            size="small"
                                            leftIcon={<CloudSavingIcon color={colors.WHITE} />}
                                            label={{ text: "Find files", inputId: "button-find-file" }}
                                            onClick={onButtonClick}
                                        />
                                    </div>
                                </div>
                            </FileUploadLabel>
                            { dragActive && <DragFileElement id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} /> }
                        </FormContainer>
                    )}
                </Grid>
            </Grid>
        </ModalBase>
    );
}

const FormContainer = styled('form')({
    height: "20rem",
    width: "100%",
    textAlign: "center",
    position: "relative",
});

const FileUploadInput = styled('input')({
    display: "none"
});

const FileUploadLabel = styled('label', { shouldForwardProp: 
    (prop) => (
        prop !== "dragActive"
    )
})<{ dragActive?: boolean; }>(({ dragActive }) => ({
    height: "100%",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "0.2rem",
    borderStyle: "dashed",
    borderRadius: "1rem",
    borderColor: colors.BLUE_500,
    backgroundColor: dragActive ? colors.WHITE : colors.BLUE_50
}));

const DragFileElement = styled('div')({
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
});

const FileObjectContainer = styled(Grid)({
    width: "100%",
    height: "5rem",
    backgroundColor: theme.PLACEHOLDER,
    borderWidth: "0.2rem",
    borderRadius: "1rem",
    padding: "0.7rem"
});