const userIdKey = "user_id";
const previousUserIdKey = "prev_id";

/**
 * This is a local storage abstraction that keeps track of all keys that
 * are using to acces the local storage data
 * Please use that and extend with custom methods.
 */
const storage = {
  /**
   * Gets user id from local storage and converts it to number
   */
  getUserId() {
    const uidString = this.userId;
    return uidString === null ? null : +uidString;
  },

  get userId() {
    return localStorage.getItem(userIdKey);
  },

  set userId(value: string | null) {
    if (value) {
      localStorage.setItem(userIdKey, value);
    }
  },

  /** Gets previous user id from LS */
  get previousUserId(): string | null {
    return localStorage.getItem(previousUserIdKey);
  },
  /** Writes previous user id to local storage */
  set previousUserId(value: string | null) {
    if (value) {
      localStorage.setItem(previousUserIdKey, value);
    }
  },

  get token(): string | null {
    return localStorage.getItem(this.getTokenKey());
  },

  set token(token: string | null) {
    if (token) {
      localStorage.setItem(this.getTokenKey(), token);
    }
  },

  removeToken(): void {
    return localStorage.removeItem(this.getTokenKey());
  },

  removeUserId(): void {
    return localStorage.removeItem(userIdKey);
  },
  removePreviousUserId(): void {
    return localStorage.removeItem(previousUserIdKey);
  },
  clear() {
    localStorage.clear();
  },
  getTokenKey(): string {
    return `token_${this.userId}`;
  },
  set lastCallTimestamp(value: string | null) {
    if (value && this.userId) {
      localStorage.setItem(`lastCall_${this.userId}`, value);
    }
  },
  get lastCallTimestamp(): string | null {
    return localStorage.getItem(`lastCall_${this.userId}`);

  },
  set targetLink(value: string | null) {
    sessionStorage.setItem(`targetLink`, value || "");
  },
  get targetLink(): string | null {
    return sessionStorage.getItem(`targetLink`);
  }
};

export default storage;
