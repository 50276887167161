import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { defaultGovernmentContact } from "../../../../libs/resources/defaults/frontend/defaultGovernmentContact";
import { GovernmentContact, GovernmentContactErrorState } from "../../../../libs/types/UniversalSurvey/GovernmentContacts/governmentContact";
import { saveGovernmentContact, submitNewGovernmentContactRecord } from "../../../../context/UniversalSurvey/asyncActions/governmentContacts";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultGovernmentContactErrorState } from "../../../../libs/resources/defaults/errorStates/defaultGovernmentContactErrorState";

type Props = Omit<ModalProps, "children">;

export default function GovernmentContactModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [contactObj, setContactObj] = useState<GovernmentContact>({ ...defaultGovernmentContact });
    const [errorState, setErrorState] = useState<GovernmentContactErrorState>({ ...defaultGovernmentContactErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.governmentContacts.contactBeingEdited) {
            setContactObj({ ...state.governmentContacts.contactBeingEdited });
        } else {
            setContactObj({ ...defaultGovernmentContact });
        }
    }, [state.governmentContacts.contactBeingEdited]);

    function updateGovernmentContactInfo<
        K extends keyof GovernmentContact,
        V extends GovernmentContact[K]
    >(key: K, value: V): void {
        const tempContactObj = { ...contactObj };
        tempContactObj[key] = value;
        setContactObj(tempContactObj);
    }

    function submit() {
        if (state.governmentContacts.contactBeingEdited) {
            saveGovernmentContact(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.governments.selectedGovernment?.id!,
                contactObj
            )
        } else {
            submitNewGovernmentContactRecord(dispatch,
                String(state.deal.dealInfo?.id),
                state.existingLiens.existingLienInfo!.id,
                state.governments.selectedGovernment!.id,
                state.governmentContacts.contactBeingReplaced,
                contactObj
            );
        }
    }

    function discard() {
        dispatch({ type: actions.SET_GOVERNMENT_CONTACT_BEING_EDITED, payload: undefined });
        dispatch({ type: actions.SET_GOVERNMENT_CONTACT_BEING_REPLACED, payload: undefined });
        setContactObj({ ...defaultGovernmentContact });
        setErrorState({ ...defaultGovernmentContactErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!contactObj.name || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, contactObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={`${!state.governmentContacts.contactBeingEdited ? "New " : ""}Government Contact`}
            saveDisabled={saveDisabled}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.GovernmentContactModal])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveGovernmentContact])}
        >
            <Grid container columnSpacing={5} rowSpacing={3}>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        autoFocus
                        label={{text: "Name", inputId: "name", isRequired: true}}
                        placeholder="Contact's name"
                        value={contactObj.name ?? undefined}
                        onChange={(e) => updateGovernmentContactInfo("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        label={{text: "Email", inputId: "email"}}
                        placeholder="Contact's email"
                        value={contactObj.email ?? undefined}
                        onChange={(e) => updateGovernmentContactInfo("email", e.target.value)}
                        fieldType="email"
                        error={{
                            showError: true,
                            setErrorState: setErrorState,
                            errorKey: "email",
                            errorState: errorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={contactObj.phone_work ?? undefined}
                        onChange={(value) => updateGovernmentContactInfo("phone_work", value)}
                        label={{ text: "Work Phone", inputId: "phone_work" }}
                        error={{
                            showError: true,
                            errorKey: "phone_work",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={contactObj.fax ?? undefined}
                        onChange={(value) => updateGovernmentContactInfo("fax", value)}
                        label={{ text: "Fax", inputId: "fax" }}
                        error={{
                            showError: true,
                            errorKey: "fax",
                            errorState: errorState,
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}
