import { Client } from "../../../types/UniversalSurvey/Client/client";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultClient: Client = {
    id: -1,
    client_id: -1,
    birthdate: undefined,
    first_name: undefined,
    middle_name: undefined,
    last_name: undefined,
    title: undefined,
    phone_work: undefined,
    fax: undefined,
    email: undefined,
    phone_cell: undefined,
    phone_home: undefined,
    is_canadian_resident: undefined,
    reside_at_subject: true,
    residential_status: undefined,
    address_for_service: defaultSimpleAddress,
    pronouns: undefined,
    preferred_name: undefined,
    marital_status: undefined,
    type: undefined,
    corporation_name: undefined,
    lived_at_property: undefined,
    residency: undefined,
    income_tax_act: undefined,
    days_183: undefined,
    sin: undefined,
    primary_id_type: undefined,
    secondary_id_type: undefined,
    primary_issuing_country: "Canada",
    secondary_issuing_country: "Canada",
    primary_issuing_jurisdiction: undefined,
    secondary_issuing_jurisdiction: undefined,
    primary_id_number: undefined,
    secondary_id_number: undefined,
    primary_expiry: undefined,
    secondary_expiry: undefined,
    employment_status: undefined,
    occupation: undefined,
    name_of_employer: undefined,
    employer_address: defaultSimpleAddress,
    employer_phone: undefined,
    industry: undefined,
    tenants_in_common_share: undefined,
    financial_info: defaultFinancialInfo,
    date_created: undefined,
    date_updated: undefined,

    roles: [],
    spousal_relationship: undefined,
    poa_eoe_relationships: []
}