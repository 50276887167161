import { styled } from "@mui/material/styles";
import {Box, BoxProps, SxProps, Theme} from "@mui/material";
import ResultsNotFoundSvg from "../../components/Svg/ResultsNotFoundSvg";
import constants from "../../styles/constants";

const DeededResultsNotFound: React.FC<
  Omit<BoxProps, "variant"> & {
    searchVal?: string;
    textNotFound?: string;
    sx?: SxProps<Theme>;
  }
> = ({searchVal, textNotFound, sx, ...props}) => (
  <ResultsNotFoundWrapper
    data-testid="no-results-found-container"
    {...props}
    sx={sx}
  >
    <ResultsNotFoundSvg />
    <Title>
      {searchVal?.length !== 0 && !textNotFound
        ? "Results not found"
        : textNotFound}
    </Title>
    {searchVal?.length !== 0 && !textNotFound && (
      <Description>Please try again using different keywords</Description>
    )}
  </ResultsNotFoundWrapper>
);

const ResultsNotFoundWrapper = styled(Box)({
  textAlign: "center",
  padding: "87px 0",
  width: "100%",
});
const Title = styled(Box)({
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "27px",
  textAlign: "center",
  color: constants.colors.title,
  marginTop: "40px",
  marginBottom: "16px",
});
const Description = styled(Box)({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "26px",
  textAlign: "center",
  color: constants.colors.deededGray,
});

export default DeededResultsNotFound;
