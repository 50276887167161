import { Address } from "../../../types/UniversalSurvey/Address/address";

export const defaultAddress: Address = {
    id: -1,
    street_address: undefined,
    city: undefined,
    province_enum: undefined,
    postal_code: undefined,
    country: "Canada",
    date_created: undefined,
    date_updated: undefined
}