import { Dispatch } from "react";

import * as actions from "../../UniversalSurvey/actionTypes"
import { 
    deleteRegionFromFireInsuranceBrokerRecord,
    getFireInsuranceBrokerRecord, 
    getFireInsuranceBrokerRecords, 
    getGlobalFireInsuranceBrokers,  
    newFireInsuranceBrokerRecord, 
    newRegionOnFireInsuranceBrokerRecord, 
    saveFireInsuranceBrokerRecord 
} from '../../../libs/service/axios/api';
import { sanitizeFireInsuranceBrokerResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeFireInsuranceBrokerRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { FireInsuranceBrokerage } from "../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import { FireInsuranceBroker, FireInsuranceBrokerListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { Loading } from "../../../libs/resources/enums/loading";

function createFireInsuranceBrokerOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, fireInsuranceBrokerage: FireInsuranceBrokerage, selectedBrokerId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerList, isLoading: true } });
    getFireInsuranceBrokerRecords(dealId, String(fireInsuranceBrokerage.id))
    .then(function (response: any) {
        const options: FireInsuranceBrokerListItem[] = [];
        const brokerIsRecordList: number[] = [];

        for(const broker of response.data) {
            options.push({
                id: broker.id,
                label: broker.name,
                isRecord: true
            });
            if(broker.fire_insurance_broker) {
                brokerIsRecordList.push(broker.fire_insurance_broker.id);
            }
        }

        if(fireInsuranceBrokerage.fire_insurance_brokerage?.id) {
            getGlobalFireInsuranceBrokers(String(fireInsuranceBrokerage.fire_insurance_brokerage.id))
            .then(function (globalResponse: any) {
                for (const globalBroker of globalResponse.data.fire_insurance_brokers) {
                    if(!brokerIsRecordList.includes(globalBroker.id)) {
                        options.push({
                            id: globalBroker.id,
                            label: globalBroker.name,
                            isRecord: false
                        });
                    }
                }
                dispatch({ type: actions.SET_FIRE_INSURANCE_BROKERS_OPTION_LIST, payload: options });
                dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: selectedBrokerId });
            })
            .catch(function(error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance brokers: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerList, isLoading: false } });
            })
        } else {
            dispatch({ type: actions.SET_FIRE_INSURANCE_BROKERS_OPTION_LIST, payload: options });
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: selectedBrokerId });
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerList, isLoading: false } });
        }
    })
    .catch(function(error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerList, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance broker records: ${error}`, type: AlertTypes.Error } });
    })
}

function createRecordFromFireInsuranceBroker(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageId: number, broker: FireInsuranceBrokerListItem) {
    newFireInsuranceBrokerRecord(dealId, String(brokerageId), { fire_insurance_broker_id: broker.id! })
    .then(function (response: any) {
        getFireInsuranceBrokerRecordInfo(dispatch, dealId, brokerageId, response.data.id);
        const newBrokerListItem: FireInsuranceBrokerListItem = { id: response.data.id, isRecord: true, label: broker.label };
        dispatch({ type: actions.REPLACE_FIRE_INSURANCE_BROKER_OPTION_WITH_RECORD, payload: { oldBrokerId: broker.id, newOption: newBrokerListItem, oldIsRecord: false } });
        dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: newBrokerListItem.id });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance broker record: ${error}`, type: AlertTypes.Error } });
    })
}

function submitNewFireInsuranceBrokerRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: FireInsuranceBroker, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBroker, isLoading: true } });
    newFireInsuranceBrokerRecord(dealId, String(brokerageRecordId))
    .then(function (response: any) {
        saveFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(response.data.id), sanitizeFireInsuranceBrokerRequest(brokerInfo))
        .then(async function() { 
            const newBrokerListItem: FireInsuranceBrokerListItem = { id: response.data.id, isRecord: true, label: brokerInfo.name ?? undefined };
            await addFireInsuranceBrokerRecordRegions(dispatch, dealId, brokerageRecordId, response.data.id, regionList);
            dispatch({ type: actions.ADD_TO_FIRE_INSURANCE_BROKER_OPTIONS, payload: newBrokerListItem });
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKER, payload: newBrokerListItem.id });
            getFireInsuranceBrokerRecordInfo(dispatch, dealId, brokerageRecordId, response.data.id);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance broker record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBroker, isLoading: false } });
        })
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBroker, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance broker record: ${error}`, type: AlertTypes.Error } });
    })
}

function saveFireInsuranceBroker(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: FireInsuranceBroker, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBroker, isLoading: true } });
    saveFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), sanitizeFireInsuranceBrokerRequest(brokerInfo))
    .then(async function () {
        await updateFireInsuranceBrokerRecordRegions(dispatch, dealId, brokerageRecordId, brokerInfo, regionList);
        getFireInsuranceBrokerRecordInfo(dispatch, dealId, brokerageRecordId, brokerInfo.id);
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance broker record: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBroker, isLoading: false } });
    })
}

function getFireInsuranceBrokerRecordInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerRecordId: number) {
    getFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(brokerRecordId))
    .then(function(response: any) {
        dispatch({ type: actions.SET_FIRE_INSURANCE_BROKER_INFO, payload: sanitizeFireInsuranceBrokerResponse(response.data) });
        dispatch({ type: actions.SET_FIRE_INSURANCE_BROKER_ID, payload: brokerRecordId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance broker record: ${error}`, type: AlertTypes.Error } });
    })
}

async function addFireInsuranceBrokerRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(brokerRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance broker record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateFireInsuranceBrokerRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, brokerInfo: FireInsuranceBroker, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (brokerInfo.regions) {
        for (const region of brokerInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance broker record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromFireInsuranceBrokerRecord(dealId, String(brokerageRecordId), String(brokerInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the fire insurance broker record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on fire insurance broker record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}


export {
    createFireInsuranceBrokerOptionsList,
    createRecordFromFireInsuranceBroker,
    submitNewFireInsuranceBrokerRecord,
    saveFireInsuranceBroker,
    getFireInsuranceBrokerRecordInfo
}