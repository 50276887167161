import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { MortgageBrokerage } from "../../../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { defaultMortgageBrokerage } from "../../../../../libs/resources/defaults/frontend/defaultMortgageBrokerage";
import { addressToString, regionListToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyMortgageBrokerageModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [brokerageObj, setBrokerageObj] = useState<MortgageBrokerage>({ ...defaultMortgageBrokerage });

    useEffect(() => {
        if(state.mortgageBrokerages.editingMortgageBrokerageRecord && state.mortgageBrokerages.brokerageInfo) {
            setBrokerageObj(state.mortgageBrokerages.brokerageInfo);
        }
    }, [state.mortgageBrokerages.editingMortgageBrokerageRecord]);

    function discard() {
        dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKERAGE_RECORD, payload: false });
        setBrokerageObj({ ...defaultMortgageBrokerage });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={brokerageObj.name}
            subtitle="MORTGAGE BROKERAGE"
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Abbreviated Name:
                        </Small>
                        <Large>
                            {brokerageObj.abbr_name}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Province(s) of Operation:
                        </Small>
                        <Large>
                            {regionListToString(brokerageObj.all_regions, brokerageObj.regions)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Phone:
                        </Small>
                        <Large>
                            <Link
                                href={brokerageObj.phone}
                                type="phone"
                            />
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address:
                        </Small>
                        <Large style={{ whiteSpace: "pre-line" }}>
                            {addressToString(brokerageObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Fax:
                        </Small>
                        <Link
                            href={brokerageObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Email:
                        </Small>
                        <Link
                            href={brokerageObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={brokerageObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>
                            {brokerageObj.mortgage_brokerage?.notes}
                        </Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}