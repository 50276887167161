import { useState, useEffect } from 'react';
import { Deals } from "../types/DealList/deals";

const usePagination = (dealsData: Deals | undefined, paginationLimit: number) => {
    // const variable declaration
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
      if (dealsData?.metadata.count) {
        setTotalPages(Math.ceil(dealsData?.metadata.total / paginationLimit));
      }
    }, [dealsData]);

    return { totalPages };
}
    
export { usePagination };