import { DocumentType } from "conveyance/libs/resources/enums/documents/documentType";
import { TransactionTypes } from "../../../../resources/enums/transactions";
import { Transaction } from "../../Transaction/transaction";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { GuarantorGuaranteeOfMortgageContextRequest } from "../documents/guarantorGuaranteeOfMortgageDocument";
import { doesMortgageHaveGuarantors } from "./guarantorWaiverOfIlaContext";


function createGuarantorGuaranteeOfMortgageName(mortgage: Transaction | undefined): string {
    if (!mortgage) return "";
    let name = "Guarantee of Mortgage";
    
    if (mortgage.label) {
        name += ` - ${mortgage.label}`;
    }
    return name;
}

function isGuarantorGuaranteeOfMortgageDocInDeal(docList: Document[], mortgage: Transaction): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.GuarantorGuaranteeOfMortgage && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.mortgage_id === mortgage.id) return true
    }
    return false;
}

function createGuarantorGuaranteeOfMortgageContextRequest(mortgage: Transaction | undefined): GuarantorGuaranteeOfMortgageContextRequest {
    return { mortgage_id: mortgage ? mortgage.id : -1 };
}

function getGuarantorGuaranteeOfMortgageDocData(transactionsInDeal: Transaction[], contextId: number): [Object, string] {
    const matchingMortgage = transactionsInDeal.find((mortgage) => mortgage.id === contextId && mortgage.transaction_type === TransactionTypes.Mortgage);
    return [createGuarantorGuaranteeOfMortgageContextRequest(matchingMortgage), createGuarantorGuaranteeOfMortgageName(matchingMortgage)];
}

function getGuarantorGuaranteeOfMortgageContextOptions(transactionsInDeal: Transaction[], docList: Document[]) {
    const options = [];
    for (const mortgage of transactionsInDeal.filter((transaction) => transaction.transaction_type === TransactionTypes.Mortgage)) {
        if(!isGuarantorGuaranteeOfMortgageDocInDeal(docList, mortgage) && doesMortgageHaveGuarantors(mortgage)) {
            options.push(mortgage.id);
        }
    }
    return options;
}

export {
    getGuarantorGuaranteeOfMortgageDocData,
    getGuarantorGuaranteeOfMortgageContextOptions
}