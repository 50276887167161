import { useEffect, useState, useContext, useCallback } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid, styled } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { defaultLegalProfessional } from "../../../../libs/resources/defaults/frontend/defaultLegalProfessional";
import { LegalProfessional, LegalProfessionalErrorState, LegalProfessionalListItem } from "../../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { LegalProfessionalType } from "../../../../libs/resources/enums/legalProfessionalType";
import { saveLegalProfessionalRecord, submitNewLegalProfessionalRecord } from "../../../../context/UniversalSurvey/asyncActions/legalProfessionals";
import * as _ from "lodash";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { H5 } from "../../../../../components/Common/Typography";
import { LegalFirmListItem } from "../../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { LegalFirmOfficeListItem } from "../../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { getLegalFirmOfficesForFirmToLink } from "../../../../context/UniversalSurvey/asyncActions/legalFirmOffices";
import { validateModalObject } from "../../../../libs/utils/validation";
import { Loading } from "../../../../libs/resources/enums/loading";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { RegionListItem } from "../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { LegalProfessionalOptions } from "../../../../libs/resources/enums/legalProfessionalOptions";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { LegalProfessionalTypeOptions } from "../../../../libs/resources/options";
import { formatLegalProfessionalType } from "../../../../libs/utils/formatValues";
import { defaultLegalProfessionalErrorState } from "../../../../libs/resources/defaults/errorStates/defaultLegalProfessionalErrorState";
import { getAllLegalFirmOptions } from "../../../../context/UniversalSurvey/asyncActions/legalFirms";
import { DEFAULT_LEGAL_FIRM_OFFICE_RESULT_LIMIT, DEFAULT_LEGAL_FIRM_RESULT_LIMIT } from "../../../../libs/utils/limits";
import { debounce } from "../../../../libs/utils/debounce";

type Props = Omit<ModalProps, "children"> & {
    type: LegalProfessionalOptions;
};

export default function LegalProfessionalModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [professionalObj, setProfessionalObj] = useState<LegalProfessional>({ ...defaultLegalProfessional });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [originalObj, setOriginalObj] = useState<LegalProfessional>({ ...defaultLegalProfessional });
    const [originalRegionList, setOriginalRegionList] = useState<RegionListItem[]>([]);
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [firmToLink, setFirmToLink] = useState<LegalFirmListItem | undefined>(undefined);
    const [officeToLink, setOfficeToLink] = useState<LegalFirmOfficeListItem | undefined>(undefined);
    const [errorState, setErrorState] = useState<LegalProfessionalErrorState>({ ...defaultLegalProfessionalErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    // Handling searching legal firms
    const [legalFirmSearchValue, setLegalFirmSearchValue] = useState<string | undefined>(undefined);
    const [officeSearchValue, setOfficeSearchValue] = useState<string | undefined>(undefined);
    
    function makeLegalFirmQuery(searchVal: string | undefined): URLSearchParams | undefined {
        if (!searchVal) return;
        const query = new URLSearchParams();
        query.set("search", searchVal);
        query.set("region_id", String(state.deal.dealInfo?.region_id))
        query.set("limit", DEFAULT_LEGAL_FIRM_RESULT_LIMIT);
        return query;
    }

    function makeOfficeQuery(searchVal: string | undefined): URLSearchParams {
        const query = new URLSearchParams();
        if (searchVal) query.set("search", searchVal);
        query.set("limit", DEFAULT_LEGAL_FIRM_OFFICE_RESULT_LIMIT);
        return query;
    }

    useEffect(() => {
        debouncedLegalFirmSearch(legalFirmSearchValue);
    }, [legalFirmSearchValue]);

    useEffect(() => {
        debouncedOfficeSearch(officeSearchValue);
    }, [officeSearchValue]);

    const debouncedLegalFirmSearch = useCallback(
        debounce((legalFirmSearchValue) => {
            getAllLegalFirmOptions(dispatch, String(state.deal.dealInfo?.id), makeLegalFirmQuery(legalFirmSearchValue));
        }, 500), []);
    
    const debouncedOfficeSearch = useCallback(
        debounce((officeSearchValue) => {
            if (firmToLink) {
                getLegalFirmOfficesForFirmToLink(dispatch, String(state.deal.dealInfo?.id), firmToLink, makeOfficeQuery(officeSearchValue));
            }
        }, 500), []);

    useEffect(() => {
        if (state.legalProfessionals.recordBeingEdited) {
            setProfessionalObj(state.legalProfessionals.recordBeingEdited);
            setOriginalObj(state.legalProfessionals.recordBeingEdited);
            let tempRegionList: RegionListItem[] = [];
            if (state.legalProfessionals.recordBeingEdited.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.legalProfessionals.recordBeingEdited.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
            setOriginalRegionList(tempRegionList);
        }
    }, [state.legalProfessionals.recordBeingEdited]);

    useEffect(() => {
        getAllLegalFirmOptions(dispatch, String(state.deal.dealInfo?.id), makeLegalFirmQuery(undefined));
    }, []);

    function updateLegalProfessionalInfo<
        K extends keyof LegalProfessional,
        V extends LegalProfessional[K]
    >(key: K, value: V): void {
        const tempObj = { ...professionalObj }
        tempObj[key] = value;
        setProfessionalObj(tempObj);
    }

    function submit() {
        const newListItem: LegalProfessionalListItem = {
            ...professionalObj,
            isRecord: true,
            label: professionalObj.name,
        };
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.legalProfessionals.recordBeingEdited) {
            saveLegalProfessionalRecord(dispatch, String(state.deal.dealInfo?.id), professionalObj, props.type, firmToLink, officeToLink, regionList);
            dispatch({ type: actions.REPLACE_LEGAL_PROFESSIONAL_OPTION_WITH_RECORD, payload: { oldId: professionalObj.id, newOption: newListItem, oldIsRecord: true }});
        } else {
            submitNewLegalProfessionalRecord(dispatch, state.deal.dealInfo!, professionalObj, state.lenders.lenderInfo, props.type, firmToLink, officeToLink, regionList);
        }
    }

    function discard() {
        dispatch({ type: actions.SET_LEGAL_PROFESSIONAL_BEING_EDITED, payload: undefined });
        setProfessionalObj({ ...defaultLegalProfessional });
        setOriginalObj({ ...defaultLegalProfessional });
        setOriginalRegionList([]);
        setSelectedRegionList([]);
        setFirmToLink(undefined);
        setOfficeToLink(undefined);
        setErrorState(defaultLegalProfessionalErrorState);
        props.onClose ? props.onClose() : undefined;
    }

    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateLegalProfessionalInfo("all_regions", true);
        } else {
            updateLegalProfessionalInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function handleDiscardClick() {
        if (_.isEqual(professionalObj, originalObj) && _.isEqual(selectedRegionList.sort(), originalRegionList.sort())) {
            discard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        discard();
    }

    function updateFirmToLink(value: LegalFirmListItem | null) {
        if (value) {
            getLegalFirmOfficesForFirmToLink(dispatch, String(state.deal.dealInfo?.id), value, makeOfficeQuery(undefined));
            setFirmToLink(value);
        } else {
            setFirmToLink(undefined);
            setOfficeToLink(undefined);
        }
    }

    function isSaveDisabled() {
        if (validateModalObject(errorState)) return true;
        if (!(professionalObj.name && selectedRegionList.length > 0)) return true;
        if (_.isEqual(professionalObj, originalObj) && _.isEqual(selectedRegionList.sort(), originalRegionList.sort()) && !firmToLink) return true;
        return false;
    }

    function getLegalFirmOptionsToLink(): LegalFirmListItem[] {
        let options: LegalFirmListItem[] = [];
        let idsToExclude: number[] = [];
        switch (props.type) {
            case LegalProfessionalOptions.DealLawyer:
                idsToExclude = state.legalFirms.lawyerFirmOptionList.map((firm) => firm.id);
                break;
            case LegalProfessionalOptions.DealSigner:
                idsToExclude = state.legalFirms.signerFirmOptionList.map((firm) => firm.id);
                break;
            case LegalProfessionalOptions.LenderLawyer:
                idsToExclude = state.legalFirms.lenderLawyerFirmOptionList.map((firm) => firm.id);
                break;
        }
        for (const firm of state.legalFirms.allFirmsOptionList) {
            if (!idsToExclude.includes(firm.id)) options.push(firm);
        }
        return options
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, professionalObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            size={props.size}
            title={`${state.legalProfessionals.recordBeingEdited ? "" : "New "}Legal Professional`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.legalProfessionals.recordBeingEdited ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LegalProfessionalModal])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLegalProfessional])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            value={professionalObj.name ?? undefined}
                            onChange={(e) => updateLegalProfessionalInfo("name", e.target.value)}
                            label={{text: "Legal Professional Name", inputId: "name", isRequired: true}}
                            placeholder="Legal professional's name"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BasicTextInput
                            fullWidth
                            value={professionalObj.initial ?? undefined}
                            onChange={(e) => updateLegalProfessionalInfo("initial", e.target.value)}
                            label={{text: "Initial", inputId: "initial"}}
                            placeholder="Initial"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ComboBox
                            options={state.regions.regions}
                            tokenField
                            value={selectedRegionList}
                            label={{ text: "Province(s) of Operation", inputId: "provinces", isRequired: true }}
                            onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                            placeholder="Province initials"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            value={professionalObj.email ?? undefined}
                            onChange={(e) => updateLegalProfessionalInfo("email", e.target.value)}
                            label={{text: "Email", inputId: "email"}}
                            placeholder="Email"
                            fieldType="email"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "email",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <PhoneField
                            value={professionalObj.phone}
                            onChange={(value) => updateLegalProfessionalInfo("phone", value)}
                            label={{text: "Phone Number", inputId: "phone"}}
                            inputProps={{ "aria-label": "Phone Number" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            value={professionalObj.phone_extension ?? undefined}
                            onChange={(e) => updateLegalProfessionalInfo("phone_extension", e.target.value)}
                            label={{text: "Extension", inputId: "phone_extension"}}
                            placeholder="Extension"
                            inputProps={{ "aria-label": "Extension" }}
                        />
                    </Grid>

                    {props.type === LegalProfessionalOptions.DealSigner && <Grid item xs={6}>
                        <Dropdown
                            value={professionalObj.type}
                            onChange={(e) => updateLegalProfessionalInfo("type", e.target.value as LegalProfessionalType)}
                            placeholder="Select type..."
                            options={LegalProfessionalTypeOptions}
                            label={{ text: "Type" }}
                            formatValue={formatLegalProfessionalType}
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <StyledH5>Link Legal Firm and Office</StyledH5>
                    </Grid>
                    <Grid item xs={6}>
                        <ComboBox
                            value={legalFirmSearchValue !== undefined ? legalFirmSearchValue : firmToLink}
                            options={getLegalFirmOptionsToLink()}
                            onChangeFn={(value) => updateFirmToLink(value as LegalFirmListItem)}
                            placeholder="Select firm..."
                            label={{ text: "Firm", inputId: "firm" }}
                            handleClear={() => updateFirmToLink(null)}
                            isHoverActionHidden={firmToLink ? false : true}
                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LawyerFirmList])}
                            onBlur={() => setLegalFirmSearchValue(undefined)}
                            setText={(value) => setLegalFirmSearchValue(value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {firmToLink &&
                        <ComboBox
                            value={officeSearchValue !== undefined ? officeSearchValue : officeToLink}
                            options={state.legalFirmOffices.officesForFirmToLink}
                            onChangeFn={(value) => setOfficeToLink(value as LegalFirmOfficeListItem)}
                            placeholder="Select office..."
                            label={{ text: "Office", inputId: "office" }}
                            handleClear={() => setOfficeToLink(undefined)}
                            isHoverActionHidden={officeToLink ? false : true}
                            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LawyerFirmOfficeList])}
                            onBlur={() => setOfficeSearchValue(undefined)}
                            setText={(value) => setOfficeSearchValue(value)}
                        />}
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}

const StyledH5 = styled(H5)({
    paddingTop: "1.6rem"
})
