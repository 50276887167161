import { StatDecDocType } from "../../../../resources/enums/statDecDocType";
import { StatDecContext, StatDecContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/statDecDocument";


export const defaultStatDecContext: StatDecContext = {
    type: StatDecDocType.StatDec,
    clients: [],
    our_lawyer: false,

    client_records: []
}

export const defaultStatDecContextRequest: StatDecContextRequest = {
    type: StatDecDocType.StatDec,
    clients: [],
    our_lawyer: false
}