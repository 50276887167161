import { AdminLoading } from "../../../libs/resources/enums/loading";
import { AdminActionTypes } from "../actionTypes";
import { AdminActions } from "../context";


export type AdminLoadingState = {
    objectsLoading: AdminLoading[];
}

export const defaultAdminLoadingState: AdminLoadingState = {
    objectsLoading: []
}

export function loadingReducer(state: AdminLoadingState, action: AdminActions): AdminLoadingState {
    switch (action.type) {
        case AdminActionTypes.SET_OBJECT_LOADING:
            let tempLoading = [...state.objectsLoading];
            if (action.payload.isLoading && !tempLoading.includes(action.payload.obj)) {
                tempLoading.push(action.payload.obj);
            } else if (!action.payload.isLoading) {
                tempLoading = tempLoading.filter((obj) => obj !== action.payload.obj);
            }
            return { ...state, objectsLoading: tempLoading };
        default:
            return state;
    }
}