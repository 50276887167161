// Importing built-in React/Router tools
import { useState, useContext } from 'react';

import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

// Importing MUI components
import { Stack, styled } from '@mui/material';

// Importing from DCP UI Library
import TransactionPill from '../../Components/TransactionPills/TransactionPill';
import TransactionModal from '../../Components/Modals/TransactionModal';
import { Transaction } from '../../../../libs/types/UniversalSurvey/Transaction/transaction';
import { TransactionTypes } from '../../../../libs/resources/enums/transactions';
import { removeMortgageTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/mortgageTransactions';
import { removeIlaTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/ilaTransactions';
import { removeTitleTransferTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/titleTransferTransactions';
import GenericDialog from '../../../../../components/Common/Modal/GenericDialog';
import { PILL_CONTAINER } from '../../../../../components/Common/componentStyling/Styles';


export default function TransactionArea() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [addTransactionOpen, setAddTransactionOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [transactionToRemove, setTransactionToRemove] = useState<Transaction | undefined>(undefined);

    function handleDelete(transaction: Transaction) {
        setRemoveModalOpen(true);
        setTransactionToRemove(transaction);
    }

    function handleEdit(transaction: Transaction) {
        dispatch({ type: actions.SET_TRANSACTION_BEING_EDITED, payload: transaction });
        setAddTransactionOpen(true);
    }

    function discardDelete() {
        setRemoveModalOpen(false);
        setTransactionToRemove(undefined);
    }

    function handleDeleteConfirm() {
        if (transactionToRemove) {
            switch (transactionToRemove.transaction_type) {
                case TransactionTypes.Mortgage:
                    removeMortgageTransaction(dispatch, String(state.deal.dealInfo?.id), transactionToRemove.id, state.dataSheet.currSection, state.dataSheet.currEntity!, state.dataSheet.tabOptions);
                    break;
                case TransactionTypes.ILA:
                    removeIlaTransaction(dispatch, String(state.deal.dealInfo?.id), transactionToRemove.id);
                    break;
                case TransactionTypes.TitleTransfer:
                    removeTitleTransferTransaction(dispatch, String(state.deal.dealInfo?.id), transactionToRemove.id);
                    break;
            }
        }
        setTransactionToRemove(undefined);
        setRemoveModalOpen(false);
    }

    function setPrimaryFirst() {
        return [...state.transactions.transactionList].sort((a, b) => {
            if (a.id === state.deal.dealInfo?.primary_transaction_id && a.transaction_type === state.deal.dealInfo?.primary_transaction_type) {
                return -1;
            }
            if (b.id === state.deal.dealInfo?.primary_transaction_id && b.transaction_type === state.deal.dealInfo?.primary_transaction_type) {
                return 1;
            }
            return state.transactions.transactionList.indexOf(a) > state.transactions.transactionList.indexOf(b) ? 1 : -1;
        })
    }

    return (
        <TransactionBase>
            <PillStack direction="row" gap={2}>
                {state.transactions.transactionList && 
                   setPrimaryFirst().map((item, i) => (
                        (item.transaction_type && item.transaction_type !== TransactionTypes.Deal) && (
                            <TransactionPill
                                key={i}
                                type={item.transaction_type}
                                label={item.label}
                                handleDelete={() => handleDelete(item)}
                                handleEdit={() => handleEdit(item)}
                                isPrimary={item.id === state.deal.dealInfo?.primary_transaction_id && item.transaction_type === state.deal.dealInfo?.primary_transaction_type}
                            />
                        )
                    ))
                }
                <TransactionPill
                    type={TransactionTypes.AddTransaction}
                    isAddPill
                    handleAdd={() => setAddTransactionOpen(true)}
                />
            </PillStack>
            <TransactionModal
                open={addTransactionOpen}
                onClose={() => {
                    setAddTransactionOpen(false); 
                    dispatch({ type: actions.SET_TRANSACTION_BEING_EDITED, payload: undefined });
                }}
                title={"New Transaction"}
                size="small"
            />
            <GenericDialog
                action="destructive"
                submitText="Remove"
                title="Confirm Removal"
                onCancel={() => discardDelete()}
                onSubmit={() => handleDeleteConfirm()}
                open={removeModalOpen}
                contentText="Are you sure you want to remove this transaction?"
            />
        </TransactionBase>
    )
}

const TransactionBase = styled('div')({
    paddingTop: "2rem",
    height: "100%"
});

//@ts-ignore
const PillStack = styled(Stack)(PILL_CONTAINER);