import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    saveDealGovernment,
    getDealGovernment,
    createDealGovernment,
    getDealGovernments,
    getGlobalGovernments
} from "../../../libs/service/axios/api";
import { sanitizeGovernmentResponse, sanitizeGovernmentsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeGovernmentRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { updateLienGovernmentIdAndTabName, updateLienTabNamesOnGovernmentSave } from "./existingLiens";
import { Government, GovernmentListItem } from "../../../libs/types/UniversalSurvey/Government/government";
import { SimpleGovernment } from "../../../libs/types/UniversalSurvey/Government/simpleGovernment";
import { Loading } from "../../../libs/resources/enums/loading";

function saveGovernment(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentInfo: Government) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernment, isLoading: true } });
    saveDealGovernment(dealId, String(governmentInfo.id), sanitizeGovernmentRequest(governmentInfo))
        .then(function () {
            getGovernmentInfo(dispatch, dealId, governmentInfo.id);
            updateLienTabNamesOnGovernmentSave(dispatch, dealId, governmentInfo);
            const newListItem: GovernmentListItem = {
                id: governmentInfo.id,
                isRecord: true,
                label: governmentInfo.name,
                name: governmentInfo.name,
                abbr_name: governmentInfo.abbr_name,
                general_address: governmentInfo.general_address
            }
            dispatch({ type: actions.REPLACE_GOVERNMENT_OPTION_WITH_RECORD, payload: { oldGovernmentId: governmentInfo.id, newOption: newListItem, oldIsRecord: true }});
            dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: governmentInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save government record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernment, isLoading: false } });
        })
}

function createGovernmentRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, government: GovernmentListItem, currEntity: number) {
    createDealGovernment(dealId, government.id)
        .then(function (response: any) {
            updateLienGovernmentIdAndTabName(dispatch, response.data.id, government.name, currEntity);
            getGovernmentInfo(dispatch, dealId, response.data.id);
            const newListItem: GovernmentListItem = {
                ...government,
                label: government.name,
                isRecord: true,
                id: response.data.id
            };
            dispatch({ type: actions.REPLACE_GOVERNMENT_OPTION_WITH_RECORD, payload: { oldGovernmentId: government.id, newOption: newListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: newListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government record: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewGovernmentRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentInfo: Government, currEntity: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernment, isLoading: true } });
    createDealGovernment(dealId)
        .then(function (response: any) {
            saveDealGovernment(dealId, String(response.data.id), sanitizeGovernmentRequest(governmentInfo))
                .then(function () {
                    const newListItem: GovernmentListItem = {
                        id: response.data.id,
                        isRecord: true,
                        general_address: governmentInfo.general_address,
                        name: governmentInfo.name,
                        abbr_name: governmentInfo.abbr_name,
                        label: governmentInfo.name
                    };
                    dispatch({ type: actions.ADD_TO_GOVERNMENT_OPTIONS, payload: newListItem });
                    dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: newListItem.id });
                    updateLienGovernmentIdAndTabName(dispatch, response.data.id, governmentInfo.name, currEntity);
                    getGovernmentInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save government record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernment, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveGovernment, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create government record: ${error}`, type: AlertTypes.Error } });
        })
}

function createGovernmentOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedGovernmentId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentList, isLoading: true } });
    getDealGovernments(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizeGovernmentsResponse(dealResponse.data);

            getGlobalGovernments()
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeGovernmentsResponse(globalResponse.data.governments);
                    dispatch({ type: actions.SET_GOVERNMENT_OPTION_LIST, payload: organizeGovernmentList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_GOVERNMENT, payload: selectedGovernmentId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global governments: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GovernmentList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government records: ${error}`, type: AlertTypes.Error } });
        })
}

function getGovernmentInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, governmentRecordId: number) {
    getDealGovernment(dealId, String(governmentRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_GOVERNMENT_INFO,
                payload: sanitizeGovernmentResponse(response.data)
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get government record: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeGovernmentList(dealGovernmentList: SimpleGovernment[], globalGovernmentList: SimpleGovernment[]): GovernmentListItem[] {
    let cleanList: GovernmentListItem[] = [];
    let isRecordList: number[] = [];

    for (const government of dealGovernmentList) {
        if (government.government?.id) {
            isRecordList.push(government.government.id);
        }
        cleanList.push({
            id: government.id,
            name: government.name,
            abbr_name: government.abbr_name,
            general_address: government.general_address,
            isRecord: true,
            label: government.name
        });
    }

    for (const government of globalGovernmentList) {
        if (!isRecordList.includes(government.id)) {
            cleanList.push({
                id: government.id,
                name: government.name,
                abbr_name: government.abbr_name,
                general_address: government.general_address,
                isRecord: false,
                label: government.name
            });
        }
    }

    cleanList.sort((a, b) => {
        if (a.name && b.name) {
            return a.name > b.name ? 1 : -1;
        } else if (a.name) {
            return 1;
        }
        return -1;
    });

    return cleanList;
}

export {
    saveGovernment,
    createGovernmentOptionsList,
    createGovernmentRecordFromGlobal,
    createNewGovernmentRecord,
    getGovernmentInfo
}