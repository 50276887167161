import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    newDealStatDec,
    saveDealStatDec,
    newClientStatDec,
    deleteClientStatDec,
    getDealStatDecs,
    getDealStatDec,
    deleteDealStatDec,
    getGloablStatDecs
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeStatDecRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import {
    sanitizeSimpleStatDecsResponse,
    sanitizeStatDecResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { StatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { SimpleStatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/simpleStatDec";
import { Loading } from "../../../libs/resources/enums/loading";

function addStatDec(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedClients: (SimpleClient & { label: string })[], statDecInfo: StatDec) {    
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: true } });
    newDealStatDec(dealId)
        .then(function (response: any) {
            saveDealStatDec(dealId, String(response.data.id), sanitizeStatDecRequest(statDecInfo))
                .then(function () {
                    dispatch({ type: actions.ADD_DECLARATION, payload: { ...statDecInfo, id: response.data.id, client_list: selectedClients } });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving statutory declaration: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
                })
            if (!statDecInfo.our_lawyer) addStatDecClients(dispatch, dealId, selectedClients, response.data.id);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating statutory declaration: ${error}`, type: AlertTypes.Error } });
        })
}

function updateStatDec(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedClients: (SimpleClient & { label: string })[], statDecInfo: StatDec) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: true } });
    saveDealStatDec(dealId, String(statDecInfo.id), sanitizeStatDecRequest(statDecInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_DECLARATION, payload: { declaration: statDecInfo, clients: selectedClients } });
            updateStatDecClients(dispatch, dealId, statDecInfo.our_lawyer ? [] : selectedClients, statDecInfo);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving statutory declaration: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveStatDec, isLoading: false } });
        })
}

function addStatDecClients(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedClients: (SimpleClient & { label: string })[], statDecId: number) {
    for (const client of selectedClients) {
        newClientStatDec(dealId, String(statDecId), String(client.id))
        .then()
        .catch(function (error: any) {
            if (error.response.data.statusCode !== 409) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${client.label} to statutory declaration: ${error}`, type: AlertTypes.Error } });
            }
        })
    }
}

function updateStatDecClients(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedClients: (SimpleClient & { label: string })[], statDecInfo: StatDec) {
    let existingClientIds: number[] = [];
    let newClientIds: number[] = [];
    if (statDecInfo.client_list) {
        for (const client of statDecInfo.client_list) {
            existingClientIds.push(client.id);
        }
    }
    for (const client of selectedClients) {
        newClientIds.push(client.id);
        if (!existingClientIds.includes(client.id)) {
            newClientStatDec(dealId, String(statDecInfo.id), String(client.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${client.label} to statutory declaration: ${error}`, type: AlertTypes.Error } });
                }
            })
        }
    } 
    for (const clientId of existingClientIds) {
        if (!newClientIds.includes(clientId)) {
            deleteClientStatDec(dealId, String(statDecInfo.id), String(clientId))
            .then()
            .catch(function (error: any) {
                if (error.response.data.message.length > 1 || !error.response.data.message.includes("Client cannot be found in the statutory declaration.")) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete client on statutory declarations: ${error}`, type: AlertTypes.Error } })
                }
            })
        }
    }
}

function getStatDecsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.StatDecList, isLoading: true } });
    getDealStatDecs(dealId)
        .then(function (response: any) {
            const statDecs: SimpleStatDec[] = sanitizeSimpleStatDecsResponse(response.data);
            dispatch({ type: actions.SET_DECLARATIONS_IN_DEAL, payload: statDecs });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal statutory declarations: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.StatDecList, isLoading: false } });
        })
}

function getGlobalStatDecsOptions(dispatch: Dispatch<Record<string, any>>) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GlobalStatDecList, isLoading: true } });
    getGloablStatDecs()
        .then(function (response: any) {
            const statDecs: SimpleStatDec[] = sanitizeSimpleStatDecsResponse(response.data.statutory_declarations);
            dispatch({ type: actions.SET_GLOBAL_DECLARATIONS, payload: statDecs });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global statutory declarations: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.GlobalStatDecList, isLoading: false } });
        })
}

function getStatDecBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, statDecId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.StatDecModal, isLoading: true } });
    getDealStatDec(dealId, String(statDecId))
    .then(function (response: any) {
        dispatch({ type: actions.SET_DECLARATION_BEING_EDITED, payload: sanitizeStatDecResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal statutory declaration: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.StatDecModal, isLoading: false } });
    })
}

function removeStatDec(dispatch: Dispatch<Record<string, any>>, dealId: string, statDecId: number) {
    deleteDealStatDec(dealId, String(statDecId))
    .then(function () {
        dispatch({ type: actions.REMOVE_DECLARATION, payload: statDecId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete deal statutory declaration: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    addStatDec,
    updateStatDec,
    getStatDecsInDeal,
    getStatDecBeingEdited,
    removeStatDec,
    getGlobalStatDecsOptions,
    updateStatDecClients,
    addStatDecClients
}