export const addErrorAC = (payload: string) => ({type: "ADD_ERROR", payload});
export const clearErrorAC = () => ({type: "CLEAR_ERROR"});

export const addSuccessMessageAC = (payload: string) => ({
  type: "ADD_SUCCESS",
  payload,
});
export const clearSuccessAC = () => ({type: "CLEAR_SUCCESS"});
export const addInfoAC = (payload: string) => ({
  type: "ADD_INFO",
  payload,
});
export const clearInfoAC = () => ({type: "CLEAR_INFO"});
const ErrorReducer = (
  state = {value: null, successMessage: null, info: null},
  action: {
    type:
      | "ADD_ERROR"
      | "ADD_SUCCESS"
      | "CLEAR_ERROR"
      | "CLEAR_SUCCESS"
      | "ADD_INFO"
      | "CLEAR_INFO";
    payload?: string;
  },
) => {
  if (action.type === "ADD_ERROR") {
    return {...state, value: action.payload as string};
  } else if (action.type === "ADD_SUCCESS") {
    return {...state, successMessage: action.payload as string};
  } else if (action.type === "ADD_INFO") {
    return {...state, info: action.payload as string};
  } else if (action.type === "CLEAR_ERROR") {
    return {...state, value: null};
  } else if (action.type === "CLEAR_SUCCESS") {
    return {...state, successMessage: null};
  } else if (action.type === "CLEAR_INFO") {
    return {...state, info: null};
  }
  return state;
};

export default ErrorReducer;
