import * as actions from "../actionTypes"

import { OtherChargeHolder, OtherChargeHolderListItem } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { findAndReplace } from "../../../libs/utils/arrays";


export type OtherChargeHoldersState = {
    optionList: OtherChargeHolderListItem[];
    chargeHolderInfo: OtherChargeHolder | undefined;
    selectedChargeHolder: OtherChargeHolderListItem | undefined;
    editingRecord: boolean;
}

export const defaultOtherChargeHoldersState: OtherChargeHoldersState = {
    optionList: [],
    chargeHolderInfo: undefined,
    selectedChargeHolder: undefined,
    editingRecord: false
}

export function otherChargeHoldersReducer(state: OtherChargeHoldersState, action: Record<string, any>): OtherChargeHoldersState {
    switch (action.type) {
        case actions.SET_OTHER_CHARGE_HOLDER_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_OTHER_CHARGE_HOLDER:
            const selectedChargeHolder = state.optionList.find((option) => option.id === action.payload);
            return { ...state, selectedChargeHolder: selectedChargeHolder };
        case actions.REPLACE_OTHER_CHARGE_HOLDER_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id"], [action.payload.oldId], action.payload.newOption) };
        case actions.ADD_TO_OTHER_CHARGE_HOLDER_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_OTHER_CHARGE_HOLDER_RECORD:
            return { ...state, editingRecord: action.payload };
        case actions.SET_OTHER_CHARGE_HOLDER_INFO:
            return { ...state, chargeHolderInfo: action.payload };
        default:
            return state;
    }
}