import { RoleTypesDB } from "../../../../libs/resources/enums/roles";
import { Role } from "../../../../libs/types/UniversalSurvey/Roles/role";


function sortMortgages(mortgages: Role[]): Role[] {
    mortgages.sort((a, b) => {
        const aPosition: number = getMortgagePosition(a.label);
        const bPosition: number = getMortgagePosition(b.label);
        if (aPosition === bPosition) {
            if (a.role === RoleTypesDB.Subject) {
                return -1;
            } else if (a.role === RoleTypesDB.Collateral && b.role !== RoleTypesDB.Subject) {
                return -1;
            }
        } else {
            return aPosition > bPosition ? 1 : -1;
        }
        return 1
    });
    return mortgages;
}

function getMortgagePosition(label: string | undefined): number {
    if(label && !isNaN(parseInt(label[4]))) {
        return parseInt(label[4]);
    }
    return 0;
}

export { sortMortgages }