import { Property } from "../../../types/UniversalSurvey/Property/property";
import { defaultSimpleAddress } from "./defaultSimpleAddress";
import { defaultSimpleFireInsurance } from "./defaultSimpleFireInsurance";

export const defaultProperty: Property = {
    id: -1,
    property_id: -1,
    condo_corporation_id: undefined,
    address: defaultSimpleAddress,
    jurisdiction: undefined,
    has_potl: undefined,
    interest_estate: undefined,
    registration_method: undefined,
    municipality: undefined,
    land_registry_office: undefined,
    roll_number: undefined,
    property_type: undefined,
    number_of_units: undefined,
    lot: undefined,
    plan: undefined,
    level: undefined,
    condo_fee: undefined,
    date_created: undefined,
    date_updated: undefined,
    roles: [],
    property_pin_records: undefined,
    fire_insurance_record: defaultSimpleFireInsurance,
    condo_corporation_record: undefined
}