import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import PhoneField from "../../../../../components/Common/PhoneField/PhoneField";
import { PropertyManagementCompany, PropertyManagementCompanyErrorState } from "../../../../libs/types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompany";
import { defaultPropertyManagementCompany } from "../../../../libs/resources/defaults/frontend/defaultPropertyManagementCompany";
import { createNewPropertyManagementCompanyRecord, savePropertyManagementCompany } from "../../../../context/UniversalSurvey/asyncActions/propertyManagementCompanies";
import { validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultPropertyManagementCompanyErrorState } from "../../../../libs/resources/defaults/errorStates/defaultPropertyManagementCompanyErrorState";

type Props = Omit<ModalProps, "children">;

export default function PropertyManagementCompanyModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [pmcObj, setPmcObj] = useState<PropertyManagementCompany>({ ...defaultPropertyManagementCompany });
    const [originalCopy, setOriginalCopy] = useState<PropertyManagementCompany>({ ...defaultPropertyManagementCompany });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<PropertyManagementCompanyErrorState>({ ...defaultPropertyManagementCompanyErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.propertyManagementCompanies.recordBeingEdited) {
            setPmcObj(state.propertyManagementCompanies.recordBeingEdited);
            setOriginalCopy(state.propertyManagementCompanies.recordBeingEdited);
        }
    }, [state.propertyManagementCompanies.recordBeingEdited]);

    function updatePropertyManagementCompanyInfo<
        K extends keyof PropertyManagementCompany,
        V extends PropertyManagementCompany[K]
    >(key: K, value: V): void {
        const tempPmcObj = { ...pmcObj }
        tempPmcObj[key] = value;
        setPmcObj(tempPmcObj);
    }

    function submit() {
        if (state.propertyManagementCompanies.recordBeingEdited) {
            savePropertyManagementCompany(dispatch, String(state.deal.dealInfo?.id), pmcObj);
        } else {
            createNewPropertyManagementCompanyRecord(dispatch, String(state.deal.dealInfo?.id), pmcObj);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_PROPERTY_MANAGEMENT_COMPANY_BEING_EDITED, payload: undefined });
        setPmcObj({ ...defaultPropertyManagementCompany });
        setOriginalCopy({ ...defaultPropertyManagementCompany });
        setErrorState({ ...defaultPropertyManagementCompanyErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(pmcObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!pmcObj.name || !pmcObj.general_address || _.isEqual(originalCopy, pmcObj) || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, pmcObj]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.propertyManagementCompanies.recordBeingEdited ? "" : "New "}Property Management`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.propertyManagementCompanies.recordBeingEdited ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.PropertyManagementCompanyModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SavePropertyManagementCompany])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{ text: "Name", inputId: "name", isRequired: true }}
                            placeholder="Management's name"
                            value={pmcObj.name ?? undefined}
                            onChange={(e) => updatePropertyManagementCompanyInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Name" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={pmcObj.phone_office}
                            onChange={(value) => updatePropertyManagementCompanyInfo("phone_office", value)}
                            label={{ text: "Office Phone", inputId: "phone_office" }}
                            inputProps={{ "aria-label": "Office Phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone_office",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={pmcObj.phone_contact}
                            onChange={(value) => updatePropertyManagementCompanyInfo("phone_contact", value)}
                            label={{ text: "Contact's Phone", inputId: "phone_contact" }}
                            inputProps={{ "aria-label": "Contact's Phone" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone_contact",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={pmcObj.fax}
                            onChange={(value) => updatePropertyManagementCompanyInfo("fax", value)}
                            label={{ text: "Fax", inputId: "fax" }}
                            inputProps={{ "aria-label": "Fax" }}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "fax",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Email", inputId: "email" }}
                            value={pmcObj.email ?? undefined}
                            onChange={(e) => updatePropertyManagementCompanyInfo("email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "email",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={pmcObj.general_address}
                            setAddress={(value) => updatePropertyManagementCompanyInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                    {pmcObj.property_management_company?.notes && 
                        <Grid item xs={12}>
                            <BasicTextInput
                                fullWidth
                                readOnly
                                multiline
                                label={{ text: "Notes", inputId: "notes" }}
                                value={pmcObj.property_management_company.notes}
                                inputProps={{ "aria-label": "Notes" }}
                            />
                        </Grid>
                    }
                </Grid>
            </>
        </ModalBase>
    );
}