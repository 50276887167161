import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import { StatDec } from "../../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { defaultStatDec } from "../../../../libs/resources/defaults/frontend/defaultStatDec";
import { SimpleClient } from "../../../../libs/types/UniversalSurvey/Client/simpleClient";
import { addStatDec, updateStatDec } from "../../../../context/UniversalSurvey/asyncActions/statutoryDeclarations";
import { getClientTabName, isClientOnlyGuarantor } from "../../../../context/UniversalSurvey/asyncActions/clients";
import { deleteEntityFromOwnModal } from "../../../../context/UniversalSurvey/asyncActions/conditions";
import { ClientType } from "../../../../libs/resources/enums/clientType";
import { defaultSimpleClient } from "../../../../libs/resources/defaults/frontend/defaultSimpleClient";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {};

export default function StatutoryDeclarationModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [statDecObj, setStatDecObj] = useState<StatDec>({ ...defaultStatDec });
    const [originalCopy, setOriginalCopy] = useState<StatDec>({ ...defaultStatDec });
    const [selectedClients, setSelectedClients] = useState<(SimpleClient & { label: string })[]>([]);
    const [originalClients, setOriginalClients] = useState<(SimpleClient & { label: string })[]>([]);
    const [statDecType, setStatDecType] = useState<"joint" | "guarantor" | "our_lawyer" | undefined>(undefined);
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const defaultOurLawyerItem = { ...defaultSimpleClient, label: "Our Lawyer", id: -1, first_name: "", last_name: "", roles: [] };

    useEffect(() => {
        if(state.statutoryDeclarations.statDecBeingEdited) {
            setStatDecObj(state.statutoryDeclarations.statDecBeingEdited);
            setOriginalCopy(state.statutoryDeclarations.statDecBeingEdited);
            const options = getClientComboboxItems();
            let selectedClientsInDeclaration = [];
            let type: "our_lawyer" | "joint" | "guarantor" | undefined = undefined;
            if (state.statutoryDeclarations.statDecBeingEdited.our_lawyer) {
                selectedClientsInDeclaration.push(defaultOurLawyerItem);
                type = "our_lawyer"
            }
            else if (state.statutoryDeclarations.statDecBeingEdited.client_list) {
                for(const client of state.statutoryDeclarations.statDecBeingEdited.client_list) {
                    for(const option of options) {
                        if(client.id === option.id) {
                            selectedClientsInDeclaration.push(option);
                            if (isClientOnlyGuarantor(option)) type = "guarantor";
                            else type = "joint";
                        }
                    }
                }
            }
            setStatDecType(type);
            setOriginalClients(selectedClientsInDeclaration);
            setSelectedClients(selectedClientsInDeclaration);
        }
    }, [state.statutoryDeclarations.statDecBeingEdited]);

    function updateStatDecInfo<
        K extends keyof StatDec,
        V extends StatDec[K]
    >(key: K, value: V): void {
        const tempStatDecObj = { ...statDecObj }
        tempStatDecObj[key] = value;
        setStatDecObj(tempStatDecObj);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_DECLARATION_BEING_EDITED, payload: undefined })
        setStatDecObj({ ...defaultStatDec });
        setOriginalCopy({ ...defaultStatDec });
        setSelectedClients([]);
        setStatDecType(undefined);
    }

    function handleDiscardClick() {
        if (_.isEqual(statDecObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (state.statutoryDeclarations.statDecBeingEdited) {
            updateStatDec(dispatch, String(state.deal.dealInfo?.id), selectedClients, statDecObj);
        } else {
            addStatDec(dispatch, String(state.deal.dealInfo?.id), selectedClients, statDecObj);
        }
    }

    function onDeleteConfirm() {
        const condition = state.conditions.conditionsInDeal.find((condition) => condition.stat_dec?.id === statDecObj.id);
        deleteEntityFromOwnModal(dispatch, String(state.deal.dealInfo?.id), "statDec", statDecObj.id, condition);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (
            _.isEqual(originalCopy, statDecObj) &&
            _.differenceWith(selectedClients, originalClients, _.isEqual).length === 0 &&
            _.differenceWith(originalClients, selectedClients, _.isEqual).length === 0
        ) {
            return true;
        }
        if (selectedClients.length === 0 || !statDecObj.declaration) return true;
        return false;
    }

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            if (client.type === ClientType.Corporation) continue;
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        options.push(defaultOurLawyerItem);
        return options;
    }

    function getDeclarationComboboxItems(): string[] {
        const options: string[] = [];
        for(const declaration of state.statutoryDeclarations.globalStatDecs) {
            options.push(declaration.declaration ? declaration.declaration : "");
        }
        return options;
    }

    function isAllButClientsOptionsDisabled(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        const errorObj = { isDisabled: true, message: "Only clients can make joint declarations" };
        if (isClientOnlyGuarantor(option)) return errorObj;
        if (option.id === -1) {
            return errorObj;
        }
        return { isDisabled: false, message: "" };
    }

    function allOptionsDisabledGuarantor(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        if (selectedClients.find((client: any) => client.id === option.id)) {
            return { isDisabled: false, message: "" };
        }
        return { isDisabled: true, message: "Only one guarantor can make a declaration at once" };
    }

    function allOptionsDisabledOurLawyer(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
            if (selectedClients.find((client: any) => client.id === option.id)) {
            return { isDisabled: false, message: "" };
        }
        return { isDisabled: true, message: "Our lawyer can only make declarations individual" };
    }

    function updateClientList(clientList: (SimpleClient & { label: string })[]) {
        setSelectedClients(clientList);
        if (clientList.length === 0) {
            setStatDecType(undefined);
            updateStatDecInfo("our_lawyer", false);
            return;
        };
        if (clientList.length === 1) {
            if (isClientOnlyGuarantor(clientList[0])) {
                setStatDecType("guarantor");
                return;
            }
            if (clientList[0].id === -1) { 
                setStatDecType("our_lawyer"); 
                updateStatDecInfo("our_lawyer", true); 
                return; 
            }
        }
        setStatDecType("joint");
    }

    function assignDisablingFunction() {
        switch(statDecType) {
            case "joint":
                return isAllButClientsOptionsDisabled;
            case "guarantor":
                return allOptionsDisabledGuarantor;
            case "our_lawyer":
                return allOptionsDisabledOurLawyer;
            default:
                return undefined;
        }
    }

    return (
        <ModalBase
            title={`${state.statutoryDeclarations.statDecBeingEdited ? "" : "New "}Declaration`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.statutoryDeclarations.statDecBeingEdited ? true : false}
            confirmDeleteText="Are you sure you want to delete this declaration, which is linked to a condition? This is permanent and irreversible."
            saveDisabled={isSaveDisabled()}
            discardButtonLabel={`Discard${state.statutoryDeclarations.statDecBeingEdited ? " Changes": ""}`}
            onDelete={onDeleteConfirm}
            size="small"
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.StatDecModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveStatDec])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12} >
                        <ComboBox
                            autoFocus
                            label={{ text: "Declarant(s)", inputId: "statutory-declaration-modal-declarants", isRequired: true }}
                            options={getClientComboboxItems()}
                            tokenField
                            placeholder="Select declarant..."
                            value={selectedClients}
                            isOptionDisabled={assignDisablingFunction()}
                            // @ts-ignore
                            onChangeFn={(value) => updateClientList(value)}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <ComboBox
                            label={{ text: "Declaration", inputId: "statutory-declaration-modal-declaration", isRequired: true }}
                            options={getDeclarationComboboxItems()}
                            placeholder="Declaration"
                            value={statDecObj.declaration ?? { label: "" }}
                            text={String(statDecObj.declaration)}
                            setText={(value) => updateStatDecInfo("declaration", value)}
                            // @ts-ignore
                            onChangeFn={(value) => updateStatDecInfo("declaration", value as string)}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}