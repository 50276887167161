import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setFolderIdAC, clearBreadCrumbsAC} from "redux/reducers/storageReducer";

const ResetStorage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFolderIdAC(null));
    dispatch(clearBreadCrumbsAC());
  }, [dispatch]);
};

export default ResetStorage;
