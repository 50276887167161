import { LenderBranchRequest } from "../../../types/UniversalSurvey/Lender/LenderBranch/lenderBranchRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultLenderBranchRequest: LenderBranchRequest = {
    branch_number: null,
    general_contact_name: null,
    general_address: defaultAddressRequest,
    general_email: null,
    final_report_contact_name: null,
    final_report_address: defaultAddressRequest,
    final_report_email: null,
    address_for_service: defaultAddressRequest,
    address_for_registration: defaultAddressRequest,
    financial_info: defaultFinancialInfoRequest,
    all_regions: null,
    notes: null
}