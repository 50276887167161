import { AffidavitRequest } from "../../../types/UniversalSurvey/Affivadit/affidavitRequest";

export const defaultAffidavitRequest: AffidavitRequest = {
    amount: null,
    cost: null,
    client_id: null,
    debtors: null,
    execution_num: null,
    certificate_num: null,
    plaintiffs: null,
    lawyer_declaration: false
}