import { Dispatch } from "react";

import { AdminActions } from "../context";
import { AdminActionTypes } from "../actionTypes";
import { deleteGlobalLenderContact, getLenderContact, getLenderContacts, newGlobalLenderContact, saveGlobalLenderContact } from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { AdminLoading } from "../../../libs/resources/enums/loading";
import { sanitizeGlobalLenderContactResponse, sanitizeGlobalSimpleLenderContactsResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { GlobalLenderContact } from "../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { sanitizeGlobalLenderContactRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultSimpleLenderBranch } from "../../../libs/resources/defaults/frontend/defaultSimpleLenderBranch";

function getGlobalLenderContacts(dispatch: Dispatch<AdminActions>, lenderId: number, query?: URLSearchParams) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContacts, isLoading: true } });
    getLenderContacts(String(lenderId), query)
        .then(function (response: any) {
            const cleanData = sanitizeGlobalSimpleLenderContactsResponse(response.data.lender_contacts);
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACTS, payload: cleanData });
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_RESULT_COUNT, payload: response.data.metadata.total });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get lender contacts: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContacts, isLoading: false } });
        })
}

function setLenderContactBeingEdited(dispatch: Dispatch<AdminActions>, contactId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContactBeingEdited, isLoading: true } });
    getLenderContact(String(contactId))
        .then(function (response: any) {
            const cleanData = sanitizeGlobalLenderContactResponse(response.data);
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_BEING_EDITED, payload: cleanData });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Get lender contact: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContactBeingEdited, isLoading: false } });
        })
}

function deleteLenderContact(dispatch: Dispatch<AdminActions>, contactId: number) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContacts, isLoading: true } });
    deleteGlobalLenderContact(String(contactId))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Delete lender contact: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.LenderContacts, isLoading: false } });
        })
}

function saveLenderContact(dispatch: Dispatch<AdminActions>, contactInfo: GlobalLenderContact) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLenderContact, isLoading: true } });
    saveGlobalLenderContact(String(contactInfo.id), sanitizeGlobalLenderContactRequest(contactInfo))
        .then(function () {
            dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH, payload: true });
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save lender contact: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLenderContact, isLoading: false } });
        })
}

function submitNewLenderContact(dispatch: Dispatch<AdminActions>, lenderId: number, contactInfo: GlobalLenderContact) {
    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLenderContact, isLoading: true } });
    newGlobalLenderContact(String(lenderId))
        .then(function (response: any) {
            saveGlobalLenderContact(String(response.data.id), sanitizeGlobalLenderContactRequest({ ...contactInfo, lender: { ...defaultSimpleLenderBranch, id: lenderId } }))
                .then(function () {
                    dispatch({ type: AdminActionTypes.SET_LENDER_CONTACT_SHOULD_REFRESH, payload: true });
                    dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Successfully created contact.`, type: AlertTypes.Success } });
                })
                .catch(function (error: any) {
                    dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLenderContact, isLoading: false } });
                    dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Save lender contact during creation: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: AdminActionTypes.SET_ALERT_DATA, payload: { message: `Create lender contact: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: AdminActionTypes.SET_OBJECT_LOADING, payload: { obj: AdminLoading.SaveLenderContact, isLoading: false } });
        })
}

export {
    getGlobalLenderContacts,
    setLenderContactBeingEdited,
    deleteLenderContact,
    saveLenderContact,
    submitNewLenderContact
}