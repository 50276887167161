import { DocumentType } from "conveyance/libs/resources/enums/documents/documentType";
import { TransactionTypes } from "../../../../resources/enums/transactions";
import { Transaction } from "../../Transaction/transaction";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { GuarantorWaiverOfIlaContextRequest } from "../documents/guarantorWaiverOfIlaDocument";


function createGuarantorWaiverOfIlaName(mortgage: Transaction | undefined): string {
    if (!mortgage) return "";
    let name = "Guarantor Waiver of ILA";
    
    if (mortgage.label) {
        name += ` - ${mortgage.label}`;
    }
    return name;
}

function isGuarantorWaiverOfIlaDocInDeal(docList: Document[], mortgage: Transaction): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.GuarantorWaiverOfIla && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.mortgage_id === mortgage.id) return true
    }
    return false;
}

function doesMortgageHaveGuarantors(mortgage: Transaction): boolean {
    return mortgage.guarantor_list !== undefined && mortgage.guarantor_list.length > 0
}

function createGuarantorWaiverOfIlaContextRequest(mortgage: Transaction | undefined): GuarantorWaiverOfIlaContextRequest {
    return { mortgage_id: mortgage ? mortgage.id : -1 };
}

function getGuarantorWaiverOfIlaDocData(transactionsInDeal: Transaction[], contextId: number): [Object, string] {
    const matchingMortgage = transactionsInDeal.find((mortgage) => mortgage.id === contextId && mortgage.transaction_type === TransactionTypes.Mortgage);
    return [createGuarantorWaiverOfIlaContextRequest(matchingMortgage), createGuarantorWaiverOfIlaName(matchingMortgage)];
}

function getGuarantorWaiverOfIlaContextOptions(transactionsInDeal: Transaction[], docList: Document[]) {
    const options = [];
    for (const mortgage of transactionsInDeal.filter((transaction) => transaction.transaction_type === TransactionTypes.Mortgage)) {
        if(!isGuarantorWaiverOfIlaDocInDeal(docList, mortgage) && doesMortgageHaveGuarantors(mortgage)) {
            options.push(mortgage.id);
        }
    }
    return options;
}

export {
    doesMortgageHaveGuarantors,
    getGuarantorWaiverOfIlaDocData,
    getGuarantorWaiverOfIlaContextOptions
}