import { useContext, useEffect, useState } from "react";

import { UniversalSurveyContext } from "../../../context/UniversalSurvey/context";
import NoteCard from "../../../../components/Common/NoteCard/NoteCard";
import { addNote, getNotesInDeal, removeNote, updateNote } from "../../../context/UniversalSurvey/asyncActions/notes";
import { labelDashboardUser } from "../../../libs/utils/labeling/dashboardUser";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import { DateObjectToDateFormat } from "../../../libs/utils/dateHelper";
import { Note } from "../../../libs/types/UniversalSurvey/Note/note";
import { defaultNote } from "../../../libs/resources/defaults/frontend/defaultNote";


export default function NotesContainer() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [noteToBeDeleted, setNoteToBeDeleted] = useState<number | undefined>(undefined);

    useEffect(() => {
        getNotesInDeal(dispatch, String(state.deal.dealInfo?.id));
    }, [])

    function handleDeleteNoteClick(noteId: number) {
        setConfirmDeleteModalActive(true);
        setNoteToBeDeleted(noteId)
    }

    function cancelNoteDelete() {
        setNoteToBeDeleted(undefined);
        setConfirmDeleteModalActive(false);
    }

    function confirmDeleteNote() {
        if (noteToBeDeleted) {
            removeNote(dispatch, String(state.deal.dealInfo?.id), noteToBeDeleted)
            setConfirmDeleteModalActive(false);
        }
    }

    function editNote(note: Note, value: string) {
        const noteCopy = { ...note };
        noteCopy.comment = value;
        noteCopy.date_updated = new Date();
        updateNote(dispatch, String(state.deal.dealInfo?.id), noteCopy)
    }

    return (
        <div style={{ padding: "3rem 4.2rem 3rem 10rem" }}>
            {isObjectLoading(state.dataSheet.objectsLoading, [Loading.Notes]) ?
                <CircularLoader containerHeight="70vh" /> :
                <>
                    <GenericDialog
                        action="destructive"
                        title="Confirm Delete"
                        onCancel={cancelNoteDelete}
                        onSubmit={confirmDeleteNote}
                        submitText="Delete"
                        open={confirmDeleteModalActive}
                        contentText="Are you sure you want to delete this note? This is permanent and irreversible."
                    />
                    {state.notes.notesInDeal.map((note, i) => (
                        <NoteCard
                            data-testid={`note-${i}`}
                            id={String(note.id)}
                            key={`note-${note.id}`}
                            title={labelDashboardUser(note.author)}
                            subtitle={`${DateObjectToDateFormat(note.date_created, true)}${note.date_created?.getTime() !== note.date_updated?.getTime() ? ` (edited ${DateObjectToDateFormat(note.date_updated, true)})` : ""}`}
                            content={note.comment}
                            userIsAuthor={state.user.user && state.user.user.id === note.author?.id}
                            onDelete={() => handleDeleteNoteClick(note.id)}
                            onSave={(value: string) => editNote(note, value)}
                        />
                    ))}
                    <div style={{ paddingTop: "5rem" }}>
                        <NoteCard
                            id="new-note"
                            title="New Note"
                            content=""
                            onSave={(value: string) => addNote(dispatch, String(state.deal.dealInfo?.id), { ...defaultNote, comment: value })}
                            onlyEdit
                        />
                    </div>
                </>
            }
        </div>
    )
}