import {useEffect, useState} from "react";
import {Box, styled, useTheme} from "@mui/material";
import CDListBase from "./components/CDListBase";
import DealsListHeader from "./components/DealsListHeader";
import DeededSearch from "v2/components/DeededSearch";
import resetStorage from "utils/resetStorage";
import DeededButton from "v2/components/DeededButton";
import { useHistory } from "react-router-dom";
import { DashboardUserRole } from "../../libs/resources/enums/dashboardUser";
import storage from "../../../utils/storage";
import { getDashboardUser } from "../../libs/service/axios/api";
import { sanitizeDashboardUserResponse } from "../../libs/types/UniversalSurvey/utils/convertResponse";

const CDList = () => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<DashboardUserRole | undefined>(undefined);
  resetStorage();
  const history = useHistory();

  useEffect(() => {
    const userId = storage.getUserId()
    getDashboardUser(String(userId))
    .then((response) => {
      const cleanUser = sanitizeDashboardUserResponse(response.data);
      setRole(cleanUser.role);
    })
  }, [])

  useEffect(() => {
    if (search === "") {
      setSearchVal(search);
    }
  }, [search]);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  const handleClickSearch = () => {
    setSearchVal(search);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") setSearchVal(search);
  }

  const theme = useTheme();

  return (
    <LeadsPage>
      <DealsListHeader />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            marginBottom: "15px",
            justifyContent: "space-between",
          },
        }}
      >
        <DeededSearch
          onChange={
            handleSearch as unknown as ((val: string) => void) &
              React.FormEventHandler<HTMLDivElement>
          }
          onKeyUp={handleKeyUp}
          placeholder="Search deals..."
          sx={{
            maxWidth: 567,
            width: 380,
            [theme.breakpoints.down(400)]: {
              width: "70%",
              minWidth: "70%",
            },
          }}
          value={search}
        />
        <DeededButton
          sx={{
            width: "75px !important",
            height: "36px",
          }}
          kind="secondary"
          onClick={handleClickSearch}
          className="cancel-appt"
          type="button"
          data-testid="search-button"
        >
          Search
        </DeededButton>
        {(role === "Admin" || role === "System Admin") &&
          <DeededButton
            sx={{
              width: "120px !important",
              height: "36px",
              marginLeft: "auto"
            }}
            kind="secondary"
            onClick={() => history.push(`/dashboard/conveyance/admin`)}
            className="cancel-appt"
            type="button"
            data-testid="admin-button"
          >
            Admin Panel
          </DeededButton>
        }
      </Box>
      <CDListBase
        searchVal={searchVal}
      />
    </LeadsPage>
  );
};

const LeadsPage = styled(Box)(({theme}) => ({
  background: "#F7FAFE",
  minHeight: "calc(100vh - 60px)",
  padding: "23px 22px 17px 22px",
  [theme.breakpoints.down(814)]: {
    padding: "23px 22px 86px 22px",
  },
}));

export default CDList;
