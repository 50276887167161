import { DashboardUser } from "../../../types/UniversalSurvey/DashboardUser/dashboardUser";

export const defaultDashboardUser: DashboardUser = {
    id: -1,
    role: undefined,
    first_name: undefined,
    middle_name: undefined,
    last_name: undefined,
    phone: undefined,
    email: undefined    
}