import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { OtherChargeHolder, OtherChargeHolderErrorState } from "../../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { defaultOtherChargeHolder } from "../../../../libs/resources/defaults/frontend/defaultOtherChargeHolder";
import { createNewOtherChargeHolder, saveOtherChargeHolder } from "../../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import PhoneField, { phoneErrorMessage } from "../../../../../components/Common/PhoneField/PhoneField";
import { emailErrorMessage, validateEmail, validateModalObject } from "../../../../libs/utils/validation";
import AddressGrid from "../AddressGrid/AddressGrid";
import FinancialInfoGrid from "../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultOtherChargeHolderErrorState } from "../../../../libs/resources/defaults/errorStates/defaultOtherChargeHolderErrorState";
import { defaultFinancialInfoErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";

type Props = Omit<ModalProps, "children">;

export default function OtherChargeHolderModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [otherChargeHolderObj, setOtherChargeHolderObj] = useState<OtherChargeHolder>({ ...defaultOtherChargeHolder });
    const [originalCopy, setOriginalCopy] = useState<OtherChargeHolder>({ ...defaultOtherChargeHolder });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<OtherChargeHolderErrorState>({ ...defaultOtherChargeHolderErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.otherChargeHolders.editingRecord && state.otherChargeHolders.chargeHolderInfo) {
            setOtherChargeHolderObj(state.otherChargeHolders.chargeHolderInfo);
            setOriginalCopy(state.otherChargeHolders.chargeHolderInfo);
        } else {
            setOtherChargeHolderObj({ ...defaultOtherChargeHolder });
        }
    }, [state.otherChargeHolders.editingRecord]);

    function updateOtherChargeHolderInfo<
        K extends keyof OtherChargeHolder,
        V extends OtherChargeHolder[K]
    >(key: K, value: V): void {
        const tempOtherChargeHolderObj = { ...otherChargeHolderObj }
        tempOtherChargeHolderObj[key] = value;
        setOtherChargeHolderObj(tempOtherChargeHolderObj);
    }

    function submit() {
        if (state.otherChargeHolders.editingRecord) {
            saveOtherChargeHolder(dispatch, String(state.deal.dealInfo?.id), otherChargeHolderObj);
        } else {
            createNewOtherChargeHolder(dispatch, String(state.deal.dealInfo?.id), otherChargeHolderObj, state.dataSheet.currEntity!);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_OTHER_CHARGE_HOLDER_RECORD, payload: false });
        setOtherChargeHolderObj({ ...defaultOtherChargeHolder });
        setOriginalCopy({ ...defaultOtherChargeHolder });
        setErrorState({ ...defaultOtherChargeHolderErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(otherChargeHolderObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function isSaveDisabled():boolean {
        if (!otherChargeHolderObj.name || _.isEqual(originalCopy, otherChargeHolderObj) || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, otherChargeHolderObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.otherChargeHolders.editingRecord ? "" : "New "}Other Charge Holder`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.otherChargeHolders.editingRecord ? " Changes" : ""}`}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveOtherChargeHolder])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{text: "Charge Holder Name", inputId: "name", isRequired: true}}
                            placeholder="Charge holder's name"
                            value={otherChargeHolderObj.name ?? undefined}
                            onChange={(e) => updateOtherChargeHolderInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Charge Holder Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            moneyField
                            valueType="positive"
                            label={{text: "Amount", inputId: "amount"}}
                            value={otherChargeHolderObj.amount !== undefined ? String(otherChargeHolderObj.amount) : undefined}
                            // @ts-ignore
                            onChange={(e) => updateOtherChargeHolderInfo("amount", e.target.value)}
                            placeholder="0.00"
                            inputProps={{ "aria-label": "Amount" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={otherChargeHolderObj.phone}
                            onChange={(value) => updateOtherChargeHolderInfo("phone", value)}
                            label={{text: "Phone Number", inputId: "phone"}}
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "phone",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Email", inputId: "email"}}
                            value={otherChargeHolderObj.email ?? undefined}
                            onChange={(e) => updateOtherChargeHolderInfo("email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorState: errorState,
                                errorKey: "email",
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={otherChargeHolderObj.general_address}
                            setAddress={(value) => updateOtherChargeHolderInfo("general_address", value)}
                            id="general_address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FinancialInfoGrid
                            financialInfo={otherChargeHolderObj.financial_info}
                            setFinancialInfo={(value) => updateOtherChargeHolderInfo("financial_info", value)}
                            errorState={financialInfoErrorState}
                            setErrorState={setFinancialInfoErrorState}
                            id="financial_info"
                            title="Financial Information"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}