import { SimpleLegalProfessional } from "../../../types/UniversalSurvey/LegalProfessional/simpleLegalProfessional"
import { LegalProfessionalType } from "../../enums/legalProfessionalType"

export const defaultSimpleLegalProfessional: SimpleLegalProfessional = {
    id: -1,
    name: undefined,
    initial: undefined,
    type: LegalProfessionalType.Lawyer,

    legal_professional: undefined,
    legal_firm_record: undefined,
    legal_firm_office_record: undefined
}
