import { UndertakingContext, UndertakingContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/undertakingDocument";
import { AddressedTo } from "../../../../resources/enums/addressedTo";
import { UndertakingDocumentType } from "../../../../resources/enums/documents/undertaking";

export const defaultUndertakingContext: UndertakingContext = {
    addressed_to: AddressedTo.Lender,
    type: UndertakingDocumentType.Mortgage,
    mortgage_id: undefined
}

export const defaultUndertakingContextRequest: UndertakingContextRequest = {
    addressed_to: AddressedTo.Lender,
    type: UndertakingDocumentType.Mortgage,
    mortgage_id: null
}