import { NewMortgage } from "../../../types/UniversalSurvey/NewMortgage/newMortgage";

export const defaultNewMortgage: NewMortgage = {
    id: -1,
    type: undefined,
    collateral_mortgage: undefined,
    loan_number: undefined,
    date_of_commitment: undefined,
    acting_for_lender: undefined,
    lender_id: undefined,
    mortgage_brokerage_id: undefined,
    mortgage_broker_id: undefined,
    priority_after_closing: undefined,
    construction_lien_holdback: undefined,
    registration_number: undefined,
    registration_date: undefined,
    instruction_received: undefined,
    preliminary_report_submitted: undefined,
    final_report_submitted: undefined,
    high_ratio_insured_mortgage: undefined,
    high_ratio_insurance_premium: undefined,
    principal_amount: undefined,
    registered_amount: undefined,
    has_fixed_interest_rate: undefined,
    interest_rate: undefined,
    calculation_period: undefined,
    interest_only: undefined,
    variable_interest_rate: undefined,
    sct_number: undefined,
    insurance_amount: undefined,
    assignment_of_rents: undefined,
    assignment_of_rents_reg_num: undefined,
    assignment_of_rents_reg_date: undefined,
    general_security_agreement: undefined,
    taxes_paid_through_mortgage: undefined,
    regular_payments: undefined,
    alternate_frequency_payments: undefined,
    interest_adjustment_date: undefined,
    first_payment_date: undefined,
    maturity_date: undefined,
    payment_frequency: undefined,
    regular_payment: undefined,
    tax_portion: undefined,
    payment_due: undefined,
    act_for_mortgagee: undefined,
    term_years: undefined,
    amortization_period_years: undefined,
    date_created: undefined,
    date_updated: undefined,

    lender_record: undefined,
    mortgage_brokerage_record: undefined,
    mortgage_broker_record: undefined,
    lender_contact_list: undefined,
    guarantor_list: undefined,
    mortgage_brokerage_other_contact_list: undefined,
    net_advance_amount: []
}