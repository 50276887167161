import { ConditionRequest } from "../../../types/UniversalSurvey/Condition/conditionRequest";

export const defaultConditionRequest: ConditionRequest = {
    source: null,
    type: null,
    holdback: null,
    condition: null,
    condition_met: false,

    lien_id: null,
    debt_id: null,
    affidavit_id: null,
    stat_dec_id: null,
    undertaking_id: null,
    mortgage_id: null
}