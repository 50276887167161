import { IconButton, styled } from "@mui/material";

import color from '../componentStyling/Colors';
import myTheme from '../componentStyling/Theme';
import { BORDER_2, FOCUS_BOX_SHADOW } from "../componentStyling/Styles";
import Theme from "../componentStyling/Theme";

export type IconButtonProps = {
    icon?: JSX.Element;
    onClick?: (e?: any) => void;
    disabled?: boolean;
    id?: string;
    testId?: string;
    className?: string;
};

const BasicIconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {
    const { icon, onClick, disabled, className, id, testId } = props;

    return (
        <div data-testid={testId}>
            <CustomIconButton
                disabled={disabled}
                disableRipple
                id={id}
                onClick={onClick}
                className={className}
            >
                {icon}
            </CustomIconButton>
        </div>
    );
}

const CustomIconButton = styled(IconButton)<{
    disabled?: boolean;
}>(({ disabled }) => ({
    color: color.GRAY_50,
    backgroundColor: disabled ? `${Theme.PLACEHOLDER} !important` : color.GRAY_50,
    border: BORDER_2('transparent'),
    padding: "0.6rem",
    borderRadius: "0.6rem",
    textTransform: "none",

    height: "3.6rem",
    width: "3.6rem",
    whiteSpace: "nowrap",
    '&:hover': {
        backgroundColor: myTheme.HOVER,
    },

    '&:focus': {
        outline: BORDER_2(color.BLUE_500),
        boxShadow: FOCUS_BOX_SHADOW        
    }
}));

export default BasicIconButton;