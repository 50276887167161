import * as actions from "../actionTypes"

import { PropertyManagementCompany, PropertyManagementCompanyListItem } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompany";
import { SimplePropertyManagementCompany } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/simplePropertyManagementCompany";
import { findAndReplace } from "../../../libs/utils/arrays";

export type PropertyManagementCompaniesState = {
    optionList: PropertyManagementCompanyListItem[];
    selectedPMC: PropertyManagementCompanyListItem | undefined;
    recordBeingEdited: PropertyManagementCompany | undefined;
    simplePmcRecords: SimplePropertyManagementCompany[];
}

export const defaultPropertyManagementCompaniesState: PropertyManagementCompaniesState = {
    optionList: [],
    selectedPMC: undefined,
    recordBeingEdited: undefined,
    simplePmcRecords: []
}

export function propertyManagementCompaniesReducer(state: PropertyManagementCompaniesState, action: Record<string, any>): PropertyManagementCompaniesState {
    switch (action.type) {
        case actions.SET_PROPERTY_MANAGEMENT_COMPANY_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY:
            const selectedPMC = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedPMC: selectedPMC };
        case actions.REPLACE_PROPERTY_MANAGEMENT_COMPANY_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_PROPERTY_MANAGEMENT_COMPANY_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_PROPERTY_MANAGEMENT_COMPANY_BEING_EDITED:
            return { ...state, recordBeingEdited: action.payload };
        case actions.SET_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS:
            return { ...state, simplePmcRecords: action.payload };
        case actions.ADD_TO_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS:
            return { ...state, simplePmcRecords: [...state.simplePmcRecords, action.payload] };
        default: 
            return state;
    }
}