import * as actions from "../actionTypes"

import { Lien } from "../../../libs/types/UniversalSurvey/ExistingLien/lien";
import { SimpleLien } from "../../../libs/types/UniversalSurvey/ExistingLien/simpleLien";
import { defaultSimpleLien } from "../../../libs/resources/defaults/frontend/defaultSimpleLien";
import { ChargeHolder } from "../../../libs/types/UniversalSurvey/ExistingLien/chargeHolders";
import { findAndRemove } from "../../../libs/utils/arrays";

export type ExistingLiensState = {
    existingLienInfo: Lien | undefined;
    liensInDeal: SimpleLien[];
    liensBeingSaved: number[];
    isLienSaving: boolean;
    shouldRefreshLien: boolean;
    chargeHolder: ChargeHolder | undefined;
    additionalAmountsBeingSaved: number[];
}

export const defaultExistingLiensState: ExistingLiensState = {
    existingLienInfo: undefined,
    liensInDeal: [],
    liensBeingSaved: [],
    isLienSaving: false,
    shouldRefreshLien: false,
    chargeHolder: undefined,
    additionalAmountsBeingSaved: []
}

export function existingLiensReducer(state: ExistingLiensState, action: Record<string, any>): ExistingLiensState {
    switch (action.type) {
        case actions.SET_LIEN_INFO:
            return { ...state, existingLienInfo: action.payload };
        case actions.SET_LIEN_LENDER_ID:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        lender_id: action.payload
                    }
                };
            }
            return state;
        case actions.SET_LIEN_PRIORITY_AFTER_CLOSING:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        priority_after_closing: action.payload
                    }
                };
            }
            return state;
        case actions.SET_LIEN_PRIORITY_BEFORE_CLOSING:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        priority_before_closing: action.payload
                    }
                };
            }
            return state;
        case actions.SET_LIENS_IN_DEAL:
            return { ...state, liensInDeal: action.payload};
        case actions.ADD_LIEN_IN_DEAL:
            return { ...state, liensInDeal: [...state.liensInDeal, action.payload] };
        case actions.UPDATE_LIEN_IN_DEAL:
            const tempLiens = [...state.liensInDeal];
            const matchingLien = tempLiens.find((lien) => lien.id === action.payload.id);
            if(matchingLien) {
                const newLien: any = { ...defaultSimpleLien };
                for(const key of Object.keys(newLien)) {
                    newLien[key as keyof SimpleLien] = action.payload[key];
                }
                tempLiens[tempLiens.indexOf(matchingLien)] = newLien;
            }
            return { ...state, liensInDeal: tempLiens}
        case actions.SET_LIEN_COMPANY_ID:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        charge_holder_company_id: action.payload
                    }
                };
            }
            return state;
        case actions.SET_LIEN_GOVERNMENT_ID:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        government_id: action.payload
                    }
                };
            }
            return state;
        case actions.SET_LIEN_OTHER_CHARGE_HOLDER_ID:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        other_charge_holder_id: action.payload
                    }
                };
            }
            return state;
        case actions.ADD_LIEN_BEING_SAVED:
            if (!state.liensBeingSaved.includes(action.payload)) {
                return { ...state, liensBeingSaved: [...state.liensBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_LIEN_BEING_SAVED:
            let isLienSaving = state.isLienSaving;
            let shouldRefreshLien = state.shouldRefreshLien;
            if(state.existingLienInfo?.id === action.payload && isLienSaving) {
                isLienSaving = false;
                shouldRefreshLien = true;
            }
            const tempLiensBeingSaved = [...state.liensBeingSaved];
            const index = tempLiensBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempLiensBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                liensBeingSaved: tempLiensBeingSaved,
                isLienSaving: isLienSaving,
                shouldRefreshLien: shouldRefreshLien
            };
        case actions.SET_IS_LIEN_SAVING:
            return { ...state, isLienSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_LIEN:
            return { ...state, shouldRefreshLien: action.payload };
        case actions.SET_CHARGE_HOLDER:
            return { ...state, chargeHolder: action.payload };
        case actions.REMOVE_LIEN_IN_DEAL:
            return { ...state, liensInDeal: findAndRemove([...state.liensInDeal], ["id"], [action.payload]) };
        case actions.SET_LIEN_CONDITION:
            if (state.existingLienInfo) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        condition_record: action.payload
                    }
                };
            }
            return state;
        case actions.DELETE_LIEN_UNDERTAKING_CONDITION:
            if (state.existingLienInfo?.condition_record) {
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        condition_record: {
                            ...state.existingLienInfo.condition_record,
                            undertaking: undefined
                        }
                    }
                };
            }
            return state;

        // Additional Amounts
        case actions.ADD_LIEN_ADDITIONAL_AMOUNT:
            return {
                ...state,
                existingLienInfo: state.existingLienInfo ? {
                    ...state.existingLienInfo,
                    payout_calc_additional_amount: [...state.existingLienInfo.payout_calc_additional_amount, action.payload]
                } : undefined
            }
        case actions.REMOVE_LIEN_ADDITIONAL_AMOUNT:
            if (state.existingLienInfo) {
                const tempAmounts = findAndRemove([...state.existingLienInfo.payout_calc_additional_amount], ["id"], [action.payload]);
                return {
                    ...state,
                    existingLienInfo: {
                        ...state.existingLienInfo,
                        payout_calc_additional_amount: tempAmounts
                    }
                }
            }
            return state;
        case actions.ADD_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED:
            if (!state.additionalAmountsBeingSaved.includes(action.payload)) {
                return { ...state, additionalAmountsBeingSaved: [...state.additionalAmountsBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_LIEN_ADDITIONAL_AMOUNT_BEING_SAVED:
            const tempAmountsBeingSaved = [...state.additionalAmountsBeingSaved];
            const amountIndex = tempAmountsBeingSaved.indexOf(action.payload);
            if (amountIndex >= 0) {
                tempAmountsBeingSaved.splice(amountIndex, 1);
            }
            return { ...state, additionalAmountsBeingSaved: tempAmountsBeingSaved };
        default:
            return state;
    }
}