const constants = {
  /** Needs to be migrated to colors */
  font: "rgb(76, 76, 76)",
  transitions: "all 0.3s ease-out",

  fontConfig_TEMP: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "1",
  },

  colors: {
    title: "#4F4F4F",
    deededGray: "#828282",
    white: "#fff",
    blue500: "#236BD8",
    gray: "#AFAFAF",
    grayLight: "#E3E3E3",
    grayDarker: "#b3b3b3",
    grayTag: "#f0f0f0",
    // Gray
    gray50: "#FAF8F3",
    gray100: "#E1E0DC",
    gray200: "#CAC8C6",
    gray300: "#B2B1AF",
    gray400: "#9B9998",
    gray600: "#6B6A6A",
    gray700: "#535253",
    gray800: "#3B3B3C",
    // Black
    black: "#000",
    black2: "#707070",
    black3: "#0B0A0E",
    //Red
    red: "#F16764",
    red2: "#E8505B",
    red3: "#F8929A",
    red4: "#E65855",
    red5: "#D94946",
    red6: "#D63F3C",
    red7: "#EB5757",
    red8: "#E16F69",
    red500: "#FF5958",
    redSaturated: "#f05a57",
    redVeryPale: "#fdf1f0",
    pinkPale: "#FFD2D1",
    redPale: "#f87b79",
    // Green
    green: "#35CF94",
    green300: "#96FF55",
    green600: "#2D9620",
    green500: "#38B523",
    // Yellow
    yellow100: "#FCF3A8",
    // Orange
    orange500: "#FF4D00",
    bg: "#f7fafe",
    font: "rgb(76, 76, 76)",
    redLight: "#fff4f4",
    descriptionTitle: "#010101",
    grayBackgroundClientInfo: "#FAFAFA",
    accepted: "#D8FEF2",
    acceptedText: "#02CD8F",
    pending: "#FEF6D8",
    pendingText: "#EC7508",
    rejected: "#FEE1D8",
    rejectedText: "#EC2308",
    grayBorder: "#E0E0E0",
    cloudGray: "#575758",
    intakeTitle: "#262626",
    deededSelectBorderCasual: "#d8d8d8",
    configNotificationsTableBorder: "#f2f2f2",
    sortingColor: "#333333",
    theAllChip: "#EAEAEA",
    theBcChip: "#349FF2",
    theOnChip: "#EC9F05",
    othersChips: "#008a00",
    searchColor: "#BDBDBD",
    blue: "#0563C1",
    blue2: "#2F80ED",
    silver: "#e7e7e7",
    disabled: "#F2F6FB",
    silver2: "#F5F5F5",
    placeholder: "#3E4B7A",
    silver3: "#D9D9D9",
    dealListItemNumber: "#7F7F7F",
    disabled2: "#F8F8F8",
    grayCalendarViewSwitch: "#919395",
    disabled3: "#ccc",
    yellowClosingsSum: "#FFC83D",
    pink: "#ffe4e4",
    silver4: "#F7F7F7",
    silver5: "#FBFBFB",
    calendarMobileEventsDate: "#f1f1f1",
    equitableBankBanner: "#f8f7f7",
    equitableBankFromTitle: "#463239",
  },

  provinceColors: {
    AB: "#02CD8F",
    BC: "#009ff9",
    ON: "#deae65",
    ON2: "#FFC83D",
    ON3: "#FB8315",
    NB: "#EC9F05",
    NC: "#60695C",
    NL: "#7B287D",
    NT: "#29339B",
    Other: "#00684c",
  },

  dealTypeColor: {
    Purchase: "#60AF76",
    Sale: "#6184DC",
    Lender: "#74B3CE",
    Mortgage: "#DDAF69",
  },
};

export default constants;
