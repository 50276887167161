import { styled } from "@mui/material/styles";

import theme from '../componentStyling/Theme';
import { Small } from '../Typography/index';

type InputErrorMessageProps = {
    errorMessage?: string;
};

export default function InputErrorMessage(props: InputErrorMessageProps) {
    return (
        <StyledSmall>
            {props.errorMessage}
        </StyledSmall>
    )
}

const StyledSmall = styled(Small)({
    paddingTop: "0.5rem",
    color: theme.ERROR
})