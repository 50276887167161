import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getDealOtherChargeHolders,
    getDealOtherChargeHolder,
    saveDealOtherChargeHolder,
    createDealOtherChargeHolder
} from "../../../libs/service/axios/api";
import { sanitizeOtherChargeHoldersResponse, sanitizeOtherChargeHolderResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeOtherChargeHolderRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { updateLienOtherChargeHolderIdAndTabName, updateLienTabNamesOnOtherChargeHolderSave } from "./existingLiens";
import { sortByKey } from "../../../libs/utils/arrays";
import { SimpleOtherChargeHolder } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/simpleOtherChargeHolder";
import { OtherChargeHolder, OtherChargeHolderListItem } from "../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { Loading } from "../../../libs/resources/enums/loading";

function saveOtherChargeHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, otherChargeHolderInfo: OtherChargeHolder) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveOtherChargeHolder, isLoading: true } });
    saveDealOtherChargeHolder(dealId, String(otherChargeHolderInfo.id), sanitizeOtherChargeHolderRequest(otherChargeHolderInfo))
        .then(function () {
            getOtherChargeHolderInfo(dispatch, dealId, otherChargeHolderInfo.id);
            updateLienTabNamesOnOtherChargeHolderSave(dispatch, dealId, otherChargeHolderInfo);
            const newListItem: OtherChargeHolderListItem = {
                id: otherChargeHolderInfo.id,
                label: otherChargeHolderInfo.name,
                name: otherChargeHolderInfo.name,
                general_address: otherChargeHolderInfo.general_address
            }
            dispatch({ type: actions.REPLACE_OTHER_CHARGE_HOLDER_OPTION_WITH_RECORD, payload: { oldId: otherChargeHolderInfo.id, newOption: newListItem }});
            dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: otherChargeHolderInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save other charge holder record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveOtherChargeHolder, isLoading: false } });
        })
}

function createNewOtherChargeHolder(dispatch: Dispatch<Record<string, any>>, dealId: string, otherChargeHolderInfo: OtherChargeHolder, currEntity: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveOtherChargeHolder, isLoading: true } });
    createDealOtherChargeHolder(dealId)
        .then(function (response: any) {
            saveDealOtherChargeHolder(dealId, String(response.data.id), sanitizeOtherChargeHolderRequest(otherChargeHolderInfo))
                .then(function () {
                    const newListItem: OtherChargeHolderListItem = {
                        id: response.data.id,
                        general_address: otherChargeHolderInfo.general_address,
                        name: otherChargeHolderInfo.name,
                        label: otherChargeHolderInfo.name
                    };
                    dispatch({ type: actions.ADD_TO_OTHER_CHARGE_HOLDER_OPTIONS, payload: newListItem });
                    dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: newListItem.id });
                    updateLienOtherChargeHolderIdAndTabName(dispatch, response.data.id, otherChargeHolderInfo.name, currEntity);
                    getOtherChargeHolderInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save other charge holder record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveOtherChargeHolder, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveOtherChargeHolder, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create other charge holder record: ${error}`, type: AlertTypes.Error } });
        })
}

function createOtherChargeHolderOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedChargeHolderId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.OtherChargeHolderList, isLoading: true } });
    getDealOtherChargeHolders(dealId)
        .then(function (response: any) {
            const cleanData = sanitizeOtherChargeHoldersResponse(response.data);
            dispatch({ type: actions.SET_OTHER_CHARGE_HOLDER_OPTION_LIST, payload: organizeOtherChargeHolderList(cleanData) });
            dispatch({ type: actions.SET_SELECTED_OTHER_CHARGE_HOLDER, payload: selectedChargeHolderId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get other charge holder records: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.OtherChargeHolderList, isLoading: false } });
        })
}

function getOtherChargeHolderInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, otherChargeHolderRecordId: number) {
    getDealOtherChargeHolder(dealId, String(otherChargeHolderRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_OTHER_CHARGE_HOLDER_INFO,
                payload: sanitizeOtherChargeHolderResponse(response.data)
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get other charge holder record: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeOtherChargeHolderList(otherChargeHolderList: SimpleOtherChargeHolder[]): OtherChargeHolderListItem[] {
    let cleanList: OtherChargeHolderListItem[] = [];

    for (const otherChargeHolder of otherChargeHolderList) {
        cleanList.push({
            id: otherChargeHolder.id,
            name: otherChargeHolder.name,
            general_address: otherChargeHolder.general_address,
            label: otherChargeHolder.name
        });
    }

    sortByKey(cleanList, "name");
    return cleanList;
}

export {
    saveOtherChargeHolder,
    createNewOtherChargeHolder,
    createOtherChargeHolderOptionsList,
    getOtherChargeHolderInfo
}