import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack, styled } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { INPUT_SMALL, Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import { PADDED_GRID } from "../../../../../../components/Common/componentStyling/Styles";
import { defaultLegalFirmOffice } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirmOffice";
import { LegalFirmOffice } from "../../../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { labelLegalFirmOffice } from "../../../../../libs/utils/labeling/legalFirmOffice";
import Link from "../../../../../../components/Common/Links/Link";
import { addressToString } from "../../../../../libs/utils/address";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyLegalFirmOfficeModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [officeObj, setOfficeObj] = useState<LegalFirmOffice>({ ...defaultLegalFirmOffice });

    useEffect(() => {
        if(state.legalFirmOffices.recordBeingEdited) {
            setOfficeObj(state.legalFirmOffices.recordBeingEdited);
        }
    }, [state.legalFirmOffices.recordBeingEdited]);

    function discard() {
        dispatch({ type: actions.SET_LEGAL_FIRM_OFFICE_BEING_EDITED, payload: undefined });
        setOfficeObj({ ...defaultLegalFirmOffice });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={labelLegalFirmOffice(officeObj)}
            subtitle="LEGAL FIRM OFFICE"
        >
            <PADDED_GRID container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Address:
                        </INPUT_SMALL>
                        <Large>
                            {addressToString(officeObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Phone Number:
                        </INPUT_SMALL>
                        <Link
                            href={officeObj.phone}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Fax:
                        </INPUT_SMALL>
                        <Link
                            href={officeObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <INPUT_SMALL>
                            Email:
                        </INPUT_SMALL>
                        <Link 
                            href={officeObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
            </PADDED_GRID>
        </ModalBase>
    );
}