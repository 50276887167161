import { Dispatch } from "react";

import * as actions from "../actionTypes"
import { getDashboardUsers, getDeal, updateDeal } from '../../../libs/service/axios/api';
import { sanitizeDashboardUsersResponse, sanitizeDealResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { TabMapping } from "../../../libs/resources/mappings/tabs";
import { TransactionTypes } from "../../../libs/resources/enums/transactions";
import { TransactionMappingCleanToDb } from "../../../libs/resources/mappings/transactions";
import { RouteSectionMapping } from "../../../libs/resources/mappings/sections";
import { sanitizeDealRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { DashboardUserGroup, DashboardUserRole } from "../../../libs/resources/enums/dashboardUser";
import { Loading } from "../../../libs/resources/enums/loading";
import { DashboardUserListItem } from "../../../libs/types/UniversalSurvey/DashboardUser/dashboardUser";
import { labelDashboardUser } from "../../../libs/utils/labeling/dashboardUser";

const PATHNAME = "/dashboard/conveyance/conveyance-deals";

function navigateURL(dispatch: Dispatch<Record<string, any>>, dealId: string, tab: string, section: string | undefined, entityId: string | undefined) {
    const newSection = RouteSectionMapping[section as keyof typeof RouteSectionMapping];
    const path = `${PATHNAME}/${dealId}/${tab}/${newSection ? newSection : ''}${entityId ? `/${entityId}` : ''}`;

    dispatch({ type: actions.SET_PAGE_URL, payload: path });
}

function getDealInfo(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_IS_LOADING, payload: true });

    getDeal(dealId)
    .then(function (response) {
        dispatch({ type: actions.SET_DEAL_INFO, payload: sanitizeDealResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal: ${error}`, type: AlertTypes.Error } });
    })
    .finally(() => {
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
    })
}

function refreshDealInfo(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    getDeal(dealId)
    .then(function (response) {
        dispatch({ type: actions.SET_DEAL_INFO, payload: sanitizeDealResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal: ${error}`, type: AlertTypes.Error } });
    })
}

function handleTabChange(dispatch: Dispatch<Record<string, any>>, tab: string) {
    dispatch({ type: actions.SET_CURR_TAB, payload: TabMapping[tab as keyof typeof TabMapping] });

    // TODO: Add more tab logic as required
}

function updateDealTransactionData(dispatch: Dispatch<Record<string, any>>, dealId: string, transactionId: number, transactionType: TransactionTypes, setPrimary?: boolean) {
    const dealUpdateInfo = { 
        primary_transaction_id: transactionId,
        primary_transaction_type: TransactionMappingCleanToDb[transactionType as keyof typeof TransactionMappingCleanToDb],
    };
    updateDeal(dealId, dealUpdateInfo)
    .then(function () {
        if (setPrimary) {
            dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_id", value: transactionId } });
            dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "primary_transaction_type", value: transactionType } });
        }
        refreshDealInfo(dispatch, dealId)
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating deal: ${error}`, type: AlertTypes.Error } });
    })
}

function updateDealData(dispatch: Dispatch<Record<string, any>>, dealData: Deal) {
    updateDeal(String(dealData.id), sanitizeDealRequest(dealData))
    .then(function () {
        dispatch({ type: actions.SET_DEAL_INFO, payload: dealData });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating deal: ${error}`, type: AlertTypes.Error } });
    })
}

function updateDealPropertyData(dispatch: Dispatch<Record<string, any>>, dealId: string, subjectPropertyId: number | undefined) {
    const dealUpdateInfo = {
        subject_property_id: subjectPropertyId ?? null
    };
    updateDeal(dealId, dealUpdateInfo)
    .then(function () {
        dispatch({ type: actions.SET_DEAL_INFO_FIELD, payload: { key: "subject_property_id", value: subjectPropertyId } });
        refreshDealInfo(dispatch, dealId)
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating deal: ${error}`, type: AlertTypes.Error } });
    })
}

function getClerkOptions(dispatch: Dispatch<Record<string, any>>) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ClerkOptions, isLoading: true } });

    const query: URLSearchParams = new URLSearchParams({ group: DashboardUserGroup.Staff, role: DashboardUserRole.LawClerk });
    getDashboardUsers(query)
    .then(function (response: any) {
        const clerkOptions: DashboardUserListItem[] = []
        const cleanData = sanitizeDashboardUsersResponse(response.data);
        for (const clerk of cleanData) {
            clerkOptions.push({
                ...clerk,
                label: labelDashboardUser(clerk)
            })
        }
        dispatch({ type: actions.SET_CLERK_OPTIONS, payload: clerkOptions });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get law clerks: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ClerkOptions, isLoading: false } });
    })
}

export {
    getDealInfo,
    updateDealTransactionData,
    updateDealPropertyData,
    handleTabChange,
    navigateURL,
    updateDealData,
    refreshDealInfo,
    getClerkOptions
}