import { PropertyManagementCompanyRequest } from "../../../types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompanyRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultPropertyManagementCompanyRequest: PropertyManagementCompanyRequest = {
    name: null,
    contact_name: null,
    general_address: defaultAddressRequest,
    phone_office: null,
    phone_contact: null,
    fax: null,
    email: null
}