import * as actions from "../actionTypes"

import { Client } from "../../../libs/types/UniversalSurvey/Client/client";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { defaultSimplePoaEoeRelationship } from "../../../libs/resources/defaults/frontend/defaultSimplePoaEoeRelationship";
import { findAndRemove, findAndReplace } from "../../../libs/utils/arrays";
import { SimplePoaEoeRelationship } from "../../../libs/types/UniversalSurvey/PoaEoeRelationship/SimplePoaEoeRelationship";
import { SimpleAddress } from "../../../libs/types/UniversalSurvey/Address/simpleAddress";
import { FinancialInfo } from "../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";

export type ClientsState = {
    clientInfo: Client | undefined;
    clientsBeingSaved: number[];
    isClientSaving: boolean;
    shouldRefreshClient: boolean;
    clientsInDeal: SimpleClient[];
    addressToCopy: SimpleAddress | undefined;
    financialInfoToCopy: FinancialInfo | undefined;
}

export const defaultClientsState: ClientsState = {
    clientInfo: undefined,
    clientsBeingSaved: [],
    isClientSaving: false,
    shouldRefreshClient: false,
    clientsInDeal: [],
    addressToCopy: undefined,
    financialInfoToCopy: undefined
}

export function clientsReducer(state: ClientsState, action: Record<string, any>): ClientsState {
    switch (action.type) {
        case actions.SET_CLIENT_INFO:
            return { ...state, clientInfo: action.payload };
        case actions.ADD_CLIENT_BEING_SAVED:
            if (!state.clientsBeingSaved.includes(action.payload)) {
                return { ...state, clientsBeingSaved: [...state.clientsBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_CLIENT_BEING_SAVED:
            let isClientSaving = state.isClientSaving;
            let shouldRefreshClient = state.shouldRefreshClient;
            if(state.clientInfo?.id === action.payload && isClientSaving) {
                isClientSaving = false;
                shouldRefreshClient = true;
            }
            const tempClientsBeingSaved = [...state.clientsBeingSaved];
            const index = tempClientsBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempClientsBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                clientsBeingSaved: tempClientsBeingSaved,
                isClientSaving: isClientSaving,
                shouldRefreshClient: shouldRefreshClient
            };
        case actions.SET_IS_CLIENT_SAVING:
            return { ...state, isClientSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_CLIENT:
            return { ...state, shouldRefreshClient: action.payload };
        case actions.SET_CLIENT_SPOUSAL_RELATIONSHIP:
            return { ...state, clientInfo: state.clientInfo ? { ...state.clientInfo, spousal_relationship: action.payload } : undefined }
        case actions.SET_CLIENTS_IN_DEAL:
            return { ...state, clientsInDeal: action.payload };
        case actions.ADD_CLIENT_IN_DEAL:
            return { ...state, clientsInDeal: [...state.clientsInDeal, action.payload]}
        case actions.UPDATE_CLIENT_IN_DEAL:
            return { ...state, clientsInDeal: findAndReplace([...state.clientsInDeal], ["id"], [action.payload.id], action.payload) };
        case actions.UPDATE_CLIENT_IN_DEAL_ROLES:
            const tempClientsRole = [...state.clientsInDeal];
            const matchingClientRole = tempClientsRole.find((client) => client.id === action.payload.id);
            if (matchingClientRole) {
                tempClientsRole[tempClientsRole.indexOf(matchingClientRole)].roles = action.payload;
            }
            return { ...state, clientsInDeal: tempClientsRole };
        case actions.ADD_CLIENT_POA_EOE_RELATIONSHIP:
            const newRelationship: SimplePoaEoeRelationship = {
                ...defaultSimplePoaEoeRelationship,
                id: action.payload.id,
                representative: state.clientsInDeal.find((client) => client.id === parseInt(action.payload.representativeId)),
                representee: state.clientsInDeal.find((client) => client.id === parseInt(action.payload.representeeId)),
                type: action.payload.type ?? undefined
            }
            // updating clientInfo
            const tempClient = state.clientInfo ? { ...state.clientInfo } : undefined;
            if (tempClient) {
                tempClient.poa_eoe_relationships.push(newRelationship);
            }
            // updating clientsInDeal
            const tempClientsInDealAdd = [...state.clientsInDeal];
            const currClient = tempClientsInDealAdd.find((client) => client.id === state.clientInfo?.id);
            if (currClient) {
                tempClientsInDealAdd[tempClientsInDealAdd.indexOf(currClient)] = { ...currClient, poa_eoe_relationships: currClient.poa_eoe_relationships ? [...currClient.poa_eoe_relationships, newRelationship] : [newRelationship] }
            }
            return { ...state, clientInfo: tempClient, clientsInDeal: tempClientsInDealAdd };
        case actions.UPDATE_CLIENT_POA_EOE_RELATIONSHIP:
            // Updating relationship in client info
            const tempClientUpdate = state.clientInfo ? { ...state.clientInfo } : undefined;
            if (tempClientUpdate) {
                const tempRelationships = [...tempClientUpdate.poa_eoe_relationships];
                const oldRelationship = tempClientUpdate.poa_eoe_relationships.find((relationship) => relationship.id === action.payload.id);
                if (oldRelationship) {
                    tempRelationships[tempRelationships.indexOf(oldRelationship)] = action.payload;
                }
                tempClientUpdate.poa_eoe_relationships = tempRelationships;
            }
            // Updating relationship in clientsInDeal
            const tempClientsInDeal = [...state.clientsInDeal];
            for (let i = 0; i < tempClientsInDeal.length; i++) {
                const tempClientInDeal = { ...tempClientsInDeal[i] };
                if (tempClientInDeal.poa_eoe_relationships) {
                    for (let j = 0; j < tempClientInDeal.poa_eoe_relationships.length; j++) {
                        if (tempClientInDeal.poa_eoe_relationships[j].id === action.payload.id) {
                            tempClientInDeal.poa_eoe_relationships[j] = action.payload;
                            tempClientsInDeal[i] = tempClientInDeal;
                        }
                    }
                }
            }
            return { ...state, clientInfo: tempClientUpdate, clientsInDeal: tempClientsInDeal };
        case actions.DELETE_CLIENT_POA_EOE_RELATIONSHIP:
            // Updating clientInfo
            const tempClientDelete = state.clientInfo ? { ...state.clientInfo } : undefined;
            if (tempClientDelete) {
                const tempRelationships = findAndRemove([...tempClientDelete.poa_eoe_relationships], ["id"], [action.payload]);
                tempClientDelete.poa_eoe_relationships = tempRelationships;
            }
            // Updating clientsInDeal
            const tempClientsInDealDelete = [...state.clientsInDeal];
            for (let i = 0; i < tempClientsInDealDelete.length; i++) {
                const tempClientInDeal = { ...tempClientsInDealDelete[i] };
                if (tempClientInDeal.poa_eoe_relationships) {
                    const relationshipsWithoutDeleted = findAndRemove([...tempClientInDeal.poa_eoe_relationships], ["id"], [action.payload]);
                    tempClientInDeal.poa_eoe_relationships = relationshipsWithoutDeleted;
                    tempClientsInDealDelete[i] = tempClientInDeal;
                }
            }
            return { ...state, clientInfo: tempClientDelete, clientsInDeal: tempClientsInDealDelete };
        case actions.CHANGE_POA_EOE_RELATIONSHIP_REP:
            // Updating clientInfo
            const tempClientChange = state.clientInfo ? { ...state.clientInfo } : undefined;
            if (tempClientChange && tempClientChange?.poa_eoe_relationships) {
                const relToUpdate = tempClientChange.poa_eoe_relationships.find((rel) => rel.id === action.payload.relationship.id);
                if (relToUpdate) {
                    const tempRel = { ...relToUpdate };
                    if (action.payload.repBeingUpdated === "representative") {
                        tempRel.representative = action.payload.newRep;
                    } else {
                        tempRel.representee = action.payload.newRep;
                    }
                    tempClientChange.poa_eoe_relationships[tempClientChange.poa_eoe_relationships.indexOf(relToUpdate)] = tempRel;
                }
            }

            // Updating clientsInDeal
            const tempClientsInDealChange = [...state.clientsInDeal];
            // Removing relationship from old client
            const oldClientInRel = tempClientsInDealChange.find((client) => client.id === action.payload.oldRepId);
            if (oldClientInRel) {
                const tempOldClientInRel = { ...oldClientInRel };
                if (tempOldClientInRel?.poa_eoe_relationships) {
                    tempOldClientInRel.poa_eoe_relationships = findAndRemove([...tempOldClientInRel.poa_eoe_relationships], ["id"], [action.payload.relationship.id]);
                }
                tempClientsInDealChange[tempClientsInDealChange.indexOf(oldClientInRel)] = tempOldClientInRel;
            }
            // Add relationship to new client
            const newClientInRel = tempClientsInDealChange.find((client) => client.id === action.payload.newRep?.id);
            if (newClientInRel) {
                const tempNewClientInRel = { ...newClientInRel };
                tempNewClientInRel.poa_eoe_relationships = tempNewClientInRel.poa_eoe_relationships ? [...tempNewClientInRel.poa_eoe_relationships, action.payload.relationship] : [action.payload.relationship];
                tempClientsInDealChange[tempClientsInDealChange.indexOf(newClientInRel)] = tempNewClientInRel;
            }
            // Update relationship for current client
            const currClientInRel = tempClientsInDealChange.find((client) => client.id === (action.payload.repBeingUpdated === "representative" ? action.payload.relationship.representee.id : action.payload.relationship.representative.id));
            if (currClientInRel) {
                const tempCurrClientInRel = { ...currClientInRel };
                const matchingRel = tempCurrClientInRel.poa_eoe_relationships!.find((rel) => rel.id === action.payload.relationship.id);
                if (matchingRel) {
                    const tempRels = [...tempCurrClientInRel.poa_eoe_relationships!];
                    tempRels[tempCurrClientInRel.poa_eoe_relationships!.indexOf(matchingRel)] = action.payload.relationship;
                    tempCurrClientInRel.poa_eoe_relationships = tempRels;
                }
                tempClientsInDealChange[tempClientsInDealChange.indexOf(currClientInRel)] = tempCurrClientInRel;
            }
            return { ...state, clientInfo: tempClientChange, clientsInDeal: tempClientsInDealChange };
        case actions.UPDATE_POA_EOE_RELATIONSHIP_TYPES:
            // updating clientInfo
            const tempClientUpdateTypes = state.clientInfo ? { ...state.clientInfo } : undefined;
            if (tempClientUpdateTypes && tempClientUpdateTypes?.poa_eoe_relationships) {
                for (const relationship of action.payload.relationships) {
                    const tempRels = [...tempClientUpdateTypes.poa_eoe_relationships];
                    const relToUpdate = tempRels.find((rel) => rel.id === relationship.id);
                    if (relToUpdate) {
                        const tempRel = { ...relToUpdate, type: action.payload.newType };
                        tempRels[tempClientUpdateTypes.poa_eoe_relationships.indexOf(relToUpdate)] = tempRel;
                        tempClientUpdateTypes.poa_eoe_relationships = tempRels;
                    }
                }
            }

            // updating clientsInDeal
            const tempClientsInDealUpdateTypes = [...state.clientsInDeal];
            for (const client of tempClientsInDealUpdateTypes) {
                if (client.poa_eoe_relationships) {
                    for (let i = 0; i < client.poa_eoe_relationships.length; i++) {
                        const matchingRel = action.payload.relationships.find((rel: SimplePoaEoeRelationship) => rel.id === client.poa_eoe_relationships![i].id);
                        if (matchingRel) {
                            client.poa_eoe_relationships[i] = { ...client.poa_eoe_relationships[i], type: action.payload.newType };
                        }
                    }
                }
            }
            return { ...state, clientInfo: tempClientUpdateTypes, clientsInDeal: tempClientsInDealUpdateTypes };
        case actions.SET_CLIENT_ADDRESS_TO_COPY:
            return { ...state, addressToCopy: action.payload };
        case actions.SET_CLIENT_FINANCIAL_INFO_TO_COPY:
            return { ...state, financialInfoToCopy: action.payload };
        default:
            return state;
    }
}