import { SimpleDebt } from "../../../types/UniversalSurvey/Debts/simpleDebt";

export const defaultSimpleDebt: SimpleDebt = {
    id: -1,
    charge_holder_type: undefined,
    amount_payable: undefined,
    action: undefined,
    debt_type: undefined,
    account_number: undefined,

    debt_holder_record: undefined
}