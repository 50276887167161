import { forwardRef } from "react";

import { Box, Stack, styled } from "@mui/material";

import color from "../componentStyling/Colors";
import theme from "../componentStyling/Theme";
import { SMALL_BOLD_TEXT } from "../componentStyling/Styles";
import { CautionIcon, CheckCircle, ErrorIcon, InfoIcon } from "../Icons/Iconography";
import BasicButton from "../Button/BasicButton";
import { SnackbarContent } from "notistack";
import { AlertTypes } from "../../../conveyance/libs/resources/enums/alertTypes";

type AlertProps = {
    variant: AlertTypes;
    primaryAction?: () => void;
    primaryActionText?: string;
    secondaryAction?: () => void;
    secondaryActionText?: string;
    textContent: string;
}

const Alert = forwardRef((props: AlertProps, ref) => {
    
    function displayIcon(type: string) {
        switch (type) {
            case AlertTypes.Error:
                return <ErrorIcon color={color.WHITE} />
            case AlertTypes.Warning:
                return <CautionIcon color={color.BLACK} />
            case AlertTypes.Success:
                return <CheckCircle color={color.BLACK} />
            case AlertTypes.Info:
                return <InfoIcon color={color.BLACK} />
        }
    }
    
    return (
        //@ts-ignore
        <SnackbarContent ref={ref}>
            <CustomAlert variant={props.variant} data-testid={`${props.variant}-alert`}>
                <Stack direction='row' gap={1} alignItems='center' justifyContent='space-between' width="100%">
                    <Box>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <div>{displayIcon(props.variant)}</div>
                            <div>{props.textContent}</div>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="column" gap={1}>
                            {props.primaryAction && (
                                <BasicButton
                                    typeOf="primary"
                                    label={{text: props.primaryActionText ?? "Action", inputId: "primary-action-btn"}}
                                    size="small"
                                    onClick={props.primaryAction}
                                />
                            )}
                            {props.secondaryAction && (
                                <BasicButton
                                    typeOf="secondary"
                                    label={{text: props.secondaryActionText ?? "Action", inputId: "secondary-action-btn"}}
                                    size="small"
                                    onClick={props.secondaryAction}
                                />
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </CustomAlert>
        </SnackbarContent>
    )
})

export default Alert;

const CustomAlert = styled('div')<{
    variant: AlertTypes;
}>(({ variant }) => ({
    width: "48rem",
    minHeight: "6rem",
    
    borderRadius: "0.6rem",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15), 0px 5px 20px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem",

    backgroundColor: variant === AlertTypes.Error ? theme.ERROR :
        variant === AlertTypes.Warning ? color.YELLOW_500 :
        variant === AlertTypes.Success ? color.GREEN_500 :
        variant === AlertTypes.Info ? color.BLUE_100 :
        color.BLUE_100,

    color: variant === AlertTypes.Error ? color.WHITE : color.BLACK,

    ...SMALL_BOLD_TEXT,
}));