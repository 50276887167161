import { TemplateRequest } from "../../../types/UniversalSurvey/Documents/templates/baseTemplateRequest";
import { DocumentType } from "../../enums/documents/documentType";
import { defaultStatDecContext } from "./contexts/defaultStatDecContext";

export const defaultTemplateRequest: TemplateRequest = {
    parent_id: null,
    type: DocumentType.StatDec,
    name: null,

    context: defaultStatDecContext
}