import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { OtherChargeHolder } from "../../../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { defaultOtherChargeHolder } from "../../../../../libs/resources/defaults/frontend/defaultOtherChargeHolder";
import { FormatNumeric } from "../../../../../../components/Common/TextField/MoneyFormat";
import { addressToString } from "../../../../../libs/utils/address";
import FinancialInfoReadonlyGrid from "../../FinancialInfoGrid/FinancialInfoReadonly";

type Props = Omit<ModalProps, "children"> & {};

export default function ReadonlyOtherChargeHolderModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [otherObj, setOtherObj] = useState<OtherChargeHolder>({ ...defaultOtherChargeHolder });

    useEffect(() => {
        if(state.otherChargeHolders.editingRecord && state.otherChargeHolders.chargeHolderInfo) {
            setOtherObj(state.otherChargeHolders.chargeHolderInfo);
        }
    }, [state.otherChargeHolders.editingRecord]);

    function discard() {
        dispatch({ type: actions.SET_EDITING_OTHER_CHARGE_HOLDER_RECORD, payload: false });
        setOtherObj({ ...defaultOtherChargeHolder });
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size={props.size}
            title={otherObj.name}
            subtitle="OTHER CHARGE HOLDER"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Amount:
                        </Small>
                        <Large>
                            <FormatNumeric value={otherObj.amount ?? 0} />
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Phone:
                        </Small>
                        <Link
                            href={otherObj.phone}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Address:
                        </Small>
                        <Large style={{ whiteSpace: "pre-line" }}>
                            {addressToString(otherObj.general_address)}
                        </Large>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Email:
                        </Small>
                        <Link
                            href={otherObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoReadonlyGrid
                        financialInfo={otherObj.financial_info}
                        title="Financial Information"
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}