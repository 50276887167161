import * as actions from "../actionTypes"

import { TabOptions } from "../../../libs/types/UniversalSurvey/Frontend/tabOption";
import { Status } from "../../../libs/types/UniversalSurvey/Frontend/status";
import { Sections } from "../../../libs/resources/enums/sections";
import { Loading } from "../../../libs/resources/enums/loading";
import { findAndReplace } from "../../../libs/utils/arrays";


export type DataSheetState = {
    objectsLoading: Loading[];
    tabOptions: TabOptions;
    currEntity: number | undefined;
    currSection: Sections | undefined;
    currSectionStatus: Status | undefined;
}

export const defaultDataSheetState: DataSheetState = {
    objectsLoading: [],
    tabOptions: [],
    currEntity: undefined,
    currSection: undefined,
    currSectionStatus: undefined
}

export function dataSheetReducer(state: DataSheetState, action: Record<string, any>): DataSheetState {
    switch (action.type) {
        case actions.SET_OBJECT_LOADING:
            let tempLoading = [...state.objectsLoading];
            if (action.payload.isLoading && !tempLoading.includes(action.payload.obj)) {
                tempLoading.push(action.payload.obj);
            } else if (!action.payload.isLoading) {
                tempLoading = tempLoading.filter((obj) => obj !== action.payload.obj);
            }
            return { ...state, objectsLoading: tempLoading };
        case actions.SET_CURR_SECTION:
            return { ...state, currSection: action.payload as Sections };
        case actions.SET_CURR_SECTION_STATUS:
            return { ...state, currSectionStatus: action.payload };
        case actions.SET_TAB_OPTIONS:
            return { ...state, tabOptions: action.payload };
        case actions.ADD_TAB_OPTION:
            return { ...state, tabOptions: state.tabOptions ? [...state.tabOptions, action.payload] : [action.payload] };
        case actions.REMOVE_TAB_OPTION:
            const tempTabsRemove = [...state.tabOptions];
            let currEntity = state.currEntity
            const tabToRemove = tempTabsRemove.find((tab) => tab.itemId === action.payload);
            if (tabToRemove) {
                const index = tempTabsRemove.indexOf(tabToRemove);
                tempTabsRemove.splice(index, 1);
                if (state.currEntity === tabToRemove.itemId && tempTabsRemove.length > 0) {
                    currEntity = tempTabsRemove[0].itemId;
                }
            }
            return { ...state, tabOptions: tempTabsRemove, currEntity: currEntity };
        case actions.UPDATE_TAB_OPTION:
            return { ...state, tabOptions: findAndReplace([...state.tabOptions], ["itemId"], [action.payload.itemId], action.payload) };
        case actions.UPDATE_TAB_OPTION_SUBTITLE:
            const tempTabsUpdate = [...state.tabOptions];
            const matchingTabToUpdate = tempTabsUpdate.find((tab) => tab.itemId === action.payload.itemId);
            if (matchingTabToUpdate) {
                const index = tempTabsUpdate.indexOf(matchingTabToUpdate);
                tempTabsUpdate[index].subTitle = action.payload.subtitle
            }
            return { ...state, tabOptions: tempTabsUpdate };
        case actions.UPDATE_TAB_OPTION_PRIORITY:
            const tempTabsPriority = [...state.tabOptions];
            const matchingPriorityTab = tempTabsPriority.find((tab) => tab.itemId === action.payload.itemId);
            if (matchingPriorityTab) {
                const index = tempTabsPriority.indexOf(matchingPriorityTab);
                tempTabsPriority[index].priority = action.payload.priority
            }
            return { ...state, tabOptions: tempTabsPriority };
        case actions.UPDATE_TAB_OPTION_TITLE:
            const tempTabsNoSub = [...state.tabOptions];
            const matchingTabNoSub = tempTabsNoSub.find((tab) => tab.itemId === action.payload.itemId);
            if (matchingTabNoSub) {
                const index = tempTabsNoSub.indexOf(matchingTabNoSub);
                const newItem = action.payload;
                tempTabsNoSub[index] = { ...matchingTabNoSub, title: newItem.title, missingFields: newItem.missingFields }
            }
            return { ...state, tabOptions: tempTabsNoSub };
        case actions.SET_CURR_ENTITY:
            return { ...state, currEntity: action.payload };
        default:
            return state;
    }
}