import React from "react";
import {TextFieldProps} from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

import theme from '../componentStyling/Theme';
import { BORDER_2 } from "../componentStyling/Styles";
import { MoneyFormat } from "../TextField/MoneyFormat";
import ComboBox from "../ComboBox/ComboBox";
import BasicTextInput from "../TextField/BasicTextInput";
import { XCircle } from "../Icons/Iconography";
import Colors from "../componentStyling/Colors";

type LineItemValue = {
  value?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  id?: string;
  size: number;
  valueType?: "positive" | "negative" | "either";
  isMoneyField?: boolean;
  isEmpty?: boolean;
  readOnly?: boolean;
}

type DeededInputProps = Omit<TextFieldProps, "error" | "variant" | "size"> & {
  textValue?: string;
  textOnChange?: (e: any) => void;
  onChangeFn?: (value: any) => void;
  valueItems: LineItemValue[];
  label?: { text: string, inputId: string };
  size: number;
  fontColor?: string;
  placeholder?: string;
  readOnly?: boolean;
  autocomplete?: boolean;
  options?: string[] | Object[];
  onRemove?: () => void;
  indent?: boolean;
  hideUnderline?: boolean;
};

const LineItemInput = React.forwardRef<HTMLDivElement, DeededInputProps>(
  function BasicLineInput(props, ref) {
    const {
      textValue,
      textOnChange,
      onChangeFn,
      valueItems,
      size,
      label,
      placeholder,
      readOnly,
      autocomplete,
      options,
      fontColor,
      onRemove,
      indent,
      hideUnderline,
      ...other
    } = props;

    function castToNegative(value: string) {
      return Number(value) < 0 ? value : String(Number(value) * -1);
    }

    function getInputProps(
      moneyField: boolean | undefined,
      readOnlyItem: boolean | undefined,
      valueType: "positive" | "negative" | "either" | undefined
    ) {
      let inputProps: any = {
        style: { textAlign: "right", color: fontColor ? fontColor : "" },
        readOnly: readOnly || readOnlyItem
      }
      if (moneyField !== false) {
        inputProps.dollarPrefix = true;
        inputProps.allowNegative = valueType === "positive" ? false : true;
      }
      return inputProps;
    }
    
    return (
      <LineItemContainer hideUnderline={hideUnderline}>
        <LineGridContainer container rowSpacing={0} columnSpacing={4} alignItems={"flex-end"}>
          <Grid item xs={size - 1}>
            {autocomplete ? (
              <ComboBox
                multiline
                options={options ?? []}
                placeholder={placeholder}
                value={textValue}
                onChangeFn={onChangeFn}
                setText={textOnChange}
                id={label?.inputId}
                size="small"
                isLineItem
              />
            ) : (
              <BasicTextInput
                {...other}
                fullWidth
                multiline
                ref={ref}
                placeholder={placeholder}
                value={textValue ?? ""}
                readOnly={readOnly}
                onChange={textOnChange}
                id={label?.inputId}
                inputProps={{
                  readOnly: readOnly,
                  style: { color: fontColor ? fontColor : "" }
                }}
                size={indent ? "extra-small" : "small"}
                isLineItem
                style={{ paddingLeft: indent ? "4rem" : undefined }}
                hideUnderline={hideUnderline}
              />
            )}
          </Grid>
          {valueItems.map((item, index) => (
            !item.isEmpty ? (
              <Grid item xs={item.size} key={index}>
                <BasicTextInput
                  {...other}
                  ref={ref}
                  fullWidth
                  value={item.value ? item.valueType === "negative" ? castToNegative(item.value) : item.value : ""}
                  placeholder={item.placeholder ? item.placeholder : "$0.00"}
                  InputProps={{
                    inputComponent: item.isMoneyField !== false ? MoneyFormat as any : undefined,
                    inputProps: getInputProps(item.isMoneyField, item.readOnly, item.valueType)
                  }}
                  readOnly={readOnly || item.readOnly}
                  onChange={item.onChange}
                  id={item.id}
                  size={indent ? "extra-small" : "small"}
                  isLineItem
                  hideUnderline={hideUnderline}
                />
              </Grid>
            ) : (
              <Grid item xs={item.size} key={index} />
            )
          ))}
          <Grid item xs={1}>
            {onRemove && 
              <RemoveDiv onClick={onRemove}>
                <XCircle color={Colors.BLACK}/>
              </RemoveDiv>
            }
          </Grid>
        </LineGridContainer>
      </LineItemContainer>
    )
  }
);

const RemoveDiv = styled('div')({
  cursor: "pointer",
  height: "4.7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

const LineItemContainer = styled('div', { shouldForwardProp: (prop) => (
  prop !== "hideUnderline"
)})<{
  hideUnderline?: boolean;
}>(({ hideUnderline }) => ({
  borderBottom: hideUnderline ? "none" : BORDER_2(theme.DISABLED)
}));

const LineGridContainer = styled(Grid)({
  marginBottom: "-0.4rem"
})

export default LineItemInput;