// First put the "name" of the context object. Then _ and an identifier so that the value are unique
export enum DocContextType {
    StatDec = "Statutory Declaration_StatDec",
    GuarantorWaiverOfIla = "Mortgage_GuarantorWaiverOfIla",
    GuarantorGuaranteeOfMortgage = "Mortgage_GuarantorGuaranteeOfMortgage",
    ConsentToActConflictOfMortgage = "Mortgage_ConsentToActConflictOfMortgage",
    Form9C = "Recipient_Form9C",
    LetterToMortgageeNewMortgage = "Mortgage_LetterToMortgageeNewMortgage",
    LetterToMortgageeExistingMortgage = "Mortgage_LetterToMortgageeExistingMortgage",
    Ledger = "Ledger_Ledger",
    StatementOfAccount = "StatementOfAccount_StatementOfAccount",
    LetterToClient = "Content Type_LetterToClient",
    Undertaking = "Source_Undertaking",
    VerificationOfIdentityAgreement = "Signing Appointment_VerificationOfIdentityAgreement",
    GeneralAssignmentOfRents = "General Assignment of Rents_GeneralAssignmentOfRents",
    MortgagePackage = "Mortgage_MortgagePackage",
    GeneralSecurityAgreement = "Mortgage_GeneralSecurityAgreement",
    DirectionReTitle = "Title Transfer_DirectionReTitle",
    DraThreeOrMore = "DraThreeOrMore_DraThreeOrMore",
    DraTwoParties = "DraTwoParties_DraTwoParties"
}