import { useState, useContext, useEffect } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

import { Grid } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { TransactionOptions } from "../../../../libs/resources/options";
import { TransactionTypes } from "../../../../libs/resources/enums/transactions";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import { addMortgageTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/mortgageTransactions';
import { addIlaTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/ilaTransactions';
import { addTitleTransferTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/titleTransferTransactions';
import { changeTransaction } from '../../../../context/UniversalSurvey/asyncActions/transactions/transactions';
import { updateDealTransactionData } from "../../../../context/UniversalSurvey/asyncActions/deal";

type Props = Omit<ModalProps, "children">;

export default function TransactionModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [transactionType, setTransactionType] = useState<TransactionTypes | undefined>(undefined);
    const [overwritePrimary, setOverwritePrimary] = useState<boolean>(false);
    const [confirmChangeOpen, setConfirmChangeOpen] = useState<boolean>(false);

    useEffect(() => {
        if (state.transactions.transactionBeingEdited) {
            setTransactionType(state.transactions.transactionBeingEdited.transaction_type);
        }
    }, [state.transactions.transactionBeingEdited])

    function isTransactionBeingEditedPrimary() {
        return state.transactions.transactionBeingEdited?.id === state.deal.dealInfo?.primary_transaction_id && state.transactions.transactionBeingEdited?.transaction_type === state.deal.dealInfo?.primary_transaction_type;
    }

    function handleSubmitClick() {
        if (overwritePrimary) {
            setConfirmChangeOpen(true);
        } else {
            submit();
        }
    }

    function submit() {
        if (state.transactions.transactionBeingEdited?.transaction_type === transactionType && transactionType) {
            updateDealTransactionData(dispatch, String(state.deal.dealInfo?.id), state.transactions.transactionBeingEdited!.id, transactionType, true)
        }
        else if (state.transactions.transactionBeingEdited && transactionType) {
            changeTransaction(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.transactions.transactionBeingEdited,
                transactionType,
                state.dataSheet.currSection,
                state.dataSheet.currEntity!,
                state.dataSheet.tabOptions,
                state.deal.dealInfo!,
                overwritePrimary
            );
        } else {
            handleAdd();
        }
        onDiscard();
    }

    function handleAdd() {
        switch (transactionType) {
            case TransactionTypes.Mortgage:
                addMortgageTransaction(dispatch, String(state.deal.dealInfo?.id), state.dataSheet.currSection, state.transactions.transactionList.length, overwritePrimary);
                return;
            case TransactionTypes.ILA:
                addIlaTransaction(dispatch, String(state.deal.dealInfo?.id), state.transactions.transactionList.length, overwritePrimary);
                return;
            case TransactionTypes.TitleTransfer:
                addTitleTransferTransaction(dispatch, String(state.deal.dealInfo?.id), state.transactions.transactionList.length, overwritePrimary);
                return;
            default:
                return;
        }
    }

    function onDiscard() {
        setTransactionType(undefined);
        setOverwritePrimary(false);
        setConfirmChangeOpen(false);
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if(!transactionType) {
            return true;
        }
        if(isTransactionBeingEditedPrimary() && !overwritePrimary) {
            return true;
        }
        if (overwritePrimary && !isTransactionBeingEditedPrimary()) {
            return false;
        }
        if (state.transactions.transactionBeingEdited && transactionType === state.transactions.transactionBeingEdited.transaction_type) {
            return true;
        }
        return false;
    }

    return (
        <ModalBase 
            open={props.open}
            onClose={onDiscard}
            onSubmit={handleSubmitClick}
            size={props.size}
            title={`${(state.transactions.transactionBeingEdited ? "" : "New ")}Transaction`}
            saveDisabled={isSaveDisabled()}
            discardButtonLabel={`Discard${(state.transactions.transactionBeingEdited ? " Changes" : "")}`}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Change"
                    onCancel={() => setConfirmChangeOpen(false)}
                    onSubmit={submit}
                    submitText="Change"
                    open={confirmChangeOpen}
                    contentText="Are you sure you want to change the primary transaction type? This will affect all requirements and outputs for this deal."
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Dropdown
                            label={{ text: "Transaction Type", isRequired: true }}
                            autoFocus
                            onChange={(e) => setTransactionType(e.target.value as TransactionTypes)}
                            value={transactionType}
                            placeholder="Select type..."
                            options={TransactionOptions}
                            isHoverActionHidden={transactionType ? false : true}
                            handleClear={() => setTransactionType(undefined)}
                        />
                    </Grid>
                    {state.transactions.transactionList.length > 0 &&
                        <Grid item xs={12}>
                            <BooleanControl
                                checked={overwritePrimary}
                                onChange={() => setOverwritePrimary(!overwritePrimary)}
                                label={{ text: "Overwrite Current Primary", inputId: "overwrite_primary" }}
                            />
                        </Grid>
                    }
                </Grid>
            </>
        </ModalBase>
    );
}