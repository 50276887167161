import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getDealPropertyManagementCompany,
    newDealPropertyManagementCompany,
    getDealPropertyManagementCompanies,
    getGlobalPropertyManagementCompanies,
    saveDealPropertyManagementCompany
} from "../../../libs/service/axios/api";
import { sanitizePropertyManagementCompaniesResponse, sanitizePropertyManagementCompanyResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizePropertyManagementCompanyRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { PropertyManagementCompany, PropertyManagementCompanyListItem } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompany";
import { SimplePropertyManagementCompany } from "../../../libs/types/UniversalSurvey/PropertyManagementCompany/simplePropertyManagementCompany";
import { Loading } from "../../../libs/resources/enums/loading";

function savePropertyManagementCompany(dispatch: Dispatch<Record<string, any>>, dealId: string, pmcInfo: PropertyManagementCompany) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SavePropertyManagementCompany, isLoading: true } });
    saveDealPropertyManagementCompany(dealId, String(pmcInfo.id), sanitizePropertyManagementCompanyRequest(pmcInfo))
        .then(function () {
            const newListItem: PropertyManagementCompanyListItem = {
                id: pmcInfo.id,
                isRecord: true,
                label: pmcInfo.name,
                name: pmcInfo.name,
            }
            dispatch({ type: actions.REPLACE_PROPERTY_MANAGEMENT_COMPANY_OPTION_WITH_RECORD, payload: { oldId: pmcInfo.id, newOption: newListItem, oldIsRecord: true } });
            dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: pmcInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save property management company record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SavePropertyManagementCompany, isLoading: false } });
        })
}

function createPropertyManagementCompanyRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, pmc: PropertyManagementCompanyListItem) {
    newDealPropertyManagementCompany(dealId, { property_management_company_id: pmc.id })
        .then(function (response: any) {
            const newListItem: PropertyManagementCompanyListItem = {
                ...pmc,
                label: pmc.name,
                isRecord: true,
                id: response.data.id
            };
            const newSimplePmcRecord: SimplePropertyManagementCompany = {
                id: response.data.id,
                name: pmc.name,
                property_management_company: {
                    id: pmc.id,
                    name: pmc.name
                }
            };
            dispatch({ type: actions.ADD_TO_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS, payload: newSimplePmcRecord });
            dispatch({ type: actions.REPLACE_PROPERTY_MANAGEMENT_COMPANY_OPTION_WITH_RECORD, payload: { oldId: pmc.id, newOption: newListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: newListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create property management company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewPropertyManagementCompanyRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, pmcInfo: PropertyManagementCompany) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SavePropertyManagementCompany, isLoading: true } });
    newDealPropertyManagementCompany(dealId)
        .then(function (response: any) {
            saveDealPropertyManagementCompany(dealId, String(response.data.id), sanitizePropertyManagementCompanyRequest(pmcInfo))
                .then(function () {
                    const newListItem: PropertyManagementCompanyListItem = {
                        id: response.data.id,
                        isRecord: true,
                        name: pmcInfo.name,
                        label: pmcInfo.name
                    };
                    dispatch({ type: actions.ADD_TO_PROPERTY_MANAGEMENT_COMPANY_OPTIONS, payload: newListItem });
                    dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: newListItem.id });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save property management company record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SavePropertyManagementCompany, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SavePropertyManagementCompany, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create property management company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createPropertyManagementCompanyOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedPmcId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.PropertyManagementCompanyList, isLoading: true } });
    getDealPropertyManagementCompanies(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizePropertyManagementCompaniesResponse(dealResponse.data);
            dispatch({ type: actions.SET_SIMPLE_PROPERTY_MANAGEMENT_COMPANY_RECORDS, payload: cleanDealData });

            getGlobalPropertyManagementCompanies()
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizePropertyManagementCompaniesResponse(globalResponse.data.property_management_companies);
                    dispatch({ type: actions.SET_PROPERTY_MANAGEMENT_COMPANY_OPTION_LIST, payload: organizePmcList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_PROPERTY_MANAGEMENT_COMPANY, payload: selectedPmcId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global property management companies: ${error}`, type: AlertTypes.Error } });
                })
                .finally(() => {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.PropertyManagementCompanyList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.PropertyManagementCompanyList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get property management company records: ${error}`, type: AlertTypes.Error } });
        })
}

function organizePmcList(dealPmcList: SimplePropertyManagementCompany[], globalPmcList: SimplePropertyManagementCompany[]): PropertyManagementCompanyListItem[] {
    let cleanList: PropertyManagementCompanyListItem[] = [];
    let isRecordList: number[] = [];

    for (const pmc of dealPmcList) {
        if (pmc.property_management_company?.id) {
            isRecordList.push(pmc.property_management_company.id);
        }
        cleanList.push({
            id: pmc.id,
            name: pmc.name,
            isRecord: true,
            label: pmc.name
        });
    }

    for (const pmc of globalPmcList) {
        if (!isRecordList.includes(pmc.id)) {
            cleanList.push({
                id: pmc.id,
                name: pmc.name,
                isRecord: false,
                label: pmc.name
            });
        }
    }

    cleanList.sort((a, b) => {
        if (a.name && b.name) {
            return a.name > b.name ? 1 : -1;
        } else if (a.name) {
            return 1;
        }
        return -1;
    });

    return cleanList;
}

function setPropertyManagementCompanyBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, pmcRecordId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.PropertyManagementCompanyModal, isLoading: true } });
    getDealPropertyManagementCompany(dealId, String(pmcRecordId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_PROPERTY_MANAGEMENT_COMPANY_BEING_EDITED, payload: sanitizePropertyManagementCompanyResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get property management company record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.PropertyManagementCompanyModal, isLoading: false } });
        })
}

export {
    savePropertyManagementCompany,
    createPropertyManagementCompanyOptionsList,
    createPropertyManagementCompanyRecordFromGlobal,
    createNewPropertyManagementCompanyRecord,
    setPropertyManagementCompanyBeingEdited
}