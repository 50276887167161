import { TransactionTypeDB, TransactionTypes } from "../enums/transactions";

export const TransactionMappingPills = {
    [TransactionTypes.Mortgage]: "MTG",
    [TransactionTypes.ILA]: "ILA",
    [TransactionTypes.TitleTransfer]: "Title Transfer",
    [TransactionTypes.Deal]: "Deal"
};

export const TransactionMappingDbToClean = {
    [TransactionTypeDB.Mortgage]: TransactionTypes.Mortgage,
    [TransactionTypeDB.ILA]: TransactionTypes.ILA,
    [TransactionTypeDB.TitleTransfer]: TransactionTypes.TitleTransfer,
    [TransactionTypeDB.Deal]: TransactionTypes.Deal
};

export const TransactionMappingCleanToDb = {
    [TransactionTypes.Mortgage]: TransactionTypeDB.Mortgage,
    [TransactionTypes.ILA]: TransactionTypeDB.ILA,
    [TransactionTypes.TitleTransfer]: TransactionTypeDB.TitleTransfer,
    [TransactionTypes.Deal]: TransactionTypeDB.Deal
};