import * as actions from "../actionTypes"

import { ChargeHolderCompany, ChargeHolderCompanyListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { findAndReplace } from "../../../libs/utils/arrays";


export type ChargeHolderCompaniesState = {
    optionList: ChargeHolderCompanyListItem[];
    companyInfo: ChargeHolderCompany | undefined;
    selectedCompany: ChargeHolderCompanyListItem | undefined;
    editingCompanyRecord: boolean;
}

export const defaultChargeHolderCompaniesState: ChargeHolderCompaniesState = {
    optionList: [],
    companyInfo: undefined,
    selectedCompany: undefined,
    editingCompanyRecord: false
}

export function chargeHolderCompaniesReducer(state: ChargeHolderCompaniesState, action: Record<string, any>): ChargeHolderCompaniesState {
    switch (action.type) {
        case actions.SET_CHARGE_HOLDER_COMPANY_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_CHARGE_HOLDER_COMPANY:
            const selectedCompany = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedCompany: selectedCompany };
        case actions.REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldCompanyId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_CHARGE_HOLDER_COMPANY_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD:
            return { ...state, editingCompanyRecord: action.payload };
        case actions.SET_CHARGE_HOLDER_COMPANY_INFO:
            return { ...state, companyInfo: action.payload };
        default:
            return state;
    }
}