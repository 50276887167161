import * as actions from "../actionTypes"

import { MortgageBroker, MortgageBrokerListItem } from "../../../libs/types/UniversalSurvey/MortgageBroker/mortgageBroker";
import { findAndReplace } from "../../../libs/utils/arrays";

export type MortgageBrokerState = {
    brokerInfo: MortgageBroker | undefined;
    brokerOptionList: MortgageBrokerListItem[];
    selectedBroker: MortgageBrokerListItem | undefined;
    editingMortgageBrokerRecord: boolean;
}

export const defaultMortgageBrokerState: MortgageBrokerState = {
    brokerInfo: undefined,
    brokerOptionList: [],
    selectedBroker: undefined,
    editingMortgageBrokerRecord: false
}

export function mortgageBrokerReducer(state: MortgageBrokerState, action: Record<string, any>): MortgageBrokerState {
    switch (action.type) {
        case actions.SET_MORTGAGE_BROKERS_OPTION_LIST:
            return { ...state, brokerOptionList: action.payload };
        case actions.SET_SELECTED_MORTGAGE_BROKER:
            const selectedBroker = state.brokerOptionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedBroker: selectedBroker };
        case actions.SET_MORTGAGE_BROKER_INFO:
            return { ...state, brokerInfo: action.payload };
        case actions.REPLACE_MORTGAGE_BROKER_OPTION_WITH_RECORD:
            return { ...state, brokerOptionList: findAndReplace([...state.brokerOptionList], ["id", "isRecord"], [action.payload.oldBrokerId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_EDITING_MORTGAGE_BROKER_RECORD:
            return { ...state, editingMortgageBrokerRecord: action.payload };
        case actions.ADD_TO_MORTGAGE_BROKER_OPTIONS:
            return { ...state, brokerOptionList: [...state.brokerOptionList, action.payload] };
        default:
            return state;
    }
}