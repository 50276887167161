export enum PoaEoeRelationshipType {
    POA = "poa",
    EOE = "eoe",
    dummy = ""
}

export enum PoaEoeRelationshipActing {
    Solely = "solely",
    Jointly = "jointly",
    Severally = "severally"
}