import { useState, useContext, useEffect } from "react";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { PoaEoeRelationshipType } from "../../../../libs/resources/enums/poaEoeRelationships";
import { BasicRepresentative } from "../../../../libs/types/UniversalSurvey/Client/basicRepresentative";
import { PoaEoeRelationshipTypeOptions } from "../../../../libs/resources/options";
import { formatPoaEoeType } from "../../../../libs/utils/formatValues";
import { createNewRepresentative } from "../../../../context/UniversalSurvey/asyncActions/poaEoeRelationships";
import { SimplePoaEoeRelationship } from "../../../../libs/types/UniversalSurvey/PoaEoeRelationship/SimplePoaEoeRelationship";
import { validateModalObject } from "../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {
    currClientId: number;
    relationship: SimplePoaEoeRelationship;
};

export default function BasicRepresentativeModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [representativeType, setRepresentativeType] = useState<PoaEoeRelationshipType | undefined>(undefined);
    const [errorState, setErrorState] = useState<{ email?: boolean }>({ email: false });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    function submit() {
        let relationshipsToUpdate: SimplePoaEoeRelationship[];
        if (representativeType) {
            relationshipsToUpdate = [...state.clients.clientInfo?.poa_eoe_relationships!];
        } else {
            setRepresentativeType(state.clients.clientInfo?.poa_eoe_relationships[0].type);
            relationshipsToUpdate = [props.relationship];
        }

        const representative: BasicRepresentative = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            represtativeType: representativeType
        }
        createNewRepresentative(dispatch, String(state.deal.dealInfo?.id), props.relationship, relationshipsToUpdate, representative);
    }

    function handleDiscard() {
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setRepresentativeType(undefined);
        setErrorState({ email: false });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (
            !firstName ||
            !lastName ||
            !email ||
            (!representativeType && state.clients.clientInfo?.poa_eoe_relationships[0].type === undefined) ||
            validateModalObject(errorState)
        ) return true
        return false
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, firstName, lastName, email]);

    return (
        <ModalBase
            open={props.open}
            onClose={handleDiscard}
            onSubmit={submit}
            size="small"
            title={(!state.clients.clientInfo?.poa_eoe_relationships.length || !state.clients.clientInfo.poa_eoe_relationships[0].type) ? "New Client Representative" : state.clients.clientInfo?.poa_eoe_relationships[0].type === PoaEoeRelationshipType.POA ? "New Power of Attorney" : "New Executor of Estate"}
            saveDisabled={saveDisabled}
            closeAfterSaving={handleDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveRepresentative])}
        >
            <>
                <Grid container spacing={3}>
                    {state.clients.clientInfo?.poa_eoe_relationships.length && !state.clients.clientInfo.poa_eoe_relationships[0].type &&
                        <Grid item xs={12}>
                            <Dropdown
                                value={representativeType}
                                autoFocus
                                onChange={(e) => setRepresentativeType(e.target.value as PoaEoeRelationshipType)}
                                placeholder="Select type..."
                                options={PoaEoeRelationshipTypeOptions}
                                label={{ text: "Representative Type", isRequired: true }}
                                formatValue={formatPoaEoeType}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "First Name", inputId: "representative_first_name", isRequired: true }}
                            placeholder="Representative's first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Last Name", inputId: "representative_last_name", isRequired: true }}
                            placeholder="Representative's last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            label={{ text: "Email", inputId: "representative_email", isRequired: true }}
                            placeholder="Representative's email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorKey: "email",
                                errorState: errorState,
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}