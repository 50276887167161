import { useState, useContext } from 'react';
import { styled } from '@mui/material';
import TransactionPill from './TransactionPill';
import _ from "lodash";
import RoleModal from '../Modals/RoleModal';
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import { EntityTypeForRoles } from '../../../../libs/resources/enums/roles';
import { Role } from '../../../../libs/types/UniversalSurvey/Roles/role';
import { TransactionTypes } from '../../../../libs/resources/enums/transactions';
import { TransactionMappingDbToClean } from '../../../../libs/resources/mappings/transactions';
import GenericDialog from '../../../../../components/Common/Modal/GenericDialog';
import { removeRole } from '../../../../context/UniversalSurvey/asyncActions/roles/roles';
import { PILL_CONTAINER } from '../../../../../components/Common/componentStyling/Styles';

type DisplayRolePillsProps = {
    addRolePill?: boolean;
    setAddRoleModalOpen?: (arg: boolean) => void;
    entityId: number;
    entityLabel: string;
    entityType: EntityTypeForRoles;
}

export default function DisplayRolePills(props: DisplayRolePillsProps) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [roleToRemove, setRoleToRemove] = useState<Role | null>(null);
    const [roleToEdit, setRoleToEdit] = useState<Role | undefined>(undefined);

    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

    const [roleModalOpen, setRoleModalOpen] = useState<boolean>(false);

    function handleDeleteClick(role: Role) {
        setRoleToRemove(role);
        setRemoveModalOpen(true);
    }

    function handleDeleteCancel() {
        setRoleToRemove(null);
        setRemoveModalOpen(false);
    }

    function handleDeleteConfirm() {
        if (roleToRemove) {
            removeRole(dispatch, String(state.deal.dealInfo?.id), state.roles.roles, roleToRemove, props.entityId);
            setRoleToRemove(null);
        }
        setRemoveModalOpen(false);
    }

    function handleEditClick(role: Role) {
        setRoleToEdit(role);
        setRoleModalOpen(true);
    }

    function handleModalCancel() {
        setRoleModalOpen(false);
        setRoleToEdit(undefined);
    }

    return (
        <>
            <GenericDialog
                action="neutral"
                title="Confirm Removal"
                onCancel={() => handleDeleteCancel()}
                onSubmit={() => handleDeleteConfirm()}
                open={removeModalOpen}
                contentText="Are you sure you want to remove this role?"
            />
            <RoleModal
                title={roleToEdit ? "Role" : "New Role"}
                open={roleModalOpen}
                entityLabel={props.entityLabel}
                onClose={handleModalCancel}
                entityId={props.entityId}
                entityType={props.entityType}
                roleToEdit={roleToEdit}
            />

            <PillContainer>
                {state.roles?.roles?.map((role, i) => (
                    <TransactionPill
                        key={i}
                        type={TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean]}
                        label={role.label}
                        handleDelete={() => handleDeleteClick(role)}
                        handleEdit={() => handleEditClick(role)}
                        isPrimary={
                            role.record_id === state.deal.dealInfo?.primary_transaction_id &&
                            TransactionMappingDbToClean[role.record_type as unknown as keyof typeof TransactionMappingDbToClean] === state.deal.dealInfo?.primary_transaction_type
                        }
                        isRolePill
                        testId="current-transaction-and-role-pill"
                    />
                ))}
                {props.addRolePill &&
                    <TransactionPill
                        type={TransactionTypes.AddTransaction}
                        label="Add Role"
                        isAddPill
                        handleAdd={() => { setRoleModalOpen(true) }}
                        testId="add-role-pill"
                    />
                }
            </PillContainer>
        </>
    );
}

const PillContainer = styled('div')({
    ...PILL_CONTAINER,
    //@ts-ignore
    paddingBottom: "4.4rem",
    paddingTop: "3rem"
});