import { FireInsuranceBrokerage, FireInsuranceBrokerageListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import * as actions from "../actionTypes";
import { findAndReplace } from "../../../libs/utils/arrays";

export type FireInsuranceBrokeragesState = {
    optionList: FireInsuranceBrokerageListItem[];
    brokerageInfo: FireInsuranceBrokerage | undefined;
    selectedBrokerage: FireInsuranceBrokerageListItem | undefined;
    editingRecord: boolean;
}

export const defaultFireInsuranceBrokeragesState: FireInsuranceBrokeragesState = {
    optionList: [],
    brokerageInfo: undefined,
    selectedBrokerage: undefined,
    editingRecord: false
}

export function fireInsuranceBrokeragesReducer(state: FireInsuranceBrokeragesState, action: Record<string, any>): FireInsuranceBrokeragesState {
    switch (action.type) {
        case actions.SET_FIRE_INSURANCE_BROKERAGE_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE:
            const selectedBrokerage = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedBrokerage: selectedBrokerage };
        case actions.REPLACE_FIRE_INSURANCE_BROKERAGE_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldBrokerageId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_FIRE_INSURANCE_BROKERAGE_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_FIRE_INSURANCE_BROKERAGE_RECORD:
            return { ...state, editingRecord: action.payload };
        case actions.SET_FIRE_INSURANCE_BROKERAGE_INFO:
            return { ...state, brokerageInfo: action.payload };
        default:
            return state;
    }
}