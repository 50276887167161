import { OtherChargeHolder } from "../../../types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultOtherChargeHolder: OtherChargeHolder = {
    id: -1,
    name: undefined,
    general_address: defaultSimpleAddress,
    amount: undefined,
    phone: undefined,
    email: undefined,
    financial_info: defaultFinancialInfo,
    date_created: undefined,
    date_updated: undefined
}