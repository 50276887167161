// Importing built-in React/Router tools
import { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Importing state management
import { AdminContext } from '../../context/AdminPanel/context';
import { AdminActionTypes } from '../../context/AdminPanel/actionTypes';

// Importing application components
import Alert from '../../../components/Common/Alerts/Alerts';
import { DCP_PAGE, DCP_PAGE_CONTAINER } from 'components/Common/componentStyling/Styles';
import { H1 } from '../../../components/Common/Typography';
import AdminGridControl from '../UniversalSurvey/Components/Grid/AdminGridControl';
import { handleAdminSectionChange } from 'conveyance/context/AdminPanel/asyncActions/navigation';
import AdminSectionBase from './Sections/AdminSectionBase';
import { getRegionList } from 'conveyance/context/AdminPanel/asyncActions/regions';

export default function AdminPanelBase() {
    const [state, dispatch] = useContext(AdminContext);
    const history = useHistory();
    const { section } = useParams<{ section: string | undefined }>();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        handleAdminSectionChange(dispatch, section);
    }, [section]);

    useEffect(() => {
        if (state.navigation.url) {
            history.push(state.navigation.url);
        }
    }, [state.navigation.url]);

    useEffect(() => {
        if(state.alerts.message) {
            enqueueSnackbar(state.alerts.message, {
                content: (key: any, message: string) => (
                    <Alert
                        variant={state.alerts.type}
                        textContent={message}
                        primaryAction={state.alerts.primaryAction}
                        primaryActionText={state.alerts.primaryText}
                        secondaryAction={state.alerts.secondaryAction}
                        secondaryActionText={state.alerts.secondaryText}
                    />
                ),
            })
        }
    }, [state.alerts])

    useEffect(() => {
        getRegionList(dispatch);

        return () => dispatch({ type: AdminActionTypes.RESET_ALERT_DATA });
    }, [])

    return (
        <DCP_PAGE>
            <DCP_PAGE_CONTAINER>
                <H1 data-testid="admin-title">DCP ADMIN PANEL</H1>
                <AdminGridControl />
                <AdminSectionBase />
            </DCP_PAGE_CONTAINER>
        </DCP_PAGE>
    );
}