import { useEffect, useState, useContext } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes"
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import * as _ from "lodash";
import { LegalFirmOffice, LegalFirmOfficeErrorState, LegalFirmOfficeListItem } from "../../../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { defaultLegalFirmOffice } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirmOffice";
import { labelLegalFirmOfficeItem } from "../../../../../libs/utils/labeling/legalFirmOffice";
import { saveLegalFirmOfficeRecord, submitNewLegalFirmOfficeRecord } from "../../../../../context/UniversalSurvey/asyncActions/legalFirmOffices";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { LegalProfessionalOptions } from "../../../../../libs/resources/enums/legalProfessionalOptions";
import { defaultLegalFirmOfficeErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLegalFirmOfficeErrorState";
import LegalFirmOfficeForm from "./LegalFirmOfficeForm";

type Props = Omit<ModalProps, "children"> & {
    type: LegalProfessionalOptions;
};

export default function LegalFirmOfficeModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [officeObj, setOfficeObj] = useState<LegalFirmOffice>({ ...defaultLegalFirmOffice });
    const [originalObj, setOriginalObj] = useState<LegalFirmOffice>({ ...defaultLegalFirmOffice });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<LegalFirmOfficeErrorState>({ ...defaultLegalFirmOfficeErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (state.legalFirmOffices.recordBeingEdited) {
            setOfficeObj(state.legalFirmOffices.recordBeingEdited);
            setOriginalObj(state.legalFirmOffices.recordBeingEdited);
        }
    }, [state.legalFirmOffices.recordBeingEdited]);

    function submit() {
        const newListItem: LegalFirmOfficeListItem = labelLegalFirmOfficeItem({ 
            ...officeObj, 
            isRecord: true,
            legal_firm_record: props.type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record! : props.type === LegalProfessionalOptions.DealLawyer ? state.deal.dealInfo?.lawyer_record?.legal_firm_record! : state.lenders.lenderInfo?.lawyer?.legal_firm_record!,
            legal_firm_office: state.legalFirmOffices.recordBeingEdited ? state.legalFirmOffices.recordBeingEdited.legal_firm_office : undefined
        });
        if (state.legalFirmOffices.recordBeingEdited) {
            saveLegalFirmOfficeRecord(dispatch, String(state.deal.dealInfo?.id), props.type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record?.id! : props.type === LegalProfessionalOptions.DealLawyer ? state.deal.dealInfo?.lawyer_record?.legal_firm_record?.id! : state.lenders.lenderInfo?.lawyer?.legal_firm_record?.id!, officeObj, props.type);
            dispatch({ type: actions.REPLACE_LEGAL_FIRM_OFFICE_OPTION_WITH_RECORD, payload: { oldId: officeObj.id, newOption: newListItem, oldIsRecord: true }});
        } else {
            submitNewLegalFirmOfficeRecord(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.deal.dealInfo?.lawyer_record?.id,
                state.deal.dealInfo?.signer_record?.id,
                state.lenders.lenderInfo?.lawyer?.id,
                props.type === LegalProfessionalOptions.DealSigner ? state.deal.dealInfo?.signer_record?.legal_firm_record! : props.type === LegalProfessionalOptions.DealLawyer ? state.deal.dealInfo?.lawyer_record?.legal_firm_record! : state.lenders.lenderInfo?.lawyer?.legal_firm_record!,
                officeObj,
                props.type
            );
        }
    }

    function discard() {
        dispatch({ type: actions.SET_LEGAL_FIRM_OFFICE_BEING_EDITED, payload: undefined });
        setOfficeObj({ ...defaultLegalFirmOffice });
        setOriginalObj({ ...defaultLegalFirmOffice });
        setErrorState({ ...defaultLegalFirmOfficeErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(officeObj, originalObj)) {
            discard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function isSaveDisabled() {
        if (_.isEqual(officeObj, originalObj)) return true;
        if (!officeObj.general_address.street_address || !officeObj.general_address.city || !officeObj.general_address.province_enum || !officeObj.general_address.postal_code) return true;
        if (validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, officeObj]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            size={props.size}
            title={`${state.legalFirmOffices.recordBeingEdited ? "" : "New "}Law Firm Office`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.legalFirmOffices.recordBeingEdited ? " Changes" : ""}`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LegalFirmOfficeModal])}
            closeAfterSaving={discard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLegalFirmOffice])}
        >
            <LegalFirmOfficeForm
                officeObj={officeObj}
                setOfficeObj={setOfficeObj}
                errorState={errorState}
                setErrorState={setErrorState}
                discard={discard}
                confirmDiscardOpen={confirmDiscardOpen}
                setConfirmDiscardOpen={setConfirmDiscardOpen}
            />
        </ModalBase>
    );
}