import { useState, useEffect } from "react";

// Importing application components
import BasicTextInput from "../TextField/BasicTextInput";
import { Body } from "../Typography";
import { styled } from "@mui/material/styles";
import { BORDER_2, FOCUS_BOX_SHADOW } from "../componentStyling/Styles";
import color from '../componentStyling/Colors';
import useSingleDoubleClick from "../../../conveyance/libs/hooks/useSingleDoubleClick";

type EditableFieldProps = {
    value: string | undefined;
    placeholder?: string;
    onClick?: () => void;
    onSave?: (newValue: string) => void;
    initialEditing?: boolean;
    defaultName?: string;
}

export default function EditableField(props: EditableFieldProps) {
    const [editing, setEditing] = useState<boolean>(props.initialEditing ?? false);
    const [editingValue, setEditingValue] = useState<string | undefined>(undefined);
    const [doubleClickTimerToggle, setDoubleClickTimerToggle] = useState<boolean>(false);
    
    useEffect(() => {
        setEditingValue(props.value);
    }, [props.value])

    useSingleDoubleClick(doubleClickTimerToggle, props.onClick, () => setEditing(true))

    function handleEnter(e: React.KeyboardEvent<HTMLSpanElement>) {
        if (e.key === "Enter") setDoubleClickTimerToggle(!doubleClickTimerToggle);
    }

    function handleSave() {
        setEditing(false);
        if (editingValue) {
            props.onSave ? props.onSave(editingValue) : undefined;
        } else {
            setEditingValue(props.value);
            if (props.defaultName && props.onSave && !props.value) {
                props.onSave(props.defaultName);
                setEditingValue(props.defaultName);
            }
        }
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {editing ?
                <BasicTextInput
                    fullWidth
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                    placeholder={props.placeholder}
                    size="extra-small"
                    onBlur={handleSave}
                    autoFocus
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            handleSave();
                        }
                    }}
                /> :
                <StyledBody onClick={() => setDoubleClickTimerToggle(!doubleClickTimerToggle)} tabIndex={0} onKeyUp={handleEnter}>
                    {props.value}
                </StyledBody>
            }
        </div>
    )
}

const StyledBody = styled(Body)({
    "&:focus": {
        appearance: "none",
        border: BORDER_2(color.BLUE_500),
        boxShadow: FOCUS_BOX_SHADOW,
        borderRadius: "0.6rem",
        outline: "none"
    }
})