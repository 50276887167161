// Importing material components
import { Grid, Stack } from "@mui/material";

// Importing application components
import { Body, Large, Small } from "../../../../../components/Common/Typography";
import { FinancialInfo } from "../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import Theme from "../../../../../components/Common/componentStyling/Theme";
import { addressToString } from "../../../../libs/utils/address";
import Link from "../../../../../components/Common/Links/Link";

type FinancialInfoReadonlyProps = {
    financialInfo: FinancialInfo;
    title?: string;
}

export default function FinancialInfoReadonlyGrid(props: FinancialInfoReadonlyProps) {
    const { financialInfo, title } = props;

    return (
        <Grid container spacing={2}>
            {title && <Grid item xs={12}><Body>{title}</Body></Grid>}
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Institution Name:
                    </Small>
                    <Large>
                        {financialInfo.name}
                    </Large>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Institution Number:
                    </Small>
                    <Large>
                        {financialInfo.institution_number}
                    </Large>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Transit Number:
                    </Small>
                    <Large>
                        {financialInfo.transit_number}
                    </Large>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Account Number:
                    </Small>
                    <Large>
                        {financialInfo.account_number}
                    </Large>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Fax for Payout/Info:
                    </Small>
                    <Link
                        href={financialInfo.fax_for_payout_info}
                        type="fax"
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Email for Payout/Info:
                    </Small>
                    <Link
                        href={financialInfo.email_for_payout_info}
                        type="email"
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Portal for Payout/Info:
                    </Small>
                    <Link
                        href={financialInfo.portal_for_payout_info}
                        type="url"
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" gap={1}>
                    <Small style={{ color: Theme.INPUT }}>
                        Branch Address:
                    </Small>
                    <Large style={{whiteSpace: "pre-line"}}>
                        {addressToString(financialInfo.branch_address)}
                    </Large>
                </Stack>
            </Grid>
        </Grid>
    )
};