import * as actions from "../actionTypes"

import { LegalFirm, LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";
import { findAndReplace } from "../../../libs/utils/arrays";

export type LegalFirmsState = {
    lawyerFirmOptionList: LegalFirmListItem[];
    signerFirmOptionList: LegalFirmListItem[];
    lenderLawyerFirmOptionList: LegalFirmListItem[];
    recordBeingEdited: LegalFirm | undefined;
    allFirmsOptionList: LegalFirmListItem[];
}

export const defaultLegalFirmsState: LegalFirmsState = {
    lawyerFirmOptionList: [],
    signerFirmOptionList: [],
    lenderLawyerFirmOptionList: [],
    recordBeingEdited: undefined,
    allFirmsOptionList: []
}

export function legalFirmsReducer(state: LegalFirmsState, action: Record<string, any>): LegalFirmsState {
    switch (action.type) {
        case actions.SET_LAWYER_FIRM_OPTION_LIST:
            return { ...state, lawyerFirmOptionList: action.payload };
        case actions.SET_SIGNER_FIRM_OPTION_LIST:
            return { ...state, signerFirmOptionList: action.payload };
        case actions.SET_LENDER_LAWYER_FIRM_OPTION_LIST:
            return { ...state, lenderLawyerFirmOptionList: action.payload };
        case actions.REPLACE_LEGAL_FIRM_OPTION_WITH_RECORD:
            // need to check all lists in case the same global firm is in multiple lists
            return {
                ...state,
                lawyerFirmOptionList: findAndReplace([...state.lawyerFirmOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption),
                signerFirmOptionList: findAndReplace([...state.signerFirmOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption),
                lenderLawyerFirmOptionList: findAndReplace([...state.lenderLawyerFirmOptionList], ["id", "isRecord"], [action.payload.oldId, action.payload.oldIsRecord], action.payload.newOption)
            }
        case actions.SET_LEGAL_FIRM_BEING_EDITED:
            return { ...state, recordBeingEdited: action.payload };
        case actions.ADD_TO_PROFESSIONAL_LEGAL_FIRM_OPTIONS:
            const tempLawyer = [...state.lawyerFirmOptionList];
            const tempSigner = [...state.signerFirmOptionList];
            const tempLenderLawyer = [...state.lenderLawyerFirmOptionList];
            if (action.payload.type === LegalProfessionalOptions.LenderLawyer) {
                tempLenderLawyer.push(action.payload.record);
            } else {
                if (action.payload.signerId === action.payload.lawyerId) {
                    tempSigner.push(action.payload.record);
                    tempLawyer.push(action.payload.record);
                } else if (action.payload.type === LegalProfessionalOptions.DealSigner) {
                    tempSigner.push(action.payload.record);
                } else if (action.payload.type === LegalProfessionalOptions.DealLawyer){
                    tempLawyer.push(action.payload.record);
                }
            }
            return { ...state, lawyerFirmOptionList: tempLawyer, signerFirmOptionList: tempSigner, lenderLawyerFirmOptionList: tempLenderLawyer };
        case actions.SET_ALL_LEGAL_FIRM_OPTIONS:
            return { ...state, allFirmsOptionList: action.payload };
        default:
            return state;
    }
}