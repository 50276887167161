import { AdminSection, Section } from "../types/UniversalSurvey/Frontend/section";
import { AdminSections, Sections } from "./enums/sections";


const dataSheetControlOptions: Section[] = [
    {
        title: Sections.DealOverview,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: false,
        }
    },
    {
        title: Sections.ClientInformation,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: false,
        }
    },
    {
        title: Sections.PropertyInformation,
        status: {
            errors: 0,
            missingFields: 4,
            isEmpty: false,
        }
    },
    {
        title: Sections.Encumbrances,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    },
    {
        title: Sections.NewMortgage,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: false,
        }
    },
    {
        title: Sections.Conditions,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    },
    {
        title: Sections.UnsecuredDebts,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    },
    {
        title: Sections.FireInsurance,
        status: {
            errors: 2,
            missingFields: 3,
            isEmpty: false,
        }
    },
    {
        title: Sections.TitleInsurance,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    },
    {
        title: Sections.StatementOfAccount,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    },
    {
        title: Sections.LandTransferTax,
        status: {
            errors: 1,
            missingFields: 3,
            isEmpty: false,
        }
    },
    {
        title: Sections.TrustLedger,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: true,
        }
    }
];

const adminControlOptions: AdminSection[] = [
    {
        title: AdminSections.LegalProfessional,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: false,
        }
    },
    {
        title: AdminSections.Lender,
        status: {
            errors: 0,
            missingFields: 0,
            isEmpty: false,
        }
    },
    // {
    //     title: AdminSections.MortgageBrokerage,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.ChargeHolderCompany,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.Governments,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.OtherChargeHolder,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.FireInsuranceCompany,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.FireInsuranceBrokerage,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.CondoCorporation,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.PropertyManagementCompany,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
    // {
    //     title: AdminSections.RealEstateBrokerage,
    //     status: {
    //         errors: 0,
    //         missingFields: 0,
    //         isEmpty: false,
    //     }
    // },
];

export { dataSheetControlOptions, adminControlOptions };