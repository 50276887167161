import { useContext, useState } from "react";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase";
import { getRecipientName, updateTrustLedgerPayment } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";
import { PaymentGroupListItem } from "../../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { DeliveryMethodOptions } from "../../../../../libs/resources/options";
import { formatDeliveryMethod } from "../../../../../libs/utils/formatValues";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import { DeliveryMethod } from "../../../../../libs/resources/enums/deliveryMethod";
import { PaymentRecipientType } from "../../../../../libs/resources/enums/paymentGroup";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {
    trustLedgerId: number;
};

export default function ChangePaymentGroupingModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod | undefined>(undefined);

    const [selectedPaymentGroup, setSelectedPaymentGroup] = useState<PaymentGroupListItem | undefined>(undefined);

    function onDiscardEditPayment() {
        setDeliveryMethod(undefined);
        setSelectedPaymentGroup(undefined);
        props.onClose ? props.onClose() : undefined;
    }

    function onSubmitEditPayment() {
        updateTrustLedgerPayment(dispatch, String(state.deal.dealInfo?.id), String(props.trustLedgerId), selectedPaymentGroup!.id, deliveryMethod);
    }

    function isSubmitEditPaymentDisabled() {
        if (!props.trustLedgerId || !selectedPaymentGroup) {
            return true;
        } else {
            return false;
        }
    }

    function getPaymentGroupComboboxItems(): PaymentGroupListItem[] {
        const options: PaymentGroupListItem[] = [];
        for(const pg of state.paymentGroups.paymentGroups) {
            options.push({ ...pg, label: getRecipientName(pg, "Payment Group") });
        }
        return options;
    }

    return (
        <ModalBase
            title={"Change Payment Group"}
            open={props.open}
            onClose={() => onDiscardEditPayment()}
            onSubmit={() => onSubmitEditPayment()}
            saveDisabled={isSubmitEditPaymentDisabled()}
            size="small"
            closeAfterSaving={onDiscardEditPayment}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveChangePaymentGroup])}
        >
            <>
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                        <ComboBox
                            options={getPaymentGroupComboboxItems()}
                            value={selectedPaymentGroup}
                            label={{ text: "Payment Group", inputId: "payment-grouping", isRequired: true }}
                            placeholder="Payment Group"
                            isHoverActionHidden={selectedPaymentGroup ? false : true}
                            handleClear={() => setSelectedPaymentGroup(undefined)}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedPaymentGroup(value)}
                        />
                    </Grid>
                    {selectedPaymentGroup?.recipient_type === PaymentRecipientType.ThirdPartyTransfer && (
                        <Grid item xs={12}>
                            <Dropdown
                                options={DeliveryMethodOptions}
                                value={deliveryMethod}
                                label={{ text: "Delivery Method", inputId: "payment-delivery-method" }}
                                placeholder="Payment Delivery Method"
                                isHoverActionHidden={deliveryMethod ? false : true}
                                handleClear={() => setDeliveryMethod(undefined)}
                                onChange={(e) => setDeliveryMethod(e.target.value as DeliveryMethod)}
                                formatValue={formatDeliveryMethod}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        </ModalBase>
    );
}