import { FireInsuranceBrokerageRequest } from "../../../types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerageRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultFireInsuranceBrokerageRequest: FireInsuranceBrokerageRequest = {
    name: null,
    abbr_name: null,
    all_regions: null,
    general_address: defaultAddressRequest,
    email: null,
    phone: null,
    fax: null
}