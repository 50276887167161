import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { AdminActionTypes } from "../actionTypes";
import { AdminActions } from "../context";


export type AdminAlertsState = {
    message: string | null;
    duration: number | undefined;
    type: AlertTypes;
    primaryText: string | undefined;
    primaryAction: (() => void) | undefined;
    secondaryText: string | undefined;
    secondaryAction: (() => void) | undefined;
}

export const defaultAdminAlertsState: AdminAlertsState = {
    message: null,
    duration: 6000,
    type: AlertTypes.Error,
    primaryText: undefined,
    primaryAction: undefined,
    secondaryText: undefined,
    secondaryAction: undefined,
}

export function alertsReducer(state: AdminAlertsState, action: AdminActions): AdminAlertsState {
    switch (action.type) {
        case AdminActionTypes.SET_ALERT_DATA:
            return {
                ...action.payload,
                duration: action.payload.duration ?? 6000,
                primaryText: action.payload.primaryText,
                primaryAction: action.payload.primaryAction,
                secondaryText: action.payload.secondaryText,
                secondaryAction: action.payload.secondaryAction
            };
        case AdminActionTypes.RESET_ALERT_DATA:
            return { ...defaultAdminAlertsState };
        default:
            return state;
    }
}