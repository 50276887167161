import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getDealFireInsuranceBrokerage,
    getAllDealFireInsuranceBrokerages,
    getAllGlobalFireInsuranceBrokerages,
    saveDealFireInsuranceBrokerage,
    createDealFireInsuranceBrokerage,
    newRegionOnFireInsuranceBrokerageRecord,
    deleteRegionFromFireInsuranceBrokerageRecord
} from "../../../libs/service/axios/api";
import { sanitizeFireInsuranceBrokerageResponse, sanitizeSimpleFireInsuranceBrokeragesResponse } from '../../../libs/types/UniversalSurvey/utils/convertResponse';
import { sanitizeFireInsuranceBrokerageRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { FireInsuranceBrokerage, FireInsuranceBrokerageListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/fireInsuranceBrokerage";
import { SimpleFireInsuranceBrokerage } from "../../../libs/types/UniversalSurvey/FireInsuranceBrokerage/simpleFireInsuranceBrokerage";
import { sortByKey } from "../../../libs/utils/arrays";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { Loading } from "../../../libs/resources/enums/loading";

function saveFireInsuranceBrokerage(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: FireInsuranceBrokerage, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBrokerage, isLoading: true } });
    saveDealFireInsuranceBrokerage(dealId, String(brokerageInfo.id), sanitizeFireInsuranceBrokerageRequest(brokerageInfo))
        .then(async function () {
            const newListItem: FireInsuranceBrokerageListItem = {
                id: brokerageInfo.id,
                label: brokerageInfo.name,
                isRecord: true,
                name: brokerageInfo.name,
                abbr_name: brokerageInfo.abbr_name,
                general_address: brokerageInfo.general_address
            }
            await updateFireInsuranceBrokerageRecordRegions(dispatch, dealId, brokerageInfo, regionList);
            getFireInsuranceBrokerageInfo(dispatch, dealId, brokerageInfo.id);
            dispatch({ type: actions.REPLACE_FIRE_INSURANCE_BROKERAGE_OPTION_WITH_RECORD, payload: { oldBrokerageId: brokerageInfo.id, newOption: newListItem, oldIsRecord: true } });
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: brokerageInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBrokerage, isLoading: false } });
        })
}

function createFireInsuranceBrokerageRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerage: FireInsuranceBrokerageListItem) {
    createDealFireInsuranceBrokerage(dealId, { fire_insurance_brokerage_id: brokerage.id })
        .then(function (response: any) {
            getFireInsuranceBrokerageInfo(dispatch, dealId, response.data.id);
            const newBrokerageListItem: FireInsuranceBrokerageListItem = {
                ...brokerage,
                label: brokerage.name,
                isRecord: true,
                id: response.data.id
            };
            dispatch({ type: actions.REPLACE_FIRE_INSURANCE_BROKERAGE_OPTION_WITH_RECORD, payload: { oldBrokerageId: brokerage.id, newOption: newBrokerageListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: newBrokerageListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewFireInsuranceBrokerageRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: FireInsuranceBrokerage, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBrokerage, isLoading: true } });
    createDealFireInsuranceBrokerage(dealId)
        .then(function (response: any) {
            saveDealFireInsuranceBrokerage(dealId, String(response.data.id), sanitizeFireInsuranceBrokerageRequest(brokerageInfo))
                .then(async function () {
                    const newBrokerageListItem: FireInsuranceBrokerageListItem = {
                        id: response.data.id,
                        label: brokerageInfo.name,
                        isRecord: true,
                        name: brokerageInfo.name,
                        abbr_name: brokerageInfo.abbr_name,
                        general_address: brokerageInfo.general_address,
                    };
                    await addFireInsuranceBrokerageRecordRegions(dispatch, dealId, response.data.id, regionList);
                    dispatch({ type: actions.ADD_TO_FIRE_INSURANCE_BROKERAGE_OPTIONS, payload: newBrokerageListItem });
                    dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: newBrokerageListItem.id });
                    getFireInsuranceBrokerageInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance brokerage record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBrokerage, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceBrokerage, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

function createFireInsuranceBrokerageOptionList(dispatch: Dispatch<Record<string, any>>, dealId: string, region: number | undefined, selectedBrokerageId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerageList, isLoading: true } });
    getAllDealFireInsuranceBrokerages(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizeSimpleFireInsuranceBrokeragesResponse(dealResponse.data);
            let query = undefined;
            if (region) {
                query = new URLSearchParams({ region_id: String(region) })
            }
            getAllGlobalFireInsuranceBrokerages(query)
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeSimpleFireInsuranceBrokeragesResponse(globalResponse.data.fire_insurance_brokerages);
                    dispatch({ type: actions.SET_FIRE_INSURANCE_BROKERAGE_OPTION_LIST, payload: organizeBrokerageList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_BROKERAGE, payload: selectedBrokerageId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance brokerages: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerageList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceBrokerageList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance brokerage records: ${error}`, type: AlertTypes.Error } });
        })
}

function getFireInsuranceBrokerageInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number) {
    getDealFireInsuranceBrokerage(dealId, String(brokerageRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_FIRE_INSURANCE_BROKERAGE_INFO,
                payload: sanitizeFireInsuranceBrokerageResponse(response.data)
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
        })
}

async function addFireInsuranceBrokerageRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnFireInsuranceBrokerageRecord(dealId, String(brokerageRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateFireInsuranceBrokerageRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, brokerageInfo: FireInsuranceBrokerage, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (brokerageInfo.regions) {
        for (const region of brokerageInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnFireInsuranceBrokerageRecord(dealId, String(brokerageInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance brokerage record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromFireInsuranceBrokerageRecord(dealId, String(brokerageInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the fire insurance brokerage record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on fire insurance brokerage record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

function organizeBrokerageList(dealBrokerageList: SimpleFireInsuranceBrokerage[], globalBrokerageList: SimpleFireInsuranceBrokerage[]): FireInsuranceBrokerageListItem[] {
    let cleanList: FireInsuranceBrokerageListItem[] = [];
    let brokerageIsRecordList: number[] = [];

    for (const brokerageItem of dealBrokerageList) {
        if (brokerageItem.fire_insurance_brokerage?.id) {
            brokerageIsRecordList.push(brokerageItem.fire_insurance_brokerage.id);
        }
        cleanList.push({
            id: brokerageItem.id,
            label: brokerageItem.name,
            isRecord: true,
            name: brokerageItem.name,
            abbr_name: brokerageItem.abbr_name,
            general_address: brokerageItem.general_address
        });
    }

    for (const brokerageItem of globalBrokerageList) {
        if (!brokerageIsRecordList.includes(brokerageItem.id)) {
            cleanList.push({
                id: brokerageItem.id,
                label: brokerageItem.name,
                isRecord: false,
                name: brokerageItem.name,
                abbr_name: brokerageItem.abbr_name,
                general_address: brokerageItem.general_address
            });
        }
    }

    sortByKey(cleanList, "name");
    return cleanList;
}

export {
    saveFireInsuranceBrokerage,
    createFireInsuranceBrokerageRecordFromGlobal,
    createNewFireInsuranceBrokerageRecord,
    createFireInsuranceBrokerageOptionList,
    getFireInsuranceBrokerageInfo
}