import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    newAffidavit,
    saveAffidavit,
    getAffidavits,
    getAffidavit,
    deleteAffidavit
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeAffidavitRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import {
    sanitizeAffidavitResponse,
    sanitizeSimpleAffidavitsResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { Affidavit } from "../../../libs/types/UniversalSurvey/Affivadit/affidavit";
import { SimpleAffidavit } from "../../../libs/types/UniversalSurvey/Affivadit/simpleAffidavit";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { Loading } from "../../../libs/resources/enums/loading";

function addAffidavit(dispatch: Dispatch<Record<string, any>>, dealId: string, client: SimpleClient | undefined, affidavitInfo: Affidavit) {    
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: true } });
    newAffidavit(dealId)
        .then(function (response: any) {
            saveAffidavit(dealId, String(response.data.id), sanitizeAffidavitRequest(affidavitInfo))
                .then(function () {
                    let newAffidavit: Affidavit = { ...affidavitInfo, id: response.data.id, client_record: client };
                    dispatch({ type: actions.ADD_AFFIDAVIT, payload: newAffidavit });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving affidavit: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating affidavit: ${error}`, type: AlertTypes.Error } });
        })
}

function updateAffidavit(dispatch: Dispatch<Record<string, any>>, dealId: string, client: SimpleClient | undefined, affidavitInfo: Affidavit) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: true } });
    saveAffidavit(dealId, String(affidavitInfo.id), sanitizeAffidavitRequest(affidavitInfo))
        .then(function () {
            dispatch({ type: actions.UPDATE_AFFIDAVIT, payload: { affidavit: affidavitInfo, client: client } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving affidavit: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveAffidavit, isLoading: false } });
        })
}

function getAffidavitsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AffidavitList, isLoading: true } });
    getAffidavits(dealId)
        .then(function (response: any) {
            const affidavits: SimpleAffidavit[] = sanitizeSimpleAffidavitsResponse(response.data);
            dispatch({ type: actions.SET_AFFIDAVITS_IN_DEAL, payload: affidavits });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get affidavits: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AffidavitList, isLoading: false } });
        })
}

function getAffidavitBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, affidavitId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AffidavitModal, isLoading: true } });
    getAffidavit(dealId, String(affidavitId))
    .then(function (response: any) {
        dispatch({ type: actions.SET_AFFIDAVIT_BEING_EDITED, payload: sanitizeAffidavitResponse(response.data) });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get affidavit: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.AffidavitModal, isLoading: false } });
    })
}

function removeAffidavit(dispatch: Dispatch<Record<string, any>>, dealId: string, affidavitId: number) {
    deleteAffidavit(dealId, String(affidavitId))
    .then(function () {
        dispatch({ type: actions.REMOVE_AFFIDAVIT, payload: affidavitId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete affidavit: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    addAffidavit,
    updateAffidavit,
    getAffidavitsInDeal,
    getAffidavitBeingEdited,
    removeAffidavit
}