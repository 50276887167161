import * as actions from "../actionTypes"

import { TitleInsurance } from "../../../libs/types/UniversalSurvey/TitleInsurance/titleInsurance";

export type TitleInsuranceState = {
    titleInsuranceInfo: TitleInsurance | undefined;
    titleInsurancesBeingSaved: number[];
    isTitleInsuranceSaving: boolean;
    shouldRefreshTitleInsurance: boolean;
}

export const defaultTitleInsuranceState: TitleInsuranceState = {
    titleInsuranceInfo: undefined,
    titleInsurancesBeingSaved: [],
    isTitleInsuranceSaving: false,
    shouldRefreshTitleInsurance: false,
}

export function titleInsuranceReducer(state: TitleInsuranceState, action: Record<string, any>): TitleInsuranceState {
    switch (action.type) {
        case actions.SET_TITLE_INSURANCE_INFO:
            return { ...state, titleInsuranceInfo: action.payload };
        case actions.ADD_TITLE_INSURANCE_BEING_SAVED:
            if (!state.titleInsurancesBeingSaved.includes(action.payload)) {
                return { ...state, titleInsurancesBeingSaved: [...state.titleInsurancesBeingSaved, action.payload] };
            }
            return state;
        case actions.REMOVE_TITLE_INSURANCE_BEING_SAVED:
            let isTitleInsuranceSaving = state.isTitleInsuranceSaving;
            let shouldRefreshTitleInsurance = state.shouldRefreshTitleInsurance;
            if (state.titleInsuranceInfo?.id === action.payload && isTitleInsuranceSaving) {
                isTitleInsuranceSaving = false;
                shouldRefreshTitleInsurance = true;
            }
            const tempTitleInsurancessBeingSaved = [...state.titleInsurancesBeingSaved];
            const index = tempTitleInsurancessBeingSaved.indexOf(action.payload);
            if (index >= 0) {
                tempTitleInsurancessBeingSaved.splice(index, 1);
            }
            return {
                ...state,
                titleInsurancesBeingSaved: tempTitleInsurancessBeingSaved,
                isTitleInsuranceSaving: isTitleInsuranceSaving,
                shouldRefreshTitleInsurance: shouldRefreshTitleInsurance
            };
        case actions.SET_IS_TITLE_INSURANCE_SAVING:
            return { ...state, isTitleInsuranceSaving: action.payload };
        case actions.SET_SHOULD_REFRESH_TITLE_INSURANCE:
            return { ...state, shouldRefreshTitleInsurance: action.payload };
        default:
            return state;
    }
}