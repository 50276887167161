import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { LetterToMortgageeExistingMortgageContextRequest } from "../documents/letterToMortgageeExistingMortgageDocument";
import { formatLetterToMortgageeExistingMortgageContentType } from "../../../../utils/formatValues";
import { LetterToMortgageeExistingMortgageContentType } from "../../../../resources/enums/documents/letterToMortgageeExistingMortgage";
import { SimpleLien } from "../../ExistingLien/simpleLien";
import { ChargeHolderType } from "../../../../resources/enums/chargeHolderType";
import Decimal from "decimal.js";

const contextValues = {
    Blank: 0,
    PayoutStatement: 0.1,
    InformationAndPostponementRequest: 0.2
}

function createLetterToMortgageeExistingMortgageName(lien: SimpleLien | undefined, contentType: LetterToMortgageeExistingMortgageContentType): string {
    if (!lien) return "";
    let name = "Letter to Mortgagee (Existing Mortgage)";
    const chargeHolderName = getChargeHolderName(lien);
    if (chargeHolderName) name += ` - ${chargeHolderName}`
    name += ` - ${formatLetterToMortgageeExistingMortgageContentType(contentType)}`
    return name;
}

function getChargeHolderName(lien: SimpleLien): string {
    switch (lien.charge_holder_type) {
        case ChargeHolderType.Company:
            return lien.charge_holder_company_record?.abbr_name ?? lien.charge_holder_company_record?.name ?? "";
        case ChargeHolderType.Government:
            return lien.government_record?.abbr_name ?? lien.government_record?.name ?? "";
        case ChargeHolderType.Lender:
            return lien.lender_record?.abbr_name ?? lien.lender_record?.name ?? "";
        case ChargeHolderType.Other:
            return lien.other_charge_holder_record?.name ?? "";
        default:
            return "";
    }
}

function labelLetterToMortgageeExistingMortgageContextOption(liensInDeal: SimpleLien[], contextId: number, contentType?: LetterToMortgageeExistingMortgageContentType) {
    const dec = Decimal.mod(contextId, 1).toNumber();
    const lienId = Decimal.sub(contextId, dec).toNumber();

    const lien = liensInDeal.find((lien) => lien.id === lienId);
    if (!lien) return "Undefined Encumbrance";
    let label = "Encumbrance";
    const chargeHolderName = getChargeHolderName(lien);
    if (chargeHolderName) label += ` - ${chargeHolderName}`;
    if (contentType) {
        label += ` - ${formatLetterToMortgageeExistingMortgageContentType(contentType)}`;
    } else {
        switch (dec) {
            case contextValues.PayoutStatement:
                label += ` - ${formatLetterToMortgageeExistingMortgageContentType(LetterToMortgageeExistingMortgageContentType.PayoutStatement)}`;
                break;
            case contextValues.InformationAndPostponementRequest:
                label += ` - ${formatLetterToMortgageeExistingMortgageContentType(LetterToMortgageeExistingMortgageContentType.InformationAndPostponementRequest)}`;
                break;
            default:
                label += ` - ${formatLetterToMortgageeExistingMortgageContentType(LetterToMortgageeExistingMortgageContentType.Blank)}`;
                break;
        }
    }
    return label;
}

function isLetterToMortgageeExistingMortgageDocInDeal(docList: Document[], contextValue: number): boolean {
    const dec = Decimal.mod(contextValue, 1).toNumber();
    const lienId = Decimal.sub(contextValue, dec).toNumber();
    const contentType = getContentType(dec);
    
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.LetterToMortgageeExistingMortgage && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.existing_mortgage_id === lienId && contentType === doc.context.content_type) return true;
    }
    return false;
}

function createLetterToMortgageeExistingMortgageContextRequest(lien: SimpleLien | undefined, contentType: LetterToMortgageeExistingMortgageContentType): LetterToMortgageeExistingMortgageContextRequest {
    return {
        existing_mortgage_id: lien ? lien.id : -1,
        content_type: contentType
    };
}

function getLetterToMortgageeExistingMortgageDocData(liensInDeal: SimpleLien[], contextId: number): [Object, string] {
    const dec = Decimal.mod(contextId, 1).toNumber();
    const lienId = Decimal.sub(contextId, dec).toNumber();
    const contentType = getContentType(dec);
    const matchingLien = liensInDeal.find((lien) => lien.id === lienId);
    return [createLetterToMortgageeExistingMortgageContextRequest(matchingLien, contentType), createLetterToMortgageeExistingMortgageName(matchingLien, contentType)];
}

function getLetterToMortgageeExistingMortgageContextOptions(liensInDeal: SimpleLien[], docList: Document[]) {
    const options: number[] = [];
    for (const lien of liensInDeal) {
        let blankValue = lien.id;
        let payoutStatementValue = lien.id + contextValues.PayoutStatement;
        let informationAndPostponementValue = lien.id + contextValues.InformationAndPostponementRequest;
        for (const value of [blankValue, payoutStatementValue, informationAndPostponementValue]) {
            if (!isLetterToMortgageeExistingMortgageDocInDeal(docList, value) && lien.instrument_type === "Charge") {
                options.push(value);
            }
        }
    }
    return options;
}

function getContentType(decimalValue: number) {
    switch (decimalValue) {
        case contextValues.PayoutStatement:
            return LetterToMortgageeExistingMortgageContentType.PayoutStatement
        case contextValues.InformationAndPostponementRequest:
            return LetterToMortgageeExistingMortgageContentType.InformationAndPostponementRequest
        default:
            return LetterToMortgageeExistingMortgageContentType.Blank
    }
}

export {
    getLetterToMortgageeExistingMortgageDocData,
    getLetterToMortgageeExistingMortgageContextOptions,
    labelLetterToMortgageeExistingMortgageContextOption
}