import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { LenderContact } from "../../../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { GovernmentContact } from "../../../../../libs/types/UniversalSurvey/GovernmentContacts/governmentContact";
import { ChargeHolderCompanyContact } from "../../../../../libs/types/UniversalSurvey/ChargeHolderCompanyContact/chargeHolderCompanyContact";
import { defaultLenderContact } from "../../../../../libs/resources/defaults/frontend/defaultLenderContact";
import { defaultChargeHolderCompanyContact } from "../../../../../libs/resources/defaults/frontend/defaultChargeHolderCompanyContact";
import { defaultGovernmentContact } from "../../../../../libs/resources/defaults/frontend/defaultGovernmentContact";

type Props = Omit<ModalProps, "children"> & {
    type: "lender" | "government" | "company"; 
};

export default function ReadonlyGeneralContactModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [contactObj, setContactObj] = useState<LenderContact | GovernmentContact | ChargeHolderCompanyContact>({...defaultLenderContact});

    useEffect(() => {
        switch(props.type) {
            case "company":
                if (state.chargeHolderCompanyContacts.contactBeingEdited) {
                    setContactObj({ ...state.chargeHolderCompanyContacts.contactBeingEdited });
                } else {
                    setContactObj({ ...defaultChargeHolderCompanyContact });
                }
                break;
            case "government":
                if (state.governmentContacts.contactBeingEdited) {
                    setContactObj({ ...state.governmentContacts.contactBeingEdited });
                } else {
                    setContactObj({ ...defaultGovernmentContact });
                }
                break;
            case "lender":
                if (state.lenderContacts.lenderContactBeingEdited) {
                    setContactObj({ ...state.lenderContacts.lenderContactBeingEdited });
                } else {
                    setContactObj({ ...defaultLenderContact });
                }
                break;
        }
    }, [
        state.lenderContacts.lenderContactBeingEdited,
        state.governmentContacts.contactBeingEdited,
        state.chargeHolderCompanyContacts.contactBeingEdited
    ]);

    function generateSubtitle(): string {
        switch(props.type) {
            case "company":
                return "COMPANY CONTACT";
            case "government":
                return "GOVERNMENT CONTACT";
            case "lender":
                return "LENDER CONTACT";
        }
    }

    function generateTeam(): { title: string, value: string | undefined } {
        let teamObj: { title: string, value: string | undefined } = { title: "", value: undefined };
        switch(props.type) {
            case "company":
                teamObj.title = "Company:";
                teamObj.value = state.chargeHolderCompanyContacts.contactBeingEdited?.charge_holder_company_record?.name;
                return teamObj;
            case "government":
                teamObj.title = "Government:";
                teamObj.value = state.governmentContacts.contactBeingEdited?.government_record.name;
                return teamObj;
            case "lender":
                teamObj.title = "Lender:";
                teamObj.value = state.lenderContacts.lenderContactBeingEdited?.lender_record?.name;
                return teamObj;
        }
    }

    function generateNotes() {
        switch(props.type) {
            case "company":
                return state.chargeHolderCompanyContacts.contactBeingEdited?.charge_holder_company_contact?.notes;
            case "government":
                return state.governmentContacts.contactBeingEdited?.government_contact?.notes;
            case "lender":
                return state.lenderContacts.lenderContactBeingEdited?.lender_contact?.notes;
        }
    }

    function discard() {
        switch(props.type) {
            case "lender":
                dispatch({ type: actions.SET_LENDER_CONTACT_BEING_EDITED, payload: undefined });
                setContactObj({ ...defaultLenderContact });
                break;
            case "company":
                dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_CONTACT_BEING_EDITED, payload: undefined });
                setContactObj({ ...defaultChargeHolderCompanyContact });
                break;
            case "government":
                dispatch({ type: actions.SET_GOVERNMENT_CONTACT_BEING_EDITED, payload: undefined });
                setContactObj({ ...defaultGovernmentContact });
        }
        props.onClose ? props.onClose() : undefined;
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            size="small"
            title={contactObj.name}
            subtitle={generateSubtitle()}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            {generateTeam().title}
                        </Small>
                        <Large>{generateTeam().value}</Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Email:
                        </Small>
                        <Link
                            href={contactObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Work Phone:
                        </Small>
                        <Link
                            href={contactObj.phone_work}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Fax:
                        </Small>
                        <Link
                            href={contactObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>{generateNotes()}</Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}