import { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import FinancialInfoGrid from "../../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { LegalFirm } from "../../../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { defaultLegalFirm } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirm";
import BooleanControl from "../../../../../../components/Common/BooleanControl/BooleanControl";
import { allRegion } from "../../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import GenericDialog from "../../../../../../components/Common/Modal/GenericDialog";

type Props = {
    legalFirm?: LegalFirm;
    setLegalFirm?: (legalFirm: LegalFirm) => void;
    updateLegalFirmInfo: <K extends keyof LegalFirm, V extends LegalFirm[K]>(key: K, value: V) => void;
    selectedRegionList: RegionListItem[]
    setSelectedRegionList: (regions: RegionListItem[]) => void;
    regions: RegionListItem[];
    financialInfoErrorState: FinancialInfoErrorState;
    setFinancialInfoErrorState: (errorState: FinancialInfoErrorState) => void;
    confirmDiscardOpen: boolean;
    setConfirmDiscardOpen: (open: boolean) => void;
    discard: Function;
};

export default function LegalFirmForm(props: Props) {
    const {
        legalFirm,
        setLegalFirm,
        updateLegalFirmInfo,
        selectedRegionList,
        setSelectedRegionList,
        regions,
        financialInfoErrorState,
        setFinancialInfoErrorState,
        confirmDiscardOpen,
        discard,
        setConfirmDiscardOpen
    } = props;
    
    const [firmObj, setFirmObj] = useState<LegalFirm>({ ...defaultLegalFirm });

    useEffect(() => {
        if (legalFirm) setFirmObj(legalFirm);
    }, [legalFirm]);

    function updateProvinces(value: RegionListItem[]) {
        let tempRegionList = value;
        if (value.map((region) => region.label).includes("All")) {
            tempRegionList = [allRegion];
            updateLegalFirmInfo("all_regions", true);
        } else {
            updateLegalFirmInfo("all_regions", undefined);
        }
        setSelectedRegionList(tempRegionList);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        discard();
    }

    return (
        <>
            <GenericDialog
                action="neutral"
                title="Confirm Discard"
                onCancel={() => setConfirmDiscardOpen(false)}
                onSubmit={() => handleDiscardConfirm()}
                submitText="Discard"
                open={confirmDiscardOpen}
                contentText="Are you sure you want to discard your changes?"
            />
            <Grid container rowSpacing={2} columnSpacing={5}>
                <Grid item xs={12}>
                    <BasicTextInput
                        fullWidth
                        value={firmObj.name ?? undefined}
                        onChange={(e) => updateLegalFirmInfo("name", e.target.value)}
                        label={{ text: "Legal Firm Name", inputId: "name", isRequired: true }}
                        placeholder="Legal firm's name"
                    />
                </Grid>
                <Grid item xs={5}>
                    <ComboBox
                        options={regions}
                        tokenField
                        value={selectedRegionList}
                        label={{ text: "Province(s) of Operation", inputId: "provinces", isRequired: true }}
                        onChangeFn={(value) => updateProvinces(value as RegionListItem[])}
                        placeholder="Province initials"
                    />
                </Grid>
                <Grid item xs={4}>
                    <BasicTextInput
                        fullWidth
                        value={firmObj.gst_number ?? undefined}
                        onChange={(e) => updateLegalFirmInfo("gst_number", e.target.value)}
                        label={{text: "Tax Registration No.", inputId: "gst_number"}}
                        placeholder="Tax Registration No."
                    />
                </Grid>
                <Grid item xs={3}>
                    <BooleanControl
                        checked={firmObj.partner}
                        onChange={() => updateLegalFirmInfo("partner", firmObj.partner ? false : true)}
                        label={{ text: "Deeded Partner", inputId: "partner" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FinancialInfoGrid
                        financialInfo={firmObj.financial_info}
                        setFinancialInfo={(value) => updateLegalFirmInfo("financial_info", value)}
                        errorState={financialInfoErrorState}
                        setErrorState={setFinancialInfoErrorState}
                        id="financial_info"
                        title="Financial Information"
                    />
                </Grid>
            </Grid>
        </>
    );
}