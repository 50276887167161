import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"

import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import { ChargeHolderCompany, ChargeHolderCompanyErrorState } from "../../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { defaultChargeHolderCompany } from "../../../../libs/resources/defaults/frontend/defaultChargeHolderCompany";
import { formatChargeHolderCompanyType } from "../../../../libs/utils/formatValues";
import { ChargeHolderCompanyType } from "../../../../libs/resources/enums/liens";
import { createNewChargeHolderCompanyRecord, saveChargeHolderCompany } from "../../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import AddressGrid from "../AddressGrid/AddressGrid";
import FinancialInfoGrid from "../FinancialInfoGrid/FinancialInfoGrid";
import { FinancialInfoErrorState } from "../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import { defaultChargeHolderCompanyErrorState } from "../../../../libs/resources/defaults/errorStates/defaultChargeHolderCompanyErrorState";
import { defaultFinancialInfoErrorState } from "../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";
import { validateModalObject } from "../../../../libs/utils/validation";

type Props = Omit<ModalProps, "children">;

export default function ChargeHolderCompanyModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [companyObj, setCompanyObj] = useState<ChargeHolderCompany>({ ...defaultChargeHolderCompany });
    const [originalCopy, setOriginalCopy] = useState<ChargeHolderCompany>({ ...defaultChargeHolderCompany });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<ChargeHolderCompanyErrorState>({ ...defaultChargeHolderCompanyErrorState });
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.chargeHolderCompanies.editingCompanyRecord && state.chargeHolderCompanies.companyInfo) {
            setCompanyObj(state.chargeHolderCompanies.companyInfo);
            setOriginalCopy(state.chargeHolderCompanies.companyInfo);
        } else {
            setCompanyObj({ ...defaultChargeHolderCompany });
        }
    }, [state.chargeHolderCompanies.editingCompanyRecord]);

    function updateCompanyInfo<
        K extends keyof ChargeHolderCompany,
        V extends ChargeHolderCompany[K]
    >(key: K, value: V): void {
        const tempCompanyObj = { ...companyObj }
        tempCompanyObj[key] = value;
        setCompanyObj(tempCompanyObj);
    }

    function submit() {
        if (state.chargeHolderCompanies.editingCompanyRecord) {
            saveChargeHolderCompany(dispatch, String(state.deal.dealInfo?.id), companyObj);
        } else {
            createNewChargeHolderCompanyRecord(dispatch, String(state.deal.dealInfo?.id), companyObj, state.dataSheet.currEntity!);
        }
    }

    function onDiscard() {
        dispatch({ type: actions.SET_EDITING_CHARGE_HOLDER_COMPANY_RECORD, payload: false });
        setCompanyObj({ ...defaultChargeHolderCompany });
        setOriginalCopy({ ...defaultChargeHolderCompany });
        setErrorState({ ...defaultChargeHolderCompanyErrorState });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function handleDiscardClick() {
        if (_.isEqual(companyObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!companyObj.name || _.isEqual(originalCopy, companyObj) || validateModalObject(errorState) || validateModalObject(financialInfoErrorState)) return true
        return false
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, companyObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={props.open}
            onClose={handleDiscardClick}
            onSubmit={submit}
            title={`${state.chargeHolderCompanies.editingCompanyRecord ? "" : "New "}Company`}
            saveDisabled={saveDisabled}
            discardButtonLabel={`Discard${state.chargeHolderCompanies.editingCompanyRecord ? " Changes" : ""}`}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveChargeHolderCompany])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            autoFocus
                            label={{text: "Company Name", inputId: "name", isRequired: true}}
                            placeholder="Company's name"
                            value={companyObj.name ?? undefined}
                            onChange={(e) => updateCompanyInfo("name", e.target.value)}
                            inputProps={{ "aria-label": "Company Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Abbreviated Name", inputId: "abbr_name"}}
                            value={companyObj.abbr_name ?? undefined}
                            onChange={(e) => updateCompanyInfo("abbr_name", e.target.value)}
                            placeholder="Abbreviated name"
                            inputProps={{ "aria-label": "Abbreviated Name" }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Dropdown
                            formatValue={formatChargeHolderCompanyType}
                            value={companyObj.type ?? ""}
                            onChange={(e) => updateCompanyInfo("type", e.target.value as ChargeHolderCompanyType)}
                            placeholder="Select an option..."
                            options={["construction", "utility"]}
                            label={{ text: "Company Type" }}
                            inputProps={{ "aria-label": "Company Type" }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "Website", inputId: "website"}}
                            value={companyObj.website ?? undefined}
                            onChange={(e) => updateCompanyInfo("website", e.target.value)}
                            placeholder="https://"
                            inputProps={{ "aria-label": "Website" }}
                            fieldType="url"
                            error={{
                                showError: true,
                                errorKey: "website",
                                errorState: errorState,
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "General Contact Name", inputId: "general_contact_name"}}
                            value={companyObj.general_contact_name ?? undefined}
                            onChange={(e) => updateCompanyInfo("general_contact_name", e.target.value)}
                            placeholder="Contact's name"
                            inputProps={{ "aria-label": "General Contact Name" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            label={{text: "General Contact Email", inputId: "general_email"}}
                            value={companyObj.general_email ?? undefined}
                            onChange={(e) => updateCompanyInfo("general_email", e.target.value)}
                            placeholder="Email address"
                            inputProps={{ "aria-label": "General Contact Email" }}
                            fieldType="email"
                            error={{
                                showError: true,
                                errorKey: "general_email",
                                errorState: errorState,
                                setErrorState: setErrorState
                            }}
                        />
                    </Grid>                    
                    <Grid item xs={12}>
                        <AddressGrid
                            address={companyObj.general_address}
                            setAddress={(value) => updateCompanyInfo("general_address", value)}
                            id="general_address"
                            title="General Address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressGrid
                            address={companyObj.address_for_service}
                            setAddress={(value) => updateCompanyInfo("address_for_service", value)}
                            id="address_for_service"
                            title="Address for Service"
                            copyAddress={{ address: companyObj.general_address, description: "General Address" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FinancialInfoGrid
                            financialInfo={companyObj.financial_info}
                            setFinancialInfo={(value) => updateCompanyInfo("financial_info", value)}
                            errorState={financialInfoErrorState}
                            setErrorState={setFinancialInfoErrorState}
                            id="financial_info"
                            title="Financial Information"
                        />
                    </Grid>
                </Grid>
            </>
        </ModalBase>
    );
}