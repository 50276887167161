import React, { useEffect, useState } from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {styled, SxProps, Theme} from "@mui/material/styles";
import { InputAdornment, InputProps } from "@mui/material";

import theme from '../componentStyling/Theme';
import color from '../componentStyling/Colors';
import { Large } from '../Typography/index';
import { SrcTextIcon, SrcCalcIcon, CalcIcon, ErrorIcon } from "../Icons/Iconography";
import { MoneyFormat } from "./MoneyFormat";
import Label, { labelProps } from "../Label/Label";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import HoverActions from "../HoverActions/ActionTooltip";
import { BORDER_2, FOCUS_BOX_SHADOW, LARGE_TEXT } from "../componentStyling/Styles";
import { BasicTooltipProps } from "../Tooltip/Tooltip";
import { InputErrorProps, emailErrorMessage, urlErrorMessage, validateEmail, validateUrl } from "../../../conveyance/libs/utils/validation";

type DeededInputProps = Omit<TextFieldProps, "error" | "size"> & {
  value?: string | number;
  disabled?: boolean;
  autoFilled?: boolean;
  hasSrcText?:boolean;
  calculateField?: boolean;
  hasCalculated?: boolean;
  moneyField?: boolean;
  valueType?: "positive" | "negative";
  percentageField?:boolean;
  sx?: SxProps<Theme>;
  label?: labelProps;
  tooltipProps?: Omit<BasicTooltipProps, "children">;
  readOnly?: boolean;
  handleEdit?: (editModalOpen: boolean) => void;
  handleClear?: (any: any) => void;
  isHoverActionHidden?: boolean;
  size?: "extra-small" | "small" | "default";
  isLineItem?: boolean;
  hideUnderline?: boolean;
  onIconClick?: () => void;
  error?: InputErrorProps;
  fieldType?: "email" | "url";
};

const defaultErrorState: InputErrorProps = {
  showError: false,
  message: "Field is invalid"
}

const BasicTextInput = React.forwardRef<HTMLDivElement, DeededInputProps>(
  function BasicTextInput(props, ref) {
    const {
      error, 
      value,
      disabled,
      autoFilled, 
      hasSrcText,
      calculateField,
      hasCalculated,
      sx,
      moneyField,
      valueType,
      percentageField,
      label,
      tooltipProps,
      readOnly,
      handleClear,
      handleEdit,
      isHoverActionHidden,
      size,
      isLineItem,
      hideUnderline,
      onIconClick,
      fieldType,
      ...other
    } = props;
    
    const [internalError, setInternalError] = useState<InputErrorProps>({ ...defaultErrorState });
    const [isFieldValid, setIsFieldValid] = useState<boolean>(true);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [inputProps, setInputProps] = useState<InputProps>({ ...other.InputProps });

    const iconClickProps = onIconClick ? { style: { cursor: "pointer"}, onClick: onIconClick } : {}

    // Setting validation
    useEffect(() => {
      if (error) {
          let newError = { ...defaultErrorState };
          if (error.errorKey) newError.errorKey = error.errorKey;
          if (error.errorState) newError.errorState = error.errorState;
          if (error.showError) newError.showError = error.showError;
          if (error.message) newError.message = error.message;
          if (error.customValidation) newError.customValidation = error.customValidation;
          setInternalError(newError);
      } else {
          setInternalError(defaultErrorState);
      }
    }, [error]);

    // Applying validation
    useEffect(() => {
      if (value && typeof value === "string" && value.length > 0) {
        let tempInternalError = { ...internalError };
        switch(fieldType) {
          case "email":
            if (!validateEmail(value)) {
              setIsFieldValid(false);
              handleErrorStateUpdate(true);
              tempInternalError.message = emailErrorMessage;
            } else {
              setIsFieldValid(true);
              handleErrorStateUpdate(false);
              tempInternalError.message = "";
            }
            setInternalError(tempInternalError);
            return;
          case "url":
            if (!validateUrl(value)) {
              setIsFieldValid(false);
              handleErrorStateUpdate(true);
              tempInternalError.message = urlErrorMessage;
            } else {
              setIsFieldValid(true);
              handleErrorStateUpdate(false);
              tempInternalError.message = "";
            }
            setInternalError(tempInternalError);
            return;
        }
        if (internalError.customValidation !== undefined) {
          handleErrorStateUpdate(true);
          return;
        }
      }
      setIsFieldValid(true);
      handleErrorStateUpdate(false);
    }, [fieldType, value]);

    useEffect(() => {
      if (error?.overwriteInternalError) {
        setIsFieldValid(false);
      }
    }, [error])

    // applying custom input props
    useEffect(() => {
      let tempInputProps = { ...inputProps };
      if (!isFieldValid && !isFocused) {
        tempInputProps.startAdornment = (
          <InputAdornment position="start" style={{ paddingLeft:10 }}>
            <ErrorIcon color={color.ORANGE_500} />
          </InputAdornment>
        )
      } else {
        if (moneyField) {
          tempInputProps.startAdornment = (
            <InputAdornment position="start">
              <Large>$</Large>
            </InputAdornment>
          )
        } else {
          tempInputProps.startAdornment = other.InputProps?.startAdornment;
        }
      }
      // Checking others that do not overwrite start adornments
      if (moneyField) {
        tempInputProps.inputComponent = MoneyFormat as any
        tempInputProps.inputProps = { allowNegative: valueType === "positive" ? false : true };
      }
      if (hasSrcText) {
        tempInputProps.endAdornment = (
          <InputAdornment position="end" {...iconClickProps}>
            <SrcTextIcon color={theme.INPUT} />
          </InputAdornment>
        )
      }
      if (calculateField) {
        tempInputProps.endAdornment = (
          <InputAdornment position="end" {...iconClickProps}>
            <CalcIcon color={theme.INPUT} />
          </InputAdornment>
        )
      }
      if (hasCalculated) {
        tempInputProps.endAdornment = (
          <InputAdornment position="end" {...iconClickProps}>
            <SrcCalcIcon color={theme.INPUT} />
          </InputAdornment>
        )
      }
      if (percentageField) {
        tempInputProps.endAdornment = (
          <InputAdornment position="start">
            <Large>%</Large>
          </InputAdornment>
        )
        tempInputProps.inputComponent = MoneyFormat as any
      }
      if (readOnly) {
        tempInputProps.readOnly = readOnly;
      }
      setInputProps(tempInputProps)
    }, [isFieldValid, isFocused, percentageField, readOnly, moneyField, hasCalculated, calculateField, hasSrcText, other.InputProps])

    function handleErrorStateUpdate(state: boolean): void {
      if (internalError.errorState && internalError.errorKey && error?.setErrorState) {
        let newError = { ...internalError }
        if (newError.errorState!.hasOwnProperty(newError.errorKey!)) {
            newError.errorState![newError.errorKey as keyof Object] = state as any;
        }
        error.setErrorState(newError.errorState!);
      }
    }

    function onFocus(): void {
      if (other.onFocus) {
        other.onFocus;
        setIsFocused(true);
      }
      setIsFocused(true);
    }

    function onBlur(): void {
      if (other.onBlur) {
        other.onBlur;
        setIsFocused(false);
      }
      // Run custom validation onBlur becuase can be resource heavy to run validation on every character change
      executeErrorValidation();
      setIsFocused(false);
    }

    function executeErrorValidation() {
      if (internalError.customValidation !== undefined) {
        if (internalError.customValidation(value)) {
          setIsFieldValid(true);
          handleErrorStateUpdate(false);
        } else {
          setIsFieldValid(false);
          handleErrorStateUpdate(true);
        }
      }
    }
    
    return (
      <HoverActions
        isHidden={((isHoverActionHidden === undefined) ? true : isHoverActionHidden ? true : false)}
        onEdit={handleEdit ? handleEdit : undefined}
        onRemove={handleClear ? handleClear : undefined}
      >
        <div>
          {typeof label === "object" && (
            <Label {...label} tooltipProps={tooltipProps}/>
          )}
          <Input
            {...other}
            onFocus={() => onFocus()}
            onBlur={() => onBlur()}
            autoComplete="off"
            ref={ref}
            variant="standard"
            hasError={!isFieldValid && !isFocused}
            hasFilled={autoFilled}
            value={value ?? ""}
            disabled={disabled}
            sx={sx}
            InputProps={inputProps}
            readOnly={readOnly}
            id={label?.inputId}
            fieldSize={size ?? "default"}
            isLineItem={isLineItem}
            hideUnderline={hideUnderline}
          />
          {!isFieldValid && internalError.showError && !isFocused && <InputErrorMessage errorMessage={internalError.message}/>}
        </div>
      </HoverActions>
    )
  }
);

export const Input = styled(TextField, { shouldForwardProp: 
  (prop) => (
    prop !== "hasError" &&
    prop !== "hasFilled" &&
    prop !== "fieldSize" &&
    prop !== "isLineItem" &&
    prop !== "hideUnderline"
  )
})<{
  hasError?: boolean;
  hasFilled?: boolean;
  readOnly?: boolean;
  fieldSize: "extra-small" | "small" | "default";
  isLineItem?: boolean;
  hideUnderline?: boolean;
}>(({ hasError, hasFilled, readOnly, fieldSize, isLineItem, hideUnderline }) => ({

  ".MuiInput-underline:hover:before": {
    borderBottom: hideUnderline ? "none" : BORDER_2(hasError ? theme.ERROR : readOnly ? color.GRAY_100 : color.BLACK),
  },

  ".MuiInput-underline:focus:hover": {
    borderBottom: "none",
  },

  ".MuiInput-underline:before": {
    borderBottom: hideUnderline ? "none" : BORDER_2(hasError ? theme.ERROR : readOnly ? color.GRAY_100 : isLineItem ? theme.DISABLED : theme.INPUT),
    margin: "0.2rem"
  },

  ".MuiInput-underline:after": {
    borderBottom: hideUnderline ? "none" : BORDER_2(hasError ? theme.ERROR : readOnly ? color.GRAY_100 : color.BLACK),
    margin: "0.2rem"
  },

  ".MuiInput-root": {
    backgroundColor: hasError ? color.ORANGE_50 : hasFilled ? theme.AUTO_FILLED : 'none',
    minHeight: fieldSize === "extra-small" ? "3rem" : fieldSize === "small" ? "4.7rem" : "6.2rem",
    "& fieldset": {
      borderColor: theme.INPUT,
      "& legend": {width: 0},
    },
    "&:hover fieldset": {
      appearance: "none",
    },
    "&.Mui-disabled ": {
      cursor: "no-drop",
    },
    "&.Mui-disabled fieldset": {},
    "&.Mui-focused fieldset": {
      appearance: "none",
      borderWidth: "0.1rem",
      boxShadow: "none",
    },
    "&.Mui-focused": {
      appearance: "none",
      border: BORDER_2(color.BLUE_500),
      boxShadow: FOCUS_BOX_SHADOW,
      borderRadius: "0.6rem",
    },
    "& .MuiInputBase-input": {
      ...LARGE_TEXT,
      color: readOnly ? color.GRAY_800 : color.BLACK,
      "&::placeholder": {
        opacity: 1,
        color: hasError ? color.BLACK : theme.PLACEHOLDER
      },
      "&[disabled]": {
        cursor: "no-drop",
      },
      padding: "0",
    },
  },
}));

export default BasicTextInput;