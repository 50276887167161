// Importing built-in React/Router tools
import { useContext } from 'react';

// Importing MUI components
import { Grid } from '@mui/material';

// Importing state management
import { AdminContext } from '../../../context/AdminPanel/context';

// Importing application components
import { H2 } from '../../../../components/Common/Typography/index';
import { AdminSections } from '../../../libs/resources/enums/sections';
import { AdminLoading } from '../../../libs/resources/enums/loading';
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { DCP_SECTION_CONTAINER, DCP_SECTION_CONTENT } from '../../../../components/Common/componentStyling/Styles';
import LenderSection from './LenderSection';
import LegalProfessionalSection from './LegalProfessionalSection';

export default function AdminSectionBase() {
    const [state] = useContext(AdminContext);

    return (
        <DCP_SECTION_CONTAINER>
            <Grid container>
                <Grid item xs={12}>
                    <H2 data-testid="current-section-header">
                        {state.navigation.currSection}
                    </H2>
                </Grid>
            </Grid>
            <DCP_SECTION_CONTENT>
                {state.loading.objectsLoading.includes(AdminLoading.Section) ? (
                    <CircularLoader containerHeight="70vh" />
                ) : (
                    <div>
                        {/* TODO: update as we add each section */}
                        {state.navigation.currSection === AdminSections.Lender && (
                            <LenderSection />
                        )}
                        {state.navigation.currSection === AdminSections.LegalProfessional && (
                            <LegalProfessionalSection />
                        )}
                    </div>
                )}
            </DCP_SECTION_CONTENT>
        </DCP_SECTION_CONTAINER>
    )
}