import { PropertyManagementCompany } from "../../../types/UniversalSurvey/PropertyManagementCompany/propertyManagementCompany";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultPropertyManagementCompany: PropertyManagementCompany = {
    id: -1,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    contact_name: undefined,
    general_address: defaultSimpleAddress,
    phone_office: undefined,
    phone_contact: undefined,
    fax: undefined,
    email: undefined,
    date_created: undefined,
    date_updated: undefined,

    property_management_company: undefined
}