import React, { useState, useEffect } from "react";

import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { styled } from "@mui/material";
import 'react-phone-input-2/lib/material.css'
import "./phoneField.css";
import Label, { labelProps } from "../Label/Label";
import HoverActions from "../HoverActions/ActionTooltip";
import { CaretDown, CaretUp } from "../Icons/Iconography";
import colors from "../componentStyling/Colors";
import { BasicTooltipProps } from "../Tooltip/Tooltip";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import { InputErrorProps, VALIDATE_ON_FOCUS } from "../../../conveyance/libs/utils/validation";

type PhoneFieldProps = PhoneInputProps & {
    label: labelProps & { inputId: string };
    tooltipProps?: Omit<BasicTooltipProps, "children">;
    handleClear?: (any: any) => void;
    isHoverActionHidden?: boolean;
    error?: InputErrorProps;
}

export const phoneErrorMessage = "Must be a valid number";

const defaultErrorState: InputErrorProps = {
    showError: false,
    message: phoneErrorMessage
}

export default function PhoneField(props: PhoneFieldProps) {
    //@ts-ignore
    const PhoneInputComponent = PhoneInput.default ? PhoneInput.default : PhoneInput;
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [internalError, setInternalError] = useState<InputErrorProps>(defaultErrorState);
    const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const {
        label,
        tooltipProps,
        handleClear,
        isHoverActionHidden,
        error,
        ...other
    } = props;

    function mutationObserverCallback(mutationList: any) {
        if(mutationList[0].target.getElementsByClassName("open").length > 0) {
            setDropdownOpen(true);
        } else {
            setDropdownOpen(false);
        }
    }

    useEffect(() => {
        const observer = new MutationObserver(mutationObserverCallback);
        observer.observe(document.getElementsByClassName(`phone-dropdown-button-${label.inputId}`)[0], { attributes: true });
        return () => observer.disconnect();
    }, []);

    // Validation
    useEffect(() => {
        // if error is provided
        if (error) {
            let newError = { ...defaultErrorState };
            if (error.errorKey) newError.errorKey = error.errorKey;
            if (error.errorState) newError.errorState = error.errorState;
            if (error.showError) newError.showError = error.showError;
            if (error.message) newError.message = error.message;
            setInternalError(newError);
        } else {
            setInternalError(defaultErrorState);
        }
    }, [error]);

    function handleErrorStateUpdate(state: boolean): void {
        if (internalError.errorState && internalError.errorKey && error?.setErrorState) {
            let newError = { ...internalError }
            if (newError.errorState!.hasOwnProperty(newError.errorKey!)) {
                newError.errorState![newError.errorKey as keyof Object] = state as any;
            }
            error.setErrorState(newError.errorState!);
        }
    }

    return (
        <HoverActions
            isHidden={((isHoverActionHidden === undefined) ? true : isHoverActionHidden ? true : false)}
            onRemove={handleClear ? handleClear : undefined}
        >
            <>
                <Label {...label} tooltipProps={tooltipProps}/>
                <PhoneInputComponent
                    {...other}
                    specialLabel={""}
                    preferredCountries={['ca', 'us']}
                    placeholder="+1 (000) 000-0000"
                    // Removing validation when component is focused
                    onFocus={() => VALIDATE_ON_FOCUS ? undefined : setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    isValid={(value: string, country: any) => {
                        const isValid = value.length === country.format?.match(/\./g).length;
                        if (!isValid && value.length > 1 && internalError.showError) {
                            handleErrorStateUpdate(true);
                            setIsPhoneValid(isValid);
                            return !isFocused ? isValid : true;
                        }
                        handleErrorStateUpdate(false);
                        setIsPhoneValid(true);
                        return true;
                    }}
                    country={'ca'}
                    jumpCursorToEnd={false}
                    dropdownStyle={{ width: document.getElementById(label.inputId)?.getBoundingClientRect().width }}
                    buttonClass={`phone-dropdown-button-${label.inputId}`}
                    inputProps={{ id: label.inputId }}
                />
                <CaretContainer>
                    {dropdownOpen ? <CaretUp color={colors.BLACK} size="small"/> : <CaretDown color={colors.BLACK} size="small"/>}
                </CaretContainer>
                {!isPhoneValid && !isFocused && <InputErrorMessage errorMessage={internalError.message}/>}
            </>
        </HoverActions>
    )
}

const CaretContainer = styled('div')({
    position: "relative",
    top: "-3.9rem",
    left: "2.8rem",
    width: "1.2rem",
    height: "0rem"
})