import { ReactElement, forwardRef, useState } from "react";

// Importing MUI components
import {styled } from "@mui/material/styles";
import { Stack, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

// Importing application components
import color from '../componentStyling/Colors';
import { EditIcon, XCircle } from "../Icons/Iconography";
import { useDidUpdateEffect } from "../../../conveyance/libs/hooks/useDidUpdateEffect";
import { InfoIcon } from "../Icons/Iconography";

type ActionProps = {
    isHidden: boolean;
    children: ReactElement;
    onRemove?: Function;
    onEdit?: Function;
    onView?: Function;
};

const HoverActions = forwardRef((props: ActionProps, ref) => {
    
    const [hasActionClicked, setHasActionClicked] = useState(false);

    useDidUpdateEffect(() => {
        if (hasActionClicked) {
            setHasActionClicked(false);
        }
    }, [hasActionClicked]);
    
    return (
        <div>
            {!hasActionClicked && (
                <CustomTooltip
                    ref={ref}
                    placement="bottom-end"
                    describeChild
                    title={
                        (!props.isHidden && (props.onEdit || props.onRemove || props.onView)) ? (
                            <ActionsStack direction="row">
                                {props.onEdit && (
                                    <IconButton onClick={() => { props.onEdit? props.onEdit() : undefined; setHasActionClicked(true) }}>
                                        <EditIcon color={color.GRAY_600} />
                                    </IconButton>
                                )}
                                {props.onView && (
                                    <IconButton onClick={() => { props.onView ? props.onView() : undefined; setHasActionClicked(true) }}>
                                        <InfoIcon color={color.GRAY_600} />
                                    </IconButton>  
                                )}
                                {props.onRemove && (
                                    <IconButton onClick={() => props.onRemove ? props.onRemove() : undefined}>
                                        <XCircle color={color.GRAY_600} />
                                    </IconButton>
                                )}
                            </ActionsStack>
                        ) : ( '' )
                    }
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -14],
                                },
                            },
                        ],
                    }}
                >
                    {props.children}
                </CustomTooltip>
            )}
        </div>
    )
});

const ActionsStack = styled(Stack)({
    height: "4.4rem",
    backgroundColor: color.GRAY_50,
})

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.GRAY_50,
        boxShadow: "0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15), 0rem 0.5rem 2rem rgba(0, 0, 0, 0.25)",
        borderRadius: "0rem 0rem 0.6rem 0.6rem"
    }
}));

export default HoverActions;