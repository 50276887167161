import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { LenderContact, LenderContactListItem, LenderContactErrorState } from "../../../../../libs/types/UniversalSurvey/LenderContact/lenderContact";
import { defaultLenderContact } from "../../../../../libs/resources/defaults/frontend/defaultLenderContact";
import { saveLenderContact, submitNewLenderContactRecord } from "../../../../../context/UniversalSurvey/asyncActions/lenderContacts";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { defaultLenderContactErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultLenderContactErrorState";
import LenderContactForm from "./LenderContactForm";

type Props = Omit<ModalProps, "children"> & {
    changeOrAddContact: (dispatch: Dispatch<Record<string, any>>, dealId: string, entity: number, lenderRecordId: number, newContactId: number, contactBeingReplaced: LenderContactListItem | undefined, lenderContactInfo: LenderContact) => void;
    mortgageOrLienId: number;
};

export default function LenderContactModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [lenderContactObj, setLenderContactObj] = useState<LenderContact>({ ...defaultLenderContact });
    const [errorState, setErrorState] = useState<LenderContactErrorState>({ ...defaultLenderContactErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.lenderContacts.lenderContactBeingEdited) {
            setLenderContactObj({ ...state.lenderContacts.lenderContactBeingEdited });
        } else {
            setLenderContactObj({ ...defaultLenderContact })
        }
    }, [state.lenderContacts.lenderContactBeingEdited]);

    function updateLenderContactInfo<
        K extends keyof LenderContact,
        V extends LenderContact[K]
    >(key: K, value: V): void {
        const tempLenderContactObj = { ...lenderContactObj }
        tempLenderContactObj[key] = value;
        setLenderContactObj(tempLenderContactObj);
    }

    function submit() {
        if (state.lenderContacts.lenderContactBeingEdited) {
            saveLenderContact(
                dispatch,
                String(state.deal.dealInfo?.id),
                state.lenders.selectedLender?.id!,
                lenderContactObj
            )
        } else {
            submitNewLenderContactRecord(
                dispatch,
                String(state.deal.dealInfo?.id),
                props.mortgageOrLienId,
                state.lenders.selectedLender?.id!,
                state.lenderContacts.lenderContactBeingReplaced,
                lenderContactObj,
                props.changeOrAddContact
            );
        }
    }

    function discard() {
        dispatch({ type: actions.SET_LENDER_CONTACT_BEING_EDITED, payload: undefined });
        dispatch({ type: actions.SET_LENDER_CONTACT_BEING_REPLACED, payload: undefined });
        setLenderContactObj({ ...defaultLenderContact });
        setErrorState({ ...defaultLenderContactErrorState });
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!lenderContactObj.name || validateModalObject(errorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [errorState, lenderContactObj])

    return (
        <ModalBase
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={`${!state.lenderContacts.lenderContactBeingEdited ? "New " : ""}Lender Contact`}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.LenderContactModal])}
            closeAfterSaving={discard}
            saveDisabled={saveDisabled}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveLenderContact])}
        >
            <LenderContactForm
                contact={lenderContactObj}
                updateContactInfo={updateLenderContactInfo}
                errorState={errorState}
                setErrorState={setErrorState}
            />
        </ModalBase>
    );
}
