import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    saveMortgageBrokerageOtherContact,
    newMortgageBrokerageOtherContact,
    addMortgageBrokerageOtherContact,
    removeMortgageBrokerageOtherContact,
    deleteMortgageBrokerageOtherContact,
    getMortgageBrokerageOtherContact,
    getMortgageBrokerageOtherContacts,
    getMortgageTransaction,
    getMortgageTransactions
} from '../../../libs/service/axios/api';
import { MortgageBrokerageOtherContactRequest } from "../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContactRequest";
import { MortgageBrokerageOtherContact } from "../../../libs/types/UniversalSurvey/MortgageBrokerageOtherContact/mortgageBrokerageOtherContact";
import { sanitizeMortgageBrokerageOtherContactRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { sanitizeMortgageBrokerageOtherContactResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { Loading } from "../../../libs/resources/enums/loading";

function updateMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, dealId: string, contactId: number, contactInfo: MortgageBrokerageOtherContactRequest): void {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: true } });
    saveMortgageBrokerageOtherContact(dealId, String(contactId), contactInfo).then(function () {
        dispatch({
            type: actions.UPDATE_MORTGAGE_BROKERAGE_OTHER_CONTACT,
            payload: { ...contactInfo, id: contactId }
        });
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Updating mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: false } });
    })
}

function createMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, contactInfo: MortgageBrokerageOtherContact): void {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: true } });
    newMortgageBrokerageOtherContact(dealId).then(function (response: any) {
        let contactAddedSuccessfully = true;
        const contactId = response.data.id;
        const promiseList = [];
        let contactRequest = sanitizeMortgageBrokerageOtherContactRequest(contactInfo);
        promiseList.push(saveMortgageBrokerageOtherContact(dealId, contactId, contactRequest).then().catch(function (error: any) {
            contactAddedSuccessfully = false;
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving mortgage brokerage other contact during creation: ${error}`, type: AlertTypes.Error } });
        }))

        promiseList.push(addMortgageBrokerageOtherContact(dealId, mortgageId, contactId).then().catch(function (error: any) {
            contactAddedSuccessfully = false;
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding mortgage brokerage other contact to mortgage transaction: ${error}`, type: AlertTypes.Error } });
        }))

        Promise.all(promiseList).then(function () {
            if (contactAddedSuccessfully) {
                dispatch({
                    type: actions.CREATE_MORTGAGE_BROKERAGE_OTHER_CONTACT,
                    payload: { ...contactInfo, id: contactId }
                })
            }
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: false } });
        })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
}

function createAndReplaceMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, oldContactId: number, contactInfo: MortgageBrokerageOtherContact): void {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: true } });
    newMortgageBrokerageOtherContact(dealId).then(function (response: any) {
        const contactId = response.data.id;
        let contactRequest = sanitizeMortgageBrokerageOtherContactRequest(contactInfo)
        saveMortgageBrokerageOtherContact(dealId, contactId, contactRequest)
            .then(function () {
                changeMortgageBrokerageOtherContactInMortgage(dispatch, dealId, mortgageId, oldContactId, { ...contactInfo, id: contactId })
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving mortgage brokerage other contact during creation: ${error}`, type: AlertTypes.Error } });
            })
            .finally(function () {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: false } });
            })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveMortgageBrokerageOtherContact, isLoading: false } });
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
}

function addMortgageBrokerageOtherContactToMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, contact: MortgageBrokerageOtherContact): void {
    addMortgageBrokerageOtherContact(dealId, mortgageId, String(contact.id)).then(function () {
        dispatch({
            type: actions.ADD_MORTGAGE_BROKERAGE_OTHER_CONTACT_TO_MORTGAGE,
            payload: contact
        });
        dispatch({ type: actions.SET_EMPTY_MORTGAGE_BROKERAGE_OTHER_CONTACT_ADDED, payload: false });
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding mortgage brokerage other contact to mortgage: ${error}`, type: AlertTypes.Error } });
    })
}

function changeMortgageBrokerageOtherContactInMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, oldContactId: number, newContact: MortgageBrokerageOtherContact): void {
    addMortgageBrokerageOtherContact(dealId, mortgageId, String(newContact.id)).then(function () {
        removeMortgageBrokerageOtherContact(dealId, mortgageId, String(oldContactId)).then(function () {
            dispatch({
                type: actions.CHANGE_MORTGAGE_BROKERAGE_OTHER_CONTACT_IN_MORTGAGE,
                payload: { oldContactId: oldContactId, newContact: newContact }
            })
        }).catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing mortgage brokerage other contact from mortgage: ${error}`, type: AlertTypes.Error } });
        })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding mortgage brokerage other contact to mortgage: ${error}`, type: AlertTypes.Error } });
    })
}

function getMortgageBrokerageOtherContactFull(dispatch: Dispatch<Record<string, any>>, dealId: string, contactId: string): void {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageOtherContactModal, isLoading: true } });
    getMortgageBrokerageOtherContact(dealId, contactId).then(function (response: any) {
        dispatch({
            type: actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_EDITED,
            payload: sanitizeMortgageBrokerageOtherContactResponse(response.data)
        });
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageOtherContactModal, isLoading: false } });
    })
}

function getMortgageBrokerageOtherContactsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string): void {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageOtherContactList, isLoading: true } });
    getMortgageBrokerageOtherContacts(dealId).then(function (response: any) {
        dispatch({
            type: actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACTS_IN_DEAL,
            payload: response.data
        })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting mortgage brokerage other contacts: ${error}`, type: AlertTypes.Error } });
    })
    .finally(function () {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.MortgageBrokerageOtherContactList, isLoading: false } });
    })
}

function removeAndDeleteMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, contactId: number): void {
    removeMortgageBrokerageOtherContact(dealId, mortgageId, String(contactId)).then(function () {
        deleteMortgageBrokerageOtherContactFromDeal(dispatch, dealId, contactId);
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing mortgage brokerage other contact from mortgage: ${error}`, type: AlertTypes.Error } });
    })
}

function removeMortgageBrokerageOtherContactFromMultipleMortgagesAndDelete(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageIds: number[], contactId: number): void {
    const promiseList = [];
    for (const mortgageId of mortgageIds) {
        promiseList.push(
            removeMortgageBrokerageOtherContact(dealId, String(mortgageId), String(contactId)).then().catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing mortgage brokerage other contact from mortgage: ${error}`, type: AlertTypes.Error } });
            })
        );
    }
    Promise.all(promiseList).then(function () {
        deleteMortgageBrokerageOtherContactFromDeal(dispatch, dealId, contactId);
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteMortgageBrokerageOtherContactFromDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, contactId: number): void {
    deleteMortgageBrokerageOtherContact(dealId, String(contactId)).then(function () {
        dispatch({ type: actions.DELETE_MORTGAGE_BROKERAGE_OTHER_CONTACT, payload: contactId })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Deleting mortgage brokerage other contact: ${error}`, type: AlertTypes.Error } });
    })
}

function removeMortgageBrokerageOtherContactFromMortgage(dispatch: Dispatch<Record<string, any>>, dealId: string, mortgageId: string, contactId: number): void {
    removeMortgageBrokerageOtherContact(dealId, mortgageId, String(contactId)).then(function () {
        dispatch({ type: actions.REMOVE_MORTGAGE_BROKERAGE_OTHER_CONTACT, payload: contactId })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Removing mortgage brokerage other contact from mortgage: ${error}`, type: AlertTypes.Error } });
    })
}

function findAllMortgagesWithMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, dealId: string, contactId: number): void {
    getMortgageTransactions(dealId).then(function (response: any) {
        const promiseList = [];
        let mortgagesWithContact: number[] = [];
        for (const mortgage of response.data) {
            promiseList.push(
                getMortgageTransaction(dealId, String(mortgage.id)).then(function (mortgageResponse: any) {
                    if (mortgageResponse.data.new_mortgage_record.mortgage_brokerage_other_contact_list) {
                        for (const contact of mortgageResponse.data.new_mortgage_record.mortgage_brokerage_other_contact_list) {
                            if (contact.id === contactId) {
                                mortgagesWithContact.push(mortgageResponse.data.id);
                            }
                        }
                    }
                }).catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting mortgage transaction: ${error}`, type: AlertTypes.Error } });
                })
            )
        }
        Promise.all(promiseList).then(function () {
            dispatch({ type: actions.SET_MORTGAGES_WITH_MORTGAGE_BROKERAGE_OTHER_CONTACT, payload: mortgagesWithContact });
        })
    }).catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting all transactions: ${error}`, type: AlertTypes.Error } });
    })
}

function handleCreateMortgageBrokerageOtherContact(dispatch: Dispatch<Record<string, any>>, setModalActive: (open: boolean) => void, contactToReplace: number | undefined): void {
    dispatch({
        type: actions.SET_MORTGAGE_BROKERAGE_OTHER_CONTACT_BEING_REPLACED,
        payload: contactToReplace
    });
    setModalActive(true);
}

export {
    updateMortgageBrokerageOtherContact,
    createMortgageBrokerageOtherContact,
    createAndReplaceMortgageBrokerageOtherContact,
    addMortgageBrokerageOtherContactToMortgage,
    changeMortgageBrokerageOtherContactInMortgage,
    getMortgageBrokerageOtherContactFull,
    getMortgageBrokerageOtherContactsInDeal,
    removeAndDeleteMortgageBrokerageOtherContact,
    removeMortgageBrokerageOtherContactFromMultipleMortgagesAndDelete,
    removeMortgageBrokerageOtherContactFromMortgage,
    findAllMortgagesWithMortgageBrokerageOtherContact,
    handleCreateMortgageBrokerageOtherContact
}