export default {
    // Gray
    GRAY_50: "#FAF8F3",
    GRAY_100: "#E1E0DC",
    GRAY_200: "#CAC8C6",
    GRAY_300: "#B2B1AF",
    GRAY_400: "#9B9998",
    GRAY_500: "#838281",
    GRAY_600: "#6B6A6A",
    GRAY_700: "#535253",
    GRAY_800: "#3B3B3C",
    GRAY_900: "#242326",

    // Yellow
    YELLOW_100: "#FCF3A8",
    YELLOW_200: "#FDE87F",
    YELLOW_500: "#FFC700",

    // Green
    GREEN_300: "#96FF55",
    GREEN_500: "#38B523",

    // Blue
    BLUE_50: "#D4FAFF",
    BLUE_100: "#B1DDF8",
    BLUE_500: "#236BD8",
    BLUE_600: "#1C5CB0",
    BLUE_700: "#144D86",

    // Orange
    ORANGE_50: "#FFF1DE",
    ORANGE_500: "#FF4D00",
    ORANGE_700: "#A33204",
    ORANGE_90o: "rgb(255, 77, 0, 0.9)",

    // Red
    RED_10: "#FBF9F9",
    RED_20: "#FBF3F3",
    RED_50: "#FFD8CC",
    RED_100: "#FFBFB5",
    RED_300: "#FF8C87",
    RED_400: "#FF7370",
    RED_500: "#FF5958",
    RED_700: "#A33237",
    RED_900: "#460A14",

    // Black
    BLACK: "#0B0A0E",
    BLACK_25o: "rgba(11, 10, 14, 0.25)",
    BLACK_75o: "rgba(11, 10, 14, 0.75)",

    // White
    WHITE: "#FFFFFF",

    // Purple
    PURPLE_100: "#EBC1F8"
}