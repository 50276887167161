import { defaultSimpleLegalFirm } from "../../../resources/defaults/frontend/defaultSimpleLegalFirm"
import { GlobalLegalFirm, LegalFirm } from "../LegalFirm/legalFirm"
import { GlobalLegalFirmOffice, LegalFirmOffice } from "../LegalFirmOffice/legalFirmOffice"
import { LenderBranch } from "../Lender/LenderBranch/lenderBranch"
import { SimpleLenderBranch } from "../Lender/LenderBranch/simpleLenderBranch"
import { Lender } from "../Lender/lender"
import { defaultSimpleGlobalLegalFirm } from "../../../resources/defaults/frontend/defaultSimpleGlobalLegalFirm"

// LENDERS
function transformLenderBranchToLender(lenderBranch: LenderBranch): Lender {
    let lenderObj = {
        ...lenderBranch,
        lender: undefined,
        type: lenderBranch.lender?.type,
        institution_number: lenderBranch.lender?.institution_number,
        name: lenderBranch.lender?.name,
        abbr_name: lenderBranch.lender?.abbr_name
    }
    return lenderObj
}
function transformLenderToSimpleLenderBranch(lender: Lender, lenderSourceId: number): SimpleLenderBranch {
    let lenderBranch: SimpleLenderBranch = {
        id: lender.id,
        branch_number: lender.branch_number,
        all_regions: lender.all_regions,
        notes: undefined,

        general_address: lender.general_address,
        financial_info: lender.financial_info,
        regions: lender.regions,
        lender: {
            name: lender.name,
            abbr_name: lender.abbr_name,
            id: lenderSourceId,
            type: lender.type!,
            institution_number: lender.institution_number
        }
    }
    return lenderBranch
}

// LEGAL FIRMS
function transformGlobalLegalFirmToLegalFirm(globalLegalFirm: GlobalLegalFirm): LegalFirm {
    let legalFirm: LegalFirm = {
        ...globalLegalFirm,
        legal_firm: undefined
    }
    return legalFirm;
}
function transformLegalFirmToGlobalLegalFirm(legalFirm: LegalFirm, notes: string | undefined = undefined): GlobalLegalFirm {
    return {
        ...legalFirm,
        notes: notes
    }
}

// LEGAL FIRM OFFICES
function transformGlobalLegalFirmOfficeToLegalFirmOffice(firmOffice: GlobalLegalFirmOffice): LegalFirmOffice {
    let legalFirmOffice: LegalFirmOffice = {
        ...firmOffice,
        legal_firm_office: undefined,
        legal_firm_record: defaultSimpleLegalFirm
    }
    return legalFirmOffice;
}
function transformLegalFirmOfficeToGlobalLegalFirmOffice(firmOffice: LegalFirmOffice, notes: string | undefined): GlobalLegalFirmOffice {
    return {
        ...firmOffice,
        legal_firm: defaultSimpleGlobalLegalFirm,
        notes: notes
    }
}

export { 
    // lender
    transformLenderBranchToLender,
    transformLenderToSimpleLenderBranch,
    // legal firm
    transformGlobalLegalFirmToLegalFirm,
    transformLegalFirmToGlobalLegalFirm,
    // legal firm office
    transformGlobalLegalFirmOfficeToLegalFirmOffice,
    transformLegalFirmOfficeToGlobalLegalFirmOffice
}