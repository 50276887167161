import React, { useContext, useEffect, useState } from "react";

import { Grid, Stack } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase";
import { deletePaymentGrouping, getRecipientName, getTrustLedgerPaymentGroupsInDeal } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";
import { PaymentGroup } from "../../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import BasicAccordion from "../../../../../../components/Common/Accordion/Accordion";
import { Body, BodyBold } from "../../../../../../components/Common/Typography";
import { FormatNumeric } from "../../../../../../components/Common/TextField/MoneyFormat";
import BasicButton from "../../../../../../components/Common/Button/BasicButton";
import { formatDeliveryMethod, formatPaymentMethod } from "../../../../../libs/utils/formatValues";
import { getTrustLedgersInDeal } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgers";
import { SimpleTrustLedger } from "../../../../../libs/types/UniversalSurvey/TrustLedger/simpleTrustLedger";
import { TrustLedgerSource, TrustLedgerType } from "../../../../../libs/resources/enums/trustLedger";
import Colors from "../../../../../../components/Common/componentStyling/Colors";
import GenericDialog from "../../../../../../components/Common/Modal/GenericDialog";
import CreatePaymentGroupingModal from "./CreatePaymentGroupModal";
import ChangePaymentGroupingModal from "./ChangePaymentGroupModal";
import { NewWindowIcon } from "../../../../../../components/Common/Icons/Iconography";
import { navigateURL } from "../../../../../context/UniversalSurvey/asyncActions/deal";
import UpdatePaymentGroupModal from "./UpdatePaymentGroupModal";
import { Loading } from "../../../../../libs/resources/enums/loading";
import { isObjectLoading } from "../../../../../libs/utils/loading";

type Props = Omit<ModalProps, "children">;

export default function PaymentGroupingModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    const [paymentGroups, setPaymentGroups] = useState<PaymentGroup[]>([]);
    const [trustLedgers, setTrustLedgers] = useState<SimpleTrustLedger[]>([]);
    const [tlId, setTlId] = useState<number | undefined>(undefined);

    const [editGroupingModalOpen, setEditGroupingModalOpen] = useState<boolean>(false);
    const [newPaymentGroupModalOpen, setNewPaymentGroupModalOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean[]>([]);
    const [editPaymentGroupModalOpen, setEditPaymentGroupModelOpen] = useState<boolean[]>([]);

    useEffect(() => {
        getTrustLedgerPaymentGroupsInDeal(dispatch, String(state.deal.dealInfo?.id));
        getTrustLedgersInDeal(dispatch, String(state.deal.dealInfo?.id), Loading.TrustLedgerListModal);
    }, [props.open]);

    useEffect(() => {
        let expenditures: SimpleTrustLedger[] = [];
        for (const expenditure of state.trustLedgers.trustLedgers) {
            if (displayTLItem(expenditure)) {
                expenditures.push(expenditure);
            }
        }
        setTrustLedgers(expenditures);
        let stateList = [];
        for (const paymentGroup of state.paymentGroups.paymentGroups) {
            stateList.push(false);
        }
        setPaymentGroups(state.paymentGroups.paymentGroups);
        setConfirmDeleteOpen(stateList);
        setEditPaymentGroupModelOpen(stateList);
    }, [state.paymentGroups.paymentGroups, state.trustLedgers.trustLedgers]);

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
    }

    function updateConfirmDeleteModalOpen(index: number, newState: boolean) {
        let tempStateList = { ...confirmDeleteOpen };
        tempStateList[index] = newState;
        setConfirmDeleteOpen(tempStateList);
    }

    function updateEditPaymentGroupModalOpen(index: number, newState: boolean) {
        let tempStateList = { ...editPaymentGroupModalOpen };
        tempStateList[index] = newState;
        setEditPaymentGroupModelOpen(tempStateList);
    }

    function displayTLItem(trustLedger: SimpleTrustLedger): boolean {
        // Logic to display TL items
        if (
            trustLedger.payment_group_id === undefined
            && trustLedger.type === TrustLedgerType.Expenditure
            && (trustLedger.source !== TrustLedgerSource.ExpenditureInternalFeesDisbursement)
        ) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <ModalBase
            title={"Payment Grouping"}
            open={props.open}
            onClose={() => onDiscard()}
            discardButtonLabel={"Close"}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.TrustLedgerListModal, Loading.TrustLedgerPaymentGroupList])}
        >
            <div style={{ paddingBottom: "1rem" }}>
                <CreatePaymentGroupingModal
                    open={newPaymentGroupModalOpen}
                    onClose={() => setNewPaymentGroupModalOpen(false)}
                />
                <ChangePaymentGroupingModal
                    open={editGroupingModalOpen}
                    onClose={() => { setEditGroupingModalOpen(false); setTlId(undefined) }}
                    trustLedgerId={tlId ?? -1}
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    {paymentGroups.map((group, i) => (
                        <Grid item xs={12} key={i}>
                            <BasicAccordion
                                title={`${getRecipientName(group, "Payment Group")}${group.payment_method ? ` - ${formatPaymentMethod(group.payment_method)}` : ""}`}
                                defaultExpanded
                                onDelete={() => updateConfirmDeleteModalOpen(i, true)}
                                onEdit={() => updateEditPaymentGroupModalOpen(i, true)}
                                id={`group-payment-accordion-${i}`}
                                dialogOpen={confirmDeleteOpen[i] || editPaymentGroupModalOpen[i]}
                                dialog={confirmDeleteOpen[i] ?
                                    <GenericDialog
                                        action="destructive"
                                        open={confirmDeleteOpen[i]}
                                        title="Confirm Delete"
                                        contentText="Are you sure you want to delete this payment group? This is permanent and irreversible."
                                        onCancel={() => updateConfirmDeleteModalOpen(i, false)}
                                        onSubmit={() => {
                                            deletePaymentGrouping(dispatch, String(state.deal.dealInfo?.id), String(group.id));
                                            updateConfirmDeleteModalOpen(i, false);
                                        }}
                                        submitText="Delete Group"
                                        hideBackdrop
                                        top={2 * (document.getElementById(`group-payment-accordion-${i}`)?.getBoundingClientRect().top! + document.getElementById(`group-payment-accordion-${i}`)?.getBoundingClientRect().height! / 2 - window.innerHeight / 2)}
                                    /> :
                                    editPaymentGroupModalOpen[i] ? 
                                    <UpdatePaymentGroupModal
                                        open={editPaymentGroupModalOpen[i]}
                                        onClose={() => updateEditPaymentGroupModalOpen(i, false)}
                                        group={group}
                                    /> : 
                                    undefined
                                }
                                dialogBackgroundColor={confirmDeleteOpen[i] ? Colors.ORANGE_90o : undefined}
                            >
                                <Grid container rowSpacing={3} columnSpacing={5} alignItems={"center"}>
                                    {group.trust_ledgers.filter((tl) => tl.type === TrustLedgerType.Expenditure).map((tl, j) => (
                                        <React.Fragment key={j}>
                                            <Grid item xs={6}>
                                                <BodyBold>
                                                    {tl.name}{tl.delivery_method ? ` - ${formatDeliveryMethod(tl.delivery_method)}` : ""}
                                                </BodyBold>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Body sx={{ textAlign: "right" }}>
                                                    <FormatNumeric value={tl.amount ?? 0} />
                                                </Body>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BasicButton
                                                    label={{ text: "Change Grouping", inputId: `change-payment-grouping-${j}` }}
                                                    typeOf="CTA"
                                                    size="small"
                                                    disabled={tl.source === TrustLedgerSource.ExpenditureWiringFee ? true : false}
                                                    onClick={() => { setEditGroupingModalOpen(true); setTlId(tl.id) }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </BasicAccordion>
                        </Grid>
                    ))}
                    {trustLedgers.length > 0 && (
                        <Grid item xs={12}>
                            <BasicAccordion
                                title={"No Grouping"}
                                defaultExpanded
                            >
                                <Grid container rowSpacing={2} columnSpacing={5} alignItems={"center"}>
                                    {trustLedgers.map((tl, i) => (
                                        <React.Fragment key={i}>
                                            <Grid item xs={6}>
                                                <BodyBold>
                                                    {tl.name}{tl.delivery_method ? ` - ${formatDeliveryMethod(tl.delivery_method)}` : ""}
                                                </BodyBold>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Body sx={{ textAlign: "right" }}>
                                                    <FormatNumeric value={tl.amount ?? 0} />
                                                </Body>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BasicButton
                                                    label={{ text: "Change Grouping", inputId: `change-payment-grouping` }}
                                                    typeOf="CTA"
                                                    size="small"
                                                    onClick={() => { setEditGroupingModalOpen(true); setTlId(tl.id) }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </BasicAccordion>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Stack direction="row" gap={1}>
                            <BasicButton
                                label={{ text: "Add Grouping", inputId: `add-payment-grouping` }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => setNewPaymentGroupModalOpen(true)}
                            />
                            <BasicButton
                                label={{ text: "View Form 9C's", inputId: `view-form-9cs` }}
                                leftIcon={<NewWindowIcon color={Colors.BLACK} />}
                                typeOf="secondary"
                                onClick={() => navigateURL(dispatch, String(state.deal.dealInfo?.id), "docs", undefined, undefined)}
                            />
                        </Stack>          
                    </Grid>
                </Grid>
            </div>
        </ModalBase>
    );
}