import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    newSigningAppointment,
    saveSigningAppointment,
    newClientSigningAppointment,
    deleteClientSigningAppointment,
    getSigningAppointments,
    deleteSigningAppointment
} from "../../../libs/service/axios/api";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sanitizeSigningAppointmentRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import {
    sanitizeSimpleSigningAppointmentResponse
} from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import { SimpleSigningAppointment } from "../../../libs/types/UniversalSurvey/SigningAppointment/simpleSigningAppointment";
import { defaultSimpleSigningAppointment } from "../../../libs/resources/defaults/frontend/defaultSimpleSigningAppointment";
import { Loading } from "../../../libs/resources/enums/loading";

function addSigningAppointment(dispatch: Dispatch<Record<string, any>>, dealId: string) {    
    newSigningAppointment(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.ADD_SIGNING_APPOINTMENT, payload: { ...defaultSimpleSigningAppointment, id: response.data.id } });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Creating signing appointment: ${error}`, type: AlertTypes.Error } });
        })
}

function updateSigningAppointment(dispatch: Dispatch<Record<string, any>>, dealId: string, signingApptInfo: SimpleSigningAppointment) {
    saveSigningAppointment(dealId, String(signingApptInfo.id), sanitizeSigningAppointmentRequest(signingApptInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Saving signing appointment: ${error}`, type: AlertTypes.Error } });
        })
}

function updateSigningAppointmentClients(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedClients: (SimpleClient & { label: string })[], signingApptInfo: SimpleSigningAppointment) {
    let existingClientIds: number[] = [];
    let newClientIds: number[] = [];
    if (signingApptInfo.clients) {
        for (const client of signingApptInfo.clients) {
            existingClientIds.push(client.id);
        }
    }
    for (const client of selectedClients) {
        newClientIds.push(client.id);
        if (!existingClientIds.includes(client.id)) {
            newClientSigningAppointment(dealId, String(signingApptInfo.id), String(client.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${client.label} to signing appointment: ${error}`, type: AlertTypes.Error } });
                }
            })
        }
    } 
    for (const clientId of existingClientIds) {
        if (!newClientIds.includes(clientId)) {
            deleteClientSigningAppointment(dealId, String(signingApptInfo.id), String(clientId))
            .then()
            .catch(function (error: any) {
                if (error.response.data.message.length > 1 || !error.response.data.message.includes("Client cannot be found in the signing appointment.")) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete client on signing appointment: ${error}`, type: AlertTypes.Error } })
                }
            })
        }
    }
    dispatch({ type: actions.UPDATE_SIGNING_APPOINTMENT_CLIENTS, payload: { signingAppt: signingApptInfo, clients: selectedClients } });
}

function getSigningAppointmentsInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SigningAppointments, isLoading: true } });
    getSigningAppointments(dealId)
        .then(function (response: any) {
            const signingAppts: SimpleSigningAppointment[] = sanitizeSimpleSigningAppointmentResponse(response.data);
            dispatch({ type: actions.SET_SIGNING_APPOINTMENTS_IN_DEAL, payload: signingAppts });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get deal signing appointments: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SigningAppointments, isLoading: false } });
        })
}

function removeSigningAppointment(dispatch: Dispatch<Record<string, any>>, dealId: string, signingApptId: number) {
    deleteSigningAppointment(dealId, String(signingApptId))
    .then(function () {
        dispatch({ type: actions.REMOVE_SIGNING_APPOINTMENT, payload: signingApptId });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete deal signing appointment: ${error}`, type: AlertTypes.Error } });
    })
}

export {
    addSigningAppointment,
    updateSigningAppointment,
    getSigningAppointmentsInDeal,
    removeSigningAppointment,
    updateSigningAppointmentClients
}