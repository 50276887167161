import React, { useEffect, useState } from "react";

import { SxProps, Theme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setHours, setMinutes, format } from "date-fns";

import Label, { labelProps } from "../Label/Label";
import { BasicTooltipProps } from "../Tooltip/Tooltip";
import ComboBox from "../ComboBox/ComboBox";

export type DateOption = {
  label?: string
  value: Date
}

type BasicTimePickerProps = {
  value: Date | null;
  setValue: Function;
  disabled?: boolean;
  autoFilled?: boolean;
  sx?: SxProps<Theme>;
  label?: labelProps;
  tooltipProps?: Omit<BasicTooltipProps, "children">;
  errorMessage?: string;
  readOnly?: boolean;
  placeholder?: string;
};

// Time range for dropdown options (between 8am and 8pm)
const TIME_INTERVAL = 15;
const MIN_TIME_24HR = 8;
const MAX_TIME_24HR = 20;

const BasicTimePicker = React.forwardRef<HTMLDivElement, BasicTimePickerProps>(
  function BasicTimeInput(props, ref) {
    const {
      value,
      setValue,
      label,
      sx,
      disabled,
      autoFilled,
      tooltipProps,
      errorMessage,
      readOnly,
      placeholder,
    } = props;

    
    const [error, setError] = useState<string | undefined>(errorMessage);
    const [timeOptions, setTimeOptions] = useState<DateOption[]>([]);

    useEffect(() => {
      generateTimeOptions(value);
    }, [value]);

    const generateTimeOptions = (value: Date | null) => {
      const options: DateOption[] = [];
      for (let hour = MIN_TIME_24HR; hour <= MAX_TIME_24HR; hour++) {
        for (let minute = 0; minute < 60; minute += TIME_INTERVAL) {
          const time = setMinutes(setHours(value ?? new Date(), hour), minute);
          let option: DateOption = {
            label: format(time, "hh:mm a"),
            value: time
          }
          options.push(option);
        }
      }
      setTimeOptions(options);
    };

    const handleTextChange = (text: string) => {
      const regex = /(\d{1,2}):(\d{2})\s?([AaPp][Mm])?/;
      const match = text.match(regex);
    
      if (match && match[3]) {
        const hour = parseInt(match[1], 10);
        const minute = parseInt(match[2], 10);
        let newHour = hour;
    
        if (match[3].toLowerCase() === "pm" && hour < 12) {
          newHour = hour + 12;
        } else if (match[3].toLowerCase() === "am" && hour === 12) {
          newHour = 0;
        }
    
        const parsedDate = setMinutes(setHours(value ?? new Date(), newHour), minute);
        
        setValue(parsedDate);
        setError(undefined);
      } else {
        setError("Invalid time format. Please provide AM or PM.");
      }
    };

    return (
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ComboBox
            fullWidth
            label={label}
            sx={sx}
            disabled={disabled}
            autoFilled={autoFilled}
            timeInput
            readOnly={readOnly}
            placeholder={placeholder ?? "Set time (00:00 AM)"}
            error={error ? true : false}
            errorMessage={error}
            options={timeOptions}
            value={value ? format(value, "hh:mm a") : null}
            setText={(value) => handleTextChange(value)}
            //@ts-ignore
            onChange={(_, newValue: DateOption) => {
                setValue(newValue.value);
                setError(undefined);
              }
            }
          />
        </LocalizationProvider>
      </div>
    );
  }
);

export default BasicTimePicker;