import { LegalFirmOffice, LegalFirmOfficeListItem } from "../../types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { addressToStringShort } from "../address";

export function labelLegalFirmOfficeItem(office: LegalFirmOfficeListItem): LegalFirmOfficeListItem {
    let tempOffice = { ...office };
    tempOffice.label = labelLegalFirmOffice(office);
    return tempOffice;
}

export function labelLegalFirmOffice(office: LegalFirmOffice | LegalFirmOfficeListItem): string {
    return addressToStringShort(office.general_address);
}