import { GeneralSecurityAgreementContext, GeneralSecurityAgreementContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/generalSecurityAgreementDocument";
import { defaultSimpleMortgageTransaction } from "../../frontend/defaultSimpleMortgageTransaction";

export const defaultGeneralSecurityAgreementContext: GeneralSecurityAgreementContext = {
    mortgage_id: -1,

    mortgage_record: defaultSimpleMortgageTransaction
}

export const defaultGeneralSecurityAgreementContextRequest: GeneralSecurityAgreementContextRequest = {
    mortgage_id: -1
}