import { GovernmentRequest } from "../../../types/UniversalSurvey/Government/governmentRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultGovernmentRequest: GovernmentRequest = {
    level: null,
    department: null,
    name: null,
    abbr_name: null,
    general_address: defaultAddressRequest,
    general_email: null,
    fax: null,
    website: null,
    financial_info: defaultFinancialInfoRequest
}