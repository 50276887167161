import { CondoCorporation } from "../../../types/UniversalSurvey/CondoCorporation/condoCorporation";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultCondoCorporation: CondoCorporation = {
    id: -1,
    property_management_company_record_id: undefined,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    abbr_name: undefined,
    fee_for_status_certificate: undefined,
    tax_on_fee: undefined,
    general_address: defaultSimpleAddress,
    phone_office: undefined,
    phone_cell: undefined,
    fax: undefined,
    email: undefined,
    self_managed: false,
    date_created: undefined,
    date_updated: undefined,

    condo_corporation: undefined,
    property_management_company_record: undefined
}