import * as actions from "../actionTypes"

import { findAndRemove } from "../../../libs/utils/arrays";
import { StatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/statDec";
import { SimpleStatDec } from "../../../libs/types/UniversalSurvey/StatutoryDeclarations/simpleStatDec";
import { defaultSimpleStatDec } from "../../../libs/resources/defaults/frontend/defaultSimpleStatDec";


export type StatutoryDeclarationsState = {
    statDecBeingEdited: StatDec | undefined;
    statDecsInDeal: SimpleStatDec[];
    globalStatDecs: SimpleStatDec[];
}

export const defaultStatutoryDeclarationsState: StatutoryDeclarationsState = {
    statDecBeingEdited: undefined,
    statDecsInDeal: [],
    globalStatDecs: []
}

export function statutoryDeclarationsReducer(state: StatutoryDeclarationsState, action: Record<string, any>): StatutoryDeclarationsState {
    switch (action.type) {
        case actions.SET_DECLARATION_BEING_EDITED:
            return { ...state, statDecBeingEdited: action.payload };
        case actions.ADD_DECLARATION:
            const statDecsCopy = [...state.statDecsInDeal, action.payload];
            return { ...state, statDecsInDeal: statDecsCopy };
        case actions.SET_DECLARATIONS_IN_DEAL:
            return { ...state, statDecsInDeal: action.payload }
        case actions.SET_GLOBAL_DECLARATIONS:
            return { ...state, globalStatDecs: action.payload }
        case actions.REMOVE_DECLARATION:
            return { ...state, statDecsInDeal: findAndRemove([...state.statDecsInDeal], ["id"], [action.payload]) };
        case actions.UPDATE_DECLARATION:
            const tempDeclarations = [...state.statDecsInDeal];
            const matchingDeclaration = tempDeclarations.find((statDec) => statDec.id === action.payload.declaration.id);
            if(matchingDeclaration) {
                const newDeclaration: any = { ...defaultSimpleStatDec };
                for(const key of Object.keys(newDeclaration)) {
                    newDeclaration[key as keyof SimpleStatDec] = action.payload.declaration[key];
                }
                const clients = [];
                for(const client of action.payload.clients) {
                    delete client['label'];
                    clients.push(client);
                }
                newDeclaration.client_list = clients;
                tempDeclarations[tempDeclarations.indexOf(matchingDeclaration)] = newDeclaration;
            }
            return { ...state, statDecsInDeal: tempDeclarations };
        default:
            return state;
    }
}