import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    getGlobalChargeHolderCompanies,
    getDealChargeHolderCompanies,
    getDealChargeHolderCompany,
    createDealChargeHolderCompany,
    saveDealChargeHolderCompany
} from "../../../libs/service/axios/api";
import { ChargeHolderCompany, ChargeHolderCompanyListItem } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { sanitizeChargeHolderCompaniesResponse, sanitizeChargeHolderCompanyResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeChargeHolderCompanyRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleChargeHolderCompany } from "../../../libs/types/UniversalSurvey/ChargeHolderCompany/simpleChargeHolderCompany";
import { updateLienCompanyIdAndTabName, updateLienTabNamesOnChargeHolderCompanySave } from "./existingLiens";
import { sortByKey } from "../../../libs/utils/arrays";
import { Loading } from "../../../libs/resources/enums/loading";

function saveChargeHolderCompany(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: ChargeHolderCompany) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompany, isLoading: true } });
    saveDealChargeHolderCompany(dealId, String(companyInfo.id), sanitizeChargeHolderCompanyRequest(companyInfo))
        .then(function () {
            getChargeHolderCompanyInfo(dispatch, dealId, companyInfo.id);
            updateLienTabNamesOnChargeHolderCompanySave(dispatch, dealId, companyInfo);
            const newListItem: ChargeHolderCompanyListItem = {
                id: companyInfo.id,
                isRecord: true,
                label: companyInfo.name,
                name: companyInfo.name,
                abbr_name: companyInfo.abbr_name,
                general_address: companyInfo.general_address
            }
            dispatch({ type: actions.REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: companyInfo.id, newOption: newListItem, oldIsRecord: true }});
            dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: companyInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompany, isLoading: false } });
        })
}

function createChargeHolderCompanyRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, company: ChargeHolderCompanyListItem, currEntity: number) {
    createDealChargeHolderCompany(dealId, company.id)
        .then(function (response: any) {
            updateLienCompanyIdAndTabName(dispatch, response.data.id, company.name, currEntity);
            getChargeHolderCompanyInfo(dispatch, dealId, response.data.id);
            const newCompanyListItem: ChargeHolderCompanyListItem = {
                ...company,
                label: company.name,
                isRecord: true,
                id: response.data.id
            };
            dispatch({ type: actions.REPLACE_CHARGE_HOLDER_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: company.id, newOption: newCompanyListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: newCompanyListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewChargeHolderCompanyRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: ChargeHolderCompany, currEntity: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompany, isLoading: true } });
    createDealChargeHolderCompany(dealId)
        .then(function (response: any) {
            saveDealChargeHolderCompany(dealId, String(response.data.id), sanitizeChargeHolderCompanyRequest(companyInfo))
                .then(function () {
                    const newCompanyListItem: ChargeHolderCompanyListItem = {
                        id: response.data.id,
                        isRecord: true,
                        general_address: companyInfo.general_address,
                        name: companyInfo.name,
                        abbr_name: companyInfo.abbr_name,
                        label: companyInfo.name
                    };
                    dispatch({ type: actions.ADD_TO_CHARGE_HOLDER_COMPANY_OPTIONS, payload: newCompanyListItem });
                    dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: newCompanyListItem.id });
                    updateLienCompanyIdAndTabName(dispatch, response.data.id, companyInfo.name, currEntity);
                    getChargeHolderCompanyInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save charge holder company record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompany, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveChargeHolderCompany, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createChargeHolderCompanyOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, selectedCompanyId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyList, isLoading: true } });
    getDealChargeHolderCompanies(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizeChargeHolderCompaniesResponse(dealResponse.data);

            getGlobalChargeHolderCompanies()
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeChargeHolderCompaniesResponse(globalResponse.data.charge_holder_companies);
                    dispatch({ type: actions.SET_CHARGE_HOLDER_COMPANY_OPTION_LIST, payload: organizeCompanyList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_CHARGE_HOLDER_COMPANY, payload: selectedCompanyId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global charge holder companies: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.ChargeHolderCompanyList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get charge holder company records: ${error}`, type: AlertTypes.Error } });
        })
}

function getChargeHolderCompanyInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, companyRecordId: number) {
    getDealChargeHolderCompany(dealId, String(companyRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_CHARGE_HOLDER_COMPANY_INFO,
                payload: sanitizeChargeHolderCompanyResponse(response.data)
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get charge holder company record: ${error}`, type: AlertTypes.Error } });
        })
}

function organizeCompanyList(dealCompanyList: SimpleChargeHolderCompany[], globalCompanyList: SimpleChargeHolderCompany[]): ChargeHolderCompanyListItem[] {
    let cleanList: ChargeHolderCompanyListItem[] = [];
    let isRecordList: number[] = [];

    for (const company of dealCompanyList) {
        if (company.charge_holder_company?.id) {
            isRecordList.push(company.charge_holder_company.id);
        }
        cleanList.push({
            id: company.id,
            name: company.name,
            abbr_name: company.abbr_name,
            general_address: company.general_address,
            isRecord: true,
            label: company.name
        });
    }

    for (const company of globalCompanyList) {
        if (!isRecordList.includes(company.id)) {
            cleanList.push({
                id: company.id,
                name: company.name,
                abbr_name: company.abbr_name,
                general_address: company.general_address,
                isRecord: false,
                label: company.name
            });
        }
    }

    sortByKey(cleanList, "name");
    return cleanList;
}

export {
    saveChargeHolderCompany,
    createChargeHolderCompanyRecordFromGlobal,
    createNewChargeHolderCompanyRecord,
    createChargeHolderCompanyOptionsList,
    getChargeHolderCompanyInfo
}