import { Preview } from "../../../types/UniversalSurvey/Documents/previews/preview";

export const defaultPreview: Preview = {
    id: -1,
    document_id: -1,
    compile_status: undefined,
    compile_error: undefined,
    html: undefined,
    date_created: undefined,
    date_updated: undefined
}