import { SimpleUndertaking } from "../../../types/UniversalSurvey/Undertaking/simpleUndertaking";
import { UndertakingStatus } from "../../enums/undertakings";

export const defaultSimpleUndertaking: SimpleUndertaking = {
    id: -1,
    direction: undefined,
    status: UndertakingStatus.Outstanding,
    discharged_by: undefined,
    discharge_date: undefined,
    addressed_to: undefined
}