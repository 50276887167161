import { Grid } from "@mui/material";

import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import GenericDialog from "../../../../../../components/Common/Modal/GenericDialog";
import { AddressRequired } from "../../../../../libs/types/UniversalSurvey/Address/address";
import { defaultAddressRequired } from "../../../../../libs/resources/defaults/frontend/defaultAddressRequired";
import { LegalFirmOffice, LegalFirmOfficeErrorState } from "../../../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import AddressGrid from "../../AddressGrid/AddressGrid";
import PhoneField from "../../../../../../components/Common/PhoneField/PhoneField";

type Props = {
    officeObj: LegalFirmOffice;
    setOfficeObj: (office: LegalFirmOffice) => void;
    errorState?: LegalFirmOfficeErrorState
    setErrorState?: (state: LegalFirmOfficeErrorState) => void;
    discard: Function;
    confirmDiscardOpen: boolean;
    setConfirmDiscardOpen: (open: boolean) => void;
};

export default function LegalFirmOfficeForm(props: Props) {
    const {
        officeObj,
        setOfficeObj,
        errorState,
        setErrorState,
        discard,
        confirmDiscardOpen,
        setConfirmDiscardOpen
    } = props;

    function updateLegalFirmOfficeInfo<
        K extends keyof LegalFirmOffice,
        V extends LegalFirmOffice[K]
    >(key: K, value: V): void {
        const tempObj = { ...officeObj }
        tempObj[key] = value;
        setOfficeObj(tempObj);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        discard();
    }

    const lawOfficeAddressRequired: AddressRequired = {
        ...defaultAddressRequired,
        street_address: true,
        city: true,
        province_enum: true,
        postal_code: true
    }

    return (
        <>
            <GenericDialog
                action="neutral"
                title="Confirm Discard"
                onCancel={() => setConfirmDiscardOpen(false)}
                onSubmit={() => handleDiscardConfirm()}
                submitText="Discard"
                open={confirmDiscardOpen}
                contentText="Are you sure you want to discard your changes?"
            />
            <Grid container rowSpacing={2} columnSpacing={5}>    
                <Grid item xs={12}>
                    <AddressGrid
                        address={officeObj.general_address}
                        setAddress={(value) => updateLegalFirmOfficeInfo("general_address", value)}
                        addressRequired={lawOfficeAddressRequired}
                        id="general_address"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={officeObj.phone}
                        onChange={(value) => updateLegalFirmOfficeInfo("phone", value)}
                        label={{ text: "Phone Number", inputId: "phone" }}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "phone",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        value={officeObj.phone_extension ?? undefined}
                        onChange={(e) => updateLegalFirmOfficeInfo("phone_extension", e.target.value)}
                        label={{text: "Extension", inputId: "phone_extension"}}
                        placeholder="Extension"
                        inputProps={{ "aria-label": "Extension" }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhoneField
                        value={officeObj.fax}
                        onChange={(value) => updateLegalFirmOfficeInfo("fax", value)}
                        label={{ text: "Fax Number", inputId: "fax" }}
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "fax",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <BasicTextInput
                        fullWidth
                        value={officeObj.email ?? undefined}
                        onChange={(e) => updateLegalFirmOfficeInfo("email", e.target.value)}
                        label={{ text: "Email", inputId: "email" }}
                        placeholder="Email"
                        fieldType="email"
                        error={{
                            showError: true,
                            errorState: errorState,
                            errorKey: "email",
                            setErrorState: setErrorState
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}