import { BaseTemplate, BaseTemplateHtml } from "../../../types/UniversalSurvey/Documents/templates/baseTemplate";
import { DocumentCategory } from "../../enums/documents/category";
import { DocumentType } from "../../enums/documents/documentType";

export const defaultBaseTemplate: BaseTemplate = {
    id: -1,
    parent_id: undefined,
    type: DocumentType.StatDec,
    name: undefined,
    category: DocumentCategory.Template,
    date_created: undefined,
    date_updated: undefined,

    tags: [],
    context: undefined
}

export const defaultBaseTemplateHtml: BaseTemplateHtml = {
    ...defaultBaseTemplate,
    template: undefined
}