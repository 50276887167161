// Importing MUI components
import { styled } from '@mui/material';

// Importing from DCP UI Library
import { Small } from '../Typography/index';

type PillProps = {
    style: {
        backgroundColor: string,
        color: string,
        border?: string,
        margin?: string,
        width?: string
    }
    label: string,
    icon?: React.ReactNode
}

export default function Pill(props: PillProps) {
    
    return (
        <StyledChip style={props.style}>
            <Small style={{color: props.style.color}}>{props.label}</Small>
            {props.icon}
        </StyledChip>
    );
}

const StyledChip = styled('div')({
    display: "flex",
    whiteSpace: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "5rem",
    height: "2.8rem",
    textTransform: "none",
    padding: "0.2rem 0.2rem 0.2rem 1rem",
    gap: "0.5rem"
});