export enum TransactionTypes {
    Mortgage = "Mortgage",
    Sale = "Sale",
    Purchase = "Purchase",
    ReverseMortgage = "Reverse Mortgage",
    ILA = "Independent Legal Advice",
    TitleTransfer = "Title Transfer",
    TransferOfCharge = "Transfer of Charge",
    Deal = "Deal",
    AddTransaction = "Add Transaction"
};

export enum TransactionTypeDB {
    Mortgage = "mortgage",
    TitleTransfer = "title_transfer",
    ILA = "ila",
    Deal = "deal"
}