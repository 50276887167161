import { SimpleLien } from "../../../types/UniversalSurvey/ExistingLien/simpleLien";

export const defaultSimpleLien: SimpleLien = {
    id: -1,
    instrument_type: undefined,
    charge_holder_type: undefined,
    mortgage_loan_number: undefined,
    account_number: undefined,
    is_to: undefined,
    priority_after_closing: undefined,
    priority_before_closing: undefined,
    payout_calc_start_date: undefined,
    payout_calc_end_date: undefined,
    payout_calc_principal:  undefined,
    payout_calc_amount_per_day: undefined,
    separate_cheque_for_discharge_fee: false,
    payout_calc_discharge_fee: undefined,

    lender_record: undefined,
    charge_holder_company_record: undefined,
    government_record: undefined,
    other_charge_holder_record: undefined,
    payout_calc_additional_amount: []
}