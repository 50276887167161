// Importing React tools
import { useContext, useState, useEffect, useCallback, useRef, Fragment } from "react";

// Importing mui design kit components
import { Grid, Stack, styled } from "@mui/material";

// Importing state management
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';

// Importing application components
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { BodyBold, H4 } from "../../../../components/Common/Typography/index";
import color from '../../../../components/Common/componentStyling/Colors';
import BasicButton from "../../../../components/Common/Button/BasicButton";
import LineItemInput from "../../../../components/Common/LineItemInput/LineItemInput";
import { debounce } from "../../../libs/utils/debounce";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import {
    createNewTrustLedger,
    deleteTrustLedgerItem,
    getTrustLedgersInDeal,
    saveTrustLedgerInDeal
} from "../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgers";
import { TrustLedgerSource, TrustLedgerType } from "../../../libs/resources/enums/trustLedger";
import Theme from "../../../../components/Common/componentStyling/Theme";
import { TrustLedger } from "../../../libs/types/UniversalSurvey/TrustLedger/trustLedger";
import { defaultTrustLedger } from "../../../libs/resources/defaults/frontend/defaultTrustLedger";
import { SimpleTrustLedger } from "../../../libs/types/UniversalSurvey/TrustLedger/simpleTrustLedger";
import { PurchaseIcon } from "../../../../components/Common/Icons/Iconography";
import Colors from "../../../../components/Common/componentStyling/Colors";
import PaymentGroupingModal from "../Components/Modals/PaymentGroupModal/PaymentGroupingModal";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import Decimal from "decimal.js";
import { getClientTabName, getDealClients } from "../../../context/UniversalSurvey/asyncActions/clients";
import { TransactionTypes } from "../../../libs/resources/enums/transactions";
import { RoleTypesDB } from "../../../libs/resources/enums/roles";
import { RecordType } from "../../../libs/resources/enums/recordTypes";

export default function TrustLedgerSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [trustLedgers, setTrustLedgers] = useState<SimpleTrustLedger[]>([]);
    const [cashShortfall, setCashShortfall] = useState<SimpleTrustLedger | undefined>(undefined);
    const [balanceToBorrower, setBalanceToBorrower] = useState<SimpleTrustLedger | undefined>(undefined);
    const [expenditureTotal, setExpenditureTotal] = useState<Decimal>(new Decimal(0));
    const [receiptTotal, setReceiptTotal] = useState<Decimal>(new Decimal(0));
    const [paymentGroupingModalOpen, setPaymentGroupingModalOpen] = useState<boolean>(false);

    const tlRef = useRef<SimpleTrustLedger[]>(state.trustLedgers.trustLedgers);

    useEffect(() => {
        getTrustLedgersInDeal(dispatch, String(state.deal.dealInfo?.id), Loading.TrustLedgerListSection);
        cleanUpEmptyTrustLedger();
        for(const ledgerItem of state.trustLedgers.trustLedgers) {
            if (state.trustLedgers.trustLedgersBeingSaved.includes(ledgerItem.id)) {
                dispatch({ type: actions.SET_IS_TRUST_LEDGER_SAVING, payload: true });
            }
        }
        getDealClients(dispatch, String(state.deal.dealInfo?.id));

        return () => cleanUpEmptyTrustLedger();
    }, [])

    useEffect(() => {
        if (state.trustLedgers.shouldRefreshTrustLedger) {
            getTrustLedgersInDeal(dispatch, String(state.deal.dealInfo?.id), Loading.TrustLedgerListSection);
            dispatch({ type: actions.SET_SHOULD_REFRESH_TRUST_LEDGER, payload: false });
        }
    }, [state.trustLedgers.shouldRefreshTrustLedger]);

    useEffect(() => {
        const ledgers = state.trustLedgers.trustLedgers.filter((tl) => tl.source !== TrustLedgerSource.Balance)
        tlRef.current = ledgers;
        setTrustLedgers(ledgers);

        let tempExpCount = new Decimal(0);
        let tempRecCount = new Decimal(0);
        for (const ledgerItem of ledgers) {
            if (ledgerItem.type === TrustLedgerType.Expenditure && ledgerItem.amount) {
                tempExpCount = tempExpCount.plus(ledgerItem.amount);
            } else if (ledgerItem.type === TrustLedgerType.Receipt && ledgerItem.amount) {
                tempRecCount = tempRecCount.plus(ledgerItem.amount);
            }
        }
        setExpenditureTotal(tempExpCount);
        setReceiptTotal(tempRecCount);

        let customTLBalancer: TrustLedger = { ...defaultTrustLedger };
        if (tempExpCount.comparedTo(tempRecCount) === 0) {
            setBalanceToBorrower(undefined);
            setCashShortfall(undefined);
        } else if (tempExpCount.comparedTo(tempRecCount) === 1) {
            customTLBalancer.type = TrustLedgerType.Receipt;
            customTLBalancer.name = "Cash Shortfall";
            customTLBalancer.amount = tempExpCount.sub(tempRecCount).toNumber();
            setCashShortfall(customTLBalancer);
            setBalanceToBorrower(undefined);
        } else if (tempExpCount.comparedTo(tempRecCount) === -1) {
            customTLBalancer.type = TrustLedgerType.Expenditure;
            const primaryClients = getPrimaryClients();
            customTLBalancer.name = `Balance to ${primaryClients.length > 0 ? primaryClients.map((client) => getClientTabName(client, true)).join(" and/or ") : "borrowers"}`;
            customTLBalancer.amount = tempRecCount.sub(tempExpCount).toNumber();
            setBalanceToBorrower(customTLBalancer);
            setCashShortfall(undefined);
        }
    }, [state.trustLedgers.trustLedgers]);

    function getPrimaryClients() {
        switch (state.deal.dealInfo?.primary_transaction_type) {
            case TransactionTypes.Mortgage:
                return state.clients.clientsInDeal.filter((client) => client.roles?.some((role) => role.role === RoleTypesDB.Borrower && role.record_type === RecordType.Mortgage && role.record_id === state.deal.dealInfo?.primary_transaction_id));
            default:
                return state.clients.clientsInDeal.filter((client) => client.roles?.some((role) => role.role === RoleTypesDB.Borrower && role.record_type === RecordType.Mortgage && role.record_id === state.deal.dealInfo?.primary_transaction_id));
        }
    }

    function cleanUpEmptyTrustLedger() {
        for (const ledgerItem of tlRef.current) {
            if (isNameEmpty(ledgerItem) && isValueEmpty(ledgerItem)) {
                deleteTrustLedgerItem(dispatch, String(state.deal.dealInfo?.id), ledgerItem.id);
            }
        }
    }

    function updateTrustLedgerInfo<
        K extends keyof SimpleTrustLedger,
        V extends SimpleTrustLedger[K]
    >(key: K, value: V, trustLedgerRecordId: number): void {
        if (Number.isNaN(value)) {
            return;
        }
        dispatch({ type: actions.ADD_TRUST_LEDGER_BEING_SAVED, payload: trustLedgerRecordId });
        const matchingLedgerItem = trustLedgers.find((ledgerItem) => ledgerItem.id === trustLedgerRecordId);
        if (matchingLedgerItem) {
            const tempLedgerItems = [ ...trustLedgers ];
            const tempLedgerItem = { ...matchingLedgerItem };
            tempLedgerItem[key] = value;
            tempLedgerItems[tempLedgerItems.indexOf(matchingLedgerItem)] = tempLedgerItem;
            setTrustLedgers(tempLedgerItems);
            dispatch({ type: actions.SET_TRUST_LEDGERS, payload: tempLedgerItems});
            debouncedSaveTrustLedgerItem(tempLedgerItem, [...state.trustLedgers.trustLedgersBeingSaved, trustLedgerRecordId]);
        }
    }

    const debouncedSaveTrustLedgerItem = useCallback(
        debounce((ledgerItem, ledgerItemsBeingSaved) => {
            if (ledgerItemsBeingSaved!.includes(ledgerItem.id)) {
                saveTrustLedgerInDeal(dispatch, String(state.deal.dealInfo?.id), ledgerItem);
                dispatch({ type: actions.REMOVE_TRUST_LEDGER_BEING_SAVED, payload: ledgerItem.id });
            }
        }, 1000), []);

    function createTrustLedgerButtonDisabled(): boolean {
        for (const ledgerItem of state.trustLedgers.trustLedgers) {
            if (isNameEmpty(ledgerItem) || isValueEmpty(ledgerItem)) {
                return true;
            }
        }
        return false;
    }

    function isNameEmpty(ledgerItem: SimpleTrustLedger): boolean {
        return (ledgerItem.name === undefined || ledgerItem.name === "")
    }

    function isValueEmpty(ledgerItem: SimpleTrustLedger): boolean {
        return (ledgerItem.amount === undefined || ledgerItem.amount === 0)
    }

    function makeTrustLedgers() {
        return trustLedgers.map((ledgerItem, index) => {
            switch(ledgerItem.source) {
                case TrustLedgerSource.ReceiptMortgageFunds:
                    return (
                        <Fragment key={index}>
                            <LineItemInput
                                textValue={ledgerItem.name}
                                size={6}
                                valueItems={[
                                    {
                                        size: 6,
                                        isEmpty: true,
                                    }
                                ]}
                                readOnly
                                hideUnderline
                            />
                            {ledgerItem.children.map((ledgerChild, childIndex) => (
                                <LineItemInput
                                    key={`${index}-${childIndex}`}
                                    textValue={ledgerChild.name}
                                    size={6}
                                    readOnly
                                    indent
                                    valueItems={[
                                        {
                                            size: 2,
                                            value: String(ledgerChild.amount)
                                        },
                                        {
                                            size: 2,
                                            isEmpty: true
                                        },
                                        {
                                            size: 2,
                                            value: (childIndex === ledgerItem.children.length - 1) ? String(ledgerItem.amount) : "",
                                            isEmpty: (childIndex === ledgerItem.children.length - 1) ? false : true
                                        }
                                    ]}
                                    hideUnderline={(childIndex === ledgerItem.children.length - 1) ? false : true}
                                />
                            ))}
                        </Fragment>
                    )
                default:
                    return (
                        <LineItemInput
                            key={index}
                            textValue={ledgerItem.name}
                            placeholder="New line item"
                            textOnChange={ledgerItem.source === TrustLedgerSource.Manual ? (e) => updateTrustLedgerInfo("name", e.target.value as string, ledgerItem.id) : undefined}
                            size={6}
                            valueItems={[
                                {
                                    size: 2,
                                    isEmpty: true,
                                },
                                {
                                    size: 2,
                                    isEmpty: ledgerItem.type === TrustLedgerType.Receipt ? true : false,
                                    value: ledgerItem.amount !== undefined ? String(ledgerItem.amount) : undefined,
                                    onChange: ledgerItem.source === TrustLedgerSource.Manual ? (e) => updateTrustLedgerInfo("amount", e.target.value as number, ledgerItem.id) : undefined
                                },
                                {
                                    size: 2,
                                    isEmpty: ledgerItem.type === TrustLedgerType.Expenditure ? true : false,
                                    value: ledgerItem.amount !== undefined ? String(ledgerItem.amount) : undefined,
                                    onChange: ledgerItem.source === TrustLedgerSource.Manual ? (e) => updateTrustLedgerInfo("amount", e.target.value as number, ledgerItem.id) : undefined
                                }
                            ]}
                            readOnly={ledgerItem.source !== TrustLedgerSource.Manual}
                            onRemove={ledgerItem.source === TrustLedgerSource.Manual ? () => deleteTrustLedgerItem(dispatch, String(state.deal.dealInfo?.id), ledgerItem.id) : undefined}
                        />
                    );
            }
        })
    }

    return (
        isObjectLoading(state.dataSheet.objectsLoading, [Loading.TrustLedgerListSection]) ? <CircularLoader containerHeight="70vh" /> :
        <div>
            <PaymentGroupingModal
                open={paymentGroupingModalOpen}
                onClose={() => setPaymentGroupingModalOpen(false)}
            />
            <AccountingContainer>
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={7} />
                    <Grid item xs={2}>
                        <RightAlignedH4>Expenditure</RightAlignedH4>
                    </Grid>
                    <Grid item xs={2}>
                        <RightAlignedH4>Receipt</RightAlignedH4>
                    </Grid>
                    <Grid item xs={12}>
                        {makeTrustLedgers()}
                        {balanceToBorrower && (
                            <LineItemInput
                                textValue={balanceToBorrower.name}
                                size={8}
                                valueItems={[
                                    {
                                        size: 2, 
                                        value: String(balanceToBorrower.amount), 
                                    },
                                    {
                                        size: 2, 
                                        isEmpty: true  
                                    }
                                ]}
                                readOnly
                            />
                        )}
                        {cashShortfall && (
                            <LineItemInput
                                textValue={cashShortfall.name}
                                size={8}
                                fontColor={Theme.ERROR}
                                valueItems={[ 
                                    {
                                        size: 2, 
                                        isEmpty: true  
                                    },
                                    {
                                        size: 2, 
                                        value: String(cashShortfall.amount), 
                                    }
                                ]}
                                readOnly
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={2}>
                            <BasicButton
                                label={{ text: "Add Expenditure", inputId: "add-expenditure" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createNewTrustLedger(dispatch, String(state.deal.dealInfo?.id), TrustLedgerType.Expenditure)}
                                disabled={createTrustLedgerButtonDisabled()}
                            />
                            <BasicButton
                                label={{ text: "Add Receipt", inputId: "add-receipt" }}
                                action="add"
                                typeOf="secondary"
                                disabled={createTrustLedgerButtonDisabled()}
                                onClick={() => createNewTrustLedger(dispatch, String(state.deal.dealInfo?.id), TrustLedgerType.Receipt)}
                            />
                            <BasicButton
                                label={{ text: "Payment Grouping", inputId: "open-payment-grouping" }}
                                leftIcon={<PurchaseIcon color={Colors.BLACK} />}
                                typeOf="secondary"
                                onClick={() => setPaymentGroupingModalOpen(true)}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </AccountingContainer>
            <DividerContainer>
                <TotalDivider />
            </DividerContainer>
            <Grid container>
                <Grid item xs={6}>
                    <BodyBold>
                        Totals:
                    </BodyBold>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="row" justifyContent="flex-end">
                        <BodyBold>
                            <FormatNumeric value={expenditureTotal.plus(balanceToBorrower ? balanceToBorrower.amount ? balanceToBorrower.amount : 0 : 0).toNumber()} />
                        </BodyBold>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="row" justifyContent="flex-end">
                        <BodyBold>
                            <FormatNumeric value={receiptTotal.plus(cashShortfall ? cashShortfall.amount ? cashShortfall.amount : 0 : 0).toNumber()} />
                        </BodyBold>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
};

const AccountingContainer = styled('div')({
    paddingTop: "4rem",
})

const DividerContainer = styled('div')({
    paddingTop: "5rem",
    paddingBottom: "5rem"
});

const TotalDivider = styled("div")({
    height: "0.2rem",
    width: "100%",
    backgroundColor: color.BLACK,
})

const RightAlignedH4 = styled(H4)({
    textAlign: "right",
})