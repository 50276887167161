import { useContext, useEffect, useState } from "react";
import * as _ from "lodash";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import * as actions from "../../../../context/UniversalSurvey/actionTypes"
import BasicTextInput from "../../../../../components/Common/TextField/BasicTextInput";
import ModalBase, { ModalProps } from "../../../../../components/Common/Modal/ModalBase"
import { Undertaking } from "../../../../libs/types/UniversalSurvey/Undertaking/undertaking";
import { defaultUndertaking } from "../../../../libs/resources/defaults/frontend/defaultUndertaking";
import GenericDialog from "../../../../../components/Common/Modal/GenericDialog";
import ComboBox from "../../../../../components/Common/ComboBox/ComboBox";
import BooleanControl from "../../../../../components/Common/BooleanControl/BooleanControl";
import BasicDatePicker from "../../../../../components/Common/DatePicker/BasicDatePicker";
import { addUndertaking, updateUndertaking } from "../../../../context/UniversalSurvey/asyncActions/undertakings";
import { undertakingDischargeNotes, undertakingDirectionOptions, AddressedToOptions } from "../../../../libs/resources/options";
import { DischargedBy, UndertakingStatus } from "../../../../libs/resources/enums/undertakings";
import { deleteEntityFromOwnModal } from "../../../../context/UniversalSurvey/asyncActions/conditions";
import { Sections } from "../../../../libs/resources/enums/sections";
import { SimpleCondition } from "../../../../libs/types/UniversalSurvey/Condition/simpleCondition";
import { isObjectLoading } from "../../../../libs/utils/loading";
import { Loading } from "../../../../libs/resources/enums/loading";
import Dropdown from "../../../../../components/Common/Dropdown/Dropdown";
import { AddressedTo } from "../../../../libs/resources/enums/addressedTo";
import { formatAddressedTo } from "../../../../libs/utils/formatValues";

type Props = Omit<ModalProps, "children">

export default function UndertakingModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [undertakingObj, setUndertakingObj] = useState<Undertaking>({ ...defaultUndertaking });
    const [originalCopy, setOriginalCopy] = useState<Undertaking>({ ...defaultUndertaking });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [saveClickedWhenDisabled, setSaveClickedWhenDisabled] = useState<boolean>(false);

    useEffect(() => {
        if(state.undertakings.undertakingBeingEdited) {
            setUndertakingObj(state.undertakings.undertakingBeingEdited);
            setOriginalCopy(state.undertakings.undertakingBeingEdited);
        }
    }, [state.undertakings.undertakingBeingEdited])

    function updateUndertakingInfo<
        K extends keyof Undertaking,
        V extends Undertaking[K]
    >(key: K, value: V): void {
        const tempUndertakingObj = { ...undertakingObj }
        tempUndertakingObj[key] = value;
        setUndertakingObj(tempUndertakingObj);
    }

    function handleDiscardConfirm() {
        setConfirmDiscardOpen(false);
        onDiscard();
    }

    function onDiscard() {
        props.onClose ? props.onClose() : undefined;
        dispatch({ type: actions.SET_UNDERTAKING_BEING_EDITED, payload: undefined })
        setUndertakingObj({ ...defaultUndertaking });
        setOriginalCopy({ ...defaultUndertaking });
    }

    function handleDiscardClick() {
        if (_.isEqual(undertakingObj, originalCopy)) {
            onDiscard();
        } else {
            setConfirmDiscardOpen(true);
        }
    }

    function submit() {
        if (state.undertakings.undertakingBeingEdited) {
            updateUndertaking(dispatch, String(state.deal.dealInfo?.id), undertakingObj);
        } else {
            addUndertaking(dispatch, String(state.deal.dealInfo?.id), undertakingObj);
        }
    }

    function onDeleteConfirm() {
        let condition: SimpleCondition | undefined;
        if (state.dataSheet.currSection === Sections.Encumbrances) {
            condition = state.existingLiens.existingLienInfo?.condition_record;
            dispatch({ type: actions.DELETE_LIEN_UNDERTAKING_CONDITION });
        } else {
            condition = state.conditions.conditionsInDeal.find((condition) => condition.undertaking?.id === undertakingObj.id);
        }
        deleteEntityFromOwnModal(dispatch, String(state.deal.dealInfo?.id), "undertaking", undertakingObj.id, condition);
        onDiscard();
    }

    function isSaveDisabled(): boolean {
        if (!undertakingObj.direction) {
            return true;
        }
        if (_.isEqual(originalCopy, undertakingObj)) {
            return true;
        }
        if (undertakingObj.status === UndertakingStatus.Fulfilled) {
            if (!undertakingObj.discharge_date) {
                return true;
            }
            if (undertakingObj.discharged_by === DischargedBy.Instrument && !undertakingObj.instrument_number) {
                return true;
            } else if (undertakingObj.discharged_by === DischargedBy.Manual && !undertakingObj.discharge_note) {
                return true;
            }
        }
        return false;
    }

    return (
        <ModalBase
            title={`${state.undertakings.undertakingBeingEdited ? "" : "New "}Undertaking`}
            open={props.open}
            onClose={() => handleDiscardClick()}
            onSubmit={submit}
            deleteButton={state.undertakings.undertakingBeingEdited ? true : false}
            confirmDeleteText="Are you sure you want to delete this undertaking, which is linked to a condition? This is permanent and irreversible."
            saveDisabled={isSaveDisabled()}
            discardButtonLabel={`Discard${state.undertakings.undertakingBeingEdited ? " Changes": ""}`}
            onDelete={onDeleteConfirm}
            saveDisabledOnClick={() => setSaveClickedWhenDisabled(true)}
            isLoading={isObjectLoading(state.dataSheet.objectsLoading, [Loading.UndertakingModal])}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveUndertaking])}
        >
            <>
                <GenericDialog
                    action="neutral"
                    title="Confirm Discard"
                    onCancel={() => setConfirmDiscardOpen(false)}
                    onSubmit={() => handleDiscardConfirm()}
                    submitText="Discard"
                    open={confirmDiscardOpen}
                    contentText="Are you sure you want to discard your changes?"
                />
                <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                        <Dropdown
                            label={{ text: "Addressed To", inputId: "undertaking-modal-addressed-to" }}
                            value={undertakingObj.addressed_to}
                            onChange={(e) => updateUndertakingInfo("addressed_to", e.target.value as AddressedTo)}
                            placeholder="Select an option..."
                            options={AddressedToOptions}
                            formatValue={formatAddressedTo}
                            isHoverActionHidden={undertakingObj.addressed_to ? false : true}
                            handleClear={() => updateUndertakingInfo("addressed_to", undefined)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            readOnly
                            label={{ text: "Condition", inputId: "undertaking-modal-condition" }}
                            placeholder="Undertaking condition"
                            value={undertakingObj?.condition ?? undefined}
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <ComboBox
                            label={{ text: "Directions", inputId: "undertaking-modal-directions", isRequired: true }}
                            options={undertakingDirectionOptions}
                            placeholder="The solicitor for the mortgagor hereby personally undertakes to..."
                            value={undertakingObj?.direction ?? undefined}
                            onChangeFn={(value) => updateUndertakingInfo("direction", value as string)}
                            text={undertakingObj.direction}
                            setText={(text: string) => updateUndertakingInfo("direction", text)}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BooleanControl
                            checked={undertakingObj.status === UndertakingStatus.Outstanding ? false : true}
                            labels={["Outstanding", "Fulfilled"]}
                            onChange={() => {
                                updateUndertakingInfo("status", undertakingObj.status === UndertakingStatus.Outstanding ? UndertakingStatus.Fulfilled : UndertakingStatus.Outstanding);
                                setSaveClickedWhenDisabled(false);
                            }}
                            label={{ text: "Status", inputId: "undertaking-modal-status" }}
                        />
                    </Grid>
                    {undertakingObj.status === UndertakingStatus.Fulfilled && (
                        <>
                            <Grid item xs={6}>
                                <BooleanControl
                                    checked={undertakingObj.discharged_by === DischargedBy.Manual ? true : false}
                                    labels={["Instrument", "Manual Action"]}
                                    onChange={() => updateUndertakingInfo("discharged_by", undertakingObj.discharged_by === DischargedBy.Instrument ? DischargedBy.Manual : DischargedBy.Instrument)}
                                    label={{ text: "Discharged By", inputId: "undertaking-modal-discharged-by" }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: -5 }} />
                            {undertakingObj.discharged_by === "manual" ? (
                                <Grid item xs={8}>
                                    <ComboBox
                                        label={{ text: "Discharge Note", inputId: "undertaking-modal-discharge-note", isRequired: true }}
                                        options={undertakingDischargeNotes}
                                        placeholder="Method of discharge"
                                        value={undertakingObj?.discharge_note ?? undefined}
                                        onChangeFn={(value) => updateUndertakingInfo("discharge_note", value as string)}
                                        text={undertakingObj.discharge_note}
                                        setText={(text: string) => updateUndertakingInfo("discharge_note", text)}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={8}>
                                    <BasicTextInput
                                        fullWidth
                                        label={{ text: "Instrument No.", inputId: "undertaking-modal-instrument-number", isRequired: true }}
                                        placeholder="Instrument no."
                                        value={undertakingObj?.instrument_number ?? undefined}
                                        onChange={(e) => updateUndertakingInfo("instrument_number", e.target.value)}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <BasicDatePicker
                                    value={undertakingObj.discharge_date ?? null}
                                    onChange={(e) => updateUndertakingInfo("discharge_date", e as Date)}
                                    label={{ text: "Discharge Date", isRequired: true }}
                                    floatRight
                                    error={!undertakingObj.discharge_date && saveClickedWhenDisabled}
                                    errorMessage={(!undertakingObj.discharge_date && saveClickedWhenDisabled) ? "Required when Status is Fulfilled" : undefined}
                                    minDate={state.deal.dealInfo?.opened_date ?? undefined}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        </ModalBase>
    );
}