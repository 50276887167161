import { TrustLedger } from "../../../types/UniversalSurvey/TrustLedger/trustLedger";
import { TrustLedgerSource } from "../../enums/trustLedger";

export const defaultTrustLedger: TrustLedger = {
    id: -1, 
    type: undefined,
    source: TrustLedgerSource.Manual,
    amount: 0,
    name: undefined,
    payment_group_id: undefined,
    delivery_method: undefined,
    date_created: undefined,
    date_updated: undefined,

    children: []
}