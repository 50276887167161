import { LetterToMortgageeNewMortgageContentType } from "../../../enums/documents/letterToMortgageeNewMortgage";
import { LetterToMortgageeNewMortgageContext, LetterToMortgageeNewMortgageContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/letterToMortgageeNewMortgageDocument";
import { defaultSimpleMortgageTransaction } from "../../frontend/defaultSimpleMortgageTransaction";

export const defaultLetterToMortgageeNewMortgageContext: LetterToMortgageeNewMortgageContext = {
    mortgage_id: -1,
    content_type: LetterToMortgageeNewMortgageContentType.Blank,
    
    mortgage_record: defaultSimpleMortgageTransaction
}

export const defaultLetterToMortgageeNewMortgageContextRequest: LetterToMortgageeNewMortgageContextRequest = {
    mortgage_id: -1,
    content_type: LetterToMortgageeNewMortgageContentType.Blank
}