import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { UniversalSurveyContext } from "../../../context/UniversalSurvey/context";
import * as actions from "../../../context/UniversalSurvey/actionTypes";

// Importing MUI components
import { styled } from "@mui/material";

// Importing from DCP UI Library
import { H2, H4, Small } from "../../../../components/Common/Typography";
import colors from "../../../../components/Common/componentStyling/Colors";
import theme from "../../../../components/Common/componentStyling/Theme";
import {
    BundledIcon,
    CheckCircle,
    DownloadIcon,
    PdfIcon,
    PlusIcon,
    SavingCloud,
    TrashIcon,
    XCircle,
    FolderIcon,
    FolderOpenIcon,
    EditIcon
} from "../../../../components/Common/Icons/Iconography";

// Importing application components
import BasicButton from "../../../../components/Common/Button/BasicButton";
import TableBase, { orderOptions } from "../../../../components/Common/Table/TableBase";
import {
    addSuggestedDoc,
    downloadGeneratedDocument,
    getDocumentList,
    createPreviewDoc,
    refreshSuggestedDocs,
    removeFolder,
    removeTemplateDocument,
    updateFolder,
    updateGeneratedDocument,
    updateTemplateDocument,
    removeGeneratedDocument,
    addFolder,
    viewGeneratedDocument,
    removeUploadedDocument,
    updateUploadedDocument,
    viewUploadedDocument,
    produceMultipleDoc,
    canAccessGeneratedDocument,
    downloadUploadedDocument
} from "../../../context/UniversalSurvey/asyncActions/documents";
import { DocumentStatus } from "../../../libs/resources/enums/documents/documentStatus";
import { DocumentCategory } from "../../../libs/resources/enums/documents/category";
import { getStrOutput } from "../../../libs/utils/pluralizationFunctions";
import { getClientTabName } from "../../../context/UniversalSurvey/asyncActions/clients";
import { formatAddressedTo, formatDocumentCategory } from "../../../libs/utils/formatValues";
import { stringToDateFormat } from "../../../libs/utils/dateHelper";
import { Document } from "../../../libs/types/UniversalSurvey/Documents/baseDocument";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import EditableField from "../../../../components/Common/EditableField/EditableField";
import CircularLoader from "../../../../components/Common/Loader/CircularLoader";
import { debounce } from "../../../libs/utils/debounce";
import { sortChildren, sortDocRows } from "../../../libs/utils/arrays";
import AddDocModal from "../Components/Modals/AddDocModal";
import DocumentViewerModal from "../Components/Modals/DocumentViewerModal";
import { ViewerDocTypeOptions, ViewerModeOptions } from "../../../../components/Common/DocumentViewer/DocumentViewer";
import { TransactionTypes } from "../../../libs/resources/enums/transactions";
import { GenerateType } from "../../../libs/resources/enums/documents/generateType";
import UploadDocumentModal from "../Components/Modals/UploadDocumentModal";
import { DocumentType } from "../../../libs/resources/enums/documents/documentType";
import { labelForm9CContextOption } from "../../../libs/types/UniversalSurvey/Documents/context/form9cContext";
import { labelLetterToMortgageeExistingMortgageContextOption } from "../../../libs/types/UniversalSurvey/Documents/context/letterToMortgageeExistingMortgageContext";
import { getClientsString } from "../../../libs/utils/clients";
import { isObjectLoading } from "../../../libs/utils/loading";
import { Loading } from "../../../libs/resources/enums/loading";
import { UndertakingDocumentType } from "../../../libs/resources/enums/documents/undertaking";

export type DocRow = Document & {
    tableContext?: string;
    tableType?: string;
    added?: string;
    produced?: string;
    versions?: string;
    children?: DocRow[];
    tableName?: JSX.Element | string;
}

export default function DocumentsContainer() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [dealDocsFilter, setDealDocsFilter] = useState<string | null>(null);
    const [suggestedDocsFilter, setSuggestedDocsFilter] = useState<string | null>(null);
    const [deleteButtonHoveredRow, setDeleteButtonHoveredRow] = useState<boolean>(false);
    const [deleteButtonHoveredTitle, setDeleteButtonHoveredTitle] = useState<boolean>(false);
    const [deleteDocModalActive, setDeleteDocModalActive] = useState<boolean>(false);
    const [docsToBeDeleted, setDocsToBeDeleted] = useState<DocRow[]>([]);
    const [dealDocRows, setDealDocRows] = useState<DocRow[]>([]);
    const [suggestedDocRows, setSuggestedDocRows] = useState<DocRow[]>([]);
    const [dealDocsSortOrder, setDealDocsSortOrder] = useState<orderOptions>(undefined);
    const [dealDocsSortKey, setDealDocsSortKey] = useState<string>("name");
    const [suggestedDocsSortOrder, setSuggestedDocsSortOrder] = useState<orderOptions>(undefined);
    const [suggestedDocsSortKey, setSuggestedDocsSortKey] = useState<string>("name");
    const [addDocsModalActive, setAddDocsModalActive] = useState<boolean>(false);
    const [viewerActive, setViewerActive] = useState<boolean>(false);
    const [viewerDocType, setViewerDocType] = useState<ViewerDocTypeOptions>("html");
    const [viewerMode, setViewerMode] = useState<ViewerModeOptions | undefined>(undefined);
    const [uploadDocModalActive, setUploadDocModalActive] = useState<boolean>(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

    const searchRef = useRef<string>("");

    useEffect(() => {
        getDocumentList(dispatch, String(state.deal.dealInfo?.id));

        return () => {
           if (intervalId) clearInterval(intervalId);
            dispatch({ type: actions.SET_DOC_DISCOVERY_STATUS, payload: DocumentStatus.Processing });
        };
    }, []);

    useEffect(() => {
        makeDealDocRows();
    }, [state.documents.documentList])

    useEffect(() => {
        makeSuggestedDocRows();
    }, [state.documents.suggestedDocList])

    useEffect(() => {
        handleSuggestedDocStatus(state.documents.discoveryStatus);
    }, [state.documents.discoveryStatus])

    useEffect(() => {
        // TODO: filter deal docs
    }, [dealDocsFilter])

    useEffect(() => {
        // TODO: filter suggested docs
    }, [suggestedDocsFilter])

    function handleSuggestedDocStatus(status: DocumentStatus | undefined) {
        if (intervalId) clearInterval(intervalId);
        if (state.documents.discoveryStatus === DocumentStatus.Complete || state.documents.discoveryStatus === DocumentStatus.Error) {
            clearInterval(intervalId);
            makeSuggestedDocRows();
        } else {
            let newIntervalId = setInterval(() => refreshSuggestedDocs(dispatch, String(state.deal.dealInfo?.id)), 2000);
            setIntervalId(newIntervalId);
        }
    }

    function makeDealDocRows() {
        const dealDocs = state.documents.documentList.filter((doc) => doc.category !== DocumentCategory.Folder);
        const dealFolders = state.documents.documentList.filter((doc) => doc.category === DocumentCategory.Folder);
        const docRows: DocRow[] = [];

        const tempDealFolders: DocRow[] = [];
        const tempHighestLevelFolders: DocRow[] = [];
        for (const folder of dealFolders) {
            tempDealFolders.push({
                ...folder,
                tableType: "",
                added: "",
                produced: "",
                versions: "",
                tableName: <FolderWrapper>
                                <FolderIconWrapper onClick={(e) => toggleFolderOpen(folder.id, e)}>
                                    {folder.open ? <FolderOpenIcon color={colors.BLACK}/> : <FolderIcon color={colors.BLACK}/>}
                                </FolderIconWrapper>
                                <EditableField
                                    initialEditing={folder.editing ? true : false}
                                    value={folder.name}
                                    placeholder="Folder name"
                                    defaultName="New Folder"
                                    onSave={(newName: string) => updateDoc(newName, folder.parent_id, folder)}
                                    onClick={() => toggleFolderOpen(folder.id)}
                                />
                            </FolderWrapper>,
                children: []
            });
        }

        // creating list of highest level folders
        for (const folder of tempDealFolders) {
            if (!folder.parent_id) {
                docRows.push(folder);
                tempHighestLevelFolders.push(folder);
            }
        }

        // assigning parents (by setting children) to all other folders
        for (const folder of tempDealFolders) {
            if (folder.parent_id) {
                const parent = tempDealFolders.find((f) => f.id === folder.parent_id);
                assignParent(parent, folder);
            }
        }

        for (const doc of dealDocs) {
            const tempDoc: DocRow = { ...doc };
            addDocContext(tempDoc);
            tempDoc.tableType = formatDocumentCategory(doc.category);
            tempDoc.added = stringToDateFormat(tempDoc.date_created, false);
            tempDoc.produced = doc.category === DocumentCategory.Generated ? stringToDateFormat(tempDoc.date_updated, false) : "";
            tempDoc.versions = ""; // TODO: update when we implement versioning
            tempDoc.tableName = <EditableField
                                    value={tempDoc.name}
                                    placeholder="Document name"
                                    onSave={(newName: string) => updateDoc(newName, tempDoc.parent_id, tempDoc)}
                                />

            if (tempDoc.parent_id) {
                const parent = tempDealFolders.find((f) => f.id === tempDoc.parent_id);
                assignParent(parent, tempDoc);
            } else {
                if (tempDoc.generate_type !== GenerateType.DOCX) {
                    docRows.push(tempDoc);
                }
            }
        }

        // assigning folder context
        for (const folder of tempHighestLevelFolders) {
            countDocChildren(folder);
        }

        // filter based on search
        if (searchRef.current) {
            // removing docs that don't match the search
            for (const folder of tempHighestLevelFolders) {
                removeChildrenThatDontMatchSearch(folder);
            }
            for (let i = docRows.length - 1; i >= 0; i--) {
                if (docRows[i].category !== DocumentCategory.Folder && !doesDocMatchSearch(docRows[i])) {
                    docRows.splice(i, 1);
                }
            }

            // remove folders with no doc children
            for (const folder of tempHighestLevelFolders) {
                removeFoldersWithNoDocChildren(folder, undefined, -1)
            }
            for (let i = docRows.length - 1; i >= 0; i--) {
                if (docRows[i].category === DocumentCategory.Folder && docRows[i].children?.length === 0) {
                    docRows.splice(i, 1);
                }
            }
        }

        // apply sort
        sortDocRows(docRows, dealDocsSortOrder, dealDocsSortKey);
        for (const row of docRows) {
            if (row.children) {
                sortChildren(row, dealDocsSortOrder, dealDocsSortKey);
            }
        }

        for (const row of docRows) {
            if (row.category === DocumentCategory.Folder) {
                removeChildrenFromClosedFolders(row);
            }
        }

        setDealDocRows(docRows);
    }

    function removeChildrenFromClosedFolders(row: DocRow) {
        if (row.children) {
            if (!row.open) {
                row.children = [];
            } else {
                for (const child of row.children) {
                    removeChildrenFromClosedFolders(child);
                }
            }
        }
    }

    function removeFoldersWithNoDocChildren(row: DocRow, parent: DocRow | undefined, selfIndex: number) {
        if (row.children) {
            for (let i = row.children.length - 1; i >= 0; i--) {
                removeFoldersWithNoDocChildren(row.children[i], row, i);
            }
            if (row.children.length === 0) {
                parent?.children?.splice(selfIndex, 1);
            }
        }
    }

    function removeChildrenThatDontMatchSearch(row: DocRow) {
        if (row.children) {
            for (let i = row.children.length - 1; i >= 0; i--) {
                if (row.children[i].category !== DocumentCategory.Folder) {
                    if (!doesDocMatchSearch(row.children[i])) {
                        row.children.splice(i, 1);
                    }
                } else {
                    removeChildrenThatDontMatchSearch(row.children[i]);
                }
            }
        }
    }

    function doesDocMatchSearch(row: DocRow) {
        if (row.tableContext?.toLowerCase().includes(searchRef.current) || row.name?.toLowerCase().includes(searchRef.current)) {
            return true;
        }
        return false;
    }

    function toggleFolderOpen(folderId: number, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e?.stopPropagation();
        dispatch({ type: actions.TOGGLE_FOLDER_OPEN, payload: folderId });
    }

    function countDocChildren(folder: DocRow) {
        let docCount = 0;
        if (folder.children) {
            for (const child of folder.children) {
                if (child.category === DocumentCategory.Folder) {
                    docCount += countDocChildren(child)
                } else {
                    docCount += 1;
                }
            }
        }
        folder.tableContext = `${docCount} document${docCount === 1 ? "" : "s"}`;
        return docCount;
    }

    function assignParent(parent: DocRow | undefined, child: DocRow) {
        if (parent) {
            if (parent.children) {
                parent.children.push(child);
            } else {
                parent.children = [child];
            }
        }
    }

    function makeSuggestedDocRows() {
        const docRows: DocRow[] = []; 
        for (const doc of state.documents.suggestedDocList) {
            const tempDoc: DocRow = { ...doc };
            addDocContext(tempDoc);

            docRows.push(tempDoc)
        }
        sortDocRows(docRows, suggestedDocsSortOrder, suggestedDocsSortKey);
        setSuggestedDocRows(docRows);
    }

    function addDocContext(doc: DocRow) {
        doc.tableContext = "";

        // Client(s)
        if (doc.context?.client_records) {
            doc.tableContext += `Client${doc.context.client_records.length > 1 ? "s" : ""}: `;

            const clientNamesFormatted = [];
            for (const client of doc.context.client_records) {
                clientNamesFormatted.push(getClientTabName(client));
            }

            doc.tableContext += getStrOutput(clientNamesFormatted);
        }

        // Mortgage
        if (doc.context?.mortgage_record) {
            if (doc.context.mortgage_id) {
                const matchingMortgage = state.transactions.transactionList.find((transaction) => transaction.id === doc.context.mortgage_id && transaction.transaction_type === TransactionTypes.Mortgage);
                if (matchingMortgage) {
                    doc.tableContext += matchingMortgage.label ?? "MTG";
                }
            }
        }

        // Title Transfer
        if (doc.context?.title_transfer_id) {
            const matchingTitleTransfer = state.transactions.transactionList.find((transaction) => transaction.id === doc.context.title_transfer_id && transaction.transaction_type === TransactionTypes.TitleTransfer);
            if (matchingTitleTransfer) {
                doc.tableContext += matchingTitleTransfer.label ?? "Title Transfer";
            }
        }

        // Signing appointments
        if (doc.context?.signing_appointment_record) {
            doc.tableContext += "Signing Appointment";
            const clientString = getClientsString(doc.context.signing_appointment_record.clients, true);
            if (clientString) {
                doc.tableContext += `: ${clientString}`;
            }
        }
        
        // Form 9C
        if (doc.type === DocumentType.Form9C) {
            doc.tableContext += labelForm9CContextOption(state.paymentGroups.paymentGroups, doc.context.payment_group_id);
        }

        // Existing mortgage
        if (doc.type === DocumentType.LetterToMortgageeExistingMortgage) {
            doc.tableContext += labelLetterToMortgageeExistingMortgageContextOption(state.existingLiens.liensInDeal, doc.context.existing_mortgage_id, doc.context.content_type);
        }

        // Undertaking
        if (doc.type === DocumentType.Undertaking) {
            if (doc.context.type === UndertakingDocumentType.Blank) doc.tableContext += "Blank";
            else doc.tableContext += formatAddressedTo(doc.context.addressed_to);
        }
        
        // add more as docs with different context types are added


        // no context
        if (doc.context === undefined || Object.keys(doc.context).length === 0) {
            doc.tableContext = doc.name;
        }
    }

    function handleDeleteMultipleDocuments(docs: DocRow[]) {
        let docsWithParentBeingDeleted: number[] = [];
        for (let i = 0; i < docs.length; i++) {
            const parent = docs.find((doc) => doc.id === docs[i].parent_id);
            if (parent) {
                docsWithParentBeingDeleted.push(i);
            }
        }

        for (let i = docsWithParentBeingDeleted.length - 1; i >= 0; i--) {
            docs.splice(docsWithParentBeingDeleted[i], 1);
        }

        for (const doc of docs) handleDeleteDocument(doc);
        setDeleteDocModalActive(false);
    }

    function handleDeleteDocument(doc: DocRow) {
        switch(doc.category) {
            case DocumentCategory.Generated:
                removeGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), doc.id);
                return;
            case DocumentCategory.Received:
                // TODO when we add received docs
                return;
            case DocumentCategory.Template:
                removeTemplateDocument(dispatch, String(state.deal.dealInfo?.id), doc.id);
                return;
            case DocumentCategory.Uploaded:
                removeUploadedDocument(dispatch, String(state.deal.dealInfo?.id), doc.id);
                return;
            case DocumentCategory.Folder:
                removeFolder(dispatch, String(state.deal.dealInfo?.id), doc.id);
                return;
            default:
                return;
        }
    }

    function updateDoc(name: string, parentId: number | undefined, docToEdit: DocRow) {
        switch(docToEdit.category) {
            case DocumentCategory.Generated:
                updateGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), docToEdit.id, { ...docToEdit, name: name, parent_id: parentId });
                return;
            case DocumentCategory.Received:
                // TODO when we add received docs
                return;
            case DocumentCategory.Template:
                updateTemplateDocument(dispatch, String(state.deal.dealInfo?.id), docToEdit.id, { ...docToEdit, name: name, parent_id: parentId });
                return;
            case DocumentCategory.Uploaded:
                updateUploadedDocument(dispatch, String(state.deal.dealInfo?.id), docToEdit.id, { ...docToEdit, name: name, parent_id: parentId });
                return;
            case DocumentCategory.Folder:
                updateFolder(dispatch, String(state.deal.dealInfo?.id), docToEdit.id, { parent_id: parentId ?? null, name: name })
                return;
            default:
                return;
        }
    }

    function onSortSuggested(objKey: string, order: orderOptions): void {
        setSuggestedDocsSortKey(objKey);
        setSuggestedDocsSortOrder(order);
        const rowsSorted = [...suggestedDocRows];
        sortDocRows(rowsSorted, order, objKey);
        setSuggestedDocRows(rowsSorted);
    }

    function onSortDeal(objKey: string, order: orderOptions): void {
        setDealDocsSortKey(objKey);
        setDealDocsSortOrder(order);
        const rowsSorted = [...dealDocRows];
        sortDocRows(rowsSorted, order, objKey);
        for (const row of rowsSorted) {
            if (row.children) {
                sortChildren(row, order, objKey);
            }
        }
        setDealDocRows(rowsSorted);
    }

    function addMultipleSuggestedDocs(docs: DocRow[]) {
        for (const doc of docs) {
            addSuggestedDoc(dispatch, String(state.deal.dealInfo?.id), doc.id);
        }
    }

    function getConfirmDeleteContextText(): string {
        let msg = "Are you sure you want to delete ";

        if (docsToBeDeleted.length === 1) {
            if (docsToBeDeleted[0].category === DocumentCategory.Folder) {
                msg += "this folder? All folders and documents in this folder will also be deleted.";
            } else {
                msg += "this document?";
            }
        } else {
            let allFolders = true;
            let allDocuments = true;
            for (const doc of docsToBeDeleted) {
                if (doc.category === DocumentCategory.Folder) {
                    allDocuments = false;
                } else {
                    allFolders = false;
                }
            }
            if (allFolders) {
                msg += "these folders? All folders and documents in these folders will also be deleted.";
            } else if (allDocuments) {
                msg += "these documents?";
            } else {
                msg += "these folders and documents? All folders and documents in the folders will also be deleted.";
            }
        }

        msg += " This is permanent and irreversible.";
        return msg;
    }

    function produceMultiple(rows: DocRow[]) {
        let docIds: number[] = rows.filter((row) => row.category === DocumentCategory.Template).map((row) => row.id);
        produceMultipleDoc(dispatch, String(state.deal.dealInfo?.id), docIds);
    }

    async function handleDownload(row: DocRow) {
        if (row.category === DocumentCategory.Generated) {
            let canDownload = await canAccessGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), row.id);
            if (canDownload) downloadGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), row.id);
        } else if (row.category === DocumentCategory.Uploaded) {
            downloadUploadedDocument(dispatch, String(state.deal.dealInfo?.id), row.id);
        }
    }

    const debouncedOnSearch = useCallback(
        debounce((value) => {
            onSearch(value);
        }, 500), [state.documents.documentList]);

    function onSearch(value: string): void {
        searchRef.current = value.toLowerCase();
        makeDealDocRows();
    }

    function onDrop(dropped: any[], droppedOn: any) {
        let newParent = undefined;
        if (droppedOn.category === DocumentCategory.Folder) {
            newParent = droppedOn.id;
        } else {
            newParent = droppedOn.parent_id;
        }
        for (const row of dropped) {
            updateDoc(row.name, newParent, row);
        }
    }

    async function handleView(category: DocumentCategory, docId: number) {
        let type: ViewerDocTypeOptions = (category === DocumentCategory.Template) ? "html" : "pdf";
        let tempViewerActive = true;

        if (type === "html") {
            createPreviewDoc(dispatch, String(state.deal.dealInfo?.id), docId, state.documents.documentList.find((doc) => doc.id === docId)?.name);
        } else {
            if (category === DocumentCategory.Uploaded) {
                viewUploadedDocument(dispatch, String(state.deal.dealInfo?.id), docId);
            } else {
                tempViewerActive = await canAccessGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), docId);
                if (tempViewerActive) viewGeneratedDocument(dispatch, String(state.deal.dealInfo?.id), docId)
            }
        }

        setViewerActive(tempViewerActive);
        setViewerDocType(type);
        setViewerMode("viewer");
    }

    function handleViewerClose() {
        setViewerActive(false);
        setViewerMode(undefined);
    }

    return (
        <div>
            <AddDocModal 
                open={addDocsModalActive}
                onClose={() => setAddDocsModalActive(false)}
            />
            <DocumentViewerModal
                open={viewerActive}
                onClose={handleViewerClose}
                viewerDocType={viewerDocType}
                viewerMode={viewerMode}
            />
            <UploadDocumentModal
                open={uploadDocModalActive}
                onClose={() => setUploadDocModalActive(false)}
            />
            <GenericDialog
                action="destructive"
                title="Confirm Delete"
                onCancel={() => setDeleteDocModalActive(false)}
                onSubmit={() => handleDeleteMultipleDocuments(docsToBeDeleted)}
                open={deleteDocModalActive}
                contentText={getConfirmDeleteContextText()}
            />
            {isObjectLoading(state.dataSheet.objectsLoading, [Loading.Documents]) ? <CircularLoader containerHeight="70vh" /> :
            <DocsContainer>
                
                <SectionHeader>
                    <H2>Deal Docs</H2>
                    <ButtonDiv>
                        <BasicButton
                            label={{ text: "Upload", inputId: "upload" }}
                            typeOf="secondary"
                            leftIcon={<SavingCloud color={colors.BLACK}/>}
                            onClick={() => setUploadDocModalActive(true)}
                        />
                        {/* <BasicButton
                            label={{ text: "Packages", inputId: "packages" }}
                            typeOf="secondary"
                            leftIcon={<BundledIcon color={colors.BLACK}/>}
                            onClick={() => console.log("packages clicked")}
                        /> */}
                        <BasicButton
                            label={{ text: "Add Doc", inputId: "add-doc" }}
                            typeOf="secondary"
                            leftIcon={<PlusIcon color={colors.BLACK}/>}
                            onClick={() => setAddDocsModalActive(true)}
                        />
                        <BasicButton
                            label={{ text: "Add Folder", inputId: "add-folder" }}
                            typeOf="secondary"
                            leftIcon={<PlusIcon color={colors.BLACK}/>}
                            onClick={() => addFolder(dispatch, String(state.deal.dealInfo?.id))}
                        />
                    </ButtonDiv>
                </SectionHeader>

                <TableBase
                    id="deal-docs-table"
                    columnHeadings={[
                        { label: "Document", objKey: "tableName", sortable: true },
                        { label: "Context", objKey: "tableContext", sortable: true },
                        { label: "Type", objKey: "tableType", sortable: true },
                        { label: "Added", objKey: "added", sortable: true },
                        { label: "Produced", objKey: "produced", sortable: true },
                        { label: "Versions", objKey: "versions" }
                    ]}
                    tableRows={dealDocRows}
                    selectEnabled
                    titleBarProps={{
                        searchEnabled: true,
                        onSearch: (value) => debouncedOnSearch(value),
                        filterProps: {
                            filterOptions: ["Closing", "Opening"], // TODO: update this with list of tags
                            filterSelected: dealDocsFilter,
                            setFilterSelected: setDealDocsFilter
                        },
                        buttonsInTitleBar: [
                            {
                                typeOf: "secondary",
                                leftIcon: <PdfIcon color={colors.BLACK}/>,
                                label: { text: "Produce", inputId: "produce-selected"},
                                onClick: produceMultiple
                            },
                            // {
                            //     typeOf: "secondary",
                            //     leftIcon: <BundledIcon color={colors.BLACK}/>,
                            //     label: { text: "Produce Merged", inputId: "produce-merged"},
                            //     onClick: () => console.log("produce merged clicked")
                            // },
                            {
                                typeOf: "destructive",
                                leftIcon: <TrashIcon color={deleteButtonHoveredTitle ? colors.WHITE : theme.ERROR}/>,
                                label: { text: "Delete", inputId: "delete-selected"},
                                onClick: (rows) => {
                                    setDocsToBeDeleted(rows);
                                    setDeleteDocModalActive(true);
                                },
                                onMouseEnter: () => setDeleteButtonHoveredTitle(true),
                                onMouseLeave: () => setDeleteButtonHoveredTitle(false)
                            }
                        ]
                    }}
                    onSort={onSortDeal}
                    draggableProps={{
                        maxDepth: 3,
                        onDrop: onDrop,
                        valuesShownWhileDragging: ["name", "tableContext"]
                    }}
                    rowHoverProps={{
                        condition: [
                            (row: DocRow) => !row.children,
                            (row: DocRow) => !row.children && (row.category === DocumentCategory.Generated || row.category === DocumentCategory.Uploaded),
                            // (row: DocRow) => !row.children && row.category === DocumentCategory.Template,
                            undefined
                        ],
                        buttonsInHoverAction: [
                            {
                                typeOf: "secondary",
                                leftIcon: <PdfIcon color={colors.BLACK}/>,
                                label: { text: "View", inputId: "view"},
                                onClick: (row: DocRow) => handleView(row.category, row.id)
                            },
                            {
                                typeOf: "secondary",
                                leftIcon: <DownloadIcon color={colors.BLACK}/>,
                                label: { text: "Download", inputId: "download"},
                                onClick: handleDownload
                            },
                            // {
                            //     typeOf: "secondary",
                            //     leftIcon: <EditIcon color={colors.BLACK}/>,
                            //     label: { text: "Edit", inputId: "edit"},
                            //     onClick: (row: DocRow) => handleEdit(row.id)
                            // },
                            {
                                typeOf: "destructive",
                                leftIcon: <TrashIcon color={deleteButtonHoveredRow ? colors.WHITE : theme.ERROR}/>,
                                label: { text: "Delete", inputId: "delete"},
                                onClick: (doc) => {
                                    setDocsToBeDeleted([doc]);
                                    setDeleteDocModalActive(true);
                                },
                                onMouseEnter: () => setDeleteButtonHoveredRow(true),
                                onMouseLeave: () => setDeleteButtonHoveredRow(false)
                            },
                        ]
                    }}
                />

                <div style={{ height: "3rem" }}/>

                {state.documents.discoveryStatus === DocumentStatus.Complete ? (
                    <TableBase
                        id="suggested-docs-table"
                        columnHeadings={[
                            { label: "Document", objKey: "name", sortable: true },
                            { label: "Context", objKey: "tableContext", sortable: true }
                        ]}
                        tableRows={suggestedDocRows}
                        selectEnabled
                        titleBarProps={{
                            filterProps: {
                                filterOptions: ["Closing", "Opening"], // TODO: update this with list of tags
                                filterSelected: suggestedDocsFilter,
                                setFilterSelected: setSuggestedDocsFilter
                            },
                            buttonsInTitleBar: [{
                                typeOf: "secondary",
                                leftIcon: <PlusIcon color={colors.BLACK}/>,
                                label: { text: "Add", inputId: "add-selected"},
                                onClick: (docs) => addMultipleSuggestedDocs(docs)
                            }],
                            title: "Suggested Docs",
                            extraTitleContent: 
                                <StatusDiv data-testid="suggested-docs-up-do-date-status">
                                    <CheckCircle color={theme.INPUT}/>
                                    <StyledSmall>Up to Date</StyledSmall>
                                </StatusDiv>
                        }}
                        
                        onSort={onSortSuggested}
                        rowHoverProps={{
                            buttonsInHoverAction: [{
                                typeOf: "secondary",
                                leftIcon: <PlusIcon color={colors.BLACK}/>,
                                label: { text: "Add", inputId: "add-one"},
                                onClick: (doc) => addSuggestedDoc(dispatch, String(state.deal.dealInfo?.id), doc.id)
                            }]
                        }}
                    />
                ) : (
                    <>
                        <SuggestedDocsHeader>
                            <StyledH4>Suggested Docs</StyledH4>
                            <StatusDiv>
                                <XCircle color={theme.INPUT}/>
                                <StyledSmall>{state.documents.discoveryStatus === DocumentStatus.Processing ? "Processing" : "Error"}</StyledSmall>
                            </StatusDiv>
                        </SuggestedDocsHeader>
                        {state.documents.discoveryStatus === DocumentStatus.Processing && <CircularLoader loaderHeight="5rem" loaderWidth="5rem" containerHeight="10rem"/>}
                    </>
                )}
                
            </DocsContainer>
            }
        </div>
    )
}

const StyledH4 = styled(H4)({
    margin: "0.5rem 0"
});

const SuggestedDocsHeader = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "1rem"
})

const FolderWrapper = styled('div')({
    display: "flex"
})

const FolderIconWrapper = styled('div')({
    paddingRight: "1rem",
    height: "2.4rem"
})

const StyledSmall = styled(Small)({
    color: theme.INPUT
})

const StatusDiv = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
})

const DocsContainer = styled('div')({
    padding: "2rem 0rem"
})

const SectionHeader = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    paddingBottom: "1rem"
})

const ButtonDiv = styled('div')({
    display: "flex",
    alignItems: "flex-start",
    gap: "2rem"
})