import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    saveDealFireInsuranceCompany,
    getDealFireInsuranceCompany,
    createDealFireInsuranceCompany,
    getDealFireInsuranceCompanies,
    getGlobalFireInsuranceCompanies,
    newRegionOnFireInsuranceCompanyRecord,
    deleteRegionFromFireInsuranceCompanyRecord
} from "../../../libs/service/axios/api";
import { sanitizeFireInsuranceCompaniesResponse, sanitizeFireInsuranceCompanyResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeFireInsuranceCompanyRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { sortByKey } from "../../../libs/utils/arrays";
import { FireInsuranceCompany, FireInsuranceCompanyListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { SimpleFireInsuranceCompany } from "../../../libs/types/UniversalSurvey/FireInsuranceCompany/simpleFireInsuranceCompany";
import { RegionListItem } from "../../../libs/types/DealList/deals";
import { Loading } from "../../../libs/resources/enums/loading";

function saveFireInsuranceCompany(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: FireInsuranceCompany, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceCompany, isLoading: true } });
    saveDealFireInsuranceCompany(dealId, String(companyInfo.id), sanitizeFireInsuranceCompanyRequest(companyInfo))
        .then(async function () {
            const newListItem: FireInsuranceCompanyListItem = {
                id: companyInfo.id,
                isRecord: true,
                label: companyInfo.name,
                name: companyInfo.name,
                abbr_name: companyInfo.abbr_name,
                general_address: companyInfo.general_address
            }
            await updateFireInsuranceCompanyRecordRegions(dispatch, dealId, companyInfo, regionList);
            getFireInsuranceCompanyInfo(dispatch, dealId, companyInfo.id);
            dispatch({ type: actions.REPLACE_FIRE_INSURANCE_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: companyInfo.id, newOption: newListItem, oldIsRecord: true }});
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: companyInfo.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance company record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceCompany, isLoading: false } });
        })
}

function createFireInsuranceCompanyRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, company: FireInsuranceCompanyListItem) {
    createDealFireInsuranceCompany(dealId, company.id)
        .then(function (response: any) {
            getFireInsuranceCompanyInfo(dispatch, dealId, response.data.id);
            const newCompanyListItem: FireInsuranceCompanyListItem = {
                ...company,
                label: company.name,
                isRecord: true,
                id: response.data.id
            };
            dispatch({ type: actions.REPLACE_FIRE_INSURANCE_COMPANY_OPTION_WITH_RECORD, payload: { oldCompanyId: company.id, newOption: newCompanyListItem, oldIsRecord: false } });
            dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: newCompanyListItem.id });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createNewFireInsuranceCompanyRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: FireInsuranceCompany, regionList: RegionListItem[]) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceCompany, isLoading: true } });
    createDealFireInsuranceCompany(dealId)
        .then(function (response: any) {
            saveDealFireInsuranceCompany(dealId, String(response.data.id), sanitizeFireInsuranceCompanyRequest(companyInfo))
                .then(async function () {
                    const newCompanyListItem: FireInsuranceCompanyListItem = {
                        id: response.data.id,
                        isRecord: true,
                        general_address: companyInfo.general_address,
                        name: companyInfo.name,
                        abbr_name: companyInfo.abbr_name,
                        label: companyInfo.name
                    };
                    await addFireInsuranceCompanyRecordRegions(dispatch, dealId, response.data.id, regionList);
                    dispatch({ type: actions.ADD_TO_FIRE_INSURANCE_COMPANY_OPTIONS, payload: newCompanyListItem });
                    dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: newCompanyListItem.id });
                    getFireInsuranceCompanyInfo(dispatch, dealId, response.data.id);
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save fire insurance company record during creation: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceCompany, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveFireInsuranceCompany, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create fire insurance company record: ${error}`, type: AlertTypes.Error } });
        })
}

function createFireInsuranceCompanyOptionsList(dispatch: Dispatch<Record<string, any>>, dealId: string, region: number | undefined, selectedCompanyId: number | undefined) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceCompanyList, isLoading: true } });
    getDealFireInsuranceCompanies(dealId)
        .then(function (dealResponse: any) {
            const cleanDealData = sanitizeFireInsuranceCompaniesResponse(dealResponse.data);
            let query = undefined;
            if (region) {
                query = new URLSearchParams({ region_id: String(region) })
            }
            getGlobalFireInsuranceCompanies(query)
                .then(function (globalResponse: any) {
                    const cleanGlobalData = sanitizeFireInsuranceCompaniesResponse(globalResponse.data.fire_insurance_companies);
                    dispatch({ type: actions.SET_FIRE_INSURANCE_COMPANY_OPTION_LIST, payload: organizeCompanyList(cleanDealData, cleanGlobalData) });
                    dispatch({ type: actions.SET_SELECTED_FIRE_INSURANCE_COMPANY, payload: selectedCompanyId });
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global fire insurance companies: ${error}`, type: AlertTypes.Error } });
                })
                .finally(function () {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceCompanyList, isLoading: false } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.FireInsuranceCompanyList, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance company records: ${error}`, type: AlertTypes.Error } });
        })
}

function getFireInsuranceCompanyInfo(dispatch: Dispatch<Record<string, any>>, dealId: string, companyRecordId: number) {
    getDealFireInsuranceCompany(dealId, String(companyRecordId))
        .then(function (response: any) {
            dispatch({
                type: actions.SET_FIRE_INSURANCE_COMPANY_INFO,
                payload: sanitizeFireInsuranceCompanyResponse(response.data)
            });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get fire insurance company record: ${error}`, type: AlertTypes.Error } });
        })
}

async function addFireInsuranceCompanyRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, companyRecordId: number, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    for (const region of regionList) {
        promiseList.push(
            newRegionOnFireInsuranceCompanyRecord(dealId, String(companyRecordId), String(region.id))
            .then()
            .catch(function (error: any) {
                if (error.response.data.statusCode !== 409) {
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance company record: ${error}`, type: AlertTypes.Error } });
                }
            })
        );
    }
    await Promise.all(promiseList);
}

async function updateFireInsuranceCompanyRecordRegions(dispatch: Dispatch<Record<string, any>>, dealId: string, companyInfo: FireInsuranceCompany, regionList: RegionListItem[]) {
    let promiseList: any[] = [];
    let existingRegionIds: number[] = [];
    let newRegionIds: number[] = [];
    if (companyInfo.regions) {
        for (const region of companyInfo.regions) {
            existingRegionIds.push(region.id);
        }
    }
    for (const region of regionList) {
        newRegionIds.push(region.id);
        if (!existingRegionIds.includes(region.id)) {
            promiseList.push(
                newRegionOnFireInsuranceCompanyRecord(dealId, String(companyInfo.id), String(region.id))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.statusCode !== 409) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Adding ${region.label} to fire insurance company record: ${error}`, type: AlertTypes.Error } });
                    }
                })
            )
        }
    } 
    for (const regionId of existingRegionIds) {
        if (!newRegionIds.includes(regionId)) {
            promiseList.push(
                deleteRegionFromFireInsuranceCompanyRecord(dealId, String(companyInfo.id), String(regionId))
                .then()
                .catch(function (error: any) {
                    if (error.response.data.message.length > 1 || !error.response.data.message.includes("Region cannot be found in the fire insurance company record.")) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Cannot delete region on fire insurance company record: ${error}`, type: AlertTypes.Error } })
                    }
                })
            )
        }
    }
    await Promise.all(promiseList);
}

function organizeCompanyList(dealCompanyList: SimpleFireInsuranceCompany[], globalCompanyList: SimpleFireInsuranceCompany[]): FireInsuranceCompanyListItem[] {
    let cleanList: FireInsuranceCompanyListItem[] = [];
    let isRecordList: number[] = [];

    for (const company of dealCompanyList) {
        if (company.fire_insurance_company?.id) {
            isRecordList.push(company.fire_insurance_company.id);
        }
        cleanList.push({
            id: company.id,
            name: company.name,
            abbr_name: company.abbr_name,
            general_address: company.general_address,
            isRecord: true,
            label: company.name
        });
    }

    for (const company of globalCompanyList) {
        if (!isRecordList.includes(company.id)) {
            cleanList.push({
                id: company.id,
                name: company.name,
                abbr_name: company.abbr_name,
                general_address: company.general_address,
                isRecord: false,
                label: company.name
            });
        }
    }

    sortByKey(cleanList, "name");
    return cleanList;
}

export {
    saveFireInsuranceCompany,
    createFireInsuranceCompanyRecordFromGlobal,
    createNewFireInsuranceCompanyRecord,
    createFireInsuranceCompanyOptionsList,
    getFireInsuranceCompanyInfo
}