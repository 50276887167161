import { useContext, useState } from "react";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase";
import { getRecipientName, updatePaymentGroup } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";
import { PaymentGroup } from "../../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { PaymentMethodOptions } from "../../../../../libs/resources/options";
import { formatPaymentMethod } from "../../../../../libs/utils/formatValues";
import { PaymentRecipientType } from "../../../../../libs/resources/enums/paymentGroup";
import { PaymentDeliveryMethod } from "../../../../../libs/resources/enums/paymentMethod";
import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children"> & {
    group: PaymentGroup;
};

export default function UpdatePaymentGroupModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    const [paymentMethod, setPaymentMethod] = useState<PaymentDeliveryMethod | undefined>(props.group.payment_method);
    const [wiringFee, setWiringFee] = useState<number | undefined>(props.group.wiring_fee);

    function onDiscard() {
        setPaymentMethod(undefined);
        setWiringFee(undefined);
        props.onClose ? props.onClose() : undefined;
    }

    function onSubmitEditPayment() {
        updatePaymentGroup(dispatch, String(state.deal.dealInfo?.id), String(props.group.id), {
            payment_method: paymentMethod ?? null,
            wiring_fee: wiringFee ?? null
        });
    }

    return (
        <ModalBase
            size="small"
            title={`Edit ${getRecipientName(props.group, "Payment Group")}`}
            open={props.open}
            saveDisabled={!paymentMethod}
            onSubmit={() => onSubmitEditPayment()}
            onClose={() => onDiscard()}
            closeAfterSaving={onDiscard}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveUpdatePaymentGroup])}
        >
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={12}>
                    <Dropdown
                        options={PaymentMethodOptions}
                        value={paymentMethod}
                        label={{ text: "Payment Method", inputId: "payment-method", isRequired: true }}
                        placeholder="Payment Method"
                        isHoverActionHidden={paymentMethod ? false : true}
                        handleClear={() => setPaymentMethod(undefined)}
                        onChange={(e) => setPaymentMethod(e.target.value as PaymentDeliveryMethod)}
                        formatValue={formatPaymentMethod}
                    />
                </Grid>
                {props.group.recipient_type === PaymentRecipientType.ThirdPartyTransfer && (
                    <Grid item xs={12}>
                        <BasicTextInput
                            fullWidth
                            inputProps={{ "aria-label": "Wiring Fee" }}
                            label={{ text: "Wiring Fee", inputId: "wiring_fee" }}
                            value={wiringFee !== undefined ? String(wiringFee) : undefined}
                            onChange={(e) => setWiringFee(Number(e.target.value))}
                            moneyField
                            valueType="positive"
                            placeholder="0.00"
                        />
                    </Grid>
                )}
            </Grid>
        </ModalBase>
    );
}