import { Fee } from "../../../types/UniversalSurvey/Fees/fee";
import { FeeSource } from "../../enums/fees";

export const defaultFee: Fee = {
    id: -1,
    source: FeeSource.Manual,
    amount: undefined,
    description: undefined,
    date_created: undefined,
    date_updated: undefined
}