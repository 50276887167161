import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    addDealFirmOfficeToLegalProfessional,
    addDealFirmToLegalProfessional,
    createDealLegalFirmOffice,
    getDealFirmOfficesForLegalProfessional,
    getDealLegalFirmOffice,
    getDealLegalFirmOffices,
    getGlobalFirmOfficesForLegalProfessional,
    getGlobalLegalFirmOffices,
    getGlobalLegalProfessional,
    saveDealLegalFirmOffice,
    setOfficeForLegalProfessionalRecord
} from "../../../libs/service/axios/api";
import { sanitizeLegalFirmOfficeResponse, sanitizeSimpleLegalFirmOfficesResponse } from "../../../libs/types/UniversalSurvey/utils/convertResponse";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { LegalProfessionalListItem } from "../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { LegalFirmListItem } from "../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { LegalFirmOffice, LegalFirmOfficeListItem } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/legalFirmOffice";
import { SimpleLegalFirmOffice } from "../../../libs/types/UniversalSurvey/LegalFirmOffice/simpleLegalFirmOffice";
import { labelLegalFirmOfficeItem } from "../../../libs/utils/labeling/legalFirmOffice";
import { SimpleLegalFirm } from "../../../libs/types/UniversalSurvey/LegalFirm/simpleLegalFirm";
import { sanitizeLegalFirmOfficeRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { defaultSimpleLegalFirmOffice } from "../../../libs/resources/defaults/frontend/defaultSimpleLegalFirmOffice";
import { Loading } from "../../../libs/resources/enums/loading";
import { LegalProfessionalOptions } from "../../../libs/resources/enums/legalProfessionalOptions";

function getLegalProfessionalFirmOfficeOptions(
    dispatch: Dispatch<Record<string, any>>,
    dealId: string,
    professional: LegalProfessionalListItem | undefined,
    firm: LegalFirmListItem | undefined,
    type: LegalProfessionalOptions,
    officeQuery?: URLSearchParams | undefined
) {
    if (professional && firm) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: type === LegalProfessionalOptions.DealSigner ? Loading.SignerOfficeList : type ===LegalProfessionalOptions.DealLawyer ? Loading.LawyerOfficeList : Loading.LenderLawyerOfficeList, isLoading: true } });
        getDealFirmOfficesForLegalProfessional(dealId, String(professional.id), String(firm.id))
            .then(function (response: any) {
                const cleanDealOffices = sanitizeSimpleLegalFirmOfficesResponse(response.data);
                if (professional.legal_professional && firm.legal_firm) {
                    getGlobalLegalProfessional(String(professional.legal_professional.id))
                        .then(function (professionalResponse) {
                            const globalFirms = professionalResponse.data.legal_firms;
                            const matchingFirm = globalFirms.find((firmToSearch: SimpleLegalFirm) => firmToSearch.id === firm.legal_firm?.id);
                            if (matchingFirm) {
                                // TODO: Update with global legal professional legal professionals
                                // getGlobalFirmOfficesForLegalProfessional(String(professional.legal_professional?.id), String(firm.legal_firm?.id))
                                // .then(function (globalResponse: any) {
                                //     const cleanGlobalOffices = sanitizeSimpleLegalFirmOfficesResponse(globalResponse.data.legal_firm_offices);
                                //     const organizedData = organizeLegalFirmOfficeListResponse(cleanDealOffices, cleanGlobalOffices);
                                //     setOptionList(dispatch, organizedData, type);
                                //     selectOfficeIfOnlyOne(dispatch, dealId, organizedData, professional.id, firm.id, type);
                                // })
                                // .catch(function (error: any) {
                                //     dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get global legal firms: ${error}`, type: AlertTypes.Error } });
                                // })
                            } else {
                                const organizedData = organizeLegalFirmOfficeListResponse(cleanDealOffices, []);
                                setOptionList(dispatch, organizedData, type);
                                selectOfficeIfOnlyOne(dispatch, dealId, organizedData, professional.id, firm.id, type);
                            }
                        })
                } 
                // TODO: Update with global legal professional legal professionals
                // Has global legal firm
                // Add global legal firm offices options for firm
                // else if (firm.legal_firm) {
                    // if (!officeQuery) return;
                    // dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerOfficeList, isLoading: true } });
                    // getGlobalLegalFirmOffices(String(firm.legal_firm.id), officeQuery)
                    //     .then(function (globalResponse: any) {
                    //         const cleanGlobalData = sanitizeSimpleLegalFirmOfficesResponse(globalResponse.data.legal_firm_offices);
                    //         const organizedData = organizeLegalFirmOfficeListResponse(cleanDealOffices, cleanGlobalData);
                    //         setOptionList(dispatch, organizedData, type);
                    //         selectOfficeIfOnlyOne(dispatch, dealId, organizedData, professional.id, firm.id, type);
                    //     })
                    //     .catch(function (error: any) {
                    //         dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm offices: ${error}`, type: AlertTypes.Error } });
                    //     })
                    //     .finally(() => {
                    //         dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerOfficeList, isLoading: false } });
                    //     })
                // No global legal firm or professional
                else {
                    const organizedData = organizeLegalFirmOfficeListResponse(cleanDealOffices, []);
                    setOptionList(dispatch, organizedData, type);
                    selectOfficeIfOnlyOne(dispatch, dealId, organizedData, professional.id, firm.id, type);
                }
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm records: ${error}`, type: AlertTypes.Error } });
            })
            .finally(() => {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: type === LegalProfessionalOptions.DealSigner ? Loading.SignerOfficeList : type ===LegalProfessionalOptions.DealLawyer ? Loading.LawyerOfficeList : Loading.LenderLawyerOfficeList, isLoading: false } });
            })
    } else {
        setOptionList(dispatch, [], type);
    }
}

function selectOfficeIfOnlyOne(dispatch: Dispatch<Record<string, any>>, dealId: string, optionList: LegalFirmOfficeListItem[], professionalId: number, firmId: number, type: LegalProfessionalOptions) {
    if (optionList.length === 1) {
        if (optionList[0].isRecord) {
            let record = { ...optionList[0] };
            delete record.isRecord;
            delete record.label;
            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE, payload: record });
            } else {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { isSigner: type === LegalProfessionalOptions.DealSigner, record } });
            }
        } else {
            createLegalFirmOfficeRecordFromGlobal(
                dispatch,
                dealId,
                type === LegalProfessionalOptions.DealLawyer ? professionalId : undefined,
                type === LegalProfessionalOptions.DealSigner ? professionalId : undefined,
                type === LegalProfessionalOptions.LenderLawyer ? professionalId : undefined,
                firmId,
                optionList[0],
                type
            );
        }
    }
}

function setOptionList(dispatch: Dispatch<Record<string, any>>, organizedData: LegalFirmOfficeListItem[], type: LegalProfessionalOptions) {
    if (type === LegalProfessionalOptions.DealSigner) {
        dispatch({ type: actions.SET_SIGNER_OFFICE_OPTION_LIST, payload: organizedData });
    } else if (type === LegalProfessionalOptions.DealLawyer) {
        dispatch({ type: actions.SET_LAWYER_OFFICE_OPTION_LIST, payload: organizedData });
    } else if (type === LegalProfessionalOptions.LenderLawyer) {
        dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE_OPTION_LIST, payload: organizedData });
    }
}

function getLegalFirmOfficesForFirmToLink(dispatch: Dispatch<Record<string, any>>, dealId: string, firm: LegalFirmListItem, query?: URLSearchParams) {
    if (firm.isRecord) {
        dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerFirmOfficeList, isLoading: true } });
        getDealLegalFirmOffices(dealId, String(firm.id))
            .then(function (response: any) {
                const cleanDealData = sanitizeSimpleLegalFirmOfficesResponse(response.data);
                if (firm.legal_firm) {
                    getGlobalOffices(dispatch, String(firm.legal_firm.id), cleanDealData);
                } else {
                    dispatch({ type: actions.SET_OFFICES_FOR_FIRM_TO_LINK, payload: organizeLegalFirmOfficeListResponse(cleanDealData, []) });
                }
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm office records: ${error}`, type: AlertTypes.Error } });
            })
            .finally(() => {
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerFirmOfficeList, isLoading: false } });
            })
    } else {
        getGlobalOffices(dispatch, String(firm.id), [], query);
    }
}

function getGlobalOffices(dispatch: Dispatch<Record<string, any>>, firmId: string, dealData: SimpleLegalFirmOffice[], query?: URLSearchParams) {
    if (!query) return;
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerFirmOfficeList, isLoading: true } });
    getGlobalLegalFirmOffices(firmId, query)
        .then(function (globalResponse: any) {
            const cleanGlobalData = sanitizeSimpleLegalFirmOfficesResponse(globalResponse.data.legal_firm_offices);
            dispatch({ type: actions.SET_OFFICES_FOR_FIRM_TO_LINK, payload: organizeLegalFirmOfficeListResponse(dealData, cleanGlobalData) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm offices: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LawyerFirmOfficeList, isLoading: false } });
        })
}

function organizeLegalFirmOfficeListResponse(dealList: SimpleLegalFirmOffice[], globalList: SimpleLegalFirmOffice[]): LegalFirmOfficeListItem[] {
    let cleanList: LegalFirmOfficeListItem[] = [];
    let isRecordList: number[] = [];

    for (const office of dealList) {
        if (office.legal_firm_office?.id) {
            isRecordList.push(office.legal_firm_office.id);
        }
        cleanList.push(labelLegalFirmOfficeItem({
            ...office,
            isRecord: true,
        }));
    }

    for (const office of globalList) {
        if (!isRecordList.includes(office.id)) {
            cleanList.push(labelLegalFirmOfficeItem({
                ...office,
                isRecord: false,
            }));
        }
    }

    return cleanList;
}

function setLegalFirmOfficeRecordBeingEdited(dispatch: Dispatch<Record<string, any>>, dealId: string, firmId: number, officeId: number) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalFirmOfficeModal, isLoading: true } });
    getDealLegalFirmOffice(dealId, String(firmId), String(officeId))
        .then(function (response: any) {
            dispatch({ type: actions.SET_LEGAL_FIRM_OFFICE_BEING_EDITED, payload: sanitizeLegalFirmOfficeResponse(response.data) });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Get legal firm office record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.LegalFirmOfficeModal, isLoading: false } });
        })
}

function setOfficeRecordForLegalProfessional(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, firmId: number, officeRecord: LegalFirmOfficeListItem | null, type: LegalProfessionalOptions) {
    setOfficeForLegalProfessionalRecord(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), String(firmId), officeRecord ? officeRecord.id : null)
        .then(function () {
            let recordData;
            if (officeRecord) {
                recordData = { ...officeRecord };
                delete recordData.isRecord;
                delete recordData.label;
            } else {
                recordData = undefined;
            }
            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE, payload: recordData });
            } else if (lawyerId === signerId) {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { record: recordData, isSigner: true } });
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { record: recordData, isSigner: false } });
            } else {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { record: recordData, isSigner: type === LegalProfessionalOptions.DealSigner } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Setting office for legal professional: ${error}`, type: AlertTypes.Error } });
        })
}

async function createLegalFirmOfficeRecordFromGlobal(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, firmId: number, office: LegalFirmOfficeListItem, type: LegalProfessionalOptions): Promise<LegalFirmOfficeListItem | undefined> {
    return new Promise<LegalFirmOfficeListItem | undefined>((resolve) => {
        createDealLegalFirmOffice(dealId, String(firmId), office.id)
            .then(function (response: any) {
                addDealFirmOfficeToLegalProfessional(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), String(firmId), String(response.data.id))
                    .then(function () {
                        const newListItem: LegalFirmOfficeListItem = labelLegalFirmOfficeItem({
                            ...office,
                            isRecord: true,
                            id: response.data.id
                        });
                        dispatch({ type: actions.REPLACE_LEGAL_FIRM_OFFICE_OPTION_WITH_RECORD, payload: { oldId: office.id, newOption: newListItem, oldIsRecord: false } });
                        setOfficeRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, firmId, newListItem, type);
                        resolve(newListItem);
                    })
                    .catch(function (error: any) {
                        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Add office to legal professional record: ${error}`, type: AlertTypes.Error } });
                        resolve(undefined);
                    })
            })
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal firm office record: ${error}`, type: AlertTypes.Error } });
                resolve(undefined);
            })
    })
}

async function linkLegalFirmToLegalProfessional(dispatch: Dispatch<Record<string, any>>, dealId: string, professionalId: number | undefined, firmId: number): Promise<undefined> {
    return new Promise<undefined>((resolve) => {
        addDealFirmToLegalProfessional(dealId, String(professionalId), String(firmId))
            .then()
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Linking firm to legal professional record: ${error}`, type: AlertTypes.Error } });
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: false } });
            })
            .finally(() => resolve(undefined))
    })
}

async function linkLegalFirmOfficeToLegalProfessional(dispatch: Dispatch<Record<string, any>>, dealId: string, professionalId: number | undefined, firmId: number, officeId: number): Promise<undefined> {
    return new Promise<undefined>((resolve) => {
        addDealFirmOfficeToLegalProfessional(dealId, String(professionalId), String(firmId), String(officeId))
            .then()
            .catch(function (error: any) {
                dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Linking firm office to legal professional record: ${error}`, type: AlertTypes.Error } });
                dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalProfessional, isLoading: false } });
            })
            .finally(() => resolve(undefined))
    })
}

function saveLegalFirmOfficeRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, firmId: number, data: LegalFirmOffice, type: LegalProfessionalOptions) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: true } });
    saveDealLegalFirmOffice(dealId, String(firmId), String(data.id), sanitizeLegalFirmOfficeRequest(data))
        .then(function () {
            const dataCopy = { ...data };
            for (const key of Object.keys(dataCopy)) {
                if (!Object.keys(defaultSimpleLegalFirmOffice).includes(key)) {
                    delete dataCopy[key as keyof typeof dataCopy];
                }
            }
            if (type === LegalProfessionalOptions.LenderLawyer) {
                dispatch({ type: actions.SET_LENDER_LAWYER_OFFICE, payload: dataCopy })
            } else {
                dispatch({ type: actions.SET_DEAL_PROFESSIONAL_OFFICE, payload: { record: dataCopy, isSigner: type === LegalProfessionalOptions.DealSigner } });
            }
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal firm office record: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: false } });
        })
}

function submitNewLegalFirmOfficeRecord(dispatch: Dispatch<Record<string, any>>, dealId: string, lawyerId: number | undefined, signerId: number | undefined, lenderLawyerId: number | undefined, firm: SimpleLegalFirm, data: LegalFirmOffice, type: LegalProfessionalOptions) {
    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: true } });
    createDealLegalFirmOffice(dealId, String(firm.id), null)
        .then(function (response: any) {
            addDealFirmOfficeToLegalProfessional(dealId, String(type === LegalProfessionalOptions.DealSigner ? signerId : type === LegalProfessionalOptions.DealLawyer ? lawyerId : lenderLawyerId), String(firm.id), String(response.data.id))
                .then(function () {
                    saveDealLegalFirmOffice(dealId, String(firm.id), String(response.data.id), sanitizeLegalFirmOfficeRequest(data))
                        .then(function () {
                            const simpleRecord: SimpleLegalFirmOffice = {
                                id: response.data.id,
                                general_address: data.general_address,
                                municipality: data.municipality,
                                jurisdiction: data.jurisdiction,
                                legal_firm_office: undefined,
                                legal_firm_record: firm
                            }
                            const listItem = labelLegalFirmOfficeItem({ ...simpleRecord, isRecord: true });
                            dispatch({ type: actions.ADD_TO_LEGAL_FIRM_OFFICE_OPTIONS, payload: { record: listItem, signerId, lawyerId, lenderLawyerId, type } });
                            setOfficeRecordForLegalProfessional(dispatch, dealId, lawyerId, signerId, lenderLawyerId, firm.id, listItem, type);
                        })
                        .catch(function (error: any) {
                            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save legal firm office record during creation: ${error}`, type: AlertTypes.Error } });
                        })
                        .finally(function () {
                            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: false } });
                        })
                })
                .catch(function (error: any) {
                    dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: false } });
                    dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Add office to legal professional record: ${error}`, type: AlertTypes.Error } });
                })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: Loading.SaveLegalFirmOffice, isLoading: false } });
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create legal firm office record: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getLegalProfessionalFirmOfficeOptions,
    setLegalFirmOfficeRecordBeingEdited,
    setOfficeRecordForLegalProfessional,
    createLegalFirmOfficeRecordFromGlobal,
    saveLegalFirmOfficeRecord,
    submitNewLegalFirmOfficeRecord,
    getLegalFirmOfficesForFirmToLink,
    linkLegalFirmOfficeToLegalProfessional,
    linkLegalFirmToLegalProfessional
}