import { SimpleFireInsuranceCompany } from "../../../types/UniversalSurvey/FireInsuranceCompany/simpleFireInsuranceCompany";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultSimpleFireInsuranceCompany: SimpleFireInsuranceCompany = {
    id: -1,
    name: undefined,
    abbr_name: undefined,
    general_address: defaultSimpleAddress,
    
    fire_insurance_company: undefined
}