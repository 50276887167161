import { GlobalSimpleLenderContact, SimpleLenderContact } from "../../../types/UniversalSurvey/LenderContact/simpleLenderContact"
import { defaultSimpleLender } from "./defaultSimpleLender"
import { defaultSimpleLenderBranch } from "./defaultSimpleLenderBranch"

export const defaultSimpleLenderContact: SimpleLenderContact = {
    id: -1,
    name: undefined,
    role: undefined,

    lender_record: defaultSimpleLender,
    lender_contact: undefined
}

export const defaultGlobalSimpleLenderContact: GlobalSimpleLenderContact = {
    id: -1,
    name: undefined,
    email: undefined,
    phone_work: undefined,
    fax: undefined,
    notes: undefined,
    date_created: undefined,

    lender: defaultSimpleLenderBranch
}