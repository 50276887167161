// Importing MUI components
import { styled, Button, Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

// Importing from DCP UI Library
import { Body } from '../../../../../components/Common/Typography/index';
import color from '../../../../../components/Common/componentStyling/Colors';
import theme from '../../../../../components/Common/componentStyling/Theme';

// Importing application components
import DisplayPills from '../../../../../components/Common/StatusPills/DisplayPills';
import { BORDER_2, BORDER_RADIUS_ACTION_W_ICON } from '../../../../../components/Common/componentStyling/Styles';
import { Status } from '../../../../libs/types/UniversalSurvey/Frontend/status';

type GridButtonProps = Omit<ButtonProps, "variant"> & {
    isActive: boolean,
    status: Status
};

const GridButton: React.FC<GridButtonProps> = ({ children, isActive, ...props }) => {
    return (
        <StyledButton
            {...props}
            sx={isActive ? activeButtonStyle : {}}
        >
            <ButtonTextContainer>
                <Body>{children}</Body>
            </ButtonTextContainer>
            {/* <StyledStack>
                <DisplayPills status={props.status} />
            </StyledStack> */}
        </StyledButton>
    );
}

const StyledStack = styled(Stack)({
    position: "absolute",
    right: "0.5rem"
})

const StyledButton = styled(Button)<ButtonProps>({
    justifyContent: "space-between",
    borderRadius: BORDER_RADIUS_ACTION_W_ICON,
    padding: "0.5rem 0.5rem 0.5rem 1rem",
    border: BORDER_2(color.WHITE),
    boxSizing: 'border-box',
    textTransform: 'none',
    height: "4.2rem",
    '&:hover': {
        backgroundColor: theme.HOVER,
    },
    '&:focus': {
        boxShadow: 'none',
        backgroundColor: color.WHITE,
        border: BORDER_2(theme.PRIMARY),
    }
});

const activeButtonStyle = {
    backgroundColor: color.WHITE,
    border: BORDER_2(theme.PRIMARY),
    boxSizing: 'border-box'
}

const ButtonTextContainer = styled('div')({
    textAlign: "left",
    textTransform: "none",
    whiteSpace: "nowrap"
});

export default GridButton;