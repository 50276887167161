import { Dispatch } from "react";
import * as actions from "../../actionTypes"

import { deleteTrustLedger, getTrustLedgers, newTrustLedger, saveTrustLedger } from "../../../../libs/service/axios/api";
import { AlertTypes } from "../../../../libs/resources/enums/alertTypes";
import { sanitizeTrustLedgersResponse } from "../../../../libs/types/UniversalSurvey/utils/convertResponse";
import { sanitizeTrustLedgerRequest } from "../../../../libs/types/UniversalSurvey/utils/convertRequest";
import { TrustLedgerType } from "../../../../libs/resources/enums/trustLedger";
import { SimpleTrustLedger } from "../../../../libs/types/UniversalSurvey/TrustLedger/simpleTrustLedger";
import { defaultSimpleTrustLedger } from "../../../../libs/resources/defaults/frontend/defaultSimpleTrustLedger";
import { Loading } from "../../../../libs/resources/enums/loading";

async function getTrustLedgersInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, loading?: Loading) {
    if (loading) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: loading, isLoading: true } });
    getTrustLedgers(dealId)
        .then(function (response: any) {
            const cleanItems = sanitizeTrustLedgersResponse(response.data);
            dispatch({ type: actions.SET_TRUST_LEDGERS, payload: cleanItems });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Getting deal trust ledgers: ${error}`, type: AlertTypes.Error } });
        })
        .finally(function () {
            if (loading) dispatch({ type: actions.SET_OBJECT_LOADING, payload: { obj: loading, isLoading: false } });
        })
}

async function saveTrustLedgerInDeal(dispatch: Dispatch<Record<string, any>>, dealId: string, trustLedgerInfo: SimpleTrustLedger) {
    saveTrustLedger(dealId, String(trustLedgerInfo.id), sanitizeTrustLedgerRequest(trustLedgerInfo))
    .then()
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save trust ledger record: ${error}`, type: AlertTypes.Error } });
    })
}

async function createNewTrustLedger(dispatch: Dispatch<Record<string, any>>, dealId: string, type: TrustLedgerType) {
    newTrustLedger(dealId, { type: type })
    .then(function (response: any) {
        let tempTrustLedger: SimpleTrustLedger = { ...defaultSimpleTrustLedger, id: response.data.id, type: type };
        dispatch({ type: actions.ADD_CUSTOM_TRUST_LEDGER, payload: tempTrustLedger });
    })
    .catch(function (error: any) {
        dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create deal trust ledger: ${error}`, type: AlertTypes.Error } });
    })
}

function deleteTrustLedgerItem(dispatch: Dispatch<Record<string, any>>, dealId: string, trustLedgerRecordId: number) {
    deleteTrustLedger(dealId, String(trustLedgerRecordId))
        .then(function () {
            getTrustLedgersInDeal(dispatch, dealId);
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete trust ledger item: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    getTrustLedgersInDeal,
    saveTrustLedgerInDeal,
    createNewTrustLedger,
    deleteTrustLedgerItem
}