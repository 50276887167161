// Importing MUI components
import { styled } from '@mui/material';

// Importing from DCP UI Library
import { SmallBold } from '../Typography/index';
import theme from '../componentStyling/Theme';

type CounterPillProps = {
    count: number;
    color?: string;
    textColor?: string;
    customStyling?: Object;
};

export default function CounterPill(props: CounterPillProps) {
    return (
        <Pill color={props.color} style={props.customStyling}>
            <SmallBold style={{ color: props.textColor }}>
                {props.count}
            </SmallBold>
        </Pill>
    );
}

const Pill = styled('div')<{
    color?: string;
}>(({ color }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 1rem",
    height: "2.8rem",
    width: "2.8rem",
    background: color ? color : theme.WARNING,
    borderRadius: "5rem",
}));