import * as actions from "../actionTypes"

import { Tabs } from "../../../libs/resources/enums/tabs";
import { Deal } from "../../../libs/types/UniversalSurvey/Deal/deal";
import { DashboardUser } from "../../../libs/types/UniversalSurvey/DashboardUser/dashboardUser";
import { findAndReplace } from "../../../libs/utils/arrays";


export type DealState = {
    isLoading: boolean;
    isSaving: boolean;
    dealInfo: Deal | undefined;
    currTab: Tabs | undefined;
    url: string | undefined;
    clerkOptions: DashboardUser[];
}

export const defaultDealState: DealState = {
    isLoading: false,
    isSaving: false,
    dealInfo: undefined,
    currTab: undefined,
    url: undefined,
    clerkOptions: []
}

export function dealReducer(state: DealState, action: Record<string, any>): DealState {
    switch (action.type) {
        case actions.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actions.SET_IS_SAVING:
            return { ...state, isSaving: action.payload };
        case actions.SET_DEAL_INFO:
            return { ...state, dealInfo: action.payload };
        case actions.SET_CURR_TAB:
            return { ...state, currTab: action.payload };
        case actions.SET_DEAL_INFO_FIELD:
            return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, [action.payload.key]: action.payload.value } : undefined };
        case actions.SET_PAGE_URL:
            return { ...state, url: action.payload }
        case actions.SET_DEAL_PROFESSIONAL:
            if (action.payload.isSigner) {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, signer_record: action.payload.record } : undefined };
            } else {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, lawyer_record: action.payload.record } : undefined };
            }
        case actions.SET_DEAL_PROFESSIONAL_FIRM:
            if (action.payload.isSigner) {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, signer_record: state.dealInfo.signer_record ? { ...state.dealInfo.signer_record, legal_firm_record: action.payload.record } : undefined } : undefined };
            } else {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, lawyer_record: state.dealInfo.lawyer_record ? { ...state.dealInfo.lawyer_record, legal_firm_record: action.payload.record } : undefined } : undefined };
            }
        case actions.SET_DEAL_PROFESSIONAL_OFFICE:
            if (action.payload.isSigner) {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, signer_record: state.dealInfo.signer_record ? { ...state.dealInfo.signer_record, legal_firm_office_record: action.payload.record } : undefined } : undefined };
            } else {
                return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, lawyer_record: state.dealInfo.lawyer_record ? { ...state.dealInfo.lawyer_record, legal_firm_office_record: action.payload.record } : undefined } : undefined };
            }
        case actions.UPDATE_DEAL_INFO_CLIENTS:
            return { ...state, dealInfo: state.dealInfo ? { ...state.dealInfo, clients: findAndReplace([ ...state.dealInfo?.clients ?? [] ], ["id"], [action.payload.id], action.payload) } : undefined };
        case actions.SET_CLERK_OPTIONS:
            return { ...state, clerkOptions: action.payload };
        default:
            return state;
    }
}