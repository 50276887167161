import { useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase";
import { createNewPaymentGrouping } from "../../../../../context/UniversalSurvey/asyncActions/trustLedgers/trustLedgerPaymentGroups";
import { CreatePaymentGroup } from "../../../../../libs/types/UniversalSurvey/TrustLedger/PaymentGroup/paymentGroup";
import Dropdown from "../../../../../../components/Common/Dropdown/Dropdown";
import { PaymentMethodOptions, PaymentRecipientTypeOptions, ThirdPartyTransferOptions } from "../../../../../libs/resources/options";
import { PaymentRecipientType, ThirdPartyTransfer } from "../../../../../libs/resources/enums/paymentGroup";
import { formatPaymentMethod, formatPaymentRecipient, formatThirdPartyTransfer } from "../../../../../libs/utils/formatValues";
import { defaultCreatePaymentGroup } from "../../../../../libs/resources/defaults/frontend/defaultCreatePaymentGroup";
import ComboBox from "../../../../../../components/Common/ComboBox/ComboBox";
import { SimpleClient } from "../../../../../libs/types/UniversalSurvey/Client/simpleClient";
import { getClientTabName, getDealClients } from "../../../../../context/UniversalSurvey/asyncActions/clients";
import { createChargeHolderCompanyOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/chargeHolderCompanies";
import { createLenderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/lenders";
import { createGovernmentOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/governments";
import { createOtherChargeHolderOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/otherChargeHolders";
import { createMortgageBrokerageOptionList } from "../../../../../context/UniversalSurvey/asyncActions/mortgageBrokerages";
import { ChargeHolderCompanyListItem } from "../../../../../libs/types/UniversalSurvey/ChargeHolderCompany/chargeHolderCompany";
import { GovernmentListItem } from "../../../../../libs/types/UniversalSurvey/Government/government";
import { LegalProfessionalListItem } from "../../../../../libs/types/UniversalSurvey/LegalProfessional/legalProfessional";
import { createLegalProfessionalOptionsList } from "../../../../../context/UniversalSurvey/asyncActions/legalProfessionals";
import { LenderListItem } from "../../../../../libs/types/UniversalSurvey/Lender/lender";
import { MortgageBrokerageListItem } from "../../../../../libs/types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { OtherChargeHolderListItem } from "../../../../../libs/types/UniversalSurvey/OtherChargeHolder/otherChargeHolder";
import BasicTextInput from "../../../../../../components/Common/TextField/BasicTextInput";
import { PaymentDeliveryMethod } from "../../../../../libs/resources/enums/paymentMethod";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { Loading } from "../../../../../libs/resources/enums/loading";

type Props = Omit<ModalProps, "children">;

export default function CreatePaymentGroupingModal(props: Props) {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [newPaymentGroup, setNewPaymentGroup] = useState<CreatePaymentGroup>({ ...defaultCreatePaymentGroup });

    const [selectedClient, setSelectedClient] = useState<(SimpleClient & { label: string }) | undefined>(undefined);
    const [selectedCompany, setSelectedCompany] = useState<ChargeHolderCompanyListItem | undefined>(undefined);
    const [selectedGovernment, setSelectedGovernment] = useState<GovernmentListItem | undefined>(undefined);
    const [selectedLawyer, setSelectedLawyer] = useState<LegalProfessionalListItem | undefined>(undefined);
    const [selectedLender, setSelectedLender] = useState<LenderListItem | undefined>(undefined);
    const [selectedBrokerage, setSelectedBrokerage] = useState<MortgageBrokerageListItem | undefined>(undefined);
    const [selectedOther, setSelectedOther] = useState<OtherChargeHolderListItem | undefined>(undefined);

    useEffect(() => {
        getDealClients(dispatch, String(state.deal.dealInfo?.id));
        createLenderOptionsList(dispatch, String(state.deal.dealInfo?.id), undefined);
        createChargeHolderCompanyOptionsList(dispatch, String(state.deal.dealInfo?.id), undefined);
        createGovernmentOptionsList(dispatch, String(state.deal.dealInfo?.id), undefined);
        createOtherChargeHolderOptionsList(dispatch, String(state.deal.dealInfo?.id), undefined);
        createMortgageBrokerageOptionList(dispatch, String(state.deal.dealInfo?.id), undefined);
        createLegalProfessionalOptionsList(dispatch, String(state.deal.dealInfo?.id), state.deal.dealInfo?.region_id);
    }, []);

    function updateCreatePaymentGroup<
        K extends keyof CreatePaymentGroup,
        V extends CreatePaymentGroup[K]
    >(key: K, value: V): void {
        const tempPGObj = { ...newPaymentGroup }
        tempPGObj[key] = value;
        setNewPaymentGroup(tempPGObj);
    }

    function onDiscardNewPayment() {
        setNewPaymentGroup({ ...defaultCreatePaymentGroup });
        props.onClose ? props.onClose() : undefined;
    }

    function isSubmitNewPaymentDisabled(): boolean {
        switch(newPaymentGroup.recipient_type) {
            case PaymentRecipientType.Client:
                if (!selectedClient) return true;
                break;
            case PaymentRecipientType.Company:
                if (!selectedCompany) return true;
                break;
            case PaymentRecipientType.Government:
                if (!selectedGovernment) return true;
                break;
            case PaymentRecipientType.Lawyer:
                if (!selectedLawyer) return true;
                break;
            case PaymentRecipientType.Lender:
                if (!selectedLender) return true;
                break;
            case PaymentRecipientType.MortgageBrokerage:
                if (!selectedBrokerage) return true;
                break;
            case PaymentRecipientType.OtherChargeHolder:
                if (!selectedOther) return true;
                break;
            case PaymentRecipientType.ThirdPartyTransfer:
                if (!newPaymentGroup.third_party_transfer) return true;
                break;
            default:
                return true;
        }
        return false;
    }

    function onSubmitNewPayment() {
        let tempGroup = { ...newPaymentGroup };
        switch(newPaymentGroup.recipient_type) {
            case PaymentRecipientType.Client:
                if (!selectedClient) return;
                tempGroup.client_id = selectedClient.id;
                break;
            case PaymentRecipientType.Company:
                if (!selectedCompany) return;
                tempGroup.company_id = selectedCompany.id;
                break;
            case PaymentRecipientType.Government:
                if (!selectedGovernment) return;
                tempGroup.government_id = selectedGovernment.id;
                break;
            case PaymentRecipientType.Lawyer:
                if (!selectedLawyer) return;
                tempGroup.lawyer_id = selectedLawyer.id;
                break;
            case PaymentRecipientType.MortgageBrokerage:
                if (!selectedBrokerage) return;
                tempGroup.mortgage_brokerage_id = selectedBrokerage.id;
                break;
            case PaymentRecipientType.Lender:
                if (!selectedLender) return;
                tempGroup.lender_id = selectedLender.id;
                break;
            case PaymentRecipientType.OtherChargeHolder:
                if (!selectedOther) return;
                tempGroup.other_charge_holder_id = selectedOther.id;
                break;
            case PaymentRecipientType.ThirdPartyTransfer:
                break;
            default:
                return;
        }
        createNewPaymentGrouping(dispatch, String(state.deal.dealInfo?.id), tempGroup);
    }

    function updatePaymentRecipientType(value: PaymentRecipientType | undefined) {
        updateCreatePaymentGroup("recipient_type", value);
        setSelectedClient(undefined);
        setSelectedCompany(undefined);
        setSelectedGovernment(undefined);
        setSelectedLawyer(undefined);
        setSelectedLender(undefined);
        setSelectedBrokerage(undefined);
        setSelectedOther(undefined);
    }

    function getClientComboboxItems(): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        return options;
    }

    function getCompanyComboboxItems(): ChargeHolderCompanyListItem[] {
        const options: ChargeHolderCompanyListItem[] = [];
        for(const company of state.chargeHolderCompanies.optionList) {
            options.push({ ...company, label: company.name });
        }
        return options;
    }

    function getGovernmentComboboxItems(): GovernmentListItem[] {
        const options: GovernmentListItem[] = [];
        for(const government of state.governments.optionList) {
            options.push({ ...government, label: government.name });
        }
        return options;
    }

    function getLawyerComboboxItems(): LegalProfessionalListItem[] {
        const options: LegalProfessionalListItem[] = [];
        for(const lawyer of state.legalProfessionals.optionList) {
            options.push({ ...lawyer, label: lawyer.name });
        }
        return options;
    }

    function getLenderComboboxItems(): LenderListItem[] {
        const options: LenderListItem[] = [];
        for(const lender of state.lenders.lenderOptionList) {
            options.push({ ...lender, label: lender.name });
        }
        return options;
    }

    function getMortgageBrokerageComboboxItems(): MortgageBrokerageListItem[] {
        const options: MortgageBrokerageListItem[] = [];
        for(const brokerage of state.mortgageBrokerages.brokerageOptionList) {
            options.push({ ...brokerage, label: brokerage.name });
        }
        return options;
    }

    function getOtherComboboxItems(): OtherChargeHolderListItem[] {
        const options: OtherChargeHolderListItem[] = [];
        for(const other of state.otherChargeHolders.optionList) {
            options.push({ ...other, label: other.name });
        }
        return options;
    }

    return (
        <ModalBase
            title={"Create Payment Grouping"}
            open={props.open}
            onClose={() => onDiscardNewPayment()}
            onSubmit={() => onSubmitNewPayment()}
            size="small"
            saveDisabled={isSubmitNewPaymentDisabled()}
            closeAfterSaving={onDiscardNewPayment}
            isSaving={isObjectLoading(state.dataSheet.objectsLoading, [Loading.SaveCreatePaymentGroup])}
        >
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={6}>
                    <Dropdown
                        options={PaymentRecipientTypeOptions}
                        value={newPaymentGroup.recipient_type}
                        label={{ text: "Payment Recipient", inputId: "payment-recipient", isRequired: true }}
                        placeholder="Payment Recipient"
                        isHoverActionHidden={newPaymentGroup.recipient_type ? false : true}
                        handleClear={() => updatePaymentRecipientType(undefined)}
                        onChange={(e) => updatePaymentRecipientType(e.target.value as PaymentRecipientType)}
                        formatValue={formatPaymentRecipient}
                    />
                </Grid>
                {newPaymentGroup.recipient_type === PaymentRecipientType.Client && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Client", inputId: "payment-client", isRequired: true }}
                            options={getClientComboboxItems()}
                            placeholder="Client"
                            value={selectedClient ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedClient(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.Company && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Company", inputId: "payment-company", isRequired: true }}
                            options={getCompanyComboboxItems()}
                            placeholder="Company"
                            value={selectedCompany ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedCompany(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.Government && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Government", inputId: "payment-government", isRequired: true }}
                            options={getGovernmentComboboxItems()}
                            placeholder="Government"
                            value={selectedGovernment ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedGovernment(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.Lawyer && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Lawyer", inputId: "payment-lawyer", isRequired: true }}
                            options={getLawyerComboboxItems()}
                            placeholder="Lawyer"
                            value={selectedLawyer ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedLawyer(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.Lender && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Lender", inputId: "payment-lender", isRequired: true }}
                            options={getLenderComboboxItems()}
                            placeholder="Lender"
                            value={selectedLender ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedLender(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.MortgageBrokerage && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Mortgage Brokerage", inputId: "payment-mortgage-brokerage", isRequired: true }}
                            options={getMortgageBrokerageComboboxItems()}
                            placeholder="Mortgage Brokerage"
                            value={selectedBrokerage ?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedBrokerage(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.OtherChargeHolder && (
                    <Grid item xs={6}>
                        <ComboBox
                            label={{ text: "Other Charge Holder", inputId: "payment-other", isRequired: true }}
                            options={getOtherComboboxItems()}
                            placeholder="Other"
                            value={selectedOther?? { label: "" }}
                            // @ts-ignore
                            onChangeFn={(value) => setSelectedOther(value)}
                        />
                    </Grid>
                )}
                {newPaymentGroup.recipient_type === PaymentRecipientType.ThirdPartyTransfer && (
                    <>
                        <Grid item xs={6}>
                            <Dropdown
                                options={ThirdPartyTransferOptions}
                                value={newPaymentGroup.third_party_transfer}
                                label={{ text: "Third Party Transfer", inputId: "payment-third-party-transfer", isRequired: true }}
                                placeholder="Third Party Transfer"
                                isHoverActionHidden={newPaymentGroup.third_party_transfer ? false : true}
                                handleClear={() => updateCreatePaymentGroup("third_party_transfer", undefined)}
                                onChange={(e) => updateCreatePaymentGroup("third_party_transfer", e.target.value as ThirdPartyTransfer)}
                                formatValue={formatThirdPartyTransfer}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BasicTextInput
                                fullWidth
                                inputProps={{ "aria-label": "Wiring Fee" }}
                                label={{ text: "Wiring Fee", inputId: "wiring_fee" }}
                                value={newPaymentGroup.wiring_fee !== undefined ? String(newPaymentGroup.wiring_fee) : undefined}
                                onChange={(e) => updateCreatePaymentGroup("wiring_fee", Number(e.target.value))}
                                moneyField
                                valueType="positive"
                                placeholder="0.00"
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Dropdown
                        options={PaymentMethodOptions}
                        value={newPaymentGroup.payment_method}
                        label={{ text: "Payment Method", inputId: "payment-method" }}
                        placeholder="Payment Method"
                        isHoverActionHidden={newPaymentGroup.payment_method ? false : true}
                        handleClear={() => updateCreatePaymentGroup("payment_method", undefined)}
                        onChange={(e) => updateCreatePaymentGroup("payment_method", e.target.value as PaymentDeliveryMethod)}
                        formatValue={formatPaymentMethod}
                    />
                </Grid>
            </Grid>
        </ModalBase>
    );
}