import React, { useState } from "react";

import { styled } from "@mui/material";
import { Popover } from "@mui/material";

import colors from "../componentStyling/Colors";
import theme from "../componentStyling/Theme";
import { H4, Body, Small, Large } from "../Typography/index";
import { SearchIcon, FilterIcon, XCircle, CloseIcon, StorageIcon } from "../Icons/Iconography";
import { BORDER_1 } from "../componentStyling/Styles";
import BasicButton, { ButtonProps } from "../Button/BasicButton";
import { Row } from "./TableBase";
import SearchBar from "../SearchBar/SearchBar";

export type TableFilterProps = {
    filterOptions: string[];
    filterSelected: string | null;
    setFilterSelected: (value: string | null) => void;
}

export type TitleBarSpecificProps = {
    searchEnabled?: boolean;
    selectedStorageEnabled?: boolean;
    selectedXEnabled?: boolean;
    onSearch?: (searchValue: string) => void;
    onSelectedStorageClick?: (rowsChecked: (boolean | "partial")[]) => void;
    onSelectedXClick?: (rowsChecked: (boolean | "partial")[]) => void;
    filterProps?: TableFilterProps;
    buttonsInTitleBar?: ButtonProps[];
    title?: string;
    extraTitleContent?: JSX.Element;
}

type TitleBarProps = TitleBarSpecificProps & {
    selectedState: boolean;
    nothingChecked: boolean;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    rowsChecked: (boolean | "partial")[];
    rows: Row[];
}

export default function TitleBar(props: TitleBarProps) {
    const [searchOpen, setSearchOpen] = useState<boolean>(false);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [filterAnchor, setFilterAnchor] = useState<HTMLDivElement | null>(null);
    const [selectedFilterHovered, setSelectedFilterHovered] = useState<boolean>(false);
    const [removeHovered, setRemoveHovered] = useState<boolean>(false);

    function handleSearchClose(): void {
        setSearchOpen(false);
        props.setSearchValue("");
        props.onSearch ? props.onSearch("") : undefined;
    }

    function handleSearchBlur(): void {
        if (props.searchValue === "") {
            setSearchOpen(false);
        }
    }

    function handleSelectedStorageClick(): void {
        props.onSelectedStorageClick ? props.onSelectedStorageClick(props.rowsChecked) : undefined;
    }

    function handleSelectedXClick(): void {
        props.onSelectedXClick ? props.onSelectedXClick(props.rowsChecked) : undefined;
    }

    function closeFilterPopover() {
        setFilterAnchor(null);
        setFilterOpen(false);
    }

    return (
        <Wrapper selectedState={props.selectedState}>
            {props.nothingChecked ? (
                <>
                    <TitleDiv>
                        <STYLED_H4>{props.title}</STYLED_H4>
                        {props.extraTitleContent}
                    </TitleDiv>
                    <TitleBarIconDiv searchOpen={searchOpen || props.filterProps?.filterSelected !== null}>
                        {props.searchEnabled && (
                            searchOpen ? (
                                <>
                                    <SearchBar 
                                        searchValue={props.searchValue}
                                        setSearchValue={props.setSearchValue}
                                        onSearch={props.onSearch}
                                        onBlur={handleSearchBlur}
                                        autoFocus
                                        id={props.title}
                                    />
                                    <CloseSearchWrapper onClick={handleSearchClose}>
                                        <CloseIcon color={theme.INPUT} />
                                    </CloseSearchWrapper>
                                </>
                            ) : (
                                <CloseSearchWrapper onClick={() => setSearchOpen(true)} data-testid={`table-search-icon${props.title ? `-${props.title}` : ""}`}>
                                    <SearchIcon color={colors.BLACK}/>
                                </CloseSearchWrapper>
                            )
                        )}
                        {props.filterProps && (searchOpen || props.filterProps.filterSelected) && <Divider />}
                        {props.filterProps?.filterSelected && 
                            <SelectedFilterDiv
                                onMouseEnter={() => setSelectedFilterHovered(true)}
                                onMouseLeave={() => setSelectedFilterHovered(false)}
                                filterHovered={selectedFilterHovered}
                                removeHovered={removeHovered}
                            >
                                <Large style={{ color: removeHovered ? colors.WHITE : colors.BLACK }}>{props.filterProps.filterSelected}</Large>
                                {selectedFilterHovered &&
                                    <PointerDiv
                                        onClick={() => {
                                            props.filterProps?.setFilterSelected(null);
                                            setRemoveHovered(false);
                                            setSelectedFilterHovered(false);
                                        }}
                                        onMouseEnter={() => setRemoveHovered(true)}
                                        onMouseLeave={() => setRemoveHovered(false)}
                                    >
                                        <XCircle color={removeHovered ? colors.WHITE : colors.BLACK}/>
                                    </PointerDiv>
                                }
                            </SelectedFilterDiv>
                        }
                        {props.filterProps && (
                            <>
                                <PointerDiv
                                    onClick={(e) => {
                                        setFilterOpen(!filterOpen);
                                        setFilterAnchor(e.currentTarget);
                                    }}
                                >
                                    <FilterIcon color={colors.BLACK} />
                                </PointerDiv>
                                <Popover
                                    open={filterOpen}
                                    anchorEl={filterAnchor}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    onClose={closeFilterPopover}
                                >
                                    <FilterDiv>
                                        <Small>Filters:</Small>
                                        {props.filterProps.filterOptions.map((option, i) => 
                                            <StyledBody
                                                key={`filter-option-${option}`}
                                                onClick={() => {
                                                    props.filterProps?.setFilterSelected(option);
                                                    closeFilterPopover();
                                                }}
                                                showBorder={i !== props.filterProps?.filterOptions.length! - 1}
                                            >
                                                {option}
                                            </StyledBody>
                                        )}
                                    </FilterDiv>
                                </Popover>
                            </>
                        )}
                    </TitleBarIconDiv>
                </>
            ) : (
                <>
                    <Body>{`${props.rowsChecked.filter((row) => row === true || row === "partial").length} selected`}</Body>
                    <TitleBarIconDiv selectedIcons>
                        {props.buttonsInTitleBar && 
                            <CustomButtonDiv>
                                {props.buttonsInTitleBar.map((buttonProps, i) => (
                                    <BasicButton
                                        {...buttonProps}
                                        size="small"
                                        key={`custom-button-${i}`}
                                        onClick={() => buttonProps.onClick ? buttonProps.onClick(props.rows.filter((row, i) => props.rowsChecked[i] === true)) : undefined}
                                    />
                                ))}
                            </CustomButtonDiv>
                        }
                        
                        {props.selectedStorageEnabled && (
                            <PointerDiv onClick={handleSelectedStorageClick}>
                                <StorageIcon color={colors.BLACK} />
                            </PointerDiv>
                        )}
                        {props.selectedXEnabled && (
                            <PointerDiv onClick={handleSelectedXClick}>
                                <XCircle color={colors.BLACK} />
                            </PointerDiv>
                        )}
                    </TitleBarIconDiv>
                </>
            )
            }
        </Wrapper>
    )
}

const TitleDiv = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "1rem"
})

const CustomButtonDiv = styled('div')({
    display: "flex",
    flexDirection: "row",
    gap: "1rem"
})

const Wrapper = styled('div')<{
    selectedState: boolean;
}>(({ selectedState }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 1rem 0",
    marginTop: "1rem",
    gap: "4rem",
    height: "5rem",
    whiteSpace: "nowrap",
    background: selectedState ? theme.SELECTED : undefined
}));

const SelectedFilterDiv = styled('div', { shouldForwardProp:
    (prop) => prop !== "filterHovered" && prop !== "removeHovered"
})<{
    filterHovered: boolean;
    removeHovered: boolean;
}>(({ filterHovered, removeHovered }) => ({
    display: "flex",
    padding: "0.2rem",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: filterHovered ? "0.6rem 5rem 5rem 0.6rem" : "0.6rem",
    background: removeHovered ? theme.ERROR : colors.GRAY_50
}))

const FilterDiv = styled('div')({
    minWidth: "16rem",
    padding: "1rem",
    borderRadius: "0.6rem",
    background: colors.GRAY_50,
    boxShadow: "0 0 4rem 0 rgba(0, 0, 0, 0.25), 0 0 0.4rem 0 rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
})

const STYLED_H4 = styled(H4)({
    margin: "0.5rem 0"
});

const StyledBody = styled(Body, { shouldForwardProp: 
    (prop) => prop !== "showBorder"
})<{
    showBorder?: boolean;
}>(({ showBorder }) => ({
    display: "flex",
    padding: "1rem 0",
    alignItems: "flex-start",
    gap: "1rem",
    alignSelf: "stretch",
    borderBottom: showBorder ? BORDER_1(colors.GRAY_300) : undefined,
    cursor: "pointer"
}))

const TitleBarIconDiv = styled('div')<{
    searchOpen?: boolean;
    selectedIcons?: boolean;
}>(({ searchOpen, selectedIcons }) => ({
    display: "flex",
    flexDirection: "row",
    gap: searchOpen ? "1.6rem" : "2rem",
    alignItems: "center",
    justifyContent: "right",
    width: "100%",
    marginRight: selectedIcons ? "0.3rem" : "0rem"
}));

const CloseSearchWrapper = styled('div')({
    cursor: "pointer",
    paddingTop: "0.2rem"
});

const PointerDiv = styled('div')({
    cursor: "pointer"
});

const Divider = styled('div')({
    width: 0,
    height: "4rem",
    borderLeft: BORDER_1(colors.GRAY_300)
});