import { LenderBranch } from "../../../types/UniversalSurvey/Lender/LenderBranch/lenderBranch";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultLenderBranch: LenderBranch = {
    id: -1,
    branch_number: undefined,
    general_contact_name: undefined,
    general_address: defaultSimpleAddress,
    general_email: undefined,
    final_report_contact_name: undefined,
    final_report_address: defaultSimpleAddress,
    final_report_email: undefined,
    notes: undefined,
    all_regions: undefined,
    preferred_payment_method: undefined,
    date_created: undefined,
    date_updated: undefined,

    lender: undefined,
    regions: [],
    address_for_service: defaultSimpleAddress,
    address_for_registration: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    lawyer: undefined
}