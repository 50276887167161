import { GlobalLenderContact, LenderContact } from "../../../types/UniversalSurvey/LenderContact/lenderContact";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultSimpleLenderBranch } from "./defaultSimpleLenderBranch";

export const defaultLenderContact: LenderContact = {
    id: undefined,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    role: undefined,
    email: undefined,
    phone_work: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    lender_record: undefined,
    lender_contact: undefined
}

export const defaultGlobalLenderContact: GlobalLenderContact = {
    id: -1,
    name: undefined,
    email: undefined,
    phone_work: undefined,
    fax: undefined,
    notes: undefined,
    date_created: undefined,
    date_updated: undefined,

    lender: defaultSimpleLenderBranch
}