import * as actions from "../actionTypes"

import { FireInsuranceCompany, FireInsuranceCompanyListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceCompany/fireInsuranceCompany";
import { findAndReplace } from "../../../libs/utils/arrays";


export type FireInsuranceCompaniesState = {
    optionList: FireInsuranceCompanyListItem[];
    companyInfo: FireInsuranceCompany | undefined;
    selectedCompany: FireInsuranceCompanyListItem | undefined;
    editingCompanyRecord: boolean;
}

export const defaultFireInsuranceCompaniesState: FireInsuranceCompaniesState = {
    optionList: [],
    companyInfo: undefined,
    selectedCompany: undefined,
    editingCompanyRecord: false
}

export function fireInsuranceCompaniesReducer(state: FireInsuranceCompaniesState, action: Record<string, any>): FireInsuranceCompaniesState {
    switch (action.type) {
        case actions.SET_FIRE_INSURANCE_COMPANY_OPTION_LIST:
            return { ...state, optionList: action.payload };
        case actions.SET_SELECTED_FIRE_INSURANCE_COMPANY:
            const selectedCompany = state.optionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedCompany: selectedCompany };
        case actions.REPLACE_FIRE_INSURANCE_COMPANY_OPTION_WITH_RECORD:
            return { ...state, optionList: findAndReplace([...state.optionList], ["id", "isRecord"], [action.payload.oldCompanyId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.ADD_TO_FIRE_INSURANCE_COMPANY_OPTIONS:
            return { ...state, optionList: [...state.optionList, action.payload] };
        case actions.SET_EDITING_FIRE_INSURANCE_COMPANY_RECORD:
            return { ...state, editingCompanyRecord: action.payload };
        case actions.SET_FIRE_INSURANCE_COMPANY_INFO:
            return { ...state, companyInfo: action.payload };
        default:
            return state;
    }
}