import { Lien } from "../../../types/UniversalSurvey/ExistingLien/lien";

export const defaultLien: Lien = {
    id: -1,
    instrument_type: undefined,
    charge_holder_type: undefined,
    mortgage_loan_number: undefined,
    account_number: undefined,
    registration_number: undefined,
    registration_date: undefined,
    correspondence: undefined,
    is_to: undefined,
    step_heloc: false,
    priority_after_closing: undefined,
    priority_before_closing: undefined,
    collateral_mortgage: undefined,
    obtain_info_statement: undefined,
    principal_balance: undefined,
    payout_calc_start_date: undefined,
    payout_calc_end_date: undefined,
    payout_calc_principal: undefined,
    payout_calc_amount_per_day: undefined,
    separate_cheque_for_discharge_fee: false,
    payout_calc_discharge_fee: undefined,
    discharge_registered_by: undefined,
    date_created: undefined,
    date_updated: undefined,

    property_id: undefined,
    lender_id: undefined,
    lender_contact_list: undefined,
    charge_holder_company_id: undefined,
    government_id: undefined,
    other_charge_holder_id: undefined,

    lender_record: undefined,
    property_record: undefined,
    charge_holder_company_record: undefined,
    government_record: undefined,
    charge_holder_company_contact_list: undefined,
    government_contact_list: undefined,
    other_charge_holder_record: undefined,
    condition_record: undefined,
    payout_calc_additional_amount: []
}