import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import { TitleInsurance } from "../../../libs/types/UniversalSurvey/TitleInsurance/titleInsurance";
import { Body, BodyBold, H4, Small } from "../../../../components/Common/Typography/index";
import Pill from "../../../../components/Common/StatusPills/Pill";
import { TitleInsuranceStatus } from "../../../libs/resources/enums/titleInsuranceStatus";
import { EditIcon, CheckCircle, EmailIcon, RefreshIcon } from "../../../../components/Common/Icons/Iconography";
import colors from "../../../../components/Common/componentStyling/Colors";
import theme from "../../../../components/Common/componentStyling/Theme";
import { saveTitleInsurance, switchToTitleInsuranceSection } from "../../../context/UniversalSurvey/asyncActions/titleInsurance";
import TableBase from "../../../../components/Common/Table/TableBase";
import LineItemInput from "../../../../components/Common/LineItemInput/LineItemInput";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import { capitalizeFirstLetter } from "../../../libs/utils/formatValues";
import { FLEX_GRID } from "../../../../components/Common/componentStyling/Styles";
import { SimpleTitleInsurancePremium } from "../../../libs/types/UniversalSurvey/TitleInsurancePremium/simpleTitleInsurancePremium";
import { FormatNumeric } from "../../../../components/Common/TextField/MoneyFormat";
import {
    createTitleInsurancePremium,
    deleteTitleInsurancePremium,
    saveTitleInsurancePremium
} from "../../../context/UniversalSurvey/asyncActions/titleInsurancePremiums";

export default function TitleInsuranceSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [titleInsuranceObj, setTitleInsuranceObj] = useState<TitleInsurance>(state.titleInsurance.titleInsuranceInfo!);
    const [premiums, setPremiums] = useState<SimpleTitleInsurancePremium[]>([]);

    const premiumRef = useRef<SimpleTitleInsurancePremium[]>(state.titleInsurancePremiums.premiums!);

    useEffect(() => {
        dispatch({ type: actions.SET_TAB_OPTIONS, payload: [] });
        cleanUpEmptyPremiums()
        for(const premium of state.titleInsurancePremiums.premiums!) {
            if (state.titleInsurancePremiums.premiumsBeingSaved.includes(premium.id)) {
                dispatch({ type: actions.SET_IS_TITLE_INSURANCE_PREMIUM_SAVING, payload: true });
            }
        }

        return () => {
            cleanUpEmptyPremiums();
            dispatch({ type: actions.SET_TITLE_INSURANCE_INFO, payload: undefined });
            dispatch({ type: actions.SET_TITLE_INSURANCE_PREMIUMS, payload: undefined });
        };
    }, [])

    useEffect(() => {
        setTitleInsuranceObj(state.titleInsurance.titleInsuranceInfo!);
        if (state.titleInsurance.titleInsurancesBeingSaved.includes(state.titleInsurance.titleInsuranceInfo!.id)) {
            dispatch({ type: actions.SET_IS_TITLE_INSURANCE_SAVING, payload: true });
        }
    }, [state.titleInsurance.titleInsuranceInfo?.id])

    useEffect(() => {
        premiumRef.current = state.titleInsurancePremiums.premiums!;
        setPremiums(state.titleInsurancePremiums.premiums!);
    }, [state.titleInsurancePremiums.premiums])    

    useEffect(() => {
        if (state.titleInsurance.shouldRefreshTitleInsurance) {
            switchToTitleInsuranceSection(dispatch, String(state.deal.dealInfo?.id));
            dispatch({ type: actions.SET_SHOULD_REFRESH_TITLE_INSURANCE, payload: false });
        }
    }, [state.titleInsurance.shouldRefreshTitleInsurance])

    useEffect(() => {
        if (state.titleInsurancePremiums.shouldRefreshPremium) {
            switchToTitleInsuranceSection(dispatch, String(state.deal.dealInfo?.id));
            dispatch({ type: actions.SET_SHOULD_REFRESH_TITLE_INSURANCE_PREMIUM, payload: false });
        }
    }, [state.titleInsurancePremiums.shouldRefreshPremium])

    function cleanUpEmptyPremiums() {
        for (const premium of premiumRef.current) {
            if (isPremiumEmpty(premium)) {
                deleteTitleInsurancePremium(dispatch, String(state.deal.dealInfo?.id), premium.id);
            }
        }
    }

    function updateTitleInsuranceInfo<
        K extends keyof TitleInsurance,
        V extends TitleInsurance[K]
    >(key: K, value: V): void {
        const tempTitleInsuranceObj = { ...titleInsuranceObj };
        tempTitleInsuranceObj[key] = value;
        setTitleInsuranceObj(tempTitleInsuranceObj);
    }

    const debouncedSave = useCallback(
        debounce((titleInsuranceObj) => {
            saveTitleInsurance(dispatch, String(state.deal.dealInfo?.id), titleInsuranceObj);
            dispatch({ type: actions.REMOVE_TITLE_INSURANCE_BEING_SAVED, payload: titleInsuranceObj.id });
        }, 1000), []);

    const prevTitleInsurance = usePrevious(titleInsuranceObj);
    useDidUpdateEffect(() => {
        if (prevTitleInsurance?.id === titleInsuranceObj.id) {
            dispatch({ type: actions.ADD_TITLE_INSURANCE_BEING_SAVED, payload: titleInsuranceObj.id });
            debouncedSave(titleInsuranceObj);
        }
    }, [titleInsuranceObj]);

    function updatePremiumInfo<
        K extends keyof SimpleTitleInsurancePremium,
        V extends SimpleTitleInsurancePremium[K]
    >(key: K, value: V, premiumId: number): void {
        if (Number.isNaN(value)) {
            return;
        }
        dispatch({ type: actions.ADD_TITLE_INSURANCE_PREMIUM_BEING_SAVED, payload: premiumId });
        const matchingPremium = premiums.find((premium) => premium.id === premiumId);
        if (matchingPremium) {
            const tempPremiums = [...premiums];
            const tempPremium = { ...matchingPremium };
            tempPremium[key] = value;
            if (key === "price" && typeof value === "number" && value < 0) {
                tempPremium.tax = undefined;
            }
            tempPremiums[tempPremiums.indexOf(matchingPremium)] = tempPremium;
            setPremiums(tempPremiums);
            dispatch({ type: actions.SET_TITLE_INSURANCE_PREMIUMS, payload: tempPremiums });
            debouncedSavePremiums(tempPremiums, [...state.titleInsurancePremiums.premiumsBeingSaved, premiumId]);
        }
    }

    const debouncedSavePremiums = useCallback(
        debounce((premiums, premiumsBeingSaved) => {
            for (const premium of premiums) {
                if (premiumsBeingSaved!.includes(premium.id)) {
                    saveTitleInsurancePremium(dispatch, String(state.deal.dealInfo?.id), premium);
                    dispatch({ type: actions.REMOVE_TITLE_INSURANCE_PREMIUM_BEING_SAVED, payload: premium.id });
                }
            }
        }, 1000), []);

    function getStatusPillProps(): [{ backgroundColor: string, color: string }, React.ReactNode] {
        switch (titleInsuranceObj.status) {
            case TitleInsuranceStatus.Completed:
                return [{ backgroundColor: colors.GREEN_500, color: colors.WHITE }, <CheckCircle color={colors.WHITE} />];
            case TitleInsuranceStatus.Submitted:
                return [{ backgroundColor: colors.YELLOW_200, color: colors.BLACK }, <EmailIcon color={colors.BLACK} />];
            case TitleInsuranceStatus.Underwriting:
                return [{ backgroundColor: colors.BLUE_100, color: colors.BLACK }, <EditIcon color={colors.BLACK} />];
            default:
                return [{ backgroundColor: colors.GREEN_500, color: colors.WHITE }, <CheckCircle color={colors.WHITE} />];
        }
    }

    function getPremiumTotal(premium: SimpleTitleInsurancePremium) {
        return premium.quantity ? premium.quantity * ((premium.price ?? 0) + (premium.tax ?? 0)) : 0
    }

    function getGrandTotals() {
        let total = 0;
        let discount = 0;
        for (const premium of premiums) {
            const premiumTotal = getPremiumTotal(premium);
            if (premiumTotal < 0) {
                discount += premiumTotal;
            } else {
                total += premiumTotal;
            }
        }
        return [total + discount, total];
    }

    function createPremiumButtonDisabled(discount: boolean): boolean {
        for (const premium of premiums) {
            if (isPremiumEmpty(premium)) {
                if (!premium.discount) {
                    return true;
                } else if (premium.discount && discount) {
                    return true;
                }
            }
        }
        return false;
    }

    function isPremiumEmpty(premium: SimpleTitleInsurancePremium): boolean {
        if (premium.charge === undefined && premium.quantity === undefined && premium.price === undefined && premium.tax === undefined) {
            return true;
        }
        return false;
    }

    return (
        (state.titleInsurance.isTitleInsuranceSaving || state.titleInsurancePremiums.isPremiumSaving) ? <CircularLoader containerHeight="70vh" /> :
            <>
                <PaddedGrid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={3}>
                        <FlexDiv>
                            <StyledSmall>Deal ID:</StyledSmall>
                            <div style={{ flexGrow: 1 }}>
                                <BasicTextInput
                                    fullWidth
                                    placeholder="123456789"
                                    value={titleInsuranceObj.provider_deal_id}
                                    onChange={(e) => updateTitleInsuranceInfo("provider_deal_id", e.target.value)}
                                    size="extra-small"
                                />
                            </div>
                        </FlexDiv>
                    </Grid>
                    <Grid item xs={3}>
                        <FlexDiv>
                            <StyledSmall>Status:</StyledSmall>
                            <Pill
                                label={capitalizeFirstLetter(titleInsuranceObj.status ?? TitleInsuranceStatus.Completed)!}
                                style={{ ...getStatusPillProps()[0], margin: "0rem 1rem 0rem 0rem" }}
                                icon={getStatusPillProps()[1]}
                            />
                            <RefreshIcon color={theme.INPUT} />
                        </FlexDiv>
                    </Grid>
                    <Grid item xs={6} />

                    <Grid item xs={3}>
                        <BasicTextInput
                            fullWidth
                            placeholder="98765-1234"
                            value={titleInsuranceObj.policy_number}
                            onChange={(e) => updateTitleInsuranceInfo("policy_number", e.target.value)}
                            label={{ text: "Policy No.", inputId: "policy_number" }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <BasicTextInput
                            fullWidth
                            moneyField
                            valueType="positive"
                            placeholder="0.00"
                            value={titleInsuranceObj.coverage_amount !== undefined ? String(titleInsuranceObj.coverage_amount) : undefined}
                            // @ts-ignore
                            onChange={(e) => updateTitleInsuranceInfo("coverage_amount", e.target.value)}
                            label={{ text: "Coverage Amount", inputId: "coverage_amount" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicTextInput
                            fullWidth
                            placeholder="Lender name"
                            value={titleInsuranceObj.name_of_insured}
                            onChange={(e) => updateTitleInsuranceInfo("name_of_insured", e.target.value)}
                            label={{ text: "Name of Insured", inputId: "name_of_insured" }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <H4>Premium</H4>
                    </Grid>
                    <Grid item xs={12}>
                        {premiums.length > 0 && 
                            <TableBase 
                                columnHeadings={[
                                    { label: "Charge", objKey: "charge" },
                                    { label: "Qty", objKey: "quantity" },
                                    { label: "Price", objKey: "price" },
                                    { label: "Tax", objKey: "tax" },
                                    { label: "Total", objKey: "total" },
                                    { label: "", objKey: "delete-button"}
                                ]}
                                tableRows={[]}
                                columnSizes={[4, 1, 2, 2, 2, 1]}
                                id="title-insurance-premiums"
                            />
                        }
                        {
                            premiums.map((premium, index) => (
                                <LineItemInput
                                    key={index}
                                    textValue={premium.charge}
                                    size={5}
                                    placeholder="Charge name"
                                    textOnChange={(e) => updatePremiumInfo("charge", e.target.value, premium.id)}
                                    onRemove={() => deleteTitleInsurancePremium(dispatch, String(state.deal.dealInfo?.id), premium.id)}
                                    valueItems={[
                                        {
                                            size: 1,
                                            value: premium.quantity !== undefined ? String(premium.quantity) : undefined,
                                            valueType: "positive",
                                            onChange: (e) => updatePremiumInfo("quantity", e.target.value === "" ? undefined : Number(e.target.value), premium.id),
                                            id: "quantity",
                                            isMoneyField: false,
                                            placeholder: "0"
                                        },
                                        {
                                            size: 2,
                                            value: premium.price !== undefined ? String(premium.price) : undefined,
                                            valueType: premium.discount ? "negative" : "either", 
                                            onChange: (e) => updatePremiumInfo("price", e.target.value, premium.id),
                                            id: "price"
                                        },
                                        {
                                            size: 2,
                                            value: premium.tax !== undefined ? String(premium.tax) : undefined,
                                            valueType: "positive",
                                            onChange: (e) => updatePremiumInfo("tax", e.target.value, premium.id),
                                            id: "tax",
                                            readOnly: premium.price && premium.price < 0 ? true : false
                                        },
                                        {
                                            size: 2,
                                            value: String(getPremiumTotal(premium)),
                                            valueType: "either",
                                            id: "total",
                                            readOnly: true
                                        }
                                    ]}
                                />
                            ))
                        }
                        </Grid>
                        <FLEX_GRID item xs={12}>
                            <BasicButton
                                label={{ text: "Add Charge", inputId: "add-charge" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createTitleInsurancePremium(dispatch, String(state.deal.dealInfo?.id))}
                                disabled={createPremiumButtonDisabled(false)}
                            />
                            <BasicButton
                                label={{ text: "Add Discount", inputId: "add-discount" }}
                                action="add"
                                typeOf="secondary"
                                onClick={() => createTitleInsurancePremium(dispatch, String(state.deal.dealInfo?.id), true)}
                                disabled={createPremiumButtonDisabled(true)}
                            />
                        </FLEX_GRID>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            <FlexDiv>
                                <Body style={{flexGrow: 1}}>Discounted:</Body>
                                <Body>{FormatNumeric({ value: getGrandTotals()[0] })}</Body>
                            </FlexDiv>
                            <FlexDiv>
                                <BodyBold style={{flexGrow: 1}}>Total:</BodyBold>
                                <BodyBold>{FormatNumeric({ value: getGrandTotals()[1] })}</BodyBold>
                            </FlexDiv>
                        </Grid>
                </PaddedGrid>
            </>
    )
};

const PaddedGrid = styled(Grid)({
    paddingTop: "5rem"
})

const FlexDiv = styled('div')({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
})

const StyledSmall = styled(Small)({
    marginRight: "4rem",
    width: "5.7rem",
    flexShrink: 0,
})