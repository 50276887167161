import { GlobalLegalFirmOfficeRequest, LegalFirmOfficeRequest } from "../../../types/UniversalSurvey/LegalFirmOffice/legalFirmOfficeRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";

export const defaultLegalFirmOfficeRequest: LegalFirmOfficeRequest = {
    general_address: defaultAddressRequest,
    jurisdiction: null,
    municipality: null,
    phone: null,
    phone_extension: null,
    fax: null,
    email: null,
}

export const defaultGlobalLegalFirmOfficeRequest: GlobalLegalFirmOfficeRequest = {
    ...defaultLegalFirmOfficeRequest
}