import { GuarantorWaiverOfIlaContext, GuarantorWaiverOfIlaContextRequest } from "../../../../types/UniversalSurvey/Documents/documents/guarantorWaiverOfIlaDocument";
import { defaultSimpleMortgageTransaction } from "../../frontend/defaultSimpleMortgageTransaction";


export const defaultGuarantorWaiverOfIlaContext: GuarantorWaiverOfIlaContext = {
    mortgage_id: -1,
    
    mortgage_record: defaultSimpleMortgageTransaction,
}

export const defaultGuarantorWaiverOfIlaContextRequest: GuarantorWaiverOfIlaContextRequest = {
    mortgage_id: -1
}