function displayListKey(list: string[] | undefined, key: string) {
    if (list && list.length > 1) {
        return key + "s:";
    } else return key + ":";
}

function getStrOutput(list: string[]) {

    var outStr = "";
    var count = list.length;

    if (count > 1){
        if (count < 3) {
            outStr = list[0] + ", " + list[1];
        } else {
            for (let i = 0; i < count - 1; i++) {
                outStr = outStr + list[i] + ", ";
            }
            outStr = outStr + "and " + list[list.length - 1];
        }
    } else {
        outStr = list[0];
    }

    return outStr;
}

export { getStrOutput, displayListKey}