import { useState, useRef, useEffect } from "react";


export const useObserveElementWidth = <T extends HTMLElement>() => {
    const [docWidth, setDocWidth] = useState(0);
    const docContainerRef = useRef<T>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setDocWidth(entries[0].contentRect.width);
        });

        if (docContainerRef.current) {
            observer.observe(docContainerRef.current);
        }

        return () => {
            docContainerRef.current && observer.unobserve(docContainerRef.current);
        };
    }, []);

    return {
        docWidth,
        docContainerRef
    };
};