import { DocumentType } from "../../../../resources/enums/documents/documentType";
import { Document } from "../baseDocument";
import { DraThreeOrMoreContextRequest } from "../documents/draThreeOrMoreDocument";

function createDraThreeOrMoreName(): string {
    return "Document Registration Agreement(3 or more parties)";
}

function isDraThreeOrMoreDocInDeal(docList: Document[]): boolean {
    const matchingDoc = docList.find((doc) => doc.type === DocumentType.DraThreeOrMore);
    return matchingDoc ? true : false
}

function createDraThreeOrMoreContextRequest(): DraThreeOrMoreContextRequest {
    return {};
}

function getDraThreeOrMoreDocData(): [Object, string] {
    return [createDraThreeOrMoreContextRequest(), createDraThreeOrMoreName()];
}

export {
    isDraThreeOrMoreDocInDeal,
    getDraThreeOrMoreDocData
}