import { Affidavit } from "../../../types/UniversalSurvey/Affivadit/affidavit";

export const defaultAffidavit: Affidavit = {
    id: -1,
    client_id: undefined,
    amount: undefined,
    cost: undefined,
    debtors: undefined,
    execution_num: undefined,
    certificate_num: undefined,
    plaintiffs: undefined,
    lawyer_declaration: false,
    date_created: undefined,
    date_updated: undefined,

    client_record: undefined
}