import { SimpleChargeHolderCompanyContact } from "../../../types/UniversalSurvey/ChargeHolderCompanyContact/simpleChargeHolderCompanyContact";
import { defaultSimpleChargeHolderCompany } from "./defaultSimpleChargeHolderCompany";

export const defaultSimpleChargeHolderCompanyContact: SimpleChargeHolderCompanyContact = {
    id: -1,
    name: undefined,
    role: undefined,
    
    charge_holder_company_record: defaultSimpleChargeHolderCompany,
    charge_holder_company_contact: undefined
}