import { DocumentType } from "conveyance/libs/resources/enums/documents/documentType";
import { TransactionTypes } from "../../../../resources/enums/transactions";
import { Transaction } from "../../Transaction/transaction";
import { Document } from "../baseDocument";
import { DocumentCategory } from "../../../../resources/enums/documents/category";
import { ConsentToActConflictOfMortgageContextRequest } from "../documents/consentToActConflictOfMortgageDocument";


function createConsentToActConflictOfMortgageName(mortgage: Transaction | undefined): string {
    if (!mortgage) return "";
    let name = "Consent to Act Re:Conflict";
    
    if (mortgage.label) {
        name += ` - ${mortgage.label}`;
    }
    return name;
}

function isConsentToActConflictOfMortgageDocInDeal(docList: Document[], mortgage: Transaction): boolean {
    const tempDocs = docList.filter((doc) => doc.type === DocumentType.ConsentToActConflictOfMortgage && doc.category === DocumentCategory.Template)
    for (const doc of tempDocs) {
        if (doc.context.mortgage_id === mortgage.id) return true
    }
    return false;
}

function createConsentToActConflictOfMortgageContextRequest(mortgage: Transaction | undefined): ConsentToActConflictOfMortgageContextRequest {
    return { mortgage_id: mortgage ? mortgage.id : -1 };
}

function getConsentToActConflictOfMortgageDocData(transactionsInDeal: Transaction[], contextId: number): [Object, string] {
    const matchingMortgage = transactionsInDeal.find((mortgage) => mortgage.id === contextId && mortgage.transaction_type === TransactionTypes.Mortgage);
    return [createConsentToActConflictOfMortgageContextRequest(matchingMortgage), createConsentToActConflictOfMortgageName(matchingMortgage)];
}

function getConsentToActConflictOfMortgageContextOptions(transactionsInDeal: Transaction[], docList: Document[]) {
    const options = [];
    for (const mortgage of transactionsInDeal.filter((transaction) => transaction.transaction_type === TransactionTypes.Mortgage)) {
        if(!isConsentToActConflictOfMortgageDocInDeal(docList, mortgage)) {
            options.push(mortgage.id);
        }
    }
    return options;
}

export {
    getConsentToActConflictOfMortgageDocData,
    getConsentToActConflictOfMortgageContextOptions
}