import { GlobalLenderContactRequest, LenderContactRequest } from "../../../types/UniversalSurvey/LenderContact/lenderContactRequest";

export const defaultLenderContactRequest: LenderContactRequest = {
    name: null,
    email: null,
    role: null,
    phone_work: null,
    fax: null
}

export const defaultGlobalLenderContactRequest: GlobalLenderContactRequest = {
    lender_id: -1,
    name: null,
    email: null,
    phone_work: null,
    fax: null,
    notes: null
}