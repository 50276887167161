import * as actions from "../actionTypes"

import { FireInsuranceBroker, FireInsuranceBrokerListItem } from "../../../libs/types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import { findAndReplace } from "../../../libs/utils/arrays";

export type FireInsuranceBrokerState = {
    brokerInfo: FireInsuranceBroker | undefined;
    brokerOptionList: FireInsuranceBrokerListItem[];
    selectedBroker: FireInsuranceBrokerListItem | undefined;
    editingBrokerRecord: boolean;
}

export const defaultFireInsuranceBrokerState: FireInsuranceBrokerState = {
    brokerInfo: undefined,
    brokerOptionList: [],
    selectedBroker: undefined,
    editingBrokerRecord: false
}

export function fireInsuranceBrokerReducer(state: FireInsuranceBrokerState, action: Record<string, any>): FireInsuranceBrokerState {
    switch (action.type) {
        case actions.SET_FIRE_INSURANCE_BROKERS_OPTION_LIST:
            return { ...state, brokerOptionList: action.payload };
        case actions.SET_SELECTED_FIRE_INSURANCE_BROKER:
            const selectedBroker = state.brokerOptionList.find((option) => option.isRecord && option.id === action.payload);
            return { ...state, selectedBroker: selectedBroker };
        case actions.SET_FIRE_INSURANCE_BROKER_INFO:
            return { ...state, brokerInfo: action.payload };
        case actions.REPLACE_FIRE_INSURANCE_BROKER_OPTION_WITH_RECORD:
            return { ...state, brokerOptionList: findAndReplace([...state.brokerOptionList], ["id", "isRecord"], [action.payload.oldBrokerId, action.payload.oldIsRecord], action.payload.newOption) };
        case actions.SET_EDITING_FIRE_INSURANCE_BROKER_RECORD:
            return { ...state, editingBrokerRecord: action.payload };
        case actions.ADD_TO_FIRE_INSURANCE_BROKER_OPTIONS:
            return { ...state, brokerOptionList: [...state.brokerOptionList, action.payload] };
        default:
            return state;
    }
}