import { styled, SxProps, Typography, TypographyProps } from '@mui/material';
import colors from '../componentStyling/Colors';
import { BODY_TEXT, BODY_BOLD_TEXT, H1_TEXT, H2_TEXT, H4_TEXT, H5_TEXT, LARGE_BOLD_TEXT, LARGE_TEXT, SMALL_BOLD_TEXT, SMALL_TEXT, H3_TEXT, MAIN_TITLE_TEXT, COPIABLE_TEXT } from '../componentStyling/Styles';
import { Component, ElementType, ReactElement, ReactNode } from 'react';
import Theme from '../componentStyling/Theme';


export const Title = styled(Typography)(({ theme }) => ({
    textAlign: "center"
}));


export const Paragraph = styled(Typography)(({ theme }) => ({
    maxWidth: "700px",
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px'
}));

// Typescale - largest to smallest
export const MainTitle = styled(Typography)({
    ...MAIN_TITLE_TEXT,
    color: colors.BLACK
})

export const H1 = styled(Typography)({
    ...H1_TEXT,
    color: colors.BLACK
});

export const H2 = styled(Typography)({
    ...H2_TEXT,
    color: colors.BLACK
});

export const H3 = styled(Typography)({
    ...H3_TEXT,
    color: colors.BLACK
});

export const H4 = styled(Typography)({
    ...H4_TEXT,
    color: colors.BLACK
});

export const H5 = styled(Typography)({
    ...H5_TEXT,
    color: colors.BLACK
});

export const Large = styled(Typography)({
    ...LARGE_TEXT,
    color: colors.BLACK
});

export const LargeBold = styled(Typography)({
    ...LARGE_BOLD_TEXT,
    color: colors.BLACK
});

export const Body = styled(Typography)({
    ...BODY_TEXT,
    color: colors.BLACK
});

export const BodyBold = styled(Typography)({
    ...BODY_BOLD_TEXT,
    color: colors.BLACK
});

export const Small = styled(Typography)({
    ...SMALL_TEXT,
    color: colors.BLACK
});

export const SmallBold = styled(Typography)({
    ...SMALL_BOLD_TEXT,
    color: colors.BLACK
});

export const INPUT_SMALL = styled(Small)({
    color: Theme.INPUT
})

export const STYLED_H4 = styled(H4)({
    paddingTop: "1.6rem"
})

export const STYLED_SMALL = styled(Small)({
    color: Theme.INPUT
})

interface TextPropsInterface {
    children?: ReactNode, 
    Component?: ReactNode, 
    copiable?: boolean,
    onClick?: () => any,
    sx?: SxProps,
}

export const Text = ({ Component, copiable = false, sx, children, onClick, ...props }: TextPropsInterface & TypographyProps) => {
    const styles = {
        ...(copiable ? COPIABLE_TEXT : {})
    };

    return <Component {...props} sx={{ ...sx, ...styles }} onClick={onClick}>
        {children}
    </Component>
}
