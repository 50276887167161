import { useContext } from 'react';

import { Stack, styled } from '@mui/material';

import SaveState from '../SaveState/SaveState';
import colors from '../../../../../components/Common/componentStyling/Colors';
import TabBarButton from './TabBarButton';
import { UniversalSurveyContext } from '../../../../context/UniversalSurvey/context';
import { Tab } from '../../../../libs/types/UniversalSurvey/Frontend/tab';
import { navigateURL } from '../../../../context/UniversalSurvey/asyncActions/deal';
import { TabRouteMapping } from '../../../../libs/resources/mappings/tabs';

type TabBarProps = {
    tabOptions: Tab[];
}

export default function TabBar(props: TabBarProps) {
    const [state, dispatch] = useContext(UniversalSurveyContext);

    return (
        <TabBarContainer>
            <TabsContainer direction="row" gap={2}>
                {props.tabOptions.map((option, i) => (
                    <TabBarButton
                        key={i}
                        label={{ text: option.title, inputId: option.title }}
                        active={option.title === state.deal.currTab ? true : false }
                        onClick={() => navigateURL(dispatch, String(state.deal.dealInfo?.id), TabRouteMapping[option.title], undefined, undefined)}
                        actionItemCount={option.actionItemCount}
                        icon={option.icon}
                    />
                ))}
                <SaveIndicatorContainer>
                    <SaveState />
                </SaveIndicatorContainer>
            </TabsContainer>
        </TabBarContainer>
    );
}

const TabBarContainer = styled('div')({
    height: "5.8rem",
    clear: "both",
    marginLeft: "-2.2rem",
    marginRight: "-7.5rem",
    padding: "5rem 3rem 3rem 3rem",
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 85.51%, ${colors.GRAY_100} 100%)`
});

const TabsContainer = styled(Stack)({
    height: "5.8rem",
    marginTop: "-2.8rem"
})

const SaveIndicatorContainer = styled('div')({
    marginLeft: "auto",
    display: "block",
    width: "13rem",
    marginRight: "0rem"
});