import { MortgageBrokerage } from "../../../types/UniversalSurvey/MortgageBrokerage/mortgageBrokerage";
import { VerificationStatus } from "../../enums/verificationStatus";
import { defaultFinancialInfo } from "./defaultFinancialInfo";
import { defaultSimpleAddress } from "./defaultSimpleAddress";

export const defaultMortgageBrokerage: MortgageBrokerage = {
    id: -1,
    mortgage_brokerage_id: undefined,
    verification_status: VerificationStatus.Unverified,
    name: undefined,
    abbr_name: undefined,
    all_regions: undefined,
    general_address: defaultSimpleAddress,
    financial_info: defaultFinancialInfo,
    phone: undefined,
    email: undefined,
    fax: undefined,
    date_created: undefined,
    date_updated: undefined,

    mortgage_brokerage: undefined,
    regions: []
}