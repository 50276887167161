import { Dispatch } from "react";

import * as actions from "../actionTypes"
import {
    deleteDealTitleInsurancePremium,
    newDealTitleInsurancePremium,
    saveDealTitleInsurancePremium
} from "../../../libs/service/axios/api";
import { sanitizeTitleInsurancePremiumRequest } from "../../../libs/types/UniversalSurvey/utils/convertRequest";
import { AlertTypes } from "../../../libs/resources/enums/alertTypes";
import { SimpleTitleInsurancePremium } from "../../../libs/types/UniversalSurvey/TitleInsurancePremium/simpleTitleInsurancePremium";
import { defaultSimpleTitleInsurancePremium } from "../../../libs/resources/defaults/frontend/defaultSimpleTitleInsurancePremium";

function createTitleInsurancePremium(dispatch: Dispatch<Record<string, any>>, dealId: string, discount?: boolean) {
    newDealTitleInsurancePremium(dealId)
        .then(function (response: any) {
            dispatch({ type: actions.CREATE_TITLE_INSURANCE_PREMIUM, payload: { ...defaultSimpleTitleInsurancePremium, id: response.data.id, discount } })
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Create title insurance premium: ${error}`, type: AlertTypes.Error } });
        })
}

function saveTitleInsurancePremium(dispatch: Dispatch<Record<string, any>>, dealId: string, premiumInfo: SimpleTitleInsurancePremium) {
    dispatch({ type: actions.SET_IS_SAVING, payload: true });
    saveDealTitleInsurancePremium(dealId, String(premiumInfo.id), sanitizeTitleInsurancePremiumRequest(premiumInfo))
        .then()
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Save title insurance premium: ${error}`, type: AlertTypes.Error } });
        })
        .finally(() => {
            dispatch({ type: actions.SET_IS_SAVING, payload: false });
        })
}

function deleteTitleInsurancePremium(dispatch: Dispatch<Record<string, any>>, dealId: string, premiumId: number) {
    deleteDealTitleInsurancePremium(dealId, String(premiumId))
        .then(function () {
            dispatch({ type: actions.DELETE_TITLE_INSURANCE_PREMIUM, payload: premiumId });
        })
        .catch(function (error: any) {
            dispatch({ type: actions.SET_ALERT_DATA, payload: { message: `Delete title insurance premium: ${error}`, type: AlertTypes.Error } });
        })
}

export {
    createTitleInsurancePremium,
    saveTitleInsurancePremium,
    deleteTitleInsurancePremium
}