import { useContext, useState, useEffect, useCallback, useRef, Fragment } from "react";
import { UniversalSurveyContext } from '../../../context/UniversalSurvey/context';
import * as actions from "../../../context/UniversalSurvey/actionTypes";
import { Grid } from "@mui/material";
import Dropdown from "../../../../components/Common/Dropdown/Dropdown";
import BasicTextInput from "../../../../components/Common/TextField/BasicTextInput";
import BooleanControl from "../../../../components/Common/BooleanControl/BooleanControl"
import BasicDatePicker from "../../../../components/Common/DatePicker/BasicDatePicker";
import DisplayRolePills from "../Components/TransactionPills/DisplayRolePills";
import PhoneField from "../../../../components/Common/PhoneField/PhoneField";
import BasicButton from "../../../../components/Common/Button/BasicButton";
import { Body, H5, STYLED_H4 } from "../../../../components/Common/Typography/index";
import { useDidUpdateEffect } from "../../../libs/hooks/useDidUpdateEffect";
import { usePrevious } from "../../../libs/hooks/usePrevious";
import { debounce } from "../../../libs/utils/debounce";
import { EntityTypeForRoles } from "../../../libs/resources/enums/roles";
import {
    saveClient,
    updateClientTabName,
    changeSelectedClient,
    getClientTabName,
    addSpousalRelationshipForClient,
    updateFamilyLawAct,
    updateSpouse,
    createNewSpouse,
    updateClientHeaderTitle,
    copyClientAddress,
    copyClientFinancialInfo,
    updateSpousalRelationship
} from "../../../context/UniversalSurvey/asyncActions/clients";
import { Client } from "../../../libs/types/UniversalSurvey/Client/client";
import { createRolesForEntity } from "../../../context/UniversalSurvey/asyncActions/roles/roles";
import CircularLoader from '../../../../components/Common/Loader/CircularLoader';
import {
    AllProvincesOptions,
    Countries,
    EmploymentStatusOptions,
    FamilyLawActOptions,
    IDTypeOptions,
    OneSpouseFamilyLawOptions,
    PoaEoeRelationshipActingOptions,
    PoaEoeRelationshipTypeOptions,
    StateOptions
} from "../../../libs/resources/options";
import ComboBox from "../../../../components/Common/ComboBox/ComboBox";
import { EmploymentStatus } from "../../../libs/resources/enums/employmentStatus";
import { formatEmploymentStatus, formatFamilyLawAct, formatPoaEoeActing, formatPoaEoeType } from "../../../libs/utils/formatValues";
import { NAICSList } from "../../../libs/resources/industryList";
import { FamilyLawAct } from "../../../libs/resources/enums/familyLawAct";
import { SimpleClient } from "../../../libs/types/UniversalSurvey/Client/simpleClient";
import BasicClientModal from "../Components/Modals/BasicClientModal";
import ClientRepresentativeModal from "../Components/Modals/ClientRepresentativeModal";
import { PoaEoeRelationshipActing, PoaEoeRelationshipType } from "../../../libs/resources/enums/poaEoeRelationships";
import {
    callSavePoaEoeRelationship,
    createNewRepresentee,
    createPoaEoeRelationship,
    removePoaEoeRelationship,
    updatePoaEoeRelationship,
    updatePoaEoeRelationshipRep,
    updatePoaEoeRelationshipTypes,
    removePoaEoeRole
} from "../../../context/UniversalSurvey/asyncActions/poaEoeRelationships";
import GenericDialog from "../../../../components/Common/Modal/GenericDialog";
import { SimplePoaEoeRelationship } from "../../../libs/types/UniversalSurvey/PoaEoeRelationship/SimplePoaEoeRelationship";
import { defaultSimplePoaEoeRelationship } from "../../../libs/resources/defaults/frontend/defaultSimplePoaEoeRelationship";
import { FLEX_GRID } from "../../../../components/Common/componentStyling/Styles";
import { ClientType } from "../../../libs/resources/enums/clientType";
import AddressGrid from "../Components/AddressGrid/AddressGrid";
import FinancialInfoGrid from "../Components/FinancialInfoGrid/FinancialInfoGrid";

export default function ClientSection() {
    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [clientObj, setClientObj] = useState<Client>(state.clients.clientInfo!);
    const [primaryJurisOptions, setPrimaryJurisOptions] = useState<String[]>([]);
    const [secondaryJurisOptions, setSecondaryJurisOptions] = useState<String[]>([]);
    const [spouseModalOpen, setSpouseModalOpen] = useState<boolean>(false);
    const [relationshipCreatingRepresenteeFor, setRelationshipCreatingRepresenteeFor] = useState<SimplePoaEoeRelationship | undefined>(undefined);
    const [relationshipCreatingRepresentativeFor, setRelationshipCreatingRepresentativeFor] = useState<SimplePoaEoeRelationship | undefined>(undefined);
    const [actingConfirmOpen, setActingConfirmOpen] = useState<boolean>(false);
    const [otherRepActingConfirmOpen, setOtherRepActingConfirmOpen] = useState<boolean>(false);
    const [typeChangeConfirmOpen, setTypeChangeConfirmOpen] = useState<boolean>(false);
    const [newPoaEoeType, setNewPoaEoeType] = useState<PoaEoeRelationshipType | undefined>(undefined);
    const [poaEoeRelationshipsToUpdate, setPoaEoeRelationshipsToUpdate] = useState<SimplePoaEoeRelationship[]>([]);
    const [reasonForPoa, setReasonForPoa] = useState<string>((state.clients.clientInfo?.poa_eoe_relationships && state.clients.clientInfo?.poa_eoe_relationships.length > 0) ? (state.clients.clientInfo.poa_eoe_relationships[0].reason_for_poa ?? "") : "");
    const [relationshipBeingUpdated, setRelationshipBeingUpdated] = useState<SimplePoaEoeRelationship | undefined>(undefined);
    const [repBeingSet, setRepBeingSet] = useState<SimpleClient | undefined>(undefined);
    const [addressCopiedFrom, setAddressCopiedFrom] = useState<(SimpleClient & { label: string }) | undefined>(undefined);
    const [financialInfoCopiedFrom, setFinancialInfoCopiedFrom] = useState<(SimpleClient & { label: string }) | undefined>(undefined);
    const [courtOrderInstrumentNum, setCourtOrderInstrumentNum] = useState<string | undefined>(state.clients.clientInfo?.spousal_relationship?.court_order_instrument_num);

    const clientInfoRef = useRef<Client | undefined>();
    clientInfoRef.current = state.clients.clientInfo;
    useEffect(() => {
        return () => {
            cleanUpEmptyPoaEoeRelationships();
            dispatch({ type: actions.SET_CLIENT_INFO, payload: undefined });
        }
    }, [])

    useEffect(() => {
        if (state.clients.clientInfo) {
            cleanUpEmptyPoaEoeRelationships();
            setClientObj(state.clients.clientInfo);
            createRolesForEntity(dispatch, state.clients.clientInfo.roles!, state.transactions.transactionList);
            if (state.clients.clientsBeingSaved.includes(state.clients.clientInfo.id)) {
                dispatch({ type: actions.SET_IS_CLIENT_SAVING, payload: true });
            }
        }
    }, [state.clients.clientInfo?.id])

    useEffect(() => {
        if (state.clients.shouldRefreshClient) {
            changeSelectedClient(dispatch, String(state.deal.dealInfo?.id), state.clients.clientInfo?.id!)
            dispatch({ type: actions.SET_SHOULD_REFRESH_CLIENT, payload: false });
        }
    }, [state.clients.shouldRefreshClient])

    useEffect(() => {
        if (clientObj.primary_issuing_country === "Canada") {
            setPrimaryJurisOptions(AllProvincesOptions);
            return;
        }
        if (clientObj.primary_issuing_country === "United States of America") {
            setPrimaryJurisOptions(StateOptions);
            return;
        }
        setPrimaryJurisOptions([]);
    }, [clientObj.primary_issuing_country])

    useEffect(() => {
        if (clientObj.secondary_issuing_country === "Canada") {
            setSecondaryJurisOptions(AllProvincesOptions);
            return;
        }
        if (clientObj.secondary_issuing_country === "United States of America") {
            setSecondaryJurisOptions(StateOptions);
            return;
        }
        setSecondaryJurisOptions([]);
    }, [clientObj.secondary_issuing_country])

    useEffect(() => {
        if (state.clients.addressToCopy) {
            updateClientInfo("address_for_service", { ...state.clients.addressToCopy });
            dispatch({ type: actions.SET_CLIENT_ADDRESS_TO_COPY, payload: undefined });
        }
    }, [state.clients.addressToCopy])

    useEffect(() => {
        if (state.clients.financialInfoToCopy) {
            updateClientInfo("financial_info", { ...state.clients.financialInfoToCopy });
            dispatch({ type: actions.SET_CLIENT_FINANCIAL_INFO_TO_COPY, payload: undefined });
        }
    }, [state.clients.financialInfoToCopy])

    function updateClientInfo<
        K extends keyof Client,
        V extends Client[K]
    >(key: K, value: V): void {
        const tempClientObj = { ...clientObj }
        tempClientObj[key] = value;
        setClientObj(tempClientObj)
        updateClientTabName(dispatch, tempClientObj);
        updateClientHeaderTitle(dispatch, tempClientObj, state.roles.roles, state.deal.dealInfo)
    }

    const debouncedSave = useCallback(
        debounce((clientObj) => {
            saveClient(dispatch, String(state.deal.dealInfo?.id), clientObj);
            dispatch({ type: actions.REMOVE_CLIENT_BEING_SAVED, payload: clientObj.id });
        }, 1000), []);

    const prevClient = usePrevious(clientObj);
    useDidUpdateEffect(() => {
        if (prevClient?.id === clientObj.id) {
            dispatch({ type: actions.ADD_CLIENT_BEING_SAVED, payload: clientObj.id });
            debouncedSave(clientObj);
        }
    }, [clientObj]);

    const debouncedReasonForPoaSave = useCallback(
        debounce((reason) => {
            if (clientInfoRef.current?.poa_eoe_relationships) {
                for (const relationship of clientInfoRef.current.poa_eoe_relationships) {
                    updatePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), relationship, "reason_for_poa", reason);
                }
            }
        }, 1000), []);

    useDidUpdateEffect(() => {
        debouncedReasonForPoaSave(reasonForPoa)
    }, [reasonForPoa])

    const debouncedCourtOrderInstrumentNumSave = useCallback(
        debounce((num) => {
            if (clientInfoRef.current?.spousal_relationship) {
                dispatch({ type: actions.SET_IS_SAVING, payload: true });
                updateSpousalRelationship(dispatch, String(state.deal.dealInfo?.id), { ...clientInfoRef.current?.spousal_relationship, court_order_instrument_num: num });
            }
        }, 1000), []);

    useDidUpdateEffect(() => {
        debouncedCourtOrderInstrumentNumSave(courtOrderInstrumentNum)
    }, [courtOrderInstrumentNum])

    function updateCountry(option: "primary_issuing_country" | "secondary_issuing_country", value: string | undefined) {
        let tempClientObj = { ...clientObj };
        tempClientObj[option] = value;
        tempClientObj[option === "primary_issuing_country" ? "primary_issuing_jurisdiction" : "secondary_issuing_jurisdiction"] = undefined;
        setClientObj(tempClientObj);
    }

    function getSpouseValue() {
        let selectedClient = undefined;
        if (state.clients.clientInfo?.spousal_relationship?.spouse_one?.id === clientObj.id) {
            selectedClient = state.clients.clientInfo.spousal_relationship.spouse_two;
        } else if (state.clients.clientInfo?.spousal_relationship?.spouse_two?.id === clientObj.id) {
            selectedClient = state.clients.clientInfo.spousal_relationship.spouse_one;
        }

        if (selectedClient) {
            return { ...selectedClient, label: getClientTabName(selectedClient) };
        }
        return selectedClient;
    }

    function isSpouseHoverActionHidden() {
        if ((state.clients.clientInfo?.spousal_relationship?.spouse_one?.id === clientObj.id &&
            state.clients.clientInfo.spousal_relationship.spouse_two) ||
            (state.clients.clientInfo?.spousal_relationship?.spouse_two?.id === clientObj.id &&
                state.clients.clientInfo.spousal_relationship.spouse_one)) {
            return false;
        }
        return true;
    }

    function isSpouseOptionDisabled(option: SimpleClient & { label: string }): { isDisabled: boolean, message: string } {
        if (!option.spousal_relationship?.spouse_one || !option.spousal_relationship?.spouse_two) {
            return { isDisabled: false, message: "" };
        } else if (option.spousal_relationship?.id && option.spousal_relationship?.id === state.clients.clientInfo?.spousal_relationship?.id) {
            return { isDisabled: false, message: "" };
        }
        return { isDisabled: true, message: `${option.label} has already been selected as a spouse` };
    }

    function getPoaEoeRepValue(i: number) {
        let selectedClient = undefined;

        if (state.clients.clientInfo?.poa_eoe_relationships[i].representative?.id === clientObj.id) {
            selectedClient = state.clients.clientInfo.poa_eoe_relationships[i].representee;
        } else if (state.clients.clientInfo?.poa_eoe_relationships[i].representee?.id === clientObj.id) {
            selectedClient = state.clients.clientInfo.poa_eoe_relationships[i].representative;
        }

        if (selectedClient) {
            return { ...selectedClient, label: getClientTabName(selectedClient) }
        }
        return selectedClient;
    }

    function isPoaEoeOptionDisabled(option: SimpleClient & { label: string }, value: SimpleClient & { label: string } | undefined): { isDisabled: boolean, message: string } {
        if (option.id === value?.id) {
            return { isDisabled: false, message: "" };
        }
        if (option.poa_eoe_relationships?.length) {
            if (state.clients.clientInfo?.poa_eoe_relationships[0].representative?.id === clientObj.id && option.poa_eoe_relationships[0].representative?.id === option.id) {
                return { isDisabled: true, message: `${option.label} is a representative in another relationship, they can't be a representee` };
            }
            if (state.clients.clientInfo?.poa_eoe_relationships[0].representee?.id === clientObj.id && option.poa_eoe_relationships[0].representee?.id === option.id) {
                return { isDisabled: true, message: `${option.label} is a representee in another relationship, they can't act as a representative` };
            }
        }
        if (state.clients.clientInfo?.poa_eoe_relationships) {
            for (const relationship of state.clients.clientInfo?.poa_eoe_relationships) {
                if (relationship.representative?.id === clientObj.id) {
                    if (option.id === relationship.representee?.id) {
                        return { isDisabled: true, message: `${option.label} is already a selected representative` };
                    }
                } else {
                    if (option.id === relationship.representative?.id) {
                        return { isDisabled: true, message: `${option.label} is already a selected representative` };
                    }
                }
            }
        }

        return { isDisabled: false, message: "" };
    }

    function handlePoaEoeActingChange(acting: PoaEoeRelationshipActing) {
        if (state.clients.clientInfo?.poa_eoe_relationships && acting === state.clients.clientInfo?.poa_eoe_relationships[0].acting) {
            // if they select the current option, do nothing
            return;
        }
        // multiple relationships for the client
        if (state.clients.clientInfo?.poa_eoe_relationships.length && state.clients.clientInfo?.poa_eoe_relationships.length > 1) {
            if (acting === PoaEoeRelationshipActing.Solely) {
                setActingConfirmOpen(true);
            } else {
                // update all relationships
                for (const relationship of state.clients.clientInfo?.poa_eoe_relationships) {
                    updatePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), relationship, "acting", acting)
                }
            }
            // only 1 relationship for the client
        } else if (state.clients.clientInfo?.poa_eoe_relationships.length === 1) {
            updatePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), state.clients.clientInfo?.poa_eoe_relationships[0], "acting", acting);
            if ((state.clients.clientInfo?.poa_eoe_relationships[0].acting === PoaEoeRelationshipActing.Solely || state.clients.clientInfo?.poa_eoe_relationships[0].acting === undefined) && acting !== PoaEoeRelationshipActing.Solely) {
                createPoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), null, String(clientObj.id), state.clients.clientInfo?.poa_eoe_relationships[0].type)
            }
        }
    }

    function confirmDeleteOtherRepresentatives() {
        if (state.clients.clientInfo?.poa_eoe_relationships) {
            for (let i = 1; i < state.clients.clientInfo?.poa_eoe_relationships.length; i++) {
                if (state.clients.clientInfo?.poa_eoe_relationships[i].representative) {
                    const rep = state.clients.clientsInDeal.find((client) => client.id === state.clients.clientInfo?.poa_eoe_relationships[i].representative!.id)
                    if (rep) {
                        removePoaEoeRole(dispatch, rep, state.clients.clientInfo?.poa_eoe_relationships[i].id);
                    }
                }
                removePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), state.clients.clientInfo?.poa_eoe_relationships[i].id);
            }
            updatePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), state.clients.clientInfo?.poa_eoe_relationships[0], "acting", PoaEoeRelationshipActing.Solely);
        }
        setActingConfirmOpen(false);
    }

    function cleanUpEmptyPoaEoeRelationships() {
        if (clientInfoRef.current?.poa_eoe_relationships) {
            for (const relationship of clientInfoRef.current.poa_eoe_relationships) {
                if (!relationship.representative || !relationship.representee) {
                    if (relationship.representative) {
                        removePoaEoeRole(dispatch, relationship.representative, relationship.id);
                    }
                    removePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), relationship.id);
                }
            }
        }
    }

    function handlePoaEoeRepUpdate(relationship: SimplePoaEoeRelationship, newRep: SimpleClient | undefined) {
        const repBeingUpdated = relationship.representative?.id === clientObj.id ? "representee" : "representative";
        const relationshipCopy = { ...relationship };

        // if adding a client as a representee, and that client already has a representative that is acting "solely" - need to update acting with a warning
        if (repBeingUpdated === "representee") {
            const rep = state.clients.clientsInDeal.find((client) => client.id === newRep?.id);
            if (rep && rep.poa_eoe_relationships?.length) {
                relationshipCopy.reason_for_poa = rep.poa_eoe_relationships[0].reason_for_poa;
                relationshipCopy.type = rep.poa_eoe_relationships[0].type;
                if ((rep.poa_eoe_relationships[0].acting === PoaEoeRelationshipActing.Solely || rep.poa_eoe_relationships[0].acting === undefined)) {
                    setRepBeingSet(newRep);
                    setRelationshipBeingUpdated(relationshipCopy);
                    setOtherRepActingConfirmOpen(true);
                    return;
                }
            } else {
                relationshipCopy.reason_for_poa = undefined;
            }
        }
        updatePoaEoeRelationshipRep(dispatch, String(state.deal.dealInfo?.id), relationshipCopy, repBeingUpdated, newRep);
    }

    function confirmHandlePoaEoeRepUpdate() {
        if(relationshipBeingUpdated) {
            // update this relationship
            callSavePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), { ...relationshipBeingUpdated, representee: repBeingSet, acting: PoaEoeRelationshipActing.Jointly });
        }
        // update other relationship for representee
        const otherClient = state.clients.clientsInDeal.find((client) => client.id === repBeingSet?.id);
        if (otherClient && otherClient.poa_eoe_relationships && otherClient.poa_eoe_relationships[0]) {
            callSavePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), { ...otherClient.poa_eoe_relationships[0], acting: PoaEoeRelationshipActing.Jointly });
        }
        setOtherRepActingConfirmOpen(false);
    }

    function handlePoaEoeTypeChange(newType: PoaEoeRelationshipType, relationship: SimplePoaEoeRelationship) {
        if (relationship.representee?.id === clientObj.id) {
            // if current client is a representee - change all relationships for this client
            const relationshipsToUpdate = state.clients.clientInfo?.poa_eoe_relationships;
            if (relationshipsToUpdate) {
                updatePoaEoeRelationshipTypes(dispatch, String(state.deal.dealInfo?.id), relationshipsToUpdate, newType);
            }
        } else if (relationship.representative?.id === clientObj.id) {
            // if current client is a representative - change all relationships for the representee
            const representee = state.clients.clientsInDeal.find((client) => client.id === relationship.representee?.id);
            if (representee) {
                const relationshipsToUpdate = representee.poa_eoe_relationships;
                if (relationshipsToUpdate) {
                    setPoaEoeRelationshipsToUpdate(relationshipsToUpdate);
                    setNewPoaEoeType(newType);
                    if (relationshipsToUpdate.length > 1 && relationshipsToUpdate[0].type && relationshipsToUpdate[0].type !== newType) {
                        setTypeChangeConfirmOpen(true);
                    } else {
                        updatePoaEoeRelationshipTypes(dispatch, String(state.deal.dealInfo?.id), relationshipsToUpdate, newType);
                    }
                }
            }
        }
    }

    function isThereRelationshipWithoutRep(repType: "representee" | "representative") {
        if (state.clients.clientInfo?.poa_eoe_relationships) {
            for (const relationship of state.clients.clientInfo?.poa_eoe_relationships) {
                if (!relationship[repType]) return true;
            }
        }
        return false;
    }

    function getClientComboboxItems(canBeCorp?: boolean): (SimpleClient & { label: string })[] {
        const options: (SimpleClient & { label: string })[] = [];
        for(const client of state.clients.clientsInDeal) {
            if (client.type === ClientType.Corporation && !canBeCorp) continue;
            if (client.id === clientObj.id) continue;
            const label = getClientTabName(client);
            options.push({ ...client, label: label });
        }
        return options;
    }

    function handleCopyAddressFromClient(client: SimpleClient) {
        setAddressCopiedFrom({ ...client, label: getClientTabName(client) });
        copyClientAddress(dispatch, String(state.deal.dealInfo?.id), client.id);
    }

    function handleCopyFinancialInfoFromClient(client: SimpleClient) {
        setFinancialInfoCopiedFrom({ ...client, label: getClientTabName(client) });
        copyClientFinancialInfo(dispatch, String(state.deal.dealInfo?.id), client.id);
    }

    return (
        state.clients.isClientSaving ? <CircularLoader containerHeight="70vh" /> :
            <div>
                <BasicClientModal
                    open={spouseModalOpen}
                    onClose={() => setSpouseModalOpen(false)}
                    currClientId={clientObj.id}
                    submitFn={createNewSpouse}
                    relationship={state.clients.clientInfo?.spousal_relationship!}
                />
                <BasicClientModal
                    open={relationshipCreatingRepresenteeFor !== undefined}
                    onClose={() => setRelationshipCreatingRepresenteeFor(undefined)}
                    currClientId={clientObj.id}
                    submitFn={createNewRepresentee}
                    relationship={relationshipCreatingRepresenteeFor}
                    title="New Representee"
                />
                <ClientRepresentativeModal
                    open={relationshipCreatingRepresentativeFor !== undefined}
                    onClose={() => setRelationshipCreatingRepresentativeFor(undefined)}
                    currClientId={clientObj.id}
                    relationship={relationshipCreatingRepresentativeFor ?? defaultSimplePoaEoeRelationship}
                />
                <GenericDialog
                    action="destructive"
                    title="Confirm Change"
                    onCancel={() => setActingConfirmOpen(false)}
                    onSubmit={() => confirmDeleteOtherRepresentatives()}
                    submitText="Change"
                    open={actingConfirmOpen}
                    contentText={`Are you sure you want to change this representative to acting solely? This will remove the other relationships for ${getClientTabName(clientObj)}.`}
                />
                <GenericDialog
                    action="neutral"
                    title="Confirm Change"
                    onCancel={() => setOtherRepActingConfirmOpen(false)}
                    onSubmit={() => confirmHandlePoaEoeRepUpdate()}
                    submitText="Change"
                    open={otherRepActingConfirmOpen}
                    contentText="This client already has a representative, are you sure you want to add this client as a representee? This will change the acting type to jointly."
                />
                <GenericDialog
                    action="neutral"
                    title="Confirm Change"
                    onCancel={() => setTypeChangeConfirmOpen(false)}
                    onSubmit={() => {
                        updatePoaEoeRelationshipTypes(dispatch, String(state.deal.dealInfo?.id), poaEoeRelationshipsToUpdate, newPoaEoeType!);
                        setTypeChangeConfirmOpen(false);
                    }}
                    submitText="Change"
                    open={typeChangeConfirmOpen}
                    contentText={`Are you sure you want to change this Representative Type? This will change the Representative Type for all ${newPoaEoeType === PoaEoeRelationshipType.EOE ? "Power of Attorneys" : "Executors of Estate"} for ${getClientTabName(poaEoeRelationshipsToUpdate.length ? poaEoeRelationshipsToUpdate[0].representee : undefined)}.`}
                />
                <DisplayRolePills
                    addRolePill
                    entityId={clientObj.id}
                    entityLabel={getClientTabName(clientObj)}
                    entityType={EntityTypeForRoles.Client}
                />
                {clientObj.type === ClientType.Corporation ? (
                    <div>
                        <Grid container rowSpacing={3} columnSpacing={5}>
                            <Grid item xs={12}>
                                <BooleanControl
                                    checked={true}
                                    labels={["Person", "Corporation"]}
                                    onChange={(e) => updateClientInfo("type", ClientType.Person)}
                                    label={{ text: "Client Type", inputId: "type" }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.corporation_name}
                                    onChange={(e) => updateClientInfo("corporation_name", e.target.value)}
                                    label={{ text: "Corporation Name", inputId: "corporation_name" }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.title}
                                    onChange={(e) => updateClientInfo("title", e.target.value)}
                                    label={{ text: "Signer's Title", inputId: "signer_title" }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.first_name}
                                    onChange={(e) => updateClientInfo("first_name", e.target.value)}
                                    label={{ text: "Signer's First Name", inputId: "first_name" }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.middle_name}
                                    onChange={(e) => updateClientInfo("middle_name", e.target.value)}
                                    label={{ text: "Signer's Middle Name", inputId: "middle_name" }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.last_name}
                                    onChange={(e) => updateClientInfo("last_name", e.target.value)}
                                    label={{ text: "Signer's Last Name", inputId: "last_name" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <STYLED_H4>Contact Details</STYLED_H4>
                            </Grid>
                            <Grid item xs={4}>
                                <BasicTextInput
                                    fullWidth
                                    value={clientObj.email}
                                    onChange={(e) => updateClientInfo("email", e.target.value)}
                                    label={{ text: "Email", inputId: "email" }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <PhoneField
                                    value={clientObj.phone_work}
                                    onChange={(value) => updateClientInfo("phone_work", value)}
                                    label={{ text: "Phone", inputId: "phone_work" }}
                                    error={{
                                        showError: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <PhoneField
                                    value={clientObj.fax}
                                    onChange={(value) => updateClientInfo("fax", value)}
                                    label={{ text: "Fax", inputId: "fax" }}
                                    error={{
                                        showError: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AddressGrid
                                    address={clientObj.address_for_service}
                                    setAddress={(value) => updateClientInfo("address_for_service", value)}
                                    id="corporation_address_for_service"
                                    title="Mailing Service"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FinancialInfoGrid
                                    financialInfo={clientObj.financial_info}
                                    setFinancialInfo={(value) => updateClientInfo("financial_info", value)}
                                    id="financial_info"
                                    title="Financial Information"
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}>
                            <BooleanControl
                                checked={false}
                                labels={["Person", "Corporation"]}
                                onChange={(e) => updateClientInfo("type", ClientType.Corporation)}
                                label={{ text: "Client Type", inputId: "type" }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.first_name}
                                onChange={(e) => updateClientInfo("first_name", e.target.value)}
                                label={{ text: "First Name", inputId: "first_name" }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.middle_name}
                                onChange={(e) => updateClientInfo("middle_name", e.target.value)}
                                label={{ text: "Middle Name", inputId: "middle_name" }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.last_name}
                                onChange={(e) => updateClientInfo("last_name", e.target.value)}
                                label={{ text: "Last Name", inputId: "last_name" }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <BasicDatePicker
                                value={clientObj.birthdate ?? null}
                                onChange={(e) => updateClientInfo("birthdate", e as Date)}
                                label={{ text: "Date of Birth" }}
                                maxDate={new Date()}
                            />
                        </Grid>
                        {(state.clients.clientInfo?.poa_eoe_relationships && state.clients.clientInfo?.poa_eoe_relationships.length > 0 && state.clients.clientInfo?.poa_eoe_relationships[0].representative?.id === clientObj.id) ? (
                            <Grid item xs={9} />
                        ) : (
                            <>
                                <Grid item xs={3}>
                                    <BooleanControl
                                        checked={clientObj.is_canadian_resident}
                                        onChange={(e) => updateClientInfo("is_canadian_resident", !clientObj.is_canadian_resident)}
                                        label={{ text: "Canadian Resident", inputId: "is_canadian_resident" }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Dropdown
                                        value={clientObj.residential_status ?? undefined}
                                        onChange={(e) => updateClientInfo("residential_status", e.target.value as string)}
                                        placeholder="Select an option..."
                                        options={["Permanent Resident", "Canadian Citizen"]} // add other options from list of all possible entries, or have options passed in as props
                                        label={{ text: "Resident Status" }}
                                        isHoverActionHidden={clientObj.residential_status ? false : true}
                                        handleClear={() => updateClientInfo("residential_status", undefined)}
                                    />
                                </Grid>
                            </>
                        )}

                        {state.clients.clientInfo?.spousal_relationship &&
                            <>
                                <Grid item xs={8}>
                                    <Dropdown
                                        value={
                                            (state.clients.clientInfo.spousal_relationship.family_law_act === FamilyLawAct.Consent &&
                                                state.clients.clientInfo.spousal_relationship.spouse_two?.id === clientObj.id) ?
                                                FamilyLawAct.Consent2 :
                                                state.clients.clientInfo.spousal_relationship.family_law_act
                                        }
                                        onChange={(e) => updateFamilyLawAct(dispatch, String(state.deal.dealInfo?.id), e.target.value as FamilyLawAct, clientObj.id, state.clients.clientInfo?.spousal_relationship!)}
                                        placeholder="Select option..."
                                        options={FamilyLawActOptions}
                                        label={{ text: "Family Law Act" }}
                                        isHoverActionHidden={state.clients.clientInfo?.spousal_relationship.family_law_act ? false : true}
                                        handleClear={() => updateFamilyLawAct(dispatch, String(state.deal.dealInfo?.id), undefined, clientObj.id, state.clients.clientInfo?.spousal_relationship!)}
                                        formatValue={formatFamilyLawAct}
                                    />
                                </Grid>
                                {state.clients.clientInfo?.spousal_relationship?.family_law_act && !OneSpouseFamilyLawOptions.includes(state.clients.clientInfo?.spousal_relationship?.family_law_act) &&
                                    <Grid item xs={4}>
                                        <ComboBox
                                            value={getSpouseValue()}
                                            onChangeFn={(value) => updateSpouse(dispatch, String(state.deal.dealInfo?.id), clientObj.id, value as SimpleClient, state.clients.clientInfo?.spousal_relationship!)}
                                            options={getClientComboboxItems()}
                                            placeholder="Select spouse..."
                                            label={{ text: "Spouse", inputId: "spouse" }}
                                            handleClear={() => updateSpouse(dispatch, String(state.deal.dealInfo?.id), clientObj.id, undefined, state.clients.clientInfo?.spousal_relationship!)}
                                            isHoverActionHidden={isSpouseHoverActionHidden()}
                                            isOptionDisabled={isSpouseOptionDisabled}
                                            isOptionEqualFn={(option, value) => option.id === value.id}
                                            createEntity="client"
                                            setCreationModalOpen={() => setSpouseModalOpen(true)}
                                        />
                                    </Grid>
                                }
                                {state.clients.clientInfo?.spousal_relationship?.family_law_act === FamilyLawAct.CourtOrder &&
                                    <Grid item xs={4}>
                                        <BasicTextInput
                                            fullWidth
                                            value={courtOrderInstrumentNum}
                                            onChange={(e) => setCourtOrderInstrumentNum(e.target.value)}
                                            label={{ text: "Court Order Instrument Number", inputId: "court_order_instrument_num" }}
                                            inputProps={{ "aria-label": "Court Order Instrument Number" }}
                                        />
                                    </Grid>
                                }
                            </>
                        }
                        <FLEX_GRID item xs={12}>
                            {!state.clients.clientInfo?.spousal_relationship &&
                                <BasicButton
                                    label={{ text: "Add Spouse", inputId: "add-spouse" }}
                                    action="add"
                                    typeOf="secondary"
                                    onClick={() => addSpousalRelationshipForClient(dispatch, String(state.deal.dealInfo?.id), clientObj)}
                                />
                            }
                            {state.clients.clientInfo?.poa_eoe_relationships.length === 0 && (
                                <>
                                    <BasicButton
                                        label={{ text: "Add POA/EOE", inputId: "add-poa-eoe" }}
                                        action="add"
                                        typeOf="secondary"
                                        onClick={() => createPoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), null, String(clientObj.id))}
                                    />
                                    <BasicButton
                                        label={{ text: "Make POA/EOE", inputId: "make-poa-eoe" }}
                                        action="add"
                                        typeOf="secondary"
                                        onClick={() => createPoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), String(clientObj.id), null)}
                                    />
                                </>
                            )}
                        </FLEX_GRID>
                        {state.clients.clientInfo?.poa_eoe_relationships && state.clients.clientInfo?.poa_eoe_relationships.length > 0 &&
                            <>
                                {state.clients.clientInfo?.poa_eoe_relationships.map((relationship, i) => (
                                    <Fragment key={`poa-eoe-${i}`}>
                                        <Grid item xs={4}>
                                            <ComboBox
                                                value={getPoaEoeRepValue(i) ?? {label: ""}}
                                                onChangeFn={(value) => handlePoaEoeRepUpdate(relationship, value as SimpleClient)}
                                                options={getClientComboboxItems(true)}
                                                placeholder={`Select ${relationship.representative?.id === clientObj.id ? "Representee" : "Representative"}...`}
                                                label={{ text: relationship.representative?.id === clientObj.id ? "Representee" : "Representative", inputId: `poa-eoe-rep-${i}` }}
                                                handleClear={() => handlePoaEoeRepUpdate(relationship, undefined)}
                                                isHoverActionHidden={((state.clients.clientInfo?.poa_eoe_relationships[i].representative?.id === clientObj.id && state.clients.clientInfo?.poa_eoe_relationships[i].representee) ||
                                                    (state.clients.clientInfo?.poa_eoe_relationships[i].representee?.id === clientObj.id && state.clients.clientInfo?.poa_eoe_relationships[i].representative)) ? false : true}
                                                isOptionDisabled={isPoaEoeOptionDisabled}
                                                isOptionEqualFn={(option, value) => option.id && option.id === value.id}
                                                createEntity="client"
                                                setCreationModalOpen={() => {
                                                    if (state.clients.clientInfo?.poa_eoe_relationships[i].representative?.id === clientObj.id) {
                                                        setRelationshipCreatingRepresenteeFor(relationship);
                                                    } else {
                                                        setRelationshipCreatingRepresentativeFor(relationship);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {relationship.representee?.id === clientObj.id ? (
                                            <>
                                                {i === 0 ? (
                                                    <>
                                                        <Grid item xs={3}>
                                                            <Dropdown
                                                                value={state.clients.clientInfo?.poa_eoe_relationships[0].type ?? PoaEoeRelationshipType.dummy}
                                                                onChange={(e) => handlePoaEoeTypeChange(e.target.value as PoaEoeRelationshipType, relationship)}
                                                                placeholder="Select type..."
                                                                options={PoaEoeRelationshipTypeOptions}
                                                                label={{ text: "Representative Type" }}
                                                                formatValue={formatPoaEoeType}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Dropdown
                                                                value={state.clients.clientInfo?.poa_eoe_relationships[i].acting}
                                                                onChange={(e) => handlePoaEoeActingChange(e.target.value as PoaEoeRelationshipActing)}
                                                                placeholder="Select capacity..."
                                                                options={PoaEoeRelationshipActingOptions}
                                                                label={{ text: "Acting..." }}
                                                                formatValue={formatPoaEoeActing}
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <Grid item xs={8}>
                                                        <BooleanControl
                                                            checked={relationship.signing}
                                                            onChange={(e) => updatePoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), relationship, "signing", !state.clients.clientInfo?.poa_eoe_relationships[i].signing)}
                                                            label={{ text: "Signing", inputId: `poa-eoe-signing-${i}` }}
                                                        />
                                                    </Grid>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={3}>
                                                    <Dropdown
                                                        value={relationship.type ?? PoaEoeRelationshipType.dummy}
                                                        onChange={(e) => handlePoaEoeTypeChange(e.target.value as PoaEoeRelationshipType, relationship)}
                                                        placeholder="Select type..."
                                                        options={PoaEoeRelationshipTypeOptions}
                                                        label={{ text: "Representative Type" }}
                                                        formatValue={formatPoaEoeType}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} />
                                            </>
                                        )}
                                    </Fragment>
                                ))}
                                {state.clients.clientInfo?.poa_eoe_relationships[0].type === PoaEoeRelationshipType.POA &&
                                    state.clients.clientInfo?.poa_eoe_relationships[0].representee?.id === clientObj.id &&
                                    <Grid item xs={8}>
                                        <BasicTextInput
                                            fullWidth
                                            value={reasonForPoa}
                                            onChange={(e) => setReasonForPoa(e.target.value)}
                                            multiline
                                            label={{ text: "Reason for POA", inputId: "reason_for_poa" }}
                                        />
                                    </Grid>
                                }
                                {state.clients.clientInfo?.poa_eoe_relationships[0].representee?.id === clientObj.id && state.clients.clientInfo?.poa_eoe_relationships[0].acting && state.clients.clientInfo?.poa_eoe_relationships[0].acting !== PoaEoeRelationshipActing.Solely &&
                                    <Grid item xs={12}>
                                        <BasicButton
                                            label={{ text: `Add ${state.clients.clientInfo?.poa_eoe_relationships[0].type === PoaEoeRelationshipType.POA ? "POA" : state.clients.clientInfo?.poa_eoe_relationships[0].type === PoaEoeRelationshipType.EOE ? "EOE" : "POA/EOE"}`, inputId: "add-poa-eoe" }}
                                            disabled={!state.clients.clientInfo?.poa_eoe_relationships[0].type || isThereRelationshipWithoutRep("representative")}
                                            action="add"
                                            typeOf="secondary"
                                            onClick={() => createPoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), null, String(clientObj.id), state.clients.clientInfo?.poa_eoe_relationships[0].type)}
                                        />
                                    </Grid>
                                }
                                {state.clients.clientInfo?.poa_eoe_relationships[0].representative?.id === clientObj.id &&
                                    <Grid item xs={12}>
                                        <BasicButton
                                            label={{ text: "Add Representee", inputId: "add-representee" }}
                                            disabled={isThereRelationshipWithoutRep("representee")}
                                            action="add"
                                            typeOf="secondary"
                                            onClick={() => createPoaEoeRelationship(dispatch, String(state.deal.dealInfo?.id), String(clientObj.id), null)}
                                        />
                                    </Grid>
                                }
                            </>
                        }

                        <Grid item xs={12}>
                            <STYLED_H4>Contact Details</STYLED_H4>
                        </Grid>

                        <Grid item xs={3}>
                            <PhoneField
                                value={clientObj.phone_home}
                                onChange={(value) => updateClientInfo("phone_home", value)}
                                label={{ text: "Home Phone", inputId: "phone_home" }}
                                error={{
                                    showError: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <PhoneField
                                value={clientObj.phone_work}
                                onChange={(value) => updateClientInfo("phone_work", value)}
                                label={{ text: "Work Phone", inputId: "phone_work" }}
                                error={{
                                    showError: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <PhoneField
                                value={clientObj.phone_cell}
                                onChange={(value) => updateClientInfo("phone_cell", value)}
                                label={{ text: "Cell Phone", inputId: "phone_cell" }}
                                error={{
                                    showError: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <PhoneField
                                value={clientObj.fax}
                                onChange={(value) => updateClientInfo("fax", value)}
                                label={{ text: "Fax", inputId: "fax" }}
                                error={{
                                    showError: true
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.email}
                                onChange={(e) => updateClientInfo("email", e.target.value)}
                                label={{ text: "Email", inputId: "email" }}
                                fieldType="email"
                                error={{
                                    showError: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BooleanControl
                                checked={clientObj.reside_at_subject}
                                onChange={(e) => updateClientInfo("reside_at_subject", !clientObj.reside_at_subject)}
                                label={{ text: "Resides at Subject Property", inputId: "reside_at_subject" }}
                            />
                        </Grid>
                        {!clientObj.reside_at_subject &&
                            <>
                                <Grid item xs={12}>
                                    <Body>Mailing Address</Body>
                                </Grid>
                                <Grid item xs={4}>
                                    <ComboBox
                                        value={addressCopiedFrom ?? {label: ""}}
                                        onChangeFn={(value) => handleCopyAddressFromClient(value as SimpleClient)}
                                        options={getClientComboboxItems()}
                                        placeholder="Select client..."
                                        label={{ text: "Copy Address", inputId: `copy-address` }}
                                        tooltipProps={{
                                            message: "Select a client to copy their address to the fields below. This does not link the addresses, so if any changes are made, they will need to be copied again.",
                                            type: "info",
                                            placement: "right"
                                        }}
                                        isOptionEqualFn={(option, value) => option.id && option.id === value.id}
                                    />
                                </Grid>
                                <Grid item xs={8}/>
                                <Grid item xs={12}>
                                    <AddressGrid
                                        address={clientObj.address_for_service}
                                        setAddress={(value) => updateClientInfo("address_for_service", value)}
                                        id="person_address_for_service"
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Body>Financial Information</Body>
                        </Grid>
                        <Grid item xs={4}>
                            <ComboBox
                                value={financialInfoCopiedFrom ?? {label: ""}}
                                onChangeFn={(value) => handleCopyFinancialInfoFromClient(value as SimpleClient)}
                                options={getClientComboboxItems()}
                                placeholder="Select client..."
                                label={{ text: "Copy Financial Information", inputId: `copy-financial-info` }}
                                tooltipProps={{
                                    message: "Select a client to copy their financial information to the fields below. This does not link the data, so if any changes are made, they will need to be copied again.",
                                    type: "info",
                                    placement: "right"
                                }}
                                isOptionEqualFn={(option, value) => option.id && option.id === value.id}
                            />
                        </Grid>
                        <Grid item xs={8}/>
                        <Grid item xs={12}>
                            <FinancialInfoGrid
                                financialInfo={clientObj.financial_info}
                                setFinancialInfo={(value) => updateClientInfo("financial_info", value)}
                                id="financial_info"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <STYLED_H4>ID Verification</STYLED_H4>
                        </Grid>

                        <Grid item xs={12}>
                            <H5>PRIMARY</H5>
                        </Grid>

                        <Grid item xs={5}>
                            <Dropdown
                                value={clientObj.primary_id_type ?? undefined}
                                onChange={(e) => updateClientInfo("primary_id_type", e.target.value as string)}
                                placeholder="Select ID type..."
                                options={IDTypeOptions}
                                label={{ text: "ID Type" }}
                                isHoverActionHidden={clientObj.primary_id_type ? false : true}
                                handleClear={() => updateClientInfo("primary_id_type", undefined)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Dropdown
                                value={clientObj.primary_issuing_country ?? undefined}
                                onChange={(e) => updateCountry("primary_issuing_country", e.target.value as string)}
                                placeholder="Select country..."
                                options={Countries}
                                label={{ text: "Issuing Country" }}
                                isHoverActionHidden={clientObj.primary_issuing_country ? false : true}
                                handleClear={() => updateCountry("primary_issuing_country", undefined)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ComboBox
                                value={clientObj.primary_issuing_jurisdiction ?? undefined}
                                onChangeFn={(value) => updateClientInfo("primary_issuing_jurisdiction", value as string)}
                                setText={(value) => updateClientInfo("primary_issuing_jurisdiction", value as string)}
                                options={primaryJurisOptions}
                                placeholder="Select jurisdiction"
                                label={{ text: "Issuing Jurisdiction", inputId: "primary_issuing_jurisdiction" }}
                                handleClear={() => updateClientInfo("primary_issuing_jurisdiction", undefined)}
                                isHoverActionHidden={clientObj.primary_issuing_jurisdiction ? false : true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.primary_id_number}
                                onChange={(e) => updateClientInfo("primary_id_number", e.target.value)}
                                placeholder="ID number"
                                label={{ text: "ID Number", inputId: "primary_id_number" }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BasicDatePicker
                                value={clientObj.primary_expiry ?? null}
                                onChange={(e) => updateClientInfo("primary_expiry", e as Date)}
                                label={{ text: "Expires" }}
                                minDate={new Date()}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <H5>SECONDARY</H5>
                        </Grid>

                        <Grid item xs={5}>
                            <Dropdown
                                value={clientObj.secondary_id_type ?? undefined}
                                onChange={(e) => updateClientInfo("secondary_id_type", e.target.value as string)}
                                placeholder="Select ID type..."
                                options={IDTypeOptions}
                                label={{ text: "ID Type" }}
                                isHoverActionHidden={clientObj.secondary_id_type ? false : true}
                                handleClear={() => updateClientInfo("secondary_id_type", undefined)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Dropdown
                                value={clientObj.secondary_issuing_country ?? undefined}
                                onChange={(e) => updateCountry("secondary_issuing_country", e.target.value as string)}
                                placeholder="Select country..."
                                options={Countries}
                                label={{ text: "Issuing Country" }}
                                isHoverActionHidden={clientObj.secondary_issuing_country ? false : true}
                                handleClear={() => updateCountry("secondary_issuing_country", undefined)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ComboBox
                                value={clientObj.secondary_issuing_jurisdiction ?? undefined}
                                onChangeFn={(value) => updateClientInfo("secondary_issuing_jurisdiction", value as string)}
                                setText={(value) => updateClientInfo("secondary_issuing_jurisdiction", value as string)}
                                options={secondaryJurisOptions}
                                placeholder="Select jurisdiction"
                                label={{ text: "Issuing Jurisdiction", inputId: "secondary_issuing_jurisdiction" }}
                                handleClear={() => updateClientInfo("secondary_issuing_jurisdiction", undefined)}
                                isHoverActionHidden={clientObj.secondary_issuing_jurisdiction ? false : true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BasicTextInput
                                fullWidth
                                value={clientObj.secondary_id_number}
                                onChange={(e) => updateClientInfo("secondary_id_number", e.target.value)}
                                placeholder="ID number"
                                label={{ text: "ID Number", inputId: "secondary_id_number" }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BasicDatePicker
                                value={clientObj.secondary_expiry ?? null}
                                onChange={(e) => updateClientInfo("secondary_expiry", e as Date)}
                                label={{ text: "Expires" }}
                                minDate={new Date()}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <H5>EMPLOYMENT</H5>
                        </Grid>

                        <Grid item xs={4}>
                            <Dropdown
                                value={clientObj.employment_status ?? undefined}
                                onChange={(e) => updateClientInfo("employment_status", e.target.value as EmploymentStatus)}
                                placeholder="Select status..."
                                options={EmploymentStatusOptions}
                                label={{ text: "Employment Status" }}
                                isHoverActionHidden={clientObj.employment_status ? false : true}
                                formatValue={formatEmploymentStatus}
                                handleClear={() => updateClientInfo("employment_status", undefined)}
                            />
                        </Grid>
                        {!clientObj.employment_status ? (
                            <Grid item xs={8} />
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    <BasicTextInput
                                        fullWidth
                                        value={clientObj.occupation}
                                        onChange={(e) => updateClientInfo("occupation", e.target.value)}
                                        placeholder="Occupation title"
                                        label={{ text: (clientObj.employment_status === EmploymentStatus.Retired || clientObj.employment_status === EmploymentStatus.Unemployed)
                                            ? "Previous Occupation"
                                            : "Occupation", inputId: "occupation"
                                        }}
                                    />
                                </Grid>
                                {(clientObj.employment_status !== EmploymentStatus.Unemployed && clientObj.employment_status !== EmploymentStatus.Retired) ? (
                                    <Grid item xs={4}>
                                        <ComboBox
                                            value={clientObj.industry ?? undefined}
                                            onChangeFn={(value) => updateClientInfo("industry", value as string)}
                                            placeholder="Select industry..."
                                            options={NAICSList}
                                            label={{ text: "Industry", inputId: "industry" }}
                                            isHoverActionHidden={clientObj.industry ? false : true}
                                            handleClear={() => updateClientInfo("industry", undefined)}
                                            text={clientObj.industry}
                                            setText={(text: string) => updateClientInfo("industry", text)}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} />
                                )}
                            </>
                        )}
                        {(
                            clientObj.employment_status
                            && clientObj.employment_status !== EmploymentStatus.Unemployed
                            && clientObj.employment_status !== EmploymentStatus.Retired
                        ) && (
                                <>
                                    <Grid item xs={6}>
                                        <BasicTextInput
                                            fullWidth
                                            value={clientObj.name_of_employer}
                                            onChange={(e) => updateClientInfo("name_of_employer", e.target.value)}
                                            placeholder="Business name"
                                            label={{ text: "Name of Employer", inputId: "name_of_employer" }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PhoneField
                                            value={clientObj.employer_phone}
                                            onChange={(value) => updateClientInfo("employer_phone", value)}
                                            label={{ text: "Employer phone", inputId: "employer_phone" }}
                                            error={{
                                                showError: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AddressGrid
                                            address={clientObj.employer_address}
                                            setAddress={(value) => updateClientInfo("employer_address", value)}
                                            id="employer_address"
                                        />
                                    </Grid>
                                </>
                            )}
                    </Grid>
                )}
            </div>
    )
};