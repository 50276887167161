import { useEffect, useState, useContext } from "react";

import { AdminContext } from "../../../../../context/AdminPanel/context";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import * as _ from "lodash";
import { validateModalObject } from "../../../../../libs/utils/validation";
import { FinancialInfoErrorState } from "../../../../../libs/types/UniversalSurvey/FinancialInfo/financialInfo";
import { RegionListItem } from "../../../../../libs/types/DealList/deals";
import { allRegion } from "../../../../../libs/resources/defaults/frontend/defaultAllRegionListItem";
import { defaultFinancialInfoErrorState } from "../../../../../libs/resources/defaults/errorStates/defaultFinancialInfoErrorState";
import { AdminActionTypes } from "../../../../../context/AdminPanel/actionTypes";
import { isObjectLoading } from "../../../../../libs/utils/loading";
import { AdminLoading } from "../../../../../libs/resources/enums/loading";
import { LegalFirm } from "../../../../../libs/types/UniversalSurvey/LegalFirm/legalFirm";
import { defaultLegalFirm } from "../../../../../libs/resources/defaults/frontend/defaultLegalFirm";
import { transformGlobalLegalFirmToLegalFirm } from "../../../../../libs/types/UniversalSurvey/utils/transformGlobals";
import { newLegalFirm, saveLegalFirm } from "../../../../../context/AdminPanel/asyncActions/legalFirms";
import LegalFirmForm from "./legalFirmForm";


export default function AdminLenderModal(props: Omit<ModalProps, "children">) {
    const [state, dispatch] = useContext(AdminContext);
    const [firmObj, setFirmObj] = useState<LegalFirm>({ ...defaultLegalFirm });
    const [selectedRegionList, setSelectedRegionList] = useState<RegionListItem[]>([]);
    const [originalRegionList, setOriginalRegionList] = useState<RegionListItem[]>([]);
    const [originalObj, setOriginalObj] = useState<LegalFirm>({ ...defaultLegalFirm });
    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);
    const [financialInfoErrorState, setFinancialInfoErrorState] = useState<FinancialInfoErrorState>({ ...defaultFinancialInfoErrorState });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if(state.legalFirms.editing && state.legalFirms.selectedLegalFirm) {
            let tempLegalFirmObj = transformGlobalLegalFirmToLegalFirm(state.legalFirms.selectedLegalFirm);
            setFirmObj(tempLegalFirmObj);
            setOriginalObj(tempLegalFirmObj);
            let tempRegionList: RegionListItem[] = [];
            if (state.legalFirms.selectedLegalFirm.all_regions) {
                tempRegionList.push(allRegion);
            } else {
                for (const region of state.legalFirms.selectedLegalFirm.regions) {
                    tempRegionList.push({
                        id: region.id,
                        province: region.province,
                        name: region.name,
                        abbreviation: region.abbreviation,
                        label: region.abbreviation
                    });
                }
            }
            setSelectedRegionList(tempRegionList);
            setOriginalRegionList(tempRegionList);
        }
    }, [state.legalFirms.editing, state.legalFirms.selectedLegalFirm]);

    function updateLegalFirmInfo<
        K extends keyof LegalFirm,
        V extends LegalFirm[K]
    >(key: K, value: V): void {
        const tempLegalFirmObj = { ...firmObj }
        // @ts-ignore
        if (Object.keys(tempLegalFirmObj).includes(key)) tempLegalFirmObj[key] = value;
        setFirmObj(tempLegalFirmObj);
    }

    function submit() {
        const regionList = selectedRegionList.filter((region) => region.label !== "All");
        if (state.legalFirms.editing) {
            saveLegalFirm(dispatch, firmObj, regionList);
        } else {
            newLegalFirm(dispatch, firmObj, regionList);
        }
    }

    function discard() {
        dispatch({ type: AdminActionTypes.SET_EDITING_LEGAL_FIRM, payload: false });
        setFirmObj({ ...defaultLegalFirm });
        setFinancialInfoErrorState({ ...defaultFinancialInfoErrorState });
        setOriginalRegionList([]);
        setOriginalObj({ ...defaultLegalFirm })
        setSelectedRegionList([]);
        props.onClose ? props.onClose() : undefined;
    }

    function isSaveDisabled(): boolean {
        if (!(firmObj.name && selectedRegionList.length > 0) || (_.isEqual(firmObj, originalObj) && _.isEqual(selectedRegionList.sort(), originalRegionList.sort())) || validateModalObject(financialInfoErrorState)) return true;
        return false;
    }

    useEffect(() => {
        isSaveDisabled() ? setSaveDisabled(true) : setSaveDisabled(false);
    }, [firmObj, financialInfoErrorState]);

    return (
        <ModalBase 
            open={props.open}
            onClose={discard}
            onSubmit={submit}
            size={props.size}
            title={props.title}
            saveDisabled={saveDisabled}
            closeAfterSaving={discard}
            isLoading={isObjectLoading(state.loading.objectsLoading, [AdminLoading.SelectedLegalFirm])}
            isSaving={isObjectLoading(state.loading.objectsLoading, [AdminLoading.SaveLegalFirm])}
        >
            <LegalFirmForm
                legalFirm={firmObj}
                setLegalFirm={setFirmObj}
                updateLegalFirmInfo={updateLegalFirmInfo}
                selectedRegionList={selectedRegionList}
                setSelectedRegionList={setSelectedRegionList}
                regions={state.regions.regions}
                financialInfoErrorState={financialInfoErrorState}
                setFinancialInfoErrorState={setFinancialInfoErrorState}
                confirmDiscardOpen={confirmDiscardOpen}
                setConfirmDiscardOpen={setConfirmDiscardOpen}
                discard={discard}
            />
        </ModalBase>
    );
}