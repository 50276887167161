import { SimplePoaEoeRelationship } from "../../../types/UniversalSurvey/PoaEoeRelationship/SimplePoaEoeRelationship";

export const defaultSimplePoaEoeRelationship: SimplePoaEoeRelationship = {
    id: -1,
    type: undefined,
    acting: undefined,
    signing: false,
    reason_for_poa: undefined,
    representative: undefined,
    representee: undefined
}