import * as actions from "../actionTypes"

import { Transaction } from "../../../libs/types/UniversalSurvey/Transaction/transaction";
import { TransactionTypes } from "../../../libs/resources/enums/transactions";
import { TransactionMappingPills } from "../../../libs/resources/mappings/transactions";
import { findAndRemove } from "../../../libs/utils/arrays";


export type TransactionsState = {
    transactionList: Transaction[];
    transactionBeingEdited: Transaction | undefined;
}

export const defaultTransactionsState: TransactionsState = {
    transactionList: [],
    transactionBeingEdited: undefined
}

export function transactionsReducer(state: TransactionsState, action: Record<string, any>): TransactionsState {
    switch (action.type) {
        case actions.SET_TRANSACTION_LIST:
            return { ...state, transactionList: action.payload };
        case actions.REMOVE_TRANSACTION:
            const tempTransactions = findAndRemove([...state.transactionList], ["id", "transaction_type"], [action.payload.id, action.payload.transaction_type]);
            decrementNumbers(tempTransactions, action.payload.transaction_type);
            return { ...state, transactionList: tempTransactions };
        case actions.CREATE_MORTGAGE_TRANSACTION:
            const [newMortgage, mortgageTransactionsCopy] = createTransaction(state.transactionList, TransactionTypes.Mortgage, action.payload);
            const firstNonMortgage = mortgageTransactionsCopy.find((transaction) => transaction.transaction_type === TransactionTypes.ILA || transaction.transaction_type === TransactionTypes.TitleTransfer);
            if(firstNonMortgage) {
                const index = mortgageTransactionsCopy.indexOf(firstNonMortgage)
                mortgageTransactionsCopy.splice(index, 0, newMortgage);
            } else {
                mortgageTransactionsCopy.push(newMortgage);
            }
            return { ...state, transactionList: mortgageTransactionsCopy };
        case actions.CREATE_ILA_TRANSACTION:
            const [newIla, ilaTransactionsCopy] = createTransaction(state.transactionList, TransactionTypes.ILA, action.payload);
            const firstTitleTransfer = ilaTransactionsCopy.find((transaction) => transaction.transaction_type === TransactionTypes.TitleTransfer);
            if (firstTitleTransfer) {
                const index = ilaTransactionsCopy.indexOf(firstTitleTransfer);
                ilaTransactionsCopy.splice(index, 0, newIla);
            } else {
                ilaTransactionsCopy.push(newIla);
            }
            return { ...state, transactionList: ilaTransactionsCopy };
        case actions.CREATE_TITLE_TRANSFER_TRANSACTION:
            const [newTitleTransfer, ttTransactionsCopy] = createTransaction(state.transactionList, TransactionTypes.TitleTransfer, action.payload);
            ttTransactionsCopy.push(newTitleTransfer);
            return { ...state, transactionList: ttTransactionsCopy };
        case actions.UPDATE_MORTGAGE_TRANSACTION_LABEL:
            const transactionsCopy = [...state.transactionList];
            const matchingMortgage = transactionsCopy.find((transaction) => transaction.transaction_type === TransactionTypes.Mortgage && transaction.id === action.payload.mortgageId);
            if (matchingMortgage) {
                const index = transactionsCopy.indexOf(matchingMortgage);
                const separatorIndex = matchingMortgage.label!.indexOf("-");
                transactionsCopy[index] = {
                    ...matchingMortgage,
                    label: separatorIndex > 0 ? (
                        action.payload.abbr_name ?
                            `${matchingMortgage.label?.slice(0, separatorIndex + 2)} ${action.payload.abbr_name}` :
                            action.payload.name ?
                            `${matchingMortgage.label?.slice(0, separatorIndex + 2)} ${action.payload.name}` :
                            matchingMortgage.label?.slice(0, separatorIndex - 1)
                    ) : matchingMortgage.label + ` - ${action.payload.abbr_name ?? action.payload.name}`
                }
            }
            return { ...state, transactionList: transactionsCopy };
        case actions.SET_TRANSACTION_BEING_EDITED:
            return { ...state, transactionBeingEdited: action.payload };
        default:
            return state;
    }
}

function decrementNumbers(transactionList: Transaction[], transactionType: TransactionTypes): void {
    const filteredTransactions: Transaction[] = transactionList.filter((transaction) => transaction.transaction_type === transactionType);
    if (filteredTransactions.length === 1) {
        transactionList[transactionList.indexOf(filteredTransactions[0])] = {
            id: filteredTransactions[0].id,
            transaction_type: transactionType,
            label: transactionType === TransactionTypes.Mortgage ?
                (String(filteredTransactions[0].label?.slice(0, 3)) + String(filteredTransactions[0].label?.slice(5))) :
                TransactionMappingPills[transactionType as keyof typeof TransactionMappingPills]
        };
    } else if (filteredTransactions.length > 1) {
        for (let i = 0; i < filteredTransactions.length; i++) {
            let splitLabel: string[] | undefined = filteredTransactions[i].label?.split(" ");
            if (splitLabel) {
                splitLabel[transactionType === TransactionTypes.Mortgage ? 1 : splitLabel.length - 1] = (i + 1).toString();
                filteredTransactions[i].label = splitLabel.join(" ");
            }
        }
    }
}

function createTransaction(transactionList: Transaction[], transactionType: TransactionTypes, transId: number): [Transaction, Transaction[]] {
    const transactionsCopy = [...transactionList];
    const filteredTransactions = transactionsCopy.filter((transaction) => transaction.transaction_type === transactionType);
    const newTransaction: Transaction = {
        id: transId,
        transaction_type: transactionType,
        label: TransactionMappingPills[transactionType as keyof typeof TransactionMappingPills]
    }
    if (filteredTransactions.length > 0) {
        newTransaction.label += ` ${filteredTransactions.length + 1}`;
    }
    if (filteredTransactions.length === 1) {
        const oldTransaction = transactionsCopy.find((transaction) => transaction.transaction_type === transactionType);
        if(transactionType === TransactionTypes.Mortgage) {

        } else {
            if(oldTransaction) {
                const oldTransactionReplacement = { ...oldTransaction };
                oldTransactionReplacement.label += " 1";
                transactionsCopy[transactionsCopy.indexOf(oldTransaction)] = oldTransactionReplacement;
            }
        }
    }
    return [newTransaction, transactionsCopy];
}