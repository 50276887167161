import { ClientRequest } from "../../../types/UniversalSurvey/Client/clientRequest";
import { defaultAddressRequest } from "./defaultAddressRequest";
import { defaultFinancialInfoRequest } from "./defaultFinancialInfoRequest";

export const defaultClientRequest: ClientRequest = {
    birthdate: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    title: null,
    phone_work: null,
    fax: null,
    email: null,
    phone_cell: null,
    phone_home: null,
    is_canadian_resident: null,
    reside_at_subject: null,
    residential_status: null,
    address_for_service: defaultAddressRequest,
    pronouns: null,
    preferred_name: null,
    marital_status: null,
    type: null,
    corporation_name: null,
    lived_at_property: null,
    residency: null,
    income_tax_act: null,
    days_183: null,
    sin: null,
    employment_status: null,
    primary_id_type: null,
    secondary_id_type: null,
    primary_issuing_country: null,
    secondary_issuing_country: null,
    primary_issuing_jurisdiction: null,
    secondary_issuing_jurisdiction: null,
    primary_id_number: null,
    secondary_id_number: null,
    primary_expiry: null,
    secondary_expiry: null,
    occupation: null,
    name_of_employer: null,
    employer_address: defaultAddressRequest,
    employer_phone: null,
    industry: null,
    tenants_in_common_share: null,
    financial_info: defaultFinancialInfoRequest
}