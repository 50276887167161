import { useEffect, useState, useContext, Dispatch } from "react";

import { UniversalSurveyContext } from '../../../../../context/UniversalSurvey/context';
import * as actions from "../../../../../context/UniversalSurvey/actionTypes";

import { Grid, Stack } from "@mui/material";

import ModalBase, { ModalProps } from "../../../../../../components/Common/Modal/ModalBase"
import { Large, Small } from "../../../../../../components/Common/Typography";
import Theme from "../../../../../../components/Common/componentStyling/Theme";
import Link from "../../../../../../components/Common/Links/Link";
import { FireInsuranceBroker } from "../../../../../libs/types/UniversalSurvey/FireInsuranceBroker/fireInsuranceBroker";
import { MortgageBroker } from "../../../../../libs/types/UniversalSurvey/MortgageBroker/mortgageBroker";
import { defaultFireInsuranceBroker } from "../../../../../libs/resources/defaults/frontend/defaultFireInsuranceBroker";
import { defaultMortgageBroker } from "../../../../../libs/resources/defaults/frontend/defaultMortgageBroker";
import { regionListToString } from "../../../../../libs/utils/address";

type Props = Omit<ModalProps, "children"> & {
    type: "fire_insurance" | "mortgage";
};

export default function ReadonlyGeneralBrokerModal(props: Props) {

    const [state, dispatch] = useContext(UniversalSurveyContext);
    const [generalObj, setGeneralObj] = useState<FireInsuranceBroker | MortgageBroker>({ ...defaultFireInsuranceBroker });

    useEffect(() => {
        switch(props.type) {
            case "fire_insurance":
                if (state.fireInsuranceBrokers.editingBrokerRecord && state.fireInsuranceBrokers.brokerInfo) {
                    setGeneralObj({ ...state.fireInsuranceBrokers.brokerInfo });
                } else {
                    setGeneralObj({ ...defaultFireInsuranceBroker });
                }
                break;
            case "mortgage":
                if (state.mortgageBrokers.editingMortgageBrokerRecord && state.mortgageBrokers.brokerInfo) {
                    setGeneralObj({ ...state.mortgageBrokers.brokerInfo });
                } else {
                    setGeneralObj({ ...defaultMortgageBroker });
                }
                break;
        }
    }, [state.fireInsuranceBrokers.editingBrokerRecord, state.mortgageBrokers.editingMortgageBrokerRecord]);

    function generateSubtitle(): string {
        switch(props.type) {
            case "fire_insurance":
                return "FIRE INSURANCE BROKER";
            case "mortgage":
                return "MORTGAGE BROKER";
        }
    }

    function generateNotes() {
        switch(props.type) {
            case "fire_insurance":
                return state.fireInsuranceBrokers.brokerInfo?.fire_insurance_broker?.notes;
            case "mortgage":
                return state.mortgageBrokers.brokerInfo?.mortgage_broker?.notes;
        }
    }

    function discard() {
        switch(props.type) {
            case "fire_insurance":
                dispatch({ type: actions.SET_EDITING_FIRE_INSURANCE_BROKER_RECORD, payload: false });
                setGeneralObj({ ...defaultFireInsuranceBroker });
                break;
            case "mortgage":
                dispatch({ type: actions.SET_EDITING_MORTGAGE_BROKER_RECORD, payload: false });
                setGeneralObj({ ...defaultMortgageBroker });
                break;
        }
        props.onClose ? props.onClose() : undefined;
    }

    function generateTeam() {
        switch(props.type) {
            case "mortgage":
                return state.mortgageBrokers.brokerInfo?.mortgage_brokerage_record?.name;
            case "fire_insurance":
                return state.fireInsuranceBrokers.brokerInfo?.fire_insurance_brokerage_record?.name;
            }
    }

    return (
        <ModalBase
            readonly
            open={props.open}
            onClose={discard}
            title={generalObj.name}
            size="small"
            subtitle={generateSubtitle()}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Brokerage:
                        </Small>
                        <Large>{generateTeam()}</Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Province(s) of Operation:
                        </Small>
                        <Large>{regionListToString(generalObj.all_regions, generalObj.regions)}</Large>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Email:
                        </Small>
                        <Link
                            href={generalObj.email}
                            type="email"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Work Phone:
                        </Small>
                        <Link
                            href={generalObj.phone_work}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Cell Phone:
                        </Small>
                        <Link
                            href={generalObj.phone_cell}
                            type="phone"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Fax:
                        </Small>
                        <Link
                            href={generalObj.fax}
                            type="fax"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1}>
                        <Small style={{ color: Theme.INPUT }}>
                            Notes:
                        </Small>
                        <Large>{generateNotes()}</Large>
                    </Stack>
                </Grid>
            </Grid>
        </ModalBase>
    );
}