import { useState } from 'react';
import { styled } from '@mui/material';
import { Small } from '../../../../../components/Common/Typography/index';
import colors from '../../../../../components/Common/componentStyling/Colors';
import { XCircle, PlusIcon, Mortgage, Sale, Purchase, LegalAdvice, TransferTitle, TransferCharge, PrimaryIcon, SurveyIcon } from '../../../../../components/Common/Icons/Iconography';
import theme from '../../../../../components/Common/componentStyling/Theme';
import { TransactionTypes } from '../../../../libs/resources/enums/transactions';
import { BORDER_1, BORDER_RADIUS_ACTION_W_ICON } from '../../../../../components/Common/componentStyling/Styles';
import { DealType } from 'types/deal';

type PillProps = {
    type: TransactionTypes | DealType;
    label?: string;
    handleDelete?: () => void;
    handleAdd?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    handleEdit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isAddPill?: boolean;
    isPrimary?: boolean;
    isRolePill?: boolean;
    testId?: string;
}

export default function TransactionPill(props: PillProps) {
    const [isChipHovered, setIsChipHovered] = useState<boolean>(false);
    const [isRemoveHovered, setIsRemoveHovered] = useState<boolean>(false);

    function getBackgroundColor(): string {
        switch (props.type) {
            case TransactionTypes.Mortgage:
                return colors.BLUE_700;
            case TransactionTypes.Sale:
                return colors.GREEN_300;
            case TransactionTypes.Purchase:
                return colors.RED_100;
            case TransactionTypes.ReverseMortgage:
                return colors.YELLOW_500;
            case TransactionTypes.ILA:
                return colors.BLUE_100;
            case TransactionTypes.TitleTransfer:
                return colors.PURPLE_100;
            case TransactionTypes.TransferOfCharge:
                return colors.YELLOW_100;
            case TransactionTypes.Deal:
                return colors.BLUE_100;
            case TransactionTypes.AddTransaction:
                return colors.WHITE;
            default:
                return "";
        }
    }

    function getIcon(): JSX.Element {
        const iconColor = isRemoveHovered ? colors.WHITE : colors.BLACK
        switch (props.type) {
            case TransactionTypes.Mortgage:
                return <Mortgage size={24} color={colors.WHITE} />;
            case TransactionTypes.Sale:
                return <Sale color={iconColor} />;
            case TransactionTypes.Purchase:
                return <Purchase color={iconColor} />;
            case TransactionTypes.ReverseMortgage:
                return <Mortgage color={iconColor} />;
            case TransactionTypes.ILA:
                return <LegalAdvice color={iconColor} />;
            case TransactionTypes.TitleTransfer:
                return <TransferTitle color={iconColor} />;
            case TransactionTypes.TransferOfCharge:
                return <TransferCharge color={iconColor} />;
            case TransactionTypes.Deal:
                return <SurveyIcon color={iconColor} />
            default:
                return <></>;
        }
    }

    return (
        <Wrapper data-testid={props.testId}>
            {props.isPrimary &&
                <PrimaryDiv
                    bgColor={getBackgroundColor()}
                    isRemoveHovered={isRemoveHovered}
                    isEditable={props.handleEdit ? true : false}
                    onMouseEnter={() => setIsChipHovered(true)}
                    onMouseLeave={() => setIsChipHovered(false)}
                >
                    <PrimaryIcon color={(isRemoveHovered || props.type === TransactionTypes.Mortgage) ? colors.WHITE : colors.BLACK}/>
                </PrimaryDiv>
            }
            <StyledChip
                bgColor={getBackgroundColor()}
                isChipHovered={isChipHovered}
                isRemoveHovered={isRemoveHovered}
                isEditable={props.handleEdit ? true : false}
                isPrimary={props.isPrimary ? true : false}
                type={props.type}
                onMouseEnter={() => setIsChipHovered(true)}
                onMouseLeave={() => setIsChipHovered(false)}
                {...(props.type === TransactionTypes.AddTransaction && { onClick: props.handleAdd})}
                {...(props.handleEdit && { onClick: props.handleEdit})}
            >
                {!props.isAddPill && getIcon()}
                <StyledSmall
                    isChipHovered={isChipHovered}
                    isRemoveHovered={isRemoveHovered}
                    type={props.type}
                >
                    {props.label ? props.label : props.type}
                </StyledSmall>
                {props.handleDelete && isChipHovered && !props.isAddPill && (!props.isPrimary || props.isRolePill) && 
                    <EndIconDiv
                        onMouseEnter={() => setIsRemoveHovered(true)}
                        onMouseLeave={() => setIsRemoveHovered(false)}
                        onClick={(e: any) => {
                            if(props.handleDelete){
                                props.handleDelete();
                            }
                            e.stopPropagation();
                        }}
                    >
                        <XCircle color={(isRemoveHovered || props.type === TransactionTypes.Mortgage) ? colors.WHITE : colors.BLACK} />
                    </EndIconDiv>
                }
                {props.isAddPill &&
                    <EndIconDiv>
                        <PlusIcon color={isChipHovered ? colors.BLACK : theme.INPUT} />
                    </EndIconDiv>
                }
            </StyledChip>
        </Wrapper>
    );
}

const StyledChip = styled('div', { shouldForwardProp: 
    (prop) => (
        prop !== "isChipHovered" && 
        prop !== "isRemoveHovered" && 
        prop !== "bgColor" && 
        prop !== "isEditable" && 
        prop !== "isPrimary"
    )
})<{
    bgColor: string;
    isChipHovered: boolean;
    isRemoveHovered: boolean;
    isEditable: boolean;
    type: TransactionTypes | DealType;
    isPrimary: boolean;
}>(({ bgColor, isChipHovered, isRemoveHovered, isEditable, type, isPrimary }) => ({
    backgroundColor: type === TransactionTypes.AddTransaction ? (isChipHovered ? colors.WHITE : "")
        : isRemoveHovered ? colors.ORANGE_500 : bgColor,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: isPrimary ? "0rem 5rem 5rem 0rem" : BORDER_RADIUS_ACTION_W_ICON,
    border: type === TransactionTypes.AddTransaction ? (isChipHovered ? BORDER_1(colors.BLACK) : BORDER_1(theme.INPUT)) : "",
    height: "2.8rem",
    width: "fit-content",
    textTransform: "none",
    padding: type === TransactionTypes.AddTransaction ? "0rem 0.2rem 0rem 1rem" : "0rem 0.2rem 0rem 0.2rem",
    gap: "0.5rem",
    cursor: (type === TransactionTypes.AddTransaction || isEditable) ? "pointer" : "default"
}));

const StyledSmall = styled(Small, { shouldForwardProp: 
    (prop) => (
        prop !== "isChipHovered" && 
        prop !== "isRemoveHovered"
    )
})<{
    isChipHovered: boolean;
    isRemoveHovered: boolean;
    type: TransactionTypes | DealType;
}>(({ isChipHovered, isRemoveHovered, type }) => ({
    color: type === TransactionTypes.AddTransaction ? (isChipHovered ? colors.BLACK : theme.INPUT)
        : (isRemoveHovered || type === TransactionTypes.Mortgage) ? colors.WHITE : colors.BLACK,
    paddingRight: "0.8rem"
}));

const EndIconDiv = styled('div')({
    paddingTop: "0.4rem",
    marginLeft: "-0.8rem",
    cursor: "pointer"
});

const PrimaryDiv = styled('div')<{
    bgColor: string;
    isEditable: boolean;
    isRemoveHovered: boolean;
}>(({ bgColor, isEditable, isRemoveHovered }) => ({
    backgroundColor: isRemoveHovered ? colors.RED_500 : bgColor,
    display: "flex",
    alignItems: "center",
    borderRadius: "0.6rem 0rem 0rem 0.6rem",
    height: "2.8rem",
    width: "fit-content",
    padding: "0rem 0.2rem 0rem 0.2rem",
    cursor: isEditable ? "pointer" : "default",
    marginRight: "0.1rem",
}));

const Wrapper = styled('div')({
    display: "flex",
    flexDirection: "row"
})